import { Component, OnInit, ViewChild } from "@angular/core";
import { AppComponent } from "src/app/app.component";
import { ReasonTypeModel } from 'src/app/shared/model/master/reason-type.model';
import { ReasonTypeService } from 'src/app/shared/service/master/reason-type.service';
import { GlobalFilterModel, IndexModel, SearchModel } from 'src/app/shared/model/common.model';
import { GlobalMessagesModel } from 'src/app/shared/model/common-messages.model';
import { AppSetting } from 'src/app/shared/model/appsetting.model';
import { MatTableDataSource, MatDialog, MatSort, MatPaginator} from "@angular/material";
import { ConfirmationDialogComponent } from "src/app/confirmation-dialog/confirmation-dialog.component";
import { AlertService } from 'src/app/shared/service/alert.service';
import { CommonService } from 'src/app/shared/service/common.service';
import { ColumnHeaderModel } from 'src/app/shared/model/commonddl.model';
import { UserTypeEnum } from 'src/app/shared/enum/fixed-values.enum';
import { AuthenticationService } from 'src/app/shared/service/authentication.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-reason-type',
  templateUrl: './reason-type.component.html',
  styleUrls: ['./reason-type.component.scss']
})
export class ReasonTypeComponent implements OnInit {

  model: ReasonTypeModel[];
  indexModel: IndexModel;
  dataSource: any;
  totalRecords: number;
  defaultValue: SearchModel;

  displayedColumns: string[] = ["index","Reason","ReasonHindi","IsActive","Action"];
  columnsToDisplay: string[] = this.displayedColumns.slice();
  ViewdisplayedColumns: ColumnHeaderModel[] = [
    { Value: "Reason", Text: "Return Reason Type" },
    { Value: "ReasonHindi", Text: "Return Reason Type(Hindi)" },
  ];

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  constructor(private readonly appComponnet: AppComponent,
    private readonly _alertService: AlertService,
    private readonly _reasonTypeService: ReasonTypeService,
    private readonly _router: Router,
    private readonly _dialog: MatDialog,
    public readonly _commonService: CommonService,
    private readonly _authService: AuthenticationService
  ) {
    this.appComponnet.setPageLayout("Return Reason Type :","add","Create","master/reason-type/add");
    //this.indexModel = new IndexModel(); 
    const Temp = JSON.parse(sessionStorage.getItem("IsReasonTypeStorage"));

    this.indexModel = JSON.parse(sessionStorage.getItem("ReasonTypeFilter"));

    if (!this.indexModel) {
      this.indexModel = new IndexModel();
    } else if (this.indexModel.Search && Temp) {
      sessionStorage.setItem("IsReasonTypeStorage", JSON.stringify(false));
      this.defaultValue = new SearchModel();
      var temp = JSON.parse(this.indexModel.Search);
      this.defaultValue.Key = Object.keys(temp)[0];
      this.defaultValue.Value = String(Object.values(temp)[0]);
    }
    else {
      this.indexModel = new IndexModel();
      this.getList();
    }
  }

  ngOnInit() {
    this.getList();
  }

  getList() {    
    this._reasonTypeService.GetList(this.indexModel).subscribe(
      data => {
        if (data.IsSuccess) {
          this.model = <ReasonTypeModel[]>data.Data.Data;
          this.dataSource = new MatTableDataSource<ReasonTypeModel>(this.model);
          this.totalRecords = data.Data.TotalRecords;
          if (!this.indexModel.IsPostBack) {
            this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.sort;
          }
        } else {
          this._alertService.error(data.Message);
        }
      },
      error => {
        this._alertService.error(error.message);
      }
    );
  }

  updateDeleteStatus(id) {
    const dialogRef = this._dialog.open(ConfirmationDialogComponent, {width: "50%", data: GlobalMessagesModel.ConfirmDeleted });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this._reasonTypeService.ChangeDeleteStatus(id).subscribe(
          data => {
            if (data.IsSuccess) {
              this._alertService.success(data.Message);
              this._commonService.ScrollingTop();
              this.getList();
            }
          },
          error => {
            this._alertService.error(error.message);
          }
        );
      }
    });
  }

  updateActiveStatus(id) {
    const dialogRef = this._dialog.open(ConfirmationDialogComponent, { width: "50%", data: GlobalMessagesModel.ConfirmStatusChanged });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this._reasonTypeService.ChangeActiveStatus(id).subscribe(
          data => {
            if (data.IsSuccess) {
              this._alertService.success(data.Message);
              this.getList();
            }
          },
          error => {
            this._alertService.error(error.message);
          }
        );
      }
    });
  }

  sortData(event) {
    this.indexModel.OrderBy = event.active;
    this.indexModel.OrderByAsc = event.direction == AppSetting.orderByDscAsc ? AppSetting.orderByAsc : AppSetting.orderByDsc;
    this.indexModel.IsPostBack = true;
    this.getList();
  }

  onPaginateChange(event) {
    this.indexModel.Page = event.pageIndex + 1;
    this.indexModel.PageSize = event.pageSize;
    this.indexModel.IsPostBack = true;
    this.getList();
  }
  SearchByKeyword(searchValue: GlobalFilterModel) {
    this.indexModel.Search = searchValue.search;
    this.indexModel.AdvanceSearchModel = { ActiveStatus: searchValue.ActiveStatus }

    this.getList();
  }

  ClearSearch() {
    this.indexModel = new IndexModel();
    this.getList();
  }


}
