import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AppComponent } from 'src/app/app.component';
import { GeneralCategoryEnumLocal, GeneralCategoryEnumProd } from 'src/app/shared/enum/fixed-values.enum';
import { GeneralEntryModel } from 'src/app/shared/model/general-entry-model.model';
import { AlertService } from 'src/app/shared/service/alert.service';
import { GeneralEntryService } from 'src/app/shared/service/general-entry.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-detail-general-entry',
  templateUrl: './detail-general-entry.component.html',
  styleUrls: ['./detail-general-entry.component.scss']
})

export class DetailGeneralEntryComponent implements OnInit {

  recordId: number;
  detailModel: GeneralEntryModel;

  constructor(
    private readonly _generalEntryService: GeneralEntryService,
    private readonly _alertService: AlertService,
    private _route: ActivatedRoute,
    private _appComponent: AppComponent
  ) {
    this.recordId = this._route.snapshot.params.id;
    this._appComponent.setPageLayout("General Entry Detail :", "keyboard_backspace", "Back to list", "/general-entry");
  }

  ngOnInit() {
    this.getDetail();
  }

  get isDocCategory(): boolean {
    let dbCatCode = environment.production ? GeneralCategoryEnumProd.Documents : GeneralCategoryEnumLocal.Documents;
    return (Number(this.detailModel.GeneralEntryCategoryCode) == Number(dbCatCode));
  }

  getDetail() {
    this._generalEntryService.Detail(this.recordId).subscribe((data) => {
      if (data.IsSuccess) {
        this.detailModel = <GeneralEntryModel>data.Data;
      }
    }, (error) => {
      this._alertService.error(error.message);
    });
  }

  downloadMyFile(temp, filename = undefined) {
    const link = document.createElement("a");
    link.setAttribute("href", temp);
    link.setAttribute(filename ? filename : "download", `Documents`);
    document.body.appendChild(link);
    link.click();
    link.remove();
  }

}
