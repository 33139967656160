import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { Router, NavigationStart } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { CommonLayoutModel } from '../model/commonddl.model';

@Injectable()

export class AlertService {
  layoutmodel: CommonLayoutModel;
  private subject = new Subject<any>();
  private keepAfterNavigationChange = false;

  constructor(private router: Router, private snackBar: MatSnackBar) {
    // clear alert message on route change
 let serv=   router.events.subscribe(event => {
  serv.unsubscribe();
      if (event instanceof NavigationStart) {
        if (this.keepAfterNavigationChange) {
          // only keep for a single location change
          this.keepAfterNavigationChange = false;
        } else {
          // clear alert
          this.subject.next();
        }
      }
    });
  }

  success(message: string, keepAfterNavigationChange = false,timeinSec=5) {
    this.keepAfterNavigationChange = keepAfterNavigationChange;
    this.subject.next({ type: 'success', text: message })
    setTimeout(() => {
      this.blank();
    }, (timeinSec*1000));

    // this.snackBar.open(message, 'OK', {
    //   duration: (timeinSec * 1000),
    //   panelClass: ['mat-toolbar', 'mat-primary']
    // });
  }

  error(message: string, keepAfterNavigationChange = false,timeinSec=5) {
    this.keepAfterNavigationChange = keepAfterNavigationChange;
    this.subject.next({ type: 'error', text: message });
    setTimeout(() => {
      this.blank();
    }, (timeinSec*1000));
  }

  blank() {
    this.subject.next();
  }

  getMessage(): Observable<any> {
    return this.subject.asObservable().pipe();
  }

  setPageLayout(Title: string, Maticon: string, Maticontitle: string, Routelink: string) {
    this.layoutmodel.Title = Title;
    this.layoutmodel.Maticon = Maticon;
    this.layoutmodel.Maticontitle = Maticontitle;
    this.layoutmodel.Routelink = Routelink;
  }

}
