import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { CustomFilterTypeEnum, UserTypeEnum } from '../../enum/fixed-values.enum';
import { AppSetting } from '../../model/appsetting.model';
import { DdlItemModel, DDLModel } from '../../model/commonddl.model';
import { AlertService } from '../../service/alert.service';
import { AuthenticationService } from '../../service/authentication.service';
import { CommonService } from '../../service/common.service';
import { CustomFilterDialogComponent } from './custom-filter-dialog/custom-filter-dialog.component';

@Component({
  selector: 'app-custom-filter-selector',
  templateUrl: './custom-filter-selector.component.html',
  styleUrls: ['./custom-filter-selector.component.scss']
})
export class CustomFilterSelectorComponent implements OnInit {

  @Input() control = new FormControl();
  @Input() label: string = "";
  @Input() Multiple = false;
  @Input() type: CustomFilterTypeEnum;

  

  public ddl: DdlItemModel[] = [];

  constructor(private readonly _dialogRef: MatDialog,
    private readonly _commonService: CommonService,
    private readonly _alertService: AlertService,
    private readonly _authService: AuthenticationService,) {

  }

  selectedFilter = { name: '', id: 0 }

  ngOnInit() {

    if (this.type == CustomFilterTypeEnum.Vendor) {
      this.GetVendor();
    }
    else if (this.type == CustomFilterTypeEnum.Department) {
      this.GetDepartment();

    }
  }

  get isFilterDisabled(){
    let userDetail = this._authService.LoggedInUserDetail.UserViewModel
    if(userDetail.UserTypeCode == UserTypeEnum.VNDRCode || userDetail.UserTypeCode == UserTypeEnum.VNDRUSERCode) {
      return this.type== CustomFilterTypeEnum.Vendor
    } else{
      return this.type== CustomFilterTypeEnum.Department
    }
  }

 

  public openDialog() {
    const dialogRef = this._dialogRef.open(CustomFilterDialogComponent, { data: { type: this.type } });

    dialogRef.afterClosed().subscribe(result => {
      if (result)
        this.selectedFilter.id = result.toString()
      this.control.setValue(this.selectedFilter.id);

    });
  }

  private GetVendor() {
    this._commonService.GetAllDDL(AppSetting.DdlKeyForVendor).subscribe(
      data => {
        if (data.IsSuccess) {
          let ddlList = <DDLModel>data.Data;
          this.ddl = ddlList.ddlVendor;
        }
      },
      error => {
        this._alertService.error(error.message);
      }
    );
  }

  private GetDepartment() {
    this._commonService.GetAllDDL(AppSetting.DdlKeyForDepartment).subscribe(
      data => {
        if (data.IsSuccess) {
          let ddlList = <DDLModel>data.Data;
          this.ddl = ddlList.ddlDepartment;
        }
      },
      error => {
        this._alertService.error(error.message);
      }
    );
  }


}
