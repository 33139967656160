import { Component, Inject, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { UserTypeEnum } from 'src/app/shared/enum/fixed-values.enum';
import { AppSetting } from 'src/app/shared/model/appsetting.model';
import { GlobalMessagesModel } from 'src/app/shared/model/common-messages.model';
import { DDLModel } from 'src/app/shared/model/commonddl.model';
import { UserViewModel, UserCreationModel, userCreationConfigModel, UpdateUserOfficeModel } from 'src/app/shared/model/user.model';
import { AlertService } from 'src/app/shared/service/alert.service';
import { AuthenticationService } from 'src/app/shared/service/authentication.service';
import { CommonService } from 'src/app/shared/service/common.service';
import { UserService } from 'src/app/shared/service/user.service';
import { SetUserPrimaryDetailDialogComponent } from '../set-user-primary-detail-dialog/set-user-primary-detail-dialog.component';

@Component({
  selector: 'app-update-user-office-dialog',
  templateUrl: './update-user-office-dialog.component.html',
  styleUrls: ['./update-user-office-dialog.component.scss']
})

export class UpdateUserOfficeDialogComponent implements OnInit {

  frmGrp: FormGroup;
  recordId: number = 0;
  loginUser: UserViewModel;
  hasMultiRights: boolean = false;
  canUpdateUser: boolean = true;
  ddlList: DDLModel = new DDLModel();
  model: UserCreationModel = new UserCreationModel();
  updateModel: UpdateUserOfficeModel = new UpdateUserOfficeModel();

  fieldConfig = new userCreationConfigModel();

  constructor(
    private readonly _authService: AuthenticationService,
    private readonly fb: FormBuilder,
    private readonly _commonService: CommonService,
    private readonly _alertService: AlertService,
    private _userService: UserService,
    public _dialogRef: MatDialogRef<UpdateUserOfficeDialogComponent>,
    private readonly _dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    if (data && data.id) {
      this.recordId = Number(data.id);
    }
    this.loginUser = this._authService.GetCurrentUserDetail().UserViewModel;
  }

  ngOnInit() {
    this.formGroupInit();
    this.getUserDetail();
  }

  formGroupInit() {
    this.frmGrp = this.fb.group({
      DepartmentCodes: [null, null],
      DepartmentCode: [null, null],
      StateCode: [null, null],
      DistrictCodes: [null, null],
      DistrictCode: [null, null],
      OfficeCodes: [null, null],
      OfficeCode: [null, null]
    });
  }

  getUserDetail() {
    if (this.recordId > 0) {
      this._userService.GetB2GUserById(this.recordId).subscribe(data => {
        if (data.IsSuccess) {
          this.model = <UserCreationModel>data.Data;
          this.manageFormFields();
        }
      }, error => {
        this._alertService.error(error.message);
      });
    }
  }

  manageFormFields() {
    let baseUserType = this.model.BaseUserType;
    let userTypeCategory = this.model.UserTypeCategory;

    //#region Manage Fields
    this.hasMultiRights = false;
    this.fieldConfig.IsDepartmentShow = false;
    this.fieldConfig.IsStateShow = false;
    this.fieldConfig.IsDistrictShow = false;
    this.fieldConfig.IsOfficeShow = false;

    const deptCodesControl = this.frmGrp.get("DepartmentCodes");
    const deptCodeControl = this.frmGrp.get("DepartmentCode");
    const stateCodeControl = this.frmGrp.get("StateCode");
    const distCodesControl = this.frmGrp.get("DistrictCodes");
    const distCodeControl = this.frmGrp.get("DistrictCode");
    const offCodesControl = this.frmGrp.get("OfficeCodes");
    const offCodeControl = this.frmGrp.get("OfficeCode");

    deptCodesControl.setValidators(null);
    deptCodeControl.setValidators(null);
    stateCodeControl.setValidators(null);
    distCodesControl.setValidators(null);
    distCodeControl.setValidators(null);
    offCodesControl.setValidators(null);
    offCodeControl.setValidators(null);

    if (baseUserType == UserTypeEnum.ADM || baseUserType == UserTypeEnum.SADM
      || baseUserType == UserTypeEnum.VNDR || baseUserType == UserTypeEnum.VNDRUSER) {
      this.canUpdateUser = false;
    }
    else if (baseUserType == UserTypeEnum.HOD) {
      this.hasMultiRights = true;
      this.fieldConfig.IsDepartmentShow = true;
      deptCodesControl.setValidators(Validators.required);
    }
    else if (baseUserType == UserTypeEnum.HOO) {
      this.hasMultiRights = true;

      this.fieldConfig.IsDepartmentShow = true;
      this.fieldConfig.IsStateShow = true;
      this.fieldConfig.IsDistrictShow = true;
      this.fieldConfig.IsOfficeShow = true;

      deptCodesControl.setValidators(Validators.required);
      stateCodeControl.setValidators(Validators.required);
      distCodesControl.setValidators(Validators.required);
      offCodesControl.setValidators(Validators.required);
    }
    else if (baseUserType == UserTypeEnum.DREC || baseUserType == UserTypeEnum.DOS) {
      this.fieldConfig.IsDepartmentShow = true;
      this.fieldConfig.IsStateShow = true;
      this.fieldConfig.IsDistrictShow = true;
      this.fieldConfig.IsOfficeShow = true;

      deptCodeControl.setValidators(Validators.required);
      stateCodeControl.setValidators(Validators.required);
      distCodeControl.setValidators(Validators.required);
      offCodeControl.setValidators(Validators.required);
    }
    else if (baseUserType == UserTypeEnum.HOOADLC) {
      this.hasMultiRights = true;

      this.fieldConfig.IsOfficeShow = true;
      offCodesControl.setValidators(Validators.required);

      switch (userTypeCategory) {
        case UserTypeEnum.HOD:
          this.fieldConfig.IsDepartmentShow = true;
          deptCodesControl.setValidators(Validators.required);
          break;
        case UserTypeEnum.DLO:
          this.fieldConfig.IsStateShow = true;
          stateCodeControl.setValidators(Validators.required);

          this.fieldConfig.IsDistrictShow = true;
          distCodesControl.setValidators(Validators.required);
          break;
      }
    }
    else if (baseUserType == UserTypeEnum.DRECADLC || baseUserType == UserTypeEnum.DOSADLC) {
      this.fieldConfig.IsOfficeShow = true;
      offCodeControl.setValidators(Validators.required);

      switch (userTypeCategory) {
        case UserTypeEnum.HOD:
          this.fieldConfig.IsDepartmentShow = true;
          deptCodeControl.setValidators(Validators.required);
          break;
        case UserTypeEnum.DLO:
          this.fieldConfig.IsStateShow = true;
          stateCodeControl.setValidators(Validators.required);

          this.fieldConfig.IsDistrictShow = true;
          distCodeControl.setValidators(Validators.required);
          break;
      }
    }

    deptCodesControl.updateValueAndValidity();
    deptCodeControl.updateValueAndValidity();
    stateCodeControl.updateValueAndValidity();
    distCodesControl.updateValueAndValidity();
    distCodeControl.updateValueAndValidity();
    offCodesControl.updateValueAndValidity();
    offCodeControl.updateValueAndValidity();
    //#endregion

    //#region Manage Update Model
    if (this.model.DepartmentCode) {
      let deptCodes = this.model.DepartmentCode.toString().split(',');
      if (deptCodes && deptCodes.length > 0) {
        this.updateModel.DepartmentCode = this.hasMultiRights ? undefined : deptCodes[0];
        this.updateModel.DepartmentCodes = this.hasMultiRights ? deptCodes : undefined;
      }
    }

    if (this.model.StateCodes) {
      let statCodes = this.model.StateCodes.toString().split(',');
      if (statCodes && statCodes.length > 0) {
        this.updateModel.StateCode = statCodes[0];
      }
    }

    if (this.model.DistrictCode) {
      let distCodes = this.model.DistrictCode.toString().split(',');
      if (distCodes && distCodes.length > 0) {
        this.updateModel.DistrictCode = this.hasMultiRights ? undefined : distCodes[0];
        this.updateModel.DistrictCodes = this.hasMultiRights ? distCodes : undefined;
      }
    }

    if (this.model.OfficeCode) {
      let offCodes = this.model.OfficeCode.toString().split(',');
      if (offCodes && offCodes.length > 0) {
        this.updateModel.OfficeCode = this.hasMultiRights ? undefined : offCodes[0];
        this.updateModel.OfficeCodes = this.hasMultiRights ? offCodes : undefined;
      }
    }
    //#endregion 

    if (this.canUpdateUser) {
      this.getDDLList();
      this.getDistricts();
      this.getOffices();
    }
  }

  get selDeptCodes(): string[] {
    if (this.hasMultiRights) {
      return (this.updateModel.DepartmentCodes && this.updateModel.DepartmentCodes.length > 0) ?
        this.updateModel.DepartmentCodes : undefined;
    }
    else {
      return (this.updateModel.DepartmentCode) ? this.updateModel.DepartmentCode.split(',') : undefined;
    }
  }

  get selDistCodes(): string[] {
    if (this.hasMultiRights) {
      return (this.updateModel.DistrictCodes && this.updateModel.DistrictCodes.length > 0) ?
        this.updateModel.DistrictCodes : undefined;
    }
    else {
      return (this.updateModel.DistrictCode) ? this.updateModel.DistrictCode.split(',') : undefined;
    }
  }

  get selOfficeCodes(): string[] {
    if (this.hasMultiRights) {
      return (this.updateModel.OfficeCodes && this.updateModel.OfficeCodes.length > 0) ?
        this.updateModel.OfficeCodes : undefined;
    }
    else {
      return (this.updateModel.OfficeCode) ? this.updateModel.OfficeCode.split(',') : undefined;
    }
  }

  getDDLList() {
    this._commonService.GetAllDDL(AppSetting.DDlKeyForUpdateUserOffice).subscribe((data) => {
      if (data.IsSuccess) {
        let ddlList = <DDLModel>data.Data;
        this.ddlList.ddlAllOfficeDepartment = ddlList.ddlAllOfficeDepartment;
        this.ddlList.ddlAllOfficeState = ddlList.ddlAllOfficeState;

        //#region Check/Bind Departments 
        if (this.selDeptCodes && this.selDeptCodes.length > 0) {
          let deptCodes = this.selDeptCodes.filter(
            item => this.ddlList.ddlAllOfficeDepartment.find(x => x.Value == item)
          );
          this.updateModel.DepartmentCodes = this.hasMultiRights ? deptCodes : undefined;
          this.updateModel.DepartmentCode = this.hasMultiRights ? undefined : deptCodes[0];
        }
        //#endregion

      }
    }, (error) => {
      this._alertService.error(error.message);
    });
  }

  getDistricts() {
    let stateCode = this.updateModel.StateCode ? this.updateModel.StateCode : 0;
    let deptCodes = (this.hasMultiRights) ?
      (this.updateModel.DepartmentCodes ? this.updateModel.DepartmentCodes.toString() : '') :
      (this.updateModel.DepartmentCode ? this.updateModel.DepartmentCode.toString() : '');

    this._commonService.GetDDL(AppSetting.ddlAllOfficeDistrict, stateCode, deptCodes).subscribe(data => {
      if (data.IsSuccess) {
        var ddl = <DDLModel>data.Data;
        this.ddlList.ddlAllOfficeDistrict = ddl.ddlAllOfficeDistrict;

        //#region Check/Bind Districts 
        if (this.selDistCodes && this.selDistCodes.length > 0) {
          let distCodes = this.selDistCodes.filter(
            item => this.ddlList.ddlAllOfficeDistrict.find(x => x.Value == item)
          );
          this.updateModel.DistrictCodes = this.hasMultiRights ? distCodes : undefined;
          this.updateModel.DistrictCode = this.hasMultiRights ? undefined : distCodes[0];
        }
        //#endregion

      }
    }, error => {
      this._alertService.error(error.message);
    });
  }

  getOffices() {
    let deptCodes = (this.hasMultiRights) ?
      (this.updateModel.DepartmentCodes ? this.updateModel.DepartmentCodes.toString() : '') :
      (this.updateModel.DepartmentCode ? this.updateModel.DepartmentCode.toString() : '');
    let distCodes = (this.hasMultiRights) ?
      (this.updateModel.DistrictCodes ? this.updateModel.DistrictCodes.toString() : '') :
      (this.updateModel.DistrictCode ? this.updateModel.DistrictCode.toString() : '');

    this._commonService.GetDDL(AppSetting.ddlAllOffice, deptCodes, distCodes).subscribe(data => {
      if (data.IsSuccess) {
        var ddl = <DDLModel>data.Data;
        this.ddlList.ddlAllOffice = ddl.ddlAllOffice;

        //#region Check/Bind Offices 
        if (this.selOfficeCodes && this.selOfficeCodes.length > 0) {
          let offCodes = this.selOfficeCodes.filter(
            item => this.ddlList.ddlAllOffice.find(x => x.Value == item)
          );
          this.updateModel.OfficeCodes = this.hasMultiRights ? offCodes : undefined;
          this.updateModel.OfficeCode = this.hasMultiRights ? undefined : offCodes[0];
        }
        //#endregion

      }
    }, error => {
      this._alertService.error(error.message);
    });
  }

  selectAllDepartment() {
    if (this.updateModel.DepartmentCodes.length != this.ddlList.ddlAllOfficeDepartment.length) {
      this.updateModel.DepartmentCodes = this.ddlList.ddlAllOfficeDepartment.map(function (a) {
        return a.Value;
      });
    } else {
      this.updateModel.DepartmentCodes = [];
    }
  }

  selectAllDistrict() {
    if (this.updateModel.DistrictCodes.length != this.ddlList.ddlAllOfficeDistrict.length) {
      this.updateModel.DistrictCodes = this.ddlList.ddlAllOfficeDistrict.map(function (a) {
        return a.Value;
      });
    } else {
      this.updateModel.DistrictCodes = [];
    }
  }

  selectAllOffice() {
    if (this.updateModel.OfficeCodes.length != this.ddlList.ddlAllOffice.length) {
      this.updateModel.OfficeCodes = this.ddlList.ddlAllOffice.map(function (a) {
        return a.Value;
      });
    } else {
      this.updateModel.OfficeCodes = [];
    }
  }

  onSubmit() {
    this.frmGrp.markAllAsTouched();
    if (this.frmGrp.valid) {
      if (this.hasMultiRights) {
        this.model.DepartmentCode = this.updateModel.DepartmentCodes;
        this.model.DistrictCode = this.updateModel.DistrictCodes;
        this.model.OfficeCode = this.updateModel.OfficeCodes;

        this.openSetPrimaryDetailPopup();
      }
      else {
        this.model.DepartmentCode = this.updateModel.DepartmentCode ? this.updateModel.DepartmentCode.toString().split(',') : undefined;
        this.model.DistrictCode = this.updateModel.DistrictCode ? this.updateModel.DistrictCode.toString().split(',') : undefined;
        this.model.OfficeCode = this.updateModel.OfficeCode ? this.updateModel.OfficeCode.toString().split(',') : undefined;

        this.model.PrimaryDepartmentCode = this.model.DepartmentCode ? String(this.model.DepartmentCode) : undefined;
        this.model.PrimaryDistrictCode = this.model.DistrictCode ? String(this.model.DistrictCode) : undefined;
        this.model.PrimaryOfficeCode = this.model.OfficeCode ? String(this.model.OfficeCode) : undefined;

        this.saveUser();
      }
    }
  }

  saveUser() {
    this._userService.AddUpdateUserCreation(this.model).subscribe((data) => {
      if (data && data.IsSuccess) {
        this._alertService.success(GlobalMessagesModel.updateSuccess);
        this.closeDialog(true);
      }
      else {
        this._alertService.error(GlobalMessagesModel.updateError);
      }
    }, (error) => {
      this._alertService.error(error.message);
    });
  }

  isRequiredField(field: string) {
    const form_field = this.frmGrp.get(field);
    if (!form_field.validator) {
      return false;
    }
    const validator = form_field.validator({} as AbstractControl);
    return (validator && validator.required);
  }

  openSetPrimaryDetailPopup() {
    const _dialogRef = this._dialog.open(SetUserPrimaryDetailDialogComponent, {
      width: "500px",
      height: "320px",
      data: { model: this.model, fieldConfig: this.fieldConfig },
      disableClose: true
    });
    _dialogRef.afterClosed().subscribe((res: any) => {
      if (res) {
        this.model.PrimaryDepartmentCode = res.PrimaryDepartmentCode;
        this.model.PrimaryDistrictCode = res.PrimaryDistrictCode;
        this.model.PrimaryOfficeCode = res.PrimaryOfficeCode;
        this.saveUser();
      }
    });
  }

  closeDialog(val = false): void {
    this._dialogRef.close(val);
  }

}
