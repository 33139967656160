import { Injectable } from '@angular/core';
import { AppSetting } from '../model/appsetting.model';
import { IndexModel } from '../model/common.model';
import { GeneralEntryPostModel,CustomSearchModel, GeneralEntrySearchModel } from '../model/general-entry-model.model';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root'
})
export class GeneralEntryService {

  constructor(private readonly _baseService: BaseService) {}
  GetList(model: IndexModel) {
    return this._baseService.post(AppSetting.GeneralEntryListUrl, model);
  }

  GetFilterList(model: CustomSearchModel, UserId: number) {
    return this._baseService.post(
      AppSetting.GeneralEntryFilterListUrl + "?UserCode=" + UserId,
      model
    );
  }

  Add(model: GeneralEntryPostModel) {
    return this._baseService.post(AppSetting.GeneralEntryAddUrl, model);
  }

  Detail(id: number) {
    return this._baseService.get(AppSetting.GeneralEntryDetailUrl + id);
  }

  Edit(id: number, model: GeneralEntryPostModel) {
    return this._baseService.post(AppSetting.GeneralEntryUpdateUrl + id, model);
  }

  ChangeDeleteStatus(id: number) {
    return this._baseService.get(
      AppSetting.GeneralEntryUpdateDeleteStatusUrl + id
    );
  }
  ChangeActiveStatus(id: number) {
    return this._baseService.get(
      AppSetting.GeneralEntryUpdateActiveStatusUrl + id
    );
  }

  GetGeneralEntrySearhList(model: GeneralEntrySearchModel, isBase64File: boolean=true) {
    return this._baseService.post(
      AppSetting.GeneralEntrySearhListUrl + "?isBase64File=" + isBase64File,
      model
    );
  }

}
