import { Injectable } from "@angular/core";
import { AppSetting } from "../../model/appsetting.model";
import { IndexModel } from "../../model/common.model";
import { Dictionary } from "../../model/dictionary.model";
import { NotificationMappingModel } from "../../model/master/notification-template-type.model";
import { NotificationSMSTemplatesModel } from "../../model/master/sms-template.model";
import { BaseService } from "../base.service";

@Injectable({
    providedIn: 'root'
})

export class SMSTemplateService {
    constructor(
        private readonly _baseService: BaseService,
    ) {
    }
    GetList(model: IndexModel) {
        return this._baseService.post(AppSetting.SMSTemplateListUrl, model);
    }

    AddNew(model: NotificationSMSTemplatesModel) {
        return this._baseService.post(AppSetting.SMSTemplateAddUrl, model);
    }

    Update(model: NotificationSMSTemplatesModel) {
        return this._baseService.post(AppSetting.SMSTemplateUpdateUrl, model);

    }

    GetById(id) {
        return this._baseService.get(AppSetting.SMSTemplateGetByIdUrl + id, null);
    }

    Delete(Id) {
        return this._baseService.get(AppSetting.SMSTemplateDeleteUrl + Id, null);
    }


    IsExistSMSTemplate(value: string, id: number) {
        const param = new Dictionary<any>();
        param.Add("value", value);
        param.Add("id", id);

        return this._baseService.get(AppSetting.IsExistSMSTemplateUrl, param);
    }

    ChangeActiveStatus(id: number) {
        return this._baseService.get(AppSetting.SMSTemplateActiveStatusChangeUrl + id);
    }


    MapSMSTemplateWithTransaction(model: NotificationMappingModel) {
        return this._baseService.post(AppSetting.MapSMSTemplateWithTransactionUrl, model);
    }

    GetMapedSMSTemplateById(id: number) {
        const param = new Dictionary<any>();
      
        param.Add("id", id);
        return this._baseService.get(AppSetting.GetMapSMSTemplateByIdUrl, param);
    }

    GetAllMapSMSTemplateListUrl(model: IndexModel) {
        return this._baseService.post(AppSetting.GetAllMapSMSTemplateListUrl, model);
    }

    ChangeMapedSMSTemplateActiveStatus(id: number) {
        return this._baseService.get(AppSetting.ChangeMapedSMSTemplateActiveStatusUrl + id);
    }

}
