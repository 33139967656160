import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SideNavBarComponent } from './side-nav-bar/side-nav-bar.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { LoaderComponent } from './loader/loader.component';
import { AlertComponent } from './alert/alert.component';
import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component';
import { GlobalListSearchComponent } from './global-list-search/global-list-search.component';
import { MenuListItemComponent } from './menu-list-item/menu-list-item.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppMaterialModule } from './shared/app-material/app-material.module';
import { CommonModule, LocationStrategy, HashLocationStrategy, TitleCasePipe, PathLocationStrategy } from '@angular/common';
import { BaseService } from './shared/service/base.service';
import { AuthGuardService } from './helper/auth-guard.service';
import { AlertService } from './shared/service/alert.service';
import { LoaderService } from './shared/service/loader.service';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { LoaderInterceptor } from './helper/loading.interceptor';
import { DashboardComponent } from './content/dashboard/dashboard.component';
import { VendorTypeComponent } from './content/master/vendor-type/vendor-type.component';
import { AddUpdateVendorTypeComponent } from './content/master/vendor-type/add-update-vendor-type/add-update-vendor-type.component';
import { VendorTypeDetailComponent } from './content/master/vendor-type/vendor-type-detail/vendor-type-detail.component';
import { CommunicationTypeComponent } from './content/master/communication-type/communication-type.component';
import { AddUpdateCommunicationTypeComponent } from './content/master/communication-type/add-update-communication-type/add-update-communication-type.component';
import { CommunicationTypeDetailComponent } from './content/master/communication-type/communication-type-detail/communication-type-detail.component';
import { CommunicationAttachmentTypeComponent } from './content/master/communication-attachment-type/communication-attachment-type.component';
import { AddUpdateCommunicationAttachmentTypeComponent } from './content/master/communication-attachment-type/add-update-communication-attachment-type/add-update-communication-attachment-type.component';
import { CommunicationAttachmentTypeDetailComponent } from './content/master/communication-attachment-type/communication-attachment-type-detail/communication-attachment-type-detail.component';
import { VendorComponent } from './content/vendor/vendor.component';
import { VendorRegistrationDialogComponent } from './content/vendor/vendor-registration-dialog/vendor-registration-dialog.component';
import { VendorDetailComponent } from './content/vendor/vendor-detail/vendor-detail.component';
import { BankAccountComponent } from './content/vendor/bank-account/bank-account.component';
import { BankAccountDetailDialogComponent } from './content/vendor/bank-account-detail-dialog/bank-account-detail-dialog.component';
import { AddUpdateBankAccountDialogComponent } from './content/vendor/add-update-bank-account-dialog/add-update-bank-account-dialog.component';
import { WorkOrderComponent } from './content/work-order/work-order.component';
import { AddUpdateWorkOrderComponent } from './content/work-order/add-update-work-order/add-update-work-order.component';
import { WorkOrderDetailComponent } from './content/work-order/work-order-detail/work-order-detail.component';
import { CommunicationComponent } from './content/communication/communication.component';
import { CommunicationDetailComponent } from './content/communication/communication-detail/communication-detail.component';
import { ChangeCommunicationOfficeDialogComponent } from './content/communication/change-communication-office-dialog/change-communication-office-dialog.component';
import { UserComponent } from './content/user/user.component';
import { AddUpdateUserDialogComponent } from './content/user/add-update-user-dialog/add-update-user-dialog.component';
import { UserDetailDialogComponent } from './content/user/user-detail-dialog/user-detail-dialog.component';
import { ActionDialogComponent } from './content/communication/action-dialog/action-dialog.component';
import { ActionHistoryComponent } from './content/communication/action-history/action-history.component';
//import { SuggestionComponent } from './content/communication/suggestion/suggestion.component';
//import { AddUpdateSuggestionComponent } from './content/communication/suggestion/add-update-suggestion/add-update-suggestion.component';
//import { SuggestionDetailComponent } from './content/communication/suggestion/suggestion-detail/suggestion-detail.component';
//import { ComplaintComponent } from './content/communication/complaint/complaint.component';
//import { AddUpdateComplaintComponent as oldAddUpdateComplaintComponent } from './content/communication/complaint/add-update-complaint/add-update-complaint.component';
//import { ComplaintDetailComponent } from './content/communication/complaint/complaint-detail/complaint-detail.component';
//import { InvoiceComponent } from './content/communication/invoice/invoice.component';
//import { AddUpdateInvoiceComponent } from './content/communication/invoice/add-update-invoice/add-update-invoice.component';
//import { InvoiceDetailComponent } from './content/communication/invoice/invoice-detail/invoice-detail.component';
//import { EmdReturnComponent } from './content/communication/emd-return/emd-return.component';
//import { AddUpdateEmdReturnComponent } from './content/communication/emd-return/add-update-emd-return/add-update-emd-return.component';
//import { EmdReturnDetailComponent } from './content/communication/emd-return/emd-return-detail/emd-return-detail.component';
//import { GeneralComponent } from './content/communication/general/general.component';
//import { AddUpdateGeneralComponent } from './content/communication/general/add-update-general/add-update-general.component';
//import { GeneralDetailComponent } from './content/communication/general/general-detail/general-detail.component';
import { LoginComponent } from './login/login.component';
import { ProjectComponent } from './content/project/project.component';
import { AddUpdateProjectComponent } from './content/project/add-update-project/add-update-project.component';
import { ProjectDetailComponent } from './content/project/project-detail/project-detail.component';
import { InboxComponent } from './content/inbox/inbox.component';
import { OutboxComponent } from './content/outbox/outbox.component';
import { OutboxDetailComponent } from './content/outbox/outbox-detail/outbox-detail.component';
import { from } from 'rxjs';
import { InboxDetailComponent } from './content/inbox/inbox-detail/inbox-detail.component';
import { DatePipe } from '@angular/common';
import { ReasonTypeComponent } from './content/master/reason-type/reason-type.component';
import { AddUpdateReasonTypeComponent } from './content/master/reason-type/add-update-reason-type/add-update-reason-type.component';
import { ReasonTypeDetailComponent } from './content/master/reason-type/reason-type-detail/reason-type-detail.component';
import { UpdateVendorProfileComponent } from './content/vendor/update-vendor-profile/update-vendor-profile.component';
import { VendorUserComponent } from './content/vendor-user/vendor-user.component';
import { AddUpdateVendorUserDialogComponent } from './content/vendor-user/add-update-vendor-user-dialog/add-update-vendor-user-dialog.component';
import { VendorUserDetailDialogComponent } from './content/vendor-user/vendor-user-detail-dialog/vendor-user-detail-dialog.component';
import { ComplaintTypeComponent } from './content/master/complaint-type/complaint-type.component';
import { AddUpdateComplaintTypeComponent } from './content/master/complaint-type/add-update-complaint-type/add-update-complaint-type.component';
import { ComplaintTypeDetailComponent } from './content/master/complaint-type/complaint-type-detail/complaint-type-detail.component';
import { RefundRequestReasonComponent } from './content/master/refund-request-reason/refund-request-reason.component';
import { AddUpdateRefundRequestReasonComponent } from './content/master/refund-request-reason/add-update-refund-request-reason/add-update-refund-request-reason.component';
import { RefundRequestReasonDetailComponent } from './content/master/refund-request-reason/refund-request-reason-detail/refund-request-reason-detail.component';
import { ConcernComponent } from './content/master/concern/concern.component';
import { ConcernDetailComponent } from './content/master/concern/concern-detail/concern-detail.component';
import { AddUpdateConcernComponent } from './content/master/concern/add-update-concern/add-update-concern.component';
import { SuggestionForComponent } from './content/master/suggestion-for/suggestion-for.component';
import { AddUpdateSuggestionForComponent } from './content/master/suggestion-for/add-update-suggestion-for/add-update-suggestion-for.component';
import { SuggestionForDetailComponent } from './content/master/suggestion-for/suggestion-for-detail/suggestion-for-detail.component';
import { DepartmentReportComponent } from './reports/master/department-report/department-report.component';
import { VendorReportComponent } from './reports/master/vendor-report/vendor-report.component';
import { CommunicationTypeReportComponent } from './reports/master/communication-type-report/communication-type-report.component';
import { DepartmentOfficeReportComponent } from './reports/master/department-office-report/department-office-report.component';
import { ReasonTypeReportComponent } from './reports/master/reason-type-report/reason-type-report.component';
import { AttachmentTypeReportComponent } from './reports/master/attachment-type-report/attachment-type-report.component';
import { ComplaintTypeReportComponent } from './reports/master/complaint-type-report/complaint-type-report.component';
import { RefundRequestReasonReportComponent } from './reports/master/refund-request-reason-report/refund-request-reason-report.component';
import { ConcernReportComponent } from './reports/master/concern-report/concern-report.component';

import { ComplaintReportComponent } from './reports/communication/complaint-report/complaint-report.component';
import { EmdReturnReportComponent } from './reports/communication/emd-return-report/emd-return-report.component';
import { GeneralReportComponent } from './reports/communication/general-report/general-report.component';
import { InvoiceReportComponent } from './reports/communication/invoice-report/invoice-report.component';

//Rewant
import { ProjectReportComponent } from './reports/master/project-report/project-report.component';
import { WorkOrderReportComponent } from './reports/master/work-order-report/work-order-report.component';
import { VendorListReportComponent } from './reports/master/vendor-list-report/vendor-list-report.component';
import { SuggestionsReportComponent } from './reports/communication/suggestions-report/suggestions-report.component';
import { AgeWiseCommunicationComponent } from './content/report/age-wise-communication/age-wise-communication.component';
import { VendorUserReportComponent } from './content/report/vendor-user-report/vendor-user-report.component';
import { NewOldCommunicationReportComponent } from './content/report/new-old-communication-report/new-old-communication-report.component';
import { TemporaryLoginComponent } from './temporary-login/temporary-login.component';
import { UserManualComponent } from './content/master/user-manual/user-manual.component';
import { OfficeStaffReportComponent } from './reports/master/office-staff-report/office-staff-report.component';
import { ProjectMappingComponent } from './content/project-mapping/project-mapping.component';
import { AddUpdateProjectMappingDialogComponent } from './content/project-mapping/add-update-project-mapping-dialog/add-update-project-mapping-dialog.component';
import { ProjectMappingDetailDialogComponent } from './content/project-mapping/project-mapping-detail-dialog/project-mapping-detail-dialog.component';
import { LoginUserCreationComponent } from './content/login-user-creation/login-user-creation.component';
import { AddUpdateloginuserCreationComponent } from './content/login-user-creation/add-updateloginuser-creation/add-updateloginuser-creation.component';
import { VendorActionDialogComponent } from './content/vendor/vendor-action-dialog/vendor-action-dialog.component';
import { OfficeInternalTransferActionDialogComponent } from './content/communication/office-internal-transfer-action-dialog/office-internal-transfer-action-dialog.component';
import { MasterDataComponent } from './content/master/master-data/master-data.component';
import { AddUpdateMasterDataComponent } from './content/master/master-data/add-update-master-data/add-update-master-data.component';
import { MasterDataDetailComponent } from './content/master/master-data/master-data-detail/master-data-detail/master-data-detail.component';
import { MapFieldsComponent } from './content/master/map-fields/map-fields/map-fields.component';
import { CommunicationFieldsComponent } from './content/master/communication-fields/communication-fields/communication-fields.component';
import { AddCommunicationTypeFieldsComponent } from './content/master/communication-fields/add-communication-type-fields/add-communication-type-fields.component';
import { AddUpdateCommunicationTransactionComponent } from './content/communication/add-update-communication-transaction/add-update-communication-transaction.component';
import { ButtonComponent } from './DynamicInputField/button/button.component';
import { CheckboxComponent } from './DynamicInputField/checkbox/checkbox.component';
import { DateComponent } from './DynamicInputField/date/date.component';
import { DynamicFieldDirective } from './DynamicInputField/dynamic-field/dynamic-field.directive';
import { DynamicFormComponent } from './DynamicInputField/dynamic-form/dynamic-form.component';
import { InputComponent } from './DynamicInputField/input/input.component';
import { RadiobuttonComponent } from './DynamicInputField/radiobutton/radiobutton.component';
import { SelectComponent } from './DynamicInputField/select/select.component';
import { AssignDefaultFieldComponent } from './content/master/communication-fields/assign-default-field/assign-default-field.component';
import { CommunicationTransactionDetailComponent } from './content/communication/communication-transaction-detail/communication-transaction-detail.component';

import { AdminDepartmentComponent } from './content/master/admin-department/admin-department.component';
import { AddUpdateAdminDepartmentComponent } from './content/master/admin-department/add-update-admin-department/add-update-admin-department.component';
import { DesignationMasterComponent } from './content/master/designation-master/designation-master.component';
import { AddUpdateDesignationMasterComponent } from './content/master/designation-master/add-update-designation-master/add-update-designation-master.component';
import { UserCreationComponent } from './content/user-creation/user-creation.component';
import { AddUpdateUserComponent } from './content/user-creation/add-update-user/add-update-user.component';

//import { SuggestionReportComponent } from './reports/communication/suggestion-report/suggestion-report.component';
import { OfficeMasterComponent } from './content/master/office-master/office-master.component';
import { AddUpdateOfficeMasterComponent } from './content/master/office-master/add-update-office-master/add-update-office-master.component';

import { DetailOfficeMasterComponent } from './content/master/office-master/detail-office-master/detail-office-master.component';
import { FilterComponent } from './content/dashboard/filter/filter.component';
import { DepartmentMasterNewComponent } from './content/master/department-master-new/department-master-new.component';
import { AddUpdateDepartmentMasterNewComponent } from './content/master/department-master-new/add-update-department-master-new/add-update-department-master-new.component';
import { DetailDepartmentMasterNewComponent } from './content/master/department-master-new/detail-department-master-new/detail-department-master-new.component';

import { AddUpdateDepartmentContactDetailsComponent } from './content/master/department-contact-details-new/add-update-department-contact-details/add-update-department-contact-details.component';
import { UserTypeMasterComponent } from './content/master/user-type-master/user-type-master.component';
import { AddUpdateUserTypeMasterComponent } from './content/master/user-type-master/add-update-user-type-master/add-update-user-type-master.component';
import { DetailDepartmentContactDetailsComponent } from './content/master/department-contact-details-new/detail-department-contact-details/detail-department-contact-details.component';
import { DepartmentContactDetailsNewComponent } from './content/master/department-contact-details-new/department-contact-details-new.component';
import { SuggestionReportComponent as masterSuggestionReportComponent } from './reports/master/suggestion-report/suggestion-report.component';
import { SuggestionReportComponent as comSuggestionReportComponent } from './reports/communication/suggestion-report/suggestion-report.component';
import { AddUpdateEmailComponent } from './content/master/notification-email-templates/add-update-email/add-update-email.component';
import { NotificationEmailTemplatesComponent } from './content/master/notification-email-templates/notification-email-templates.component';
import { AddUpdateSmsComponent } from './content/master/notification-sms-templates/add-update-sms/add-update-sms.component';
import { NotificationSmsTemplatesComponent } from './content/master/notification-sms-templates/notification-sms-templates.component';
import { AddEditDialogComponent } from './content/master/notification-template-type/add-edit-dialog/add-edit-dialog.component';
import { NotificationTemplateTypeComponent } from './content/master/notification-template-type/notification-template-type/notification-template-type.component';
import { UserNotificationComponent } from './content/user/user-notification/user-notification.component';
import { PermissionMasterComponent } from './content/master/permission-master/permission-master.component';
import { AddUpdatePermissionMasterComponent } from './content/master/permission-master/add-update-permission-master/add-update-permission-master.component';
import { AddUpdateMenuMasterComponent } from './content/master/permission-master/add-update-menu-master/add-update-menu-master.component';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { SpecificUserPermissionComponent } from './content/master/permission/specific-user-permission/specific-user-permission.component';
import { UserDefaultPermissionComponent } from './content/master/permission/user-default-permission/user-default-permission.component';
import { ComplaintSoftwareComponent } from './content/complaint-software/complaint-software.component';

import { AddUpdateComplaintComponent } from './content/complaint-software/add-update-complaint/add-update-complaint.component';
import { CompliantActionDialogComponent } from './content/complaint-software/compliant-action-dialog/compliant-action-dialog.component';
import { ImportExcelDataComponent } from './content/import-excel-data/import-excel-data.component';
import { MasterTypeDataComponent } from './content/master/master-type-data/master-type-data.component';
import { AddUpdateMasterTypeDataComponent } from './content/master/master-type-data/add-update-master-type-data/add-update-master-type-data.component';
import { ProjectCategoryComponent } from './content/master/project-category/project-category.component';
import { AddUpdateProjectCategoryComponent } from './content/master/project-category/add-update-project-category/add-update-project-category.component';
import { ProjectCategoryDetailComponent } from './content/master/project-category/project-category-detail/project-category-detail.component';
import { MasterReportsComponent } from './reports/masterreport/master-reports.component';
import { MatDialogRef, MatSnackBarModule, MAT_DATE_LOCALE, MAT_DIALOG_DATA } from '@angular/material';
import { ProjectSubCategoryComponent } from './content/master/project-sub-category/project-sub-category.component';
import { AddUpdateProjectSubCategoryComponent } from './content/master/project-sub-category/add-update-project-sub-category/add-update-project-sub-category.component';
import { ProjectSubCategoryDetailComponent } from './content/master/project-sub-category/project-sub-category-detail/project-sub-category-detail.component';
import { LocateCommunicationComponent } from './content/communication/locate-communication/locate-communication.component';
import { WorkOrderCategoryComponent } from './content/master/work-order-category/work-order-category.component';
import { AddUpdateWorkOrderCategoryComponent } from './content/master/work-order-category/add-update-work-order-category/add-update-work-order-category.component';
import { WorkOrderCategoryDetailComponent } from './content/master/work-order-category/work-order-category-detail/work-order-category-detail.component';
import { WorkOrderSubCategoryComponent } from './content/master/work-order-sub-category/work-order-sub-category.component';
import { AddUpdateWorkOrderSubCategoryComponent } from './content/master/work-order-sub-category/add-update-work-order-sub-category/add-update-work-order-sub-category.component';
import { WorkOrderSubCategoryDetailComponent } from './content/master/work-order-sub-category/work-order-sub-category-detail/work-order-sub-category-detail.component';
import { AddUpdateChildUserTypeComponent } from './content/master/user-type-master/add-update-child-user-type/add-update-child-user-type.component';
import { UserReportComponent } from './reports/user/user-report.component';
import { PageAuthenticationDialogComponent } from './content/master/permission-master/page-authentication-dialog/page-authentication-dialog.component';
import { CommunicationReOpenDialogComponent } from './content/communication/communication-re-open-dialog/communication-re-open-dialog.component';
import { AdditionalPermissionComponent } from './content/master/permission/additional-permission/addtional-permission.component';
import { AddUpdateAdditionalPermissionComponent } from './content/master/permission/additional-permission/add-update-additional-permission/add-update-additional-permission.component';
import { AdditionalPermissionStatusDialogComponent } from './content/master/permission/additional-permission/additional-permission-status-dialog/additional-permission-status-dialog.component';
import { ResponseBackDialogComponent } from './content/communication/response-back-dialog/response-back-dialog.component';
import { DetailCommunicationTypeFieldDialogComponent } from './content/master/communication-fields/detail-communication-type-field-dialog/detail-communication-type-field-dialog.component';
import { SetPagePasswordDialogComponent } from './content/master/permission-master/set-page-password-dialog/set-page-password-dialog.component';
import { SpecialCharacterDirective } from './helper/special-character.directive';
import { LocateCommunicationDetailComponent } from './content/communication/locate-communication/locate-communication-detail/locate-communication-detail.component';
import { GlobalCommunicationSearchComponent } from './global-communication-search/global-communication-search.component';
import { GeneralEntryCategoryMasterComponent } from './content/master/GeneralEntryMaster/general-entry-category-master/general-entry-category-master.component';
import { AddUpdateGeneralEntryCategoryMasterComponent } from './content/master/GeneralEntryMaster/general-entry-category-master/add-update-general-entry-category-master/add-update-general-entry-category-master.component';
import { GeneralEntrySubCategoryMasterComponent } from './content/master/GeneralEntryMaster/general-entry-sub-category-master/general-entry-sub-category-master.component';
import { AddUpdateGeneralEntrySubCategoryMasterComponent } from './content/master/GeneralEntryMaster/general-entry-sub-category-master/add-update-general-entry-sub-category-master/add-update-general-entry-sub-category-master.component';
import { GeneralEntryComponent } from './content/general-entry/general-entry.component';
import { AddUpdateGeneralEntryComponent } from './content/general-entry/add-update-general-entry/add-update-general-entry.component';
import { DetailGeneralEntryComponent } from './content/general-entry/detail-general-entry/detail-general-entry.component';
import { B2GUserManualComponent } from './content/master/b2-guser-manual/b2-guser-manual.component';
import { AddUpdateB2GUserManualComponent } from './content/master/b2-guser-manual/add-update-b2-guser-manual/add-update-b2-guser-manual.component';
import { UserManualDialogComponent } from './header/user-manual-dialog/user-manual-dialog.component';
import { NotificationComponent } from './content/notification/notification.component';
import { DashboardCommunicationListComponent } from './content/dashboard/dashboard-communication-list/dashboard-communication-list.component';
import { TimeAgoPipe } from './helper/time-ago.pipe';
import { PrintHelperComponent } from './helper/print-helper/print-helper.component';
import { UpdateProfileComponent } from './content/user-creation/update-profile/update-profile.component';
import { TransferUserComponent } from './content/user-creation/transfer-user/transfer-user.component';
import { SearchCommunicationDialogComponent } from './content/communication/search-communication-dialog/search-communication-dialog.component';
import { SMSTemplateMappingComponent } from './content/master/notification-sms-templates/smstemplate-mapping/smstemplate-mapping.component';
import { AddUpdateSMSTemplateMappingDialogComponent } from './content/master/notification-sms-templates/smstemplate-mapping/add-update-smstemplate-mapping-dialog/add-update-smstemplate-mapping-dialog.component'
import { SmsTemplateDialogComponent } from './reports/user/sms-template-dialog/sms-template-dialog.component';
import { ReportSearchCriteriaDialogComponent } from './content/report/report-search-criteria-dialog/report-search-criteria-dialog.component';
import { StatisticalReportsComponent } from './content/report/statistical-reports/statistical-reports.component';
import { AdminContactDialogComponent } from './content/admin-contact-dialog/admin-contact-dialog.component';
import { PagePermissionListDialogComponent } from './content/master/permission/page-permission-list-dialog/page-permission-list-dialog.component';
import { DefaultPermissionDialogComponent } from './content/master/permission/default-permission-dialog/default-permission-dialog.component';
import { AddUpdateStatusMasterComponent } from './content/master/status-master/add-update-status-master/add-update-status-master.component';
import { DetailStatusMasterComponent } from './content/master/status-master/detail-status-master/detail-status-master.component';
import { StatusMasterComponent } from './content/master/status-master/status-master.component';


import { UserSpecificPermissionDialogComponent } from './content/master/permission/user-specific-permission-dialog/user-specific-permission-dialog.component'
import { ComplaintSoftwareDetailComponent } from './content/complaint-software/complaint-software-detail/complaint-software-detail.component';
import { UserActivityLogComponent } from './content/master/user-activity-log/user-activity-log.component'
import { AgGridModule } from 'ag-grid-angular';
import { DashboardHelpDocumentComponent } from './content/dashboard/dashboard-help-document/dashboard-help-document.component';
import { ProjectGroupComponent } from './content/project/project-group/project-group.component';
import { AddUpdateProjectGroupComponent } from './content/project/project-group/add-update-project-group/add-update-project-group.component';
import { ProjectGroupDetailComponent } from './content/project/project-group/project-group-detail/project-group-detail.component';
import { LogReportComponent } from './reports/log-report/log-report.component';
import { SmsSentReportComponent } from './reports/sms-sent-report/sms-sent-report.component';
import { LookupReportComponent } from './reports/lookup-report/lookup-report.component'
import { PreferenceListComponent } from './content/B2G/preference-list/preference-list.component';
import { AddUpdatePreferenceComponent } from './content/B2G/preference-list/add-update-preference/add-update-preference.component';
import { ChangeOfficeDialogComponent } from './header/change-office-dialog/change-office-dialog.component';
import { PreviousCommunicationListComponent } from './content/communication/previous-communication-list/previous-communication-list.component';
import { AddCCForCommunicationComponent } from './content/communication/add-update-communication-transaction/add-ccfor-communication/add-ccfor-communication.component';
import { UpdateVendorUserProfileComponent } from './content/vendor-user/update-vendor-user-profile/update-vendor-user-profile.component';
import { DashboardDrillDownComponent } from './content/dashboard/dashboard-drill-down/dashboard-drill-down.component';
import { EntryTypeComponent } from './content/complaint-software/Masters/entry-type/entry-type.component';
import { StatusComponent } from './content/complaint-software/Masters/status/status.component';
import { AddUpdateEntryTypeComponent } from './content/complaint-software/Masters/entry-type/add-update-entry-type/add-update-entry-type.component';
import { AddUpdateStatusComponent } from './content/complaint-software/Masters/status/add-update-status/add-update-status.component';
import { DistrictComponent } from './content/master/district/district.component';
import { AddUpdateDistrictComponent } from './content/master/district/add-update-district/add-update-district.component';
import { DistrictDetailComponent } from './content/master/district/district-detail/district-detail.component';
import { GeneralHelpDialogComponent } from './header/general-help-dialog/general-help-dialog.component';
import { SetUserPrimaryDetailDialogComponent } from './content/user-creation/set-user-primary-detail-dialog/set-user-primary-detail-dialog.component'
import { FieldDetailSectionComponent } from './content/communication/field-detail-section/field-detail-section.component';
import { CommunicationDefaultFieldConfigComponent } from './content/master/communication-fields/communication-default-field-config/communication-default-field-config.component';
import { UpdateUserOfficeDialogComponent } from './content/user-creation/update-user-office-dialog/update-user-office-dialog.component';
import { LocateUserComponent } from './content/user-creation/locate-user/locate-user.component';
import { SelfRegisterDialogComponent } from './content/user-creation/self-register-dialog/self-register-dialog.component';
import { ContactUsComponent } from './content/contactus/contact-us/contact-us.component';
import { AddUpdateAdditionalUserTypeDialogComponent } from './content/user-creation/add-update-additional-user-type-dialog/add-update-additional-user-type-dialog.component';
import { ChangeUserTypeDialogComponent } from './header/change-user-type-dialog/change-user-type-dialog.component';
import { UpdateVendorProfileDialogComponent } from './content/vendor/update-vendor-profile-dialog/update-vendor-profile-dialog.component';
import { ApplicationDataUtilityComponent } from './content/Utility/application-data-utility/application-data-utility.component';
import { PreferredDataSelecterComponent } from './shared/component/preferred-data-selecter/preferred-data-selecter.component';
import { PreferredDataDialogComponent } from './shared/component/preferred-data-selecter/preferred-data-dialog/preferred-data-dialog.component';
import { StatisticalReportDrillDownComponent } from './content/report/statistical-reports/statistical-report-drill-down/statistical-report-drill-down.component';
import { TextInfoComponent } from './DynamicInputField/text-info/text-info.component';
import { VendorCommunicationPermissionComponent } from './content/master/vendor-communication-permission/vendor-communication-permission.component';
import { CustomFilterSelectorComponent } from './shared/component/custom-filter-selector/custom-filter-selector.component';
import { CustomFilterDialogComponent } from './shared/component/custom-filter-selector/custom-filter-dialog/custom-filter-dialog.component';
import { VendorPermissionDelegationComponent } from './content/communication/vendor-permission-delegation/vendor-permission-delegation.component';
import { ClearProjectPopupComponent } from './content/project/clear-project-popup/clear-project-popup.component';
import { ClearWorkOrderPopupComponent } from './content/work-order/clear-work-order-popup/clear-work-order-popup.component';
import { ClearCommunicationPopUpComponent } from './content/communication/clear-communication-pop-up/clear-communication-pop-up.component';
import { ChiefMinisterProfileComponent } from './content/master/chiefminister-profile/chiefminister-profile.component';
import { AddUpdateChiefMinisterProfileComponent } from './content/master/chiefminister-profile/add-update-chiefminister-profile/add-update-chiefminister-profile.component';
import { PreferredListComponent } from './content/preferred-list/preferred-list.component';
import { AddUpdatePreferredComponent } from './content/preferred-list/add-update-preferred/add-update-preferred.component';
import { OfficePreferredListComponent } from './content/preferred-list/office-preferred-list/office-preferred-list.component';
import { VendorPreferredListComponent } from './content/preferred-list/vendor-preferred-list/vendor-preferred-list.component';
import { OfficeListComponent } from './content/preferred-list/office-list/office-list.component';
import { SecondaryDashboardComponent } from './secondary-dashboard/secondary-dashboard.component';
import { OfficerListComponent } from './content/preferred-list/officer-list/officer-list.component';
import { VendorListComponent } from './content/preferred-list/vendor-list/vendor-list.component';
import { OfficerPreferredListComponent } from './content/preferred-list/officer-preferred-list/officer-preferred-list.component';
import { FileUploaderModule } from './content/file-uploader/file-uploader/file-uploader.module';
import { FileUploaderComponent } from './content/file-uploader/file-uploader/file-uploader.component';


//import { LoadingBarModule } from '@ngx-loading-bar/core';

@NgModule({
  declarations: [
    AppComponent,
    SideNavBarComponent,
    HeaderComponent,
    FooterComponent,
    LoaderComponent,
    AlertComponent,
    ConfirmationDialogComponent,
    GlobalListSearchComponent,
    MenuListItemComponent,
    DashboardComponent,
    VendorTypeComponent,
    AddUpdateVendorTypeComponent,
    VendorTypeDetailComponent,
    CommunicationTypeComponent,
    AddUpdateCommunicationTypeComponent,
    CommunicationTypeDetailComponent,
    CommunicationAttachmentTypeComponent,
    AddUpdateCommunicationAttachmentTypeComponent,
    CommunicationAttachmentTypeDetailComponent,
    VendorComponent,
    VendorRegistrationDialogComponent,
    VendorDetailComponent,
    BankAccountComponent,
    AddUpdateBankAccountDialogComponent,
    BankAccountDetailDialogComponent,
    WorkOrderComponent,
    AddUpdateWorkOrderComponent,
    WorkOrderDetailComponent,
    CommunicationComponent,
    CommunicationDetailComponent,
    ChangeCommunicationOfficeDialogComponent,
    UserComponent,
    AddUpdateUserDialogComponent,
    UserDetailDialogComponent,
    ActionDialogComponent,
    ActionHistoryComponent,
    LoginComponent,
    ProjectComponent,
    AddUpdateProjectComponent,
    ProjectDetailComponent,
    InboxComponent,
    OutboxComponent,
    OutboxDetailComponent,
    InboxDetailComponent,
    ReasonTypeComponent,
    AddUpdateReasonTypeComponent,
    ReasonTypeDetailComponent,
    UpdateVendorProfileComponent,
    VendorUserComponent,
    AddUpdateVendorUserDialogComponent,
    VendorUserDetailDialogComponent,
    ComplaintTypeComponent,
    AddUpdateComplaintTypeComponent,
    ComplaintTypeDetailComponent,
    RefundRequestReasonComponent,
    AddUpdateRefundRequestReasonComponent,
    RefundRequestReasonDetailComponent,
    ConcernComponent,
    ConcernDetailComponent,
    AddUpdateConcernComponent,
    SuggestionForComponent,
    AddUpdateSuggestionForComponent,
    SuggestionForDetailComponent,
    DepartmentReportComponent,
    VendorReportComponent,
    CommunicationTypeReportComponent,
    DepartmentOfficeReportComponent,
    ReasonTypeReportComponent,
    AttachmentTypeReportComponent,
    ComplaintTypeReportComponent,
    RefundRequestReasonReportComponent,
    ConcernReportComponent,
    ProjectReportComponent,
    WorkOrderReportComponent,
    VendorListReportComponent,
    ComplaintReportComponent,
    EmdReturnReportComponent,
    GeneralReportComponent,
    SuggestionsReportComponent,
    InvoiceReportComponent,
    VendorUserReportComponent,
    NewOldCommunicationReportComponent,
    TemporaryLoginComponent,
    UserManualComponent,
    AgeWiseCommunicationComponent,
    OfficeStaffReportComponent,
    ProjectMappingComponent,
    AddUpdateProjectMappingDialogComponent,
    ProjectMappingDetailDialogComponent,
    LoginUserCreationComponent,
    AddUpdateloginuserCreationComponent,
    VendorActionDialogComponent,
    OfficeInternalTransferActionDialogComponent,
    MasterDataComponent,
    AddUpdateMasterDataComponent,
    MasterDataDetailComponent,
    CommunicationFieldsComponent,
    AddCommunicationTypeFieldsComponent,
    AddUpdateCommunicationTransactionComponent
    , InputComponent
    , ButtonComponent
    , SelectComponent
    , DateComponent
    , RadiobuttonComponent
    , CheckboxComponent
    , DynamicFieldDirective
    , DynamicFormComponent,
    AssignDefaultFieldComponent,
    CommunicationTransactionDetailComponent,
    AdminDepartmentComponent,
    AddUpdateAdminDepartmentComponent,
    DesignationMasterComponent,
    AddUpdateDesignationMasterComponent,
    MapFieldsComponent,
    //  SuggestionReportComponent,
    OfficeMasterComponent,
    AddUpdateOfficeMasterComponent,
    FilterComponent,
    AddUpdateOfficeMasterComponent,
    DetailOfficeMasterComponent,
    DepartmentMasterNewComponent,
    AddUpdateDepartmentMasterNewComponent,
    DetailDepartmentMasterNewComponent,
    DepartmentContactDetailsNewComponent,
    AddUpdateDepartmentContactDetailsComponent,
    AddUpdateUserComponent,
    UserCreationComponent,
    UserTypeMasterComponent,
    AddUpdateUserTypeMasterComponent,
    DetailDepartmentContactDetailsComponent,
    NotificationTemplateTypeComponent,
    AddEditDialogComponent,
    NotificationEmailTemplatesComponent,
    AddUpdateEmailComponent,
    NotificationSmsTemplatesComponent,
    AddUpdateSmsComponent,
    UserNotificationComponent,
    PermissionMasterComponent,
    AddUpdatePermissionMasterComponent,
    AddUpdateMenuMasterComponent,
    ComplaintSoftwareComponent,
    masterSuggestionReportComponent,
    comSuggestionReportComponent,
    SpecificUserPermissionComponent,
    UserDefaultPermissionComponent,
    AddUpdateComplaintComponent,
    CompliantActionDialogComponent,
    ImportExcelDataComponent,
    MasterTypeDataComponent,
    AddUpdateMasterTypeDataComponent,
    ProjectCategoryComponent,
    AddUpdateProjectCategoryComponent,
    ProjectCategoryDetailComponent,
    MasterReportsComponent,
    ProjectSubCategoryComponent,
    AddUpdateProjectSubCategoryComponent,
    ProjectSubCategoryDetailComponent,
    NotificationComponent,
    LocateCommunicationComponent,
    WorkOrderCategoryComponent,
    AddUpdateWorkOrderCategoryComponent,
    WorkOrderCategoryDetailComponent,
    WorkOrderSubCategoryComponent,
    AddUpdateWorkOrderSubCategoryComponent,
    WorkOrderSubCategoryDetailComponent,
    AddUpdateChildUserTypeComponent,
    UserReportComponent,
    CommunicationReOpenDialogComponent,
    PageAuthenticationDialogComponent,
    AdditionalPermissionComponent,
    AddUpdateAdditionalPermissionComponent,
    AdditionalPermissionStatusDialogComponent,
    ResponseBackDialogComponent,
    DetailCommunicationTypeFieldDialogComponent,
    SetPagePasswordDialogComponent,
    SpecialCharacterDirective,
    LocateCommunicationDetailComponent,
    GlobalCommunicationSearchComponent,
    GeneralEntryCategoryMasterComponent,
    AddUpdateGeneralEntryCategoryMasterComponent,
    GeneralEntrySubCategoryMasterComponent,
    AddUpdateGeneralEntrySubCategoryMasterComponent,
    GeneralEntryComponent,
    AddUpdateGeneralEntryComponent,
    DetailGeneralEntryComponent,
    B2GUserManualComponent,
    AddUpdateB2GUserManualComponent,
    UserManualDialogComponent,
    DashboardCommunicationListComponent,
    TimeAgoPipe,
    PrintHelperComponent,
    FieldDetailSectionComponent,
    UpdateProfileComponent,
    TransferUserComponent,
    SearchCommunicationDialogComponent,
    SMSTemplateMappingComponent,
    AddUpdateSMSTemplateMappingDialogComponent,
    SmsTemplateDialogComponent,
    ReportSearchCriteriaDialogComponent,
    StatisticalReportsComponent,
    AdminContactDialogComponent,
    PagePermissionListDialogComponent,
    DefaultPermissionDialogComponent,
    StatusMasterComponent,
    AddUpdateStatusMasterComponent,
    DetailStatusMasterComponent,
    UserSpecificPermissionDialogComponent,
    ComplaintSoftwareDetailComponent,
    UserActivityLogComponent,
    DashboardHelpDocumentComponent,
    ProjectGroupComponent,
    AddUpdateProjectGroupComponent,
    ProjectGroupDetailComponent,
    PreferenceListComponent,
    LogReportComponent,
    SmsSentReportComponent,
    LookupReportComponent,
    PreferenceListComponent,
    AddUpdatePreferenceComponent,
    ChangeOfficeDialogComponent,
    PreviousCommunicationListComponent,
    AddCCForCommunicationComponent,
    UpdateVendorUserProfileComponent,
    DashboardDrillDownComponent,
    EntryTypeComponent,
    StatusComponent,
    AddUpdateEntryTypeComponent,
    AddUpdateStatusComponent,
    DistrictComponent,
    AddUpdateDistrictComponent,
    DistrictDetailComponent,
    GeneralHelpDialogComponent,
    SetUserPrimaryDetailDialogComponent,
    CommunicationDefaultFieldConfigComponent,
    UpdateUserOfficeDialogComponent,
    LocateUserComponent,
    SelfRegisterDialogComponent,
    ContactUsComponent,
    AddUpdateAdditionalUserTypeDialogComponent,
    ChangeUserTypeDialogComponent,
    UpdateVendorProfileDialogComponent,
    ApplicationDataUtilityComponent,
    UpdateVendorProfileDialogComponent,
    PreferredDataSelecterComponent,
    PreferredDataDialogComponent,
    StatisticalReportDrillDownComponent,
    TextInfoComponent,
    VendorCommunicationPermissionComponent,
    CustomFilterSelectorComponent,
    CustomFilterDialogComponent,
    VendorPermissionDelegationComponent,
    ClearProjectPopupComponent,
    ClearWorkOrderPopupComponent,
    ClearCommunicationPopUpComponent,
    ChiefMinisterProfileComponent,
    AddUpdateChiefMinisterProfileComponent,
    PreferredListComponent,
    AddUpdatePreferredComponent,
    OfficePreferredListComponent,
    VendorPreferredListComponent,
    OfficeListComponent,
    SecondaryDashboardComponent,
    OfficerListComponent,
    VendorListComponent,
    OfficerPreferredListComponent,
    FileUploaderComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    AppMaterialModule,
    AngularEditorModule,
    AgGridModule.withComponents([]),
    MatSnackBarModule,

    //  LoadingBarModule
  ],
  entryComponents: [
    ConfirmationDialogComponent,
    VendorRegistrationDialogComponent,
    AddUpdateBankAccountDialogComponent,
    BankAccountDetailDialogComponent,
    ChangeCommunicationOfficeDialogComponent,
    AddUpdateUserDialogComponent,
    UserDetailDialogComponent,
    ActionDialogComponent,
    AddUpdateVendorUserDialogComponent,
    VendorUserDetailDialogComponent,
    AddUpdateProjectMappingDialogComponent,
    VendorActionDialogComponent,
    OfficeInternalTransferActionDialogComponent,
    ProjectMappingDetailDialogComponent,
    AddUpdateloginuserCreationComponent,
    AddCommunicationTypeFieldsComponent,
    InputComponent,
    ButtonComponent,
    SelectComponent,
    DateComponent,
    TextInfoComponent,
    RadiobuttonComponent,
    CheckboxComponent,
    AssignDefaultFieldComponent,
    AddUpdateDesignationMasterComponent,
    AddUpdateAdminDepartmentComponent,
    FilterComponent,
    AddUpdateUserTypeMasterComponent,
    AddEditDialogComponent,
    AddUpdateMenuMasterComponent,
    CompliantActionDialogComponent,
    AddUpdateMasterTypeDataComponent,
    AddUpdateChildUserTypeComponent,
    CommunicationReOpenDialogComponent,
    PageAuthenticationDialogComponent,
    AdditionalPermissionStatusDialogComponent,
    ResponseBackDialogComponent,
    DetailCommunicationTypeFieldDialogComponent,
    SetPagePasswordDialogComponent,
    AddUpdateGeneralEntrySubCategoryMasterComponent,
    UserManualDialogComponent,
    SearchCommunicationDialogComponent,
    AddUpdateSMSTemplateMappingDialogComponent,
    ReportSearchCriteriaDialogComponent,
    AdminContactDialogComponent,
    PagePermissionListDialogComponent,
    DefaultPermissionDialogComponent,
    UserSpecificPermissionDialogComponent,
    DashboardHelpDocumentComponent,
    ChangeOfficeDialogComponent,
    AddUpdatePreferenceComponent,
    UpdateVendorUserProfileComponent,
    AddUpdateStatusComponent,
    AddUpdateEntryTypeComponent,
    GeneralHelpDialogComponent,
    SetUserPrimaryDetailDialogComponent,
    CommunicationDefaultFieldConfigComponent,
    UpdateUserOfficeDialogComponent,
    SelfRegisterDialogComponent,
    AddUpdateAdditionalUserTypeDialogComponent,
    ChangeUserTypeDialogComponent,
    UpdateVendorProfileDialogComponent,
    PreferredDataDialogComponent,
    CustomFilterSelectorComponent,
    CustomFilterDialogComponent,
    ClearProjectPopupComponent,
    ClearWorkOrderPopupComponent,
    ClearCommunicationPopUpComponent,
    ChiefMinisterProfileComponent,
    PreferredListComponent,
    AddUpdatePreferredComponent,
    AddUpdateChiefMinisterProfileComponent,
    OfficePreferredListComponent,
    VendorPreferredListComponent,
    OfficeListComponent,

  ],
  providers: [BaseService, AuthGuardService, AlertService, LoaderService, DatePipe, TitleCasePipe,
    { provide: MatDialogRef, useValue: {} },
    { provide: MAT_DIALOG_DATA, useValue: {} },
    { provide: LocationStrategy, useClass: PathLocationStrategy }, //for hide hash
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
  ],
  bootstrap: [AppComponent]
})

export class AppModule { }
//
