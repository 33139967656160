import { Component, OnInit, ViewChild } from "@angular/core";
import { MatPaginator, MatSort, MatDialog, MatTableDataSource } from "@angular/material";
import { Router } from "@angular/router";
import { AppComponent } from "src/app/app.component";
import { ConfirmationDialogComponent } from "src/app/confirmation-dialog/confirmation-dialog.component";
import { AppSetting } from "src/app/shared/model/appsetting.model";
import { GlobalMessagesModel } from "src/app/shared/model/common-messages.model";
import { PermissionModel, IndexModel } from "src/app/shared/model/common.model";
import { ColumnHeaderModel } from "src/app/shared/model/commonddl.model";
import { ComplainEntryTypeMasterViewModel } from "src/app/shared/model/master/complain-entry-type-master.model";
import { AlertService } from "src/app/shared/service/alert.service";
import { AuthenticationService } from "src/app/shared/service/authentication.service";
import { CommonService } from "src/app/shared/service/common.service";
import { ComplainEntryTypeMasterService } from "src/app/shared/service/complain-entry-type-master.service";
import { AddUpdateEntryTypeComponent } from "./add-update-entry-type/add-update-entry-type.component";



@Component({
  selector: 'app-entry-type',
  templateUrl: './entry-type.component.html',
  styleUrls: ['./entry-type.component.scss']
})
export class EntryTypeComponent implements OnInit {

  //#region Variable
  listModel: ComplainEntryTypeMasterViewModel[];
  dataSource: any;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  id: number;
  displayedColumns: string[] = ['index', 'Name', 'NameHindi', 'IsActive', 'Action'];
  ViewdisplayedColumns: ColumnHeaderModel[] = [{ Value: 'Name', Text: 'Entry Type Name' }, { Value: 'NameHindi', Text: 'Entry Type Name In Hindi' }];
  columnsToDisplay: string[] = this.displayedColumns.slice();
  Permission: PermissionModel = this._authService.GetPagePermission("/complaint-software/master/entrytype", "/complaint-software/master/entrytype/add", "", "/complaint-software/master/entrytype/edit", "");

  indexModel: IndexModel;
  totalRecords: number;
  //#endregion
  constructor(
    private readonly _alertService: AlertService,
    private readonly _parentApi: AppComponent,
    private readonly _dialog: MatDialog, 
    private readonly _router: Router,
    private readonly _authService: AuthenticationService,
    private readonly _complainEntryTypeMasterService: ComplainEntryTypeMasterService) {

    this._parentApi.setPageLayout("Suggestion-Feedback - Entry Type Master :", "", "", "", true);
    this.indexModel = new IndexModel();

  }
  //#endregion

  ngOnInit() {
    this.GetList();
  }


  GetList() {
    this._complainEntryTypeMasterService.GetList(this.indexModel).subscribe(
      data => {
        if ((data.IsSuccess)) {
          this.listModel = <ComplainEntryTypeMasterViewModel[]>data.Data.Data;
          this.dataSource = new MatTableDataSource<ComplainEntryTypeMasterViewModel>(this.listModel);
          this.totalRecords = data.Data.TotalRecords;
          if (!this.indexModel.IsPostBack) {
            this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.sort;
          }
        }
      },
      error => {
        this._alertService.error(error.message);
      }
    );
  }


  OpenDialog(Id) {
    const _dialogRef = this._dialog.open(AddUpdateEntryTypeComponent, {
      width: "500px",
      data: Id,
      disableClose: true
    });
    _dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        this.GetList();
      }
    });
  }


  OnStatusClick(id) {

    const _dialogRef = this._dialog.open(ConfirmationDialogComponent, {
      width: "50%",
      data: GlobalMessagesModel.ConfirmStatusChanged
    });
    _dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        this._complainEntryTypeMasterService.ChangeActiveStatus(id).subscribe(
          data => {
            if (data.IsSuccess) {
              this.GetList();
              this._alertService.success(data.Message);
            } else {
              this._alertService.error(data.Message);
            }
          },
          error => {
            this._alertService.error(error.message);
          }
        );

      }
    });

  }


  sortData(event) {
    this.indexModel.OrderBy = event.active;
    this.indexModel.OrderByAsc =
      event.direction == AppSetting.orderByDscAsc
        ? AppSetting.orderByAsc
        : AppSetting.orderByDsc;
    this.indexModel.IsPostBack = true;
    this.GetList();
  }

  onPaginateChange(event) {
    this.indexModel.Page = event.pageIndex + 1;
    this.indexModel.PageSize = event.pageSize;
    this.indexModel.IsPostBack = true;
    this.GetList();
  }
  //#endregion


}
