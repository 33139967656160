export class VendorListRequestModel {
    SSOId: string;
    SearchTxt: string;
    CompanyName: string;

    constructor() {
        this.SSOId = '';
        this.SearchTxt = '';
        this.CompanyName = '';
    }
}