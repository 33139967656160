import { Injectable } from '@angular/core';
import { AppSetting } from '../../model/appsetting.model';
import { CCSearchViewModel, UserPreferLookupViewModel } from '../../model/B2G/preference-list';
import { IndexModel } from '../../model/common.model';
import { BaseService } from '../base.service';

@Injectable({
  providedIn: 'root'
})
export class PreferenceService {

  constructor(private readonly _baseService: BaseService) { }
  GetList(userOfficeId: number) {
    return this._baseService.get(AppSetting.PreferenceListUrl + "?userOfficeId=" + userOfficeId);
  }
  AddUpdate(model: UserPreferLookupViewModel) {
    return this._baseService.post(AppSetting.PreferenceAddUpdateUrl, model);
  }
  GetAllList(model: IndexModel) {
    return this._baseService.post(AppSetting.PreferenceAllListUrl, model);
  }
  ChangeDeleteStatus(id: number) {
    return this._baseService.get(AppSetting.PreferenceDeleteStatusChangeUrl + id);
  }
  PreferedOfficeList(model: CCSearchViewModel) {
    return this._baseService.post(AppSetting.PreferedOfficeListUrl, model);
  }

}

