import { CommonService } from './../../../../shared/service/common.service';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Router, ActivatedRoute } from '@angular/router';
import { AppSetting } from 'src/app/shared/model/appsetting.model';
import { DDLModel } from 'src/app/shared/model/commonddl.model';
import { NotificationTemplateTypeModel } from 'src/app/shared/model/master/notification-template-type.model';
import { AlertService } from 'src/app/shared/service/alert.service';
import { NotificationTemplateTypeService } from 'src/app/shared/service/master/notification-template-type.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { GlobalMessagesModel } from 'src/app/shared/model/common-messages.model';

@Component({
  selector: 'app-add-edit-dialog',
  templateUrl: './add-edit-dialog.component.html',
  styleUrls: ['./add-edit-dialog.component.scss']
})
export class AddEditDialogComponent implements OnInit {

  model = new NotificationTemplateTypeModel();
  dDLList = new DDLModel();
  templateForm: FormGroup;
  constructor(private readonly _alertService: AlertService,
    private _router: Router,
    public _commonService: CommonService,
    public _dialogRef: MatDialogRef<AddEditDialogComponent>,
    private readonly _route: ActivatedRoute,
    private readonly _notificationTemplateTypeService: NotificationTemplateTypeService,
    private readonly formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any) {

    this.GetDDLList();

    if (data) {
      this.model.Id = data;
    }
  }

  ngOnInit() {

    this.formInit();

    if (this.model.Id > 0) {
     
        this.GetById();
     
     
    }
  }


  formInit() {
    this.templateForm = this.formBuilder.group({
      Name: [undefined, Validators.required],
      NameHindi: [undefined, Validators.required],
      TransactionENUM: [undefined, Validators.required],
      IsSMS: [undefined],
      IsMail: [undefined]


    });
  }

  GetById() {
    this._notificationTemplateTypeService.GetById(this.model.Id).subscribe(
      data => {
        
        if (data.IsSuccess) {
          this.model = <NotificationTemplateTypeModel>data.Data;
      
        }
      },
      error => {
        this._alertService.error(error.message);
      }
    );
  }

  SaveClick() {
    this.templateForm.markAllAsTouched();
    if (this.templateForm.valid) {
      if (this.model.Id > 0) {

        this._notificationTemplateTypeService.Edit(this.model).subscribe(data => {
          if (data.IsSuccess) {
            this._alertService.success(data.Message);
            this._dialogRef.close(true);
          } else {
            this._alertService.error(data.Message);

          }
        }, error => {
          this._alertService.error(error.message);
        });
      } else {
        this._notificationTemplateTypeService.Add(this.model).subscribe(data => {
          if (data.IsSuccess) {
            this._alertService.success(data.Message);
            this._dialogRef.close(true);
          } else {
            this._alertService.error(data.Message);

          }
        }, error => {
          this._alertService.error(error.message);
        });
      }
    }
  }

  GetDDLList() {

    this._commonService.GetAllDDL(AppSetting.NotificationTypeDropdownKeys).subscribe(

      data => {

        if (data.IsSuccess) {
          this.dDLList = <DDLModel>data.Data;
        }
      },
      error => {
        this._alertService.error(error.message);
      }
    );
  }


  checkEnumValueExist(Event) {
    this._notificationTemplateTypeService.checkEnumValueExist(this.model.TransactionENUM, this.model.Id).subscribe(result => {
      if (result.IsSuccess) {
        let resposeData = <boolean>result.Data
     
          this.model.TransactionENUM=undefined;
          this._alertService.error(GlobalMessagesModel.Exist);
       
      }
    }, error => {
      this._commonService.ScrollingTop();
      this._alertService.error(error.message);
    });

  }


  onNoClick(): void {
    this._dialogRef.close();
  }

}
