import { IndexModel } from "../common.model";

export class ProjectReportRequestModel extends IndexModel {

    IsActive: number;
    IsOICAssigned: number;
    ProjectName: string;

    constructor() {
        super();
        this.IsActive = -1;
        this.IsOICAssigned = -1;
        this.ProjectName = "";
    }
}