import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource, MatDialog, MatSort, MatPaginator, MatRadioChange } from "@angular/material";
import { IndexModel } from 'src/app/shared/model/common.model';
import { ColumnHeaderModel, DdlItemModel, DDLModel } from 'src/app/shared/model/commonddl.model';
import { AppComponent } from 'src/app/app.component';
import { AlertService } from 'src/app/shared/service/alert.service';
import { CommonService } from 'src/app/shared/service/common.service';
import { ConfirmationDialogComponent } from 'src/app/confirmation-dialog/confirmation-dialog.component';
import { GlobalMessagesModel } from 'src/app/shared/model/common-messages.model';
import { AppSetting } from 'src/app/shared/model/appsetting.model';
import { CommunicationModel } from 'src/app/shared/model/communication.model';
import { CommunicationService } from 'src/app/shared/service/communication.service';
import { Router, ActivatedRoute } from '@angular/router';
import { isNullOrUndefined } from 'util';
import { UserViewModel } from 'src/app/shared/model/user.model';
import { AuthenticationService } from 'src/app/shared/service/authentication.service';
import { UserTypeEnum, StatusEnum } from 'src/app/shared/enum/fixed-values.enum';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { ReportFilterModel } from 'src/app/shared/model/report-filter.model';
import { DatePipe } from '@angular/common';
import { FormControl, Validators } from '@angular/forms';
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

declare const require: any;
const jsPDF = require('jspdf');
require('jspdf-autotable');

@Component({
  selector: 'app-age-wise-communication',
  templateUrl: './age-wise-communication.component.html',
  styleUrls: ['./age-wise-communication.component.scss']
})
export class AgeWiseCommunicationComponent implements OnInit {
  model: CommunicationModel[];
  modellist: CommunicationModel;
  loginUserCode: number;
  loginBaseUserType: string;
  userTypeVNDR: string = UserTypeEnum.VNDR;
  indexModel: IndexModel;
  dataSource: any;
  totalRecords: number;
  ddlStatus: DdlItemModel[] = [];
  search: string = '';
  reportTitle: string = "";
  reportFileName: string = "";
  reportModel: ReportFilterModel;
  ddlList: DDLModel;
  ageComunication: Date | string;
  days: number = 0;
  daysValue = new FormControl('', [Validators.required]);

  displayedColumns: string[] = ["index", "Subject", "DepartmentName", "OfficeName", "OrderDate", "Status"];  //"IsActive"
  columnsToDisplay: string[] = this.displayedColumns.slice();
  ViewdisplayedColumns: ColumnHeaderModel[] = [
    { Value: "Subject", Text: "Subject" },
    { Value: "DepartmentName", Text: "Department" },
    { Value: "OfficeName", Text: "Office" },
    { Value: "OrderDate", Text: "Date" },
  ];

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  constructor(private readonly appComponnet: AppComponent,
    private readonly _alertService: AlertService,
    private readonly _router: Router,
    private _route: ActivatedRoute,
    private readonly _communicationService: CommunicationService,
    private readonly _dialog: MatDialog,
    public readonly _commonService: CommonService,
    private readonly _authService: AuthenticationService,
    private datePipe: DatePipe
  ) {
    this.indexModel = new IndexModel();
    this.reportModel = new ReportFilterModel();
    this.loginUserCode = this._authService.UserCode;
    this.loginBaseUserType = this._authService.BaseUserType;

    this.ageComunication = new Date().toLocaleString();

    if (this.loginBaseUserType == UserTypeEnum.VNDR)
      this.appComponnet.setPageLayout("Age Wise Communication Report :", "", "", "", false, false);
  }


  ngOnInit() {
    this.getList();
  }



  getList() {



    this._communicationService.GetAgeWiseCommunicationList(this.loginUserCode, this.days, this.reportModel).subscribe(
      data => {
        if (data.IsSuccess) {
          this.model = <CommunicationModel[]>data.Data;
          this.dataSource = new MatTableDataSource<CommunicationModel>(this.model);
          this.totalRecords = data.Data.length;
        } else {
          this._alertService.error(data.Message);
        }
      },
      error => {
        this._alertService.error(error.message);
      }
    );
  }

  onClearclick() {
    this.days = 0;
    this.getList();
  }

  //Report

  completeModel: CommunicationModel[];//change
  completeIndexModel: IndexModel;

  getCompleteData() {
    this.completeIndexModel = new IndexModel();
    this.completeIndexModel.Page = 1;
    this.completeIndexModel.PageSize = this.totalRecords;
    this.completeIndexModel.IsPostBack = true;
    this._communicationService.GetAgeWiseCommunicationList(this.loginUserCode, this.days, this.completeIndexModel).subscribe(
      data => {
        if (data.IsSuccess) {

          this.completeModel = <CommunicationModel[]>data.Data;
          this.dataSource = new MatTableDataSource<CommunicationModel>(this.model);
          this.totalRecords = data.Data.length;
        } else {
          this._alertService.error(data.Message);
        }
      },
      error => {
        this._alertService.error(error.message);
      }
    );

  }

  rowsNew: any = [];

  download() {

    var i = 1;
    this.rowsNew = [];
    this.completeModel.forEach(element => {
      var temp = {
        SNo: i, Subject: element.Subject, DepartmentName: element.DepartmentName, OfficeName: element.OfficeName, Status: element.Status
      };
      i = i + 1;
      this.rowsNew.push(temp);
    });
  }

  buttonClickExportAsXLSX(): void {
    this.getCompleteData();
    setTimeout(() => {
      this.download();
      this.exportAsExcelFile();
    }, 1000);
  }

  buttonClickExportAsPDF(): void {
    this.getCompleteData();
    setTimeout(() => {
      this.download();
      this.exportAsPdf();
    }, 1000);
  }

  public exportAsPdf(): void {
    var doc = new jsPDF('p', 'pt');
    var header = function (data) {
      doc.setFontSize(10);
      doc.setTextColor(40);
      doc.text("Communication-Report", data.settings.margin.left, 50);
      doc.text("Date Time : " + new Date().toLocaleDateString() + " " + new Date().toLocaleTimeString(), data.settings.margin.left, 800);
    };
    var headers = [{
      title: "#",
      dataKey: "SNo"
    }, {
      title: "Subject",
      dataKey: "Subject"
    },
    {
      title: "Department Name",
      dataKey: "DepartmentName"
    },
    {
      title: "Office Name",
      dataKey: "OfficeName"
    },
    {
      title: "Status",
      dataKey: "Status"
    }
    ];
    doc.autoTable(headers, this.rowsNew, { margin: { top: 70 }, beforePageContent: header });
    doc.save("Age-Wise-Communicaation-Status-Report_" + new Date().toLocaleDateString() + "_" + new Date().toLocaleTimeString() + ".pdf");
  }

  public exportAsExcelFile(): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.rowsNew);
    const workbook: XLSX.WorkBook = { Sheets: { 'Communication-Report': worksheet }, SheetNames: ['Communication-Report'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFile(excelBuffer, 'Age-Wise-Communication-Status');
  }

  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], { type: EXCEL_TYPE });
    FileSaver.saveAs(data, fileName + '_Report_' + new Date().toLocaleDateString() + "_" + new Date().toLocaleTimeString() + EXCEL_EXTENSION);
  }

}
