import { Injectable } from "@angular/core";
import { Subject, Observable } from "rxjs";
import { SearchCriteriaModel } from "../../model/report/search-criteria.model";

@Injectable({
  providedIn: "root",
})
export class GlobalReportService {
  private searchModel = new Subject<SearchCriteriaModel>();
  constructor() { }
  GetData(): Observable<any> {
    return this.searchModel.asObservable();
  }

  SetData(filter) {
    this.searchModel.next(filter);
  }
}
