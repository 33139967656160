import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { AppComponent } from "src/app/app.component";
import { MasterReportModel } from 'src/app/shared/model/master-reports.modal';
import { MasterReportsService } from 'src/app/shared/service/master-reports.service';
import { GlobalFilterModel, IndexModel } from 'src/app/shared/model/common.model';
import { AppSetting } from 'src/app/shared/model/appsetting.model';
import { MatTableDataSource, MatDialog, MatSort, MatPaginator } from "@angular/material";
import { AlertService } from 'src/app/shared/service/alert.service';
import { CommonService } from 'src/app/shared/service/common.service';
import { ColumnHeaderModel } from 'src/app/shared/model/commonddl.model';
import { UserTypeEnum } from 'src/app/shared/enum/fixed-values.enum';
import { AuthenticationService } from 'src/app/shared/service/authentication.service';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';


declare const require: any;
const jsPDF = require('jspdf');
require('jspdf-autotable');

@Component({
  selector: 'app-master-reports',
  templateUrl: './master-reports.component.html',
  styleUrls: ['./master-reports.component.scss']
})
export class MasterReportsComponent implements OnInit {

  @ViewChild('pdfTable', { static: false }) pdfTable: ElementRef;

  model: MasterReportModel[];
  indexModel = this._commonService.modelSetGet(new IndexModel()) as IndexModel;
  dataSource: any;
  totalRecords: number;
  masterTypeCode: string;
  masterTypeName: string;
  displayedColumns: string[] = ["index", "FieldValueInEnglish", "FieldValueInHindi", "ModifiedOn", "UserName", "IsActive"];
  columnsToDisplay: string[] = this.displayedColumns.slice();
  userTypeEnum=UserTypeEnum;
  ViewdisplayedColumns: ColumnHeaderModel[] = [
    { Value: "FieldValueInEnglish", Text: "Title" },
    { Value: "FieldValueInHindi", Text: "Title (Hindi)" },
    { Value: "ModifiedOn", Text: "Modified On" },
    { Value: "UserName", Text: "Modified By" },
  ];
  SearchdisplayedColumns: ColumnHeaderModel[] = [
    { Value: "FieldValueInEnglish", Text: "Title" },
    { Value: "FieldValueInHindi", Text: "Title (Hindi)" },

  ];

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(
    private readonly appComponnet: AppComponent,
    private readonly _alertService: AlertService,
    private readonly _masterReportService: MasterReportsService,
    private readonly _router: Router,
    private readonly _dialog: MatDialog,
    public readonly _commonService: CommonService,
    private readonly _authService: AuthenticationService,
    private readonly _route: ActivatedRoute
  ) {
    this.appComponnet.setPageLayout("Master Report :", "", "", "");


    if (this._route.snapshot.params.id) {

      this.masterTypeCode = this._route.snapshot.params.id.split("_")[0];
      this.masterTypeName = this._route.snapshot.params.id.split("_")[1];
    }



  }



  ngOnInit() {

    this._route.paramMap.subscribe((params: ParamMap) => {
      this.masterTypeCode = this._route.snapshot.params.id.split("_")[0];
      this.masterTypeName = this._route.snapshot.params.id.split("_")[1];

      if (this.userTypeEnum.ADM != this._authService.BaseUserType && this.userTypeEnum.SADM  != this._authService.BaseUserType) {
        this._router.navigate(["dashboard"]);
      }

      this.getList();
    });




  }

  getList() {

    this._masterReportService.GetList(this._commonService.modelSetGet(this.indexModel, true), this.masterTypeCode).subscribe(
      data => {
        if (data.IsSuccess) {
          this.model = <MasterReportModel[]>data.Data.Data;
          this.dataSource = new MatTableDataSource<MasterReportModel>(this.model);
          this.totalRecords = data.Data.TotalRecords;
          if (!this.indexModel.IsPostBack) {
            this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.sort;
          }
        } else {
          this._alertService.error(data.Message);
        }
      },
      error => {
        this._alertService.error(error.message);
      }
    );
  }

  DownloadPdf() {
    this._masterReportService.GetPDFDownload(this.indexModel, this.masterTypeCode).subscribe(
      data => {
        if (data.IsSuccess) {
          // const linkSource = data.Data;
          // const downloadLink = document.createElement("a");
          // const fileName = 'Vendor Type Report';

          // downloadLink.href = linkSource;
          // downloadLink.download = fileName;
          // downloadLink.target = "blank";
          // downloadLink.click();
          var w = window.open("about:blank");

          setTimeout(function () {
            //FireFox seems to require a setTimeout for this to work.
            w.document.body.appendChild(w.document.createElement("iframe")).src = data.Data;
            w.document.getElementsByTagName("iframe")[0].style.width = "100%";
            w.document.getElementsByTagName("iframe")[0].style.height = "100%";
          }, 0);
        } else {
          this._alertService.error(data.Message);
        }
      },
      error => {
        this._alertService.error(error.message);
      }
    );
  }
  /*
    updateDeleteStatus(id) {
      const dialogRef = this._dialog.open(ConfirmationDialogComponent, { width: "50%", data: GlobalMessagesModel.ConfirmDeleted });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this._vendorTypeService.ChangeDeleteStatus(id).subscribe(
            data => {
              if (data.IsSuccess) {
                this._alertService.success(data.Message);
                this._commonService.ScrollingTop();
                this.getList();
              }
            },
            error => {
              this._alertService.error(error.message);
            }
          );
        }
      });
    }
  
    updateActiveStatus(id) {
      const dialogRef = this._dialog.open(ConfirmationDialogComponent, { width: "50%", data: GlobalMessagesModel.ConfirmStatusChanged });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this._vendorTypeService.ChangeActiveStatus(id).subscribe(
            data => {
              if (data.IsSuccess) {
                this._alertService.success(data.Message);
                this.getList();
              }
            },
            error => {
              this._alertService.error(error.message);
            }
          );
        }
      });
    }
  */
  sortData(event) {
    this.indexModel.OrderBy = event.active;
    this.indexModel.OrderByAsc = event.direction == AppSetting.orderByDscAsc ? AppSetting.orderByAsc : AppSetting.orderByDsc;
    this.indexModel.IsPostBack = true;
    this.getList();
  }

  onPaginateChange(event) {
    this.indexModel.Page = event.pageIndex + 1;
    this.indexModel.PageSize = event.pageSize;
    this.indexModel.IsPostBack = true;
    this.getList();
  }

  SearchByKeyword(searchValue: GlobalFilterModel) {
    this.indexModel.Search = searchValue.search;
    this.indexModel.AdvanceSearchModel = { ActiveStatus: searchValue.ActiveStatus }

    this.getList();
  }

  ClearSearch() {
    this.indexModel = new IndexModel();
    this.getList();
  }


  completeModel: MasterReportModel[];//change
  completeIndexModel: IndexModel;
  getCompleteData() {
    this.completeIndexModel = new IndexModel();
    this.completeIndexModel.Page = 1;
    this.completeIndexModel.PageSize = this.totalRecords;
    this.completeIndexModel.IsPostBack = true;

    this._masterReportService.GetList(this.completeIndexModel, this.masterTypeCode).subscribe( //change
      (completeData: any) => {
        if (completeData.IsSuccess) {

          this.completeModel = <MasterReportModel[]>completeData.Data.Data; //change
          this.totalRecords = completeData.Data.TotalRecords;
          this.download();
        } else {
          this._alertService.error(completeData.Message);
        }
      },
      error => {
        this._alertService.error(error.message);
      }
    );
  }

  buttonClickExportAsPDF(): void {
    // this.getCompleteData();
    // setTimeout(() => {
    //   this.download();
    //   this.exportAsPdf();
    // }, 1000);
    this.DownloadPdf();
  }
  buttonClickExportAsXLSX(): void {
    this.getCompleteData();
    setTimeout(() => {
      this.download();
      this.exportAsExcelFile();
    }, 1000);
  }

  rowsNew: any = [];

  download() {
    //completeModel
    var i = 1;
    this.rowsNew = [];
    this.completeModel.forEach(element => {
      var temp = {
        index: i, FieldValueInEnglish: element.FieldValueInEnglish,
        FieldValueInHindi: element.FieldValueInHindi,
        IsActive: element.IsActive, ModifiedBy: element.UserName, ModifiedOn: element.ModifiedOn
      }; //change
      i = i + 1;
      this.rowsNew.push(temp);
    });

  }

  public exportAsPdf(): void {
    var doc = new jsPDF('p', 'pt');
    var header = function (data) {
      // doc.addFont('kruti-dev-021.ttf', 'kruti-dev-021', 'normal');
      // doc.setFont('kruti-dev-021');

      doc.setFontSize(10);
      doc.setTextColor(40);
      doc.text("MasterReport", data.settings.margin.left, 50);
      doc.text("Date Time : " + new Date().toLocaleDateString() + " " + new Date().toLocaleTimeString(), data.settings.margin.left, 800);
    };
    var headers = [{  //change
      title: "#",
      dataKey: "index"
    }, {
      title: "Vendor Type",
      dataKey: "VendorType"
    },
    // {
    // title: "VendorType Hindi",
    // dataKey: "DepartmentNameHindi"
    //},
    // {
    //   title: "VendorType code",
    //   dataKey: "VendorTypeCode"
    // },
    {
      title: "Vendor Type (Hindi)",
      dataKey: "VendorTypeHindi"
    },

    {
      title: "Is Active",
      dataKey: "IsActive"
    },
    {
      title: "Modified By",
      dataKey: "ModifiedBy"
    },
    {
      title: "Modified On",
      dataKey: "ModifiedOn"
    },
    ];
    doc.autoTable(headers, this.rowsNew, { margin: { top: 70 }, beforePageContent: header });
    doc.save(this.masterTypeName + "-Report" + new Date().toLocaleDateString() + "_" + new Date().toLocaleTimeString() + ".pdf"); //change
  }

  //excellll
  public exportAsExcelFile(): void {
    //this.getCompleteData();
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.rowsNew);
    const workbook: XLSX.WorkBook = { Sheets: { 'Report': worksheet }, SheetNames: ['Report'] }; //change
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFile(excelBuffer, this.masterTypeName); //change
  }
  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], { type: EXCEL_TYPE });
    FileSaver.saveAs(data, fileName + '_Report_' + new Date().toLocaleDateString() + "_" + new Date().toLocaleTimeString() + EXCEL_EXTENSION);
  }


}
