import { Component, OnInit, ViewChild } from "@angular/core";
import { MatPaginator, MatSort, MatDialog, MatTableDataSource } from "@angular/material";
import { of } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { AppComponent } from "src/app/app.component";
import { ConfirmationDialogComponent } from "src/app/confirmation-dialog/confirmation-dialog.component";
import { StateEnum, UserTypeEnum } from "src/app/shared/enum/fixed-values.enum";
import { AppSetting } from "src/app/shared/model/appsetting.model";
import { GlobalMessagesModel } from "src/app/shared/model/common-messages.model";
import { IndexModel, SearchModel } from "src/app/shared/model/common.model";
import { ColumnHeaderModel, DdlItemModel, DDLModel } from "src/app/shared/model/commonddl.model";
import { OfficeMasterFilterModel } from "src/app/shared/model/master/office-master-filter.model";
import { CustomSearchModel, OfficeMasterViewModel } from "src/app/shared/model/master/office-master.model";
import { UserViewModel } from "src/app/shared/model/user.model";
import { AlertService } from "src/app/shared/service/alert.service";
import { AuthenticationService } from "src/app/shared/service/authentication.service";
import { CommonService } from "src/app/shared/service/common.service";
import { OfficeMasterService } from "src/app/shared/service/master/office-master.service";
import { UserService } from "src/app/shared/service/user.service";

@Component({
  selector: 'app-office-master',
  templateUrl: './office-master.component.html',
  styleUrls: ['./office-master.component.scss']
})
export class OfficeMasterComponent implements OnInit {

  userTypeEnum = UserTypeEnum;
  model: OfficeMasterViewModel[];
  dataSource: any;
  displayedColumns: string[] = ["index", "Department", "OfficeName", "OfficeNameHindi", "ParentOfficeName", "District",
    "CreatedDate", "ModifiedDate", "IsActive", "Action"];
  ViewdisplayedColumns: ColumnHeaderModel[] = [
    { Value: "Department", Text: "Department" },
    { Value: "OfficeName", Text: "Name" },
    { Value: "OfficeNameHindi", Text: "Hindi Name" },
    { Value: "ParentOfficeName", Text: "Parent Office" },
  ];

  columnsToDisplay: string[] = this.displayedColumns.slice();
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  totalRecords: number;
  Permission = this._authService.GetPagePermission("/master/office-master", "/office-master/create", "/office-master/detail", "/office-master/update", "/office-master/delete");
  ddlList = new DDLModel();
  fromDate: Date | string = new Date("12/17/2018");
  toDate: Date | string = new Date();
  isShow = true;
  loginData: UserViewModel;
  public filterModel: OfficeMasterFilterModel = new OfficeMasterFilterModel();

  constructor(
    private readonly _appComponent: AppComponent,
    private readonly _alertService: AlertService,
    private readonly _officeService: OfficeMasterService,
    private readonly _userService: UserService,
    private readonly _dialog: MatDialog,
    private readonly _authService: AuthenticationService,
    public readonly _commonService: CommonService
  ) {
    this.Permission.AddPageAccess ? this._appComponent.setPageLayout("Office Master:", "add", "Create", "master/office-master/create")
      : this._appComponent.setPageLayout("Office Master:");

    this.loginData = this._authService.GetCurrentUserDetail().UserViewModel;

    if (this.loginData.BaseUserType == this.userTypeEnum.DOS || this.loginData.BaseUserType == this.userTypeEnum.DOSADLC) {
      this.displayedColumns.forEach((value, index) => {
        if (value == 'IsActive') this.displayedColumns.splice(index, 1);
      });
      this.columnsToDisplay = this.displayedColumns.slice();
    }
  }

  ngOnInit() {
    this.GetDDLList();
    this.getList();
    this.GetDistrict();
  }

  toggleDisplay() {
    this.isShow = !this.isShow;
  }

  getList() {
    
    this._officeService.GetList(this.filterModel).subscribe(
      (data) => {
        if (data.IsSuccess) {
          this.model = <OfficeMasterViewModel[]>data.Data.Data;
          this.dataSource = new MatTableDataSource<OfficeMasterViewModel>(this.model);
          this.totalRecords = data.Data.TotalRecords;
        } else {
          this._alertService.error(GlobalMessagesModel.InternalError);
        }
      },
      (error) => {
        this._alertService.error(error.message);
      }
    );
  }

  getDepartments() {
    this._commonService.getDepartmentsByAdminDepartment(Number(this.filterModel.AdminDepartmentCode)).subscribe(response => {
      this.ddlList.ddlDepartment = [];

      if (response.IsSuccess == true){
        this.ddlList.ddlDepartment = response.Data;
      }
    }, error =>{
      this._alertService.error(error.Message);
    })
  }

  GetDistrict() {
    this._userService.loginUserCode = this.loginData.UserCode;
    let stateCode = String(StateEnum.Rajasthan);
    this._userService.GetUserDistrictForUserOfficeCreation(stateCode, '', true).subscribe(data => {
      if (data.IsSuccess) {
        this.ddlList.ddlDistrict = <DdlItemModel[]>data.Data;
      }
    }, error => {
      this._alertService.error(error.message);
    });
  }

  public onDepartmentChange() {
    if (Number(this.filterModel.DistrictCode) > 0){
      this.getParentOffices().subscribe(offices => this.ddlList.ddlDepartmentOffice = offices);
    }
  }

  getDepartmentOffices() {
    this.ddlList.ddlDepartmentOffice = [];
    if (Number(this.filterModel.DepartmentCode) > 0) {
      this._userService.GetUserOfficeList(this.filterModel.DepartmentCode).subscribe(data => {
        if (data.IsSuccess) {
          this.ddlList.ddlDepartmentOffice = <DdlItemModel[]>data.Data;
        }
      }, error => {
        this._alertService.error(error.message);
      });
    }
  }

  GetDDLList() {
    this._commonService.GetAllDDL(AppSetting.DdlKeyForOfficeListFilter).subscribe(
      response => {
        if (response.IsSuccess) {
          let data = <DDLModel>response.Data;

          this.ddlList.ddlAdminDepartment = data.ddlAdminDepartment;
        }
      },
      error => {
        this._alertService.error(error.message);
      }
    );
  }

  Reset() {
    this.filterModel = new OfficeMasterFilterModel();
    this.getList();
  }

  updateDeleteStatus(id) {
    const dialogRef = this._dialog.open(ConfirmationDialogComponent, {
      width: "50%",
      data: GlobalMessagesModel.ConfirmStatusChanged,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this._officeService.ChangeDeleteStatus(id).subscribe(
          (data) => {
            if (data.IsSuccess) {
              this._alertService.success(GlobalMessagesModel.deleteSuccess);
              this._commonService.ScrollingTop();
              this.getList();
            }
          },
          (error) => {
            this._alertService.error(GlobalMessagesModel.InternalError);
          }
        );
      }
    });
  }

  updateActiveStatus(id) {
    const dialogRef = this._dialog.open(ConfirmationDialogComponent, {
      width: "50%",
      data: GlobalMessagesModel.ConfirmStatusChanged,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this._officeService.ChangeActiveStatus(id).subscribe(
          (data) => {
            if (data.IsSuccess) {
              this._alertService.success(GlobalMessagesModel.updateSuccess);
              this.getList();
            }
          },
          (error) => {
            this._alertService.error(error.message);
          }
        );
      }
    });
  }

  sortData(event) {
    this.filterModel.OrderBy = event.active;
    this.filterModel.OrderByAsc =
      event.direction == AppSetting.orderByDscAsc
        ? AppSetting.orderByAsc
        : AppSetting.orderByDsc;
    this.getList();
  }

  onPaginateChange(event) {
    this.filterModel.Page = event.pageIndex + 1;
    this.filterModel.PageSize = event.pageSize;
    this.filterModel.IsPostBack = true;
    this.getList();
  }

  PagePrint(printContent) {
    this._commonService.printHTMLContent(printContent, "Office Master");
  }

  private getParentOffices() {
    return this._commonService.getOfficesBy(Number(this.filterModel.DepartmentCode), Number(this.filterModel.DistrictCode)).pipe(
      map(response => {
        if (response.IsSuccess == true){
          return <DdlItemModel[]>response.Data;
        }
        return [];
      }),
      catchError(error => of(<DdlItemModel[]>[]))
    )
  }
}
