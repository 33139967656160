import { IndexModel } from './../../shared/model/common.model';
import { Component, OnInit } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { DynamicFieldConfig } from "src/app/shared/model/dynamic-field-config.model";
@Component({
  selector: "app-select",
  template: `
  <div [ngClass]="[field.CSSClass?field.CSSClass:'col l4 xl4 m4 s12']">
<mat-form-field class="example-full-width"  [formGroup]="group" appearance="outline">
<mat-label>{{field.label}} <span *ngIf="field.IsRequired" style="color: red">*</span>

</mat-label>
<mat-select (focus)="this.group.get(this.field.name).markAsTouched()" [placeholder]="field.label" [formControlName]="field.name" [(ngModel)]="field.value" matTooltip="{{field.ToolTip}}"  matTooltipPosition="above" [disabled]="field.IsDisabledForEdit">
<mat-option>--Select--</mat-option>
<mat-option *ngFor="let item of field.options" value="{{item.Value}}">{{item.Text}}</mat-option>
</mat-select>
</mat-form-field>
<ng-container *ngFor="let validation of field.validations; let indx=Index" ngProjectAs="mat-error">
<mat-error class="dynamic-feild-error" *ngIf="group.get(field.name).hasError(validation.name) && group.get(field.name).touched">{{validation.message}}</mat-error>

</ng-container>

</div>
`,
  styles: []
})
export class SelectComponent implements OnInit {
  field: DynamicFieldConfig;
  group: FormGroup;
  fields: DynamicFieldConfig[];

  constructor() { }
  ngOnInit() { }
}
