import { Component, OnInit } from "@angular/core";
import { DdlItemModel, DDLModel, FilterDDlModel } from "src/app/shared/model/commonddl.model";
import { UserViewModel, SSOUserModel, UserCreationModel, userCreationConfigModel } from "src/app/shared/model/user.model";
import { UserTypeService } from "src/app/shared/service/user-type.service";
import { UserTypeModel } from "src/app/shared/model/user-type.model";
import { AbstractControl, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { AppComponent } from "src/app/app.component";
import { CommonService } from "src/app/shared/service/common.service";
import { AlertService } from "src/app/shared/service/alert.service";
import { Router, ActivatedRoute } from "@angular/router";
import { UserService } from "src/app/shared/service/user.service";
import { AuthenticationService } from "src/app/shared/service/authentication.service";
import { MatDialog } from "@angular/material";
import { GlobalMessagesModel } from "src/app/shared/model/common-messages.model";
import { AlertTypeEnum, StateEnum, UserTypeEnum } from "src/app/shared/enum/fixed-values.enum";
import { AppSetting } from "src/app/shared/model/appsetting.model";
import { isNullOrUndefined } from "util";
import { SetUserPrimaryDetailDialogComponent } from "../set-user-primary-detail-dialog/set-user-primary-detail-dialog.component";

@Component({
  selector: "app-add-update-user",
  templateUrl: "./add-update-user.component.html",
  styleUrls: ["./add-update-user.component.scss"],
})

export class AddUpdateUserComponent implements OnInit {

  model: UserCreationModel;
  ddlUserType: DdlItemModel[] = [];
  loginUserDetail: UserViewModel;
  UserForm: FormGroup;
  dDLList: DDLModel = new DDLModel();
  oldSSOId: string;
  ssoUserDetail: SSOUserModel;
  isUserExistOnSso = false;
  ddlAdminDepartment: DdlItemModel[] = [];
  ddlDepartment: DdlItemModel[] = [];
  ddlAllDepartment: DdlItemModel[] = [];

  filterDDlModel: FilterDDlModel[] = [];
  selectedAll = -1;
  selectedDptAll = -1;
  AdminDepartment: any;
  userTypeEnum = UserTypeEnum;
  ddlDistrictByOffice: DdlItemModel[] = [];
  ddlProject: DdlItemModel[];
  recordId: number = 0;
  userTypeList: UserTypeModel[] = [];
  submitBtn = true;
  fieldConfig = new userCreationConfigModel();
  isMultipleRights = true;

  constructor(
    private readonly fb: FormBuilder,
    private readonly appComponnet: AppComponent,
    public readonly _commonService: CommonService,
    private readonly _alertService: AlertService,
    private readonly _router: Router,
    private readonly _userService: UserService,
    private readonly _authService: AuthenticationService,
    private readonly _userTypeService: UserTypeService,
    private readonly _route: ActivatedRoute,
    private readonly _dialog: MatDialog
  ) {
    this.model = new UserCreationModel();
    this.loginUserDetail = this._authService.GetCurrentUserDetail().UserViewModel;
    this.dDLList = new DDLModel();
    this.appComponnet.setPageLayout("Add User :", "keyboard_backspace", "Back To List", "user-master");
    const id = this._route.snapshot.params.id;
    if (id) {
      this.GetById(id);
      this.appComponnet.setPageLayout("Update User :", "keyboard_backspace", "Back To List", "user-master");
    } else {
      this.getDistrictByState();
    }
    this.getDDLList();
  }

  ngOnInit() {
    this.formGroupInit();
    this.getDownLevelUserType();
    //this.getDistrictByoffice();
    //this.GetProjectDDLList();
    this.getAdminDepartment();
  }

  formGroupInit() {
    this.UserForm = this.fb.group({
      UserType: ["", Validators.required],
      SSOID: [null, Validators.compose([Validators.required, Validators.maxLength(50)])],
      Title: [null, null],
      Office: [null, null],
      UserName: [null, Validators.required],
      Designation: [null, null],
      Gender: [null, Validators.required],
      Email: [null, Validators.compose([Validators.required, Validators.email])],
      Mobile: [null, Validators.compose([Validators.required, Validators.maxLength(12)])],
      ParentDepartmentCode: [null, Validators.required],
      AdminDepartment: [null],
      Department: [null],
      District: [null, null],
      ProjectCodes: [null],
      StateCodes: [null],
      UserTypeCategory: [null, null]
    });
  }

  onSubmit() {

    this.UserForm.markAllAsTouched();
    console.log(this.UserForm)
    if (this.UserForm.valid) {

      this.submitBtn = false;
      if (!this.isMultipleRights) {
        this.fieldControlChange(true)
      }
      this.RebindField(true);

      this.UserForm.markAllAsTouched();
      if (this.UserForm.valid && this.isUserExistOnSso) {

        if (!this.fieldConfig.IsProjectShow) { this.model.ProjectCode = []; }
        if (!this.fieldConfig.IsAdminDepartmentShow) { this.model.AdminDepartment = []; }
        if (!this.fieldConfig.IsDepartmentShow) { this.model.DepartmentCode = []; }
        if (!this.fieldConfig.IsStateShow) { this.model.StateCodes = []; }
        if (!this.fieldConfig.IsDistrictShow) { this.model.DistrictCode = []; }
        if (!this.fieldConfig.IsOfficeShow) { this.model.OfficeCode = []; }

        if (this.isMultipleRights) {
          this.OpenSetPrimaryDetailPopup();
        }
        else {
          this.model.PrimaryDepartmentCode = this.model.DepartmentCode ? String(this.model.DepartmentCode) : undefined;
          this.model.PrimaryDistrictCode = this.model.DistrictCode ? String(this.model.DistrictCode) : undefined;
          this.model.PrimaryOfficeCode = this.model.OfficeCode ? String(this.model.OfficeCode) : undefined;
          this.saveUser();
        }

      }
      else if (!this.isUserExistOnSso) {
        this.fieldControlChange();
        this.submitBtn = true;
        this._alertService.error(this.model.SSOID + " SSO ID Not exist on sso server!");
      }
      else {
        this.fieldControlChange();
        this.submitBtn = true;
      }
    }
  }

  saveUser() {
   
    this._userService.AddUpdateUserCreation(this.model).subscribe((data) => {
      if (data != null && data.IsSuccess) {
        this._alertService.success(this.recordId == 0 ? GlobalMessagesModel.saveSuccess : GlobalMessagesModel.updateSuccess);
        this._router.navigate(["user-master"]);
      }
      else {
        if (data) {
          this.submitBtn = true;
          this._alertService.error(data.Message);
        }
        else {
          this.submitBtn = true;
          this._alertService.error(data.Message);
          this._alertService.error(this.recordId == 0 ? data.message : GlobalMessagesModel.updateError);
        }
      }
      this.RebindField();
    }, (error) => {
      this.submitBtn = true;
      this._alertService.error(error.message);
    });
  }

  resetModel() {
    this.getDDLList();
    // this.getOfficeList();
    this.setUserTypeValidators();
  }

  getAdminDepartment() {
    this._userService.GetUserAdminDepartmentForUserOfficeCreation(true).subscribe((data) => {
      if (data.IsSuccess) {
        this.ddlAdminDepartment = <DdlItemModel[]>data.Data;
      }
    }, (error) => {
      this._alertService.error(error.message);
    });
  }

  getDownLevelUserType() {
    this._userTypeService.GetDownLevelUserType(this.loginUserDetail.BaseUserType).subscribe((data) => {
      if (data.IsSuccess) {
        this.ddlUserType = [];
        this.userTypeList = <UserTypeModel[]>data.Data;
        this.userTypeList.forEach((element) => {
          this.ddlUserType.push({
            Value: element.UserType,
            Text: element.UserType + " (" + element.UserTypeTitle + ")",
          });
        });
        this.RebindField();
      }
    }, (error) => {
      this._alertService.error(error.message);
    });
  }

  getDDLList() {
    this._commonService.GetAllDDL(AppSetting.DDlKeyForUser).subscribe((data) => {
      if (data.IsSuccess) {
        var ddlList = <DDLModel>data.Data;
        this.dDLList.ddlState = ddlList.ddlState;
        this.dDLList.ddlTitle = ddlList.ddlTitle;
        this.dDLList.ddlGender = ddlList.ddlGender;
        this.dDLList.ddlDesignation = ddlList.ddlDesignation;
        this.dDLList.ddlADLCUserCategory = ddlList.ddlADLCUserCategory;
        this.ddlAllDepartment = ddlList.ddlAllDepartment;// ddlList.ddlDepartment;
      }
    }, (error) => {
      this._alertService.error(error.message);
    });
  }

  getDepartments() {
    if (this.model.AdminDepartment) {
      let adminDeptCodes = this.model.AdminDepartment ? this.model.AdminDepartment : 0;
      this._userService.GetUserDepartmentForUserOfficeCreation(adminDeptCodes.toString(), true).subscribe(data => {
        if (data.IsSuccess) {
          this.ddlDepartment = <DdlItemModel[]>data.Data;
        }
      }, error => {
        this._alertService.error(error.message);
      });
    }
  }

  getOfficeList() {
    if (this.model.DepartmentCode || this.model.DistrictCode) {
      let deptCodes = this.model.DepartmentCode ? this.model.DepartmentCode.toString() : '0';
      let distCodes = this.model.DistrictCode ? this.model.DistrictCode.toString() : '0';
      this._userService.GetUserOfficeForUserCreation(deptCodes, distCodes).subscribe((data) => {
        if (data.IsSuccess) {
          this.dDLList.ddlOffice = <DdlItemModel[]>data.Data;
          if (this.isMultipleRights) {
            var offCodes = this.model.OfficeCode.toString().split(',');
            let selected = [];
            this.dDLList.ddlOffice.forEach(off => {

              if (offCodes.includes(off.Value)) {
                selected.push(off.Value);
              }
            });
            this.model.OfficeCode = selected;
          }
        }
      }, (error) => {
        this._alertService.error(error.message);
      });
    }
  }

  getDistrictByState() {
    if (this.model.StateCodes || this.model.DepartmentCode) {
      let stateCodes = this.model.StateCodes ? this.model.StateCodes.toString() : StateEnum.Rajasthan.toString();
      let deptCodes = (this.model && this.model.DepartmentCode && this.model.DepartmentCode.length > 0) ? this.model.DepartmentCode.toString() : "0";

      this._userService.GetUserDistrictForUserOfficeCreation(stateCodes, deptCodes, true).subscribe((data) => {
        if (data.IsSuccess) {
          this.ddlDistrictByOffice = <DdlItemModel[]>data.Data;
        }
      }, (error) => {
        this._alertService.error(error.message);
      });
    }
  }

  GetProjectDDLList() {
    var deptCodes = this.loginUserDetail.DepartmentCodes;
    if (this.loginUserDetail.BaseUserType == UserTypeEnum.ADM || this.loginUserDetail.BaseUserType == UserTypeEnum.SADM) {
      deptCodes = "0";
    }
    this._commonService.GetDDL(AppSetting.ddlProject, deptCodes).subscribe((data) => {
      if (data.IsSuccess) {
        var ddl = <DDLModel>data.Data;
        this.ddlProject = ddl.ddlProject;
        setTimeout(() => {
          this.dDLList.ddlProject = ddl.ddlProject;
        }, 200);
      }
    }, (error) => {
      this._alertService.error(error.message);
    });
  }

  selectADMAll() {
    setTimeout(() => {
      if (this.selectedDptAll < 0) {
        this.model.AdminDepartment = this.ddlAdminDepartment.map(function (a) {
          return String(a.Value);
        });
        this.selectedDptAll = 1;
      } else {
        this.selectedDptAll = -1;
        this.model.AdminDepartment = [];
      }
    }, 100);
  }

  selectAllDepartment() {
    if (this.selectedAll < 0) {
      this.model.DepartmentCode = this.ddlDepartment.map(function (a) {
        return a.Value;
      });
      this.selectedAll = 1;
    } else {
      this.selectedAll = -1;
      this.model.DepartmentCode = [];
    }
  }

  selectAllProject() {
    if (this.selectedAll < 0) {
      this.model.ProjectCode = this.ddlProject.map(function (a) {
        return a.Value;
      });
      this.selectedAll = 1;
    } else {
      this.selectedAll = -1;
      this.model.ProjectCode = [];
    }
  }

  selectAllState() {
    if (this.model.StateCodes.length != this.dDLList.ddlState.length) {
      this.model.StateCodes = this.dDLList.ddlState.map(function (a) {
        return a.Value;
      });
    }
    else {
      this.model.StateCodes = [];
    }
  }

  selectAllDistrict() {
    if (this.model.DistrictCode.length != this.ddlDistrictByOffice.length) {
      this.model.DistrictCode = this.ddlDistrictByOffice.map(function (a) {
        return a.Value;
      });
    } else {
      this.model.DistrictCode = [];
    }
  }

  selectAllOffice() {
    if (this.model.OfficeCode.length != this.dDLList.ddlOffice.length) {
      this.model.OfficeCode = this.dDLList.ddlOffice.map(function (a) {
        return a.Value;
      });
    } else {
      this.model.OfficeCode = [];
    }
  }

  GetById(id) {
    debugger;
    this._userService.GetB2GUserById(id).subscribe((data) => {
      if (data.IsSuccess) {
        this.model = <UserCreationModel>data.Data;
        this.oldSSOId = this.model.SSOID;
        this.isUserExistOnSso = true;

        if (this.model.ParentDepartmentCode) {
          this.model.ParentDepartmentCode = this.model.ParentDepartmentCode.toString();
        }
        this.RebindField(true);
        if (this.model.AdminDepartment) {
          this.getDepartments();
        }
        if (this.model.DepartmentCode || this.model.OfficeCode || this.model.DistrictCode) {
          this.getOfficeList();
        }
        if (this.model.StateCodes) {
          // this.model.StateCodes = String(this.model.StateCodes);
          this.getDistrictByState();
        } else {
          // this.model.StateCodes = "20";
          this.getDistrictByState();
        }
      }
    }, (error) => {
      this.model = new UserCreationModel();
      this._commonService.alert(error.message, AlertTypeEnum.Error);
    });
  }

  getSSODetail() {
    this.clearSSOFields();
    let userCode = this.model.UserCode ? this.model.UserCode : 0;
    this._userService.IsSSOIdExist(this.model.SSOID, userCode).subscribe((result) => {
      if (result.IsSuccess) {
        const isExist = result.Data != null ? <boolean>result.Data : null;
        if (isExist != null && isExist == true) {
          this.model.SSOID = this.oldSSOId;
          if (this.oldSSOId) { this.isUserExistOnSso = true; }

          this._commonService.ScrollingTop();
          this._alertService.error(result.Message);
        }
        else {
          this.getUserDetailFromSSO(this.model.SSOID);
        }
      }
    }, (error) => {
      this._commonService.ScrollingTop();
      this._alertService.error(GlobalMessagesModel.InternalError);
    });
  }

  getUserDetailFromSSO(ssoId: string) {
    this._userService.GetUserDetailFromSSO(ssoId).subscribe((result) => {
      this._alertService.blank();
      if (result.IsSuccess) {
        this.ssoUserDetail = <SSOUserModel>result.Data;

        this.model.UserName = (this.ssoUserDetail.FirstName ? this.ssoUserDetail.FirstName : '') + " " + (this.ssoUserDetail.LastName ? this.ssoUserDetail.LastName : '');
        this.model.Email = this.ssoUserDetail.MailPersonal;
        this.model.Mobile = this.ssoUserDetail.Mobile;
        this.model.Gender = this.ssoUserDetail.Gender;
        this.model.ProfilePic = this.ssoUserDetail.Photo && this.ssoUserDetail.Photo.length > 0 ? this.ssoUserDetail.Photo : "";
        this.isUserExistOnSso = true;

        if (ssoId.toLowerCase() != this.ssoUserDetail.SSOID.toLowerCase()) {
          this.model.SSOID = this.ssoUserDetail.SSOID;
          this.getSSODetail();
        }
      } else {
        this._commonService.ScrollingTop();
        this._commonService.alert(ssoId + " SSO ID Not Found!", AlertTypeEnum.Error);
      }
    }, (error) => {
      this._commonService.ScrollingTop();
      this._commonService.alert(GlobalMessagesModel.InternalError, AlertTypeEnum.Error);
    });
  }

  clearSSOFields() {
    this.model.UserName = undefined;
    this.model.Email = undefined;
    this.model.Mobile = undefined;
    this.model.Gender = undefined;
    this.model.ProfilePic = "";
    this.isUserExistOnSso = false;
  }

  setUserTypeValidators() {
    const departmentControl = this.UserForm.get("Department");
    const districtControl = this.UserForm.get("District");
    const adminDepartmentControl = this.UserForm.get("AdminDepartment");
    const officeControl = this.UserForm.get("Office");
    const projectControl = this.UserForm.get("ProjectCodes");
    const designationControl = this.UserForm.get("Designation");
    const userTypeCategory = this.UserForm.get("UserTypeCategory");

    projectControl.setValidators(null);
    officeControl.setValidators(null);
    adminDepartmentControl.setValidators(null);
    departmentControl.setValidators(null);
    districtControl.setValidators(null);
    designationControl.setValidators(null);
    userTypeCategory.setValidators(null)

    if (
      this.SelBaseUserType == UserTypeEnum.DREC ||
      this.SelBaseUserType == UserTypeEnum.HOO ||
      this.SelBaseUserType == UserTypeEnum.DOS ||
      this.SelBaseUserType == UserTypeEnum.DLO ||
      this.SelBaseUserType == this.userTypeEnum.HOOADLC ||
      this.SelBaseUserType == this.userTypeEnum.DRECADLC ||
      this.SelBaseUserType == this.userTypeEnum.DOSADLC
    ) {
      departmentControl.setValidators([Validators.required]);
      adminDepartmentControl.setValidators([Validators.required]);
      officeControl.setValidators([Validators.required]);
      districtControl.setValidators([Validators.required]);
    }

    if (this.SelBaseUserType == UserTypeEnum.HOD) {
      departmentControl.setValidators([Validators.required]);
      adminDepartmentControl.setValidators([Validators.required]);
    }

    if (this.SelBaseUserType == UserTypeEnum.PROJ) {
      departmentControl.setValidators([Validators.required]);
      adminDepartmentControl.setValidators([Validators.required]);
      officeControl.setValidators([Validators.required]);
      districtControl.setValidators([Validators.required]);
      //projectControl.setValidators([Validators.required]);
    }

    if (this.SelBaseUserType == UserTypeEnum.VNDR) {
      districtControl.setValidators([Validators.required]);
    }

    if (this.SelBaseUserType == UserTypeEnum.ADM || this.SelBaseUserType == UserTypeEnum.SADM || this.SelBaseUserType == UserTypeEnum.ADLC) {
      departmentControl.setValidators(null);
      adminDepartmentControl.setValidators(null);
      officeControl.setValidators(null);
      districtControl.setValidators(null);
      projectControl.setValidators(null);
    }

    if (this.SelBaseUserType == UserTypeEnum.ADLC) {
      userTypeCategory.setValidators([Validators.required]);
    }

    adminDepartmentControl.updateValueAndValidity();
    departmentControl.updateValueAndValidity();
    districtControl.updateValueAndValidity();
    officeControl.updateValueAndValidity();
    projectControl.updateValueAndValidity();
    designationControl.updateValueAndValidity();
    userTypeCategory.updateValueAndValidity();
  }

  fieldControlChange(isChangeInList = false) {
    if (isChangeInList == true) {
      if (this.fieldConfig.IsAdminDepartmentShow) {
        this.model.AdminDepartment = !isNullOrUndefined(this.model.AdminDepartment) ? this.model.AdminDepartment.toString().split(',') : undefined;
      }
      if (this.fieldConfig.IsDepartmentShow) {
        this.model.DepartmentCode = !isNullOrUndefined(this.model.DepartmentCode) ? this.model.DepartmentCode.toString().split(',') : undefined;
      }
      if (this.fieldConfig.IsDistrictShow) {
        this.model.DistrictCode = !isNullOrUndefined(this.model.DistrictCode) ? this.model.DistrictCode.toString().split(',') : undefined;
      }
      if (this.fieldConfig.IsOfficeShow) {
        this.model.OfficeCode = !isNullOrUndefined(this.model.OfficeCode) ? this.model.OfficeCode.toString().split(',') : undefined;
      }
      if (this.fieldConfig.IsStateShow) {
        this.model.StateCodes = !isNullOrUndefined(this.model.StateCodes) ? this.model.StateCodes.toString().split(',') : ["20"];
      }
      if (this.fieldConfig.IsProjectShow) {
        this.model.ProjectCode = !isNullOrUndefined(this.model.ProjectCode) ? this.model.ProjectCode.toString().split(',') : undefined;
      }
    } else {
      if (this.isMultipleRights == false) {
        if (this.fieldConfig.IsAdminDepartmentShow) {
          this.model.AdminDepartment = !isNullOrUndefined(this.model.AdminDepartment) ? String(this.model.AdminDepartment) : undefined;
        }
        if (this.fieldConfig.IsDepartmentShow) {
          this.model.DepartmentCode = !isNullOrUndefined(this.model.DepartmentCode) ? String(this.model.DepartmentCode) : undefined;
        }
        if (this.fieldConfig.IsDistrictShow) {
          this.model.DistrictCode = !isNullOrUndefined(this.model.DistrictCode) ? String(this.model.DistrictCode) : undefined;
        }
        if (this.fieldConfig.IsOfficeShow) {
          this.model.OfficeCode = !isNullOrUndefined(this.model.OfficeCode) ? String(this.model.OfficeCode) : undefined;
        }
        if (this.fieldConfig.IsStateShow) {
          this.model.StateCodes = !isNullOrUndefined(this.model.StateCodes) ? String(this.model.StateCodes) : "20";
        }
        if (this.fieldConfig.IsProjectShow) {
          this.model.ProjectCode = !isNullOrUndefined(this.model.ProjectCode) ? String(this.model.ProjectCode) : undefined;
        }


      } else {
        if (this.fieldConfig.IsAdminDepartmentShow) {
          this.model.AdminDepartment = !isNullOrUndefined(this.model.AdminDepartment) ? this.model.AdminDepartment.toString().split(',') : undefined;
        }
        if (this.fieldConfig.IsDepartmentShow) {
          this.model.DepartmentCode = !isNullOrUndefined(this.model.DepartmentCode) ? this.model.DepartmentCode.toString().split(',') : undefined;
        }
        if (this.fieldConfig.IsDistrictShow) {
          this.model.DistrictCode = !isNullOrUndefined(this.model.DistrictCode) ? this.model.DistrictCode.toString().split(',') : undefined;
        }
        if (this.fieldConfig.IsOfficeShow) {
          this.model.OfficeCode = !isNullOrUndefined(this.model.OfficeCode) ? this.model.OfficeCode.toString().split(',') : undefined;
        }
        if (this.fieldConfig.IsStateShow) {
          this.model.StateCodes = !isNullOrUndefined(this.model.StateCodes) ? this.model.StateCodes.toString().split(',') : ["20"];
        }
        if (this.fieldConfig.IsProjectShow) {
          this.model.ProjectCode = !isNullOrUndefined(this.model.ProjectCode) ? this.model.ProjectCode.toString().split(',') : undefined;
        }

      }
    }


    // console.clear();

  }

  RebindField(isRecont = false) {
    //this.isMultipleRights = true;
    this.fieldConfig.IsAdminDepartmentShow = true;
    this.fieldConfig.IsDepartmentShow = true;
    this.fieldConfig.IsStateShow = true;
    this.fieldConfig.IsDistrictShow = true;
    this.fieldConfig.IsOfficeShow = true;
    this.fieldConfig.IsProjectShow = true;
    this.fieldConfig.IsUserTypeCategoryShow = true;
    this.fieldConfig.IsDesignationShow = true;

    const departmentControl = this.UserForm.get("Department");
    const districtControl = this.UserForm.get("District");
    const adminDepartmentControl = this.UserForm.get("AdminDepartment");
    const officeControl = this.UserForm.get("Office");
    const projectControl = this.UserForm.get("ProjectCodes");
    const designationControl = this.UserForm.get("Designation");
    const userTypeCategoryControl = this.UserForm.get("UserTypeCategory");
    const StateCodesControl = this.UserForm.get("StateCodes");

    // projectControl.setValidators([Validators.required]);
    officeControl.setValidators([Validators.required]);
    adminDepartmentControl.setValidators([Validators.required]);
    departmentControl.setValidators([Validators.required]);
    districtControl.setValidators([Validators.required]);
    designationControl.setValidators([Validators.required]);
    userTypeCategoryControl.setValidators([Validators.required]);
    StateCodesControl.setValidators([Validators.required]);
    let UserType = !isNullOrUndefined(this.SelBaseUserType) && this.SelBaseUserType != "" ? this.SelBaseUserType : this.model.UserType;

    if (UserType == UserTypeEnum.ADLC) {
      this.isMultipleRights = true;
      switch (this.model.UserTypeCategory) {
        case this.userTypeEnum.HOD:


          this.fieldConfig.IsProjectShow = false;
          projectControl.setValidators(null);

          this.fieldConfig.IsOfficeShow = false;
          officeControl.setValidators(null);

          this.fieldConfig.IsDistrictShow = false;
          districtControl.setValidators(null);

          this.fieldConfig.IsStateShow = false;
          StateCodesControl.setValidators(null);


          break;
        case this.userTypeEnum.DLO:

          this.fieldConfig.IsProjectShow = false;
          projectControl.setValidators(null);

          this.fieldConfig.IsOfficeShow = false;
          officeControl.setValidators(null);

          this.fieldConfig.IsAdminDepartmentShow = false;
          adminDepartmentControl.setValidators(null);

          this.fieldConfig.IsDepartmentShow = false;
          departmentControl.setValidators(null);




          break;
        default:

          this.fieldConfig.IsProjectShow = false;
          projectControl.setValidators(null);

          this.fieldConfig.IsOfficeShow = false;
          officeControl.setValidators(null);

          this.fieldConfig.IsAdminDepartmentShow = false;
          adminDepartmentControl.setValidators(null);

          this.fieldConfig.IsDepartmentShow = false;
          departmentControl.setValidators(null);

          this.fieldConfig.IsDistrictShow = false;
          districtControl.setValidators(null);


          this.fieldConfig.IsStateShow = false;
          StateCodesControl.setValidators(null);

          break;
      }
    }
    else if (UserType == UserTypeEnum.ADM || UserType == UserTypeEnum.SADM) {
      this.isMultipleRights = false;
      this.fieldConfig.IsProjectShow = false;
      projectControl.setValidators(null);


      this.fieldConfig.IsOfficeShow = false;
      officeControl.setValidators(null);


      this.fieldConfig.IsAdminDepartmentShow = false;
      adminDepartmentControl.setValidators(null);


      this.fieldConfig.IsDepartmentShow = false;
      departmentControl.setValidators(null);


      this.fieldConfig.IsDistrictShow = false;
      districtControl.setValidators(null);

      this.fieldConfig.IsUserTypeCategoryShow = false;
      userTypeCategoryControl.setValidators(null);

      this.fieldConfig.IsStateShow = false;
      StateCodesControl.setValidators(null);



    }
    else if (UserType == UserTypeEnum.SADM) {
      this.isMultipleRights = false;
      this.fieldConfig.IsProjectShow = false;
      projectControl.setValidators(null);

      this.fieldConfig.IsOfficeShow = false;
      officeControl.setValidators(null);

      this.fieldConfig.IsAdminDepartmentShow = false;
      adminDepartmentControl.setValidators(null);

      this.fieldConfig.IsDepartmentShow = false;
      departmentControl.setValidators(null);

      this.fieldConfig.IsDistrictShow = false;
      districtControl.setValidators(null);

      this.fieldConfig.IsUserTypeCategoryShow = false;
      userTypeCategoryControl.setValidators(null);

      this.fieldConfig.IsStateShow = false;
      StateCodesControl.setValidators(null);


    }
    else if (UserType == UserTypeEnum.DREC) {
      this.isMultipleRights = false;

      this.fieldConfig.IsProjectShow = false;
      projectControl.setValidators(null);

      this.fieldConfig.IsUserTypeCategoryShow = false;
      userTypeCategoryControl.setValidators(null);
    }
    else if (UserType == UserTypeEnum.DRECADLC) {
      this.isMultipleRights = false;

      this.fieldConfig.IsProjectShow = false;
      projectControl.setValidators(null);

    }
    else if (UserType == UserTypeEnum.HOD) {
      this.isMultipleRights = true;

      this.fieldConfig.IsProjectShow = false;
      projectControl.setValidators(null);

      this.fieldConfig.IsOfficeShow = false;
      officeControl.setValidators(null);

      this.fieldConfig.IsDistrictShow = false;
      districtControl.setValidators(null);

      this.fieldConfig.IsUserTypeCategoryShow = false;
      userTypeCategoryControl.setValidators(null);

      this.fieldConfig.IsStateShow = false;
      StateCodesControl.setValidators(null);
    }
    else if (UserType == UserTypeEnum.VNDR) {
      this.isMultipleRights = false;
      this.fieldConfig.IsProjectShow = false;
      projectControl.setValidators(null);

      this.fieldConfig.IsOfficeShow = false;
      officeControl.setValidators(null);

      this.fieldConfig.IsAdminDepartmentShow = false;
      adminDepartmentControl.setValidators(null);

      this.fieldConfig.IsDepartmentShow = false;
      departmentControl.setValidators(null);

      this.fieldConfig.IsDistrictShow = false;
      districtControl.setValidators(null);

      this.fieldConfig.IsDesignationShow = false;
      designationControl.setValidators(null);


      this.fieldConfig.IsUserTypeCategoryShow = false;
      userTypeCategoryControl.setValidators(null);

      this.fieldConfig.IsStateShow = false;
      StateCodesControl.setValidators(null);


    }
    else if (UserType == UserTypeEnum.VNDRUSER) {

      this.fieldConfig.IsProjectShow = false;
      projectControl.setValidators(null);

      this.fieldConfig.IsOfficeShow = false;
      officeControl.setValidators(null);

      this.fieldConfig.IsAdminDepartmentShow = false;
      adminDepartmentControl.setValidators(null);

      this.fieldConfig.IsDepartmentShow = false;
      departmentControl.setValidators(null);

      this.fieldConfig.IsDistrictShow = false;
      districtControl.setValidators(null);

      this.fieldConfig.IsDesignationShow = false;
      designationControl.setValidators(null);

      this.fieldConfig.IsUserTypeCategoryShow = false;
      userTypeCategoryControl.setValidators(null);

      this.fieldConfig.IsStateShow = false;
      StateCodesControl.setValidators(null);
    }
    else if (UserType == UserTypeEnum.HOO) {
      this.isMultipleRights = true;
      this.fieldConfig.IsProjectShow = false;
      projectControl.setValidators(null);

      this.fieldConfig.IsUserTypeCategoryShow = false;
      userTypeCategoryControl.setValidators(null);

      this.fieldConfig.IsStateShow = false;
      StateCodesControl.setValidators(null);

      this.getDistrictByState(); ////for bind default district
    }
    else if (UserType == UserTypeEnum.HOOADLC) {
      this.isMultipleRights = true;
      this.fieldConfig.IsProjectShow = false;
      projectControl.setValidators(null);

      this.fieldConfig.IsStateShow = false;
      StateCodesControl.setValidators(null);

      this.getDistrictByState(); ////for bind default district
    }
    else if (UserType == UserTypeEnum.DLO) {
      this.isMultipleRights = true;
      this.fieldConfig.IsProjectShow = false;
      projectControl.setValidators(null);

      this.fieldConfig.IsUserTypeCategoryShow = false;
      userTypeCategoryControl.setValidators(null);

      this.fieldConfig.IsStateShow = false;
      StateCodesControl.setValidators(null);
      this.getDistrictByState(); ////for bind default district
    }
    else if (UserType == UserTypeEnum.DOS) {
      this.isMultipleRights = true;
      this.fieldConfig.IsProjectShow = false;
      projectControl.setValidators(null);

      this.fieldConfig.IsUserTypeCategoryShow = false;
      userTypeCategoryControl.setValidators(null);
    }
    else if (UserType == UserTypeEnum.DOSADLC) {
      this.isMultipleRights = false;
      this.fieldConfig.IsProjectShow = false;
      projectControl.setValidators(null);
    }
    else if (UserType == UserTypeEnum.PROJ) {
      this.isMultipleRights = true;
      this.fieldConfig.IsOfficeShow = false;
      officeControl.setValidators(null);

      this.fieldConfig.IsDistrictShow = false;
      districtControl.setValidators(null);

      this.fieldConfig.IsUserTypeCategoryShow = false;
      userTypeCategoryControl.setValidators(null);

      this.fieldConfig.IsStateShow = false;
      StateCodesControl.setValidators(null);
      this.getDistrictByState(); ////for bind default district
    }

    adminDepartmentControl.updateValueAndValidity();
    departmentControl.updateValueAndValidity();
    districtControl.updateValueAndValidity();
    officeControl.updateValueAndValidity();
    projectControl.updateValueAndValidity();
    designationControl.updateValueAndValidity();
    userTypeCategoryControl.updateValueAndValidity();
    StateCodesControl.updateValueAndValidity();

    if (isRecont == false) {
      this.fieldControlChange();
    }
  }

  isRequiredField(field: string) {
    const form_field = this.UserForm.get(field);
    if (!form_field.validator) {
      return false;
    }
    const validator = form_field.validator({} as AbstractControl);
    return (validator && validator.required);
  }

  get SelBaseUserType(): string {
    let baseUserType = '';
    let userTypeDetail = this.userTypeList.filter(t => t.UserType == this.model.UserType);
    if (userTypeDetail != null && userTypeDetail.length > 0)
      baseUserType = userTypeDetail[0].BaseUserType;
    return baseUserType;
  }

  get SelBaseUserTypeCode(): number {
    let baseUserTypeCode: number;
    let userTypeDetail = this.userTypeList.filter(t => t.UserType == this.model.UserType);
    if (userTypeDetail != null && userTypeDetail.length > 0)
      baseUserTypeCode = Number(userTypeDetail[0].BaseUserTypeCode);
    return baseUserTypeCode;
  }

  OpenSetPrimaryDetailPopup() {
    const _dialogRef = this._dialog.open(SetUserPrimaryDetailDialogComponent, {
      width: "500px",
      height: "320px",
      data: { model: this.model, fieldConfig: this.fieldConfig },
      disableClose: true
    });
    _dialogRef.afterClosed().subscribe((res: any) => {
      if (res) {
        this.model.PrimaryDepartmentCode = res.PrimaryDepartmentCode;
        this.model.PrimaryDistrictCode = res.PrimaryDistrictCode;
        this.model.PrimaryOfficeCode = res.PrimaryOfficeCode;
        this.saveUser();
      }
    });
  }

}
