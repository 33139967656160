import { Injectable } from '@angular/core';
import { AppSetting } from '../model/appsetting.model';
import { IndexModel } from '../model/common.model';
import { ComplaintListModel, ComplaintActionModel, CompliantSearchModel } from '../model/compliant.model';
import { AuthenticationService } from './authentication.service';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root'
})
export class ComplaintService {
  constructor(private readonly _baseService: BaseService, private readonly _authService: AuthenticationService) { }

  GetList(model: CompliantSearchModel) {
    model.LoginUserCode = this._authService.UserCode;
    const result = this._baseService.post(AppSetting.ComplaintList, model);
    return result;
  }
  ExportComplaintData(model: CompliantSearchModel) {
    model.LoginUserCode = this._authService.UserCode;
    const result = this._baseService.post(AppSetting.ExportComplaintList, model);
    return result;
  }

  Detail(id: number) {
    return this._baseService.get(AppSetting.ComplaintDetailUrl + id);
  }

  Add(model: ComplaintListModel) {
    const result = this._baseService.post(AppSetting.ComplaintAdd, model);
    return result;
  }

  AddAction(model: ComplaintActionModel) {
    const result = this._baseService.post(AppSetting.ComplaintAddAction, model);
    return result;
  }

}
