import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { GlobalMessagesModel } from 'src/app/shared/model/common-messages.model';
import { AlertService } from 'src/app/shared/service/alert.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { PermissionMasterModel } from "src/app/shared/model/master/permission-master.model";
import { PermissionMasterService } from "src/app/shared/service/master/permission-master.service";

@Component({
  selector: 'app-set-page-password-dialog',
  templateUrl: './set-page-password-dialog.component.html',
  styleUrls: ['./set-page-password-dialog.component.scss']
})

export class SetPagePasswordDialogComponent implements OnInit {
  frmGrp: FormGroup;
  model: PermissionMasterModel = new PermissionMasterModel();
  permissionCode = 0;

  constructor(
    private readonly fb: FormBuilder,
    private readonly _alertService: AlertService,
    private readonly _permissionMasterService: PermissionMasterService,
    public _dialogRef: MatDialogRef<SetPagePasswordDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    if (data) {
      if (data.Id) {
        this.permissionCode = data.Id;
      }
    }
    this.model.HasExtraAuthentication = false;
  }

  ngOnInit() {
    this.formGroupInit();
    if (this.permissionCode > 0) {
      this.getDetail();
    }
  }

  formGroupInit() {
    this.frmGrp = this.fb.group({
      HasExtraAuthentication: [null],
      Password: [null]
    });
  }

  getDetail() {
    this._permissionMasterService.Detail(this.permissionCode).subscribe(
      data => {
        if (data.IsSuccess) {
          this.model = <PermissionMasterModel>data.Data;
          this.updatePasswordValidation();
        }
      },
      error => {
      });
  }

  updatePasswordValidation() {
    if (this.model.HasExtraAuthentication) {
      this.frmGrp.get("Password").setValidators(Validators.compose([Validators.required, Validators.maxLength(100)]));
    }
    else {
      this.frmGrp.get("Password").clearValidators();
    }
    this.frmGrp.get("Password").updateValueAndValidity();
  }

  onSaveClick() {
    if (!this.model.HasExtraAuthentication) {
      this.model.Password = '';
    }

    this.frmGrp.markAllAsTouched();
    if (this.frmGrp.valid) {
      this._permissionMasterService.SavePageAuthenticationDetail(this.model.PermissionCode, this.model.HasExtraAuthentication, this.model.Password).subscribe(
        data => {
          if (data.IsSuccess) {
            this._alertService.success(GlobalMessagesModel.PageAuthenticationSaveSuccess, true);
            this._dialogRef.close(true);
          }
        },
        error => {
          this._alertService.error(GlobalMessagesModel.saveError);
        }
      );
    }
  }

  closeDialog(): void {
    this._dialogRef.close(false);
  }

}