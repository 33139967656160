
import { ActivatedRoute } from '@angular/router';
import { AlertService } from 'src/app/shared/service/alert.service';
import { DepartmentMasterViewModel } from './../../../../shared/model/master/department-master.model';
import { Component, OnInit } from '@angular/core';
import { AppComponent } from 'src/app/app.component';
import { DepartmentMaster } from 'src/app/shared/service/master/department-master.service';

@Component({
  selector: 'app-detail-department-master-new',
  templateUrl: './detail-department-master-new.component.html',
  styleUrls: ['./detail-department-master-new.component.scss']
})

export class DetailDepartmentMasterNewComponent implements OnInit {

  model: DepartmentMasterViewModel;
  title: string;

  constructor(private _parentApi: AppComponent,
    private readonly _departmentService: DepartmentMaster,
    private readonly _alertService: AlertService,
    private _route: ActivatedRoute) {
    this.model = new DepartmentMasterViewModel();
    const id = this._route.snapshot.params.id;

    if (id) {
      this.model.DepartmentId = id;
      this.GetById();
      this._parentApi.setPageLayout(
        "Detail Department Master :",
        "keyboard_backspace",
        "Back To List",
        "master/department-master"
      );
      this.title = "Detail";
    }
    //else {
    //   this._parentApi.setPageLayout(
    //     "Add Department Master :",
    //     "keyboard_backspace",
    //     "Back To List",
    //     "master/department-master"
    //   );
    //   this.title = "Add";
    // }
  }

  ngOnInit() {
  }

  GetById() {

    this._departmentService.GetById(this.model.DepartmentId).subscribe(
      data => {

        if (data.IsSuccess) {
          this.model = <DepartmentMasterViewModel>data.Data;
        } else {
          this._alertService.error(data.Message);
        }
      },
      error => {
        this._alertService.error(error.message);
      }
    );
  }

  viewAttachment(url: string) {
    if (url != '') {

      var newWin = open("'url'", "_blank");
      newWin.document.write('<iframe title="PDF" src="' + url + '"  height="99%" width="100%"></iframe>');

    }
  }


}
