import { IndexModel } from "../../model/common.model";
import { BaseService } from "../base.service";
import { CommunicationDefaultFieldConfigPostModel, CommunicationDefaultFieldPostModel, CommunicationTypeFieldsMasterModel, DefaultFieldSortOrderPostModel } from '../../model/master/communication-type-fields-master.model';
import { AppSetting } from "../../model/appsetting.model";
import { Injectable } from "@angular/core";
import { Dictionary } from "../../model/dictionary.model";
@Injectable({
    providedIn: 'root'
})

export class CommunicationTypeFieldsMasterService {
    constructor(private readonly _baseService: BaseService,) { }

    GetList(id: string, model: IndexModel) {
        return this._baseService.post(AppSetting.CommunicationTypeFieldsMasterListUrl + id, model);
    }

    AddUpdate(id: number, model: CommunicationTypeFieldsMasterModel) {
        return this._baseService.post(AppSetting.CommunicationTypeFieldsMasterAddUpdateUrl, model);
    }

    Detail(id: number) {
        return this._baseService.get(AppSetting.CommunicationTypeFieldsMasterDetailUrl + id);
    }

    ChangeDeleteStatus(id: number) {
        return this._baseService.get(AppSetting.CommunicationTypeFieldsMasterDeleteStatusChangeUrl + id);
    }
    ChangeHiddenStatus(id: number) {
        return this._baseService.get(AppSetting.CommunicationTypeFieldsMasterHiddenStatusChangeUrl + id);
    }


    ChangeActiveStatus(id: number) {
        return this._baseService.get(AppSetting.CommunicationTypeFieldsMasterActiveStatusChangeUrl + id);
    }

    IsCommunicationTypeFieldsMasterExist(model: CommunicationTypeFieldsMasterModel) {
        return this._baseService.post(AppSetting.IsCommunicationTypeFieldsMasterExistUrl, model);
    }

    GetFieldsForCommunication(CommunicationTypeEnum: string, CommunicationCode: number = 0) {
        var param = new Dictionary<any>();
        param.Add("CommunicationTypeEnum", CommunicationTypeEnum)
        param.Add("CommunicationCode", CommunicationCode)
        return this._baseService.get(AppSetting.FieldsForCommunicationListUrl, param);
    }



    GetDynamicFieldsByTypeCode(id :number){
        var param = new Dictionary<any>();
        param.Add('id', id)
       
        return this._baseService.get(AppSetting.DynamicFieldsByTypeCodeListUrl, param);
    }

    GetFieldValueListByFieldCode(fieldCode: number) {
        var param = new Dictionary<any>();
        param.Add('id', fieldCode)
        return this._baseService.get(AppSetting.FieldValueMasterListByFieldCodeUrl, param)
    }

    GetDefaultFieldsForCommunication(CommunicationTypeCode: number = 0, CommunicationTypeEnum: string = "") {
        var param = new Dictionary<any>();
        param.Add('CommunicationTypeCode', CommunicationTypeCode)
        param.Add('CommunicationTypeEnum', CommunicationTypeEnum)


        return this._baseService.get(AppSetting.DefaultFieldsForCommunicationListUrl, param)
    }
    ChangeDefaultFieldDeleteStatus(id: number) {
        var param = new Dictionary<any>();
        param.Add('id', id)
        return this._baseService.get(AppSetting.CommunicationTypeDefaultFieldsMasterDeleteStatusChangeUrl, param);
    }


    AddUpdateDefaultFields(model: CommunicationDefaultFieldPostModel[]) {
        return this._baseService.post(AppSetting.CommunicationTypeDefaultFieldsMasterAddUpdateUrl, model);
    }


    GetddlDefaultFields(id: number) {
        var param = new Dictionary<any>();
        param.Add('id', id)
        return this._baseService.get(AppSetting.GetddlDefaultFields, param);
    }


    GetFieldsValidations(fieldTypeCode: number, fieldCode: number = 0) {
        var param = new Dictionary<any>();
        param.Add('fieldTypeCode', fieldTypeCode)
        param.Add('fieldCode', fieldCode)
        return this._baseService.get(AppSetting.GetCommunicationTypeFieldsValidations, param);
    }

    SetDefaultFieldSortOrder(model: DefaultFieldSortOrderPostModel) {
        return this._baseService.post(AppSetting.SetDefaultFieldSortOrderUrl, model);
    }

    ConfigCommunicationAssignField(model: CommunicationDefaultFieldConfigPostModel) {
        return this._baseService.post(AppSetting.ConfigureAssignFieldUrl, model);
    }

}