import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { AuthenticationService } from 'src/app/shared/service/authentication.service';
import { GlobalMessagesModel } from 'src/app/shared/model/common-messages.model';
import { AlertService } from 'src/app/shared/service/alert.service';
import { MAT_DIALOG_DATA, MatDialogRef, MatRadioChange } from '@angular/material';
import { CommonService } from 'src/app/shared/service/common.service';
import { DdlItemModel, DDLModel } from '../../../../shared/model/commonddl.model';
import { AppSetting } from 'src/app/shared/model/appsetting.model';
import { CommunicationTypeFieldsMasterModel } from 'src/app/shared/model/master/communication-type-fields-master.model';
import { CommunicationTypeFieldsMasterService } from 'src/app/shared/service/master/communication-type-fields-master.service';
import { UserViewModel } from 'src/app/shared/model/user.model';
@Component({
  selector: 'app-map-fields',
  templateUrl: './map-fields.component.html',
  styleUrls: ['./map-fields.component.scss']
})
export class MapFieldsComponent implements OnInit {
  //#region <<Variable>>
  loginUserDetail: UserViewModel;
  loginUserCode: number;
  frmGrp: FormGroup;
  model: CommunicationTypeFieldsMasterModel = new CommunicationTypeFieldsMasterModel();
  fileValidationMsg: string;
  ddlList: DDLModel = new DDLModel();
  disabled: boolean = false;
  RecordId: number = 0;
  //#endregion

  constructor(
    private readonly fb: FormBuilder,
    public readonly _commonService: CommonService,
    private readonly _alertService: AlertService,
    public _dialogRef: MatDialogRef<MapFieldsComponent>,
    private readonly _communicationTypeFieldsMasterService: CommunicationTypeFieldsMasterService,
    private readonly _router: Router,
    private readonly _authService: AuthenticationService,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.loginUserDetail = this._authService.GetCurrentUserDetail().UserViewModel;    
    if (data) {
      if (data.Id) {
        this.model.CommunicationTypeCode = data.Id;
      }
    }
    this.GetDDLList();
  }

  ngOnInit() {
    this.formGroupInit();
  }

  formGroupInit() {
    this.frmGrp = this.fb.group({
      FieldLabel: [null, Validators.required],
      FieldTypeCode: [null, Validators.required],
      ExistingMasterCode: [null]
    });
  }

  GetDDLList() {
    this._commonService.GetAllDDL(AppSetting.CommunicationTypeFieldsDropdownKeys).subscribe(
      data => {
        if (data.IsSuccess) {
          this.ddlList = <DDLModel>data.Data;
        }
      },
      error => {
        this._alertService.error(error.message);
      }
    );
  }

  disableMaster(event) {
    if (event.value == 3) {
      this.disabled = true;
    }
    else {
      this.disabled = false;
    }
  }

  saveClick() {    
    this.frmGrp.markAllAsTouched();
    if (this.frmGrp.valid) {

      if (this.RecordId == 0) {
        this.model.CreatedBy = this.loginUserDetail.UserCode;
      }
      else {
        this.model.ModifiedBy = this.loginUserDetail.UserCode;
      }

      this._communicationTypeFieldsMasterService.AddUpdate(this.RecordId, this.model).subscribe(
        data => {
          if (data) {
            if (data.IsSuccess) {              
              this._alertService.success(this.RecordId == 0 ? GlobalMessagesModel.saveSuccess : GlobalMessagesModel.updateSuccess);
              this._router.navigate(["master/communication-type"]);
            } else {
              this._commonService.ScrollingTop();
              this._alertService.error(this.RecordId == 0 ? GlobalMessagesModel.saveError : GlobalMessagesModel.updateError);
            }
          } else {
            this._commonService.ScrollingTop();
            this._alertService.error(GlobalMessagesModel.InternalError);
          }
        },
        error => {
          this._commonService.ScrollingTop();
          this._alertService.error(GlobalMessagesModel.InternalError);
        }
      );
    }
  }

  CloseDialog(): void {
    this._dialogRef.close();
  }
}