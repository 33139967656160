
import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { IndexModel } from '../model/common.model';
import { AppSetting } from '../model/appsetting.model';
import { CommunicationClearModel, CommunicationModel, CommunicationReportSearchViewModel, CommunicationSearchModel } from '../model/communication.model';
import { ChangeCommunicationDepartmentOfficeModel, CommunicationSearchPostModel } from '../model/change-communication-department-office.model';
import { CommunicationTypeEnum } from '../enum/fixed-values.enum';
import { Dictionary } from '../model/dictionary.model';
import { CommunicationTransactionPostModel } from '../model/communication-transaction.model';
import { GlobalFilterSearchModel } from '../model/Global-communication-search.model';
import { DashboardDrilDownModel } from '../model/dashboard.model';

@Injectable({
    providedIn: 'root'
})

export class CommunicationService {

    constructor(private readonly _baseService: BaseService) { }

    GetList(workOrderCode: number, loginUserCode: number, search: string, status: string, communicationType: string, model: IndexModel) {
        return this._baseService.post(AppSetting.CommunicationListUrl + "?communicationType=" + communicationType + "&workOrderCode=" + workOrderCode + "&loginUserCode=" + loginUserCode + "&search=" + search + "&status=" + status, model);
    }

    AddUpdate(model: CommunicationTransactionPostModel) {
        return this._baseService.post(AppSetting.CommunicationAddUpdateUrl, model);
    }

    Detail(id: number, isAssigned: boolean = true) {
        var param = new Dictionary<any>();
        param.Add("id", id)
        param.Add("isBase64", true);
        param.Add("isAssigned", isAssigned);
        return this._baseService.get(AppSetting.CommunicationDetailUrl, param);
    }

    ChangeActiveStatus(id: number) {
        return this._baseService.get(AppSetting.CommunicationActiveStatusChangeUrl + id);
    }

    ChangeDeleteStatus(id: number) {
        return this._baseService.get(AppSetting.CommunicationDeleteStatusChangeUrl + id);
    }

    // ChangeCommunicationOffice(id: number, officeCode: number, userCode: number,userMapCode : number,purpose:string,model:CommunicationOfficeLookUpDocumentModel[]=[]) {
    //     return this._baseService.get(AppSetting.ChangeCommunicationOfficeUrl, + id + "?officeCode=" + officeCode + "&userCode=" + userCode+"&userMapCode="+userMapCode+"&purpose="+purpose+"&model="+model);
    // }
    ChangeCommunicationOffice(model: ChangeCommunicationDepartmentOfficeModel) {
        return this._baseService.post(AppSetting.ChangeCommunicationOfficeUrl, model);
    }

    GetNewOldCommunicationList(model: CommunicationReportSearchViewModel) {
        return this._baseService.post(AppSetting.CommunicationNewOrOldListUrl, model);
    }
    ExportCommunicationData(model: CommunicationReportSearchViewModel) {
        return this._baseService.post(AppSetting.ExportCommunicationReportUrl, model);
    }

    GetMooveDepartmentOfficeCommunicationList(loginUserCode: number, model: IndexModel) {
        return this._baseService.post(AppSetting.CommunicationMoveDepartmentAndOfficeListUrl + "?loginUserCode=" + loginUserCode, model);
    }

    GetForwardedCommunicationReport(loginUserCode: number, status: string, model: IndexModel) {
        return this._baseService.post(AppSetting.CommunicationForwardListUrl + "?loginUserCode=" + loginUserCode + "&status=" + status, model);
    }

    GetAgeWiseCommunicationList(loginUserCode: number, ageComunication: number, model: IndexModel) {
        return this._baseService.post(AppSetting.CommunicationAgeWiseListUrl + "?loginUserCode=" + loginUserCode + "&ageComunication=" + ageComunication, model);
    }

    GetAllBySearch(model: CommunicationSearchPostModel) {
        return this._baseService.post(AppSetting.CommunicationSearchListUrl, model);

    }

    PullBackInternalTransfer(loginUserCode: number, id: number) {
        return this._baseService.post(AppSetting.PullBackInternalTransferUrl + "?id=" + id + "&loginUserCode=" + loginUserCode, null);
    }

    GetAllGlobalListSearch(model: GlobalFilterSearchModel) {
        return this._baseService.post(AppSetting.AllGlobalListSearchUrl, model);
    }

    GetCommunicationListBySearch(model: CommunicationSearchModel) {
        return this._baseService.post(AppSetting.CommunicationListByAdvanceSearchUrl, model);
    }

    GetPreviouseCommunicationList(CommunicationCode: number) {
        var param = new Dictionary<any>();
        param.Add("CommunicationCode", CommunicationCode)
        return this._baseService.get(AppSetting.PreviousCommunicationListUrl, param);
    }



    GetCommunicationDrilldownList(model: DashboardDrilDownModel) {
        return this._baseService.post(AppSetting.GetCommunicationDrilldownListUrl, model);
    }

    getCommunicationCCOfficeList(communicationCode) {

    }

    clearCommunication() {
        return this._baseService.post(AppSetting.CommunicationClear, null);
    }

    CommunicationClear(communicationClearmodel: CommunicationClearModel) {
        return this._baseService.post(AppSetting.CommunicationClear, communicationClearmodel);

    }


}
