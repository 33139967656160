import { Component, OnInit, Inject } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { AngularEditorConfig } from "@kolkov/angular-editor";
import { StatusEnum } from "src/app/shared/enum/fixed-values.enum";
import { ActionModel, ActionDocumentModel } from "src/app/shared/model/action.model";
import { AppSetting } from "src/app/shared/model/appsetting.model";
import { GlobalMessagesModel } from "src/app/shared/model/common-messages.model";
import { ActionService } from "src/app/shared/service/action.service";
import { AlertService } from "src/app/shared/service/alert.service";
import { AuthenticationService } from "src/app/shared/service/authentication.service";
import { CommonService } from "src/app/shared/service/common.service";

@Component({
  selector: 'app-response-back-dialog',
  templateUrl: './response-back-dialog.component.html',
  styleUrls: ['./response-back-dialog.component.scss']
})

export class ResponseBackDialogComponent implements OnInit {
  frmGrp: FormGroup;
  editorConfig = AppSetting.editorConfig as AngularEditorConfig;
  model = new ActionModel();
  fileValidationMsg: string;
  tempDocumentList: ActionDocumentModel[] = [];
  isDisableSubmitBtn=false;
  constructor(
    private readonly fb: FormBuilder,
    private readonly _alertService: AlertService,
    private readonly _actionService: ActionService,
    private readonly _authService: AuthenticationService,
    public _commonService:CommonService,
    public _dialogRef: MatDialogRef<ResponseBackDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    if (data) {
      if (data.Id) {
        this.model.CommunicationCode = data.Id;
      }
    }
  }

  ngOnInit() {
    this.formGroupInit();
  }

  formGroupInit() {
    this.frmGrp = this.fb.group({
      Reply: [null, Validators.required]
    });
  }

  closeDialog(): void {
    this._dialogRef.close(false);
  }

  handleFileInput(files: FileList) {
    this.tempDocumentList = [];
    for (let index = 0; index < files.length; index++) {
      if (files.item(index).type.match("application/pdf")) {
        if (files.item(index).size < AppSetting.FileSizeLimit.PDF) {
          const reader = new FileReader();
          reader.onload = (e: any) => {
            this.tempDocumentList.push(new ActionDocumentModel());
            this.tempDocumentList[index].DocName = <string>reader.result;
            this.tempDocumentList[index].DocPath = <string>reader.result;

            this.tempDocumentList[index].DocName = files[index].name;
            this.tempDocumentList[index].Extension = files[index].name.split(".")[1];
            this.tempDocumentList[index].DocPath = <string>reader.result;
          };
          reader.readAsDataURL(files[index]);
        }
        else {
          this.tempDocumentList = [];
          this.fileValidationMsg = AppSetting.FileSizeLimit.PDFErrorMSG;
          return;
        }
      }
      else {
        this.tempDocumentList = [];
        this.fileValidationMsg = "Only *pdf";
        return;
      }
    }
    this.fileValidationMsg = "";
  }

  downloadPdf(Url, name) {
    const linkSource = Url;
    const downloadLink = document.createElement("a");
    const fileName = name;
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.target = "blank";
    downloadLink.click();
  }

  deleteDocument(index: number) {
    this.tempDocumentList.splice(index, 1);
  }

  saveClick() {
    this.isDisableSubmitBtn=true;
    this.frmGrp.markAllAsTouched();
    if (this.frmGrp.valid) {
      this.model.ActionCode = 0;
      this.model.CreatedBy = this._authService.UserCode;
      this.model.DocumentList = this.tempDocumentList;
      this.model.Status = StatusEnum.RESPONSE_BACK;
      this.model.ReasonCode = 0;

      this._actionService.AddUpdate(this.model).subscribe(
        data => {
          if (data) {
            if (data.IsSuccess) {
              this.isDisableSubmitBtn=false;
              this._alertService.success(GlobalMessagesModel.ResponseBackCommunicationSuccess);
              this._dialogRef.close(true);
            }
            else {
              this.isDisableSubmitBtn=false;
              this._alertService.error(GlobalMessagesModel.ResponseBackCommunicationFailed);
            }
          }
          else {
            this.isDisableSubmitBtn=false;
            this._alertService.error(GlobalMessagesModel.InternalError);
          }
        },
        error => {
          this.isDisableSubmitBtn=false;
          this._alertService.error(error.message);
        }
      );
    }else{
      this.isDisableSubmitBtn=false;
    }
  }

}
