export class ActionModel {
    ActionCode: number;
    CommunicationCode: number;
    Status: string;
    Reply: string;
    ReasonCode: number;
    Reason: string;
    CreatedBy: number | null;
    CreatedOn: Date | string;
    UserName: string;
    UserType: string;
    UserTypeTitle: string;
    DocumentList: ActionDocumentModel[] = [];
    FromUser: string;
    ToUser: string;
    ToUserType: string;
    ToUserTypeTitle: string;
    FromBaseUserType: string;
    FromBaseUserTypeTitle: string;
    ToBaseUserType: string;
    ToBaseUserTypeTitle: string;
}

export class ActionDocumentModel {
    DocumentCode: number;
    ActionCode: number;
    DocName: string;
    DocPath: string;
    Extension: string;
}

