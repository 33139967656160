import { Validator } from './../../../../shared/model/dynamic-field-config.model';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, NgModel, FormControl } from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import { AuthenticationService } from 'src/app/shared/service/authentication.service';
import { GlobalMessagesModel } from 'src/app/shared/model/common-messages.model';
import { AppComponent } from 'src/app/app.component';
import { AlertService } from 'src/app/shared/service/alert.service';
import { CommonService } from 'src/app/shared/service/common.service';
import { UserViewModel } from 'src/app/shared/model/user.model';
import { isNullOrUndefined } from 'util';
import { CommunicationTypeAttachmentViewModel, CommunicationTypeModel } from 'src/app/shared/model/master/communication-type.model';
import { CommunicationTypeService } from 'src/app/shared/service/master/communication-type.service';
import { DdlItemModel, DDLModel } from 'src/app/shared/model/commonddl.model';
import { AppSetting } from 'src/app/shared/model/appsetting.model';
import { UserTypeEnum } from 'src/app/shared/enum/fixed-values.enum';

@Component({
  selector: 'app-add-update-communication-type',
  templateUrl: './add-update-communication-type.component.html',
  styleUrls: ['./add-update-communication-type.component.scss']
})

export class AddUpdateCommunicationTypeComponent implements OnInit {

  //#region <<Variable>>
  loginUserDetail: UserViewModel;
  fromGroup: FormGroup;
  model: CommunicationTypeModel;
  RecordId: number = 0;
  loginUserCode: number;
  oldCommunicationType: string;
  ddlList: DDLModel;
  IsStorage: boolean;
  attachmentTypeModel: CommunicationTypeAttachmentViewModel[] = [];
  userTypeEnum = UserTypeEnum;
  submitBtn = true;
  //#endregion

  //#region <<Constructor>>
  constructor(
    private readonly fb: FormBuilder,
    private readonly appComponnet: AppComponent,
    public readonly _commonService: CommonService,
    private readonly _alertService: AlertService,
    private readonly _router: Router,
    private _route: ActivatedRoute,
    private readonly _communicationTypeService: CommunicationTypeService,
    private readonly _authService: AuthenticationService,

  ) {
    if (!isNullOrUndefined(this._route.snapshot.params.id)) {
      this.RecordId = this._route.snapshot.params.id;
    }
    var pageHeading = (this.RecordId == 0 ? "Add" : "Update") + " Communication Type :";
    this.appComponnet.setPageLayout(pageHeading, "keyboard_backspace", "Back To List", "master/communication-type");
    this.model = new CommunicationTypeModel();
    this.ddlList = new DDLModel();
    this.getDropdownList();
    this.loginUserDetail = this._authService.GetCurrentUserDetail().UserViewModel;
  }
  //#endregion

  ngOnInit() {
    this.formGroupInit();
    if (this.RecordId != 0) {
      this.getDetail();
    }
    setTimeout(() => {
      this.GetAttachmentTypeList();
    }, 500);
  }

  getDropdownList() {
    this._commonService.GetAllDDL(AppSetting.CommunicationTypeDropdownKeys).subscribe(
      data => {
        if (data.IsSuccess) {
          this.ddlList = <DDLModel>data.Data;

          if (this.ddlList) {

            this.ddlList.ddlUserType = this.ddlList.ddlUserType.filter(i => i.Value == UserTypeEnum.DREC || i.Value == UserTypeEnum.VNDR || i.Value == UserTypeEnum.HOD);

            // this.ddlList.ddlUserType = this.ddlList.ddlUserType.filter(function (item) {
            //   return (item.Value == this.userTypeEnum.DREC || this.userTypeEnum.VNDR);
            // });
            this.ddlList.ddlUserType.map(item => {
              item.Text = (item.Value == this.userTypeEnum.DREC ? ("Department to Vendor") : (item.Value == this.userTypeEnum.VNDR ? "Vendor to Department" : "Department to Department"));
            });

            if (this.loginUserDetail.BaseUserType != UserTypeEnum.VNDR.toString() && this.loginUserDetail.BaseUserType != UserTypeEnum.VNDRUSER.toString()) {
              let newItem = new DdlItemModel();
              newItem.Text = "Internal to Department";
              newItem.Value = this.userTypeEnum.HOD;
              this.ddlList.ddlUserType.push(newItem);
            }
          }

        }
      },
      error => {
        this._alertService.error(error.message);
      }
    );
  }

  // getDropdownList() {
  //   this._commonService.GetAllDDL(AppSetting.CommunicationTypeDropdownKeys).subscribe(
  //     data => {
  //       if (data.IsSuccess) {
  //         this.ddlList = <DDLModel>data.Data;
  //         if (this.loginUserDetail.BaseUserType == UserTypeEnum.ADM || this.loginUserDetail.BaseUserType == UserTypeEnum.SADM) {
  //           if (this.ddlList) {
  //             this.ddlList.ddlUserType = this.ddlList.ddlUserType.filter(i => i.Value == UserTypeEnum.DREC || i.Value == UserTypeEnum.VNDR || i.Value == UserTypeEnum.HOD || i.Value == UserTypeEnum.INTDPT);

  //             this.ddlList.ddlUserType.map(item => {
  //               if (item.Value == this.userTypeEnum.DREC) {
  //                 item.Text = "Department to Vendor";
  //               } else if (item.Value == this.userTypeEnum.VNDR) {
  //                 item.Text = "Vendor to Department";
  //               } else {
  //                 item.Text = "Department to Department";
  //               }
  //             });

  //             if (this.loginUserDetail.BaseUserType != UserTypeEnum.VNDR.toString() && this.loginUserDetail.BaseUserType != UserTypeEnum.VNDRUSER.toString()) {
  //               let newItem = new DdlItemModel();
  //               newItem.Text = "Internal to Department";
  //               newItem.Value = this.userTypeEnum.DREC;
  //               this.ddlList.ddlUserType.push(newItem);
  //             }

  //           }
  //         }
  //       }
  //     },
  //     error => {
  //       this._alertService.error(error.message);
  //     }
  //   );
  // }


  GetAttachmentTypeList() {
    this._communicationTypeService.GetAttachementTypeListByCommunicationType(this.RecordId).subscribe(response => {
      if (response.IsSuccess) {

        this.attachmentTypeModel = <CommunicationTypeAttachmentViewModel[]>response.Data;
      }
    });
  }

  formGroupInit() {
    this.fromGroup = this.fb.group({
      CommunicationType: [null, Validators.compose([Validators.required, Validators.maxLength(100)])],
      CommunicationTypeHindi: [null, Validators.compose([Validators.required, Validators.maxLength(100)])],
      AttachmentTypeCodeList: [null],
      CommunicationTypeForUserTypeCode: [null, Validators.required],
      SLANotificationDays: [null, Validators.compose([Validators.required, this.compareIsMinFromNotificationValidator.bind(this)])],
      SLADays: [null, Validators.compose([Validators.required, this.compareIsMinFromDayValidator.bind(this)])],
      Abbreviation: [null, Validators.required]
    });
  }

  getDetail() {
    this._communicationTypeService.Detail(this.RecordId).subscribe(
      data => {
        if (data.IsSuccess) {
          this.model = <CommunicationTypeModel>data.Data;
          this.oldCommunicationType = this.model.CommunicationType;
          this.model.AttachmentTypeCodeList = this.model.AttachmentTypeCodes == null ? null : this.model.AttachmentTypeCodes.split(',');
          this.model.CommunicationTypeForUserTypeCode = this.model.CommunicationTypeForUserTypeCode;
        }
      },
      error => {
        this._alertService.error(error.message);
      }
    );
  }

  saveClick() {
    this.submitBtn = false;
    this.fromGroup.markAllAsTouched();
    if (this.fromGroup.valid) {
      if (this.RecordId == 0) {
        this.model.CreatedBy = this.loginUserDetail.UserCode;
      }
      else {
        this.model.ModifiedBy = this.loginUserDetail.UserCode;
      }
      this.model.AttachmentTypeListModel = this.attachmentTypeModel.filter(i => i.IsSelected == true);
      this._communicationTypeService.AddUpdate(this.RecordId, this.model).subscribe(
        data => {
          if (data) {
            if (data.IsSuccess) {

              this._alertService.success(this.RecordId == 0 ? GlobalMessagesModel.saveSuccess : GlobalMessagesModel.updateSuccess);
              if (this.RecordId > 0) {
                this._router.navigate(["master/communication-type"]);
              } else {
                this._router.navigate(["master/communication-type/communication-fields/" + data.Data])
              }
            } else {
              this.submitBtn = true;
              this._commonService.ScrollingTop();
              this._alertService.error(this.RecordId == 0 ? GlobalMessagesModel.saveError : GlobalMessagesModel.updateError);
            }
          } else {
            this.submitBtn = true;
            this._commonService.ScrollingTop();
            this._alertService.error(GlobalMessagesModel.InternalError);
          }
        },
        error => {
          this.submitBtn = true;
          this._commonService.ScrollingTop();
          this._alertService.error(GlobalMessagesModel.InternalError);
        }
      );
    } else {
      this.submitBtn = true;
    }
  }

  IsCommunicationTypeExist(Event) {
    if (this.oldCommunicationType != Event.currentTarget.value) {
      this._communicationTypeService.IsCommunicationTypeExist(Event.currentTarget.value).subscribe(result => {
        if (result.IsSuccess) {
          let UserName = <boolean>result.Data
          if (UserName == true) {
            this.model.CommunicationType = null;
            this._alertService.error("Communication Type is already exist!");
          }
        }
      }, error => {
        this._commonService.ScrollingTop();
        this._alertService.error(error.message);
      });
    }
  }

  ngOnDestroy() {
    // this.IsStorage = true;
    // sessionStorage.setItem("IsCommunicationTypeStorage", JSON.stringify(this.IsStorage));
  }

  compareIsMinFromNotificationValidator(control: FormControl): any {

    if (control.pristine) {
      return null;
    }

    const minValue = control.parent.value.SLADays;
    const MaxValue = control.value;
    if (minValue != undefined && MaxValue != undefined && Number(minValue) < Number(MaxValue)) {
      return { compareNotificationValidationError: true }
    }

    return null;
  }

  compareIsMinFromDayValidator(control: FormControl): any {

    if (control.pristine) {
      return null;
    }
    const minValue = control.value;
    const maxValue = control.parent.value.SLANotificationDays;
    if (minValue != undefined && maxValue != undefined && Number(minValue) < Number(maxValue)) {
      return { compareDaysValidationError: true }
    }


    return null;
  }

}
