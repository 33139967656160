import { Component, OnInit } from '@angular/core';
import { FormControl, Validators, FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { AppComponent } from 'src/app/app.component';
import { AppSetting } from 'src/app/shared/model/appsetting.model';
import { DDLModel } from 'src/app/shared/model/commonddl.model';
import { NotificationEmailTemplatesModel } from 'src/app/shared/model/master/email-template.model';
import { AlertService } from 'src/app/shared/service/alert.service';
import { CommonService } from 'src/app/shared/service/common.service';
import { EmailTemplateService } from 'src/app/shared/service/master/email-template.service';
import { AngularEditorConfig } from '@kolkov/angular-editor';

@Component({
  selector: 'app-add-update-email',
  templateUrl: './add-update-email.component.html',
  styleUrls: ['./add-update-email.component.scss']
})
export class AddUpdateEmailComponent implements OnInit {

  //#region angular editor

  editorConfig  = AppSetting.editorConfig as AngularEditorConfig ;

  //#endregion

  dDLList: DDLModel;
  model: NotificationEmailTemplatesModel;

  TypeCode = new FormControl('', [Validators.required]);
  Subject = new FormControl('', [Validators.required]);
  EmailContent = new FormControl('', [Validators.required]);
  KeyVariable: string[] = [];

 constructor(
    public readonly _commonService: CommonService,
    private readonly _alertService: AlertService,
    private _parentApi: AppComponent,
    private readonly _router: Router,
    private _route: ActivatedRoute,
    private readonly formBuilder: FormBuilder,
    private readonly _emailTemplateService: EmailTemplateService,
  ) {
    this._parentApi.setPageLayout(
      'Notification Email Templates :',
      'keyboard_backspace',
      'Back to List',
      'master/notification-email-templates'
    );
    this.getEmailNotificationContentKey();
    this.model = new NotificationEmailTemplatesModel();
    this.model.EmailContent = '';
    this.model.Id = this._route.snapshot.params.id;
    if (this.model.Id) {
      this.GetByID();
    }
  }

  ngOnInit() {
    this.GetDDLList();
  }

  saveClick() {    
    this.TypeCode.markAsTouched();
    this.Subject.markAsTouched();
    this.EmailContent.markAsTouched();
    if (this.TypeCode.valid && this.Subject.valid && this.EmailContent.valid) {
      if (this.model.Id) {

        this._emailTemplateService.Update(this.model).subscribe(data => {
          if (data.IsSuccess) {
            this._router.navigate(['master/notification-email-templates']);
            this._alertService.success(data.Message);
          } else {
            this._alertService.error(data.Message);
          }
        }, error => {
          this._alertService.error(error.message);
        });
      } else {
        this._emailTemplateService.AddNew(this.model).subscribe(data => {
          if (data.IsSuccess) {
            this._alertService.success(data.Message);
            this._router.navigate(['master/notification-email-templates']);
          } else {
            this._alertService.error(data.Message);
          }
        }, error => {
          this._alertService.error(error.message);
        });
      }
    }


  }

  GetByID() {
    this._emailTemplateService.GetById(this.model.Id).subscribe(
      data => {
        if (data.IsSuccess) {
          this.model = <NotificationEmailTemplatesModel>data.Data;
          if (this.model.TypeCode) {
            this.model.TypeCode = String(this.model.TypeCode);
          }
        } else {
          this._alertService.error(data.Message);
        }
      },
      error => {
        this._alertService.error(error.message);
      }
    );
  }

  GetDDLList() {
    this._commonService.GetAllDDL(AppSetting.NotificationTemplateTypeKey).subscribe(
      data => {
        if (data.IsSuccess) {
          this.dDLList = <DDLModel>data.Data;
        }
      },
      error => {
        this._alertService.error(error.message);
      }
    );
  }

  onAppendText(text) {

    if (this.model.EmailContent == undefined) {
      this.model.EmailContent = "";
    }
    this.model.EmailContent += text + " ";
  }

  getEmailNotificationContentKey() {
    this._emailTemplateService.GetEmailNotificationContentKey()
      .subscribe(
        data => {
          if (data.IsSuccess) {
            this.KeyVariable = <string[]>data.Data;
          }
        },
        error => {
          this._alertService.error(error.message);
        }
      );
  }
}
