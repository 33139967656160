import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AppComponent } from 'src/app/app.component';
import { ReasonTypeModel } from 'src/app/shared/model/master/reason-type.model';
import { StatusMasterModel } from 'src/app/shared/model/master/status-master.model';
import { AlertService } from 'src/app/shared/service/alert.service';
import { AuthenticationService } from 'src/app/shared/service/authentication.service';
import { StatusMasterService } from 'src/app/shared/service/master/status-master.service';

@Component({
  selector: 'app-detail-status-master',
  templateUrl: './detail-status-master.component.html',
  styleUrls: ['./detail-status-master.component.scss']
})
export class DetailStatusMasterComponent implements OnInit {

  model: StatusMasterModel;
  RecordId: number;
  IsStorage: boolean;

  constructor(private readonly _appComponent: AppComponent,
    private readonly _router: Router,
    private _route: ActivatedRoute,
    private readonly _statusMasterService: StatusMasterService,
    private readonly _alertService: AlertService,
    private readonly _authService: AuthenticationService
  ) {
    this.RecordId = this._route.snapshot.params.id;
    this.model = new StatusMasterModel();
    this._appComponent.setPageLayout(
      "Status Detail :",
      "keyboard_backspace",
      "Back To List",
      "master/status-master"
    );
  }

  ngOnInit() {
    this.getDetail();
  }

  getDetail() {
    this._statusMasterService.Detail(this.RecordId).subscribe(
      data => {
        if (data.IsSuccess) {
          this.model = <StatusMasterModel>data.Data;
        }
      },
      error => {
        this._alertService.error(error.message);
      }
    );
  }

  ngOnDestroy() {
    this.IsStorage = true;
    sessionStorage.setItem("IsStatusStorage", JSON.stringify(this.IsStorage));
  }

}
