import { UserTypeEnum } from "src/app/shared/enum/fixed-values.enum";
import { isNullOrUndefined } from "util";
import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Router, ActivatedRoute } from '@angular/router';
import { DdlItemModel } from 'src/app/shared/model/commonddl.model';
import { UserTypeModel } from 'src/app/shared/model/user-type.model';
import { AlertService } from 'src/app/shared/service/alert.service';
import { AuthenticationService } from 'src/app/shared/service/authentication.service';
import { CommonService } from 'src/app/shared/service/common.service';
import { UserTypeService } from 'src/app/shared/service/user-type.service';
import { UserService } from 'src/app/shared/service/user.service';
import { UserViewModel } from "src/app/shared/model/user.model";

@Component({
  selector: 'app-add-update-user-type-master',
  templateUrl: './add-update-user-type-master.component.html',
  styleUrls: ['./add-update-user-type-master.component.scss']
})
export class AddUpdateUserTypeMasterComponent implements OnInit {

  id: number;
  model = new UserTypeModel();
  fieldShow = false;
  UserTitle = new FormControl('', [Validators.required]);
  ParentUserType = new FormControl('', [Validators.required]);
  UserType = new FormControl('', null);
  loginUser: UserViewModel;
  title: string;
  userTypeEnum = UserTypeEnum;
  // dDLList: DDLModel;
  ddlUserType: DdlItemModel[] = [];
  submitBtn = true;
  constructor(
    private readonly _alertService: AlertService,
    private readonly _router: Router,
    private _route: ActivatedRoute,
    private _userService: UserService,
    private readonly _userTypeService: UserTypeService,
    private readonly _authService: AuthenticationService,

    public readonly _dialogRef: MatDialogRef<AddUpdateUserTypeMasterComponent>,
    public readonly _commonService: CommonService,
    @Inject(MAT_DIALOG_DATA) public data: any) {

    if (data) {
      this.id = data;
      this.GetById();
      this.title = "Update";
    } else {
      this.model = new UserTypeModel();
      this.title = "Add";
    }


  }

  ngOnInit() {
    this.loginUser = this._authService.GetCurrentUserDetail().UserViewModel;

    this.getDownLevelUserType();
  }

    getDownLevelUserType() {

      this._userTypeService
        .GetDownLevelUserType(this.loginUser.UserType, true)
        .subscribe(
          (data) => {
            if (data.IsSuccess) {
              var usertypes = <UserTypeModel[]>data.Data;
              if (usertypes) {
                usertypes = usertypes;
                usertypes.forEach((element) => {
                  this.ddlUserType.push({
                    Value: element.UserType,
                    Text: element.UserTypeTitle,
                  });
                });
              }

            }
          },
          (error) => {
            this._alertService.error(error.message);
          }
        );
    }

  GetById() {
    this._userTypeService.GetById(this.id).subscribe(
      data => {
        if (
          (data.IsSuccess)
        ) {
          this.model = <UserTypeModel>data.Data;
          this.model.ParrentUserType = isNullOrUndefined(this.model.ParrentUserType) ? undefined : String(this.model.ParrentUserType);
        }
      },
      error => {
        this.model = new UserTypeModel();
        this._alertService.error(error.message);
      }
    );
  }

  SaveClick() {
    this.submitBtn=false;
    this.UserTitle.markAsTouched();
    this.ParentUserType.markAsTouched();
    this.UserType.markAsTouched();
    if (this.UserTitle.valid && this.ParentUserType.valid && this.UserType.valid) {
      if (this.model.UserTypeCode) {

        this._userTypeService.Edit(this.model.UserTypeCode, this.model).subscribe(data => {
          if (data.IsSuccess) {
            this._alertService.success(data.Message);
            this._dialogRef.close(true);
          } else {
            this.submitBtn=true;
            this._alertService.error(data.Message);

          }
        }, error => {
          this.submitBtn=true;
          this._alertService.error(error.message);
        });
      } else {
        this._userTypeService.Add(this.model).subscribe(data => {
          if (data.IsSuccess) {
            this._alertService.success(data.Message);
            this._dialogRef.close(true);
          } else {
            this.submitBtn=true;
            this._alertService.error(data.Message);
          }
        }, error => {
          this.submitBtn=true;
          this._alertService.error(error.message);
        });
      }
    }else{
      this.submitBtn=true;
    }

  }

  onNoClick(): void {

    this._dialogRef.close();
  }

}
