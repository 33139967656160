import { UserAdditionalUserTypeModel } from './../../../shared/model/user-permission.model';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { DDLModel } from 'src/app/shared/model/commonddl.model';
import { UserAdditionalUserTypePostModel } from 'src/app/shared/model/user-permission.model';
import { UserTypeModel } from 'src/app/shared/model/user-type.model';
import { AlertService } from 'src/app/shared/service/alert.service';
import { AuthenticationService } from 'src/app/shared/service/authentication.service';
import { UserTypeService } from 'src/app/shared/service/user-type.service';
import { UserService } from '../../../shared/service/user.service';
import { DdlItemModel } from '../../../shared/model/commonddl.model';

@Component({
  selector: 'app-add-update-additional-user-type-dialog',
  templateUrl: './add-update-additional-user-type-dialog.component.html',
  styleUrls: ['./add-update-additional-user-type-dialog.component.scss']
})
export class AddUpdateAdditionalUserTypeDialogComponent implements OnInit {
  UserForm: FormGroup;
  model = new UserAdditionalUserTypePostModel();
  dDLList: DDLModel = new DDLModel();
  userCode: number;
  constructor(private readonly _dialog: MatDialog,
    private readonly _authService: AuthenticationService,
    private readonly _userService: UserService,
    private readonly fb: FormBuilder, private readonly _alertService: AlertService,
    private readonly _userTypeService: UserTypeService,
    public _dialogRef: MatDialogRef<AddUpdateAdditionalUserTypeDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.formGroupInit();
  }

  ngOnInit() {
    if (this.data.Id) {
      this.userCode = this.data.Id;
      this.getDetail();
    } else {
      this.closeDialog(false);
    }
  }



  formGroupInit() {
    this.UserForm = this.fb.group({
      //UserType: ["", Validators.required],
      UserType: [""],
    });
  }

  getDetail() {
    this._userService.GetAdditionalUserTypeById(this.userCode)
      .subscribe(res => {
        let data = res.Data as UserAdditionalUserTypeModel;
        this.dDLList.ddlUserType = data.UserType.map(x => {
          let itm = new DdlItemModel();
          itm.Text = x.DisplayUserTypeTitle;
          itm.Value = x.UserTypeCode
          return itm;
        })
        this.model.UserTypeCode = data.UserType.filter(x => x.IsSelected).map(x => x.UserTypeCode.toString());
      })

  }
  selectAllUserType() {

    if (this.model.UserTypeCode.length != this.dDLList.ddlUserType.length) {
      this.model.UserTypeCode = [];
      this.model.UserTypeCode = this.dDLList.ddlUserType.map(function (a) {
        return String(a.Value);
      });

    } else {

      this.model.UserTypeCode = [];
    }
  }

  onSubmit() {
    this.UserForm.markAllAsTouched();
    if (this.UserForm.valid) {
      this.model.UserCode = this.userCode;
      this._userService.AddUpdateAdditionalType(this.model).subscribe(res => {
        if (res.IsSuccess) {

          this.closeDialog(true);


        }
      })
    }
  }

  closeDialog(IsSucess): void {
    this._dialogRef.close(IsSucess);
  }
}
