export class WorkOrderCategoryModel {
    CategoryCode: number;
    Category: string;
    CategoryHindi: string;
    IsActive: boolean;
    IsDeleted: boolean;
    CreatedBy: number | null;
    CreatedOn: Date | null;
    ModifiedBy: number | null;
    ModifiedOn: Date | null;   
}
