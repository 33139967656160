import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource, MatDialog, MatSort, MatPaginator } from "@angular/material";
import { PermissionModel, SearchModel } from 'src/app/shared/model/common.model';
import { ColumnHeaderModel, DDLModel, FilterDDlModel } from 'src/app/shared/model/commonddl.model';
import { AppComponent } from 'src/app/app.component';
import { AlertService } from 'src/app/shared/service/alert.service';
import { CommonService } from 'src/app/shared/service/common.service';
import { ConfirmationDialogComponent } from 'src/app/confirmation-dialog/confirmation-dialog.component';
import { GlobalMessagesModel } from 'src/app/shared/model/common-messages.model';
import { AppSetting } from 'src/app/shared/model/appsetting.model';
import { WorkOrderSearchModel, WorkOrderViewModel } from 'src/app/shared/model/work-order.model';
import { WorkOrderService } from 'src/app/shared/service/work-order.service';
import { AuthenticationService } from 'src/app/shared/service/authentication.service';
import { UserTypeEnum } from 'src/app/shared/enum/fixed-values.enum';
import { isNullOrUndefined } from 'util';
import { ClearWorkOrderPopupComponent } from './clear-work-order-popup/clear-work-order-popup.component';

@Component({
  selector: 'app-work-order',
  templateUrl: './work-order.component.html',
  styleUrls: ['./work-order.component.scss']
})

export class WorkOrderComponent implements OnInit {

  model: WorkOrderViewModel[];
  searchModel = this._commonService.modelSetGet(new WorkOrderSearchModel()) as WorkOrderSearchModel;
  dataSource: any;
  totalRecords: number;
  loginUserCode: number;
  loginBaseUserType: string;
  userTypeEnum = UserTypeEnum;
  defaultValue: SearchModel;
  displayedColumns: string[] = [];
  columnsToDisplay: string[] = [];
  public isFormVisible: boolean = false;

  ViewdisplayedColumns: ColumnHeaderModel[] = [
    { Value: "Subject", Text: "Subject" },
    { Value: "ProjectName", Text: "Project" },
    { Value: "OfficeName", Text: "Office" },
  ];

  ddlList = new DDLModel();
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  Permission: PermissionModel = this._authService.GetPagePermission("/work-order", "work-order/add", "work-order/detail", "work-order/update", "work-order/delete");
  isShowFilter = false;

  constructor(
    private readonly appComponent: AppComponent,
    private readonly _alertService: AlertService,
    public readonly _workOrderService: WorkOrderService,
    private readonly _dialog: MatDialog,
    public readonly _commonService: CommonService,
    private readonly _authService: AuthenticationService,
    private readonly _dialogRef: MatDialog,
  ) {

    this.loginUserCode = this._authService.UserCode;
    this.loginBaseUserType = this._authService.BaseUserType;

    this.displayedColumns = ["index", "Subject", "ProjectName", "OfficeName", "OrderDate", "ContactPersonName", "WorkOrderStartDate", "CreatedOn", "ModifiedOn", "Status", "Action"];
    if (this.loginBaseUserType == this.userTypeEnum.ADM || this.loginBaseUserType == this.userTypeEnum.SADM) {
      this.displayedColumns.push("IsOICAccepted");
    }
    this.columnsToDisplay = this.displayedColumns.slice();

    if (_workOrderService.canUserTypeCreateWO()) {
      this.Permission.AddPageAccess ? this.appComponent.setPageLayout("Work Order :", "add", "Create", "work-order/add") : this.appComponent.setPageLayout("Work Order :", "", "", "");
    }
    else {
      this.appComponent.setPageLayout("Work Order :", "", "", "");
    }
    this.setIsFormVisible();
  }

  ngOnInit() {
    this.GetDDLList();
    this.searchModel.IndexModel.OrderByAsc = 0;
    this.searchModel.IndexModel.OrderBy = "CreatedOn";
    this.getList();
  }

  getList() {
    this._workOrderService.GetFilterList(this._commonService.modelSetGet(this.searchModel, true)).subscribe(data => {
      if (data.IsSuccess) {
        this.model = <WorkOrderViewModel[]>data.Data.Data;

        this.dataSource = new MatTableDataSource<WorkOrderViewModel>(this.model);
        this.totalRecords = data.Data.TotalRecords;
        if (!this.searchModel.IndexModel.IsPostBack) {
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
        }
      } else {
        this._alertService.error(data.Message);
      }
    }, error => {
      this._alertService.error(error.message);
    });
  }

  updateActiveStatus(id) {
    const dialogRef = this._dialog.open(ConfirmationDialogComponent, { width: "50%", data: GlobalMessagesModel.ConfirmStatusChanged });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this._workOrderService.ChangeActiveStatus(id).subscribe(data => {
          if (data.IsSuccess) {
            this._alertService.success(data.Message);
            this.getList();
          }
        }, error => {
          this._alertService.error(error.message);
        });
      }
    });
  }

  updateDeleteStatus(id) {
    const dialogRef = this._dialog.open(ConfirmationDialogComponent, { width: "50%", data: GlobalMessagesModel.ConfirmDeleted });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this._workOrderService.ChangeDeleteStatus(id).subscribe(data => {
          if (data.IsSuccess) {
            this._alertService.success(data.Message);
            this._commonService.ScrollingTop();
            this.getList();
          }
        }, error => {
          this._alertService.error(error.message);
        });
      }
    });
  }

  UpdateOICAccepted(id) {
    const dialogRef = this._dialog.open(ConfirmationDialogComponent, { width: "50%", data: GlobalMessagesModel.ConfirmStatusChanged });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this._workOrderService.ChangeOICAccepted(id).subscribe(
          data => {
            if (data.IsSuccess) {

              this._alertService.success(data.Message);
              this.getList();
            }
          },
          error => {
            this._alertService.error(error.message);
          }
        );
      }
    });
  }

  sortData(event) {
    this.searchModel.IndexModel.OrderBy = event.active;
    this.searchModel.IndexModel.OrderByAsc = event.direction == AppSetting.orderByDscAsc ? AppSetting.orderByAsc : AppSetting.orderByDsc;
    this.searchModel.IndexModel.IsPostBack = true;
    this.getList();
  }

  onPaginateChange(event) {
    this.searchModel.IndexModel.Page = event.pageIndex + 1;
    this.searchModel.IndexModel.PageSize = event.pageSize;
    this.searchModel.IndexModel.IsPostBack = true;
    this.getList();
  }

  ClearSearch() {
    this.searchModel = new WorkOrderSearchModel();
    this.GetDDLList();
    this.getList();
  }

  onDepartmentChange() {
    this.GetProjectByDepartmentOffice();
  }

  GetProjectByDepartmentOffice() {
    if (!isNullOrUndefined(this.searchModel.DepartmentCode)) {
      this._commonService.GetDDL(AppSetting.ddlOfficeProject, this.searchModel.DepartmentCode, 0, this.loginUserCode)
        .subscribe(
          (data) => {
            if (data.IsSuccess) {
              var ddl = <DDLModel>data.Data;
              this.ddlList.ddlProject = ddl.ddlOfficeProject;

            }
          },
          (error) => {
            this._alertService.error(error.message);
          }
        );
    } else {
      this.ddlList.ddlProject = null;
    }
  }

  GetDDLList() {
    this._commonService.GetAllDDL(AppSetting.WorkOrderFilterDropdownKeys, Number(this.loginBaseUserType), this.loginUserCode).subscribe(data => {
      if (data.IsSuccess) {
        this.ddlList = <DDLModel>data.Data;
      }
    }, error => {
      this._alertService.error(error.message);
    });
  }

  getFilterdDDL(filterFor, value, filterFrom) {
    const item = new FilterDDlModel();
    item.FilterFor = filterFor;
    item.Value = value;
    item.FilterFrom = filterFrom;
    let filterDDlModel: any[] = [];
    filterDDlModel.push(item);
    this._commonService.GetFilterdDDL(filterDDlModel).subscribe(
      data => {
        if (data.IsSuccess) {

          if (item.FilterFor == "ddlWorkOrderSubCategory") {
            this.ddlList.ddlWorkOrderSubCategory = data.Data.ddlWorkOrderSubCategory;
          }
          else if (item.FilterFor == "ddlProject") {
            this.ddlList.ddlProject = data.Data.ddlProject;

          }
        }
      },
      error => {
        this._alertService.error(error.message);
      }
    );
  }
  public openDialog() {
   const dialogRef = this._dialogRef.open(ClearWorkOrderPopupComponent);
  dialogRef.afterClosed().subscribe(result => {
      if (result)
        console.log('hi')
    });
  }

  private setIsFormVisible() {
    let userDetail = this._authService.LoggedInUserDetail.UserViewModel;
    if (userDetail.UserType == UserTypeEnum.SADM) {
      this.isFormVisible = true
    }
  }


}
