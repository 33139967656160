import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { AppComponent } from "src/app/app.component";
import { AlertService } from "src/app/shared/service/alert.service";
import { VendorModel } from "src/app/shared/model/vendor.model";
import { VendorService } from "src/app/shared/service/vendor.service";
import { AppSetting } from "src/app/shared/model/appsetting.model";
import { AuthenticationService } from "src/app/shared/service/authentication.service";
import { CommonService } from "src/app/shared/service/common.service";
import { UrlService } from "src/app/shared/service/url.service";

@Component({
  selector: "app-vendor-detail",
  templateUrl: "./vendor-detail.component.html",
  styleUrls: ["./vendor-detail.component.scss"],
})

export class VendorDetailComponent implements OnInit {

  private previousUrl: string = "";

  model: VendorModel;
  RecordId: number = 0;

  constructor(
    private readonly _appComponent: AppComponent,
    private _route: ActivatedRoute,
    private _router: Router,
    private readonly _vendorService: VendorService,
    public readonly _commonService: CommonService,
    private readonly _alertService: AlertService,
    private readonly _authService: AuthenticationService,
    private readonly _urlService: UrlService
  ) {
    this.RecordId = this._route.snapshot.params.id;
    this._urlService.previousUrl$.subscribe(url => this.previousUrl = url);
    this.model = new VendorModel();


  }

  ngOnInit() {

    if (this._router.url.includes("vendor-permission")) {

      setTimeout(() => {
        this._appComponent.setPageLayout(
          "Vendor Detail :",
          "keyboard_backspace",
          "Back To List",
          this.previousUrl
        );
      })

    }
    else {
      setTimeout(() => {
        this._appComponent.setPageLayout(
          "Vendor Detail :",
          "keyboard_backspace",
          "Back To List",
          "vendor"
        );
      })

    }

    if (this.RecordId != 0) {
      this.getDetail();
    }
  }

  getDetail() {
    let backUrl = `vendor/bank-account/${this.RecordId}`;

    this._vendorService.Detail(this.RecordId).subscribe(
      (data) => {
        if (data.IsSuccess) {
          this.model = <VendorModel>data.Data;
          if (this.model.UserCode == this._authService.UserCode) {
            this._appComponent.setPageLayout(
              "My Profile :",
              "add",
              "Bank Detail",
              backUrl,
              false,
              false,
              "Edit Profile",
              "vendor/update-vendor-profile/" + this.RecordId
            );
          }
          if (this.model.PANNo) {
            this.model.PANNo = this._commonService.convertText(
              this.model.PANNo,
              false
            );
            this.model.PANNo = this._commonService.transform(this.model.PANNo);
          }
          if (this.model.AadharNo) {
            this.model.AadharNo = this._commonService.convertText(
              this.model.AadharNo,
              false
            );
            this.model.AadharNo = this._commonService.transform(this.model.AadharNo)
          }
        }
      }, (error) => {
        this._alertService.error(error.message);
      });
  }

  CallPrint(printContent) {
    this._commonService.printHTMLContent(printContent);
  }

  downloadPdf(url: string, name: string) {
    const downloadLink = document.createElement("a");
    downloadLink.href = url;
    downloadLink.download = name;
    downloadLink.target = "blank";
    downloadLink.click();
  }

}
