import { InternalTransferCommunicationHistoryViewModel } from './internal-transfer-communication-history.model';
import { InvoiceCommunicationOfficeHistoryViewModel } from './outbox.model';
import { ActionModel } from './action.model';

export class CommunicationTransactionPostModel {
    constructor() {
        this.CommunicationCode = 0;
        // this.DepartmentCode = 0
        // this.DistrictCode = 0
        // this.OfficeCode = 0
        // this.ProjectCode = 0
        // this.WorkOrderCode = 0
        // this.BankCode = 0
        this.IsActive = true;
        this.IsDeleted = false;
    }
    CommunicationCode: number;
    Subject: string;
    Description: string;
    DepartmentCode: number | string;
    DistrictCode: number | string;
    OfficeCode: number | string;
    ProjectCode: number | string;
    WorkOrderCode: number | string;
    BankCode: number | string;
    VendorUserCode: number | string;
    Amount: string | number;
    Status: string;
    IsActive: boolean;
    IsDeleted: boolean;
    CreatedBy: number | null;
    CreatedOn: Date | string | any;
    ModifiedBy: number | null;
    ModifiedOn: Date | null;
    CommunicationTypeCode: number | string;
    RefNo: string;
    CreatedByOfficeCode: number | string;

    DeletedDocumentCodes: string;
    DynamicFieldValueList: CommunicationTransactionDynamicPostModel[] = [];
    DocumentList: CommunicationTransactionDocumentPostModel[] = [];
    Summary: string;
    SummaryDocumentList: CommunicationSummaryDocumentPostModel[] = [];
    CCOfficeList: string[] = [];
    PreviouseCommunicationCode: number | string;
    PreviouseCommunicationRefNo: number | string;
}


export class CommunicationTransactionDocumentPostModel {
    DocumentCode: number;
    CommunicationCode: number;
    AttachmentTypeCode: number | null;
    AttachmentType: string;
    AttachmentTypeHindi: string;
    Description: string;
    DocName: string;
    DocPath: string;
    Ext: string;
}


export class CommunicationTransactionDynamicPostModel {
    constructor() {
        this.ID = 0;
        this.CommunicationCode = 0;
        this.IsActive = true;
        this.ModifiedBy = undefined;
        this.CreateBy = undefined;
    }

    ID: number;
    CommunicationCode: number;
    FieldCode: number | string;
    FieldValue: string;
    IsActive: boolean;
    IsDeleted: boolean;
    CreateBy: number;
    ModifiedBy: number;
    CreateDate: Date;
    ModifiedDate: Date;
}


export class CommunicationTransactionViewModel {
    CommunicationCode: number;
    Subject: string;
    Description: string;
    Amount: string | number;
    WorkOrderCode: number | string;
    WorkOrderTitle: string
    WorkOrderSubject: string;
    OrderNumber: string;
    CommunicationTypeCode: number | string;
    CommunicationType: string;
    CommunicationTypeHindi: string;
    CommunicationTypeEnumValue: string;
    OfficeCode: number | string;
    OfficeName: string
    OfficeDDOCode: number | null;
    DepartmentCode: number | string;
    DepartmentName: string;
    DepartmentNameHindi: string;
    DistrictCode: number | string;
    DistrictName: string;
    DistrictNameHindi: string;
    RefNo: string;
    Status: string;
    CurrentStatus: string;
    ProjectOrTenderName: string;
    ProjectName: string;
    projectCode: number | string;
    StatusDateTime: Date | string;
    IsActive: boolean;
    IsDeleted: boolean;
    CreatedBy: number | null;
    CreatedOn: Date | null;
    SearchText: string;
    Vendor: string;
    VendorCode: number | string
    OfficeStaffCode: number | string;
    OfficeStaffUser: string;
    CurrentStaffCode: number | string;
    CreatedByOfficeCode: number | string;

    Purpose: string;
    DynamicFieldValueList: CommunicationTransactionDynamicPostModel[]
    DocumentList: CommunicationTransactionDocumentViewModel[];
    BankCode: string | number;
    BankName: string;
    BankNameHindi: string;
    VendorUserCode: number | null;
    VendorContactPersonName: string;
    CanReturn: number;
    CanReOpen: number;
    IsCreatedByVendor: number;
    CanInternalTransfer: boolean;
    CanMoveOffice: boolean;
    CanVendorCommunication: boolean;
    CanResponseBack: boolean;
    Department_AdmDepartmentCode: number;
    IsReturned: number;
    OfficeLookupList: InvoiceCommunicationOfficeHistoryViewModel[] = [];
    InternalTransferCommunicationList: InternalTransferCommunicationHistoryViewModel[] = [];
    ActionHistoryList: ActionModel[] = []
    Summary: string;
    SummaryDocumentList: CommunicationSummaryDocumentViewModel[] = [];
    PreviouseCommunicationCode: number | null;
 CCList: CommunicationCCViewModel[];									 

}


export class CommunicationTransactionDocumentViewModel {
    constructor() {
        // this.DocName = "";
        // this.DocPath = "";
        // this.Ext = "";
        // this.Description = "";
        // this.AttachmentType = "";
        // this.AttachmentTypeHindi = "";
    }
    DocumentCode: number;
    CommunicationCode: number;
    AttachmentTypeCode: number | null;
    AttachmentType: string;
    AttachmentTypeHindi: string;
    DocFakePath: string;
    DocName: string;
    DocPath: string | any;
    Ext: string;
    Description: string;
    IsRequired = false;
}

export class CommunicationSummaryDocumentPostModel {
    DocumentCode: number;
    CommunicationCode: number;
    DocName: string;
    DocPath: string;
    Extension: string;
}

export class CommunicationSummaryDocumentViewModel {
    DocumentCode: number;
    CommunicationCode: number;
    DocName: string;
    DocPath: string;
    Extension: string;
}


export class PreviousCommunicationViewModel {
    CommunicationCode: number;
    Subject: string;
    Description: string;
    WorkOrderCode: number;
    WorkOrderTitle: string;
    WorkOrderSubject: string;
    OrderDate: string | null;
    OrderNumber: string;
    CommunicationTypeCode: number;
    CommunicationType: string;
    CommunicationTypeHindi: string;
    CommunicationTypeEnumValue: string;
    SLADays: number | null;
    SLANotificationDays: number | null;
    OfficeCode: number | null;
    OfficeName: string;
    DepartmentCode: number | null;
    DepartmentName: string;
    DepartmentNameHindi: string;
    DistrictCode: number | null;
    DistrictName: string;
    DistrictNameHindi: string;
    RefNo: string;
    Status: string;
    ProjectOrTenderName: string;
    ProjectName: string;
    projectCode: number;
    StatusDateTime: string | null;
    IsActive: boolean;
    IsDeleted: boolean;
    CreatedBy: number | null;
    CreatedOn: string | null;
    SearchText: string;
    Vendor: string;
    VendorCode: number | null;
    OfficeStaffCode: number | null;
    OfficeStaffUser: string;
    Purpose: string;
    BankCode: number | null;
    BankName: string;
    BankNameHindi: string;
    UserTypeCode: number;
    UserType: string;
    VendorContactPersonName: string;
    IsMoved: number;
    IsCreatedByVendor: number;
    VendorUserCode: number | null;
    CanReturn: number;
    CanReOpen: number;
    IsAssigned: number;
    IsReSubmitted: number;
    Department_AdmDepartmentCode: number | null;
    Summary: string;
    LastActionDate: string | null;
    PreviouseCommunicationCode: number | null;
    Amount: number | null;
    CreatedByOfficeCode: number | null;
    CurrentStatus: string;
}

export class CommunicationCCViewModel {
  CCCode: number;
  CommunitionCode: number;
  OfficeCode: number;
  IsActive: boolean;
  IsDeleted: boolean;
  CreatedBy: number | null;
  CreatedOn: string | null;
  ModifiedBy: number | null;
  ModifiedOn: string | null;
  OfficeName: string;
  DepartmentCode: number | null;
  Department: string;
  DistrictCode: number | null;
  District: string;
  SSOID: string;
}