import { SelectionModel } from '@angular/cdk/collections';
import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { MatCheckboxChange, MatDialogRef } from '@angular/material';
import { AppSetting } from 'src/app/shared/model/appsetting.model';
import { GlobalMessagesModel } from 'src/app/shared/model/common-messages.model';
import { DdlItemModel, DDLModel } from 'src/app/shared/model/commonddl.model';
import { OfficerAddRequestModel } from 'src/app/shared/model/preferred-list/officer-add-request.model';
import { OfficerListRequestModel } from 'src/app/shared/model/preferred-list/officer-list-request.model';
import { OfficerListModel } from 'src/app/shared/model/preferred-list/officer-list.model';
import { AlertService } from 'src/app/shared/service/alert.service';
import { CommonService } from 'src/app/shared/service/common.service';
import { OfficerListService } from 'src/app/shared/service/Preferred-List/officer-list.service';
import { UserService } from 'src/app/shared/service/user.service';

@Component({
  selector: 'app-officer-list',
  templateUrl: './officer-list.component.html',
  styleUrls: ['./officer-list.component.scss'],
  providers: [OfficerListService]
})
export class OfficerListComponent implements OnInit, AfterViewInit {
  public model: OfficerAddRequestModel = new OfficerAddRequestModel();

  public viewModel = {
    displayedColumns: ['selection', 'name', 'ssoid', 'department'],
    filterModel: new OfficerListRequestModel(),
    ddlList: new DDLModel(),
    officers: [] as OfficerListModel[]
  }

  public selectOfficers = new SelectionModel<OfficerListModel>(true, []);

  @Input() selectedTab: any;

  constructor(
    public readonly _commonService: CommonService,
    public readonly _alertService: AlertService,
    private readonly _userService: UserService,
    private readonly _officerService: OfficerListService,
    public _dialogRef: MatDialogRef<OfficerListComponent>
  ) { }

  ngOnInit() {

  }

  ngAfterViewInit() {
    this.getDDLList();
  }

  //#region Properties
  get LoginUserCode() {
    return 0;
  }
  //#endregion Properties


  public onSubmit() {
    this.model.OfficerIds = this.selectOfficers.selected.map(x => x.UserCode);

    this._officerService.AddAllOfficer(this.model).subscribe(
      response => {
        if (response.IsSuccess == true) {
          this._alertService.success(GlobalMessagesModel.saveSuccess, true);
          this._dialogRef.close();
        }
      },
      error => {
        this._alertService.error(GlobalMessagesModel.updateError);
      }
    )
  }

  //#region Events
  public onAdminDepartmentChange() {
    this.GetDepartment();
  }

  public onDepartmentChange() {
    this.getOfficeList();
  }

  public onSearch() {
    let requestModel = this.getRequestModel();

    this.getList(requestModel);
  }

  public onClearSearch() {
    this.viewModel.filterModel = new OfficerListRequestModel();
    this.viewModel.officers = [];
  }
  //#endregion Events

  //#region Private Methods
  private GetDepartment() {
    this.viewModel.ddlList.ddlOfficeDepartment = [];

    this._userService.loginUserCode = this.LoginUserCode;
    let adminDeptCodes = this.viewModel.filterModel.AdminDepartmentCode.toString();

    this._userService.GetUserDepartmentForUserOfficeCreation(adminDeptCodes, true).subscribe(data => {
      if (data.IsSuccess) {
        this.viewModel.ddlList.ddlOfficeDepartment = <DdlItemModel[]>data.Data;
      }
    }, error => {
      this._alertService.error(error.message);
    });
  }

  private getOfficeList() {
    this.viewModel.ddlList.ddlOffice = [];
    let deptCodes = this.viewModel.filterModel.DepartmentId.toString();
    let distCodes = '0';
    this._userService.GetUserOfficeForUserCreation(deptCodes, distCodes).subscribe((data) => {
      if (data.IsSuccess) {
        this.viewModel.ddlList.ddlOffice = <DdlItemModel[]>data.Data;
      }
    }, (error) => {
      this._alertService.error(error.message);
    });
  }

  private getList(model: OfficerListRequestModel) {
    this._officerService.GetList(model).subscribe((data) => {
      if (data.IsSuccess) {
        this.viewModel.officers = <OfficerListModel[]>data.Data;
      }
    }, (error) => {
      this._alertService.error(error.message);
    });
  }

  private getDDLList() {
    this._commonService.GetAllDDL(AppSetting.DDlKeyForOfficer).subscribe((data) => {
      if (data.IsSuccess) {
        this.viewModel.ddlList = <DDLModel>data.Data;
      }
    }, (error) => {
      this._alertService.error(error.message);
    });
  }

  private getRequestModel(): OfficerListRequestModel {
    let output = new OfficerListRequestModel();

    output.SSOId = this.viewModel.filterModel.SSOId;
    output.SearchTxt = this.viewModel.filterModel.SearchTxt;
    output.AdminDepartmentCode = Number(this.viewModel.filterModel.AdminDepartmentCode);
    output.DepartmentId = Number(this.viewModel.filterModel.DepartmentId);
    output.OfficeId = Number(this.viewModel.filterModel.OfficeId);


    return output;
  }
  //#endregion Private Methods

}
