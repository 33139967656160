
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { CommonService } from 'src/app/shared/service/common.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CustomFilterTypeEnum, PreferSearchTypeEnum, UserTypeEnum } from 'src/app/shared/enum/fixed-values.enum';
import { AppSetting } from 'src/app/shared/model/appsetting.model';
import { DDLModel } from 'src/app/shared/model/commonddl.model';
import { AlertService } from 'src/app/shared/service/alert.service';
import { CustomFilterService } from 'src/app/shared/service/custom-filter.service';
import { VendorAdminListingModel } from 'src/app/shared/model/vendor-admins.model';
import { FilterVendorAdminModel } from 'src/app/shared/model/filter-vendor-admin.model';
import { FilterDepartmentModel } from 'src/app/shared/model/filter-department.model';
import { AuthenticationService } from 'src/app/shared/service/authentication.service';
import { DepartmentListingModel } from 'src/app/shared/model/department.model';

@Component({
  selector: 'app-custom-filter-dialog',
  templateUrl: './custom-filter-dialog.component.html',
  styleUrls: ['./custom-filter-dialog.component.scss']
})
export class CustomFilterDialogComponent implements OnInit {


  public ddlList: DDLModel;

  public listModel: VendorAdminListingModel[];
  public deptlistModel: DepartmentListingModel[];
  public isFormVisible: boolean = true;
  private selectedId
  private filterDepartmentModel = new FilterDepartmentModel();
  private filterVendorAdminModel = new FilterVendorAdminModel();
  venderList = [
    { name: "ofice name", id: 1 },
    { name: "ofice name 2", id: 2 },
  ]
  constructor(private readonly fb: FormBuilder,
    private readonly _alertService: AlertService,
    private _customFilterService: CustomFilterService,
    public readonly _commonService: CommonService,
    private readonly _authService: AuthenticationService,
    public _dialogRef: MatDialogRef<CustomFilterDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
     
    this.setIsFormVisible();
  }

  ngOnInit() {
    this.GetVendorType();

  }

  public closeDialog(): void {
    this._dialogRef.close(null);
  }

  public getList() {

    if (this.data.type == CustomFilterTypeEnum.Vendor) {
      this.getVendorData();
    }
    else if (this.data.type == CustomFilterTypeEnum.Department) {
      this.getDepartmentData();
    }
  }

  public ClearSearch() {
    if (this.data.type == CustomFilterTypeEnum.Vendor) {
      this.filterVendorAdminModel = new FilterVendorAdminModel();
      if (!this.filterVendorAdminModel.SSOID && !this.filterVendorAdminModel.OrganizationName && !this.filterVendorAdminModel.VendorType && !this.filterVendorAdminModel.VendorName) {
        this.listModel = []
        return
      }
    }

    else if (this.data.type  == CustomFilterTypeEnum.Department) {
      this.filterDepartmentModel = new FilterDepartmentModel();
      if (!this.filterDepartmentModel.SSOID && !this.filterDepartmentModel.DepartmentTitle && !this.filterDepartmentModel.DepartmentAddress) {
        this.deptlistModel = []
        return
      }
    }
  }

  public onSubmit() {
    if (this.selectedId)
      this._dialogRef.close(this.selectedId)
  }

  private getVendorData() {
    this._customFilterService.getAll(this.filterVendorAdminModel).subscribe(list => {
      this.listModel = list;
    });
  }

  private getDepartmentData() {
    this._customFilterService.getDepartment(this.filterDepartmentModel).subscribe(list => {
      this.deptlistModel = list;
    });
  }
  private GetVendorType() {

    this._commonService.GetAllDDL(AppSetting.VendorDropdownKeys).subscribe(
      (data) => {
        if (data.IsSuccess) {

          this.ddlList = <DDLModel>data.Data;
        }
      },
      (error) => {
        this._alertService.error(error.message);
      }
    );
  }

  private setIsFormVisible() {
      if (this.data.type == CustomFilterTypeEnum.Department) {
      this.isFormVisible = false
    }
    else {
      this.isFormVisible = true
    }
  }
  radioChanged(e) {
    this.selectedId = e.value
  }
}
