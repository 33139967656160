import { Injectable } from '@angular/core';
import { AppSetting } from '../../model/appsetting.model';
import { IndexModel } from '../../model/common.model';
import { Dictionary } from '../../model/dictionary.model';
import { WorkOrderCubCategoryModel } from '../../model/master/work-order-cub-category.model';
import { BaseService } from '../base.service';

@Injectable({
  providedIn: 'root'
})
export class WorkOrderSubCategoryService {

  constructor(private readonly _baseService: BaseService, ) { }

    GetList(model: IndexModel) {
        return this._baseService.post(AppSetting.WorkOrderSubCategoryListUrl, model);
    }

    AddUpdate(id: number, model: WorkOrderCubCategoryModel) {
        return this._baseService.post(AppSetting.WorkOrderSubCategoryAddUpdateUrl, model);
    }

    Detail(id: number) {
         return this._baseService.get(AppSetting.WorkOrderSubCategoryDetailUrl + id);
    }

    ChangeDeleteStatus(id: number) {
        return this._baseService.get(AppSetting.WorkOrderSubCategoryDeleteStatusChangeUrl + id);
    }

    ChangeActiveStatus(id: number) {
        return this._baseService.get(AppSetting.WorkOrderSubCategoryActiveStatusChangeUrl + id);
    }

    // IsWorkOrderSubCategoryExist(name: string){
    //     return this._baseService.get(AppSetting.IsWorkOrderSubCategoryExistUrl + name);
    // }


    IsWorkOrderSubCategoryExist(subCategoryName: string,id:any,catId:any){
        const param = new Dictionary<any>();
        param.Add("name", subCategoryName);
        param.Add("id", id==undefined?"0":id);
        param.Add("catId", catId==undefined?"0":catId);
        return this._baseService.get(AppSetting.IsWorkOrderSubCategoryExistUrl , param);
    }
    GetWorkOrderSubCategoryPDFDownload(model: IndexModel) {
        return this._baseService.post(AppSetting.WorkOrderSubCategoryPDFDownloadUrl, model);
    }
}
