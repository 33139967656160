export class GlobalMessagesModel {
  //#region Common
  static saveSuccess: string = 'Record saved successfully.';
  static saveFailed: string = 'Failed to save record.';
  static saveError: string = 'Record not saved, please try again..!';
  static updateSuccess: string = 'Record updated  successfully.';
  static updateError: string = 'Record not updated, please try again..!';
  static deleteSuccess: string = 'Record deleted successfully.';
  static deleteError: string = 'Record not deleted, please try again..!';
  static InternalError: string = 'Getting error, please try again..!';
  static ConfirmStatusChanged: string = 'Are you sure want to update status? ';
  static ConfirmDeleted: string = 'Are you sure want to delete record? ';
  static ConfirmHide: string = 'Are you sure want to hide this field? ';
  static Exist: string = 'This record already exist.';
  static AllFieldsAreEmpty: string = 'Please fill all the fields, Then try again..!';
  static EmptyField: string = 'field must have value!';
  static NoFieldFound: string = 'There is no new field available...!';
  static FillMandatoryField = 'Please re-check form for valid input value...!'
  static DataRetrieveIssue = 'Error on Data retrieve, Please try again latter...!'
  static NotSaveAllRecord: string = 'Some of Record saved successfully, please correct Provided data and upload again.';
  static ConfirmPullBack: string = 'Are you sure want to pull back the selected record? ';
  static ReopenCommunication: string = 'Are you sure want to Re-Open this communication? ';
  static PageAuthenticationSaveSuccess: string = 'Page authentication saved successfully.';
  static ResponseBackCommunicationSuccess: string ='Response back reply submitted successfully.'
  static ResponseBackCommunicationFailed: string ='Failed to submit Response back reply.'
  static ConfirmFinalSubmit: string = `Are you sure want to Final Submit? <br/><h6 class='popup-note'>No: Only record detail saved.<br/>Yes: Record detail saved but can not be updated after.</h6>`;
  static ConfirmDeletedAll: string = 'Are you sure want to delete all record? ';
  static RegistrationSuccess: string = 'Registration Successfully, please login from SSO...';
  static SaveProfileSuccess: string = 'Profile Update Successfully...!';
  static NoRecordFound: string = 'No record Found..!';
  static RoleSwitchSuccess : string ='Role Switched successfully...!'
  static RoleSavedSuccess : string ='Role save successfully...!'
  static Unauthorized : string ='Un-Authorized user...!'

  //#endregion

  //#region User Login
  static LogOut: string = 'Log Out Successfully.';
  //#endregion

  //#region Communication
  static updateCommunicationOfficeSuccess: string = 'Communication successfully moved to selected office.';
  static updateCommunicationOfficeError: string = 'Communication failed to move in selected office.';
  static updateCommunicationOfficeCodeChange: string = 'Please select the office in which you want to move.';
  static updateCommunicationOfficeStaffCodeChange: string = 'Please select the office staff which you want to assign.';
  static clearCommunication: string = "Are you certain that you wish to continue? This action will remove all communication, projects, project groups, work orders, and OIC mapping from the system.";
  //#endregion
  //#region User Permission
  static updateDefaultPermissionSuccess: string = "Default permissions updated successfully.";
  static updateDefaultPermissionError: string = "Default permissions not updated.";
  static updateDefaultPermissionFailed: string = "Failed to update default permissions.";

  static updateUserPermissionSuccess: string = "User permissions updated successfully.";
  static updateUserPermissionError: string = "User permissions not updated.";
  static updateUserPermissionFailed: string = "Failed to update user permissions.";
  //#endregion
}

