import { UserService } from './../../../shared/service/user.service';
import { ConfirmationDialogComponent } from './../../../confirmation-dialog/confirmation-dialog.component';
import { MatDialog } from '@angular/material';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import { AuthenticationService } from 'src/app/shared/service/authentication.service';
import { GlobalMessagesModel } from 'src/app/shared/model/common-messages.model';
import { AppComponent } from 'src/app/app.component';
import { AlertService } from 'src/app/shared/service/alert.service';
import { CommonService } from 'src/app/shared/service/common.service';
import { UserViewModel } from 'src/app/shared/model/user.model';
import { AppSetting } from 'src/app/shared/model/appsetting.model';
import { ProjectService } from 'src/app/shared/service/project.service';
import { ProjectDocumentModel, ProjectModel } from 'src/app/shared/model/project.model';
import { PreferSearchTypeEnum, StateEnum, UserTypeEnum } from 'src/app/shared/enum/fixed-values.enum';
import { DdlItemModel, DDLModel, FilterDDlModel } from 'src/app/shared/model/commonddl.model';
import { WorkOrderDocumentModel } from 'src/app/shared/model/work-order.model';
import { PreferredListComponent } from '../../preferred-list/preferred-list.component';
import { PreferredListEnum } from 'src/app/shared/enum/preferred-list.enum';

@Component({
  selector: 'app-add-update-project',
  templateUrl: './add-update-project.component.html',
  styleUrls: ['./add-update-project.component.scss']
})

export class AddUpdateProjectComponent implements OnInit {

  ddlList = new DDLModel();
  frmGrp: FormGroup;
  RecordId: number = 0;
  oldProjectName: string;
  filterDDlModel: any[];
  fileValidationMsg: string;
  minDate = new Date();
  enableSubmitBtn = true;
  userTypeEnum = UserTypeEnum;
  tempDeletedDocumentCodes: string[] = [];
  model: ProjectModel = new ProjectModel();
  tempDocumentList: ProjectDocumentModel[] = [];
  loginUserDetail: UserViewModel = new UserViewModel();
  preferSearchTypeEnum = PreferSearchTypeEnum;
  isApplicableToAllVendorVisible: boolean = true;
  @ViewChild('files', { static: false }) files: any;
  constructor(private readonly fb: FormBuilder,
    private readonly appComponnet: AppComponent,
    public readonly _commonService: CommonService,
    private readonly _alertService: AlertService,
    private readonly _router: Router,
    private _route: ActivatedRoute,
    private readonly _projectService: ProjectService,
    private readonly _authService: AuthenticationService,
    private readonly _dialog: MatDialog,
    private readonly _userService: UserService) {
    if (!this._projectService.canUserTypeCreateProject()) {
      this._router.navigate(["dashboard"]);
    }
    this.loginUserDetail = this._authService.GetCurrentUserDetail().UserViewModel;

    if (this._route.snapshot.params.id) {
      this.RecordId = this._route.snapshot.params.id;
    }
    var pageHeading = (this.RecordId == 0 ? "Add" : "Update") + " Project :";
    this.appComponnet.setPageLayout(pageHeading, "keyboard_backspace", "Back To List", "project");
    this.GetDDLList();
    this.setApplicableToAllVendorVisibleVisible();
  }

  ngOnInit() {
    this.formGroupInit();
    this.getDetail();

    if (this.RecordId == 0 && (this.loginUserDetail.BaseUserType == this.userTypeEnum.VNDR || this.loginUserDetail.BaseUserType == this.userTypeEnum.VNDRUSER)) {
      this.model.VendorCode == this.loginUserDetail.UserCode;
    }
  }

  get PreferredListEnum() {
    return PreferredListEnum;
  }

  formGroupInit() {
    this.frmGrp = this.fb.group({
      DepartmentCode: [null, Validators.required],
      ProjectName: [null, Validators.compose([Validators.required, Validators.maxLength(100)])],
      PIC_Name: [null],
      PIC_Designation: [null],
      PIC_Email: [null, Validators.email],
      PIC_MobileNo: [null, Validators.compose([Validators.minLength(10), Validators.maxLength(10)])],
      DistrictCode: [null, Validators.required],
      OfficeCode: [null, Validators.required],
      ProjectGroupCode: [null, Validators.required],
      ProjectCategoryCode: [null, Validators.required],
      ProjectSubCategoryCode: [null],
      TendorID: [null, null],
      TendorRefNumber: [null, null],
      TendorRefDate: ["", null],
      FirmRefNumber: [null],
      FirmRefDate: [null],
      Remarks: [null],
      Description: [null],
      UserCode: [null],
      IsApplicableToAllVendor: [null],
      VendorCode: [null],
      UBNNumber: [null],
      UBNDate: [null],
      ProjectStartDate: [null]
    });
  }

  changeFieldForUser() {
    const VendorControl = this.frmGrp.get("VendorCode");
    if (this.loginUserDetail.BaseUserType == this.userTypeEnum.DREC || this.loginUserDetail.BaseUserType == this.userTypeEnum.HOO
      || this.loginUserDetail.BaseUserType == this.userTypeEnum.DOS || this.loginUserDetail.BaseUserType == this.userTypeEnum.HOD
      || this.loginUserDetail.BaseUserType == this.userTypeEnum.DRECADLC || this.loginUserDetail.BaseUserType == this.userTypeEnum.HOOADLC
      || this.loginUserDetail.BaseUserType == this.userTypeEnum.DOSADLC || this.loginUserDetail.BaseUserType == UserTypeEnum.VNDR
      || this.loginUserDetail.BaseUserType == UserTypeEnum.VNDRUSER) {

      if (this.RecordId == 0) {
        if (this.loginUserDetail.DepartmentCodes && this.loginUserDetail.DepartmentCodes.split(',').length > 0)
          this.model.DepartmentCode = String(this.loginUserDetail.DepartmentCodes.split(',')[0]);
        if (this.loginUserDetail.DistrictCodes && this.loginUserDetail.DistrictCodes.split(',').length > 0)
          this.model.DistrictCode = String(this.loginUserDetail.DistrictCodes.split(',')[0]);
        if (this.loginUserDetail.OfficeCodes && this.loginUserDetail.OfficeCodes.split(',').length > 0)
          this.model.OfficeCode = String(this.loginUserDetail.OfficeCodes.split(',')[0]);
      }
    }

    if (this.loginUserDetail.BaseUserType == this.userTypeEnum.VNDR || this.loginUserDetail.BaseUserType == this.userTypeEnum.VNDRUSER) {
      VendorControl.setValidators(null);
      this.model.VendorCode = String(this.loginUserDetail.UserCode);
    } else {
      // VendorControl.setValidators([Validators.required]);
      VendorControl.setValidators(null);
    }

    VendorControl.updateValueAndValidity();
  }

  GetDDLList() {
    let ddlKeyName = ((this.loginUserDetail.BaseUserType == this.userTypeEnum.VNDR || this.loginUserDetail.BaseUserType == this.userTypeEnum.VNDRUSER) ? AppSetting.ProjectDropDownKeys : AppSetting.ProjectDropDownDEPTKeys);
    this._commonService.GetAllDDL(ddlKeyName, 0, this.loginUserDetail.UserCode).subscribe((data) => {
      if (data.IsSuccess) {
        let ddlList = <DDLModel>data.Data;
        this.ddlList.ddlOfficeDepartment = ddlList.ddlOfficeDepartment;
        this.ddlList.ddlProjectCategory = ddlList.ddlProjectCategory;
        this.ddlList.ddlLoggedUserVendor = ddlList.ddlLoggedUserVendor;
        this.ddlList.ddlProjectGroup = ddlList.ddlProjectGroup;
        this.ddlList.ddlDepartmentByVendorPermission = ddlList.ddlDepartmentByVendorPermission;

        if (this.RecordId == 0) {
          this.changeFieldForUser();
        }
      }
    }, (error) => {
      this._alertService.error(error.message);
    });
  }

  getDetail() {
    if (this.RecordId > 0) {
      this._projectService.Detail(this.RecordId).subscribe(data => {
        if (data.IsSuccess) {
          this.model = <ProjectModel>data.Data;
          this.RecordId = this.model.ProjectCode;
          this.oldProjectName = this.model.ProjectName;
          if (this.model.DepartmentCode) { this.model.DepartmentCode = String(this.model.DepartmentCode); }
          if (this.model.DistrictCode) { this.model.DistrictCode = String(this.model.DistrictCode); }
          if (this.model.OfficeCode) { this.model.OfficeCode = String(this.model.OfficeCode); }
          if (this.model.ProjectGroupCode) { this.model.ProjectGroupCode = String(this.model.ProjectGroupCode); }
          if (this.model.ProjectCategoryCode) { this.model.ProjectCategoryCode = String(this.model.ProjectCategoryCode); }
          if (this.model.ProjectSubCategoryCode) { this.model.ProjectSubCategoryCode = String(this.model.ProjectSubCategoryCode); }
          if (this.model.UserCode) { this.model.UserCode = String(this.model.UserCode); }
          if (this.model.VendorCode) { this.model.VendorCode = String(this.model.VendorCode); }
          this.tempDocumentList = this.model.DocumentList;
          this.changeFieldForUser();
        }
      }, error => {
        this._alertService.error(error.message);
      });
    }
  }

  IsProjectNameExist(Event) {
    if (this.oldProjectName != Event.currentTarget.value) {
      this._projectService.IsProjectNameExist(Event.currentTarget.value).subscribe(result => {
        if (result.IsSuccess) {
          let UserName = <boolean>result.Data
          if (UserName == true) {
            this.model.ProjectName = null;
            this._alertService.error("Project Name is already exist!");
          }
        }
      }, error => {
        this._commonService.ScrollingTop();
        this._alertService.error(error.message);
      });
    }
  }

  keyPressAlphaNumeric(event) {
    var inp = String.fromCharCode(event.keyCode);
    if (/[a-zA-Z0-9 ]/.test(inp)) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }

  onChangeDepartment() {
    this.ddlList.ddlOfficeByLoginUserId = [];
    this.ddlList.ddlOfficeHavingStaffByLoginUserId = [];
    this.GetDistrictByDepartment();
  }

  GetDistrictByDepartment() {
    if (this.loginUserDetail.UserTypeCategory == this.userTypeEnum.STTLVL) {
      this.model.DepartmentCode = '';
      let stateCode = String(StateEnum.Rajasthan);
      this._userService.GetUserDistrictList(stateCode, false, this.model.DepartmentCode.toString()).subscribe(data => {
        if (data.IsSuccess) {
          this.ddlList.ddlDistrict = <DdlItemModel[]>data.Data;
          if (this.ddlList.ddlDistrict
            && this.ddlList.ddlDistrict.findIndex(x => x.Value == this.model.DistrictCode) == -1) {
            this.model.DistrictCode = undefined;
          } else {
            //this.GetOfficeByDistrict();
            this.GetOfficeHavingStaffByDistrict();
          }
        }
      },
        error => {
          this._alertService.error(error.message);
        }
      );
    }
    else if (this.model.DepartmentCode) {
      let stateCode = String(StateEnum.Rajasthan);
      this._userService.GetUserDistrictList(stateCode, false, this.model.DepartmentCode.toString()).subscribe(data => {
        if (data.IsSuccess) {
          this.ddlList.ddlDistrict = <DdlItemModel[]>data.Data;

          // #CheckSelectedValueExist
          if (this.ddlList.ddlDistrict
            && this.ddlList.ddlDistrict.findIndex(x => x.Value == this.model.DistrictCode) == -1) {
            this.model.DistrictCode = undefined;
          } else {
            //this.GetOfficeByDistrict();
            this.GetOfficeHavingStaffByDistrict();
          }

          // this.model.DistrictCode = (this.ddlList.ddlDistrict
          //   && this.ddlList.ddlDistrict.findIndex(x => x.Value == this.model.DistrictCode) == -1) ?
          //   undefined : String(this.model.DistrictCode);

        }
      },
        error => {
          this._alertService.error(error.message);
        }
      );
    }
    else {
      this.ddlList.ddlDistrict = [];
    }
  }

  GetOfficeByDistrict() {
    if (this.model.DepartmentCode) {
      let districtCode = this.model.DistrictCode ? this.model.DistrictCode : 0;
      this._userService.GetUserOfficeList(this.model.DepartmentCode, districtCode).subscribe(data => {
        if (data.IsSuccess) {
          this.ddlList.ddlOfficeByLoginUserId = <DdlItemModel[]>data.Data;

          // #CheckSelectedValueExist
          if (this.ddlList.ddlOfficeByLoginUserId
            && this.ddlList.ddlOfficeByLoginUserId.findIndex(x => x.Value == this.model.OfficeCode) == -1) {
            this.model.OfficeCode = undefined;
          }
        }
      }, (error) => {
        this._alertService.error(error.message);
      });
    } else {
      this.ddlList.ddlOfficeByLoginUserId = [];
    }
  }

  GetOfficeHavingStaffByDistrict() {
    if (this.model.DepartmentCode) {
      let districtCode = this.model.DistrictCode ? this.model.DistrictCode : 0;
      this._userService.GetUserOfficeHavingStaffList(this.model.DepartmentCode, districtCode).subscribe(data => {
        if (data.IsSuccess) {
          this.ddlList.ddlOfficeHavingStaffByLoginUserId = <DdlItemModel[]>data.Data;

          // #CheckSelectedValueExist
          if (this.ddlList.ddlOfficeHavingStaffByLoginUserId
            && this.ddlList.ddlOfficeHavingStaffByLoginUserId.findIndex(x => x.Value == this.model.OfficeCode) == -1) {
            this.model.OfficeCode = undefined;
          }
        }
      }, (error) => {
        this._alertService.error(error.message);
      });
    } else {
      this.ddlList.ddlOfficeHavingStaffByLoginUserId = [];
    }
  }

  getFilterdDDL(filterFor, value, filterFrom) {
    const item = new FilterDDlModel();
    item.FilterFor = filterFor;
    item.Value = value;
    item.FilterFrom = filterFrom;
    this.filterDDlModel = [];
    this.filterDDlModel.push(item);

    this._commonService.GetFilterdDDL(this.filterDDlModel).subscribe(data => {
      if (data.IsSuccess) {
        if (item.FilterFor == "ddlProjectSubCategory") {
          this.ddlList.ddlProjectSubCategory = data.Data.ddlProjectSubCategory;
        }
      }
    }, error => {
      this._alertService.error(error.message);
    });
  }

  handleFileInput(files: FileList) {
    let count = 0;
    if (this.tempDocumentList != undefined && this.tempDocumentList.length > 0) {
      count = this.tempDocumentList.length;
    }

    for (let index = 0; index < files.length; index++) {
      if (files.item(index).type.match("application/pdf")) {
        if (files.item(index).size < AppSetting.FileSizeLimit.PDF) {
          const reader = new FileReader();
          reader.onload = (e: any) => {
            if (this.tempDocumentList == undefined) {
              this.tempDocumentList = [];
            }
            this.tempDocumentList.push(new WorkOrderDocumentModel()
            );
            this.tempDocumentList[count + index].DocName = <string>reader.result;
            this.tempDocumentList[count + index].DocPath = <string>reader.result;

            this.tempDocumentList[count + index].DocName = files[index].name;
            this.tempDocumentList[count + index].Extension = files[index].name.split(".")[1];
            this.tempDocumentList[count + index].DocPath = <string>reader.result;
          };
          reader.readAsDataURL(files[index]);
        }
        else {
          this.tempDocumentList = [];
          this.fileValidationMsg = AppSetting.FileSizeLimit.PDFErrorMSG;
          return;
        }
      }
      else {
        this.tempDocumentList = [];
        this.fileValidationMsg = "Only *pdf";
        return;
      }
    }
    this.fileValidationMsg = "";
  }

  downloadPdf(Url, name) {
    const linkSource = Url;
    const downloadLink = document.createElement("a");
    const fileName = name;

    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.target = "blank";
    downloadLink.click();
  }



  deleteWorkOrderDocument(documentCode: number, index: number) {
    if (documentCode)
      this.tempDeletedDocumentCodes.push(documentCode.toString());
    this.tempDocumentList.splice(index, 1);
    this.files.nativeElement.value = null;
  }

  onApplicationToAllVendorChange() {
    const fieldVendor = this.frmGrp.get("VendorCode");
    if (this.model.IsApplicableToAllVendor) {
      this.model.VendorCode = undefined;
      fieldVendor.setValidators(null);
    }
    else {
      fieldVendor.setValidators([Validators.required]);
    }
    fieldVendor.updateValueAndValidity();
  }

  saveAsDraft() {
    this.model.IsDraft = true;
    this.checkProjectDetail();
  }

  onSubmit() {
    this.enableSubmitBtn = false;
    this.model.IsDraft = false;
    this.checkProjectDetail();
    this.enableSubmitBtn = true;
  }

  // checkProjectDetail() {
  //   if (!this.model.IsDraft) {
  //     this.frmGrp.markAllAsTouched();
  //   }

  //   if ((this.frmGrp.valid && !this.model.IsDraft) || this.model.IsDraft) {
  //     if (this.RecordId == 0) {
  //       this.model.CreatedBy = this.loginUserDetail.UserCode;
  //     }
  //     else {
  //       this.model.ModifiedBy = this.loginUserDetail.UserCode;
  //     }

  //     this.model.DocumentList = this.tempDocumentList;
  //     this.model.DeletedDocumentCodes = this.tempDeletedDocumentCodes.toString();

  //     if (!this.model.IsDraft) {
  //       const dialogRef = this._dialog.open(ConfirmationDialogComponent, {
  //         width: "50%",
  //         data: GlobalMessagesModel.ConfirmFinalSubmit,
  //         disableClose: true
  //       });
  //       dialogRef.afterClosed().subscribe(result => {
  //         if (result) {
  //           this.model.IsFinalSubmit = (result as boolean);
  //         } else {
  //           this.model.IsFinalSubmit = false;
  //         }
  //         this.saveProjectDetail();
  //       });
  //     }
  //     else {
  //       this.saveProjectDetail();
  //     }

  //   } else {
  //     this.enableSubmitBtn = true;
  //   }
  // }

  checkProjectDetail() {
    if (!this.model.IsDraft) {
      this.frmGrp.markAllAsTouched();
    }

    if ((this.frmGrp.valid && !this.model.IsDraft) || this.model.IsDraft) {
      if (this.RecordId == 0) {
        this.model.CreatedBy = this.loginUserDetail.UserCode;
      }
      else {
        this.model.ModifiedBy = this.loginUserDetail.UserCode;
      }

      this.model.DocumentList = this.tempDocumentList;
      this.model.DeletedDocumentCodes = this.tempDeletedDocumentCodes.toString();

      this.model.IsFinalSubmit = false;
      this.saveProjectDetail();



    } else {
      this.enableSubmitBtn = true;
    }
  }

  saveProjectDetail() {
    this._projectService.AddUpdate(this.RecordId, this.model).subscribe(data => {
      if (data) {
        if (data.IsSuccess) {
          this._alertService.success(this.RecordId == 0 ? GlobalMessagesModel.saveSuccess : GlobalMessagesModel.updateSuccess);
          this._router.navigate(["project"]);
        }
        else {
          this._commonService.ScrollingTop();
          this._alertService.error(this.RecordId == 0 ? GlobalMessagesModel.saveError : GlobalMessagesModel.updateError);
        }
        this.enableSubmitBtn = true;
      }
      else {
        this.enableSubmitBtn = true;
        this._commonService.ScrollingTop();
        this._alertService.error(GlobalMessagesModel.InternalError);
      }
      this.enableSubmitBtn = true;
    }, error => {
      this.enableSubmitBtn = true;
      this._commonService.ScrollingTop();
      this._alertService.error(GlobalMessagesModel.InternalError);
    });
  }

  onOfficeChange() {
    //this.GetProjectByDepartmentOffice();
    setTimeout(() => {
      this.GetOfficeUser();
    }, 100);
  }

  GetOfficeUser() {
    if (this.model.OfficeCode) {
      this._commonService.GetDDL(AppSetting.ddlOfficeReceptionUsers, this.model.OfficeCode).subscribe(
        data => {
          if (data.IsSuccess) {
            var ddl = <DDLModel>data.Data;
            this.ddlList.ddlOfficeReceptionUsers = ddl.ddlOfficeReceptionUsers;
          }
          else {
            this.ddlList.ddlOfficeReceptionUsers = null;
          }
        },
        error => {
          this._alertService.error(error.message);
        }
      );
    }
    else {
      this.ddlList.ddlOfficeReceptionUsers = null;
    }
  }

  GetUserDetail() {
    if (this.model.UserCode && this.model.UserCode > 0) {
      this._userService.Detail(Number(this.model.UserCode)).subscribe(
        data => {
          if (data.IsSuccess) {
            var userModel = <UserViewModel>data.Data;
            this.model.PIC_Email = userModel.Email;
            this.model.PIC_Designation = userModel.Designation;
            this.model.PIC_DesignationName = userModel.DesignationName;
            this.model.PIC_MobileNo = userModel.Mobile;
          }
          else {
            this.model.PIC_Email = undefined;
            this.model.PIC_Designation = undefined;
            this.model.PIC_DesignationName = undefined;
            this.model.PIC_MobileNo = undefined;
          }
        },
        error => {
          this._alertService.error(error.message);
        }
      );
    }
  }


  public openPreferredListDialog(preferredList: PreferredListEnum): void {
    const dialogRef = this._dialog.open(PreferredListComponent, {
      width: '100%',
      height: '100%',
      data: {
        selectedTab: preferredList
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (preferredList == PreferredListEnum.Office) {
        this.model.OfficeCode = result;
      } else if (preferredList == PreferredListEnum.Vendor) {
        this.model.VendorCode = result;
      }
    });
  }


  private setApplicableToAllVendorVisibleVisible() {

    let userDetail = this._authService.LoggedInUserDetail.UserViewModel;

    if (userDetail.UserTypeCode == UserTypeEnum.HODCode) {

      this.isApplicableToAllVendorVisible = false;

    }
  }
}
