import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { AppComponent } from 'src/app/app.component';
import { ConfirmationDialogComponent } from 'src/app/confirmation-dialog/confirmation-dialog.component';
import { GlobalMessagesModel } from 'src/app/shared/model/common-messages.model';
import { IndexModel } from 'src/app/shared/model/common.model';
import { UserNotificationModel } from 'src/app/shared/model/user-notification.model';
import { UserViewModel } from 'src/app/shared/model/user.model';
import { AlertService } from 'src/app/shared/service/alert.service';
import { AuthenticationService } from 'src/app/shared/service/authentication.service';
import { CommonService } from 'src/app/shared/service/common.service';
import { UserNotificationService } from 'src/app/shared/service/user-notification.service';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})

export class NotificationComponent implements OnInit {
  userDetail: UserViewModel;
  notificationList: UserNotificationModel[] = [];

  constructor(private readonly appComponent: AppComponent,
    public readonly _authenticationService: AuthenticationService,
    private readonly _notificationService: UserNotificationService,
    private readonly _alertService: AlertService,
    public readonly _commonService: CommonService,
    private readonly _dialog: MatDialog) {
    this.userDetail = _authenticationService.GetCurrentUserDetail() == null ? null : _authenticationService.GetCurrentUserDetail().UserViewModel;

    this.appComponent.setPageLayout("Notifications", "", "", "");
  }

  ngOnInit() {
    this.getUserNotifications();
  }

  getUserNotifications() {
    
    this._notificationService.GetList(this.userDetail.UserCode).subscribe(
      data => {
        if (data.IsSuccess) {
          
          this.notificationList = <UserNotificationModel[]>data.Data;
        }
        else
          this._alertService.error(data.Message);
      },
      error => {
        this._alertService.error(error.message);
      }
    );
  }

  deleteNotification(code: number) {
    const dialogRef = this._dialog.open(ConfirmationDialogComponent, { width: "50%", data: GlobalMessagesModel.ConfirmDeleted });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this._notificationService.ChangeDeleteStatus(code).subscribe(
          data => {
            if (data.IsSuccess) {
              this._alertService.success(GlobalMessagesModel.deleteSuccess);
              this._commonService.ScrollingTop();
              this.getUserNotifications();
            }
          },
          error => {
            this._alertService.error(error.message);
          }
        );
      }
    });
  }

  deleteAllNotification() {
    const dialogRef = this._dialog.open(ConfirmationDialogComponent, { width: "50%", data: GlobalMessagesModel.ConfirmDeletedAll });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this._notificationService.ChangeDeleteAllStatus(this.userDetail.UserCode).subscribe(
          data => {
            if (data.IsSuccess) {
              this._alertService.success(GlobalMessagesModel.deleteSuccess);
              this._commonService.ScrollingTop();
              this.getUserNotifications();
            }
          },
          error => {
            this._alertService.error(error.message);
          }
        );
      }
    });
  }

}
