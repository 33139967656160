import { FieldTypeEnum } from 'src/app/shared/enum/fixed-values.enum';
import { isNullOrUndefined } from 'util';
import { CommunicationTypeFieldValidationPostModel, CommunicationTypeFieldValidationViewModel } from '../../../../shared/model/master/communication-type-fields-master.model';
import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl, FormArray } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { AuthenticationService } from 'src/app/shared/service/authentication.service';
import { GlobalMessagesModel } from 'src/app/shared/model/common-messages.model';
import { AlertService } from 'src/app/shared/service/alert.service';
import { MAT_DIALOG_DATA, MatDialogRef, MatRadioChange } from '@angular/material';
import { CommonService } from 'src/app/shared/service/common.service';
import { DdlItemModel, DDLModel } from '../../../../shared/model/commonddl.model';
import { AppSetting } from 'src/app/shared/model/appsetting.model';
import { CommunicationTypeFieldsMasterModel } from 'src/app/shared/model/master/communication-type-fields-master.model';
import { CommunicationTypeFieldsMasterService } from 'src/app/shared/service/master/communication-type-fields-master.service';
import { UserViewModel } from 'src/app/shared/model/user.model';
import { formatDate } from '@angular/common';

@Component({
  selector: 'app-add-communication-type-fields',
  templateUrl: './add-communication-type-fields.component.html',
  styleUrls: ['./add-communication-type-fields.component.scss']
})
export class AddCommunicationTypeFieldsComponent implements OnInit {
  //#region <<Variable>>
  loginUserDetail: UserViewModel;
  loginUserCode: number;
  frmGrp: FormGroup;
  model: CommunicationTypeFieldsMasterModel = new CommunicationTypeFieldsMasterModel();
  fileValidationMsg: string;
  ddlList: DDLModel = new DDLModel();
  disabled: boolean = false;
  disabledFieldType: boolean = true;
  recordId: number = 0;
  oldFieldsLabel: string;
  existingFieldDisplayOrder = [];
  DisplayOrderList: number[] = Array.from({ length: 5 }, (v, k) => k + 1);
  validationsListViewModel: CommunicationTypeFieldValidationViewModel[] = [];
  fieldType = FieldTypeEnum;
  submitBtn: boolean = true;

  ddlDynamicFields: DdlItemModel[] = [];
  //#endregion

  constructor(private readonly fb: FormBuilder,
    public readonly _commonService: CommonService,
    private readonly _alertService: AlertService,
    public _dialogRef: MatDialogRef<AddCommunicationTypeFieldsComponent>,
    private readonly _communicationTypeFieldsMasterService: CommunicationTypeFieldsMasterService,
    private readonly _router: Router,
    private readonly _authService: AuthenticationService,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.formGroupInit();

    this.loginUserDetail = this._authService.GetCurrentUserDetail().UserViewModel;

    this.GetDDLList();

    if (data) {
      if (data.Id) {
        this.model.CommunicationTypeFieldsCode = data.Id;
        this.recordId = data.Id;

      }
      if (data.SelectedDisplayOrder) {
        this.existingFieldDisplayOrder = [];
        data.SelectedDisplayOrder.split(',').forEach(element => {
          this.existingFieldDisplayOrder.push(element)
        });
      }
      if (data.CommunicationTypeCode) {
        this.model.CommunicationTypeCode = data.CommunicationTypeCode;
      }
    }
  }

  ngOnInit() {
    this.getDynamicField();
    if (this.recordId > 0) {
      setTimeout(() => {
        this.GetDetail();
      }, 50);
    }

  }

  formGroupInit() {
    this.frmGrp = this.fb.group({
      FieldLabel: [null, Validators.required],
      FieldTypeCode: [null, Validators.required],
      ExistingMasterCode: [null],
      IsRequired: [null],
      DisplayOrder: [null, Validators.required],
      CSSClassCode: [null, Validators.required],
      ToolTip: [null, Validators.required],
      IsDisabledForEdit: [null],
      ValidationsControls: new FormArray([])
    });
  }



  GetDDLList() {
    this._commonService.GetAllDDL(AppSetting.CommunicationTypeFieldsDropdownKeys).subscribe(
      data => {
        if (data.IsSuccess) {
          this.ddlList = <DDLModel>data.Data;
        }
      },
      error => {
        this._alertService.error(error.message);
      }
    );
  }

  disableMaster(event) {
    if (event.value == 3) {
      this.disabled = true;
    }
    else {
      this.disabled = false;
    }
  }

  saveClick() {
    this.submitBtn = false;

    this.frmGrp.markAllAsTouched();
    if (this.frmGrp.valid) {

      if (this.recordId == 0) {
        this.model.CreatedBy = this.loginUserDetail.UserCode;
      }
      else {
        this.model.ModifiedBy = this.loginUserDetail.UserCode;
      }

      this.validationsListViewModel.forEach((ele, index) => {
        
        if (ele.IsSelected) {


          let items = new CommunicationTypeFieldValidationPostModel();
          items.code = ele.code == null ? 0 : ele.code;
          items.FieldCode = this.model.CommunicationTypeFieldsCode;

          //for range related data 
          if (ele.FieldType == FieldTypeEnum.DATE && ele.IsRange && !ele.IsForwardValue) {

            items.Value = this._commonService.getFormatDate(ele.Value);
            items.DependentValue = this._commonService.getFormatDate(ele.DependentValue);

          }
          //for Compaire
          else if (ele.FieldType == FieldTypeEnum.DATE && ele.IsRange && ele.IsForwardValue) {

            items.Value = ele.Value
            items.DependentValue = ele.DependentValue;

          }
          //other 
          else {

            items.Value = ele.Value
            items.DependentValue = ele.DependentValue;
          }


          // items.Value = (ele.FieldType == FieldTypeEnum.DATE && ele.IsRange ? this._commonService.getFormatDate(ele.Value) : ele.Value);
          // items.DependentValue = (ele.FieldType == FieldTypeEnum.DATE && ele.IsRange ? this._commonService.getFormatDate(ele.DependentValue) : ele.DependentValue)

          items.ValidationRuleMasterCode = ele.ValidationMasterRuleCode;
          items.ValidationMessage = ele.ValidationMessage;
          items.IsActive = true;

          if (this.model.ValidationsList == null) {
            this.model.ValidationsList = [];
          }

          this.model.ValidationsList.push(items)
        }



      })

      // this.frmGrp.get("FieldTypeCode").enable();
      this._communicationTypeFieldsMasterService.AddUpdate(this.recordId, this.model).subscribe(
        data => {
          //    this.frmGrp.get("FieldTypeCode").disable();
          if (data.IsSuccess) {

            this._alertService.success(this.recordId == 0 ? GlobalMessagesModel.saveSuccess : GlobalMessagesModel.updateSuccess);
            this._dialogRef.close(true);
          } else {
            this.submitBtn = true;
            this._alertService.error(data.Message);
            this._alertService.error(this.recordId == 0 ? GlobalMessagesModel.saveError : GlobalMessagesModel.updateError);
          }
        },
        error => {
          this.submitBtn = true;
          //     this.frmGrp.get("FieldTypeCode").disable();
          this._alertService.error(error.message);
        }
      );
    }
    else {
      this.submitBtn = true;
    }
  }

  CloseDialog(): void {
    this._dialogRef.close();
  }

  GetDetail() {
    this._communicationTypeFieldsMasterService.Detail(this.recordId).subscribe(
      data => {
        if (data.IsSuccess) {

          this.model = <CommunicationTypeFieldsMasterModel>data.Data;
          this.model.FieldTypeCode = String(this.model.FieldTypeCode);
          this.oldFieldsLabel = this.model.FieldLabel;
          this.model.ExistingMasterCode = String(this.model.ExistingMasterCode);
          this.model.IsRequired = this.model.IsRequired;
          this.model.IsDisabledForEdit = this.model.IsDisabledForEdit;
          this.model.DisplayOrder = String(this.model.DisplayOrder);
          this.model.CSSClassCode = String(this.model.CSSClassCode);
          // this.frmGrp.get("FieldTypeCode").disable();
        }
      },
      error => {
        this.model = new CommunicationTypeFieldsMasterModel();
        this._alertService.error(error.message);
      }
    );
  }

  IsFieldLabelExist(Event) {

    if (this.oldFieldsLabel != Event.currentTarget.value) {
      this._communicationTypeFieldsMasterService.IsCommunicationTypeFieldsMasterExist(this.model).subscribe(result => {
        if (result.IsSuccess) {
          this.model.FieldTypeCode = null;
          this._alertService.error("Field Label is already exist!");
        }
      }, error => {
        this._commonService.ScrollingTop();
        this._alertService.error(error.message);
      });
    }
  }


  checkDisplayOrder(value: number) {
    let isNotExist = true;
    this.existingFieldDisplayOrder.forEach(ele => {

      if (ele == value && ele != this.model.DisplayOrder && this.model.DisplayOrder > 0) {
        isNotExist = false;
      }
    })
    return isNotExist

  }

  GetFieldValidationList() {
    this.validationsListViewModel = [];
    if (!isNullOrUndefined(this.model.FieldTypeCode)) {
      this._communicationTypeFieldsMasterService.GetFieldsValidations(Number(this.model.FieldTypeCode), this.model.CommunicationTypeFieldsCode).subscribe(response => {
        if (response.IsSuccess) {

       

          this.validationsListViewModel = response.Data;
          this.validationsListViewModel.map(itm => {

            // For Date Range  -->FieldTypeEnum.DATE &&  itm.IsRange && !itm.IsForwardValue
            // For Date Min Max  --> FieldTypeEnum.DATE &&   itm.IsRange && itm.IsForwardValue

            itm.IsSelected = itm.code > 0 ? true : false,
              itm.Value = itm.FieldType == FieldTypeEnum.DATE && itm.IsRange && !itm.IsForwardValue ? new Date(itm.Value) : (FieldTypeEnum.DATE && itm.Value && itm.IsRange && itm.IsForwardValue ? String(itm.Value) : itm.Value);
            itm.DependentValue = itm.FieldType == FieldTypeEnum.DATE && itm.IsRange && !itm.IsForwardValue ? new Date(itm.DependentValue) : (FieldTypeEnum.DATE && itm.DependentValue && itm.IsRange && itm.IsForwardValue ? String(itm.DependentValue) : itm.DependentValue);
          })
          if (this.validationsListViewModel.length > 0) {
            this.AddRemoveValidation();

          }
        }
      })
    }
  }


  get fieldValidationControl() { return this.frmGrp.controls; }
  get dynamicValidationFormGroup() { return this.fieldValidationControl.ValidationsControls as FormArray; }


  AddRemoveValidation(isSelected: boolean = false, CurrentIndex = -1) {


    // if checked
    if (isSelected && CurrentIndex >= 0) {
      

      if (this.validationsListViewModel[CurrentIndex].IsLength || (this.validationsListViewModel[CurrentIndex].IsRange && !this.validationsListViewModel[CurrentIndex].IsForwardValue)) {

        this.dynamicValidationFormGroup.controls[CurrentIndex].get('Value').setValidators(Validators.compose([Validators.required, this.CompareMinValidator.bind(this)]));
        this.dynamicValidationFormGroup.controls[CurrentIndex].get('Value').updateValueAndValidity();
        this.dynamicValidationFormGroup.controls[CurrentIndex].get('DependentValue').setValidators(Validators.compose([Validators.required, this.CompareMaxValidator.bind(this)]));
        this.dynamicValidationFormGroup.controls[CurrentIndex].get('DependentValue').updateValueAndValidity();
        this.dynamicValidationFormGroup.controls[CurrentIndex].get('ValidationMessage').setValidators(null);
        this.dynamicValidationFormGroup.controls[CurrentIndex].get('ValidationMessage').updateValueAndValidity();

      }
      else if (this.validationsListViewModel[CurrentIndex].IsCustomRegex) {


        this.dynamicValidationFormGroup.controls[CurrentIndex].get('Value').setValidators(Validators.required);
        this.dynamicValidationFormGroup.controls[CurrentIndex].get('Value').updateValueAndValidity();
        this.dynamicValidationFormGroup.controls[CurrentIndex].get('ValidationMessage').setValidators(Validators.required);
        this.dynamicValidationFormGroup.controls[CurrentIndex].get('ValidationMessage').updateValueAndValidity();

      }

      else if ((this.validationsListViewModel[CurrentIndex].IsRange && this.validationsListViewModel[CurrentIndex].IsForwardValue)) {

        this.dynamicValidationFormGroup.controls[CurrentIndex].get('Value').setValidators(Validators.required);
        this.dynamicValidationFormGroup.controls[CurrentIndex].get('Value').updateValueAndValidity();

        let k = this.dynamicValidationFormGroup.controls[CurrentIndex]
        // this.dynamicValidationFormGroup.controls[CurrentIndex].get('DependentValue').setValidators(null);
        // this.dynamicValidationFormGroup.controls[CurrentIndex].get('DependentValue').updateValueAndValidity();

        this.dynamicValidationFormGroup.controls[CurrentIndex].get('ValidationMessage').setValidators(Validators.required);
        this.dynamicValidationFormGroup.controls[CurrentIndex].get('ValidationMessage').updateValueAndValidity();

      }

      else {

        
        this.dynamicValidationFormGroup.controls[CurrentIndex].get('ValidationMessage').setValidators(Validators.required);
        this.dynamicValidationFormGroup.controls[CurrentIndex].get('ValidationMessage').updateValueAndValidity();
      }

      this.validationsListViewModel.forEach((item, valIndex) => {
        
        if (item.FieldType == FieldTypeEnum.DATE) {

          if (valIndex != CurrentIndex && item.IsSelected == true) {
            item.IsSelected = false;
            item.ValidationMessage = undefined;
            item.code = undefined;
            item.Value = undefined;
            item.DependentValue = undefined;
            this.AddRemoveValidation(false, valIndex);
          }

        } else {


          if (valIndex != CurrentIndex && item.IsSelected == true && (item.IsRegex || item.IsCustomRegex) &&
            (this.validationsListViewModel[CurrentIndex].IsRegex || this.validationsListViewModel[CurrentIndex].IsCustomRegex)) {
            item.IsSelected = false;
            item.ValidationMessage = undefined;
            item.code = undefined;
            this.AddRemoveValidation(false, valIndex);
          }
        }
      }
      )

    }
    // if unchecked
    else if (!isSelected && CurrentIndex >= 0) {
      // if (this.validationsListViewModel[CurrentIndex].IsLength || (this.validationsListViewModel[CurrentIndex].IsRange && !this.validationsListViewModel[CurrentIndex].IsForwardValue)) {
      //   this.dynamicValidationFormGroup.controls[CurrentIndex].get('Value').setValidators(null);
      //   this.dynamicValidationFormGroup.controls[CurrentIndex].get('Value').updateValueAndValidity();
      //   this.dynamicValidationFormGroup.controls[CurrentIndex].get('DependentValue').setValidators(null);
      //   this.dynamicValidationFormGroup.controls[CurrentIndex].get('DependentValue').updateValueAndValidity();
      //   this.dynamicValidationFormGroup.controls[CurrentIndex].get('ValidationMessage').setValidators(null);
      //   this.dynamicValidationFormGroup.controls[CurrentIndex].get('ValidationMessage').updateValueAndValidity();

      // }
      // else if (this.validationsListViewModel[CurrentIndex].IsCustomRegex) {
      //   this.dynamicValidationFormGroup.controls[CurrentIndex].get('Value').setValidators(null);
      //   this.dynamicValidationFormGroup.controls[CurrentIndex].get('Value').updateValueAndValidity();

      //   this.dynamicValidationFormGroup.controls[CurrentIndex].get('ValidationMessage').setValidators(null);
      //   this.dynamicValidationFormGroup.controls[CurrentIndex].get('ValidationMessage').updateValueAndValidity();
      // }
      // else if ((this.validationsListViewModel[CurrentIndex].IsRange && this.validationsListViewModel[CurrentIndex].IsForwardValue)) {

      this.dynamicValidationFormGroup.controls[CurrentIndex].get('Value').setValidators(null);
      this.dynamicValidationFormGroup.controls[CurrentIndex].get('Value').updateValueAndValidity();
      this.dynamicValidationFormGroup.controls[CurrentIndex].get('DependentValue').setValidators(null);
      this.dynamicValidationFormGroup.controls[CurrentIndex].get('DependentValue').updateValueAndValidity();
      this.dynamicValidationFormGroup.controls[CurrentIndex].get('ValidationMessage').setValidators(null);
      this.dynamicValidationFormGroup.controls[CurrentIndex].get('ValidationMessage').updateValueAndValidity();

      // }


      // else {
      //   this.dynamicValidationFormGroup.controls[CurrentIndex].get('ValidationMessage').setValidators(null);
      //   this.dynamicValidationFormGroup.controls[CurrentIndex].get('ValidationMessage').updateValueAndValidity();
      // }
    }

    else {
      // validation initialize 
      this.validationsListViewModel.forEach((element, index) => {

        if (element.IsSelected || element.code > 0) {
          if ((this.validationsListViewModel[index].IsLength || (this.validationsListViewModel[index].IsRange && this.validationsListViewModel[index].FieldType != FieldTypeEnum.DATE))) {

            this.dynamicValidationFormGroup.push(this.fb.group({

              Value: [undefined, Validators.compose([Validators.required, this.CompareMinValidator.bind(this)])],
              DependentValue: [undefined, Validators.compose([Validators.required, this.CompareMaxValidator.bind(this)])],
              ValidationMessage: [undefined, null]

            }));

          }

          if (((this.validationsListViewModel[index].IsRange && this.validationsListViewModel[index].FieldType == FieldTypeEnum.DATE))) {

            if (this.validationsListViewModel[index].IsForwardValue) {

              this.dynamicValidationFormGroup.push(this.fb.group({
                Value: [undefined, Validators.compose([Validators.required, this.CompareMinValidator.bind(this)])],
                DependentValue: [undefined, null],
                ValidationMessage: [undefined, null]

              }));

            } else {

              this.dynamicValidationFormGroup.push(this.fb.group({
                Value: [undefined, Validators.compose([Validators.required, this.CompareMinValidator.bind(this)])],
                DependentValue: [undefined, Validators.compose([Validators.required, this.CompareMaxValidator.bind(this)])],
                ValidationMessage: [undefined, null]

              }));
            }
          }

          else if (this.validationsListViewModel[index].IsCustomRegex) {

            this.dynamicValidationFormGroup.push(this.fb.group({

              Value: [undefined, Validators.required],
              DependentValue: [undefined, null],
              ValidationMessage: [undefined, Validators.required]
            }));

          }
          else {

            this.dynamicValidationFormGroup.push(this.fb.group({

              Value: [undefined, null],
              DependentValue: [undefined, null],
              ValidationMessage: [undefined, Validators.required]
            }));

          }

        }

        else {

          this.dynamicValidationFormGroup.push(this.fb.group({
            DependentValue: [undefined, null],
            Value: [undefined, null],
            ValidationMessage: [undefined, null]
          }));
        }
      });

    }


  }

  CompareMinValidator(control: FormControl): any {

    if (control.pristine) {
      return null;
    }

    const minValue = control.value;
    const MaxValue = control.parent.value.DependentValue;
    if (minValue != undefined && MaxValue != undefined && Number(MaxValue) < Number(minValue)) {
      return { compareMinValidationError: true }
    }

    return null;
  }

  CompareMaxValidator(control: FormControl): any {

    if (control.pristine) {
      return null;
    }
    const minValue = control.parent.value.Value;
    const maxValue = control.value;
    if (minValue != undefined && maxValue != undefined && Number(minValue) > Number(maxValue)) {
      return { compareMaxValidationError: true }
    }


    return null;
  }

  regexTest(control: FormControl): any {
    if (control.pristine) {
      return null;
    }


    var regex = /^((?:(?:[^?+*{}()[\]\\|]+|\\.|\[(?:\^?\\.|\^[^\\]|[^\\^])(?:[^\]\\]+|\\.)*\]|\((?:\?[:=!]|\?<[=!]|\?>)??\)|\(\?(?:R|[+-]?\d+)\))(?:(?:[?+*]|\{\d+(?:,\d*)?\})[?+]?)?|\|)*)$/;
    var isValid = regex.test(control.value);

    if (!isValid) {

      return { patternError: true };
    }


    return null;

  }


  getDynamicField() {
    this._communicationTypeFieldsMasterService.GetDynamicFieldsByTypeCode(this.model.CommunicationTypeCode).subscribe(result => {
      if (result.IsSuccess) {
        var data = result.Data as CommunicationTypeFieldsMasterModel[];
        data = data.filter(x => x.FieldType == FieldTypeEnum.DATE && (this.recordId > 0 ? x.CommunicationTypeFieldsCode != this.recordId : true))
        this.ddlDynamicFields = [];
        if (data.length > 0) {
          data.forEach(element => {
            let item = new DdlItemModel();
            item.Text = element.FieldLabel;
            item.Value = String(element.CommunicationTypeFieldsCode);
            this.ddlDynamicFields.push(item);
          });
        }
      }
    }, error => {
      this._commonService.ScrollingTop();
      this._alertService.error(error.message);
    });


  }


}
