import { Injectable } from '@angular/core';
import { AppSetting } from '../../model/appsetting.model';
import { IndexModel } from '../../model/common.model';
import { PermissionMasterModel } from '../../model/master/permission-master.model';
import { PageAuthenticationModel } from '../../model/page-authentication.model';
import { BaseService } from '../base.service';

@Injectable({
  providedIn: 'root'
})
export class PermissionMasterService {

  constructor(private readonly _baseService: BaseService,) { }

  GetList(model: IndexModel) {
    return this._baseService.post(AppSetting.PermissionMasterListUrl, model);
  }

  Add(model: PermissionMasterModel) {
    return this._baseService.post(AppSetting.PermissionMasterAddUrl, model);
  }

  Detail(id: number) {
    return this._baseService.get(AppSetting.PermissionMasterDetailUrl + id)
  }

  Edit(id: number, model: PermissionMasterModel) {
    return this._baseService.post(AppSetting.PermissionMasterEditUrl + id, model);
  }

  ChangeDeleteStatus(id: number) {
    return this._baseService.get(AppSetting.PermissionMasterDeleteStatusChangeUrl + id);
  }

  ChangeActiveStatus(id: number) {
    return this._baseService.get(AppSetting.PermissionMasterActiveStatusChangeUrl + id);
  }

  CheckPageAuthentication(id: number, pass: string) {
    return this._baseService.get(AppSetting.PermissionMasterAuthenticationUrl + id + "&pass=" + pass);
  }

  SavePageAuthenticationDetail(id: number, hasAuth: boolean, pass: string) {
    return this._baseService.post(AppSetting.SavePageAuthenticationDetailUrl + "?id=" + id + "&hasAuth=" + hasAuth + "&pass=" + pass, null);
  }


  IsPageAuthentication(model: PageAuthenticationModel) {
    return this._baseService.post(AppSetting.PermissionMasterAuthenticationUrl, model);
  }

}
