export class GeneralEntryCategoryMaster {
}
export class GeneralEntryCategoryMasterModel {
  CategoryId: number;
  CategoryCode: number;
  Title: string;
  TitleHindi: string;
  CreatedBy: number;
  ModifiedBy: number;
  CreatedDate: Date | string;
  ModifiedDate: Date | string;
  IsActive: boolean;
  IsDeleted: boolean;
  ImagePath: string;
  IsVisible: boolean;
  CategoryIsVisible: boolean;
  IsVisibleToEndUser: boolean;
  HelpFileURL: string;
  IsVisibleDate: boolean;
  IsVisibleDescriptionHindi: boolean;
  LabelGeneralEntryHindi: string;
  LabelDescriptionHindi: string;
  LabelDate: string;
  LabelURL: string;
  LabelAddPDF: string;
  LabelAttachImage: string;
  IsShowConnectWithCMIS: boolean;
  IsShowBeneficiaryCategory: boolean;
  IsPDFMandatory: boolean;
  IsURLMandatory: boolean;
  IsImageMandatory: boolean;
  MenuClassificationCode: number | string;
  MenuClassificationPageTypeCode: number | string;
  GeneralDepartmentDistrictMapping: number | string;
  GeneralDepartmentDistrictMappingList: string[] | number[];
  SubMenuNameHindi: string;
  SubMenuNameEnglish: string;
  ClassificationPageTypeName: string;
  MenuClassificationName: string;
  SSOID: string;
  IsShowTimeSlotCode: boolean;
  IsTimeSlotCodeMandatory: boolean;

  constructor() {
    this.LabelGeneralEntryHindi = "General Entry Hindi";
    this.LabelAddPDF = "Add PDF";
    this.LabelAttachImage = "Attach Image";
    this.LabelDate = "Date";
    this.LabelDescriptionHindi = "Description Hindi";
    this.LabelURL = "URL";
    this.IsVisibleDate = true;
    this.IsVisibleDescriptionHindi = true;
  }
}