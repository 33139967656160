import { Injectable } from '@angular/core';
import { AppSetting } from '../../model/appsetting.model';
import { IndexModel } from '../../model/common.model';
import { Dictionary } from '../../model/dictionary.model';
import { AdminDepartmentMasterModel } from '../../Model/Master/admin-department.model';
import { BaseService } from '../base.service';
// import { BaseService } from './base.service';
// import { AppSetting } from '../Model/appsetting';
// import { IndexModel } from '../Model/general-model';
// import { AdminDepartmentMasterModel } from '../Model/Master/admin-department.model';

@Injectable({
  providedIn: 'root'
})
export class AdminDepartmentService {

  constructor(private readonly _baseService: BaseService) {

  }

 GetList(model: IndexModel) {

   return this._baseService.post(AppSetting.AdminDepartmentListUrl, model);
 }

 Add(model: AdminDepartmentMasterModel) {
   return this._baseService.post(AppSetting.AdminDepartmentAddUrl, model);
 }

 Edit(model: AdminDepartmentMasterModel) {
   return this._baseService.post(AppSetting.AdminDepartmentUpdateUrl, model);
 }

 ChangeActiveStatus(id: number) {
   return this._baseService.get(AppSetting.AdminDepartmentUpdateStatusUrl + id);
 }

 GetById(id: number) {
   return this._baseService.get(AppSetting.AdminDepartmentGetByIdUrl + id);
 }
 
IsAdminDepartmentExist(name: string,id:number){
  const param = new Dictionary<any>();
  param.Add("name", name);
  param.Add("id", id);
  
  return this._baseService.get(AppSetting.IsAdminDepartmentMasterExistUrl ,param);
}
}
