import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { IndexModel } from '../model/common.model';
import { AppSetting } from '../model/appsetting.model';
import { BankAccountModel } from '../model/bank-account.model';

@Injectable({
    providedIn: 'root'
})

export class BankAccountService {

    constructor(private readonly _baseService: BaseService) { }

    GetList(id: number, model: IndexModel) {
        return this._baseService.post(AppSetting.BankAccountListUrl + id, model);
    }

    AddUpdate(id: number, model: BankAccountModel) {
        return this._baseService.post(AppSetting.BankAccountAddUpdateUrl, model);
    }

    Detail(id: number) {
        return this._baseService.get(AppSetting.BankAccountDetailUrl + id);
    }

    ChangeActiveStatus(id: number) {
        return this._baseService.get(AppSetting.BankAccountActiveStatusChangeUrl + id);
    }

    ChangeDeleteStatus(id: number) {
        return this._baseService.get(AppSetting.BankAccountDeleteStatusChangeUrl + id);
    }

    IsVendorBankAccountExist(bankAccountNo: string, vendorCode: number) {
        return this._baseService.get(AppSetting.IsVendorBankAccountExistUrl + bankAccountNo + '&vendorCode=' + vendorCode);
    }

    GetBankBranchDetailByBranchCode(id: any) {
        return this._baseService.get(AppSetting.BankBranchDetailByBranchCodeUrl + id);
    }

    GetBankBranchDetailByIFSC(id: string) {
        return this._baseService.get(AppSetting.BankBranchDetailByIFSCUrl + id);
    }

}