import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AppSetting } from 'src/app/shared/model/appsetting.model';
import { GlobalMessagesModel } from 'src/app/shared/model/common-messages.model';
import { DDLModel } from 'src/app/shared/model/commonddl.model';
import { ProjectClearModel } from 'src/app/shared/model/project.model';
import { AlertService } from 'src/app/shared/service/alert.service';
import { CommonService } from 'src/app/shared/service/common.service';
import { ProjectService } from 'src/app/shared/service/project.service';

@Component({
  selector: 'app-clear-project-popup',
  templateUrl: './clear-project-popup.component.html',
  styleUrls: ['./clear-project-popup.component.scss']
})
export class ClearProjectPopupComponent implements OnInit {
  frmGrp: FormGroup;
  public ddlList = new DDLModel();
  public model: ProjectClearModel = new ProjectClearModel()
  constructor(
    private readonly _alertService: AlertService,
    private _commonService: CommonService,
    private fb: FormBuilder,
    private projectService: ProjectService,
    private readonly _router: Router,
    public dialogRef: MatDialogRef<ClearProjectPopupComponent>
  ) { }

  ngOnInit() {
    this.GetVendorList();
    this.GetDDLList();
    this.formGroupInit();
  }

  GetVendorList() {
    this._commonService.GetAllDDL(AppSetting.VendorCommunicationPermissionDdl).subscribe(
      response => {
        if (response.IsSuccess) {
          let data = <DDLModel>response.Data;
          this.ddlList.ddlVendor = data.ddlVendor;

        }
      },
      error => {
        this._alertService.error(error.message);
      }
    );
  }

  GetDDLList() {
    this._commonService.GetAllDDL(AppSetting.VendorCommunicationPermissionDdl).subscribe(
      response => {
        if (response.IsSuccess) {
          let data = <DDLModel>response.Data;

          this.ddlList.ddlDepartment = data.ddlDepartment;


        }
      },
      error => {
        this._alertService.error(error.message);
      }
    );
  }

  formGroupInit() {
    this.frmGrp = this.fb.group({
      DepartmentCode: [this.model.DepartmentCode],
      VendorCode: [this.model.VendorCode]

    });
  }

  public onSubmit() {
    if (this.frmGrp.valid) {
      this.projectService.ProjectClear(this.model).subscribe(data => {
        if (data) {
          if (data.IsSuccess) {
            this._alertService.success(GlobalMessagesModel.deleteSuccess);
            this.dialogRef.close();
          }
          else {
            this._alertService.error('Projects are being used in communication.');
          }
        }
      });
    }
  }
}
