import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FileUploaderModel } from 'src/app/shared/model/file-uploader.model';
import { CommonService } from 'src/app/shared/service/common.service';

@Component({
  selector: 'app-file-uploader',
  templateUrl: './file-uploader.component.html',
  styleUrls: ['./file-uploader.component.scss']
})
export class FileUploaderComponent {

  @Input() label: string = 'Attachment';
  @Input() isBase64: boolean = false;
  @Input() isRequired: boolean = false;
  @Input() isMultiple: boolean = false;
  @Input() letterPath: string | null = "";
  @Input() acceptFileTypes: string[] = ['png', 'jpg', 'jpeg', 'pdf'];  // ['pdf', 'xls', 'xlsx', 'doc', 'docx', 'png', 'jpg', 'mp3', 'mp4', 'video/*', 'image/*']

  @Input() files: FileUploaderModel[] = [];
  @Output() fileSelected = new EventEmitter<FileUploaderModel[]>();
  @Output() isFileDeleted = new EventEmitter<boolean>();

  error: string = '';
  maxFileSizeInMB: number = 5;
  maxFileSizeInBytes: number = 5000000;

  @ViewChild('fileInput', { static: false }) fileInput!: ElementRef;
  @ViewChild('btnCloseFileViewPopup', { static: false }) btnCloseFileViewPopup!: ElementRef;

  public constructor(
    private readonly _commonService: CommonService
  ) {
    this.maxFileSizeInMB = this._commonService.getMaxFileSizeInMB();
    this.maxFileSizeInBytes = this.maxFileSizeInMB * 1000000;
  }

  public selectFile(event: any) {
    this.error = '';


    const selectedFiles = event.target.files;
    if (!selectedFiles || selectedFiles.length === 0) {
      return;
    }

    if (!this.isMultiple) {
      this.files = [];
    }

    let emitPromise = new Promise((resolve, reject) => {
      for (let index = 0; index < selectedFiles.length; index++) {
        const file = selectedFiles.item(index);
        if (!file) {
          continue;
        }

        const ext = file.name.split('.').pop().toLowerCase();
        if (this.acceptFileTypes && this.acceptFileTypes.length > 0 && !this.acceptFileTypes.includes(ext)) {
          this.error = 'Only ' + this.acceptFileTypes.join(', ') + ' files are allowed.';
          continue;
        }

        if (file.size > this.maxFileSizeInBytes) {
          this.error = 'File size must be less than ' + this.maxFileSizeInMB + 'MB';
          continue;
        }



        let reader = new FileReader();
        reader.onload = (e: any) => {
          this.files.push({
            Id: 0,
            Name: file.name,
            Path: <string>reader.result,
            Base64: this.isBase64 ? <string>reader.result : '',
            File: <File>file
          });

          console.log('files', this.files);

          this.fileSelected.emit(this.files);
        }
        reader.readAsDataURL(file);
      }
    })



    this.fileInput.nativeElement.value = '';


  }

  public removeFile(index: number) {

    this.files.splice(index, 1);

    if (this.isRequired && (!this.files || this.files.length == 0)) {
      this.error = 'Required';
    }

    if (this.files !== undefined && this.files !== null && this.files.length === 0) {
      this.fileSelected.emit(this.files);
      this.isFileDeleted.emit(true);
    }

  }

}
