import { Component, Inject, OnInit } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { mode } from "crypto-js";
import { AppComponent } from "src/app/app.component";
import { UserTypeEnum } from "src/app/shared/enum/fixed-values.enum";
import { AppSetting } from "src/app/shared/model/appsetting.model";
import { UserPreferLookupViewModel } from "src/app/shared/model/B2G/preference-list";
import { GlobalMessagesModel } from "src/app/shared/model/common-messages.model";
import { DdlItemModel, DDLModel } from "src/app/shared/model/commonddl.model";
import { UserViewModel } from "src/app/shared/model/user.model";
import { AlertService } from "src/app/shared/service/alert.service";
import { AuthenticationService } from "src/app/shared/service/authentication.service";
import { PreferenceService } from "src/app/shared/service/B2G/preference.service";
import { CommonService } from "src/app/shared/service/common.service";
import { Validator } from '../../../../shared/model/dynamic-field-config.model';



@Component({
  selector: 'app-add-update-preference',
  templateUrl: './add-update-preference.component.html',
  styleUrls: ['./add-update-preference.component.scss']
})
export class AddUpdatePreferenceComponent implements OnInit {

  //#region <<Variable>>
  userTypeEnum = UserTypeEnum;
  preferType: string = "Office";
  loginData: UserViewModel;
  ddlList: DDLModel = new DDLModel;
  fromGroup: FormGroup;
  model: UserPreferLookupViewModel = new UserPreferLookupViewModel();
  RecordId: number = 0;
  loginUserCode: number;
  submitBtn = true;
  ddlUserOffice: DdlItemModel[];
  //#endregion

  constructor(
    private readonly fb: FormBuilder,
    public readonly _commonService: CommonService,
    private readonly _alertService: AlertService,
    private readonly _preferenceService: PreferenceService,
    private readonly _authService: AuthenticationService,
    public readonly _dialogRef: MatDialogRef<AddUpdatePreferenceComponent>    
  ) {
    this.loginData = this._authService.GetCurrentUserDetail().UserViewModel;
    this.GetDDLList();
  }

  ngOnInit() {
    this.formGroupInit();

    this.getDetail();
    this.getUserOffice();
    this.UpdateValidation();
  }
  getUserOffice() {
    if (this.loginData.DepartmentCodes != null) {

      this.GetOfficeByDepartment(Number(this.loginData.DepartmentCodes), true)
    }
  }
  formGroupInit() {
    this.fromGroup = this.fb.group({
      UserOfficeCode: [null, null],
      DepartmentCodes: [null, null],
      OfficeCodeIds: [null, null],
      VendorCodeIds: [null, null],
      PreferType: [undefined, null],
    });
  }

  GetDDLList() {
    this._commonService.GetAllDDL(AppSetting.PreferenceDataDropdownKeys).subscribe(
      data => {
        if (data.IsSuccess) {
          let ddlList = <DDLModel>data.Data;
          this.ddlList.ddlAllOfficeDepartment = ddlList.ddlAllOfficeDepartment;
          this.ddlList.ddlVendor = ddlList.ddlVendor;
          this.removeExistingUser(ddlList.ddlVendor)

        }
      },
      error => {
        this._alertService.error(error.message);
      }
    );
  }

  getDetail() {
    let userOfficeCode = 0;
    //#region  office list for Vendor
    if (this.preferType == 'Office') {
      userOfficeCode = 0;
    } else {
      userOfficeCode = (Number)(this.loginData.OfficeCodes.split(',')[0]);
    }
    //#endregion
    //#region Office list for office
    this._preferenceService.GetList(userOfficeCode).subscribe(
      data => {
        if (data.IsSuccess) {
          this.model = <UserPreferLookupViewModel>data.Data;
          if (this.model.DepartmentCodes) {
            //  this.model.DepartmentCodeIds = this.model.DepartmentCodes.split(',');
          }
          if (this.model.OfficeCodes) {
            this.model.OfficeCodeIds = this.model.OfficeCodes.split(',');
          }
          if (this.model.VendorCodes) {
            this.model.VendorCodeIds = this.model.VendorCodes.split(',');
            this.removeExistingUser(this.ddlList.ddlVendor)
          }
        }
      },
      error => {
        this._alertService.error(error.message);
      }
    );
  }

  saveClick() {
    
    this.submitBtn = false;
    this.model.CreatedBy = this.loginData.UserCode;
    this.fromGroup.markAllAsTouched();
    if (this.fromGroup.valid) {

      this.model.CreatedBy = this.loginData.UserCode;
      //#region  office list for Vendor
      if (this.preferType == 'Office' && !this.IsVendorTypeUser) {
        this.model.OfficeCodes = this.model.OfficeCodeIds.toString();
        this.model.VendorCodes = '';
        this.model.UserOfficeCode = this.IsVendorTypeUser ? null : this.model.UserOfficeCode////#region Office list for office;
      }
      if (this.IsVendorTypeUser) {
        this.model.OfficeCodes = this.model.OfficeCodeIds.toString();
        this.model.VendorCodes = '';
        this.model.UserOfficeCode = null;
      }
      //#endregion
      //#region Vendor list for office
      if (this.preferType == 'Vendor' && !this.IsVendorTypeUser) {
        this.model.OfficeCodes = '';
        this.model.VendorCodes = this.model.VendorCodeIds.toString();
        this.model.UserOfficeCode = this.model.UserOfficeCode;
      }
      //#endregion


      this._preferenceService.AddUpdate(this.model).subscribe(
        data => {
          if (data) {
            if (data.IsSuccess) {
              this._alertService.success(data.Message);
              this._dialogRef.close(true);

            } else {
              this.submitBtn = true;
              this._alertService.error(data.Message);
            }
          } else {
            this._commonService.ScrollingTop();
            this._alertService.error(GlobalMessagesModel.InternalError);
          }
        },
        error => {
          this.submitBtn = true;
          this._alertService.error(error.message);
        }
      );
    }
  }

  removeExistingUser(list: any) {
    if (this.model.VendorCodeIds == undefined || this.model.VendorCodeIds.length == 0) {
      this.model.VendorCodeIds = ['-1'];
    }
    this.ddlList.ddlVendor = list.filter(
      item => this.model.VendorCodeIds.indexOf(item.Value) == -1
    );
  }
  GetOfficeByDepartment(val: number, isUSerOffice: boolean) {

    if (val && val > 0) {

      let ddlkey = isUSerOffice ? AppSetting.ddlOfficeByLoginUserId : AppSetting.ddlDepartmentOffice;

      this._commonService.GetDDL(ddlkey, val).subscribe(
        data => {
          if (data.IsSuccess) {
            var ddl = <DDLModel>data.Data;
            if (isUSerOffice) {
              this.ddlUserOffice = ddl.ddlOfficeByLoginUserId;
            }
            else {
              if (this.model.OfficeCodeIds == undefined || this.model.OfficeCodeIds.length == 0) {
                this.model.OfficeCodeIds = ['-1'];
              }
              this.ddlList.ddlDepartmentOffice = ddl.ddlDepartmentOffice.filter(
                item => this.model.OfficeCodeIds.indexOf(item.Value) == -1
              );
            }
          }
        },
        error => {
          this._alertService.error(error.message);
        }
      );
    }
    else {
      this.ddlList.ddlDepartmentOffice = null;
    }
  }

  onNoClick(): void {
    this._dialogRef.close();
  }
  get IsVendorTypeUser(): boolean {
    return (this.loginData.BaseUserType == this.userTypeEnum.VNDR) || (this.loginData.BaseUserType == this.userTypeEnum.VNDRUSER);
  }

  UpdateValidation() {

    let UserOfficeCodeField = this.fromGroup.get("UserOfficeCode");
    let DepartmentCodesField = this.fromGroup.get("DepartmentCodes");
    let OfficeCodeIdsField = this.fromGroup.get("OfficeCodeIds");
    let VendorCodeIdsField = this.fromGroup.get("VendorCodeIds");

    OfficeCodeIdsField.setValidators([undefined]);
    DepartmentCodesField.setValidators([undefined]);
    UserOfficeCodeField.setValidators([undefined]);
    VendorCodeIdsField.setValidators([undefined]);



    if (this.preferType == 'Office' && !this.IsVendorTypeUser) {
      OfficeCodeIdsField.setValidators([Validators.required]);
      DepartmentCodesField.setValidators([Validators.required]);
      UserOfficeCodeField.setValidators([Validators.required]);

    }
    if (this.IsVendorTypeUser) {
      OfficeCodeIdsField.setValidators([Validators.required]);
      DepartmentCodesField.setValidators([Validators.required]);

    }

    if (this.preferType == 'Vendor' && !this.IsVendorTypeUser) {
      UserOfficeCodeField.setValidators([Validators.required]);
      VendorCodeIdsField.setValidators([Validators.required]);

    }

    UserOfficeCodeField.updateValueAndValidity();
    DepartmentCodesField.updateValueAndValidity();
    OfficeCodeIdsField.updateValueAndValidity();
    VendorCodeIdsField.updateValueAndValidity();

  }

  onSelectAllVendor() {
    if (this.model.VendorCodeIds.length != this.ddlList.ddlVendor.length) {
      this.model.VendorCodeIds = this.ddlList.ddlVendor.map(function (a) {
        return a.Value;
      });
    }
    else {
      this.model.VendorCodeIds = [];
    }
  }

  onSelectAllOffice() {
    if (this.model.OfficeCodeIds.length != this.ddlList.ddlDepartmentOffice.length) {
      this.model.OfficeCodeIds = this.ddlList.ddlDepartmentOffice.map(function (a) {
        return a.Value;
      });
    }
    else {
      this.model.OfficeCodeIds = [];
    }
  }

}



