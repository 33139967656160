import { Component, OnInit, Inject } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { Router } from "@angular/router";
import { GlobalMessagesModel } from "src/app/shared/model/common-messages.model";
import { DDLModel } from "src/app/shared/model/commonddl.model";
import { CommunicationTypeDefaultFieldsMasterModel, CommunicationDefaultFieldConfigPostModel } from "src/app/shared/model/master/communication-type-fields-master.model";
import { UserViewModel } from "src/app/shared/model/user.model";
import { AlertService } from "src/app/shared/service/alert.service";
import { AuthenticationService } from "src/app/shared/service/authentication.service";
import { CommonService } from "src/app/shared/service/common.service";
import { CommunicationTypeFieldsMasterService } from "src/app/shared/service/master/communication-type-fields-master.service";

@Component({
  selector: 'app-communication-default-field-config',
  templateUrl: './communication-default-field-config.component.html',
  styleUrls: ['./communication-default-field-config.component.scss']
})
export class CommunicationDefaultFieldConfigComponent implements OnInit {
  loginUserDetail: UserViewModel;

  RecordFieldCode = 0;
  ddlSortOrder: string[] = [];
  frmGrp: FormGroup;
  defaultFieldModel: CommunicationTypeDefaultFieldsMasterModel[] = [];
  ddlList: DDLModel = new DDLModel();
  model: CommunicationDefaultFieldConfigPostModel = new CommunicationDefaultFieldConfigPostModel();

  constructor(private readonly fb: FormBuilder,
    public readonly _commonService: CommonService,
    private readonly _alertService: AlertService,
    public _dialogRef: MatDialogRef<CommunicationDefaultFieldConfigComponent>,
    private readonly _communicationTypeFieldsMasterService: CommunicationTypeFieldsMasterService,
    private readonly _router: Router,
    private readonly _authService: AuthenticationService,
    @Inject(MAT_DIALOG_DATA) public data: any) {

    this.loginUserDetail = this._authService.GetCurrentUserDetail().UserViewModel;

    if (data) {
      
      if (data.FieldCode) {
        this.RecordFieldCode = data.FieldCode;
      }


      if (data.FieldModel) {
        this.defaultFieldModel = data.FieldModel;
        this.bindDetail();
      }


      if (data.CommunicationTypeCode) {
        this.model.CommunicationTypeCode = data.CommunicationTypeCode;
      }

    } else {
      this._dialogRef.close();
    }
  }
  ngOnInit() {
    this.formGroupInit();
    this.bindDDLSortOrder();
  }

  formGroupInit() {
    this.frmGrp = this.fb.group({
      // FieldCode: [null, Validators.required],
      SortOrder: [null, Validators.required],
      FieldLabel: [null, Validators.required],
      IsRequired: [false, null]
    });
  }

  bindDetail() {
    
    let data = this.defaultFieldModel.find(x => x.FieldCode == this.RecordFieldCode);
    this.model.FieldCode = data.FieldCode;
    this.model.CommunicationTypeCode = data.CommunicationTypeCode;
    this.model.FieldLabel = data.FieldName;
    this.model.SortOrder = String(data.SortOrder);
    this.model.IsRequired = data.IsRequired;
  }


  //#region <Field Setting>
  checkExistingSortOrder(value: string) {

    return this.model.SortOrder == value || (!this.defaultFieldModel.find(a => a.FieldCode != this.model.FieldCode && a.SortOrder && a.SortOrder == Number(value)));
  }
  bindDDLSortOrder() {
    this.defaultFieldModel.forEach((item, i) => {
      this.ddlSortOrder.push(String((i + 1)));
    });
  }


  saveClick() {
    
    this.frmGrp.markAllAsTouched();
    if (this.frmGrp.valid) {
      this._communicationTypeFieldsMasterService.ConfigCommunicationAssignField(this.model).subscribe(
        data => {
          if (data.IsSuccess) {

            this._alertService.success(GlobalMessagesModel.saveSuccess);
            this._dialogRef.close(true);
          } else {
            this._alertService.error(data.Message);
            this._alertService.error(GlobalMessagesModel.saveError);
          }
        },
        error => {
          this._alertService.error(error.message);
        }
      );
    }
  }

  CloseDialog(): void {
    this._dialogRef.close();
  }


  //#endregion



}
