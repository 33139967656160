import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AppComponent } from 'src/app/app.component';
import { DistrictModel } from 'src/app/shared/model/master/district.model';
import { AlertService } from 'src/app/shared/service/alert.service';
import { DistrictService } from 'src/app/shared/service/master/district.service';

@Component({
  selector: 'app-district-detail',
  templateUrl: './district-detail.component.html',
  styleUrls: ['./district-detail.component.scss']
})

export class DistrictDetailComponent implements OnInit {

  recordId: number = 0;
  model: DistrictModel = new DistrictModel();

  constructor(
    private readonly _route: ActivatedRoute,
    private readonly _appComponent: AppComponent,
    private readonly _distService: DistrictService,
    private readonly _alertService: AlertService
  ) {
    this.recordId = this._route.snapshot.params.id;
    this._appComponent.setPageLayout("District Detail :", "keyboard_backspace", "Back To List", "master/district");
  }

  ngOnInit() {
    this.getDetail();
  }

  getDetail() {
    if (this.recordId > 0) {
      this._distService.GetDetail(this.recordId).subscribe(data => {
        if (data.IsSuccess) {
          this.model = <DistrictModel>data.Data;
        }
      }, error => {
        this._alertService.error(error.message);
      });
    }
  }

}
