export class ProjectSubCategoryModel {
    SubCategoryCode: number;
    SubCategory: string;
    SubCategoryHindi: string;
    ProjectCategoryCode: number | string;
    IsActive: boolean;
    IsDeleted: boolean;
    CreatedBy: number | null;
    CreatedOn: Date | null;
    ModifiedBy: number | null;
    ModifiedOn: Date | null;
    Category: string;
    CreatedByUser: string;
    ModifiedByUser: string;
}
