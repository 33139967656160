import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MatPaginator, MatSort, MatTableDataSource, MAT_DIALOG_DATA } from '@angular/material';
import { Router } from '@angular/router';
import { AppSetting } from 'src/app/shared/model/appsetting.model';
import { DDLModel, DdlItemModel, ColumnHeaderModel } from 'src/app/shared/model/commonddl.model';
import { CommunicationModel } from 'src/app/shared/model/communication.model';
import { GlobalFilterSearchModel } from 'src/app/shared/model/Global-communication-search.model';
import { UserTypeModel } from 'src/app/shared/model/user-type.model';
import { AlertService } from 'src/app/shared/service/alert.service';
import { AuthenticationService } from 'src/app/shared/service/authentication.service';
import { CommonService } from 'src/app/shared/service/common.service';
import { CommunicationService } from 'src/app/shared/service/communication.service';
import { UserService } from 'src/app/shared/service/user.service';
import { isNullOrUndefined } from 'util';

@Component({
  selector: 'app-search-communication-dialog',
  templateUrl: './search-communication-dialog.component.html',
  styleUrls: ['./search-communication-dialog.component.scss']
})
export class SearchCommunicationDialogComponent implements OnInit {
  loginUserCode: number = 0;
  loginBaseUserType: string;
  ddlList = new DDLModel();
  ddlAdminDepartment: DdlItemModel[] = [];
  searchModel = new GlobalFilterSearchModel();
  ddlUserType: DdlItemModel[] = [];
  userTypeList: UserTypeModel[] = [];
  fromDate: Date | string;
  toDate: Date | string;
  ddlWorkOrder: DdlItemModel[] = [];
  ddlDistrict: DdlItemModel[] = [];
  model: CommunicationModel[] = [];
  dataSource: any;
  totalRecords: number;
  displayedColumns: string[] = ["index","Subject", "DepartmentName", "OfficeName", "CommunicationType", "RefNo", "CurrentStatus", "LastActionDate", "CreatedOn", "IsActive", "Select"];
  columnsToDisplay: string[] = this.displayedColumns.slice();
  ViewdisplayedColumns: ColumnHeaderModel[] = [
    
    { Value: "Subject", Text: "Subject" },
    { Value: "DepartmentName", Text: "Department" },
    { Value: "OfficeName", Text: "Office" },
    { Value: "CommunicationType", Text: "Communication Type" },
    { Value: "RefNo", Text: "References No." },
    { Value: "CurrentStatus", Text: "Status" },

  ];

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(public _dialogRef: MatDialogRef<SearchCommunicationDialogComponent>,
    private readonly _userService: UserService,
    public readonly _commonService: CommonService,
    private readonly _authService: AuthenticationService,
    private readonly _alertService: AlertService,
    private readonly _communicationService: CommunicationService,
    private readonly _router: Router,
    @Inject(MAT_DIALOG_DATA) public data: GlobalFilterSearchModel) {

    this.getAdminDepartment();
    this.GetDistrict();
    this.GetDDLList();
    if (data != null) {
      this.searchModel = data;
    }
  }

  ngOnInit() {
    this.GetDepartment();
    this.searchModel.indexModel.OrderBy = "CreatedOn";
    this.searchModel.indexModel.OrderByAsc = 0;
    if (this.searchModel.DepartmentCode) {
      this.GetProjectListByDepartmentWise();
      this.getOfficeList();
    }
    this.GetGlobalSearchData()

  }

  GetWorkOrderByproject() {
    if (
      !isNullOrUndefined(
        this.searchModel.ProjectCode && this.searchModel.ProjectCode > 0
      )
    ) {
      this._commonService
        .GetDDLMultipleValue(
          AppSetting.ddlWorkOrder,
          this.searchModel.ProjectCode.toString(),
          this.loginUserCode
        )
        .subscribe(
          (data) => {
            if (data.IsSuccess) {
              var ddl = <DDLModel>data.Data;
              this.ddlList.ddlWorkOrder = ddl.ddlWorkOrder;
            }
          },
          (error) => {
            this._alertService.error(error.message);
          }
        );
    } else {
      this.ddlList.ddlWorkOrder = null;
    }
  }

  getAdminDepartment() {
    this._userService.GetUserAdminDepartmentList().subscribe(
      (data) => {
        if (data.IsSuccess) {
          this.ddlAdminDepartment = <DdlItemModel[]>data.Data;
        }
      },
      (error) => {
        this._alertService.error(error.message);
      }
    );
  }

  GetProjectListByDepartmentWise() {
    if (
      !isNullOrUndefined(this.searchModel.DepartmentCode) &&
      this.searchModel.DepartmentCode.length > 0
    ) {
      this._commonService
        .GetDDLMultipleValue(
          AppSetting.ddlProject,
          this.searchModel.DepartmentCode.toString(),
          this.loginUserCode
        )
        .subscribe(
          (data) => {
            if (data.IsSuccess) {
              var ddl = <DDLModel>data.Data;
              this.ddlList.ddlProject = ddl.ddlProject;
            }
          },
          (error) => {
            this._alertService.error(error.message);
          }
        );
    }
  }

  GetDDLList() {
    this._commonService.GetAllDDL(AppSetting.GlobalFilterKey).subscribe(
      (data) => {
        if (data.IsSuccess) {
          this.ddlList = <DDLModel>data.Data;


        }
      },
      (error) => {
        this._alertService.error(error.message);
      }
    );
  }

  GetDepartment() {
    this._userService.loginUserCode = this.loginUserCode; //todo for assign admin department code
    this._userService
      .GetUserDepartmentList(this.searchModel.AdminDepartmentCode ? this.searchModel.AdminDepartmentCode : 0)
      .subscribe(
        (data) => {
          if (data.IsSuccess) {
            this.ddlList.ddlOfficeDepartment = <DdlItemModel[]>data.Data;
          }
        },
        (error) => {
          this._alertService.error(error.message);
        }
      );
  }

  GetDistrict() {

    this._userService.loginUserCode = this.loginUserCode;
    this._userService.GetUserDistrictList(20).subscribe(
      (data) => {
        if (data.IsSuccess) {
          this.ddlDistrict = <DdlItemModel[]>data.Data;
        }
      },
      (error) => {
        this._alertService.error(error.message);
      }
    );
  }

  getOfficeList() {
    let deptCode = isNullOrUndefined(this.searchModel.DepartmentCode)
      ? "0"
      : this.searchModel.DepartmentCode;
    let districtCode = isNullOrUndefined(this.searchModel.DistrictCode)
      ? "0"
      : this.searchModel.DistrictCode;
    this._userService.GetUserOfficeList(deptCode, districtCode).subscribe(
      (data) => {
        if (data.IsSuccess) {
          this.ddlList.ddlOffice = <DdlItemModel[]>data.Data;
        }
      },
      (error) => {
        this._alertService.error(error.message);
      }
    );
  }


  CloseDialog(): void {
    this._dialogRef.close();
  }

  GetGlobalSearchData() {
    this._communicationService.GetAllGlobalListSearch(this.searchModel).subscribe(
      (data) => {

        if (data.IsSuccess) {
          this.model = <CommunicationModel[]>data.Data.Data ? data.Data.Data : [];

          this.dataSource = new MatTableDataSource<CommunicationModel>(this.model);
          this.totalRecords = data.Data.TotalRecords;
          if (!this.searchModel.indexModel.IsPostBack) {
            this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.sort;
          }

        } else {
          this._alertService.error(data.Message);
        }
      },
      (error) => {
        this._alertService.error(error.message);
      }
    );

  }
  sortData(event) {
    this.searchModel.indexModel.OrderBy = event.active;
    this.searchModel.indexModel.OrderByAsc = event.direction == AppSetting.orderByDscAsc ? AppSetting.orderByAsc : AppSetting.orderByDsc;
    this.searchModel.indexModel.IsPostBack = true;
    this.GetGlobalSearchData();

  }

  onPaginateChange(event) {
    this.searchModel.indexModel.Page = event.pageIndex + 1;
    this.searchModel.indexModel.PageSize = event.pageSize;
    this.searchModel.indexModel.IsPostBack = true;
    this.GetGlobalSearchData();
  }
  onSelectItem(item: CommunicationModel) {
    this._dialogRef.close(item);

  }
  clearSearch() {

    this.searchModel.DepartmentCode = undefined;
    this.searchModel.DistrictCode = undefined;
    this.searchModel.OfficeCode = undefined;
    this.searchModel.VendorUserCode = undefined;
    this.searchModel.ProjectCode = undefined;
    this.searchModel.WorkOrderCode = undefined;
    this.searchModel.FromDate = undefined;
    this.searchModel.ToDate = undefined;
  }

}
