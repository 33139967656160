import { Injectable } from '@angular/core';
import { AppSetting } from '../../model/appsetting.model';
import { IndexModel } from '../../model/common.model';
import { Dictionary } from '../../model/dictionary.model';
import { B2GUserManualModel, PageManualModel } from '../../model/master/b2-guser-manual-model';
import { BaseService } from '../base.service';

@Injectable({
  providedIn: 'root'
})
export class B2GUserManualService {

  constructor( private readonly _baseService: BaseService) { }

  GetList(model: IndexModel) {
    return this._baseService.post(AppSetting.B2GUserManualGetAllUrl, model);
   }
 
   Add(model: B2GUserManualModel) {
     return this._baseService.post(AppSetting.B2GUserManualAddUrl, model);
   }
 
   GetById(id) {
     return this._baseService.get(AppSetting.B2GUserManualGetByIdUrl + id, null);
   }
 
   Edit(model: B2GUserManualModel) {
     return this._baseService.post(AppSetting.B2GUserManualEditUrl, model
     );
   }
 
   ChangeActiveStatus(id: number) {
     return this._baseService.get(AppSetting.B2GUserManualUpdateStatusUrl + id);
   }
 
   GetPageDetailByPageCode(id) {
     return this._baseService.get(AppSetting.PageDetailByPageCodeUrl + id);
   }
 
   GetPageListByMenuName(manuName: PageManualModel) {
     return this._baseService.post(AppSetting.PageListByMenuNameUrl , manuName);
   }

   GetCountForUserManualUrl(communicationTypeCode :string="0",masterTypeCode :string="0", manuName: string='') {
    var param = new Dictionary<any>();
    param.Add("communicationTypeCode", communicationTypeCode)
    param.Add("masterTypeCode", masterTypeCode)
    param.Add("manuName", manuName)

    return this._baseService.get(AppSetting.GetCountForUserManualUrl, param);

  }

   
}
