import { AuthenticationService } from './../../shared/service/authentication.service';
import { isNullOrUndefined } from 'util';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource, MatDialog, MatSort, MatPaginator } from "@angular/material";
import { GlobalFilterModel, IndexModel, PermissionModel } from 'src/app/shared/model/common.model';
import { ColumnHeaderModel, DDLModel } from 'src/app/shared/model/commonddl.model';
import { AppComponent } from 'src/app/app.component';
import { AlertService } from 'src/app/shared/service/alert.service';
import { ConfirmationDialogComponent } from 'src/app/confirmation-dialog/confirmation-dialog.component';
import { GlobalMessagesModel } from 'src/app/shared/model/common-messages.model';
import { AppSetting } from 'src/app/shared/model/appsetting.model';
import { UserViewModel, VendorUserSearchModel } from 'src/app/shared/model/user.model';
import { CommonService } from 'src/app/shared/service/common.service';
import { UserService } from 'src/app/shared/service/user.service';
import { AddUpdateVendorUserDialogComponent } from './add-update-vendor-user-dialog/add-update-vendor-user-dialog.component';
import { VendorUserDetailDialogComponent } from './vendor-user-detail-dialog/vendor-user-detail-dialog.component';
import { UserTypeEnum } from '../../shared/enum/fixed-values.enum';

@Component({
  selector: 'app-vendor-user',
  templateUrl: './vendor-user.component.html',
  styleUrls: ['./vendor-user.component.scss']
})

export class VendorUserComponent implements OnInit {
  model: UserViewModel[];
  //indexModel = this._commonService.modelSetGet(new IndexModel()) as IndexModel;
  searchModel = this._commonService.modelSetGet(new VendorUserSearchModel()) as VendorUserSearchModel;
  dataSource: any;
  totalRecords: number;
  isShow = true;
  ddlList = new DDLModel();
  loginUserDetail: UserViewModel;
  loginUserCode: number;
  loginBaseUserType: string;


  displayedColumns: string[] = ["index", "Name", "SSOID", "UserTypeCategoryTitle", "CreatedByUserName", "DepartmentNames", "DistrictNames", "ProjectNames", "OfficeNames", "IsActive", "Action"];
  columnsToDisplay: string[] = this.displayedColumns.slice();
  ViewdisplayedColumns: ColumnHeaderModel[] = [
    { Value: "Name", Text: "Name" },
    { Value: "SSOID", Text: "SSO ID" },
    { Value: "UserTypeCategoryTitle", Text: "Type " },
    //  { Value: "CreatedByUserName", Text: "Vendor Company" },
    // { Value: "CreatedOn", Text: "Created On" },
    // { Value: "DepartmentNames", Text: "Department" },
    //{ Value: "DistrictNames", Text: "District" },
    //{ Value: "ProjectNames", Text: "Project" },
  ];

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  Permission: PermissionModel = this._authService.GetPagePermission("vendor-user", "vendor-user/create", "vendor-user/detail", "vendor-user/update", "vendor-user/delete");

  constructor(
    private readonly appComponent: AppComponent,
    private readonly _alertService: AlertService,
    private readonly _userService: UserService,
    private readonly _dialog: MatDialog,
    public readonly _commonService: CommonService,
    private readonly _authService: AuthenticationService

  ) {
    this.appComponent.setPageLayout("Vendor User :", "", "", "", true);

    this.loginUserDetail = this._authService.GetCurrentUserDetail().UserViewModel;
    this.loginUserCode = this._authService.UserCode;
    this.loginBaseUserType = this._authService.BaseUserType;
  }

  ngOnInit() {
    this.GetDDLList();
    this.getList();

  }

  getList() {
    
    // this._userService.GetVendorUserList(0, this._commonService.modelSetGet(this.indexModel, true)).subscribe(
    this._userService.GetVendorUserSearchList(this._commonService.modelSetGet(this.searchModel, true)).subscribe(
      data => {
        if (data.IsSuccess) {
          
          this.model = <UserViewModel[]>data.Data.Data;
          this.dataSource = new MatTableDataSource<UserViewModel>(this.model);
          //this.ViewdisplayedColumns.values(this.model.values);
          this.totalRecords = data.Data.TotalRecords;
          if (!this.searchModel.indexModel.IsPostBack) {
            this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.sort;
          }
        } else {
          this._alertService.error(data.Message);
        }
      },
      error => {
        this._alertService.error(error.message);
      }
    );
  }

  updateActiveStatus(id) {
    const dialogRef = this._dialog.open(ConfirmationDialogComponent, { width: "50%", data: GlobalMessagesModel.ConfirmStatusChanged });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this._userService.ChangeActiveStatus(id).subscribe(
          data => {
            if (data.IsSuccess) {
              this._alertService.success(data.Message);
              this.getList();
            }
          },
          error => {
            this._alertService.error(error.message);
          }
        );
      }
    });
  }

  updateDeleteStatus(id) {
    const dialogRef = this._dialog.open(ConfirmationDialogComponent, { width: "50%", data: GlobalMessagesModel.ConfirmDeleted });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this._userService.ChangeDeleteStatus(id).subscribe(
          data => {
            if (data.IsSuccess) {
              this._alertService.success(data.Message);
              this._commonService.ScrollingTop();
              this.getList();
            }
          },
          error => {
            this._alertService.error(error.message);
          }
        );
      }
    });
  }

  sortData(event) {
    this.searchModel.indexModel.OrderBy = event.active;
    this.searchModel.indexModel.OrderByAsc = event.direction == AppSetting.orderByDscAsc ? AppSetting.orderByAsc : AppSetting.orderByDsc;
    this.searchModel.indexModel.IsPostBack = true;
    this.getList();
  }

  onPaginateChange(event) {
    this.searchModel.indexModel.Page = event.pageIndex + 1;
    this.searchModel.indexModel.PageSize = event.pageSize;
    this.searchModel.indexModel.IsPostBack = true;
    this.getList();
  }

  SearchByKeyword(searchValue: GlobalFilterModel) {
    this.searchModel.indexModel.Search = searchValue.search;
    this.searchModel.indexModel.AdvanceSearchModel = { ActiveStatus: searchValue.ActiveStatus }

    this.getList();
  }


  ClearSearch() {
    this.searchModel = new VendorUserSearchModel();
    this.GetDDLList();
    this.getList();

  }

  OpenAddUpdateVendorUserDialog(Id) {
    const _dialogRef = this._dialog.open(AddUpdateVendorUserDialogComponent, {
      width: "1000px",
      data: { Id },
      disableClose: true
    });
    _dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        this.getList();
      }
    });
  }

  OpenVendorUserDetailDialog(Id) {
    const _dialogRef = this._dialog.open(VendorUserDetailDialogComponent, {
      width: "1000px",
      data: { Id },
      disableClose: true
    });
    _dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        this.getList();
      }
    });
  }

  //#region Adv search
  toggleDisplay() {
    this.isShow = !this.isShow;
  }

  GetOfficeByDepartment() {
    if (!isNullOrUndefined(this.searchModel.DepartmentCode)) {
      this.searchModel.DistrictCode = this.searchModel.DistrictCode != undefined && this.searchModel.DistrictCode > 0 ? this.searchModel.DistrictCode : (this.loginUserDetail != undefined && this.loginUserDetail.DistrictCode != undefined && this.loginUserDetail.DistrictCode.length > 0 ? this.loginUserDetail.DistrictCode[0] : undefined);
      this._commonService.GetDDL(AppSetting.ddlOfficeByLoginUserId, this.searchModel.DepartmentCode, this.searchModel.DistrictCode).subscribe(
        (data) => {

          if (data.IsSuccess) {
            var ddl = <DDLModel>data.Data;
            this.ddlList.ddlOfficeByLoginUserId = ddl.ddlOfficeByLoginUserId;
          }
        },
        (error) => {
          this._alertService.error(error.message);
        }
      );

    } else {
      this.ddlList.ddlOfficeByLoginUserId = null;
    }
  }

  GetDistrictByDepartment() {
    if (!isNullOrUndefined(this.searchModel.DepartmentCode)) {
      this._commonService.GetDDL(AppSetting.ddlDistrict, this.searchModel.DepartmentCode).subscribe(
        data => {
          if (data.IsSuccess) {
            var ddl = <DDLModel>data.Data;
            this.ddlList.ddlDistrict = ddl.ddlDistrict;
          }
        },
        error => {
          this._alertService.error(error.message);
        }
      );
    }
    else {
      this.ddlList.ddlDistrict = null;
    }
  }

  GetDDLList() {
    this._commonService.GetAllDDL(AppSetting.VendorUserDropdownKeys, Number(this.loginBaseUserType), this.loginUserCode).subscribe(
      data => {
        if (data.IsSuccess) {
          this.ddlList = <DDLModel>data.Data;
        
          if (this.ddlList) {
            this.ddlList.ddlDisplayUserTypeTitle = this.ddlList.ddlDisplayUserTypeTitle.filter(function (item) {
              return (item.Value == UserTypeEnum.HOD || item.Value == UserTypeEnum.DREC  || item.Value ==  UserTypeEnum.DLO || item.Value ==  UserTypeEnum.PROJ || item.Value == UserTypeEnum.STTLVL);
            });
          }
        }
      },
      error => {
        this._alertService.error(error.message);
      }
    );
  }

 
  // GetDDLList() {
  //   this._commonService.GetAllDDL(AppSetting.WorkOrderFilterDropdownKeys, Number(this.loginBaseUserType), this.loginUserCode).subscribe(
  //     data => {
  //       if (data.IsSuccess) {
  //         this.ddlList = <DDLModel>data.Data;
  //       }
  //     },
  //     error => {
  //       this._alertService.error(error.message);
  //     }
  //   );
  // }

  GetDDLList1() {
    this._commonService.GetAllDDL(AppSetting.VendorUserDropdownKeys, Number(this.loginUserDetail.UserTypeCode), this.loginUserDetail.UserCode).subscribe(
      data => {
        if (data.IsSuccess) {
          this.ddlList = <DDLModel>data.Data;
          if (this.ddlList) {
            this.ddlList.ddlDisplayUserTypeTitle = this.ddlList.ddlDisplayUserTypeTitle.filter(function (item) {
              return (item.Value == UserTypeEnum.HOD || item.Value == UserTypeEnum.DREC || item.Value == UserTypeEnum.DRECADLC || item.Value == UserTypeEnum.DLO || item.Value == UserTypeEnum.PROJ);
            });
          }
        }
      },


      error => {
        this._alertService.error(error.message);
      }
    );
  }

  onDepartmentChange() {
    this.GetProjectByDepartmentOffice();

  }

  GetProjectByDepartmentOffice() {

    if (!isNullOrUndefined(this.searchModel.DepartmentCode)) {
      this._commonService.GetDDL(AppSetting.ddlOfficeProject, this.searchModel.DepartmentCode, 0, this.loginUserCode)
        .subscribe(
          (data) => {
            if (data.IsSuccess) {
              var ddl = <DDLModel>data.Data;
              this.ddlList.ddlProject = ddl.ddlOfficeProject;

            }
          },
          (error) => {
            this._alertService.error(error.message);
          }
        );
    } else {
      this.ddlList.ddlProject = null;
    }
  }

}
