import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { IndexModel } from '../model/common.model';
import { AppSetting } from '../model/appsetting.model';
import { ComplaintCommunicationModel } from '../model/complaint-communication.model';
import { CommunicationTransactionPostModel } from '../model/communication-transaction.model';

@Injectable({
    providedIn: 'root'
})

export class ComplaintCommunicationService {

    constructor(private readonly _baseService: BaseService) { }

    GetList(loginUserCode: number, search: string, status: string, model: IndexModel) {
        return this._baseService.post(AppSetting.ComplaintCommunicationListUrl + "?loginUserCode=" + loginUserCode + "&search=" + search + "&status=" + status, model);
    }

    AddUpdate(model: ComplaintCommunicationModel) {
        return this._baseService.post(AppSetting.ComplaintCommunicationAddUpdateUrl, model);
    }

    Detail(id: number) {
        return this._baseService.get(AppSetting.ComplaintCommunicationDetailUrl + id);
    }

    GetComplaintCommunicationPDFDownload(loginUserCode: number, search: string, status: string, model: IndexModel) {
        return this._baseService.post(AppSetting.ComplaintCommunicationPDFDownloadUrl + "?loginUserCode=" + loginUserCode + "&search=" + search + "&status=" + status, model);
    }

  
}