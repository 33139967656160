export class ProjectMappingViewModel {
  ProjectCode: number;
  ProjectName: string;
  UserCode: number | string;

  AssignToUserCode: number | null;
  AssignByUserCode: number | null;
  AssignDate: Date | string;
  AssignOn: Date | null;

  AssignedProjectOfficerName: string;
  OIC_UserType: string;
  SSOID: string;
  Email: string;
  Mobile: string;
  AssignByUserName: string;

  IsOICAssigned: number;
  DepartmentName: string;

  UserType: string;
  ProjectCodes: string;
  ProjectCodeList: string[]  = [];
  DepartmentCodes: string;
  DepartmentCode: string[] = [];
  DistrictCodes: string;
  OfficeCodes: string;
  OfficeCode: string[] = [];
  AdminDepartmentCodes: string;
  AdminDepartmentCode: string[] = [];
}

export class ProjectDetailViewModel {
  ProjectCode: number;
  ProjectName: string;
  DistrictCode: number | null;
  OfficeCode: number | null;
  ProjectSubCategoryCode: number | null;
  TendorID: string;
  TendorRefNumber: string;
  TendorRefDate: string | null;
  FirmRefNumber: string;
  FirmRefDate: string | null;
  Remarks: string;
  Attchement: string;
  Description: string;
  IsActive: boolean | null;
  IsDeleted: boolean | null;
  CreatedBy: number | null;
  CreateDate: string | null;
  ModifiedBy: number | null;
  ModifiedDate: string | null;
  IsAssigned: boolean;
  IsAccepted: boolean;
  AcceptedBy: number | null;
  PIC_Name: string;
  PIC_Designation: string;
  PIC_Email: string;
  PIC_MobileNo: string;
  DepartmentCode: number | null;
  DepartmentName: string;
  DepartmentNameHindi: string;
  UserName: string;
  AcceptedByName: string;
  VendorCompany: string;
  AssignedProjectOfficerName: string;
  SSOID: string;
  Email: string;
  Mobile: string;
  AssignToUserCode: number | null;
  AssignDate: string | null;
  AssignByUserCode: number | null;
  AssignByUserName: string;
  DistrictTitle: string;
  OfficeName: string;
  Category: string;
  SubCategory: string;
  ProjectCategoryCode: number | null;
  IsOICAssigned: number;
}