import { Component, OnInit, ViewChild, Inject } from "@angular/core";
import { MatTableDataSource, MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatSort, MatPaginator } from "@angular/material";
import { IndexModel,SearchModel } from 'src/app/shared/model/common.model';
import { ColumnHeaderModel, DdlItemModel, DDLModel } from 'src/app/shared/model/commonddl.model';
import { AppComponent } from 'src/app/app.component';
import { AlertService } from 'src/app/shared/service/alert.service';
import { AppSetting } from 'src/app/shared/model/appsetting.model';
import { AuthenticationService } from 'src/app/shared/service/authentication.service';
import { UserTypeEnum } from 'src/app/shared/enum/fixed-values.enum';
import { Router } from '@angular/router';
import { UserViewModel, CustomSearchModel,UserForNotificationListModel } from 'src/app/shared/model/user.model';
import { UserService } from 'src/app/shared/service/user.service';
import { CommonService } from 'src/app/shared/service/common.service';
import { UserTypeModel } from 'src/app/shared/model/user-type.model';
import { isNullOrUndefined } from 'util';
import { FormControl, Validators } from '@angular/forms';
import { SMSTemplateService } from 'src/app/shared/service/master/sms-template.service';
import { UserTypeService } from 'src/app/shared/service/user-type.service';
import{SmsTemplateDialogComponent} from 'src/app/reports/user/sms-template-dialog/sms-template-dialog.component';

import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';


declare const require: any;
const jsPDF = require('jspdf');
require('jspdf-autotable');

@Component({
  selector: 'app-user-report',
  templateUrl: './user-report.component.html',
  styleUrls: ['./user-report.component.scss']
})
export class UserReportComponent implements OnInit {
  model: UserViewModel[];
  userType: string | number;
  dataSource: any;
  loginData: UserViewModel;
  totalRecords: number;
  loginBaseUserType: string;
  listModel: UserForNotificationListModel[];
  ddlList = new DDLModel();
  displayedColumns: string[] = ["index", "Name", "SSOID", "DepartmentNames", "DistrictNames", "ProjectNames", "UserType", "IsActive"];
  
  columnsToDisplay: string[] = this.displayedColumns.slice();
  userTypeEnum =UserTypeEnum;
  ViewdisplayedColumns: ColumnHeaderModel[] = [
    { Value: "Name", Text: "Name" },
    { Value: "SSOID", Text: "SSO ID" },
    { Value: "DepartmentNames", Text: "Department" },
    { Value: "DistrictNames", Text: "District" },
    { Value: "UserType", Text: "UserType" },
  ];
  content = new FormControl("", [Validators.required]);
  fromDate: Date | string = new Date("12/17/2018");
  toDate: Date | string = new Date();
  searchModel = this._commonService.modelSetGet(new CustomSearchModel(), false);
  isShow = true;
  ddlAdminDepartment: DdlItemModel[] = [];
  ddlUserType: DdlItemModel[] = [];
  userTypeList: UserTypeModel[] = [];
  loginUserCode: number = 0;
  SearchdisplayedColumns: ColumnHeaderModel[] = [
    { Value: "DepartmentNames", Text: "Department" },
    { Value: "DistrictNames", Text: "District" },
  ];

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(
    private readonly _smsService: SMSTemplateService,
    private readonly appComponnet: AppComponent,
    private readonly _alertService: AlertService,
    private readonly _userService: UserService,
    private readonly _dialog: MatDialog,
    private readonly _router: Router,
    public readonly _commonService: CommonService,
    private readonly _authService: AuthenticationService,
    private readonly _userTypeService: UserTypeService,
    public dialog: MatDialog
  ) {
    this.appComponnet.setPageLayout("User Report :", "", "", "");
   
    this.loginData = this._authService.GetCurrentUserDetail().UserViewModel;
    this.loginBaseUserType = this._authService.BaseUserType;
  }

  ngOnInit() {
    if (this.loginBaseUserType != this.userTypeEnum.ADM && this.loginBaseUserType != this.userTypeEnum.DREC && this.loginBaseUserType !=  this.userTypeEnum.DRECADLC && this.userTypeEnum.HOD != this.loginBaseUserType && this.userTypeEnum.SADM != this.loginBaseUserType) {
      this._router.navigate(["dashboard"]);
    }
    this.getList();
    this.getAdminDepartment();
    this.GetDepartment();
    this.GetDistrict();
    this.getDownLevelUserType();
  }

  toggleDisplay() {
    this.isShow = !this.isShow;
  }

  getList() {
    if (this.fromDate) {
      const uTCFromDate = new Date(Date.UTC(new Date(this.fromDate).getFullYear(), new Date(this.fromDate).getMonth(), new Date(this.fromDate).getDate()
      )
      );
      this.fromDate = uTCFromDate;
    }

    if (this.toDate) {
      const uTCToDate = new Date(
        Date.UTC(
          new Date(this.toDate).getFullYear(),
          new Date(this.toDate).getMonth(),
          new Date(this.toDate).getDate()
        )
      );
      this.toDate = uTCToDate;
    }

    if (this.fromDate || this.toDate) {
      this.searchModel.indexModel.AdvanceSearchModel = {
        FromDate: this.fromDate,
        ToDate: this.toDate,
      };
    }

    this.searchModel.CreatedFrom = this.fromDate;
    this.searchModel.CreatedTo = this.toDate;
    this.searchModel.UserCode = this.loginData.UserCode;
    this.searchModel.indexModel.AdvanceSearchModel = {

      Name: this.searchModel.Name,
      Ssoid: this.searchModel.Ssoid,
      AdminDepartmentCode: this.searchModel.AdminDepartmentCode,
      UserTypeCode: this.searchModel.UserType,
      DepartmentCode: this.searchModel.DepartmentCode,
      OfficeCode: this.searchModel.OfficeCode,
      DistrictCode: this.searchModel.DistrictCode,
      Email: this.searchModel.Email,
      ActiveView: this.searchModel.ActiveView,
      CreatedFrom: this.searchModel.CreatedFrom,
      CreatedTo: this.searchModel.CreatedTo,
      FromDate: this.fromDate,
      ToDate: this.toDate,


    };
    this.searchModel = this._commonService.modelSetGet(this.searchModel, true);

    // this.indexModel.AdvanceSearchModel = this.searchModel;
    this._userService.GetUserListForReport(this.searchModel.indexModel).subscribe(
      data => {
        if (data.IsSuccess) {
          this.model = <UserViewModel[]>data.Data.Data;
          this.dataSource = new MatTableDataSource<UserViewModel>(this.model);
          this.totalRecords = data.Data.TotalRecords;
          if (!this.searchModel.indexModel.IsPostBack) {
            this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.sort;
          }
        } else {
          this._alertService.error(data.Message);
        }
      },
      error => {
        this._alertService.error(error.message);
      }
    );
  }
  sortData(event) {
    this.searchModel.indexModel.OrderBy = event.active;
    this.searchModel.indexModel.OrderByAsc = event.direction == AppSetting.orderByDscAsc ? AppSetting.orderByAsc : AppSetting.orderByDsc;
    this.searchModel.indexModel.IsPostBack = true;
    this.getList();
  }

  onPaginateChange(event) {
    this.searchModel.indexModel.Page = event.pageIndex + 1;
    this.searchModel.indexModel.PageSize = event.pageSize;
    this.searchModel.indexModel.IsPostBack = true;
    this.getList();
  }

  SearchByKeyword(searchValue) {
    this.searchModel.indexModel.Search = searchValue;
    this.getList();
  }

  ClearSearch() {
    this.searchModel = new CustomSearchModel();
    this.fromDate = '';
    this.toDate = '';
    this.getList();
  }
  completeModel: UserViewModel[];//change
  completeIndexModel: IndexModel;
  getCompleteData() {
    this.completeIndexModel = new IndexModel();
    this.completeIndexModel.Page = 1;
    this.completeIndexModel.PageSize = this.totalRecords;
    this.completeIndexModel.IsPostBack = true;

    this._userService.GetUserListForReport(this.completeIndexModel).subscribe( //change
      (completeData: any) => {
        if (completeData.IsSuccess) {
          this.completeModel = <UserViewModel[]>completeData.Data.Data; //change
          this.totalRecords = completeData.Data.TotalRecords;
          this.download();
        } else {
          this._alertService.error(completeData.Message);
        }
      },
      error => {
        this._alertService.error(error.message);
      }
    );
  }

  rowsNew: any = [];

  download() {
    //completeModel
    var i = 1;
    this.rowsNew = [];
    this.completeModel.forEach(element => {
      var temp = {
        index: i, Name: element.Name,
        SSOID: element.SSOID,
        DepartmentNames: element.DepartmentNames, DistrictNames: element.DistrictNames, ProjectNames: element.ProjectNames,
        Designation: element.Designation, IsActive: element.IsActive
      }; //change
      i = i + 1;
      this.rowsNew.push(temp);
    });

  }
  DownloadPdf() {
    // this._masterReportService.GetPDFDownload(this.indexModel, this.masterTypeCode).subscribe(
    //   data => {
    //     if (data.IsSuccess) {

    //       var w = window.open("about:blank");

    //       setTimeout(function () {
    //         //FireFox seems to require a setTimeout for this to work.
    //         w.document.body.appendChild(w.document.createElement("iframe")).src = data.Data;
    //         w.document.getElementsByTagName("iframe")[0].style.width = "100%";
    //         w.document.getElementsByTagName("iframe")[0].style.height = "100%";
    //       }, 0);
    //     } else {
    //       this._alertService.error(data.Message);
    //     }
    //   },
    //   error => {
    //     this._alertService.error(error.message);
    //   }
    // );
  }

  buttonClickExportAsPDF(): void {
    this.getCompleteData();
    setTimeout(() => {
      this.download();
      this.exportAsPdf();
    }, 1000);
    //this.DownloadPdf();
  }
  buttonClickExportAsXLSX(): void {
    this.getCompleteData();
    setTimeout(() => {
      this.download();
      this.exportAsExcelFile();
    }, 1000);
  }

  public exportAsPdf(): void {
    var doc = new jsPDF('p', 'pt');

    var header = function (data) {
      doc.setFontSize(10);
      doc.setTextColor(40);
      doc.text("User Report", data.settings.margin.left, 50);
      doc.text("Date Time : " + new Date().toLocaleDateString() + " " + new Date().toLocaleTimeString(), data.settings.margin.left, 800);
    };


    var headers = [{
      title: "#",
      dataKey: "SNo"
    }, {
      title: "Name",
      dataKey: "Name"
    },
    {
      title: "SSOID",
      dataKey: "SSOID"
    },
    {
      title: "Department Names",
      dataKey: "DepartmentNames"
    },
    {
      title: "District Names",
      dataKey: "DistrictNames"
    },
    {
      title: "Project Names",
      dataKey: "ProjectNames"
    },
    {
      title: "UserType",
      dataKey: "UserType"
    }
      ,
    {
      title: "IsActive",
      dataKey: "IsActive"
    }
    ];
    doc.autoTable(headers, this.rowsNew, { margin: { top: 70 }, beforePageContent: header });
    doc.save("User_Report_" + new Date().toLocaleDateString() + "_" + new Date().toLocaleTimeString() + ".pdf");
  }

  //excellll
  public exportAsExcelFile(): void {
    //this.getCompleteData();
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.rowsNew);
    const workbook: XLSX.WorkBook = { Sheets: { 'Report': worksheet }, SheetNames: ['Report'] }; //change
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFile(excelBuffer, "User"); //change
  }
  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], { type: EXCEL_TYPE });
    FileSaver.saveAs(data, fileName + '_Report_' + new Date().toLocaleDateString() + "_" + new Date().toLocaleTimeString() + EXCEL_EXTENSION);
  }
  getOfficeList() {
  
    this.ddlList.ddlOffice = [];
    let deptCode = isNullOrUndefined(this.searchModel.DepartmentCode) ? "0" : this.searchModel.DepartmentCode;
    let districtCode = isNullOrUndefined(this.searchModel.DistrictCode) ? "0" : this.searchModel.DistrictCode;
    this._userService.GetUserOfficeList(deptCode, districtCode).subscribe(
      (data) => {
        if (data.IsSuccess) {
          this.ddlList.ddlOffice = <DdlItemModel[]>data.Data;
        }
      },
      (error) => {
        this._alertService.error(error.message);
      }
    );
  }


  getDownLevelUserType() {
    this._userTypeService.GetDownLevelUserType(this.loginBaseUserType).subscribe(
      (data) => {
        if (data.IsSuccess) {
          this.ddlUserType = [];
          this.userTypeList = <UserTypeModel[]>data.Data;
          this.userTypeList.forEach((element) => {
            this.ddlUserType.push({
              Value: element.UserType,
              Text: element.UserType + " (" + element.UserTypeTitle + ")",
            });
          });
          //this.RebindField();
        }
      },
      (error) => {
        this._alertService.error(error.message);
      }
    );
  }

  getAdminDepartment() {
    this.ddlAdminDepartment = [];
    this._userService.GetUserAdminDepartmentList().subscribe(
      (data) => {
        if (data.IsSuccess) {
          this.ddlAdminDepartment = <DdlItemModel[]>data.Data;
        }
      },
      (error) => {
        this._alertService.error(error.message);
      }
    );
  }
  GetDepartment() {
    
    this.ddlList.ddlOfficeDepartment = [];
    this._userService.loginUserCode = this.loginUserCode;
    this._userService.GetUserDepartmentList(this.searchModel.AdminDepartmentCode).subscribe(
      data => {

        if (data.IsSuccess) {

          this.ddlList.ddlOfficeDepartment = <DdlItemModel[]>data.Data;
        }
      },
      error => {
        this._alertService.error(error.message);
      }
    );
  }

  GetDistrict() {
    this.ddlList.ddlDistrict = [];
    this._userService.loginUserCode = this.loginUserCode;
    this._userService.GetUserDistrictList(20).subscribe(
      data => {

        if (data.IsSuccess) {

          this.ddlList.ddlDistrict = <DdlItemModel[]>data.Data;
        }
      },
      error => {
        this._alertService.error(error.message);
      }
    );
  }
  GetSMSTemplate() {
    
    this.ddlList.ddlSMSTemplate = [];
    this._userService.loginUserCode = this.loginUserCode;
    this._smsService.GetList(this.searchModel.indexModel).subscribe(
      data => {

        if (data.IsSuccess) {

          this.ddlList.ddlSMSTemplate = <DdlItemModel[]>data.Data;
        }
      },
      error => {
        this._alertService.error(error.message);
      }
    );
  }



  selectAll(event) {
    if (event.checked) {
      this.searchModel.IsSelectAll = true;
      this.searchModel.MobileNo = this.listModel.map(
        function(a) {
          return a.Mobile;
        }
      );
    }
    else {
      this.searchModel.MobileNo = [];
      this.searchModel.IsSelectAll = false;
    }
  }

  selectItem(event, data: string) {
    
    if (event.checked) {
      this.searchModel.MobileNo.push(data);
      this.searchModel.UnSelectedList = this.searchModel.UnSelectedList.filter(c => c !== data);
    } else {
      this.searchModel.UnSelectedList.push(data);
      this.searchModel.MobileNo = this.searchModel.MobileNo.filter(c => c !== data);
    }
  }

  SaveClick() {
    
    this.content.markAsTouched();
    if (this.content.valid) {
      this.searchModel.FilterModel = this.searchModel.indexModel;
      this._userService
        .SendNotificationToUser(this.searchModel)
        .subscribe(
          data => {
            if (data.IsSuccess) {
              this._alertService.success(data.Message);
            } else {
              this._alertService.error(data.Message);
            }
            this._commonService.ScrollingTop();
          },
          error => {
            this._alertService.error(error.message);
            this._commonService.ScrollingTop();
          }
        );
    }else{
      this._commonService.ScrollingTop();
    }
  }
  openDialog(): void {
    const dialogRef = this.dialog.open(SmsTemplateDialogComponent, {
      width: '250px',
      data: {}
    });

    dialogRef.afterClosed().subscribe(result => {
      
    });
  }



}
