import { Component, OnInit, Inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { AlertService } from 'src/app/shared/service/alert.service';
import { UserViewModel } from 'src/app/shared/model/user.model';
import { AuthenticationService } from 'src/app/shared/service/authentication.service';
import { UserService } from 'src/app/shared/service/user.service';
import { UserTypeEnum } from 'src/app/shared/enum/fixed-values.enum';

@Component({
  selector: 'app-user-detail-dialog',
  templateUrl: './user-detail-dialog.component.html',
  styleUrls: ['./user-detail-dialog.component.scss']
})

export class UserDetailDialogComponent implements OnInit {

  heading: string;
  recordId: number = 0;
  loginUserCode: number;
  model: UserViewModel = new UserViewModel();;
  userTypeEnum =UserTypeEnum;
  constructor(
    private readonly _alertService: AlertService,
    private readonly _userService: UserService,
    private readonly _authService: AuthenticationService,
    public _dialogRef: MatDialogRef<UserDetailDialogComponent>,
    private readonly _route: ActivatedRoute,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    if (data && data.Id) {
      this.recordId = data.Id;
    }
    this.loginUserCode = this._authService.UserCode;
    this.heading = (this.recordId == this.loginUserCode ? "My" : "") + " Profile";
  }

  ngOnInit() {
    this.getDetail();
  }

  getDetail() {
    
    if (this.recordId > 0) {
      this._userService.Detail(this.recordId).subscribe(data => {
        if (data.IsSuccess) {
          this.model = <UserViewModel>data.Data;
        }
      }, error => {
        this.model = new UserViewModel();
        this._alertService.error(error.message);
      });
    }
  }

  get isDeptLevelUser(): boolean {
    return (this.model.BaseUserType != UserTypeEnum.SADM && this.model.BaseUserType != UserTypeEnum.ADM
      && this.model.BaseUserType != UserTypeEnum.VNDR && this.model.BaseUserType != UserTypeEnum.VNDRUSER)
  }

  get isVenderLevelUser(): boolean {
    return (this.model.BaseUserType == UserTypeEnum.VNDR || this.model.BaseUserType == UserTypeEnum.VNDRUSER)
  }

  get isVenderLevelStateUser(): boolean {
    
    return (this.model.UserTypeCategory == UserTypeEnum.STTLVL)
  }

  closeDialog(): void {
    this._dialogRef.close();
  }

}
