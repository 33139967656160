import { Injectable } from "@angular/core";
import { AppSetting } from "../../model/appsetting.model";
import { IndexModel } from "../../model/common.model";
import { Dictionary } from "../../model/dictionary.model";
import { ProjectCategoryModel } from "../../model/master/project-category.model";
import { BaseService } from "../base.service";

@Injectable({providedIn:'root'})
export class ProjectCategoryService {
    constructor(private readonly _baseService: BaseService, ) { }

    GetList(model: IndexModel) {
        return this._baseService.post(AppSetting.ProjectCategoryListUrl, model);
    }

    AddUpdate(id: number, model: ProjectCategoryModel) {
        return this._baseService.post(AppSetting.ProjectCategoryAddUpdateUrl, model);
    }

    Detail(id: number) {
         return this._baseService.get(AppSetting.ProjectCategoryDetailUrl + id);
    }

    ChangeDeleteStatus(id: number) {
        return this._baseService.get(AppSetting.ProjectCategoryDeleteStatusChangeUrl + id);
    }

    ChangeActiveStatus(id: number) {
        return this._baseService.get(AppSetting.ProjectCategoryActiveStatusChangeUrl + id);
    }

    IsProjectCategoryExist(categoryName: string,id:number){
        const param = new Dictionary<any>();
        param.Add("categoryName", categoryName);
        param.Add("id", id);
        
        return this._baseService.get(AppSetting.IsProjectCategoryExistUrl ,param);
    }

    GetProjectCategoryPDFDownload(model: IndexModel) {
       
        return this._baseService.post(AppSetting.ProjectCategoryPDFDownloadUrl, model);
    }
}
