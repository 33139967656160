import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { AppComponent } from 'src/app/app.component';
import { IndexModel, PermissionModel } from 'src/app/shared/model/common.model';
import { ColumnHeaderModel, DDLModel, DdlItemModel } from 'src/app/shared/model/commonddl.model';
import { VendorPermissionDelegationRequestModel } from 'src/app/shared/model/master/vendor-permission-delegation.model';
import { VendorPermissionDelegationListViewModel } from 'src/app/shared/model/master/vendor-permission-list-view.model';
import { UserViewModel } from 'src/app/shared/model/user.model';
import { AlertService } from 'src/app/shared/service/alert.service';
import { AuthenticationService } from 'src/app/shared/service/authentication.service';
import { CommonService } from 'src/app/shared/service/common.service';
import { UserService } from 'src/app/shared/service/user.service';
import { VendorPermissionDelegationService } from 'src/app/shared/service/vendor-permission-delegation.service';



@Component({
  selector: 'app-vendor-permission-delegation',
  templateUrl: './vendor-permission-delegation.component.html',
  styleUrls: ['./vendor-permission-delegation.component.scss'],
  providers: [VendorPermissionDelegationService]
})
export class VendorPermissionDelegationComponent implements OnInit {
  public displayedColumns: string[] = [];
  public columnsToDisplay: string[] = [];
  public submitButtonText: string;
  public ddlList = new DDLModel();
  public loginData: UserViewModel[] = [];
  public departmentCode: number;
  public indexModel: IndexModel;
  public ddlUserByDepartment: DdlItemModel[] = [];
  public model: VendorPermissionDelegationRequestModel;
  public userDeptCode: any;
  public dataSource: VendorPermissionDelegationListViewModel[] = [];


  Permission: PermissionModel = this._authService.GetPagePermission("/vendor permission delegation", "vendor permission delegation/add", "vendor permission delegation/detail", "vendor permission delegation/update", "vendor permission delegation/delete");

  ViewdisplayedColumns: ColumnHeaderModel[] = [
    { Value: "SSOID", Text: "SSOID" },
    { Value: "UserName", Text: "User Name" }

  ];



  constructor(
    private readonly appComponent: AppComponent,
    private readonly _alertService: AlertService,
    public readonly _vendorPermissionDelegationService: VendorPermissionDelegationService,
    private readonly _dialog: MatDialog,
    public readonly _commonService: CommonService,
    private readonly _userService: UserService,
    private readonly _authService: AuthenticationService
  ) {

    this.setSubmitButton();

    this.displayedColumns = ["index", "SSOID", "UserName"];

    this.columnsToDisplay = this.displayedColumns.slice();
    this.model = new VendorPermissionDelegationRequestModel();
    this.indexModel = new IndexModel();

    if (_vendorPermissionDelegationService.canUserTypeCreateWO()) {
      this.Permission.AddPageAccess ? this.appComponent.setPageLayout("Vendor Permission Delegation  :", "add", "Create", "vendor permission delegation/add") : this.appComponent.setPageLayout("Vendor Permission Delegation  :", "", "", "");
    }
    else {
      this.appComponent.setPageLayout("Vendor Permission Delegation  :", "", "", "");
    }
  }

  ngOnInit() {
    this.getData();
    let data = JSON.parse(this._commonService.convertText(localStorage.getItem('UserDetails'), false))
    this.userDeptCode = data.UserViewModel.PrimaryDepartmentCode;
    this.GetUserByDepartment(this.userDeptCode);

  }
  get Form() {
    return this._vendorPermissionDelegationService.Form;
  }
  get DelegateUserID() {
    return this._vendorPermissionDelegationService.DelegateUserID;
  }

  get DelegatorUserID() {
    return this._vendorPermissionDelegationService.DelegatorUserID;
  }

  public onSubmit() {
    if (this.Form.invalid) {
      return;
    }

    this.mapToModel();

    this._vendorPermissionDelegationService.add(this.model).subscribe(response => {
      if (response) {
        this.getData();
      }
    });
  }
  private mapToModel() {
    this.model = this.Form.value;
    let data = JSON.parse(this._commonService.convertText(localStorage.getItem('UserDetails'), false))
    this.model.delegatorUserID = data.UserViewModel.UserCode
    this.model.delegateUserID = +this.model.delegateUserID;
  }

  private setSubmitButton() {

    this.submitButtonText = "Add";

  }

  private getData() {
    this._vendorPermissionDelegationService.getAll(this.indexModel).subscribe(list => {
      this.dataSource = list;
    });
  }

  private GetUserByDepartment(departmentCode = 0) {
    this._commonService.GetUserByDepartment(departmentCode).subscribe(
      data => {

        if (data.IsSuccess) {
          this.ddlList = data.Data;
        }
      },
      error => {
        this._alertService.error(error.message);
      }
    );
  }





}
