import { Component, OnInit } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { DynamicFieldConfig } from "src/app/shared/model/dynamic-field-config.model";
@Component({
  selector: "app-button",
  template: `
<div class="col s12 m12 l12 m-1 text-center" [formGroup]="group">
<button type="submit" mat-raised-button color="primary">{{field.label}}</button>
</div>
`,
  styles: []
})
export class ButtonComponent implements OnInit {
  field: DynamicFieldConfig;
  group: FormGroup;
  fields: DynamicFieldConfig[];

  constructor() {}
  ngOnInit() {}
}
