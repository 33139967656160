import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AppComponent } from 'src/app/app.component';
import { AreaTypeEnum } from 'src/app/shared/enum/fixed-values.enum';
import { OfficeMasterViewModel } from 'src/app/shared/model/master/office-master.model';
import { AlertService } from 'src/app/shared/service/alert.service';
import { OfficeMasterService } from 'src/app/shared/service/master/office-master.service';

@Component({
  selector: 'app-detail-office-master',
  templateUrl: './detail-office-master.component.html',
  styleUrls: ['./detail-office-master.component.scss']
})
export class DetailOfficeMasterComponent implements OnInit {
  model = new OfficeMasterViewModel();
  RecordId: number;
  areaTypeEnum = AreaTypeEnum;
  
  constructor(
    private readonly _appComponent: AppComponent,
    private readonly _router: Router,
    private _route: ActivatedRoute,
    private readonly _officeService: OfficeMasterService,
    private readonly _alertService: AlertService

  ) {
    this.RecordId = this._route.snapshot.params.id;

    this._appComponent.setPageLayout(
      "Office Detail :",
      "keyboard_backspace",
      "Back To List",
      "master/office-master"
    );
  }

  ngOnInit() {
    this.getOfficeDetail();
  }

  getOfficeDetail() {
    
    this._officeService.Detail(this.RecordId).subscribe(
      (data) => {
        
        if (data.IsSuccess) {
          this.model = <OfficeMasterViewModel>data.Data;

        }
      },
      (error) => {
        this._alertService.error(error.message);
      }
    );
  }

}
