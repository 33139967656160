import { Injectable } from "@angular/core";
import { AppSetting } from "../../model/appsetting.model";
import { IndexModel } from "../../model/common.model";
import { Dictionary } from "../../model/dictionary.model";
import { ProjectSubCategoryModel } from "../../model/master/project-sub-category.model";
import { BaseService } from "../base.service";
@Injectable({providedIn:'root'})
export class ProjectSubCategoryService {
    constructor(private readonly _baseService: BaseService, ) { }

    GetList(model: IndexModel) {
        return this._baseService.post(AppSetting.ProjectSubCategoryListUrl, model);
    }

    AddUpdate(id: number, model: ProjectSubCategoryModel) {
        return this._baseService.post(AppSetting.ProjectSubCategoryAddUpdateUrl, model);
    }

    Detail(id: number) {
         return this._baseService.get(AppSetting.ProjectSubCategoryDetailUrl + id);
    }

    ChangeDeleteStatus(id: number) {
        return this._baseService.get(AppSetting.ProjectSubCategoryDeleteStatusChangeUrl + id);
    }

    ChangeActiveStatus(id: number) {
        return this._baseService.get(AppSetting.ProjectSubCategoryActiveStatusChangeUrl + id);
    }

    IsProjectSubCategoryExist(subCategoryName: string,id:number,catId:number){
        const param = new Dictionary<any>();
        param.Add("subCategoryName", subCategoryName);
        param.Add("id", id);
        param.Add("catId", catId);
        return this._baseService.get(AppSetting.IsProjectSubCategoryExistUrl , param);
    }

    GetProjectSubCategoryPDFDownload(model: IndexModel) {
        return this._baseService.post(AppSetting.ProjectSubCategoryPDFDownloadUrl, model);
    }
}
