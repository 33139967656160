import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AppComponent } from 'src/app/app.component';
import { UserTypeEnum } from 'src/app/shared/enum/fixed-values.enum';
import { ProjectSubCategoryModel } from 'src/app/shared/model/master/project-sub-category.model';
import { AlertService } from 'src/app/shared/service/alert.service';
import { AuthenticationService } from 'src/app/shared/service/authentication.service';
import { ProjectSubCategoryService } from 'src/app/shared/service/master/project-sub-category.service';

@Component({
  selector: 'app-project-sub-category-detail',
  templateUrl: './project-sub-category-detail.component.html',
  styleUrls: ['./project-sub-category-detail.component.scss']
})
export class ProjectSubCategoryDetailComponent implements OnInit {

  model: ProjectSubCategoryModel;
  RecordId: number;
  IsStorage: boolean;

  constructor(private readonly _appComponent: AppComponent,
    private readonly _router: Router,
    private _route: ActivatedRoute,
    private readonly _projectCategoryService: ProjectSubCategoryService,
    private readonly _alertService: AlertService,
    private readonly _authService: AuthenticationService
  ) {
    this.RecordId = this._route.snapshot.params.id;
    this.model = new ProjectSubCategoryModel();
    this._appComponent.setPageLayout(
      "Project Sub Category Detail :",
      "keyboard_backspace",
      "Back To List",
      "master/project-sub-category"
    );
  }

  ngOnInit() {
    this.getDetail();
  }

  getDetail() {
    this._projectCategoryService.Detail(this.RecordId).subscribe(
      data => {
        if (data.IsSuccess) {
          this.model = <ProjectSubCategoryModel>data.Data;
        }
      },
      error => {
        this._alertService.error(error.message);
      }
    );
  }

  ngOnDestroy() {
    this.IsStorage = true;
    sessionStorage.setItem("IsProjectSubCategoryStorage", JSON.stringify(this.IsStorage));
  }

}
