import { Injectable } from '@angular/core';
import { BaseService } from '../base.service';
import { AppSetting } from '../../model/appsetting.model';
import { IndexModel } from '../../model/common.model';
import { MasterDataModel } from '../../model/master/master-data.model';

@Injectable({
    providedIn: 'root'
})
export class MasterDataService {
    constructor(private readonly _baseService: BaseService,) { }

    GetList(model: IndexModel) {
        return this._baseService.post(AppSetting.MasterDataListUrl, model);
    }

    GetPDFDownload(model: IndexModel) {
        return this._baseService.post(AppSetting.MasterDataPDFDownloadUrl, model);
    }


    AddUpdate(id: number, model: MasterDataModel) {
        return this._baseService.post(AppSetting.MasterDataAddUpdateUrl, model);
    }

    Detail(id: number) {
        return this._baseService.get(AppSetting.MasterDataDetailUrl + id);
    }

    ChangeDeleteStatus(id: number) {
        return this._baseService.get(AppSetting.MasterDataDeleteStatusChangeUrl + id);
    }

    ChangeActiveStatus(id: number) {
        return this._baseService.get(AppSetting.MasterDataActiveStatusChangeUrl + id);
    }

    IsMasterDataExist(masterTypeCode: number, vendorType: string) {
        return this._baseService.get(AppSetting.IsMasterDataExistUrl + masterTypeCode + vendorType);
    }

    IsMasterDataExist1(model: MasterDataModel) {
        return this._baseService.post(AppSetting.IsMasterDataExistUrl, model);
    }
}
