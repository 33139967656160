import { Component, OnInit } from '@angular/core';
import { UserTypeEnum } from 'src/app/shared/enum/fixed-values.enum';
import { ProjectModel, ProjectViewModel } from 'src/app/shared/model/project.model';
import { AppComponent } from 'src/app/app.component';
import { CommonService } from 'src/app/shared/service/common.service';
import { ActivatedRoute } from '@angular/router';
import { AlertService } from 'src/app/shared/service/alert.service';
import { MatDialog } from '@angular/material';
import { ProjectService } from 'src/app/shared/service/project.service';
import { AuthenticationService } from 'src/app/shared/service/authentication.service';
import { isNullOrUndefined } from 'util';

@Component({
  selector: 'app-project-detail',
  templateUrl: './project-detail.component.html',
  styleUrls: ['./project-detail.component.scss']
})
export class ProjectDetailComponent implements OnInit {

  loginBaseUserType: string;
  userTypeEnum = UserTypeEnum;
  model = new ProjectViewModel();;
  recordId: number = 0;

  constructor(
    private readonly appComponent: AppComponent,
    public readonly _commonService: CommonService,
    private readonly _alertService: AlertService,
    private _route: ActivatedRoute,
    private readonly _dialog: MatDialog,
    private readonly _projectService: ProjectService,
    private readonly _authService: AuthenticationService
  ) {
    this.appComponent.setPageLayout("Project Detail :", "keyboard_backspace", "Back To List", "project");

    if (!isNullOrUndefined(this._route.snapshot.params.id)) {
      this.recordId = this._route.snapshot.params.id;
    }
    this.loginBaseUserType = this._authService.BaseUserType;
  }

  ngOnInit() {
    this.getDetail();
  }

  getDetail() {
    if (this.recordId > 0) {
      this._projectService.Detail(this.recordId).subscribe(data => {
        if (data.IsSuccess) {
          this.model = <ProjectViewModel>data.Data;
        }
      }, error => {
        this._alertService.error(error.message);
      });
    }
  }

  projectAcceptMethod(id) {
    this._projectService.ChangeAcceptMethod(id).subscribe(data => {
      if (data.IsSuccess) {
        this._alertService.success(data.Message);
        this.getDetail();
      }
    }, error => {
      this._alertService.error(error.message);
    });
  }

  CallPrint(printContent) {
    this._commonService.printHTMLContent(printContent);
  }
}