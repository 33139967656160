import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppSetting } from '../../model/appsetting.model';
import { OfficeAddRequestModel } from '../../model/preferred-list/office-add-request.model';
import { OfficeFilterModel } from '../../model/preferred-list/office-filter.model';
import { BaseService } from '../base.service';

@Injectable()
export class OfficeListService {

  constructor(
    private readonly _baseService: BaseService
  ) { }

  GetList(model: OfficeFilterModel): Observable<any> {
    const url = AppSetting.GetAllOfficeList;
    return this._baseService.post(url, new OfficeFilterModel());
  }

  AddAllOffice(model: OfficeAddRequestModel): Observable<any> {
    const url = AppSetting.AddAllOffice;
    return this._baseService.post(url, model);
  }

}
