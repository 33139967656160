export class NavItem {
    displayName: string;
    disabled?: boolean;
    iconName: string | any;
    route?: string;
    children?: NavItem[];
    isRedirect?: boolean = false;
    isExpanded?: boolean = false;
    isParent?: boolean = false;
    isActivate?: boolean = false;
    dataCount?: string;
}
