import { IndexModel } from './common.model';
export class CommunicationModel {
  CommunicationCode: number;
  Subject: string;
  Description: string;
  WorkOrderCode: number | string;
  WorkOrderTitle: string;
  WorkOrderSubject: string;
  OrderDate: Date | null;
  OrderNumber: string;
  CommunicationTypeCode: number | string;
  CommunicationType: string;
  CommunicationTypeHindi: string;
  CommunicationTypeEnumValue: string;
  OfficeCode: number | string;
  OfficeName: string;
  OfficeDDOCode: number | null;
  DepartmentCode: number | string;
  DepartmentName: string;
  DepartmentNameHindi: string;
  DistrictCode: number | string;
  DistrictName: string;
  DistrictNameHindi: string;
  RefNo: string;
  Status: string;
  ProjectOrTenderName: string;
  ProjectName: string;
  StatusDate: string;
  StatusDateTime: Date | string;
  IsActive: boolean;
  IsDeleted: boolean;
  IsCreatedByVendor: number;
  CreatedBy: number | null;
  CreatedOn: Date | null;
  ModifiedBy: number | null;
  ModifiedOn: Date | null;
  DocumentList: CommunicationDocumentModel[] = [];
  DeletedDocumentCodes: string;
  IsView_BtnAction: boolean = false;
  IsView_BtnActionHistory: boolean = false;
  OfficeStaffCode: number | string;
  OfficeStaffUser: string;
  Purpose: string;
  IsEditDelete_BtnActionHistory: boolean = false;
  IsReopen_BtnAction: boolean = false;
  CurrentStatus: string;
  CanReturn: number;
  CanReOpen: number;
  VendorUserCode: number | null;
  VendorContactPersonName: string;
  ForwordToActionCode: number | string;
  LastActionDate: Date | null;
  IsPreviousCommunicationExists: boolean | null;
  DRECADLCUserDetail: string;
}

export class CommunicationDocumentModel {
  DocumentCode: number;
  CommunicationCode: number;
  AttachmentTypeCode: number | null;
  AttachmentType: string;
  AttachmentTypeHindi: string;
  DocName: string;
  DocPath: string;
  Ext: string;
}

export class CommunicationSearchModel {
  constructor() {
    this.indexModel = new IndexModel();
    this.ActiveView = -1;
  }
  LoginUserCode: number;
  CommunicationTypeEnum: string;
  ReferenceNumber: string;
  DepartmentCode: number | string;
  FromDate: string;
  ToDate: string;
  SearchText: string;
  Status: string;
  indexModel: IndexModel;
  //CommunicationTypeCode: number | null;
  OfficeCode: number | null;
  DistrictCode: number | string;
  ProjectCode: number | null;
  WorkOrderCode: number | null;
  ActiveView: number;
  VendorCode: number | null;
  OfficeStaffCode: number | null;
  BankCode: number | null;
  VendorUserCode: number | null;
  IsCreatedByVendor: number;
}
export class CommunicationReportSearchViewModel {
  constructor() {
    this.indexModel = new IndexModel();
    this.ProjectCode = '';
    this.DepartmentCode = '';
    this.VendorCode = 0;
    this.OfficeCode = 0;
    this.CommunicationTypeCode = 0;
    this.WorkOrderCode = '';
    this.UserType = '';
    this.UserCode = '';
    // this.FromDate = new Date((new Date().getTime()));
    // this.ToDate = new Date((new Date().getTime()));
    this.FromDate = '';
    this.ToDate = '';
    this.SearchText = '';
    //this.Status = 'SENT';
    this.Status = '';
    this.IsExportToExcel = false;
  }
  Status: string;
  ProjectCodes: string[];
  ProjectCode: string;
  DepartmentCodes: string[];
  DepartmentCode: string;
  VendorCode: number | null;
  OfficeCode: number | null;
  CommunicationTypeCode: number | null;
  WorkOrderCodes: string[];
  WorkOrderCode: string;
  FromDate: string;
  ToDate: string;
  SearchText: string;
  indexModel: IndexModel;
  UserTypes: string[];
  UserType: string;
  UserCodes: string[]; UserCode: string;
  IsExportToExcel: boolean;
}
export class CommunicationClearModel {
  constructor() {

    this.DepartmentCode = 0;
    this.VendorCode = 0;
    this.CommunicationTypeForUserTypeCode = 0;

  }
  DepartmentCode: number | string;
  VendorCode: number | string;
  CommunicationTypeForUserTypeCode: number | string;
}
