import { Component, OnInit, ViewChild } from "@angular/core";
import { AppComponent } from "src/app/app.component";
import { AlertService } from "src/app/shared/service/alert.service";
import { MatDialog } from "@angular/material";
import { CommonService } from "src/app/shared/service/common.service";
import { GlobalMessagesModel } from 'src/app/shared/model/common-messages.model';
import { UserService } from "src/app/shared/service/user.service";
import { UserViewModel } from "src/app/shared/model/user.model";
import { UserDetailDialogComponent } from "../../user/user-detail-dialog/user-detail-dialog.component";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { UpdateUserOfficeDialogComponent } from "../update-user-office-dialog/update-user-office-dialog.component";

@Component({
  selector: 'app-locate-user',
  templateUrl: './locate-user.component.html',
  styleUrls: ['./locate-user.component.scss']
})

export class LocateUserComponent implements OnInit {

  frmGrp: FormGroup;
  SSOId:string;
  model: UserViewModel;

  constructor(
    private readonly fb: FormBuilder,
    private readonly appComponnet: AppComponent,
    private readonly _alertService: AlertService,
    private readonly _dialog: MatDialog,
    public readonly _commonService: CommonService,
    private readonly _userService: UserService
  ) {
    this.appComponnet.setPageLayout("Locate User");
  }

  ngOnInit() {
    this.formGroupInit();
  }

  formGroupInit() {
    this.frmGrp = this.fb.group({
      SSOId: [null, Validators.required],
    });
  }

  onSubmit() {
    this.model = null;
    this.frmGrp.markAllAsTouched();
    if (this.frmGrp.valid) {
      this._userService.LocateUser(this.SSOId).subscribe((data) => {
        if (data.IsSuccess) {
          this.model = <UserViewModel>data.Data;
        } else {
          this._alertService.error(GlobalMessagesModel.DataRetrieveIssue);
        }
      }, (error) => {
        this._alertService.error(GlobalMessagesModel.DataRetrieveIssue);
      });
    }
  }

  openUserDetailDialog(id: number) {
    const _dialogRef = this._dialog.open(UserDetailDialogComponent, {
      width: "1000px",
      data: { Id: id },
      disableClose: true
    });
  }

  openUpdateUserOfficeDialog(id: number) {
    const _dialogRef = this._dialog.open(UpdateUserOfficeDialogComponent, {
      width: "500px",
      height: "425px",
      data: { id: id },
      disableClose: true
    });
  }

}
