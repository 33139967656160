import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatSort, MatDialog, MatTableDataSource } from '@angular/material';
import { ActivatedRoute } from '@angular/router';
import { AppComponent } from 'src/app/app.component';
import { ConfirmationDialogComponent } from 'src/app/confirmation-dialog/confirmation-dialog.component';
import { AppSetting } from 'src/app/shared/model/appsetting.model';
import { GlobalMessagesModel } from 'src/app/shared/model/common-messages.model';
import { DDLModel, ColumnHeaderModel, DdlItemModel } from 'src/app/shared/model/commonddl.model';
import { CustomSearchModel, GeneralEntryModel } from 'src/app/shared/model/general-entry-model.model';
import { GeneralEntryCategoryMasterModel } from 'src/app/shared/model/master/general-entry-category-master.model';
import { GeneralEntrySubCategoryMasterModel } from 'src/app/shared/model/master/general-entry-sub-category-master.model';
import { UserDepartmentViewModel, UserViewModel } from 'src/app/shared/model/user.model';
import { AlertService } from 'src/app/shared/service/alert.service';
import { AuthenticationService } from 'src/app/shared/service/authentication.service';
import { CommonService } from 'src/app/shared/service/common.service';
import { GeneralEntryService } from 'src/app/shared/service/general-entry.service';
import { UserService } from 'src/app/shared/service/user.service';

@Component({
  selector: 'app-general-entry',
  templateUrl: './general-entry.component.html',
  styleUrls: ['./general-entry.component.scss']
})
export class GeneralEntryComponent implements OnInit {

  //#region Variable
  model: GeneralEntryModel[];
  loginData: UserViewModel;
  dataSource: any;
  // catCode: number;
  searchModel = this._commonService.modelSetGet(new CustomSearchModel()) as CustomSearchModel;
  dDLList = new DDLModel();
  deptlNameItems: { [index: string]: string } = {};
  categoryNameItems: { [index: string]: string } = {};
  subcategoryNameItems: { [index: string]: string } = {};
  displayedColumns: string[] = [
    "index",
    "Department",
    //"GeneralEntryCategory",
    // "GeneralEntrySubCategory",
    //"GeneralEntry",
    "GeneralEntryHindi",
    //"CMOComments",
    "DescriptionHindi",
    "UserName",
    "IsActive",
    "Action",
  ];
  ViewdisplayedColumns: ColumnHeaderModel[] = [
    // { Value: "Department", Text: "Department" },
    // { Value: "GeneralEntryCategory", Text: "Category" },
    // { Value: "GeneralEntrySubCategory", Text: "Sub category" },
    // //{ Value: "GeneralEntry", Text: "GeneralEntry" },
    // { Value: "GeneralEntryHindi", Text: "GeneralEntry (Hindi)" },
    // { Value: "CMOComments", Text: "CMO Comments" },
    // { Value: "GeneralEntryDate", Text: "Date" },
  ];

  columnsToDisplay: string[] = this.displayedColumns.slice();
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  indexModel: CustomSearchModel;
  totalRecords: number;

  Permission = this._authService.GetPagePermission(
    "general-entry",
    "general-entry/add",
    "general-entry/detail",
    "general-entry/update",
    "general-entry/delete",
    "general-entry/update-comment"
  );
  ddlDepartment: UserDepartmentViewModel[];
  ddlGeneralEntryCategory: GeneralEntryCategoryMasterModel[];
  ddlGeneralEntrySubCategoryList: GeneralEntrySubCategoryMasterModel[];
  fromDate: Date | string; //= new Date("12/17/2018");
  toDate: Date | string; //= new Date();
  isShow = true;
  toggleDisplay() {
    this.isShow = !this.isShow;
  }
  //#endregion

  //#region constructor
  constructor(
    private readonly _appComponent: AppComponent,
    private readonly _alertService: AlertService,
    private readonly _generalEntryService: GeneralEntryService,
    private readonly _dialog: MatDialog,
    private readonly _userService: UserService,
    private readonly _authService: AuthenticationService,
    public readonly _commonService: CommonService,
    private _route: ActivatedRoute,
  ) {

    this.loginData = this._authService.GetCurrentUserDetail().UserViewModel;
    this.Permission.AddPageAccess
      ? this._appComponent.setPageLayout('General Entry :', "add", "Create", "general-entry/add")
      : this._appComponent.setPageLayout('General Entry :');

  }
  //#endregion

  //#region  Method
  ngOnInit() {

    this.GetDDLList();
    this.getDepartment();
    this.getGeneralEntrySubcategory();
  }

  getDepartment() {
    this._userService.GetUserDepartment(this.loginData.UserCode).subscribe(
      (data) => {
        if (data.IsSuccess) {
          this.ddlDepartment = <UserDepartmentViewModel[]>data.Data;
        }
      },
      (error) => {
        this._alertService.error(error.message);
      }
    );
  }

  getGeneralEntrySubcategory() {

    this._userService
      .GetGeneralEntrySubCategory(this.loginData.UserCode, Number(this.searchModel.CategoryCode))
      .subscribe(
        (data) => {
          if (data.IsSuccess) {
            let lstSubCategory = <GeneralEntrySubCategoryMasterModel[]>data.Data;
            this.dDLList.ddlGeneralEntrySubCategory = [];
            lstSubCategory.forEach(item => {
              let newItem = new DdlItemModel();
              newItem.Value = item.SubCategoryCode.toString();
              newItem.Text = item.Title;
              this.dDLList.ddlGeneralEntrySubCategory.push(newItem);
            });

          }
        },
        (error) => {
          this._alertService.error(error.message);
        }
      );
  }


  GetDDLList() {
    this._commonService.GetAllDDL(AppSetting.DDLKeyForGeneralEntrySearch).subscribe(
      (data) => {
        if (data.IsSuccess) {
          this.dDLList = <DDLModel>data.Data;
          this.dDLList.ddlDepartment.forEach((obj) => {
            this.deptlNameItems[obj.Value] = obj.Text;
          });
          this.dDLList.ddlGeneralEntryCategory.forEach((obj) => {
            this.categoryNameItems[obj.Value] = obj.Text;
          });
          this.dDLList.ddlGeneralEntrySubCategory.forEach((obj) => {
            this.subcategoryNameItems[obj.Value] = obj.Text;
          });
        }
      },
      (error) => {
        this._alertService.error(error.message);
      }
    );
  }

  updateDeleteStatus(id) {
    const dialogRef = this._dialog.open(ConfirmationDialogComponent, {
      // width: "50%",
      data: GlobalMessagesModel.ConfirmStatusChanged,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {

        this._generalEntryService.ChangeDeleteStatus(id).subscribe(
          (data) => {
            if (data.IsSuccess) {
              this._alertService.success(data.Message);
              this._commonService.ScrollingTop();
              this.getList();
            }
          },
          (error) => {
            this._alertService.error(error.message);
          }
        );
      }
    });
  }

  updateActiveStatus(id) {
    const dialogRef = this._dialog.open(ConfirmationDialogComponent, {
      // width: "50%",
      data: GlobalMessagesModel.ConfirmStatusChanged,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this._generalEntryService.ChangeActiveStatus(id).subscribe(
          (data) => {
            if (data.IsSuccess) {
              this._alertService.success(data.Message);
              this.getList();
            }
          },
          (error) => {
            this._alertService.error(error.message);
          }
        );
      }
    });
  }

  sortData(event) {
    this.searchModel.indexmodel.OrderBy = event.active;
    this.searchModel.indexmodel.OrderByAsc =
      event.direction == AppSetting.orderByDscAsc
        ? AppSetting.orderByAsc
        : AppSetting.orderByDsc;
    this.searchModel.indexmodel.IsPostBack = true;
    this.getList();
  }

  onPaginateChange(event) {

    this.searchModel.indexmodel.Page = event.pageIndex + 1;
    this.searchModel.indexmodel.PageSize = event.pageSize;
    this.searchModel.indexmodel.IsPostBack = true;
    this.getList();
  }



  reset() {
    this.searchModel = new CustomSearchModel();
    this.indexModel = new CustomSearchModel();
    this.searchModel.indexmodel.OrderByAsc = 1;
    //this.searchModel.indexmodel.PageSize = 10000000;
    this.fromDate = null;
    this.toDate = null;
    //  
    this.dataSource = null;
    this.model = null;
    this.getList();
  }

  print() {
    this.searchModel.indexmodel.PageSize = 101;
    this._generalEntryService
      .GetFilterList(this.searchModel, this.loginData.UserCode)
      .subscribe(
        (data) => {
          if (data.IsSuccess) {
            this.model = <GeneralEntryModel[]>data.Data.Data;
            this.dataSource = new MatTableDataSource<GeneralEntryModel>(
              this.model
            );
            this.totalRecords = data.Data.TotalRecords;
            if (!this.searchModel.indexmodel.IsPostBack) {
              this.dataSource.paginator = this.paginator;
              this.dataSource.sort = this.sort;
              let printContents, popupWin;
              printContents = document.getElementById("print").outerHTML;
              popupWin = window.open(
                "",
                "_blank",
                "top=0,left=0,height=100%,width=auto"
              );
              popupWin.document.open();
              popupWin.document.write(`
    <html>
      <head>
        <title>Important Decisions</title>
        <style>
        .doNotPrint{display:none !important;}
        table th,table td{
          border: 1px solid black;

          // padding:1px;
        }
        #print {
          border-collapse: collapse;
        }
        </style>
      </head>
  <body onload="window.print();window.close()">${printContents}</body>
    </html>`);
              popupWin.document.close();
            }
          } else {
            this._alertService.error(data.Message);
          }
        },
        (error) => {
          this._alertService.error(error.message);
        }
      );
  }

  downloadCsv() {
    this.searchModel.IsExportToExcel = true;
    this.getList();
  }

  getList() {

    if (this.fromDate) {
      const uTCFromDate = new Date(
        Date.UTC(
          new Date(this.fromDate).getFullYear(),
          new Date(this.fromDate).getMonth(),
          new Date(this.fromDate).getDate()
        )
      );
      this.fromDate = uTCFromDate;
    }

    if (this.toDate) {
      const uTCToDate = new Date(
        Date.UTC(
          new Date(this.toDate).getFullYear(),
          new Date(this.toDate).getMonth(),
          new Date(this.toDate).getDate()
        )
      );
      this.toDate = uTCToDate;
    }
    this.searchModel.CreatedFrom = this.fromDate;
    this.searchModel.CreatedTo = this.toDate;

    this._generalEntryService
      .GetFilterList(this._commonService.modelSetGet(this.searchModel, true), this.loginData.UserCode)
      .subscribe(
        (data) => {
          if (data.IsSuccess) {
            this.model = <GeneralEntryModel[]>data.Data.Data;
            this.dataSource = new MatTableDataSource<GeneralEntryModel>(
              this.model
            );
            this.totalRecords = data.Data.TotalRecords;
            this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.sort;
            // if (!this.searchModel.indexmodel.IsPostBack) {
            //   // this.dataSource.paginator = this.paginator;
            //   this.dataSource.sort = this.sort;
            // }
          } else {
            this._alertService.error(data.Message);
          }
        },
        (error) => {
          this._alertService.error(error.message);
        }
      );

  }

  downloadPdf(Url) {
    // 

    var w = window.open('about:blank');

    setTimeout(function () { //FireFox seems to require a setTimeout for this to work.
      w.document.body.appendChild(w.document.createElement('iframe'))
        .src = Url;
      w.document.getElementsByTagName("iframe")[0].style.width = '100%';
      w.document.getElementsByTagName("iframe")[0].style.height = '100%';
    }, 0);


  }


  //#endregion
}
