import { CommunicationInitiateByEnum } from './../../../shared/enum/fixed-values.enum';
import { UserViewModel } from 'src/app/shared/model/user.model';
import { UserService } from 'src/app/shared/service/user.service';
import { CommunicationSearchModel } from './../../../shared/model/communication.model';
import { TitleCasePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatSort, MatDialog, MatTableDataSource } from '@angular/material';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { AppComponent } from 'src/app/app.component';
import { ConfirmationDialogComponent } from 'src/app/confirmation-dialog/confirmation-dialog.component';
import { GlobalCommunicationSearchComponent } from 'src/app/global-communication-search/global-communication-search.component';
import { UserManualDialogComponent } from 'src/app/header/user-manual-dialog/user-manual-dialog.component';
import { UserTypeEnum, StatusEnum, CommunicationTypeEnum } from 'src/app/shared/enum/fixed-values.enum';
import { AppSetting } from 'src/app/shared/model/appsetting.model';
import { GlobalMessagesModel } from 'src/app/shared/model/common-messages.model';
import { IndexModel, PermissionModel, GlobalFilterModel } from 'src/app/shared/model/common.model';
import { DdlItemModel, DDLModel, ColumnHeaderModel } from 'src/app/shared/model/commonddl.model';
import { CommunicationModel } from 'src/app/shared/model/communication.model';
import { GlobalFilterSearchModel } from 'src/app/shared/model/Global-communication-search.model';
import { CommunicationUserTypeViewModel } from 'src/app/shared/model/master/communication-type.model';
import { AlertService } from 'src/app/shared/service/alert.service';
import { AuthenticationService } from 'src/app/shared/service/authentication.service';
import { CommonService } from 'src/app/shared/service/common.service';
import { CommunicationService } from 'src/app/shared/service/communication.service';
import { B2GUserManualService } from 'src/app/shared/service/master/b2-guser-manual.service';
import { CommunicationTypeService } from 'src/app/shared/service/master/communication-type.service';
import { isNullOrUndefined } from 'util';
import { ActionDialogComponent } from '../../communication/action-dialog/action-dialog.component';
import { CommunicationReOpenDialogComponent } from '../../communication/communication-re-open-dialog/communication-re-open-dialog.component';
import { StatusMasterModel } from 'src/app/shared/model/master/status-master.model';
import { StatusMasterService } from 'src/app/shared/service/master/status-master.service';

@Component({
  selector: 'app-dashboard-communication-list',
  templateUrl: './dashboard-communication-list.component.html',
  styleUrls: ['./dashboard-communication-list.component.scss']
})

export class DashboardCommunicationListComponent implements OnInit {
  model: CommunicationModel[];
  loginUserCode: number;
  loginBaseUserType: string;
  userTypeEnum = UserTypeEnum;
  dataSource: any;
  totalRecords: number;
  ddlStatus: DdlItemModel[] = [];
  status: string = '';
  search: string = '';
  statusPara: string;
  ddlList: DDLModel;
  communicationTypeCode: string;
  communicationTypeEnum: string = "";
  communicationTypeTitle: string = "";
  moduleCode: string;
  communicationStatusEnum = StatusEnum;
  IsAddEdit = false;
  Permission: PermissionModel = this._authService.GetPagePermission("/communications-list", "/communications-list/create", "/communications-list/detail", "/communications-list/update", "/communications-list/delete");
  isShow = true;
  searchModel = this._commonService.modelSetGet(new CommunicationSearchModel(), false) as CommunicationSearchModel;
  fromDate: Date | string;
  toDate: Date | string;
  ddlDistrict: DdlItemModel[] = [];
  loginUserDetail: UserViewModel;
  IsCreatedByVendor: string = "";
  modelStatus: StatusMasterModel;
  displayedColumns: string[] = ["index", "Subject", "DepartmentName", "OfficeName", "CommunicationType", "RefNo", "LastActionDate", "CurrentStatus", "Action"];
  columnsToDisplay: string[] = this.displayedColumns.slice();
  ViewdisplayedColumns: ColumnHeaderModel[] = [
    { Value: "Subject", Text: "Subject" },
    { Value: "DepartmentName", Text: "Department" },
    { Value: "OfficeName", Text: "Office" },
    { Value: "CommunicationType", Text: "Communication Type" },
    { Value: "RefNo", Text: "References No." },
    { Value: "CurrentStatus", Text: "Status" },
  ];

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  isShowUserManual = true;
  constructor(
    private readonly appComponnet: AppComponent,
    private readonly _alertService: AlertService,
    private readonly _router: Router,
    private _route: ActivatedRoute,
    private readonly _communicationService: CommunicationService,
    private readonly _dialog: MatDialog,
    public readonly _commonService: CommonService,
    private readonly _authService: AuthenticationService, public titleCasePipe: TitleCasePipe,
    private readonly _communicationTypeService: CommunicationTypeService,
    private readonly _b2gUserManualMasterService: B2GUserManualService,
    private readonly _userService: UserService,
    private readonly _statusMasterService: StatusMasterService,
  ) {


    if (!isNullOrUndefined(this._route.snapshot.queryParams.status) || !isNullOrUndefined(this._route.snapshot.params.status)) {
      this.status = this._route.snapshot.queryParams.status ? this._route.snapshot.queryParams.status : this._route.snapshot.params.status;
      this.searchModel.Status = this.status;
    }

    if (!isNullOrUndefined(this._route.snapshot.queryParams.isVendorCommunication) || !isNullOrUndefined(this._route.snapshot.params.isVendorCommunication)) {

      this.searchModel.IsCreatedByVendor = this._route.snapshot.queryParams.isVendorCommunication ? this._route.snapshot.queryParams.isVendorCommunication : this._route.snapshot.params.isVendorCommunication;
      this.IsCreatedByVendor = this.searchModel.IsCreatedByVendor.toString();
    }
    if (!isNullOrUndefined(this._route.snapshot.queryParams.typeEnum) || !isNullOrUndefined(this._route.snapshot.params.typeEnum)) {
      this.communicationTypeEnum = this._route.snapshot.queryParams.typeEnum ? this._route.snapshot.queryParams.typeEnum : this._route.snapshot.params.typeEnum;
      this.communicationTypeTitle = this.titleCasePipe.transform(this.communicationTypeEnum.split("_")[1]);
    }
    this.loginUserCode = this._authService.UserCode;
    this.loginBaseUserType = this._authService.BaseUserType;
    this.GetStatusName(this.status);
  }

  GetGlobalSearchData() {

    this.searchModel.CommunicationTypeEnum = this.communicationTypeEnum;
    this._communicationService.GetCommunicationListBySearch(this._commonService.modelSetGet(this.searchModel, true)).subscribe(
      (data) => {

        if (data.IsSuccess) {
          this.model = <CommunicationModel[]>data.Data.Data ? data.Data.Data : [];

          if (this.model.length > 0) {
            this.communicationTypeTitle = this.model[0].CommunicationType;
          }

          this.model.forEach(element => {
            if (element.CurrentStatus != this.communicationStatusEnum.SENT) {
              element.IsView_BtnActionHistory = true;
            }
            if (element.CurrentStatus == this.communicationStatusEnum.RETURN || element.CurrentStatus == this.communicationStatusEnum.SENT) {
              element.IsEditDelete_BtnActionHistory = true;
            }

            if (element.CurrentStatus == this.communicationStatusEnum.DISPOSED && (this.loginBaseUserType == this.userTypeEnum.VNDR || this.loginBaseUserType == this.userTypeEnum.VNDRUSER)) {
              element.IsReopen_BtnAction = true;
            }
            else {
              element.IsReopen_BtnAction = false;
            }

            if (this.loginBaseUserType == this.userTypeEnum.VNDR || this.loginBaseUserType == this.userTypeEnum.VNDRUSER) {
              element.IsView_BtnAction = false;
            }
            else if (element.CurrentStatus == this.communicationStatusEnum.RECEIVED) {
              element.IsView_BtnAction = true;
            }
          });

          this.dataSource = new MatTableDataSource<CommunicationModel>(this.model);
          this.totalRecords = data.Data.TotalRecords;
          if (!this.searchModel.indexModel.IsPostBack) {

            this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.sort;
          }

        } else {
          this._alertService.error(data.Message);
        }
      },
      (error) => {
        this._alertService.error(error.message);
      }
    );

  }

  GetDDLList() {
    this._commonService.GetAllDDL(AppSetting.CommunicationDropdownKeys).subscribe(
      data => {
        if (data.IsSuccess) {
          this.ddlList = <DDLModel>data.Data;
        }
      },
      error => {
        this._alertService.error(error.message);
      }
    );
  }

  ngOnInit() {
    this.GetDDLList();
    this._route.paramMap.subscribe((params: ParamMap) => {
      if (!isNullOrUndefined(this._route.snapshot.params.typeEnum)) {
        this.communicationTypeEnum = this._route.snapshot.params.typeEnum;
        this.communicationTypeTitle = this.titleCasePipe.transform(this.communicationTypeEnum.split("_")[1]);
      }
      if (!isNullOrUndefined(this._route.snapshot.queryParamMap.get("status"))) {
        this.status = this._route.snapshot.queryParamMap.get("status");

        this.searchModel.Status = this.status;
      }
      this.checkAddEditRecord();
      this.bindStatus();

      this.GetGlobalSearchData();
      this.GetCountForUserManualUrl();
    });

  }

  bindStatus() {
    var item4: DdlItemModel = new DdlItemModel();
    item4.Text = this.communicationStatusEnum.RETURN;
    item4.Value = this.communicationStatusEnum.RETURN;
    this.ddlStatus.push(item4);

    var item2: DdlItemModel = new DdlItemModel();
    item2.Text = this.communicationStatusEnum.ACCEPT;
    item2.Value = this.communicationStatusEnum.ACCEPT;
    this.ddlStatus.push(item2);

    var item3: DdlItemModel = new DdlItemModel();
    item3.Text = this.communicationStatusEnum.REJECT;
    item3.Value = this.communicationStatusEnum.REJECT;
    this.ddlStatus.push(item3);

    var item1: DdlItemModel = new DdlItemModel();
    item1.Text = (this.loginBaseUserType == this.userTypeEnum.VNDR) ? this.communicationStatusEnum.SENT : this.communicationStatusEnum.RECEIVED;
    item1.Value = this.communicationStatusEnum.SENT;
    this.ddlStatus.push(item1);
  }

  updateActiveStatus(id) {
    const dialogRef = this._dialog.open(ConfirmationDialogComponent, {
      data: GlobalMessagesModel.ConfirmStatusChanged
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this._communicationService.ChangeActiveStatus(id).subscribe(
          data => {
            if (data.IsSuccess) {
              this._alertService.success(data.Message);
              this.GetGlobalSearchData();
              this.GetGlobalSearchData();
            }
          },
          error => {
            this._alertService.error(error.message);
          }
        );
      }
    });
  }

  updateDeleteStatus(id) {
    const dialogRef = this._dialog.open(ConfirmationDialogComponent, {
      data: GlobalMessagesModel.ConfirmDeleted
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this._communicationService.ChangeDeleteStatus(id).subscribe(
          data => {
            if (data.IsSuccess) {
              this._alertService.success(data.Message);
              this._commonService.ScrollingTop(); this.GetGlobalSearchData();
              this.GetGlobalSearchData();
            }
          },
          error => {
            this._alertService.error(error.message);
          }
        );
      }
    });
  }

  sortData(event) {
    this.searchModel.indexModel.OrderBy = event.active;
    this.searchModel.indexModel.OrderByAsc = event.direction == AppSetting.orderByDscAsc ? AppSetting.orderByAsc : AppSetting.orderByDsc;
    this.searchModel.indexModel.IsPostBack = true;
    this.GetGlobalSearchData();
  }

  onPaginateChange(event) {

    this.searchModel.indexModel.Page = event.pageIndex + 1;
    this.searchModel.indexModel.PageSize = event.pageSize;
    this.searchModel.indexModel.IsPostBack = true;
    this.GetGlobalSearchData();
  }

  proceedToAction(Id, Status, CanReturn) {
    const _dialogRef = this._dialog.open(ActionDialogComponent, {
      width: "75%",
      height: "90%",
      data: { id: Id, status: Status, canReturn: CanReturn },
      disableClose: true
    });
    _dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        this.GetGlobalSearchData();
      }
    });
  }

  onClearclick() {
    this.search = '';
    this.status = '';
    this.GetGlobalSearchData();
  }

  clearsearch() {
    this.searchModel = this._commonService.modelSetGet(
      new CommunicationSearchModel(),
      false,
    );
    this.GetGlobalSearchData();
  }

  openPageManual(value) {
    const dialogRef = this._dialog.open(UserManualDialogComponent, {
      width: "90%",
      data: { MasterType: 0, CommunicationType: this.communicationTypeEnum.split("_")[0], isReturn: value }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result != null) {
        this.isShowUserManual = result;
      }
    });
  }

  GetCountForUserManualUrl() {

    if (this.communicationTypeEnum != undefined && this.communicationTypeEnum.length > 0) {
      this._b2gUserManualMasterService.GetCountForUserManualUrl(this.communicationTypeEnum.split("_")[0]).subscribe(
        data => {
          if (data.IsSuccess) {

            let Count = <Number>data.Data;

            this.isShowUserManual = Count > 0 ? true : false;
          }
        },
        error => {
          this._alertService.error(error.message);
        }
      );
    }
  }

  SearchByKeyword(searchValue: GlobalFilterModel) {
    this.GetGlobalSearchData();
  }

  ClearSearch() {
    this.GetGlobalSearchData();
  }

  redirecttoCreatePage() {
    switch (this.communicationTypeCode) {
      case "1":
        this._router.navigate(["/communication/emdreturn/add"]);
        break;
      case "2":
        this._router.navigate(["/communication/invoice/add"]);
        break;
      case "1002":
        this._router.navigate(["/communication/complaint/add"]);
        break;
      case "1003":
        this._router.navigate(["/communication/general/add"]);
        break;
      case "1004":
        this._router.navigate(["/communication/suggestion/add"]);
        break;
      default:
        this._router.navigate(["communications-list/add-update-communication-transaction/" + "letter" + "/0"]);
        break;
    }
  }

  setLayout(lableName = "") {
    if (this.communicationTypeTitle != undefined && this.communicationTypeTitle.length > 0) {
      if (this.Permission.AddPageAccess && this.IsAddEdit) {
        this.appComponnet.setPageLayout((this.communicationTypeTitle != undefined ? this.communicationTypeTitle : "") + " Communication :", "add", "Create", "/communications-list/add-update-communication-transaction/" + this.communicationTypeEnum + "/0", false, false);
      }
      else {
        this.appComponnet.setPageLayout((this.communicationTypeTitle != undefined ? this.communicationTypeTitle : "") + " Communication List :", "", "", "", false, false);
      }
    }
    else {
      this.appComponnet.setPageLayout("Communication List :" + lableName, "keyboard_backspace", "Back To Dashboard", "/Dashboard", false, true);

    }
  }

  checkAddEditRecord() {
    if (this.communicationTypeEnum != undefined && this.communicationTypeEnum.length > 0) {
      this._communicationTypeService.GetUserTypeByCommunicationType(this.communicationTypeEnum).subscribe(
        response => {
          if (response.IsSuccess) {
            this.IsAddEdit = false;
            let resItem: CommunicationUserTypeViewModel[] = response.Data;
            let Item: CommunicationUserTypeViewModel = resItem.filter(item => (item.UserType == this.loginBaseUserType) || (item.UserType == this.userTypeEnum.DREC && (this.loginBaseUserType == this.userTypeEnum.HOO || this.loginBaseUserType == this.userTypeEnum.HOD || this.loginBaseUserType == this.userTypeEnum.DOS || this.loginBaseUserType == this.userTypeEnum.HOOADLC || this.loginBaseUserType == this.userTypeEnum.DRECADLC || this.loginBaseUserType == this.userTypeEnum.DOSADLC)) || (item.UserType == this.userTypeEnum.VNDR && (this.loginBaseUserType == this.userTypeEnum.VNDRUSER)))[0];
            this.communicationTypeTitle = resItem != null ? resItem[0].CommunicationType : this.communicationTypeTitle;

            if (Item != undefined && Item.UserType == this.loginBaseUserType) {
              this.IsAddEdit = true;

            } else if (Item != undefined && Item.UserType == this.userTypeEnum.DREC && (this.loginBaseUserType == this.userTypeEnum.HOO || this.loginBaseUserType == this.userTypeEnum.HOD || this.loginBaseUserType == this.userTypeEnum.DOS || this.loginBaseUserType == this.userTypeEnum.HOOADLC || this.loginBaseUserType == this.userTypeEnum.DRECADLC || this.loginBaseUserType == this.userTypeEnum.DOSADLC)) {
              this.IsAddEdit = true;
            }

            else if (Item != undefined && Item.UserType == this.userTypeEnum.VNDR && this.loginBaseUserType == this.userTypeEnum.VNDRUSER) {
              this.IsAddEdit = true;
            }
          }

        }, error => { }
      );
    } else {
    }
  }

  ReOpenCommunciation(code, commTypeEmun) {
    const dialogRef = this._dialog.open(ConfirmationDialogComponent,
      {
        //  width: "75%",
        height: "95%",
        data: GlobalMessagesModel.ReopenCommunication
      });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        //  this._router.navigate(['/communications-list/add-update-communication-transaction/'+commTypeEmun+'/'+code]);  

        this.openReopnDialogBox(code, commTypeEmun);

      }
    });
  }

  openReopnDialogBox(code, commTypeEmun) {
    const _dialogRef = this._dialog.open(CommunicationReOpenDialogComponent, {
      width: "75%",
      height: "95%",
      data: { Id: code, CommTypeEmun: commTypeEmun },
      disableClose: true
    });
    _dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        this.GetGlobalSearchData();
      }
    });
  }

  openCommunicationSearchModal() {
    const _dialogRef = this._dialog.open(GlobalCommunicationSearchComponent, {
      width: "75%",
      height: "95%",
      data: { IsShowCommunicationType: false, CommunicationType: this.communicationTypeEnum, IsShowSearchButton: true },
      disableClose: true
    });
    _dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.isShow = !this.isShow;
        this.searchModel = result;
        this.GetGlobalSearchData();
      }
    });

  }

  Reset() {
    this.searchModel.indexModel = new IndexModel();
    this.searchModel = new CommunicationSearchModel();
    this.status = this._route.snapshot.queryParamMap.get("status");
    this.searchModel.Status = this.status;
    this.GetGlobalSearchData();
  }

  GetProjectListByDepartmentWise() {
    if (
      !isNullOrUndefined(this.searchModel.DepartmentCode)
    ) {
      this._commonService
        .GetDDLMultipleValue(
          AppSetting.ddlProject,
          this.searchModel.DepartmentCode.toString(),
          this.loginUserCode
        )
        .subscribe(
          (data) => {
            if (data.IsSuccess) {
              var ddl = <DDLModel>data.Data;
              this.ddlList.ddlProject = ddl.ddlProject;
            }
          },
          (error) => {
            this._alertService.error(error.message);
          }
        );
    }
  }

  GetOfficeByDepartment() {
    if (!isNullOrUndefined(this.searchModel.DepartmentCode)) {
      this.searchModel.DistrictCode = this.searchModel.DistrictCode != undefined && this.searchModel.DistrictCode > 0 ? this.searchModel.DistrictCode : (this.loginUserDetail != undefined && this.loginUserDetail.DistrictCode != undefined && this.loginUserDetail.DistrictCode.length > 0 ? this.loginUserDetail.DistrictCode[0] : undefined);
      this._commonService.GetDDL(AppSetting.ddlOfficeByLoginUserId, this.searchModel.DepartmentCode, this.searchModel.DistrictCode).subscribe(
        (data) => {

          if (data.IsSuccess) {
            var ddl = <DDLModel>data.Data;
            this.ddlList.ddlOfficeByLoginUserId = ddl.ddlOfficeByLoginUserId;
          }
        },
        (error) => {
          this._alertService.error(error.message);
        }
      );

    } else {
      this.ddlList.ddlOfficeByLoginUserId = null;
    }
  }

  GetWorkOrderByproject() {
    if (
      !isNullOrUndefined(
        this.searchModel.ProjectCode
      )
    ) {
      this._commonService
        .GetDDLMultipleValue(
          AppSetting.ddlWorkOrder,
          this.searchModel.ProjectCode.toString(),
          this.loginUserCode
        )
        .subscribe(
          (data) => {
            if (data.IsSuccess) {
              var ddl = <DDLModel>data.Data;
              this.ddlList.ddlWorkOrder = ddl.ddlWorkOrder;
            }
          },
          (error) => {
            this._alertService.error(error.message);
          }
        );
    } else {
      this.ddlList.ddlWorkOrder = null;
    }
  }

  toggleDisplay() {
    this.isShow = !this.isShow;
  }

  GetDistrictByDepartment() {
    if (!isNullOrUndefined(this.searchModel.DepartmentCode)) {
      this._commonService.GetDDL(AppSetting.ddlDistrict, this.searchModel.DepartmentCode).subscribe(
        data => {
          if (data.IsSuccess) {
            var ddl = <DDLModel>data.Data;
            this.ddlList.ddlDistrict = ddl.ddlDistrict;
          }
        },
        error => {
          this._alertService.error(error.message);
        }
      );
    }
    else {
      this.ddlList.ddlDistrict = null;
    }
  }

  GetStatusName(name: string) {
    var _status = status;
    this._statusMasterService.GetByStatus(name).subscribe(
      data => {
        if (data.IsSuccess) {
          this.modelStatus = <StatusMasterModel>data.Data;

          if (this.loginBaseUserType == this.userTypeEnum.VNDR ||
            this.loginBaseUserType == this.userTypeEnum.VNDRUSER) {
            if (this.IsCreatedByVendor == CommunicationInitiateByEnum.V2DValue.toString()) {
              _status = this.modelStatus.VTOD_VendorDisplayName;
            }
            else {
              _status = this.modelStatus.DTOV_VendorDisplayName;
            }
          }
          else {
            if (this.IsCreatedByVendor == CommunicationInitiateByEnum.V2DValue.toString()) {
              _status = this.modelStatus.VTOD_DepartmentDisplayName;
            }
            else {
              _status = this.modelStatus.DTOV_DepartmentDisplayName;
            }
          }

        }
        this.setLayout(_status);
      },
      error => {
        this._alertService.error(error.message);
      }
    );
  }

  GetStatusNameOLD(status: string) {
    var _status = status;

    if (status == StatusEnum.ACCEPT) {
      _status = "Pending for forward to officer";
    }
    else if (status == StatusEnum.REJECT) {
      _status = "Rejected";
    }
    else if (status == StatusEnum.CLOSE) {
      _status = "Closed";
    }
    else if (status == "MOVE") {
      _status = "In process with office";
    }
    else if (status == StatusEnum.DISPOSED) {
      _status = "Disposed";
    }
    else if (status == StatusEnum.REOPEN) {
      _status = "Re-Open";
    }
    else if (status == "NONASSIGNED") {
      _status = "Non Assigned Communication";
    }
    else if (status == "NONMOVE") {
      _status = "Pending For Forward to Officer";
    }

    else if (this.loginBaseUserType == this.userTypeEnum.VNDR ||
      this.loginBaseUserType == this.userTypeEnum.VNDRUSER) {
      if (status == StatusEnum.RECEIVED) {
        _status = "Pending with department";
      }
      if (status == StatusEnum.RETURN) {
        _status = "Missing Information";
      }
    }
    else if (this.loginBaseUserType == this.userTypeEnum.HOD || this.loginBaseUserType == this.userTypeEnum.HOO
      || this.loginBaseUserType == this.userTypeEnum.DREC || this.loginBaseUserType == this.userTypeEnum.DOS
      || this.loginBaseUserType == this.userTypeEnum.HOOADLC || this.loginBaseUserType == this.userTypeEnum.DRECADLC
      || this.loginBaseUserType == this.userTypeEnum.DOSADLC) {
      if (status == StatusEnum.RECEIVED) {
        _status = "Pending for processing";
      }
      if (status == StatusEnum.RETURN) {
        _status = "Pending with vendors";
      }
    }
    return _status;
  }
}