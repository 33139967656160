import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatPaginator, MatSort, MatDialog, MatTableDataSource } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { AppComponent } from 'src/app/app.component';
import { ConfirmationDialogComponent } from 'src/app/confirmation-dialog/confirmation-dialog.component';
import { AppSetting } from 'src/app/shared/model/appsetting.model';
import { GlobalMessagesModel } from 'src/app/shared/model/common-messages.model';
import { PermissionModel, IndexModel, GlobalFilterModel } from 'src/app/shared/model/common.model';
import { ColumnHeaderModel, CommonLayoutModel, DDLModel } from 'src/app/shared/model/commonddl.model';
import { AdditionalUserPermissionModel, AdditionalUserPermissionViewModel } from 'src/app/shared/model/user-permission.model';
import { UserViewModel } from 'src/app/shared/model/user.model';
import { AlertService } from 'src/app/shared/service/alert.service';
import { AuthenticationService } from 'src/app/shared/service/authentication.service';
import { CommonService } from 'src/app/shared/service/common.service';
import { UserPermissionService } from 'src/app/shared/service/user-permission.service';
import { UserService } from 'src/app/shared/service/user.service';
import { AdditionalPermissionStatusDialogComponent } from './additional-permission-status-dialog/additional-permission-status-dialog.component';

@Component({
  selector: 'app-additional-permission',
  templateUrl: './additional-permission.component.html',
  styleUrls: ['./additional-permission.component.scss']
})
export class AdditionalPermissionComponent implements OnInit {


  model: AdditionalUserPermissionViewModel[];
  modelNew = new AdditionalUserPermissionViewModel();
  dataSource: any;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  id: number;
  displayedColumns: string[] = ["index", "FromUserName", "ToUserName", "FromDate", "ToDate", "Remark", "CreatedByName", "ModifiedByName", "IsActive", "IsLocked", "Action",];
  ViewdisplayedColumns: ColumnHeaderModel[] = [{ Value: "FromUserName", Text: "From User" },
  { Value: "ToUserName", Text: "To User" }, { Value: "FromDate", Text: "FromDate" },
  { Value: "ToDate", Text: "ToDate" }, { Value: "Remark", Text: "Remark" },
  { Value: "CreatedByName", Text: "CreatedBy" }, { Value: "ModifiedByName", Text: "ModifiedBy" },
  ];
  columnsToDisplay: string[] = this.displayedColumns.slice();

  indexModel = this._commonService.modelSetGet(new IndexModel()) as IndexModel;
  totalRecords: number;
  loginData: UserViewModel;
  Permission: PermissionModel = this._authService.GetPagePermission("/master/permission/user-additional-permission", "permission/user-additional-permission/add", "permission/user-additional-permission/detail", "permission/user-additional-permission/update", "permission/user-additional-permission/delete");


  constructor(
    private readonly _appComponent: AppComponent,
    private readonly _alertService: AlertService,
    private readonly _permissionService: UserPermissionService,
    private readonly _dialog: MatDialog,
    private readonly _authService: AuthenticationService,
    public readonly _commonService: CommonService
  ) {
    this.indexModel = new IndexModel();
    this.loginData = this._authService.GetCurrentUserDetail().UserViewModel;

    this.Permission.AddPageAccess ?
      this._appComponent.setPageLayout("Additional Permission :", "add", "Create", "/master/permission/user-additional-permission/add") : this._appComponent.setPageLayout("Additional Permission :");

  }

  ngOnInit(): void {
    this.getList();
  }

  getList() {
    this._permissionService.UserAdditionalPermissionGetList(this._commonService.modelSetGet(this.indexModel, true)).subscribe(responce => {

      if (responce.IsSuccess) {
        this.model = responce.Data.Data as AdditionalUserPermissionViewModel[];
        this.dataSource = new MatTableDataSource<AdditionalUserPermissionViewModel>(this.model);
        this.totalRecords = responce.Data.TotalRecords;
        if (!this.indexModel.IsPostBack) {
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
        }
      } else {
        this._alertService.error(responce.Message);
      }
    },
      error => {
        this._alertService.error(error.message);
      });
  }
  sortData(event) {
    this.indexModel.OrderBy = event.active;
    this.indexModel.OrderByAsc = event.direction == AppSetting.orderByDscAsc ? AppSetting.orderByAsc : AppSetting.orderByDsc;
    this.indexModel.IsPostBack = true;
    this.getList();
  }
  onPaginateChange(event) {
    this.indexModel.Page = event.pageIndex + 1;
    this.indexModel.PageSize = event.pageSize;
    this.indexModel.IsPostBack = true;
    this.getList();
  }
  OnLockedStatus(Id) {
    const dialogRef = this._dialog.open(ConfirmationDialogComponent, {
      width: '50%',
      data: GlobalMessagesModel.ConfirmStatusChanged,
      disableClose: true
    });
    dialogRef.afterClosed().subscribe(isTrue => {
      if (isTrue) {
        this._permissionService.UserAdditionalPermissionChangeLockedStatus(Id).subscribe(
          data => {

            if (data.IsSuccess) {

              this.getList();
              this._alertService.success(data.Message);

            }
          },
          error => {
            // this._commonService.ScrollingTop();
            this._alertService.error(error.message);
          }
        );
      }
    });
  }


  OnActiveStatus(IsUpdate: boolean, newid) {
    
    this.modelNew.Id = newid;
    const _dialogRef = this._dialog.open(AdditionalPermissionStatusDialogComponent, {
      width: "500px",

      data: IsUpdate ? { Id: this._commonService.IsNullOrEmpty(this.modelNew.Id) ? null : this.modelNew.Id } : null,
    });
    _dialogRef.afterClosed().subscribe((result: any) => {
      if (result.IsSuccess) {

        this.getList();
      }
    });
  }





  updateDeleteStatus(id) {
    const dialogRef = this._dialog.open(ConfirmationDialogComponent, {
      width: "50%",
      data: GlobalMessagesModel.ConfirmStatusChanged
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this._permissionService.UserAdditionalPermissionChangeActiveStatus(id).subscribe(
          data => {
            if (data.IsSuccess) {

              this._alertService.success(data.Message);
              //  this._commonService.ScrollingTop();
              this.getList();
            }
          },
          error => {
            this._alertService.error(error.message);
          }
        );
      }
    });
  }
  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase()
  }

  SearchByKeyword(searchValue: GlobalFilterModel) {
    this.indexModel.Search = searchValue.search;
    this.indexModel.AdvanceSearchModel = { ActiveStatus: searchValue.ActiveStatus }

    this.getList();
  }

  ClearSearch() {
    this.indexModel = new IndexModel();
    this.getList();
  }

}
