import { Component, Inject, OnInit, ViewChild } from "@angular/core";
import { MatDialogRef, MatPaginator, MatTableDataSource, MAT_DIALOG_DATA } from "@angular/material";
import { IndexModel } from "src/app/shared/model/common.model";
import { ColumnHeaderModel } from "src/app/shared/model/commonddl.model";
import { UserSpecificPagePermissionModel } from "src/app/shared/model/user-permission.model";
import { AlertService } from "src/app/shared/service/alert.service";
import { UserPermissionService } from "src/app/shared/service/user-permission.service";

@Component({
  selector: 'app-user-specific-permission-dialog',
  templateUrl: './user-specific-permission-dialog.component.html',
  styleUrls: ['./user-specific-permission-dialog.component.scss']
})

export class UserSpecificPermissionDialogComponent implements OnInit {

  userId: number = 0;
  dataSource: any;
  indexModel: IndexModel = new IndexModel();
  listModel: UserSpecificPagePermissionModel[] = [];

  displayedColumns: string[] = ["index", "ApplicationTitle", "PermissionTitle", "Permissions"];
  ViewdisplayedColumns: ColumnHeaderModel[] = [
    { Value: "ApplicationTitle", Text: "Application" },
    { Value: "PermissionTitle", Text: "Page" },
  ];

  columnsToDisplay: string[] = this.displayedColumns.slice();
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  constructor(
    public _dialogRef: MatDialogRef<UserSpecificPermissionDialogComponent>,
    private readonly _alertService: AlertService,
    private readonly _userPermissionService: UserPermissionService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    if (data) {
      this.userId = data;
      this.GetUserSpecificPagePermissionList();
    }
  }

  ngOnInit() { }

  GetUserSpecificPagePermissionList() {
    this._userPermissionService.GetUserSpecificPagePermission(this.userId).subscribe((data) => {
      if (data.IsSuccess) {
        this.listModel = <UserSpecificPagePermissionModel[]>data.Data;

        if (this.listModel && this.listModel.length > 0) {
          this.listModel.forEach(item => {
            let arrPermissions = [];
            if (item.AddPermission) { arrPermissions.push('Add'); }
            if (item.EditPermission) { arrPermissions.push('Edit'); }
            if (item.DeletePermission) { arrPermissions.push('Delete'); }
            if (item.ViewPermission) { arrPermissions.push('View'); }

            item.Permissions = arrPermissions.toString();
          });
        }

        this.dataSource = new MatTableDataSource<any>(this.listModel);
        this.dataSource.paginator = this.paginator;
      }
    }, (error) => {
      this._alertService.error(error.message);
    });
  }

  closePopup(): void {
    this._dialogRef.close();
  }

  onPaginateChange(event) {
    this.indexModel.Page = event.pageIndex + 1;
    this.indexModel.PageSize = event.pageSize;
  }

}

