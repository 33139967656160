import { Injectable } from '@angular/core';
import { AppSetting } from '../../model/appsetting.model';
import { IndexModel } from '../../model/common.model';
import { Dictionary } from '../../model/dictionary.model';
import { StatusMasterModel } from '../../model/master/status-master.model';
import { BaseService } from '../base.service';

@Injectable({
  providedIn: 'root'
})
export class StatusMasterService {

  constructor(private readonly _baseService: BaseService,) { }

  GetList(model: IndexModel) {
    return this._baseService.post(AppSetting.StatusListUrl, model);
  }

  AddUpdate(id: number, model: StatusMasterModel) {
    return this._baseService.post(AppSetting.StatusAddUpdateUrl, model);
  }

  Detail(id: number) {
    return this._baseService.get(AppSetting.StatusDetailUrl + id);
  }

  ChangeDeleteStatus(id: number) {
    return this._baseService.get(AppSetting.StatusDeleteStatusChangeUrl + id);
  }

  ChangeActiveStatus(id: number) {
    return this._baseService.get(AppSetting.StatusActiveStatusChangeUrl + id);
  }

  IsVendorDisplayNameExist(id, displayName: string) {
    const param = new Dictionary<any>();
    param.Add("name", displayName);
    param.Add("id", id);
    return this._baseService.get(AppSetting.IsVendorDisplayNameExistUrl, param);
  }

  IsDepartmentDisplayNameExist(id, displayName: string) {
    const param = new Dictionary<any>();
    param.Add("name", displayName);
    param.Add("id", id);
    return this._baseService.get(AppSetting.IsDepartmentDisplayNameExistUrl, param);
  }

  GetByStatus(name: string) {
    const param = new Dictionary<any>();
    param.Add("name", name);
    return this._baseService.get(AppSetting.GetStatusNamelUrl, param);
  }
}