import { Injectable } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Observable } from "rxjs";
import { map, startWith } from "rxjs/operators";
import { UserTypeEnum } from "../../enum/fixed-values.enum";
import { AppSetting } from "../../model/appsetting.model";
import { IndexModel } from "../../model/common.model";
import { DdlItemModel, DDLModel } from "../../model/commonddl.model";
import { AddVendorCommunicationPermissionRequestModel } from "../../model/master/add-vendor-communication-permission-request.model";
import { VendorCommunicationPermissionApproveRequestModel } from "../../model/master/vendor-communication-permission-approve-request.model";
import { VendorCommunicationPermissionListViewModel } from "../../model/master/vendor-communication-permission-list-view.model";
import { AlertService } from "../alert.service";
import { AuthenticationService } from "../authentication.service";
import { BaseService } from "../base.service";
import { CommonService } from "../common.service";
import { VendorCommunicationPermissionFilterModel } from "../../model/master/vendor-communication-permission-filter.model";


@Injectable()

export class VendorCommunicationPermissionService {

    private _form: FormGroup;
    private _filteredDdlVendor: Observable<DdlItemModel[]>;
    private _ddlVendor: DdlItemModel[] = [];

    public constructor(
        private readonly _formBuilder: FormBuilder,
        private readonly _commonService: CommonService,
        private readonly _baseService: BaseService,
        private readonly _alertService: AlertService,
        private readonly _authService: AuthenticationService
    ) {
        this.initializeForm();
        this.getDdl();
    }

    get Form(): FormGroup {
        return this._form;
    }

    get FilteredDdlVendor(): Observable<DdlItemModel[]> {
        return this._filteredDdlVendor;
    }

    GetList(model: VendorCommunicationPermissionFilterModel) {
        return this._baseService.post(AppSetting.OfficeMasterListUrl, model);
    }

    public getDdl(): Observable<DDLModel | undefined> {
        return this._commonService.GetAllDDL(AppSetting.VendorCommunicationPermissionDdl).pipe(
            map(response => {
                if (response.IsSuccess) {
                    this._ddlVendor = response.Data.ddlVendor;
                    this.initilaizeFilteredDdlVendor();
                    this.updateForm();
                    return response.Data as DDLModel;
                }

                return undefined;
            })
        );
    }

    public add(model: AddVendorCommunicationPermissionRequestModel): Observable<boolean> {
        return this._baseService.post(AppSetting.AddVendorCommunicationPermission, model).pipe(
            map(response => {
                if (response.IsSuccess) {
                    this._alertService.success(response.Message);
                    return true;
                }
                this._alertService.error(response.Message)
                return false;
            })
        );
    }

    public IsDelegateUserExists(delegateId: number): Observable<boolean> {

        const apiUrlWithId = `${AppSetting.IsDelegateUserExists}?delegateId=${delegateId}`;

        return this._baseService.get(apiUrlWithId).pipe(
            map(response => {
                if (response != null) {
                    return response;
                }
                return false;
            })
        );
    }

    public getAll(model: IndexModel): Observable<VendorCommunicationPermissionListViewModel[]> {
        return this._baseService.post(AppSetting.GetAllVendorCommunicationPermission, model).pipe(
            map(response => {
                if (response.IsSuccess) {
                    return response.Data as VendorCommunicationPermissionListViewModel[];
                }
                return [];
            })
        );
    }


    public approve(model: VendorCommunicationPermissionApproveRequestModel) {
        return this._baseService.post(AppSetting.ApproveVendorCommunicationPermission, model).pipe(
            map(response => {
                if (response.IsSuccess) {
                    this._alertService.success(response.Message);
                    return true;
                }

                this._alertService.error(response.Message);
                return false;
            })
        )
    }

    private initializeForm() {

        let userDetail = this._authService.LoggedInUserDetail.UserViewModel;


        if (userDetail.UserTypeCode == UserTypeEnum.HODCode) {
            this._form = this._formBuilder.group({
                departmentCode: [{ value: String(userDetail.PrimaryDepartmentCode), disabled: true }, Validators.required],
                vendorCode: ["", Validators.required]
            });
        }
        else if (userDetail.UserTypeCode == UserTypeEnum.VNDRCode || userDetail.UserTypeCode == UserTypeEnum.VNDRUSERCode) {

            this._form = this._formBuilder.group({
                departmentCode: ["", Validators.required],
                vendorCode: [userDetail.VendorCode.toString(), Validators.required]
            });
        }
    }

    private initilaizeFilteredDdlVendor() {
        this._filteredDdlVendor = this._form.get('vendorCode').valueChanges.pipe(
            startWith(''),
            map(value => this.filterDdlVendor(value || ''))
        )

    }

    private filterDdlVendor(value: string): DdlItemModel[] {
        if (typeof (value) == 'string') {
            const filterValue = value.toLowerCase();
            return this._ddlVendor.filter(option => option.Text.toLowerCase().includes(filterValue));
        }

        return this._ddlVendor;
    }

    private updateForm() {
        let userDetail = this._authService.LoggedInUserDetail.UserViewModel;
        let vendor = this._ddlVendor.filter(v => v.Value == userDetail.VendorCode)[0];

        if (userDetail.UserTypeCode == UserTypeEnum.VNDRCode || userDetail.UserTypeCode == UserTypeEnum.VNDRUSERCode) {
            this._form.get('vendorCode').setValue(vendor.Value);
            this._form.get('vendorCode').disable();
        }
        else {
            this._form.get('vendorCode').setValue(vendor);
            this._form.get('vendorCode').disable();
        }

    }

}