import {
  Component,

  EventEmitter,
  Input,

  OnInit,
  Output,
  ViewChild
} from "@angular/core";
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormControl
} from "@angular/forms";
import { DynamicFieldConfig } from "src/app/shared/model/dynamic-field-config.model";

@Component({
  exportAs: "dynamicForm",
  selector: "dynamic-form",
  template: `
  <form class="dynamic-form" #dynamicFormsubmit [formGroup]="form" (submit)="onSubmit($event)">
  <ng-container *ngFor="let field of fields;" dynamicField [fields]="fields" [field]="field" [group]="form">
  <h1 *ngIf="field.type=='SECTIONHEADING'" class="comunication-section-title">{{field.label}}</h1>
  </ng-container>

  </form>
  `,
  styles: []
})
export class DynamicFormComponent implements OnInit {
  @Input() fields: DynamicFieldConfig[] = [];

  @Output() submit: EventEmitter<any> = new EventEmitter<any>();

  form: FormGroup;
  // @ViewChild('dynamicFormsubmit',{static:true}) formSubmit;
  get value() {
    return this.form.value;
  }
  constructor(private fb: FormBuilder) { }

  ngOnInit() {
    this.form = this.createControl();
  }


  onSubmit(event: Event) {

    event.preventDefault();
    event.stopPropagation();
    if (this.form.valid) {
      this.submit.emit(this.fields);
    } else {
      this.validateAllFormFields(this.form);
    }
  }

  submitDynamicForm() {


    // this.formSubmit.nativeElement.submit();
    if (this.form.valid) {
      this.submit.emit(this.fields);

    } else {
      this.validateAllFormFields(this.form);

    }

  }

  submitSaveAsDraftDynamicForm() {
    this.submit.emit(this.fields);
  }


  createControl() {

    const group = this.fb.group({});
    this.fields.forEach(field => {
      if (field.type === "button") { return };

      const Validations = field.validations != undefined && field.validations.length > 0 ? this.bindValidations(field.validations) : [];
      const control = this.fb.control(field.value, Validations);
      group.addControl(field.name, control);
    });
    return group;
  }

  bindValidations(validations: any) {

    if (validations.length > 0) {
      const validList = [];
      validations.forEach(valid => {
        validList.push(valid.validator);
      });
      return Validators.compose(validList);
    }
    return null;
  }

  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      control.markAsTouched({ onlySelf: true });
    });
  }
}
