import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { IndexModel } from '../model/common.model';
import { VendorFilterModel } from '../model/vendor-filter.model';
import { AppSetting } from '../model/appsetting.model';
import { VendorModel } from '../model/vendor.model';
import { AuthenticationService } from './authentication.service';
import { Dictionary } from '../model/dictionary.model';

@Injectable({
    providedIn: 'root'
})

export class VendorService {
    loginUserCode: number;

    constructor(private readonly _baseService: BaseService, private readonly _authenticationService: AuthenticationService) {
        this.loginUserCode = _authenticationService.UserCode;
    }

    GetList(model: IndexModel) {
        return this._baseService.post(AppSetting.VendorListUrl + "?loginUserCode=" + this.loginUserCode, model);
    }


    GetSearchList(model: VendorFilterModel) {
        return this._baseService.post(AppSetting.VendorSearchListUrl + "?loginUserCode=" + this.loginUserCode, model);
    }

    AddUpdate(id: number, model: VendorModel) {
        if (this._authenticationService.GetUserIsAuthenticated()) {
            return this._baseService.post(AppSetting.VendorAddUpdateUrl, model);
        }
        else {
            return this._baseService.post(AppSetting.NewVendorRegisterUrl, model);
        }
    }

    Detail(id: number) {
        return this._baseService.get(AppSetting.VendorDetailUrl + id);
    }

    ChangeActiveStatus(id: number) {
        return this._baseService.get(AppSetting.VendorActiveStatusChangeUrl + id);
    }

    IsVendorSSOIdExist(ssoId: string) {
        return this._baseService.get(AppSetting.IsVendorSSOIdExistUrl + ssoId);
    }

    IsSSOIdExist(ssoId: string) {
        var param = new Dictionary<any>();
        param.Add("id", ssoId);
        return this._baseService.get(AppSetting.UserSSOIDExistUrl ,param);
    }

    GetVendorWorkOrderList() {
        return this._baseService.get(AppSetting.GetVendorWorkOrderDDlUrl + "?userCode=" + this.loginUserCode);
    }

    ChangeVerifiedStatus(id: number) {
        return this._baseService.get(AppSetting.VendorVerifiedStatusChangeUrl + id);
    }

    // GetVendorListPDFDownload(model: IndexModel) {
    //     return this._baseService.post(AppSetting.VendorPDFDownloadUrl + "?loginUserCode=" + this.loginUserCode, model);
    // }

    GetVendorListPDFDownload(model: VendorFilterModel) {
        return this._baseService.post(AppSetting.VendorPDFDownloadUrl + "?loginUserCode=" + this.loginUserCode, model);
    }
}