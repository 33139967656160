import { IndexModel } from "../common.model";

export class VendorCommunicationPermissionFilterModel extends IndexModel {
    isApproved: boolean | null;
    isLatest: boolean;
    departmentCode: number;
    vendorCode: number;



    constructor(isApproved: boolean = false, isLatest: boolean = false) {
        super();
        this.isApproved = null;
        this.isLatest = isLatest;
        this.departmentCode = 0;
        this.vendorCode = 0;
    }
}