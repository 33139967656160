import { Component, Inject, OnInit } from "@angular/core";
import { AbstractControl, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { StateEnum } from "src/app/shared/enum/fixed-values.enum";
import { DdlItemModel } from "src/app/shared/model/commonddl.model";
import { userCreationConfigModel, UserCreationModel } from "src/app/shared/model/user.model";
import { UserService } from "src/app/shared/service/user.service";

@Component({
  selector: 'app-set-user-primary-detail-dialog',
  templateUrl: './set-user-primary-detail-dialog.component.html',
  styleUrls: ['./set-user-primary-detail-dialog.component.scss']
})

export class SetUserPrimaryDetailDialogComponent implements OnInit {

  frmGrp: FormGroup;
  ddlDepartment: DdlItemModel[] = [];
  ddlDistricts: DdlItemModel[] = [];
  ddlOffices: DdlItemModel[] = [];
  userDeptCodes: string[] = [];
  userDistCodes: string[] = [];
  userOfficeCodes: string[] = [];
  model: UserCreationModel = new UserCreationModel();
  fieldConfig: userCreationConfigModel = new userCreationConfigModel();

  constructor(
    private readonly fb: FormBuilder,
    private readonly _userService: UserService,
    public _dialogRef: MatDialogRef<SetUserPrimaryDetailDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    if (data && data.model) {
      this.model = data.model;
    }
    if (data && data.fieldConfig) {
      this.fieldConfig = data.fieldConfig;
    }

    if (this.model.DepartmentCode) { this.userDeptCodes = this.model.DepartmentCode.toString().split(','); }
    if (this.model.DistrictCode) { this.userDistCodes = this.model.DistrictCode.toString().split(','); }
    if (this.model.OfficeCode) { this.userOfficeCodes = this.model.OfficeCode.toString().split(','); }

    if (this.model.UserCode == 0) {
      this.model.PrimaryDepartmentCode = undefined;
      this.model.PrimaryDistrictCode = undefined;
      this.model.PrimaryOfficeCode = undefined;
    }
    else {
      // set department 
      if (this.model.PrimaryDepartmentCode && this.userDeptCodes.indexOf(this.model.PrimaryDepartmentCode.toString()) > -1) { this.model.PrimaryDepartmentCode = String(this.model.PrimaryDepartmentCode); }
      else if (this.userDeptCodes.length == 1) { this.model.PrimaryDepartmentCode = this.userDeptCodes[0]; }
      else { this.model.PrimaryDepartmentCode = undefined; }

      // set district 
      if (this.model.PrimaryDistrictCode && this.userDistCodes.indexOf(this.model.PrimaryDistrictCode.toString()) > -1) { this.model.PrimaryDistrictCode = String(this.model.PrimaryDistrictCode); }
      else if (this.userDistCodes.length == 1) { this.model.PrimaryDistrictCode = this.userDistCodes[0]; }
      else { this.model.PrimaryDistrictCode = undefined; }

      // set office 
      if (this.model.PrimaryOfficeCode && this.userOfficeCodes.indexOf(this.model.PrimaryOfficeCode.toString()) > -1) { this.model.PrimaryOfficeCode = String(this.model.PrimaryOfficeCode); }
      else if (this.userOfficeCodes.length == 1) { this.model.PrimaryOfficeCode = this.userOfficeCodes[0]; }
      else { this.model.PrimaryOfficeCode = undefined; }


      // this.model.PrimaryDepartmentCode =
      //   (this.model.PrimaryDepartmentCode && this.userDeptCodes.indexOf(this.model.PrimaryDepartmentCode.toString()) > -1) ? String(this.model.PrimaryDepartmentCode) : undefined;
      // this.model.PrimaryDistrictCode =
      //   (this.model.PrimaryDistrictCode && this.userDistCodes.indexOf(this.model.PrimaryDistrictCode.toString()) > -1) ? String(this.model.PrimaryDistrictCode) : undefined;
      // this.model.PrimaryOfficeCode =
      //   (this.model.PrimaryOfficeCode && this.userOfficeCodes.indexOf(this.model.PrimaryOfficeCode.toString()) > -1) ? String(this.model.PrimaryOfficeCode) : undefined;
    }

    this.getDepartments();
    this.getDistricts();
  }

  ngOnInit() {
    this.formGroupInit();
  }

  formGroupInit() {
    this.frmGrp = this.fb.group({
      PrimaryDepartmentCode: [null, (this.fieldConfig.IsDepartmentShow ? Validators.required : null)],
      PrimaryDistrictCode: [null, (this.fieldConfig.IsDistrictShow ? Validators.required : null)],
      PrimaryOfficeCode: [null, (this.fieldConfig.IsOfficeShow ? Validators.required : null)]
    });
  }

  getDepartments() {
    if (this.model.AdminDepartment) {
      let adminDeptCodes = this.model.AdminDepartment ? this.model.AdminDepartment : 0;
      this._userService.GetUserDepartmentForUserOfficeCreation(adminDeptCodes.toString(), true).subscribe(data => {
        if (data.IsSuccess) {
          let departments = <DdlItemModel[]>data.Data;
          this.ddlDepartment = departments.filter(x => this.userDeptCodes.indexOf(x.Value as string) > -1);

          // check selected value exist in list 
          if (!this.ddlDepartment.find(x => x.Value == this.model.PrimaryDepartmentCode)) {
            this.model.PrimaryDepartmentCode = undefined;
          }
        }
      }, error => {
        console.error(error.message);
      });
    }
  }

  getDistricts() {
    if (this.model.StateCodes || this.model.PrimaryDepartmentCode) {
      let stateCodes = this.model.StateCodes ? this.model.StateCodes.toString() : StateEnum.Rajasthan.toString();
      let deptCodes = this.model.PrimaryDepartmentCode ? this.model.PrimaryDepartmentCode.toString() : "0";

      this._userService.GetUserDistrictForUserOfficeCreation(stateCodes, deptCodes, true).subscribe((data) => {
        if (data.IsSuccess) {
          let districts = <DdlItemModel[]>data.Data;
          this.ddlDistricts = districts.filter(x => this.userDistCodes.indexOf(x.Value as string) > -1);

          // check selected value exist in list 
          if (!this.ddlDistricts.find(x => x.Value == this.model.PrimaryDistrictCode)) {
            this.model.PrimaryDistrictCode = undefined;
          }
        }
      }, (error) => {
        console.error(error.message);
      });
    }
  }

  getOffices() {
    if (this.model.PrimaryDepartmentCode || this.model.PrimaryDistrictCode) {
      let deptCodes = this.model.PrimaryDepartmentCode ? this.model.PrimaryDepartmentCode.toString() : '0';
      let distCodes = this.model.PrimaryDistrictCode ? this.model.PrimaryDistrictCode.toString() : '0';

      this._userService.GetUserOfficeForUserCreation(deptCodes, distCodes).subscribe((data) => {
        if (data.IsSuccess) {
          let offices = <DdlItemModel[]>data.Data;
          this.ddlOffices = offices.filter(x => this.userOfficeCodes.indexOf(x.Value as string) > -1);

          // check selected value exist in list 
          if (!this.ddlOffices.find(x => x.Value == this.model.PrimaryOfficeCode)) {
            this.model.PrimaryOfficeCode = undefined;
          }
        }
      }, (error) => {
        console.error(error.message);
      });
    }
  }

  onSubmit() {
    this.frmGrp.markAllAsTouched();
    if (this.frmGrp.valid) {
      //this._dialogRef.close(this.model);
      this._dialogRef.close({
        PrimaryDepartmentCode: this.model.PrimaryDepartmentCode,
        PrimaryDistrictCode: this.model.PrimaryDistrictCode,
        PrimaryOfficeCode: this.model.PrimaryOfficeCode,
      });
    }
  }

  isRequiredField(field: string) {
    const form_field = this.frmGrp.get(field);
    if (!form_field.validator) {
      return false;
    }
    const validator = form_field.validator({} as AbstractControl);
    return (validator && validator.required);
  }

}
