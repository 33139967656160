import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { OfficerPreferredListModel } from 'src/app/shared/model/preferred-list/officer-preferred-list.model';
import { AlertService } from 'src/app/shared/service/alert.service';
import { AuthenticationService } from 'src/app/shared/service/authentication.service';
import { OfficerPreferredListService } from 'src/app/shared/service/Preferred-List/officer-preferred-list.service';

@Component({
  selector: 'app-officer-preferred-list',
  templateUrl: './officer-preferred-list.component.html',
  styleUrls: ['./officer-preferred-list.component.scss'],
  providers: [OfficerPreferredListService]
})
export class OfficerPreferredListComponent implements OnInit {
  @Input() selectedTab: any;
  @Output() selectItemEvent = new EventEmitter<string>();

  private selectedId: string = "";

  public dataSource: any;
  public loginUserCode: number;

  public viewModel = {
    displayedColumns: ['radio', 'name'],
    preferredOfficers: [] as OfficerPreferredListModel[],
  }


  constructor(
    public readonly _alertService: AlertService,
    public readonly _officerPreferredService: OfficerPreferredListService,
    private readonly _authService: AuthenticationService,
    public _dialogRef: MatDialogRef<OfficerPreferredListComponent>
  ) {
    this.loginUserCode = _authService.UserCode;

  }

  ngOnInit() {
    this.getList();
  }

  public onSubmit() {
    if (this.selectedId == "") {
      return;
    }

    this.selectItemEvent.emit(this.selectedId);
  }

  public getList() {

    this._officerPreferredService.GetList(this.selectedTab, this.loginUserCode).subscribe((data) => {
      if (data.IsSuccess) {
        this.viewModel.preferredOfficers = <OfficerPreferredListModel[]>data.Data.Officer;
        this.dataSource = this.viewModel.preferredOfficers;
      }
    }, (error) => {
      this._alertService.error(error.message);
    });
  }

  public radioChanged(row: OfficerPreferredListModel) {
    this.selectedId = String(row.OfficerId);
  }

}
