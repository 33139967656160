import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { ReasonTypeModel } from 'src/app/shared/model/master/reason-type.model';
import { AppComponent } from 'src/app/app.component';
import { ReasonTypeService } from 'src/app/shared/service/master/reason-type.service';
import { AlertService } from 'src/app/shared/service/alert.service';
import { UserTypeEnum } from 'src/app/shared/enum/fixed-values.enum';
import { AuthenticationService } from 'src/app/shared/service/authentication.service';

@Component({
  selector: 'app-reason-type-detail',
  templateUrl: './reason-type-detail.component.html',
  styleUrls: ['./reason-type-detail.component.scss']
})
export class ReasonTypeDetailComponent implements OnInit {
  model: ReasonTypeModel;
  RecordId: number;
  IsStorage: boolean;

  constructor(private readonly _appComponent: AppComponent,
    private readonly _router: Router,
    private _route: ActivatedRoute,
    private readonly _vendorTypeService: ReasonTypeService,
    private readonly _alertService: AlertService,
    private readonly _authService: AuthenticationService
  ) {
    this.RecordId = this._route.snapshot.params.id;
    this.model = new ReasonTypeModel();
    this._appComponent.setPageLayout(
      "Return Reason Type Detail :",
      "keyboard_backspace",
      "Back To List",
      "master/reason-type"
    );
  }

  ngOnInit() {
    this.getDetail();
  }

  getDetail() {
    this._vendorTypeService.Detail(this.RecordId).subscribe(
      data => {
        if (data.IsSuccess) {
          this.model = <ReasonTypeModel>data.Data;
        }
      },
      error => {
        this._alertService.error(error.message);
      }
    );
  }

  ngOnDestroy() {
    this.IsStorage = true;
    sessionStorage.setItem("IsReasonTypeStorage", JSON.stringify(this.IsStorage));
  }

}
