export class StatisticalReportModel {
  JsonData: string;
}

export class DynamicColumn {
  headerName: string;
  field: string;
  constructor(headerName, field) {
    this.headerName = headerName;
    this.field = field;
  }
}
