import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { AppComponent } from "src/app/app.component";
import { AppSetting } from "src/app/shared/model/appsetting.model";
import { GlobalMessagesModel } from "src/app/shared/model/common-messages.model";
import { DDLModel } from "src/app/shared/model/commonddl.model";
import { DistrictModel } from "src/app/shared/model/master/district.model";
import { UserViewModel } from "src/app/shared/model/user.model";
import { AlertService } from "src/app/shared/service/alert.service";
import { AuthenticationService } from "src/app/shared/service/authentication.service";
import { CommonService } from "src/app/shared/service/common.service";
import { DistrictService } from "src/app/shared/service/master/district.service";

@Component({
  selector: 'app-add-update-district',
  templateUrl: './add-update-district.component.html',
  styleUrls: ['./add-update-district.component.scss']
})

export class AddUpdateDistrictComponent implements OnInit {

  submitBtn = true;
  recordId: number = 0;
  frmGroup: FormGroup;
  loginUserCode: number;
  ddlList: DDLModel = new DDLModel();
  model: DistrictModel = new DistrictModel();
  loginUserDetail: UserViewModel = new UserViewModel();

  constructor(private readonly fb: FormBuilder,
    private readonly _appComponent: AppComponent,
    public readonly _commonService: CommonService,
    private readonly _alertService: AlertService,
    private readonly _router: Router,
    private _route: ActivatedRoute,
    private readonly _distService: DistrictService,
    private readonly _authService: AuthenticationService
  ) {
    if (this._route.snapshot.params.id) {
      this.recordId = this._route.snapshot.params.id;
    }
    this.loginUserDetail = this._authService.GetCurrentUserDetail().UserViewModel;
    var pageHeading = (this.recordId == 0 ? "Add" : "Update") + " District :";
    this._appComponent.setPageLayout(pageHeading, "keyboard_backspace", "Back To List", "master/district");
    this.getDDLList();
  }

  ngOnInit() {
    this.formGroupInit();
    this.getDetail();
  }

  formGroupInit() {
    this.frmGroup = this.fb.group({
      DistrictTitle: [null, Validators.compose([Validators.required, Validators.maxLength(100)])],
      DistrictTitleHindi: [null, Validators.compose([Validators.required, Validators.maxLength(100)])],
      DistrictShortTitle: [null, null],
      DistrictShortTitleHindi: [null, null],
      District_StateCode: [null, Validators.required],
      District_DivisionCode: [null, null]
    });
  }

  getDDLList() {
    this._commonService.GetAllDDL(AppSetting.DistrictDropdownKeys).subscribe(data => {
      if (data.IsSuccess) {
        let ddlData = <DDLModel>data.Data;
        this.ddlList.ddlState = ddlData.ddlState;
      }
    }, error => {
      this._alertService.error(error.message);
    });
  }

  getDetail() {
    if (this.recordId > 0) {
      this._distService.GetDetail(this.recordId).subscribe(data => {
        if (data.IsSuccess) {
          this.model = <DistrictModel>data.Data;
          if (this.model.District_StateCode) { this.model.District_StateCode = String(this.model.District_StateCode); }
        }
      }, error => {
        this._alertService.error(error.message);
      });
    }
  }

  saveClick() {
    this.submitBtn = false;
    this.frmGroup.markAllAsTouched();
    if (this.frmGroup.valid) {

      if (this.recordId == 0)
        this.model.CreatedBy = this.loginUserDetail.UserCode;
      else
        this.model.ModifiedBy = this.loginUserDetail.UserCode;

      this._distService.AddUpdate(this.model).subscribe(data => {
        if (data) {
          if (data.IsSuccess) {
            this._alertService.success(this.recordId == 0 ? GlobalMessagesModel.saveSuccess : GlobalMessagesModel.updateSuccess);
            this._router.navigate(["master/district"]);
          }
          else {
            this.submitBtn = true;
            this._commonService.ScrollingTop();
            this._alertService.error(this.recordId == 0 ? GlobalMessagesModel.saveError : GlobalMessagesModel.updateError);
          }
        } else {
          this.submitBtn = true;
          this._commonService.ScrollingTop();
          this._alertService.error(GlobalMessagesModel.InternalError);
        }
      }, error => {
        this.submitBtn = true;
        this._commonService.ScrollingTop();
        this._alertService.error(error.error.ResponseMessage);
      });
    }
    else {
      this.submitBtn = true;
    }
  }

  checkDistrictExist() {
    if (this.frmGroup.get('DistrictTitle').valid && this.model.District_StateCode) {
      this._distService.IsDistrictExist(this.model.DistrictTitle.trim(), this.model.DistrictCode, this.model.District_StateCode).subscribe(result => {
        if (result.IsSuccess) {
          let isExist = <boolean>result.Data
          if (isExist) {
            this.model.DistrictTitle = undefined;
            this._alertService.error("District is already exist!");
          }
        }
      }, error => {
        this._commonService.ScrollingTop();
        this._alertService.error(error.message);
      });
    }
  }

}
