import { GeneralEntrySubCategoryEnum } from './../../shared/enum/fixed-values.enum';
import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { GeneralCategoryEnumProd } from 'src/app/shared/enum/fixed-values.enum';
import { GeneralEntryModel, GeneralEntrySearchModel } from 'src/app/shared/model/general-entry-model.model';
import { AuthenticationService } from 'src/app/shared/service/authentication.service';
import { CommonService } from 'src/app/shared/service/common.service';
import { GeneralEntryService } from 'src/app/shared/service/general-entry.service';
import { AlertService } from '../../shared/service/alert.service';
import { GlobalMessagesModel } from '../../shared/model/common-messages.model';

@Component({
  selector: 'app-general-help-dialog',
  templateUrl: './general-help-dialog.component.html',
  styleUrls: ['./general-help-dialog.component.scss']
})
export class GeneralHelpDialogComponent implements OnInit {
  searchModel = new GeneralEntrySearchModel();
  model = new GeneralEntryModel();
  PDFFile: SafeResourceUrl;

  constructor(
    private readonly _commonService: CommonService,
    public sanitizer: DomSanitizer,
    private _alertSrvice: AlertService,
    private _generalEntryService: GeneralEntryService,
    public _dialogRef: MatDialogRef<GeneralHelpDialogComponent>,
    public readonly _authenticationService: AuthenticationService,
  ) { }

  ngOnInit() {
    this.GetData();
  }

  GetData() {

    this.searchModel.CategoryCode = GeneralCategoryEnumProd.Documents;
    this.searchModel.SubCategoryCode = GeneralEntrySubCategoryEnum.GeneralHelp;
    this.searchModel.IndexModel.PageSize = 1;
    this.searchModel.IsActive = true;
    this.searchModel.IsPdfRequired = true;
    //Default its showing priority data
    this._generalEntryService.GetGeneralEntrySearhList(this.searchModel,false).subscribe(data => {
      
      if (data.IsSuccess) {
        
        let records = <GeneralEntryModel[]>data.Data.Data;

        if (records && records.length > 0) {
          this.model = records[0];
          this.PDFFile = this.sanitizer.bypassSecurityTrustResourceUrl(this.model.PdfFIleName);

        }
        else {
         
          this._alertSrvice.error(GlobalMessagesModel.NoRecordFound);
          this.onNoClick();
        }
      }
      else{
        this._alertSrvice.error(data.Message);
        this.onNoClick();

      }
    });
  }


  onNoClick(): void {
    this._dialogRef.close();
  }

}
