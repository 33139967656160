export class MasterDataModel {
    MasterCode: number;
    MasterTypeCode: number | string;
    MasterTypeName: string;
    FieldValueInEnglish: string;
    FieldValueInHindi: string;
    IsActive: boolean;
    IsDeleted: boolean;
    CreatedByUserName: string;
    CreatedBy: number | null;
    CreatedOn: Date | null;
    ModifiedByUserName: string;
    ModifiedBy: number | null;
    ModifiedOn: Date | null;
    Description: string;
    SortOrder: number;
    ModifiedDate: Date | null;
}
