import { Injectable } from "@angular/core";
import { AppSetting } from "../../model/appsetting.model";
import { IndexModel } from "../../model/common.model";
import { NotificationEmailTemplatesModel } from "../../model/master/email-template.model";
import { BaseService } from "../base.service";

@Injectable({
  providedIn: 'root'
})
export class EmailTemplateService {
  constructor(
    private readonly _baseService: BaseService,
  ) {
  }
  GetList(model: IndexModel) {
    return this._baseService.post(AppSetting.EmailTemplateListUrl, model);
  }

  AddNew(model: NotificationEmailTemplatesModel) {
    return this._baseService.post(AppSetting.EmailTemplateAddUrl, model);
  }

  Update(model: NotificationEmailTemplatesModel) {
    return this._baseService.post(AppSetting.EmailTemplateUpdateUrl, model);

  }

  GetById(id) {
    return this._baseService.get(AppSetting.EmailTemplateGetByIdUrl + id, null);
  }

  Delete(Id) {
    return this._baseService.get(AppSetting.EmailTemplateDeleteUrl + Id, null);
  }

  GetEmailNotificationContentKey() {
    const result = this._baseService.get(
      AppSetting.GetNotificationContentKeyUrl

    );
    return result;

  }
}
