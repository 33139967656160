import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { AppComponent } from 'src/app/app.component';
import { ConfirmationDialogComponent } from 'src/app/confirmation-dialog/confirmation-dialog.component';
import { UserTypeEnum } from 'src/app/shared/enum/fixed-values.enum';
import { GlobalMessagesModel } from 'src/app/shared/model/common-messages.model';
import { PermissionModel } from 'src/app/shared/model/common.model';
import { UserViewModel } from 'src/app/shared/model/user.model';
import { AlertService } from 'src/app/shared/service/alert.service';
import { AuthenticationService } from 'src/app/shared/service/authentication.service';
import { CommonService } from 'src/app/shared/service/common.service';
import { UtilityService } from 'src/app/shared/service/utility.service';

@Component({
  selector: 'app-application-data-utility',
  templateUrl: './application-data-utility.component.html',
  styleUrls: ['./application-data-utility.component.scss']
})
export class ApplicationDataUtilityComponent implements OnInit {

  ActionType: string = "";
  frmGrp = this.fb.group({
    ActionType: [null, Validators.required]
  });
  Permission: PermissionModel = this._authService.GetPagePermission("utility/application-data-utility", "utility/application-data-utility");
  loggedInUserDetail = new UserViewModel();
  constructor(private readonly fb: FormBuilder,
    private readonly _alertService: AlertService,
    public readonly _utilityService: UtilityService,
    private readonly _dialog: MatDialog,
    public readonly _commonService: CommonService,
    private readonly _authService: AuthenticationService,
    private readonly _appComponent: AppComponent,
    private readonly _router: Router,
    private readonly _auth: AuthenticationService,) {
    this.loggedInUserDetail = this._auth.LoggedInUserDetail.UserViewModel;
    this._appComponent.setPageLayout("System Clean Up", "keyboard_backspace", "", "");
  }

  ngOnInit() {
    
    if (!this.Permission.AddPageAccess || this.loggedInUserDetail.BaseUserType != UserTypeEnum.SADM) {
      this._alertService.error(GlobalMessagesModel.Unauthorized);
      this._router.navigate(["dashboard"]);
    }
  }

  DeleteDatabaseRecords() {
    //this._alertService.success("In progress");   
    this.frmGrp.markAllAsTouched();
    if (this.frmGrp.valid && this.ActionType != "Select") {
      const dialogRef = this._dialog.open(ConfirmationDialogComponent, { width: "50%", data: GlobalMessagesModel.ConfirmDeleted });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this._utilityService.DeleteDatabaseRecords(this.ActionType).subscribe(data => {
            if (data.IsSuccess) {
              this._alertService.success(data.Message);
              this._commonService.ScrollingTop();
            }
          }, error => {
            this._alertService.error(error.message);
          });
        }
      });
    }
  }
}
