import { Injectable } from '@angular/core';
import { AppSetting } from '../../model/appsetting.model';
import { MenuViewModel } from '../../model/master/menu-view-model.model';
import { BaseService } from '../base.service';

@Injectable({
  providedIn: 'root'
})
export class MenuMasterService {

  constructor(private readonly _baseService: BaseService, ) {

  }
  GetList() {

    return this._baseService.get(AppSetting.MenuMasterListUrl);
  }

  AddMenu(model: MenuViewModel) {
    return this._baseService.post(AppSetting.MenuMasterAddUrl, model);
  }

  GetById(id) {
    return this._baseService.get(AppSetting.MenuMasterDetailUrl + id, null);
  }
  GetAllByUserId(id) {
    return this._baseService.get(AppSetting.MenuMasterByUserIdUrl + id);
  }

  EditMenu(id: number, model: MenuViewModel) {
    return this._baseService.post(AppSetting.MenuMasterEditUrl + id, model);
  }

  DeleteMenu(Id) {
    return this._baseService.get(AppSetting.MenuMasterDeleteUrl + Id, null);
  }
}
