import { CounterModel } from './../shared/model/counter-model';
import { OutboxService } from './../shared/service/outbox.service';
import { InboxService } from './../shared/service/inbox.service';
import { Component, ViewEncapsulation, OnInit, ElementRef, ViewChild } from '@angular/core';
import { AuthenticationService } from '../shared/service/authentication.service';
import { CommonService } from '../shared/service/common.service';
import { NavItem } from '../shared/model/nav-item.model';
import { UserTypeEnum } from '../shared/enum/fixed-values.enum';
import { Router } from '@angular/router';
import { CommunicationTypeService } from '../shared/service/master/communication-type.service';
import { CommunicationTypeModel } from '../shared/model/master/communication-type.model';
import { MenuMasterService } from '../shared/service/master/menu-master.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-side-nav-bar',
  templateUrl: './side-nav-bar.component.html',
  encapsulation: ViewEncapsulation.None
})

export class SideNavBarComponent implements OnInit {
  SSOId: String;
  UserName: string;
  userTypeEnum = UserTypeEnum;
  model = new CounterModel();
  applicationVersion = this._commonService.versionDate;// environment.applicationVersion;;
  @ViewChild("appDrawer", null) appDrawer: ElementRef;

  constructor(private _auth: AuthenticationService,
    private readonly _commonService: CommonService,
    private readonly _router: Router,
    private readonly _menuService: MenuMasterService,
    private readonly _communicationTypeService: CommunicationTypeService,
    private readonly _inboxService: InboxService,
    private readonly _outboxService: OutboxService
  ) {

    if (_auth.GetCurrentUserDetail() != null && _auth.GetCurrentUserDetail().UserViewModel != null && !_commonService.IsNullOrEmpty(_auth.GetCurrentUserDetail().UserViewModel.SSOID)) {
      this.SSOId = this._auth.GetCurrentUserDetail().UserViewModel.SSOID;
      this.UserName = this._auth.GetCurrentUserDetail().UserViewModel.Name;

      //Update Inbox/outbox counts if user takes action on communication
      let serv = this._commonService.isUpdateInboxoutbox.subscribe(res => {
        serv.unsubscribe();
        if (res) {
          this.bindInboxOutBoxCount();
        }
      });
    }
  }

  public navItems: NavItem[] = [{
    displayName: 'Dashboard',
    iconName: 'list',
    route: 'dashboard'
  },
  ];

  ngOnInit() {
    this.BindMenuItems();
    //this.bindStaticMenu();
  }

  bindCommunicationTypeMenu() {
    let serv = this._communicationTypeService.GetActiveCommunicationType().subscribe(

      data => {
        serv.unsubscribe();
        if (data.IsSuccess) {
          var modellist = <CommunicationTypeModel[]>data.Data;
          var ct_menuList: NavItem[] = [];
          modellist.forEach(element => {
            var menu = new NavItem();
            menu.displayName = element.CommunicationType;
            menu.iconName = "list";
            menu.route = "communications-list/" + element.EnumValue.toLowerCase();
            ct_menuList.push(menu);
          });
          if (ct_menuList.length > 0) {
            this.navItems.push({
              displayName: "Communication",
              iconName: "list",
              route: "communication",
              children: ct_menuList
            });
          }
        }
        else {
        }
      },
      error => {
        serv.unsubscribe();
      }
    );
  }

  bindStaticMenu() {
    // setTimeout(() => {
    //   let element: HTMLElement = document.getElementById('menuItem_Communication') as HTMLElement;
    //   element.click();
    // }, 1000);

    var baseUserType = this._auth.BaseUserType;
    if (baseUserType == this.userTypeEnum.DREC || baseUserType == this.userTypeEnum.DOS || baseUserType == this.userTypeEnum.DRECADLC || baseUserType == this.userTypeEnum.DOSADLC || baseUserType == this.userTypeEnum.HOD || baseUserType == this.userTypeEnum.VNDR || baseUserType == this.userTypeEnum.VNDRUSER) {
      this.navItems.push({
        displayName: 'Inbox',
        iconName: 'list',
        route: 'inbox'
      });
    }
    if (baseUserType == this.userTypeEnum.ADM || baseUserType == this.userTypeEnum.SADM) {
      this.navItems.push({
        displayName: "Master",
        iconName: "lock",
        route: "master",
        children: [
          {
            displayName: "Help Document",
            iconName: "list",
            route: "master/help-document"
          },
          // {
          //   displayName: "Vendor Type",
          //   iconName: "list",
          //   route: "master/vendor-type"
          // },
          {
            displayName: "Communication Type",
            iconName: "list",
            route: "master/communication-type"
          },
          // {
          //   displayName: "Comm. Attachment Type",
          //   iconName: "list",
          //   route: "master/communication-attachment-type"
          // },
          // {
          //   displayName: "Return Reason Type",
          //   iconName: "list",
          //   route: "master/reason-type"
          // },
          // {
          //   displayName: "Department",
          //   iconName: "list",
          //   route: "master/department"
          // },
          {
            displayName: "Department",
            iconName: "list",
            route: "master/department-master"
          },
          {
            displayName: "Department Contact Details",
            iconName: "list",
            route: "master/department-contact-details"
          },
          {
            displayName: "Admin Department",
            iconName: "list",
            route: "master/admin-department"
          },
          {
            displayName: "Designation",
            iconName: "list",
            route: "master/designation-master"
          },

          {
            displayName: "Project Category",
            iconName: "list",
            route: "master/project-category"
          },

          {
            displayName: "Project Sub Category",
            iconName: "list",
            route: "master/project-sub-category"
          },

          {
            displayName: "WorkOrder Category",
            iconName: "list",
            route: "master/workorder-category"
          },

          {
            displayName: "WorkOrder Sub Category",
            iconName: "list",
            route: "master/WorkOrder-sub-category"
          },

          {
            displayName: "Office",
            iconName: "list",
            route: "master/office-master"
          },
          {
            displayName: "User Type",
            iconName: "list",
            route: "master/user-type"
          },



          // {
          //   displayName: "Complaint Type",
          //   iconName: "list",
          //   route: "master/complaint-type"
          // },
          // {
          //   displayName: "Refund Request Reason",
          //   iconName: "list",
          //   route: "master/refund-request-reason"
          // },
          // {
          //   displayName: "Concern",
          //   iconName: "list",
          //   route: "master/concern"
          // },
          // {
          //   displayName: "Suggestion For",
          //   iconName: "list",
          //   route: "master/suggestion-for"
          // },
          {
            displayName: "Look Up",
            iconName: "list",
            route: "master/master-data"
          },
          {
            displayName: "Templates",
            iconName: "build",
            route: "master",
            children: [
              {
                displayName: "Templates Type",
                iconName: "list",
                route: "master/notification-template-type"
              },
              {
                displayName: "Email Template",
                iconName: "list",
                route: "master/notification-email-templates"
              },
              {
                displayName: "SMS Template",
                iconName: "list",
                route: "master/notification-sms-templates"
              }

            ]
          },
          {
            displayName: "Permission Master",
            iconName: "list",
            route: "master/permission-master"
          },
          {
            displayName: "Permission",
            iconName: "lock",
            children: [
              {
                displayName: "Default Permission",
                iconName: "shield",
                route: "master/permission/user-default-permission"
              },
              {
                displayName: "Specific User Permission",
                iconName: "admin_panel_settings",
                route: "master/permission/user-specific-permission"
              },


            ]
          },
          {
            displayName: "Import Excel Data",
            iconName: "list",
            route: "import-excel-data"
          },


        ]
      });
    }

    if (baseUserType == this.userTypeEnum.DREC) {
      this.navItems.push(
        // {
        //   displayName: 'Department Office',
        //   iconName: 'list',
        //   route: 'master/office'
        // }
        {
          displayName: "Office",
          iconName: "list",
          route: "master/office-master"
        },

        {
          displayName: 'OIC Project Mapping',
          iconName: 'list',
          route: 'projectmapping'
        }
      );
    }
    if (baseUserType == this.userTypeEnum.ADM || baseUserType == this.userTypeEnum.DREC) {
      this.navItems.push(
        {
          displayName: 'Office Staff',
          iconName: 'list',
          route: 'user'
        });
    }

    if (baseUserType == this.userTypeEnum.ADM) {
      this.navItems.push(
        {
          displayName: 'User Creation',
          iconName: 'list',
          route: 'user-master'
        },

        {
          displayName: 'User Notification',
          iconName: 'list',
          route: 'user/user-notification'
        },
        {
          displayName: "Suggetion-Feedback",
          iconName: "list",
          route: "complaint-software"
        },

      );
    }

    if (baseUserType == this.userTypeEnum.ADM) {
      this.navItems.push(
        {
          displayName: 'Temporary Login',
          iconName: 'list',
          route: 'templogin'
        },
        // {
        //   displayName: 'Admin For Login Creation',
        //   iconName: 'list',
        //   route: 'adminForloginCreation'
        // }
      );
    }

    if (baseUserType == this.userTypeEnum.ADM || baseUserType == this.userTypeEnum.DREC || baseUserType == this.userTypeEnum.HOD) {
      this.navItems.push({
        displayName: 'Vendor',
        iconName: 'list',
        route: 'vendor'
      });
    }

    if (baseUserType == this.userTypeEnum.ADM || baseUserType == this.userTypeEnum.VNDR || baseUserType == this.userTypeEnum.HOD || baseUserType == this.userTypeEnum.DREC || baseUserType == this.userTypeEnum.VNDRUSER || baseUserType == this.userTypeEnum.DOS) {
      this.navItems.push({
        displayName: 'Project',
        iconName: 'list',
        route: 'project'
      });
    }

    if (baseUserType == this.userTypeEnum.ADM || baseUserType == this.userTypeEnum.VNDR) {
      this.navItems.push({
        displayName: "Vendor User",
        iconName: "list",
        route: "vendor-user"
      }
      );
    }

    //HOD MENU
    if (baseUserType == this.userTypeEnum.HOD) {
      this.navItems.push(
        {
          displayName: "Department",
          iconName: "list",
          route: "master/department-master"
        },
        {
          displayName: "Department Contact Details",
          iconName: "list",
          route: "master/department-contact-details"
        },
        // {
        //   displayName: 'Department Office',
        //   iconName: 'list',
        //   route: 'master/office'
        // }
        {
          displayName: "Office",
          iconName: "list",
          route: "master/office-master"
        },

        {
          displayName: 'Office Staff',
          iconName: 'list',
          route: 'user'
        }
      );
    }


    if (baseUserType != this.userTypeEnum.ADLC) {
      this.navItems.push(
        {
          displayName: 'Work Order',
          iconName: 'list',
          route: 'work-order'
        }

      );

      this.bindCommunicationTypeMenu();
    }


    setTimeout(() => {
      if (baseUserType == this.userTypeEnum.DREC || baseUserType == this.userTypeEnum.DOS || baseUserType == this.userTypeEnum.HOD || baseUserType == this.userTypeEnum.VNDR || baseUserType == this.userTypeEnum.VNDRUSER) {
        this.navItems.push({
          displayName: 'Outbox',
          iconName: 'list',
          route: 'outbox'
        }
        );
      }
      if (baseUserType == this.userTypeEnum.VNDR) {
        this.navItems.push({
          displayName: "Report",
          iconName: "list",
          children: [
            {
              displayName: "Vendor User",
              iconName: "list",
              route: "vendor-user-report"
            },

            //added by pravesh
            {
              displayName: "Old Reject Communication",
              iconName: "list",
              route: "old-communication-reject-report/oldreject"
            }
            ,
            //added by pravesh
            {
              displayName: "Old Return Communication",
              iconName: "list",
              route: "old-communication-return-report/oldreturn"
            },
            {
              displayName: "Old Disposed Communication",
              iconName: "list",
              route: "old-communication-disposed-report/olddisposed"
            }
            ,
            {
              displayName: "Old Accept Communication",
              iconName: "list",
              route: "old-communication-accept-report/oldaccept"
            },
            {
              displayName: "Old Communication Sent",
              iconName: "list",
              route: "old-communication-report/oldsent"
            },
            {
              displayName: "New Communication Sent",
              iconName: "list",
              route: "new-communication-report/newsent"
            },
            {
              displayName: "New Return Communication",
              iconName: "list",
              route: "new-communication-return-report/newreturn"
            },

            {
              displayName: "New Reject Communication",
              iconName: "list",
              route: "new-communication-reject-report/newreject"
            },

            {
              displayName: "New Disposed Communication",
              iconName: "list",
              route: "new-communication-disposed-report/newdisposed"
            }
            ,
            {
              displayName: "New Accept Communication",
              iconName: "list",
              route: "new-communication-accept-report/newaccept"
            },

            // {
            //   displayName: "Old Return Communication",
            //   iconName: "list",
            //   route: "old-communication-return-report/oldreturn"
            // },

            {
              displayName: "Vendor Department",
              iconName: "list",
              route: "reports/department-report/vndrdept"
            },
            {
              displayName: "Communication list Move Department",
              iconName: "list",
              route: "move-to-department-office-communication/movedept"
            },
            {
              displayName: "Forward Communication list",
              iconName: "list",
              route: "forward-communication-list/forwarded"
            },
            {
              displayName: "Pending Communication list",
              iconName: "list",
              route: "pending-communication-list"
            },
            {
              displayName: "Age Wise Communication list",
              iconName: "list",
              route: "agewise-communication-list"
            },
          ]
        })
      }

      if (baseUserType == this.userTypeEnum.ADM) {
        this.navItems.push({
          displayName: 'Reports',
          iconName: 'list',
          //route:  "master/vendor-type",
          children: [
            {
              // displayName: "Vendor-Reports",
              displayName: "Vendor Type Report",
              iconName: "list",
              route: "reports/vendor-report"
            },
            //added menu by pravesh
            {
              displayName: "Master Reports",
              iconName: "list",
              route: "reports/master-reports"
            },
            {
              displayName: "Department-Reports",
              iconName: "list",
              route: "reports/department-report"
            },
            {
              displayName: "Department-Office-Reports",
              iconName: "list",
              route: "reports/department-office-report"
            },
            {
              displayName: "Office Staff-Reports",
              iconName: "list",
              route: "reports/office-staff-report"
            },
            {
              displayName: "Communication-Type-Reports",
              iconName: "list",
              route: "reports/communication-type-report"
            },
            {
              displayName: "Reason-Type-Reports",
              iconName: "list",
              route: "reports/reason-type-report"
            },
            {
              displayName: "Comm. Attachment Type",
              iconName: "list",
              route: "reports/attachment-type-report"
            },
            {
              displayName: "Complaint Type",
              iconName: "list",
              route: "reports/complaint-type-report"
            },
            {
              displayName: "Refund-Request-Reason-Reports",
              iconName: "list",
              route: "reports/refund-request-reason-report"
            },
            {
              displayName: "Concern-Reports",
              iconName: "list",
              route: "reports/concern-report"
            },
            {
              displayName: "Suggestion For -Reports",
              iconName: "list",
              route: "reports/suggestion-report"
            },
            {
              displayName: "Project-Reports",
              iconName: "list",
              route: "reports/project-report"
            },
            {
              displayName: "Work-Order-Reports",
              iconName: "list",
              route: "reports/work-order-report"
            },
            {
              displayName: "Vendor-List-Report",
              iconName: "list",
              route: "reports/vendor-list-report"
            },
            {
              displayName: "Complaint-Reports",
              iconName: "list",
              route: "reports/complaint-report"
            },
            {
              displayName: "EmdReturn-Reports",
              iconName: "list",
              route: "reports/emd-return-report"
            },
            {
              displayName: "General-Reports",
              iconName: "list",
              route: "reports/general-report"
            },
            // {
            //   displayName: "Invoice-Reports",
            //   iconName: "list",
            //   route: "reports/invoice-report"
            // },
            {
              displayName: "Comm.Suggestions-Reports",
              iconName: "list",
              route: "reports/suggestions-report"
            },
            {
              displayName: "Old Return Communication",
              iconName: "list",
              route: "old-communication-return-report/oldreturn"
            },
            {
              displayName: "Old Disposed Communication",
              iconName: "list",
              route: "old-communication-disposed-report/olddisposed"
            }
            ,
            {
              displayName: "Old Accept Communication",
              iconName: "list",
              route: "old-communication-accept-report/oldaccept"
            },
            {
              displayName: "Old Communication Sent",
              iconName: "list",
              route: "old-communication-report/oldsent"
            },
            {
              displayName: "New Communication Sent",
              iconName: "list",
              route: "new-communication-report/newsent"
            },
            {
              displayName: "New Return Communication",
              iconName: "list",
              route: "new-communication-return-report/newreturn"
            },

            {
              displayName: "New Reject Communication",
              iconName: "list",
              route: "new-communication-reject-report/newreject"
            },

            {
              displayName: "New Disposed Communication",
              iconName: "list",
              route: "new-communication-disposed-report/newdisposed"
            }
            ,
            {
              displayName: "New Accept Communication",
              iconName: "list",
              route: "new-communication-accept-report/newaccept"
            }

          ]
        });
      }

      if (baseUserType == this.userTypeEnum.DREC) {
        this.navItems.push({
          displayName: 'Reports',
          iconName: 'list',
          //route:  "master/vendor-type",
          children: [
            //Pravesh_22032021
            {
              displayName: "Old Reject Communication",
              iconName: "list",
              route: "old-communication-reject-report/oldreject"
            }
            ,
            //added by pravesh
            {
              displayName: "Old Return Communication",
              iconName: "list",
              route: "old-communication-return-report/oldreturn"
            },
            {
              displayName: "Old Disposed Communication",
              iconName: "list",
              route: "old-communication-disposed-report/olddisposed"
            }
            ,
            {
              displayName: "Old Accept Communication",
              iconName: "list",
              route: "old-communication-accept-report/oldaccept"
            },
            {
              displayName: "Old Communication Sent",
              iconName: "list",
              route: "old-communication-report/oldsent"
            },
            {
              displayName: "New Communication Sent",
              iconName: "list",
              route: "new-communication-report/newsent"
            },
            {
              displayName: "New Return Communication",
              iconName: "list",
              route: "new-communication-return-report/newreturn"
            },

            {
              displayName: "New Reject Communication",
              iconName: "list",
              route: "new-communication-reject-report/newreject"
            },

            {
              displayName: "New Disposed Communication",
              iconName: "list",
              route: "new-communication-disposed-report/newdisposed"
            }
            ,
            {
              displayName: "New Accept Communication",
              iconName: "list",
              route: "new-communication-accept-report/newaccept"
            }
            ,
            {
              displayName: "Department-Office-Reports",
              iconName: "list",
              route: "reports/department-office-report"
            },
            {
              displayName: "Office Staff-Reports",
              iconName: "list",
              route: "reports/office-staff-report"
            },

            {
              displayName: "Project-Reports",
              iconName: "list",
              route: "reports/project-report"
            },
            {
              displayName: "Work-Order-Reports",
              iconName: "list",
              route: "reports/work-order-report"
            },
            // {
            //   displayName: "Vendor-List-Report",
            //   iconName: "list",
            //   route: "reports/vendor-list-report"
            // },
            {
              displayName: "Complaint-Reports",
              iconName: "list",
              route: "reports/complaint-report"
            },
            {
              displayName: "EmdReturn-Reports",
              iconName: "list",
              route: "reports/emd-return-report"
            },
            {
              displayName: "General-Reports",
              iconName: "list",
              route: "reports/general-report"
            },
            {
              displayName: "Comm.Suggestions-Reports",
              iconName: "list",
              route: "reports/suggestions-report"
            }

          ]
        });
      }

      if (baseUserType == this.userTypeEnum.HOD) {
        this.navItems.push({
          displayName: 'Reports',
          iconName: 'list',
          children: [
            {
              displayName: "Department-Reports",
              iconName: "list",
              route: "reports/department-report"
            },
            {
              displayName: "Department-Office-Reports",
              iconName: "list",
              route: "reports/department-office-report"
            },
            {
              displayName: "Office Staff-Reports",
              iconName: "list",
              route: "reports/office-staff-report"
            },

            {
              displayName: "Project-Reports",
              iconName: "list",
              route: "reports/project-report"
            },
            {
              displayName: "Work-Order-Reports",
              iconName: "list",
              route: "reports/work-order-report"
            },
            {
              displayName: "Complaint-Reports",
              iconName: "list",
              route: "reports/complaint-report"
            },
            {
              displayName: "EmdReturn-Reports",
              iconName: "list",
              route: "reports/emd-return-report"
            },
            {
              displayName: "General-Reports",
              iconName: "list",
              route: "reports/general-report"
            },
            {
              displayName: "Comm.Suggestions-Reports",
              iconName: "list",
              route: "reports/suggestions-report"
            }

          ]
        });
      }

    }, 1000);
  }

  BindMenuItems() {
    this.navItems = [];
    if (this._auth.LoggedInUserDetail.UserMenuViewModelList)
      this.navItems = this._auth.LoggedInUserDetail.UserMenuViewModelList;
    this.navItems.map(ele => { ele.isParent = true });
    this.bindInboxOutBoxCount();
  }

  UpdateLoginUserMenu(userCode = this._auth.GetCurrentUserDetail().UserViewModel.UserCode) {
    let serv = this._menuService.GetAllByUserId(userCode).subscribe((response) => {
      serv.unsubscribe();
      if (response.IsSuccess) {
        this._auth.LoggedInUserDetail.UserMenuViewModelList = <NavItem[]>(response.Data);

        this._commonService.RemoveLocalStorage("UserDetails");
        this._commonService.SetLocalStorage("UserDetails", JSON.stringify(this._auth.LoggedInUserDetail));
        this.BindMenuItems();
      }
    });
  }


  //#region Menu Hide/Show with active menu
  closeParentMenu(currentMeuIndex) {
    this.navItems.forEach((element, index) => {
      if (currentMeuIndex != index) {
        element.isExpanded = false;
      }
      else if (currentMeuIndex == index && element.isExpanded) {
        element.isExpanded = !element.isExpanded;

      }
      else {
        this.navItems[currentMeuIndex].isExpanded = true;
      }
    });
  }

  onActiveUpdate(currUrl, index) {
    this.navItems.forEach(element => {
      if (element.children != null && element.children.length > 0) {
        this.updateChildDeactivate(element.children, currUrl)
      }
      else if (currUrl != null && element.route == currUrl) {
        element.isActivate = true;

      }
      else {
        element.isActivate = false;
      }
    });


  }

  updateChildDeactivate(obj: NavItem[], currUrl: string) {

    obj.forEach(element => {
      if (element.children != null && element.children.length > 0) {
        this.updateChildDeactivate(element.children, currUrl)
      }
      else if (currUrl != null && element.route == currUrl) {
        element.isActivate = true;

      }
      else {
        element.isActivate = false;
      }
    });
  }

  bindInboxOutBoxCount() {
    let serv = this._inboxService.GetCounter().subscribe(data => {
      serv.unsubscribe();
      if (data.IsSuccess) {
        this.model = <CounterModel>data.Data;
        let inboxIndex = this.navItems.findIndex(x => x.displayName.toLowerCase() == 'inbox');
        if (inboxIndex > -1 && this.model.InboxCount > 0) {
          this.navItems[inboxIndex].dataCount = this.model.InboxCount.toString();
        }

        let outboxIndex = this.navItems.findIndex(x => x.displayName.toLowerCase() == 'outbox');
        if (outboxIndex > -1 && this.model.OutboxCount > 0) {
          this.navItems[outboxIndex].dataCount = this.model.OutboxCount.toString();
        }
      }
    });
  }

  //#endregion
}
