import { Injectable } from '@angular/core';
import { AppSetting } from '../../model/appsetting.model';
import { IndexModel } from '../../model/common.model';
import { GeneralEntryCategoryMasterModel } from '../../model/master/general-entry-category-master.model';
import { BaseService } from '../base.service';

@Injectable({
  providedIn: 'root'
})
export class GeneralEntryCategoryMasterService {

  constructor(
    private readonly _baseService: BaseService,
  ) {
  }
  GetList(model: IndexModel) {
    var result = this._baseService.post(AppSetting.GeneralEntryCategoryMasterListUrl, model);
    return result;
  }

  Add(model: GeneralEntryCategoryMasterModel) {
    var result = this._baseService.post(AppSetting.GeneralEntryCategoryMasterAddUrl, model);
    return result;
  }

  GetById(id) {
    var result = this._baseService.get(AppSetting.GeneralEntryCategoryMasterGetByIdUrl + id, null);
    return result;
  }

  Edit(id,model: GeneralEntryCategoryMasterModel) {
    var result = this._baseService.post(AppSetting.GeneralEntryCategoryMasterEditUrl+id, model);
    return result;
  }

  ChangeActiveStatus(id: number) {
    return this._baseService.get(AppSetting.GeneralEntryCategoryMasterActiveStatusUrl + id);
  }
  
}
