import { UserTypeService } from './../../shared/service/user-type.service'; 
import { AuthenticationService } from "src/app/shared/service/authentication.service";
import { UserExportModel, UserViewModel } from "./../../shared/model/user.model";
import { AlertModel, PermissionModel } from "./../../shared/model/common.model";
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AppComponent } from 'src/app/app.component';
import { GlobalMessagesModel } from 'src/app/shared/model/common-messages.model';
import { AlertService } from 'src/app/shared/service/alert.service';
import { CommonService } from 'src/app/shared/service/common.service';
import { UserService } from 'src/app/shared/service/user.service'; 
import { AlertTypeEnum, UserTypeEnum } from "src/app/shared/enum/fixed-values.enum"; 
import { UserTypeModel } from 'src/app/shared/model/user-type.model';
import { Router } from '@angular/router';
import { DDLModel } from 'src/app/shared/model/commonddl.model';

@Component({
  selector: 'app-import-excel-data',
  templateUrl: './import-excel-data.component.html',
  styleUrls: ['./import-excel-data.component.scss']
})
export class ImportExcelDataComponent implements OnInit {
  alertModel = new AlertModel()
  model = new UserExportModel()
  frmGrp: FormGroup;
  dDLList = new DDLModel();
  loginData: UserViewModel;
  isDataImporting: boolean = false;

  Permission: PermissionModel = this._authService.GetPagePermission("/import-excel-data", "import-excel-data/Add", "import-excel-data/detail", "import-excel-data/update", "/import-excel-data/delete");
  loggedInUserDetail = new UserViewModel();
  constructor(private readonly fb: FormBuilder,
    private readonly _userService: UserService,
    private readonly _alertService: AlertService,
    public readonly _commonService: CommonService,
    private readonly appComponent: AppComponent,
    private readonly _router: Router,
    private readonly _auth: AuthenticationService,
    private readonly _authService: AuthenticationService,
    private readonly _userTypeService: UserTypeService
  ) {
    this.loggedInUserDetail = this._auth.LoggedInUserDetail.UserViewModel;
    this.appComponent.setPageLayout("Import Excel Data", "keyboard_backspace", "", "");
    this.loginData = this._authService.GetCurrentUserDetail().UserViewModel;
    this.getUserType();
  }

  ngOnInit() {
    if (this.Permission.AddPageAccess || this.Permission.UpdatePageAccess) {
      this.formGroupInit();
    } else {
      this._router.navigate(["dashboard"]);
    }
  }

  formGroupInit() {
    this.frmGrp = this.fb.group({
      UserTypeCode: [null, Validators.required],
      fileUploader: [null, Validators.required],
    });
  }

  saveClick() {
    this.frmGrp.markAllAsTouched();
    if (this.frmGrp.valid) {

      //this._alertService.success(GlobalMessagesModel.saveSuccess, false, 10);
      this.model.CreateBy = this.loggedInUserDetail.UserCode;
      this._userService.ImportExcelUserAndOffice(this.model).subscribe(
        (data) => {
          if (data) {
            if (data.IsSuccess) {

              if (data.Data.FileUrl) {
                this.downloadExcel(data.Data.FileUrl, "ErrorInRecords.xlsx")
                this._alertService.error(GlobalMessagesModel.NotSaveAllRecord);
              }
              else {
                this._alertService.success(
                  GlobalMessagesModel.saveSuccess
                );
              }
            }
          }
        },
        (error) => {
          this._commonService.ScrollingTop();
          this._alertService.error(GlobalMessagesModel.InternalError);
        }
      )

    }
  }

  alert(message: string, type: string) {
    this.alertModel = new AlertModel();
    this.alertModel.Message = message;
    this.alertModel.Type = type;
    setTimeout(() => {
      this.alertModel = new AlertModel();
    }, 5000);
  }

  handleFileInput(event: any) {

    let type: string[] = ['.csv', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.ms-excel'];
    if (event.target.files.length > 0 && (event.target.files.item(0).type.match(type[0]) || event.target.files.item(0).type.match(type[1]) || event.target.files.item(0).type.match(type[2]))) {
      if (event.target.files.item(0).size > 10000000) {
        this.alert("File size must be less than 10 MB", AlertTypeEnum.Error);
        this._commonService.ScrollingTop();
      } else {
        let reader = new FileReader();
        reader.onload = (event: any) => {
          this.model.Attachment = event.target.result;
        }
        reader.readAsDataURL(event.target.files.item(0));
      }
    } else {
      this.model.Attachment = undefined;
      this.alert('Only *excel accepted!', AlertTypeEnum.Error);
    }
  }

  downloadExcel(url, FileName) {
    var link = document.createElement('a');
    link.href = url;
    link.download = FileName;//"file.xlsx";// url.split('/')[url.split('/').length - 1];
    link.click();
    link.remove()
  }

  downloadSampleExcelFile() {
    let link = document.createElement('a');
    link.setAttribute('type', 'hidden');
    link.href = 'assets/Template/TemplateUserImport.xls';
    link.download = 'TemplateUserImport.xls';
    document.body.appendChild(link);
    link.click();
    link.remove();
  }

  getUserType() {
    this._userTypeService
      .GetDownLevelUserType(UserTypeEnum.ADLC, true).subscribe(
        (data) => {
          if (data.IsSuccess) {
            var userTypes = <UserTypeModel[]>data.Data;
            if (userTypes) {
              userTypes = userTypes;
              userTypes.forEach((element) => {
                this.dDLList.ddlUserType.push({
                  Value: String(element.UserTypeCode),
                  Text: element.UserTypeTitle,
                });
              });
            }

          }
        },
        (error) => {
          this._alertService.error(error.message);
        }
      );
  }
}


