
import { Component, OnInit, ViewChild, Inject } from "@angular/core";
import { MatTableDataSource, MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatSort, MatPaginator } from "@angular/material";
import { IndexModel,SearchModel } from 'src/app/shared/model/common.model';
import { ColumnHeaderModel, DdlItemModel, DDLModel } from 'src/app/shared/model/commonddl.model';
import { AppComponent } from 'src/app/app.component';
import { AlertService } from 'src/app/shared/service/alert.service';
import { AppSetting } from 'src/app/shared/model/appsetting.model';
import { AuthenticationService } from 'src/app/shared/service/authentication.service';
import { UserTypeEnum } from 'src/app/shared/enum/fixed-values.enum';
import { Router } from '@angular/router';
import { UserViewModel, CustomSearchModel,UserForNotificationListModel } from 'src/app/shared/model/user.model';
import { LogReportService } from 'src/app/shared/service/report/log-report.service';
import { CommonService } from 'src/app/shared/service/common.service';
import { UserTypeModel } from 'src/app/shared/model/user-type.model';
import { isNullOrUndefined } from 'util';
import { FormControl, Validators } from '@angular/forms';
import { LogReportModel,LogCustomSearchModel } from 'src/app/shared/model/report/log-report.model';

import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

@Component({
  selector: 'app-log-report',
  templateUrl: './log-report.component.html',
  styleUrls: ['./log-report.component.scss']
})
export class LogReportComponent implements OnInit {
  model: LogReportModel[];
  userType: string | number;
  dataSource: any;
  loginData: UserViewModel;
  totalRecords: number;
  loginBaseUserType: string;
  listModel: UserForNotificationListModel[];
  ddlList = new DDLModel();
  displayedColumns: string[] = ["index", "PageURL", "URLType", "ScreenType", "PermissionTitle", "ActivityDate",
   "Browser", "IPAddress","ActionDetail","SSOID","ModelData"];
  
  columnsToDisplay: string[] = this.displayedColumns.slice();
  userTypeEnum =UserTypeEnum;
  ViewdisplayedColumns: ColumnHeaderModel[] = [
    { Value: "PageURL", Text: "PageURL" },
    { Value: "URLType", Text: "URL Type" },
    { Value: "ScreenType", Text: "Screen Type" },
    { Value: "PermissionTitle", Text: "Permission Title" },
    { Value: "ActivityDate", Text: "Activity Date" },

    { Value: "Browser", Text: "Browser" },
    { Value: "IPAddress", Text: "IPAddress" },
    { Value: "ActionDetail", Text: "Action Detail" },
    { Value: "SSOID", Text: "SSOID" },
    { Value: "ModelData", Text: "Model Data" },
   

  ];

  SearchdisplayedColumns: ColumnHeaderModel[] = [
    { Value: "PageURL", Text: "PageURL" },
    { Value: "URLType", Text: "URL Type" },
    { Value: "ScreenType", Text: "Screen Type" },
    { Value: "PermissionTitle", Text: "Permission Title" },   
    { Value: "Browser", Text: "Browser" },
    { Value: "IPAddress", Text: "IPAddress" },
    { Value: "ActionDetail", Text: "Action Detail" },
    { Value: "SSOID", Text: "SSOID" },   
  ];
  
  content = new FormControl("", [Validators.required]);
  fromDate: Date | string = new Date("12/17/2018");
  toDate: Date | string = new Date();
  searchModel = this._commonService.modelSetGet(new LogCustomSearchModel(), false);
  isShow = true;
  ddlAdminDepartment: DdlItemModel[] = [];
  ddlUserType: DdlItemModel[] = [];
  userTypeList: UserTypeModel[] = [];
  loginUserCode: number = 0;
  

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  constructor(private readonly appComponnet: AppComponent,
    private readonly _alertService: AlertService,
    private readonly _logReportService: LogReportService,
    private readonly _dialog: MatDialog,
    private readonly _router: Router,
    public readonly _commonService: CommonService,
    private readonly _authService: AuthenticationService,
   
    public dialog: MatDialog) {

    this.appComponnet.setPageLayout("Activity Log Report :", "", "", "");   
    this.loginData = this._authService.GetCurrentUserDetail().UserViewModel;
    this.loginBaseUserType = this._authService.BaseUserType;
  }

  ngOnInit() {
    if (this.loginBaseUserType != this.userTypeEnum.ADM && this.loginBaseUserType != this.userTypeEnum.DREC && this.loginBaseUserType !=  this.userTypeEnum.DRECADLC && this.userTypeEnum.HOD != this.loginBaseUserType && this.userTypeEnum.SADM != this.loginBaseUserType) {
      this._router.navigate(["dashboard"]);
    }
    this.getList();
   
  }

  toggleDisplay() {
    this.isShow = !this.isShow;
  }
  getList() {
   
    if (this.fromDate) {
      const uTCFromDate = new Date(Date.UTC(new Date(this.fromDate).getFullYear(), new Date(this.fromDate).getMonth(), new Date(this.fromDate).getDate()
      )
      );
      this.fromDate = uTCFromDate;
    }

    if (this.toDate) {
      const uTCToDate = new Date(
        Date.UTC(
          new Date(this.toDate).getFullYear(),
          new Date(this.toDate).getMonth(),
          new Date(this.toDate).getDate()
        )
      );
      this.toDate = uTCToDate;
    }

    if (this.fromDate || this.toDate) {
      this.searchModel.indexModel.AdvanceSearchModel = {
        FromDate: this.fromDate,
        ToDate: this.toDate,
      };
    }

    this.searchModel.CreatedFrom = this.fromDate;
    this.searchModel.CreatedTo = this.toDate;
    this.searchModel.UserCode = this.loginData.UserCode;
    this.searchModel.indexModel.AdvanceSearchModel = {

      Name: this.searchModel.Name,
      Ssoid: this.searchModel.Ssoid,
      UserTypeCode: this.searchModel.UserType, 
      CreatedFrom: this.searchModel.CreatedFrom,
      CreatedTo: this.searchModel.CreatedTo,
      FromDate: this.fromDate,
      ToDate: this.toDate,


    };
    this.searchModel = this._commonService.modelSetGet(this.searchModel, true);

    // this.indexModel.AdvanceSearchModel = this.searchModel;
    this._logReportService.GetLogReportData(this.searchModel.indexModel).subscribe(
      data => {
        if (data.IsSuccess) {
         
          this.model = <LogReportModel[]>data.Data.Data;
          this.dataSource = new MatTableDataSource<LogReportModel>(this.model);
          this.totalRecords = data.Data.TotalRecords;
          if (!this.searchModel.indexModel.IsPostBack) {
            this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.sort;
          }
        } else {
          this._alertService.error(data.Message);
        }
      },
      error => {
        this._alertService.error(error.message);
      }
    );
  }

  sortData(event) {
    this.searchModel.indexModel.OrderBy = event.active;
    this.searchModel.indexModel.OrderByAsc = event.direction == AppSetting.orderByDscAsc ? AppSetting.orderByAsc : AppSetting.orderByDsc;
    this.searchModel.indexModel.IsPostBack = true;
    this.getList();
  }

  onPaginateChange(event) {
    this.searchModel.indexModel.Page = event.pageIndex + 1;
    this.searchModel.indexModel.PageSize = event.pageSize;
    this.searchModel.indexModel.IsPostBack = true;
    this.getList();
  }

  SearchByKeyword(searchValue) {
    this.searchModel.indexModel.Search = searchValue;
    this.getList();
  }

  ClearSearch() {
    this.searchModel = new CustomSearchModel();
    this.fromDate = '';
    this.toDate = '';
    this.getList();
  }
  buttonClickExportAsPDF(): void {
    
  }

  buttonClickExportAsXLSX(): void {
   
  }
}
