import { CommonService } from './../../shared/service/common.service';
import { Component, OnInit, ViewChild } from "@angular/core";
import { MatTableDataSource, MatDialog, MatSort, MatPaginator } from "@angular/material";
import { GlobalFilterModel, IndexModel, PermissionModel, SearchModel } from 'src/app/shared/model/common.model';
import { ColumnHeaderModel } from 'src/app/shared/model/commonddl.model';
import { AppComponent } from 'src/app/app.component';
import { AlertService } from 'src/app/shared/service/alert.service';
import { ConfirmationDialogComponent } from 'src/app/confirmation-dialog/confirmation-dialog.component';
import { GlobalMessagesModel } from 'src/app/shared/model/common-messages.model';
import { AppSetting } from 'src/app/shared/model/appsetting.model';
import { VendorModel } from 'src/app/shared/model/vendor.model';
import { VendorService } from 'src/app/shared/service/vendor.service';
import { VendorRegistrationDialogComponent } from './vendor-registration-dialog/vendor-registration-dialog.component';
import { AuthenticationService } from 'src/app/shared/service/authentication.service';
import { UserTypeEnum } from 'src/app/shared/enum/fixed-values.enum';
import { Router } from '@angular/router';

@Component({
  selector: 'app-vendor',
  templateUrl: './vendor.component.html',
  styleUrls: ['./vendor.component.scss']
})

export class VendorComponent implements OnInit {
  model: VendorModel[];
  indexModel = this._commonService.modelSetGet(new IndexModel()) as IndexModel;
  dataSource: any;
  totalRecords: number;
  loginUserCode: number;
  loginBaseUserType: string;
  userTypeEnum = UserTypeEnum;
  defaultValue: SearchModel;

  displayedColumns: string[] = [];
  columnsToDisplay: string[] = [];
  ViewdisplayedColumns: ColumnHeaderModel[] = [
    { Value: "SSOId", Text: "SSOId" },
    { Value: "ContactPersonName", Text: "Vendor Name" },
    { Value: "VendorType", Text: "Vendor Type" },
    { Value: "CompanyName", Text: "Organisation Name" },
    //{ Value: "CompanyDetail", Text: "Company/Address" },
    { Value: "CompanyAddress", Text: "Organization Address" }, 
    // { Value: "ContactPersonName", Text: "Contact Person" },
    { Value: "Email", Text: "Email" },
    { Value: "CompanyMobileNos", Text: "Mobile No" },
    { Value: "GSTINNo", Text: "GSTIN No" },
    { Value: "RegistrationDate", Text: "Registration Date" },
  ];

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  Permission: PermissionModel = this._authService.GetPagePermission("vendor", "vendor/create", "vendor/detail", "vendor/update", "vendor/delete", "vendor/bank-account");

  constructor(
    private readonly appComponnet: AppComponent,
    private readonly _alertService: AlertService,
    private readonly _vendorService: VendorService,
    private readonly _dialog: MatDialog,
    private readonly _router: Router,
    private readonly _authService: AuthenticationService,
    public readonly _commonService: CommonService
  ) {
    this.appComponnet.setPageLayout("Vendor :", "", "", "", true);
    this.loginUserCode = this._authService.UserCode;
    this.loginBaseUserType = this._authService.BaseUserType;

    this.displayedColumns = ["index", "SSOId", "ContactPersonName", "VendorType", "CompanyName", "CompanyAddress", "GSTINNo", "RegistrationDate"];
    if (this.loginBaseUserType == this.userTypeEnum.ADM || this.loginBaseUserType == this.userTypeEnum.SADM) {
      this.displayedColumns.push("Email");
      this.displayedColumns.push("CompanyMobileNos");
      this.displayedColumns.push("IsActive");
      this.displayedColumns.push("IsVerified");
    }
    this.displayedColumns.push("Action");

    this.columnsToDisplay = this.displayedColumns.slice();
  }

  ngOnInit() {
    if (this.loginBaseUserType == UserTypeEnum.VNDR || this.loginBaseUserType == UserTypeEnum.VNDRUSER) {
      this._router.navigate(["dashboard"]);
    }
    this.getList();
  }

  getList() {
    this._vendorService.GetList(this._commonService.modelSetGet(this.indexModel, true)).subscribe(
      data => {
        if (data.IsSuccess) {

          this.model = <VendorModel[]>data.Data.Data;
          this.model.forEach((element) => {
          });
          this.dataSource = new MatTableDataSource<VendorModel>(this.model);
          this.totalRecords = data.Data.TotalRecords;
          if (!this.indexModel.IsPostBack) {
            this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.sort;
          }
        } else {
          this._alertService.error(data.Message);
        }
      },
      error => {
        this._alertService.error(error.message);
      }
    );
  }

  updateActiveStatus(id) {
    const dialogRef = this._dialog.open(ConfirmationDialogComponent, { width: "50%", data: GlobalMessagesModel.ConfirmStatusChanged });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this._vendorService.ChangeActiveStatus(id).subscribe(
          data => {
            if (data.IsSuccess) {
              this._alertService.success(data.Message);
              this.getList();
            }
          },
          error => {
            this._alertService.error(error.message);
          }
        );
      }
    });
  }

  updateVerifiedStatus(id) {
    const dialogRef = this._dialog.open(ConfirmationDialogComponent, { width: "50%", data: GlobalMessagesModel.ConfirmStatusChanged });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this._vendorService.ChangeVerifiedStatus(id).subscribe(
          data => {
            if (data.IsSuccess) {

              this._alertService.success(data.Message);
              this.getList();
            }
          },
          error => {
            this._alertService.error(error.message);
          }
        );
      }
    });
  }

  sortData(event) {
    this.indexModel.OrderBy = event.active;
    this.indexModel.OrderByAsc = event.direction == AppSetting.orderByDscAsc ? AppSetting.orderByAsc : AppSetting.orderByDsc;
    this.indexModel.IsPostBack = true;
    this.getList();
  }


  onPaginateChange(event) {
    this.indexModel.Page = event.pageIndex + 1;
    this.indexModel.PageSize = event.pageSize;
    this.indexModel.IsPostBack = true;
    this.getList();
  }
  SearchByKeyword(searchValue: GlobalFilterModel) {
    this.indexModel.Search = searchValue.search;
    this.indexModel.AdvanceSearchModel = { ActiveStatus: searchValue.ActiveStatus }

    this.getList();
  }

  ClearSearch() {
    this.indexModel = new IndexModel();
    this.getList();
  }


  OpenDialog(Id) {
    const _dialogRef = this._dialog.open(VendorRegistrationDialogComponent, {
      width: "1000px",
      data: { Id },
      disableClose: true
    });
    _dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        this.getList();
      }
    });
  }
}