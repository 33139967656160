import { Component, Inject, OnInit } from "@angular/core";
import { DDLModel } from "src/app/shared/model/commonddl.model";
import { SSOUserModel, UserCreationModel, userCreationConfigModel, UpdateUserOfficeModel } from "src/app/shared/model/user.model";
import { UserTypeService } from "src/app/shared/service/user-type.service";
import { AbstractControl, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { CommonService } from "src/app/shared/service/common.service";
import { AlertService } from "src/app/shared/service/alert.service";
import { UserService } from "src/app/shared/service/user.service";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { GlobalMessagesModel } from "src/app/shared/model/common-messages.model";
import { AlertTypeEnum, UserTypeEnum } from "src/app/shared/enum/fixed-values.enum";
import { AppSetting } from "src/app/shared/model/appsetting.model";
import { SetUserPrimaryDetailDialogComponent } from "../set-user-primary-detail-dialog/set-user-primary-detail-dialog.component";

@Component({
  selector: 'app-self-register-dialog',
  templateUrl: './self-register-dialog.component.html',
  styleUrls: ['./self-register-dialog.component.scss']
})

export class SelfRegisterDialogComponent implements OnInit {

  frmGrp: FormGroup;
  isUserExistOnSSO = false;
  hasMultiRights: boolean = false;
  ddlList: DDLModel = new DDLModel();
  model: UserCreationModel = new UserCreationModel();
  updateModel: UpdateUserOfficeModel = new UpdateUserOfficeModel();
  fieldConfig: userCreationConfigModel = new userCreationConfigModel();
  displayUserType: string = 'Officer' + ' (' + String(UserTypeEnum.DOS) + ')';

  constructor(
    private readonly fb: FormBuilder,
    public readonly _commonService: CommonService,
    private readonly _alertService: AlertService,
    private readonly _userService: UserService,
    private readonly _userTypeService: UserTypeService,
    private readonly _dialog: MatDialog,
    public _dialogRef: MatDialogRef<SelfRegisterDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    if (data && data.ssoid) {
      this.model.SSOID = data.ssoid;
      this.checkSSOID();
    }
    this.model.UserType = String(UserTypeEnum.DOS);
    this.getDDLList();
  }

  ngOnInit() {
    this.formGroupInit();
  }

  getDDLList() {
    this._commonService.GetAllDDL(AppSetting.DDlKeyForSelfRegister).subscribe((data) => {
      if (data.IsSuccess) {
        let ddl = <DDLModel>data.Data;
        this.ddlList.ddlTitle = ddl.ddlTitle;
        this.ddlList.ddlGender = ddl.ddlGender;
        this.ddlList.ddlDesignation = ddl.ddlDesignation;
        this.ddlList.ddlAllDepartment = ddl.ddlAllDepartment;
        this.ddlList.ddlAllOfficeAdminDepartment = ddl.ddlAllOfficeAdminDepartment;
        this.ddlList.ddlAllOfficeState = ddl.ddlAllOfficeState;
      }
    }, (error) => {
      this._alertService.error(error.message);
    });
  }

  formGroupInit() {
    this.frmGrp = this.fb.group({
      SSOID: [null, Validators.required],
      Title: [null, null],
      UserName: [null, Validators.required],
      Email: [null, Validators.compose([Validators.required, Validators.email])],
      Mobile: [null, Validators.compose([Validators.required, Validators.maxLength(10)])],
      ParentDepartmentCode: [null, Validators.required],
      Gender: [null, Validators.required],
      Designation: [null, null],
      AdmDepartmentCodes: [null, null],
      AdmDepartmentCode: [null, null],
      DepartmentCodes: [null, null],
      DepartmentCode: [null, null],
      StateCode: [null, null],
      DistrictCodes: [null, null],
      DistrictCode: [null, null],
      OfficeCodes: [null, null],
      OfficeCode: [null, null]
    });

    this.manageFormFields();
  }

  manageFormFields() {
    if (this.model.SSOID) {
      this.frmGrp.get("SSOID").disable();
    }

    let baseUserType = this.model.BaseUserType ? this.model.BaseUserType : this.model.UserType;
    let userTypeCategory = this.model.UserTypeCategory;

    //#region Manage Fields
    this.hasMultiRights = false;
    this.fieldConfig.IsAdminDepartmentShow = false;
    this.fieldConfig.IsDepartmentShow = false;
    this.fieldConfig.IsStateShow = false;
    this.fieldConfig.IsDistrictShow = false;
    this.fieldConfig.IsOfficeShow = false;

    const admDeptCodesControl = this.frmGrp.get("AdmDepartmentCodes");
    const admDeptCodeControl = this.frmGrp.get("AdmDepartmentCode");
    const deptCodesControl = this.frmGrp.get("DepartmentCodes");
    const deptCodeControl = this.frmGrp.get("DepartmentCode");
    const stateCodeControl = this.frmGrp.get("StateCode");
    const distCodesControl = this.frmGrp.get("DistrictCodes");
    const distCodeControl = this.frmGrp.get("DistrictCode");
    const offCodesControl = this.frmGrp.get("OfficeCodes");
    const offCodeControl = this.frmGrp.get("OfficeCode");

    admDeptCodesControl.setValidators(null);
    admDeptCodeControl.setValidators(null);
    deptCodesControl.setValidators(null);
    deptCodeControl.setValidators(null);
    stateCodeControl.setValidators(null);
    distCodesControl.setValidators(null);
    distCodeControl.setValidators(null);
    offCodesControl.setValidators(null);
    offCodeControl.setValidators(null);

    if (baseUserType == UserTypeEnum.ADM || baseUserType == UserTypeEnum.SADM
      || baseUserType == UserTypeEnum.VNDR || baseUserType == UserTypeEnum.VNDRUSER) {

    }
    else if (baseUserType == UserTypeEnum.HOD) {
      this.hasMultiRights = true;
      this.fieldConfig.IsAdminDepartmentShow = true;
      this.fieldConfig.IsDepartmentShow = true;
      admDeptCodesControl.setValidators(Validators.required);
      deptCodesControl.setValidators(Validators.required);
    }
    else if (baseUserType == UserTypeEnum.HOO) {
      this.hasMultiRights = true;

      this.fieldConfig.IsAdminDepartmentShow = true;
      this.fieldConfig.IsDepartmentShow = true;
      this.fieldConfig.IsStateShow = true;
      this.fieldConfig.IsDistrictShow = true;
      this.fieldConfig.IsOfficeShow = true;

      admDeptCodesControl.setValidators(Validators.required);
      deptCodesControl.setValidators(Validators.required);
      stateCodeControl.setValidators(Validators.required);
      distCodesControl.setValidators(Validators.required);
      offCodesControl.setValidators(Validators.required);
    }
    else if (baseUserType == UserTypeEnum.DREC || baseUserType == UserTypeEnum.DOS) {
      this.fieldConfig.IsAdminDepartmentShow = true;
      this.fieldConfig.IsDepartmentShow = true;
      this.fieldConfig.IsStateShow = true;
      this.fieldConfig.IsDistrictShow = true;
      this.fieldConfig.IsOfficeShow = true;

      admDeptCodeControl.setValidators(Validators.required);
      deptCodeControl.setValidators(Validators.required);
      stateCodeControl.setValidators(Validators.required);
      distCodeControl.setValidators(Validators.required);
      offCodeControl.setValidators(Validators.required);
    }
    else if (baseUserType == UserTypeEnum.HOOADLC) {
      this.hasMultiRights = true;

      this.fieldConfig.IsOfficeShow = true;
      offCodesControl.setValidators(Validators.required);

      switch (userTypeCategory) {
        case UserTypeEnum.HOD:
          this.fieldConfig.IsAdminDepartmentShow = true;
          this.fieldConfig.IsDepartmentShow = true;
          admDeptCodesControl.setValidators(Validators.required);
          deptCodesControl.setValidators(Validators.required);
          break;
        case UserTypeEnum.DLO:
          this.fieldConfig.IsStateShow = true;
          stateCodeControl.setValidators(Validators.required);

          this.fieldConfig.IsDistrictShow = true;
          distCodesControl.setValidators(Validators.required);
          break;
      }
    }
    else if (baseUserType == UserTypeEnum.DRECADLC || baseUserType == UserTypeEnum.DOSADLC) {
      this.fieldConfig.IsOfficeShow = true;
      offCodeControl.setValidators(Validators.required);

      switch (userTypeCategory) {
        case UserTypeEnum.HOD:
          this.fieldConfig.IsAdminDepartmentShow = true;
          this.fieldConfig.IsDepartmentShow = true;
          admDeptCodeControl.setValidators(Validators.required);
          deptCodeControl.setValidators(Validators.required);
          break;
        case UserTypeEnum.DLO:
          this.fieldConfig.IsStateShow = true;
          stateCodeControl.setValidators(Validators.required);

          this.fieldConfig.IsDistrictShow = true;
          distCodeControl.setValidators(Validators.required);
          break;
      }
    }

    admDeptCodesControl.updateValueAndValidity();
    admDeptCodeControl.updateValueAndValidity();
    deptCodesControl.updateValueAndValidity();
    deptCodeControl.updateValueAndValidity();
    stateCodeControl.updateValueAndValidity();
    distCodesControl.updateValueAndValidity();
    distCodeControl.updateValueAndValidity();
    offCodesControl.updateValueAndValidity();
    offCodeControl.updateValueAndValidity();
    //#endregion

    //#region Manage Update Model
    if (this.model.AdminDepartment) {
      let admDeptCodes = this.model.AdminDepartment.toString().split(',');
      if (admDeptCodes && admDeptCodes.length > 0) {
        this.updateModel.AdmDepartmentCode = this.hasMultiRights ? undefined : admDeptCodes[0];
        this.updateModel.AdmDepartmentCodes = this.hasMultiRights ? admDeptCodes : undefined;
      }
    }

    if (this.model.DepartmentCode) {
      let deptCodes = this.model.DepartmentCode.toString().split(',');
      if (deptCodes && deptCodes.length > 0) {
        this.updateModel.DepartmentCode = this.hasMultiRights ? undefined : deptCodes[0];
        this.updateModel.DepartmentCodes = this.hasMultiRights ? deptCodes : undefined;
      }
    }

    if (this.model.StateCodes) {
      let statCodes = this.model.StateCodes.toString().split(',');
      if (statCodes && statCodes.length > 0) {
        this.updateModel.StateCode = statCodes[0];
      }
    }

    if (this.model.DistrictCode) {
      let distCodes = this.model.DistrictCode.toString().split(',');
      if (distCodes && distCodes.length > 0) {
        this.updateModel.DistrictCode = this.hasMultiRights ? undefined : distCodes[0];
        this.updateModel.DistrictCodes = this.hasMultiRights ? distCodes : undefined;
      }
    }

    if (this.model.OfficeCode) {
      let offCodes = this.model.OfficeCode.toString().split(',');
      if (offCodes && offCodes.length > 0) {
        this.updateModel.OfficeCode = this.hasMultiRights ? undefined : offCodes[0];
        this.updateModel.OfficeCodes = this.hasMultiRights ? offCodes : undefined;
      }
    }
    //#endregion 
  }

  checkSSOID() {
    this.clearSSOFields();
    this._userService.IsSSOIdExist(this.model.SSOID, 0).subscribe((result) => {
      if (result.IsSuccess) {
        const isExist = (result.Data != null) ? <boolean>result.Data : null;
        if (isExist != null && isExist == true) {
          this.model.SSOID = undefined;
          this._alertService.error(result.Message);
        }
        else {
          this.getUserDetailFromSSO(this.model.SSOID);
        }
      }
    }, (error) => {
      this._alertService.error(GlobalMessagesModel.InternalError);
    });
  }

  getUserDetailFromSSO(ssoId: string) {
    this._userService.GetUserDetailFromSSO(ssoId).subscribe((result) => {
      if (result.IsSuccess) {
        let ssoUserDetail = <SSOUserModel>result.Data;

        let firstName = ssoUserDetail.FirstName ? ssoUserDetail.FirstName : '';
        let lastName = ssoUserDetail.LastName ? ssoUserDetail.LastName : '';
        this.model.UserName = firstName + ' ' + lastName;
        this.model.Email = ssoUserDetail.MailPersonal ? ssoUserDetail.MailPersonal : undefined;
        this.model.Mobile = ssoUserDetail.Mobile ? ssoUserDetail.Mobile : undefined;
        this.model.Gender = ssoUserDetail.Gender ? ssoUserDetail.Gender : undefined;
        this.model.ProfilePic = (ssoUserDetail.Photo && ssoUserDetail.Photo.length > 0) ? ssoUserDetail.Photo : '';
        this.isUserExistOnSSO = true;

        if (ssoId.toLowerCase() != ssoUserDetail.SSOID.toLowerCase()) {
          this.model.SSOID = ssoUserDetail.SSOID;
          this.checkSSOID();
        }
      } else {
        this._commonService.alert(ssoId + " SSO ID Not Found!", AlertTypeEnum.Error);
      }
    }, (error) => {
      this._commonService.alert(GlobalMessagesModel.InternalError, AlertTypeEnum.Error);
    });
  }

  clearSSOFields() {
    this.model.UserName = undefined;
    this.model.Email = undefined;
    this.model.Mobile = undefined;
    this.model.Gender = undefined;
    this.model.ProfilePic = '';
    this.isUserExistOnSSO = false;
  }

  get selAdmDeptCodes(): string[] {
    if (this.hasMultiRights) {
      return (this.updateModel.AdmDepartmentCodes && this.updateModel.AdmDepartmentCodes.length > 0) ?
        this.updateModel.AdmDepartmentCodes : undefined;
    }
    else {
      return (this.updateModel.AdmDepartmentCode) ? this.updateModel.AdmDepartmentCode.split(',') : undefined;
    }
  }

  get selDeptCodes(): string[] {
    if (this.hasMultiRights) {
      return (this.updateModel.DepartmentCodes && this.updateModel.DepartmentCodes.length > 0) ?
        this.updateModel.DepartmentCodes : undefined;
    }
    else {
      return (this.updateModel.DepartmentCode) ? this.updateModel.DepartmentCode.split(',') : undefined;
    }
  }

  get selDistCodes(): string[] {
    if (this.hasMultiRights) {
      return (this.updateModel.DistrictCodes && this.updateModel.DistrictCodes.length > 0) ?
        this.updateModel.DistrictCodes : undefined;
    }
    else {
      return (this.updateModel.DistrictCode) ? this.updateModel.DistrictCode.split(',') : undefined;
    }
  }

  get selOfficeCodes(): string[] {
    if (this.hasMultiRights) {
      return (this.updateModel.OfficeCodes && this.updateModel.OfficeCodes.length > 0) ?
        this.updateModel.OfficeCodes : undefined;
    }
    else {
      return (this.updateModel.OfficeCode) ? this.updateModel.OfficeCode.split(',') : undefined;
    }
  }

  getDepartments() {
    let admDeptCodes = this.selAdmDeptCodes ? this.selAdmDeptCodes.toString() : '';

    this._commonService.GetDDL(AppSetting.ddlAllOfficeDepartment, admDeptCodes).subscribe(data => {
      if (data.IsSuccess) {
        var ddl = <DDLModel>data.Data;
        this.ddlList.ddlAllOfficeDepartment = ddl.ddlAllOfficeDepartment;

        //#region Check/Bind Departments 
        if (this.selDeptCodes && this.selDeptCodes.length > 0) {
          let deptCodes = this.selDeptCodes.filter(
            item => this.ddlList.ddlAllOfficeDepartment.find(x => x.Value == item)
          );
          this.updateModel.DepartmentCodes = this.hasMultiRights ? deptCodes : undefined;
          this.updateModel.DepartmentCode = this.hasMultiRights ? undefined : deptCodes[0];
        }
        //#endregion

      }
    }, error => {
      this._alertService.error(error.message);
    });
  }

  getDistricts() {
    let stateCode = this.updateModel.StateCode ? this.updateModel.StateCode : 0;
    let deptCodes = this.selDeptCodes ? this.selDeptCodes.toString() : '';

    this._commonService.GetDDL(AppSetting.ddlAllOfficeDistrict, stateCode, deptCodes).subscribe(data => {
      if (data.IsSuccess) {
        var ddl = <DDLModel>data.Data;
        this.ddlList.ddlAllOfficeDistrict = ddl.ddlAllOfficeDistrict;

        //#region Check/Bind Districts 
        if (this.selDistCodes && this.selDistCodes.length > 0) {
          let distCodes = this.selDistCodes.filter(
            item => this.ddlList.ddlAllOfficeDistrict.find(x => x.Value == item)
          );
          this.updateModel.DistrictCodes = this.hasMultiRights ? distCodes : undefined;
          this.updateModel.DistrictCode = this.hasMultiRights ? undefined : distCodes[0];
        }
        //#endregion

      }
    }, error => {
      this._alertService.error(error.message);
    });
  }

  getOffices() {
    let deptCodes = this.selDeptCodes ? this.selDeptCodes.toString() : '';
    let distCodes = this.selDistCodes ? this.selDistCodes.toString() : '';

    this._commonService.GetDDL(AppSetting.ddlAllOffice, deptCodes, distCodes).subscribe(data => {
      if (data.IsSuccess) {
        var ddl = <DDLModel>data.Data;
        this.ddlList.ddlAllOffice = ddl.ddlAllOffice;

        //#region Check/Bind Offices 
        if (this.selOfficeCodes && this.selOfficeCodes.length > 0) {
          let offCodes = this.selOfficeCodes.filter(
            item => this.ddlList.ddlAllOffice.find(x => x.Value == item)
          );
          this.updateModel.OfficeCodes = this.hasMultiRights ? offCodes : undefined;
          this.updateModel.OfficeCode = this.hasMultiRights ? undefined : offCodes[0];
        }
        //#endregion

      }
    }, error => {
      this._alertService.error(error.message);
    });
  }

  selectAllAdmDepartment() {
    if (this.updateModel.AdmDepartmentCodes.length != this.ddlList.ddlAllOfficeAdminDepartment.length) {
      this.updateModel.AdmDepartmentCodes = this.ddlList.ddlAllOfficeAdminDepartment.map(function (a) {
        return a.Value;
      });
    } else {
      this.updateModel.AdmDepartmentCodes = [];
    }
  }

  selectAllDepartment() {
    if (this.updateModel.DepartmentCodes.length != this.ddlList.ddlAllOfficeDepartment.length) {
      this.updateModel.DepartmentCodes = this.ddlList.ddlAllOfficeDepartment.map(function (a) {
        return a.Value;
      });
    } else {
      this.updateModel.DepartmentCodes = [];
    }
  }

  selectAllDistrict() {
    if (this.updateModel.DistrictCodes.length != this.ddlList.ddlAllOfficeDistrict.length) {
      this.updateModel.DistrictCodes = this.ddlList.ddlAllOfficeDistrict.map(function (a) {
        return a.Value;
      });
    } else {
      this.updateModel.DistrictCodes = [];
    }
  }

  selectAllOffice() {
    if (this.updateModel.OfficeCodes.length != this.ddlList.ddlAllOffice.length) {
      this.updateModel.OfficeCodes = this.ddlList.ddlAllOffice.map(function (a) {
        return a.Value;
      });
    } else {
      this.updateModel.OfficeCodes = [];
    }
  }

  isRequiredField(field: string) {
    const form_field = this.frmGrp.get(field);
    if (!form_field.validator) {
      return false;
    }
    const validator = form_field.validator({} as AbstractControl);
    return (validator && validator.required);
  }

  onSubmit() {
    this.frmGrp.markAllAsTouched();
    if (this.frmGrp.valid && this.isUserExistOnSSO) {
      if (this.hasMultiRights) {
        this.model.DepartmentCode = this.updateModel.DepartmentCodes;
        this.model.DistrictCode = this.updateModel.DistrictCodes;
        this.model.OfficeCode = this.updateModel.OfficeCodes;

        this.openSetPrimaryDetailPopup();
      }
      else {
        this.model.DepartmentCode = this.updateModel.DepartmentCode ? this.updateModel.DepartmentCode.toString().split(',') : undefined;
        this.model.DistrictCode = this.updateModel.DistrictCode ? this.updateModel.DistrictCode.toString().split(',') : undefined;
        this.model.OfficeCode = this.updateModel.OfficeCode ? this.updateModel.OfficeCode.toString().split(',') : undefined;

        this.model.PrimaryDepartmentCode = this.model.DepartmentCode ? String(this.model.DepartmentCode) : undefined;
        this.model.PrimaryDistrictCode = this.model.DistrictCode ? String(this.model.DistrictCode) : undefined;
        this.model.PrimaryOfficeCode = this.model.OfficeCode ? String(this.model.OfficeCode) : undefined;

        this.saveUser();
      }
    }
    else if (!this.isUserExistOnSSO) {
      this._alertService.error(this.model.SSOID + " SSO ID not exist on SSO Server!");
    }
  }

  saveUser() {
    this._userService.SelfRegister(this.model).subscribe((data) => {
      if (data && data.IsSuccess) {
        this._alertService.success(GlobalMessagesModel.saveSuccess);
        this.closeDialog();
      }
      else {
        this._alertService.error(GlobalMessagesModel.saveError);
      }
    }, (error) => {
      this._alertService.error(error.message);
    });
  }

  openSetPrimaryDetailPopup() {
    const _dialogRef = this._dialog.open(SetUserPrimaryDetailDialogComponent, {
      width: "500px",
      height: "320px",
      data: { model: this.model, fieldConfig: this.fieldConfig },
      disableClose: true
    });
    _dialogRef.afterClosed().subscribe((res: any) => {
      if (res) {
        this.model.PrimaryDepartmentCode = res.PrimaryDepartmentCode;
        this.model.PrimaryDistrictCode = res.PrimaryDistrictCode;
        this.model.PrimaryOfficeCode = res.PrimaryOfficeCode;
        this.saveUser();
      }
    });
  }

  closeDialog(): void {
    this._dialogRef.close({
      officeCode: this.model.PrimaryOfficeCode,
    });
  }

}
