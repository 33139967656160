import { Component, OnInit, ViewChild } from '@angular/core';
import { AppComponent } from "src/app/app.component";
import { ConcernModel } from 'src/app/shared/model/master/concern.model';
import { ConcernService } from 'src/app/shared/service/master/concern.service';
import { GlobalFilterModel, IndexModel, SearchModel } from 'src/app/shared/model/common.model';
import { GlobalMessagesModel } from 'src/app/shared/model/common-messages.model';
import { AppSetting } from 'src/app/shared/model/appsetting.model';
import { MatTableDataSource, MatDialog, MatSort, MatPaginator} from "@angular/material";
import { ConfirmationDialogComponent } from "src/app/confirmation-dialog/confirmation-dialog.component";
import { AlertService } from 'src/app/shared/service/alert.service';
import { CommonService } from 'src/app/shared/service/common.service';
import { ColumnHeaderModel } from 'src/app/shared/model/commonddl.model';
import { Router } from '@angular/router';

@Component({
  selector: 'app-concern',
  templateUrl: './concern.component.html',
  styleUrls: ['./concern.component.scss']
})
export class ConcernComponent implements OnInit {

  model: ConcernModel[];
  indexModel: IndexModel;
  dataSource: any;
  totalRecords: number;
  defaultValue: SearchModel;

  displayedColumns: string[] = ["index","ConcernName","ConcernNameHindi","IsActive","Action"];
  columnsToDisplay: string[] = this.displayedColumns.slice();
  ViewdisplayedColumns: ColumnHeaderModel[] = [
    { Value: "ConcernName", Text: "Concern" },
    { Value: "ConcernNameHindi", Text: "Concern (Hindi)" },
  ];

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(
    private readonly appComponnet: AppComponent,
    private readonly _alertService: AlertService,
    private readonly _concernService: ConcernService,
    private readonly _router: Router,
    private readonly _dialog: MatDialog,
    public readonly _commonService: CommonService
  ) {
    this.appComponnet.setPageLayout("Concern :","add","Create","master/concern/add");
    //this.indexModel = new IndexModel();
    const Temp = JSON.parse(sessionStorage.getItem("IsConcernStorage"));

    this.indexModel = JSON.parse(sessionStorage.getItem("ConcernFilter"));

    if (!this.indexModel) {
      this.indexModel = new IndexModel();
    } else if (this.indexModel.Search && Temp) {
      sessionStorage.setItem("IsConcernStorage", JSON.stringify(false));
      this.defaultValue = new SearchModel();
      var temp = JSON.parse(this.indexModel.Search);
      this.defaultValue.Key = Object.keys(temp)[0];
      this.defaultValue.Value = String(Object.values(temp)[0]);
    }
    else {
      this.indexModel = new IndexModel();
      this.getList();
    }
  }

  ngOnInit() {
    this.getList();
  }

  getList() {
    this._concernService.GetList(this.indexModel).subscribe(
      data => {
        if (data.IsSuccess) {
          this.model = <ConcernModel[]>data.Data.Data;
          this.dataSource = new MatTableDataSource<ConcernModel>(this.model);
          this.totalRecords = data.Data.TotalRecords;
          if (!this.indexModel.IsPostBack) {
            this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.sort;
          }
        } else {
          this._alertService.error(data.Message);
        }
      },
      error => {
        this._alertService.error(error.message);
      }
    );
  }

  updateDeleteStatus(id) {
    const dialogRef = this._dialog.open(ConfirmationDialogComponent, {width: "50%", data: GlobalMessagesModel.ConfirmDeleted });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this._concernService.ChangeDeleteStatus(id).subscribe(
          data => {
            if (data.IsSuccess) {
              this._alertService.success(data.Message);
              this._commonService.ScrollingTop();
              this.getList();
            }
          },
          error => {
            this._alertService.error(error.message);
          }
        );
      }
    });
  }

  updateActiveStatus(id) {
    const dialogRef = this._dialog.open(ConfirmationDialogComponent, { width: "50%", data: GlobalMessagesModel.ConfirmStatusChanged });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this._concernService.ChangeActiveStatus(id).subscribe(
          data => {
            if (data.IsSuccess) {
              this._alertService.success(data.Message);
              this.getList();
            }
          },
          error => {
            this._alertService.error(error.message);
          }
        );
      }
    });
  }

  sortData(event) {
    this.indexModel.OrderBy = event.active;
    this.indexModel.OrderByAsc = event.direction == AppSetting.orderByDscAsc ? AppSetting.orderByAsc : AppSetting.orderByDsc;
    this.indexModel.IsPostBack = true;
    this.getList();
  }

  onPaginateChange(event) {
    this.indexModel.Page = event.pageIndex + 1;
    this.indexModel.PageSize = event.pageSize;
    this.indexModel.IsPostBack = true;
    this.getList();
  }


  SearchByKeyword(searchValue: GlobalFilterModel) {
   
    this.indexModel.Search = searchValue.search;
    this.indexModel.AdvanceSearchModel = { ActiveStatus: searchValue.ActiveStatus }
    this.getList();
  }

  ClearSearch() {
    this.indexModel.Search = null;
    this.indexModel.AdvanceSearchModel = { ActiveStatus: "1" }
    this.getList();
  }

}
