import { GlobalMessagesModel } from 'src/app/shared/model/common-messages.model';
import { CommonService } from 'src/app/shared/service/common.service';
import { UserService } from 'src/app/shared/service/user.service';
import { AuthenticationService } from 'src/app/shared/service/authentication.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertService } from 'src/app/shared/service/alert.service';
import { DDLModel } from 'src/app/shared/model/commonddl.model';
import { DepartmentMasterModel } from './../../../../shared/model/master/department-master.model';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserViewModel } from 'src/app/shared/model/user.model';
import { AppComponent } from 'src/app/app.component';
import { DepartmentMaster } from 'src/app/shared/service/master/department-master.service';
import { AppSetting } from 'src/app/shared/model/appsetting.model';
import { AlertTypeEnum } from 'src/app/shared/enum/fixed-values.enum';

@Component({
  selector: 'app-add-update-department-master-new',
  templateUrl: './add-update-department-master-new.component.html',
  styleUrls: ['./add-update-department-master-new.component.scss']
})
export class AddUpdateDepartmentMasterNewComponent implements OnInit {
  formGroup: FormGroup;
  model: DepartmentMasterModel;
  title: string;
  loginData: UserViewModel;
  dDLList: DDLModel;
  submitBtn = true;
  constructor(private _parentApi: AppComponent,
    private readonly _departmentService: DepartmentMaster,
    private readonly _alertService: AlertService,
    private readonly _router: Router,
    private _route: ActivatedRoute,
    private readonly formBuilder: FormBuilder,
    private readonly _authService: AuthenticationService,
    public readonly _commonService: CommonService
  ) {
    this.model = new DepartmentMasterModel();
    const id = this._route.snapshot.params.id;

    if (id) {
      this.model.DepartmentId = id;
      this.GetById();
      this._parentApi.setPageLayout(
        "Update Department Master :",
        "keyboard_backspace",
        "Back To List",
        "master/department-master"
      );
      this.title = "Update";
    } else {
      this._parentApi.setPageLayout(
        "Add Department Master :",
        "keyboard_backspace",
        "Back To List",
        "master/department-master"
      );
      this.title = "Add";
    }
  }

  ngOnInit() {
    this.FormGroupInit();
    this.GetDDLList();
    this.loginData = this._authService.GetCurrentUserDetail().UserViewModel;
  }

  FormGroupInit() {
    this.formGroup = this.formBuilder.group({
      DepartmentTitle: [null, Validators.required],
      DepartmentTitleHindi: [null, Validators.required],
      DepartmentShortTitle: [null],
      DepartmentShortTitleHindi: [null],
      DepartmentAddress: [null],
      DepartmentAddressHindi: [null],
      Department_DistrictCode: [null],
      Department_AdmDepartmentCode: [null, Validators.required],
      //Department_GroupCode: [null],
      DepartmentCategoryCode: [null, Validators.required],
      DisplayName: [null],
      WebsiteName: [null],
      FacebookURL: [null],
      TwitterURL: [null],
      YoutubeURL: [null],
      InstagramURL: [null],
      fileUploader: [null, null],
      DepartmentDistrictCode: [null, Validators.required],
      //CMOOfficerCode: [null, null],
      DisplayOrder: [null, Validators.required],
      Category_Id: [null],
      Category: [null],
      Organization_Type_id: [null],
      Organization_Type: [null],
    });
  }

  GetDDLList() {

    this._commonService.GetAllDDL(AppSetting.DDlKeyForDepartmentMaster).subscribe(
      data => {

        if (data.IsSuccess) {
          this.dDLList = <DDLModel>data.Data;
        }
      },
      error => {
        this._alertService.error(error.message);
      }
    );
  }

  GetById() {

    this._departmentService.GetById(this.model.DepartmentId).subscribe(
      data => {

        if (data.IsSuccess) {

          this.model = <DepartmentMasterModel>data.Data;

          if (this.model.Department_AdmDepartmentCode) {
            this.model.Department_AdmDepartmentCode = String(
              this.model.Department_AdmDepartmentCode
            );
          }
          if (this.model.Department_DistrictCode) {
            this.model.Department_DistrictCode = String(
              this.model.Department_DistrictCode
            );
          }
          // if (this.model.Department_GroupCode) {
          //   this.model.Department_GroupCode = String(
          //     this.model.Department_GroupCode
          //   );
          // }
          if (this.model.DepartmentCategoryCode) {
            this.model.DepartmentCategoryCode = String(this.model.DepartmentCategoryCode);
          }
          // if (this.model.CMOOfficerCode) {
          //   this.model.CMOOfficerCode = String(
          //     this.model.CMOOfficerCode
          //   );
          // }

          if (this.model.DepartmentDistrictCode) {
            this.model.DepartmentDistrictCode = String(
              this.model.DepartmentDistrictCode
            );
          }
        } else {
          this._alertService.error(data.Message);
        }
      },
      error => {
        this._alertService.error(error.message);
      }
    );
  }

  SaveClick() {
    this.submitBtn = false;
    this.formGroup.markAllAsTouched();
    if (this.formGroup.valid) {
      if (this.model.DepartmentId) {
        this._departmentService.Edit(this.model).subscribe(
          data => {
            if (data.IsSuccess) {
              this._alertService.success(GlobalMessagesModel.saveSuccess);
              this._router.navigate(["master/department-master"]);
            } else {
              this.submitBtn = true;
              this._alertService.error(data.Message);
            }
          },
          error => {
            this.submitBtn = true;
            this._alertService.error(error.message);
          }
        );
      } else {
        this._departmentService.Add(this.model).subscribe(
          data => {
            if (data.IsSuccess) {
              this._alertService.success(GlobalMessagesModel.updateSuccess);
              this._router.navigate(["master/department-master"]);
            } else {
              this.submitBtn = true;
              this._alertService.error(data.Message);
            }
          },
          error => {
            this.submitBtn = true;
            this._alertService.error(error.message);
          }
        );
      }
    } else {
      this.submitBtn = true;
    }
  }



  handleFileInput(event: any) {


    if (event.target.files.length > 0 && event.target.files.item(0).type.match('image/*')) {
      if (event.target.files.item(0).size > AppSetting.FileSizeLimit.IMG) {
        //this.alert(AppSetting.FileSizeLimit.IMGErrorMSG, AlertTypeEnum.Error);
        this._commonService.ScrollingTop();
      } else {
        let reader = new FileReader();
        reader.onload = (event: any) => {
          this.model.LogoUrl = event.target.result;
        }
        this.model.LogoFileName = event.target.files.item(0).name;
        reader.readAsDataURL(event.target.files.item(0));
      }
    } else {
      this.model.LogoFileName = undefined;
      // this.alert('Only *excel accepted!', AlertTypeEnum.Error);
    }
  }


  IsDepartmentMasterExist() {

    this._departmentService.IsDepartmentExist(this.model.DepartmentTitle, this.model.DepartmentCode).subscribe(result => {
      if (result.IsSuccess) {
        let UserName = <boolean>result.Data

        this.model.DepartmentTitle = undefined;
        this._alertService.error(GlobalMessagesModel.Exist);

      }
    }, error => {
      this._commonService.ScrollingTop();
      this._alertService.error(error.message);
    });

  }

  IsDepartmentShortTitleExist() {

    this._departmentService.IsDepartmentShortTitleExist(this.model.DepartmentShortTitle, this.model.DepartmentCode).subscribe(result => {
      if (result.IsSuccess) {
        let resposeData = <boolean>result.Data

        this.model.DepartmentShortTitle = undefined;
        this._alertService.error(GlobalMessagesModel.Exist);

      }
    }, error => {
      this._commonService.ScrollingTop();
      this._alertService.error(error.message);
    });

  }

}
