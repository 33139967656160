import { Component, OnInit } from '@angular/core';
import { MatDialog, MatTableDataSource } from '@angular/material';
import { AppComponent } from 'src/app/app.component';
import { UserManualDialogComponent } from 'src/app/header/user-manual-dialog/user-manual-dialog.component';
import { AppSetting } from 'src/app/shared/model/appsetting.model';
import { GlobalMessagesModel } from 'src/app/shared/model/common-messages.model';
import { DDLModel } from 'src/app/shared/model/commonddl.model';
import { DefaultPermissionViewModel } from 'src/app/shared/model/user-permission.model';
import { AlertService } from 'src/app/shared/service/alert.service';
import { AuthenticationService } from 'src/app/shared/service/authentication.service';
import { CommonService } from 'src/app/shared/service/common.service';
import { UserPermissionService } from 'src/app/shared/service/user-permission.service';
import { DefaultPermissionDialogComponent } from '../default-permission-dialog/default-permission-dialog.component';
import { PagePermissionListDialogComponent } from '../page-permission-list-dialog/page-permission-list-dialog.component';

@Component({
  selector: 'app-user-default-permission',
  templateUrl: './user-default-permission.component.html',
  styleUrls: ['./user-default-permission.component.scss']
})

export class UserDefaultPermissionComponent implements OnInit {
  defaultPagePermissionList: DefaultPermissionViewModel[] = [];
  dataSource: any;
  dropdownList: DDLModel;
  Application: any = '';
  UserType: any;
  IsHeaderAddSelected: boolean = false;
  IsHeaderEditSelected: boolean = false;
  IsHeaderDeleteSelected: boolean = false;
  IsHeaderViewSelected: boolean = false;

  displayedColumns: string[] = [
    "s_no",
    "ApplicationCode",
    "PermissionTypeName",
    "PermissionTitle",
    "add_permission",
    "edit_permission",
    "delete_permission",
    "view_permission",
    "Action",
  ];
  columnsToDisplay: string[] = this.displayedColumns.slice();

  constructor(
    private readonly appComponent: AppComponent,
    public readonly _commonService: CommonService,
    private readonly _alertService: AlertService,
    private readonly _userPermissionService: UserPermissionService,
    private _auth: AuthenticationService,
    private readonly _dialog: MatDialog,
  ) {
    this.appComponent.setPageLayout("User Default Permission :");
  }

  ngOnInit() {
    this.GetDropdownList();
  }

  GetDropdownList() {
    this._commonService.GetAllDDL(AppSetting.DDlKeyForDefaultPermission).subscribe((data) => {
      if (data.IsSuccess) {
        this.dropdownList = <DDLModel>data.Data;
      }
    },
      (error) => {
        this._alertService.error(error.message);
      }
    );
  }

  BindDefaultPagePermissionList() {
    if (this.UserType) {
      this._userPermissionService.GetDefaultPermissions(this.Application, this.UserType).subscribe((data) => {
        if (data.IsSuccess) {
          this.defaultPagePermissionList = <DefaultPermissionViewModel[]>data.Data;
          this.dataSource = new MatTableDataSource<DefaultPermissionViewModel>(this.defaultPagePermissionList);
          this.checkAllSelected();
        }
      }, (error) => {
        this._alertService.error(error.message);
      });
    }
    else {
      this.defaultPagePermissionList = [];
      this.dataSource = [];
    }
  }

  checkAllSelected() {
    this.IsAllSelected("Add");
    this.IsAllSelected("Edit");
    this.IsAllSelected("Delete");
    this.IsAllSelected("View");
  }

  CheckUncheckAll(perType: string) {
    for (var i = 0; i < this.defaultPagePermissionList.length; i++) {
      if (perType == "Add") {
        this.defaultPagePermissionList[
          i
        ].AddPermission = this.IsHeaderAddSelected;
      } else if (perType == "Edit") {
        this.defaultPagePermissionList[
          i
        ].EditPermission = this.IsHeaderEditSelected;
      } else if (perType == "Delete") {
        this.defaultPagePermissionList[
          i
        ].DeletePermission = this.IsHeaderDeleteSelected;
      } else if (perType == "View") {
        this.defaultPagePermissionList[
          i
        ].ViewPermission = this.IsHeaderViewSelected;
      }
    }
  }

  IsAllSelected(perType: string) {
    if (perType == "Add") {
      this.IsHeaderAddSelected = this.defaultPagePermissionList.every(function (
        item: any
      ) {
        return item.AddPermission == true;
      });
    } else if (perType == "Edit") {
      this.IsHeaderEditSelected = this.defaultPagePermissionList.every(
        function (item: any) {
          return item.EditPermission == true;
        }
      );
    } else if (perType == "Delete") {
      this.IsHeaderDeleteSelected = this.defaultPagePermissionList.every(
        function (item: any) {
          return item.DeletePermission == true;
        }
      );
    } else if (perType == "View") {
      this.IsHeaderViewSelected = this.defaultPagePermissionList.every(
        function (item: any) {
          return item.ViewPermission == true;
        }
      );
    }
  }

  SavePermissions() {
    this.defaultPagePermissionList.map(item => item.UserType = this.UserType)
    this._userPermissionService.SaveDefaultPermissions(this.defaultPagePermissionList).subscribe(
      (data) => {
        this._commonService.ScrollingTop();
        if (data) {
          if (data.IsSuccess) {
            this._alertService.success(data.Message);
            if (this.UserType == this._auth.GetCurrentUserDetail().UserViewModel.UserType) {
              this._auth.UpdateLoginUserPermission();
              setTimeout(() => {
                this.appComponent.UpdateMenu();
              }, 100);
            }
          } else {
            this._alertService.error(data.Message);
          }
        } else {
          this._alertService.error(GlobalMessagesModel.updateDefaultPermissionFailed
          );
        }
      },
      (error) => {
        this._commonService.ScrollingTop();
        this._alertService.error(
          GlobalMessagesModel.updateDefaultPermissionError
        );
      }
    );
  }

  openPageManual(value) {
    this._dialog.open(UserManualDialogComponent, {
      width: "90%",
      data: { MasterType: 0, CommunicationType: 0, PageCode: value }
    });
  }

  viewPermission(code: number, title: string) {
    this._dialog.open(PagePermissionListDialogComponent, {
      width: "850px",
      data: { 'code': code, 'title': title },
    });
  }

  printPreview() {
    const data = this.defaultPagePermissionList.filter(f => f.ViewPermission == true || f.EditPermission == true
      || f.AddPermission == true || f.DeletePermission == true);
    this._dialog.open(DefaultPermissionDialogComponent, {
      width: "850px",
      data: data,
    });
  }

}
