import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { GlobalMessagesModel } from 'src/app/shared/model/common-messages.model';
import { AdditionalUserPermissionModel } from 'src/app/shared/model/user-permission.model';
import { UserViewModel } from 'src/app/shared/model/user.model';
import { AlertService } from 'src/app/shared/service/alert.service';
import { AuthenticationService } from 'src/app/shared/service/authentication.service';
import { CommonService } from 'src/app/shared/service/common.service';
import { UserPermissionService } from 'src/app/shared/service/user-permission.service';

@Component({
  selector: 'app-additional-permission-status-dialog',
  templateUrl: './additional-permission-status-dialog.component.html',
  styleUrls: ['./additional-permission-status-dialog.component.scss']
})
export class AdditionalPermissionStatusDialogComponent implements OnInit {

  model: AdditionalUserPermissionModel;
  Postmodel = new AdditionalUserPermissionModel;
  id: number;

  fromGroup: FormGroup;
  pageTitle = "Update Status";
  loginData: UserViewModel;
  constructor(public readonly _commonService: CommonService,
    private readonly _alertService: AlertService,
    private readonly fb: FormBuilder,
    private readonly _permissionService: UserPermissionService,
    public _dialogRef: MatDialogRef<AdditionalPermissionStatusDialogComponent>,
    private readonly _authService: AuthenticationService,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.model = new AdditionalUserPermissionModel();
    this.loginData = this._authService.GetCurrentUserDetail().UserViewModel;
  }

  ngOnInit() {
    
    this.formGroupInit();
    if (this.data != null) {
      this.pageTitle = "Update Addtional Permission";
      this.id = this.data.Id;
      this.GetById();
    }
  }
  formGroupInit() {
    this.fromGroup = this.fb.group({
      ToDate: [null, Validators.required],
      
    });
  }


  GetById() {
    
    this._permissionService.UserAdditionalPermissionGetById(this.id).subscribe(
      data => {
        if (data.IsSuccess) {
          this.model = <AdditionalUserPermissionModel>data.Data;
          this.Postmodel=this.model;
        }
      },
      error => {
        this._alertService.error(error.message);
      }
    );
  }

  SaveClick() {
    
    this.fromGroup.markAllAsTouched();
    if (this.fromGroup.valid) {
    if (!this._commonService.IsNullOrEmpty(this.Postmodel.Id) && this.Postmodel.Id > 0) {
      this.Postmodel.ToDate = this.Postmodel.ToDate;
      this.Postmodel.ModifiedOn = new Date();
      this.Postmodel.ModifiedBy=this.loginData.UserCode;
      this._permissionService.UserAdditionalPermissionAddUpdate(this.Postmodel).subscribe(data => {
        if (data.IsSuccess) {
        
          this._permissionService.UserAdditionalPermissionChangeActiveStatus(this.Postmodel.Id).subscribe(
            data => {
  
              if (data.IsSuccess) {
              
              }
            },
            error => {
             
              this._alertService.error(error.message);
            }
          );
          this._alertService.success(GlobalMessagesModel.updateSuccess);
          this._dialogRef.close({ Id: data.Data, IsSuccess: data.IsSuccess });
        } else {
          this._alertService.error(data.Message);
        }
      }, error => {
        this._alertService.error(error.message);
      });
    }
  }}

  onCloseClick() {
    this._dialogRef.close();
  }

}
