import { UserTypeEnum } from 'src/app/shared/enum/fixed-values.enum';
import { ConfirmationDialogComponent } from 'src/app/confirmation-dialog/confirmation-dialog.component';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/shared/service/common.service';
import { AlertService } from 'src/app/shared/service/alert.service';
import { PermissionModel, IndexModel, SearchModel, GlobalFilterModel } from 'src/app/shared/model/common.model';
import { ColumnHeaderModel } from 'src/app/shared/model/commonddl.model';
import { MatPaginator, MatSort, MatDialog, MatTableDataSource } from '@angular/material';
import { Component, OnInit, ViewChild } from '@angular/core';
import { AppComponent } from 'src/app/app.component';
import { DepartmentMaster } from 'src/app/shared/service/master/department-master.service';
import { GlobalMessagesModel } from 'src/app/shared/model/common-messages.model';
import { AppSetting } from 'src/app/shared/model/appsetting.model';
import { AuthenticationService } from 'src/app/shared/service/authentication.service';
import { DepartmentMasterViewModel, DepartmentReportModel, DepartmentSchemeReportModel } from 'src/app/shared/model/master/department-master.model';

@Component({
  selector: 'app-department-master-new',
  templateUrl: './department-master-new.component.html',
  styleUrls: ['./department-master-new.component.scss']
})
export class DepartmentMasterNewComponent implements OnInit {
  //#region Variable
  loginUserCode: number;
  loginBaseUserType: string;

  userTypeEnum = UserTypeEnum;
  listModel: DepartmentMasterViewModel[];
  dataSource: any;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  id: number;


  displayedColumns: string[] = [
    "index",
    "AdmDepartmentTitle",
    "DepartmentTitle",
  ];
  ViewdisplayedColumns: ColumnHeaderModel[] = [
    { Value: "AdmDepartmentTitle", Text: "Admin Department" },
    { Value: "DepartmentCategoryName", Text: "Department Category" },
    { Value: "DepartmentCode", Text: "Department Code" },
    { Value: "DepartmentTitle", Text: "Department Name in English" },
    { Value: "DistrictTitle", Text: "District" },

  ];

  columnsToDisplay: string[] = [];
  Permission: PermissionModel = this._authService.GetPagePermission("/master/department-master", "department-master/add", "department-master/detail", "department-master/update", "department-master/delete");
  indexModel = this._commonService.modelSetGet(new IndexModel(), false) as IndexModel;
  totalRecords: number;
  //#endregion
  //#region <Report Scheme and department Variable>

  departmentReportList: DepartmentReportModel[];
  departmentSchemeReportList: DepartmentSchemeReportModel[];
  defaultValue: SearchModel;

  //#endregion <Report Scheme and department Variable>
  constructor(private readonly _alertService: AlertService,
    private readonly _parentApi: AppComponent,
    private readonly _dialog: MatDialog,
    public readonly _commonService: CommonService,
    private readonly _router: Router,
    private readonly _departmentService: DepartmentMaster,
    private readonly _authService: AuthenticationService) {

    this.loginUserCode = _authService.UserCode;
    this.loginBaseUserType = _authService.BaseUserType;
    // if (this.loginBaseUserType == this.userTypeEnum.ADM || this.loginBaseUserType == this.userTypeEnum.SADM || this.loginBaseUserType == UserTypeEnum.ADLC) {
    //   this.displayedColumns.push("DepartmentIsActive");
    // }
    if (this.loginBaseUserType != this.userTypeEnum.VNDR && this.loginBaseUserType != this.userTypeEnum.VNDRUSER) {
      this.displayedColumns.push("DepartmentCategoryName");
      this.displayedColumns.push("DepartmentCode");
      this.displayedColumns.push("DepartmentTitleHindi");
      this.displayedColumns.push("DistrictTitle");
      this.displayedColumns.push("ModifiedDate");
      if (this.loginBaseUserType != this.userTypeEnum.DOS && this.loginBaseUserType != this.userTypeEnum.DOSADLC) {
        this.displayedColumns.push("DepartmentIsActive");
      }
      this.displayedColumns.push("Action");
    }

    this.columnsToDisplay = this.displayedColumns.slice();
    this.Permission.AddPageAccess
      ? this._parentApi.setPageLayout("Department Master:", "add", "Create", "master/department-master/add")
      : this._parentApi.setPageLayout("Department Master List :");
    this.GetList();

  }

  ngOnInit() {
    this.GetList();
    // this.getDepartmentReport();
    // this.getDepartmentSchemeReport();
  }

  SearchByKeyword(searchValue: GlobalFilterModel) {

    this.indexModel.Search = searchValue.search;
    this.indexModel.AdvanceSearchModel = { ActiveStatus: searchValue.ActiveStatus }
    this.GetList();
  }

  ClearSearch() {
    this.indexModel = new IndexModel();
    this.indexModel.AdvanceSearchModel = { ActiveStatus: "1" }
    this.GetList();
  }

  GetList() {
    //this.indexModel.OrderByAsc = 1;
    this._departmentService.GetList(this._commonService.modelSetGet(this.indexModel, true), this.loginUserCode).subscribe(
      (data) => {
        if (data.IsSuccess) {
          this.listModel = <DepartmentMasterViewModel[]>data.Data.Data;
          this.dataSource = new MatTableDataSource<DepartmentMasterViewModel>(
            this.listModel
          );
          this.totalRecords = data.Data.TotalRecords;
          if (!this.indexModel.IsPostBack) {
            this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.sort;
          }
        }
      },
      (error) => {
        this._alertService.error(error.message);
      }
    );
  }

  OnStatusClick(id) {
    const dialogRef = this._dialog.open(ConfirmationDialogComponent, { width: "50%", data: GlobalMessagesModel.ConfirmStatusChanged });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this._departmentService.ChangeActiveStatus(id).subscribe(
          (data) => {
            if (data.IsSuccess) {
              this.GetList();
              this._alertService.success(data.Message);
            } else {
              this._alertService.error(data.Message);
            }
          },
          (error) => {
            this._alertService.error(error.message);
          }
        );
      }
    });
  }

  sortData(event) {
    this.indexModel.OrderBy = event.active;
    this.indexModel.OrderByAsc =
      event.direction == AppSetting.orderByDscAsc
        ? AppSetting.orderByAsc
        : AppSetting.orderByDsc;
    this.indexModel.IsPostBack = true;
    this.GetList();
  }

  onPaginateChange(event) {
    this.indexModel.Page = event.pageIndex + 1;
    this.indexModel.PageSize = event.pageSize;
    this.indexModel.IsPostBack = true;
    this.GetList();
  }



  //#region <Report Scheme and department>
  printDepartment(id) {
    // print START
    let printContents, popupWin;
    printContents = document.getElementById(id).outerHTML;
    popupWin = window.open("", "_blank", "top=0,left=0,height=100%,width=auto");
    popupWin.document.open();
    popupWin.document.write(`
<html>
  <head>
    <title>Report</title>
      <style>
      table th,table td{
        border: 1px solid black;
      }
      table{
        border-collapse: collapse;
      }
      span{
        font-weight: bold;
        margin-bottom: 10px;
      }
      </style>
  </head>
<body onload="window.print();window.close()">${printContents}</body>
</html>`);
    popupWin.document.close();

    // print END
  }

  getDepartmentReport() {
    this._departmentService.GetDepartmentReport().subscribe(
      (data) => {
        if (data.IsSuccess) {
          this.departmentReportList = <DepartmentReportModel[]>data.Data;
        }
      },
      (error) => {
        this._alertService.error(error.message);
      }
    );
  }

  getDepartmentSchemeReport() {
    this._departmentService.GetDepartmentSchemeReport().subscribe(
      (data) => {
        if (data.IsSuccess) {
          this.departmentSchemeReportList = <DepartmentSchemeReportModel[]>data.Data;
        }
      },
      (error) => {
        this._alertService.error(error.message);
      }
    );
  }

  PagePrint(printContent) {
    this._commonService.printHTMLContent(printContent, "Department Master");
  }

  //#endregion <Report Scheme and department>

}
