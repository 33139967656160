import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { AppComponent } from 'src/app/app.component';
import { AreaTypeEnum, StateEnum } from 'src/app/shared/enum/fixed-values.enum';
import { AppSetting } from 'src/app/shared/model/appsetting.model';
import { GlobalMessagesModel } from 'src/app/shared/model/common-messages.model';
import { DdlItemModel, DDLModel, FilterDDlModel } from 'src/app/shared/model/commonddl.model';
import { OfficeMasterPostModel, OfficeMasterViewModel } from 'src/app/shared/model/master/office-master.model';
import { UserViewModel } from 'src/app/shared/model/user.model';
import { AlertService } from 'src/app/shared/service/alert.service';
import { AuthenticationService } from 'src/app/shared/service/authentication.service';
import { CommonService } from 'src/app/shared/service/common.service';
import { OfficeMasterService } from 'src/app/shared/service/master/office-master.service';
import { UserService } from 'src/app/shared/service/user.service';
import { isNullOrUndefined } from 'util';

@Component({
  selector: 'app-add-update-office-master',
  templateUrl: './add-update-office-master.component.html',
  styleUrls: ['./add-update-office-master.component.scss']
})

export class AddUpdateOfficeMasterComponent implements OnInit {

  recordId: number = 0;
  fromGroup: FormGroup;
  ddlList: DDLModel = new DDLModel();
  filterDDlModel: FilterDDlModel[] = [];
  loginData: UserViewModel = new UserViewModel();
  model: OfficeMasterPostModel = new OfficeMasterPostModel();
  areaTypeEnum = AreaTypeEnum;
  submitBtn = true;

  constructor(
    private readonly fb: FormBuilder,
    private readonly appComponnet: AppComponent,
    public readonly _commonService: CommonService,
    private readonly _alertService: AlertService,
    private readonly _router: Router,
    private _route: ActivatedRoute,
    private readonly _officeService: OfficeMasterService,
    private readonly _authService: AuthenticationService,
    private readonly _userService: UserService
  ) {
    if (!isNullOrUndefined(this._route.snapshot.params.id)) {
      this.recordId = this._route.snapshot.params.id;
    }
    this.loginData = this._authService.GetCurrentUserDetail().UserViewModel;
    this.getAdminDepartment();

    setTimeout(() => { this.getDepartment(); }, 50);

    this.getDDLList();
    if (this.recordId == 0) {
      this.model.StateCode = String(StateEnum.Rajasthan);
      this.getDistrict();
    }
    this.appComponnet.setPageLayout(this.recordId > 0 ? "Update Office :" : "Add Office :", "keyboard_backspace", "Back To List", "master/office-master");
  }

  ngOnInit() {
    this.formGroupInit();
    if (this.recordId > 0) {
      setTimeout(() => {
        this.getOfficeDetail();
      }, 200);
    }
  }

  getAdminDepartment() {
    this._userService.GetUserAdminDepartmentForUserOfficeCreation(false).subscribe(data => {
      if (data.IsSuccess) {
        this.ddlList.ddlAdminDepartment = <DdlItemModel[]>data.Data;
      }
    }, error => {
      this._alertService.error(error.message);
    });
  }

  getDepartment() {
    let adminDeptCodes = this.model.AdmDepartmentCode ? this.model.AdmDepartmentCode : 0;
    this._userService.GetUserDepartmentForUserOfficeCreation(adminDeptCodes.toString(), false).subscribe(data => {
      if (data.IsSuccess) {
        this.ddlList.ddlDepartment = <DdlItemModel[]>data.Data;
      }
    }, error => {
      this._alertService.error(error.message);
    });
  }

  onStateChange() {
    this.model.DistrictCode = undefined;
    this.ddlList.ddlDistrictbyState = [];
    this.getDistrict();
  }

  getDistrict() {
    if (this.model.StateCode) {
      this._userService.GetUserDistrictForUserOfficeCreation(String(this.model.StateCode), '', false).subscribe(data => {
        if (data.IsSuccess) {
          this.ddlList.ddlDistrictbyState = <DdlItemModel[]>data.Data;
          if (this.model.DistrictCode)
            this.model.DistrictCode = String(this.model.DistrictCode);
          if (this.ddlList.ddlDistrictbyState.length > 0) {
            this.fromGroup.get("DistrictCode").setValidators([Validators.required]);
          }
          else {
            this.fromGroup.get("DistrictCode").setValidators(null);
          }
          this.fromGroup.get("DistrictCode").updateValueAndValidity();
        }
      }, error => {
        this._alertService.error(error.message);
      });
    }
  }

  getDepartmentOffices() {
    this.ddlList.ddlDepartmentOffice = [];
    if (this.model.DepartmentCode > 0) {
      this._userService.GetUserOfficeList(this.model.DepartmentCode).subscribe(data => {
        if (data.IsSuccess) {
          let offices = <DdlItemModel[]>data.Data;
          this.ddlList.ddlDepartmentOffice = offices.filter(x => x.Value != this.recordId.toString());
        }
      }, error => {
        this._alertService.error(error.message);
      });
    }
  }

  getOfficeDetail() {

    this._officeService.Detail(this.recordId).subscribe(
      (data) => {
        if (data.IsSuccess) {
          let item = <OfficeMasterViewModel>data.Data;
          this.model = item;
          this.model.AdmDepartmentCode = isNullOrUndefined(item.AdmDepartmentCode) ? undefined : String(item.AdmDepartmentCode);
          this.model.DepartmentCode = isNullOrUndefined(item.DepartmentCode) ? undefined : String(item.DepartmentCode);
          this.model.StateCode = item.StateCode ? String(item.StateCode) : undefined;
          this.getDistrict();
          this.model.DistrictCode = isNullOrUndefined(item.DistrictCode) ? undefined : String(item.DistrictCode);
          this.model.TehsilCode = isNullOrUndefined(item.TehsilCode) ? undefined : String(item.TehsilCode);
          this.model.BlockCode = isNullOrUndefined(item.BlockCode) ? undefined : String(item.BlockCode);
          this.model.OfficeLevelCode = item.OfficeLevelCode ? String(item.OfficeLevelCode) : undefined;
          this.model.ParentOfficeCode = item.ParentOfficeCode ? String(item.ParentOfficeCode) : undefined;

          this.model.AreaTypeCode = item.AreaTypeCode ? String(item.AreaTypeCode) : undefined;
          this.model.GramPanchayatCode = item.GramPanchayatCode ? String(item.GramPanchayatCode) : undefined;
          this.model.VillageCode = item.VillageCode ? String(item.VillageCode) : undefined;
          this.model.WardNumber = item.WardNumber ? item.WardNumber : undefined;
          this.model.lookuptype1 = isNullOrUndefined(item.lookuptype1) ? undefined : String(item.lookuptype1);
          this.model.lookuptype2 = isNullOrUndefined(item.lookuptype2) ? undefined : String(item.lookuptype2);

        }
      },
      (error) => {
        this._alertService.error(error.message);
      }
    );
  }

  saveClick() {
    this.submitBtn = false;
    this.fromGroup.markAllAsTouched();
    if (this.fromGroup.valid) {
      if (this.recordId > 0) {
        this.model.ModifiedBy = this.loginData.UserCode;
        this.model.CreatedBy = isNullOrUndefined(this.model.CreatedBy) ? this.loginData.UserCode : this.model.CreatedBy;
      } else {
        this.model.CreatedBy = this.loginData.UserCode;
      }

      this._officeService.AddUpdate(this.model).subscribe((data) => {
        if (data) {
          if (data.IsSuccess) {
            this._alertService.success(
              this.recordId > 0 ? GlobalMessagesModel.updateSuccess : GlobalMessagesModel.saveSuccess, true
            );
            this._router.navigate(["master/office-master"]);
          } else {
            this.submitBtn = true;
            this._commonService.ScrollingTop();
            this._alertService.error(GlobalMessagesModel.updateError);
          }
        }
      }, (error) => {
        this.submitBtn = true;
        this._commonService.ScrollingTop();
        this._alertService.error(GlobalMessagesModel.InternalError);
      }
      );
    }
    else {
      this.submitBtn = true;
      this._alertService.error(GlobalMessagesModel.FillMandatoryField);
    }
  }

  getDDLList() {
    this._commonService.GetAllDDL(AppSetting.DDlKeyForOfficeMaster, this.loginData.UserTypeCode, this.loginData.UserCode).subscribe((data) => {
      if (data.IsSuccess) {
        let ddlData = <DDLModel>data.Data;
        this.ddlList.ddlState = ddlData.ddlState;
        this.ddlList.ddlTehsil = ddlData.ddlTehsil;
        this.ddlList.ddlBlock = ddlData.ddlBlock;
        this.ddlList.ddlAreaType = ddlData.ddlAreaType;
        this.ddlList.ddlGramPanchayat = ddlData.ddlGramPanchayat;
        this.ddlList.ddlVillage = ddlData.ddlVillage;
        this.ddlList.ddlOfficeLevel = ddlData.ddlOfficeLevel;
        this.ddlList.ddlOfficelookupType1 = ddlData.ddlOfficelookupType1;
        this.ddlList.ddlOfficelookupType2 = ddlData.ddlOfficelookupType2;

      }
    }, (error) => {
      this._alertService.error(GlobalMessagesModel.InternalError);
    });
  }

  formGroupInit() {
    this.fromGroup = this.fb.group({
      OfficeName: [null, Validators.compose([Validators.required, Validators.maxLength(500)])],
      OfficeNameHindi: [null, Validators.compose([Validators.required, Validators.maxLength(500)])],
      OfficeAddress: [null, Validators.compose([Validators.required, Validators.maxLength(1000)])],
      AdmDepartmentCode: [null, Validators.required],
      DepartmentCode: [null, Validators.required],
      StateCode: [null, null],
      DistrictCode: [null, Validators.required],
      TehsilCode: [null, null],
      BlockCode: [null, null],
      EmailId: [null, Validators.compose([Validators.email])],
      Mobile: [null, Validators.maxLength(10)],
      LandlineNo: [null, Validators.maxLength(11)],
      IPNo: [null, Validators.maxLength(10)],
      SIPFOfficeCode: [null, Validators.maxLength(20)],
      AreaTypeCode: [null],
      WardNumber: [null],
      GramPanchayatCode: [null],
      VillageCode: [null],
      OfficeLevelCode: [null, null],
      ParentOfficeCode: [null, null],
      lookuptype1: [null, null],
      lookuptype2: [null, null],

    });
  }

  numberOnly(value, isCommaOrDash: boolean = false): boolean {
    return this._commonService.NumberOnly(value, isCommaOrDash);
  }

  getFilterdDDL(event, key, filterFrom, ctrlchield = undefined) {
    if (event != undefined) {
      const item = new FilterDDlModel();
      item.FilterFor = key;
      item.Value = event.multiple != undefined && event.multiple == true
        ? JSON.stringify(event.value) : isNullOrUndefined(event) ? "" : "[" + event + "]";
      item.FilterFrom = filterFrom;
      this.filterDDlModel = [];
      this.filterDDlModel.push(item);

      if (item.Value == "") {
        const frmControl = this.fromGroup.get(ctrlchield);
      }

      this._commonService.GetFilterdDDL(this.filterDDlModel, this.loginData.UserTypeCode, this.loginData.UserCode).subscribe((data) => {
        if (data.IsSuccess) {
          if (key == "ddlBlock") {
            this.ddlList.ddlBlock = data.Data.ddlBlock;
            this.model.BlockCode = isNullOrUndefined(this.model.BlockCode) ? undefined : String(this.model.BlockCode);
          }
          else if (key == "ddlTehsil") {
            this.ddlList.ddlTehsil = data.Data.ddlTehsil;
            this.model.TehsilCode = isNullOrUndefined(this.model.TehsilCode) ? undefined : String(this.model.TehsilCode);
          }
          else if (key == "ddlGramPanchayat") {
            this.ddlList.ddlGramPanchayat = data.Data.ddlGramPanchayat;
            this.model.GramPanchayatCode = isNullOrUndefined(this.model.GramPanchayatCode) ? undefined : String(this.model.GramPanchayatCode);
          }
          else if (key == "ddlVillage") {
            this.ddlList.ddlVillage = data.Data.ddlVillage;
            this.model.VillageCode = isNullOrUndefined(this.model.VillageCode) ? undefined : String(this.model.VillageCode);
          }
        }
      }, (error) => {
        this._alertService.error(error.message);
      });
    }

  }

  bindTehsilBlock() {
    this.getFilterdDDL(this.model.DistrictCode, 'ddlTehsil', 'ddlDistrictbyState');
    this.getFilterdDDL(this.model.DistrictCode, 'ddlBlock', 'ddlDistrictbyState');
  }

  bindGrampanchayat() {
    this.getFilterdDDL(this.model.BlockCode, 'ddlGramPanchayat', 'ddlBlock');
  }

  bindVillage() {
    this.getFilterdDDL(this.model.GramPanchayatCode, 'ddlVillage', 'ddlGramPanchayat');
  }

}



