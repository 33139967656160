import { Component, OnInit } from '@angular/core';
import { validateBasis } from '@angular/flex-layout';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { AppComponent } from 'src/app/app.component';
import { AppSetting } from 'src/app/shared/model/appsetting.model';
import { GlobalMessagesModel } from 'src/app/shared/model/common-messages.model';
import { DDLModel } from 'src/app/shared/model/commonddl.model';
import { NotificationSMSTemplatesModel } from 'src/app/shared/model/master/sms-template.model';
import { AlertService } from 'src/app/shared/service/alert.service';
import { CommonService } from 'src/app/shared/service/common.service';
import { NotificationTemplateTypeService } from 'src/app/shared/service/master/notification-template-type.service';
import { SMSTemplateService } from 'src/app/shared/service/master/sms-template.service';

@Component({
  selector: 'app-add-update-sms',
  templateUrl: './add-update-sms.component.html',
  styleUrls: ['./add-update-sms.component.scss']
})
export class AddUpdateSmsComponent implements OnInit {


  model: NotificationSMSTemplatesModel;
  smsForm: FormGroup;
  KeyVariable: string[] = [];//["#VENODRNAME", "#USERNAME", "#DEPARTMENTNAME", "#COMMUNICATION_REFERENCE"]
  constructor(public readonly _commonService: CommonService,
    private readonly _alertService: AlertService,
    private _parentApi: AppComponent,
    private readonly _router: Router,
    private _route: ActivatedRoute,
    private readonly formBuilder: FormBuilder,
    private readonly _smsTemplateService: SMSTemplateService,
    private readonly _notificationTemplateTypeService: NotificationTemplateTypeService,
  ) {
    this._parentApi.setPageLayout(
      'Notification SMS Templates :',
      'keyboard_backspace',
      'Back to List',
      'master/notification-sms-templates'
    );
    this.getNotificationContentKey();
    this.model = new NotificationSMSTemplatesModel();
    this.model.Id = this._route.snapshot.params.id;
    if (this.model.Id) {
      this.GetByID();
    }
  }

  ngOnInit() {
    this.formInit();

  }

  formInit() {
    this.smsForm = this.formBuilder.group({
      TemplateName: [null, Validators.required],
      SMSContent: [null, Validators.required],
      TemplateID: [null],
      SenderID: [null],
      DateOfApproval: [null],
      IsTransaction: [null]
    });

  }
  saveClick() {
    this.smsForm.markAllAsTouched();
    if (this.smsForm.valid) {
      if (this.model.Id) {
        this._smsTemplateService.Update(this.model).subscribe(
          data => {
            if (data.IsSuccess) {
              this._router.navigate(['master/notification-sms-templates']);
              this._alertService.success(data.Message);
            } else {
              this._alertService.error(data.Message);
            }
          },
          error => {
            this._alertService.error(error.message);
          }
        );
      } else {
        this._smsTemplateService.AddNew(this.model).subscribe(
          data => {
            if (data.IsSuccess) {
              this._alertService.success(data.Message);
              this._router.navigate(['master/notification-sms-templates']);
            } else {
              this._alertService.error(data.Message);
            }
          },
          error => {
            this._alertService.error(error.message);
          }
        );
      }
    }
  }

  GetByID() {
    this._smsTemplateService.GetById(this.model.Id).subscribe(
      data => {
        if (data.IsSuccess) {
          this.model = <NotificationSMSTemplatesModel>data.Data;

        } else {
          this._alertService.error(data.Message);
        }
      },
      error => {
        this._alertService.error(error.message);
      }
    );
  }
  getNotificationContentKey() {
    this._notificationTemplateTypeService.GetNotificationContentKey()
      .subscribe(
        data => {
          if (data.IsSuccess) {
            this.KeyVariable = <string[]>data.Data;
          }
        },
        error => {
          this._alertService.error(error.message);
        }
      );
  }

  onAppendText(text) {

    if (this.model.SMSContent == undefined) {
      this.model.SMSContent = "";
    }
    this.model.SMSContent += text + " ";
  }



  IsExistSMSTemplate(Event) {
    this._smsTemplateService.IsExistSMSTemplate(this.model.TemplateName, this.model.Id).subscribe(result => {
      if (result.IsSuccess) {
        let resposeData = <boolean>result.Data

        this.model.TemplateName = undefined;
        this._alertService.error(GlobalMessagesModel.Exist);

      }
    }, error => {
      this._commonService.ScrollingTop();
      this._alertService.error(error.message);
    });

  }

}
