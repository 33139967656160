import { Injectable } from '@angular/core';
import { AppSetting } from '../../model/appsetting.model';
import { UserActivityLogSearchModel } from '../../model/master/user-activity-class.model';
import { AuthenticationService } from '../authentication.service';
import { BaseService } from '../base.service';

@Injectable({
  providedIn: 'root'
})
export class UserActivityLogService {

  constructor(private readonly _baseService: BaseService, private readonly _authService: AuthenticationService) { }

  GetList(model: UserActivityLogSearchModel) {
    model.LoginUserCode = this._authService.UserCode;
    const result = this._baseService.post(AppSetting.UserActivityLogList, model);
    return result;
  }
}
