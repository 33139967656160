import { IndexModel } from "../common.model";


export class UserActivityLogViewModel {
  PageURL: string;
  URLType: string;
  ScreenType: string;
  PermissionTitle: string;
  ActivityLogCode: number;
  UserCode: number;
  PagePermsissionUrlId: number | null;
  ActivityDate: string | null;
  Browser: string;
  IPAddress: string;
  ActionDetail: string;
  SSOID: string;
  UserTypeCode: number | null;
  UserDepartmentName: string;
  UserDepartment: string;
  UserOffice: string;
  UserDistrict: string;
  UserProject: string;
  RecordId: number | null;
  ModelData: string;
  Name: string;
  BaseUserTypeTitle: string;
  MenuTitle: string;
  ApplicationCode: string;
  UserType: string;
  BaseUserType: string;
  MenuCode: number | string;
}

export class UserActivityLogSearchModel {
  constructor() {
    this.indexModel = new IndexModel();
    this.UserType = null
  }
  LoginUserCode: number;
  FromDate: string;
  ToDate: string;
  URLType: string;
  SearchText: string;
  indexModel: IndexModel;
  UserType: number | string;
  UserDepartment: number | string;
  UserDistrict: number | string;
  UserOffice: number | string;
  UserCode: number;
  Vendor: number;
  MenuCode: number | string;
}
