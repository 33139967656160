import { CommonService } from 'src/app/shared/service/common.service';
import { AppComponent } from './../../../app.component';
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PreviousCommunicationViewModel } from 'src/app/shared/model/communication-transaction.model';
import { CommunicationService } from 'src/app/shared/service/communication.service';

@Component({
  selector: 'app-previous-communication-list',
  templateUrl: './previous-communication-list.component.html',
  styleUrls: ['./previous-communication-list.component.scss']
})
export class PreviousCommunicationListComponent implements OnInit {
  @Input() CommunicationCode: number;
  @Input() ShowPrintButton: boolean = true;

  @Output() OnDataGet = new EventEmitter<number>(true);
  communicationTypeEnum: string;
  model: PreviousCommunicationViewModel[] = [];

  constructor(
    private readonly _communicationService: CommunicationService,
    private _route: ActivatedRoute,
    private readonly appComponent: AppComponent,
    private readonly _commonService: CommonService) {
    if (this._route.snapshot.params.id) {
      this.CommunicationCode = this._route.snapshot.params.id;
    }
    if ((this._route.snapshot.params.typeEnum)) {
      this.communicationTypeEnum = this._route.snapshot.params.typeEnum;
      this.appComponent.setPageLayout(" Previous Communication History :", "keyboard_backspace", "Back To List", "/communications-list/" + this.communicationTypeEnum);
    }
  }

  ngOnInit() {
    this.getPreviouseCommunicationList();
  }

  getPreviouseCommunicationList() {
    this.model = [];
    this._communicationService.GetPreviouseCommunicationList(this.CommunicationCode).subscribe(result => {
      if (result.IsSuccess) {
        this.model = result.Data as PreviousCommunicationViewModel[];
        this.OnDataGet.emit(this.model ? this.model.length : 0);
      }
      else {
        this.OnDataGet.emit(0);
      }
    }, error => { })
  }

  PagePrint(printContent) {
    this._commonService.printHTMLContent(printContent, "Previous Communication History");
  }

}
