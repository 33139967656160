import { SelectionModel } from '@angular/cdk/collections';
import { Component, Input, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { DDLModel } from 'src/app/shared/model/commonddl.model';
import { VendorListRequestModel } from 'src/app/shared/model/preferred-list/vendor-list-request.model';
import { VendorListModel } from 'src/app/shared/model/preferred-list/vendor-list.model';
import { VendorListService } from 'src/app/shared/service/Preferred-List/vendor-list.service';
import { AlertService } from 'src/app/shared/service/alert.service';
import { CommonService } from 'src/app/shared/service/common.service';
import { UserService } from 'src/app/shared/service/user.service';
import { GlobalMessagesModel } from 'src/app/shared/model/common-messages.model';
import { VendorAddRequestModel } from 'src/app/shared/model/preferred-list/vendor-add-request.model';

@Component({
  selector: 'app-vendor-list',
  templateUrl: './vendor-list.component.html',
  styleUrls: ['./vendor-list.component.scss'],
  providers: [VendorListService]
})
export class VendorListComponent implements OnInit {
  public model: VendorAddRequestModel = new VendorAddRequestModel();
  public viewModel = {
    displayedColumns: ['selection', 'ssoid', 'name', 'vendorType', 'organizationName', 'organizationAddress', 'email', 'contact'],
    filterModel: new VendorListRequestModel(),
    ddlList: new DDLModel(),
    vendors: [] as VendorListModel[],

  }
  public selectOfficers = new SelectionModel<VendorListModel>(true, []);
  @Input() selectedTab: any;

  constructor(
    public readonly _commonService: CommonService,
    public readonly _alertService: AlertService,
    private readonly _userService: UserService,
    private readonly _vendorService: VendorListService,
    public _dialogRef: MatDialogRef<VendorListComponent>
  ) { }

  ngOnInit() {
  }

  //#region Events
  public onSearch() {
    let requestModel = this.getRequestModel();

    this.getList(requestModel);
  }

  public onClearSearch() {
    this.viewModel.filterModel = new VendorListRequestModel();
    this.viewModel.vendors = [];
  }

  public onSubmit() {
    this.model.VendorIds = this.selectOfficers.selected.map(x => x.VendorCode);

    this._vendorService.AddAllVendor(this.model).subscribe(
      response => {
        if (response.IsSuccess == true) {
          this._alertService.success(GlobalMessagesModel.saveSuccess, true);
          this._dialogRef.close();
        }
      },
      error => {
        this._alertService.error(GlobalMessagesModel.updateError);
      }
    )
  }
  //#endregion Events

  //#region Private Methods
  private getRequestModel(): VendorListRequestModel {
    let output = new VendorListRequestModel();

    output.SSOId = this.viewModel.filterModel.SSOId;
    output.SearchTxt = this.viewModel.filterModel.SearchTxt;
    output.CompanyName = this.viewModel.filterModel.CompanyName;

    return output;
  }


  private getList(model: VendorListRequestModel) {
    this._vendorService.GetList(model).subscribe((data) => {
      if (data.IsSuccess) {
        this.viewModel.vendors = <VendorListModel[]>data.Data;
      }
    }, (error) => {
      this._alertService.error(error.message);
    });
  }

  //#endregion Private Methods
}
