import { Injectable } from '@angular/core';
import { BaseService } from '../base.service';
import { IndexModel } from '../../model/common.model';
import { UserManualModel } from '../../model/master/user-manual.model';
import { AppSetting } from '../../model/appsetting.model';

@Injectable({
  providedIn: 'root',
})
export class UserManualService {
  // tslint:disable-next-line: variable-name
  constructor(private readonly _baseService: BaseService) { }
  GetList(model: IndexModel) {
    const result = this._baseService.post(AppSetting.UserManualListUrl, model);
    return result;
  }

  Add(model: UserManualModel) {
    const result = this._baseService.post(AppSetting.UserManualAddUrl, model);
    return result;
  }

  GetById(id, isBase64) {
    const result = this._baseService.get(AppSetting.UserManualUrlById + id + "?isBase64=" + isBase64);
    return result;
  }


  Edit(model: UserManualModel) {
    const result = this._baseService.post(AppSetting.UserManualEditUrl, model);
    return result;
  }

  Delete(Id) {
    const result = this._baseService.get(
      AppSetting.UserManualDeleteUrl + Id,
      null
    );
    return result;
  }
  GetHelpDocument(id) {
    const result = this._baseService.get(AppSetting.GetHelpDocumentUrlById + id, null);
    return result;
  }
}
