import { Injectable } from "@angular/core";
import { AppSetting } from "../../model/appsetting.model";
import { LogReportModel } from "../../model/report/log-report.model";
import { BaseService } from "../base.service";
import { IndexModel } from "../../model/common.model";
@Injectable({
    providedIn: "root",
  })
  
  export class LogReportService {
    constructor(private readonly _baseService: BaseService) {}
  
    GetLogReportData(model: IndexModel) {
      return this._baseService.post(AppSetting.LogReportDataUrl, model);
    }
  }
  