import { CommunicationTypeFieldsMasterService } from "src/app/shared/service/master/communication-type-fields-master.service";
import { CommunicationTypeAttachmentViewModel } from './../../../../shared/model/master/communication-type.model';
import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { AppComponent } from 'src/app/app.component';
import { AlertService } from 'src/app/shared/service/alert.service';
import { CommunicationTypeModel } from 'src/app/shared/model/master/communication-type.model';
import { CommunicationTypeService } from 'src/app/shared/service/master/communication-type.service';
import { UserTypeEnum } from 'src/app/shared/enum/fixed-values.enum';
import { AuthenticationService } from 'src/app/shared/service/authentication.service';
import { IndexModel } from "src/app/shared/model/common.model";
import { CommunicationTypeDefaultFieldsMasterModel, CommunicationTypeFieldsMasterModel } from "src/app/shared/model/master/communication-type-fields-master.model";
import { MatTableDataSource } from "@angular/material";
import { CommonService } from "src/app/shared/service/common.service";

@Component({
  selector: 'app-communication-type-detail',
  templateUrl: './communication-type-detail.component.html',
  styleUrls: ['./communication-type-detail.component.scss']
})

export class CommunicationTypeDetailComponent implements OnInit {
  model: CommunicationTypeModel;
  RecordId: number;
  IsStorage: boolean;
  attachmentTypeModel: CommunicationTypeAttachmentViewModel[] = [];
  dynamicFieldViewModel: CommunicationTypeFieldsMasterModel[] = [];
  defaultFieldViewModel: CommunicationTypeDefaultFieldsMasterModel[] = [];

  constructor(
    private readonly _appComponent: AppComponent,
    private readonly _router: Router,
    private _route: ActivatedRoute,
    private readonly _communicationTypeService: CommunicationTypeService,
    private readonly _alertService: AlertService,
    private readonly _authService: AuthenticationService,
    private readonly _communicationTypeFieldsMasterService: CommunicationTypeFieldsMasterService,
    public _commonService: CommonService,

  ) {
    this.RecordId = this._route.snapshot.params.id;
    this.model = new CommunicationTypeModel();
    this._appComponent.setPageLayout(
      "Communication Type Detail :",
      "keyboard_backspace",
      "Back To List",
      "master/communication-type"
    );
  }

  ngOnInit() {
    if (this._authService.BaseUserType == UserTypeEnum.VNDR ||   this._authService.BaseUserType == UserTypeEnum.VNDRUSER ) {
      this._router.navigate(["dashboard"]);
    }
    this.getDetail();

  }

  getDetail() {
    this._communicationTypeService.Detail(this.RecordId).subscribe(
      data => {
        if (data.IsSuccess) {
          this.model = <CommunicationTypeModel>data.Data;
          this.GetAttachmentTypeList();
          this.getDefaultFieldList();
          this.getDynamicFieldList();
        }
      },
      error => {
        this._alertService.error(error.message);
      }
    );
  }

  GetAttachmentTypeList() {
    
    this._communicationTypeService.GetAttachementTypeListByCommunicationType(this.RecordId).subscribe(response => {
      if (response.IsSuccess) {
        
        this.attachmentTypeModel = <CommunicationTypeAttachmentViewModel[]>response.Data;
        this.attachmentTypeModel = this.attachmentTypeModel.filter(i => i.IsSelected == true);
      }

    });
  }


  getDefaultFieldList() {
    
    this._communicationTypeFieldsMasterService.GetDefaultFieldsForCommunication(0, this.model.EnumValue).subscribe(
      data => {

        if (data.IsSuccess) {
          
          this.defaultFieldViewModel = <CommunicationTypeDefaultFieldsMasterModel[]>data.Data;
          //this.defaultFieldViewModel = this.defaultFieldViewModel;

          this.defaultFieldViewModel = this.defaultFieldViewModel.filter(x => x.IsDefaultVisibleOnScreen == false);

        } else {
          this._alertService.error(data.Message);
        }
      },
      error => {
        this._alertService.error(error.message);
      }
    );
  }



  getDynamicFieldList() {
    let indexModel = new IndexModel();
    indexModel.PageSize = 101;
    this._communicationTypeFieldsMasterService.GetList(this.model.EnumValue, indexModel).subscribe(
      data => {
        
        if (data.IsSuccess) {

          this.dynamicFieldViewModel = <CommunicationTypeFieldsMasterModel[]>data.Data.Data;
        } else {
          this._alertService.error(data.Message);
        }
      },
      error => {
        this._alertService.error(error.message);
      }
    );
  }


  ngOnDestroy() {
    // this.IsStorage = true;
    // sessionStorage.setItem("IsCommunicationTypeStorage", JSON.stringify(this.IsStorage));
  }

  CallPrint(printContent) {
    this._commonService.printHTMLContent(printContent, this.model.CommunicationType + " Communication Type Detail");
  }

}
