import { Component, OnInit } from "@angular/core";
import { AppComponent } from "src/app/app.component";
import { CommonService } from 'src/app/shared/service/common.service';
import { DashboardService } from 'src/app/shared/service/dashboard.service';
import { AlertService } from 'src/app/shared/service/alert.service';
import { AuthenticationService } from 'src/app/shared/service/authentication.service';
import { UserTypeEnum, StatusEnum, CommunicationTypeEnum } from 'src/app/shared/enum/fixed-values.enum';
import { CommunicationModel } from 'src/app/shared/model/communication.model';
import { DatePipe } from '@angular/common';
import { AppSetting } from 'src/app/shared/model/appsetting.model';
import { DDLModel } from 'src/app/shared/model/commonddl.model';
import { isNullOrUndefined } from 'util';
import { ComplaintCommunicationModel } from 'src/app/shared/model/complaint-communication.model';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { DashBoardFilterModel } from 'src/app/shared/model/dashboard-filter.model';
import { UserViewModel } from 'src/app/shared/model/user.model';
import { on } from 'process';

@Component({
  selector: 'app-invoice',
  templateUrl: './invoice-report.component.html',
  styleUrls: ['./invoice-report.component.scss']
})

export class InvoiceReportComponent implements OnInit {
  // loginBaseUserType: string;
  // totalAccepted: number = 0;
  // acceptedCommunicationList: CommunicationModel[] = [];
  // totalRejected: number = 0;
  // rejectedCommunicationList: CommunicationModel[] = [];
  // totalSent: number = 0;
  // sentCommunicationList: CommunicationModel[] = [];
  // totalReturn: number = 0;
  // returnCommunicationList: CommunicationModel[] = [];
  // IsUser_VNDR: boolean = false;
  // IsUser_DREC: boolean = false;
  // IsUser_DOS: boolean = false;
  // IsUser_HOD: boolean = false;
  // IsUser_ADM: boolean = false;

  // IsUser_VNDRUSER: boolean = false;

  // ddlList: DDLModel;
  // model: ComplaintCommunicationModel;
  // commTypeEMDReturn: string = "EMD Return";
  // frmGrp: FormGroup;
  // comTypeEnum = CommunicationTypeEnum;
  // isVndrDisabled: boolean = true;
  // isDeptDisabled: boolean = true;
  // isDistDisabled: boolean = true;
  // isOfficeDisabled: boolean = true;
  // loginUserDetail: UserViewModel;
  // filterModel: DashBoardFilterModel = new DashBoardFilterModel();

  // constructor(private readonly _appComponet: AppComponent,
  //   public readonly _commonService: CommonService,
  //   private readonly _alertService: AlertService,
  //   private readonly _authService: AuthenticationService,
  //   private readonly _dashboardService: DashboardService,
  //   private readonly fb: FormBuilder) {
  //   this._appComponet.setPageLayout(" Dashboard (Total Communication:" + (0).toString() + ")", "", "", "", false, true);
  //   this.loginBaseUserType = _authService.BaseUserType;
  //   this.loginUserDetail = this._authService.GetCurrentUserDetail().UserViewModel;

  //   this.GetDDLList();

  //   if (this.loginBaseUserType == UserTypeEnum.VNDR) { this.IsUser_VNDR = true; }
  //   else if (this.loginBaseUserType == UserTypeEnum.DREC) { this.IsUser_DREC = true; }
  //   else if (this.loginBaseUserType == UserTypeEnum.DOS) { this.IsUser_DOS = true; }
  //   else if (this.loginBaseUserType == UserTypeEnum.HOD) { this.IsUser_HOD = true; }
  //   else if (this.loginBaseUserType == UserTypeEnum.ADM) { this.IsUser_ADM = true; }
  //   else if (this.loginBaseUserType == UserTypeEnum.VNDRUSER) { this.IsUser_VNDRUSER = true; }
  // }

   ngOnInit() {

  //   if (this.IsUser_VNDR || this.IsUser_DREC || this.IsUser_DOS || this.IsUser_HOD || this.IsUser_ADM || this.IsUser_VNDRUSER) {
  //     this.bindSentCommunications();
  //     this.bindAcceptedCommunications();
  //     this.bindRejectedCommunications();
  //     this.bindReturnCommunications();
  //   }
  //   if (this.IsUser_VNDR) {
  //     this.isVndrDisabled = true;
  //     this.isDeptDisabled = false;
  //     this.isDistDisabled = false;
  //     this.isOfficeDisabled = false;
  //   }
  //   else if (this.IsUser_DREC) {
  //     this.isVndrDisabled = false;
  //     this.isDeptDisabled = true;
  //     this.isDistDisabled = false;
  //     this.isOfficeDisabled = false;
  //   }
  //   else if (this.IsUser_DOS) {
  //     this.isVndrDisabled = false;
  //     this.isDeptDisabled = true;
  //     this.isDistDisabled = true;
  //     this.isOfficeDisabled = true;
  //   }
  //   else if (this.IsUser_VNDRUSER) {
  //     this.isVndrDisabled = true;
  //     this.isDeptDisabled = true;
  //     this.isDistDisabled = true;
  //     this.isOfficeDisabled = true;
  //   }
  //   else {
  //     this.isVndrDisabled = false;
  //     this.isDeptDisabled = false;
  //     this.isDistDisabled = false;
  //     this.isOfficeDisabled = false;
  //   }
  // }

  // onSearch() {
  //   this.bindAcceptedCommunications();
  //   this.bindRejectedCommunications();
  //   this.bindSentCommunications();
  //   this.bindReturnCommunications();
  // }

  // onClearClick() {
  //   this.filterModel = new DashBoardFilterModel();
  //   this.onSearch();
  //   this.GetDDLList();
  // }

  // bindAcceptedCommunications() {
  //   this.filterModel.Status = StatusEnum.ACCEPT;
  //   this.filterModel.LoginUserCode = this._authService.UserCode;
  //   this._dashboardService.GetCommunicationByStatus(this.filterModel).subscribe(
  //     data => {
  //       if (data.IsSuccess) {
  //         this.acceptedCommunicationList = <CommunicationModel[]>data.Data;
  //         this.totalAccepted = this.acceptedCommunicationList.length > 0 ? this.acceptedCommunicationList.length : 0;
  //         this._appComponet.setPageLayout("Dashboard (Total Communication:" + (this.totalSent + this.totalAccepted + this.totalRejected + this.totalReturn).toString() + ")", "", "", "", false, true);
  //       }
  //       else {
  //         this._alertService.error(data.Message);
  //       }
  //     },
  //     error => {
  //       this._alertService.error(error.message);
  //     }
  //   );
  // }

  // bindRejectedCommunications() {
  //   this.filterModel.Status = StatusEnum.REJECT;
  //   this.filterModel.LoginUserCode = this._authService.UserCode;

  //   this._dashboardService.GetCommunicationByStatus(this.filterModel).subscribe(
  //     data => {
  //       if (data.IsSuccess) {
  //         this.rejectedCommunicationList = <CommunicationModel[]>data.Data;
  //         this.totalRejected = this.rejectedCommunicationList.length > 0 ? this.rejectedCommunicationList.length : 0;
  //         this._appComponet.setPageLayout("Dashboard (Total Communication:" + (this.totalSent + this.totalAccepted + this.totalRejected + this.totalReturn).toString() + ")", "", "", "", false, true);

  //       }
  //       else {
  //         this._alertService.error(data.Message);
  //       }
  //     },
  //     error => {
  //       this._alertService.error(error.message);
  //     }
  //   );
  // }

  // bindSentCommunications() {
  //   this.filterModel.Status = StatusEnum.SENT;
  //   this.filterModel.LoginUserCode = this._authService.UserCode;

  //   this._dashboardService.GetCommunicationByStatus(this.filterModel).subscribe(
  //     data => {
  //       if (data.IsSuccess) {
  //         this.sentCommunicationList = <CommunicationModel[]>data.Data;
  //         this.totalSent = this.sentCommunicationList.length > 0 ? this.sentCommunicationList.length : 0;
  //         this._appComponet.setPageLayout("Dashboard (Total Communication:" + (this.totalSent + this.totalAccepted + this.totalRejected + this.totalReturn).toString() + ")", "", "", "", false, true);
  //       }
  //       else {
  //         this._alertService.error(data.Message);
  //       }
  //     },
  //     error => {
  //       this._alertService.error(error.message);
  //     }
  //   );
  // }

  // bindReturnCommunications() {
  //   this.filterModel.Status = StatusEnum.RETURN;
  //   this.filterModel.LoginUserCode = this._authService.UserCode;

  //   this._dashboardService.GetCommunicationByStatus(this.filterModel).subscribe(
  //     data => {
  //       if (data.IsSuccess) {
  //         this.returnCommunicationList = <CommunicationModel[]>data.Data;
  //         this.totalReturn = this.returnCommunicationList.length > 0 ? this.returnCommunicationList.length : 0;
  //         this._appComponet.setPageLayout("Dashboard (Total Communication:" + (this.totalSent + this.totalAccepted + this.totalRejected + this.totalReturn).toString() + ")", "", "", "", false, true);

  //       }
  //       else {
  //         this._alertService.error(data.Message);
  //       }
  //     },
  //     error => {
  //       this._alertService.error(error.message);
  //     }
  //   );
  }

  // formGroupInit() {
  //   this.frmGrp = this.fb.group({
  //     DepartmentCode: [null, null],
  //     OfficeCode: [this.model.OfficeCode, null]
  //   });
  // }

  // //#region Filter
  // GetDDLList() {
  //   this._commonService
  //     .GetAllDDL(AppSetting.FilterDropdownKeys)
  //     .subscribe(
  //       (data) => {
  //         if (data.IsSuccess) {
  //           
  //           this.ddlList = <DDLModel>data.Data;
  //           this.GetProjectDDLList();
  //           this.GetDistrictDDLList();//Added on 07/09/2020

  //           this.filterModel.DepartmentCode = this.loginUserDetail.DepartmentCodes;
  //           if (!isNullOrUndefined(this.loginUserDetail.VendorCode)) {
  //             this.filterModel.VendorCode = this.loginUserDetail.VendorCode.toString();
  //           }
  //           else if (!isNullOrUndefined(this.loginUserDetail.VendorUser_VendorCode)) {
  //             this.filterModel.VendorCode = this.loginUserDetail.VendorUser_VendorCode.toString();
  //           }
  //           this.GetOffice();
  //         }
  //       },
  //       (error) => {
  //         this._alertService.error(error.message);
  //       }
  //     );
  // }

  // GetProjectDDLList() {
  //   
  //   var userDept = (this.loginUserDetail.DepartmentCodes == null) ? 0 : this.loginUserDetail.DepartmentCodes;
  //   this._commonService.GetDDL(AppSetting.ddlProject, userDept, this.loginUserDetail.UserCode).subscribe(
  //     data => {
  //       if (data.IsSuccess) {
  //         var ddl = <DDLModel>data.Data;

  //         this.ddlList.ddlProject = ddl.ddlProject;

  //         //Added on 07/09/2020
  //         if (this.ddlList.ddlProject != null && this.ddlList.ddlProject.length == 1) {
  //           this.filterModel.ProjectCode = ddl.ddlProject[0].Value;
  //         }
  //       }
  //     },
  //     error => {
  //       this._alertService.error(error.message);
  //     }
  //   );
  // }
  // GetOffice() {
  //   this.GetProjectListByDepartmentWise();
  //   if (!isNullOrUndefined(this.filterModel.DepartmentCode)) {
  //     this.filterModel.DistrictCode =
  //       this.filterModel.DistrictCode == undefined ? 0 : this.filterModel.DistrictCode;
  //     this._commonService
  //       .GetDDL(
  //         AppSetting.ddlDepartmentOffice,
  //         this.filterModel.DepartmentCode,
  //         this.filterModel.DistrictCode
  //       )
  //       .subscribe(
  //         (data) => {
  //           if (data.IsSuccess) {
  //             var ddl = <DDLModel>data.Data;
  //             this.ddlList.ddlDepartmentOffice = ddl.ddlDepartmentOffice;
  //           }
  //         },
  //         (error) => {
  //           this._alertService.error(error.message);
  //         }
  //       );

  //   } else {
  //     this.ddlList.ddlDepartmentOffice = null;
  //   }
  // }

  // GetProjectListByDepartmentWise() {
  //   
  //   var userDept = (this.loginUserDetail.DepartmentCodes == null) ? 0 : this.loginUserDetail.DepartmentCodes;

  //   if (!isNullOrUndefined(this.filterModel.DepartmentCode)) {

  //     this._commonService.GetDDL(AppSetting.ddlProject, this.filterModel.DepartmentCode, this.loginUserDetail.UserCode).subscribe(
  //       data => {
  //         if (data.IsSuccess) {
  //           var ddl = <DDLModel>data.Data;
  //           this.ddlList.ddlProject = ddl.ddlProject;
  //         }
  //       },
  //       error => {
  //         this._alertService.error(error.message);
  //       }
  //     );
  //   }
  //   else {
  //     this.GetProjectDDLList();
  //   }

  // }
  // //#endregion

  // //Added on 07/09/2020
  // GetDistrictDDLList() {
  //   
  //   this._commonService.GetDDL(AppSetting.ddlDistrict, 0, this.loginUserDetail.UserCode).subscribe(
  //     data => {
  //       if (data.IsSuccess) {
  //         var ddl = <DDLModel>data.Data;
  //         this.ddlList.ddlDistrict = ddl.ddlDistrict;

  //         if (this.ddlList.ddlDistrict != null && this.ddlList.ddlDistrict.length == 1) {
  //           this.filterModel.DistrictCode = ddl.ddlDistrict[0].Value;
  //         }
  //       }
  //     },
  //     error => {
  //       this._alertService.error(error.message);
  //     }
  //   );
  // }


}