import { ProjectGroupSearchModel } from './../../model/master/project-group.model';
import { Injectable } from "@angular/core";
import { AppSetting } from "../../model/appsetting.model";
import { IndexModel } from "../../model/common.model";
import { Dictionary } from "../../model/dictionary.model";
import { ProjectGroupModel } from "../../model/master/project-group.model";
import { BaseService } from "../base.service";

@Injectable({ providedIn: 'root' })

export class ProjectGroupService {

    constructor(private readonly _baseService: BaseService,) { }

    GetList(model: IndexModel) {
        return this._baseService.post(AppSetting.ProjectGroupListUrl, model);
    }

    AddUpdate(model: ProjectGroupModel) {
        return this._baseService.post(AppSetting.ProjectGroupAddUpdateUrl, model);
    }

    Detail(id: number) {
        return this._baseService.get(AppSetting.ProjectGroupDetailUrl + id);
    }

    ChangeDeleteStatus(id: number) {
        return this._baseService.get(AppSetting.ProjectGroupDeleteStatusChangeUrl + id);
    }

    ChangeActiveStatus(id: number) {
        return this._baseService.get(AppSetting.ProjectGroupActiveStatusChangeUrl + id);
    }

    IsProjectGroupExist(groupTitle: string, id: number,officeCode: number) {
        const param = new Dictionary<any>();
        param.Add("groupTitle", groupTitle);
        param.Add("id", id);
        param.Add("officeCode",officeCode)
        return this._baseService.get(AppSetting.IsProjectGroupExistUrl, param);
    }

    GetProjectGroupPDFDownload(model: IndexModel) {
        return this._baseService.post(AppSetting.ProjectGroupPDFDownloadUrl, model);
    }

    GetProjectGroupBySearch(projectGroupSearchmodel: ProjectGroupSearchModel) {
        return this._baseService.post(AppSetting.ProjectGroupListySearchUrl, projectGroupSearchmodel);
    }
}