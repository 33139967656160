import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { LoaderService } from '../shared/service/loader.service';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent {
  color = 'primary';
  mode = 'indeterminate';
  value = 50;
  isLoading: boolean;
  isProcess: boolean;
  constructor(private loaderService: LoaderService, private cdRef: ChangeDetectorRef) {
  }


  ngAfterViewChecked(): void {

    let serv = this.loaderService.isLoading.subscribe((isLoading) => {
      this.isLoading = isLoading;
      this.cdRef.detectChanges();
    });

    let servProcess = this.loaderService.isProcess.subscribe((isProcess) => {
      servProcess.unsubscribe();
      this.isProcess = isProcess;
      this.cdRef.detectChanges();
    });
  }

}
