

export class AddVendorCommunicationPermissionRequestModel {

    constructor(vendorCode: number = 0, departmentCode: number = 0){
        this.vendorCode = vendorCode;
        this.departmentCode = departmentCode;
    }

    
    public vendorCode: number;
    public departmentCode: number;
}