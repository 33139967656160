import { BaseService } from './base.service';
import { AppSetting } from '../model/appsetting.model';
import { IndexModel } from '../model/common.model';
import { Injectable } from '@angular/core';
import { GlobalFilterSearchModel } from '../model/Global-communication-search.model';

@Injectable({
    providedIn: 'root'
})
export class InboxService {
    constructor(private readonly _baseService: BaseService) { }

    GetList(workOrderCode: number, loginUserCode: number, search: string, model: IndexModel) {
        return this._baseService.post(AppSetting.InboxListUrl + "?workOrderCode=" + workOrderCode + "&loginUserCode=" + loginUserCode + "&search=" + search, model);
    }

    GetListbySearchFilter(model: GlobalFilterSearchModel) {
        return this._baseService.post(AppSetting.InboxListUrl, model);
    }


    Detail(id: number) {
        return this._baseService.get(AppSetting.InboxDetailUrl + id);
    }

    ChangeDeleteStatus(id: number) {
        return this._baseService.get(AppSetting.InboxDeleteStatusChangeUrl + id);
    }

    GetCounter() {
        return this._baseService.get(AppSetting.InboxCounterUrl);
    }  
}