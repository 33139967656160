import { AddUpdateMenuMasterComponent } from './../add-update-menu-master/add-update-menu-master.component';
import { PermissionMasterService } from './../../../../shared/service/master/permission-master.service';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { AppComponent } from 'src/app/app.component';
import { AppSetting } from 'src/app/shared/model/appsetting.model';
import { GlobalMessagesModel } from 'src/app/shared/model/common-messages.model';
import { DDLModel, FilterDDlModel } from 'src/app/shared/model/commonddl.model';
import { PermissionMasterModel, UrlViewModel } from 'src/app/shared/model/master/permission-master.model';
import { AlertService } from 'src/app/shared/service/alert.service';
import { CommonService } from 'src/app/shared/service/common.service';

@Component({
  selector: 'app-add-update-permission-master',
  templateUrl: './add-update-permission-master.component.html',
  styleUrls: ['./add-update-permission-master.component.scss']
})

export class AddUpdatePermissionMasterComponent implements OnInit {

  fromGroup: FormGroup =null;
  model = new PermissionMasterModel();
  dDLList = new DDLModel();
  filterDDlModel: any[];
  UrlItemPostionExist: number = 0;
  permissionCode = 0;
  submitBtn = true;

  constructor(private readonly appComponnet: AppComponent,
    public readonly fb: FormBuilder,
    public readonly _commonService: CommonService,
    private readonly _alertService: AlertService,
    private readonly _permissionMasterService: PermissionMasterService,
    private readonly _activeRoute: ActivatedRoute,
    private readonly _route: Router,
    private _dialog: MatDialog) {
    this.formGroupInit();

    this.appComponnet.setPageLayout("Add Pages :", "keyboard_backspace", "Back To List", "master/permission-master");

    this.permissionCode = _commonService.IsNullOrEmpty(_activeRoute.snapshot.params.PermissionCode) ? 0 : _activeRoute.snapshot.params.PermissionCode;
    this.model.UrlList.push(new UrlViewModel());
    this.model.HasExtraAuthentication = false;
  }

  ngOnInit() {


    this.getDDLList();



    if (this.permissionCode > 0) {
      this.getDetail();
    }
  }

  getDDLList() {
    this._commonService.GetAllDDL(AppSetting.DDlKeyForPermissionMaster).subscribe(
      data => {
        if (data.IsSuccess) {
          this.dDLList = <DDLModel>data.Data;
        }
      },
      error => {
        this._alertService.error(error.message);
      }
    );
  }

  formGroupInit() {
    this.fromGroup = this.fb.group({
      ApplicationCode: [null, Validators.required],
      PermissionTypeCode: [null, [Validators.required]],
      MenuCode: [null],
      PermissionTitle: [null, Validators.compose([Validators.required, Validators.maxLength(100)])],
      HasExtraAuthentication: [null],
      Password: [null],
      PermissionUrl_1: [null, Validators.compose([Validators.required, Validators.maxLength(100)])],
      PermissionType_1: [null, Validators.compose([Validators.required])],
    });
  }

  getFilterdDDL() {
    let menuValue = this._commonService.IsNullOrEmpty(this.model.MenuCode) ? undefined : this.model.MenuCode;
    const item = new FilterDDlModel();
    item.FilterFor = "ddlApplicationMenu";
    item.Value = this.model.ApplicationCode;
    item.FilterFrom = "ddlApplicationType";
    this.filterDDlModel = [];
    this.filterDDlModel.push(item);
    this._commonService.GetFilterdDDL(this.filterDDlModel).subscribe(
      data => {
        if (data.IsSuccess) {
          if (item.FilterFor === "ddlApplicationMenu") {
            this.dDLList.ddlApplicationMenu = data.Data.ddlApplicationMenu;
            this.model.MenuCode = !this._commonService.IsNullOrEmpty(this.permissionCode) && this.permissionCode > 0 ? menuValue : undefined;
          }
        }
      },
      error => {
        this._alertService.error(error.message);
      }
    );
  }

  AddMoreUrlItem() {
    this.model.UrlList.push(new UrlViewModel());
    this.UrlItemPostionExist = this.UrlItemPostionExist + 1;
  }

  RemoveUrlItem(index: number) {
    this.model.UrlList.splice(index, 1);
    this.UrlItemPostionExist = this.UrlItemPostionExist - 1;
  }

  onSaveClick() {
    this.submitBtn = false;
    if (!this.model.HasExtraAuthentication) {
      this.model.Password = '';
    }

    this.fromGroup.markAllAsTouched();
    if (!this.IsAllUrlItemsValid(this.model.UrlList)) {
      this._commonService.ScrollingTop();
      this._alertService.error("Url " + GlobalMessagesModel.EmptyField);

    }
    else if (this.fromGroup.valid) {
      this._permissionMasterService.Add(this.model).subscribe(data => {
        if (data.IsSuccess) {
          if (this.permissionCode > 0) {
            this._alertService.success(GlobalMessagesModel.updateSuccess, true);
          } else {
            this._alertService.success(GlobalMessagesModel.saveSuccess, true);
          }
          this._route.navigate(["/master/permission-master"]);
        } else {
          this.submitBtn = true;
          this._commonService.ScrollingTop();
          this._alertService.error(data.Message);
        }
      },
        error => {
          this.submitBtn = true;
          this._commonService.ScrollingTop();
          this._alertService.error(GlobalMessagesModel.saveError);
        }
      );
    }
    else {
      this.submitBtn = true;
    }

  }

  IsAllUrlItemsValid(urlitems: UrlViewModel[]): boolean {
    let data = urlitems.find(x => this._commonService.IsNullOrEmpty(x.PermissionUrl) || this._commonService.IsNullOrEmpty(x.PermissionType));
    return data != undefined && data != null ? false : true;
  }

  getDetail() {
    this._permissionMasterService.Detail(this.permissionCode).subscribe(
      data => {
        if (data.IsSuccess) {
          this.model = <PermissionMasterModel>data.Data;
          this.UrlItemPostionExist = this.model.UrlList.length - 1;
          this.model.MenuCode = <string>data.Data.MenuCode.toString();
          this.model.PermissionTypeCode = data.Data.PermissionTypeCode.toString();
          this.updatePasswordValidation();
          this.getFilterdDDL();
        }
      },
      error => {
      });
  }

  addUpdateMenuDialog(IsUpdate: boolean) {
    const _dialogRef = this._dialog.open(AddUpdateMenuMasterComponent, {
      width: "500px",
      data: IsUpdate ? { Id: this._commonService.IsNullOrEmpty(this.model.MenuCode) ? null : this.model.MenuCode } : null,
    });
    _dialogRef.afterClosed().subscribe((result: any) => {
      if (result.IsSuccess) {
        this.getFilterdDDL();
        this.model.MenuCode = result.Id;
      }
    });
  }

  updatePasswordValidation() {
    if (this.model.HasExtraAuthentication) {
      this.fromGroup.get("Password").setValidators(Validators.compose([Validators.required, Validators.maxLength(100)]));
    }
    else {
      this.fromGroup.get("Password").clearValidators();
    }
    this.fromGroup.get("Password").updateValueAndValidity();
  }

}
