import { Observable } from "rxjs";
import { Injectable } from '@angular/core';
import { BaseService } from '../base.service';
import { IndexModel } from '../../model/common.model';
import { AppSetting } from '../../model/appsetting.model';
import { CommunicationTypeModel } from '../../model/master/communication-type.model';
import { Dictionary } from '../../model/dictionary.model';


@Injectable({
    providedIn: 'root'
})

export class CommunicationTypeService {

    constructor(private readonly _baseService: BaseService) { }

    GetList(model: IndexModel) {
        return this._baseService.post(AppSetting.CommunicationTypeListUrl, model);
    }

    AddUpdate(id: number, model: CommunicationTypeModel) {
        return this._baseService.post(AppSetting.CommunicationTypeAddUpdateUrl, model);
    }

    Detail(id: number) {
        return this._baseService.get(AppSetting.CommunicationTypeDetailUrl + id);
    }

    ChangeDeleteStatus(id: number) {
        return this._baseService.get(AppSetting.CommunicationTypeDeleteStatusChangeUrl + id);
    }

    ChangeActiveStatus(id: number) {
        return this._baseService.get(AppSetting.CommunicationTypeActiveStatusChangeUrl + id);
    }

    IsCommunicationTypeExist(CommunicationType: string) {
        
        const param = new Dictionary<any>();

        param.Add("id", CommunicationType);

        return this._baseService.get(AppSetting.IsCommunicationTypeExistUrl, param);
    }

    GetActiveCommunicationType() {
        return this._baseService.get(AppSetting.ActiveCommunicationTypeUrl);
    }

    GetCommunicationTypePDFDownload(model: IndexModel) {
        return this._baseService.post(AppSetting.CommunicationTypePDFDownloadUrl, model);
    }


    GetAttachementTypeListByCommunicationType(CommunicationTypeCode: number = 0, CommunicationTypeEnum: string = "") {
        const param = new Dictionary<any>();
        param.Add("id", CommunicationTypeCode);
        param.Add("communicationTypeEnum", CommunicationTypeEnum);
        return this._baseService.get(AppSetting.AttachementTypeListByCommunicationTypeUrl, param);
    }


    GetUserTypeByCommunicationType(communicationTypeEnumValue): Observable<any> {
        const param = new Dictionary<any>();
        param.Add("communicationTypeEnumValue", communicationTypeEnumValue);

        return this._baseService.get(AppSetting.GetUserTypeByCommunicationTypeUrl, param);
    }


}