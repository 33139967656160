import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { AppSetting } from 'src/app/shared/model/appsetting.model';
import { DDLModel } from 'src/app/shared/model/commonddl.model';
import { GlobalMessagesModel } from 'src/app/shared/model/common-messages.model';
import { CommonService } from 'src/app/shared/service/common.service';
import { AlertService } from 'src/app/shared/service/alert.service';
import { UserViewModel } from 'src/app/shared/model/user.model';
import { AuthenticationService } from 'src/app/shared/service/authentication.service';
import { CommunicationModel } from 'src/app/shared/model/communication.model';
import { CommunicationService } from 'src/app/shared/service/communication.service';
import { isNullOrUndefined } from 'util';
import { ChangeCommunicationDepartmentOfficeModel, CommunicationOfficeLookUpDocumentModel } from 'src/app/shared/model/change-communication-department-office.model';
import { AngularEditorConfig } from '@kolkov/angular-editor';

@Component({
  selector: 'app-change-communication-office-dialog',
  templateUrl: './change-communication-office-dialog.component.html',
  styleUrls: ['./change-communication-office-dialog.component.scss']
})

export class ChangeCommunicationOfficeDialogComponent implements OnInit {
  
  loginUserDetail: UserViewModel;
  frmGrp: FormGroup;
  model = new CommunicationModel();
  ddlList = new DDLModel();
  recordId: number = 0;
  oldOfficeCode: number | string = null;
  newOfficeCode: number | string = null;

  changeCommunicationDepartmentOfficeModel = new ChangeCommunicationDepartmentOfficeModel();
  fileValidationMsg: string;
  tempDocumentList: CommunicationOfficeLookUpDocumentModel[] = [];
  editorConfig = AppSetting.editorConfig as AngularEditorConfig;
  isDisableSubmitBtn=false;
  constructor(private readonly fb: FormBuilder,
    public readonly _commonService: CommonService,
    private readonly _alertService: AlertService,
    private readonly _router: Router,
    private readonly _communicationService: CommunicationService,
    private readonly _authService: AuthenticationService,
    public _dialogRef: MatDialogRef<ChangeCommunicationOfficeDialogComponent>,
    private readonly _route: ActivatedRoute,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.loginUserDetail = this._authService.GetCurrentUserDetail().UserViewModel;
    if (data) {
      if (data.Id) {
        this.recordId = data.Id;
      }
    }
  }

  ngOnInit() {
    
    this.formGroupInit();
    if (this.recordId != 0) {
      this.getDetail();
    }
    else {
      this.CloseDialog();
    }
    this.GetDDLList();

  }

  formGroupInit() {
    this.frmGrp = this.fb.group({
      DepartmentCode: [null, Validators.required],
      DistrictCode: [null],
      OfficeCode: [null, Validators.required],
      Purpose: [null, Validators.required],
    });
  }

  GetDDLList() {
    
    this._commonService.GetAllDDL(AppSetting.CommunicationChangeOfficeDropdownKeys).subscribe(
      data => {
        if (data.IsSuccess) {
          this.ddlList = <DDLModel>data.Data;
        }
      },
      error => {
        this._alertService.error(error.message);
      }
    );
  }

  getDetail() {
    
    this._communicationService.Detail(this.recordId).subscribe(
      data => {
        
        if (data.IsSuccess) {
          
          this.model = <CommunicationModel>data.Data;
          this.model.DepartmentCode = this.model.DepartmentCode.toString();
          this.model.DistrictCode = this.model.DistrictCode.toString();
          //  this.GetOffice();
          this.model.OfficeCode = this.model.OfficeCode.toString();

          this.oldOfficeCode = this.model.OfficeCode.toString();
          
          if (this.ddlList.ddlDepartmentOffice != undefined && this.ddlList.ddlDepartmentOffice.length > 0) {
            let rmIndex = this.ddlList.ddlDepartmentOffice.findIndex(x => x.Value == this.oldOfficeCode);
            if (rmIndex > -1) {
              this.ddlList.ddlDepartmentOffice.splice(rmIndex, 1);


            }
          }
        }
      },
      error => {
        this._alertService.error(error.message);
      }
    );
  }

  GetDistrict() {
    
    if (!isNullOrUndefined(this.model.DepartmentCode)) {

      this._commonService.GetDDL(AppSetting.ddlOfficeDistrict, 0, this.model.DepartmentCode).subscribe(
        data => {

          if (data.IsSuccess) {
            var ddl = <DDLModel>data.Data;
            this.ddlList.ddlOfficeDistrict = ddl.ddlOfficeDistrict;
          }
        },
        error => {
          this._alertService.error(error.message);
        }
      );
    }
    else {
      this.ddlList.ddlOfficeDistrict = null;
    }
  }

  GetOffice() {
    if (!isNullOrUndefined(this.model.DepartmentCode)) {

      let DistrictCode = (this.model.DistrictCode == undefined) ? 0 : this.model.DistrictCode;
      let DepartmentCode = (this.model.DepartmentCode == undefined) ? 0 : this.model.DepartmentCode;
      this._commonService.GetDDL(AppSetting.ddlDepartmentOffice, DepartmentCode, DistrictCode).subscribe(
        data => {
          if (data.IsSuccess) {
            
            var ddl = <DDLModel>data.Data;
            this.ddlList.ddlDepartmentOffice = ddl.ddlDepartmentOffice;
            let rmIndex = this.ddlList.ddlDepartmentOffice.findIndex(x => x.Value == this.oldOfficeCode);
            if (rmIndex > -1) {
              this.ddlList.ddlDepartmentOffice.splice(rmIndex, 1);
              //Remove District If No Office Found for current Selected District
              if (this.ddlList.ddlDepartmentOffice.length == 0) {
                let rmDidtIndex = this.ddlList.ddlOfficeDistrict.findIndex(x => x.Value == this.model.DistrictCode);
                if (rmDidtIndex > -1) {
                  this.ddlList.ddlOfficeDistrict.splice(rmDidtIndex, 1);
                }
                // 
              }
            }

            this.model.OfficeCode = undefined;

          }
        },
        error => {
          this._alertService.error(error.message);
        }
      );
    }
    else {
      this.ddlList.ddlDepartmentOffice = null;
    }
  }



  saveClick() {
    
    this.isDisableSubmitBtn=true;
    this.frmGrp.markAllAsTouched();
    if (this.frmGrp.valid) {
      this.changeCommunicationDepartmentOfficeModel.CommunicationOfficeLookupCode = 0;
      this.changeCommunicationDepartmentOfficeModel.CommunicationCode = this.recordId;
      this.changeCommunicationDepartmentOfficeModel.OfficeCode = Number(this.model.OfficeCode);
      this.changeCommunicationDepartmentOfficeModel.UserCode = Number(this.loginUserDetail.UserCode);
      this.changeCommunicationDepartmentOfficeModel.UserMapCode = 0;
      this.changeCommunicationDepartmentOfficeModel.Purpose = this.model.Purpose;
      this.changeCommunicationDepartmentOfficeModel.DocumentList = this.tempDocumentList;
      if (this.oldOfficeCode != this.model.OfficeCode) {
        this._communicationService.ChangeCommunicationOffice(this.changeCommunicationDepartmentOfficeModel).subscribe(
          data => {
            if (data) {
              if (data.IsSuccess) {
               
                this._alertService.success(GlobalMessagesModel.updateCommunicationOfficeSuccess);
                this._dialogRef.close(true);
              } else {
                this._alertService.error(GlobalMessagesModel.updateCommunicationOfficeError);
              }
              this.isDisableSubmitBtn=false;
            } else {
              this._alertService.error(GlobalMessagesModel.InternalError);
            }
          },
          error => {
            this.isDisableSubmitBtn=false;
            this._alertService.error(error.message);
          }
        );
      }
      
      else {
        this.isDisableSubmitBtn=false;
        this._alertService.success(GlobalMessagesModel.updateCommunicationOfficeCodeChange);
      }
    }else {
      this.isDisableSubmitBtn=false;
    }
  }

  CloseDialog(): void {
    this._dialogRef.close();
  }

  GetSelectedOfficeCode() {
    if (!isNullOrUndefined(this.model.OfficeCode)) {
      this.newOfficeCode = this.model.OfficeCode;
    }
    else {
      this.newOfficeCode = null;
    }
  }

  handleFileInput(files: FileList) {
    let count = 0;
    if (this.tempDocumentList.length > 0) {
      count = this.tempDocumentList.length;
    }
    for (let index = 0; index < files.length; index++) {
      if (files.item(index).type.match("application/pdf")) {
        if (files.item(index).size < AppSetting.FileSizeLimit.PDF) {
          const reader = new FileReader();
          reader.onload = (e: any) => {
            this.tempDocumentList.push(
              new CommunicationOfficeLookUpDocumentModel()
            );
            this.tempDocumentList[count + index].DocName = <string>reader.result;
            this.tempDocumentList[count + index].DocPath = <string>reader.result;
            this.tempDocumentList[count + index].DocName = files[index].name;
            this.tempDocumentList[count + index].Extension = files[index].name.split(".")[1];
            this.tempDocumentList[count + index].DocPath = <string>reader.result;
          };
          reader.readAsDataURL(files[index]);
        }
        else {
          this.tempDocumentList = [];
          this.fileValidationMsg = AppSetting.FileSizeLimit.PDFErrorMSG;
          return;
        }
      }
      else {
        this.tempDocumentList = [];
        this.fileValidationMsg = "Only *pdf";
        return;
      }
    }
    this.fileValidationMsg = "";
  }

  downloadPdf(Url, name) {
    const linkSource = Url;
    const downloadLink = document.createElement("a");
    const fileName = name;

    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.target = "blank";
    downloadLink.click();
  }

  deleteDocument(documentCode: number, index: number) {
    this.tempDocumentList.splice(index, 1);
  }

}

