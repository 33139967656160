import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';
import { DDLModel } from 'src/app/shared/model/commonddl.model';
import { AlertService } from 'src/app/shared/service/alert.service';
import { CommonService } from 'src/app/shared/service/common.service';

@Component({
  selector: 'app-add-update-preferred',
  templateUrl: './add-update-preferred.component.html',
  styleUrls: ['./add-update-preferred.component.scss']
})
export class AddUpdatePreferredComponent implements OnInit {
  public ddlList = new DDLModel();

  constructor(
    public readonly _commonService: CommonService,
    public readonly _alertService: AlertService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
  }

  public closeDialog() {

  }

  public getDepartments() {
    //   this._commonService.getDepartmentsByAdminDepartment(Number(this.filterModel.AdminDepartmentCode)).subscribe(response => {
    //     this.ddlList.ddlDepartment = [];

    //     if (response.IsSuccess == true){
    //       this.ddlList.ddlDepartment = response.Data;
    //     }
    //   }, error =>{
    //     this._alertService.error(error.Message);
    //   })
  }

}
