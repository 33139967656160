import { Component, OnInit, ViewChild } from "@angular/core";
import { MatPaginator, MatSort, MatDialog, MatTableDataSource } from "@angular/material";
import { ActivatedRoute } from "@angular/router";
import { AppComponent } from "src/app/app.component";
import { CompliantEnum } from "src/app/shared/enum/compliant.enum";
import { StateEnum, UserTypeEnum } from "src/app/shared/enum/fixed-values.enum";
import { AppSetting } from "src/app/shared/model/appsetting.model";
import { PermissionModel } from "src/app/shared/model/common.model";
import { DDLModel, ColumnHeaderModel, DdlItemModel } from "src/app/shared/model/commonddl.model";
import { UserActivityLogViewModel, UserActivityLogSearchModel } from "src/app/shared/model/master/user-activity-class.model";
import { UserViewModel } from "src/app/shared/model/user.model";
import { AlertService } from "src/app/shared/service/alert.service";
import { AuthenticationService } from "src/app/shared/service/authentication.service";
import { CommonService } from "src/app/shared/service/common.service";
import { UserActivityLogService } from "src/app/shared/service/master/user-activity-log.service";
import { UserService } from "src/app/shared/service/user.service";
import { environment } from "src/environments/environment";
import { CompliantActionDialogComponent } from "../../complaint-software/compliant-action-dialog/compliant-action-dialog.component";


@Component({
  selector: 'app-user-activity-log',
  templateUrl: './user-activity-log.component.html',
  styleUrls: ['./user-activity-log.component.scss']
})
export class UserActivityLogComponent implements OnInit {

  ddlList = new DDLModel();
  listModel: UserActivityLogViewModel[];
  indexModel: UserActivityLogSearchModel;
  dataSource: any;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  totalRecords: number;
  compliantEnum = CompliantEnum;
  displayedColumns: string[] = [
    "index",
    "PageDetail",
    "MenuDetail",
    "RecordId",
    "ActionDetail",
    "UserDetail",
    //"Action",
  ];
  ViewdisplayedColumns: ColumnHeaderModel[] = [
    { Value: "RecordId", Text: "Transaction Id" },
    { Value: "ActionDetail", Text: "Action Detail" },
  ];
  columnsToDisplay: string[] = this.displayedColumns.slice();
  statusId: number[];
  Permission: PermissionModel = this._authService.GetPagePermission("/user-activity-log", "user-activity-log/create", "user-activity-log/detail", "user-activity-log/update", "user-activity-log/delete");
  isShow = true;
  searchModel = this._commonService.modelSetGet(new UserActivityLogSearchModel()) as UserActivityLogSearchModel;

  loginUserDetail: UserViewModel = new UserViewModel();
  userTypeEnum = UserTypeEnum;
  ddlSSOId: DdlItemModel[] = [];

  constructor(private _parentApi: AppComponent,
    private readonly _alertService: AlertService,
    public readonly _commonService: CommonService,
    public readonly _authService: AuthenticationService,
    private readonly _dialog: MatDialog,
    private _route: ActivatedRoute,
    private _userActivityLogService: UserActivityLogService,
    private readonly _userService: UserService
  ) {
    this.loginUserDetail = this._authService.GetCurrentUserDetail().UserViewModel;
    this._parentApi.setPageLayout('User Activity Log :', "", "", "");
    this.indexModel = new UserActivityLogSearchModel();
  }

  ngOnInit() {
    this.GetList();
    this.GetDDLList();
    this.changeFieldForUser();
  }

  GetDDLList() {
    let ddlKeyName = ((this.loginUserDetail.BaseUserType == this.userTypeEnum.VNDR || this.loginUserDetail.BaseUserType == this.userTypeEnum.VNDRUSER) ? AppSetting.ActivityLogDropDownKeys : AppSetting.ActivityLogDropDownDEPTKeys);
    this._commonService.GetAllDDL(ddlKeyName, 0, this.loginUserDetail.UserCode).subscribe((data) => {
      if (data.IsSuccess) {
        let ddlList = <DDLModel>data.Data;
        this.ddlList.ddlOfficeDepartment = ddlList.ddlOfficeDepartment;
        this.ddlList.ddlUserType = ddlList.ddlUserType;
        this.ddlList.ddlUserTypetemplogin = ddlList.ddlUserTypetemplogin;
        this.ddlList.ddlVendor = ddlList.ddlVendor;
        this.ddlList.ddlMenuActivityLog = ddlList.ddlMenuActivityLog;
      }
    }, (error) => {
      this._alertService.error(error.message);
    });
  }

  GetList() {
    if (this.statusId) {
      this.searchModel.indexModel.AdvanceSearchModel = { StatusIds: JSON.stringify(this.statusId) };
    }

    this._userActivityLogService.GetList(this._commonService.modelSetGet(this.searchModel, true)).subscribe(
      data => {
        if (data.IsSuccess) {
          this.listModel = <UserActivityLogViewModel[]>data.Data.Data;
          this.dataSource = new MatTableDataSource<UserActivityLogViewModel>(this.listModel);
          this.totalRecords = data.Data.TotalRecords;
          if (!this.searchModel.indexModel.IsPostBack) {
            this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.sort;
          }
        }
      },
      (error) => {
        this._alertService.error(error.message);
      }
    );
  }

  // getAbsalutePath(url) {
  //   return environment.ApiBaseUrl + url.replace("~/", "").trim();
  // }

  sortData(event) {
    this.searchModel.indexModel.OrderBy = event.active;
    this.searchModel.indexModel.OrderByAsc =
      event.direction == AppSetting.orderByDscAsc
        ? AppSetting.orderByAsc
        : AppSetting.orderByDsc;
    this.searchModel.indexModel.IsPostBack = true;
    this.GetList();
  }

  onPaginateChange(event) {
    this.searchModel.indexModel.Page = event.pageIndex + 1;
    this.searchModel.indexModel.PageSize = event.pageSize;
    this.searchModel.indexModel.IsPostBack = true;
    this.GetList();
  }

  OpenDialog(id) {
    const dialogRef = this._dialog.open(CompliantActionDialogComponent, {
      width: '500px',
      data: id
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.GetList();
      }
    });
  }
  toggleDisplay() {
    this.isShow = !this.isShow;
  }
  Reset() {
    this.searchModel = new UserActivityLogSearchModel();
    this.GetDDLList();
    this.GetList();
  }

  onChangeDepartment() {
    this.ddlList.ddlOfficeByLoginUserId = [];
    this.GetDistrictByDepartment();
  }
  GetDistrictByDepartment() {
    if (this.searchModel.UserDepartment) {
      let stateCode = String(StateEnum.Rajasthan);
      this._userService.GetUserDistrictList(stateCode, false, this.searchModel.UserDepartment.toString()).subscribe(data => {
        if (data.IsSuccess) {
          this.ddlList.ddlDistrict = <DdlItemModel[]>data.Data;

          // #CheckSelectedValueExist
          if (this.ddlList.ddlDistrict
            && this.ddlList.ddlDistrict.findIndex(x => x.Value == this.searchModel.UserDistrict) == -1) {
            this.searchModel.UserDistrict = undefined;
          } else {
            this.GetOfficeByDistrict();

          }

          // this.model.DistrictCode = (this.ddlList.ddlDistrict
          //   && this.ddlList.ddlDistrict.findIndex(x => x.Value == this.model.DistrictCode) == -1) ?
          //   undefined : String(this.model.DistrictCode);

        }
      },
        error => {
          this._alertService.error(error.message);
        }
      );
    }
    else {
      this.ddlList.ddlDistrict = [];
    }
  }

  GetOfficeByDistrict() {
    if (this.searchModel.UserDepartment) {
      let districtCode = this.searchModel.UserDistrict ? this.searchModel.UserDistrict : 0;
      this._userService.GetUserOfficeList(this.searchModel.UserDepartment, districtCode).subscribe(data => {
        if (data.IsSuccess) {
          this.ddlList.ddlOfficeByLoginUserId = <DdlItemModel[]>data.Data;
          //this.getSSOIDListByDepartment(this.searchModel.UserDepartment)
          // #CheckSelectedValueExist
          if (this.ddlList.ddlOfficeByLoginUserId
            && this.ddlList.ddlOfficeByLoginUserId.findIndex(x => x.Value == this.searchModel.UserOffice) == -1) {
            this.searchModel.UserOffice = undefined;
          }
        }
      }, (error) => {
        this._alertService.error(error.message);
      });
    } else {
      this.ddlList.ddlOfficeByLoginUserId = [];
    }
  }
  changeFieldForUser() {
    if (this.loginUserDetail.BaseUserType == this.userTypeEnum.DREC || this.loginUserDetail.BaseUserType == this.userTypeEnum.HOO
      || this.loginUserDetail.BaseUserType == this.userTypeEnum.DOS || this.loginUserDetail.BaseUserType == this.userTypeEnum.HOD
      || this.loginUserDetail.BaseUserType == this.userTypeEnum.DRECADLC || this.loginUserDetail.BaseUserType == this.userTypeEnum.HOOADLC
      || this.loginUserDetail.BaseUserType == this.userTypeEnum.DOSADLC || this.loginUserDetail.BaseUserType == UserTypeEnum.VNDR
      || this.loginUserDetail.BaseUserType == UserTypeEnum.VNDRUSER) {

      if (this.loginUserDetail.DepartmentCodes && this.loginUserDetail.DepartmentCodes.split(',').length > 0)
        this.searchModel.UserDepartment = String(this.loginUserDetail.DepartmentCodes.split(',')[0]);
      if (this.loginUserDetail.DistrictCodes && this.loginUserDetail.DistrictCodes.split(',').length > 0)
        this.searchModel.UserDistrict = String(this.loginUserDetail.DistrictCodes.split(',')[0]);
      if (this.loginUserDetail.OfficeCodes && this.loginUserDetail.OfficeCodes.split(',').length > 0)
        this.searchModel.UserOffice = String(this.loginUserDetail.OfficeCodes.split(',')[0]);
    }
  }

  getSSOIDList() {
    let selUserType = this.searchModel.UserType ? Number(this.searchModel.UserType) : 0;
    let selOfficeCode = this.searchModel.UserOffice ? Number(this.searchModel.UserOffice) : 0;
    let selSSOId = '';

    this._commonService.GetAllSSOIDByUserType(selUserType, selOfficeCode, selSSOId).subscribe((data) => {
      if (data.IsSuccess) {
        this.ddlSSOId = <DdlItemModel[]>data.Data;
        // if (this.ddlSSOId && this.ddlSSOId.length > 0 && this.selectedSSOId) {
        //   let isExist = this.ddlSSOId.find(x => x.Value == this.selectedSSOId);
        //   if (!isExist) {
        //     this.selectedSSOId = undefined;
        //   }
        // }
      }
    }, (error) => {
      this._alertService.error(error.message);
    });
  }

}
