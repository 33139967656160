//New
export class DepartmentMasterViewModel {
  DepartmentId: number;
  DepartmentTitle: string;
  DepartmentTitleHindi: string;
  DepartmentShortTitle: string;
  DepartmentShortTitleHindi: string;
  AdmDepartmentTitle: string;
  AdmDepartmentTitleHindi: string;
  DepartmentAddress: string;
  DepartmentAddressHindi: string;
  DepartmentIsActive: boolean;
  DepartmentIsDeleted: boolean;
  GroupTitle: string;
  GroupTitleHindi: string;
  DistrictTitle: string;
  DistrictTitleHindi: string;
  DepartmentCode: number;
  DepartmentCategoryName: string;
  DisplayName: string;
  WebsiteName: string;
  FacebookURL: string;
  TwitterURL: string;
  YoutubeURL: string;
  InstagramURL: string;
  DepartmentDistrictCode: number | string;
  DepartmentDistrictTypeName: string
  CMOOfficerCode: number | string;
  CMOOfficerName: string
  CMOOfficerNameHindi: string
  LogoUrl: string;
  LogoFileName: string;
  DisplayOrderWithinAdminDepartment: number | string;
  ModifiedDate: Date | null;
  SSOID: string;
  Category_Id: string;
  Category: string;
  Organization_Type_id: number | null;
  Organization_Type: string;
}

export class DepartmentMasterModel {
  DepartmentId: number;
  DepartmentTitle: string;
  DepartmentTitleHindi: string;
  DepartmentShortTitle: string;
  DepartmentShortTitleHindi: string;
  DepartmentAddress: string;
  DepartmentAddressHindi: string;
  DepartmentIsActive: number;
  DepartmentIsDeleted: number;
  Department_DistrictCode: number | string;
  DepartmentCode: number;
  Department_AdmDepartmentCode: number | string;
  Department_GroupCode: number | string;
  DepartmentCategoryCode: number | string;
  DisplayName: string;
  WebsiteName: string;
  FacebookURL: string;
  TwitterURL: string;
  YoutubeURL: string;
  InstagramURL: string;
  DepartmentDistrictCode: number | string;
  CMOOfficerCode: number | string;
  LogoUrl: string;
  LogoFileName: string;
  DisplayOrderWithinAdminDepartment: number | null;
  Category_Id: string;
  Category: string;
  Organization_Type_id: number | null;
  Organization_Type: string;
}

//#region <Report Scheme and department>

export class DepartmentReportModel {
  DepartmentId: number;
  DepartmentTitle: string;
  DepartmentCode: number;
}

export class DepartmentSchemeReportModel {
  Id: number;
  ShortNameHindi: string;
  NameHindi: string;
  ShortNameEnglish: string;
  NameEnglish: string;
  NodalDepartmentTitle: string;
  NodelDepartmentCode: number;
}

//#endregion <Report Scheme and department>

//#region <Website Details>

export class LoginUserDepartmentListModel {
  DepartmentId: number;
  DepartmentTitle: string;
  WebsiteName: string;
  NodalOfficerName: string;
  NodalOfficerDesignation: string;
  MobileNo: string;
  Email: string;
  SSOID: string;
  FacebookURL: string;
  TwitterURL: string;
  YoutubeURL: string;
  InstagramURL: string;
}

//#endregion <Website Details>



//#region <Department Profile>

export class DepartmentProfileModel {
  Id: number;
  DepartmentCode: string | number;
  EntryTypeCode: string | number;
  Details: string;
  ImageURL: string;
  PDFURL: string;
}

export class DepartmentProfileListModel {
  Id: number;
  DepartmentTitle: string;
  EntryTypeName: string;
  Details: string;
  ImageURL: string;
  PDFURL: string;
  IsActive: boolean;
}

export class DepartmentProfileExistModel {
  Id: number;
  DepartmentCode: number;
  EntryTypeCode: number;
}