import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from "@angular/router";
import { RefundRequestReasonModel } from 'src/app/shared/model/master/refund-request-reason.model';
import { AppComponent } from 'src/app/app.component';
import { RefundRequestReasonService } from 'src/app/shared/service/master/refund-request-reason.service';
import { AlertService } from 'src/app/shared/service/alert.service';
import { UserTypeEnum } from 'src/app/shared/enum/fixed-values.enum';
import { AuthenticationService } from 'src/app/shared/service/authentication.service';

@Component({
  selector: 'app-refund-request-reason-detail',
  templateUrl: './refund-request-reason-detail.component.html',
  styleUrls: ['./refund-request-reason-detail.component.scss']
})
export class RefundRequestReasonDetailComponent implements OnInit {
  IsStorage: boolean;
  model: RefundRequestReasonModel;
  RecordId: number;

  constructor(
    private readonly _appComponent: AppComponent,
    private readonly _router: Router,
    private _route: ActivatedRoute,
    private readonly _vendorTypeService: RefundRequestReasonService,
    private readonly _alertService: AlertService,
    private readonly _authService: AuthenticationService
  ) {
    this.RecordId = this._route.snapshot.params.id;
    this.model = new RefundRequestReasonModel();
    this._appComponent.setPageLayout(
      "Refund Request Reason Detail :",
      "keyboard_backspace",
      "Back To List",
      "master/refund-request-reason"
    );
  }

  ngOnInit() {
    this.getDetail();
  }

  getDetail() {
    this._vendorTypeService.Detail(this.RecordId).subscribe(
      data => {
        if (data.IsSuccess) {
          this.model = <RefundRequestReasonModel>data.Data;
        }
      },
      error => {
        this._alertService.error(error.message);
      }
    );
  }

  ngOnDestroy() {
    this.IsStorage = true;
    sessionStorage.setItem("IsRefundStatusStorage", JSON.stringify(this.IsStorage));
  }
}
