import { Component, OnInit, Inject } from '@angular/core';
import { AuthenticationService } from 'src/app/shared/service/authentication.service';
import { CommonService } from 'src/app/shared/service/common.service';
import { DashboardService } from 'src/app/shared/service/dashboard.service';
import { Router } from '@angular/router';
import { AlertService } from 'src/app/shared/service/alert.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { DDLModel } from 'src/app/shared/model/commonddl.model';
import { UserViewModel } from 'src/app/shared/model/user.model';
import { AppSetting } from 'src/app/shared/model/appsetting.model';
import { isNullOrUndefined } from 'util';
import { DashBoardFilterModel } from 'src/app/shared/model/dashboard-filter.model';
import { UserTypeEnum } from 'src/app/shared/enum/fixed-values.enum';

@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss']
})

export class FilterComponent implements OnInit {
  loginBaseUserType: string;
  ddlList: DDLModel;
  isReadonlyEmtry = true;
  pageTitle = "Add Filter";
  loginData: UserViewModel;
  filterModel: DashBoardFilterModel = new DashBoardFilterModel();

  isDeptDisabled: boolean = true;
  isVendorDisabled: boolean = true;
  isOfficerDisabled: boolean = true;
  isOfficeDisabled: boolean = true;
  isWorkOrderDisabled: boolean = true;
  isDistDisabled: boolean = true;
  isVendorUserDisabled: boolean = true;

  constructor(private readonly _authService: AuthenticationService,
    public readonly _commonService: CommonService,
    private readonly _dashboardService: DashboardService,
    private readonly _router: Router,
    private readonly _alertService: AlertService,
    public _dialogRef: MatDialogRef<FilterComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.loginData = _authService.GetCurrentUserDetail().UserViewModel;
    this.loginBaseUserType = _authService.BaseUserType;

    if (this.loginBaseUserType == UserTypeEnum.HOD)
      this.isVendorDisabled = this.isOfficerDisabled = this.isOfficeDisabled = this.isDistDisabled = false;
    else if (this.loginBaseUserType == UserTypeEnum.HOO || this.loginBaseUserType == UserTypeEnum.HOOADLC )
      this.isVendorDisabled = this.isOfficerDisabled = false;
    else if (this.loginBaseUserType == UserTypeEnum.DREC ||  this.loginBaseUserType == UserTypeEnum.DRECADLC )
      this.isVendorDisabled = this.isOfficerDisabled = this.isWorkOrderDisabled = false;
    else if (this.loginBaseUserType == UserTypeEnum.DOS ||  this.loginBaseUserType == UserTypeEnum.DOSADLC)
      this.isVendorDisabled = this.isWorkOrderDisabled = false;
    else if (this.loginBaseUserType == UserTypeEnum.VNDR)
      this.isDeptDisabled = this.isOfficerDisabled = this.isOfficeDisabled = this.isWorkOrderDisabled = this.isDistDisabled = this.isVendorUserDisabled = false;
    else if (this.loginBaseUserType == UserTypeEnum.VNDRUSER)
      this.isDeptDisabled = this.isOfficerDisabled = this.isOfficeDisabled = this.isDistDisabled = this.isWorkOrderDisabled = false;
  }

  ngOnInit() {
    this.GetDDLList();
    if (this.data != null) {
      this.pageTitle = "Update Filter";
    }
  }

  GetDDLList() {
    this._commonService.GetAllDDL(AppSetting.FilterDropdownKeys).subscribe(
      (data) => {
        if (data.IsSuccess) {
          this.ddlList = <DDLModel>data.Data;
          this.GetProjectList();
          this.GetVendorUserList();

          this.filterModel.DepartmentCode = this.loginData.DepartmentCodes;
          if (!isNullOrUndefined(this.loginData.VendorCode)) {
            this.filterModel.VendorCode = this.loginData.VendorCode.toString();
          }
          else if (!isNullOrUndefined(this.loginData.VendorUser_VendorCode)) {
            this.filterModel.VendorCode = this.loginData.VendorUser_VendorCode.toString();
          }
          this.GetOffice();
        }
      },
      (error) => {
        this._alertService.error(error.message);
      }
    );
  }

  GetProjectList() {
    this._commonService.GetDDL(AppSetting.ddlProject, 0, this.loginData.UserCode).subscribe(
      data => {
        if (data.IsSuccess) {
          var ddl = <DDLModel>data.Data;
          this.ddlList.ddlProject = ddl.ddlProject;
        }
      },
      error => {
        this._alertService.error(error.message);
      }
    );
  }

  GetVendorUserList() {
    this._commonService.GetDDL(AppSetting.ddlVendorUser, this.loginData.UserCode).subscribe(
      data => {
        if (data.IsSuccess) {
          var ddl = <DDLModel>data.Data;
          this.ddlList.ddlVendorUser = ddl.ddlVendorUser;
        }
      },
      error => {
        this._alertService.error(error.message);
      }
    );
  }

  GetOffice() {
    if (!isNullOrUndefined(this.filterModel.DepartmentCode)) {
      this.filterModel.DistrictCode = this.filterModel.DistrictCode == undefined ? 0 : this.filterModel.DistrictCode;
      this._commonService.GetDDL(AppSetting.ddlDepartmentOffice, this.filterModel.DepartmentCode, this.filterModel.DistrictCode).subscribe(
        (data) => {
          if (data.IsSuccess) {
            var ddl = <DDLModel>data.Data;
            this.ddlList.ddlDepartmentOffice = ddl.ddlDepartmentOffice;
          }
        },
        (error) => {
          this._alertService.error(error.message);
        }
      );

    } else {
      this.ddlList.ddlDepartmentOffice = null;
    }
  }

  GetOfficeStaff() {
    if (!isNullOrUndefined(this.filterModel.OfficeCode)) {
      this._commonService.GetDDL(AppSetting.ddlOfficeStaff, this.filterModel.OfficeCode).subscribe(
        (data) => {
          if (data.IsSuccess) {
            var ddl = <DDLModel>data.Data;
            this.ddlList.ddlOfficeStaff = ddl.ddlOfficeStaff;
          }
        },
        (error) => {
          this._alertService.error(error.message);
        }
      );
    }
    else {
      this.ddlList.ddlOfficeStaff = null;
    }
  }

  onCloseClick() {
    this._dialogRef.close();

  }

  onSearch() {
    if (this.filterModel.ToDate) {
      let uTCDate = new Date(
        Date.UTC(
          new Date(this.filterModel.ToDate).getFullYear(),
          new Date(this.filterModel.ToDate).getMonth(),
          new Date(this.filterModel.ToDate).getDate()
        )
      ).toISOString();
      this.filterModel.ToDate = uTCDate;
    }
    if (this.filterModel.FromDate) {
      let uTCDate = new Date(
        Date.UTC(
          new Date(this.filterModel.FromDate).getFullYear(),
          new Date(this.filterModel.FromDate).getMonth(),
          new Date(this.filterModel.FromDate).getDate()
        )
      ).toISOString();
      this.filterModel.FromDate = uTCDate;
    }
    this._dialogRef.close(this.filterModel);
  }

  onClearClick() {
    this.filterModel = new DashBoardFilterModel();
    this.onSearch();
    this.GetDDLList();
  }

}
