import { Component, OnInit, Inject } from '@angular/core';
import { UserViewModel, SSOUserModel, UserCreationViewModel } from 'src/app/shared/model/user.model';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { DDLModel, DdlItemModel } from 'src/app/shared/model/commonddl.model';
import { AlertModel } from 'src/app/shared/model/common.model';
import { CommonService } from 'src/app/shared/service/common.service';
import { AlertService } from 'src/app/shared/service/alert.service';
import { ProjectMappingService } from 'src/app/shared/service/project-mapping.service';
import { AuthenticationService } from 'src/app/shared/service/authentication.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { AddUpdateProjectMappingDialogComponent } from '../../project-mapping/add-update-project-mapping-dialog/add-update-project-mapping-dialog.component';
import { ActivatedRoute } from '@angular/router';
import { UserTypeEnum, AlertTypeEnum } from 'src/app/shared/enum/fixed-values.enum';
import { AppSetting } from 'src/app/shared/model/appsetting.model';
import { GlobalMessagesModel } from 'src/app/shared/model/common-messages.model';
import { isNullOrUndefined } from 'util';
import { UserService } from 'src/app/shared/service/user.service';

@Component({
  selector: 'app-add-updateloginuser-creation',
  templateUrl: './add-updateloginuser-creation.component.html',
  styleUrls: ['./add-updateloginuser-creation.component.scss']
})
export class AddUpdateloginuserCreationComponent implements OnInit {
  loginUserDetail: UserViewModel;
  frmGrp: FormGroup;
  ddlList: DDLModel;
  todayDate: Date = new Date();
  ddlOfficeDepartment: DdlItemModel[];
  ddlDistrict: DdlItemModel[];
  ddlDepartmentOffice: DdlItemModel[];
  model: UserCreationViewModel;
  ssoUserDetail: SSOUserModel;
  recordId: number = 0;
  heading: string;
  oldSSOId: string;
  alertModel: AlertModel;
  selectedAll = -1;
  isUserExistOnSso = true;
userTypeEnum=UserTypeEnum
  constructor(private readonly fb: FormBuilder,
    public readonly _commonService: CommonService,
    private readonly _alertService: AlertService,
    private readonly _projectMappingService: ProjectMappingService,
    private readonly _authService: AuthenticationService,
    public _dialogRef: MatDialogRef<AddUpdateProjectMappingDialogComponent>,
    private readonly _route: ActivatedRoute,
    private readonly _userService: UserService,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.loginUserDetail = _authService.LoggedInUserDetail.UserViewModel;
    this.GetDDLList();
    this.bindDepartmentDropdown();
    this.model = new UserCreationViewModel();
    if (data) {
      if (data.Id) {
        this.recordId = data.Id;
      }
    }

    if (this.loginUserDetail.BaseUserType == UserTypeEnum.ADLC) {
      this.heading = (this.recordId == 0 ? "Add" : "Update") + " Admin For login Creation";
    }
    if (this.loginUserDetail.BaseUserType == UserTypeEnum.ADM|| this.loginUserDetail.BaseUserType == UserTypeEnum.SADM) {
      this.heading = (this.recordId == 0 ? "Add" : "Update") + " User Creation";
    }
  }

  ngOnInit() {
    if (this.loginUserDetail.DepartmentCodes) {
      this.model.DepartmentCodes = this.loginUserDetail.DepartmentCodes;
    }
    if (this.loginUserDetail.DistrictCodes) {
      this.model.DistrictCodes = this.loginUserDetail.DistrictCodes;
    }
    if (this.loginUserDetail.DepartmentCodes && this.loginUserDetail.DistrictCodes) {
      this.GetOffice();
    }

    if (this.recordId > 0) {
      this.getDetail();
    }
    this.formGroupInit();
  }

  formGroupInit() {
    this.frmGrp = this.fb.group({
      UserType: new FormControl({ value: '' }, [Validators.required]),
      SSOID: [null, Validators.compose([Validators.required, Validators.maxLength(50)])],
      Title: [null, Validators.compose([Validators.required])],
      Name: [null, Validators.required],
      Email: [null, Validators.compose([Validators.required, Validators.email])],
      Gender: [null, Validators.required],
      Mobile: [null, Validators.compose([Validators.required, Validators.maxLength(35)])],
      Designation: [null, Validators.required],
      DepartmentCodes: new FormControl({ value: '', disabled: (this.loginUserDetail.BaseUserType == UserTypeEnum.DREC  || this.loginUserDetail.BaseUserType == this.userTypeEnum.DRECADLC ) }, [Validators.required]),
      DistrictCodes: [null],
      OfficeCodes: [null],
    });
  }

  GetDDLList() {
    this._commonService.GetAllDDL(AppSetting.AdminUserCreationDropdownKeys).subscribe(
      data => {
        if (data.IsSuccess) {
          this.ddlList = <DDLModel>data.Data;
          if (this.loginUserDetail.BaseUserType == UserTypeEnum.ADLC) {
            if (this.ddlList) {
              this.ddlList.ddlUserType = this.ddlList.ddlUserType.filter(function (item) {
                return (item.Value == UserTypeEnum.HOD || UserTypeEnum.HOO  ||UserTypeEnum.DREC || item.Value == UserTypeEnum.HOOADLC || UserTypeEnum.HOOADLC  ||UserTypeEnum.DRECADLC );
              });
            }
          }
          if (this.loginUserDetail.BaseUserType == UserTypeEnum.ADM || this.loginUserDetail.BaseUserType == UserTypeEnum.SADM) {
            if (this.ddlList) {
              this.ddlList.ddlUserType = this.ddlList.ddlUserType.filter(function (item) {
                return (item.Value == UserTypeEnum.ADLC);
              });
            }
          }

        }
      },
      error => {
        this._alertService.error(error.message);
      }
    );
  }

  selectAll() {
    if (this.selectedAll < 0) {
      this.model.ProjectCode = this.ddlList.ddlProject.map(
        function (a) {
          return a.Value;
        }
      );
      this.selectedAll = 1;
    } else {
      this.selectedAll = -1;
      this.model.ProjectCode = [];
    }
  }

  GetProjectDDLList() {
    var deptCodes = this.loginUserDetail.DepartmentCodes;
    if (this.loginUserDetail.BaseUserType == UserTypeEnum.ADM|| this.loginUserDetail.BaseUserType == UserTypeEnum.SADM) {
      deptCodes = "0";
    }
    this._commonService.GetDDL(AppSetting.ddlProject, deptCodes).subscribe(
      data => {
        if (data.IsSuccess) {
          var ddl = <DDLModel>data.Data;
          setTimeout(() => {
            this.ddlList.ddlProject = ddl.ddlProject;
          }, 200);
        }
      },
      error => {
        this._alertService.error(error.message);
      }
    );
  }

  bindDepartmentDropdown() {
    this._userService.GetUserDepartmentList(0).subscribe(
      data => {
        if (data.IsSuccess) {
          this.ddlOfficeDepartment = <DdlItemModel[]>data.Data;
        }
      },
      error => {
        this.alert(error.message, AlertTypeEnum.Error);
      }
    );
  }

  bindDistrictDropdown() {
    this._userService.GetUserDistrictList(0).subscribe(
      data => {
        if (data.IsSuccess) {
          this.ddlDistrict = <DdlItemModel[]>data.Data;
        }
      },
      error => {
        this.alert(error.message, AlertTypeEnum.Error);
      }
    );
  }

  getDetail() {
    this._projectMappingService.Detail(this.recordId).subscribe(
      data => {
        if (data.IsSuccess) {
          this.model = <UserCreationViewModel>data.Data;
          this.oldSSOId = this.model.SSOID;
          // this.model.ProjectCode = this.model.ProjectCodes.split(",")

          // this.model.OfficeCodes = data.Data.OfficeCodes;
          this.OfficeValidtionMethod(this.model.UserType);
          this.GetOffice();
        }
      },
      error => {
        this.model = new UserCreationViewModel();
        this.alert(error.message, AlertTypeEnum.Error);
      }
    );
  }

  getUserDetailFromSSO(ssoId: string) {
    this._userService.GetUserDetailFromSSO(ssoId).subscribe(result => {
      this._alertService.blank();
      if (result.IsSuccess) {
        this.ssoUserDetail = <SSOUserModel>result.Data
        this.model.Name = this.ssoUserDetail.FirstName + " " + this.ssoUserDetail.LastName;
        this.model.Email = this.ssoUserDetail.MailPersonal;
        this.model.Mobile = this.ssoUserDetail.Mobile;
        this.model.Gender = this.ssoUserDetail.Gender;
        this.model.ProfilePic = this.ssoUserDetail.Photo != null && this.ssoUserDetail.Photo.length > 0 ? this.ssoUserDetail.Photo : "";
        this.isUserExistOnSso = true;
      } else {
        this.model.Name = undefined;
        this.model.Email = undefined;
        this.model.Mobile = undefined;
        this.model.Gender = undefined;
        this.model.ProfilePic = "";
        this._commonService.ScrollingTop();
        this.alert(ssoId + "SSO ID Not exist on sso server!", AlertTypeEnum.Error);
        this.isUserExistOnSso = false;

      }
    }, error => {
      this._commonService.ScrollingTop();
      this.alert(GlobalMessagesModel.InternalError, AlertTypeEnum.Error);
    });
  }

  getSSODetail(Event) {

    if (Event.currentTarget.value != this.oldSSOId) {
      this._userService.IsSSOIdExist(Event.currentTarget.value).subscribe(result => {
        if (result.IsSuccess) {
          let ssoId = result.Data != null ? <boolean>result.Data : null;
          if (ssoId != null && ssoId == true) {
            this.model.Email = undefined;
            this.model.Mobile = undefined;
            this.model.Gender = undefined;
            this.model.ProfilePic = "";
            this._commonService.ScrollingTop();
            this.alert(result.Message, AlertTypeEnum.Error);
          } else {
            this.getUserDetailFromSSO(this.model.SSOID);
          }
        }
      }, error => {
        this._commonService.ScrollingTop();
        this.alert(GlobalMessagesModel.InternalError, AlertTypeEnum.Error);
      });
    }
  }

  handleFileInput(event: any) {
    if (event.target.files.item(0).type.match('image/*')) {
      if (event.target.files.item(0).size > 5242880) {
        this.alert("File size must be less than 5 MB", AlertTypeEnum.Error);
        this._commonService.ScrollingTop();
      } else {
        let reader = new FileReader();
        reader.onload = (event: any) => {
          this.model.ProfilePic = event.target.result;
        }
        reader.readAsDataURL(event.target.files.item(0));
      }
    } else {
      this.alert('Only *images accepted!', AlertTypeEnum.Error);
    }
  }

  GetOffice() {
    if (!isNullOrUndefined(this.model.DepartmentCodes)) {
      this.model.DistrictCodes = (this.model.DistrictCodes == undefined) ? "0" : this.model.DistrictCodes;
      this._userService.GetUserOfficeList(this.model.DepartmentCodes, this.model.DistrictCodes).subscribe(
        data => {
          if (data.IsSuccess) {
            this.ddlDepartmentOffice = <DdlItemModel[]>data.Data;
            this.GetDistrictByDepartment();
          }
        },
        error => {
          this.alert(error.message, AlertTypeEnum.Error);
        }
      );
    }
    else {
      this.ddlDepartmentOffice = null;
    }
  }

  saveClick() {
    this.frmGrp.markAllAsTouched();
    if (this.frmGrp.valid && this.isUserExistOnSso) {
      if (this.recordId == 0) {
        this.model.CreatedBy = this.loginUserDetail.UserCode;
      }
      else {
        this.model.ModifiedBy = this.loginUserDetail.UserCode;
      }
      if (!isNullOrUndefined(this.model.ProjectCode)) {
        this.model.ProjectCodes = this.model.ProjectCode.join(",");
      }
      else {
        this.model.ProjectCodes = '';
      }

      // let uTCDate = new Date(
      //   Date.UTC(new Date( this.model.AssignDate).getFullYear(), new Date( this.model.AssignDate).getMonth(), new Date( this.model.AssignDate).getDate())
      //   ).toISOString();
      //   this.model.AssignDate=uTCDate;

      this._userService.AddUpdateLoginUserCreation(this.recordId, this.model).subscribe(
        data => {
          if (data.IsSuccess) {
            this._alertService.success(this.recordId == 0 ? GlobalMessagesModel.saveSuccess : GlobalMessagesModel.updateSuccess);
            this._dialogRef.close(true);
          } else {
            if (data) {
              this.alert(data.Message, AlertTypeEnum.Error);
            }
            else {
              this.alert(data.Message, AlertTypeEnum.Error);
              this.alert(this.recordId == 0 ? data.message : GlobalMessagesModel.updateError, AlertTypeEnum.Error);
            }
          }
        },
        error => {
          this.alert(error.message, AlertTypeEnum.Error);
        });
    }
    else if (!this.isUserExistOnSso) {
      this.alert(this.model.SSOID + " SSO ID Not exist on sso server!", AlertTypeEnum.Error);
    }
  }

  closeDialog(): void {
    this._dialogRef.close();
  }

  alert(message: string, type: string) {
    this.alertModel = new AlertModel();
    this.alertModel.Message = message;
    this.alertModel.Type = type;
    setTimeout(() => {
      this.alertModel = new AlertModel();
    }, 5000);
  }

  GetDistrictByDepartment() {
    if (!isNullOrUndefined(this.model.DepartmentCodes)) {
      this._commonService.GetDDL(AppSetting.ddlDistrict, this.model.DepartmentCodes).subscribe(
        data => {
          if (data.IsSuccess) {
            var ddl = <DDLModel>data.Data;
            this.ddlDistrict = ddl.ddlDistrict;
          }
        },
        error => {
          this._alertService.error(error.message);
        }
      );
    }
    else {
      this.ddlList.ddlDistrict = null;
    }
  }

  OfficeValidtionMethod(data) {
    if (data == this.userTypeEnum.DREC || data==this.userTypeEnum.DRECADLC) {
      this.frmGrp.get("OfficeCodes").setValidators([Validators.required]);
      this.frmGrp.get("OfficeCodes").updateValueAndValidity();
      this.frmGrp.get("DepartmentCodes").setValidators([Validators.required]);
      this.frmGrp.get("DepartmentCodes").updateValueAndValidity();
      this.frmGrp.get("DistrictCodes").setValidators([Validators.required]);
      this.frmGrp.get("DistrictCodes").updateValueAndValidity();
    } else if (data == 'HOD') {
      this.frmGrp.get("OfficeCodes").setValidators(null);
      this.frmGrp.get("OfficeCodes").updateValueAndValidity();
      this.frmGrp.get("DistrictCodes").setValidators(null);
      this.frmGrp.get("DistrictCodes").updateValueAndValidity();
      this.frmGrp.get("DepartmentCodes").setValidators([Validators.required]);
      this.frmGrp.get("DistrictCodes").markAsUntouched();
      this.frmGrp.get("OfficeCodes").markAsUntouched();
    }
    // else if(data == 'DLO'){
    //     this.frmGrp.get("DepartmentCodes").setValidators([Validators.required]);
    //     this.frmGrp.get("DepartmentCodes").updateValueAndValidity();
    //     this.frmGrp.get("DistrictCodes").setValidators([Validators.required]);
    //     this.frmGrp.get("DistrictCodes").updateValueAndValidity();
    //     this.frmGrp.get("OfficeCodes").setValidators(null);
    //     this.frmGrp.get("OfficeCodes").updateValueAndValidity();
    //     this.frmGrp.get("OfficeCodes").markAsUntouched();
    // }
    else if (data == this.userTypeEnum.HOO  || data==this.userTypeEnum.HOOADLC) {
      this.frmGrp.get("OfficeCodes").setValidators([Validators.required]);
      this.frmGrp.get("DepartmentCodes").setValidators([Validators.required]);
      this.frmGrp.get("DistrictCodes").setValidators([Validators.required]);
      this.frmGrp.get("DepartmentCodes").updateValueAndValidity();
      this.frmGrp.get("DistrictCodes").updateValueAndValidity();
      this.frmGrp.get("OfficeCodes").updateValueAndValidity();
    } else if (data == 'ADLC') {
      this.frmGrp.get("OfficeCodes").setValidators(null);
      this.frmGrp.get("OfficeCodes").updateValueAndValidity();
      this.frmGrp.get("DistrictCodes").setValidators(null);
      this.frmGrp.get("DistrictCodes").updateValueAndValidity();
      this.frmGrp.get("DepartmentCodes").setValidators(null);
      this.frmGrp.get("DepartmentCodes").updateValueAndValidity();
    }

  }

}
