import { PreferSearchTypeEnum } from './../../../enum/fixed-values.enum';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CommonService } from 'src/app/shared/service/common.service';

@Component({
  selector: 'app-preferred-data-dialog',
  templateUrl: './preferred-data-dialog.component.html',
  styleUrls: ['./preferred-data-dialog.component.scss']
})
export class PreferredDataDialogComponent implements OnInit {
  
  IsOfficeSearch: boolean = true;
  constructor(private readonly fb: FormBuilder,
    public readonly _commonService: CommonService,

    public _dialogRef: MatDialogRef<PreferredDataDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any
  ) {


  }

  ngOnInit() {
    
    this.IsOfficeSearch = this.data.labelFor == PreferSearchTypeEnum.Office ? true : false;

  }

  public closeDialog(): void {
    this._dialogRef.close();
  }
}
