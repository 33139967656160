import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatSort, MatDialog, MatTableDataSource } from '@angular/material';
import { Router } from '@angular/router';
import { AppComponent } from 'src/app/app.component';
import { ConfirmationDialogComponent } from 'src/app/confirmation-dialog/confirmation-dialog.component';
import { AppSetting } from 'src/app/shared/model/appsetting.model';
import { GlobalMessagesModel } from 'src/app/shared/model/common-messages.model';
import { PermissionModel, IndexModel, GlobalFilterModel } from 'src/app/shared/model/common.model';
import { ColumnHeaderModel } from 'src/app/shared/model/commonddl.model';
import { DesignationMasterViewModel } from 'src/app/shared/model/master/DesignationMaster.model';
import { AlertService } from 'src/app/shared/service/alert.service';
import { AuthenticationService } from 'src/app/shared/service/authentication.service';
import { CommonService } from 'src/app/shared/service/common.service';
import { DesignationMasterService } from 'src/app/shared/service/master/designation-master.service';
import { AddUpdateDesignationMasterComponent } from './add-update-designation-master/add-update-designation-master.component';

@Component({
  selector: 'app-designation-master',
  templateUrl: './designation-master.component.html',
  styleUrls: ['./designation-master.component.scss']
})
export class DesignationMasterComponent implements OnInit {

  //#region  Variable's
  listModel: DesignationMasterViewModel[];
  dataSource: any;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  id: number;
  displayedColumns: string[] = ['index', 'Name', 'NameHindi', 'Code', 'DisplayOrder', 'ModifiedDate', 'IsActive', 'Action'];
  ViewdisplayedColumns: ColumnHeaderModel[] = [{ Value: 'Name', Text: 'Name' }, { Value: 'NameHindi', Text: 'Name In Hindi' }, { Value: 'Code', Text: 'Short Name' }, { Value: 'DisplayOrder', Text: 'Display Order' }];
  columnsToDisplay: string[] = this.displayedColumns.slice();
  Permission: PermissionModel = this._authService.GetPagePermission("/master/designation-master", "designation-master/create", "designation-master/detail", "designation-master/update", "designation-master/delete");
  indexModel = this._commonService.modelSetGet(new IndexModel()) as IndexModel;
  totalRecords: number;
  //#endregion

  //#region Constructor
  constructor(
    private readonly _alertService: AlertService,
    private readonly _parentApi: AppComponent,
    private readonly _dialog: MatDialog,
    public readonly _commonService: CommonService, private readonly _authService: AuthenticationService,
    private readonly _designationMasterService: DesignationMasterService
  ) {
    this._parentApi.setPageLayout("", "", "", "", true);

  }
  //#endregion

  //#region  Method's

  ngOnInit() {
    this.GetList();
  }

  GetList() {

    this._designationMasterService.GetList(this._commonService.modelSetGet(this.indexModel, true)).subscribe(
      data => {

        if
          (data.IsSuccess) {

          this.listModel = <DesignationMasterViewModel[]>data.Data.Data;
          this.dataSource = new MatTableDataSource<DesignationMasterViewModel>(this.listModel);

          this.totalRecords = data.Data.TotalRecords;
          if (!this.indexModel.IsPostBack) {
            this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.sort;
          }
        }
      },
      error => {
        this._alertService.error(error.message);
      }
    );
  }


  OpenDialog(DesignationId) {
    const _dialogRef = this._dialog.open(AddUpdateDesignationMasterComponent, {
      width: "600px",
      data: DesignationId,
      disableClose: true
    });
    _dialogRef.afterClosed().subscribe((result: boolean) => {

      if (result) {
        this.GetList();
      }
    });
  }

  OnStatusClick(id) {
    const dialogRef = this._dialog.open(ConfirmationDialogComponent, { width: "50%", data: GlobalMessagesModel.ConfirmDeleted });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this._designationMasterService.ChangeActiveStatus(id).subscribe(
          data => {
            if (data.IsSuccess) {
              this.GetList();
              this._alertService.success(data.Message);
            } else {
              this._alertService.error(data.Message);
            }
          },
          error => {
            this._alertService.error(error.message);
          }
        );
      }
    });
  }

  sortData(event) {
    this.indexModel.OrderBy = event.active;
    this.indexModel.OrderByAsc =
      event.direction == AppSetting.orderByDscAsc
        ? AppSetting.orderByAsc
        : AppSetting.orderByDsc;
    this.indexModel.IsPostBack = true;
    this.GetList();
  }

  onPaginateChange(event) {
    this.indexModel.Page = event.pageIndex + 1;
    this.indexModel.PageSize = event.pageSize;
    this.indexModel.IsPostBack = true;
    this.GetList();
  }

  SearchByKeyword(searchValue: GlobalFilterModel) {
    this.indexModel.Search = searchValue.search;
    this.indexModel.AdvanceSearchModel = { ActiveStatus: searchValue.ActiveStatus }

    this.GetList();
  }

  ClearSearch() {
    this.indexModel = new IndexModel();
    this.GetList();
  }

  PagePrint(printContent) {
    this._commonService.printHTMLContent(printContent, "Designation Master");
  }
  //#endregion
}