import { IndexModel } from "../common.model";

export class OfficeMaster {
}


export class CustomSearchModel {
  constructor() {
    this.indexModel = new IndexModel();
  }
  KeywordSearch: string;
  UserCode: number;
  DepartmentCode: string[];
  OfficeCode: string[];
  DistrictCode: string[];
  OfficeName: string[];
  OfficeNameHindi: string;
  OfficeShortName: string;
  ActiveView: boolean = true;
  CreatedFrom: Date | string;
  CreatedTo: Date | string;
  indexModel: IndexModel;
  SSOID: string;
  ParentOfficeCode: number;
  OfficeLevelCode: number;
}

export class OfficeMasterPostModel {
  OfficeCode: number | null;
  OfficeName: string;
  OfficeNameHindi: string;
  OfficeAddress: string;
  OfficeShortName: string;
  OfficeShortNameHindi: string;
  AdmDepartmentCode: number | null | string;
  DepartmentCode: number | null | string;
  DistrictCode: number | null | string;
  ModifiedBy: number | null;
  CreatedBy: number | null;
  IsActive: boolean | null;
  EmailId: string;
  Mobile: string | any;
  LandlineNo: string;
  IPNo: string;
  TehsilCode: number | null | string;
  BlockCode: number | null | string;
  SIPFOfficeCode: string;
  AreaTypeCode: number | null | string;
  GramPanchayatCode: number | null | string;
  VillageCode: number | null | string;
  WardNumber: string;
  OfficeLevelCode: number | null | string;
  ParentOfficeCode: number | null | string;
  StateCode: number | null | string;
  lookuptype1: number | null | string;
  lookuptype2: number | null | string;
}

export class OfficeMasterViewModel {
  OfficeId: number;
  OfficeCode: number | null;
  OfficeName: string;
  OfficeNameHindi: string;
  OfficeShortName: string;
  OfficeShortNameHindi: string | null;
  AdmDepartmentCode: number | null;
  AdmDepartmentTitle: string;
  DepartmentCode: number | null;
  Department: string;
  OfficeAddress: string;
  DistrictCode: number | null;
  District: string;
  TehsilCode: number | null;
  Tehsil: string;
  BlockCode: number | null;
  Block: string;
  EmailId: string;
  Mobile: string;
  LandlineNo: string;
  IPNo: string;
  SIPFOfficeCode: string;
  ModifiedBy: number | null;
  CreatedBy: number | null;
  CreatedDate: Date | string | null;
  ModifiedDate: Date | string | null;
  IsActive: boolean | null;
  IsDelete: boolean | null;
  CreatedByName: string;
  SSOID: string;
  AreaTypeCode: number | null | string;
  GramPanchayatCode: number | null | string;
  VillageCode: number | null | string;
  WardNumber: string;
  GrampanchayatName: string;
  VillageName: string
  AreaType: string;
  OfficeLevelCode: number | null | string;
  ParentOfficeCode: number | null | string;
  OfficeLevelName: string;
  ParentOfficeName: string;
  IsSelected: boolean | null;
  StateCode: number | null | string;
  State: string;
  lookuptype1: number | null;
  lookuptype2: number | null;
}
