export enum RowAndColumnValueEnum {
    OIC = '1',
    Office = '2',
    District = '3',
    Department = '4',
    Vendor = '5',
    VendorUser = '6',
    Communication = '7',
    CommunicationStatus = '8',
    DepartmentCommunication = '9',
    VendorCommunication = '10'
}

export enum DataSourceEnum {
    CommunicationData = '1',
    DeptUserData = '2',
    VendorUserData = '3',
    Project = '4',
    WorkOrderNumber = '5',
    Vendor = '6',
    Office = '7',
    WorkOrderAmount = '8'
}


