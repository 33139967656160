import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import { AuthenticationService } from 'src/app/shared/service/authentication.service';
import { GlobalMessagesModel } from 'src/app/shared/model/common-messages.model';
import { AppComponent } from 'src/app/app.component';
import { AlertService } from 'src/app/shared/service/alert.service';
import { ReasonTypeModel } from 'src/app/shared/model/master/reason-type.model';
import { ReasonTypeService } from 'src/app/shared/service/master/reason-type.service';
import { CommonService } from 'src/app/shared/service/common.service';
import { UserViewModel } from 'src/app/shared/model/user.model';
import { isNullOrUndefined } from 'util';
import { UserTypeEnum } from 'src/app/shared/enum/fixed-values.enum';

@Component({
  selector: 'app-add-update-reason-type',
  templateUrl: './add-update-reason-type.component.html',
  styleUrls: ['./add-update-reason-type.component.scss']
})
export class AddUpdateReasonTypeComponent implements OnInit {

   //#region <<Variable>>
   loginUserDetail: UserViewModel;
   fromGroup: FormGroup;
   model: ReasonTypeModel;
   RecordId: number = 0;
   loginUserCode: number;
   oldReasonType: string;
   IsStorage:boolean;
   //#endregion
 
   //#region <<Constructor>>
   constructor(
     private readonly fb: FormBuilder,
     private readonly appComponnet: AppComponent,
     public readonly _commonService: CommonService,
     private readonly _alertService: AlertService,
     private readonly _router: Router,
     private _route: ActivatedRoute,
     private readonly _reasonTypeService: ReasonTypeService,
     private readonly _authService: AuthenticationService
   ) {
     if (!isNullOrUndefined(this._route.snapshot.params.id)) {
       this.RecordId = this._route.snapshot.params.id;
     }
     var pageHeading = (this.RecordId == 0 ? "Add" : "Update") + " Reason Type :";
     this.appComponnet.setPageLayout(pageHeading, "keyboard_backspace", "Back To List", "master/reason-type");
     this.model = new ReasonTypeModel();
     this.loginUserDetail = this._authService.GetCurrentUserDetail().UserViewModel;
   }
   //#endregion

  ngOnInit() {
    this.formGroupInit();
    if (this.RecordId != 0) {
      this.getDetail();
    }
  }

  formGroupInit() {
    this.fromGroup = this.fb.group({
      Reason: [null, Validators.compose([Validators.required, Validators.maxLength(100)])],
      ReasonHindi: [null, Validators.compose([Validators.required, Validators.maxLength(100)])]
    });
  }

  getDetail() {
    this._reasonTypeService.Detail(this.RecordId).subscribe(
      data => {
        if (data.IsSuccess) {
          this.model = <ReasonTypeModel>data.Data;
          this.oldReasonType = this.model.Reason;
        }
      },
      error => {
        this._alertService.error(error.message);
      }
    );
  }

  saveClick() {
    this.fromGroup.markAllAsTouched();
    if (this.fromGroup.valid) {

      if (this.RecordId == 0) {
        this.model.CreatedBy = this.loginUserDetail.UserCode;
      }
      else {
        this.model.ModifiedBy = this.loginUserDetail.UserCode;
      }

      this._reasonTypeService.AddUpdate(this.RecordId, this.model).subscribe(
        data => {
          if (data) {
            if (data.IsSuccess) {
              this._alertService.success(this.RecordId == 0 ? GlobalMessagesModel.saveSuccess : GlobalMessagesModel.updateSuccess);
              this._router.navigate(["master/reason-type"]);
            } else {
              this._commonService.ScrollingTop();
              this._alertService.error(this.RecordId == 0 ? GlobalMessagesModel.saveError : GlobalMessagesModel.updateError);
            }
          } else {
            this._commonService.ScrollingTop();
            this._alertService.error(GlobalMessagesModel.InternalError);
          }
        },
        error => {
          this._commonService.ScrollingTop();
          this._alertService.error(GlobalMessagesModel.InternalError);
        }
      );
    }
  }

  IsReasonTypeExist(Event) {
    if (this.oldReasonType != Event.currentTarget.value) {
      this._reasonTypeService.IsReasonTypeExist(Event.currentTarget.value).subscribe(result => {

        if (result.IsSuccess) {
          let UserName = <boolean>result.Data
          if (UserName == true) {
            this.model.Reason = null;
            this._alertService.error("Reason Type is already exist!");
          }
        }
      }, error => {
        this._commonService.ScrollingTop();
        this._alertService.error(error.message);
      });
    }
  }

  ngOnDestroy() {
    this.IsStorage = true;
    sessionStorage.setItem("IsReasonTypeStorage", JSON.stringify(this.IsStorage));
   }

}
