import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()

export class LoaderService {
    isLoading = new Subject<boolean>();
    isProcess = new Subject<boolean>();
    CompletedProcess = new Subject<number>();

    show() {
        this.isLoading.next(true);
        this.CompletedProcess.next(0);
        this.isProcess.next(true);
    }

    hide() {
        this.isLoading.next(false);
    }

    hideProcess() {
        this.isProcess.next(false);
        this.CompletedProcess.next(100);
    }

}