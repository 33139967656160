import { UserTypeEnum } from "src/app/shared/enum/fixed-values.enum";
import { isNullOrUndefined } from "util";
import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Router, ActivatedRoute } from '@angular/router';
import { DdlItemModel } from 'src/app/shared/model/commonddl.model';
import { UserTypeModel } from 'src/app/shared/model/user-type.model';
import { AlertService } from 'src/app/shared/service/alert.service';
import { AuthenticationService } from 'src/app/shared/service/authentication.service';
import { CommonService } from 'src/app/shared/service/common.service';
import { UserTypeService } from 'src/app/shared/service/user-type.service';
import { UserService } from 'src/app/shared/service/user.service';
import { UserViewModel } from "src/app/shared/model/user.model";

@Component({
  selector: 'app-add-update-child-user-type',
  templateUrl: './add-update-child-user-type.component.html',
  styleUrls: ['./add-update-child-user-type.component.scss']
})

export class AddUpdateChildUserTypeComponent implements OnInit {

  id: number;
  model = new UserTypeModel();

  UserType = new FormControl('', [Validators.required]);
  UserTypeTitle = new FormControl('', [Validators.required]);
  DisplayUserTypeTitle = new FormControl('', [Validators.required]);
  BaseUserTypeCode = new FormControl('', [Validators.required]);

  loginUser: UserViewModel;
  title: string;
  userTypeEnum = UserTypeEnum;
  ddlUserType: DdlItemModel[] = [];
  oldUserType: string;

  constructor(
    private readonly _alertService: AlertService,
    private readonly _router: Router,
    private _route: ActivatedRoute,
    private _userService: UserService,
    private readonly _userTypeService: UserTypeService,
    private readonly _authService: AuthenticationService,
    public readonly _dialogRef: MatDialogRef<AddUpdateChildUserTypeComponent>,
    public readonly _commonService: CommonService,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    if (data) {
      this.id = data;
      this.GetById();
      this.title = "Update";
    } else {
      this.model = new UserTypeModel();
      this.title = "Add";
    }
  }

  ngOnInit() {
    this.loginUser = this._authService.GetCurrentUserDetail().UserViewModel;
    this.getDownLevelUserType();
  }

  getDownLevelUserType() {
    this._userTypeService.GetDownLevelUserType(this.loginUser.UserType, false).subscribe(
      (data) => {
        if (data.IsSuccess) {
          
          var userTypes = <UserTypeModel[]>data.Data;
          if (userTypes) {
            userTypes = userTypes.filter(t => t.BaseUserTypeCode == t.UserTypeCode);
            userTypes.forEach((element) => {
              this.ddlUserType.push({
                Value: element.UserTypeCode.toString(),
                Text: element.UserType + ' (' + element.UserTypeTitle + ')',
              });
            });
          }

        }
      },
      (error) => {
        this._alertService.error(error.message);
      }
    );
  }

  GetById() {
    this._userTypeService.GetById(this.id).subscribe(
      data => {
        if (
          (data.IsSuccess)
        ) {
          this.model = <UserTypeModel>data.Data;
          this.oldUserType = this.model.UserType;
          this.model.BaseUserTypeCode = this.model.BaseUserTypeCode.toString();
          this.model.ParrentUserType = isNullOrUndefined(this.model.ParrentUserType) ? undefined : String(this.model.ParrentUserType);
        }
      },
      error => {
        this.model = new UserTypeModel();
        this._alertService.error(error.message);
      }
    );
  }

  IsUserTypeExist(Event) {
    
    if (this.oldUserType != Event.currentTarget.value) {
      this._userTypeService.IsUserTypeExist(Event.currentTarget.value).subscribe(result => {
        if (result.IsSuccess) {
          let UserName = <boolean>result.Data;
          if (UserName == true) {
            this._alertService.error("User Type is already exist!");
            this.model.UserType=undefined;
          }
        }
      }, error => {
        this._commonService.ScrollingTop();
        this._alertService.error(error.message);
      });
    }
  }

  SaveClick() {
    this.UserType.markAsTouched();
    this.UserTypeTitle.markAsTouched();
    this.DisplayUserTypeTitle.markAsTouched();
    this.BaseUserTypeCode.markAsTouched();
    if (this.UserType.valid && this.UserTypeTitle.valid && this.DisplayUserTypeTitle.valid && this.BaseUserTypeCode.valid) {
      this._userTypeService.AddUpdateChildUserType(this.model).subscribe(data => {
        if (data.IsSuccess) {
          this._alertService.success(data.Message);
          this._dialogRef.close(true);
        } else {
          this._alertService.error(data.Message);
        }
      }, error => {
        this._alertService.error(error.message);
      });
    }
  }

  onNoClick(): void {
    this._dialogRef.close();
  }

}
