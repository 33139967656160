import { ActionModel } from './../../../shared/model/action.model';
import { VendorActionDialogComponent } from './../../vendor/vendor-action-dialog/vendor-action-dialog.component';
import { OfficeInternalTransferActionDialogComponent } from './../office-internal-transfer-action-dialog/office-internal-transfer-action-dialog.component';
import { ChangeCommunicationOfficeDialogComponent } from './../change-communication-office-dialog/change-communication-office-dialog.component';
import { MatDialog } from '@angular/material';
import { FieldTypeEnum, UserTypeEnum, StatusEnum, UserTypeProdEnum } from './../../../shared/enum/fixed-values.enum';
import { DynamicFieldConfig } from './../../../shared/model/dynamic-field-config.model';
import { CommunicationTransactionViewModel } from './../../../shared/model/communication-transaction.model';
import { CommunicationService } from 'src/app/shared/service/communication.service';
import { AuthenticationService } from 'src/app/shared/service/authentication.service';
import { isNullOrUndefined } from 'util';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppComponent } from 'src/app/app.component';
import { CommunicationFieldValueViewModel, CommunicationTypeDefaultFieldsMasterModel, DefaultFieldVisibleModel } from 'src/app/shared/model/master/communication-type-fields-master.model';
import { DefaultFieldEnum } from 'src/app/shared/enum/default-field.enum';
import { CommunicationTypeFieldsMasterService } from 'src/app/shared/service/master/communication-type-fields-master.service';
import { AlertService } from 'src/app/shared/service/alert.service';
import { CommonService } from 'src/app/shared/service/common.service';
import { ConfirmationDialogComponent } from 'src/app/confirmation-dialog/confirmation-dialog.component';
import { GlobalMessagesModel } from 'src/app/shared/model/common-messages.model';
import { ActionService } from 'src/app/shared/service/action.service';

import { ResponseBackDialogComponent } from '../response-back-dialog/response-back-dialog.component';

@Component({
  selector: 'app-communication-transaction-detail',
  templateUrl: './communication-transaction-detail.component.html',
  styleUrls: ['./communication-transaction-detail.component.scss']
})
export class CommunicationTransactionDetailComponent implements OnInit {
  isReSubmit: boolean = false;
  loginUserCode: number;
  loginBaseUserType: string;
  RecordId: number = 0;
  model: CommunicationTransactionViewModel = new CommunicationTransactionViewModel();
  defaultFieldEnum = DefaultFieldEnum;
  defaultFieldVisibleModel = new DefaultFieldVisibleModel();
  dynamicField: DynamicFieldConfig[] = [];
  communicationFieldValueViewModel: CommunicationFieldValueViewModel[] = []
  fieldTypeEnum = FieldTypeEnum;
  filedCodes: string = '';
  userTypeEnum = UserTypeEnum;
  userTypeProdEnum = UserTypeProdEnum;
  IsHideDeptInternal_VendorCommBtn: boolean = false;
  IsHideVendorCommBtn: boolean = false;
  IsHideOtherOfficeBtn: boolean = false;
  redirectFrom: string = '';
  actionModel = new ActionModel();
  statusEnum = StatusEnum;
  isShowPreviouseCommunication: number;
  routerStatusParams: string;
  ageFrom: number = 0;
  ageTo: number = 0;
  communicationType: string;
  dashboardDrilldownUrl: string;
  //cId: number = 0;
  cId: string = '';
  referId: string = '';

  constructor(private _route: ActivatedRoute,
    private readonly _authService: AuthenticationService,
    private readonly _communicationService: CommunicationService,
    private readonly appComponent: AppComponent,
    private readonly _communicationTypeFieldsMasterService: CommunicationTypeFieldsMasterService,
    private readonly _alertService: AlertService,
    private readonly _dialog: MatDialog,
    private _router: Router,
    public _commonService: CommonService,
    private readonly _actionService: ActionService,) {

    let params = this._route.snapshot.params;
    if (params) {
      this.RecordId = params.id ? params.id : 0;
      if (params.redirectFrom) {
        this.redirectFrom = params.redirectFrom;
      }
      if (params.routerStatusParams) {
        this.routerStatusParams = params.routerStatusParams;
      }


      if (params.type || params.para1 || params.para2 || params.para3 || params.para4)
        this.dashboardDrilldownUrl = "/" + params.type + "/" + params.para1 + "/" + params.para2 + "/" + params.para3 + "/" + params.para4;
    }



    this.SetLayout();

    this.loginBaseUserType = this._authService.BaseUserType;
    this.loginUserCode = this._authService.UserCode;


    if (!isNullOrUndefined(this._route.snapshot.params.redirectFrom)) {

      this.cId = this._route.snapshot.params.redirectFrom;
    }

  }

  ngOnInit() {
    if (this.RecordId > 0) {
      this.getDetail();
    }
  }

  getDetail() {

    this._communicationService.Detail(this.RecordId, true).subscribe(
      data => {
        if (data.IsSuccess) {

          this.referId = data.Data.CommunicationTypeEnumValue;

          this.model = <CommunicationTransactionViewModel>data.Data;
          let hasInProcessRecord = false;

          let count = 0;
          if (this.loginBaseUserType == UserTypeEnum.VNDR || this.loginBaseUserType == UserTypeEnum.VNDRUSER) {
            if (this.model.ActionHistoryList && this.model.ActionHistoryList.length > 0) {
              this.model.ActionHistoryList = this.model.ActionHistoryList.filter(function (item) {
                if (item.Status == 'RETURN')
                  count = count + 1
                if (item.Status == 'RESPONSE-BACK')
                  count = count + 1
                if (!(item.FromBaseUserType == UserTypeEnum.VNDR || item.FromBaseUserType == UserTypeEnum.VNDRUSER || item.ToBaseUserType == UserTypeEnum.VNDR || item.ToBaseUserType == UserTypeEnum.VNDRUSER)) {
                  if (hasInProcessRecord == false) {
                    item.Status = StatusEnum.INPROCESS;
                    item.ToUser = null;
                    item.ToUserTypeTitle = null;
                    item.Reply = null;
                    hasInProcessRecord = true;


                    return true;
                  }
                  else {
                    return false;
                  }
                }
                else {
                  return true;
                }
                if (count >= 2)
                  this.isReSubmit = true;
              }
              );
            }
          }

          if (this.model.IsCreatedByVendor == 0 && this.model.CurrentStatus == StatusEnum.RECEIVED && (this.loginBaseUserType == UserTypeEnum.VNDR || this.loginBaseUserType == UserTypeEnum.VNDRUSER)) {
            this.AutoAccepted();
          }


          if (this.model.CurrentStatus == StatusEnum.CLOSE || this.model.CurrentStatus == StatusEnum.DISPOSED || this.model.CurrentStatus == StatusEnum.REJECT) {
            this.IsHideOtherOfficeBtn = true
          }
          else if (!isNullOrUndefined(this.model.InternalTransferCommunicationList) && this.model.InternalTransferCommunicationList.length > 0 && (this.model.InternalTransferCommunicationList[0].IsPullBack == false)) {

            this.IsHideOtherOfficeBtn = true;
          }
          else { this.IsHideOtherOfficeBtn = false }

          if (this.model.CurrentStatus == StatusEnum.CLOSE || this.model.CurrentStatus == StatusEnum.DISPOSED || this.model.CurrentStatus == StatusEnum.REJECT || this.model.CurrentStatus == StatusEnum.RETURN) {
            this.IsHideVendorCommBtn = true;
          }
          else {
            this.IsHideVendorCommBtn = false;
          }

          this.checkDefaultList(this.model.CommunicationTypeEnumValue);
          this.GetDynamicField();

        } else {
          this._router.navigate(['/communications/search-detail/' + this.RecordId + (!isNullOrUndefined(this.redirectFrom) && this.redirectFrom != '' ? "/" + this.redirectFrom : '')])
        }

        this.SetLayout(this.model.CommunicationType);

        if (this.cId != "dashboard" && this.cId != "") {

          this.appComponent.setPageLayout(this.model.CommunicationType + ' Communication Detail', "keyboard_backspace", "Back To Previous communication-list",
            `communications-list/communication-detail/${this.cId}`)

        }
        else if (this.cId == "dashboard") {

          this.SetLayout(this.model.CommunicationType);
        }
        else {

          this.appComponent.setPageLayout(this.model.CommunicationType + ' Communication Detail', "keyboard_backspace", "Back To List", `communications-list/${this.referId}`);

        }
      }
    );


  }

  SetLayout(commType = "") {
    let redirect = isNullOrUndefined(this.redirectFrom == undefined) || this.redirectFrom == "" ? "" : this.redirectFrom;
    switch (redirect.toLocaleLowerCase()) {
      case "inbox":
        this.appComponent.setPageLayout(commType + " Communication Detail :", "keyboard_backspace", "Back to List", "/inbox");
        break;

      case "outbox":
        this.appComponent.setPageLayout(commType + " Communication Detail :", "keyboard_backspace", "Back to List", "/outbox");
        break;

      case "locate":
        this.appComponent.setPageLayout(commType + " Communication Detail :", "keyboard_backspace", "Back to List", "communications/search");
        break;

      case "dashboard":
        this.appComponent.setPageLayout(commType + " Communication Detail :", "keyboard_backspace", "Back to List", "dashboard-communication/" + this.routerStatusParams + "/" + this.model.IsCreatedByVendor);
        break;

      case "dashboard-drilldown":
        this.appComponent.setPageLayout(commType + " Communication Detail :", "keyboard_backspace", "Back to List", "dashboard/communication-drilldown/" + this.dashboardDrilldownUrl);
        break;

      default:
        this.appComponent.setPageLayout(commType + " Communication Detail :", "keyboard_backspace", "Back to List", "communications-list/" + this.model.CommunicationTypeEnumValue);
        break;
    }
  }
  downloadPdf(url, name) {
    //  const linkSource = url;
    const downloadLink = document.createElement("a");
    //  const fileName = name;
    downloadLink.href = url;
    downloadLink.download = name;
    downloadLink.target = "blank";
    downloadLink.click();
  }

  checkDefaultList(communicationType) {
    this._communicationTypeFieldsMasterService.GetDefaultFieldsForCommunication(0, communicationType).subscribe(
      data => {

        if (data.IsSuccess) {

          let defaultFieldModel = <CommunicationTypeDefaultFieldsMasterModel[]>data.Data;

          defaultFieldModel.forEach(element => {
            switch (String(element.FieldCode)) {

              case this.defaultFieldEnum.Project:
                this.defaultFieldVisibleModel.IsShowProjectField = true;
                this.defaultFieldVisibleModel.ProjectLabel = element.FieldName;
                break;

              case this.defaultFieldEnum.Department:
                this.defaultFieldVisibleModel.IsShowDepartmentField = true;
                this.defaultFieldVisibleModel.DepartmentLabel = element.FieldName;
                break;

              case this.defaultFieldEnum.District:
                this.defaultFieldVisibleModel.IsShowDistrictField = true;
                this.defaultFieldVisibleModel.DistrictLabel = element.FieldName;
                break;

              case this.defaultFieldEnum.WorkOrder:
                this.defaultFieldVisibleModel.IsShowWorkOrderField = true;
                this.defaultFieldVisibleModel.WorkOrderLabel = element.FieldName;
                break;

              case this.defaultFieldEnum.VendorBank:
                this.defaultFieldVisibleModel.IsShowVendorBankField = true;
                this.defaultFieldVisibleModel.VendorBankLabel = element.FieldName;
                break;

              case this.defaultFieldEnum.Attachments:
                this.defaultFieldVisibleModel.IsShowAttachmentsField = true;
                this.defaultFieldVisibleModel.AttachmentsLabel = element.FieldName;
                break;

              case this.defaultFieldEnum.Office:
                this.defaultFieldVisibleModel.IsShowOfficeField = true;
                this.defaultFieldVisibleModel.OfficeLabel = element.FieldName;
                break;


              case this.defaultFieldEnum.Amount:
                this.defaultFieldVisibleModel.IsShowAmountField = true;
                this.defaultFieldVisibleModel.AmountLabel = element.FieldName;
                break;


              case this.defaultFieldEnum.Subject:
                this.defaultFieldVisibleModel.IsShowSubjectField = true;
                this.defaultFieldVisibleModel.SubjectLabel = element.FieldName;
                break;


              case this.defaultFieldEnum.Description:
                this.defaultFieldVisibleModel.IsShowDescriptionField = true;
                this.defaultFieldVisibleModel.DescriptionLabel = element.FieldName;
                break;

              case this.defaultFieldEnum.PreviouseCommunication:
                this.defaultFieldVisibleModel.IsShowPreviouseCommunicationField = true;
                this.defaultFieldVisibleModel.PreviouseCommunicationLabel = element.FieldName;
                break;

              default:
                break;
            }
          });

        } else {
          this._alertService.error("Field Section " + data.Message);
        }
      },
      error => {
        this._alertService.error("Field Error" + error.message);
      }
    );
  }

  GetDynamicField() {
    this.dynamicField = [];
    this._communicationTypeFieldsMasterService.GetFieldsForCommunication(this.model.CommunicationTypeEnumValue, this.RecordId).subscribe(response => {
      if (response.IsSuccess) {
        this.communicationFieldValueViewModel = response.Data as CommunicationFieldValueViewModel[];
      } else {
        this._alertService.error(response.Message);
      }
    },
      error => {
        this._alertService.error("Dyanmic " + error.message);
      });
  }

  CallPrint(printContent) {
    this._commonService.printHTMLContent(printContent, this.model.CommunicationType + " Communication Detail");
  }

  OpenChangeOfficeDialog(Id) {
    const _dialogRef = this._dialog.open(ChangeCommunicationOfficeDialogComponent, {
      width: "90%",
      height: "90%",
      data: { Id },
      disableClose: true
    });
    _dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        this.getDetail();
      }
    });
  }

  OpenInternalTransferDialog(Id) {
    const _dialogRef = this._dialog.open(OfficeInternalTransferActionDialogComponent, {
      width: "90%",
      height: "90%",
      data: { Id: Id },
      disableClose: true
    });
    _dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        this._router.navigate(["communications-list/" + this.model.CommunicationTypeEnumValue]);
      } else {
        this.getDetail();
      }
    });
  }

  OpenProcessCommunicationDialog(Id, CanReturn) {
    const _dialogRef = this._dialog.open(VendorActionDialogComponent, {
      width: "90%",
      height: "90%",
      data: { Id, CanReturn, Status: this.model.CurrentStatus },
      disableClose: true
    });
    _dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        this.getDetail();
      }
    });
  }

  pullBackTransfer(id: number) {
    const dialogRef = this._dialog.open(ConfirmationDialogComponent,
      {
        // width: "90%",
        // height: "90%",
        data: GlobalMessagesModel.ConfirmPullBack
      });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this._communicationService.PullBackInternalTransfer(this.loginUserCode, id).subscribe(
          data => {
            if (data.IsSuccess) {
              this._alertService.success(data.Message);
              this.getDetail();
            }
          },
          error => {
            this._alertService.error("Pullback " + error.message);
          }
        );
      }
    });
  }

  AutoAccepted() {
    this.actionModel.CommunicationCode = this.RecordId;
    this.actionModel.ActionCode = 0;
    this.actionModel.CreatedBy = this.loginUserCode;
    this.actionModel.Status = StatusEnum.ACCEPT;
    this.actionModel.Reply = "Auto Accepted";
    this._actionService.AddUpdate(this.actionModel).subscribe(
      data => {
        if (data) {
          if (data.IsSuccess) {
            this.getDetail();
            //Update Inbox/outbox counts
            this._commonService.isUpdateInboxoutbox.next(true);
          }
          else {
          }
        }
        else {
          this._alertService.error(GlobalMessagesModel.InternalError);
        }
      },
      error => {
        this._alertService.error("Auto Accept " + error.message);
      }
    );
  }

  OpenResponseBackDialog(Id) {
    const _dialogRef = this._dialog.open(ResponseBackDialogComponent, {
      width: "90%",
      height: "90%",
      data: { Id },
      disableClose: true
    });
    _dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        this.getDetail();
      }
    });
  }

}