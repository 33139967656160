import { Component, OnInit, ViewChild } from "@angular/core";
import { MatTableDataSource, MatDialog, MatSort, MatPaginator } from "@angular/material";
import { GlobalFilterModel, IndexModel, PermissionModel } from 'src/app/shared/model/common.model';
import { ColumnHeaderModel } from 'src/app/shared/model/commonddl.model';
import { AppComponent } from 'src/app/app.component';
import { AlertService } from 'src/app/shared/service/alert.service';
import { CommonService } from 'src/app/shared/service/common.service';
import { ConfirmationDialogComponent } from 'src/app/confirmation-dialog/confirmation-dialog.component';
import { GlobalMessagesModel } from 'src/app/shared/model/common-messages.model';
import { AppSetting } from 'src/app/shared/model/appsetting.model';
import { AddUpdateBankAccountDialogComponent } from '../add-update-bank-account-dialog/add-update-bank-account-dialog.component';
import { BankAccountModel } from 'src/app/shared/model/bank-account.model';
import { BankAccountService } from 'src/app/shared/service/bank-account.service';
import { ActivatedRoute } from '@angular/router';
import { BankAccountDetailDialogComponent } from '../bank-account-detail-dialog/bank-account-detail-dialog.component';
import { UserTypeEnum } from 'src/app/shared/enum/fixed-values.enum';
import { AuthenticationService } from 'src/app/shared/service/authentication.service';

@Component({
  selector: 'app-bank-account',
  templateUrl: './bank-account.component.html',
  styleUrls: ['./bank-account.component.scss']
})

export class BankAccountComponent implements OnInit {
  model: BankAccountModel[];
  indexModel = this._commonService.modelSetGet(new IndexModel()) as IndexModel;
  dataSource: any;
  totalRecords: number;
  vendorCode: number;
  loginBaseUserType: string;
  userTypeEnum = UserTypeEnum;


  displayedColumns: string[] = [];
  columnsToDisplay: string[] = [];
  ViewdisplayedColumns: ColumnHeaderModel[] = [
    { Value: "BankHolderName", Text: "Bank Account Holder Name" },
    { Value: "BankName", Text: "Bank" },
    { Value: "BankAccountNo", Text: "Account No" },
    { Value: "IFSCCode", Text: "IFSC Code" },
    { Value: "BankBranchAddress", Text: "Address" },
    { Value: "IsPrimary", Text: "Is Primary" },
  ];

  ViewSearchColumns: ColumnHeaderModel[] = [
    { Value: "BankHolderName", Text: "Bank Account Holder Name" },
    { Value: "BankName", Text: "Bank" },
    { Value: "BankAccountNo", Text: "Account No" },
    { Value: "IFSCCode", Text: "IFSC Code" },
    { Value: "BankBranchAddress", Text: "Address" }
  ];
  Permission: PermissionModel = this._authService.GetPagePermission("vendor/bank-account", "bank-account/add", "bank-account/detail", "bank-account/update", "bank-account/delete");

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(
    private readonly appComponnet: AppComponent,
    private _route: ActivatedRoute,
    private readonly _alertService: AlertService,
    private readonly _bankAccountService: BankAccountService,
    private readonly _dialog: MatDialog,
    public readonly _commonService: CommonService,
    private readonly _authService: AuthenticationService
  ) {
    this.vendorCode = this._route.snapshot.params.id;
    this.appComponnet.setPageLayout("Bank Accounts :", "", "", "", true);

    this.loginBaseUserType = this._authService.BaseUserType;

    this.displayedColumns = ["index", "BankHolderName", "BankName", "BankAccountNo", "IFSCCode", "BankBranchAddress", "IsPrimary"];
    if (this.loginBaseUserType == this.userTypeEnum.ADM || this.loginBaseUserType == this.userTypeEnum.SADM) {
      this.displayedColumns.push("IsActive");
    }
    this.displayedColumns.push("Action");
    this.columnsToDisplay = this.displayedColumns.slice();
  }

  ngOnInit() {
    this.getList();
  }

  getList() {
    this._bankAccountService.GetList(this.vendorCode, this._commonService.modelSetGet(this.indexModel, true)).subscribe(
      data => {
        if (data.IsSuccess) {
          this.model = <BankAccountModel[]>data.Data.Data;
          this.dataSource = new MatTableDataSource<BankAccountModel>(this.model);
          this.totalRecords = data.Data.TotalRecords;
          if (!this.indexModel.IsPostBack) {
            this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.sort;
          }
          this.model.forEach(element => {
            element.IsPrimary = element.IsPrimary == true ? "Yes" : "No";
          });
        } else {
          this._alertService.error(data.Message);
        }
      },
      error => {
        this._alertService.error(error.message);
      }
    );
  }

  updateActiveStatus(id) {
    const dialogRef = this._dialog.open(ConfirmationDialogComponent, { width: "50%", data: GlobalMessagesModel.ConfirmStatusChanged });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this._bankAccountService.ChangeActiveStatus(id).subscribe(
          data => {
            if (data.IsSuccess) {
              this._alertService.success(data.Message);
              this.getList();
            }
          },
          error => {
            this._alertService.error(error.message);
          }
        );
      }
    });
  }

  updateDeleteStatus(id) {
    const dialogRef = this._dialog.open(ConfirmationDialogComponent, { width: "50%", data: GlobalMessagesModel.ConfirmDeleted });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this._bankAccountService.ChangeDeleteStatus(id).subscribe(
          data => {
            if (data.IsSuccess) {
              this._alertService.success(data.Message);
              this._commonService.ScrollingTop();
              this.getList();
            }
          },
          error => {
            this._alertService.error(error.message);
          }
        );
      }
    });
  }

  sortData(event) {
    this.indexModel.OrderBy = event.active;
    this.indexModel.OrderByAsc = event.direction == AppSetting.orderByDscAsc ? AppSetting.orderByAsc : AppSetting.orderByDsc;
    this.indexModel.IsPostBack = true;
    this.getList();
  }

  onPaginateChange(event) {
    this.indexModel.Page = event.pageIndex + 1;
    this.indexModel.PageSize = event.pageSize;
    this.indexModel.IsPostBack = true;
    this.getList();
  }

  SearchByKeyword(searchValue: GlobalFilterModel) {
    this.indexModel.Search = searchValue.search;
    this.indexModel.AdvanceSearchModel = { ActiveStatus: searchValue.ActiveStatus }

    this.getList();
  }

  ClearSearch() {
    this.indexModel = new IndexModel();
    this.getList();
  }

  OpenAddUpdateDialog(Id) {
    let VendorCode = this.vendorCode;
    const _dialogRef = this._dialog.open(AddUpdateBankAccountDialogComponent, {
      width: "750px",
      data: { Id, VendorCode },
      disableClose: true
    });
    _dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        this.getList();
      }
    });
  }

  OpenDetailDialog(Id) {
    const _dialogRef = this._dialog.open(BankAccountDetailDialogComponent, {
      width: "750px",
      data: { Id },
      disableClose: true
    });
    _dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
      }
    });
  }


}