import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { MatDialog, MatDialogRef, MatTableDataSource } from '@angular/material';
import { PreferredListEnum } from 'src/app/shared/enum/preferred-list.enum';
import { OfficePreferredListModel } from 'src/app/shared/model/preferred-list/office-preferred-list.model';


import { AlertService } from 'src/app/shared/service/alert.service';
import { AuthenticationService } from 'src/app/shared/service/authentication.service';
import { OfficePreferredListService } from 'src/app/shared/service/Preferred-List/office-preferred-list.service';

@Component({
  selector: 'app-office-preferred-list',
  templateUrl: './office-preferred-list.component.html',
  styleUrls: ['./office-preferred-list.component.scss'],
  providers: [OfficePreferredListService]
})
export class OfficePreferredListComponent implements OnInit {
  @Input() selectedTab: any;
  @Output() selectOfficePreferredEvent = new EventEmitter<string>();

  public displayedColumns: string[] = ['radio', 'postedOffice'];
  public dataSource: any;
  public modelList: OfficePreferredListModel[] = [];
  public preferredListEnum: PreferredListEnum;
  public loginUserCode: number;
  private selectedId: string = "";

  constructor(
    private readonly _officePreferredService: OfficePreferredListService,
    private readonly _alertService: AlertService,
    private readonly _authService: AuthenticationService,
    public _dialogRef: MatDialogRef<OfficePreferredListComponent>
  ) {
    this.loginUserCode = _authService.UserCode;
  }

  ngOnInit() {
    this.getList();
  }

  public getList() {

    this._officePreferredService.GetList(this.selectedTab, this.loginUserCode).subscribe((data) => {
      if (data.IsSuccess) {
        this.modelList = <OfficePreferredListModel[]>data.Data.Offices;
        this.dataSource = this.modelList;
      }
    }, (error) => {
      this._alertService.error(error.message);
    });
  }

  public onSubmit() {
    if (this.selectedId == "") {
      return;
    }

    this.selectOfficePreferredEvent.emit(this.selectedId);
  }

  public radioChanged(row: OfficePreferredListModel) {
    this.selectedId = String(row.OfficeId);
  }
}
