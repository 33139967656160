import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { MatTableDataSource, MatDialog, MatSort, MatPaginator } from "@angular/material";
import { IndexModel } from 'src/app/shared/model/common.model';
import { ColumnHeaderModel, DdlItemModel, DDLModel } from 'src/app/shared/model/commonddl.model';
import { AppComponent } from 'src/app/app.component';
import { AlertService } from 'src/app/shared/service/alert.service';
import { CommonService } from 'src/app/shared/service/common.service';
import { ConfirmationDialogComponent } from 'src/app/confirmation-dialog/confirmation-dialog.component';
import { GlobalMessagesModel } from 'src/app/shared/model/common-messages.model';
import { AppSetting } from 'src/app/shared/model/appsetting.model';
import { Router, ActivatedRoute } from '@angular/router';
import { isNullOrUndefined } from 'util';
import { AuthenticationService } from 'src/app/shared/service/authentication.service';
import { UserTypeEnum, StatusEnum } from 'src/app/shared/enum/fixed-values.enum';
import { ActionDialogComponent } from 'src/app/content/communication/action-dialog/action-dialog.component';
import { GeneralCommunicationModel } from '../../../shared/model/general-communication.model';
import { GeneralCommunicationService } from '../../../shared/service/general-communication.service';
import { CommunicationService } from '../../../shared/service/communication.service';
import { UserViewModel } from 'src/app/shared/model/user.model';
import { DashBoardFilterModel } from 'src/app/shared/model/dashboard-filter.model';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

declare const require: any;
const jsPDF = require('jspdf');
require('jspdf-autotable');

@Component({
  selector: 'app-general-report',
  templateUrl: './general-report.component.html',
  styleUrls: ['./general-report.component.scss']
})
export class GeneralReportComponent implements OnInit {

  model: GeneralCommunicationModel[];
  loginUserCode: number;
  loginBaseUserType: string;
  userTypeVNDR: string = UserTypeEnum.VNDR;
  userTypeVNDRUSER: string = UserTypeEnum.VNDRUSER;
  indexModel: IndexModel;
  dataSource: any;
  totalRecords: number;
  ddlStatus: DdlItemModel[] = [];
  status: string = '';
  search: string = '';
  ddlList: DDLModel;
  loginUserDetail: UserViewModel;
  filterModel: DashBoardFilterModel = new DashBoardFilterModel();
  displayedColumns: string[] = ["index", "Subject", "DepartmentName", "OfficeName", "DistrictName", "ConcernName", "Status"];  //"IsActive"
  columnsToDisplay: string[] = this.displayedColumns.slice();
  ViewdisplayedColumns: ColumnHeaderModel[] = [
    { Value: "Subject", Text: "Subject" },
    { Value: "DepartmentName", Text: "Department" },
    { Value: "OfficeName", Text: "Office" },
    { Value: "DistrictName", Text: "District" },
    { Value: "ConcernName", Text: "Concern To" },
  ];

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  isDeptDisabled=false;
  constructor(
    private readonly appComponnet: AppComponent,
    private readonly _alertService: AlertService,
    private readonly _router: Router,
    private _route: ActivatedRoute,
    private readonly _communicationService: CommunicationService,
    private readonly _generalCommunicationService: GeneralCommunicationService,
    private readonly _dialog: MatDialog,
    public readonly _commonService: CommonService,
    private readonly _authService: AuthenticationService
  ) {
    this.indexModel = new IndexModel();
    if (!isNullOrUndefined(this._route.snapshot.queryParamMap.get("status"))) {
      this.status = this._route.snapshot.queryParamMap.get("status");
    }
    this.loginUserDetail = this._authService.GetCurrentUserDetail().UserViewModel;
    this.GetDDLList();
    this.loginUserCode = this._authService.UserCode;
    this.loginBaseUserType = this._authService.BaseUserType;
    if (this.loginBaseUserType == UserTypeEnum.VNDR || this.loginBaseUserType == UserTypeEnum.VNDRUSER) {
      this.appComponnet.setPageLayout("General Communication Report :", "add", "Create", "communication/general/add", false, false);
    }
    else {
      this.appComponnet.setPageLayout("General Communication Report :", "", "", "", false, false);
    }
  }

  ngOnInit() {
    this.bindStatus();
    this.getList();
  }

  bindStatus() {
    var item1: DdlItemModel = new DdlItemModel();
    item1.Text = (this.loginBaseUserType == UserTypeEnum.VNDR || this.loginBaseUserType == UserTypeEnum.VNDRUSER) ? StatusEnum.SENT : StatusEnum.RECEIVED;
    item1.Value = StatusEnum.SENT;
    this.ddlStatus.push(item1);

    var item2: DdlItemModel = new DdlItemModel();
    item2.Text = StatusEnum.ACCEPT;
    item2.Value = StatusEnum.ACCEPT;
    this.ddlStatus.push(item2);

    var item3: DdlItemModel = new DdlItemModel();
    item3.Text = StatusEnum.REJECT;
    item3.Value = StatusEnum.REJECT;
    this.ddlStatus.push(item3);

    var item4: DdlItemModel = new DdlItemModel();
    item4.Text = StatusEnum.RETURN;
    item4.Value = StatusEnum.RETURN;
    this.ddlStatus.push(item4);
  }
  filterData: any = [];
  getList() {
    this._generalCommunicationService.GetList(this.loginUserCode, this.search, this.status, this.indexModel).subscribe(
      data => {
        if (data.IsSuccess) {
          this.filterData = [];
          this.model = <GeneralCommunicationModel[]>data.Data.Data;
          this.model.forEach(element => {
            if (element.Status != StatusEnum.SENT) {
              element.IsView_BtnActionHistory = true;
            }
            if ((this.loginBaseUserType == UserTypeEnum.DREC|| this.loginBaseUserType == UserTypeEnum.DRECADLC) && element.Status == StatusEnum.SENT) {
              element.IsView_BtnAction = true;
            }
            if (!(this.loginBaseUserType == UserTypeEnum.VNDR || this.loginBaseUserType == UserTypeEnum.VNDRUSER) && element.Status == StatusEnum.SENT) {
              element.Status = StatusEnum.RECEIVED;
            }
            var addRecord = true;
            if (isNullOrUndefined(this.filterModel.DepartmentCode)) {
            } else {
              if (this.filterModel.DepartmentCode == element.DepartmentCode) {
              } else {
                addRecord = false;
              }
            }
            if (isNullOrUndefined(this.filterModel.OfficeCode) || this.filterModel.OfficeCode == 0) {
            }
            else {
              if (this.filterModel.OfficeCode == element.OfficeCode) {
              } else {
                addRecord = false;
              }
            }
            if (addRecord) {
              this.filterData.push(element);
            }

          });
          this.dataSource = new MatTableDataSource<GeneralCommunicationModel>(this.filterData);
          this.totalRecords = data.Data.TotalRecords;
          if (!this.indexModel.IsPostBack) {
            this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.sort;
          }
        } else {
          this._alertService.error(data.Message);
        }
      },
      error => {
        this._alertService.error(error.message);
      }
    );
  }

  updateActiveStatus(id) {
    const dialogRef = this._dialog.open(ConfirmationDialogComponent, { width: "50%", data: GlobalMessagesModel.ConfirmStatusChanged });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this._communicationService.ChangeActiveStatus(id).subscribe(
          data => {
            if (data.IsSuccess) {
              this._alertService.success(data.Message);
              this.getList();
            }
          },
          error => {
            this._alertService.error(error.message);
          }
        );
      }
    });
  }

  updateDeleteStatus(id) {
    const dialogRef = this._dialog.open(ConfirmationDialogComponent, { width: "50%", data: GlobalMessagesModel.ConfirmDeleted });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this._communicationService.ChangeDeleteStatus(id).subscribe(
          data => {
            if (data.IsSuccess) {
              this._alertService.success(data.Message);
              this._commonService.ScrollingTop();
              this.getList();
            }
          },
          error => {
            this._alertService.error(error.message);
          }
        );
      }
    });
  }

  sortData(event) {
    this.indexModel.OrderBy = event.active;
    this.indexModel.OrderByAsc = event.direction == AppSetting.orderByDscAsc ? AppSetting.orderByAsc : AppSetting.orderByDsc;
    this.indexModel.IsPostBack = true;
    this.getList();
  }

  onPaginateChange(event) {
    this.indexModel.Page = event.pageIndex + 1;
    this.indexModel.PageSize = event.pageSize;
    this.indexModel.IsPostBack = true;
    this.getList();
  }

  proceedToAaction(Id) {
    const _dialogRef = this._dialog.open(ActionDialogComponent, {
      width: "500px",
      data: { Id },
      disableClose: true
    });
    _dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        this.getList();
      }
    });
  }

  onClearclick() {
    this.search = '';
    this.status = '';
    this.filterModel.DepartmentCode = null;
    this.filterModel.DistrictCode = null;
    this.filterModel.OfficeCode = null;
    this.getList();
  }
  @ViewChild('pdfTable', { static: false }) pdfTable: ElementRef;


  completeModel: GeneralCommunicationModel[];
  completeIndexModel: IndexModel;
  getCompleteData() {
    this.completeIndexModel = new IndexModel();
    this.completeIndexModel.Page = 1;
    this.completeIndexModel.PageSize = this.totalRecords;
    this.completeIndexModel.IsPostBack = true;

    this._generalCommunicationService.GetList(this.loginUserCode, this.search, this.status, this.completeIndexModel).subscribe( 
      (completeData: any) => {
        if (completeData.IsSuccess) {
          this.filterData = [];
          this.completeModel = <GeneralCommunicationModel[]>completeData.Data.Data; 
          this.totalRecords = completeData.Data.TotalRecords;
          this.completeModel.forEach(element => {
            if (element.Status != StatusEnum.SENT) {
              element.IsView_BtnActionHistory = true;
            }
            if ((this.loginBaseUserType == UserTypeEnum.DREC|| this.loginBaseUserType == UserTypeEnum.DRECADLC) && element.Status == StatusEnum.SENT) {
              element.IsView_BtnAction = true;
            }
            if (!(this.loginBaseUserType == UserTypeEnum.VNDR || this.loginBaseUserType == UserTypeEnum.VNDRUSER) && element.Status == StatusEnum.SENT) {
              element.Status = StatusEnum.RECEIVED;
            }
            var addRecord = true;
            if (isNullOrUndefined(this.filterModel.DepartmentCode)) {
            } else {
              if (this.filterModel.DepartmentCode == element.DepartmentCode) {
              } else {
                addRecord = false;
              }
            }

            if (isNullOrUndefined(this.filterModel.OfficeCode) || this.filterModel.OfficeCode == 0) {
            }
            else {
              if (this.filterModel.OfficeCode == element.OfficeCode) {
              } else {
                addRecord = false;
              }
            }
            if (addRecord) {
              this.filterData.push(element);
            }
          });
          this.dataSource = new MatTableDataSource<GeneralCommunicationModel>(this.filterData);
          if (!this.completeIndexModel.IsPostBack) {
            this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.sort;
          }

        } else {
          this._alertService.error(completeData.Message);
        }
      },
      error => {
        this._alertService.error(error.message);
      }
    );
  }

  buttonClickExportAsPDF(): void {
    this.getCompleteData();
    setTimeout(() => {
      this.download();
      this.exportAsPdf();
    }, 1000);
  }
  buttonClickExportAsXLSX(): void {
    this.getCompleteData();
    setTimeout(() => {
      this.download();
      this.exportAsExcelFile();
    }, 1000);
  }
  GetDDLList() {
    this._commonService
      .GetAllDDL(AppSetting.FilterDropdownKeys)
      .subscribe(
        (data) => {
          if (data.IsSuccess) {
            this.ddlList = <DDLModel>data.Data;
            this.GetProjectDDLList();
            this.GetDistrictDDLList();
            this.filterModel.DepartmentCode = this.loginUserDetail.DepartmentCodes;
            if (!isNullOrUndefined(this.loginUserDetail.VendorCode)) {
              this.filterModel.VendorCode = this.loginUserDetail.VendorCode.toString();
            }
            else if (!isNullOrUndefined(this.loginUserDetail.VendorUser_VendorCode)) {
              this.filterModel.VendorCode = this.loginUserDetail.VendorUser_VendorCode.toString();
            }
            this.GetOffice();
          }
        },
        (error) => {
          this._alertService.error(error.message);
        }
      );
  }

  GetProjectDDLList() {
    var userDept = (this.loginUserDetail.DepartmentCodes == null) ? 0 : this.loginUserDetail.DepartmentCodes;
    this._commonService.GetDDL(AppSetting.ddlProject, userDept, this.loginUserDetail.UserCode).subscribe(
      data => {
        if (data.IsSuccess) {
          var ddl = <DDLModel>data.Data;
          this.ddlList.ddlProject = ddl.ddlProject;
        }
      },
      error => {
        this._alertService.error(error.message);
      }
    );
  }
  GetOffice() {
    
    this.GetProjectListByDepartmentWise();
    if (!isNullOrUndefined(this.filterModel.DepartmentCode)) {
      this.filterModel.DistrictCode =
        this.filterModel.DistrictCode == undefined ? 0 : this.filterModel.DistrictCode;
      this._commonService
        .GetDDL(
          AppSetting.ddlDepartmentOffice,
          this.filterModel.DepartmentCode,
          this.filterModel.DistrictCode
        )
        .subscribe(
          (data) => {
            if (data.IsSuccess) {
              var ddl = <DDLModel>data.Data;
              this.ddlList.ddlDepartmentOffice = ddl.ddlDepartmentOffice;
            }
          },
          (error) => {
            this._alertService.error(error.message);
          }
        );

    } else {
      this.ddlList.ddlDepartmentOffice = null;
    }
  }

  GetProjectListByDepartmentWise() {
    var userDept = (this.loginUserDetail.DepartmentCodes == null) ? 0 : this.loginUserDetail.DepartmentCodes;

    if (!isNullOrUndefined(this.filterModel.DepartmentCode)) {

      this._commonService.GetDDL(AppSetting.ddlProject, this.filterModel.DepartmentCode, this.loginUserDetail.UserCode).subscribe(
        data => {
          if (data.IsSuccess) {
            var ddl = <DDLModel>data.Data;
            this.ddlList.ddlProject = ddl.ddlProject;
          }
        },
        error => {
          this._alertService.error(error.message);
        }
      );
    }
    else {
      this.GetProjectDDLList();
    }

  }
  
  GetDistrictDDLList() {
    this._commonService.GetDDL(AppSetting.ddlDistrict, 0, this.loginUserDetail.UserCode).subscribe(
      data => {
        if (data.IsSuccess) {
          var ddl = <DDLModel>data.Data;
          this.ddlList.ddlDistrict = ddl.ddlDistrict;      
        }
      },
      error => {
        this._alertService.error(error.message);
      }
    );
  }

  rowsNew: any = [];

  download() {    
    var i = 1;
    this.rowsNew = [];
    this.filterData.forEach(element => {
      var temp = {
        SNo: i, Subject: element.Subject, Department: element.DepartmentName, Office: element.OfficeName,DistrictName:element.DistrictName,
        "Concern To": element.ConcernName, Status: element.Status, ModifiedBy: element.UserName,
        ModifiedOn: element.ModifiedOn
      }; //change
      i = i + 1;
      this.rowsNew.push(temp);
    });

  }

  public exportAsPdf(): void {
    var doc = new jsPDF('p', 'pt');
    var header = function (data) {
      doc.setFontSize(10);
      doc.setTextColor(40);
      doc.text("Communication General Report", data.settings.margin.left, 50);
      doc.text("Date Time : " + new Date().toLocaleDateString() + " " + new Date().toLocaleTimeString(), data.settings.margin.left, 800);
    };
    var headers = [{  //change
      title: "#",
      dataKey: "SNo"
    }, {
      title: "Subject",
      dataKey: "Subject"
    }, {
      title: "Department",
      dataKey: "Department"
    }, {
      title: "Office",
      dataKey: "Office"
    },
    {
      title: "DistrictName",
      dataKey: "DistrictName"
    },    
    {
      title: "ConcernTo",
      dataKey: "Concern To"
    }, {
      title: "Status",
      dataKey: "Status"
    },   
    {
      title: "Modified By",
      dataKey: "ModifiedBy"
    },
    {
      title: "Modified On",
      dataKey: "ModifiedOn"
    }

    ];
    doc.autoTable(headers, this.rowsNew, { margin: { top: 70 }, beforePageContent: header });
    doc.save("Communication-General_Report_" + new Date().toLocaleDateString() + "_" + new Date().toLocaleTimeString() + ".pdf"); //change
  }

  //excellll
  public exportAsExcelFile(): void {
    //this.getCompleteData();
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.rowsNew);
    const workbook: XLSX.WorkBook = { Sheets: { 'communicationComplainReport': worksheet }, SheetNames: ['communicationComplainReport'] }; //change
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFile(excelBuffer, 'Communication-General'); //change
  }
  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], { type: EXCEL_TYPE });
    FileSaver.saveAs(data, fileName + '_Report_' + new Date().toLocaleDateString() + "_" + new Date().toLocaleTimeString() + EXCEL_EXTENSION);
  }
}



