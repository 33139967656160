export class CommunicationTypeModel {
    CommunicationTypeCode: number;
    CommunicationType: string;
    CommunicationTypeHindi: string;
    EnumValue: string;
    IsActive: boolean;
    IsDeleted: boolean;
    CreatedBy: number | null;
    CreatedOn: Date | null;
    ModifiedBy: number | null;
    ModifiedOn: Date | null;
    AttachmentTypeCodeList: string[];
    AttachmentTypeCodes: string;
    AttachmentTypeNames: string;
    UserName: string;
    AttachmentTypeListModel: CommunicationTypeAttachmentPostModel[];
    CommunicationTypeForUserTypeCode: string;
    CommunicationTypeForUserTypeTitle: string;
    SLADays: number | null;
    SLANotificationDays: number | null;  
    CreatedByUserName: string;
    ModifiedByUserName: string;
   
}

export class CommunicationTypeAttachmentViewModel {
    LookupCode: number;
    CommunicationTypeCode: number;
    CommunicationType: string;
    AttachmentTypeCode: number;
    AttachmentType: string;
    IsSelected: boolean;
    IsRequired: boolean;
}

export class CommunicationTypeAttachmentPostModel {
    LookupCode: number;
    CommunicationTypeCode: number;
    AttachmentTypeCode: number;
    IsRequired: boolean;
}

export class CommunicationUserTypeViewModel {
    Code: number;
    UserType: string;
    EnumValue: string;
    CommunicationType: string;
    CommunicationTypeHindi: string;
}