export class OfficerListRequestModel {
    SSOId: string;
    SearchTxt: string;
    AdminDepartmentCode: string | number;
    DepartmentId: string | number;
    OfficeId: string | number;

    constructor() {
        this.SSOId = '';
        this.SearchTxt = '';
        this.AdminDepartmentCode = '0';
        this.DepartmentId = '0';
        this.OfficeId = '0';
    }
}