import { NotificationMappingModel } from 'src/app/shared/model/master/notification-template-type.model';
import { Component, Inject, OnInit } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import { AppComponent } from "src/app/app.component";
import { AppSetting } from "src/app/shared/model/appsetting.model";
import { DDLModel } from "src/app/shared/model/commonddl.model";
import { AlertService } from "src/app/shared/service/alert.service";
import { CommonService } from "src/app/shared/service/common.service";
import { SMSTemplateService } from "src/app/shared/service/master/sms-template.service";
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-add-update-smstemplate-mapping-dialog',
  templateUrl: './add-update-smstemplate-mapping-dialog.component.html',
  styleUrls: ['./add-update-smstemplate-mapping-dialog.component.scss']
})
export class AddUpdateSMSTemplateMappingDialogComponent implements OnInit {
  dDLList = new DDLModel();
  model = new NotificationMappingModel();
  frmSMS: FormGroup;
  constructor(public readonly _commonService: CommonService,
    private readonly _alertService: AlertService,
    private readonly formBuilder: FormBuilder,
    private readonly _smsTemplateService: SMSTemplateService,
    public _dialogRef: MatDialogRef<AddUpdateSMSTemplateMappingDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {

    this.formInit();
    if (data) {
      this.model.Id = data;
    }

    this.GetDDLList();
  }




  ngOnInit() {
    if (this.model.Id > 0) { this.GetById(); }
  }

  GetById() {
    this._smsTemplateService.GetById(this.model.Id).subscribe(
      data => {
        if (data.IsSuccess) {
          this.model = <NotificationMappingModel>data.Data;
          this.model.TemplateCode = this.model.TemplateCode ? String(this.model.TemplateCode) : undefined;
          this.model.TransactionCode = this.model.TransactionCode ? String(this.model.TransactionCode) : undefined;
        }
      },
      error => {
        this._alertService.error(error.message);
      }
    );
  }

  formInit() {
    this.frmSMS = this.formBuilder.group({
      TransactionCode: [null, Validators.required],
      TemplateCode: [null, Validators.required]
    });

  }

  GetDDLList() {
    this._commonService.GetAllDDL(AppSetting.SMSTemplateMappingDDlKey)
      .subscribe(
        data => {
          if (data.IsSuccess) {
            this.dDLList = <DDLModel>data.Data;
          }
        },
        error => {
          this._alertService.error(error.message);
        }
      );
  }


  saveClick() {
    this.frmSMS.markAllAsTouched();
    if (this.frmSMS.valid) {

      this._smsTemplateService.MapSMSTemplateWithTransaction(this.model).subscribe(
        data => {
          if (data.IsSuccess) {

            this._alertService.success(data.Message);
            this._dialogRef.close();
          } else {
            this._alertService.error(data.Message);
          }
        },
        error => {
          this._alertService.error(error.message);
        }
      );

    }
  }

  onNoClick(): void {
    this._dialogRef.close();
  }

}
