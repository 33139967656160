import { ProjectModel, ProjectViewModel } from 'src/app/shared/model/project.model';
import { ProjectService } from 'src/app/shared/service/project.service';
import { Component, OnInit, Inject } from '@angular/core';
import { UserTypeEnum, AlertTypeEnum } from 'src/app/shared/enum/fixed-values.enum';
import { ProjectMappingViewModel } from 'src/app/shared/model/projectMapping.model';
import { MAT_DIALOG_DATA, MatDialogRef, DateAdapter, MAT_DATE_FORMATS, MatDialog } from '@angular/material';
import { ProjectMappingService } from 'src/app/shared/service/project-mapping.service';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { CommonService } from 'src/app/shared/service/common.service';
import { AlertService } from 'src/app/shared/service/alert.service';
import { DdlItemModel, DDLModel } from 'src/app/shared/model/commonddl.model';
import { AlertModel } from 'src/app/shared/model/common.model';
import { AuthenticationService } from 'src/app/shared/service/authentication.service';
import { AppSetting } from 'src/app/shared/model/appsetting.model';
import { GlobalMessagesModel } from 'src/app/shared/model/common-messages.model';
import { isNullOrUndefined } from 'util';
import { AppDateAdapter, APP_DATE_FORMATS } from 'src/app/shared/model/format-datepicker';
import { UserViewModel } from 'src/app/shared/model/user.model';
import { PreferredListEnum } from 'src/app/shared/enum/preferred-list.enum';
import { PreferredListComponent } from '../../preferred-list/preferred-list.component';

@Component({
  selector: 'app-add-update-project-mapping-dialog',
  templateUrl: './add-update-project-mapping-dialog.component.html',
  styleUrls: ['./add-update-project-mapping-dialog.component.scss'],
  providers: [
    { provide: DateAdapter, useClass: AppDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS },
  ],
})

export class AddUpdateProjectMappingDialogComponent implements OnInit {
  heading: string;
  frmGrp: FormGroup;
  ddlList: DDLModel;
  selectedAll = -1;
  submitBtn = true;
  maxDate = new Date();
  todayDate: Date = new Date();
  minDate = new Date("1/1/100");
  alertModel: AlertModel;
  ddlUser: DdlItemModel[];
  ddlProject: DdlItemModel[];
  loginUserDetail: UserViewModel;
  ddlOfficeByLoginUserId: DdlItemModel[];
  ddlOfficeHavingStaffByLoginUserId: DdlItemModel[];
  projectDetailModel = new ProjectViewModel();
  model: ProjectMappingViewModel = new ProjectMappingViewModel();
  isDepartmentCodes: boolean = false;
  isDistrictCodes: boolean = false;
  isOfficecodes: boolean = false;

  constructor(private readonly fb: FormBuilder,
    public readonly _commonService: CommonService,
    private readonly _alertService: AlertService,
    private readonly _projectMappingService: ProjectMappingService,
    private readonly _projectService: ProjectService,
    private readonly _authService: AuthenticationService,
    public _dialogRef: MatDialogRef<AddUpdateProjectMappingDialogComponent>,
    private readonly _dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: { id: number, isReassign: boolean }) {
    this.loginUserDetail = this._authService.LoggedInUserDetail.UserViewModel;
  }

  ngOnInit() {
    this.formGroupInit();
    this.getDDLList();

    if (this.RecordId == 0) {
      this.getNotAssignedProjects();
    }

    if (this.IsReassign == false) {
      this.heading = "Update Project Assign Detail";
    }
    else {
      this.heading = (this.data.id == 0 ? "Assign" : "Re-Assign") + " OIC Project Mapping";
      this.frmGrp.get("UserType").disable();
      this.frmGrp.get("UserCode").disable();
    }

    if (this.data.id > 0) {
      this.getProjectMappingDetail(this.data.id);
    }

    // if (this.data) {
    //   if (this.data.id) {
    //     this.recordId = this.data.id;
    //     this.getProjectMappingDetail();
    //   }
    //   if (this.data.isReassign) {
    //     this.isReassign = this.data.isReassign;
    //   }
    // }




    if (this.loginUserDetail.BaseUserType == UserTypeEnum.DREC || this.loginUserDetail.BaseUserType == UserTypeEnum.DRECADLC) {
      this.model.DepartmentCodes = !isNullOrUndefined(this.loginUserDetail.DepartmentCodes) ? this.loginUserDetail.DepartmentCodes : undefined;
      this.model.DistrictCodes = !isNullOrUndefined(this.loginUserDetail.DistrictCodes) ? this.loginUserDetail.DistrictCodes : undefined;
      this.model.OfficeCodes = !isNullOrUndefined(this.loginUserDetail.OfficeCodes) ? this.loginUserDetail.OfficeCodes : undefined;
      if (!this.model.DepartmentCodes) {
        this.frmGrp.get("DepartmentCodes").enable();
        this.isDepartmentCodes = true
      }
      if (!this.model.DistrictCodes) {
        this.frmGrp.get("DistrictCodes").enable();
        this.isDistrictCodes = true
      }
      else {
        this.getOffices();
      }
      if (!this.model.OfficeCodes) {
        this.frmGrp.get("OfficeCodes").enable();
        this.isOfficecodes = true
      }

    }
  }

  get IsReassign() {
    return this.data.isReassign;
  }

  get RecordId() {
    return this.data.id;
  }

  formGroupInit() {
    this.frmGrp = this.fb.group({
      UserType: new FormControl({ value: '' }, [Validators.required]),
      UserCode: [null, Validators.compose([Validators.required])],
      ProjectCodes: [null, (this.RecordId == 0 ? Validators.required : null)],
      DepartmentCodes: new FormControl({ value: null, disabled: true }),
      DistrictCodes: new FormControl({ value: null, disabled: true }),
      OfficeCodes: new FormControl({ value: null, disabled: true }),
      AssignDate: new FormControl({ value: '' }, [Validators.required])
    });
  }

  getNotAssignedProjects() {
    this._projectService.GetAllNotAssignedProjects(this.loginUserDetail.UserCode).subscribe(
      data => {
        if (data.IsSuccess) {
          var projectList = <ProjectModel[]>data.Data;
          if (projectList) {
            this.ddlProject = [];
            projectList.forEach((element) => {
              this.ddlProject.push({
                Value: element.ProjectCode.toString(),
                Text: element.ProjectName,
              });
            });
          }
        } else {
          this._alertService.error(data.Message);
        }
      },
      error => {
        this._alertService.error(error.message);
      }
    );
  }

  getDDLList() {
    this._commonService.GetAllDDL(AppSetting.ProjectMappingKeys).subscribe(data => {
      if (data.IsSuccess) {
        this.ddlList = <DDLModel>data.Data;
        if (this.ddlList) {
          this.ddlList.ddlUserType = this.ddlList.ddlUserType.filter(function (item) {
            return (item.Value === UserTypeEnum.DOS || item.Value === UserTypeEnum.DOSADLC);
          });

          if (this.RecordId == 0 && this.ddlList.ddlUserType) {
            this.model.UserType = String(this.ddlList.ddlUserType[0].Value);
          }
        }
      }
    }, error => {
      this._alertService.error(error.message);
    });
  }

  public openPreferredListDialog(): void {

    const selectedTabValue: PreferredListEnum = PreferredListEnum.Officer;

    const dialogRef = this._dialog.open(PreferredListComponent, {
      width: '100%',
      height: '100%',
      data: {
        selectedTab: selectedTabValue
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.model.UserCode = result;
    });
  }

  getOffices() {
    if (!isNullOrUndefined(this.model.DistrictCodes)) {
      this.model.DistrictCodes = (this.model.DistrictCodes == undefined) ? "0" : this.model.DistrictCodes;
      this._commonService
        .GetDDL(AppSetting.ddlOfficeByLoginUserId, this.model.DepartmentCodes, this.model.DistrictCodes)
        .subscribe(
          (data) => {
            if (data.IsSuccess) {
              var ddl = <DDLModel>data.Data;
              this.ddlOfficeByLoginUserId = ddl.ddlOfficeByLoginUserId;
            }
          },
          (error) => {
            this._alertService.error(error.message);
          }
        );
    }
    else {
      this.ddlOfficeByLoginUserId = null;
    }
  }

  getOfficeHavingStaff() {
    if (!isNullOrUndefined(this.model.DistrictCodes)) {
      this.model.DistrictCodes = (this.model.DistrictCodes == undefined) ? "0" : this.model.DistrictCodes;
      this._commonService
        .GetDDL(AppSetting.ddlOfficeHavingStaffByLoginUserId, this.model.DepartmentCodes, this.model.DistrictCodes)
        .subscribe(
          (data) => {
            if (data.IsSuccess) {
              var ddl = <DDLModel>data.Data;
              this.ddlOfficeHavingStaffByLoginUserId = ddl.ddlOfficeHavingStaffByLoginUserId;
            }
          },
          (error) => {
            this._alertService.error(error.message);
          }
        );
    }
    else {
      this.ddlOfficeHavingStaffByLoginUserId = null;
    }
  }

  selectAllProject() {
    if (this.selectedAll < 0) {
      this.model.ProjectCodeList = this.ddlProject.map(
        function (a) {
          return String(a.Value);
        }
      );
      this.selectedAll = 1;
    } else {
      this.selectedAll = -1;
      this.model.ProjectCodeList = [];
    }
  }

  getProjectMappingDetail(id: number) {
    this._projectMappingService.Detail(id).subscribe(data => {
      if (data.IsSuccess) {
        this.model = <ProjectMappingViewModel>data.Data;

        this.minDate = this.model.AssignDate ? new Date(this.model.AssignDate) : this.minDate;
        this.model.AssignDate = this.model.AssignDate;
        this.model.UserCode = this.model.AssignToUserCode.toString();
        this.model.ProjectCodes = this.model.ProjectCode ? this.model.ProjectCode.toString() : undefined;
        this.model.DepartmentCodes = this.loginUserDetail.DepartmentCodes;
        this.model.DistrictCodes = this.loginUserDetail.DistrictCodes;
        this.model.OfficeCodes = this.loginUserDetail.OfficeCodes;
        this.model.ProjectCodeList = [];
        this.model.ProjectCodeList.push(this.model.ProjectCode.toString());
        this.model.UserType = this.model.OIC_UserType;

        this.getProjectDetail(this.model.ProjectCode);

      }
    }, error => {
      this.alert(error.message, AlertTypeEnum.Error);
    });
  }


  saveClick() {
    this.submitBtn = false;
    this.frmGrp.markAllAsTouched();
    if (this.frmGrp.valid) {

      this.model.AssignByUserCode = this.loginUserDetail.UserCode;
      if (!isNullOrUndefined(this.model.ProjectCodeList)) {
        this.model.ProjectCodes = this.model.ProjectCodeList.toString();
      }
      else {
        this.model.ProjectCodes = '';
      }

      this._projectMappingService.AssignProject(this.model).subscribe(data => {
        if (data.IsSuccess) {
          this._alertService.success(this.RecordId == 0 ? GlobalMessagesModel.saveSuccess : GlobalMessagesModel.updateSuccess);
          this._dialogRef.close(true);
        }
        else {
          this.submitBtn = true;
          this.alert(GlobalMessagesModel.saveError, AlertTypeEnum.Error);
        }
      }, error => {
        this.submitBtn = true;
        this.alert(error.message, AlertTypeEnum.Error);
      });

    } else {
      this.submitBtn = true;
    }
  }

  getUsers(userType: string, officeCode: number) {
    this._commonService.GetUserByUserTypeAndOfficeCode(userType, officeCode).subscribe(
      (data) => {
        if (data.IsSuccess) {
          this.ddlUser = <DdlItemModel[]>data.Data;
        }
      },
      (error) => {
        this._alertService.error(error.message);
      }
    );
  }

  closeDialog(): void {
    this._dialogRef.close();
  }

  alert(message: string, type: string) {
    this.alertModel = new AlertModel();
    this.alertModel.Message = message;
    this.alertModel.Type = type;
    setTimeout(() => {
      this.alertModel = new AlertModel();
    }, 5000);
  }

  getProjectDetail(Value: any) {
    if (Value != undefined && Value.length != "") {
      this._projectService.Detail(Value.toString()).subscribe(data => {
        if (data.IsSuccess) {
          this.projectDetailModel = <ProjectViewModel>data.Data;
          this.model.DistrictCodes = String(this.projectDetailModel.DistrictCode);
          this.getOffices();
          this.model.OfficeCodes = String(this.projectDetailModel.OfficeCode);
          this.getUsers(this.model.UserType, this.projectDetailModel.OfficeCode)
          if (this.RecordId == 0) {
            this.minDate = new Date(this.projectDetailModel.TendorRefDate);
          }
        }
      }, error => {
        this._alertService.error(error.message);
      });
    }
  }

}
