import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { AppComponent } from 'src/app/app.component';
import { AppSetting } from 'src/app/shared/model/appsetting.model';
import { GlobalFilterModel, IndexModel, SearchModel } from 'src/app/shared/model/common.model';
import { ColumnHeaderModel } from 'src/app/shared/model/commonddl.model';
import { ContactusModel } from 'src/app/shared/model/contactus.model';
import { ConcernModel } from 'src/app/shared/model/master/concern.model';
import { AlertService } from 'src/app/shared/service/alert.service';
import { CommonService } from 'src/app/shared/service/common.service';
import { ContactusService } from 'src/app/shared/service/contactus.service';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent implements OnInit {

  model: ContactusModel[];
  indexModel: IndexModel;
  dataSource: any;
  totalRecords: number;
  defaultValue: SearchModel;

  displayedColumns: string[] = ["index", "Name", "MobileNo", "Email", "Description"];
  columnsToDisplay: string[] = this.displayedColumns.slice();
  ViewdisplayedColumns: ColumnHeaderModel[] = [
    { Value: "Name", Text: "Name" },
    { Value: "MobileNo", Text: "Mobile No" },
    { Value: "Email", Text: "Email-ID" },
    { Value: "Description", Text: "Description" }
  ];

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;


  constructor(private readonly appComponnet: AppComponent,
    private readonly _alertService: AlertService,
    private readonly _contactusService: ContactusService,
    public readonly _commonService: CommonService) {
    this.appComponnet.setPageLayout("Contact Us:", "", "", "");
    const Temp = JSON.parse(sessionStorage.getItem("IsContactUsStorage"));

    this.indexModel = JSON.parse(sessionStorage.getItem("ContactUsFilter"));

    if (!this.indexModel) {
      this.indexModel = new IndexModel();
    } else if (this.indexModel.Search && Temp) {
      sessionStorage.setItem("IsContactUsStorage", JSON.stringify(false));
      this.defaultValue = new SearchModel();
      var temp = JSON.parse(this.indexModel.Search);
      this.defaultValue.Key = Object.keys(temp)[0];
      this.defaultValue.Value = String(Object.values(temp)[0]);
    }
    else {
      this.indexModel = new IndexModel();
      this.GetList();
    }
  }

  ngOnInit() {
    this.GetList();
  }

  GetList() {
    this._contactusService.GetList(this.indexModel).subscribe(
      data => {
        if (data.IsSuccess) {
          this.model = <ContactusModel[]>data.Data.Data;
          this.dataSource = new MatTableDataSource<ContactusModel>(this.model);
          this.totalRecords = data.Data.TotalRecords;
          if (!this.indexModel.IsPostBack) {
            this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.sort;
          }
        } else {
          this._alertService.error(data.Message);
        }
      },
      error => {
        this._alertService.error(error.message);
      }
    );
  }


  sortData(event) {
    this.indexModel.OrderBy = event.active;
    this.indexModel.OrderByAsc = event.direction == AppSetting.orderByDscAsc ? AppSetting.orderByAsc : AppSetting.orderByDsc;
    this.indexModel.IsPostBack = true;
    this.GetList();
  }

  onPaginateChange(event) {
    this.indexModel.Page = event.pageIndex + 1;
    this.indexModel.PageSize = event.pageSize;
    this.indexModel.IsPostBack = true;
    this.GetList();
  }

  SearchByKeyword(searchValue: GlobalFilterModel) {
    this.indexModel.Search = searchValue.search;
    this.indexModel.AdvanceSearchModel = { ActiveStatus: searchValue.ActiveStatus }
    this.GetList();
  }

  ClearSearch() {
    this.indexModel.Search = null;
    this.indexModel.AdvanceSearchModel = { ActiveStatus: "1" }
    this.GetList();
  }
}