import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { PreferredListEnum } from 'src/app/shared/enum/preferred-list.enum';
import { PermissionModel } from 'src/app/shared/model/common.model';
import { AuthenticationService } from 'src/app/shared/service/authentication.service';

@Component({
  selector: 'app-preferred-list',
  templateUrl: './preferred-list.component.html',
  styleUrls: ['./preferred-list.component.scss']
})
export class PreferredListComponent implements OnInit {

  public Permission: PermissionModel = this._authService.GetPagePermission("/preferred-list", "preferred-list/create", "preferred-list/detail", "preferred-list/update", "preferred-list/delete");
  public id: number;
  public selectedTab: PreferredListEnum = PreferredListEnum.Office;
  public officeListSelected: boolean = false;
  public officerListSelected: boolean = false;
  public vendorListSelected: boolean = false;
  public vendorPreferredListSelected: boolean = false;
  public officerPreferredListSelected: boolean = false;
  public officePreferredListSelected: boolean = false;

  constructor(
    private readonly _authService: AuthenticationService,
    public dialogRef: MatDialogRef<PreferredListComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {

  }

  ngOnInit() {


  }

  get PreferredListEnum() {
    return PreferredListEnum;
  }


  public handleTabClick(event: any) {

    switch (event.index) {
      case 0:
        this.selectedTab = PreferredListEnum.Office;
        this.officePreferredListSelected = true;
        this.officeListSelected = false;
        this.officerListSelected = false;
        this.vendorListSelected = false;
        this.vendorPreferredListSelected = false;
        this.officerPreferredListSelected = false;
        break;

      case 1:
        this.selectedTab = PreferredListEnum.AllOffice;
        this.officePreferredListSelected = false;
        this.officeListSelected = true;
        this.officerListSelected = false;
        this.vendorListSelected = false;
        this.vendorPreferredListSelected = false;
        this.officerPreferredListSelected = false;
        break;

      case 2:
        this.selectedTab = PreferredListEnum.Vendor;
        this.officePreferredListSelected = false;
        this.officeListSelected = false;
        this.officerListSelected = false;
        this.vendorListSelected = false;
        this.vendorPreferredListSelected = true;
        this.officerPreferredListSelected = false;
        break;

      case 3:
        this.selectedTab = PreferredListEnum.AllVendor;
        this.officePreferredListSelected = false;
        this.officeListSelected = false;
        this.officerListSelected = false;
        this.vendorListSelected = true;
        this.vendorPreferredListSelected = false;
        this.officerPreferredListSelected = false;
        break;

      case 4:
        this.selectedTab = PreferredListEnum.AllOfficer;
        this.officePreferredListSelected = false;
        this.officeListSelected = false
        this.officerListSelected = true;
        this.vendorListSelected = false;
        this.vendorPreferredListSelected = false;
        this.officerPreferredListSelected = false;
        break;

      case 5:
        this.selectedTab = PreferredListEnum.Officer;
        this.officePreferredListSelected = false;
        this.officeListSelected = false
        this.officerListSelected = false;
        this.vendorListSelected = false;
        this.vendorPreferredListSelected = false;
        this.officerPreferredListSelected = true;
        break;

      default:
        break;
    }
  }

  public closeDialog(): void {
    this.dialogRef.close();
  }

  public itemSelected(value: string) {
    this.dialogRef.close(value);
  }
}
