import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatSort, MatDialog, MatTableDataSource } from '@angular/material';
import { Router } from '@angular/router';
import { AppComponent } from 'src/app/app.component';
import { ConfirmationDialogComponent } from 'src/app/confirmation-dialog/confirmation-dialog.component';
import { UserManualDialogComponent } from 'src/app/header/user-manual-dialog/user-manual-dialog.component';
import { AppSetting } from 'src/app/shared/model/appsetting.model';
import { GlobalMessagesModel } from 'src/app/shared/model/common-messages.model';
import { PermissionModel, IndexModel, GlobalFilterModel } from 'src/app/shared/model/common.model';
import { ColumnHeaderModel } from 'src/app/shared/model/commonddl.model';
import { MasterTypeViewModel } from 'src/app/shared/model/master/master-type.model';
import { AlertService } from 'src/app/shared/service/alert.service';
import { AuthenticationService } from 'src/app/shared/service/authentication.service';
import { CommonService } from 'src/app/shared/service/common.service';
import { MasterTypeService } from 'src/app/shared/service/master/master-type.service';
import { AddUpdateMasterTypeDataComponent } from './add-update-master-type-data/add-update-master-type-data.component';

@Component({
  selector: 'app-master-type-data',
  templateUrl: './master-type-data.component.html',
  styleUrls: ['./master-type-data.component.scss']
})
export class MasterTypeDataComponent implements OnInit {

  //#region  Variable's
  listModel: MasterTypeViewModel[] = [];
  dataSource: any;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  id: number;
  displayedColumns: string[] = ['index', 'MasterTypeName', 'ModifiedDate','IsActive', 'Action'];
  ViewdisplayedColumns: ColumnHeaderModel[] = [{ Value: 'MasterTypeName', Text: 'Name' },];
  columnsToDisplay: string[] = this.displayedColumns.slice();
  Permission: PermissionModel = this._authService.GetPagePermission("/master/master-type", "master-type/create", "master-type/detail", "master-type/update", "master-type/delete");
  indexModel: IndexModel;
  totalRecords: number;
  ActiveStatus: number | string = "1";
  ShowFilter = false;
  //#endregion

  //#region Constructor
  constructor(
    private readonly _alertService: AlertService,
    private readonly _parentApi: AppComponent,
    private readonly _dialog: MatDialog,
    public readonly _commonService: CommonService,
    private readonly _masterTypeService: MasterTypeService,
    private readonly _authService: AuthenticationService
  ) {
    this._parentApi.setPageLayout("", "", "", "", true);
    this.indexModel = this._commonService.modelSetGet(new IndexModel) as IndexModel;
    //  this.ActiveStatus = this.indexModel.AdvanceSearchModel != undefined && this.indexModel.AdvanceSearchModel.ActiveStatus != undefined ? this.indexModel.AdvanceSearchModel.ActiveStatus : "1";

  }
  //#endregion

  //#region  Method's

  ngOnInit() {
    this.GetList();
  }

  GetList() {
    // this.indexModel.AdvanceSearchModel = { ActiveStatus: this.ActiveStatus }

    this._masterTypeService.GetList(this._commonService.modelSetGet(this.indexModel, true)).subscribe(data => {
      if (data.IsSuccess) {
        this.listModel = <MasterTypeViewModel[]>data.Data.Data;
        this.dataSource = new MatTableDataSource<MasterTypeViewModel>(this.listModel);
        this.totalRecords = data.Data.TotalRecords;
        if (!this.indexModel.IsPostBack) {
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
        }
      }
    },
      error => {
        this._alertService.error(error.message);
      }
    );
  }


  OpenDialog(id) {

    const _dialogRef = this._dialog.open(AddUpdateMasterTypeDataComponent, {
      width: "800px",
      height: "500px",
      data: id,
      disableClose: true
    });
    _dialogRef.afterClosed().subscribe((result: boolean) => {

      if (result) {
        this.GetList();
      }
    });
  }


  OnStatusClick(id) {



    const dialogRef = this._dialog.open(ConfirmationDialogComponent, { width: "50%", data: GlobalMessagesModel.ConfirmDeleted });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this._masterTypeService.ChangeActiveStatus(id).subscribe(
          data => {
            if (data.IsSuccess) {
              this.GetList();
              this._alertService.success(data.Message);
            } else {
              this._alertService.error(data.Message);
            }
          },
          error => {
            this._alertService.error(error.message);
          }
        );
      }
    });


  }


  sortData(event) {
    this.indexModel.OrderBy = event.active;
    this.indexModel.OrderByAsc =
      event.direction == AppSetting.orderByDscAsc
        ? AppSetting.orderByAsc
        : AppSetting.orderByDsc;
    this.indexModel.IsPostBack = true;
    this.GetList();
  }

  onPaginateChange(event) {
    this.indexModel.Page = event.pageIndex + 1;
    this.indexModel.PageSize = event.pageSize;
    this.indexModel.IsPostBack = true;
    this.GetList();
  }

  OnDeleteClick(id) {

    const dialogRef = this._dialog.open(ConfirmationDialogComponent, { width: "50%", data: GlobalMessagesModel.ConfirmDeleted });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this._masterTypeService.ChangeDeleteStatus(id).subscribe(
          data => {
            if (data.IsSuccess) {
              this.GetList();
              this._alertService.success(data.Message);
            } else {
              this._alertService.error(data.Message);
            }
          },
          error => {
            this._alertService.error(error.message);
          }
        );
      }
    });


  }

  SearchByKeyword(searchValue: GlobalFilterModel) {
    this.indexModel.Search = searchValue.search;
    this.indexModel.AdvanceSearchModel = { ActiveStatus: searchValue.ActiveStatus }

    this.GetList();
  }

  ClearSearch() {
    this.indexModel = new IndexModel();
    this.GetList();
  }


  showHideFilter() {

    this.ShowFilter = !this.ShowFilter;
  }

  openPageManual(value){
    this._dialog.open(UserManualDialogComponent, {
       width: "90%",
       data: {MasterType: value, CommunicationType: 0}
     });
   }

   PagePrint(printContent) {
    this._commonService.printHTMLContent(printContent, "Lookup Type data");
  }
  //#endregion



}
// function PageManualComponent(PageManualComponent: any, arg1: { width: string; data: { LookupTypeId: any; SchemeTypeCode: number; }; }) {
//   throw new Error('Function not implemented.');
// }

