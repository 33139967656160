import { Component, OnInit, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { GlobalMessagesModel } from "src/app/shared/model/common-messages.model";
import { UserViewModel } from "src/app/shared/model/user.model";
import { UserService } from "src/app/shared/service/user.service";

@Component({
  selector: 'app-admin-contact-dialog',
  templateUrl: './admin-contact-dialog.component.html',
  styleUrls: ['./admin-contact-dialog.component.scss']
})

export class AdminContactDialogComponent implements OnInit {

  officeCode: number = 0;
  approvers: UserViewModel[] = [];

  constructor(
    private readonly _userService: UserService,
    public _dialogRef: MatDialogRef<AdminContactDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    if (data && data.id) {
      this.officeCode = Number(data.id);
    }
    this.getApprovers();
  }

  ngOnInit() { }

  getApprovers() {
    this._userService.GetApproverUsers(this.officeCode).subscribe((data) => {
      if (data && data.IsSuccess) {
        this.approvers = <UserViewModel[]>data.Data;
      }
      else {
        console.error(GlobalMessagesModel.InternalError);
      }
    }, (error) => {
      console.error(error.message);
    });
  }

  closeDialog(): void {
    this._dialogRef.close(true);
  }

}
