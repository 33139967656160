import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { AppComponent } from "src/app/app.component"
import { OfficeModel } from 'src/app/shared/model/master/office.model';
import { OfficeMasterService } from "src/app/shared/service/master/office-master.service";
import { GlobalFilterModel, IndexModel } from 'src/app/shared/model/common.model';
import { GlobalMessagesModel } from 'src/app/shared/model/common-messages.model';
import { AppSetting } from 'src/app/shared/model/appsetting.model';
import { MatTableDataSource, MatDialog, MatSort, MatPaginator } from "@angular/material";
import { ConfirmationDialogComponent } from "src/app/confirmation-dialog/confirmation-dialog.component";
import { AlertService } from 'src/app/shared/service/alert.service';
import { CommonService } from 'src/app/shared/service/common.service';
import { ColumnHeaderModel } from 'src/app/shared/model/commonddl.model';
import { UserTypeEnum } from 'src/app/shared/enum/fixed-values.enum';
import { AuthenticationService } from 'src/app/shared/service/authentication.service';
import { Router } from '@angular/router';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { OfficeMasterFilterModel } from 'src/app/shared/model/master/office-master-filter.model';
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

declare const require: any;
const jsPDF = require('jspdf');
require('jspdf-autotable');

@Component({
  selector: 'app-department-office-report',
  templateUrl: './department-office-report.component.html',
  styleUrls: ['./department-office-report.component.scss']
})
export class DepartmentOfficeReportComponent implements OnInit {
  model: OfficeModel[];
  indexModel: IndexModel;
  dataSource: any;
  totalRecords: number;
  loginBaseUserType: string;
  userTypeEnum = UserTypeEnum;

  loginUserCode: number;

  // displayedColumns: string[] = ["index", "OfficeName", "DistrictName", "DepartmentName", "IsActive", "Action"];
  // columnsToDisplay: string[] = this.displayedColumns.slice();

  displayedColumns: string[] = [];
  columnsToDisplay: string[] = [];


  ViewdisplayedColumns: ColumnHeaderModel[] = [
    { Value: "Department", Text: "Department" },
    { Value: "OfficeName", Text: "Name" },
    { Value: "OfficeNameHindi", Text: "Hindi Name" },
    { Value: "OfficeShortName", Text: "Office Short Name" },
    { Value: "District", Text: "District" },
    { Value: "CreatedByName", Text: "Created By" },
    { Value: "CreatedDate", Text: "Created Date" }
  ];

  SearchdisplayedColumns: ColumnHeaderModel[] = [
    { Value: "OfficeName", Text: "Office Name" },
    { Value: "District", Text: "District" },
    { Value: "Department", Text: "Department" }
  ];

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(private readonly appComponnet: AppComponent,
    private readonly _alertService: AlertService,
    private readonly _officeService: OfficeMasterService,
    private readonly _router: Router,
    private readonly _dialog: MatDialog,
    public readonly _commonService: CommonService,
    private readonly _authService: AuthenticationService
  ) {
    this.loginBaseUserType = this._authService.BaseUserType;
    this.displayedColumns = ["index",
      "Department",
      "OfficeName",
      "OfficeNameHindi",
      "OfficeShortName",
      "District",
      "CreatedByName",
      "CreatedDate",
      "IsActive"];
    this.loginBaseUserType = _authService.BaseUserType;
    this.loginUserCode = _authService.UserCode;
    this.columnsToDisplay = this.displayedColumns.slice();
    this.appComponnet.setPageLayout("Department Office Report :", "", "", "");
    this.indexModel = new IndexModel();
  }

  ngOnInit() {
    if (this.userTypeEnum.ADM != this.loginBaseUserType && this.userTypeEnum.SADM != this.loginBaseUserType && this.userTypeEnum.DREC != this.loginBaseUserType && this.userTypeEnum.DRECADLC != this.loginBaseUserType && this.userTypeEnum.HOD != this.loginBaseUserType) {
      this._router.navigate(["dashboard"]);
    }
    this.getList();
  }

  getList() {
    this._officeService.GetDepartmentOfficeList(this.loginUserCode, this.indexModel).subscribe(
      data => {
        if (data.IsSuccess) {
          this.model = <OfficeModel[]>data.Data.Data;
          this.dataSource = new MatTableDataSource<OfficeModel>(this.model);
          this.totalRecords = data.Data.TotalRecords;
          if (!this.indexModel.IsPostBack) {
            this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.sort;
          }
        } else {
          this._alertService.error(data.Message);
        }
      },
      error => {
        this._alertService.error(error.message);
      }
    );
  }

  updateDeleteStatus(id) {
    const dialogRef = this._dialog.open(ConfirmationDialogComponent, { width: "50%", data: GlobalMessagesModel.ConfirmDeleted });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this._officeService.ChangeDeleteStatus(id).subscribe(
          data => {
            if (data.IsSuccess) {
              this._alertService.success(data.Message);
              this._commonService.ScrollingTop();
              this.getList();
            }
          },
          error => {
            this._alertService.error(error.message);
          }
        );
      }
    });
  }

  updateActiveStatus(id) {
    const dialogRef = this._dialog.open(ConfirmationDialogComponent, { width: "50%", data: GlobalMessagesModel.ConfirmStatusChanged });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this._officeService.ChangeActiveStatus(id).subscribe(
          data => {
            if (data.IsSuccess) {
              this._alertService.success(data.Message);
              this.getList();
            }
          },
          error => {
            this._alertService.error(error.message);
          }
        );
      }
    });
  }

  sortData(event) {
    this.indexModel.OrderBy = event.active;
    this.indexModel.OrderByAsc = event.direction == AppSetting.orderByDscAsc ? AppSetting.orderByAsc : AppSetting.orderByDsc;
    this.indexModel.IsPostBack = true;
    this.getList();
  }

  onPaginateChange(event) {
    this.indexModel.Page = event.pageIndex + 1;
    this.indexModel.PageSize = event.pageSize;
    this.indexModel.IsPostBack = true;
    this.getList();
  }

  SearchByKeyword(searchValue: GlobalFilterModel) {
    this.indexModel.Search = searchValue.search;
    this.indexModel.AdvanceSearchModel = { ActiveStatus: searchValue.ActiveStatus }

    this.getList();
  }

  ClearSearch() {
    this.indexModel = new IndexModel();
    this.getList();
  }


  @ViewChild('pdfTable', { static: false }) pdfTable: ElementRef;
  completeModel: OfficeModel[];
  completeIndexModel: OfficeMasterFilterModel;

  getCompleteData() {
    this.completeIndexModel = new OfficeMasterFilterModel();
    this.completeIndexModel.Page = 1;
    this.completeIndexModel.PageSize = this.totalRecords;
    this.completeIndexModel.IsPostBack = true;
    this.completeIndexModel.Search = this.indexModel.Search;
    this._officeService.GetList(this.completeIndexModel).subscribe(
      (completeData: any) => {
        if (completeData.IsSuccess) {
          this.completeModel = <OfficeModel[]>completeData.Data.Data;
          this.totalRecords = completeData.Data.TotalRecords;
        } else {
          this._alertService.error(completeData.Message);
        }
      },
      error => {
        this._alertService.error(error.message);
      }
    );
  }
  // this._officeService.GetList(this.indexModel).subscribe(

  DownloadPdf() {

    this._officeService.GetOfficeMasterPDFDownload(this.loginUserCode, this.indexModel).subscribe(
      data => {
        if (data.IsSuccess) {
          // const linkSource = data.Data;
          // const downloadLink = document.createElement("a");
          // const fileName = 'Department office Report';

          // downloadLink.href = linkSource;
          // downloadLink.download = fileName;
          // downloadLink.target = "blank";
          // downloadLink.click();
          var w = window.open("about:blank");

          setTimeout(function () {
            //FireFox seems to require a setTimeout for this to work.
            w.document.body.appendChild(w.document.createElement("iframe")).src = data.Data;
            w.document.getElementsByTagName("iframe")[0].style.width = "100%";
            w.document.getElementsByTagName("iframe")[0].style.height = "100%";
          }, 0);
        } else {
          this._alertService.error(data.Message);
        }
      },
      error => {
        this._alertService.error(error.message);
      }
    );

  }


  buttonClickExportAsPDF(): void {
    // this.getCompleteData();
    // setTimeout(() => {
    //   this.download();
    //   this.exportAsPdf();
    // }, 1000);
    this.DownloadPdf();
  }

  buttonClickExportAsXLSX(): void {
    this.getCompleteData();
    setTimeout(() => {
      this.download();
      this.exportAsExcelFile();
    }, 1000);
  }

  rowsNew: any = [];

  download() {
    var i = 1;
    this.rowsNew = [];
    this.completeModel.forEach(element => {
      var temp = {
        index: i, OfficeName: element.OfficeName,
        DistrictName: element.DistrictName, SSOID: element.SSOID,
        DepartmentName: element.DepartmentName,
        IsActive: element.IsActive, ModifiedBy: element.UserName, ModifiedDate: element.ModifiedDate
      };
      i = i + 1;
      this.rowsNew.push(temp);
    });

  }

  public exportAsPdf(): void {
    var doc = new jsPDF('p', 'pt');
    var header = function (data) {
      doc.setFontSize(10);
      doc.setTextColor(40);
      doc.text("Department office", data.settings.margin.left, 50);
      doc.text("Date Time : " + new Date().toLocaleDateString() + " " + new Date().toLocaleTimeString(), data.settings.margin.left, 800);
    };
    var headers = [{  //change
      title: "#",
      dataKey: "index"
    }, {
      title: "OfficeName",
      dataKey: "OfficeName"
    },
    // {
    // title: "VendorType Hindi",
    // dataKey: "DepartmentNameHindi"
    //},
    {
      title: "DistrictName",
      dataKey: "District"
    },
    {
      title: "DepartmentName",
      dataKey: "Department"
    },

    {
      title: "Is Active",
      dataKey: "IsActive"
    },
    {
      title: "Modified By",
      dataKey: "ModifiedBy"
    },
    {
      title: "Modified Date",
      dataKey: "ModifiedDate"
    },

    ];
    doc.autoTable(headers, this.rowsNew, { margin: { top: 70 }, beforePageContent: header });
    doc.save("Department-Office-Report_" + new Date().toLocaleDateString() + "_" + new Date().toLocaleTimeString() + ".pdf"); //change
  }

  //excellll
  public exportAsExcelFile(): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.rowsNew);
    const workbook: XLSX.WorkBook = { Sheets: { 'officeReport': worksheet }, SheetNames: ['officeReport'] }; //change
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFile(excelBuffer, 'Department-Office'); //change
  }
  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], { type: EXCEL_TYPE });
    FileSaver.saveAs(data, fileName + '_Report_' + new Date().toLocaleDateString() + "_" + new Date().toLocaleTimeString() + EXCEL_EXTENSION);
  }
}
