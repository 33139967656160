import { SearchCriteriaProjectFilterModel, SearchCriteriaOfficeFilterModel, SearchCriteriaDistrictFilterModel, SearchCriteriaWorkOrderFilterModel, SearchCriteriaVendorFilterModel, SearchCriteriaUserFilterModel } from './../model/report/search-criteria.model';

import { formatDate } from '@angular/common';
import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { AuthenticationService } from './authentication.service';
import { AppSetting } from '../model/appsetting.model';
import { ColumnHeaderModel } from '../model/commonddl.model';
import * as CryptoJS from 'crypto-js';
import { environment } from 'src/environments/environment';
import { AlertModel, PermissionModel } from '../model/common.model';
import { Dictionary } from '../model/dictionary.model';
import { Router } from '@angular/router';
import { SearchCriteriaDepartmentFilterModel } from '../model/report/search-criteria.model';
import { Subject } from 'rxjs';

declare function printContent(id: any): any;
declare function printPopUpContent(id: any): any;


@Injectable({
  providedIn: 'root'
})

export class CommonService {
  data: number[] = [];
  alertModel: AlertModel;

  isResetGlobalFilter = new Subject<boolean>();
  isUpdateInboxoutbox = new Subject<boolean>();
  public versionDate: string;

  constructor(private readonly _baseService: BaseService,
    private router: Router) {
    this.versionDate = "2024.04.30";
  }

  GetAllDDL(model: any, userTypeCode: any = 0, userCode: any = 0) {

    return this._baseService.get(AppSetting.GetDDlUrl + model + "&userTypeCode=" + (String(userTypeCode).length > 0 ? userTypeCode : 0) + "&userCode=" + (String(userCode).length > 0 ? userCode : 0));
  }

  GetFilterdDDL(model: any, userTypeCode: any = 0, userCode: any = 0) {
    return this._baseService.post(AppSetting.GetFilterdDDlUrl + "?userTypeCode=" + userTypeCode + "&userCode=" + userCode, model);
  }



  GetDDL(key: any, id: any = 0, id2: any = 0, id3: any = 0) {

    var param = new Dictionary<any>();
    param.Add("key", key)
    param.Add("id", id)
    param.Add("id2", id2)
    param.Add("id3", id3)


    return this._baseService.get(AppSetting.GetSingleDDlUrl, param);
  }

  GetDDLMultipleValue(key: any, id: string, id2: any = 0, id3: any = 0) {
    return this._baseService.get(AppSetting.GetMultipleDDlUrl + key + "&id=" + id + "&id2=" + id2);
  }

  public getDepartmentsByAdminDepartment(adminDepartmentCode: number) {
    var param = new Dictionary<any>();
    param.Add("adminDepartmentCode", adminDepartmentCode);

    return this._baseService.get(AppSetting.GetDepartmentsByAdminDepartment, param);
  }

  public getOfficesBy(departmentCode: number, districtCode: number) {
    var param = new Dictionary<any>();
    param.Add("departmentCode", departmentCode);
    param.Add("districtCode", districtCode);

    return this._baseService.get(AppSetting.GetOfficesBy, param);
  }

  GetUserByUserType(userType: string, officeCode: string) {
    return this._baseService.get(
      AppSetting.GetUserByUserTypeUrl + "?userType=" + userType + "&OfficeCode=" + officeCode
    );
  }

  GetUserByUserTypeAndOfficeCode(userType: string, officeCode: number) {
    return this._baseService.get(
      AppSetting.GetUserByUserTypeAndOfficeCode + "?userType=" + userType + "&officeCode=" + officeCode
    );
  }


  GetUserManualDocument(module: number) {
    return this._baseService.get(AppSetting.GetManualDocUrl + module);
  }

  SaveLoginUserLog(isLogin = false) {

    var param = new Dictionary<any>();
    param.Add("IsLogin", isLogin)
    return this._baseService.get(AppSetting.SaveLoginUserLog, param);
  }

  ScrollingTop() {
    let scrollToTop = window.setInterval(() => {
      let pos = window.pageYOffset;
      if (pos > 0) {
        window.scrollTo(0, pos - 20); // how far to scroll on each step
      } else {
        window.clearInterval(scrollToTop);
      }
    }, 16);
  }

  ShowData(data: string) {
    var temp = data.split(',');
    for (var i = 0; i < temp.length; i++) {
      this.data[i] = parseInt(temp[i]);
    }
    return this.data;
  }

  IsNullOrEmpty(strValue: any) {
    if (strValue != null && strValue != undefined && strValue != "null" && strValue != "undefined" && String(strValue).replace(/^\s+|\s+$/gm, '') !== "") {
      return false;
    }
    return true;
  }

  NumberOnly(event, isCommaOrDash: boolean = false): boolean {

    const charCode = event.which ? event.which : event.keyCode;
    if (isCommaOrDash) {
      if (charCode == 44 || charCode == 45) {
        return true;
      }
    }
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  checkDecimalNumberOnly(event, txt): boolean {

    var charCode = (event.which) ? event.which : event.keyCode;
    if (charCode == 46) {
      //Check if the text already contains the . character
      if (txt.indexOf('.') === -1) {
        return true;
      } else {
        return false;
      }
    }
    else {
      if (txt.split('.').length > 1 && txt.split('.')[1].length > 1) {
        return false;
      }
      else if (charCode > 31 && (charCode < 48 || charCode > 57))
        return false;
    }
    return true;
  }

  AlphaNumericOnly(e) {
    var keyCode = e.keyCode || e.which;
    var regex = /^[A-Za-z0-9]+$/;
    var isValid = regex.test(String.fromCharCode(keyCode));
    if (!isValid) {
      //alert("Only Alphabets and Numbers are allowed.");
    }
    return isValid;
  }

  AlphabetOnly(e) {
    var keyCode = e.keyCode || e.which;
    var regex = /^[a-zA-Z& ]*$/;;
    var isValid = regex.test(String.fromCharCode(keyCode));
    if (!isValid) {
      //alert("Only Alphabets and Numbers are allowed.");
    }
    return isValid;
  }

  downloadPdf(url, name) {
    const linkSource = url;
    const downloadLink = document.createElement("a");
    const fileName = name;

    downloadLink.href = AppSetting.BaseApiUrl + linkSource;
    downloadLink.download = fileName;
    downloadLink.target = "blank";
    downloadLink.click();
  }
  downloadPdfFromFullUrl(url, name) {

    const linkSource = url;
    const downloadLink = document.createElement("a");
    const fileName = name;

    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.target = "blank";
    downloadLink.click();
  }

  GetProjectByDepartment(code: string) {
    return this._baseService.get(AppSetting.GetProjectByDepartment + code);
  }

  GetUserByDepartment(code: number) {
    return this._baseService.get(AppSetting.GetUserByDepartmentUrl + code);
  }

  convertText(plaintext: string, isencrypt = true) {
    if (isencrypt) {
      return CryptoJS.AES.encrypt(plaintext.trim(), environment.encPassword.trim()).toString();
    }
    else {
      return CryptoJS.AES.decrypt(plaintext.trim(), environment.encPassword.trim()).toString(CryptoJS.enc.Utf8);

    }
  }

  transform(value: string): string {
    if (value.length > 10 && value != null) {
      return 'XXXX-XXXX-' + value.substring(value.length - 4);
    }
    else if (value.length === 10 && value != null)
      return 'XXX-XXX-' + value.substring(value.length - 4);
  }

  public GetMonthYear(addMonth: number) {
    var date = new Date();
    var month = ((date.getMonth() + addMonth) % 12);
    var monthCodes: string[] = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    return monthCodes[month] + ' ' + date.getFullYear();
  }

  ObjectToJsonByKeyValuePair(key, value): string {
    if (key && value) {
      if (!this.IsNullOrEmpty(key.trim()) && !this.IsNullOrEmpty(value.trim())) {
        const objJson = {};
        objJson[key.trim()] = value.trim();
        return JSON.stringify(objJson);
      }
    }
  }

  ObjectToJson(keys: ColumnHeaderModel[], value): string {
    if (keys.length > 0 && !this.IsNullOrEmpty(value)) {
      const objJson = {};
      keys.forEach(element => {
        objJson[element.Value] = value;
      });
      return JSON.stringify(objJson);
    }
  }

  printHTMLContent(id: string, printHeader = "") {
    //printContent(id);
    var divToPrint = document.getElementById(id).innerHTML;
    sessionStorage.setItem("PrintContentPageTitle", printHeader);
    sessionStorage.setItem("PrintContent", divToPrint);

    const url = window.location.href.replace(this.router.url, '/print');
    window.open(url, '_blank');
  }

  printHTMLPopContent(id: string) {
    printPopUpContent(id);
  }

  GetAllSSOIDByUserType(userType: number, officeCode: number, ssoid: string) {
    var param = new Dictionary<any>();
    param.Add("userType", userType);
    param.Add("officeCode", officeCode);
    param.Add("ssoid", ssoid);
    return this._baseService.get(AppSetting.GetFilterSSOIDsUrl, param);
  }

  GetAllSSOIDByDepartment_UserType(departmentCode: number) {
    return this._baseService.get(AppSetting.GetAllSSOIDByDepartmentUserTypeUrl + "?DepartmentCode=" + departmentCode);
  }

  alert(message: string, type: string) {
    this.alertModel = new AlertModel();
    this.alertModel.Message = message;
    this.alertModel.Type = type;
    setTimeout(() => {
      this.alertModel = new AlertModel();
    }, 5000);
  }

  GetOfficeList(distCode: any = "", dptCode: any = "") {
    var param = new Dictionary<any>();
    param.Add("dptCode", dptCode);
    param.Add("distCode", distCode);
    return this._baseService.get(AppSetting.GetOfficeList, param);
  }

  GetDistrictByOffice(code: number) {
    return this._baseService.get(AppSetting.GetDistrictByOfficeUrl + code);
  }

  compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  GetPageMasterByPageTypeCode(pageTypeCode: number, applicationCode: string) {
    return this._baseService.get(AppSetting.PageMasterByPageTypeCodeUrl + pageTypeCode + '&applicationCode=' + applicationCode);
  }

  ConvertMbintoByte(data) {
    return data * 1024 * 1024;
  }

  ConvertByteToMB(data) {
    return data / 1024;
  }


  IsValidPhoneNumber(event): boolean {    // Format: 0141-256235
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode == 45) {   // Allow hyphen (-)
      return true;
    }
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {    // Check numbers
      return false;
    }
    return true;
  }

  getFormatDate(dateValue: any, format: string = AppSetting.DateFormat): string {
    return formatDate(dateValue, format, "en-US");
  }

  modelSetGet(model: any, isSet: boolean = false, listName: string = this.router.url) {

    if (isSet) {
      sessionStorage.setItem("postBackSearch_" + listName, JSON.stringify(model));
      return model;
    } else {
      if (sessionStorage.getItem("postBackSearch_" + listName) != undefined) {

        return JSON.parse(sessionStorage.getItem("postBackSearch_" + listName));
      } else {
        sessionStorage.setItem("postBackSearch_" + listName, JSON.stringify(model));
        return model;
      }
    }
  }

  GetDepartmentDistrictList(code: number) {
    return this._baseService.get(AppSetting.GetDepartmentDistrictListUrl + code);
  }
  GetKPIByDepartmentCode(code: number) {
    return this._baseService.get(AppSetting.KPIByDepartmentCodeUrl + code);
  }

  GetFilterUserListForAdditionalPermission(userTypeCode: number, deptCodes: string, distCodes: string, officeCodes: string) {
    var param = new Dictionary<any>();
    param.Add("userTypeCode", userTypeCode);
    param.Add("deptCodes", deptCodes);
    param.Add("distCodes", distCodes);
    param.Add("officeCodes", officeCodes);
    return this._baseService.get(AppSetting.GetUserListForAdditionalPermissionUrl, param);
  }

  //#region <Search Criteria DDL service>

  GetSearchCriteriaDepartmentList(model: SearchCriteriaDepartmentFilterModel) {
    return this._baseService.post(AppSetting.SearchCriteriaDepartmentListUrl, model);
  }

  GetSearchCriteriaProjectList(model: SearchCriteriaProjectFilterModel) {
    return this._baseService.post(AppSetting.SearchCriteriaProjectListUrl, model);
  }

  GetSearchCriteriaOfficeList(model: SearchCriteriaOfficeFilterModel) {
    return this._baseService.post(AppSetting.SearchCriteriaOfficeListUrl, model);
  }

  GetSearchCriteriaDistrictList(model: SearchCriteriaDistrictFilterModel) {
    return this._baseService.post(AppSetting.SearchCriteriaDistrictListUrl, model);
  }

  GetSearchCriteriaWorkOrderList(model: SearchCriteriaWorkOrderFilterModel) {
    return this._baseService.post(AppSetting.SearchCriteriaWorkOrderListUrl, model);
  }

  GetSearchCriteriaVendorList(model: SearchCriteriaVendorFilterModel) {
    return this._baseService.post(AppSetting.SearchCriteriaVendorListUrl, model);
  }

  GetSearchCriteriaUserList(model: SearchCriteriaUserFilterModel) {
    return this._baseService.post(AppSetting.SearchCriteriaUserListUrl, model);
  }

  GetSearchCriteriaProjectOICList(codes: string) {
    return this._baseService.get(AppSetting.SearchCriteriaProjectOICListUrl + codes);
  }

  GetSearchCriteriaCommunicationTypeList(userType: string) {
    return this._baseService.get(AppSetting.SearchCriteriaCommunicationTypeListUrl + userType);
  }
  //#endregion

  //#region For Pagging
  getpageSizeOptions(totalRecords) {
    let pageSizes = [10, 20, 50, 100, totalRecords];
    pageSizes = [totalRecords];
    if (totalRecords > 10)
      pageSizes = [10, totalRecords];
    if (totalRecords > 20)
      pageSizes = [10, 20, totalRecords];
    if (totalRecords > 50)
      pageSizes = [10, 20, 50, totalRecords];
    if (totalRecords > 100)
      pageSizes = [10, 20, 50, 100, totalRecords];
    return pageSizes;
  }
  getMinpageSizeOptions(totalRecords): boolean {
    let pageSize = this.GetLocalStorage('PageSize') != '' ? Number(this.GetLocalStorage('PageSize')) > 0 ? Number(this.GetLocalStorage('PageSize')) : 10 : 10;
    if (totalRecords > pageSize)
      return true;
    else
      return false;
  }
  numberOnly(event, isCommaOrDash: boolean = false): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (isCommaOrDash) {
      if (charCode == 44 || charCode == 45) {
        return true;
      }
    }
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
  //#endregion


  //#region  <Encrypted storage >
  SetLocalStorage(key: string, Value: string) {
    localStorage.setItem(key, this.convertText(Value, true));
  }

  GetLocalStorage(key: string) {
    let decValue = localStorage.getItem(key)
    return decValue ? this.convertText(decValue, false) : undefined;

  }

  RemoveLocalStorage(key: string) {

    localStorage.removeItem(key);
  }

  SetSessionStorage(key: string, Value: string) {
    sessionStorage.setItem(key, this.convertText(Value, true));
  }

  GetSessionStorage(key: string) {
    let decValue = sessionStorage.getItem(key)
    return decValue ? this.convertText(decValue, false) : undefined;
  }

  RemoveSessionStorage(key: string) {

    sessionStorage.removeItem(key);
  }

  getMaxFileSizeInMB(size_50x: number = 1) {
    let fileSizeInMB = localStorage.getItem("FileValidation");
    if (fileSizeInMB) {
      return Number(fileSizeInMB) * size_50x;
    }
    else {
      return 5;   // set 5 MB
    }
  }
  //#endregion
}
