import { MatDialog } from '@angular/material';
import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from "@angular/core";
import { AddUpdatePreferenceComponent } from "src/app/content/B2G/preference-list/add-update-preference/add-update-preference.component";
import { AppSetting } from "src/app/shared/model/appsetting.model";
import { CCSearchViewModel } from "src/app/shared/model/B2G/preference-list";
import { GlobalMessagesModel } from "src/app/shared/model/common-messages.model";
import { IndexModel } from "src/app/shared/model/common.model";
import { DDLModel, DdlItemModel } from "src/app/shared/model/commonddl.model";
import { CommunicationCCViewModel } from "src/app/shared/model/communication-transaction.model";
import { OfficeMasterViewModel } from "src/app/shared/model/master/office-master.model";
import { UserViewModel } from "src/app/shared/model/user.model";
import { AlertService } from "src/app/shared/service/alert.service";
import { AuthenticationService } from "src/app/shared/service/authentication.service";
import { PreferenceService } from "src/app/shared/service/B2G/preference.service";
import { CommonService } from "src/app/shared/service/common.service";
import { CommunicationService } from "src/app/shared/service/communication.service";
import { UserService } from "src/app/shared/service/user.service";
@Component({
  selector: 'app-add-ccfor-communication',
  templateUrl: './add-ccfor-communication.component.html',
  styleUrls: ['./add-ccfor-communication.component.scss'],
})
export class AddCCForCommunicationComponent implements OnInit {

  @Input() communicationCode: number = 0;
  @Output() onCCOffice = new EventEmitter<string[]>();
  @Input() communicationOfficeList: CommunicationCCViewModel[] = [];
  model: OfficeMasterViewModel[];
  ddlList = new DDLModel();
  Permission = this._authService.GetPagePermission("/preferencelist", "preferencelist/create", "preferencelist/detail", "preferencelist/update", "preferencelist/delete");

  searchModel: CCSearchViewModel = new CCSearchViewModel();
  isShow = true;
  loginData: UserViewModel;

  constructor(
    private readonly _alertService: AlertService,
    private readonly _preferenceService: PreferenceService,
    private readonly _userService: UserService,
    private readonly _authService: AuthenticationService,
    public readonly _commonService: CommonService,
    public readonly _communication: CommunicationService,
    private readonly _dialog: MatDialog,


  ) {
    this.loginData = this._authService.GetCurrentUserDetail().UserViewModel;
  }
  ngOnInit() {
    this.getList();
    this.GetDepartment();

  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.communicationOfficeList.previousValue != changes.communicationOfficeList.currentValue) {
      this.bindCommunicationCCOfficeList();
    }
  }

  submitOfficeList() {
    if (this.model) {
      let officelist = this.model.filter(x => x.IsSelected).map(x => x.OfficeCode.toString());

      this.onCCOffice.emit(officelist);
    } else {
      this.onCCOffice.emit([]);

    }

  }
  getList() {

    if (this.searchModel.DepartmentCode > 0 || this.searchModel.DistrictCode > 0 || this.searchModel.IsPrefered) {
      this.searchModel.UserOfficeCode = this.loginData.OfficeCodes;
      this._preferenceService.PreferedOfficeList(this.searchModel).subscribe(
        (data) => {
          if (data.IsSuccess) {

            this.model = <OfficeMasterViewModel[]>data.Data;
            this.bindCommunicationCCOfficeList();

          } else {
            this._alertService.error(GlobalMessagesModel.InternalError);
          }
        },
        (error) => {
          this._alertService.error(error.message);
        }
      );
    }
    else {
      this._alertService.error("Please Select " + (this.searchModel.DepartmentCode == 0 && this.searchModel.DistrictCode == 0 ? 'Department or District' : (this.searchModel.DepartmentCode == 0 ? 'Department' : 'District')) + ' field...!')
    }

  }

  GetDepartment() {

    this._commonService.GetDDL(AppSetting.ddlAllOfficeDepartment).subscribe(
      data => {

        if (data.IsSuccess) {
          var ddl = <DDLModel>data.Data;
          this.ddlList.ddlAllOfficeDepartment = <DdlItemModel[]>ddl.ddlAllOfficeDepartment;
        }
      },
      error => {
        this._alertService.error(error.message);
      }
    );


  }

  Reset() {
    this.searchModel.indexModel = new IndexModel();
    this.searchModel = new CCSearchViewModel();
    this.getList();
  }
  GetDistrictByDepartment() {
    if (this.searchModel.DepartmentCode) {
      this._commonService.GetDDL(AppSetting.ddlDistrict, this.searchModel.DepartmentCode).subscribe(
        data => {
          if (data.IsSuccess) {
            var ddl = <DDLModel>data.Data;
            this.ddlList.ddlDistrict = ddl.ddlDistrict;

            if (this.ddlList.ddlDistrict.findIndex(x => x.Value == String(this.searchModel.DistrictCode)) == -1) {
              this.searchModel.DistrictCode = 0;
            }

          }
        },
        error => {
          this._alertService.error(error.message);
        }
      );
    }
    else {
      this.ddlList.ddlDistrict = [];
      this.searchModel.DistrictCode = 0;
    }
  }

  toggleDisplay() {
    this.isShow = !this.isShow;
  }

  bindCommunicationCCOfficeList() {
    if (this.model.length > 0 && this.communicationOfficeList) {
      this.model.forEach(obj => {
        if (this.communicationOfficeList.filter(x => x.OfficeCode == obj.OfficeCode).length > 0) {
          obj.IsSelected = true;
        }
      });
    }

  }


  OpenAddUpdatePreferDialog() {
    
    const _dialogRef = this._dialog.open(AddUpdatePreferenceComponent, {
      width: "50%",    
      disableClose: true
    });
    _dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        this.getList();
      }
    });
  }

}
