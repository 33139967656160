export enum DefaultFieldEnum {
	Department = '1',
	District = '2',
	Office = '3',
	Project = '4',
	WorkOrder = '5',
	VendorBank = '6',
	Attachments = '7',
	Vendor = '8',
	Subject = '9',
	Description = '10',
	Amount = '11',
	PreviouseCommunication = '12',
	CreatedByOffice = '13'
}

export enum DefaultField {


}
