export class BankAccountModel {
    BankAccountCode: number;
    VendorCode: number | null;
    BankHolderName: string;
    BankAccountNo: string;
    IsPrimary: boolean | string;
    IsKnowIFSCCode:boolean|string;

    BankBranchCode: number | string;
    BranchName: string;
    BranchNameHindi: string;
    IFSCCode: string;
    MICR: string;
    BankBranchAddress: string;
    BankBranchAddressHindi: string;
    BankBranchStateCode: number | string;
    BankBranchStateName: string;
    BankBranchStateNameHindi: string;

    BankCode: number | string;
    BankName: string;
    BankNameHindi: string;
    BankShortName: string;
    BankAccountMinLength: number | string;
    BankAccountMaxLength: number | string;
    BankTypeCode: number | string;
    BankType: string;

    IsActive: boolean;
    IsDeleted: boolean;
    CreatedBy: number | null;
    CreatedOn: Date | null;
    ModifiedBy: number | null;
    ModifiedOn: Date | null;
    CancelChequeFileURL: string;
    CancelChequeBase64: string;
    UploadFile: string
}

export class BankBranchViewModel {
    BankBranchCode: number;
    BankCode: number;
    BranchName: string;
    BranchNameHindi: string;
    IFSCCode: string;
    MICR: string;
    Address: string;
    AddressHindi: string;
    BankName: string;
}