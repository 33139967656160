import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { B2GUserManualViewModel, PageManualModel } from 'src/app/shared/model/master/b2-guser-manual-model';
import { AlertService } from 'src/app/shared/service/alert.service';
import { CommonService } from 'src/app/shared/service/common.service';
import { B2GUserManualService } from 'src/app/shared/service/master/b2-guser-manual.service';

@Component({
  selector: 'app-user-manual-dialog',
  templateUrl: './user-manual-dialog.component.html',
  styleUrls: ['./user-manual-dialog.component.scss']
})
export class UserManualDialogComponent implements OnInit {
  //#region <Variables>

  listModel: B2GUserManualViewModel[];
  isReturn = false;
  isRedirect = false;
  PDFFile: SafeResourceUrl;
  //#endregion <Variables>


  //#region <Constructor>

  constructor(
    private readonly _b2gUserManualMasterService: B2GUserManualService,
    private readonly _alertService: AlertService,
    private _commonService: CommonService,
    public readonly _dialogRef: MatDialogRef<B2GUserManualViewModel>,
    private sanitized: DomSanitizer,

    @Inject(MAT_DIALOG_DATA) public data: any
  ) {

    if (data) {

      if (data.isReturn) {
        this.isReturn = data.isReturn;
      }

      if (data.isMedia) {
        this.isRedirect = data.isMedia;
      }

      if (data.CommunicationType) {
        const manualModel = new PageManualModel("", 0, data.CommunicationType);
        this.GetList(manualModel);
      } else if (data.MasterType) {
        const manualModel = new PageManualModel("", data.MasterType, 0);
        this.GetList(manualModel);
      }
      else if (data.PageCode) {
        const manualModel = new PageManualModel("", 0, 0, data.PageCode);
        this.GetList(manualModel);
      }
      else {
        this.GetList();
      }

    } else {
      this.GetList();
    }
  }

  //#endregion <Constructor>

  //#region <Methods>

  ngOnInit() {
    //this.GetList();
  }

  transform(value) {
    // 
    return this.sanitized.bypassSecurityTrustHtml(value);
  }

  GetList(usermanualModel = null) {
    debugger
    const manuName = sessionStorage.getItem("MenuName");
    const manualModel = new PageManualModel(manuName);
    //if (manuName) {

    this._b2gUserManualMasterService.GetPageListByMenuName(usermanualModel ? usermanualModel : manualModel).subscribe(
      data => {
        if (data.IsSuccess) {
          this.listModel = <B2GUserManualViewModel[]>data.Data;

          if (this.listModel[0].IsShowPDF) {
            this.PDFFile = this.sanitized.bypassSecurityTrustResourceUrl(this.listModel[0].PDFURL);
            console.log(this.PDFFile)
          }

          if (this.isRedirect) {

            let urlLinks = this.listModel.filter(item => item.URL != undefined);
            if (urlLinks.length > 0) {
              window.open(urlLinks[0].URL, "_blank")
              this.onNoClick();

            } else {
              this.onNoClick();
            }

          }
          if (this.isReturn) {


            if (this.listModel.length > 0) {
              this._dialogRef.close(true);
            } else {
              this._dialogRef.close(false);
            }
          }
        }
      },
      error => {
        this._alertService.error(error.message);
      }
    );
    //}
  }

  onNoClick(): void {
    this._dialogRef.close();
  }

  downloadPdf(Url) {
    var w = window.open("about:blank");

    setTimeout(function () {
      //FireFox seems to require a setTimeout for this to work.
      w.document.body.appendChild(w.document.createElement("iframe")).src = Url;
      w.document.getElementsByTagName("iframe")[0].style.width = "100%";
      w.document.getElementsByTagName("iframe")[0].style.height = "100%";
    }, 0);
  }
  CallPrint(printContent) {

    this._commonService.printHTMLPopContent(printContent);
  }
  //#endregion <Methods>
}
