import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Router } from '@angular/router';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { AppSetting } from 'src/app/shared/model/appsetting.model';
import { DdlItemModel, DDLModel, DocumentUrlModel } from 'src/app/shared/model/commonddl.model';
import { ComplaintActionModel } from 'src/app/shared/model/compliant.model';
import { AlertService } from 'src/app/shared/service/alert.service';
import { CommonService } from 'src/app/shared/service/common.service';
import { ComplaintService } from 'src/app/shared/service/complaint.service';

@Component({
  selector: 'app-compliant-action-dialog',
  templateUrl: './compliant-action-dialog.component.html',
  styleUrls: ['./compliant-action-dialog.component.scss']
})
export class CompliantActionDialogComponent implements OnInit {

  //#region   Variable
  frmGrp: FormGroup;
  dDLList: DDLModel;
  // Status = new FormControl('', [Validators.required]);
  // Comment = new FormControl('', [Validators.required]);
  // Attachment = new FormControl('', null);
  model: ComplaintActionModel;
  fileValidationMsg: string;
  documentUrlList: DocumentUrlModel[] = [];
  editorConfig = AppSetting.editorConfig as AngularEditorConfig;
  completebyDeveloperStatusId: number = 0;
  //#endregion

  constructor(private readonly fb: FormBuilder,
    public _dialogRef: MatDialogRef<CompliantActionDialogComponent>,
    private readonly _alertService: AlertService,
    public readonly _commonService: CommonService,
    private readonly _router: Router,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _complaintService: ComplaintService) {
    this.model = new ComplaintActionModel();
    if (data) {
      this.model.ComplaintEntryId = data;
    }
  }

  ngOnInit() {
    this.formGroupInit()
    this.GetDDLList();
  }
  formGroupInit() {

    this.frmGrp = this.fb.group({
      Status: [null, Validators.required],
      Comment: [null, Validators.required],
      Attachment: [null, null],

    });
  }
  GetDDLList() {
    this._commonService.GetAllDDL(AppSetting.GenerateComplaintDDLKey).subscribe(
      data => {
        if (data.IsSuccess) {
          this.dDLList = <DDLModel>data.Data;
          if (this.dDLList) {
            let statusddl = this.dDLList.ddlCompliantAction as DdlItemModel[];
            let id = (statusddl.find(x => x.Text == 'Complete by Developer')).Value;
            this.completebyDeveloperStatusId = id ? Number(id) : 0;
          }
        }
      },
      error => {
        this._alertService.error(error.message);
      }
    );
  }


  onNoClick(): void {
    this._dialogRef.close();
  }

  Saveclick() {

    // this.Status.markAsTouched();
    // this.Comment.markAsTouched();
    // this.Attachment.markAsTouched();
    // if (this.Status.valid && this.Comment.valid && this.Attachment.valid) {
    this.frmGrp.markAllAsTouched();
    if (this.frmGrp.valid) {
      this._complaintService.AddAction(this.model).subscribe(
        data => {
          if (data.IsSuccess) {

            this._alertService.success(data.Message);
            this._dialogRef.close(true);
          } else {

            this._commonService.ScrollingTop();
            this._alertService.error(data.Message);
          }
        },
        // tslint:disable-next-line: no-shadowed-variable
        error => {
          this._commonService.ScrollingTop();
          this._alertService.error(error.error.ExceptionMessage);
        }
      );
    }
  }

  handleFileInput(files) {

    if (files.item(0).type.match('application/pdf|image/jpeg|image/png')) {
      if (files.item(0).size < AppSetting.FileSizeLimit.DEFAULT) {
        const reader = new FileReader();
        reader.onload = (event: any) => {  
          this.model.AttachmentURL = event.target.result;
          this.model.Extension = (files.item(0).name.split('.'))[1];
        };
        reader.readAsDataURL(files.item(0));
      }
    } else {
      this.fileValidationMsg = "only pdf/Image file accepted  ";
    }
  }



  download(URL) {

    const linkSource = URL;
    const downloadLink = document.createElement("a");
    const fileName = name;

    downloadLink.href = linkSource;
    downloadLink.download = linkSource;//fileName;
    downloadLink.target = "blank";
    downloadLink.click();

  }


  RemoveImageFile() {

    this.model.AttachmentURL = null;
    this.model.Extension = null;
    // this.Imeges.slice(i, 1);
  }

  AddRequireValidation() {
    const attachmentControl = this.frmGrp.get("Attachment");

    if (this.model.StatusId == this.completebyDeveloperStatusId) {
      attachmentControl.setValidators([Validators.required]);
    }
    else { attachmentControl.setValidators(null); }
    attachmentControl.updateValueAndValidity();
  }

}
