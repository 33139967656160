import { Subject } from 'rxjs';
import { Component, OnInit, Inject, Optional, Input, Output, EventEmitter } from '@angular/core';
import { UserService } from "../shared/service/user.service";
import { CommonService } from "../shared/service/common.service";
import { AuthenticationService } from "../shared/service/authentication.service";
import { DdlItemModel, DDLModel } from "../shared/model/commonddl.model";
import { AlertService } from "../shared/service/alert.service";
import { isNullOrUndefined } from "util";
import { GlobalFilterSearchModel } from "../shared/model/Global-communication-search.model";
import { UserTypeService } from "../shared/service/user-type.service";
import { UserTypeModel } from "../shared/model/user-type.model";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { AppSetting } from "../shared/model/appsetting.model";
import { VendorService } from "../shared/service/vendor.service";
import { CommunicationService } from '../shared/service/communication.service';
import { Router } from '@angular/router';

@Component({
  selector: "app-global-communication-search",
  templateUrl: "./global-communication-search.component.html",
  styleUrls: ["./global-communication-search.component.scss"]

})
export class GlobalCommunicationSearchComponent implements OnInit {
  //#region Variable's
  loginUserCode: number = 0;
  loginBaseUserType: string;
  searchModel = this._commonService.modelSetGet(new GlobalFilterSearchModel(), false, "communicationGlobalFilter");
  ddlList = new DDLModel();
  ddlAdminDepartment: DdlItemModel[] = [];
  ddlUserType: DdlItemModel[] = [];
  userTypeList: UserTypeModel[] = [];
  ddlWorkOrder: DdlItemModel[] = [];
  ddlDistrict: DdlItemModel[] = [];
  @Input() IsShowCommunicationType: boolean = true;
  @Input() isShowHeader: boolean = true;
  @Input() IsShowSearchButton: boolean = false;
  @Output() onSearch = new EventEmitter<GlobalFilterSearchModel>();
  fromDate: Date | string;
  toDate: Date | string;
  @Input() isShowinDialog = true;
  //#endregion

  constructor(
    @Optional() public _dialogRef: MatDialogRef<GlobalCommunicationSearchComponent>,
    // public _dialogRef: MatDialogRef<GlobalCommunicationSearchComponent>,
    private readonly _userService: UserService,
    public readonly _commonService: CommonService,
    private readonly _authService: AuthenticationService,
    private readonly _alertService: AlertService,
    private readonly _communicationService: CommunicationService,
    private readonly _router: Router,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.loginUserCode = this._authService.LoggedInUserDetail.UserViewModel.UserCode;
    this.loginBaseUserType = this._authService.LoggedInUserDetail.UserViewModel.BaseUserType;
    // this.searchModel = new GlobalFilterSearchModel();
    if (data.IsShowCommunicationType != null) {
      this.IsShowCommunicationType = data.IsShowCommunicationType;
    }
    if (data.CommunicationType != null) {
      this.searchModel.conn = data.CommunicationType
    }
    if (data.IsShowSearchButton != null) {
      this.IsShowSearchButton = data.IsShowSearchButton;
    }


  }

  //#region Method

  ngOnInit() {
    this.getAdminDepartment();
    //this.GetDepartment();
    this.GetDistrict();
    this.GetDDLList();
    if (this._commonService.GetLocalStorage("GlobalCommunicationFilterSearch")) {
      this.searchModel = JSON.parse(this._commonService.GetLocalStorage("GlobalCommunicationFilterSearch"));
      if (this.searchModel.AdminDepartmentCode) {
        this.GetDepartment();
      }
      if (this.searchModel.DepartmentCode) {
        this.GetProjectListByDepartmentWise();
        this.getOfficeList();
      }
    }
    else {

    }
  }

  GetWorkOrderByproject() {
    if (
      !isNullOrUndefined(
        this.searchModel.ProjectCode && this.searchModel.ProjectCode.length > 0
      )
    ) {
      this._commonService
        .GetDDLMultipleValue(
          AppSetting.ddlWorkOrder,
          this.searchModel.ProjectCode.toString(),
          this.loginUserCode
        )
        .subscribe(
          (data) => {
            if (data.IsSuccess) {
              var ddl = <DDLModel>data.Data;
              this.ddlList.ddlWorkOrder = ddl.ddlWorkOrder;
            }
          },
          (error) => {
            this._alertService.error(error.message);
          }
        );
    } else {
      this.ddlList.ddlWorkOrder = null;
    }
  }

  getAdminDepartment() {
    this._userService.GetUserAdminDepartmentList().subscribe(
      (data) => {
        if (data.IsSuccess) {
          this.ddlAdminDepartment = <DdlItemModel[]>data.Data;
        }
      },
      (error) => {
        this._alertService.error(error.message);
      }
    );
  }

  GetProjectListByDepartmentWise() {
    if (
      !isNullOrUndefined(this.searchModel.DepartmentCode) &&
      this.searchModel.DepartmentCode.length > 0
    ) {
      this._commonService
        .GetDDLMultipleValue(
          AppSetting.ddlProject,
          this.searchModel.DepartmentCode.toString(),
          this.loginUserCode
        )
        .subscribe(
          (data) => {
            if (data.IsSuccess) {
              var ddl = <DDLModel>data.Data;
              this.ddlList.ddlProject = ddl.ddlProject;
            }
          },
          (error) => {
            this._alertService.error(error.message);
          }
        );
    }
  }

  GetDDLList() {
    this._commonService.GetAllDDL(AppSetting.GlobalFilterKey).subscribe(
      (data) => {
        if (data.IsSuccess) {
          this.ddlList = <DDLModel>data.Data;
        }
      },
      (error) => {
        this._alertService.error(error.message);
      }
    );
  }

  GetDepartment() {
    this._userService.loginUserCode = this.loginUserCode; //todo for assign admin department code
    this._userService
      .GetUserDepartmentList(this.searchModel.AdminDepartmentCode)
      .subscribe(
        (data) => {
          if (data.IsSuccess) {
            this.ddlList.ddlOfficeDepartment = <DdlItemModel[]>data.Data;
          }
        },
        (error) => {
          this._alertService.error(error.message);
        }
      );
  }

  GetDistrict() {
    this._userService.loginUserCode = this.loginUserCode;
    this._userService.GetUserDistrictList(20).subscribe(
      (data) => {
        if (data.IsSuccess) {
          this.ddlDistrict = <DdlItemModel[]>data.Data;
        }
      },
      (error) => {
        this._alertService.error(error.message);
      }
    );
  }

  getOfficeList() {
    let deptCodes = this.searchModel.DepartmentCode ? this.searchModel.DepartmentCode.toString() : "0";
    let distCodes = this.searchModel.DistrictCode ? this.searchModel.DistrictCode.toString() : "0";
    this._userService.GetUserOfficeList(deptCodes, distCodes).subscribe((data) => {
      if (data.IsSuccess) {
        this.ddlList.ddlOffice = <DdlItemModel[]>data.Data;
      }
    }, (error) => {
      this._alertService.error(error.message);
    });
  }

  CloseDialog(): void {
    this._dialogRef.close();
  }

  SaveData() {

    this._commonService.SetLocalStorage("GlobalCommunicationFilterSearch", JSON.stringify(this.searchModel));
    this._commonService.SetLocalStorage("IsGlobalSearch", "1");
    this._dialogRef.close(this.searchModel);
    this._commonService.isResetGlobalFilter.next(true);
  }

  SubmitAndSearchData() {

    if (!this.isShowinDialog) {
      this.onSearch.emit(this.searchModel);
    }
    else {
      this._commonService.SetLocalStorage("GlobalCommunicationFilterSearch", JSON.stringify(this.searchModel));
      this._commonService.SetLocalStorage("IsGlobalSearch", "1");
      this._dialogRef.close(this.searchModel);
    }


  }

  clearSearch() {

    if (!this.isShowinDialog) {
      this.searchModel = this._commonService.modelSetGet(new GlobalFilterSearchModel(), true);
      this.onSearch.emit(this.searchModel);
    }
    else {

      this.searchModel = this._commonService.modelSetGet(
        new GlobalFilterSearchModel(), true, "communicationGlobalFilter");
      this._commonService.SetLocalStorage("IsGlobalSearch", "0");
      this._commonService.SetLocalStorage("GlobalCommunicationFilterSearch", JSON.stringify(this.searchModel)
      );
      this._dialogRef.close(this.searchModel);
      this._commonService.isResetGlobalFilter.next(true);
    }
  }
  //#endregion
}
