import { Injectable } from '@angular/core';
import { AppSetting } from '../model/appsetting.model';
import { IndexModel } from '../model/common.model';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root'
})
export class ContactusService {
  

  constructor(private readonly _baseService: BaseService) { }

  GetList(model: IndexModel) {
    return this._baseService.post(AppSetting.ContactUsListUrl, model);
  }

  Detail(id: number) {
    return this._baseService.get(AppSetting.ContactUsDetailUrl + id);
  }
}
