

import { Component, OnInit, ViewChild } from "@angular/core";
import { MatPaginator, MatSort, MatDialog, MatTableDataSource } from "@angular/material";
import { AppComponent } from "src/app/app.component";
import { ConfirmationDialogComponent } from "src/app/confirmation-dialog/confirmation-dialog.component";
import { StateEnum, UserTypeEnum } from "src/app/shared/enum/fixed-values.enum";
import { AppSetting } from "src/app/shared/model/appsetting.model";
import { UserPreferLookupViewModel } from "src/app/shared/model/B2G/preference-list";
import { GlobalMessagesModel } from "src/app/shared/model/common-messages.model";
import { IndexModel, SearchModel } from "src/app/shared/model/common.model";
import { ColumnHeaderModel, DdlItemModel, DDLModel } from "src/app/shared/model/commonddl.model";
import { CustomSearchModel } from "src/app/shared/model/master/office-master.model";
import { UserViewModel } from "src/app/shared/model/user.model";
import { AlertService } from "src/app/shared/service/alert.service";
import { AuthenticationService } from "src/app/shared/service/authentication.service";
import { PreferenceService } from "src/app/shared/service/B2G/preference.service";
import { CommonService } from "src/app/shared/service/common.service";
import { OfficeMasterService } from "src/app/shared/service/master/office-master.service";
import { UserService } from "src/app/shared/service/user.service";
import { AddUpdatePreferenceComponent } from "./add-update-preference/add-update-preference.component";


@Component({
  selector: 'app-preference-list',
  templateUrl: './preference-list.component.html',
  styleUrls: ['./preference-list.component.scss']
})
export class PreferenceListComponent implements OnInit {
  userTypeEnum = UserTypeEnum;
  vendorOffice: string = "Office";
  model: UserPreferLookupViewModel[];
  dataSource: any;
  //displayedColumns: string[] = ["index", "PDistrict", "PDepartment", "POfficeName", "SSOID",
  // "Action",];
  ViewdisplayedColumns: ColumnHeaderModel[] = [
    { Value: "PDistrict", Text: "District" },
    { Value: "PDepartment", Text: "Department" },
    { Value: "POfficeName", Text: "Office Name" },
    { Value: "CompanyName", Text: "Vendor Name" },
    { Value: "SSOID", Text: "Created By" }
  ];

  columnsToDisplay: string[]// = this.displayedColumns.slice();
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  totalRecords: number;
  Permission = this._authService.GetPagePermission("/preferencelist", "preferencelist/create", "preferencelist/detail", "preferencelist/update", "preferencelist/delete");
  ddlList = new DDLModel();
  fromDate: Date | string = new Date("12/17/2018");
  toDate: Date | string = new Date();
  searchModel = this._commonService.modelSetGet(new CustomSearchModel(), false);
  isShow = true;
  loginData: UserViewModel;

  constructor(
    private readonly _alertService: AlertService,
    private readonly _preferenceService: PreferenceService,
    private readonly _userService: UserService,
    private readonly _dialog: MatDialog,
    private readonly _authService: AuthenticationService,
    public readonly _commonService: CommonService,
    private readonly _parentApi: AppComponent
  ) {

    this._parentApi.setPageLayout("Preference List:", "", "", "", true);

    this.loginData = this._authService.GetCurrentUserDetail().UserViewModel;
    this.GetDDLList();
    if (this.searchModel.DepartmentCode) {
      this.getDepartmentOffices();
    }
  }


  ngOnInit() {
    this.getDisplayedColumns(); 
    this.GetDepartment();
    this.GetDistrict();

    if (this.IsVendorTypeUser) {
      vendorOffice: "Office";
    }
  }
  onVendorOfficeChange() {
    this.model = [];
    this.getDisplayedColumns();
  }
  getDisplayedColumns() {
    this.columnsToDisplay = [];
    if (this.vendorOffice == "Office") {
      this.columnsToDisplay = ["index", "PDistrict", "PDepartment", "POfficeName", "SSOID",
        "Action"];
    }
    if (this.vendorOffice == "Vendor") {
      this.columnsToDisplay = ["index", "CompanyName", "SSOID", "Action"];
    }
    this.getList();
  }
  toggleDisplay() {
    this.isShow = !this.isShow;
  }

  getList() {
    //#region Filter Search
    if (this.fromDate) {
      const uTCFromDate = new Date(Date.UTC(new Date(this.fromDate).getFullYear(), new Date(this.fromDate).getMonth(), new Date(this.fromDate).getDate()
      )
      );
      this.fromDate = uTCFromDate;
    }

    if (this.toDate) {
      const uTCToDate = new Date(
        Date.UTC(
          new Date(this.toDate).getFullYear(),
          new Date(this.toDate).getMonth(),
          new Date(this.toDate).getDate()
        )
      );
      this.toDate = uTCToDate;
    }

    if (this.fromDate || this.toDate) {
      this.searchModel.indexModel.AdvanceSearchModel = {
        FromDate: this.fromDate,
        ToDate: this.toDate,
      };
    }

    this.searchModel.CreatedFrom = this.fromDate;
    this.searchModel.CreatedTo = this.toDate;
    this.searchModel.UserCode = this.loginData.UserCode;
    this.searchModel.indexModel.AdvanceSearchModel = {
      KeywordSearch: this.searchModel.KeywordSearch,
      UserCode: this.searchModel.UserCode,
      DepartmentCode: this.searchModel.DepartmentCode,
      OfficeCode: this.searchModel.OfficeCode,
      DistrictCode: this.searchModel.DistrictCode,
      OfficeName: this.searchModel.OfficeName,
      OfficeNameHindi: this.searchModel.OfficeNameHindi,
      OfficeShortName: this.searchModel.OfficeShortName,
      ActiveView: this.searchModel.ActiveView,
      CreatedFrom: this.searchModel.CreatedFrom,
      CreatedTo: this.searchModel.CreatedTo,
      FromDate: this.fromDate,
      ToDate: this.toDate,
      ParentOfficeCode: this.searchModel.ParentOfficeCode,
      OfficeLevelCode: this.searchModel.OfficeLevelCode,
    };
    this.searchModel = this._commonService.modelSetGet(this.searchModel, true);
    //#endregion
    if (this.vendorOffice == "Office") {
      this.searchModel.indexModel.Search = "Vendor";
    }
    if (this.vendorOffice == "Vendor") {
      this.searchModel.indexModel.Search = "Office";
    }
    this._preferenceService.GetAllList(this.searchModel.indexModel).subscribe(
      (data) => {
        if (data.IsSuccess) {
          this.model = <UserPreferLookupViewModel[]>data.Data.Data;
          this.dataSource = new MatTableDataSource<UserPreferLookupViewModel>(this.model);
          this.totalRecords = data.Data.TotalRecords;
          if (!this.searchModel.indexModel.IsPostBack) {
            this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.sort;
          }
        } else {
          this._alertService.error(GlobalMessagesModel.InternalError);
        }
      },
      (error) => {
        this._alertService.error(error.message);
      }
    );
  }

  GetDepartment() {
    this._userService.loginUserCode = this.loginData.UserCode;
    this._userService.GetUserDepartmentForUserOfficeCreation('0', true).subscribe(data => {
      if (data.IsSuccess) {
        this.ddlList.ddlOfficeDepartment = <DdlItemModel[]>data.Data;
      }
    }, error => {
      this._alertService.error(error.message);
    });
  }

  GetDistrict() {
    this._userService.loginUserCode = this.loginData.UserCode;
    let stateCode = String(StateEnum.Rajasthan);
    this._userService.GetUserDistrictForUserOfficeCreation(stateCode, '', true).subscribe(data => {
      if (data.IsSuccess) {
        this.ddlList.ddlDistrict = <DdlItemModel[]>data.Data;
      }
    }, error => {
      this._alertService.error(error.message);
    });
  }

  onDepartmentChange() {
    this.searchModel.ParentOfficeCode = undefined;
    this.getDepartmentOffices();
  }

  getDepartmentOffices() {
    this.ddlList.ddlDepartmentOffice = [];
    if (this.searchModel.DepartmentCode > 0) {
      this._userService.GetUserOfficeList(this.searchModel.DepartmentCode).subscribe(data => {
        if (data.IsSuccess) {
          this.ddlList.ddlDepartmentOffice = <DdlItemModel[]>data.Data;
        }
      }, error => {
        this._alertService.error(error.message);
      });
    }
  }

  GetDDLList() {
    this._commonService.GetAllDDL(AppSetting.DdlKeyForOfficeListFilter).subscribe(
      data => {
        if (data.IsSuccess) {
          let ddlList = <DDLModel>data.Data;
          this.ddlList.ddlOfficeLevel = ddlList.ddlOfficeLevel;
        }
      },
      error => {
        this._alertService.error(error.message);
      }
    );
  }

  Reset() {
    this.searchModel.indexModel = new IndexModel();
    this.searchModel = new CustomSearchModel();
    this.getList();
  }

  updateDeleteStatus(id) {
    const dialogRef = this._dialog.open(ConfirmationDialogComponent, {
      width: "50%",
      data: GlobalMessagesModel.ConfirmDeleted,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this._preferenceService.ChangeDeleteStatus(id).subscribe(
          (data) => {
            if (data.IsSuccess) {
              this._alertService.success(GlobalMessagesModel.deleteSuccess);
              this._commonService.ScrollingTop();
              this.getList();
            }
          },
          (error) => {
            this._alertService.error(GlobalMessagesModel.InternalError);
          }
        );
      }
    });
  }

  sortData(event) {
    this.searchModel.indexModel.OrderBy = event.active;
    this.searchModel.indexModel.OrderByAsc =
      event.direction == AppSetting.orderByDscAsc
        ? AppSetting.orderByAsc
        : AppSetting.orderByDsc;
    this.searchModel.indexModel.IsPostBack = true;
    this.getList();
  }

  onPaginateChange(event) {
    this.searchModel.indexModel.Page = event.pageIndex + 1;
    this.searchModel.indexModel.PageSize = event.pageSize;
    this.searchModel.indexModel.IsPostBack = true;
    this.getList();
  }

  SearchByKeyword(searchValue) {
    this.searchModel.indexModel.Search = searchValue;
    this.getList();
  }

  PagePrint(printContent) {
    this._commonService.printHTMLContent(printContent, "Preference List");
  }

  OpenAddUpdatePreferDialog() {
    
    const _dialogRef = this._dialog.open(AddUpdatePreferenceComponent, {
      width: "50%",    
      disableClose: true
    });
    _dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        this.getList();
      }
    });
  }
  get IsVendorTypeUser(): boolean {
    return (this.loginData.BaseUserType == this.userTypeEnum.VNDR) || (this.loginData.BaseUserType == this.userTypeEnum.VNDRUSER);
  }
  get IsOfficeTypeUser(): boolean {
    return ((this.loginData.BaseUserType == this.userTypeEnum.HOD) || (this.loginData.BaseUserType == this.userTypeEnum.HOOADLC) || (this.loginData.BaseUserType == this.userTypeEnum.DREC) || (this.loginData.BaseUserType == this.userTypeEnum.DRECADLC) || (this.loginData.BaseUserType == this.userTypeEnum.DOS) || (this.loginData.BaseUserType == this.userTypeEnum.DOSADLC));
  }

}
