import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { DdlItemModel, DDLModel } from 'src/app/shared/model/commonddl.model';
import { UserAdditionalUserTypeModel } from 'src/app/shared/model/user-permission.model';
import { AlertService } from 'src/app/shared/service/alert.service';
import { AuthenticationService } from 'src/app/shared/service/authentication.service';
import { UserService } from 'src/app/shared/service/user.service';
import { ChangeOfficeDialogComponent } from '../change-office-dialog/change-office-dialog.component';

@Component({
  selector: 'app-change-user-type-dialog',
  templateUrl: './change-user-type-dialog.component.html',
  styleUrls: ['./change-user-type-dialog.component.scss']
})
export class ChangeUserTypeDialogComponent implements OnInit {
  dDLList: DDLModel = new DDLModel();
  userTypeCode: number;
  constructor(
    private readonly _authService: AuthenticationService,
    private readonly _userService: UserService,
    public _dialogRef: MatDialogRef<ChangeUserTypeDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }


  ngOnInit() {
    this.getDetail();
  }


  getDetail() {

    this._userService.GetAdditionalUserTypeById(this._authService.UserCode)
      .subscribe(res => {
        if (res.IsSuccess) {
          let data = res.Data as UserAdditionalUserTypeModel;
          this.dDLList.ddlUserType = data.UserType.filter(x => x.IsSelected).map(x => {
            let itm = new DdlItemModel();
            itm.Text = x.DisplayUserTypeTitle;
            itm.Value = x.UserTypeCode
            return itm;
          })
        } else {

        }
      })

  }

  onUserTypeChange(isReset = false) {

    this._userService.UpdateCurrentUserType(!isReset ? this.userTypeCode : 0)
      .subscribe(res => {
        if (res.IsSuccess) {
          this.closeDialog(true);
        }
      })


  }
  switchToMain() {

    this.onUserTypeChange(true)
  }

  closeDialog(result): void {
    this._dialogRef.close(result);
  }

}
