import { Injectable } from "@angular/core";
import { GlobalFilterSearchModel } from "../model/Global-communication-search.model";
import { CommonService } from "./common.service";

@Injectable({
    providedIn: "root",
})

export class GlobalCommunicationSearchService {

    SearchModel: GlobalFilterSearchModel = new GlobalFilterSearchModel();

    constructor(private readonly _commonService: CommonService) {
        this.SearchModel = this.GetDetail();
    }

    GetDetail(): GlobalFilterSearchModel {
        let globalSearch = this._commonService.GetLocalStorage("GlobalCommunicationFilterSearch");
        if (globalSearch) {
            this.SearchModel = JSON.parse(globalSearch);
        }
        return this.SearchModel;
    }

    SetDetail(model: GlobalFilterSearchModel) {
        this.SearchModel = model;
        this._commonService.SetLocalStorage("GlobalCommunicationFilterSearch", JSON.stringify(this.SearchModel));
        this._commonService.SetLocalStorage("IsGlobalSearch", "1");
        this._commonService.isResetGlobalFilter.next(true);
    }



}


