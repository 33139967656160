import { CompliantActionDialogComponent } from './compliant-action-dialog/compliant-action-dialog.component';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatSort, MatDialog, MatTableDataSource } from '@angular/material';
import { ActivatedRoute } from '@angular/router';
import { AppComponent } from 'src/app/app.component';
import { CompliantEnum } from 'src/app/shared/enum/compliant.enum';
import { AppSetting } from 'src/app/shared/model/appsetting.model';
import { DDLModel, ColumnHeaderModel } from 'src/app/shared/model/commonddl.model';
import { ComplaintEntryListModel, CompliantSearchModel } from 'src/app/shared/model/compliant.model';
import { AlertService } from 'src/app/shared/service/alert.service';
import { CommonService } from 'src/app/shared/service/common.service';
import { ComplaintService } from 'src/app/shared/service/complaint.service';
import { environment } from 'src/environments/environment';
import { IndexModel, PermissionModel } from 'src/app/shared/model/common.model';
import { AuthenticationService } from 'src/app/shared/service/authentication.service';

@Component({
  selector: 'app-complaint-software',
  templateUrl: './complaint-software.component.html',
  styleUrls: ['./complaint-software.component.scss']
})
export class ComplaintSoftwareComponent implements OnInit {

  dDLList: DDLModel;
  listModel: ComplaintEntryListModel[];
  indexModel: CompliantSearchModel;
  dataSource: any;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  totalRecords: number;
  compliantEnum = CompliantEnum;
  displayedColumns: string[] = [
    "index",
    "Description",
    "ScreenURL",
    "StatusName",
    "EntryTypeName",
    "ApplicationTitle",
    "PermissionTitle",
    "UserDetail",
    "PriorityId",
    "Attachments",
    "Action",
  ];
  ViewdisplayedColumns: ColumnHeaderModel[] = [
   // { Value: "StatusName", Text: "Status" },
    { Value: "EntryTypeName", Text: "Entry Type" },
    { Value: "ApplicationTitle", Text: "Application" },
    { Value: "PermissionTitle", Text: "Page" }
  ];
  columnsToDisplay: string[] = this.displayedColumns.slice();

  Permission: PermissionModel = this._authService.GetPagePermission("/complaint-software", "complaint-software/create", "complaint-software/detail", "complaint-software/update", "complaint-software/delete");
  isShow = true;
  searchModel = this._commonService.modelSetGet(new CompliantSearchModel()) as CompliantSearchModel;
  selectedStatusAll = -1;

  constructor(private _parentApi: AppComponent,
    private readonly _alertService: AlertService,
    public readonly _commonService: CommonService,
    public readonly _authService: AuthenticationService,
    private readonly _dialog: MatDialog,
    private _route: ActivatedRoute,
    private _complaintService: ComplaintService
  ) {
    this._parentApi.setPageLayout('Suggestion-Feedback :', "add", "Add", "/complaint-software/create");
  }

  ngOnInit() {
    this.searchModel.StatusId == null ? this.searchModel.StatusIds = ['2'] : this.searchModel.StatusIds;
    this.GetList();
    this.GetDDLList();


  }

  GetDDLList() {
    this._commonService.GetAllDDL(AppSetting.ComplaintFilterDDLKey).subscribe(
      data => {
        if (data.IsSuccess) {
          this.dDLList = <DDLModel>data.Data;
        }
      },
      error => {
        this._alertService.error(error.message);
      }
    );
  }

  GetList() {
    
    this.searchModel.StatusId = (this.searchModel.StatusIds) ? this.searchModel.StatusIds.toString() : '';

    if (this.searchModel.IsExportToExcel) {
      this.exportToCSV();
    }
    else {
    this._complaintService.GetList(this._commonService.modelSetGet(this.searchModel, true)).subscribe(
      data => {
        if (data.IsSuccess) {
          this.listModel = <ComplaintEntryListModel[]>data.Data.Data;
        this.dataSource = new MatTableDataSource<ComplaintEntryListModel>(this.listModel);
        this.totalRecords = data.Data.TotalRecords;
        if (!this.searchModel.indexModel.IsPostBack) {
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
        }
      }
    },
      (error) => {
        this._alertService.error(error.message);
      }
      );
    }
  }

  // getAbsalutePath(url) {
  //   return environment.ApiBaseUrl + url.replace("~/", "").trim();
  // }

  sortData(event) {
    this.searchModel.indexModel.OrderBy = event.active;
    this.searchModel.indexModel.OrderByAsc =
      event.direction == AppSetting.orderByDscAsc
        ? AppSetting.orderByAsc
        : AppSetting.orderByDsc;
    this.searchModel.indexModel.IsPostBack = true;
    this.GetList();
  }

  onPaginateChange(event) {
    this.searchModel.indexModel.Page = event.pageIndex + 1;
    this.searchModel.indexModel.PageSize = event.pageSize;
    this.searchModel.indexModel.IsPostBack = true;
    this.GetList();
  }

  OpenDialog(id) {
    const dialogRef = this._dialog.open(CompliantActionDialogComponent, {
      width: '500px',
      data: id
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.GetList();
      }
    });
  }
  toggleDisplay() {
    this.isShow = !this.isShow;
  }
  Reset() {
    this.searchModel.StatusIds = [];
    this.searchModel = new CompliantSearchModel();
    this.GetDDLList();
    this.GetList();
    this.searchModel = this._commonService.modelSetGet(this.searchModel, true)
  }
  selectStatusAll() {
    if (this.selectedStatusAll < 0) {
      this.searchModel.StatusIds = this.dDLList.ddlCompliantStatus.map(
        function (a) {
          return a.Value;
        }
      );
      this.selectedStatusAll = 1;
    } else {
      this.selectedStatusAll = -1;
      this.searchModel.StatusIds = [];
    }
  }
  Search() {
    this.searchModel.indexModel = new IndexModel();
    this.GetList();
    this.searchModel = this._commonService.modelSetGet(this.searchModel, true)
  }
  downloadCsv() {
    this.searchModel.IsExportToExcel = true;
    this.GetList();
  }
  exportToCSV() {
    this._complaintService.ExportComplaintData(this._commonService.modelSetGet(this.searchModel, true)).subscribe((data) => {
      if (data.IsSuccess) {
        const downloadLink = document.createElement("a");
        downloadLink.href = data.Data;
        downloadLink.download = "SuggestionFeedback_" + new Date().toLocaleDateString() + "_" + new Date().toLocaleTimeString();
        downloadLink.target = "blank";
        downloadLink.click();
      } else {
        this._alertService.error(data.Message);
      }
      this.searchModel.IsExportToExcel = false;
    }, (error) => {
      this._alertService.error(error.message);
    });
  }
}
