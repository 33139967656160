import { IndexModel } from './../common.model';

export class ProjectGroupModel {
    ProjectGroupCode: number;
    ProjectGroupTitle: string;
    ProjectGroupTitleHindi: string;
    Description: string
    IsActive: boolean;
    IsDeleted: boolean;
    CreatedBy: number | null;
    CreatedOn: Date | null;
    ModifiedBy: number | null;
    ModifiedOn: Date | null;
    DepartmentCode: number |string| null;
    DistrictCode: number |string| null;
    OfficeCode: number |string| null;
    IsApplicabletoDepartment: boolean | null;
    DepartmentTitle: string;
    DepartmentTitleHindi: string;
    OfficeName: string;
    OfficeNameHindi: string;
    DistrictTitle: string;
    DistrictTitleHindi: string;
    VendorCode: number | string;
    CreatedByName:string;
    ModifiedByName:string;

    constructor() {
        this.ProjectGroupCode = 0;
        this.IsActive = true;
        this.IsDeleted = false;
    }
}

export class ProjectGroupSearchModel {
    constructor() {
        this.indexModel = new IndexModel();
        this.DepartmentCode = 0;
        this.DistrictCode = 0;
        this.OfficeCode = 0;
        this.IsActive = -1;
        this.IsApplicabletoDepartment=-1;     
    }

    LoginUserCode: number;
    DepartmentCode: number | null;
    DistrictCode: number |string| null;
    OfficeCode: number | null;
    ProjectGroupTitle: string;
    ProjectGroupTitleHindi: string;
    Description: string;
    IsActive: number;
    IsApplicabletoDepartment: number;
    FromDate: string;
    ToDate: string;
    SearchText: string;
    VendorCode: number | null;
    indexModel: IndexModel;
}