import { Component, OnInit, Inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { AlertService } from 'src/app/shared/service/alert.service';
import { FormBuilder } from '@angular/forms';
import { BankAccountModel } from 'src/app/shared/model/bank-account.model';
import { BankAccountService } from 'src/app/shared/service/bank-account.service';

@Component({
  selector: 'app-bank-account-detail-dialog',
  templateUrl: './bank-account-detail-dialog.component.html',
  styleUrls: ['./bank-account-detail-dialog.component.scss']
})

export class BankAccountDetailDialogComponent implements OnInit {

  recordId: number = 0;
  model: BankAccountModel = new BankAccountModel();

  constructor(private readonly fb: FormBuilder,
    private readonly _alertService: AlertService,
    private readonly _bankAccountService: BankAccountService,
    public _dialogRef: MatDialogRef<BankAccountDetailDialogComponent>,
    private readonly _route: ActivatedRoute,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.model = new BankAccountModel();
    if (data) {
      if (data.Id) {
        this.recordId = data.Id;
        this.GetDetail();
      }
    }
  }

  ngOnInit() {
  }

  GetDetail() {
    this._bankAccountService.Detail(this.recordId).subscribe(
      data => {
        if (data.IsSuccess) {
          this.model = <BankAccountModel>data.Data;
        }
      },
      error => {
        this.model = new BankAccountModel();
        this._alertService.error(error.message);
      }
    );
  }

  download(url: string) {
    const downloadLink = document.createElement("a");
    downloadLink.href = url;
    downloadLink.download = 'cancel-cheque';
    downloadLink.target = "blank";
    downloadLink.click();
  }

  CloseDetailDialog(): void {
    this._dialogRef.close();
  }

}

