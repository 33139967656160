export class AdminDepartmentMasterModel {
  AdmDepartmentId: number;
  AdmDepartmentTitle: string;
  AdmDepartmentTitleHindi: string;
}


export class AdminDepartmentMasterViewModel {
  AdmDepartmentId: number;
  AdmDepartmentCode: number;
  AdmDepartmentTitle: string;
  AdmDepartmentTitleHindi: string;
  AdmDepartmentShortTitle: string;
  AdmDepartmentShortTitleHindi: string;
  AdmDepartmentIsActive: boolean;
  AdmDepartmentIsDeleted: boolean;
  CreatedDate: Date | null;
  CreatedBy: number;
  ModifiedDate: Date | null;
  SSOID: string;
}

