import { UserTypeEnum } from './../../../shared/enum/fixed-values.enum';
import { UserTypeService } from './../../../shared/service/user-type.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatTableDataSource, MatPaginator, MatSort, MatDialog } from '@angular/material';
import { AppComponent } from 'src/app/app.component';
import { AppSetting } from 'src/app/shared/model/appsetting.model';
import { PermissionModel } from 'src/app/shared/model/common.model';
import { ColumnHeaderModel, DDLModel } from 'src/app/shared/model/commonddl.model';
import { UserForNotificationListModel, UserNotificationFilterModel, UserNotificationModel } from 'src/app/shared/model/user.model';
import { AlertService } from 'src/app/shared/service/alert.service';
import { CommonService } from 'src/app/shared/service/common.service';
import { UserService } from 'src/app/shared/service/user.service';
import { isNullOrUndefined } from 'util';
import { UserTypeModel } from 'src/app/shared/model/user-type.model';
import { NotificationMappingModel } from 'src/app/shared/model/master/notification-template-type.model';
import { SMSTemplateService } from 'src/app/shared/service/master/sms-template.service';

@Component({
  selector: 'app-user-notification',
  templateUrl: './user-notification.component.html',
  styleUrls: ['./user-notification.component.scss']
})
export class UserNotificationComponent implements OnInit {

  listModel: UserForNotificationListModel[];
  dataSource: MatTableDataSource<UserForNotificationListModel>;
  displayedColumns: string[] = [
    "index",
    "UserName",
    "UserEmail",
    "Mobile",
    "DepartmentTitle",
    "OfficeName",
    "DistrictNames",
    "Action"
  ];
  ViewdisplayedColumns: ColumnHeaderModel[] = [
    { Value: "UserName", Text: "User Name" },
    { Value: "UserEmail", Text: "User Email" },
    { Value: "Mobile", Text: "Mobile No." },
    { Value: "DepartmentTitle", Text: "Department" },
    { Value: "OfficeName", Text: "Office" },
    { Value: "DistrictNames", Text: "District" }
  ];
  columnsToDisplay: string[] = this.displayedColumns.slice();
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  Permission: PermissionModel = new PermissionModel();
  // // this._authService.GetPagePermission(
  //   "/master/usernotification",
  //   "/master/usernotification/add"
  // );
  searchModel: UserNotificationFilterModel;
  dDLList: DDLModel = new DDLModel();
  totalRecords: number;
  notificationModel: UserNotificationModel;
  content = new FormControl("", [Validators.required]);

  //#region << constructor >>

  constructor(
    private _parentComponent: AppComponent,
    private readonly _userService: UserService,
    private readonly _alertService: AlertService,
    private _dialog: MatDialog,
    public readonly _commonService: CommonService,
    private readonly _userTypeService: UserTypeService,
    private readonly _smsTemplateService: SMSTemplateService
  ) {
    this.notificationModel = new UserNotificationModel();
    this._parentComponent.setPageLayout("User Notification List:", "", "", "");
    this.searchModel = new UserNotificationFilterModel();
    this.GetUserType();
  }
  //#endregion

  ngOnInit() {
    this.GetList();
    this.GetDDLList();
  }

  GetDDLList() {
    
    this._commonService.GetAllDDL(AppSetting.DDlKeyUserNotification).subscribe(data => {
      if (data.IsSuccess) {
        
        var ddlList = <DDLModel>data.Data;
        this.dDLList.ddlOfficeDepartment = ddlList.ddlOfficeDepartment;
        this.dDLList.ddlOfficeDistrict = ddlList.ddlOfficeDistrict;
        this.dDLList.ddlSMSTemplate = ddlList.ddlSMSTemplate;
      }
    }, error => {
      this._alertService.error(error.message);
    });
  }

  SortData(event) {
    this.searchModel.OrderBy = event.active;
    this.searchModel.OrderByAsc =
      event.direction === AppSetting.orderByDscAsc
        ? AppSetting.orderByAsc
        : AppSetting.orderByDsc;
    this.searchModel.IsPostBack = true;
    this.GetList();
  }

  onPaginateChange(event) {
    this.searchModel.Page = event.pageIndex + 1;
    this.searchModel.PageSize = event.pageSize;
    this.searchModel.IsPostBack = true;
    this.GetList();
  }

  GetList() {
    this._userService.GetUseForNotification(this.searchModel).subscribe(
      data => {
        if (data.IsSuccess) {

          this.listModel = <UserForNotificationListModel[]>data.Data.Data;
          if (this.notificationModel.IsSelectAll) {
            this.notificationModel.MobileNo = this.listModel.map(
              function (a) {
                return a.Mobile;
              }
            );
            this.notificationModel.MobileNo = this.notificationModel.MobileNo.filter(c => !this.notificationModel.UnSelectedList.includes(c));
          }
          this.dataSource = new MatTableDataSource<
            UserForNotificationListModel
          >(this.listModel);
          if (this.searchModel.IsPostBack === false) {
            this.dataSource.paginator = this.paginator;
            this.totalRecords = data.Data.TotalRecords;
            this.dataSource.sort = this.sort;
          }

        }
      },
      error => {
        this._alertService.error(error.message);
      }
    );
  }

  onSearchclick() {
    this.searchModel.Page = 1;
    this.searchModel.IsPostBack = false;
    this.GetList();
  }

  onClearclick() {
    this.searchModel.Page = 1;
    this.searchModel.OfficeCode = undefined;
    this.searchModel.DepartmentCode = undefined;
    this.searchModel.IsPostBack = false;
    this.GetList();
  }

  selectAll(event) {
    if (event.checked) {
      this.notificationModel.IsSelectAll = true;
      this.notificationModel.MobileNo = this.listModel.map(
        function (a) {
          return a.Mobile;
        }
      );
    }
    else {
      this.notificationModel.MobileNo = [];
      this.notificationModel.IsSelectAll = false;
    }
  }

  selectItem(event, data: string) {

    if (event.checked) {
      this.notificationModel.MobileNo.push(data);
      this.notificationModel.UnSelectedList = this.notificationModel.UnSelectedList.filter(c => c !== data);
    } else {
      this.notificationModel.UnSelectedList.push(data);
      this.notificationModel.MobileNo = this.notificationModel.MobileNo.filter(c => c !== data);
    }
  }

  SaveClick() {
    
    this.content.markAsTouched();
    if (this.content.valid) {
      this.notificationModel.FilterModel = this.searchModel;
      this._userService
        .SendNotificationToUser(this.notificationModel)
        .subscribe(
          data => {
            if (data.IsSuccess) {
              
              this._alertService.success(data.Message);
            } else {
              this._alertService.error(data.Message);
            }
            this._commonService.ScrollingTop();
          },
          error => {
            this._alertService.error(error.message);
            this._commonService.ScrollingTop();
          }
        );
    } else {
      this._commonService.ScrollingTop();
    }
  }

  GetOffice() {
    if (!isNullOrUndefined(this.searchModel.DepartmentCode)) {
      this.searchModel.DistrictCode = (this.searchModel.DistrictCode == undefined) ? 0 : this.searchModel.DistrictCode;
      this._commonService.GetDDL(AppSetting.ddlDepartmentOffice, this.searchModel.DepartmentCode, this.searchModel.DistrictCode).subscribe(
        data => {
          if (data.IsSuccess) {
            var ddl = <DDLModel>data.Data;
            this.dDLList.ddlDepartmentOffice = ddl.ddlDepartmentOffice;
          }
        },
        error => {
          this._alertService.error(error.message);
        }
      );
    }
    else {
      this.dDLList.ddlDepartmentOffice = null;
    }
  }

  GetDistrictByDepartment() {
    if (!isNullOrUndefined(this.searchModel.DepartmentCode)) {
      this._commonService.GetDDL(AppSetting.ddlDistrict, this.searchModel.DepartmentCode).subscribe(
        data => {
          if (data.IsSuccess) {
            var ddl = <DDLModel>data.Data;
            this.dDLList.ddlDistrict = ddl.ddlDistrict;
          }
        },
        error => {
          this._alertService.error(error.message);
        }
      );
    }
    else {
      this.dDLList.ddlDistrict = null;
    }
  }

  onDepartmentChange() {
    setTimeout(() => {
      this.GetOffice();
    }, 100);
    setTimeout(() => {
      this.GetDistrictByDepartment();
    }, 100);
  }

  GetUserType() {
    this._userTypeService
      .GetDownLevelUserType(UserTypeEnum.ADLC, true).subscribe(
        (data) => {
          if (data.IsSuccess) {
            var userTypes = <UserTypeModel[]>data.Data;
            if (userTypes) {
              userTypes = userTypes;
              userTypes.forEach((element) => {
                this.dDLList.ddlUserType.push({
                  Value: String(element.UserTypeCode),
                  Text: element.UserTypeTitle,
                });
              });
            }

          }
        },
        (error) => {
          this._alertService.error(error.message);
        }
      );
  }

  GetSMSTemplateContent() {           
    if (!isNullOrUndefined(this.searchModel.TemplateCode)) {
      this._smsTemplateService.GetById(this.searchModel.TemplateCode).subscribe(
        data => {
          if (data.IsSuccess) {            
            this.notificationModel.Content = data.Data.SMSContent;
          }
        },
        error => {
          this._alertService.error(error.message);
        }
      );
    }
    else{
      this.notificationModel.Content = undefined;
    }
  }
}