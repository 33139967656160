import { IndexModel } from "./common.model";
import { NavItem } from "./nav-item.model";
import { ProjectDetailViewModel } from "./projectMapping.model";
import { AssignedUserPermissionViewModel } from "./user-permission.model";

export class UserViewModel {
  UserCode: number;
  UserTypeCode: number | string;
  UserType: string;
  UserTypeTitle: string;
  SSOID: string;
  Title: string;
  Name: string;
  ProfilePic: string;
  Email: string;
  Mobile: string;
  Gender: string;
  VendorCode: number | null;
  OfficeCodes: string;
  OfficeCode: string[] | number[];
  OfficeNames: string;
  DepartmentCodes: string;
  DepartmentCode: string[] | number[];
  DepartmentNames: string;
  DistrictCodes: string;
  StateCodes: string;
  StateNames: string;
  StateCode: string[] | number[];
  DistrictCode: string[] | number[];
  DistrictNames: string;
  IsActive: boolean;
  IsDeleted: boolean;
  CreatedOn: Date | null;
  CreatedBy: number | null;
  CreatedByUserName: string;
  ModifiedOn: Date | null;
  ModifiedBy: number | null;
  ModifiedByUserName: string;
  Designation: string;
  DesignationName: string;
  ProjectCodes: string;
  ProjectCode: string[];
  ProjectNames: string;
  VendorUser_VendorCode: string;
  Vendor_UserCode: number | null;
  Vendor_CompanyName: string;
  WorkOrderNames: string;
  AssignDate: string | Date | null;
  UserTypeCategoryCode: number | string;
  UserTypeCategory: string;
  UserTypeCategoryTitle: string;
  BaseUserTypeCode: number | string;
  BaseUserType: string;
  BaseUserTypeTitle: string;
  ParentDepartmentCode: number | string;
  ParentDepartmentName: string;
  IsUpdateProfile: boolean;
  PrimaryOfficeCode: number | null;
  PrimaryDistrictCode: number | null;
  PrimaryDepartmentCode: number | null;

  PrimaryOffice: string;
  PrimaryDistrict: string;
  PrimaryDepartment: string;
  ProjectList: ProjectDetailViewModel[];
  HasAdditionalRole: boolean;
  AdditionalUserTypes: string;
}

export class LoggedInUserDetailViewModel {
  UserViewModel: UserViewModel;
  AssignedUserPermissionViewModelList: AssignedUserPermissionViewModel[];
  UserMenuViewModelList: NavItem[];
  Token: string;
  SSOUserType: string;
}

export class SSOUserModel {
  SSOID: string;
  DateOfBirth: string;
  DisplayName: string;
  FirstName: string;
  LastName: string;
  Gender: string;
  MailOfficial: string;
  MailPersonal: string;
  Mobile: string;
  Photo: string;
  TelephoneNumber: string;
  IpPhone: string;
  PostalAddress: string;
  State: string;
  StateCode: string;
  District: string;
  DistrictCode: string;
  City: string;
  PostalCode: string;
  UserType: string;
}

export class LoginUserLogModel {
  LogCode: number;
  UserCode: number;
  SSOID: string;
  LoginTime: Date | string;
  LogOutTime: Date | string;
  IPAddress: string;
  LoginLogOutStaus: string;
}

export class UserModel {
  UserCode: number;
  UserTypeCode: number | string;
  UserType: string;
  UserTypeTitle: string;
  SSOID: string;
  Title: string;
  UserName: string;
  ProfilePic: string;
  Email: string;
  Mobile: string;
  Gender: string;
  VendorCode: number | null;
  OfficeCode: string[] | number[];
  OfficeNames: string;
  DepartmentCode: string[] | number[];
  DepartmentNames: string;
  DistrictCode: string[] | number[];
  DistrictNames: string;
  IsActive: boolean;
  IsDeleted: boolean;
  CreatedOn: Date | null;
  CreatedBy: number | null;
  CreatedByUserName: string;
  ModifiedOn: Date | null;
  ModifiedBy: number | null;
  ModifiedByUserName: string;
  Designation: string;
  ProjectCode: string[] | number[];
  ProjectNames: string;
  VendorUser_VendorCode: string;
  WorkOrderNames: string;
  AssignDate: string | Date | null;
}


export class UserCreationModel {
  UserCode: number;
  UserType: string;
  SSOID: string;
  Title: string;
  UserName: string;
  ProfilePic: string;
  Email: string;
  Mobile: string;
  Gender: string;
  OfficeCode: string[] | string;
  DepartmentCode: string[] | string;
  DistrictCode: string[] | string;
  Designation: string;
  ProjectCode: string[] | string;
  AdminDepartment: string[] | string;
  StateCodes: string[] | string;
  UserTypeCategory: number | string;
  ParentDepartmentCode: number | string;
  IsUpdateProfile: boolean;
  PrimaryOfficeCode: number | string;
  PrimaryDistrictCode: number | string;
  PrimaryDepartmentCode: number | string;
  BaseUserTypeCode: number;
  BaseUserType: string;
}

export class UserDepartmentViewModel {
  DepartmentCode: number;
  DepartmentTitle: string;
  DepartmentTitleHindi: string;
  DepartmentShortTitle: string;
  DepartmentShortTitleHindi: string;
  AdmDepartmentCode: number | string | any;
  AdmDepartmentTitle: string;
  UserId: number;
}

export class UserForNotificationListModel {
  UserName: string;
  SSOID: string;
  UserId: number;
  UserEmail: string;
  Mobile: string;
  User_DepartmentCode: number;
  DepartmentTitle: string;
  OfficeCode: number;
  OfficeName: string;
  DistrictCodes: string;
  DistrictNames: string
  BaseUserTypeCode: number;
  BaseUserType: string
}

export class UserNotificationFilterModel extends IndexModel {
  OfficeCode: number;
  DepartmentCode: number;
  DistrictCode: number;
  UserTypeCode: number;
  TemplateCode: number;

  constructor() {
    super();
  }
}

export class UserNotificationModel {
  Content: string;
  MobileNo: string[] = [];
  MobileNumber: string;
  IsSelectAll: boolean;
  UnSelectedList: string[] = [];
  FilterModel: UserNotificationFilterModel;
}


export class UserExportModel {
  UserTypeCode: number;
  Attachment: string;
  CreateBy: number;
}
export class UserExportViewModel {

  FileUrl: string;

}

export class userCreationConfigModel {
  IsAdminDepartmentShow: boolean = true;
  IsDepartmentShow: boolean = true;
  IsStateShow: boolean = true;
  IsDistrictShow: boolean = true;
  IsOfficeShow: boolean = true;
  IsProjectShow: boolean = true;
  IsDesignationShow: boolean = true;
  IsUserTypeCategoryShow: boolean = true;
}

export class CustomSearchModel {
  constructor() {
    this.indexModel = new IndexModel();
  }
  UserCode: number;
  Ssoid: number | string;
  Name: string;
  UserType: string;
  AdminDepartmentCode: string[];
  DepartmentCode: string[];
  OfficeCode: string[];
  DistrictCode: string[];
  Email: string;
  Mobile: string;
  ActiveView: boolean = true;
  CreatedFrom: Date | string;
  CreatedTo: Date | string;
  indexModel: IndexModel;
}

export class UserCreationViewModel {
  UserCode: number;
  UserTypeCode: number;
  UserType: string;
  UserTypeTitle: string;
  BaseUserTypeCode: number;
  BaseUserType: string;
  BaseUserTypeTitle: string;
  SSOID: string;
  Title: string;
  Name: string;
  UserName: string;
  AssignDate: string | null;
  ProfilePic: string;
  VendorCode: number | null;
  VendorUser_VendorCode: number | null;
  ParentDepartmentCode: number | string;
  ParentDepartmentName: string;
  UserTypeCategoryCode: number | null;
  UserTypeCategory: string;
  UserTypeCategoryTitle: string;
  OfficeCodes: string;
  OfficeCode: string[] | number[];
  OfficeNames: string;
  AdminDepartmentCodes: string;
  AdminDepartmentCode: string[] | number[];
  AdminDepartmentNames: string;
  DepartmentCodes: string;
  DepartmentCode: string[] | number[];
  DepartmentNames: string;
  StateCodes: string;
  StateNames: string;
  DistrictCodes: string;
  DistrictCode: string[] | number[];
  DistrictNames: string;
  Email: string;
  Mobile: string;
  Gender: string;
  IsActive: boolean;
  IsDeleted: boolean;
  CreatedBy: number | null;
  CreatedOn: string;
  CreatedByUserName: string;
  ModifiedBy: number | null;
  ModifiedOn: string | null;
  ModifiedByUserName: string;
  Designation: string;
  DesignationName: string;
  ProjectCodes: string;
  ProjectCode: string[] | number[];
  ProjectNames: string;
  WorkOrderNames: string;
  SpecificPermissionCount: number;
}

export class UserCustomSearchModel {
  UserCode: number;
  Ssoid: number | string;
  Name: string;
  UserType: string;
  AdminDepartmentCode: string[];
  DepartmentCode: string[];
  OfficeCode: string[];
  DistrictCode: string[];
  DesignationCode: string;
  Email: string;
  ActiveView: number = -1;
  IsUpdateProfile: number = -1;
  CreatedFrom: Date | string;
  CreatedTo: Date | string;
  indexModel: IndexModel;
  IsExportToExcel: boolean;
  IsShowUserWithSpecificPermission: boolean;
  UserTypeCategory: number | string;

  constructor() {
    this.indexModel = new IndexModel();
    this.IsExportToExcel = false;
  }
}

export class UpdateUserOfficeModel {
  AdmDepartmentCodes: string[];
  AdmDepartmentCode: string;
  DepartmentCodes: string[];
  DepartmentCode: string;
  StateCode: string;
  DistrictCodes: string[];
  DistrictCode: string;
  OfficeCodes: string[];
  OfficeCode: string;
}

//Adv Search
export class VendorUserSearchModel {
  constructor() {
    this.indexModel = new IndexModel();
    this.IsActive = "-1";
    this.DepartmentCode = 0;
    this.DistrictCode = 0;
    this.OfficeCode = 0;
    this.ProjectCode = 0;
  }
  LoginUserCode: number;
  DepartmentCode: number | null;
  DistrictCode: number | string | null;
  OfficeCode: number | null;
  ProjectCode: number | null;
  UserTypeCategory: string;
  SearchText: string;
  IsActive: number | null | string = "-1";
  indexModel: IndexModel;
}

