import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatStepper } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { AppComponent } from 'src/app/app.component';
import { AppSetting } from 'src/app/shared/model/appsetting.model';
import { GlobalMessagesModel } from 'src/app/shared/model/common-messages.model';
import { DDLModel, DdlItemModel } from 'src/app/shared/model/commonddl.model';
import { GeneralEntryCategoryMasterModel } from 'src/app/shared/model/master/general-entry-category-master.model';
import { UserViewModel } from 'src/app/shared/model/user.model';
import { AlertService } from 'src/app/shared/service/alert.service';
import { AuthenticationService } from 'src/app/shared/service/authentication.service';
import { CommonService } from 'src/app/shared/service/common.service';
import { GeneralEntryCategoryMasterService } from 'src/app/shared/service/master/general-entry-category-master.service';

@Component({
  selector: 'app-add-update-general-entry-category-master',
  templateUrl: './add-update-general-entry-category-master.component.html',
  styleUrls: ['./add-update-general-entry-category-master.component.scss']
})
export class AddUpdateGeneralEntryCategoryMasterComponent implements OnInit {

  //#region Variable
  id: number;
  model: GeneralEntryCategoryMasterModel;
  loginData: UserViewModel;
  // firstFormGroup: FormGroup;
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  thirdFormGroup: FormGroup;
  title: string;
  ImagefileValidationMsg: string ="";
  helpFileValidationMsg: string = "";
  fileSizeValidationMsg: string =AppSetting.FileSizeLimit.PDFErrorMSG;
  isLinear = true;
  dDLList: DDLModel;
  selectedAllDepartmentDistrict = -1;
  dDLDistrictDepartment: DdlItemModel[];
  //#endregion

  //#region constructor
  constructor(
    private readonly fb: FormBuilder,
    private readonly _alertService: AlertService,
    public readonly _commonService: CommonService,
    private readonly _achievementCategoryService: GeneralEntryCategoryMasterService,
    private readonly _authService: AuthenticationService,
    private _route: ActivatedRoute,
    private _parentApi: AppComponent,
    private readonly _router: Router,
  ) {
    this.model = new GeneralEntryCategoryMasterModel();

    this.loginData = this._authService.GetCurrentUserDetail().UserViewModel;
   
    const id = this._route.snapshot.params.id;
    if (id) {
      this.model.CategoryId = id;
      this.GetById();
      this._parentApi.setPageLayout(
        "Update General Entry Category :",
        "keyboard_backspace",
        "Back To List",
        "master/general-entry-category"
      );
      this.title = "Update";
    } else {
      this._parentApi.setPageLayout(
        "Add General Entry Category :",
        "keyboard_backspace",
        "Back To List",
        "master/general-entry-category"
      );
      this.title = "Add";
    }

  }
  //#endregion

  //#region Method
  ngOnInit() {
    this.formGroupInit();
    this.GetDDLList();
  }

  GetById() {
    this._achievementCategoryService.GetById(this.model.CategoryId).subscribe(
      data => {
        if (data.IsSuccess) {
          this.model = <GeneralEntryCategoryMasterModel>data.Data;
          if (this.model.MenuClassificationCode) {
            this.model.MenuClassificationCode = String(this.model.MenuClassificationCode);
          }
          if (this.model.MenuClassificationPageTypeCode) {
            this.model.MenuClassificationPageTypeCode = String(this.model.MenuClassificationPageTypeCode);
          }
          if (this.model.GeneralDepartmentDistrictMapping) {
            this.model.GeneralDepartmentDistrictMapping = String(this.model.GeneralDepartmentDistrictMapping);
            this.GetDepartmentList(this.model.GeneralDepartmentDistrictMapping);
          }
        }
      },
      error => {
        this.model = new GeneralEntryCategoryMasterModel();
        this._alertService.error(error.message);
      }
    );
  }

  GetDDLList() {
    this._commonService.GetAllDDL(AppSetting.DDLKeyForGeneralEntryCategoryMaster).subscribe(
      data => {
        if (data.IsSuccess) {
          this.dDLList = <DDLModel>data.Data;
        }
      },
      error => {
        this._alertService.error(error.message);
      }
    );
  }


  touchFirst(stepper: MatStepper) {
    if (!this.firstFormGroup.valid) {
      this.firstFormGroup.markAllAsTouched();
    }
  }

  touchSecond(stepper: MatStepper) {
    if (!this.secondFormGroup.valid) {
      this.secondFormGroup.markAllAsTouched();
    }
  }

  touchthird(stepper: MatStepper) {
    if (!this.thirdFormGroup.valid) {
      this.thirdFormGroup.markAllAsTouched();
    }
  }

  SaveClick() {
    
    this.firstFormGroup.markAllAsTouched();
    this.secondFormGroup.markAllAsTouched();
    this.thirdFormGroup.markAllAsTouched();
    if (this.firstFormGroup.valid && this.secondFormGroup.valid && this.thirdFormGroup.valid) {
      if (this.model.CategoryId) {
        this.model.ModifiedBy = this.loginData.UserCode;
        this._achievementCategoryService
          .Edit(this.model.CategoryId, this.model)
          .subscribe(
            data => {
              if (data.IsSuccess) {
                this._alertService.success(GlobalMessagesModel.updateSuccess);
                this._router.navigate(["master/general-entry-category"]);
              } else {
                this._alertService.error(data.Message);
              }
            },
            error => {
              this._alertService.error(GlobalMessagesModel.updateError);
            }
          );
      } else {
        this.model.CreatedBy = this.loginData.UserCode;
        this._achievementCategoryService.Add(this.model).subscribe(
          data => {
            if (data.IsSuccess) {
              this._alertService.success(GlobalMessagesModel.saveSuccess);
              this._router.navigate(["master/general-entry-category"]);
            } else {
              this._alertService.error(data.Message);
            }
          },
          error => {
            this._alertService.error(GlobalMessagesModel.saveError);
          }
        );
      }
    }
  }


  formGroupInit() {
    this.firstFormGroup = this.fb.group({
      Title: [null, Validators.required],
      TitleHindi: [null, Validators.required],
      ImagePath: [undefined, null],
      IsVisible: [undefined, null],
      CategoryIsVisible: [undefined, null],
      IsVisibleToEndUser: [null],
      HelpFileURL: [null],
      LabelGeneralEntryHindi: [null],
      LabelDescriptionHindi: [null],
      LabelDate: [null],
      LabelURL: [null],
      LabelAddPDF: [null],
      LabelAttachImage: [null],

    });

    this.secondFormGroup = this.fb.group({
      IsVisibleDate: [null],
      IsVisibleDescriptionHindi: [null],
      IsShowConnectWithCMIS: [null],
      IsShowBeneficiaryCategory: [null],
      IsPDFMandatory: [null],
      IsURLMandatory: [null],
      IsImageMandatory: [null],
      IsShowTimeSlot: [null],
      IsTimeSlotMandatory : [null]

    });

    this.thirdFormGroup = this.fb.group({
      MenuClassificationCode: [null],
      MenuClassificationPageTypeCode: [null],
      GeneralDepartmentDistrictMapping: [null],
      GeneralDepartmentDistrictMappingList: [null],
      SubMenuNameEnglish: [null],
      SubMenuNameHindi: [null],
    });

  }



  handleFileInput(event: any) {
    if (event.target.files.item(0).type.match("image/*")) {
      var reader = new FileReader();
      reader.onload = (event: any) => {
        this.model.ImagePath = event.target.result;
      };
      reader.readAsDataURL(event.target.files.item(0));
      this.ImagefileValidationMsg = "";
    } else {
      this.ImagefileValidationMsg = "only *image file accepted ";
    }
  }

  handleFileForMoreDetail(event: any) {
    if (event.target.files.item(0).type.match("application/pdf")) {
      if (
        event.target.files.item(0).size < AppSetting.FileSizeLimit.PDF) {
        const reader = new FileReader();
        reader.onload = (event: any) => {
          this.model.HelpFileURL = event.target.result;
          this.helpFileValidationMsg = "";
        };
        reader.readAsDataURL(event.target.files.item(0));
      } else {
        this.helpFileValidationMsg = AppSetting.FileSizeLimit.PDFErrorMSG;
      }
    } else {
      this.helpFileValidationMsg = "only pdf file accepted";
    }
  }

  RemoveImageFile() {
    this.model.ImagePath = undefined;
  }

  RemoveHelpFile() {
    this.model.HelpFileURL = null;
  }

  downloadPdf(Url) {
    
    const linkSource = Url;
    const downloadLink = document.createElement("a");
    const fileName = "HelpFileDocs";

    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.target = "blank";
    downloadLink.click();
  }

  selectAllDepartment() {
    
    if (this.selectedAllDepartmentDistrict < 0) {
      this.model.GeneralDepartmentDistrictMappingList = this.dDLDistrictDepartment.map(function (a) {
        return a.Value;
      });
      this.selectedAllDepartmentDistrict = 1;
    } else {
      this.selectedAllDepartmentDistrict = -1;
      this.model.GeneralDepartmentDistrictMappingList = [];
    }
  }

  GetDepartmentList(data) {
    if (data) {
      this._commonService.GetDepartmentDistrictList(data).subscribe(
        data => {
          if (data.IsSuccess) {
            this.dDLDistrictDepartment = <DdlItemModel[]>data.Data;
          }
        },
        error => {
          this._alertService.error(error.message);
        }
      );
    } else {
      this.dDLDistrictDepartment = [];
    }
  }

  //#endregion
}
