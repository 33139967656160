import { Component, OnInit,Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA} from "@angular/material";
@Component({
  selector: 'app-sms-template-dialog',
  templateUrl: './sms-template-dialog.component.html',
  styleUrls: ['./sms-template-dialog.component.scss']
})
export class SmsTemplateDialogComponent implements OnInit {

  smstemplate: string;
  constructor(public dialogRef: MatDialogRef<SmsTemplateDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) { }

  ngOnInit() {
  }

  onNoClick(){

    this.dialogRef.close();
  }

}
export interface DialogData {
  smstemplate: string;
  
}