import { IndexModel } from "./common.model";

export class ChangeCommunicationDepartmentOfficeModel {
    CommunicationOfficeLookupCode:number;
    CommunicationCode: number;
    OfficeCode: number;
    UserCode: number;
    UserMapCode: number;
    Purpose:string;  
    DocumentList: CommunicationOfficeLookUpDocumentModel[] = [];
    ForwordToAction:number|string;
}

export class CommunicationOfficeLookUpDocumentModel {
    CommuncationOfficeDocumentLookupCode: number;
    CommunicationOfficeLookupCode: number;
    DocName: string;
    DocPath: string;
    Extension: string;
}


export class CommunicationSearchViewModel {
    CommunicationCode: number;
    WorkOrderCode: number;
    WorkOrderTitle: string;
    WorkOrderSubject: string;
    OrderDate: string | null;
    OrderNumber: string;
    CommunicationTypeCode: number;
    CommunicationType: string;
    CommunicationTypeHindi: string;
    CommunicationTypeEnumValue: string;
    SLADays: number | null;
    SLANotificationDays: number | null;
    OfficeCode: number | null;
    OfficeName: string;
    DepartmentCode: number | null;
    DepartmentName: string;
    DepartmentNameHindi: string;
    DistrictCode: number | null;
    DistrictName: string;
    DistrictNameHindi: string;
    RefNo: string;
    Status: string;
    ProjectOrTenderName: string;
    ProjectName: string;
    projectCode: number;
    StatusDateTime: string | null;
    IsActive: boolean;
    IsDeleted: boolean;
    CreatedBy: number | null;
    CreatedOn: string |Date| null;
    SearchText: string;
    Vendor: string;
    VendorCode: number | null;
    OfficeStaffCode: number | null;
    OfficeStaffUser: string;
    Purpose: string;
    BankCode: number | null;
    BankName: string;
    BankNameHindi: string;
    UserTypeCode: number;
    UserType: string;
    VendorContactPersonName: string;
    IsMoved: number;
    IsCreatedByVendor: number;
    VendorUserCode: number | null;
    CurrentStatus: string;
    IsAssigned:boolean;
    TotalRecords: number;
}

export class CommunicationSearchPostModel {
    LoginUserCode: number;
    CommunicationTypeEnum: string;
    ReferenceNumber: string;
    DepartmentCode: number | null;
    FromDate: string;
    ToDate: string;
    Search: string;
    Status: string;
    IndexModel=new IndexModel();
}