import { IndexModel } from "../common.model";

export class LogReportModel {
    PageURL: string;
    URLType: string;
    ScreenType: string;
    PermissionTitle: string;
    ActivityLogCode: number;
    UserCode: number;
    PagePermsissionUrlId: number | null;
    ActivityDate: Date | null;
    Browser:string;
    IPAddress: string;
    ActionDetail: string;
    SSOID: string;
    UserTypeCode: number|null;
    UserDepartment: string;
    UserOffice: string;
    UserDistrict: string;
    UserProject: string;
    RecordId:number|null;
    ModelData: string;
  }

  export class LogCustomSearchModel {
    constructor() {
      this.indexModel = new IndexModel();
    }
    UserCode: number;
  Ssoid: number | string;
  Name: string;
  UserType: string;
 
  CreatedFrom: Date | string;
  CreatedTo: Date | string;
    indexModel: IndexModel;
  }