import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { AppSetting } from '../model/appsetting.model';
import { IndexModel } from '../model/common.model';
import { Dictionary } from '../model/dictionary.model';

@Injectable({
  providedIn: 'root'
})
export class UserTypeService {

  constructor(private readonly _baseService: BaseService) {
  }

  GetList(model: IndexModel) {
    return this._baseService.post(AppSetting.UserTypeListUrl, model);
  }

  Add(model: any) {
    return this._baseService.post(AppSetting.UserTypeAddUrl, model);
  }

  Detail(id: number) {
    return this._baseService.get(AppSetting.UserTypeDetailUrl + id);
  }

  Edit(id: number, model: any) {
    return this._baseService.post(AppSetting.UserTypeEditUrl + id, model);
  }

  GetById(id: number) {
    return this._baseService.get(AppSetting.UserTypeDetailUrl + id);
  }

  ChangeDeleteStatus(id: number) {
    return this._baseService.post(AppSetting.UserTypeDeleteUrl, id);
  }

  ChangeActiveStatus(id: number) {
    return this._baseService.get(AppSetting.UserTypeUpdateIsActiveUrl + id);
  }

  GetDownLevelUserType(userType: string, includeself = false, isUserCreation: number = 1) {
    var param = new Dictionary<any>();
    param.Add("id", userType)
    param.Add("isInculdeSelf", includeself);
    param.Add("isUserCreation", isUserCreation);
    return this._baseService.get(AppSetting.UserTypeGetDownLevelUrl, param);
  }

  AddUpdateChildUserType(model: any) {
    return this._baseService.post(AppSetting.AddUpdateChildUserTypeUrl, model);
  }

  IsUserTypeExist(userType: string) {
    return this._baseService.get(AppSetting.IsUserTypeExistUrl + userType);
  }

  DetailByUserType(usertype: string) {
    var param = new Dictionary<any>();
    param.Add("usertype", usertype);
    return this._baseService.get(AppSetting.UserTypeDetailByUserTypeUrl, param);
  }

}
