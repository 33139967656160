import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AppComponent } from 'src/app/app.component';
import { UserTypeEnum } from 'src/app/shared/enum/fixed-values.enum';
import { ProjectCategoryModel } from 'src/app/shared/model/master/project-category.model';
import { AlertService } from 'src/app/shared/service/alert.service';
import { AuthenticationService } from 'src/app/shared/service/authentication.service';
import { ProjectCategoryService } from 'src/app/shared/service/master/project-category.service';

@Component({
  selector: 'app-project-category-detail',
  templateUrl: './project-category-detail.component.html',
  styleUrls: ['./project-category-detail.component.scss']
})
export class ProjectCategoryDetailComponent implements OnInit {
  model: ProjectCategoryModel;
  RecordId: number;
  IsStorage:boolean;
  constructor( private readonly _appComponent: AppComponent,
    private readonly _router: Router,
    private _route: ActivatedRoute,
    private readonly _projectCategoryService: ProjectCategoryService,
    private readonly _alertService: AlertService, 
    private readonly _authService: AuthenticationService
  ) {
    this.RecordId = this._route.snapshot.params.id;
    this.model = new ProjectCategoryModel();
    this._appComponent.setPageLayout(
      "Project Category Detail :",
      "keyboard_backspace",
      "Back To List",
      "master/project-category"
    );}

    ngOnInit() {
      this.getDetail();
    }
  
    getDetail() {
      this._projectCategoryService.Detail(this.RecordId).subscribe(
        data => {
          if (data.IsSuccess) {
            this.model = <ProjectCategoryModel>data.Data;
          }
        },
        error => {
          this._alertService.error(error.message);
        }
      );
    }
  
    ngOnDestroy() {
      this.IsStorage = true;
     // sessionStorage.setItem("IsProjectCategoryStorage", JSON.stringify(this.IsStorage));
     }

}
