import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import { AuthenticationService } from 'src/app/shared/service/authentication.service';
import { GlobalMessagesModel } from 'src/app/shared/model/common-messages.model';
import { AppComponent } from 'src/app/app.component';
import { AlertService } from 'src/app/shared/service/alert.service';
import { ComplaintTypeModel } from 'src/app/shared/model/master/complaint-type.model';
import { ComplaintTypeService } from 'src/app/shared/service/master/complaint-type.service';
import { CommonService } from 'src/app/shared/service/common.service';
import { UserViewModel } from 'src/app/shared/model/user.model';
import { isNullOrUndefined } from 'util';
import { UserTypeEnum } from 'src/app/shared/enum/fixed-values.enum';

@Component({
  selector: 'app-add-update-complaint-type',
  templateUrl: './add-update-complaint-type.component.html',
  styleUrls: ['./add-update-complaint-type.component.scss']
})
export class AddUpdateComplaintTypeComponent implements OnInit {

  //#region <<Variable>>
  loginUserDetail: UserViewModel;
  fromGroup: FormGroup;
  model: ComplaintTypeModel;
  RecordId: number = 0;
  loginUserCode: number;
  oldComplaintType: string;
  IsStorage:boolean;
  //#endregion

  constructor(
    private readonly fb: FormBuilder,
    private readonly appComponnet: AppComponent,
    public readonly _commonService: CommonService,
    private readonly _alertService: AlertService,
    private readonly _router: Router,
    private _route: ActivatedRoute,
    private readonly _complaintTypeService: ComplaintTypeService,
    private readonly _authService: AuthenticationService
  ) {
    if (!isNullOrUndefined(this._route.snapshot.params.id)) {
      this.RecordId = this._route.snapshot.params.id;
    }
    var pageHeading = (this.RecordId == 0 ? "Add" : "Update") + " Complaint Type :";
    this.appComponnet.setPageLayout(pageHeading, "keyboard_backspace", "Back To List", "master/complaint-type");
    this.model = new ComplaintTypeModel();
    this.loginUserDetail = this._authService.GetCurrentUserDetail().UserViewModel;
  }

  ngOnInit() {
    this.formGroupInit();
    if (this.RecordId != 0) {
      this.getDetail();
    }
  }

  formGroupInit() {
    this.fromGroup = this.fb.group({
      ComplaintType: [null, Validators.compose([Validators.required, Validators.maxLength(100)])],
      ComplaintTypeHindi: [null, Validators.compose([Validators.required, Validators.maxLength(100)])]
    });
  }

  getDetail() {
    this._complaintTypeService.Detail(this.RecordId).subscribe(
      data => {
        if (data.IsSuccess) {
          this.model = <ComplaintTypeModel>data.Data;
          this.oldComplaintType = this.model.ComplaintType;
        }
      },
      error => {
        this._alertService.error(error.message);
      }
    );
  }

  saveClick() {
    this.fromGroup.markAllAsTouched();
    if (this.fromGroup.valid) {

      if (this.RecordId == 0) {
        this.model.CreatedBy = this.loginUserDetail.UserCode;
      }
      else {
        this.model.ModifiedBy = this.loginUserDetail.UserCode;
      }

      this._complaintTypeService.AddUpdate(this.RecordId, this.model).subscribe(
        data => {
          if (data) {
            if (data.IsSuccess) {
              this._alertService.success(this.RecordId == 0 ? GlobalMessagesModel.saveSuccess : GlobalMessagesModel.updateSuccess);
              this._router.navigate(["master/complaint-type"]);
            } else {
              this._commonService.ScrollingTop();
              this._alertService.error(this.RecordId == 0 ? GlobalMessagesModel.saveError : GlobalMessagesModel.updateError);
            }
          } else {
            this._commonService.ScrollingTop();
            this._alertService.error(GlobalMessagesModel.InternalError);
          }
        },
        error => {
          this._commonService.ScrollingTop();
          this._alertService.error(GlobalMessagesModel.InternalError);
        }
      );
    }
  }

  IsComplaintTypeExist(Event) {
    if (this.oldComplaintType != Event.currentTarget.value) {
      this._complaintTypeService.IsComplaintTypeExist(Event.currentTarget.value).subscribe(result => {

        if (result.IsSuccess) {
          let UserName = <boolean>result.Data
          if (UserName == true) {
            this.model.ComplaintType = null;
            this._alertService.error("Complaint Type is already exist!");
          }
        }
      }, error => {
        this._commonService.ScrollingTop();
        this._alertService.error(error.message);
      });
    }
  }

  ngOnDestroy() {
    this.IsStorage = true;
    sessionStorage.setItem("IsComplaintTypeStorage", JSON.stringify(this.IsStorage));
   }

}
