import { Injectable } from '@angular/core';
import { AppSetting } from '../model/appsetting.model';
import { IndexModel } from '../model/common.model';
import { ComplainEntryTypeMasterViewModel } from '../model/master/complain-entry-type-master.model';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root'
})
export class ComplainEntryTypeMasterService {

  constructor(private readonly _baseService: BaseService) { }

  GetList(model: IndexModel) {
    return this._baseService.post(AppSetting.ComplainEntryTypeListUrl, model);
  }

  Add(model: ComplainEntryTypeMasterViewModel) {
    return this._baseService.post(AppSetting.ComplainEntryTypeAddUrl, model);
  }

  Edit(model: ComplainEntryTypeMasterViewModel) {
    return this._baseService.post(AppSetting.ComplainEntryTypeUpdateUrl, model);
  }

  ChangeActiveStatus(id: number) {
    return this._baseService.get(AppSetting.ComplainEntryTypeUpdateStatusUrl + id);
  }

  GetById(id: number) {
    return this._baseService.get(AppSetting.ComplainEntryTypeGetByIdUrl + id);
  }
}

