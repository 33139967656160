import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatSort, MatDialog, MatTableDataSource } from '@angular/material';
import { AppComponent } from 'src/app/app.component';
import { ConfirmationDialogComponent } from 'src/app/confirmation-dialog/confirmation-dialog.component';
import { AppSetting } from 'src/app/shared/model/appsetting.model';
import { GlobalMessagesModel } from 'src/app/shared/model/common-messages.model';
import { PermissionModel } from 'src/app/shared/model/common.model';
import { ColumnHeaderModel, DDLModel, DdlItemModel } from 'src/app/shared/model/commonddl.model';
import { B2GUserManualFilterModel, B2GUserManualViewModel } from 'src/app/shared/model/master/b2-guser-manual-model';
import { AlertService } from 'src/app/shared/service/alert.service';
import { AuthenticationService } from 'src/app/shared/service/authentication.service';
import { CommonService } from 'src/app/shared/service/common.service';
import { B2GUserManualService } from 'src/app/shared/service/master/b2-guser-manual.service';

@Component({
  selector: 'app-b2-guser-manual',
  templateUrl: './b2-guser-manual.component.html',
  styleUrls: ['./b2-guser-manual.component.scss']
})
export class B2GUserManualComponent implements OnInit {
  //#region <Variable>

  listModel: B2GUserManualViewModel[];
  dataSource: any;
  displayedColumns: string[] = [
    "index",
    "PermissionTypeName",    
    "PermissionTitle",
    "PageDescriptionCategoryName",
    "ApplicationTitle",
    "ModifiedDate",
    "IsActive",
    "Action"
  ];
  ViewdisplayedColumns: ColumnHeaderModel[] = [
    { Value: "PageDescriptionCategoryName", Text: "Description Category" },
    { Value: "ApplicationTitle", Text: "Application" },
    { Value: "PermissionTitle", Text: "Page" },
    { Value: "PermissionTypeName", Text: "Page Type" }
  ];

  columnsToDisplay: string[] = this.displayedColumns.slice();
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  Permission: PermissionModel = this._authService.GetPagePermission(
    "master/b2g-user-manual",
    "master/b2g-user-manual/add",
    "",
    "master/b2g-user-manual/update"
  );
  filterModel= this._commonService.modelSetGet(new B2GUserManualFilterModel()) as  B2GUserManualFilterModel;
  totalRecords: number;
  dDLList: DDLModel;
  ddlPageMasterDetails: DdlItemModel[];
  //#endregion <Variable>

  //#region <Constructor>

  constructor(
    private readonly _b2gUserManualMasterService: B2GUserManualService,
    public readonly _commonService: CommonService,private readonly _authService: AuthenticationService,
    private readonly _alertService: AlertService,
    private _parentApi: AppComponent,
    private _dialog: MatDialog
  ) {
    this.Permission.AddPageAccess
      ? this._parentApi.setPageLayout(
        "B2G User Manual List:",
        "add",
        "Create",
        "master/b2g-user-manual/add"
      )
      : this._parentApi.setPageLayout("B2G User Manual List:");
  
  }

  //#endregion <Constructor>

  //#region <Method>

  ngOnInit() {
    this.filterModel.PageSize=101;
    this.filterModel.OrderBy ="PermissionTitle"
    this.GetList();
    this.GetDDLList();
  }

  GetDDLList() {
    this._commonService.GetAllDDL(AppSetting.B2GUserManualDDLKey).subscribe(
      data => {
        
        if (data.IsSuccess) {
          this.dDLList = <DDLModel>data.Data;
        }
      },
      error => {
        this._alertService.error(error.message);
      }
    );
  }

  GetPageByPageType(code = 0, applicationCode = '') {
    this._commonService.GetPageMasterByPageTypeCode(code, applicationCode).subscribe(
      data => {
        
        if (data.IsSuccess) {
          this.ddlPageMasterDetails = <DdlItemModel[]>data.Data;
        }
      },
      error => {
        this._alertService.error(error.message);
      }
    );
  }

  ClearClick() {
    this.filterModel = new B2GUserManualFilterModel();
    this.GetList();
  }

  GetList() {
    this._b2gUserManualMasterService.GetList(this._commonService.modelSetGet(this.filterModel, true)).subscribe(
      data => {
        if (data.IsSuccess) {
          this.listModel = <B2GUserManualViewModel[]>data.Data.Data;
          this.dataSource = new MatTableDataSource<B2GUserManualViewModel>(
            this.listModel
          );
          this.dataSource.paginator = this.paginator;
          this.totalRecords = data.Data.TotalRecords;
          this.dataSource.sort = this.sort;
        }
      },
      error => {
        this._alertService.error(error.message);
      }
    );
  }

  onPaginateChange(event) {
    this.filterModel.Page = event.pageIndex + 1;
    this.filterModel.PageSize = event.pageSize;
    this.GetList();
  }

  sortData(event) {
    this.filterModel.OrderBy = event.active;
    this.filterModel.OrderByAsc =
      event.direction == AppSetting.orderByDscAsc
        ? AppSetting.orderByAsc
        : AppSetting.orderByDsc;
    this.GetList();
  }


  onActiveStatus(id) {
    const dialogRef = this._dialog.open(ConfirmationDialogComponent, {
    //   width: "50%", 
       data: GlobalMessagesModel.ConfirmStatusChanged });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this._b2gUserManualMasterService.ChangeActiveStatus(id).subscribe(
          data => {
            if (data.IsSuccess) {
              this.GetList();
              this._alertService.success(data.Message);
            } else {
              this._alertService.error(data.Message);
            }
          },
          error => {
            this._alertService.error(error.message);
          }
        );
      }
    });
  }

  PagePrint(printContent) {
    this._commonService.printHTMLContent(printContent, "B2G Page Manual Master");
  }


  //#endregion <Method>
}