export class VendorTypeModel {
    VendorTypeCode: number;
    VendorType: string;
    VendorTypeHindi: string;
    IsActive: boolean;
    IsDeleted: boolean;
    CreatedBy: number | null;
    CreatedOn: Date | null;
    ModifiedBy: number | null;
    ModifiedOn: Date | null;
    UserName:string;
}

export class VendorTypeReportModel {
    MasterCode: number;
    FieldValueInEnglish: string;
    FieldValueInHindi: string;
    IsActive: boolean;
    IsDeleted: boolean;
    CreatedBy: number | null;
    CreatedOn: Date | null;
    ModifiedBy: number | null;
    ModifiedOn: Date | null;
    UserName:string;
}

