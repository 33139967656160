import { Component, OnInit, Input } from "@angular/core";
import { MatDialog } from "@angular/material";
import { isNullOrUndefined } from "util";
import { AppComponent } from "../app.component";
import { DashboardHelpDocumentComponent } from "../content/dashboard/dashboard-help-document/dashboard-help-document.component";
import { ReportSearchCriteriaDialogComponent } from "../content/report/report-search-criteria-dialog/report-search-criteria-dialog.component";
import { UpdateProfileComponent } from "../content/user-creation/update-profile/update-profile.component";
import { UserDetailDialogComponent } from "../content/user/user-detail-dialog/user-detail-dialog.component";
import { UpdateVendorUserProfileComponent } from "../content/vendor-user/update-vendor-user-profile/update-vendor-user-profile.component";
import { UpdateVendorProfileDialogComponent } from "../content/vendor/update-vendor-profile-dialog/update-vendor-profile-dialog.component";
import { GlobalCommunicationSearchComponent } from "../global-communication-search/global-communication-search.component";
import { UserTypeEnum } from "../shared/enum/fixed-values.enum";
import { AppSetting } from "../shared/model/appsetting.model";
import { GlobalMessagesModel } from "../shared/model/common-messages.model";
import { CommonDocModel, PermissionModel } from "../shared/model/common.model";
import { DDLModel } from "../shared/model/commonddl.model";
import { AdditionalUserPermissionModel } from "../shared/model/user-permission.model";
import { UserViewModel, UserNotificationModel, UserCreationModel } from "../shared/model/user.model";
import { AlertService } from "../shared/service/alert.service";
import { AuthenticationService } from "../shared/service/authentication.service";
import { CommonService } from "../shared/service/common.service";
import { ChiefMinisterProfileService } from "../shared/service/master/chiefminister-profile.service";
import { GlobalReportService } from "../shared/service/report/global-report.service";
import { UserNotificationService } from "../shared/service/user-notification.service";
import { UserService } from "../shared/service/user.service";
import { ChangeOfficeDialogComponent } from "./change-office-dialog/change-office-dialog.component";
import { ChangeUserTypeDialogComponent } from "./change-user-type-dialog/change-user-type-dialog.component";
import { GeneralHelpDialogComponent } from "./general-help-dialog/general-help-dialog.component"
import { UserManualDialogComponent } from "./user-manual-dialog/user-manual-dialog.component";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  providers: [ChiefMinisterProfileService]
})

export class HeaderComponent implements OnInit {

  isAdditionalUser = false;
  userName: string;
  dDLList = new DDLModel();
  userTypeEnum = UserTypeEnum;
  userType: string;
  loggedUserDepartment: string;
  loggedUserDistrict: string;
  loggedUserOfficeName: string;
  loggedUserName: string;
  helpDocument: CommonDocModel;
  totalNotifications: number = 0;
  userDetail: UserViewModel = new UserViewModel();
  top5NotificationList: UserNotificationModel[] = [];
  AdditionalUserPermissionModel = new AdditionalUserPermissionModel();
  Permission: PermissionModel = this._authenticationService.GetPagePermission("/communications-list");
  dataSource: any;
  @Input() PageTitle: string;

  get OriginalLoginUserCode(): number {
    if (this._commonService.GetLocalStorage("originalUserCode") != undefined) {
      this.isAdditionalUser = true;
      return Number(this._commonService.GetLocalStorage("originalUserCode"));
    }
    else {
      return this._authenticationService.UserCode;

    }
  }

  get OriginalLoginUserName() {
    return this._commonService.GetLocalStorage("originalUserName") != undefined ? this._commonService.GetLocalStorage("originalUserName") :
      this._authenticationService.LoggedInUserDetail.UserViewModel.Name;
  };

  IsUserHaveMultipleOffice: boolean
  HasAdditionalRole: boolean;
  constructor(private readonly _authenticationService: AuthenticationService,
    private readonly _dialog: MatDialog,
    private readonly _commonService: CommonService,
    private readonly _alertService: AlertService,
    private readonly _notificationService: UserNotificationService,
    private readonly _app: AppComponent,
    private readonly _user: UserService,
    private matDialog: MatDialog,
    private _globalReportService: GlobalReportService,
    private readonly _chiefMinisterProfileService: ChiefMinisterProfileService,
  ) {

  }

  ngOnInit() {
    this.getDDLListForAdditionalUser();
    this.bindUserDetail();
    this.GetList();
  }

  bindUserDetail() {

    this.userDetail = this._authenticationService.GetCurrentUserDetail() == null ? null : this._authenticationService.GetCurrentUserDetail().UserViewModel;
    this.userName = this.userDetail.Name;
    this.userType = this.userDetail.UserTypeTitle;
    this.loggedUserDepartment = this.userDetail.DepartmentNames;
    if (!isNullOrUndefined(this.userDetail.DistrictNames)) {
      this.loggedUserDistrict = '(' + this.userDetail.DistrictNames + ')';
    }
    this.loggedUserOfficeName = this.userDetail.OfficeNames;
    this.loggedUserName = `${this.userDetail.Name} (${this.userDetail.UserTypeTitle}${this.userDetail.UserTypeCategoryTitle ? " - " + this.userDetail.UserTypeCategoryTitle : ""})`;
    this.HasAdditionalRole = (this.userDetail.HasAdditionalRole);
    this.IsUserHaveMultipleOffice = this.userDetail.BaseUserType == UserTypeEnum.HOO ? true : false;


    this.GetUserManualDocument();

    this.getUserNotifications();
    this.ShowUpdateProfilePopup();

  }

  ShowUpdateProfilePopup() {
    if (!this.userDetail.IsUpdateProfile
      && this.userDetail.BaseUserType != this.userTypeEnum.VNDR
      && this.userDetail.BaseUserType != this.userTypeEnum.VNDRUSER
      && this.userDetail.BaseUserType != this.userTypeEnum.ADM
      && this.userDetail.BaseUserType != this.userTypeEnum.SADM) {
      // this.OpenUserProfileDialog(false);
    }
    else if (!this.userDetail.IsUpdateProfile
      &&
      this.userDetail.BaseUserType == this.userTypeEnum.VNDR
    ) {
      // this.OpenVendorProfileDialog();
    }
    else if (!this.userDetail.IsUpdateProfile
      &&
      this.userDetail.BaseUserType == this.userTypeEnum.VNDRUSER
    ) {
      //this.OpenVendorUserProfileDialog();
    }
    else if (this.userDetail.BaseUserType != this.userTypeEnum.VNDR
      && this.userDetail.BaseUserType != this.userTypeEnum.VNDRUSER
      && this.userDetail.BaseUserType != this.userTypeEnum.ADM
      && this.userDetail.BaseUserType != this.userTypeEnum.SADM
      && !this.userDetail.IsUpdateProfile) {
      //this.OpenUserProfileDialog();
    }
    else { this.showHelpDocument(); }

  }

  getUserNotifications() {
    let serv = this._notificationService.GetList(this.userDetail.UserCode).subscribe(
      data => {
        serv.unsubscribe();
        if (data.IsSuccess) {
          var allNotifications = <UserNotificationModel[]>data.Data;
          this.totalNotifications = !isNullOrUndefined(allNotifications) && allNotifications.length > 0 ? allNotifications.length : 0;
          this.top5NotificationList = allNotifications.slice(0, 5);
        }
        else
          this._alertService.error(data.Message);
      },
      error => {
        this._alertService.error(error.message);
      }
    );
  }

  logOut() {
    this._app.clearLayout();
    this.SaveLoginUserLog();

    setTimeout(() => { this._authenticationService.LogOut(); }, 200);
  }

  SaveLoginUserLog() {
    let serv = this._commonService.SaveLoginUserLog().subscribe(data => {
      serv.unsubscribe();
    }, error => {
      serv.unsubscribe();
      this._alertService.error(error.message);
    });
  }

  backToSSO() {

    this._authenticationService.BackToSSO();
  }

  globalReportSearchClick() {
    const _dialogRef = this._dialog.open(ReportSearchCriteriaDialogComponent, {
      width: "1000px",
      disableClose: true
    });
    let serv = _dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        serv.unsubscribe();
        this._globalReportService.SetData(result);
      }
    });
  }

  GetUserManualDocument() {
    let serv = this._commonService.GetUserManualDocument(Number(this.userDetail.UserTypeCode)).subscribe(
      data => {
        serv.unsubscribe();
        if (data.IsSuccess) {

          this.helpDocument = <CommonDocModel>data.Data;
        } else {
          this._alertService.error(data.Message);
        }
      },
      error => {
        serv.unsubscribe();
        this._alertService.error(error.message);
      }
    );
  }

  public GetList() {
    this._chiefMinisterProfileService.get().subscribe({
      next: response => {
        if (response.IsSuccess == true) {
          console.log(response.Data);
          if (response.Data && response.Data.length > 0) {
            this.dataSource = response.Data[0].PdfFIleName;
            console.log(this.dataSource);
          }
        }
      },
      error: error => {
        console.log(error);
      }
    });
  }

  downloadPdf(url: string) {
    var w = window.open('about:blank');
    setTimeout(function () {
      w.document.body.appendChild(w.document.createElement('iframe'))
        .src = url;
      w.document.getElementsByTagName("iframe")[0].style.width = '100%';
      w.document.getElementsByTagName("iframe")[0].style.height = '100%';
    }, 0);
  }

  OpenUserDetailDialog(Id) {

    const _dialogRef = this._dialog.open(UserDetailDialogComponent, {
      width: "1000px",
      data: { Id },
      disableClose: true
    });
  }

  OpenUserProfileDialog(hasCloseBtn: boolean = true) {
    const _dialogRef = this._dialog.open(UpdateProfileComponent, {
      width: "75%",
      // height: "95%",
      data: { hasCloseBtn: String(hasCloseBtn) },
      disableClose: true,
      panelClass: 'no-print'
    });
    let serv = _dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        serv.unsubscribe();
        this._authenticationService.UpdateLoginUserDetail();
        setTimeout(() => { this.bindUserDetail(); }, 5000);
      }
      this.showHelpDocument();
    });
  }

  OpenVendorUserProfileDialog() {
    const _dialogRef = this._dialog.open(UpdateVendorUserProfileComponent, {
      width: "75%",

      disableClose: true,
      panelClass: 'no-print'
    });
    let serv = _dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        serv.unsubscribe();
        this._authenticationService.UpdateLoginUserDetail();
        setTimeout(() => {
          this.bindUserDetail();
        }, 2500);
      }

    });
  }

  OpenVendorProfileDialog() {
    const _dialogRef = this._dialog.open(UpdateVendorProfileDialogComponent, {
      width: "75%",
      data: 0,
      disableClose: true,
      panelClass: 'no-print'
    });
    let serv = _dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        serv.unsubscribe();
        this._authenticationService.UpdateLoginUserDetail();
        setTimeout(() => {
          this.bindUserDetail();
        }, 2500);
      }

    });
  }

  switchUser(userCode) {
    if (this._commonService.GetLocalStorage("originalUserCode") == undefined) {
      this._commonService.SetLocalStorage("originalUserCode", this.OriginalLoginUserCode.toString());
      this._commonService.SetLocalStorage("originalUserName", this.OriginalLoginUserName.toString());
      this.isAdditionalUser = true;
    }
    if (userCode == this.OriginalLoginUserCode) {
      this.isAdditionalUser = false;
      this._commonService.RemoveLocalStorage("originalUserCode");
      this._commonService.RemoveLocalStorage("originalUserName");
    }
    else {
      this._commonService.SetLocalStorage("originalUserCode", this.OriginalLoginUserCode.toString());
      this._commonService.SetLocalStorage("originalUserName", this.OriginalLoginUserName.toString());
      this.isAdditionalUser = true;
    }

    let serv = this._user.GetB2GUserById(userCode).subscribe(response => {
      serv.unsubscribe();
      if (response.IsSuccess) {

        let userDetail = <UserCreationModel>response.Data;
        this._authenticationService.SwitchUser(userDetail.SSOID);
      }
    })
  }

  getDDLListForAdditionalUser() {

    let serv = this._commonService.GetAllDDL(AppSetting.DDlKeyForAdditionalUser, "", this.OriginalLoginUserCode).subscribe((data) => {
      serv.unsubscribe();
      if (data.IsSuccess) {
        let ddlList = <DDLModel>data.Data;
        this.dDLList.ddlAdditionalUser = ddlList.ddlAdditionalUser;
      }
    }, (error) => {
      this._alertService.error(GlobalMessagesModel.InternalError);
    });
  }

  openCommunicationSearchModal() {
    const _dialogRef = this._dialog.open(GlobalCommunicationSearchComponent, {
      width: "90%",
      height: "80%",
      data: { IsShowCommunicationType: false, CommunicationType: null },
      disableClose: true
    });
    let serv = _dialogRef.afterClosed().subscribe((result: boolean) => {
      serv.unsubscribe();
      if (result) {
        // this.getList();
      }
    });
  }

  openPageManual(redirect = false) {
    this._dialog.open(UserManualDialogComponent, {
      width: "95%",
      disableClose: true,
      data: { isMedia: redirect }
    });
  }

  openChangeOfficeModal() {
    const _dialogRef = this._dialog.open(ChangeOfficeDialogComponent, {
      width: "500px",
      disableClose: true,
      data: {}
    });
  }

  showHelpDocument() {
    if (localStorage.getItem("IsLogin") != undefined && localStorage.getItem("IsLogin") != "" && JSON.parse(this._commonService.GetLocalStorage("IsLogin"))) {
      setTimeout(() => {
        this._commonService.SetLocalStorage("IsLogin", "false");
      }, 3000);
      if (JSON.parse(this._commonService.GetLocalStorage("IsLogin"))) {
        setTimeout(() => {
          this.openHelpDocument();
        }, 1500);
      }
    }
  }

  openHelpDocument() {
    const _dialoghelp = this._dialog.open(DashboardHelpDocumentComponent, {
      width: "80%",
      disableClose: true
    });
    let serv = _dialoghelp.afterClosed().subscribe((result: boolean) => {
      serv.unsubscribe();
      if (result) {
      }
    });
  }

  openGeneralHelpDocument() {
    const _dialoghelp = this._dialog.open(GeneralHelpDialogComponent, {
      width: "80%",
      disableClose: true
    });
    let serv = _dialoghelp.afterClosed().subscribe((result: boolean) => {
      serv.unsubscribe();
      if (result) {
      }
    });
  }

  openChangeRoleModal() {

    const _dialogRef = this._dialog.open(ChangeUserTypeDialogComponent, {
      width: "500px",
      disableClose: true,
      data: {}
    });
    let serv = _dialogRef.afterClosed().subscribe((result: boolean) => {
      serv.unsubscribe();
      if (result) {
        this._alertService.success(GlobalMessagesModel.RoleSwitchSuccess);
        this._authenticationService.RefreshLoginUserDetail();
        setTimeout(() => {
          this._app.UpdateMenu();
          this.bindUserDetail();
        }, 1000);
      }
    });
  }


}
