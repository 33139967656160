import { isNullOrUndefined } from 'util';
import { Route } from '@angular/compiler/src/core';
import { CommonService } from 'src/app/shared/service/common.service';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { GlobalFilterModel, SearchModel } from '../shared/model/common.model';
import { ColumnHeaderModel } from '../shared/model/commonddl.model';
import { Router } from '@angular/router';

@Component({
  selector: 'app-global-list-search',
  templateUrl: './global-list-search.component.html',
  styleUrls: ['./global-list-search.component.scss']
})

export class GlobalListSearchComponent implements OnInit {
  searchModel: SearchModel;
  @Input() SearchFields: ColumnHeaderModel[];
  @Input() DefaultValue: SearchModel;
  @Output() onSearch = new EventEmitter<GlobalFilterModel>();
  @Output() onClear = new EventEmitter<void>();
  @Input() isShowActiveFilter = true;
  model = new GlobalFilterModel()
  constructor(public readonly _commonService: CommonService, private readonly router: Router) {
    
    if (this.DefaultValue) {
      this.searchModel = this.DefaultValue;
    } else {
      this.setDefaultValue();
    }

  }

  ngOnInit() {

  }

  onSearchClick() {
   
    this.model.search = this._commonService.ObjectToJsonByKeyValuePair(this.searchModel.Key, this.searchModel.Value)
    this.onSearch.emit(this.model);
  }

  onClearClick() {
    this.searchModel = new SearchModel();
    this.model = new GlobalFilterModel();
    this.onClear.emit();
  }

  setValue(key, value) {
    this.searchModel.Key = key;
    this.searchModel.Value = value;
  }
  setDefaultValue() {
   
    this.searchModel = new SearchModel();
    if (this.DefaultValue == undefined) {
      if (sessionStorage.getItem("postBackSearch_" + this.router.url) != undefined) {
        var temp = JSON.parse(sessionStorage.getItem("postBackSearch_" + this.router.url));
      }
      if (temp != null && !isNullOrUndefined(temp.Search) && temp.Search.length > 0) {
        this.DefaultValue = new SearchModel()
        this.DefaultValue.Key = Object.keys(JSON.parse(temp.Search))[0];
        this.DefaultValue.Value = String(Object.values(JSON.parse(temp.Search))[0]);

        this.searchModel = this.DefaultValue;
      }
      if (temp != null && temp.AdvanceSearchModel != undefined && temp.AdvanceSearchModel.ActiveStatus) {
        this.model.ActiveStatus = temp.AdvanceSearchModel.ActiveStatus;

      }

    }
  }
}
