import { Component, OnInit, ViewChild } from "@angular/core";
import { AppComponent } from "src/app/app.component";
import { GlobalFilterModel, IndexModel, PermissionModel, SearchModel } from 'src/app/shared/model/common.model';
import { GlobalMessagesModel } from 'src/app/shared/model/common-messages.model';
import { AppSetting } from 'src/app/shared/model/appsetting.model';
import { MatTableDataSource, MatDialog, MatSort, MatPaginator } from "@angular/material";
import { ConfirmationDialogComponent } from "src/app/confirmation-dialog/confirmation-dialog.component";
import { AlertService } from 'src/app/shared/service/alert.service';
import { CommonService } from 'src/app/shared/service/common.service';
import { ColumnHeaderModel } from 'src/app/shared/model/commonddl.model';
import { UserTypeEnum } from 'src/app/shared/enum/fixed-values.enum';
import { AuthenticationService } from 'src/app/shared/service/authentication.service';
import { Router } from '@angular/router';
import { MasterDataModel } from 'src/app/shared/model/master/master-data.model';
import { MasterDataService } from 'src/app/shared/service/master/master-data.service';

@Component({
  selector: 'app-master-data',
  templateUrl: './master-data.component.html',
  styleUrls: ['./master-data.component.scss']
})
export class MasterDataComponent implements OnInit {

  model: MasterDataModel[];
  indexModel = this._commonService.modelSetGet(new IndexModel()) as IndexModel;;
  dataSource: any;
  totalRecords: number;
  defaultValue: SearchModel;

  displayedColumns: string[] = ["index", "MasterTypeName", "FieldValueInEnglish", "FieldValueInHindi", "SortOrder", "ModifiedDate", "IsActive", "Action"];
  columnsToDisplay: string[] = this.displayedColumns.slice();
  ViewdisplayedColumns: ColumnHeaderModel[] = [
    { Value: "MasterTypeName", Text: "Master Type Name" },
    { Value: "FieldValueInEnglish", Text: "FieldValue (English)" },
    { Value: "FieldValueInHindi", Text: "FieldValue (Hindi)" },
    { Value: "SortOrder", Text: "SortOrder" },
  ];
  // ActiveStatus: number | string = "1";
  ShowFilter = false;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  Permission: PermissionModel = this._authService.GetPagePermission("/master/master-data", "master-data/add", "master-data/detail", "master-data/update", "master-data/delete");

  constructor(
    private readonly appComponnet: AppComponent,
    private readonly _alertService: AlertService,
    private readonly _masterDataService: MasterDataService,
    private readonly _dialog: MatDialog, private readonly _authService: AuthenticationService,
    public readonly _commonService: CommonService) {

    this.Permission.AddPageAccess ? this.appComponnet.setPageLayout("Look Up Data :", "add", "Create", "master/master-data/add") : this.appComponnet.setPageLayout("Look Up Data :");

  }


  ngOnInit() {
    this.getList();
  }

  getList() {

    this._masterDataService.GetList(this._commonService.modelSetGet(this.indexModel, true)).subscribe(
      data => {
        if (data.IsSuccess) {
          this.model = <MasterDataModel[]>data.Data.Data;
          this.dataSource = new MatTableDataSource<MasterDataModel>(this.model);

          this.totalRecords = data.Data.TotalRecords;
          if (!this.indexModel.IsPostBack) {
            this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.sort;
          }
        } else {
          this._alertService.error(data.Message);
        }
      },
      error => {
        this._alertService.error(error.message);
      }
    );
  }

  updateDeleteStatus(id) {
    const dialogRef = this._dialog.open(ConfirmationDialogComponent, { width: "50%", data: GlobalMessagesModel.ConfirmDeleted });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this._masterDataService.ChangeDeleteStatus(id).subscribe(
          data => {
            if (data.IsSuccess) {
              this._alertService.success(data.Message);
              this._commonService.ScrollingTop();
              this.getList();
            }
          },
          error => {
            this._alertService.error(error.message);
          }
        );
      }
    });
  }

  updateActiveStatus(id) {
    const dialogRef = this._dialog.open(ConfirmationDialogComponent, { width: "50%", data: GlobalMessagesModel.ConfirmStatusChanged });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this._masterDataService.ChangeActiveStatus(id).subscribe(
          data => {
            if (data.IsSuccess) {
              this._alertService.success(data.Message);
              this.getList();
            }
          },
          error => {
            this._alertService.error(error.message);
          }
        );
      }
    });
  }

  sortData(event) {
    this.indexModel.OrderBy = event.active;
    this.indexModel.OrderByAsc = event.direction == AppSetting.orderByDscAsc ? AppSetting.orderByAsc : AppSetting.orderByDsc;
    this.indexModel.IsPostBack = true;
    this.getList();
  }

  onPaginateChange(event) {
    this.indexModel.Page = event.pageIndex + 1;
    this.indexModel.PageSize = event.pageSize;
    this.indexModel.IsPostBack = true;
    this.getList();
  }

  SearchByKeyword(searchValue: GlobalFilterModel) {
    this.indexModel.Search = searchValue.search;
    this.indexModel.AdvanceSearchModel = { ActiveStatus: searchValue.ActiveStatus }

    this.getList();
  }

  ClearSearch() {
    this.indexModel = new IndexModel();
    this.getList();
  }


  showHideFilter() {

    this.ShowFilter = !this.ShowFilter;
  }

  PagePrint(printContent) {
    this._commonService.printHTMLContent(printContent, "Lookup Data");
  }


}
