import { AppSetting } from './../../model/appsetting.model';
import { IndexModel } from './../../model/common.model';
import { BaseService } from './../base.service';
import { Injectable } from '@angular/core';
import { DepartmentMasterModel, LoginUserDepartmentListModel, DepartmentProfileModel, DepartmentProfileExistModel } from '../../model/master/department-master.model';
import { Dictionary } from '../../model/dictionary.model';


//New
// import { DepartmentMasterModel, LoginUserDepartmentListModel, DepartmentProfileModel, DepartmentProfileExistModel } from '../Model/Master/department.model';


@Injectable({
  providedIn: 'root'
})
export class DepartmentMaster {

  constructor(private readonly _baseService: BaseService) {

  }


  // GetList(model: IndexModel, isActive: number) {

  //  return this._baseService.post(AppSetting.DepartmentMasterListUrl + isActive, model);
  // }

  GetList(model: IndexModel,  loginUserCode: number) {


    return this._baseService.post(AppSetting.DepartmentMasterListUrl + "?loginUserCode=" + loginUserCode , model);
  }

  Add(model: DepartmentMasterModel) {
    return this._baseService.post(AppSetting.DepartmentMasterAddUrl, model);
  }

  Edit(model: DepartmentMasterModel) {
    return this._baseService.post(AppSetting.DepartmentMasterUpdateUrl, model);
  }

  ChangeActiveStatus(id: number) {
    return this._baseService.get(AppSetting.DepartmentMasterUpdateStatusUrl + id);
  }

  GetById(id: number) {
    return this._baseService.get(AppSetting.DepartmentMasterGetByIdUrl + id);
  }

  //#region <Report Scheme and department>

  GetDepartmentReport() {
    return this._baseService.get(AppSetting.DepartmentReportUrl);
  }

  GetDepartmentSchemeReport() {
    return this._baseService.get(AppSetting.DepartmentSchemeReportUrl);
  }

  //#endregion <Report Scheme and department>

  //#region <Website Details>

  GetLoginUserDepartmentList() {
    return this._baseService.get(AppSetting.GetLoginUserDepartmentListUrl);
  }

  UpdateLoginUserDepartment(model: LoginUserDepartmentListModel) {
    return this._baseService.post(AppSetting.UpdateLoginUserDepartmentUrl, model);
  }

  //#endregion <Website Details>

  //#region <Department Profile>

  GetDepartmentProfileList(model: IndexModel) {
    return this._baseService.post(AppSetting.GetDepartmentProfileListUrl, model);
  }

  GetDepartmentProfileById(id: number) {
    return this._baseService.get(AppSetting.GetDepartmentProfileByIdUrl + id);
  }

  DepartmentProfileAddUpdate(model: DepartmentProfileModel) {
    return this._baseService.post(AppSetting.DepartmentProfileAddUpdateUrl, model);
  }

  UpdateDepartmentProfileStatus(id: number) {
    return this._baseService.get(AppSetting.UpdateDepartmentProfileStatusUrl + id);
  }

  IsDepartmentProfileExist(model: DepartmentProfileExistModel) {

    
    return this._baseService.post(AppSetting.IsDepartmentProfileExistUrl, model);
  }
//added by pravesh dt 16032021
  GetDepartmentReportPDFDownload(model: IndexModel, isActive: number, loginUserCode: number) {
    return this._baseService.post(AppSetting.DepartmentMasterListUrl + "?loginUserCode=" + loginUserCode + "&isActive=" + isActive, model);
  }

  GetPDFDownload(model: IndexModel, isActive: number, loginUserCode: number) {
    return this._baseService.post(AppSetting.DepartmentMasterReportPDFDownloadUrl+ "?loginUserCode=" + 1 + "&isActive=" + 1, model);
}

GetDepartmentByVendorList(loginUserCode:number,  model: IndexModel) {
  return this._baseService.post(AppSetting.DepartmentbyVendorListUrl+ "?loginUserCode=" + loginUserCode, model);
}   

IsDepartmentExist(name: string,id:number){
  const param = new Dictionary<any>();
  param.Add("name", name);
  param.Add("id", id);
  
  return this._baseService.get(AppSetting.IsDepartmentMasterExistUrl ,param);
}

IsDepartmentShortTitleExist(name: string,id:number){
  const param = new Dictionary<any>();
  param.Add("name", name);
  param.Add("id", id);
  
  return this._baseService.get(AppSetting.IsDepartmentMasterShortTitleExistUrl ,param);
}

}
