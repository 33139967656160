export class FileUploaderModel {
    public Id: number = 0;

    public Name: string | null = null;

    public Path: string | null = null;

    public Base64: string | null = null;

    public File: File | null = null;
}
