import { ConfirmationDialogComponent } from 'src/app/confirmation-dialog/confirmation-dialog.component';
import { BaseService } from '../../../shared/service/base.service';
import { DepartmentContactDetailsService } from '../../../shared/service/master/department-contact-details.service';
import { CommonService } from 'src/app/shared/service/common.service';
import { AlertService } from 'src/app/shared/service/alert.service';
import { ColumnHeaderModel } from 'src/app/shared/model/commonddl.model';
import { MatTableDataSource, MatPaginator, MatSort, MatDialog } from '@angular/material';
import { GlobalFilterModel, IndexModel, PermissionModel } from 'src/app/shared/model/common.model';
import { DepartmentContactDetailsViewModel } from '../../../shared/model/master/department-contact-details.model';
import { Component, OnInit, ViewChild } from '@angular/core';
import { AppComponent } from 'src/app/app.component';
import { AppSetting } from 'src/app/shared/model/appsetting.model';
import { GlobalMessagesModel } from 'src/app/shared/model/common-messages.model';
import { AuthenticationService } from 'src/app/shared/service/authentication.service';

@Component({
  selector: 'app-department-contact-details-new',
  templateUrl: './department-contact-details-new.component.html',
  styleUrls: ['./department-contact-details-new.component.scss']
})
export class DepartmentContactDetailsNewComponent implements OnInit {

  //#region <Variable>

  listModel: DepartmentContactDetailsViewModel[];
  indexModel = this._commonService.modelSetGet(new IndexModel()) as IndexModel;
  totalRecords: number;
  dataSource: MatTableDataSource<DepartmentContactDetailsViewModel>;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  displayedColumns: string[] = [
    "index",
    "DepartmentTitle",
    "DesignationName",
    "OfficerName",
    "MobileNo",
    "Email",
    "SSOID",
    "IsVisibleOnFront",
    "ModifiedDate",
    "Status",
    "Action",
  ];
  ViewdisplayedColumns: ColumnHeaderModel[] = [
    { Value: "DepartmentTitle", Text: "Department" },
    { Value: "DesignationName", Text: "Designation" },
    { Value: "OfficerName", Text: "Officer Name" },
    { Value: "MobileNo", Text: "Mobile No." },
    { Value: "Email", Text: "Email" },
    { Value: "SSOID", Text: "SSOID" }
  ];
  columnsToDisplay: string[] = this.displayedColumns.slice();
  permission: PermissionModel = this._authService.GetPagePermission("/master/department-contact-details", "department-contact-details/add", "department-contact-details/detail", "department-contact-details/update", "department-contact-details/delete");

  //#endregion <Variable>

  //#region <constructor>
  constructor(private readonly _alertService: AlertService,
    private readonly _parentApi: AppComponent,
    public readonly _commonService: CommonService, private readonly _authService: AuthenticationService,
    private readonly _departmentContactService: DepartmentContactDetailsService,
    private readonly _dialog: MatDialog) {
    this.permission.AddPageAccess
      ? this._parentApi.setPageLayout("Department Contact Details List:", "add", "Add", "master/department-contact-details/add"
      )
      : this._parentApi.setPageLayout("Department Contact Details List :");

  }
  //#endregion <constructor>

  ngOnInit() {
    this.GetList();
  }

  GetList() {
    this._departmentContactService.GetList(this._commonService.modelSetGet(this.indexModel, true)).subscribe(
      (data) => {
        if (data.IsSuccess) {
          this.listModel = <DepartmentContactDetailsViewModel[]>data.Data.Data;
          this.dataSource = new MatTableDataSource<DepartmentContactDetailsViewModel>(
            this.listModel
          );
          //   if (this.indexModel.IsPostBack === false) {
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
          // }
          this.totalRecords = data.Data.TotalRecords;
        }
      },
      (error) => {
        this._alertService.error(error.message);
      }
    );
  }


  sortData(event) {
    this.indexModel.OrderBy = event.active;
    this.indexModel.OrderByAsc =
      event.direction == AppSetting.orderByDscAsc
        ? AppSetting.orderByAsc
        : AppSetting.orderByDsc;
    this.indexModel.IsPostBack = true;
    this.GetList();
  }

  onPaginateChange(event) {
    this.indexModel.Page = event.pageIndex + 1;
    this.indexModel.PageSize = event.pageSize;
    this.indexModel.IsPostBack = true;
    this.GetList();
  }

  ChangeActiveStatusClick(id) {
    const dialogRef = this._dialog.open(ConfirmationDialogComponent, { width: "50%", data: GlobalMessagesModel.ConfirmStatusChanged });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this._departmentContactService.ChangeActiveStatus(id).subscribe(
          data => {
            if (data.IsSuccess) {
              this.GetList();
              this._alertService.success(data.Message);
            } else {
              this._alertService.error(data.Message);
            }
          },
          error => {
            this._alertService.error(error.message);
          }
        );
      }
    });
  }

  SearchByKeyword(searchValue: GlobalFilterModel) {

    this.indexModel.Search = searchValue.search;
    this.indexModel.AdvanceSearchModel = { ActiveStatus: searchValue.ActiveStatus }
    this.GetList();
  }

  ClearSearch() {
    this.indexModel = new IndexModel();
    this.indexModel.AdvanceSearchModel = { ActiveStatus: "1" }
    this.GetList();
  }

  PagePrint(printContent) {
    this._commonService.printHTMLContent(printContent, "Department Contact Master");
  }
}