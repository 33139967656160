export class DashBoardFilterModel {
    constructor() {
        this.LoginUserCode = 0;
        this.VendorCode = 0;
        this.DepartmentCode = 0;
        this.OfficerCode = 0;
        this.OfficeCode = 0;
        this.ProjectCode = 0;
        this.WorkOrderCode = 0;
        this.DistrictCode = 0;
        this.VendorUserCode = 0;
        this.CommunicationTypeCode = 0;
        this.Status = "";
        this.FromDate = null;
        this.ToDate = null;
        this.FilterTypeId = 0;
    }

    LoginUserCode: number;
    VendorCode: number | string;
    DepartmentCode: number | string;
    OfficerCode: number | string;
    OfficeCode: number | string;
    ProjectCode: number | string;
    WorkOrderCode: number | string;
    DistrictCode: number | string;
    VendorUserCode: number | string;
    CommunicationTypeCode: number | string;
    Status: string;
    FromDate: Date | string;
    ToDate: Date | string;
    FilterTypeId: number | string;
}
