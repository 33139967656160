
import { GlobalFilterModel, IndexModel, PermissionModel } from 'src/app/shared/model/common.model';
import { ColumnHeaderModel } from 'src/app/shared/model/commonddl.model';
import { MatTableDataSource, MatPaginator, MatSort, MatDialog } from '@angular/material';
import { Component, OnInit, ViewChild } from '@angular/core';
import { AppComponent } from 'src/app/app.component';
import { AlertService } from 'src/app/shared/service/alert.service';
import { AppSetting } from 'src/app/shared/model/appsetting.model';
import { NotificationTemplateTypeService } from 'src/app/shared/service/master/notification-template-type.service';
import { AddEditDialogComponent } from '../add-edit-dialog/add-edit-dialog.component';
import { CommonService } from 'src/app/shared/service/common.service';
import { NotificationTemplateTypeViewModel } from 'src/app/shared/model/master/notification-template-type.model';
import { AuthenticationService } from 'src/app/shared/service/authentication.service';

@Component({
  selector: 'app-notification-template-type',
  templateUrl: './notification-template-type.component.html',
  styleUrls: ['./notification-template-type.component.scss']
})
export class NotificationTemplateTypeComponent implements OnInit {

  //#region << Variable >>
  listModel: NotificationTemplateTypeViewModel[];
  dataSource: MatTableDataSource<NotificationTemplateTypeViewModel>;
  displayedColumns: string[] = [
    'index',
    'Name',
    'NameHindi',
    'TransactionENUM',
    'IsMail',
    'IsSMS',
    'Action'
  ];
  // tslint:disable-next-line: max-line-length
  ViewdisplayedColumns: ColumnHeaderModel[] = [
    { Value: 'Name', Text: 'Name' },
    { Value: 'NameHindi', Text: 'Name In Hindi' },
    { Value: 'TransactionENUM', Text: 'Enum Value' },
  //  { Value: 'IsSMS', Text: 'Applicable For SMS' },
  //  { Value: 'IsMail', Text: 'Applicable For Mail' }

  ];

  columnsToDisplay: string[] = this.displayedColumns.slice();
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  indexModel = this._commonService.modelSetGet(new IndexModel()) as IndexModel;
  totalRecords: number;
  Permission: PermissionModel = this._authService.GetPagePermission("/master/notification-template-type", "notification-template-type/create", "notification-template-type/detail", "notification-template-type/update", "notification-template-type/delete");

  //#endregion

  //#region << constructor >>

  constructor(
    private _parentApi: AppComponent,
    private readonly _notificationTemplateTypeService: NotificationTemplateTypeService,
    private readonly _alertService: AlertService,
    private readonly _dialog: MatDialog,
    public readonly _commonService: CommonService,
    private readonly _authService: AuthenticationService,
  ) {
    this._parentApi.setPageLayout("", "", "", "", true);

  }

  //#endregion

  //#region << Method >>
  ngOnInit() {
    this.GetList();
  }

  SortData(event) {
    this.indexModel.OrderBy = event.active;
    this.indexModel.OrderByAsc =
      event.direction === AppSetting.orderByDscAsc
        ? AppSetting.orderByAsc
        : AppSetting.orderByDsc;
    this.indexModel.IsPostBack = true;
    this.GetList();
  }

  onPaginateChange(event) {
    this.indexModel.Page = event.pageIndex + 1;
    this.indexModel.PageSize = event.pageSize;
    this.indexModel.IsPostBack = true;
    this.GetList();
  }

  GetList() {
    this._notificationTemplateTypeService.GetList(this._commonService.modelSetGet(this.indexModel, true)).subscribe(
      data => {
        if (data.IsSuccess) {
          
          this.listModel = <NotificationTemplateTypeViewModel[]>data.Data.Data;
          this.dataSource = new MatTableDataSource<NotificationTemplateTypeViewModel>(this.listModel);
          this.dataSource.paginator = this.paginator;
          this.totalRecords = data.Data.TotalRecords;
          this.dataSource.sort = this.sort;
        }
      },
      error => {
        this._alertService.error(error.message);
      }
    );
  }

  OpenDialog(Id) {
    const _dialogRef = this._dialog.open(AddEditDialogComponent, {
      width: "50%",
      data: Id
    });
    _dialogRef.afterClosed().subscribe((result: boolean) => {

      if (result) {
        this.GetList();
      }
    });
  }

  SearchByKeyword(searchValue: GlobalFilterModel) {
    this.indexModel.Search = searchValue.search;
    this.indexModel.AdvanceSearchModel = { ActiveStatus: searchValue.ActiveStatus }

    this.GetList();
  }

  ClearSearch() {
    this.indexModel = new IndexModel();
    this.GetList();
  }

  //#endregion

}
