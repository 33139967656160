import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialog, MatTableDataSource, MatSort } from '@angular/material';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { AppComponent } from 'src/app/app.component';
import { ConfirmationDialogComponent } from 'src/app/confirmation-dialog/confirmation-dialog.component';
import { CustomFilterTypeEnum, GeneralEntrySubCategoryEnum, StatusEnum, UserTypeEnum } from 'src/app/shared/enum/fixed-values.enum';
import { AppSetting } from 'src/app/shared/model/appsetting.model';
import { GlobalMessagesModel } from 'src/app/shared/model/common-messages.model';
import { IndexModel, PermissionModel } from 'src/app/shared/model/common.model';
import { DdlItemModel, DDLModel } from 'src/app/shared/model/commonddl.model';
import { AddVendorCommunicationPermissionRequestModel } from 'src/app/shared/model/master/add-vendor-communication-permission-request.model';
import { VendorCommunicationPermissionApproveRequestModel } from 'src/app/shared/model/master/vendor-communication-permission-approve-request.model';
import { VendorCommunicationPermissionFilterModel } from 'src/app/shared/model/master/vendor-communication-permission-filter.model';
import { VendorCommunicationPermissionListViewModel } from 'src/app/shared/model/master/vendor-communication-permission-list-view.model';
import { VendorPermissionDelegationViewDetailsModel } from 'src/app/shared/model/master/vendor-permission-delegation-view-details.model';
import { VendorPermissionDelegationListViewModel } from 'src/app/shared/model/master/vendor-permission-list-view.model';
import { AlertService } from 'src/app/shared/service/alert.service';
import { AuthenticationService } from 'src/app/shared/service/authentication.service';
import { CommonService } from 'src/app/shared/service/common.service';
import { VendorCommunicationPermissionService } from 'src/app/shared/service/master/vendor-communication-permission.service';
import { UserService } from 'src/app/shared/service/user.service';
import { VendorPermissionDelegationService } from 'src/app/shared/service/vendor-permission-delegation.service';

@Component({
  selector: 'app-vendor-communication-permission',
  templateUrl: './vendor-communication-permission.component.html',
  styleUrls: ['./vendor-communication-permission.component.scss'],
  providers: [VendorCommunicationPermissionService]
})
export class VendorCommunicationPermissionComponent implements OnInit {

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  indexModel = this._commonService.modelSetGet(new IndexModel()) as IndexModel;
  private _requestModel: AddVendorCommunicationPermissionRequestModel;
  private vendorCommunicationPermission: VendorCommunicationPermissionFilterModel;
  public dropdownList: DDLModel;
  public form: FormGroup;
  public _listModel: VendorCommunicationPermissionListViewModel[] = [];
  public columnsToDisplay: string[];
  public permission: PermissionModel;
  public noRecordText: string;
  public submitButtonText: string;
  public isFormVisible: boolean = false;
  public isViewDetailsButtonVisible: boolean = false;
  public dataSource: any;
  public customType = CustomFilterTypeEnum
  public vendorLabel: string = 'Vendor';
  public departmentLabel: string = 'Department';
  public isUserVendor = false;
  public status: string;
  public dashboard: any;
  public ddlList = new DDLModel();
  public isShow = true;
  public hasPermission: boolean = false;
  public userCode: any;
  public userId: any;


  constructor(
    private readonly _appComponent: AppComponent,
    private readonly _route: ActivatedRoute,
    private readonly _authService: AuthenticationService,
    private readonly _vendorCommunicationPersmissionService: VendorCommunicationPermissionService,
    private readonly _dialog: MatDialog,
    private _commonService: CommonService,
    private readonly _alertService: AlertService,
    private readonly _userService: UserService
  ) {
    this._appComponent.setPageLayout("Vendor Communication Permission:");
    this._vendorCommunicationPersmissionService.getDdl().subscribe(list => this.dropdownList = list);
    this.form = this._vendorCommunicationPersmissionService.Form;
    this._requestModel = new AddVendorCommunicationPermissionRequestModel();
    this.vendorCommunicationPermission = new VendorCommunicationPermissionFilterModel();
    this.permission = this._authService.GetPagePermission("/master/vendor-permission", "/master/vendor-permission/add", null, null, "/master/vendor-permission/delete");
    this.setNoRecordText();
    this.setSubmitButton();
    this.setIsFormVisible();
    this.setIsViewDeatilButtonVisible();
    this.status = this._route.snapshot.params.status;


    let data = JSON.parse(this._commonService.convertText(localStorage.getItem('UserDetails'), false))
    this.userId = data.UserViewModel.UserCode;

    this.dashboard = this._route.snapshot.routeConfig.path;

    if ((this.dashboard).includes('dashboard')) {
      this._appComponent.setPageLayout("Vendor Communication Permission:", "keyboard_backspace", "Back To Dashboard", "Dashboard");

    }
  }

  ngOnInit() {
    if (this.permission.ListPageAccess) {


      this.setFilterModel();
      this.getData();
      this.GetDDLList();
      this.GetVendorList();
      this.isDelegateUserExists(this.userId);
    }

    let userDetail = this._authService.LoggedInUserDetail.UserViewModel;
    this.isUserVendor = userDetail.UserTypeCode == UserTypeEnum.VNDRCode || userDetail.UserTypeCode == UserTypeEnum.VNDRUSERCode

  }

  get FilteredDdlVendor(): Observable<DdlItemModel[]> {
    return this._vendorCommunicationPersmissionService.FilteredDdlVendor;
  }

  public autocompleteDisplayFunction(selectedValue: DdlItemModel) {
    if (selectedValue) {
      return selectedValue.Text;
    }
  }

  public Reset() {
    this.vendorCommunicationPermission = new VendorCommunicationPermissionFilterModel();
    this.getData();
  }


  GetDDLList() {
    this._commonService.GetAllDDL(AppSetting.VendorCommunicationPermissionDdl).subscribe(
      response => {
        if (response.IsSuccess) {
          let data = <DDLModel>response.Data;

          this.ddlList.ddlDepartment = data.ddlDepartment;

        }
      },
      error => {
        this._alertService.error(error.message);
      }
    );
  }

  GetVendorList() {
    this._commonService.GetAllDDL(AppSetting.VendorCommunicationPermissionDdl).subscribe(
      response => {
        if (response.IsSuccess) {
          let data = <DDLModel>response.Data;

          this.ddlList.ddlVendor = data.ddlVendor;

        }
      },
      error => {
        this._alertService.error(error.message);
      }
    );
  }


  public onSubmit() {
    if (this.form.invalid) {
      return;
    }

    this.mapToRequestModel();

    this._vendorCommunicationPersmissionService.add(this._requestModel).subscribe(response => {
      if (response) {
        this.getData();
      }
    });
  }

  public markStatusApproved(id) {
    const dialogRef = this._dialog.open(ConfirmationDialogComponent, {
      width: "50%",
      data: GlobalMessagesModel.ConfirmStatusChanged,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this._vendorCommunicationPersmissionService.approve(new VendorCommunicationPermissionApproveRequestModel(id)).subscribe(result => {
          if (result) {
            this.getData();
          }
        });
      }
    });
  }
  public sortData(event) {
    this.indexModel.OrderBy = event.direction ? event.active : '';
    this.indexModel.OrderByAsc = event.direction == AppSetting.orderByDscAsc ? AppSetting.orderByAsc : AppSetting.orderByDsc;
    this.indexModel.IsPostBack = true;
    this.getData();
  }
  public toggleDisplay() {
    this.isShow = !this.isShow;
  }
  private mapToRequestModel() {
    let model = this.form.getRawValue();

    this._requestModel = new AddVendorCommunicationPermissionRequestModel(model.vendorCode, model.departmentCode);
  }

  private getData() {
    this._vendorCommunicationPersmissionService.getAll(this.vendorCommunicationPermission).subscribe(list => {
      this._listModel = list;
    });
  }

  private setFilterModel() {
    if (this.status == 'accept') {
      this.vendorCommunicationPermission.isApproved = true;
      this.vendorCommunicationPermission.isLatest = false;
    }
    else if (this.status == 'pending') {
      this.vendorCommunicationPermission.isApproved = false;
      this.vendorCommunicationPermission.isLatest = false;
    }
    else if (this.status == 'latest') {
      this.vendorCommunicationPermission.isApproved = false;
      this.vendorCommunicationPermission.isLatest = true;
    }
  }

  private initializeColumnsToDisplay() {
    let userDetail = this._authService.LoggedInUserDetail.UserViewModel;
    if (userDetail.UserTypeCode == UserTypeEnum.HODCode || userDetail.UserTypeCode == UserTypeEnum.DOSADLCCode || userDetail.UserTypeCode == UserTypeEnum.DRECADLCCode || userDetail.UserTypeCode == UserTypeEnum.HOOADLCCode || this.hasPermission || userDetail.UserTypeCode == UserTypeEnum.DRECCode) {
      this.columnsToDisplay = [
        "index",
        "department",
        "vendor",
        "request",
        "approvedBy",
        "approved",
        //"dateAdded",
        "action"
      ];
    }

    else {
      this.columnsToDisplay = [
        "index",
        "department",
        "vendor",
        "request",
        "approvedBy",
        "approved",
        // "dateAdded",

      ];
    }
  }

  private setNoRecordText() {
    let userDetail = this._authService.LoggedInUserDetail.UserViewModel;


    if (userDetail.UserTypeCode == UserTypeEnum.VNDRCode || userDetail.UserTypeCode == UserTypeEnum.VNDRUSERCode) {
      this.noRecordText = "No Department for communicaton. Please send a request to add one";
    }
    else if (userDetail.UserTypeCode == UserTypeEnum.HODCode) {
      this.noRecordText = "No vendor added for communcation.";
    }
  }

  private setSubmitButton() {
    let userDetail = this._authService.LoggedInUserDetail.UserViewModel;

    if (userDetail.UserTypeCode == UserTypeEnum.HODCode) {
      this.submitButtonText = "Add";
    }
    else if (userDetail.UserTypeCode == UserTypeEnum.VNDRCode || userDetail.UserTypeCode == UserTypeEnum.VNDRUSERCode) {
      this.submitButtonText = "Send Request";
    }
  }

  private setIsFormVisible() {
    let userDetail = this._authService.LoggedInUserDetail.UserViewModel;
    if (userDetail.UserType == UserTypeEnum.VNDR || userDetail.UserTypeCode == UserTypeEnum.HODCode || userDetail.UserTypeCategoryCode == UserTypeEnum.STTLVLCode) {
      this.isFormVisible = true
    }
  }
  private setIsViewDeatilButtonVisible() {
    let userDetail = this._authService.LoggedInUserDetail.UserViewModel;
    if (userDetail.UserType == UserTypeEnum.VNDR) {
      this.isViewDetailsButtonVisible = true;
    }
  }

  private isDelegateUserExists(id: number) {

    let data = JSON.parse(this._commonService.convertText(localStorage.getItem('UserDetails'), false))
    this.userCode = data.UserViewModel.UserCode;
    this._vendorCommunicationPersmissionService.IsDelegateUserExists(id).subscribe(response => {

      this.hasPermission = response
      this.initializeColumnsToDisplay();
    })
  }
}
