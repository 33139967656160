import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource, MatPaginator, MatSort, MatDialog } from '@angular/material';
import { AppComponent } from 'src/app/app.component';
import { ConfirmationDialogComponent } from 'src/app/confirmation-dialog/confirmation-dialog.component';
import { AppSetting } from 'src/app/shared/model/appsetting.model';
import { GlobalMessagesModel } from 'src/app/shared/model/common-messages.model';
import { GlobalFilterModel, IndexModel, PermissionModel } from 'src/app/shared/model/common.model';
import { ColumnHeaderModel } from 'src/app/shared/model/commonddl.model';
import { NotificationSMSTemplateViewModel } from 'src/app/shared/model/master/sms-template.model';
import { AlertService } from 'src/app/shared/service/alert.service';
import { AuthenticationService } from 'src/app/shared/service/authentication.service';
import { CommonService } from 'src/app/shared/service/common.service';
import { SMSTemplateService } from 'src/app/shared/service/master/sms-template.service';

@Component({
  selector: 'app-notification-sms-templates',
  templateUrl: './notification-sms-templates.component.html',
  styleUrls: ['./notification-sms-templates.component.scss']
})
export class NotificationSmsTemplatesComponent implements OnInit {

  //#region << Variable >>
  listModel: NotificationSMSTemplateViewModel[];
  dataSource: MatTableDataSource<NotificationSMSTemplateViewModel>;
  displayedColumns: string[] = [
    'index',
    'TemplateName',
    'SMSContent',
    'IsActive',
    'Action'
  ];
  // tslint:disable-next-line: max-line-length
  ViewdisplayedColumns: ColumnHeaderModel[] = [
    { Value: 'TemplateName', Text: 'Template Name' },
    { Value: 'SMSContent', Text: 'SMS Content' }
  ];
  Permission: PermissionModel = this._authService.GetPagePermission("/master/notification-sms-templates", "notification-sms-templates/add", "notification-sms-templates/detail", "notification-sms-templates/update", "notification-sms-templates/delete");

  columnsToDisplay: string[] = this.displayedColumns.slice();
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  indexModel = this._commonService.modelSetGet(new IndexModel()) as IndexModel;
  totalRecords: number;
  //#endregion

  //#region << constructor >>

  constructor(
    private _parentApi: AppComponent,
    private readonly _smsService: SMSTemplateService,
    private readonly _alertService: AlertService,
    private readonly _dialog: MatDialog,
    public readonly _commonService: CommonService,
    private readonly _authService: AuthenticationService

  ) {
    this.Permission.AddPageAccess ? this._parentApi.setPageLayout(
      'Notification SMS Templates :',
      'add',
      'Create',
      'master/notification-sms-templates/add'
    ) : this._parentApi.setPageLayout(
      'Notification SMS Templates :');
   
  }

  //#endregion



  //#region << Method >>
  ngOnInit() {
    this.GetList();
  }

  SortData(event) {
    this.indexModel.OrderBy = event.active;
    this.indexModel.OrderByAsc =
      event.direction === AppSetting.orderByDscAsc
        ? AppSetting.orderByAsc
        : AppSetting.orderByDsc;
    this.indexModel.IsPostBack = true;
    this.GetList();
  }

  onPaginateChange(event) {
    this.indexModel.Page = event.pageIndex + 1;
    this.indexModel.PageSize = event.pageSize;
    this.indexModel.IsPostBack = true;
    this.GetList();
  }

  GetList() {
    this._smsService.GetList(this._commonService.modelSetGet(this.indexModel, true)).subscribe(
      data => {
        if (data.IsSuccess) {
          this.listModel = <NotificationSMSTemplateViewModel[]>data.Data.Data;
          this.dataSource = new MatTableDataSource<
            NotificationSMSTemplateViewModel
          >(this.listModel);
          if (this.indexModel.IsPostBack === false) {
            this.dataSource.paginator = this.paginator;
            this.totalRecords = data.Data.TotalRecords;
            this.dataSource.sort = this.sort;
          }
        }
      },
      error => {
        this._alertService.error(error.message);
      }
    );
  }

  onDelete(id) {

    const dialogRef = this._dialog.open(ConfirmationDialogComponent, {
      width: '50%',
      data: GlobalMessagesModel.ConfirmDeleted
    });
    dialogRef.afterClosed().subscribe(result => {

      if (result) {
        this._smsService.Delete(id).subscribe(
          data => {

            if (
              (data.IsSuccess)
            ) {
              this.GetList();
              this._alertService.success(data.Message);
            }
          },
          error => {
            this._alertService.error(error.message);
          }
        );
      }
    });

  }
  SearchByKeyword(searchValue: GlobalFilterModel) {
    this.indexModel.Search = searchValue.search;
    this.indexModel.AdvanceSearchModel = { ActiveStatus: searchValue.ActiveStatus }

    this.GetList();
  }

  ClearSearch() {
    this.indexModel = new IndexModel();
    this.GetList();
  }


  updateActiveStatus(id) {
    const dialogRef = this._dialog.open(ConfirmationDialogComponent, {
      width: "50%",
      data: GlobalMessagesModel.ConfirmStatusChanged,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this._smsService.ChangeActiveStatus(id).subscribe(
          (data) => {
            if (data.IsSuccess) {
              this._alertService.success(GlobalMessagesModel.updateSuccess);
              this.GetList();
            }
          },
          (error) => {
            this._alertService.error(error.message);
          }
        );
      }
    });
  }


  //#endregion

}
