export enum PreferredListEnum {
    Office = 1,
    Vendor = 2,
    AllOffice = 3,
    AllOfficer = 4,
    AllVendor = 5,
    Officer = 6
}

export enum ListType {
    Office = 1,
    Vendor = 2
}