import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import { AuthenticationService } from 'src/app/shared/service/authentication.service';
import { GlobalMessagesModel } from 'src/app/shared/model/common-messages.model';
import { AppComponent } from 'src/app/app.component';
import { AlertService } from 'src/app/shared/service/alert.service';
import { VendorTypeModel } from 'src/app/shared/model/master/vendor-type.model';
import { VendorTypeService } from 'src/app/shared/service/master/vendor-type.service';
import { CommonService } from 'src/app/shared/service/common.service';
import { UserViewModel } from 'src/app/shared/model/user.model';
import { isNullOrUndefined } from 'util';
import { UserTypeEnum } from 'src/app/shared/enum/fixed-values.enum';
import { MasterDataModel } from 'src/app/shared/model/master/master-data.model';
import { MasterDataService } from 'src/app/shared/service/master/master-data.service';
import { AppSetting } from 'src/app/shared/model/appsetting.model';
import { DDLModel } from 'src/app/shared/model/commonddl.model';

@Component({
  selector: 'app-add-update-master-data',
  templateUrl: './add-update-master-data.component.html',
  styleUrls: ['./add-update-master-data.component.scss']
})
export class AddUpdateMasterDataComponent implements OnInit {

  //#region <<Variable>>
  loginUserDetail: UserViewModel;
  ddlList: DDLModel;
  fromGroup: FormGroup;
  model: MasterDataModel;
  RecordId: number = 0;
  loginUserCode: number;
  oldFieldsValueEng: string;
  IsStorage: boolean;
  //#endregion

  constructor(
    private readonly fb: FormBuilder,
    private readonly appComponnet: AppComponent,
    public readonly _commonService: CommonService,
    private readonly _alertService: AlertService,
    private readonly _router: Router,
    private _route: ActivatedRoute,
    private readonly _masterDataService: MasterDataService,
    private readonly _authService: AuthenticationService
  ) {
    this.GetDDLList();
    if (!isNullOrUndefined(this._route.snapshot.params.id)) {
      this.RecordId = this._route.snapshot.params.id;
    }
    var pageHeading = (this.RecordId == 0 ? "Add" : "Update") + " Look Up Data :";
    this.appComponnet.setPageLayout(pageHeading, "keyboard_backspace", "Back To List", "master/master-data");
    this.model = new MasterDataModel();
    this.loginUserDetail = this._authService.GetCurrentUserDetail().UserViewModel;
  }

  ngOnInit() {
    this.formGroupInit();
    if (this.RecordId != 0) {
      this.getDetail();
    }
  }

  formGroupInit() {
    this.fromGroup = this.fb.group({
      MasterTypeCode: [null, Validators.required],
      FieldValueInEnglish: [null, Validators.compose([Validators.required, Validators.maxLength(100)])],
      FieldValueInHindi: [null, Validators.compose([Validators.required, Validators.maxLength(100)])],
      SortOrder: [null, Validators.required],
      Description: [null]
    });
  }

  GetDDLList() {
    this._commonService.GetAllDDL(AppSetting.MasterDataDropdownKeys).subscribe(
      data => {
        if (data.IsSuccess) {
          this.ddlList = <DDLModel>data.Data;
        }
      },
      error => {
        this._alertService.error(error.message);
      }
    );
  }

  getDetail() {
    this._masterDataService.Detail(this.RecordId).subscribe(
      data => {
        if (data.IsSuccess) {
          this.model = <MasterDataModel>data.Data;
          this.oldFieldsValueEng = this.model.FieldValueInEnglish;
          this.model.MasterTypeCode =  this.model.MasterTypeCode ?String(this.model.MasterTypeCode):undefined;
        }
      },
      error => {
        this._alertService.error(error.message);
      }
    );
  }

  saveClick() {
    this.fromGroup.markAllAsTouched();
    if (this.fromGroup.valid) {
      if (this.RecordId == 0) {
        this.model.CreatedBy = this.loginUserDetail.UserCode;
      }
      else {
        this.model.ModifiedBy = this.loginUserDetail.UserCode;
      }

      this._masterDataService.AddUpdate(this.RecordId, this.model).subscribe(
        data => {
          if (data) {
            if (data.IsSuccess) {
              this._alertService.success(this.RecordId == 0 ? GlobalMessagesModel.saveSuccess : GlobalMessagesModel.updateSuccess);
              this._router.navigate(["master/master-data"]);
            } else {
              this._commonService.ScrollingTop();
              this._alertService.error(this.RecordId == 0 ? GlobalMessagesModel.saveError : GlobalMessagesModel.updateError);
            }
          } else {
            this._commonService.ScrollingTop();
            this._alertService.error(GlobalMessagesModel.InternalError);
          }
        },
        error => {
          this._commonService.ScrollingTop();
          this._alertService.error(GlobalMessagesModel.InternalError);
        }
      );
    }
  }

  IsMasterDataExist(Event) {
    if (this.oldFieldsValueEng != Event.currentTarget.value) {
      this._masterDataService.IsMasterDataExist1(this.model).subscribe(result => {
        if (result.IsSuccess) {
          this.model.FieldValueInEnglish = null;
          this._alertService.error("Field Value is already exist for selected Master Type!");
        }
      }, error => {
        this._commonService.ScrollingTop();
        this._alertService.error(error.message);
      });
    }
  }

  ngOnDestroy() {
    this.IsStorage = true;
    // sessionStorage.setItem("IsMasterDataStorage", JSON.stringify(this.IsStorage));
  }

}
