import { ProjectMappingViewModel } from 'src/app/shared/model/projectMapping.model';
import { Injectable } from '@angular/core';
import { AppSetting } from '../model/appsetting.model';
import { IndexModel } from '../model/common.model';
import { BaseService } from './base.service';
import { AuthenticationService } from './authentication.service';
import { UserCreationViewModel } from '../model/user.model';

@Injectable({
    providedIn: 'root'
})
export class ProjectMappingService {
    loginUserCode: number;

    constructor(private readonly _baseService: BaseService, private readonly _authenticationService: AuthenticationService) {
        this.loginUserCode = _authenticationService.UserCode;
    }

    GetList(model: IndexModel) {
        return this._baseService.post(AppSetting.ProjectMappingListUrl, model);
    }

    AssignProject(model: ProjectMappingViewModel) {
        return this._baseService.post(AppSetting.ProjectAssignUrl, model);
    }

    Detail(id: number) {
        return this._baseService.get(AppSetting.ProjectMappingDetailUrl + id);
    }

}
