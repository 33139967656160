import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators, AbstractControl } from '@angular/forms';
import { MatDialogRef, MatSnackBar, MAT_DIALOG_DATA } from '@angular/material';
import { ActivatedRoute } from '@angular/router';
import { UserTypeEnum, AlertTypeEnum } from 'src/app/shared/enum/fixed-values.enum';
import { AppSetting } from 'src/app/shared/model/appsetting.model';
import { GlobalMessagesModel } from 'src/app/shared/model/common-messages.model';
import { AlertModel } from 'src/app/shared/model/common.model';
import { DDLModel, DdlItemModel } from 'src/app/shared/model/commonddl.model';
import { UserViewModel, SSOUserModel, userCreationConfigModel } from 'src/app/shared/model/user.model';
import { AlertService } from 'src/app/shared/service/alert.service';
import { AuthenticationService } from 'src/app/shared/service/authentication.service';
import { CommonService } from 'src/app/shared/service/common.service';
import { UserService } from 'src/app/shared/service/user.service';
import { isNullOrUndefined } from 'util';
import { AddUpdateVendorUserDialogComponent } from '../add-update-vendor-user-dialog/add-update-vendor-user-dialog.component';

@Component({
  selector: 'app-update-vendor-user-profile',
  templateUrl: './update-vendor-user-profile.component.html',
  styleUrls: ['./update-vendor-user-profile.component.scss']
})
export class UpdateVendorUserProfileComponent implements OnInit {

  loginUserDetail: UserViewModel;
  frmGrp: FormGroup;
  ddlList: DDLModel;

  model = new UserViewModel();
  ssoUserDetail: SSOUserModel;
  recordId: number = 0;
  heading: string;
  oldSSOId: string;
  alertModel: AlertModel;
  loginUserCode: number;
  loginBaseUserType: string;
  submitBtn: boolean = true;
  fileValidationMsg: string;
  constructor(private readonly fb: FormBuilder,
    public readonly _commonService: CommonService,
    private readonly _alertService: AlertService,
    private readonly _userService: UserService,
    private readonly _authService: AuthenticationService,
    public _dialogRef: MatDialogRef<AddUpdateVendorUserDialogComponent>,
    private readonly _route: ActivatedRoute,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.loginUserDetail = this._authService.LoggedInUserDetail.UserViewModel;
    this.loginBaseUserType = this._authService.BaseUserType;
    this.GetDDLList();


    if (this.loginUserDetail.UserCode > 0) {

      this.recordId = this.loginUserDetail.UserCode;
      this.getDetail();

    } else {
      this._dialogRef.close(false);
    }

    this.heading = (this.recordId == 0 ? "Add" : "Update") + " Profile";


  }

  ngOnInit() {

    this.formGroupInit();
  }

  formGroupInit() {
    this.frmGrp = this.fb.group({
      Title: [null, Validators.compose([Validators.required])],
      Name: [null, Validators.required],
      Email: [null, Validators.compose([Validators.required, Validators.email])],
      Gender: [null, Validators.required],
      Mobile: [null, Validators.compose([Validators.required, Validators.maxLength(10)])],

    });
  }

  GetDDLList() {
    this._commonService.GetAllDDL(AppSetting.VendorUserProfileDropdownKeys, Number(this.loginUserDetail.UserTypeCode), this.loginUserDetail.UserCode).subscribe(
      data => {
        if (data.IsSuccess) {
          this.ddlList = <DDLModel>data.Data;

        }
      },
      error => {
        this._alertService.error(error.message);
      }
    );
  }


  getDetail() {

    this._userService.Detail(this.recordId).subscribe(
      data => {
        if (data.IsSuccess) {

          this.model = <UserViewModel>data.Data;
          this.oldSSOId = this.model.SSOID;
        }
      },
      error => {
        this.model = new UserViewModel();
        this._alertService.error(error.message);
      }
    );
  }

  isRequiredField(field: string) {
    const form_field = this.frmGrp.get(field);
    if (!form_field.validator) {
      return false;
    }

    const validator = form_field.validator({} as AbstractControl);
    return (validator && validator.required);
  }


  getSSODetail(Event) {
    if (Event.currentTarget.value != this.oldSSOId) {
      this._userService.IsSSOIdExist(Event.currentTarget.value).subscribe(result => {
        if (result.IsSuccess) {
          let ssoId = result.Data != null ? <boolean>result.Data : null;
          if (ssoId != null && ssoId == true) {
            this.model.SSOID = undefined;
            this.model.Email = undefined;
            this.model.Mobile = undefined;
            this.model.Gender = undefined;
            this.model.ProfilePic = "";
            this._commonService.ScrollingTop();
            this._alertService.error(result.Message);
          } else {
            this.getUserDetailFromSSO(this.model.SSOID);
          }
        }
      }, error => {
        this._commonService.ScrollingTop();
        this._alertService.error(GlobalMessagesModel.InternalError);
      });
    }
  }

  getUserDetailFromSSO(ssoId: string) {
    this._userService.GetUserDetailFromSSO(ssoId).subscribe(result => {
      this._alertService.blank();
      if (result.IsSuccess) {
        this.ssoUserDetail = <SSOUserModel>result.Data
        this.model.Name = this.ssoUserDetail.FirstName + " " + this.ssoUserDetail.LastName;
        this.model.Email = this.ssoUserDetail.MailPersonal;
        this.model.Mobile = this.ssoUserDetail.Mobile;
        this.model.Gender = this.ssoUserDetail.Gender;
        this.model.ProfilePic = this.ssoUserDetail.Photo != null && this.ssoUserDetail.Photo.length > 0 ? this.ssoUserDetail.Photo : "";
      } else {
        this.model.Name = undefined;
        this.model.Email = undefined;
        this.model.Mobile = undefined;
        this.model.Gender = undefined;
        this.model.ProfilePic = "";
        this._commonService.ScrollingTop();
        this._alertService.error(ssoId + " SSO ID Not Found!");
      }
    }, error => {
      this._commonService.ScrollingTop();
      this._alertService.error(GlobalMessagesModel.InternalError);
    });
  }

  handleFileInput(event: any) {
    this.fileValidationMsg = "";
    if (event.target.files.item(0).type.match('image/*')) {
      if (event.target.files.item(0).size > AppSetting.FileSizeLimit.DEFAULT) {
        this.fileValidationMsg = AppSetting.FileSizeLimit.IMGErrorMSG;
       // this._alertService.error(AppSetting.FileSizeLimit.DEFAULTErrorMSG);
        this._commonService.ScrollingTop();
      } else {
        let reader = new FileReader();
        reader.onload = (event: any) => {
          this.fileValidationMsg = "";
          this.model.ProfilePic = event.target.result;
        }
        reader.readAsDataURL(event.target.files.item(0));
      }
    } else {
      // this._alertService.error('Only *images accepted!');
      this.fileValidationMsg = "Only JPG, JPEG & PNG image file accepted!";
    }
  }


  saveClick() {
    
    this.submitBtn = false;
    this.frmGrp.markAllAsTouched();
    if (this.frmGrp.valid) {
      if (this.recordId > 0) {
        this.model.ModifiedBy = this.loginUserDetail.UserCode;
        this._userService.AddUpdate(this.recordId, this.model).subscribe(
          data => {
            if (data.IsSuccess) {
              this._alertService.success(this.recordId == 0 ? GlobalMessagesModel.saveSuccess : GlobalMessagesModel.updateSuccess);
              this._dialogRef.close(true);
            } else {
              this.submitBtn = true;

              this._alertService.error(this.recordId == 0 ? GlobalMessagesModel.saveError : GlobalMessagesModel.updateError);
            }
          },
          error => {
            this.submitBtn = true;
            this._alertService.error(error.message);
          });
      }


    }
    this.submitBtn = true;

  }

  closeDialog(): void {
    this._dialogRef.close();
  }



}
