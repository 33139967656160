export class IndexModel {
    Page: number;
    PageSize: number;
    Search: string;
    OrderBy: string;
    OrderByAsc: number;
    IsPostBack: boolean;
    AdvanceSearchModel: any;
    constructor() {
        this.Page = 1;
        this.PageSize = 10;
        this.Search = "";
        this.OrderBy = null;
        this.OrderByAsc = 0;
        this.IsPostBack = false;
        this.AdvanceSearchModel = { ActiveStatus: "1" };
    }
}

export class SearchModel {
    Key: string;
    Value: string;
    constructor() {
        this.Key = '';
        this.Value = '';
    }
}

export class GlobalFilterModel {
    constructor() {
        this.search = null;
        this.ActiveStatus = "1";
        
    }
    search: string;
    ActiveStatus: string = "1";
   
}

export class AlertModel {
    Message: string;
    Type: string;
    constructor() {
        this.Message = '';
        this.Type = '';
    }
}

export class PermissionModel {
    ListPageAccess: boolean;
    AddPageAccess: boolean;
    DetailPageAccess: boolean;
    UpdatePageAccess: boolean;
    DeletePageAccess: boolean;
    Custom1PageAccess: boolean;
    Custom2PageAccess: boolean;
    Custom3PageAccess: boolean;

    constructor() {
        this.ListPageAccess = true;
        this.AddPageAccess = true;
        this.DetailPageAccess = true;
        this.UpdatePageAccess = true;
        this.DeletePageAccess = true;
        this.Custom1PageAccess = true;
        this.Custom2PageAccess = true;
        this.Custom3PageAccess = true;
    }

}
export class CommonDocModel {
  Url: string;
  BlankDocUrl: string;
}
