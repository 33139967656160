import { DDLModel } from 'src/app/shared/model/commonddl.model';
import { UserDepartmentViewModel } from './../../../../shared/model/user.model';
import { DepartmentContactDetailsModel, DepartmentWebsiteDetailsModel } from './../../../../shared/model/master/department-contact-details.model';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserViewModel } from 'src/app/shared/model/user.model';
import { Router, ActivatedRoute } from '@angular/router';
import { AppComponent } from 'src/app/app.component';
import { AlertService } from 'src/app/shared/service/alert.service';
import { AuthenticationService } from 'src/app/shared/service/authentication.service';
import { CommonService } from 'src/app/shared/service/common.service';
import { DepartmentContactDetailsService } from 'src/app/shared/service/master/department-contact-details.service';
import { UserService } from 'src/app/shared/service/user.service';
import { AppSetting } from 'src/app/shared/model/appsetting.model';

@Component({
  selector: 'app-add-update-department-contact-details',
  templateUrl: './add-update-department-contact-details.component.html',
  styleUrls: ['./add-update-department-contact-details.component.scss']
})
export class AddUpdateDepartmentContactDetailsComponent implements OnInit {
  //#region <Variable>

  formGroup: FormGroup;
  model: DepartmentContactDetailsModel;
  title: string;
  loginData: UserViewModel;
  ddlDepartment: UserDepartmentViewModel[];
  dDLList: DDLModel;

  //#endregion <Variable>

  //#region <constructor>
  constructor(private _parentApi: AppComponent,
    private readonly _departmentContactService: DepartmentContactDetailsService,
    private readonly _alertService: AlertService,
    private readonly _router: Router,
    private _route: ActivatedRoute,
    private readonly formBuilder: FormBuilder,
    private readonly _userService: UserService,
    private readonly _authService: AuthenticationService,
    public readonly _commonService: CommonService) {
    this.model = new DepartmentContactDetailsModel();
    if (this._route.snapshot.params.id) {
      this.model.Id = this._route.snapshot.params.id;
      this.GetById();
      this._parentApi.setPageLayout("Update Department Contact Details :", "keyboard_backspace", "Back To List", "master/department-contact-details");
      this.title = "Update";
    } else {
      this._parentApi.setPageLayout("Add Department Contact Details :", "keyboard_backspace", "Back To List", "master/department-contact-details");
      this.title = "Add";
    }
  }

  //#endregion <constructor>

  //#region <Methods>

  ngOnInit() {
    this.FormGroupInit();
    this.GetDDLList();
    this.loginData = this._authService.GetCurrentUserDetail().UserViewModel;
    this.getDepartment();
  }

  FormGroupInit() {
    this.formGroup = this.formBuilder.group({
      DepartmentCode: [null, Validators.required],
      DesignationCode: [null, Validators.required],
      OfficerName: [null, Validators.required],
      MobileNo: [null, Validators.required],
      Email: [null],
      SSOID: [null],
      IsVisibleOnFront: [null],
      WebsiteName: [null]
    });
  }

  GetById() {
    this._departmentContactService.GetById(this.model.Id)
      .subscribe(
        data => {
          if (data.IsSuccess) {
            this.model = <DepartmentContactDetailsModel>data.Data;
            this.getWebsite();
            if (this.model.DepartmentCode) {
              this.model.DepartmentCode = String(this.model.DepartmentCode);
            } if (this.model.DesignationCode) {
              this.model.DesignationCode = String(this.model.DesignationCode);
            }
          } else {
            this._alertService.error(data.Message);
          }
        },
        error => {
          this._alertService.error(error.message);
        }
      );
  }

  SaveClick() {
    
    this.formGroup.markAllAsTouched();
    if (this.formGroup.valid) {
      this._departmentContactService.AddUpdate(this.model)
        .subscribe(
          data => {
            if (data.IsSuccess) {
              
              this._alertService.success(data.Message);
              this._router.navigate(["master/department-contact-details"]);
            } else {
              this._alertService.error(data.Message);
            }
          },
          error => {
            this._alertService.error(error.message);
          }
        );
    } else {

    }

  }

  getDepartment() {    
    this._userService.GetUserDepartment(this.loginData.UserCode).subscribe(
      data => {
        if (data.IsSuccess) {
          this.ddlDepartment = <UserDepartmentViewModel[]>data.Data;
        }
      },
      error => {
        this._alertService.error(error.message);
      }
    );
  }

  GetDDLList() {
    this._commonService.GetAllDDL(AppSetting.DepartmentContactDetailsDDLKey).subscribe(
      data => {
        if (data.IsSuccess) {
          this.dDLList = <DDLModel>data.Data;
        }
      },
      error => {
        this._alertService.error(error.message);
      }
    );
  }

  getWebsite() {
    if (this.model.DepartmentCode) {
      this._departmentContactService.GetDepartmentByCode(Number(this.model.DepartmentCode)).subscribe(
        data => {
          if (data.IsSuccess) {
            const temp = <DepartmentWebsiteDetailsModel>data.Data;
            this.model.WebsiteName = temp.WebsiteName;
          }
        },
        error => {
          this._alertService.error(error.message);
        }
      );
    } else {
      this.model.WebsiteName = null;
    }
  }

  //#endregion
}
