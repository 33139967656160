import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { IndexModel } from '../model/common.model';
import { AppSetting } from '../model/appsetting.model';

@Injectable({
    providedIn: 'root'
})

export class UserNotificationService {

    constructor(private readonly _baseService: BaseService) { }

    GetList(loginUserCode: number) {
        return this._baseService.get(AppSetting.UserNotificationListUrl + "?loginUserCode=" + loginUserCode);
    }

    ChangeDeleteStatus(id: number) {
        return this._baseService.get(AppSetting.UserNotificationDeleteStatusChangeUrl + id);
    }

    ChangeDeleteAllStatus(id: number) {
        return this._baseService.get(AppSetting.UserNotificationDeleteAllStatusChangeUrl + id);
    }

}
