import { AdditionalUserPermissionModel, AdditionalUserPermissionViewModel } from 'src/app/shared/model/user-permission.model';
import { Injectable } from '@angular/core';
import { AppSetting } from '../model/appsetting.model';
import { Dictionary } from '../model/dictionary.model';
import { DefaultPermissionViewModel, UserPermissionViewModel } from '../model/user-permission.model';
import { BaseService } from './base.service';
import { IndexModel } from '../model/common.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserPermissionService {
  constructor(private readonly _baseService: BaseService) { }

  GetDefaultPermissions(applicationCode: string = "", userType: string = "") {
    var param = new Dictionary<any>();
    param.Add("applicationCode", applicationCode)
    param.Add("userType", userType)
    return this._baseService.get(AppSetting.GetDefaultPermissionUrl, param);
  }

  SaveDefaultPermissions(model: DefaultPermissionViewModel[]): any {
    return this._baseService.post(AppSetting.SaveDefaultPermissionUrl, model);
  }

  GetUserPermissions(applicationCode: string, userCode: number) {
    var param = new Dictionary<any>();
    param.Add("applicationCode", applicationCode)
    param.Add("userCode", userCode)

    return this._baseService.get(AppSetting.GetUserPermissionByApplicationUrl, param);
  }

  GetUserDefaultPermissions(applicationCode: string, userCode: number) {
    var param = new Dictionary<any>();
    param.Add("applicationCode", applicationCode)
    param.Add("userCode", userCode)

    return this._baseService.get(AppSetting.GetUserDefaultPermissionByApplicationUrl, param);
  }

  GetUserListByApplicationUrl(userType: string, departmentCode: string, office: string) {
    var param = new Dictionary<any>();
    param.Add("userType", userType)
    param.Add("departmentCode", departmentCode.toString())
    param.Add("office", office.toString())

    return this._baseService.get(AppSetting.GetUserByApplicationUrl, param);
  }

  SaveUserPermissions(model: UserPermissionViewModel[]): any {
    return this._baseService.post(AppSetting.SaveUserPermissionUrl, model);
  }
  GetPermissionByUserCode(userCode) {
    var param = new Dictionary<any>();
    param.Add("userCode", userCode)
    return this._baseService.get(AppSetting.GetAssignedUserPermissionUrl, param
    );
  }

  ///New Function added
  GetUserListByUserType(userType: string) {
    var param = new Dictionary<any>();
    param.Add("userType", userType)
    return this._baseService.get(AppSetting.GetUserByUserRoleVender, param);
  }

  UserAdditionalPermissionGetList(model: IndexModel): Observable<any> {
    return this._baseService.post(AppSetting.UserAdditionalPermissionListUrl, model);
  }
  UserAdditionalPermissionGetById(id): Observable<any> {
    var param = new Dictionary<any>(); param.Add("id", id)
    var result = this._baseService.get(AppSetting.UserAdditionalPermissionDetailUrl, param); return result;
  }
  UserAdditionalPermissionChangeActiveStatus(id: number) {
    var param = new Dictionary<any>(); param.Add("id", id)
    return this._baseService.get(AppSetting.UserAdditionalPermissionActiveUrl, param);
  }

  UserAdditionalPermissionChangeLockedStatus(id: number) {
    var param = new Dictionary<any>(); 
    param.Add("id", id);
    return this._baseService.get(AppSetting.UserAdditionalPermissionLockedUrl, param);
  }

  UserAdditionalPermissionAddUpdate(model: AdditionalUserPermissionModel) { 
    return this._baseService.post(AppSetting.UserAddPermissionAddUpdateUrl, model); 
  }

  GetDefaultPermissionUserTypes(perCode: number) {
    var param = new Dictionary<any>(); 
    param.Add("id", perCode);
    return this._baseService.get(AppSetting.DefaultPermissionUserTypesUrl, param);
  }

  GetSpecificPermissionUsers(perCode: number) {
    var param = new Dictionary<any>(); 
    param.Add("id", perCode);
    return this._baseService.get(AppSetting.SpecificPermissionUsersUrl, param);
  }

  ResetUserSpecificPermission(userId: number) {
    var param = new Dictionary<any>(); 
    param.Add("id", userId);
    return this._baseService.get(AppSetting.ResetUserSpecificPermissionUrl, param);
  }

  GetUserSpecificPagePermission(userId: number) {
    var param = new Dictionary<any>(); 
    param.Add("id", userId);
    return this._baseService.get(AppSetting.UserSpecificPagePermissionListUrl, param );
  }

}
