import { IndexModel } from "./common.model";

export class WorkOrderModel {
    WorkOrderCode: number;
    Title: string;
    Subject: string;
    Description: string;
    OfficeCode: number | string;
    OfficeName: string;
    OfficeDDOCode: number | null;
    DepartmentCode: number | string;
    DepartmentName: string;
    DepartmentNameHindi: string;
    DistrictCode: number | string;
    DistrictName: string;
    DistrictNameHindi: string;
    OrderNumber: string;
    OrderDate: Date | string | any;
    Referencee: string;
    Tender: string;
    Amount: string;
    OIC_Name: string;
    OIC_Designation: string;
    OIC_DesignationName: string;
    OIC_Email: string;
    OIC_Mobile: string;
    TotalCommunications: number | null;
    IsActive: boolean;
    IsDeleted: boolean;
    CreatedBy: number | null;
    CreatedOn: Date | string | any;
    ModifiedBy: number | null;
    ModifiedOn: Date | null;
    DocumentList: WorkOrderDocumentModel[] = [];
    DeletedDocumentCodes: string;
    ProjectCode: number | string;
    ProjectName: string;
    UserCode: number | string;
    UserName: string;
    CreatedByName: string;
    VendorSSOID: string;
    VendorCode: number;
    ContactPersonName: string;
    CompanyName: string;
    MobileNo1: string;
    IsOICAccepted: boolean;
    IsAccepted: boolean;
    AcceptedBy: number | null;
    AcceptedByName: string;
    AssignedProjectOfficerName: string;
    SSOID: string;
    Email: string;
    Mobile: string;
    WorkOrderStartDate: string | string | any;
    WorkOrderEndtDate: string | string | any;
    PaymentTypeCode: number | string | null;
    WorkOrderSubCategoryCode: number | string | null;
    SubCategory: string;
    Category: string;
    CategoryCode: number | string | null;
    PaymentType: string;
    IsDraft: boolean;
    IsFinalSubmit: boolean;
    WorkLocation: string;
}

export class WorkOrderDocumentModel {
    DocumentCode: number;
    WorkOrderCode: number;
    DocName: string;
    DocPath: string;
    Extension: string;
}

export class WorkOrderSearchModel {
    constructor() {
        this.IndexModel = new IndexModel();
        this.IsActive = "-1";
        this.IsUsedForCommunication = "-1";
    }
    LoginUserCode: number;
    AdminDepartmentCode: number | null;
    DepartmentCode: number | null;
    ProjectCode: number | null;
    CategoryCode: number | null;
    SubCategoryCode: number | null;
    IsActive: number | null | string = "-1";
    IsUsedForCommunication: number | null | string = "-1";
    FromDate: string;
    ToDate: string;
    SearchText: string;
    IndexModel: IndexModel;
}

export class WorkOrderViewModel {
    WorkOrderCode: number;
    Title: string;
    Subject: string;
    Description: string;
    OrderNumber: string;
    OrderDate: string | null;
    Referencee: string;
    Tender: string;
    Amount: string;
    OfficeCode: number | null;
    OfficeName: string;
    DepartmentCode: number | null;
    DepartmentName: string;
    DepartmentNameHindi: string;
    DistrictCode: number | null;
    DistrictName: string;
    DistrictNameHindi: string;
    OIC_Name: string;
    OIC_Designation: string;
    OIC_Email: string;
    OIC_Mobile: string;
    TotalCommunications: number | null;
    IsActive: boolean;
    IsDeleted: boolean;
    CreatedBy: number | null;
    CreatedOn: string | null;
    ModifiedBy: number | null;
    ModifiedOn: string | null;
    ProjectCode: number | null;
    ProjectName: string;
    CreatedByName: string;
    ModifiedByName:string;
    AcceptedByName: string;
    IsAccepted: boolean;
    AcceptedBy: number | null;
    VendorSSOID: string;
    VendorCode: number | null;
    ContactPersonName: string;
    CompanyName: string;
    MobileNo1: string;
    UserCode: number | null;
    IsOICAccepted: boolean | null;
    AssignedProjectOfficerName: string;
    SSOID: string;
    Email: string;
    Mobile: string;
    WorkOrderStartDate: string | null;
    WorkOrderEndtDate: string | null;
    PaymentTypeCode: number | null;
    WorkOrderSubCategoryCode: number | null;
    SubCategory: string;
    Category: string;
    CategoryCode: number | null;
    PaymentType: string;
    IsDraft: boolean;
    IsFinalSubmit: boolean;
    TotalRecords: number;
}


export class WorkOrderClearModel {
    constructor() {

        this.ProjectCode = 0; 
    }
    ProjectCode: number | string;  
}