import { IndexModel } from "./common.model";

export class DashBoardModel {
  constructor() {
    this.CommStatusWiseAgeCountList = [];
    this.CommTypeWiseAgeCountList = [];
    this.CommProjectWiseAgeCountList = [];
    this.CommVendorWiseAgeCountList = [];
    this.NoActionCommTypeWiseAgeCountList = [];
    this.CommDepartmentWiseAgeCountList = [];
  }
  CommStatusWiseAgeCountList: CommStatusWiseAgeCountModel[] = [];
  CommTypeWiseAgeCountList: CommTypeWiseAgeCountModel[] = [];
  CommProjectWiseAgeCountList: CommProjectWiseAgeCountModel[] = [];
  CommVendorWiseAgeCountList: CommVendorWiseAgeCountModel[] = [];
  NoActionCommTypeWiseAgeCountList: NoActionCommTypeWiseAgeCountModel[] = [];
  CommDepartmentWiseAgeCountList: CommDepartmentWiseAgeCountModel[] = [];
}

export class CommStatusWiseAgeCountModel {
  Status: string;
  Total: number | string;
  DayAge_0_15: number | string;
  DayAge_15_30: number | string;
  DayAge_30_60: number | string;
}

export class CommTypeWiseAgeCountModel {
  CommunicationTypeCode: number;
  CommunicationType: string;
  Total: number | string;
  DayAge_0_15: number | string;
  DayAge_15_30: number | string;
  DayAge_30_60: number | string;
}

export class CommProjectWiseAgeCountModel {
  ProjectCode: number;
  ProjectName: string;
  Total: number | string;
  DayAge_0_15: number | string;
  DayAge_15_30: number | string;
  DayAge_30_60: number | string;
}

export class CommVendorWiseAgeCountModel {
  VendorCode: number;
  Vendor: string;
  CommunicationTypeCode: number;
  CommunicationType: string;
  Total: number | string;
  DayAge_0_15: number | string;
  DayAge_15_30: number | string;
  DayAge_30_60: number | string;
}

export class NoActionCommTypeWiseAgeCountModel {
  CommunicationTypeCode: number;
  CommunicationType: string;
  Total: number | string;
  DayAge_0_15: number | string;
  DayAge_15_30: number | string;
  DayAge_30_60: number | string;
}

export class CommDepartmentWiseAgeCountModel {
  DepartmentCode: number;
  DepartmentName: string;
  CommunicationTypeCode: number;
  CommunicationType: string;
  Total: number | string;
  DayAge_0_15: number | string;
  DayAge_15_30: number | string;
  DayAge_30_60: number | string;
}


export class DashboardStatusModel {
  constructor() {
    this.CommStausCount = new CommStausCountModel();
    this.TodoListCount = new ToDoListCountModel();
    this.VendorStatusCount = new VendorStatusCountModel();
    this.DepartmentStatusCount = new DepartmentStatusCountModel();
    this.VendorCommunicationStausCount = new VendorCommunicationDisplayStatusCountViewModel();
    this.DepartmentCommunicatoinStausCount = new DepartmentCommunicationDisplayStatusCountViewModel();
    this.VendorCommunicationPermission = new VendorCommunicationPermissionViewModel();
  }

  CommStausCount: CommStausCountModel;
  TodoListCount: ToDoListCountModel;
  VendorStatusCount: VendorStatusCountModel;
  DepartmentStatusCount: DepartmentStatusCountModel;
  VendorCommunicationStausCount: VendorCommunicationDisplayStatusCountViewModel;
  DepartmentCommunicatoinStausCount: DepartmentCommunicationDisplayStatusCountViewModel;
  VendorCommunicationPermission: VendorCommunicationPermissionViewModel;
}

export class CommStausCountModel {
  Total: number = 0;
  Sent: number = 0;
  Return: number = 0;
  Accept: number = 0;
  Moved: number = 0;
  Reject: number = 0;
  Disposed: number = 0;
  Reopen: number = 0;
  Close: number = 0;
  NonAssigned: number = 0;
}

export class ToDoListCountModel {
  SMSReceived: number = 0;
  ReceivedFromVendor_NoActionTaken: number = 0;
  VendorRequestedForActivation: number = 0;
  ProjectNotAssignedToOfficer: number = 0;
  BlacklistedVendor: number = 0;
  WorkOrderPendingForAcceptance: number = 0;
  Alert: number = 0;
  ProjectNotAssignedToVendorUser: number = 0;
  BlacklistedVendorUser: number = 0;
}

export class VendorStatusCountModel {
  Total: number = 0;
  Active: number = 0;
  Inactive: number = 0;
  Blacklisted: number = 0;
}

export class DepartmentStatusCountModel {
  Total: number = 0;
  Active: number = 0;
  Inactive: number = 0;
}

// export class VendorCommunicationStausCountViewModel {
//     Total: number = 0;
//     Sent: number = 0;
//     Return: number = 0;
//     Accept: number = 0;
//     Moved: number = 0;
//     Reject: number = 0;
//     Disposed: number = 0;
//     Reopen: number = 0;
//     Close: number = 0;
//     NonAssigned: number = 0;
// }

// export class DepartmentCommunicationStausCountViewModel {
//     Total: number = 0;
//     Sent: number = 0;
//     Return: number = 0;
//     Accept: number = 0;
//     Moved: number = 0;
//     Reject: number = 0;
//     Disposed: number = 0;
//     Reopen: number = 0;
//     Close: number = 0;
//     NonAssigned: number = 0;
// }


export class VendorCommunicationDisplayStatusCountViewModel {
  Total: DashboardStatusCountModel;
  Sent: DashboardStatusCountModel;
  Return: DashboardStatusCountModel;
  Accept: DashboardStatusCountModel;
  Moved: DashboardStatusCountModel;
  Reject: DashboardStatusCountModel;
  Disposed: DashboardStatusCountModel;
  Reopen: DashboardStatusCountModel;
  Close: DashboardStatusCountModel;
  NonAssigned: DashboardStatusCountModel;
  NonMoved: DashboardStatusCountModel;
  IsReSubmitted: DashboardStatusCountModel;
  Received: DashboardStatusCountModel;
  Pending: DashboardStatusCountModel;
}

export class DepartmentCommunicationDisplayStatusCountViewModel {
  Total: DashboardStatusCountModel;
  Sent: DashboardStatusCountModel;
  Return: DashboardStatusCountModel;
  Accept: DashboardStatusCountModel;
  Moved: DashboardStatusCountModel;
  Reject: DashboardStatusCountModel;
  Disposed: DashboardStatusCountModel;
  Reopen: DashboardStatusCountModel;
  Close: DashboardStatusCountModel;
  NonAssigned: DashboardStatusCountModel;
  NonMoved: DashboardStatusCountModel;
  ResponseBack: DashboardStatusCountModel;
  IsReSubmitted: DashboardStatusCountModel;
  Received: DashboardStatusCountModel;
}
export class DashboardStatusCountModel {
  Count: number;
  StatusName: string;
}

export class DashboardDrilDownModel {
  constructor() {
    this.indexModel = new IndexModel();
  }
  LoginUserCode: number;
  VendorCode: number | null;
  DepartmentCode: number | null;
  OfficerCode: number | null;
  OfficeCode: number | null;
  ProjectCode: number | null;
  WorkOrderCode: number | null;
  DistrictCode: number | null;
  VendorUserCode: number | null;
  CommunicationTypeCode: number | null;
  Status: string;
  FromDate: string;
  ToDate: string;
  FilterTypeId: number | null;
  indexModel: IndexModel;

  ReportType: string;
  Para1: string;
  Para2: string;
  Para3: string;
  Para4: string;
}

export class VendorCommunicationPermissionViewModel {
  Total: DashboardStatusCountModel;
  Accept: DashboardStatusCountModel;
  Pending: DashboardStatusCountModel;
  Latest: DashboardStatusCountModel;
}
