export class RefundRequestReasonModel {
    ReasonCode: number;
    Reason: string;
    ReasonHindi: string;
    IsActive: boolean;
    IsDeleted: boolean;
    CreatedBy: number | null;
    CreatedOn: Date | null;
    ModifiedBy: number | null;
    ModifiedOn: Date | null;
    UserName: String;
}

export class RefundRequestReasonReportModel {
    MasterCode: number;
    FieldValueInEnglish: string;
    FieldValueInHindi: string;
    IsActive: boolean;
    IsDeleted: boolean;
    CreatedBy: number | null;
    CreatedOn: Date | null;
    ModifiedBy: number | null;
    ModifiedOn: Date | null;
    UserName:string;
}