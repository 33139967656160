export class DDLModel {

  constructor() {
    this.ddlAdminDepartment = [];
    this.ddlOfficeDepartment = [];
    this.ddlDepartment = [];
    this.ddlDistrict = [];
    this.ddlUserType = [];
    this.ddlUserTypetemplogin = [];
    this.ddlTitle = [];
    this.ddlGender = [];
    this.ddlVendorType = [];
    this.ddlCommunicationAttachmentType = [];
    this.ddlBank = [];
    this.ddlBankBranch = [];
    this.ddlState = [];
    this.ddlAllOffice = [];
    this.ddlDepartmentOffice = [];
    this.ddlDepartmentOfficeHavingStaff = [];
    this.ddlWorkOrder = [];
    this.ddlConcern = [];
    this.ddlEMDType = [];
    this.ddlRefundRequestReason = [];
    this.ddlInForrmOf = [];
    this.ddlComplaintType = [];
    this.ddlTax = [];
    this.ddlSuggestion = [];
    this.ddlReason = [];
    this.ddlOfficeReceptionUsers = [];
    this.ddlOfficeStaff = [];
    this.ddlProject = [];
    this.ddlVendorBank = [];
    this.ddlVendorBankAccount = [];
    this.ddlOfficeByLoginUserId = [];
    this.ddlOfficeHavingStaffByLoginUserId = [];
    this.ddlDepartmentByLoginUserId = [];
    this.ddlUser = [];
    this.ddlMasterType = [];
    this.ddlFieldType = [];
    this.ddlDisplayUserTypeTitle = [];
    this.ddlDistrictbyState = [];
    this.ddlDefaultField = [];
    this.ddlCSSClass = [];
    this.ddlLoggedUserVendor = [];
    this.ddlBlock = [];
    this.ddlTehsil = [];
    this.ddlRajDistrict = [];
    this.ddlOffice = [];
    this.ddlDesignation = [];
    this.ddlDistrictbyState = [];
    this.ddlDefaultField = [];
    this.ddlCSSClass = [];
    this.ddlUserGroup = [];
    this.ddlDepartmentCategory = [];
    this.ddlApplicationMenu = [];
    this.ddlApplicationType = [];
    this.ddlURLType = [];
    this.ddlPermissionType = [];
    this.ddlCompliantFilter = [];
    this.ddlCompliantStatus = [];
    this.ddlEntryTypeModule = [];
    this.ddlPriorityModule = [];
    this.ddlForwordToAction = [];
    this.ddlCompliantAction = [];
    this.ddlNotificationTemplateType = [];
    this.ddlVendor = [];
    this.ddlVendorUser = [];
    this.ddlCommunicationType = [];
    this.ddlProjectCategory = [];
    this.ddlProjectSubCategory = [];
    this.ddlDptContactDesignation = [];
    this.ddlADLCUserCategory = [];
    this.ddlWorkOrderCategory = [];
    this.ddlWorkOrderSubCategory = [];
    this.ddlPaymentType = [];
    this.RadioDepartmentDistrict = [];
    this.ddlCMOOfficers = [];
    this.ddlAdditionalUser = [];
    this.ddlClassification = [];
    this.ddlClassificationPageType = [];
    this.ddlGeneralEntryCategory = [];
    this.ddlGeneralEntrySubCategory = [];
    this.ddlOfficeDistrict = [];
    this.ddlOfficeProject = [];
    this.ddlB2GUserManualCategory = [];
    this.ddlNotificationTransactionType = [];
    this.ddlUserTypeByParent = [];
    this.ddlTrainingTime = [];
    this.ddlAreaType = [];
    this.ddlGramPanchayat = [];
    this.ddlVillage = [];
    this.ddAvailableSMSNotificationTransactionType = [];
    this.ddlAvailableSMSTemplate = [];
    this.ddlSMSTemplate = [];
    this.ddlAllDepartment = [];
    this.ddlProjectGroup = [];
    this.ddlOfficeLevel = [];
    this.ddlMenuActivityLog = [];
    this.ddlProjectDepartment = [];
    this.ddlAllOfficeAdminDepartment = [];
    this.ddlAllOfficeDepartment = [];
    this.ddlAllOfficeState = [];
    this.ddlAllOfficeDistrict = [];
    this.ddlExistingDistrictOfOffice = [];
    this.ddlStatus = [];
    this.ddlVendorUserCategory = [];
    this.ddlUserCategory = [];
    this.ddlDepartmentByVendorPermission = [];
    this.ddlOfficelookupType1 = [];
    this.ddlOfficelookupType2 =[];
  }
  ddlDesignation: DdlItemModel[];
  ddlDisplayUserTypeTitle: DdlItemModel[];
  ddlOfficeByLoginUserId: DdlItemModel[];
  ddlOfficeHavingStaffByLoginUserId: DdlItemModel[];
  ddlDepartmentByLoginUserId: DdlItemModel[];
  ddlAdminDepartment: DdlItemModel[];
  ddlOfficeDepartment: DdlItemModel[];
  ddlOffice: DdlItemModel[];
  ddlDepartment: DdlItemModel[];
  ddlDistrict: DdlItemModel[];
  ddlUser: DdlItemModel[];
  ddlUserType: DdlItemModel[];
  ddlUserTypetemplogin: DdlItemModel[];
  ddlTitle: DdlItemModel[];
  ddlGender: DdlItemModel[];
  ddlVendorType: DdlItemModel[];
  ddlCommunicationAttachmentType: DdlItemModel[];
  ddlBank: DdlItemModel[];
  ddlBankBranch: DdlItemModel[];
  ddlState: DdlItemModel[];
  ddlAllOffice: DdlItemModel[];
  ddlDepartmentOffice: DdlItemModel[];
  ddlDepartmentOfficeHavingStaff: DdlItemModel[];
  ddlWorkOrder: DdlItemModel[];
  ddlConcern: DdlItemModel[];
  ddlEMDType: DdlItemModel[];
  ddlRefundRequestReason: DdlItemModel[];
  ddlInForrmOf: DdlItemModel[];
  ddlComplaintType: DdlItemModel[];
  ddlTax: DdlItemModel[];
  ddlSuggestion: DdlItemModel[];
  ddlReason: DdlItemModel[];
  ddlAcceptReason: DdlItemModel[];
  ddlRejectReason: DdlItemModel[];
  ddlDisposedReason: DdlItemModel[];
  ddlCloseReason: DdlItemModel[];
  ddlOfficeReceptionUsers: DdlItemModel[];
  ddlOfficeStaff: DdlItemModel[];
  ddlProject: DdlItemModel[];
  ddlVendorBank: DdlItemModel[];
  ddlVendorBankAccount: DdlItemModel[];
  ddlMasterType: DdlItemModel[];
  ddlFieldType: DdlItemModel[];
  ddlDefaultField: DdlItemModel[];
  ddlDistrictbyState: DdlItemModel[];
  ddlCSSClass: DdlItemModel[];
  ddlLoggedUserVendor: DdlItemModel[];
  ddlBlock: DdlItemModel[] = [];
  ddlTehsil: DdlItemModel[] = [];
  ddlRajDistrict: DdlItemModel[] = [];
  ddlUserGroup: DdlItemModel[];
  ddlDepartmentCategory: DdlItemModel[];
  ddlApplicationMenu: DdlItemModel[];
  ddlApplicationType: DdlItemModel[];
  ddlURLType: DdlItemModel[];
  ddlPermissionType: DdlItemModel[];
  ddlCompliantFilter: DdlItemModel[];
  ddlCompliantStatus: DdlItemModel[];
  ddlEntryTypeModule: DdlItemModel[];
  ddlPriorityModule: DdlItemModel[];
  ddlForwordToAction: DdlItemModel[];
  ddlCompliantAction: DdlItemModel[];
  ddlNotificationTemplateType: DdlItemModel[];
  ddlVendor: DdlItemModel[];
  ddlVendorUser: DdlItemModel[];
  ddlCommunicationType: DdlItemModel[];
  ddlProjectCategory: DdlItemModel[];
  ddlProjectSubCategory: DdlItemModel[];
  ddlDptContactDesignation: DdlItemModel[];
  ddlADLCUserCategory: DdlItemModel[];
  ddlWorkOrderCategory: DdlItemModel[];
  ddlWorkOrderSubCategory: DdlItemModel[];
  ddlPaymentType: DdlItemModel[];
  RadioDepartmentDistrict: DdlItemModel[];
  ddlCMOOfficers: DdlItemModel[];
  ddlReOpenReason: DdlItemModel[];
  ddlAdditionalUser: DdlItemModel[];
  ddlClassification: DdlItemModel[];
  ddlClassificationPageType: DdlItemModel[];
  ddlGeneralEntryCategory: DdlItemModel[];
  ddlGeneralEntrySubCategory: DdlItemModel[];
  ddlOfficeDistrict: DdlItemModel[];
  ddlOfficeProject: DdlItemModel[];
  ddlB2GUserManualCategory: DdlItemModel[];
  ddlNotificationTransactionType: DdlItemModel[];
  ddlUserTypeByParent: DdlItemModel[];
  ddlTrainingTime: DdlItemModel[];
  ddlAreaType: DdlItemModel[];
  ddlGramPanchayat: DdlItemModel[];
  ddlVillage: DdlItemModel[];
  ddAvailableSMSNotificationTransactionType: DdlItemModel[];
  ddlAvailableSMSTemplate: DdlItemModel[];
  ddlSMSTemplate: DdlItemModel[];
  ddlDataSource: DdlItemModel[];
  ddlColumnValue: DdlItemModel[];
  ddlRowValueEnum: DdlItemModel[];
  ddlAllDepartment: DdlItemModel[];
  ddlProjectGroup: DdlItemModel[];
  ddlOfficeLevel: DdlItemModel[];
  ddlMenuActivityLog: DdlItemModel[];
  ddlProjectDepartment: DdlItemModel[];
  ddlAllOfficeAdminDepartment: DdlItemModel[];
  ddlAllOfficeDepartment: DdlItemModel[];
  ddlAllOfficeDistrict: DdlItemModel[];
  ddlAllOfficeState: DdlItemModel[];
  ddlExistingDistrictOfOffice: DdlItemModel[];
  ddlStatus: DdlItemModel[];
  ddlVendorUserCategory: DdlItemModel[];
  ddlUserCategory: DdlItemModel[];
  ddlDepartmentByVendorPermission: DdlItemModel[];
  ddlUserByDepartment: DdlItemModel[];
  ddlOfficelookupType1: DdlItemModel[];
  ddlOfficelookupType2: DdlItemModel[];

}

export class DdlItemModel {
  Value: string | any;
  Text: string;
}

export class CommonLayoutModel {
  constructor() {
    this.Title = "";
    this.Maticon = "";
    this.Maticontitle = "";
    this.Routelink = "";
    this.ListBtnTitle = "";
    this.ListBtnUrl = "";
  }
  Title: string;
  Maticon: string;
  Maticontitle: string;
  Routelink: string;
  ListBtnTitle: string;
  ListBtnUrl: string;
}

export class FilterDDlModel {
  FilterFor: string;
  Value: any;
  FilterFrom: string;
}

export class ColumnHeaderModel {
  Value: string;
  Text: string;
}

export class DocumentUrlModel {
  Url: string;
  Extension: string;
  DisplayName: string;
  IsNew: boolean;
  constructor() {
    this.Url = '';
    this.Extension = '';
  }
}

//   export class RelatedToDDlModel {
//     rowno: string;
//     particulars: string;
//     prj_dept: string;
//   }

//   export class CommonIdModel{
//     Id: number;
//     Paasword: string;
//   }
