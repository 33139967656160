import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { AppComponent } from 'src/app/app.component';
import { DefaultFieldEnum } from 'src/app/shared/enum/default-field.enum';
import { FieldTypeEnum, UserTypeEnum, StatusEnum } from 'src/app/shared/enum/fixed-values.enum';
import { ActionModel } from 'src/app/shared/model/action.model';
import { CommunicationTransactionViewModel } from 'src/app/shared/model/communication-transaction.model';
import { DynamicFieldConfig } from 'src/app/shared/model/dynamic-field-config.model';
import { DefaultFieldVisibleModel, CommunicationFieldValueViewModel, CommunicationTypeDefaultFieldsMasterModel } from 'src/app/shared/model/master/communication-type-fields-master.model';
import { ActionService } from 'src/app/shared/service/action.service';
import { AlertService } from 'src/app/shared/service/alert.service';
import { AuthenticationService } from 'src/app/shared/service/authentication.service';
import { CommonService } from 'src/app/shared/service/common.service';
import { CommunicationService } from 'src/app/shared/service/communication.service';
import { CommunicationTypeFieldsMasterService } from 'src/app/shared/service/master/communication-type-fields-master.service';
import { isNullOrUndefined } from 'util';

@Component({
  selector: 'app-locate-communication-detail',
  templateUrl: './locate-communication-detail.component.html',
  styleUrls: ['./locate-communication-detail.component.scss']
})
export class LocateCommunicationDetailComponent implements OnInit {
  loginUserCode: number;
  loginBaseUserType: string;
  RecordId: number = 0;
  model: CommunicationTransactionViewModel = new CommunicationTransactionViewModel();
  defaultFieldEnum = DefaultFieldEnum;
  defaultFieldVisibleModel = new DefaultFieldVisibleModel();
  dynamicField: DynamicFieldConfig[] = [];
  communicationFieldValueViewModel: CommunicationFieldValueViewModel[] = []
  fieldTypeEnum = FieldTypeEnum;
  filedCodes: string = '';

  IsHideDeptInternal_VendorCommBtn: boolean = false;
  IsHideVendorCommBtn: boolean = false;
  IsHideOtherOfficeBtn: boolean = false;
  redirectFrom: string = '';
  actionModel = new ActionModel();
  statusACCEPT = StatusEnum.ACCEPT;

  constructor(private _route: ActivatedRoute,
    private readonly _authService: AuthenticationService,
    private readonly _communicationService: CommunicationService,
    private readonly appComponent: AppComponent,
    private readonly _communicationTypeFieldsMasterService: CommunicationTypeFieldsMasterService,
    private readonly _alertService: AlertService,
    private readonly _dialog: MatDialog,
    private _router: Router,
    public _commonService: CommonService,
    private readonly _actionService: ActionService,) {
    if (!isNullOrUndefined(this._route.snapshot.params.id)) {
      this.RecordId = this._route.snapshot.params.id;
    }

    if (!isNullOrUndefined(this._route.snapshot.params.redirectFrom)) {
      this.redirectFrom = this._route.snapshot.params.redirectFrom;
    }

    this.loginBaseUserType = this._authService.BaseUserType;
    this.loginUserCode = this._authService.UserCode;
    this.SetLayout();
  }

  ngOnInit() {
    if (this.RecordId > 0) {
      this.getDetail();
    }
  }

  getDetail() {

    this._communicationService.Detail(this.RecordId, false).subscribe(
      data => {
        if (data.IsSuccess) {
           this.model = <CommunicationTransactionViewModel>data.Data;
          this.checkDefaultList(this.model.CommunicationTypeEnumValue);
          this.GetDynamicField();
        }
        this.SetLayout(this.model.CommunicationType);

      }
    );
  }

  SetLayout(commType="") {
    switch (this.redirectFrom.toLocaleLowerCase()) {
      case "inbox":
        this.appComponent.setPageLayout(commType + " Communication Detail :", "keyboard_backspace", "Back To Inbox List", "/inbox");

        break;
      case "outbox":
        this.appComponent.setPageLayout(commType + " Communication Detail :", "keyboard_backspace", "Back To Outbox List", "/outbox");

        break;

      case "locate":
        this.appComponent.setPageLayout(commType + " Communication Detail :", "keyboard_backspace", "Back To Locate Communication List", "communications/search");

        break;


      default:
        this.appComponent.setPageLayout(commType + " Communication Detail :", "keyboard_backspace", "Back To List", "communications-list/" + this.model.CommunicationTypeEnumValue);

        break;
    }
  }

  downloadPdf(url, name) {
    const linkSource = url;
    const downloadLink = document.createElement("a");
    const fileName = name;
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.target = "blank";
    downloadLink.click();
  }

  checkDefaultList(communicationType) {
    this._communicationTypeFieldsMasterService.GetDefaultFieldsForCommunication(0, communicationType).subscribe(
      data => {

        if (data.IsSuccess) {

          let defaultFieldModel = <CommunicationTypeDefaultFieldsMasterModel[]>data.Data;

          defaultFieldModel.forEach(element => {
            switch (String(element.FieldCode)) {

              case this.defaultFieldEnum.Project:
                this.defaultFieldVisibleModel.IsShowProjectField = true;
                break;

              case this.defaultFieldEnum.Department:
                this.defaultFieldVisibleModel.IsShowDepartmentField = true;
                break;

              case this.defaultFieldEnum.District:
                this.defaultFieldVisibleModel.IsShowDistrictField = true;
                break;

              case this.defaultFieldEnum.WorkOrder:
                this.defaultFieldVisibleModel.IsShowWorkOrderField = true;
                break;

              case this.defaultFieldEnum.VendorBank:
                this.defaultFieldVisibleModel.IsShowVendorBankField = true;
                break;

              case this.defaultFieldEnum.Attachments:
                this.defaultFieldVisibleModel.IsShowAttachmentsField = true;
                break;

              case this.defaultFieldEnum.Office:
                this.defaultFieldVisibleModel.IsShowOfficeField = true;
                break;

              default:
                break;
            }
          });

        } else {
          this._alertService.error(data.Message);
        }
      },
      error => {
        this._alertService.error(error.message);
      }
    );
  }

  GetDynamicField() {
    this.dynamicField = [];
    this._communicationTypeFieldsMasterService.GetFieldsForCommunication(this.model.CommunicationTypeEnumValue, this.RecordId).subscribe(response => {
      if (response.IsSuccess) {
        this.communicationFieldValueViewModel = response.Data as CommunicationFieldValueViewModel[];
      } else {
        this._alertService.error(response.Message);
      }
    },
      error => {
        this._alertService.error(error.message);
      });
  }

  CallPrint(printContent) {
    this._commonService.printHTMLContent(printContent);
  }



}
