import { Component, ViewChild, ChangeDetectorRef } from "@angular/core";
import { SideNavBarComponent } from "./side-nav-bar/side-nav-bar.component";
import { AuthenticationService } from "./shared/service/authentication.service";
import { AlertService } from "./shared/service/alert.service";
import { CommonLayoutModel } from "./shared/model/commonddl.model";
import { MatDialog } from "@angular/material";
import { VendorRegistrationDialogComponent } from "./content/vendor/vendor-registration-dialog/vendor-registration-dialog.component";
import { ActivatedRoute, Router } from "@angular/router";
import { LoaderService } from "./shared/service/loader.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})

export class AppComponent {
  layoutmodel: CommonLayoutModel;
  isMaster: boolean = false;
  IsAouth: boolean = false;
  isDashBoard: boolean = false;
  pageTitle: string;

  isProcess: boolean;
  CompletedProcess: number = 100;
  @ViewChild(SideNavBarComponent, null) sideNav: SideNavBarComponent;

  constructor(
    public readonly _authenticationService: AuthenticationService,
    private readonly _alertService: AlertService,
    private readonly cd: ChangeDetectorRef,
    private readonly _dialog: MatDialog,
    public readonly _router: Router,
    private loaderService: LoaderService,
    private _route: ActivatedRoute
  ) { }

  ngOnInit() {
    // setTimeout(() => {
    //   if (!this._authenticationService.GetUserIsAuthenticated()) {
    //     this.OpenVendorRegistrationDialog(0);
    //   }
    // }, 1000);
  }

  // ngAfterViewChecked() {
  //   this.loaderService.isProcess.subscribe((isProcess) => {

  //     this.isProcess = isProcess;
  //     this.cd.detectChanges();
  //   });

  //   this.loaderService.CompletedProcess.subscribe((value) => {
  //     this.CompletedProcess = value;
  //     this.cd.detectChanges();
  //   });
  // }

  setPageLayout(
    Title: string,
    Maticon: string = "",
    Maticontitle: string = "",
    Routelink: string = "",
    isMaster: boolean = false,
    isDashBoard = false,
    listBtnTitle: string = "",
    listBtnUrl: string = ""
  ) {
    // this.clearLayout();
    if (this.layoutmodel == null) {
      this.layoutmodel = new CommonLayoutModel();
    }
    this.layoutmodel.Title = Title;
    this.layoutmodel.Maticon = Maticon;
    this.layoutmodel.Maticontitle = Maticontitle;
    this.layoutmodel.Routelink = Routelink;
    this.isMaster = isMaster;
    this.isDashBoard = isDashBoard;
    this.layoutmodel.ListBtnTitle = listBtnTitle;
    this.layoutmodel.ListBtnUrl = listBtnUrl;
    this.cd.detectChanges();
    this.pageTitle = Title.replace(":", "");
  }

  clearLayout() {
    this.layoutmodel = new CommonLayoutModel();
    this.cd.detectChanges();
  }

  SuccessMessage(msg: string) {
    this._alertService.success(msg);
  }

  ErrorMessage(msg: string) {
    this._alertService.error(msg);
  }

  OpenVendorRegistrationDialog(Id) {
    const _dialogRef = this._dialog.open(VendorRegistrationDialogComponent, {
      width: "1000px",
      data: { Id },
      disableClose: true,
    });
    _dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        this._authenticationService.Login();
      }
    });
  }

  // @HostListener('contextmenu', ['$event'])
  // onRightClick(event) {
  //   event.preventDefault();
  // }

  UpdateMenu(userCode = this._authenticationService.GetCurrentUserDetail().UserViewModel.UserCode): void {
    this.sideNav.UpdateLoginUserMenu(userCode);
  }

  goBack(route) {

    let fromRoute = this._router.url
    if (fromRoute.includes('/vendor/detail/')) {
      this._router.navigate(['/master/vendor-permission']);
    } else if (route) {
      this._router.navigate([route]);
    }


  }

}
