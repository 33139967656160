import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from "@angular/router";
import { AuthenticationService } from 'src/app/shared/service/authentication.service';
import { AppComponent } from 'src/app/app.component';
import { AlertService } from 'src/app/shared/service/alert.service';
import { UserViewModel } from 'src/app/shared/model/user.model';
import { isNullOrUndefined, debug } from 'util';
import { WorkOrderService } from 'src/app/shared/service/work-order.service';
import { WorkOrderModel } from 'src/app/shared/model/work-order.model';
import { CommonService } from 'src/app/shared/service/common.service';
import { UserTypeEnum } from 'src/app/shared/enum/fixed-values.enum';

@Component({
  selector: 'app-work-order-detail',
  templateUrl: './work-order-detail.component.html',
  styleUrls: ['./work-order-detail.component.scss']
})

export class WorkOrderDetailComponent implements OnInit {

  loginUserDetail: UserViewModel;
  model: WorkOrderModel;
  RecordId: number = 0;
  userTypeEnum = UserTypeEnum;
  loginBaseUserType: string;

  constructor(
    private readonly appComponnet: AppComponent,
    public readonly _commonService: CommonService,
    private readonly _alertService: AlertService,
    private _route: ActivatedRoute,
    private readonly _workOrderService: WorkOrderService,
    private readonly _authService: AuthenticationService
  ) {
    if (!isNullOrUndefined(this._route.snapshot.params.id)) {
      this.RecordId = this._route.snapshot.params.id;
    }
    this.model = new WorkOrderModel();
    this.appComponnet.setPageLayout("Work Order Detail :", "keyboard_backspace", "Back To List", "work-order");
    this.loginUserDetail = this._authService.GetCurrentUserDetail().UserViewModel;
    this.loginBaseUserType = this._authService.BaseUserType;
  }

  ngOnInit() {
    if (this.RecordId != 0) {
      this.getDetail();
    }
  }

  getDetail() {
    this._workOrderService.Detail(this.RecordId).subscribe(data => {
      if (data.IsSuccess) {
        this.model = <WorkOrderModel>data.Data;
      }
    }, error => {
      this._alertService.error(error.message);
    });
  }

  CallPrint(printContent) {
    this._commonService.printHTMLContent(printContent);
  }

  workOrderAcceptMethod(id) {
    this._workOrderService.ChangeAcceptMethod(id).subscribe(
      data => {
        if (data.IsSuccess) {
          this._alertService.success(data.Message);
          this.getDetail();
        }
      },
      error => {
        this._alertService.error(error.message);
      }
    );
  }

}
