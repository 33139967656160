import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material';
import { AppComponent } from 'src/app/app.component';
import { AppSetting } from 'src/app/shared/model/appsetting.model';
import { GlobalMessagesModel } from 'src/app/shared/model/common-messages.model';
import { DdlItemModel, DDLModel } from 'src/app/shared/model/commonddl.model';
import { CustomSearchModel } from 'src/app/shared/model/master/office-master.model';
import { DefaultPermissionViewModel, UserPermissionViewModel } from 'src/app/shared/model/user-permission.model';
import { AlertService } from 'src/app/shared/service/alert.service';
import { AuthenticationService } from 'src/app/shared/service/authentication.service';
import { CommonService } from 'src/app/shared/service/common.service';
import { UserPermissionService } from 'src/app/shared/service/user-permission.service';

@Component({
  selector: 'app-specific-user-permission',
  templateUrl: './specific-user-permission.component.html',
  styleUrls: ['./specific-user-permission.component.scss']
})

export class SpecificUserPermissionComponent implements OnInit {

  isShow = false;
  dataSource: any;
  dataSource1: any;
  Application: any;
  UserCode: any;
  UserType: string = "";
  office: string | string[] = "";
  DepartmentCode: string | string[] = "";
  dropdownList: DDLModel = new DDLModel();
  searchModel: CustomSearchModel = new CustomSearchModel();
  userPagePermissionList: UserPermissionViewModel[] = [];
  userPageDefaultPermissionList: DefaultPermissionViewModel[] = [];

  IsHeaderAddSelected: boolean = false;
  IsHeaderEditSelected: boolean = false;
  IsHeaderDeleteSelected: boolean = false;
  IsHeaderViewSelected: boolean = false;

  IsDefaultHeaderAddSelected: boolean = false;
  IsDefaultHeaderEditSelected: boolean = false;
  IsDefaultHeaderDeleteSelected: boolean = false;
  IsDefaultHeaderViewSelected: boolean = false;

  displayedColumns: string[] = ["s_no", "PermissionTitle", "PermissionTypeName", "add_permission", "edit_permission", "delete_permission", "view_permission"];
  columnsToDisplay: string[] = this.displayedColumns.slice();

  constructor(
    private readonly appComponent: AppComponent,
    public readonly _commonService: CommonService,
    private readonly _alertService: AlertService,
    private readonly _auth: AuthenticationService,
    private readonly _userPermissionService: UserPermissionService
  ) {
    this.appComponent.setPageLayout("User Specific Permission :");
    this.GetDropdownList();
  }

  ngOnInit() {
    //this.getOfficeList();
  }

  GetDropdownList() {
    this._commonService.GetAllDDL(AppSetting.DDlKeyForSpecificUserPermission).subscribe((data) => {
      if (data.IsSuccess) {
        let ddlList = <DDLModel>data.Data;
        this.dropdownList.ddlApplicationType = ddlList.ddlApplicationType;
        this.dropdownList.ddlUserType = ddlList.ddlUserType;
        this.dropdownList.ddlDepartment = ddlList.ddlDepartment;
      }
    }, (error) => {
      this._alertService.error(error.message);
    });
  }

  getOfficeList() {
    if (this.DepartmentCode) {
      this._commonService.GetOfficeList("", this.DepartmentCode.toString()).subscribe(
        (data) => {
          if (data.IsSuccess) {
            this.dropdownList.ddlOffice = data.Data;
          }
        },
        (error) => {
          this._alertService.error(error.message);
        }
      );
    }
  }

  bindUserList() {
    this._userPermissionService.GetUserListByApplicationUrl(this.UserType, this.DepartmentCode.toString(), this.office.toString()).subscribe((data) => {
      if (data.IsSuccess) {
        this.dropdownList.ddlUser = data.Data as DdlItemModel[];
      }
    }, (error) => {
      this._alertService.error(error.message);
    });
  }

  selectAll() {
    if (this.searchModel.DepartmentCode == undefined) {
      this.searchModel.DepartmentCode = []
    }
    if (this.searchModel.DepartmentCode.length != this.dropdownList.ddlDepartment.length) {
      this.searchModel.DepartmentCode = this.dropdownList.ddlDepartment.map(function (a) {
        return String(a.Value);
      });
    } else {
      this.searchModel.DepartmentCode = [];
    }
    this.DepartmentCode = this.searchModel.DepartmentCode;
    this.onDepartmentChange();
  }

  selectAlloffice() {
    if (this.searchModel.OfficeCode == undefined) {
      this.searchModel.OfficeCode = []
    }
    if (this.searchModel.OfficeCode.length != this.dropdownList.ddlOffice.length) {
      this.searchModel.OfficeCode = this.dropdownList.ddlOffice.map(function (a) {
        return a.Value;
      });
    } else {
      this.searchModel.OfficeCode = [];
    }
    this.office = this.searchModel.OfficeCode;
    this.bindUserList();
  }

  BindUserPagePermissionList() {
    if (
      !this._commonService.IsNullOrEmpty(this.Application) &&
      !this._commonService.IsNullOrEmpty(this.UserCode)
    ) {
      this._userPermissionService.GetUserPermissions(this.Application, this.UserCode)
        .subscribe(
          (data) => {
            if (data.IsSuccess) {
              this.userPagePermissionList = <UserPermissionViewModel[]>(data.Data);
              this.dataSource = new MatTableDataSource<UserPermissionViewModel>(this.userPagePermissionList);
              this.checkAllSelected();
            }
          },
          (error) => {
            this._alertService.error(error.message);
          }
        );
    } else {
      this.userPagePermissionList = [];
      this.dataSource = [];
    }
  }

  BindUserDefaultPagePermissionList() {
    if (!this._commonService.IsNullOrEmpty(this.Application) && !this._commonService.IsNullOrEmpty(this.UserCode)) {
      this._userPermissionService.GetUserDefaultPermissions(this.Application, this.UserCode)
        .subscribe((data) => {

          if (data.IsSuccess) {

            this.userPageDefaultPermissionList = <DefaultPermissionViewModel[]>data.Data;
            this.isShow = true;
            this.dataSource1 = new MatTableDataSource<DefaultPermissionViewModel>(this.userPageDefaultPermissionList);
            this.checkDefaultAllSelected();
          }
        },
          (error) => {
            this._alertService.error(error.message);
          }
        );
    } else {
      this.userPageDefaultPermissionList = [];
      this.dataSource1 = [];
    }
  }

  checkAllSelected() {
    this.IsAllSelected("Add");
    this.IsAllSelected("Edit");
    this.IsAllSelected("Delete");
    this.IsAllSelected("View");
  }

  checkDefaultAllSelected() {
    this.IsDefaultAllSelected("Add");
    this.IsDefaultAllSelected("Edit");
    this.IsDefaultAllSelected("Delete");
    this.IsDefaultAllSelected("View");
  }

  CheckUncheckAll(perType: string) {
    for (let i = 0; i < this.userPagePermissionList.length; i++) {
      if (perType === "Add") {
        this.userPagePermissionList[i].AddPermission = this.IsHeaderAddSelected;
      } else if (perType === "Edit") {
        this.userPagePermissionList[
          i
        ].EditPermission = this.IsHeaderEditSelected;
      } else if (perType === "Delete") {
        this.userPagePermissionList[
          i
        ].DeletePermission = this.IsHeaderDeleteSelected;
      } else if (perType === "View") {
        this.userPagePermissionList[
          i
        ].ViewPermission = this.IsHeaderViewSelected;
      }
    }
  }

  IsAllSelected(perType: string) {
    if (perType === "Add") {
      this.IsHeaderAddSelected = this.userPagePermissionList.every(function (
        item: any
      ) {
        return item.AddPermission === true;
      });
    } else if (perType === "Edit") {
      this.IsHeaderEditSelected = this.userPagePermissionList.every(function (
        item: any
      ) {
        return item.EditPermission === true;
      });
    } else if (perType === "Delete") {
      this.IsHeaderDeleteSelected = this.userPagePermissionList.every(function (
        item: any
      ) {
        return item.DeletePermission === true;
      });
    } else if (perType === "View") {
      this.IsHeaderViewSelected = this.userPagePermissionList.every(function (
        item: any
      ) {
        return item.ViewPermission === true;
      });
    }
  }

  IsDefaultAllSelected(perType: string) {
    if (perType === "Add") {
      this.IsDefaultHeaderAddSelected = this.userPageDefaultPermissionList.every(function (
        item: any
      ) {
        return item.AddPermission === true;
      });
    } else if (perType === "Edit") {
      this.IsDefaultHeaderEditSelected = this.userPageDefaultPermissionList.every(function (
        item: any
      ) {
        return item.EditPermission === true;
      });
    } else if (perType === "Delete") {
      this.IsDefaultHeaderDeleteSelected = this.userPageDefaultPermissionList.every(function (
        item: any
      ) {
        return item.DeletePermission === true;
      });
    } else if (perType === "View") {
      this.IsDefaultHeaderViewSelected = this.userPageDefaultPermissionList.every(function (item: any) {
        return item.ViewPermission === true;
      });
    }
  }

  SavePermissions() {
    this._userPermissionService.SaveUserPermissions(this.userPagePermissionList)
      .subscribe(
        (data) => {
          this._commonService.ScrollingTop();
          if (data) {
            if (data.IsSuccess) {
              this._alertService.success(data.Message);
              if (this._auth.GetCurrentUserDetail().UserViewModel.UserCode == this.UserCode) {



                this._auth.UpdateLoginUserPermission();

                setTimeout(() => {
                  this.appComponent.UpdateMenu();
                }, 100);
              }
            } else {
              this._alertService.error(data.Message);
            }
          } else {
            this._alertService.error(
              GlobalMessagesModel.updateUserPermissionFailed
            );
          }
        },
        (error) => {
          this._commonService.ScrollingTop();
          this._alertService.error(
            GlobalMessagesModel.updateUserPermissionError
          );
        }
      );
  }

  UserPermissionList() {
    if (this.UserCode) {
      this.BindUserPagePermissionList();
      this.BindUserDefaultPagePermissionList();
    }
  }

  onDepartmentChange() {
    this.bindUserList();
    setTimeout(() => {
      this.getOfficeList();
    }, 100);
  }

}
