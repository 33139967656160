import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { AlertService } from 'src/app/shared/service/alert.service';
import { CommonService } from 'src/app/shared/service/common.service';
import { CommunicationService } from 'src/app/shared/service/communication.service';
import { MatDialogRef } from '@angular/material/dialog';
import { AppSetting } from 'src/app/shared/model/appsetting.model';
import { DDLModel } from 'src/app/shared/model/commonddl.model';
import { CommunicationClearModel } from 'src/app/shared/model/communication.model';
import { GlobalMessagesModel } from 'src/app/shared/model/common-messages.model';
import { CommunicationInitiateByEnum, UserTypeEnum } from 'src/app/shared/enum/fixed-values.enum';

@Component({
  selector: 'app-clear-communication-pop-up',
  templateUrl: './clear-communication-pop-up.component.html',
  styleUrls: ['./clear-communication-pop-up.component.scss']
})
export class ClearCommunicationPopUpComponent implements OnInit {

  frmGrp: FormGroup;
  public ddlList = new DDLModel();
  public model: CommunicationClearModel = new CommunicationClearModel()
  userTypeEnum = UserTypeEnum;

  constructor(private readonly _alertService: AlertService,
    private _commonService: CommonService,
    private fb: FormBuilder,
    private readonly communicationService: CommunicationService,
    private readonly _router: Router,
    public dialogRef: MatDialogRef<ClearCommunicationPopUpComponent>) { }



  ngOnInit() {
    this.GetVendorList();
    this.GetDDLList();
    this.GetCommunicationList();
    this.formGroupInit();

  }

  GetVendorList() {
    this._commonService.GetAllDDL(AppSetting.VendorCommunicationPermissionDdl).subscribe(
      response => {
        if (response.IsSuccess) {
          let data = <DDLModel>response.Data;
          this.ddlList.ddlVendor = data.ddlVendor;

        }
      },
      error => {
        this._alertService.error(error.message);
      }
    );
  }

  GetDDLList() {
    this._commonService.GetAllDDL(AppSetting.VendorCommunicationPermissionDdl).subscribe(
      response => {
        if (response.IsSuccess) {
          let data = <DDLModel>response.Data;

          this.ddlList.ddlDepartment = data.ddlDepartment;


        }
      },
      error => {
        this._alertService.error(error.message);
      }
    );
  }


  GetCommunicationList() {
    this._commonService.GetAllDDL(AppSetting.VendorCommunicationPermissionDdl).subscribe(
      response => {
        if (response.IsSuccess) {
          let data = <DDLModel>response.Data;
          this.ddlList.ddlUserType = data.ddlUserType;
          this.ddlList.ddlUserType = this.ddlList.ddlUserType.filter(i => i.Value == UserTypeEnum.DREC || i.Value == UserTypeEnum.VNDR);

          this.ddlList.ddlUserType.map(item => {
            item.Text = (item.Value == this.userTypeEnum.DREC ? ("Department to Vendor") : (item.Value == this.userTypeEnum.VNDR ? "Vendor to Department" : " "));
          });

        }
      },
      error => {
        this._alertService.error(error.message);
      }
    );
  }

  formGroupInit() {
    this.frmGrp = this.fb.group({
      DepartmentCode: [this.model.DepartmentCode],
      VendorCode: [this.model.VendorCode],
      CommunicationTypeForUserTypeCode: [this.model.CommunicationTypeForUserTypeCode]

    });
  }

  public onSubmit() {

    if (this.frmGrp.valid) {
      this.communicationService.CommunicationClear(this.model).subscribe(data => {
        if (data) {
          if (data.IsSuccess) {
            this._alertService.success(GlobalMessagesModel.deleteSuccess);
            this.dialogRef.close();
          }
          else {
            this._alertService.error('Communications are being used in communication.');
          }
        }
      });
    }
  }


}
