export class WorkOrderCubCategoryModel {
    SubCategoryCode: number;
    SubCategory: string;
    SubCategoryHindi: string;
    WorkOrderCategoryCode:number| string;
    IsActive: boolean;
    IsDeleted: boolean;
    CreatedBy: number | null;
    CreatedOn: Date | null;
    ModifiedBy: number | null;
    ModifiedOn: Date | null; 
    Category:string;
    CreatedByUser: string;
    ModifiedByUser: string;
}
