import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import { AuthenticationService } from 'src/app/shared/service/authentication.service';
import { GlobalMessagesModel } from 'src/app/shared/model/common-messages.model';
import { AppComponent } from 'src/app/app.component';
import { AlertService } from 'src/app/shared/service/alert.service';
import { VendorTypeModel } from 'src/app/shared/model/master/vendor-type.model';
import { VendorTypeService } from 'src/app/shared/service/master/vendor-type.service';
import { CommonService } from 'src/app/shared/service/common.service';
import { UserViewModel } from 'src/app/shared/model/user.model';
import { isNullOrUndefined } from 'util';
import { UserTypeEnum } from 'src/app/shared/enum/fixed-values.enum';

@Component({
  selector: 'app-add-update-vendor-type',
  templateUrl: './add-update-vendor-type.component.html',
  styleUrls: ['./add-update-vendor-type.component.scss']
})

export class AddUpdateVendorTypeComponent implements OnInit {

  //#region <<Variable>>
  loginUserDetail: UserViewModel;
  fromGroup: FormGroup;
  model: VendorTypeModel;
  RecordId: number = 0;
  loginUserCode: number;
  oldVendorType: string;
  IsStorage: boolean;
  submitBtn = true;
  //#endregion

  //#region <<Constructor>>
  constructor(
    private readonly fb: FormBuilder,
    private readonly appComponnet: AppComponent,
    public readonly _commonService: CommonService,
    private readonly _alertService: AlertService,
    private readonly _router: Router,
    private _route: ActivatedRoute,
    private readonly _vendorTypeService: VendorTypeService,
    private readonly _authService: AuthenticationService
  ) {
    if (!isNullOrUndefined(this._route.snapshot.params.id)) {
      this.RecordId = this._route.snapshot.params.id;
    }
    var pageHeading = (this.RecordId == 0 ? "Add" : "Update") + " Vendor Type :";
    this.appComponnet.setPageLayout(pageHeading, "keyboard_backspace", "Back To List", "master/vendor-type");
    this.model = new VendorTypeModel();
    this.loginUserDetail = this._authService.GetCurrentUserDetail().UserViewModel;
  }
  //#endregion

  ngOnInit() {
    this.formGroupInit();
    if (this.RecordId != 0) {
      this.getDetail();
    }
  }

  formGroupInit() {
    this.fromGroup = this.fb.group({
      VendorType: [null, Validators.compose([Validators.required, Validators.maxLength(100)])],
      VendorTypeHindi: [null, Validators.compose([Validators.required, Validators.maxLength(100)])]
    });
  }

  getDetail() {
    this._vendorTypeService.Detail(this.RecordId).subscribe(
      data => {
        if (data.IsSuccess) {
          this.model = <VendorTypeModel>data.Data;
          this.oldVendorType = this.model.VendorType;
        }
      },
      error => {
        this._alertService.error(error.message);
      }
    );
  }

  saveClick() {
    this.submitBtn=false;
    this.fromGroup.markAllAsTouched();
    if (this.fromGroup.valid) {

      if (this.RecordId == 0) {
        this.model.CreatedBy = this.loginUserDetail.UserCode;
      }
      else {
        this.model.ModifiedBy = this.loginUserDetail.UserCode;
      }

      this._vendorTypeService.AddUpdate(this.RecordId, this.model).subscribe(
        data => {
          if (data) {
            if (data.IsSuccess) {
              this._alertService.success(this.RecordId == 0 ? GlobalMessagesModel.saveSuccess : GlobalMessagesModel.updateSuccess);
              this._router.navigate(["master/vendor-type"]);
            } else {
              this.submitBtn=true;
              this._commonService.ScrollingTop();
              this._alertService.error(this.RecordId == 0 ? GlobalMessagesModel.saveError : GlobalMessagesModel.updateError);
            }
          } else {
            this.submitBtn=true;
            this._commonService.ScrollingTop();
            this._alertService.error(GlobalMessagesModel.InternalError);
          }
        },
        error => {
          this.submitBtn=true;
          this._commonService.ScrollingTop();
          this._alertService.error(GlobalMessagesModel.InternalError);
        }
      );
    }else{
      this.submitBtn=true;
    }
  }

  IsVendorTypeExist(Event) {
    if (this.oldVendorType != Event.currentTarget.value) {
      this._vendorTypeService.IsVendorTypeExist(Event.currentTarget.value).subscribe(result => {

        if (result.IsSuccess) {
          let UserName = <boolean>result.Data
          if (UserName == true) {
            this.model.VendorType = null;
            this._alertService.error("Vendor Type is already exist!");
          }
        }
      }, error => {
        this._commonService.ScrollingTop();
        this._alertService.error(error.message);
      });
    }
  }

  ngOnDestroy() {
    this.IsStorage = true;
    sessionStorage.setItem("IsVendorStorage", JSON.stringify(this.IsStorage));
  }

}
