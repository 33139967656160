import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { PageAuthenticationModel } from 'src/app/shared/model/page-authentication.model';
import { AlertService } from 'src/app/shared/service/alert.service';
import { PermissionMasterService } from 'src/app/shared/service/master/permission-master.service';

@Component({
  selector: 'app-page-authentication-dialog',
  templateUrl: './page-authentication-dialog.component.html',
  styleUrls: ['./page-authentication-dialog.component.scss']
})

export class PageAuthenticationDialogComponent implements OnInit {
  frmGrp: FormGroup;
  permissionId: number;
  model: PageAuthenticationModel = new PageAuthenticationModel();

  constructor(private readonly fb: FormBuilder,
    public _dialogRef: MatDialogRef<PageAuthenticationDialogComponent>,
    private readonly _perMasterService: PermissionMasterService,
    private readonly _alertService: AlertService,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    if (data) {
      if (data.Id) {
        
        this.permissionId = data.Id;
        this.model.id = this.permissionId;
      }
    }
  }

  ngOnInit() {
    this.formGroupInit();
  }

  formGroupInit() {
    this.frmGrp = this.fb.group({
      Password: [null, Validators.required]
    });
  }

  CloseDialog(): void {
    this._dialogRef.close(false);
  }

  checkAuthenticationOld() {
    this.frmGrp.markAllAsTouched();
    if (this.frmGrp.valid) {
      let password = this.frmGrp.get("Password").value;
      this._perMasterService.CheckPageAuthentication(this.permissionId, password).subscribe(result => {
        if (result.IsSuccess) {
          let status = <boolean>result.Data;
          if (status == true) {
            this._dialogRef.close(true);
          }
          else {
            this._alertService.error("Invalid Password!");
            this._dialogRef.close(false);
          }
        }
      },
        error => { }
      );
    }
  }

  checkAuthentication() {
    this.frmGrp.markAllAsTouched();
    if (this.frmGrp.valid) {
      let password = this.frmGrp.get("Password").value;
      this.model.pagePassword = password;     
      this._perMasterService.IsPageAuthentication(this.model).subscribe(result => {
        if (result.IsSuccess) {
          let status = <boolean>result.Data;
          if (status == true) {
            this._dialogRef.close(true);
          }
          else {
            this._alertService.error("Invalid Password!");
            this._dialogRef.close(false);
          }
        }
      },
        error => { }
      );
    }
  }

}
