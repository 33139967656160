import { AuthenticationService } from 'src/app/shared/service/authentication.service';
import { environment } from 'src/environments/environment';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource, MatPaginator, MatSort, MatDialog } from '@angular/material';
import { AppComponent } from 'src/app/app.component';
import { ConfirmationDialogComponent } from 'src/app/confirmation-dialog/confirmation-dialog.component';
import { AppSetting } from 'src/app/shared/model/appsetting.model';
import { GlobalFilterModel, IndexModel, PermissionModel } from 'src/app/shared/model/common.model';
import { ColumnHeaderModel } from 'src/app/shared/model/commonddl.model';
import { NotificationEmailTemplateViewModel } from 'src/app/shared/model/master/email-template.model';
import { AlertService } from 'src/app/shared/service/alert.service';
import { CommonService } from 'src/app/shared/service/common.service';
import { EmailTemplateService } from 'src/app/shared/service/master/email-template.service';
import { GlobalMessagesModel } from 'src/app/shared/model/common-messages.model';

@Component({
  selector: 'app-notification-email-templates',
  templateUrl: './notification-email-templates.component.html',
  styleUrls: ['./notification-email-templates.component.scss']
})

export class NotificationEmailTemplatesComponent implements OnInit {
  listModel: NotificationEmailTemplateViewModel[];
  dataSource: MatTableDataSource<NotificationEmailTemplateViewModel>;
  displayedColumns: string[] = [
    'index',
    'TemplateType',
    'Subject',
    'EmailContent',
    'Action'
  ];
  // tslint:disable-next-line: max-line-length
  ViewdisplayedColumns: ColumnHeaderModel[] = [
    { Value: 'TemplateType', Text: 'Template Type' },
    { Value: 'Subject', Text: 'Subject' },
    // { Value: 'EmailContent', Text: 'Email Content' }
  ];

  columnsToDisplay: string[] = this.displayedColumns.slice();
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  indexModel = this._commonService.modelSetGet(new IndexModel) as IndexModel;
  totalRecords: number;
  Permission: PermissionModel = this._authService.GetPagePermission("/master/notification-sms-templates", "notification-sms-templates/add", "notification-sms-templates/detail", "notification-sms-templates/update", "notification-sms-templates/delete");

  constructor(
    private _parentApi: AppComponent,
    private readonly _emailService: EmailTemplateService,
    private readonly _alertService: AlertService,
    private readonly _dialog: MatDialog,
    public readonly _commonService: CommonService,
    private readonly _authService: AuthenticationService
  ) {
    this.Permission.AddPageAccess ? this._parentApi.setPageLayout('Notification Email Templates :', 'add', 'Create', 'master/notification-email-templates/add') : this._parentApi.setPageLayout('Notification Email Templates :');
   
  }

  ngOnInit() {
    this.GetList();
  }

  SortData(event) {
    this.indexModel.OrderBy = event.active;
    this.indexModel.OrderByAsc =
      event.direction === AppSetting.orderByDscAsc
        ? AppSetting.orderByAsc
        : AppSetting.orderByDsc;
    this.indexModel.IsPostBack = true;
    this.GetList();
  }

  onPaginateChange(event) {
    this.indexModel.Page = event.pageIndex + 1;
    this.indexModel.PageSize = event.pageSize;
    this.indexModel.IsPostBack = true;
    this.GetList();
  }

  GetList() {
    this._emailService.GetList(this._commonService.modelSetGet(this.indexModel, true)).subscribe(
      data => {
        if (data.IsSuccess) {
          this.listModel = <NotificationEmailTemplateViewModel[]>data.Data.Data;
          this.dataSource = new MatTableDataSource<
            NotificationEmailTemplateViewModel
          >(this.listModel);
          if (this.indexModel.IsPostBack === false) {
            this.dataSource.paginator = this.paginator;
            this.totalRecords = data.Data.TotalRecords;
            this.dataSource.sort = this.sort;
          }
        }
      },
      error => {
        this._alertService.error(error.message);
      }
    );
  }

  onDelete(id) {

    const dialogRef = this._dialog.open(ConfirmationDialogComponent, {
      width: '50%',
      data: GlobalMessagesModel.ConfirmDeleted
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this._emailService.Delete(id).subscribe(
          data => {
            if (data.IsSuccess) {
              this.GetList();
              this._alertService.success(data.Message);
            }
          },
          error => {
            this._alertService.error(error.message);
          }
        );
      }
    });
  }

  SearchByKeyword(searchValue: GlobalFilterModel) {
    this.indexModel.Search = searchValue.search;
    this.indexModel.AdvanceSearchModel = { ActiveStatus: searchValue.ActiveStatus }

    this.GetList();
  }

  ClearSearch() {
    this.indexModel = new IndexModel();
    this.GetList();
  }


}
