import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { AppComponent } from 'src/app/app.component';
import { UserTypeEnum } from 'src/app/shared/enum/fixed-values.enum';
import { GlobalMessagesModel } from 'src/app/shared/model/common-messages.model';
import { WorkOrderCategoryModel } from 'src/app/shared/model/master/work-order-category.model';
import { UserViewModel } from 'src/app/shared/model/user.model';
import { AlertService } from 'src/app/shared/service/alert.service';
import { AuthenticationService } from 'src/app/shared/service/authentication.service';
import { CommonService } from 'src/app/shared/service/common.service';
import { WorkOrderCategoryService } from 'src/app/shared/service/master/work-order-category.service';
import { isNullOrUndefined } from 'util';

@Component({
  selector: 'app-add-update-work-order-category',
  templateUrl: './add-update-work-order-category.component.html',
  styleUrls: ['./add-update-work-order-category.component.scss']
})
export class AddUpdateWorkOrderCategoryComponent implements OnInit {

  //#region <<Variable>>
  loginUserDetail: UserViewModel;
  fromGroup: FormGroup;
  model = new WorkOrderCategoryModel();
  RecordId: number = 0;
  loginUserCode: number;
  oldCategory: string;
 
  //#endregion

  constructor(private readonly fb: FormBuilder,
    private readonly _appComponent: AppComponent,
    public readonly _commonService: CommonService,
    private readonly _alertService: AlertService,
    private readonly _router: Router,
    private _route: ActivatedRoute,
    private readonly _workorderCategoryService: WorkOrderCategoryService,
    private readonly _authService: AuthenticationService
  ) {
    if (!isNullOrUndefined(this._route.snapshot.params.id)) {
      this.RecordId = this._route.snapshot.params.id;
    }
    var pageHeading = (this.RecordId == 0 ? "Add" : "Update") + " WorkOrder Category :";
    this._appComponent.setPageLayout(pageHeading, "keyboard_backspace", "Back To List", "master/work-order-category");
    this.loginUserDetail = this._authService.GetCurrentUserDetail().UserViewModel;
  }

  ngOnInit() {
    this.formGroupInit();
    if (this.RecordId != 0) {
      this.getDetail();
    }
  }

  formGroupInit() {
    this.fromGroup = this.fb.group({
      Category: [null, Validators.compose([Validators.required, Validators.maxLength(100)])],
      CategoryHindi: [null, Validators.compose([Validators.required, Validators.maxLength(100)])]
    });
  }

  getDetail() {
    this._workorderCategoryService.Detail(this.RecordId).subscribe(
      data => {
        if (data.IsSuccess) {
          this.model = <WorkOrderCategoryModel>data.Data;
        }
      },
      error => {
        this._alertService.error(error.message);
      }
    );
  }

  saveClick() {
    this.fromGroup.markAllAsTouched();
    if (this.fromGroup.valid) {

      if (this.RecordId == 0) {
        this.model.CreatedBy = this.loginUserDetail.UserCode;
      }
      else {
        this.model.ModifiedBy = this.loginUserDetail.UserCode;
      }

      this._workorderCategoryService.AddUpdate(this.RecordId, this.model).subscribe(
        data => {
          if (data) {
            if (data.IsSuccess) {
              this._alertService.success(this.RecordId == 0 ? GlobalMessagesModel.saveSuccess : GlobalMessagesModel.updateSuccess);
              this._router.navigate(["master/work-order-category"]);
            } else {
              this._commonService.ScrollingTop();
              this._alertService.error(this.RecordId == 0 ? GlobalMessagesModel.saveError : GlobalMessagesModel.updateError);
            }
          } else {
            this._commonService.ScrollingTop();
            this._alertService.error(GlobalMessagesModel.InternalError);
          }
        },
        error => {
          this._commonService.ScrollingTop();
          this._alertService.error(GlobalMessagesModel.InternalError);
        }
      );
    }
  }

  IsWorkOrderCategoryExist() {
    this._workorderCategoryService.IsWorkOrderCategoryExist(this.model.Category.trim(), this.model.CategoryCode).subscribe(result => {
      if (result.IsSuccess) {
        let UserName = <boolean>result.Data
        if (UserName == true) {
          this.model.Category = null;
          this._alertService.error("WorkOrder Category Name is already exist!");
        }
      }
    }, error => {
      this._commonService.ScrollingTop();
      this._alertService.error(error.message);
    });

  }

}
