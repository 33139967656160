import { UserService } from './../../../shared/service/user.service';
import { Component, OnInit, Inject } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { AppSetting } from "src/app/shared/model/appsetting.model";
import { DDLModel, FilterDDlModel } from "src/app/shared/model/commonddl.model";
import { GlobalMessagesModel } from "src/app/shared/model/common-messages.model";
import { VendorModel, VendorDocumentModel } from "src/app/shared/model/vendor.model";
import { CommonService } from "src/app/shared/service/common.service";
import { VendorService } from "src/app/shared/service/vendor.service";
import { AlertService } from "src/app/shared/service/alert.service";
import { SSOUserModel, UserViewModel } from "src/app/shared/model/user.model";
import { FormGroup, Validators, FormBuilder } from "@angular/forms";
import { AuthenticationService } from "src/app/shared/service/authentication.service";
import { AlertTypeEnum } from "src/app/shared/enum/fixed-values.enum";
import { AlertModel } from 'src/app/shared/model/common.model';

@Component({
  selector: "app-vendor-registration-dialog",
  templateUrl: "./vendor-registration-dialog.component.html",
  styleUrls: ["./vendor-registration-dialog.component.scss"],
  providers: [CommonService, VendorService],
})

export class VendorRegistrationDialogComponent implements OnInit {

  frmGrp: FormGroup;
  ddlList: DDLModel;
  recordId: number = 0;
  heading: string;
  oldSSOId: string;
  isUserExistOnSso: boolean = false;
  fileValidationMsg: string;
  model: VendorModel = new VendorModel();
  tempDocumentList: VendorDocumentModel[] = [];
  loginUserDetail: UserViewModel = new UserViewModel();
  isAuthenticated: boolean = false;
  filterDDlModel: any[];
  submitBtn = true;
  alertModel: AlertModel;

  constructor(
    private readonly fb: FormBuilder,
    public readonly _commonService: CommonService,
    private readonly _alertService: AlertService,
    private readonly _vendorService: VendorService,
    private readonly _authService: AuthenticationService,
    public _dialogRef: MatDialogRef<VendorRegistrationDialogComponent>,
    private readonly _route: ActivatedRoute,
    private readonly _router: Router,
    private readonly _userService: UserService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    if (data && data.Id) {
      this.recordId = data.Id;
    }

    this.GetDDLList();
    if (this._authService.GetCurrentUserDetail()) {
      this.loginUserDetail = this._authService.GetCurrentUserDetail().UserViewModel;
    } else {
      this.model.SSOId = this._authService.SSOID;
    }
    this.isAuthenticated = this._authService.IsAuthentication;

    this.heading = this.loginUserDetail.UserCode ? (this.recordId == 0 ? "Add" : "Update") + " Vendor" : "Vendor Registration";
  }

  ngOnInit() {
    this.formGroupInit();
    this.GetDetail();
  }

  formGroupInit() {
    const panRegex = '^[A-Za-z]{5}[0-9]{4}[A-Za-z]$';
    const adharRgex = '^[2-9]{1}[0-9]{3}[0-9]{4}[0-9]{4}$';
    const TanRgex = '^[2-9]{1}[0-9]{3}[0-9]{4}[0-9]{4}$';

    this.frmGrp = this.fb.group({
      CompanyName: [null],
      VendorTypeCode: [null, Validators.required],
      SSOId: [null, Validators.compose([Validators.required, Validators.maxLength(50)])],
      ContactPersonName: [null, Validators.compose([Validators.required, Validators.maxLength(100)]),],
      MobileNo1: [null, Validators.compose([Validators.required, Validators.maxLength(10)])],
      MobileNo2: [null, Validators.maxLength(12)],
      OfficeNo: [null],
      Email: [null, Validators.compose([Validators.required, Validators.email, Validators.maxLength(100),])],
      PANNo: [null],
      PanFileUploader: [null],
      TANNo: [null],
      TanFileUploader: [null],
      AadharNo: [null],
      GSTINNo: [null],
      GSTINFileUploader: [null],
      CompanyAddress: [null],
      IsAcceptTC: [null],
      StateCode: [null, Validators.required],
      DistrictCode: [null, Validators.required],
      BRNNo: [null],
      BRNFileUploader: [null]
    });
  }

  keyPresscharacter(event) {
    var inp = String.fromCharCode(event.keyCode);

    if (/[a-zA-Z.& ]/.test(inp)) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }

  AlphaNumericWithSpecial(event): boolean {
    var key = window.event ? event.keyCode : event.which;
    if (event.keyCode === 46 || event.keyCode === 38 || event.keyCode === 32) {
      return true;
    }
    else if (key >= 48 && key <= 57) {  // for numbers 
      return true;
    }
    else if (key >= 65 && key <= 90) {  // for capital letters 
      return true;
    }
    else if (key >= 97 && key <= 122) { // for small letters  
      return true;
    }
    else {
      return false;
    }
  }

  checkIsValidPhoneNumber(value): boolean {
    return this._commonService.IsValidPhoneNumber(value);
  }

  NumericWithSpecial(event): boolean {
    var key = window.event ? event.keyCode : event.which;
    if (key >= 48 && key <= 57) {
      return true;
    }
    else if (key >= 32 && key <= 46) {
      return true;
    }
    else {
      return false;
    }
  }

  keyPressAlphaNumeric(event) {
    var inp = String.fromCharCode(event.keyCode);
    if (/[a-zA-Z0-9]/.test(inp)) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }

  GetDDLList() {
    this._commonService.GetAllDDL(AppSetting.VendorDropdownKeys).subscribe((data) => {
      if (data.IsSuccess) {
        this.ddlList = <DDLModel>data.Data;
        this.model.StateCode = this.model.StateCode == undefined ? "20" : String(this.model.StateCode);
      }
    }, (error) => {
      this.alert(error.message, AlertTypeEnum.Error);
    });
  }

  GetDetail() {
    if (this.recordId > 0) {
      this._vendorService.Detail(this.recordId).subscribe((data) => {
        if (data.IsSuccess) {
          this.model = <VendorModel>data.Data;
          this.model.VendorTypeCode = String(this.model.VendorTypeCode);
          this.oldSSOId = this.model.SSOId.toLowerCase();
          this.isUserExistOnSso = true;

          if (this.model.PANNo) {
            this.model.PANNo = this._commonService.convertText(this.model.PANNo, false);
          }
          if (this.model.AadharNo) {
            this.model.AadharNo = this._commonService.convertText(this.model.AadharNo, false);
          }

          this.model.StateCode = String(this.model.StateCode);
          this.getFilteredDDL(String(this.model.DistrictCode));
          // this.model.DistrictCode = String(this.model.DistrictCode);

        }
      }, (error) => {
        this.model = new VendorModel();
        this.alert(error.message, AlertTypeEnum.Error);
      });
    }
  }

  onSubmit() {

    this.submitBtn = false;
    this.frmGrp.markAllAsTouched();

    if (this.frmGrp.valid && this.isUserExistOnSso) {
      if (this.recordId == 0) {
        this.model.CreatedBy = this.loginUserDetail.UserCode;
      } else {
        this.model.ModifiedBy = this.loginUserDetail.UserCode;
      }
      this.model.DocumentList = this.tempDocumentList;

      if (this.model.AadharNo) {
        this.frmGrp.get("AadharNo").setValidators(null);
        this.frmGrp.get("AadharNo").updateValueAndValidity();
        this.model.AadharNo = this._commonService.convertText(this.model.AadharNo);
      }

      if (this.model.PANNo) {
        this.frmGrp.get("PANNo").setValidators(null);
        this.frmGrp.get("PANNo").updateValueAndValidity();
        this.model.PANNo = this._commonService.convertText(this.model.PANNo);
      }

      this._vendorService.AddUpdate(this.recordId, this.model).subscribe((data) => {
        // this.frmGrp.get("AadharNo").setValidators(Validators.compose([Validators.required, Validators.maxLength(10), Validators.pattern('^[A-Za-z]{5}[0-9]{4}[A-Za-z]$')]));

        this.frmGrp.get("AadharNo").updateValueAndValidity();
        // this.frmGrp.get("PANNo").setValidators(Validators.compose([Validators.required, Validators.maxLength(10), Validators.pattern('^[A-Za-z]{5}[0-9]{4}[A-Za-z]$')]));
        this.frmGrp.get("PANNo").updateValueAndValidity();
        if (data.IsSuccess) {
          this.alert(this.recordId == 0 ? GlobalMessagesModel.saveSuccess : GlobalMessagesModel.updateSuccess, AlertTypeEnum.Success);
          this._dialogRef.close(true);
        }
        else {
          this.submitBtn = true;
          this.alert(this.recordId == 0 ? GlobalMessagesModel.saveError : GlobalMessagesModel.updateError, AlertTypeEnum.Error);
        }
      }, (error) => {
        this.submitBtn = true;
        // this.frmGrp.get("AadharNo").setValidators(Validators.compose([Validators.required, Validators.maxLength(10), Validators.pattern('^[A-Za-z]{5}[0-9]{4}[A-Za-z]$')]));
        this.frmGrp.get("AadharNo").updateValueAndValidity();
        //  this.frmGrp.get("PANNo").setValidators(Validators.compose([Validators.required, Validators.maxLength(10), Validators.pattern('^[A-Za-z]{5}[0-9]{4}[A-Za-z]$')]));
        this.frmGrp.get("PANNo").updateValueAndValidity();
        this.alert(error.message, AlertTypeEnum.Error);
      });
    }
    else if (!this.isUserExistOnSso) {
      this.submitBtn = true;
      this.alert(this.model.SSOId + " SSO ID does not exist on SSO Server!", AlertTypeEnum.Error);
    }
    else {
      this.submitBtn = true;
    }
  }

  checkSSOId() {
    if (this.model.SSOId.toLowerCase() != this.oldSSOId) {
      this.clearSSOFields();
      this._vendorService.IsSSOIdExist(this.model.SSOId).subscribe((result) => {
        if (result.IsSuccess) {
          let isExist = <boolean>result.Data;
          if (isExist == true) {
            this.model.SSOId = this.oldSSOId;
            if (this.oldSSOId) { this.isUserExistOnSso = true; }

            this._commonService.ScrollingTop();
            this.alert('SSO ID already exist!', AlertTypeEnum.Error);
          }
          else {
            this.getUserDetailFromSSO(this.model.SSOId);
          }
        }
      }, (error) => {
        this._commonService.ScrollingTop();
        this.alert(error.message, AlertTypeEnum.Error);
      });
    }
  }

  getUserDetailFromSSO(ssoId: string) {
    this._userService.GetUserDetailFromSSO(ssoId).subscribe((result) => {
      this._alertService.blank();
      if (result.IsSuccess) {
        let ssoDetail = <SSOUserModel>result.Data;

        this.model.UserName = (ssoDetail.FirstName || ssoDetail.LastName) ? ((ssoDetail.FirstName ? ssoDetail.FirstName + " " : ssoId) + ssoDetail.LastName ? ssoDetail.LastName : ssoId) : ssoDetail.DisplayName ? ssoDetail.DisplayName : ssoId;
        this.model.Email = ssoDetail.MailPersonal ? ssoDetail.MailPersonal : undefined;
        this.model.MobileNo1 = ssoDetail.Mobile ? ssoDetail.Mobile : undefined;
        this.model.StateCode = ssoDetail.StateCode ? String(ssoDetail.StateCode) : undefined;
        this.model.DistrictCode = ssoDetail.DistrictCode ? String(ssoDetail.DistrictCode) : undefined;
        this.model.ContactPersonName = this.model.UserName;
        this.isUserExistOnSso = true;

        if (ssoId.toLowerCase() != ssoDetail.SSOID.toLowerCase()) {
          this.model.SSOId = ssoDetail.SSOID;
          this.checkSSOId();
        }
      } else {
        this._commonService.ScrollingTop();
        this.alert(ssoId + " SSO ID Not Found!", AlertTypeEnum.Error);
      }
    }, (error) => {
      this._commonService.ScrollingTop();
      this.alert(GlobalMessagesModel.InternalError, AlertTypeEnum.Error);
    });
  }

  clearSSOFields() {
    this.model.UserName = undefined;
    this.model.Email = undefined;
    this.model.MobileNo1 = undefined;
    this.model.ContactPersonName = undefined;
    this.model.StateCode = undefined;
    this.model.DistrictCode = undefined;
    this.isUserExistOnSso = false;
  }

  handleFileInput(files: FileList) {
    this.tempDocumentList = [];
    for (let index = 0; index < files.length; index++) {
      if (files.item(index).type.match("application/pdf")) {
        if (files.item(index).size < AppSetting.FileSizeLimit.PDF) {
          const reader = new FileReader();
          reader.onload = (e: any) => {
            this.tempDocumentList.push(new VendorDocumentModel());
            this.tempDocumentList[index].DocName = <string>reader.result;
            this.tempDocumentList[index].DocPath = <string>reader.result;

            this.tempDocumentList[index].DocName = files[index].name;
            this.tempDocumentList[index].Extension = files[index].name.split(".")[1];
            this.tempDocumentList[index].DocPath = <string>reader.result;
          };
          reader.readAsDataURL(files[index]);
        } else {
          this.tempDocumentList = [];
          this.fileValidationMsg = AppSetting.FileSizeLimit.PDFErrorMSG;
          return;
        }
      } else {
        this.tempDocumentList = [];
        this.fileValidationMsg = "Only *pdf";
        return;
      }
    }
    this.fileValidationMsg = "";
  }

  closeDialog(): void {
    this._dialogRef.close();
  }

  getFilteredDDL(defaultDistrict: string) {
    const item = new FilterDDlModel();
    item.FilterFor = "ddlDistrictbyState";
    this.model.StateCode = this.model.StateCode == undefined ? "20" : String(this.model.StateCode);
    item.Value = this.model.StateCode;
    item.FilterFrom = "ddlState";
    this.filterDDlModel = [];
    this.filterDDlModel.push(item);

    this._commonService.GetFilterdDDL(this.filterDDlModel).subscribe(data => {
      if (data.IsSuccess) {
        if (item.FilterFor == "ddlDistrictbyState" || data.Data.ddlDistrictbyState) {
          this.model.DistrictCode = this.model.DistrictCode != undefined ? this.model.DistrictCode : defaultDistrict;
          this.ddlList.ddlDistrictbyState = data.Data.ddlDistrictbyState;
        }
      }
    }, error => {
    });
  }

  backToLogin() {
    this._authService.LogOut();
    window.location.reload();
  }

  handleDocFileInput(event: any, fileType: string = '') {
    if (event.target.files.length > 0 && event.target.files.item(0).type.match('image/*')) {
      if (event.target.files.item(0).size > AppSetting.FileSizeLimit.IMG) {
        this._commonService.ScrollingTop();
      }
      else {
        let reader = new FileReader();
        reader.onload = (event: any) => {
          if (fileType == 'PAN') {
            this.model.PANFileURL = event.target.result;
          }
          else if (fileType == 'TAN') {
            this.model.TANFileURL = event.target.result;
          }
          else if (fileType == 'GSTIN') {
            this.model.GISTINFileURL = event.target.result;
          }
          else if (fileType == 'BRN') {
            this.model.BRNFileURL = event.target.result;
          }
        }

        if (fileType == 'PAN') {
          this.model.PANFileName = event.target.files.item(0).name;
        }
        else if (fileType == 'TAN') {
          this.model.TANFileName = event.target.files.item(0).name;
        }
        else if (fileType == 'GSTIN') {
          this.model.GSTINFileName = event.target.files.item(0).name;
        }
        else if (fileType == 'BRN') {
          this.model.BRNFileName = event.target.files.item(0).name;
        }

        reader.readAsDataURL(event.target.files.item(0));
      }
    }
    else {
      if (fileType == 'PAN') {
        this.model.PANFileName = undefined;
      }
      else if (fileType == 'TAN') {
        this.model.TANFileName = undefined;
      }
      else if (fileType == 'GSTIN') {
        this.model.GSTINFileName = undefined;
      }
      else if (fileType == 'BRN') {
        this.model.BRNFileName = undefined;
      }
    }
  }

  removeDocFileInput(fileType = '') {
    if (fileType == 'PAN') {
      this.model.PANFileName = undefined;
      this.model.PANFileURL = undefined;
    }
    else if (fileType == 'TAN') {
      this.model.TANFileName = undefined;
      this.model.TANFileURL = undefined;
    }
    else if (fileType == 'GSTIN') {
      this.model.GSTINFileName = undefined;
      this.model.GISTINFileURL = undefined;
    }
    else if (fileType == 'BRN') {
      this.model.BRNFileName = undefined;
      this.model.BRNFileURL = undefined;
    }
  }

  setRequiredBRNFileUploader() {
    const BRNFileUploader = this.frmGrp.get('BRNFileUploader')
    if (this.model.BRNNo && this.model.BRNNo.trim().length > 0) {
      BRNFileUploader.setValidators(Validators.required);
    }
    else {
      BRNFileUploader.setValidators(null);
    }
    BRNFileUploader.updateValueAndValidity()
  }

  alert(message: string, type: string) {
    this.alertModel = new AlertModel();
    this.alertModel.Message = message;
    this.alertModel.Type = type;
    setTimeout(() => {
      this.alertModel = new AlertModel();
    }, 5000);
  }

}