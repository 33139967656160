import { GlobalMessagesModel } from 'src/app/shared/model/common-messages.model';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatSort, MatDialog, MatTableDataSource } from '@angular/material';
import { Router } from '@angular/router';
import { AppComponent } from 'src/app/app.component';
import { ConfirmationDialogComponent } from 'src/app/confirmation-dialog/confirmation-dialog.component';
import { AppSetting } from 'src/app/shared/model/appsetting.model';
import { PermissionModel, IndexModel, GlobalFilterModel } from 'src/app/shared/model/common.model';
import { ColumnHeaderModel } from 'src/app/shared/model/commonddl.model';
import { GeneralEntryCategoryMasterModel } from 'src/app/shared/model/master/general-entry-category-master.model';
import { AlertService } from 'src/app/shared/service/alert.service';
import { CommonService } from 'src/app/shared/service/common.service';
import { AddUpdateGeneralEntryCategoryMasterComponent } from './add-update-general-entry-category-master/add-update-general-entry-category-master.component';
import { GeneralEntryCategoryMasterService } from 'src/app/shared/service/master/general-entry-category-master.service';
import { AuthenticationService } from 'src/app/shared/service/authentication.service';

@Component({
  selector: 'app-general-entry-category-master',
  templateUrl: './general-entry-category-master.component.html',
  styleUrls: ['./general-entry-category-master.component.scss']
})
export class GeneralEntryCategoryMasterComponent implements OnInit {

  //#region Variable
  model: GeneralEntryCategoryMasterModel[];
  dataSource: any;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  id: number;
  displayedColumns: string[] = ['index', 'CategoryCode', 'Title', 'TitleHindi', 'IsVisible', 'CategoryIsVisible', 'IsVisibleToEndUser', "ClassificationPageTypeName", "MenuClassificationName","ModifiedDate",  'IsActive', 'Action'];
  ViewdisplayedColumns: ColumnHeaderModel[] = [{ Value: 'Title', Text: 'Title' }, { Value: 'TitleHindi', Text: 'Title Hindi' }];
  columnsToDisplay: string[] = this.displayedColumns.slice();
  Permission: PermissionModel = this._authService.GetPagePermission("master/general-entry-category", "master/general-entry-category/add", "master/general-entry-category/detail", "master/general-entry-category/update", "master/general-entry-category/delete");
  indexModel = this._commonService.modelSetGet(new IndexModel()) as IndexModel;
  totalRecords: number;
  //#endregion

  //#region constructor
  constructor(private readonly _alertService: AlertService,
    private readonly _parentApi: AppComponent,
    private readonly _dialog: MatDialog,
    public readonly _commonService: CommonService,
    private readonly _router: Router,
    private readonly _authService: AuthenticationService,
    private readonly _CategoryService: GeneralEntryCategoryMasterService) {

    this.Permission.AddPageAccess
      ? this._parentApi.setPageLayout(
        "General Entry Category:", "add", "Create", "/master/general-entry-category/add"
      )
      : this._parentApi.setPageLayout("General Entry Category :");


  }
  //#endregion

  //#region  Method

  ngOnInit() {
    this.GetList();
  }

  GetList() {
    
    this._CategoryService.GetList(this._commonService.modelSetGet(this.indexModel, true)).subscribe(
      data => {

        if (
          (data.IsSuccess)
        ) {
          this.model = <GeneralEntryCategoryMasterModel[]>data.Data.Data;
          this.dataSource = new MatTableDataSource<GeneralEntryCategoryMasterModel>(this.model);
          this.totalRecords = data.Data.TotalRecords;
          //   if (!this.indexModel.IsPostBack) {
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
          //   }
        }
      },
      error => {
        this._alertService.error(error.message);
      }
    );
  }


  OpenDialog(Id) {

    const _dialogRef = this._dialog.open(AddUpdateGeneralEntryCategoryMasterComponent, {
      width: "1200px",
      data: Id,
      disableClose: true
    });
    _dialogRef.afterClosed().subscribe((result: boolean) => {

      if (result) {
        this.GetList();
      }
    });
  }

  //  OnActiveStatus(id) {
  //    
  //    this._commonService.GenerateOTP().subscribe(
  //      data => {
  //        if (data.IsSuccess) {

  //          const _dialogRef = this._dialog.open(OTPDialogComponent, {
  //            width: "500px",
  //            disableClose:true
  //          });
  //          _dialogRef.afterClosed().subscribe((result: boolean) => {

  //            if (result) {

  //              this._CategoryService.ChangeActiveStatus(id).subscribe(
  //                data => {
  //                  if (data.IsSuccess) {
  //                    this.GetList();
  //                    this._alertService.success(data.Message);
  //                  } else {
  //                    this._alertService.error(data.Message);
  //                  }
  //                },
  //                error => {
  //                  this._alertService.error(error.message);
  //                }
  //              );

  //            }
  //          });
  //        }else{
  //          this._alertService.error(data.Message);
  //        }
  //      },
  //      error => {
  //        this._alertService.error(error.message);
  //      }
  //    );
  //  }


  OnActiveStatus(Id) {

    const dialogRef = this._dialog.open(ConfirmationDialogComponent, {
      width: '50%',
      data: GlobalMessagesModel.ConfirmStatusChanged,
      disableClose: true
    });
    dialogRef.afterClosed().subscribe(result => {

      if (result) {
        this._CategoryService.ChangeActiveStatus(Id).subscribe(
          data => {

            if (
              (data.IsSuccess)
            ) {
              this.GetList();
              this._alertService.success(data.Message);

            }
          },
          error => {
            this._commonService.ScrollingTop();
            this._alertService.error(error.message);
          }
        );
      }
    });

  }

  sortData(event) {
    this.indexModel.OrderBy = event.active;
    this.indexModel.OrderByAsc =
      event.direction == AppSetting.orderByDscAsc
        ? AppSetting.orderByAsc
        : AppSetting.orderByDsc;
    this.indexModel.IsPostBack = true;
    this.GetList();
  }

  onPaginateChange(event) {
    this.indexModel.Page = event.pageIndex + 1;
    this.indexModel.PageSize = event.pageSize;
    this.indexModel.IsPostBack = true;
    this.GetList();
  }

  SearchByKeyword(searchValue: GlobalFilterModel) {
    this.indexModel.Search = searchValue.search;
    this.indexModel.AdvanceSearchModel = { ActiveStatus: searchValue.ActiveStatus }
    this.GetList();
  }

  ClearSearch() {
    this.indexModel = new IndexModel();
    this.GetList();
  }

  PagePrint(printContent) {
    this._commonService.printHTMLContent(printContent, "General Entry Category Master");
  }
  //#endregion

}
