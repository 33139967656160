import { Injectable } from '@angular/core';
import { BaseService } from '../base.service';
import { AppSetting } from '../../model/appsetting.model';
import { IndexModel } from '../../model/common.model';
import { ConcernModel } from '../../model/master/concern.model';

@Injectable({providedIn:'root'})

export class ConcernService {
    constructor(private readonly _baseService: BaseService, ) { }

    GetList(model: IndexModel) {
        return this._baseService.post(AppSetting.ConcernListUrl, model);
    }

    AddUpdate(id: number, model: ConcernModel) {
        return this._baseService.post(AppSetting.ConcernAddUpdateUrl, model);
    }

    Detail(id: number) {
         return this._baseService.get(AppSetting.ConcernDetailUrl + id);
    }

    ChangeDeleteStatus(id: number) {
        return this._baseService.get(AppSetting.ConcernDeleteStatusChangeUrl + id);
    }

    ChangeActiveStatus(id: number) {
        return this._baseService.get(AppSetting.ConcernActiveStatusChangeUrl + id);
    }

    IsConcernExist(concernName: string){
        return this._baseService.get(AppSetting.IsConcernExistUrl + concernName);
    }

    GetConcernPDFDownload(model: IndexModel) {
        return this._baseService.post(AppSetting.ConcernPDFDownloadUrl, model);
    }
}
