export class CommunicationTypeFieldsMasterModel {
    CommunicationTypeFieldsCode: number;
    CommunicationTypeCode: number;
    FieldLabel: string;
    FieldTypeCode: number | string;
    ExistingMasterCode: number | string;
    ExistingMasterName: string;
    IsRequired: boolean;
    IsDisabledForEdit: boolean;
    IsDeleted: boolean;
    ToolTip: string;
    CreatedBy: number | null;
    CreatedDate: Date | null;
    ModifiedBy: number | null;
    ModifiedDate: Date | null;
    CommunicationType: string;
    FieldType: string;
    TableName: string;
    ValueMember: string;
    DisplayMember: string;
    DisplayOrder: number | string;
    CSSClassCode: number | string;
    CSSDisplayText: string;
    CSSClass: string;
    IsHidden: boolean;
    ValidationsList: CommunicationTypeFieldValidationPostModel[] = [];

}

export class CommunicationFieldValueViewModel {
    CommunicationTypeFieldsCode: number;
    CommunicationTypeCode: number;
    FieldLabel: string;
    FieldTypeCode: number | string;
    ExistingMasterCode: number | string;
    IsRequired: boolean;
    IsDisabledForEdit: boolean;
    IsDeleted: boolean;
    ToolTip: string;
    CreatedBy: number | null;
    CreatedDate: Date | null;
    ModifiedBy: number | null;
    ModifiedDate: Date | null;
    CommunicationType: string;
    FieldType: string;
    TableName: string;
    ValueMember: string;
    DisplayMember: string;
    FieldValue: string;
    CSSClassCode: number | null;
    IsHidden: boolean;
    CSSDisplayText: string;
    CSSClass: string;
    FieldTextValue: string;
    ValidationsList: CommunicationTypeFieldValidationViewModel[] = [];
}
export class CommunicationTypeDefaultFieldsMasterModel {
    CommunicationDefaultFieldCode: number;
    CommunicationTypeCode: number | string;
    CommunicationType: string;
    FieldCode: number;
    FieldName: string;
    IsDefaultVisibleOnScreen: boolean;
    SortOrder: number | string;
    IsRequired: boolean;

}


export class CommunicationDefaultFieldPostModel {
    CommunicationTypeCode: number;
    FieldCode: number;
    SortOrder: number;
    CreateBy: number;
    ModifiedBy: number | null;

}

export class CommunicationDefaultFieldConfigPostModel {
    CommunicationTypeCode: number | string;
    FieldCode: number;
    SortOrder: number | string;
    FieldLabel: string;
    CreateBy: number;
    ModifiedBy: number | null;
    IsRequired: boolean;
}




export class DefaultFieldVisibleModel {
    constructor() {
        this.IsShowDepartmentField = false;

        this.IsShowDistrictField = false;

        this.IsShowOfficeField = false;

        this.IsShowProjectField = false;

        this.IsShowWorkOrderField = false;

        this.IsShowVendorBankField = false;

        this.IsShowVendorField = false;

        this.IsShowAttachmentsField = false;

        this.IsShowSubjectField = false;

        this.IsShowDescriptionField = false;

        this.IsShowAmountField = false;

        this.IsShowPreviouseCommunicationField = false;

        this.IsShowCreatedByOfficeField = false;



    }

    IsShowDepartmentField: boolean;
    DepartmentSortOrder: number;
    DepartmentLabel: string;
    IsRequiredDepartmentField: boolean;

    IsShowDistrictField: boolean;
    DistrictSortOrder: number;
    DistrictLabel: string;
    IsRequiredDistrictField: boolean;

    IsShowOfficeField: boolean;
    OfficeSortOrder: number;
    OfficeLabel: string;
    IsRequiredOfficeField: boolean;

    IsShowProjectField: boolean;
    ProjectSortOrder: number;
    ProjectLabel: string;
    IsRequiredProjectField: boolean;

    IsShowWorkOrderField: boolean;
    WorkOrderSortOrder: number;
    WorkOrderLabel: string;
    IsRequiredWorkOrderField: boolean;

    IsShowVendorBankField: boolean;
    VendorBankSortOrder: number;
    VendorBankLabel: string;
    IsRequiredVendorBankField: boolean;

    IsShowVendorField: boolean;
    VendorSortOrder: number;
    VendorLabel: string;
    IsRequiredVendorField: boolean;

    IsShowAttachmentsField: boolean;
    AttachmentsSortOrder: number;
    AttachmentsLabel: string;
    IsRequiredAttachmentsField: boolean;

    IsShowSubjectField: boolean;
    SubjectSortOrder: number;
    SubjectLabel: string;
    IsRequiredSubjectField: boolean;

    IsShowDescriptionField: boolean;
    DescriptionSortOrder: number;
    DescriptionLabel: string;
    IsRequiredDescriptionField: boolean;

    IsShowAmountField: boolean;
    AmountSortOrder: number;
    AmountLabel: string;
    IsRequiredAmountField: boolean;

    IsShowPreviouseCommunicationField: Boolean;
    PreviouseCommunicationSortOrder: number;
    PreviouseCommunicationLabel: string;
    IsRequiredPreviouseCommunicationField: Boolean;

    IsShowCreatedByOfficeField: Boolean;
    CreatedByOfficeSortOrder: number;
    CreatedByOfficeLabel: string;
    IsRequiredCreatedByOfficeField: Boolean;


}


export class CommunicationTypeFieldValidationViewModel {
    code: number;
    FieldCode: number;
    Value: string | any;
    DependentValue: string | any;
    ValidationMasterRuleCode: number;
    ValidationTitle: string;
    DefaultValidationMessage: string;
    ValidationDescription: string;
    ValidationMessage: string;
    FieldType: string;
    FieldTypeCode: number;
    FieldTitle: string;
    IsRegex: boolean;
    IsPreviousValue: boolean;
    IsForwardValue: boolean;
    RegexValue: string;
    IsCustomRegex: boolean;
    IsLength: boolean;
    IsRange: boolean;
    IsActive: boolean;
    IsDelete: boolean;
    CreatedOn: string;
    CreatedBy: number;
    ModifiedOn: string;
    ModifiedBy: number;
    IsSelected: boolean;
}



export class CommunicationTypeFieldValidationPostModel {
    code: number;
    FieldCode: number;
    ValidationRuleMasterCode: number;
    Value: string;
    DependentValue: string;
    ValidationMessage: string;
    IsActive: boolean;


}

export class DefaultFieldSortOrderPostModel {
    SortOrder: number | string;
    CommunicationTypeCode: number;
    FieldCode: number;
}