import { Component, OnInit } from "@angular/core";
import { MatDialogRef } from "@angular/material";
import { SafeResourceUrl, DomSanitizer } from "@angular/platform-browser";
import { Router } from "@angular/router";
import { UserManualViewModel } from "src/app/shared/model/master/user-manual.model";
import { AuthenticationService } from "src/app/shared/service/authentication.service";
import { CommonService } from "src/app/shared/service/common.service";
import { UserManualService } from "src/app/shared/service/master/user-manual.service";

@Component({
  selector: 'app-dashboard-help-document',
  templateUrl: './dashboard-help-document.component.html',
  styleUrls: ['./dashboard-help-document.component.scss']
})
export class DashboardHelpDocumentComponent implements OnInit {
  //#region

  helpDocument: UserManualViewModel = new UserManualViewModel();
  PDFFile: SafeResourceUrl;
  constructor(
    private readonly _commonService: CommonService,
    public sanitizer: DomSanitizer,
    private _router: Router,
    public _dialogRef: MatDialogRef<DashboardHelpDocumentComponent>,
    private readonly _userManualService: UserManualService,
    public readonly _authenticationService: AuthenticationService,
  ) {
  }

  ngOnInit() {
    this.GetHelpDocument();
  }

  GetHelpDocument() {
    this._userManualService.GetHelpDocument(this._authenticationService.LoggedInUserDetail.UserViewModel.UserTypeCode).subscribe(
      data => {
        if (data.IsSuccess) {
          this.helpDocument = <UserManualViewModel>data.Data;
          if (this.helpDocument && this.helpDocument != null && (this.helpDocument.UserManualUrl != undefined && this.helpDocument.UserManualUrl != null)) {
            this.PDFFile = this.sanitizer.bypassSecurityTrustResourceUrl(this.helpDocument.UserManualUrl);
          }
          else {
            this._dialogRef.close();
          }
        } else {
          this._dialogRef.close();
        }
      },
      error => {
        this._dialogRef.close();
        console.warn(error.message);
      }
    );
  }

  onNoClick(): void {
    this._dialogRef.close();
  }


}
