import { Component, OnInit } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { DynamicFieldConfig } from "src/app/shared/model/dynamic-field-config.model";
@Component({
  selector: "app-radiobutton",
  template: `


<div [ngClass]="[field.CSSClass?field.CSSClass:'col l4 xl4 m4 s12']" [formGroup]="group">
			<label class="lable">{{field.label}}</label>
			<mat-radio-group (focus)="this.group.get(this.field.name).markAsTouched()" class="border-radio-line"  [formControlName]="field.name" [(ngModel)]="field.value" matTooltip="{{field.ToolTip}}"  matTooltipPosition="above">
				<mat-radio-button *ngFor="let item of field.options" [value]="item.Value">{{item.Text}}</mat-radio-button>
			      </mat-radio-group>
            <ng-container *ngFor="let validation of field.validations;let indx=index" ngProjectAs="mat-error">
<mat-error class="dynamic-feild-error" *ngIf="group.get(field.name).hasError(validation.name) && group.get(field.name).touched">{{validation.message}}

</mat-error>


</ng-container>

		</div>`,
  styles: []
})
export class RadiobuttonComponent implements OnInit {
  field: DynamicFieldConfig;
  group: FormGroup;
  fields: DynamicFieldConfig[];

  constructor() { }
  ngOnInit() { }
}
