import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { IndexModel } from '../model/common.model';
import { AppSetting } from '../model/appsetting.model';
import { SuggestionCommunicationModel } from '../model/suggestion-communication.model';

@Injectable({
    providedIn: 'root'
})

export class SuggestionCommunicationService {

    constructor(private readonly _baseService: BaseService) { }

    GetList(loginUserCode: number, search: string, status: string, model: IndexModel) {
        return this._baseService.post(AppSetting.SuggestionCommunicationListUrl + "?loginUserCode=" + loginUserCode + "&search=" + search + "&status=" + status, model);
    }

    AddUpdate(model: SuggestionCommunicationModel) {
        return this._baseService.post(AppSetting.SuggestionCommunicationAddUpdateUrl, model);
    }

    Detail(id: number) {
        return this._baseService.get(AppSetting.SuggestionCommunicationDetailUrl + id);
    }

}