import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { VendorTypeModel } from 'src/app/shared/model/master/vendor-type.model';
import { AppComponent } from 'src/app/app.component';
import { VendorTypeService } from 'src/app/shared/service/master/vendor-type.service';
import { AlertService } from 'src/app/shared/service/alert.service';

@Component({
  selector: 'app-vendor-type-detail',
  templateUrl: './vendor-type-detail.component.html',
  styleUrls: ['./vendor-type-detail.component.scss']
})

export class VendorTypeDetailComponent implements OnInit {
  model: VendorTypeModel;
  RecordId: number;
  IsStorage:boolean;
  constructor(
    private readonly _appComponent: AppComponent,
    private readonly _router: Router,
    private _route: ActivatedRoute,
    private readonly _vendorTypeService: VendorTypeService,
    private readonly _alertService: AlertService
  ) {
    this.RecordId = this._route.snapshot.params.id;
    this.model = new VendorTypeModel();
    this._appComponent.setPageLayout(
      "Vendor Type Detail :",
      "keyboard_backspace",
      "Back To List",
      "master/vendor-type"
    );
  }

  ngOnInit() {
    this.getDetail();
  }

  getDetail() {
    this._vendorTypeService.Detail(this.RecordId).subscribe(
      data => {
        if (data.IsSuccess) {
          this.model = <VendorTypeModel>data.Data;
        }
      },
      error => {
        this._alertService.error(error.message);
      }
    );
  }

  ngOnDestroy() {
   this.IsStorage = true;
   sessionStorage.setItem("IsVendorStorage", JSON.stringify(this.IsStorage));
  }

}
