import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { IndexModel } from '../model/common.model';
import { AppSetting } from '../model/appsetting.model';
import { GeneralCommunicationModel } from '../model/general-communication.model';

@Injectable({
    providedIn: 'root'
})

export class GeneralCommunicationService {

    constructor(private readonly _baseService: BaseService) { }

    GetList(loginUserCode: number, search: string, status: string, model: IndexModel) {
        return this._baseService.post(AppSetting.GeneralCommunicationListUrl + "?loginUserCode=" + loginUserCode + "&search=" + search + "&status=" + status, model);
    }

    AddUpdate(model: GeneralCommunicationModel) {
        return this._baseService.post(AppSetting.GeneralCommunicationAddUpdateUrl, model);
    }

    Detail(id: number) {
        return this._baseService.get(AppSetting.GeneralCommunicationDetailUrl + id);
    }

}