import { Component, HostListener, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-print-helper',
  templateUrl: './print-helper.component.html',
  styleUrls: ['./print-helper.component.scss']
})
export class PrintHelperComponent implements OnInit {
  Content: any;
  Heading: any;
  isPageReady = false;
  printWindow = window;

  constructor() {
    this.Heading = sessionStorage.getItem("PrintContentPageTitle");
    this.Content = sessionStorage.getItem("PrintContent");

  }

  ngOnInit() {
    this.printWindow.print();
    sessionStorage.removeItem("PrintContentPageTitle");
    sessionStorage.removeItem("PrintContent");
    setTimeout(() => {
      this.isPageReady = true;
    }, 1000);

  }

  @HostListener('window:afterprint', ['$event'])
  close(event) {

    sessionStorage.removeItem("PrintContentPageTitle");
    sessionStorage.removeItem("PrintContent");
    this.printWindow.close();
    
  }


  @HostListener('mousemove', ['$event'])
  onMousemove(event: MouseEvent) {
    if (this.isPageReady) {
      sessionStorage.removeItem("PrintContentPageTitle");
      sessionStorage.removeItem("PrintContent");
      this.printWindow.close();
    }
  }


}
