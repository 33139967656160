import { BankAccountModel } from './bank-account.model';

export class VendorModel {
    VendorCode: number;
    UserCode: number | null;
    VendorTypeCode: number | string;
    SSOId: string;
    ContactPersonName: string;
    CompanyName: string;
    CompanyAddress: string;
    MobileNo1: string;
    MobileNo2: string;
    OfficeNo: string;
    Email: string;
    PANNo: string;
    TANNo: string;
    AadharNo: string;
    GSTINNo: string;
    IsActive: boolean;
    IsDeleted: boolean;
    CreatedBy: number | null;
    CreatedOn: Date | null;
    ModifiedBy: number | null;
    ModifiedOn: Date | null;
    IsAcceptTC: boolean;
    IsVerified: boolean;
    BankAccountList: BankAccountModel[];
    RegistrationDate: string;
    VendorType: string;
    VendorTypeHindi: string;
    CompanyDetail: string;
    CompanyMobileNos: string;
    DocumentList: VendorDocumentModel[] = [];
    UserName: string;
    StateCode: number | string;
    DistrictCode: number | string;
    State: string;
    DistrictName: string;
    PANFileURL : string;
    PANFileName: string;
    TANFileURL : string;
    TANFileName: string;
    GISTINFileURL : string;
    GSTINFileName: string;
    BRNNo: string;
    BRNFileURL : string;
    BRNFileName: string;
    

}

export class VendorDocumentModel {
    DocumentCode: number;
    VendorCode: number;
    DocName: string;
    DocPath: string;
    Extension: string;
}