import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from "@angular/router";
import { AppComponent } from 'src/app/app.component';
import { AlertService } from 'src/app/shared/service/alert.service';
import { UserTypeEnum } from 'src/app/shared/enum/fixed-values.enum';
import { AuthenticationService } from 'src/app/shared/service/authentication.service';

import { SuggestionForModel } from 'src/app/shared/model/master/suggestion-for.model';
import { SuggestionForService } from 'src/app/shared/service/master/suggestion-for.service';

@Component({
  selector: 'app-suggestion-for-detail',
  templateUrl: './suggestion-for-detail.component.html',
  styleUrls: ['./suggestion-for-detail.component.scss']
})
export class SuggestionForDetailComponent implements OnInit {

  model: SuggestionForModel;
  RecordId: number;
  IsStorage:boolean;
  constructor(
    private readonly _appComponent: AppComponent,
    private readonly _router: Router,
    private _route: ActivatedRoute,
    private readonly _suggestionForService: SuggestionForService,
    private readonly _alertService: AlertService
  ) {
    this.RecordId = this._route.snapshot.params.id;
    this.model = new SuggestionForModel();
    this._appComponent.setPageLayout(
      "Suggestion For Detail :",
      "keyboard_backspace",
      "Back To List",
      "master/suggestion-for"
    );
  }

  ngOnInit() {
    this.getDetail();
  }

  getDetail() {
    this._suggestionForService.Detail(this.RecordId).subscribe(
      data => {
        if (data.IsSuccess) {
          this.model = <SuggestionForModel>data.Data;
        }
      },
      error => {
        this._alertService.error(error.message);
      }
    );
  }

  ngOnDestroy() {
    this.IsStorage = true;
    sessionStorage.setItem("IsSuggestionForStorage", JSON.stringify(this.IsStorage));
   }
}
