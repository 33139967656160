import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import { AuthenticationService } from 'src/app/shared/service/authentication.service';
import { GlobalMessagesModel } from 'src/app/shared/model/common-messages.model';
import { AppComponent } from 'src/app/app.component';
import { AlertService } from 'src/app/shared/service/alert.service';
import { CommonService } from 'src/app/shared/service/common.service';
import { UserViewModel } from 'src/app/shared/model/user.model';
import { isNullOrUndefined } from 'util';
import { UserTypeEnum } from 'src/app/shared/enum/fixed-values.enum';
import { SuggestionForModel } from 'src/app/shared/model/master/suggestion-for.model';
import { SuggestionForService } from 'src/app/shared/service/master/suggestion-for.service';

@Component({
  selector: 'app-add-update-suggestion-for',
  templateUrl: './add-update-suggestion-for.component.html',
  styleUrls: ['./add-update-suggestion-for.component.scss']
})
export class AddUpdateSuggestionForComponent implements OnInit {

  //#region <<Variable>>
  loginUserDetail: UserViewModel;
  fromGroup: FormGroup;
  model: SuggestionForModel;
  RecordId: number = 0;
  loginUserCode: number;
  oldSuggestion: string;
  IsStorage:boolean;
  //#endregion

  //#region <<Constructor>>
  constructor(
    private readonly fb: FormBuilder,
    private readonly appComponnet: AppComponent,
    public readonly _commonService: CommonService,
    private readonly _alertService: AlertService,
    private readonly _router: Router,
    private _route: ActivatedRoute,
    private readonly _suggestionForService: SuggestionForService,
    private readonly _authService: AuthenticationService
  ) {
    if (!isNullOrUndefined(this._route.snapshot.params.id)) {
      this.RecordId = this._route.snapshot.params.id;
    }
    var pageHeading = (this.RecordId == 0 ? "Add" : "Update") + " Suggestion For :";
    this.appComponnet.setPageLayout(pageHeading, "keyboard_backspace", "Back To List", "master/suggestion-for");
    this.model = new SuggestionForModel();
    this.loginUserDetail = this._authService.GetCurrentUserDetail().UserViewModel;
  }
  //#endregion

  ngOnInit() {
    this.formGroupInit();
    if (this.RecordId != 0) {
      this.getDetail();
    }
  }

  formGroupInit() {
    this.fromGroup = this.fb.group({
      Suggestion: [null, Validators.compose([Validators.required, Validators.maxLength(100)])],
      SuggestionHindi: [null, Validators.compose([Validators.required, Validators.maxLength(100)])]
    });
  }

  getDetail() {
    this._suggestionForService.Detail(this.RecordId).subscribe(
      data => {
        if (data.IsSuccess) {
          this.model = <SuggestionForModel>data.Data;
          this.oldSuggestion = this.model.Suggestion;
        }
      },
      error => {
        this._alertService.error(error.message);
      }
    );
  }

  saveClick() {
    this.fromGroup.markAllAsTouched();
    if (this.fromGroup.valid) {

      if (this.RecordId == 0) {
        this.model.CreatedBy = this.loginUserDetail.UserCode;
      }
      else {
        this.model.ModifiedBy = this.loginUserDetail.UserCode;
      }

      this._suggestionForService.AddUpdate(this.RecordId, this.model).subscribe(
        data => {
          if (data) {
            if (data.IsSuccess) {
              this._alertService.success(this.RecordId == 0 ? GlobalMessagesModel.saveSuccess : GlobalMessagesModel.updateSuccess);
              this._router.navigate(["master/suggestion-for"]);
            } else {
              this._commonService.ScrollingTop();
              this._alertService.error(this.RecordId == 0 ? GlobalMessagesModel.saveError : GlobalMessagesModel.updateError);
            }
          } else {
            this._commonService.ScrollingTop();
            this._alertService.error(GlobalMessagesModel.InternalError);
          }
        },
        error => {
          this._commonService.ScrollingTop();
          this._alertService.error(GlobalMessagesModel.InternalError);
        }
      );
    }
  }

  IsSuggestionForExist(Event) {
    if (this.oldSuggestion != Event.currentTarget.value) {
      this._suggestionForService.IsSuggestionExist(Event.currentTarget.value).subscribe(result => {

        if (result.IsSuccess) {
          let UserName = <boolean>result.Data
          if (UserName == true) {
            this.model.Suggestion = null;
            this._alertService.error("Suggestion For is already exist!");
          }
        }
      }, error => {
        this._commonService.ScrollingTop();
        this._alertService.error(error.message);
      });
    }
  }

  ngOnDestroy() {
    this.IsStorage = true;
    sessionStorage.setItem("IsSuggestionForStorage", JSON.stringify(this.IsStorage));
   }

}
