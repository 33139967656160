import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { AppComponent } from 'src/app/app.component';
import { UserTypeEnum } from 'src/app/shared/enum/fixed-values.enum';
import { GlobalMessagesModel } from 'src/app/shared/model/common-messages.model';
import { ProjectCategoryModel } from 'src/app/shared/model/master/project-category.model';
import { AlertService } from 'src/app/shared/service/alert.service';
import { AuthenticationService } from 'src/app/shared/service/authentication.service';
import { CommonService } from 'src/app/shared/service/common.service';
import { ProjectCategoryService } from 'src/app/shared/service/master/project-category.service';
import { isNullOrUndefined } from 'util';
import { UserViewModel } from 'src/app/shared/model/user.model';

@Component({
  selector: 'app-add-update-project-category',
  templateUrl: './add-update-project-category.component.html',
  styleUrls: ['./add-update-project-category.component.scss']
})

export class AddUpdateProjectCategoryComponent implements OnInit {
  //#region <<Variable>>
  loginUserDetail: UserViewModel;
  fromGroup: FormGroup;
  model = new ProjectCategoryModel();
  RecordId: number = 0;
  loginUserCode: number;
  oldCategory: string;
  IsStorage: boolean;
  submitBtn = true;
  //#endregion

  //#region <<Constructor>>
  constructor(private readonly fb: FormBuilder,
    private readonly _appComponent: AppComponent,
    public readonly _commonService: CommonService,
    private readonly _alertService: AlertService,
    private readonly _router: Router,
    private _route: ActivatedRoute,
    private readonly _projectCategoryService: ProjectCategoryService,
    private readonly _authService: AuthenticationService
  ) {
    if (!isNullOrUndefined(this._route.snapshot.params.id)) {
      this.RecordId = this._route.snapshot.params.id;
    }
    var pageHeading = (this.RecordId == 0 ? "Add" : "Update") + " Project Category :";
    this._appComponent.setPageLayout(pageHeading, "keyboard_backspace", "Back To List", "master/project-category");
    this.loginUserDetail = this._authService.GetCurrentUserDetail().UserViewModel;
  }
  //#endregion

  ngOnInit() {
    this.formGroupInit();
    if (this.RecordId != 0) {
      this.getDetail();
    }
  }

  formGroupInit() {
    this.fromGroup = this.fb.group({
      Category: [null, Validators.compose([Validators.required, Validators.maxLength(100)])],
      CategoryHindi: [null, Validators.compose([Validators.required, Validators.maxLength(100)])]
    });
  }

  getDetail() {
    this._projectCategoryService.Detail(this.RecordId).subscribe(
      data => {
        if (data.IsSuccess) {
          this.model = <ProjectCategoryModel>data.Data;
        }
      },
      error => {
        this._alertService.error(error.message);
      }
    );
  }

  saveClick() {
    
    this.submitBtn=false;
    this.fromGroup.markAllAsTouched();
    if (this.fromGroup.valid) {

      if (this.RecordId == 0) {
        this.model.CreatedBy = this.loginUserDetail.UserCode;
      }
      else {
        this.model.ModifiedBy = this.loginUserDetail.UserCode;
      }

      this._projectCategoryService.AddUpdate(this.RecordId, this.model).subscribe(
        data => {
          if (data) {
            
            if (data.IsSuccess) {
              this._alertService.success(this.RecordId == 0 ? GlobalMessagesModel.saveSuccess : GlobalMessagesModel.updateSuccess);
              this._router.navigate(["master/project-category"]);
            } else {
              this.submitBtn=true;
              this._commonService.ScrollingTop();
              this._alertService.error(this.RecordId == 0 ? GlobalMessagesModel.saveError : GlobalMessagesModel.updateError);
            }
          } else {
            this.submitBtn=true;
            this._commonService.ScrollingTop();
            this._alertService.error(GlobalMessagesModel.InternalError);
          }
        },
        error => {
          this.submitBtn=true;
          this._commonService.ScrollingTop();
          this._alertService.error(error.error.ResponseMessage);
        }
      );
    }
    else{
      this.submitBtn=true;
    }
  }

  IsProjectCategoryExist() {

    this._projectCategoryService.IsProjectCategoryExist(this.model.Category.trim(), this.model.CategoryCode).subscribe(result => {
      if (result.IsSuccess) {
        let UserName = <boolean>result.Data
        if (UserName == true) {
          this.model.Category = null;
          this._alertService.error("Project Category Name is already exist!");
        }
      }
    }, error => {
      this._commonService.ScrollingTop();
      this._alertService.error(error.message);
    });

  }



}
