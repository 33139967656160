import { Injectable } from '@angular/core';
import { AppSetting } from '../../model/appsetting.model';
import { IndexModel } from '../../model/common.model';
import { Dictionary } from '../../model/dictionary.model';
import { WorkOrderCategoryModel } from '../../model/master/work-order-category.model';
import { BaseService } from '../base.service';

@Injectable({
    providedIn: 'root'
})
export class WorkOrderCategoryService {

    constructor(private readonly _baseService: BaseService,) { }

    GetList(model: IndexModel) {
        return this._baseService.post(AppSetting.WorkOrderCategoryListUrl, model);
    }

    AddUpdate(id: number, model: WorkOrderCategoryModel) {
        return this._baseService.post(AppSetting.WorkOrderCategoryAddUpdateUrl, model);
    }

    Detail(id: number) {
        return this._baseService.get(AppSetting.WorkOrderCategoryDetailUrl + id);
    }

    ChangeDeleteStatus(id: number) {
        return this._baseService.get(AppSetting.WorkOrderCategoryDeleteStatusChangeUrl + id);
    }

    ChangeActiveStatus(id: number) {
        return this._baseService.get(AppSetting.WorkOrderCategoryActiveStatusChangeUrl + id);
    }

    // IsWorkOrderCategoryExist(name: string){
    //     return this._baseService.get(AppSetting.IsWorkOrderCategoryExistUrl + name);
    // }

    IsWorkOrderCategoryExist(categoryName: string, id: number) {
        const param = new Dictionary<any>();
        param.Add("categoryName", categoryName);
        param.Add("id", id);

        return this._baseService.get(AppSetting.IsWorkOrderCategoryExistUrl, param);
    }

    GetWorkOrderCategoryPDFDownload(model: IndexModel) {
        return this._baseService.post(AppSetting.WorkOrderCategoryPDFDownloadUrl, model);
    }
}
