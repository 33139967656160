import { GlobalMessagesModel } from './../shared/model/common-messages.model';
import { Component, OnInit } from "@angular/core";
import { LoginModel } from "../shared/model/login.model";
import { environment } from "src/environments/environment";
import { AuthenticationService } from "../shared/service/authentication.service";
import { LoggedInUserDetailViewModel } from "../shared/model/user.model";
import { isNullOrUndefined } from "util";
import { AppSetting } from "../shared/model/appsetting.model";
import { BaseService } from "../shared/service/base.service";
import { VendorRegistrationDialogComponent } from "../content/vendor/vendor-registration-dialog/vendor-registration-dialog.component";
import { MatDialog } from "@angular/material";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { SelfRegisterDialogComponent } from '../content/user-creation/self-register-dialog/self-register-dialog.component';
import { AdminContactDialogComponent } from '../content/admin-contact-dialog/admin-contact-dialog.component';
import { CommonService } from '../shared/service/common.service';
import { Router } from '@angular/router';

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})

export class LoginComponent implements OnInit {

  private timer;
  private currentUrl: string = "";
  private readonly otpResendTimeInSecs = 30;

  model = new LoginModel();
  SsoId = new FormControl("", Validators.required);
  Password = new FormControl("", Validators.required);
  form: FormGroup = new FormGroup({});
  IsAuth: boolean = false;
  public isShowLogin: boolean = false;
  public isShowOTPFeild: boolean = false;
  public isShowLoginButton: boolean = false;
  public isShowOTPButton: boolean = true;
  public isShowTimeLeft: boolean = false;
  public isShowResendOTPButton: boolean = false;
  public timeLeft: number = this.otpResendTimeInSecs;

  constructor(
    public readonly _auth: AuthenticationService,
    private readonly _router: Router,
    private readonly _baseService: BaseService,
    private readonly _dialog: MatDialog,
    private readonly _commonService: CommonService,
    private readonly _fb: FormBuilder
  ) {
    this.initializeForm();
  }

  ngOnInit() {
    this.currentUrl = this._router.url;

    if (this.currentUrl.includes("emitra")) {
      this._router.navigate(["login/emitra"]);
    }

    this.IsAuth = this._commonService.GetLocalStorage("IsAuthentication") && this._commonService.GetLocalStorage("IsAuthentication") == "true" ? true : false;
    if (this.IsAuth || this._auth.IsAuthentication) {
      this.IsAuth = true;
      this._router.navigate(["dashboard"]);
    }
    else if (environment.SSOPortalLogin && (this._auth.IsAuthentication == undefined || this._auth.IsAuthentication == false)) {
      this.model.SsoId = this._commonService.GetLocalStorage("SSOID");
      this.LoginClick();
    }
    // else if (environment.LoginByPass && (this._auth.IsAuthentication == undefined || this._auth.IsAuthentication == false)) {
    //   this.model.SsoId = 'Cmisnewtest1'
    //   this.LoginClick();
    // }
    else {
      this.isShowLogin = true;
    }
    //this.openVendorRegistrationDialog(0);
    //this.openSelfRegisterPopup();
    //this.openAdminContactDialog(458); //80 
  }

  get IsEmitraLogin() {
    return this.currentUrl.includes("emitra");
  }

  get Id() {
    return this.form.get('id');
  }

  get OTP() {
    return this.form.get('otp');
  }


  LoginClick() {
    this.IsAuth = false;
    // if (this.model.Password === environment.Password || environment.SSOPortalLogin || environment.LoginByPass) 
    if (this.model.Password === environment.Password || environment.SSOPortalLogin) {
      this._auth.SSOID = this.model.SsoId;

      let serv = this._baseService.get(AppSetting.LogInUrl + this.model.SsoId).subscribe((data) => {
        serv.unsubscribe();
        if (data.IsSuccess) {
          this.IsAuth = true;
          this._auth.IsAuthentication = true;
          this._auth.LoggedInUserDetail = <LoggedInUserDetailViewModel>(data.Data);
          this._auth.SSOID = this._auth.LoggedInUserDetail.UserViewModel.SSOID;
          this._auth.UserCode = this._auth.LoggedInUserDetail.UserViewModel.UserCode;
          this._auth.UserType = this._auth.LoggedInUserDetail.UserViewModel.UserType;
          this._auth.BaseUserType = this._auth.LoggedInUserDetail.UserViewModel.BaseUserType;

          this._commonService.SetLocalStorage("SessionTime", new Date().toString())


          // alert("UserDetails:::" + JSON.stringify(this._auth.LoggedInUserDetail));
          // alert("deprtment code :: "+this._auth.LoggedInUserDetail.UserViewModel.DepartmentCode)
          // alert("deprtment codes :: "+this._auth.LoggedInUserDetail.UserViewModel.DepartmentCodes)
          // if (this.IsSessionOut()) {
          //   this.BackToSSO();
          // }
          this._commonService.SetLocalStorage(
            "UserDetails",
            JSON.stringify(this._auth.LoggedInUserDetail)
          );
          this._commonService.SetLocalStorage(
            "IsAuthentication",
            String(this._auth.IsAuthentication)
          );
          this._commonService.SetLocalStorage("IsLogin", String(this._auth.IsAuthentication));
          this._commonService.SetLocalStorage(
            "Token",
            "AuthCMO " + this._auth.LoggedInUserDetail.Token
          );
          this._commonService.SetLocalStorage(
            "UserCode",
            this._auth.LoggedInUserDetail.UserViewModel.UserCode.toString()
          );
          this._commonService.SetLocalStorage(
            "UserTypeCode",
            this._auth.LoggedInUserDetail.UserViewModel.UserTypeCode.toString()
          );
          this._commonService.SetLocalStorage(
            "UserType",
            this._auth.LoggedInUserDetail.UserViewModel.UserType
          );
          this._commonService.SetLocalStorage(
            "BaseUserType",
            this._auth.LoggedInUserDetail.UserViewModel.BaseUserType
          );
          this._commonService.SetLocalStorage(
            "SSOID",
            this._auth.LoggedInUserDetail.UserViewModel.SSOID
          );
          this._commonService.SetLocalStorage(
            "Name",
            this._auth.LoggedInUserDetail.UserViewModel.Name
          );
          if (
            isNullOrUndefined(
              this._auth.LoggedInUserDetail.UserViewModel.ProfilePic
            )
          ) {
            this._auth.LoggedInUserDetail.UserViewModel.ProfilePic =
              AppSetting.DefaultProfilePic;
          }
          this._commonService.RemoveLocalStorage("IsGlobalSearch");
          this._commonService.RemoveLocalStorage("GlobalCommunicationFilterSearch");

          this._router.navigate(['']);
          setTimeout(() => {
            //   window.location.href = environment.ApplicationBaseUrl;
          }, 0);
        }
        else if (data.StatusCode === 401) {
          if (environment.SSOPortalLogin) {
            this._auth.LogOut();

          }

          this._auth.IsAuthentication = false;
        }
        else if (data.Data != null && data.Data.UserViewModel != null && data.Data.UserViewModel.IsActive == false) {
          this._auth.IsAuthentication = false;
          alert('User is Deactivated. Please contact administrator!!');
        }
        else {
          this._auth.IsAuthentication = false;
          // this.BackToSSO();

          let userData = <LoggedInUserDetailViewModel>(data.Data);
          if (userData.SSOUserType) {
            if (userData.SSOUserType.toLowerCase() == 'govt')
              this.openSelfRegisterPopup();
            else
              this.openVendorRegistrationDialog(0);
          }
          else {
            alert('Invalid SSO ID !!!');
          }

        }
      }, (error) => {
        this._auth.IsAuthentication = false;
      });
    }
    else {
      alert("Invalid Password!");
    }
  }

  openSelfRegisterPopup() {
    const _dialogRef = this._dialog.open(SelfRegisterDialogComponent, {
      width: "750px",
      height: "550px",
      data: { ssoid: this.model.SsoId },
      disableClose: true,
    });
    _dialogRef.afterClosed().subscribe((res: any) => {
      if (res && res.officeCode) {
        this.openAdminContactDialog(res.officeCode);
      }
    });
  }

  openAdminContactDialog(officeCode: number) {
    const _dialogRef = this._dialog.open(AdminContactDialogComponent, {
      width: "600px",
      height: "350px",
      data: { id: officeCode },
      disableClose: true,
    });
    _dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        if (environment.SSOPortalLogin)
          this._auth.BackToSSO();
        else
          this.isShowLogin = true;
      }
    });
  }

  openVendorRegistrationDialog(Id) {
    const _dialogRef = this._dialog.open(VendorRegistrationDialogComponent, {
      width: "100%",
      height: "100%",
      data: { Id },
      disableClose: true,
    });
    _dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        if (result == true) {
          alert(GlobalMessagesModel.RegistrationSuccess);
        }

        if (environment.SSOPortalLogin)
          this._auth.BackToSSO();
        else
          this.isShowLogin = true;

      }
    });
  }

  public getOtpForEmitraLogin() {
    this.Id.markAsTouched();
    if (this.Id.errors != null && this.Id.errors.required) {
      return;
    }

    let id = this.Id.value;

    this._baseService.post(AppSetting.EmitraLoginOTP, { SSOId: id }).subscribe(response => {
      if (response.IsSuccess == false) {
        alert(response.Message);
        return;
      }

      this.startTimer();
      this.isShowOTPButton = false;
      this.isShowLoginButton = true;
      this.isShowResendOTPButton = false;
      this.isShowOTPFeild = true;
      this.isShowTimeLeft = true;
    });
  }

  public loginEmitra() {
    this.Id.markAsTouched();
    this.OTP.markAsTouched();

    if (this.form.invalid) {
      return;
    }

    let ssoId = this.Id.value;
    let otp = this.OTP.value;

    this._baseService.post(AppSetting.EmitraLoginByOTP, { SSOId: ssoId, OTP: otp }).subscribe(response => {

      if (response.IsSuccess) {
        this._baseService.get(AppSetting.LogInUrl + response.Data).subscribe(data => {
          this.IsAuth = false;
          this._auth.SSOID = this.model.SsoId;

          if (data.IsSuccess) {
            this.IsAuth = true;
            this._auth.IsAuthentication = true;
            this._auth.LoggedInUserDetail = <LoggedInUserDetailViewModel>(data.Data);
            this._auth.SSOID = this._auth.LoggedInUserDetail.UserViewModel.SSOID;
            this._auth.UserCode = this._auth.LoggedInUserDetail.UserViewModel.UserCode;
            this._auth.UserType = this._auth.LoggedInUserDetail.UserViewModel.UserType;
            this._auth.BaseUserType = this._auth.LoggedInUserDetail.UserViewModel.BaseUserType;

            this._commonService.SetLocalStorage("SessionTime", new Date().toString())


            // alert("UserDetails:::" + JSON.stringify(this._auth.LoggedInUserDetail));
            // alert("deprtment code :: "+this._auth.LoggedInUserDetail.UserViewModel.DepartmentCode)
            // alert("deprtment codes :: "+this._auth.LoggedInUserDetail.UserViewModel.DepartmentCodes)
            // if (this.IsSessionOut()) {
            //   this.BackToSSO();
            // }
            this._commonService.SetLocalStorage(
              "UserDetails",
              JSON.stringify(this._auth.LoggedInUserDetail)
            );
            this._commonService.SetLocalStorage(
              "IsAuthentication",
              String(this._auth.IsAuthentication)
            );
            this._commonService.SetLocalStorage("IsLogin", String(this._auth.IsAuthentication));
            this._commonService.SetLocalStorage(
              "Token",
              "AuthCMO " + this._auth.LoggedInUserDetail.Token
            );
            this._commonService.SetLocalStorage(
              "UserCode",
              this._auth.LoggedInUserDetail.UserViewModel.UserCode.toString()
            );
            this._commonService.SetLocalStorage(
              "UserTypeCode",
              this._auth.LoggedInUserDetail.UserViewModel.UserTypeCode.toString()
            );
            this._commonService.SetLocalStorage(
              "UserType",
              this._auth.LoggedInUserDetail.UserViewModel.UserType
            );
            this._commonService.SetLocalStorage(
              "BaseUserType",
              this._auth.LoggedInUserDetail.UserViewModel.BaseUserType
            );
            this._commonService.SetLocalStorage(
              "SSOID",
              this._auth.LoggedInUserDetail.UserViewModel.SSOID
            );
            this._commonService.SetLocalStorage(
              "Name",
              this._auth.LoggedInUserDetail.UserViewModel.Name
            );
            if (
              isNullOrUndefined(
                this._auth.LoggedInUserDetail.UserViewModel.ProfilePic
              )
            ) {
              this._auth.LoggedInUserDetail.UserViewModel.ProfilePic =
                AppSetting.DefaultProfilePic;
            }
            this._commonService.RemoveLocalStorage("IsGlobalSearch");
            this._commonService.RemoveLocalStorage("GlobalCommunicationFilterSearch");

            this._router.navigate(['dashboard']);

          }
          else if (data.StatusCode === 401) {
            if (environment.SSOPortalLogin) {
              this._auth.LogOut();
            }
            this._auth.IsAuthentication = false;
          }
          else if (data.Data != null && data.Data.UserViewModel != null && data.Data.UserViewModel.IsActive == false) {
            this._auth.IsAuthentication = false;
            alert('User is Deactivated. Please contact administrator!!');
          }
          else {
            this._auth.IsAuthentication = false;

            let userData = <LoggedInUserDetailViewModel>(data.Data);

            if (userData.SSOUserType) {
              if (userData.SSOUserType.toLowerCase() == 'govt')
                this.openSelfRegisterPopup();
              else
                this.openVendorRegistrationDialog(0);
            }
            else {
              alert('Invalid SSO ID !!!');
            }
          }
        }, (error) => {
          this._auth.IsAuthentication = false;
        });
      }
      else {
        alert(response.Message);
      }
    });
  }


  private initializeForm() {
    this.form = this._fb.group({
      id: ['', Validators.required],
      otp: ['', Validators.required]
    });
  }

  private startTimer() {
    this.timer = setInterval(() => {
      if (this.timeLeft > 0) {
        this.timeLeft--;
      } else {
        this.stopTimer();
        this.timeLeft = this.otpResendTimeInSecs;
        this.isShowResendOTPButton = true;
        this.isShowTimeLeft = false;
      }
    }, 1000)
  }

  private stopTimer() {
    clearInterval(this.timer);
  }
}
