export class NotificationEmailTemplatesModel {
    Id?: number;
    TypeCode: string;
    Subject: string;
    EmailContent: string;
}

export class NotificationEmailTemplateViewModel {
    Id: number;
    Subject: string;
    EmailContent: string;
    TemplateType: string;

}