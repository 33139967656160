export class UserTypeModel {
  UserTypeCode: number;
  UserType: string;
  UserTypeTitle: string;
  DisplayUserTypeTitle: string;
  BaseUserTypeCode: number | string;
  BaseUserType: string;
  BaseUserTypeTitle: string;
  ParrentUserType: string;
  IsActive: boolean;
  IsDelete: boolean;
}

export class UserTypeViewModel {
  UserTypeCode: number;
  UserTypeTitle: string;
  UserType: string;
  ParrentUserType: string;
  ParrentUserTypeTitle
  IsActive: boolean;
  IsDelete: boolean;
  BaseUserTypeCode: number | string;
  BaseUserType: string;
  BaseUserTypeTitle: string;
}
