import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from "@angular/router";
import { AppComponent } from 'src/app/app.component';
import { AlertService } from 'src/app/shared/service/alert.service';
import { UserTypeEnum } from 'src/app/shared/enum/fixed-values.enum';
import { AuthenticationService } from 'src/app/shared/service/authentication.service';
import { ConcernModel } from 'src/app/shared/model/master/concern.model';
import { ConcernService } from 'src/app/shared/service/master/concern.service';

@Component({
  selector: 'app-concern-detail',
  templateUrl: './concern-detail.component.html',
  styleUrls: ['./concern-detail.component.scss']
})
export class ConcernDetailComponent implements OnInit {
  model: ConcernModel;
  RecordId: number;
  IsStorage:boolean;
  constructor(
    private readonly _appComponent: AppComponent,
    private readonly _router: Router,
    private _route: ActivatedRoute,
    private readonly _concernService: ConcernService,
    private readonly _alertService: AlertService
  ) {
    this.RecordId = this._route.snapshot.params.id;
    this.model = new ConcernModel();
    this._appComponent.setPageLayout(
      "Concern Detail :",
      "keyboard_backspace",
      "Back To List",
      "master/concern"
    );
  }

  ngOnInit() {
    this.getDetail();
  }

  getDetail() {
    this._concernService.Detail(this.RecordId).subscribe(
      data => {
        if (data.IsSuccess) {
          this.model = <ConcernModel>data.Data;
        }
      },
      error => {
        this._alertService.error(error.message);
      }
    );
  }

  ngOnDestroy() {
    this.IsStorage = true;
    sessionStorage.setItem("IsConcernStorage", JSON.stringify(this.IsStorage));
   }

}
