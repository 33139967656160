import { IndexModel } from "../common.model";

export class OfficeMasterFilterModel extends IndexModel {
    
    public OfficeName: string = "";
    
    public OfficeNameHindi: string = "";

    public AdminDepartmentCode: string = "0";

    public DepartmentCode: string = "0";

    public DistrictCode: string = "0";

    public ParentOfficeCode: string = "0";

    public IsActive: boolean = true;
}