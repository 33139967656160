import { Injectable } from '@angular/core';
import { BaseService } from '../base.service';
import { AppSetting } from '../../model/appsetting.model';
import { IndexModel } from '../../model/common.model';
import { CommunicationAttachmentTypeModel } from '../../model/master/communication-attachment-type.model';

@Injectable({
    providedIn: 'root'
})

export class CommunicationAttachmentTypeService {

    constructor(private readonly _baseService: BaseService,) { }

    GetList(model: IndexModel) {
        return this._baseService.post(AppSetting.CommunicationAttachmentTypeListUrl, model);
    }

    AddUpdate(id: number, model: CommunicationAttachmentTypeModel) {
        return this._baseService.post(AppSetting.CommunicationAttachmentTypeAddUpdateUrl, model);
    }

    Detail(id: number) {
        return this._baseService.get(AppSetting.CommunicationAttachmentTypeDetailUrl + id);
    }

    ChangeDeleteStatus(id: number) {
        return this._baseService.get(AppSetting.CommunicationAttachmentTypeDeleteStatusChangeUrl + id);
    }

    ChangeActiveStatus(id: number) {
        return this._baseService.get(AppSetting.CommunicationAttachmentTypeActiveStatusChangeUrl + id);
    }

    IsCommunicationAttachmentTypeExist(vendorType: string) {
        return this._baseService.get(AppSetting.IsCommunicationAttachmentTypeExistUrl + vendorType);
    }

    GetCommunicationAttachmentTypePDFDownload(model: IndexModel) {
        return this._baseService.post(AppSetting.CommunicationAttachmentTypePDFDownloadUrl, model);
    }



}