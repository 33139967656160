import { SelectionModel } from '@angular/cdk/collections';
import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { AppSetting } from 'src/app/shared/model/appsetting.model';
import { GlobalMessagesModel } from 'src/app/shared/model/common-messages.model';
import { DDLModel, DdlItemModel } from 'src/app/shared/model/commonddl.model';
import { OfficeAddRequestModel } from 'src/app/shared/model/preferred-list/office-add-request.model';
import { OfficeFilterModel } from 'src/app/shared/model/preferred-list/office-filter.model';
import { OfficeListModel } from 'src/app/shared/model/preferred-list/office-list.model';
import { AlertService } from 'src/app/shared/service/alert.service';
import { CommonService } from 'src/app/shared/service/common.service';
import { OfficeListService } from 'src/app/shared/service/Preferred-List/office-list.service';
import { UserService } from 'src/app/shared/service/user.service';

@Component({
  selector: 'app-office-list',
  templateUrl: './office-list.component.html',
  styleUrls: ['./office-list.component.scss'],
  providers: [OfficeListService]
})
export class OfficeListComponent implements OnInit, AfterViewInit {
  @Input() selectedTab: any;
  public filterModel: OfficeFilterModel = new OfficeFilterModel();
  public modelList: OfficeListModel[] = [];
  public dataSource: any;
  public selectOffices = new SelectionModel<OfficeListModel>(true, []);
  public displayedColumns: string[] = ['selection', 'postedOffice', 'officeNameHindi', 'emailId', 'mobile', 'landlineNo', 'ipNo', 'wardNumber'];
  public model: OfficeAddRequestModel = new OfficeAddRequestModel();
  public ddlList: DDLModel;
  public viewModel = {
    offices: [] as OfficeListModel[]
  }


  constructor(
    private readonly _officeService: OfficeListService,
    private readonly _alertService: AlertService,
    private readonly _userService: UserService,
    private readonly _commonService: CommonService,
    public _dialogRef: MatDialogRef<OfficeListComponent>
  ) { }

  ngOnInit(): void {

  }
  ngAfterViewInit() {
    this.getDDLList();
  }

  get LoginUserCode() {
    return 0;
  }

  public onAdminDepartmentChange() {
    this.GetDepartment();
  }


  public onSearch() {
    let requestModel = this.getRequestModel();

    this.getList(requestModel);
  }

  public onClearSearch() {
    this.filterModel = new OfficeFilterModel();
    this.viewModel.offices = [];
  }
  public getList(model: OfficeFilterModel) {
    this._officeService.GetList(model).subscribe((data) => {
      if (data.IsSuccess) {
        this.modelList = <OfficeListModel[]>data.Data;
        this.dataSource = this.modelList;
      }
    }, (error) => {
      this._alertService.error(error.message);
    });
  }

  public onSubmit() {
    this.model.OfficeIds = this.selectOffices.selected.map(x => x.OfficeId);

    this._officeService.AddAllOffice(this.model).subscribe(
      response => {
        if (response.IsSuccess == true) {
          this._alertService.success(GlobalMessagesModel.saveSuccess, true);
          this._dialogRef.close();
        }
      },
      error => {
        this._alertService.error(GlobalMessagesModel.updateError);
      }
    )
  }

  private GetDepartment() {
    this.ddlList.ddlOfficeDepartment = [];

    this._userService.loginUserCode = this.LoginUserCode;
    let adminDeptCodes = this.filterModel.AdminDepartmentCode.toString();

    this._userService.GetUserDepartmentForUserOfficeCreation(adminDeptCodes, true).subscribe(data => {
      if (data.IsSuccess) {
        this.ddlList.ddlOfficeDepartment = <DdlItemModel[]>data.Data;
      }
    }, error => {
      this._alertService.error(error.message);
    });
  }

  private getRequestModel(): OfficeFilterModel {
    let output = new OfficeFilterModel();

    output.AdminDepartmentCode = Number(this.filterModel.AdminDepartmentCode);
    output.DepartmentId = Number(this.filterModel.DepartmentId);
    output.OfficeName = this.filterModel.OfficeName;


    return output;
  }

  private getDDLList() {
    this._commonService.GetAllDDL(AppSetting.DDlKeyForAllOffice).subscribe((data) => {
      if (data.IsSuccess) {
        this.ddlList = <DDLModel>data.Data;
      }
    }, (error) => {
      this._alertService.error(error.message);
    });
  }
}
