import { Component, OnInit, Inject } from "@angular/core";
import { FormGroup, FormBuilder, FormControl, Validators, AbstractControl } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { ActivatedRoute } from "@angular/router";
import { UserTypeEnum, StateEnum, AlertTypeEnum } from "src/app/shared/enum/fixed-values.enum";
import { AppSetting } from "src/app/shared/model/appsetting.model";
import { GlobalMessagesModel } from "src/app/shared/model/common-messages.model";
import { AlertModel } from "src/app/shared/model/common.model";
import { DDLModel, DdlItemModel } from "src/app/shared/model/commonddl.model";
import { UserViewModel, SSOUserModel, userCreationConfigModel } from "src/app/shared/model/user.model";
import { AlertService } from "src/app/shared/service/alert.service";
import { AuthenticationService } from "src/app/shared/service/authentication.service";
import { CommonService } from "src/app/shared/service/common.service";
import { UserService } from "src/app/shared/service/user.service";
import { isNullOrUndefined } from "util";

@Component({
  selector: 'app-add-update-vendor-user-dialog',
  templateUrl: './add-update-vendor-user-dialog.component.html',
  styleUrls: ['./add-update-vendor-user-dialog.component.scss']
})

export class AddUpdateVendorUserDialogComponent implements OnInit {

  loginUserDetail: UserViewModel;
  frmGrp: FormGroup;
  ddlList = new DDLModel();
  ddlUserType: DdlItemModel[];
  ddlOfficeDepartment: DdlItemModel[];
  ddlDistrict: DdlItemModel[];
  ddlDepartmentOffice: DdlItemModel[];
  ddlDepartmentOfficeHavingStaff:DdlItemModel[];
  model: UserViewModel = new UserViewModel();
  ssoUserDetail: SSOUserModel;
  recordId: number = 0;
  heading: string;
  oldSSOId: string;
  alertModel: AlertModel;
  loginUserCode: number;
  loginBaseUserType: string;
  selectedAll = -1;
  selectedStateAll = -1;
  userTypeEnum = UserTypeEnum;
  fieldConfig = new userCreationConfigModel();
  submitBtn: boolean = true;
  isUserExistOnSso = false;

  constructor(private readonly fb: FormBuilder,
    public readonly _commonService: CommonService,
    private readonly _alertService: AlertService,
    private readonly _userService: UserService,
    private readonly _authService: AuthenticationService,
    public _dialogRef: MatDialogRef<AddUpdateVendorUserDialogComponent>,
    private readonly _route: ActivatedRoute,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    if (data && data!.Id) {
      this.recordId = data.Id;
    }

    this.loginUserDetail = this._authService!.LoggedInUserDetail!.UserViewModel;
    this.loginBaseUserType = this._authService!.BaseUserType;
    this.GetDDLList();
    //this.getUserCategory();
    //this.bindDepartmentDropdown();
    this.model.UserType = this.userTypeEnum.VNDRUSER;
    this.heading = (this.recordId == 0 ? "Add" : "Update") + " Vendor User";

    if (this.recordId == 0) {
      this.model.StateCode = [String(StateEnum.Rajasthan)];
      this.bindDistrictDropdown();
    }
  }

  ngOnInit() {
    if (this.loginUserDetail.BaseUserType == this.userTypeEnum.VNDR) {
      this.model.DepartmentCodes = this.loginUserDetail.DepartmentCodes;
      this.model.DistrictCodes = this.loginUserDetail.DistrictCodes;
      //this.GetOffice();
      this.GetOfficeHavingStaff();
      this.model.OfficeCodes = this.loginUserDetail.OfficeCodes;
    }
    this.formGroupInit();
    this.getDetail();

  }

  formGroupInit() {
    this.frmGrp = this.fb.group({
      UserType: new FormControl({ value: '' }, [Validators.required]),
      SSOID: [null, Validators.compose([Validators.required, Validators.maxLength(50)])],
      Title: [null, Validators.compose([Validators.required])],
      Name: [null, Validators.required],
      Email: [null, Validators.compose([Validators.required, Validators.email])],
      Gender: [null, Validators.required],
      Mobile: [null, Validators.compose([Validators.required, Validators.maxLength(10)])],
      DepartmentCodes: [null],
      DistrictCodes: [null],
      StateCode: [null],
      OfficeCodes: [null],
      ProjectCodes: [null],
    });
  }

  GetDDLList() {
    this._commonService.GetAllDDL(AppSetting.VendorUserDropdownKeys, Number(this.loginUserDetail.UserTypeCode), this.loginUserDetail.UserCode).subscribe(data => {
      if (data.IsSuccess) {
        this.ddlList = <DDLModel>data.Data;
        this.ddlUserType = this.ddlList!.ddlDisplayUserTypeTitle;
        this.ddlOfficeDepartment = this.ddlList.ddlDepartmentByVendorPermission;
        this.getUserCategory();

        //this.ddlList.ddlDisplayUserTypeTitle = [];
        // if (this.ddlList) {
        //   this.ddlList.ddlDisplayUserTypeTitle = this.ddlList.ddlDisplayUserTypeTitle.filter(function (item) {
        //     return (item.Value == UserTypeEnum.HOD || item.Value == UserTypeEnum.DREC || item.Value == UserTypeEnum.DLO || item.Value == UserTypeEnum.PROJ || item.Value == UserTypeEnum.STTLVL);
        //   });
        // }
      }
    }, error => {
      this._alertService.error(error.message);
    });
  }

  getUserCategory() {
    this.ddlList.ddlDisplayUserTypeTitle = [];
    this._commonService.GetDDL(AppSetting.DDlKeyForUserCategory, UserTypeEnum.VNDRUSERCode).subscribe(
      data => {
        if (data.IsSuccess) {
          let ddlList = <DDLModel>data.Data;
          this.ddlList.ddlDisplayUserTypeTitle = ddlList!.ddlUserCategory;
          this.onUserTypeChange();
        }
      },
      error => {
        this._alertService.error(error.message);
      }
    );

  }

  bindDepartmentDropdown() {
    this._userService.GetUserDepartmentList(0).subscribe(data => {
      if (data.IsSuccess) {
        this.ddlOfficeDepartment = <DdlItemModel[]>data.Data;
      }
    }, error => {
      this.alert(error.message, AlertTypeEnum.Error);
    });
  }

  bindDistrictDropdown() {
    let stateCodes = this.model.StateCode ? this.model.StateCode.toString() : "";
    let departmentCodes = this.model!.DepartmentCode ? this.model!.DepartmentCode.toString() : "";

    this._userService.GetUserDistrictList(stateCodes, false, departmentCodes).subscribe(data => {
      if (data.IsSuccess) {
        this.ddlDistrict = <DdlItemModel[]>data.Data;
      }
    }, error => {
      this.alert(error.message, AlertTypeEnum.Error);
    });
  }

  getDetail() {
    if (this.recordId > 0) {
      this._userService.Detail(this.recordId).subscribe(data => {
        if (data.IsSuccess) {
          this.model = <UserViewModel>data.Data;
          this.oldSSOId = this.model.SSOID.toLowerCase();
          this.isUserExistOnSso = true;

          if (this.model.UserTypeCategoryCode) {
            this.model.UserTypeCategoryCode = String(this.model.UserTypeCategoryCode);
          }

          if (this.model.DepartmentCodes) {
            this.model.DepartmentCode = this.model.DepartmentCodes.split(",");
          }
          if (this.model.StateCodes) {
            this.model.StateCode = this.model.StateCodes.split(",");
            this.bindDistrictDropdown();
          }

          if (this.model.DistrictCodes) {
            this.model.DistrictCode = this.model.DistrictCodes.split(",");
          }
          this.GetOffice();
          this.GetOfficeHavingStaff();

          if (this.model.OfficeCodes) {
            this.model.OfficeCode = this.model.OfficeCodes.split(",");
          }
          if (this.model.ProjectCodes) {
            this.model.ProjectCode = this.model.ProjectCodes.split(",")
          }
          this.GetProjectByDepartment();



          // setTimeout(() => {
          //      this.onUserTypeChange();
          // }, 500);

        }
      }, error => {
        this.model = new UserViewModel();
        this.alert(error.message, AlertTypeEnum.Error);
      });
    }
  }

  onUserTypeChange() {
    this.fieldConfig.IsDepartmentShow = true;
    this.fieldConfig.IsDistrictShow = true;
    this.fieldConfig.IsOfficeShow = true;
    this.fieldConfig.IsProjectShow = true;
    this.fieldConfig.IsStateShow = true;

    const ProjectCodes = this.frmGrp.get("ProjectCodes");
    const OfficeCodes = this.frmGrp.get("OfficeCodes");
    const DistrictCodes = this.frmGrp.get("DistrictCodes");
    const DepartmentCodes = this.frmGrp.get("DepartmentCodes");
    const StateCode = this.frmGrp.get("StateCode");

    ProjectCodes.setValidators([Validators.required]);
    OfficeCodes.setValidators([Validators.required]);
    DistrictCodes.setValidators([Validators.required]);
    DepartmentCodes.setValidators([Validators.required]);
    StateCode.setValidators([Validators.required]);

    let type = this.ddlList!.ddlDisplayUserTypeTitle.find(x => x.Value == this.model!.UserTypeCategoryCode);
    if (type) {

      let CatType = this.ddlUserType!.find(x => x.Text == type!.Text);
      switch (CatType!.Value) {
        case this.userTypeEnum.HOD:
          this.fieldConfig.IsDistrictShow = false;
          this.fieldConfig.IsStateShow = false;
          this.fieldConfig.IsOfficeShow = false;
          this.fieldConfig.IsProjectShow = false;
          ProjectCodes.setValidators(null);
          OfficeCodes.setValidators(null);
          DistrictCodes.setValidators(null);
          StateCode.setValidators(null);
          break;

        case this.userTypeEnum.DREC || this.userTypeEnum.DRECADLC:
          this.fieldConfig.IsProjectShow = false;
          ProjectCodes.setValidators(null);
          break;

        case this.userTypeEnum.DLO:
          this.fieldConfig.IsDepartmentShow = false;
          this.fieldConfig.IsOfficeShow = false;
          this.fieldConfig.IsProjectShow = false;
          DepartmentCodes.setValidators(null);
          ProjectCodes.setValidators(null);
          OfficeCodes.setValidators(null);
          break;

        case this.userTypeEnum.PROJ:
          this.fieldConfig.IsDistrictShow = false;
          this.fieldConfig.IsStateShow = false;
          this.fieldConfig.IsOfficeShow = false;
          OfficeCodes.setValidators(null);
          DistrictCodes.setValidators(null);
          break;

        case this.userTypeEnum.STTLVL:
          this.fieldConfig.IsDepartmentShow = false;
          this.fieldConfig.IsOfficeShow = false;
          this.fieldConfig.IsDistrictShow = false;
          this.fieldConfig.IsStateShow = false;
          this.fieldConfig.IsProjectShow = false;

          DepartmentCodes.setValidators(null);
          ProjectCodes.setValidators(null);
          DistrictCodes.setValidators(null);
          OfficeCodes.setValidators(null);
          StateCode.setValidators(null);
      }

      ProjectCodes.updateValueAndValidity();
      OfficeCodes.updateValueAndValidity();
      DistrictCodes.updateValueAndValidity();
      DepartmentCodes.updateValueAndValidity();
      StateCode.updateValueAndValidity();
    }
  }

  isRequiredField(field: string) {
    const form_field = this.frmGrp.get(field);
    if (!form_field.validator) {
      return false;
    }

    const validator = form_field.validator({} as AbstractControl);
    return (validator && validator.required);
  }

  getSSODetail() {
    if (this.frmGrp.get('SSOID').valid) {
      if (this.model.SSOID.toLowerCase() != this.oldSSOId) {
        this.clearSSOFields();
        this._userService.IsSSOIdExist(this.model.SSOID).subscribe(result => {
          if (result.IsSuccess) {
            let isExist = result.Data != null ? <boolean>result.Data : null;
            if (isExist != null && isExist == true) {
              this.model.SSOID = this.oldSSOId;
              if (this.oldSSOId) { this.isUserExistOnSso = true; }

              this._commonService.ScrollingTop();
              this.alert(result.Message, AlertTypeEnum.Error);
            }
            else {
              this.getUserDetailFromSSO(this.model.SSOID);
            }
          }
        }, error => {
          this._commonService.ScrollingTop();
          this.alert(GlobalMessagesModel.InternalError, AlertTypeEnum.Error);
        });
      }
    }
  }

  getUserDetailFromSSO(ssoId: string) {
    this._userService.GetUserDetailFromSSO(ssoId).subscribe(result => {
      this._alertService.blank();
      if (result.IsSuccess) {
        this.ssoUserDetail = <SSOUserModel>result.Data
        this.model.Name = (this.ssoUserDetail.FirstName ? this.ssoUserDetail.FirstName + ' ' : '') + (this.ssoUserDetail.LastName ? this.ssoUserDetail.LastName : '');
        this.model.Email = this.ssoUserDetail.MailPersonal;
        this.model.Mobile = this.ssoUserDetail.Mobile;
        this.model.Gender = this.ssoUserDetail.Gender;
        this.model.ProfilePic = this.ssoUserDetail.Photo != null && this.ssoUserDetail.Photo.length > 0 ? this.ssoUserDetail.Photo : "";
        this.isUserExistOnSso = true;
        if (ssoId.toLowerCase() != this.ssoUserDetail.SSOID.toLowerCase()) {
          this.model.SSOID = this.ssoUserDetail.SSOID;
          this.getSSODetail();
        }
      } else {
        this.clearSSOFields();
        this._commonService.ScrollingTop();
        this.alert(ssoId + " SSO ID Not Found!", AlertTypeEnum.Error);
      }
    }, error => {
      this._commonService.ScrollingTop();
      this.alert(GlobalMessagesModel.InternalError, AlertTypeEnum.Error);
    });
  }

  clearSSOFields() {
    this.model.Name = undefined;
    this.model.Email = undefined;
    this.model.Mobile = undefined;
    this.model.Gender = undefined;
    this.model.ProfilePic = "";
    this.isUserExistOnSso = false;
  }

  handleFileInput(event: any) {
    if (event.target.files.item(0).type.match('image/*')) {
      if (event.target.files.item(0).size > AppSetting.FileSizeLimit.DEFAULT) {
        this.alert(AppSetting.FileSizeLimit.DEFAULTErrorMSG, AlertTypeEnum.Error);
        this._commonService.ScrollingTop();
      } else {
        let reader = new FileReader();
        reader.onload = (event: any) => {
          this.model.ProfilePic = event.target.result;
        }
        reader.readAsDataURL(event.target.files.item(0));
      }
    } else {
      this.alert('Only *images accepted!', AlertTypeEnum.Error);
    }
  }

  GetOffice() {
    if (!isNullOrUndefined(this.model.DepartmentCode)) {
      if (!this.model.DistrictCode) {
        this.model.DistrictCode = [];
      }
      this._userService.GetVenderOfficeList(this.model.DepartmentCode.join(','), this.model.DistrictCode.join(',')).subscribe(
        data => {

          if (data.IsSuccess) {
            this.ddlDepartmentOffice = <DdlItemModel[]>data.Data;

            if (this.loginBaseUserType == this.userTypeEnum.VNDR || this.loginBaseUserType == this.userTypeEnum.ADM || this.loginBaseUserType == this.userTypeEnum.SADM) {
              this.GetProjectByDepartment();
            }

          }
        },
        error => {
          this.alert(error.message, AlertTypeEnum.Error);
        }
      );
    }
    else {
      this.ddlDepartmentOffice = null;
    }
  }

  GetOfficeHavingStaff() {
    if (!isNullOrUndefined(this.model.DepartmentCode)) {
      if (!this.model.DistrictCode) {
        this.model.DistrictCode = [];
      }
      this._userService.GetVenderOfficeHavingStaffList(this.model.DepartmentCode.join(','), this.model.DistrictCode.join(',')).subscribe(
        data => {

          if (data.IsSuccess) {
            this.ddlDepartmentOfficeHavingStaff = <DdlItemModel[]>data.Data;

            if (this.loginBaseUserType == this.userTypeEnum.VNDR || this.loginBaseUserType == this.userTypeEnum.ADM || this.loginBaseUserType == this.userTypeEnum.SADM) {
              this.GetProjectByDepartment();
            }

          }
        },
        error => {
          this.alert(error.message, AlertTypeEnum.Error);
        }
      );
    }
    else {
      this.ddlDepartmentOfficeHavingStaff = null;
    }
  }

  GetProjectByDepartment() {
    
    if (!isNullOrUndefined(this.model.DepartmentCode)) {
      this._commonService.GetProjectByDepartment(this.model.DepartmentCode.toString()).subscribe(
        data => {
          if (data.IsSuccess) {
            this.ddlList.ddlProject = <DdlItemModel[]>data.Data;
          }
        },
        error => {
          this._alertService.error(error.message);
        }
      );
    }
    else {
      this.ddlList.ddlProject = null;
    }
  }

  saveClick() {
   
    this.submitBtn = false;
    this.frmGrp.markAllAsTouched();
    if (this.frmGrp.valid && this.isUserExistOnSso) {
      if (this.recordId == 0) {
        this.model.CreatedBy = this.loginUserDetail.UserCode;
      }
      else {
        this.model.ModifiedBy = this.loginUserDetail.UserCode;
      }
      if (!isNullOrUndefined(this.model.ProjectCode)) {
        this.model.ProjectCodes = this.model.ProjectCode.join(",");
      }
      else {
        this.model.ProjectCodes = '';
      }
      if (!isNullOrUndefined(this.model.DepartmentCode)) {
        this.model.DepartmentCodes = this.model.DepartmentCode.join(",");
      }
      else {
        this.model.DepartmentCodes = '';
      }
      if (!isNullOrUndefined(this.model.DistrictCode)) {
        this.model.DistrictCodes = this.model.DistrictCode.join(",");
      }
      else {
        this.model.DistrictCodes = '';
      }
      if (!isNullOrUndefined(this.model.OfficeCode)) {
        this.model.OfficeCodes = this.model.OfficeCode.join(",");
      }
      else {
        this.model.OfficeCodes = '';
      }

      this.model.UserType = this.userTypeEnum.VNDRUSER;
      this._userService.AddUpdate(this.recordId, this.model).subscribe(
        data => {
          if (data.IsSuccess) {
            this._alertService.success(this.recordId == 0 ? GlobalMessagesModel.saveSuccess : GlobalMessagesModel.updateSuccess);
            this._dialogRef.close(true);
          } else {
            this.submitBtn = true;
            this.alert(data.Message, AlertTypeEnum.Error);
            this.alert(this.recordId == 0 ? GlobalMessagesModel.saveError : GlobalMessagesModel.updateError, AlertTypeEnum.Error);
          }
        },
        error => {
          this.submitBtn = true;
          this.alert(error.message, AlertTypeEnum.Error);
        });
    }
    else if (!this.isUserExistOnSso) {
      this.submitBtn = true;
      this.alert(this.model.SSOID + " SSO ID Not exist on sso server!", AlertTypeEnum.Error);
    }
    else {
      this.submitBtn = true;
    }
  }

  closeDialog(): void {
    this._dialogRef.close();
  }

  alert(message: string, type: string) {
    this.alertModel = new AlertModel();
    this.alertModel.Message = message;
    this.alertModel.Type = type;
    setTimeout(() => {
      this.alertModel = new AlertModel();
    }, 5000);
  }

  GetDepartmentByProject() {
    if (!isNullOrUndefined(this.model.ProjectCode)) {
      this._commonService
        .GetDDL(
          AppSetting.ProjectDropDownKeys,
          this.model.ProjectCode
        )
        .subscribe(
          (data) => {
            if (data.IsSuccess) {
              var ddl = <DDLModel>data.Data;
              this.ddlList.ddlOfficeDepartment = ddl.ddlOfficeDepartment;
            }
          },
          (error) => {
            this._alertService.error(error.message);
          }
        );
    } else {
      this.ddlList.ddlOfficeDepartment = null;
    }
  }

  selectAllDepartment() {
    if (this.selectedAll < 0) {
      this.model.DepartmentCode = this.ddlOfficeDepartment.map(
        function (a) {
          return a.Value as string;
        }
      );
      this.selectedAll = 1;
    } else {
      this.selectedAll = -1;
      this.model.DepartmentCode = [];
    }
  }

  selectAllState() {
    if (this.selectedStateAll < 0) {
      this.model.StateCode = this.ddlList.ddlState.map(
        function (a) {
          return a.Value as string;
        }
      );
      this.selectedStateAll = 1;
    } else {
      this.selectedStateAll = -1;
      this.model.StateCode = [];
    }
  }

  selectAllDistrict() {
    if (this.selectedAll < 0) {
      this.model.DistrictCode = this.ddlDistrict.map(
        function (a) {
          return a.Value as string;
        }
      );
      this.selectedAll = 1;
    } else {
      this.selectedAll = -1;
      this.model.DistrictCode = [];
    }
  }

  selectAllOffice() {
    if (this.selectedAll < 0) {
      this.model.OfficeCode = this.ddlDepartmentOffice.map(
        function (a) {
          return a.Value as string;
        }
      );
      this.selectedAll = 1;
    } else {
      this.selectedAll = -1;
      this.model.OfficeCode = [];
    }
  }

  selectAllOfficeHavingStaff() {
    if (this.selectedAll < 0) {
      this.model.OfficeCode = this.ddlDepartmentOfficeHavingStaff.map(
        function (a) {
          return a.Value as string;
        }
      );
      this.selectedAll = 1;
    } else {
      this.selectedAll = -1;
      this.model.OfficeCode = [];
    }
  }

  selectAllProject() {
    if (this.selectedAll < 0) {
      this.model.ProjectCode = this.ddlList.ddlProject.map(
        function (a) {
          return a.Value as string;
        }
      );
      this.selectedAll = 1;
    } else {
      this.selectedAll = -1;
      this.model.ProjectCode = [];
    }
  }

}
