import { Injectable } from '@angular/core';
import { BaseService } from '../base.service';
import { AppSetting } from '../../model/appsetting.model';
import { IndexModel } from '../../model/common.model';
import { VendorTypeModel } from '../../model/master/vendor-type.model';
import { RefundRequestReasonModel } from '../../model/master/refund-request-reason.model';

@Injectable({
    providedIn: 'root'
})

export class RefundRequestReasonService {
    constructor(private readonly _baseService: BaseService, ) { }

    GetList(model: IndexModel) {
        return this._baseService.post(AppSetting.RefundRequestReasonListUrl, model);
    }

    AddUpdate(id: number, model: RefundRequestReasonModel) {
        return this._baseService.post(AppSetting.RefundRequestReasonAddUpdateUrl, model);
    }

    Detail(id: number) {
         return this._baseService.get(AppSetting.RefundRequestReasonDetailUrl + id);
    }

    ChangeDeleteStatus(id: number) {
        return this._baseService.get(AppSetting.RefundRequestReasonDeleteStatusChangeUrl + id);
    }

    ChangeActiveStatus(id: number) {
        return this._baseService.get(AppSetting.RefundRequestReasonActiveStatusChangeUrl + id);
    }

    IsRefundRequestReasonExist(Reason: string){
        return this._baseService.get(AppSetting.IsRefundRequestReasonExistUrl + Reason);
    }

    GetRefundRequestReasonPDFDownload(model: IndexModel) {
        return this._baseService.post(AppSetting.RefundRequestReasonPDFDownloadUrl, model);
    }
}
