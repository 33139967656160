import { IndexModel } from './common.model';

export class ReportFilterModel extends IndexModel {

    LoginUserCode: number;
    DepartmentCode: string[];
    ProjectCode: string[];
    WorkOrderCode: string[];
    Status: string;
    UserCode: number | string;
    FromDate: Date | string;
    ToDate: Date | string;
    UserType: number | string;
    
    constructor() {
    super();
    this.LoginUserCode = 0;
    this.DepartmentCode = [];
    this.ProjectCode = [];
    this.WorkOrderCode = [];
    this.Status="";
    this.UserCode = 0;
    this.UserType = 0;
    this.FromDate = "";
    this.ToDate = "";
    }
    
    
}


