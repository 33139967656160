import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { PreferSearchTypeEnum } from '../../enum/fixed-values.enum';
import { PreferredDataDialogComponent } from './preferred-data-dialog/preferred-data-dialog.component';

@Component({
  selector: 'app-preferred-data-selecter',
  templateUrl: './preferred-data-selecter.component.html',
  styleUrls: ['./preferred-data-selecter.component.scss']
})
export class PreferredDataSelecterComponent implements OnInit {

  constructor(private readonly _dialogRef: MatDialog) {
  }
  @Input() formControl = new FormControl();
  @Input() label: string = "";
  @Input() labelFor: PreferSearchTypeEnum;
  @Input() Multiple = false;
  @Output() ngModel: EventEmitter<any>;

  ngOnInit() {
    

  }

  public openDialog() {
    const dialogRef = this._dialogRef.open(PreferredDataDialogComponent, {
      data: { label: this.label, labelFor: this.labelFor, Multple: this.Multiple }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.ngModel.emit(result)
    });
  }
}
