
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from "@angular/router";
import { AuthenticationService } from 'src/app/shared/service/authentication.service';
import { AppComponent } from 'src/app/app.component';
import { AlertService } from 'src/app/shared/service/alert.service';
import { isNullOrUndefined } from 'util';
import { MatDialog } from '@angular/material';
import { UserTypeEnum, StatusEnum } from 'src/app/shared/enum/fixed-values.enum';
import { CommonService } from 'src/app/shared/service/common.service';
import { ChangeCommunicationOfficeDialogComponent } from '../../communication/change-communication-office-dialog/change-communication-office-dialog.component';
import { ComplaintService } from 'src/app/shared/service/complaint.service';
import { ComplaintEntryListModel } from 'src/app/shared/model/compliant.model';
import { environment } from 'src/environments/environment';
import { PermissionModel } from 'src/app/shared/model/common.model';
import { CompliantActionDialogComponent } from '../compliant-action-dialog/compliant-action-dialog.component';

@Component({
  selector: 'app-complaint-software-detail',
  templateUrl: './complaint-software-detail.component.html',
  styleUrls: ['./complaint-software-detail.component.scss']
})
export class ComplaintSoftwareDetailComponent implements OnInit {

  //#region <<Variable>>
  loginBaseUserType: string;
  model: ComplaintEntryListModel;
  RecordId: number = 0;
  IsHideDeptInternal_VendorCommBtn: boolean = false;
  userTypeEnum = UserTypeEnum;
  Permission: PermissionModel = this._authService.GetPagePermission("/complaint-software", "complaint-software/create", "complaint-software/detail", "complaint-software/update", "complaint-software/delete");
  //#endregion

  //#region <<Constructor>>
  constructor(
    private readonly appComponent: AppComponent,
    public readonly _commonService: CommonService,
    private readonly _alertService: AlertService,
    private _route: ActivatedRoute,
    private readonly _dialog: MatDialog,

    private readonly _authService: AuthenticationService,
    private _complaintService: ComplaintService
  ) {
    this.model = new ComplaintEntryListModel();
    if (!isNullOrUndefined(this._route.snapshot.params.id)) {
      this.RecordId = this._route.snapshot.params.id;
    }
    this.loginBaseUserType = this._authService.BaseUserType;
  }
  //#endregion

  ngOnInit() {
    if (this.RecordId != 0) {
      this.getDetail();
    }
  }

  getDetail() {
    this._complaintService.Detail(this.RecordId).subscribe(
      data => {
        if (data.IsSuccess) {
          this.model = <ComplaintEntryListModel>data.Data;
          // if (this.model.Status == StatusEnum.SENT && this.loginBaseUserType != UserTypeEnum.VNDR && this.loginBaseUserType != UserTypeEnum.VNDRUSER) {
          //   this.model.Status = StatusEnum.RECEIVED;
          // }

          // if (this.model.Status == StatusEnum.RECEIVED || this.model.Status == StatusEnum.RETURN || this.model.Status == StatusEnum.ACCEPT || this.model.Status == StatusEnum.CLOSE || this.model.Status == StatusEnum.DISPOSED || this.model.Status == StatusEnum.REJECT) {
          //   this.IsHideDeptInternal_VendorCommBtn = true;
          // }
          // else{
          //   this.IsHideDeptInternal_VendorCommBtn = false;
          // }
          this.appComponent.setPageLayout("Suggestion-Feedback Detail :", "keyboard_backspace", "Back To List", "complaint-software");
        }
      },
      error => {
        this._alertService.error(error.message);
      }
    );
  }

  OpenChangeOfficeDialog(Id) {
    const _dialogRef = this._dialog.open(ChangeCommunicationOfficeDialogComponent, {
      width: "500px",
      data: { Id },
      disableClose: true
    });
    _dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        this.getDetail();
      }
    });
  }
  // getAbsalutePath(url) {
  //   return environment.ApiBaseUrl + url.replace("~/", "").trim();
  // }
  OpenDialog(id) {
    const dialogRef = this._dialog.open(CompliantActionDialogComponent, {
      width: '500px',
      data: id
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getDetail();
      }
    });
  }
}

