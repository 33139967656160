import { BaseService } from '../base.service';
import { AppSetting } from '../../model/appsetting.model';
import { IndexModel } from '../../model/common.model';
import { ComplaintTypeModel } from '../../model/master/complaint-type.model';
import { Injectable } from '@angular/core';


@Injectable({
    providedIn: 'root'
})

export class ComplaintTypeService {
    constructor(private readonly _baseService: BaseService, ) { }

    GetList(model: IndexModel) {
        return this._baseService.post(AppSetting.ComplaintTypeListUrl, model);
    }

    AddUpdate(id: number, model: ComplaintTypeModel) {
        return this._baseService.post(AppSetting.ComplaintTypeAddUpdateUrl, model);
    }

    Detail(id: number) {
         return this._baseService.get(AppSetting.ComplaintTypeDetailUrl + id);
    }

    ChangeDeleteStatus(id: number) {
        return this._baseService.get(AppSetting.ComplaintTypeDeleteStatusChangeUrl + id);
    }

    ChangeActiveStatus(id: number) {
        return this._baseService.get(AppSetting.ComplaintTypeActiveStatusChangeUrl + id);
    }

    IsComplaintTypeExist(complaintType: string){
        return this._baseService.get(AppSetting.IsComplaintTypeExistUrl + complaintType);
    }

    GetComplaintTypePDFDownload(model: IndexModel) {
        return this._baseService.post(AppSetting.ComplaintTypePDFDownloadUrl, model);
    }
}
