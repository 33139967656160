import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppSetting } from '../../model/appsetting.model';
import { OfficerListRequestModel } from '../../model/preferred-list/officer-list-request.model';
import { BaseService } from '../base.service';
import { OfficerAddRequestModel } from '../../model/preferred-list/officer-add-request.model';


@Injectable()
export class OfficerListService {

  constructor(
    private readonly _baseService: BaseService
  ) { }

  GetList(model: OfficerListRequestModel): Observable<any> {
    const url = AppSetting.GetAllOfficerList;
    return this._baseService.post(url, model);
  }

  AddAllOfficer(model: OfficerAddRequestModel): Observable<any> {
    const url = AppSetting.AddAllOfficer;
    return this._baseService.post(url, model);
  }
}
