import { Component, OnInit } from '@angular/core';
import { StatusEnum, UserTypeEnum } from 'src/app/shared/enum/fixed-values.enum';
import { OutboxModel } from 'src/app/shared/model/outbox.model';
import { AppComponent } from 'src/app/app.component';
import { CommonService } from 'src/app/shared/service/common.service';
import { ActivatedRoute } from '@angular/router';
import { AlertService } from 'src/app/shared/service/alert.service';
import { MatDialog } from '@angular/material';
import { OutboxService } from 'src/app/shared/service/outbox.service';
import { AuthenticationService } from 'src/app/shared/service/authentication.service';
import { isNullOrUndefined } from 'util';


@Component({
  selector: 'app-outbox-detail',
  templateUrl: './outbox-detail.component.html',
  styleUrls: ['./outbox-detail.component.scss']
})
export class OutboxDetailComponent implements OnInit {

  //#region <<Variable>>
  loginBaseUserType: string;
  
  userTypeEnum = UserTypeEnum;

  model: OutboxModel;
  recordId: number = 0;
  //#endregion

  //#region <<Constructor>>
  constructor(
    private readonly appComponnet: AppComponent,
    public readonly _commonService: CommonService,
    private readonly _alertService: AlertService,
    private _route: ActivatedRoute,
    private readonly _dialog: MatDialog,
    private readonly _outboxService: OutboxService,
    private readonly _authService: AuthenticationService
  ) {
    this.model = new OutboxModel();
    if (!isNullOrUndefined(this._route.snapshot.params.id)) {
      this.recordId = this._route.snapshot.params.id;
    }
    this.loginBaseUserType = this._authService.BaseUserType;
  }
  //#endregion

  ngOnInit() {
    if (this.recordId != 0) {
      this.getDetail();
    }
  }

  getDetail() {
    this._outboxService.Detail(this.recordId).subscribe(
      data => {
        if (data.IsSuccess) {
          this.model = <OutboxModel>data.Data;

          if (this.model.Status == StatusEnum.SENT && !(this.loginBaseUserType == UserTypeEnum.VNDR || this.loginBaseUserType == UserTypeEnum.VNDRUSER)) {
            this.model.Status = StatusEnum.RECEIVED;
          }
          this.appComponnet.setPageLayout("Outbox Detail :", "keyboard_backspace", "Back To List", "outbox");
        }
      },
      error => {
        this._alertService.error(error.message);
      }
    );
  }

}
