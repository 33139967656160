import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { AppSetting } from 'src/app/shared/model/appsetting.model';
import { DDLModel } from 'src/app/shared/model/commonddl.model';
import { GlobalMessagesModel } from 'src/app/shared/model/common-messages.model';
import { CommonService } from 'src/app/shared/service/common.service';
import { AlertService } from 'src/app/shared/service/alert.service';
import { UserViewModel } from 'src/app/shared/model/user.model';
import { AuthenticationService } from 'src/app/shared/service/authentication.service';
import { CommunicationModel } from 'src/app/shared/model/communication.model';
import { CommunicationService } from 'src/app/shared/service/communication.service';
import { isNullOrUndefined } from 'util';
import { ChangeCommunicationDepartmentOfficeModel, CommunicationOfficeLookUpDocumentModel } from 'src/app/shared/model/change-communication-department-office.model';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { CommunicationTransactionViewModel } from 'src/app/shared/model/communication-transaction.model';

@Component({
  selector: 'app-office-internal-transfer-action-dialog',
  templateUrl: './office-internal-transfer-action-dialog.component.html',
  styleUrls: ['./office-internal-transfer-action-dialog.component.scss']
})
export class OfficeInternalTransferActionDialogComponent implements OnInit {

  loginUserDetail: UserViewModel;
  frmGrp: FormGroup;
  model = new CommunicationModel();
  ddlList = new DDLModel();
  recordId: number = 0;
  oldOfficeCode: number | string = null;
  newOfficeCode: number | string = null;
  oldOfficeStaffCode: number | string = null;
  newOfficeStaffCode: number | string = null;
  editorConfig = AppSetting.editorConfig as AngularEditorConfig;
  changeCommunicationDepartmentOfficeModel = new ChangeCommunicationDepartmentOfficeModel();
  fileValidationMsg: string;
  tempDocumentList: CommunicationOfficeLookUpDocumentModel[] = [];
  isDisableSubmitBtn = false;
  constructor(private readonly fb: FormBuilder,
    public readonly _commonService: CommonService,
    private readonly _alertService: AlertService,
    private _router: Router,
    private readonly _communicationService: CommunicationService,
    private readonly _authService: AuthenticationService,
    public _dialogRef: MatDialogRef<OfficeInternalTransferActionDialogComponent>,
    // private readonly _route: ActivatedRoute,
    @Inject(MAT_DIALOG_DATA) public data: any) {

    if (data) {
      if (data.Id) {

        this.recordId = data.Id;

        // this.model.Purpose = "";
        // this.changeCommunicationDepartmentOfficeModel = new ChangeCommunicationDepartmentOfficeModel();
      }
    }
    this.loginUserDetail = this._authService.GetCurrentUserDetail().UserViewModel;

    if (this.recordId != 0) {
      this.GetDDLList();

    }
    else {
      this.CloseDialog();
    }

  }

  ngOnInit() {
    this.formGroupInit();


  }

  formGroupInit() {
    this.frmGrp = this.fb.group({
      DepartmentCode: [{ value: null, disabled: false }],
      DistrictCode: [{ value: null, disabled: false }],
      OfficeCode: [{ value: null, disabled: false }],
      OfficeStaffCode: [null, Validators.required],
      Purpose: [null, Validators.required],
      ForwordToActionCode: [null, null]
    });
  }

  GetDDLList() {
    this._commonService.GetAllDDL(AppSetting.CommunicationInternalTransferDropdownKeys).subscribe(
      data => {
        if (data.IsSuccess) {

          this.ddlList = <DDLModel>data.Data;
        }

        this.getDetail();

      },
      error => {
        this._alertService.error(error.message);
      }
    );
  }

  updateComments() {

    const selectedOption = this.ddlList.ddlForwordToAction.find(option => option.Value === this.model.ForwordToActionCode);
    const selectedText = selectedOption ? selectedOption.Text : '';
    this.frmGrp.get('Purpose').setValue(selectedText);
  }


  getDetail() {
    this._communicationService.Detail(this.recordId).subscribe(
      data => {
        if (data.IsSuccess) {

          let detail = data.Data as CommunicationTransactionViewModel;

          this.model.DepartmentCode = this.loginUserDetail.DepartmentCodes ? String(this.loginUserDetail.DepartmentCodes) : undefined;
          this.model.DistrictCode = this.loginUserDetail.DistrictCodes ? String(this.loginUserDetail.DistrictCodes) : undefined;
          this.model.OfficeCode = this.loginUserDetail.OfficeCodes ? String(this.loginUserDetail.OfficeCodes) : undefined;

          this.oldOfficeCode = detail.OfficeCode ? String(detail.OfficeCode) : undefined;

          this.oldOfficeStaffCode = detail.CurrentStaffCode




          //  this.model.DepartmentCode = this.model.DepartmentCode ? String(this.model.DepartmentCode) : undefined;
          // this.model.DistrictCode = this.model.DistrictCode ? String(this.model.DistrictCode) : undefined;
          //  this.model.OfficeCode = this.model.OfficeCode ? String(this.model.OfficeCode) : undefined;
          //  this.oldOfficeCode = this.model.OfficeCode ? String(this.model.OfficeCode) : undefined;
          //   this.oldOfficeStaffCode = this.model.OfficeStaffCode;
          //   this.model.OfficeStaffUser = this.model.OfficeStaffUser;

          this.model.Purpose = "";
          this.changeCommunicationDepartmentOfficeModel.DocumentList = null;

        }
      },
      error => {
        this._alertService.error(error.message);
      }
    );
  }

  GetOffice() {
    if (!isNullOrUndefined(this.model.DepartmentCode)) {
      this.model.DistrictCode = (this.model.DistrictCode == undefined) ? 0 : this.model.DistrictCode;
      this._commonService.GetDDL(AppSetting.ddlDepartmentOffice, this.model.DepartmentCode, this.model.DistrictCode).subscribe(
        data => {
          if (data.IsSuccess) {

            var ddl = <DDLModel>data.Data;
            this.ddlList.ddlDepartmentOffice = [];
            if (ddl.ddlDepartmentOffice != undefined) { this.ddlList.ddlDepartmentOffice = ddl.ddlDepartmentOffice; }
          }
        },
        error => {
          this._alertService.error(error.message);
        }
      );
    }
    else {
      this.ddlList.ddlDepartmentOffice = [];
    }
  }

  GetOfficeHavingStaff() {

    if (!isNullOrUndefined(this.model.DepartmentCode)) {
      this.model.DistrictCode = (this.model.DistrictCode == undefined) ? 0 : this.model.DistrictCode;
      this._commonService.GetDDL(AppSetting.ddlDepartmentOfficeHavingStaff, this.model.DepartmentCode, this.model.DistrictCode).subscribe(
        data => {
          if (data.IsSuccess) {

            var ddl = <DDLModel>data.Data;
            this.ddlList.ddlDepartmentOfficeHavingStaff = [];
            if (ddl.ddlDepartmentOfficeHavingStaff != undefined) { this.ddlList.ddlDepartmentOfficeHavingStaff = ddl.ddlDepartmentOfficeHavingStaff; }
          }
        },
        error => {
          this._alertService.error(error.message);
        }
      );
    }
    else {
      this.ddlList.ddlDepartmentOfficeHavingStaff = [];
    }
  }


  saveClick() {
    this.isDisableSubmitBtn = true;
    this.frmGrp.markAllAsTouched();
    if (this.frmGrp.valid) {
      this.changeCommunicationDepartmentOfficeModel.CommunicationOfficeLookupCode = 0;
      this.changeCommunicationDepartmentOfficeModel.CommunicationCode = this.recordId;
      this.changeCommunicationDepartmentOfficeModel.OfficeCode = Number(this.model.OfficeCode);
      this.changeCommunicationDepartmentOfficeModel.UserCode = Number(this.loginUserDetail.UserCode);
      this.changeCommunicationDepartmentOfficeModel.UserMapCode = Number(this.model.OfficeStaffCode);
      this.changeCommunicationDepartmentOfficeModel.Purpose = this.model.Purpose;
      this.changeCommunicationDepartmentOfficeModel.DocumentList = this.tempDocumentList;
      this.changeCommunicationDepartmentOfficeModel.ForwordToAction = this.model.ForwordToActionCode;
      if (this.oldOfficeStaffCode != this.model.OfficeStaffCode) {
        this._communicationService.ChangeCommunicationOffice(this.changeCommunicationDepartmentOfficeModel).subscribe(
          data => {
            if (data) {
              if (data.IsSuccess) {
                this.isDisableSubmitBtn = false;
                this._alertService.success(GlobalMessagesModel.updateCommunicationOfficeSuccess);

                this._dialogRef.close(true);

              } else {
                this.isDisableSubmitBtn = false;
                this._alertService.error(GlobalMessagesModel.updateCommunicationOfficeError);
              }

            } else {
              this.isDisableSubmitBtn = false;
              this._alertService.error(GlobalMessagesModel.InternalError);
            }
          },
          error => {
            this.isDisableSubmitBtn = false;
            this._alertService.error(error.message);
          }
        );
      }
      else {
        this.isDisableSubmitBtn = false;
        this._alertService.success(GlobalMessagesModel.updateCommunicationOfficeStaffCodeChange);
      }

    } else {
      this.isDisableSubmitBtn = false;
    }
  }

  CloseDialog(): void {
    this._dialogRef.close();
  }

  GetSelectedOfficeCode() {
    if (!isNullOrUndefined(this.model.OfficeCode)) {
      this.newOfficeCode = this.model.OfficeCode;
    }
    else {
      this.newOfficeCode = null;
    }
  }

  GetSelectedOfficeStaffCode() {
    if (!isNullOrUndefined(this.model.OfficeStaffCode)) {
      this.newOfficeStaffCode = this.model.OfficeStaffCode;
    }
    else {
      this.newOfficeStaffCode = null;
    }
  }

  GetOfficeUser() {
    if (!isNullOrUndefined(this.model.OfficeCode)) {
      this.model.DistrictCode = (this.model.DistrictCode == undefined) ? 0 : this.model.DistrictCode;
      this._commonService.GetDDL(AppSetting.ddlOfficeStaff, this.model.OfficeCode).subscribe(
        data => {
          if (data.IsSuccess) {
            var ddl = <DDLModel>data.Data;
            this.ddlList.ddlOfficeStaff = ddl.ddlOfficeStaff;
          }
        },
        error => {
          this._alertService.error(error.message);
        }
      );
    }
    else {
      this.ddlList.ddlOfficeReceptionUsers = null;
    }
  }

  handleFileInput(files: FileList) {
    let count = 0;
    if (this.tempDocumentList.length > 0) {
      count = this.tempDocumentList.length;
    }
    for (let index = 0; index < files.length; index++) {
      if (files.item(index).type.match("application/pdf")) {
        if (files.item(index).size < AppSetting.FileSizeLimit.PDF) {
          const reader = new FileReader();
          reader.onload = (e: any) => {
            this.tempDocumentList.push(new CommunicationOfficeLookUpDocumentModel());
            this.tempDocumentList[count + index].DocName = <string>reader.result;
            this.tempDocumentList[count + index].DocPath = <string>reader.result;

            this.tempDocumentList[count + index].DocName = files[index].name;
            this.tempDocumentList[count + index].Extension = files[index].name.split(".")[1];
            this.tempDocumentList[count + index].DocPath = <string>reader.result;
          };
          reader.readAsDataURL(files[index]);
        }
        else {
          this.tempDocumentList = [];
          this.fileValidationMsg = AppSetting.FileSizeLimit.PDFErrorMSG;
          return;
        }
      }
      else {
        this.tempDocumentList = [];
        this.fileValidationMsg = "Only *pdf";
        return;
      }
    }
    this.fileValidationMsg = "";
  }

  downloadPdf(Url, name) {
    const linkSource = Url;
    const downloadLink = document.createElement("a");
    const fileName = name;

    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.target = "blank";
    downloadLink.click();
  }

  deleteDocument(documentCode: number, index: number) {
    this.tempDocumentList.splice(index, 1);
  }


}
