import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { AppComponent } from "src/app/app.component"
import { WorkOrderService } from 'src/app/shared/service/work-order.service';
import { GlobalFilterModel, IndexModel } from 'src/app/shared/model/common.model';
import { GlobalMessagesModel } from 'src/app/shared/model/common-messages.model';
import { AppSetting } from 'src/app/shared/model/appsetting.model';
import { MatTableDataSource, MatDialog, MatSort, MatPaginator } from "@angular/material";
import { ConfirmationDialogComponent } from "src/app/confirmation-dialog/confirmation-dialog.component";
import { AlertService } from 'src/app/shared/service/alert.service';
import { CommonService } from 'src/app/shared/service/common.service';
import { ColumnHeaderModel } from 'src/app/shared/model/commonddl.model';
import { UserTypeEnum } from 'src/app/shared/enum/fixed-values.enum';
import { AuthenticationService } from 'src/app/shared/service/authentication.service';
import { Router } from '@angular/router';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { WorkOrderReportModel } from 'src/app/shared/model/master/work-order-report.model';
import { ProjectReportModel } from 'src/app/shared/model/master/project-report.model';
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

declare const require: any;
const jsPDF = require('jspdf');
require('jspdf-autotable');
@Component({
  selector: 'app-work-order-report',
  templateUrl: './work-order-report.component.html',
  styleUrls: ['./work-order-report.component.scss']
})
export class WorkOrderReportComponent implements OnInit {
  model: WorkOrderReportModel[];
  indexModel: IndexModel;
  isOICAccepted: string;
  dataSource: any;
  totalRecords: number;
  loginUserCode: number;
  loginBaseUserType: string;
 
 userTypeEnum  = UserTypeEnum;
  
 filterModel: string = "";
  displayedColumns: string[] = [];
  columnsToDisplay: string[] = [];

  ViewdisplayedColumns: ColumnHeaderModel[] = [
    { Value: "OrderNumber", Text: "Order No" },
    { Value: "Title", Text: "Title" },
    { Value: "Subject", Text: "Subject" },
    { Value: "DepartmentName", Text: "Department" },
    { Value: "OfficeName", Text: "Office" },
    // { Value: "OrderDate", Text: "Order Date" },
    // { Value: "ModifiedOn", Text: "Modified On" },
    { Value: "UserName", Text: "Modified By" },
    { Value: "CompanyName", Text: "Vendor" }


  ];
  SearchColumns: ColumnHeaderModel[] = [
    { Value: "OrderNumber", Text: "Order No" },
    { Value: "Title", Text: "Title" },
    { Value: "Subject", Text: "Subject" },
    { Value: "DepartmentName", Text: "Department" },
    { Value: "OfficeName", Text: "Office" },
    { Value: "OrderDate", Text: "Order Date" },
    { Value: "UserName", Text: "Vendor" },
    { Value: "IsOICAccepted", Text: "Is OIC Accepted" },
    { Value: "CompanyName", Text: "Vendor" }

  ];
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  constructor(private readonly appComponent: AppComponent,
    private readonly _alertService: AlertService,
    private readonly _workOrderService: WorkOrderService,
    private readonly _router: Router,
    private readonly _dialog: MatDialog,
    public readonly _commonService: CommonService,
    private readonly _authService: AuthenticationService
  ) {
    this.loginUserCode = this._authService.UserCode;
    this.loginBaseUserType = this._authService.BaseUserType;

    this.displayedColumns = ["index", "OrderNumber", "Title", "Subject", "DepartmentName", "OfficeName", "OrderDate", "UserName", "CompanyName", "ModifiedOn"];
    if (this.loginBaseUserType == this.userTypeEnum.ADM || this.loginBaseUserType == this.userTypeEnum.SADM) {
      this.displayedColumns.push("IsActive");
      this.displayedColumns.push("IsOICAccepted");

    }
    //  this.displayedColumns.push("Action");
    this.columnsToDisplay = this.displayedColumns.slice();
    this.appComponent.setPageLayout("Work Order Report :", "", "", "");
    this.indexModel = new IndexModel();
  }

  ngOnInit() {
    if (this.userTypeEnum.ADM != this.loginBaseUserType && this.userTypeEnum.SADM != this.loginBaseUserType && this.userTypeEnum.HOD != this.loginBaseUserType && this.loginBaseUserType !=  this.userTypeEnum.DRECADLC && this.userTypeEnum.DREC != this.loginBaseUserType) {
      this._router.navigate(["dashboard"]);
    }
    this.getList();
  }
  getList() {


    this._workOrderService.GetList(this.loginUserCode, this.indexModel).subscribe(
      data => {
        if (data.IsSuccess) {
          this.model = <WorkOrderReportModel[]>data.Data.Data;

          this.model.forEach(element => {
          });
          this.dataSource = new MatTableDataSource<WorkOrderReportModel>(this.model);
          this.totalRecords = data.Data.TotalRecords;
          if (!this.indexModel.IsPostBack) {
            this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.sort;
          }
        } else {
          this._alertService.error(data.Message);
        }
      },
      error => {
        this._alertService.error(error.message);
      }
    );
  }

  sortData(event) {
    this.indexModel.OrderBy = event.active;
    this.indexModel.OrderByAsc = event.direction == AppSetting.orderByDscAsc ? AppSetting.orderByAsc : AppSetting.orderByDsc;
    this.indexModel.IsPostBack = true;
    this.getList();
  }

  onPaginateChange(event) {
    this.indexModel.Page = event.pageIndex + 1;
    this.indexModel.PageSize = event.pageSize;
    this.indexModel.IsPostBack = true;
    this.getList();
  }

  SearchByKeyword(searchValue: GlobalFilterModel) {
    this.indexModel.Search = searchValue.search;
    this.indexModel.AdvanceSearchModel = { ActiveStatus: searchValue.ActiveStatus }

    this.getList();
  }

  ClearSearch() {
    this.indexModel = new IndexModel();
    this.getList();
  }

  @ViewChild('pdfTable', { static: false }) pdfTable: ElementRef;
  completeModel: WorkOrderReportModel[];
  completeIndexModel: IndexModel;

  getCompleteData() {
    this.completeIndexModel = new IndexModel();
    this.completeIndexModel.Page = 1;
    this.completeIndexModel.PageSize = this.totalRecords;
    this.completeIndexModel.IsPostBack = true;
    this.completeIndexModel.Search = this.indexModel.Search;
    this._workOrderService.GetList(this.loginUserCode, this.completeIndexModel).subscribe(
      (completeData: any) => {
        if (completeData.IsSuccess) {
          this.completeModel = <WorkOrderReportModel[]>completeData.Data.Data;
          this.totalRecords = completeData.Data.TotalRecords;
          this.DownloadPdf();
        } else {
          this._alertService.error(completeData.Message);
        }
      },
      error => {
        this._alertService.error(error.message);
      }
    );
  }

  rowsNew: any = [];

  download() {
    var i = 1;
    this.rowsNew = [];
    this.completeModel.forEach(element => {
      var temp = {
        index: i, OrderNumber: element.OrderNumber, Title: element.Title, Subject: element.Subject,
        DepartmentName: element.DepartmentName, OfficeName: element.OfficeName,
        IsActive: element.IsActive, UserName: element.UserName, CompanyName: element.CompanyName, ModifiedOn: element.ModifiedOn, OrderDate: element.OrderDate
      };
      i = i + 1;
      this.rowsNew.push(temp);
    });
    this.exportAsPdf();
  }

  DownloadPdf() {    
    this._workOrderService.GetWorkorderListPDFDownload(this.loginUserCode,this.indexModel).subscribe(
      data => {
        if (data.IsSuccess) {          
          // const linkSource = data.Data;
          // const downloadLink = document.createElement("a");
          // const fileName = 'Department Report';

          // downloadLink.href = linkSource;
          // downloadLink.download = fileName;
          // downloadLink.target = "blank";
          // downloadLink.click();
          var w = window.open("about:blank");

          setTimeout(function() {
            //FireFox seems to require a setTimeout for this to work.
            w.document.body.appendChild(w.document.createElement("iframe")).src = data.Data;
            w.document.getElementsByTagName("iframe")[0].style.width = "100%";
            w.document.getElementsByTagName("iframe")[0].style.height = "100%";
          }, 0);
        } else {
          this._alertService.error(data.Message);
        }
      },
      error => {
        this._alertService.error(error.message);
      }
    );
  }

  public exportAsPdf(): void {
    var doc = new jsPDF('p', 'pt');
    var header = function (data) {
      doc.setFontSize(10);
      doc.setTextColor(40);
      doc.setFontStyle('normal');
      doc.text("Work Order", data.settings.margin.left, 50);
      doc.text("Date Time : "+new Date().toLocaleDateString() + " " + new Date().toLocaleTimeString(), data.settings.margin.left, 800);
    };
    var headers = [{
      title: "#",
      dataKey: "index"
    },
    {
      title: "Order Number",
      dataKey: "OrderNumber"
    },
    {
      title: "Title",
      dataKey: "Title"
    },
    {
      title: "Subject",
      dataKey: "Subject"
    },
    {
      title: "Department",
      dataKey: "DepartmentName"
    },
    {
      title: "Office",
      dataKey: "OfficeName"
    },
    {
      title: "Is Active",
      dataKey: "IsActive"
    },
    {
      title: "Vendor",
      dataKey: "CompanyName"
    },

    {
      title: "Modified By",
      dataKey: "UserName"
    },
    {
      title: "Modified On",
      dataKey: "ModifiedOn"
    },
    {
      title: "Order Date",
      dataKey: "OrderDate"
    }
    ];
    doc.autoTable(headers, this.rowsNew, { margin: { top: 70 }, beforePageContent: header });
    doc.save("Work-Order-Report" + new Date().toLocaleDateString() + "_" + new Date().toLocaleTimeString() + ".pdf");
  }

  //excellll
  public exportAsExcelFile(): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.rowsNew);
    const workbook: XLSX.WorkBook = { Sheets: { 'workOrderReport': worksheet }, SheetNames: ['workOrderReport'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFile(excelBuffer, 'Work-Order-');
  }

  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], { type: EXCEL_TYPE });
    FileSaver.saveAs(data, fileName + 'Report_' + new Date().toLocaleDateString() + "_" + new Date().toLocaleTimeString() + EXCEL_EXTENSION);
  }

  buttonClickExportAsPDF(): void {
    this.getCompleteData();
  }
  buttonClickExportAsXLSX(): void {
    this.getCompleteExcelData();
  }


  getCompleteExcelData() {
    this.completeIndexModel = new IndexModel();
    this.completeIndexModel.Page = 1;
    this.completeIndexModel.PageSize = this.totalRecords;
    this.completeIndexModel.IsPostBack = true;
    this.completeIndexModel.Search = this.indexModel.Search;
    this._workOrderService.GetList(this.loginUserCode, this.completeIndexModel).subscribe(
      (completeData: any) => {
        if (completeData.IsSuccess) {
          this.completeModel = <WorkOrderReportModel[]>completeData.Data.Data;
          this.totalRecords = completeData.Data.TotalRecords;
          this.downloadExcel();
        } else {
          this._alertService.error(completeData.Message);
        }
      },
      error => {
        this._alertService.error(error.message);
      }
    );
  }

  downloadExcel() {
    var i = 1;
    this.rowsNew = [];
    this.completeModel.forEach(element => {
      var temp = {
        SNo: i, "Order Number": element.OrderNumber, Title: element.Title, Subject: element.Subject,
        Department: element.DepartmentName, Office: element.OfficeName,
        IsActive: element.IsActive, "Vendor": element.CompanyName, OrderDate: element.OrderDate, "Modified By": element.UserName, "Modified On": element.ModifiedOn
      };
      i = i + 1;
      this.rowsNew.push(temp);
    });
    this.exportAsExcelFile();
  }


}
