import { TitleCasePipe } from "@angular/common";
import { Component, OnInit, ViewChild } from "@angular/core";
import { FormGroup, FormBuilder, FormArray, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material";
import { ActivatedRoute, Router } from "@angular/router";
import { AppComponent } from "src/app/app.component";
import { DynamicFormComponent } from "src/app/DynamicInputField/dynamic-form/dynamic-form.component";
import { DefaultFieldEnum } from "src/app/shared/enum/default-field.enum";
import { FieldTypeEnum, UserTypeEnum, StatusEnum, CommunicationInitiateByEnum } from "src/app/shared/enum/fixed-values.enum";
import { AppSetting } from "src/app/shared/model/appsetting.model";
import { GlobalMessagesModel } from "src/app/shared/model/common-messages.model";
import { DDLModel, DdlItemModel } from "src/app/shared/model/commonddl.model";
import { CommunicationTransactionPostModel, CommunicationTransactionDocumentViewModel, CommunicationTransactionViewModel, CommunicationSummaryDocumentPostModel, CommunicationTransactionDynamicPostModel } from "src/app/shared/model/communication-transaction.model";
import { CommunicationModel } from "src/app/shared/model/communication.model";
import { ComplaintCommunicationDocumentModel } from "src/app/shared/model/complaint-communication.model";
import { DynamicFieldConfig } from "src/app/shared/model/dynamic-field-config.model";
import { FieldValueViewModel } from "src/app/shared/model/field.model";
import { GlobalFilterSearchModel } from "src/app/shared/model/Global-communication-search.model";
import { CommunicationFieldValueViewModel, DefaultFieldVisibleModel, CommunicationTypeDefaultFieldsMasterModel } from "src/app/shared/model/master/communication-type-fields-master.model";
import { CommunicationTypeAttachmentViewModel, CommunicationUserTypeViewModel } from "src/app/shared/model/master/communication-type.model";
import { UserViewModel } from "src/app/shared/model/user.model";
import { AlertService } from "src/app/shared/service/alert.service";
import { AuthenticationService } from "src/app/shared/service/authentication.service";
import { CommonService } from "src/app/shared/service/common.service";
import { CommunicationService } from "src/app/shared/service/communication.service";
import { CommunicationTypeFieldsMasterService } from "src/app/shared/service/master/communication-type-fields-master.service";
import { CommunicationTypeService } from "src/app/shared/service/master/communication-type.service";
import { UserService } from "src/app/shared/service/user.service";
import { isNullOrUndefined } from "util";
import { SearchCommunicationDialogComponent } from "../search-communication-dialog/search-communication-dialog.component";
import { AddCCForCommunicationComponent } from "./add-ccfor-communication/add-ccfor-communication.component";


@Component({
  selector: 'app-add-update-communication-transaction',
  templateUrl: './add-update-communication-transaction.component.html',
  styleUrls: ['./add-update-communication-transaction.component.scss'],
  providers: [TitleCasePipe]
})

export class AddUpdateCommunicationTransactionComponent implements OnInit {
  //#region <<Variable>>
  loginUserCode: number;
  loginBaseUserType: string;
  loginUserDetail: UserViewModel;
  frmGrp: FormGroup;
  model = new CommunicationTransactionPostModel();
  ddlList = new DDLModel();
  recordId: number = 0;
  tempDocumentList: ComplaintCommunicationDocumentModel[] = [];
  tempDeletedDocumentCodes: string[] = [];
  StatusType: string;
  communicationTypeCode: number = 0;
  communicationType: string = "";
  dynamicField: DynamicFieldConfig[] = [];
  dynamicFieldModel: DynamicFieldConfig[] = [];
  communicationFieldValueViewModel: CommunicationFieldValueViewModel[] = []
  fieldTypeEnum = FieldTypeEnum;
  filedCodes: string = '';
  defaultFieldEnum = DefaultFieldEnum;
  defaultFieldVisibleModel = new DefaultFieldVisibleModel();
  @ViewChild(DynamicFormComponent, { static: false }) childComponent: DynamicFormComponent;
  @ViewChild(AddCCForCommunicationComponent, { static: false }) submitOfficeList: AddCCForCommunicationComponent;
  attachmentTypeModel: CommunicationTypeAttachmentViewModel[] = [];
  tempAttachmentsModel: CommunicationTransactionDocumentViewModel[] = [];
  detailViewModel = new CommunicationTransactionViewModel();
  communicationUserTypeModel: CommunicationUserTypeViewModel[] = [];
  userTypeEnum = UserTypeEnum;
  IsShowDynamic = false;
  statusEnumValues = StatusEnum;
  fileSizeDetail = AppSetting.FileSizeLimit;
  submitBtn = true;
  IsReadOnlyFields = false;
  isReturned = false;
  fileValidationMsg: string;
  summaryDocumentList: CommunicationSummaryDocumentPostModel[] = [];
  communicationInitiateByEnum = CommunicationInitiateByEnum;
  ddlCreatedByOffice: DdlItemModel[] = [];
  defaultMaxField: number = 9999
  @ViewChild('files', { static: false }) files: any;
  //#endregion
  constructor(private readonly appComponent: AppComponent, private readonly fb: FormBuilder,
    public readonly _commonService: CommonService,
    private readonly _alertService: AlertService,
    private _route: ActivatedRoute,
    public _communicationService: CommunicationService,
    private readonly _authService: AuthenticationService,
    private readonly _communicationTypeFieldsMasterService: CommunicationTypeFieldsMasterService,
    private readonly _communicationTypeService: CommunicationTypeService,
    private readonly _router: Router, public titleCasePipe: TitleCasePipe,
    private readonly _userService: UserService,
    private readonly _dialog: MatDialog,
  ) {
    if (!isNullOrUndefined(this._route.snapshot.params.Id)) {
      this.recordId = this._route.snapshot.params.Id;
    }
    if (!isNullOrUndefined(this._route.snapshot.params.typeCode)) {
      this.communicationTypeCode = this._route.snapshot.params.typeCode;
    }
    if (!isNullOrUndefined(this._route.snapshot.params.typeEnum)) {
      this.communicationType = this._route.snapshot.params.typeEnum;
      this.communicationTypeCode = Number(this.communicationType.split("_")[0])
    }
    this.appComponent.setPageLayout(this.titleCasePipe.transform(this.communicationType.split("_")[1]) + " Communication", "keyboard_backspace", "Back To List", "communications-list/" + this.communicationType);
    this.loginUserCode = this._authService.UserCode;
    this.loginBaseUserType = this._authService.BaseUserType;
    this.loginUserDetail = this._authService.LoggedInUserDetail.UserViewModel;
    this.formGroupInit();
  }

  ngOnInit() {

    if (this.communicationType.length > 0) {
      this.GetUserTypeByCommunicationType();

    }
    // if (this.recordId > 0) {
    //   this.getDetail();// call dropdown methods inside detail method.
    // }
  }

  //#region FormGroup Section
  formGroupInit() {
    this.frmGrp = this.fb.group({
      DepartmentCode: [undefined, null],
      DistrictCode: [undefined, null],
      OfficeCode: [undefined, null],
      VendorCode: [undefined, null],
      Subject: [undefined, null],
      Description: [undefined, null],
      Amount: [undefined, null],
      ProjectCode: [undefined, null],
      WorkOrderCode: [undefined, null],
      BankCode: [undefined, null],
      Attachments: new FormArray([]),
      Summary: [undefined, null],
      PreviouseCommunicationCode: [undefined, null],
      CreatedByOfficeCode: [undefined, null]
    });
  }

  get f() { return this.frmGrp.controls; }
  get attach() { return this.f.Attachments as FormArray }

  AddRemoveAttachmentValidation(IsRemove = false) {
    if (!IsRemove) {
      for (let i = this.attach.length; i < this.attachmentTypeModel.length; i++) {

        if (this.attachmentTypeModel[i].IsRequired) {
          this.attach.push(this.fb.group({
            fileUploader: [undefined, Validators.required],
            fileDescription: [undefined, [Validators.required, Validators.maxLength(1000)]]
          }));
        } else {
          this.attach.push(this.fb.group({
            fileUploader: ['', null],
            fileDescription: ['', null]
          }));
        }

      }
    } else {


      this.attachmentTypeModel.forEach((element, index) => {

        const fileUploader = this.attach.controls[index].get('fileUploader');
        this.attach.controls[index].get('fileUploader').setValidators(null);
        this.attach.controls[index].get('fileUploader').updateValueAndValidity();

        const fileDescription = this.attach.controls[index].get('fileDescription');
        this.attach.controls[index].get('fileDescription').setValidators(null);
        this.attach.controls[index].get('fileDescription').updateValueAndValidity();


      });

    }

  }

  RemoveOptionalFieldValidation() {
    if (this.defaultFieldVisibleModel.IsShowProjectField) {
      this.frmGrp.get("ProjectCode").setValidators(null);
      this.frmGrp.get("ProjectCode").updateValueAndValidity();
    }
    if (this.defaultFieldVisibleModel.IsShowWorkOrderField) {
      this.frmGrp.get("WorkOrderCode").setValidators(null);
      this.frmGrp.get("WorkOrderCode").updateValueAndValidity();
    }
    if (this.defaultFieldVisibleModel.IsShowVendorBankField) {
      this.frmGrp.get("BankCode").setValidators(null);
      this.frmGrp.get("BankCode").updateValueAndValidity();
    }


    if (this.defaultFieldVisibleModel.IsShowSubjectField) {
      this.frmGrp.get("Subject").setValidators(null);
      this.frmGrp.get("Subject").updateValueAndValidity();
    }

    if (this.defaultFieldVisibleModel.IsShowDescriptionField) {
      this.frmGrp.get("Description").setValidators(null);
      this.frmGrp.get("Description").updateValueAndValidity();
    }

    if (this.defaultFieldVisibleModel.IsShowAmountField) {
      this.frmGrp.get("Amount").setValidators(null);
      this.frmGrp.get("Amount").updateValueAndValidity();
    }
  }
  //#endregion

  //#region Dropdown Section
  GetDDLList() {

    let ddlKey = ((this.loginBaseUserType == this.userTypeEnum.VNDR || this.loginBaseUserType == this.userTypeEnum.VNDRUSER) ? AppSetting.VendorCommunicationDropdownKeys :
      (this.communicationUserTypeModel.length > 0 && this.communicationUserTypeModel[0].UserType == this.communicationInitiateByEnum.D2D ? AppSetting.D2DCommunicationDropdownKeys :
        AppSetting.D2VCommunicationDropdownKeys))
    this._commonService.GetAllDDL(ddlKey, 0, this.loginUserCode).subscribe(
      (data) => {
        if (data.IsSuccess) {
          let ddlData = <DDLModel>data.Data;


          if (ddlData.ddlOfficeDepartment) {
            this.ddlList.ddlOfficeDepartment = ddlData.ddlOfficeDepartment;
          }

          if (ddlData.ddlRajDistrict) {
            this.ddlList.ddlRajDistrict = ddlData.ddlRajDistrict;
          }

          if (ddlData.ddlOfficeByLoginUserId) {
            this.ddlList.ddlOfficeByLoginUserId = ddlData.ddlOfficeByLoginUserId;
          }
          if (ddlData.ddlOfficeHavingStaffByLoginUserId) {
            this.ddlList.ddlOfficeHavingStaffByLoginUserId = ddlData.ddlOfficeHavingStaffByLoginUserId;
          }
          if (ddlData.ddlDepartmentByLoginUserId) {

            this.ddlList.ddlDepartmentByLoginUserId = ddlData.ddlDepartmentByLoginUserId;
          }

          if (ddlData.ddlAllDepartment) {
            this.ddlList.ddlAllDepartment = ddlData.ddlAllDepartment;
          }

          if (ddlData.ddlLoggedUserVendor) {
            this.ddlList.ddlLoggedUserVendor = ddlData.ddlLoggedUserVendor;
          }

          if (ddlData.ddlProject) {

            this.ddlList.ddlProject = ddlData.ddlProject;
          }

          if (ddlData.ddlDepartmentByVendorPermission) {
            this.ddlList.ddlDepartmentByVendorPermission = ddlData.ddlDepartmentByVendorPermission;
          }


          this.model.DepartmentCode = Number(this.model.DepartmentCode) > 0 ? String(this.model.DepartmentCode) : this.loginUserDetail != undefined && this.loginUserDetail.DepartmentCodes != undefined ? String(this.loginUserDetail.DepartmentCodes) : undefined;
          this.model.VendorUserCode = Number(this.model.VendorUserCode) > 0 ? String(this.model.VendorUserCode) : undefined;


          if ((this.loginBaseUserType == this.userTypeEnum.DREC || this.loginBaseUserType == this.userTypeEnum.HOD || this.loginBaseUserType == this.userTypeEnum.HOO || this.loginBaseUserType == this.userTypeEnum.DOS || this.loginBaseUserType == this.userTypeEnum.DOSADLC || this.loginBaseUserType == this.userTypeEnum.HOOADLC || this.loginBaseUserType == this.userTypeEnum.DRECADLC)) {
            this.GetProjectByVendor();
          } else {
            this.GetProjectByDepartment();
          }
        }
      },
      (error) => {
        this._alertService.error(error.message);
      }
    );
  }

  GetDistrict() {
    if (this.defaultFieldVisibleModel.IsShowDistrictField) {
      const loginUserCode = (this.communicationUserTypeModel.length > 0 && this.communicationUserTypeModel[0].UserType == this.communicationInitiateByEnum.D2D ? 0 : this.loginUserDetail.UserCode);
      this._commonService.GetDDL(AppSetting.ddlDistrict, this.model.DepartmentCode != undefined ?
        this.model.DepartmentCode : 0, loginUserCode).subscribe(
          data => {
            if (data.IsSuccess) {

              var ddl = <DDLModel>data.Data;
              this.ddlList.ddlRajDistrict = ddl.ddlDistrict;
              if (this.ddlList.ddlRajDistrict != null && this.ddlList.ddlRajDistrict.length > 0) {
                this.model.DistrictCode = Number(this.model.DistrictCode) > 0 ? String(this.model.DistrictCode) : (this.loginUserDetail != undefined && this.loginUserDetail.DistrictCodes != undefined ? this.loginUserDetail.DistrictCodes : undefined);// ddl.ddlRajDistrict[0].Value;
                this.GetOffice();
                this.GetOfficeHavingStaff();
              }
            }
          },
          error => {
            this.ddlList.ddlRajDistrict = [];

            this._alertService.error(error.message);
          }
        );
    }
    else {
      this.ddlList.ddlRajDistrict = [];

    }
  }

  GetOffice() {
    const ddlOfficekey = (this.communicationUserTypeModel.length > 0 && this.communicationUserTypeModel[0].UserType == this.communicationInitiateByEnum.D2D ? AppSetting.ddlDepartmentOffice : AppSetting.ddlOfficeByLoginUserId);

    if (this.model.DepartmentCode && this.defaultFieldVisibleModel.IsShowDepartmentField) {
      this.model.DistrictCode = this.model.DistrictCode && Number(this.model.DistrictCode) > 0 ? this.model.DistrictCode : (this.loginUserDetail && this.loginUserDetail.DistrictCode && this.loginUserDetail.DistrictCode.length > 0 ? this.loginUserDetail.DistrictCode[0] : undefined);// ddl.ddlRajDistrict[0].Value;
      this._commonService.GetDDL(ddlOfficekey, this.model.DepartmentCode, this.model.DistrictCode).subscribe(
        (data) => {

          if (data.IsSuccess) {
            var ddl = <DDLModel>data.Data;
            this.ddlList.ddlOfficeByLoginUserId =
              (this.communicationUserTypeModel.length > 0 && this.communicationUserTypeModel[0].UserType == this.communicationInitiateByEnum.D2D ? ddl.ddlDepartmentOffice : ddl.ddlOfficeByLoginUserId);
            this.model.OfficeCode = this.model.OfficeCode != undefined ? this.model.OfficeCode.toString() : this.model.OfficeCode;

          }
        },
        (error) => {
          this._alertService.error(error.message);
        }
      );

    } else {
      this.ddlList.ddlDepartmentOffice = [];
    }
  }

  GetOfficeHavingStaff() {
    const ddlOfficekey = (this.communicationUserTypeModel.length > 0 && this.communicationUserTypeModel[0].UserType == this.communicationInitiateByEnum.D2D ? AppSetting.ddlDepartmentOffice : AppSetting.ddlOfficeHavingStaffByLoginUserId);

    if (this.model.DepartmentCode && this.defaultFieldVisibleModel.IsShowDepartmentField) {
      this.model.DistrictCode = this.model.DistrictCode && Number(this.model.DistrictCode) > 0 ? this.model.DistrictCode : (this.loginUserDetail && this.loginUserDetail.DistrictCode && this.loginUserDetail.DistrictCode.length > 0 ? this.loginUserDetail.DistrictCode[0] : undefined);// ddl.ddlRajDistrict[0].Value;
      this._commonService.GetDDL(ddlOfficekey, this.model.DepartmentCode, this.model.DistrictCode).subscribe(
        (data) => {

          if (data.IsSuccess) {
            var ddl = <DDLModel>data.Data;
            this.ddlList.ddlOfficeHavingStaffByLoginUserId =
              (this.communicationUserTypeModel.length > 0 && this.communicationUserTypeModel[0].UserType == this.communicationInitiateByEnum.D2D ? ddl.ddlDepartmentOffice : ddl.ddlOfficeHavingStaffByLoginUserId);
            this.model.OfficeCode = this.model.OfficeCode != undefined ? this.model.OfficeCode.toString() : this.model.OfficeCode;

          }
        },
        (error) => {
          this._alertService.error(error.message);
        }
      );

    } else {
      this.ddlList.ddlDepartmentOffice = [];
    }
  }
  GetWorkOrderByProject() {
    if (this.model.ProjectCode && this.defaultFieldVisibleModel.IsShowWorkOrderField) {
      this._commonService.GetDDL(AppSetting.ddlWorkOrder, this.model.ProjectCode, this.loginUserCode)
        .subscribe(
          (data) => {
            if (data.IsSuccess) {
              var ddl = <DDLModel>data.Data;
              this.ddlList.ddlWorkOrder = ddl.ddlWorkOrder;
            }
          },
          (error) => {
            this._alertService.error(error.message);
          }
        );
    } else {
      this.ddlList.ddlWorkOrder = [];
    }
  }

  GetProjectByDepartment() {

    if (this.model.DepartmentCode && this.defaultFieldVisibleModel.IsShowProjectField) {

      const loginUserCode = (this.communicationUserTypeModel.length > 0 && this.communicationUserTypeModel[0].UserType == this.communicationInitiateByEnum.D2D ? 0 : this.loginUserDetail.UserCode);

      this._commonService.GetDDL(AppSetting.ddlOfficeProject, this.model.DepartmentCode, this.model.OfficeCode != undefined ? this.model.OfficeCode : 0, loginUserCode)
        .subscribe(
          (data) => {
            if (data.IsSuccess) {
              var ddl = <DDLModel>data.Data;
              this.ddlList.ddlProject = ddl.ddlOfficeProject;
              this.model.ProjectCode = Number(this.model.ProjectCode) > 0 ? String(this.model.ProjectCode) : undefined;
              this.GetWorkOrderByProject();
            }
          },
          (error) => {
            this._alertService.error(error.message);
          }
        );
    } else {
      this.ddlList.ddlProject = [];
    }
  }

  GetProjectByVendor() {
    if (this.model.VendorUserCode && this.defaultFieldVisibleModel.IsShowProjectField) {
      this._commonService.GetDDL(AppSetting.ddlProject, 0, this.loginUserCode, this.model.VendorUserCode)
        .subscribe(
          (data) => {
            if (data.IsSuccess) {

              var ddl = <DDLModel>data.Data;
              this.ddlList.ddlProject = ddl.ddlProject;

              this.model.ProjectCode = Number(this.model.ProjectCode) > 0 ? String(this.model.ProjectCode) : undefined;
              this.GetWorkOrderByProject();
            }
          },
          (error) => {
            this._alertService.error(error.message);
          }
        );
    } else {
      this.ddlList.ddlProject = [];
    }
  }

  GetBankByVendor() {

    if (this.loginUserCode && this.defaultFieldVisibleModel.IsShowVendorBankField) {
      let vendorCode = 0;
      if (this.loginBaseUserType == this.userTypeEnum.VNDR || this.loginBaseUserType == this.userTypeEnum.VNDRUSER) {
        vendorCode = this.loginUserDetail.VendorCode;
      }
      else {
        vendorCode = Number(this.model.VendorUserCode);
      }

      this._commonService.GetDDL(AppSetting.ddlVendorBankAccount, 0, vendorCode).subscribe((data) => {
        if (data.IsSuccess) {
          var ddl = <DDLModel>data.Data;
          this.ddlList.ddlVendorBankAccount = ddl.ddlVendorBankAccount;
        }
      }, (error) => {
        this._alertService.error(error.message);
      });
    }
    else {
      this.ddlList.ddlVendorBankAccount = [];
    }
  }

  GetAttachmentTypeList() {
    this._communicationTypeService.GetAttachementTypeListByCommunicationType(0, this.communicationType).subscribe(response => {
      if (response.IsSuccess) {

        this.attachmentTypeModel = <CommunicationTypeAttachmentViewModel[]>response.Data;
        this.attachmentTypeModel = this.attachmentTypeModel.filter(item => item.IsSelected == true);
        this.attachmentTypeModel = this.attachmentTypeModel.sort((a, b) => {
          if (a.IsRequired == true) {
            return -1;
          }

          return 1;
        })

        this.attachmentTypeModel.map((element, index) => {

          let item = new CommunicationTransactionDocumentViewModel();
          item.AttachmentType = element.AttachmentType;
          item.AttachmentTypeCode = element.AttachmentTypeCode;
          item.IsRequired = element.IsRequired;
          this.tempAttachmentsModel.push(item);

        });

        this.AddRemoveAttachmentValidation();
      }

    });
  }

  bindFile(files, fileIndex) {

    for (let index = 0; index < files.length; index++) {
      if (files.item(index).type.match("application/pdf")) {
        if (files.item(index).size < AppSetting.FileSizeLimit.PDF) {
          const reader = new FileReader();
          reader.onload = (e: any) => {


            this.tempAttachmentsModel[fileIndex].Ext = files[index].name.substr(files[index].name.lastIndexOf(".") + 1);
            this.tempAttachmentsModel[fileIndex].DocPath = <string>reader.result;
            this.tempAttachmentsModel[fileIndex].DocName = files[index].name;
            this.tempAttachmentsModel[fileIndex].DocFakePath = files[index].name;
            if (this.tempAttachmentsModel[fileIndex].DocPath.split(',')[1].substr(0, 5).toUpperCase() == 'PCFKB') {
              alert("File is Invalid or Corrupted, Please upload valid File..!");
              this.deleteDocument(fileIndex);
              return false;
            }

          };

          reader.readAsDataURL(files[index]);
        } else {

          alert(AppSetting.FileSizeLimit.PDFErrorMSG);
          this.deleteDocument(fileIndex);
          return false;
        }
      }
      else {
        this.deleteDocument(fileIndex);
        alert("Please Attach Pdf file Only..!");
        return false;
      }
    }

  }

  downloadPdf(Url, name) {
    const linkSource = Url;
    const downloadLink = document.createElement("a");
    const fileName = name;

    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.target = "blank";
    downloadLink.click();
  }

  deleteDocument(index: number) {
    this.tempAttachmentsModel[index].DocPath = undefined;
    this.tempAttachmentsModel[index].DocName = undefined;
    this.tempAttachmentsModel[index].Ext = undefined;
    this.tempAttachmentsModel[index].DocFakePath = "";

    //this.files.nativeElement.value = null;
    (document.querySelector('#file-input_' + index) as any).value = null;
  }

  onDepartmentChange() {

    this.ddlList.ddlRajDistrict = [];
    this.ddlList.ddlOfficeByLoginUserId = [];
    this.ddlList.ddlOfficeHavingStaffByLoginUserId = [];
    this.ddlList.ddlProject = [];

    if (Number(this.model.DepartmentCode) > 0) {
      this.GetDistrict();
      this.GetOffice();
      this.GetOfficeHavingStaff();
      this.GetProjectByDepartment();
    }

  }

  //#endregion

  //#region  save section
  submitForm() {

    this.submitBtn = false;
    if (this.childComponent != undefined) {
      this.childComponent.form.markAllAsTouched();
    }
    this.frmGrp.markAllAsTouched();
    let DynamicFormValid = true;
    if (this.childComponent != undefined && this.childComponent.form.invalid) {
      DynamicFormValid = false;
    }

    if (this.frmGrp.valid && DynamicFormValid) {
      if (this.childComponent != undefined) {
        this.childComponent.submitDynamicForm();
      }
      if (this.submitOfficeList != undefined) {
        this.submitOfficeList.submitOfficeList();
      }
      if (this.recordId == 0) {
        this.model.CreatedBy = this.loginUserCode;
        this.model.Status = this.statusEnumValues.SENT
      } else {
        this.model.ModifiedBy = this.loginUserCode;

        //resubmit case
        if (!isNullOrUndefined(this.model.Status) && this.model.Status == this.statusEnumValues.RETURN) {
          this.model.Status = this.statusEnumValues.SENT;
        }
        //Final Submit after draft
        else if (this.model.Status == this.statusEnumValues.DRAFT) {
          this.model.Status = this.statusEnumValues.SENT;
        }
        //reopen communication
        else if (this.model.Status == this.statusEnumValues.DISPOSED) {
          this.model.Status = this.statusEnumValues.REOPEN;
        }

      }

      this.model.DocumentList = this.tempAttachmentsModel.filter(item => !isNullOrUndefined(item.DocPath));

      if (this.recordId == 0) {
        this.model.CommunicationTypeCode = Number(this.communicationType.split('_')[0]);
      }
      if (this.loginBaseUserType == this.userTypeEnum.DREC || this.loginBaseUserType == this.userTypeEnum.DRECADLC
        || this.loginBaseUserType == this.userTypeEnum.DOS || this.loginBaseUserType == this.userTypeEnum.DOS) {
        this.model.OfficeCode = this.model.OfficeCode != undefined ? this.model.OfficeCode : this.loginUserDetail.OfficeCodes;
        this.model.DepartmentCode = this.model.DepartmentCode != undefined ? this.model.DepartmentCode : this.loginUserDetail.DepartmentCodes;
      }

      this.model.SummaryDocumentList = this.summaryDocumentList;


      this._communicationService.AddUpdate(this.model).subscribe(response => {

        if (response.IsSuccess) {

          this._alertService.success(GlobalMessagesModel.saveSuccess);
          this._router.navigate(["communications-list/" + this.communicationType])

          //Update Inbox/outbox counts
          this._commonService.isUpdateInboxoutbox.next(true);
        }
        else {
          this.submitBtn = true;
          this._alertService.error(GlobalMessagesModel.saveFailed);
        }
      },
        error => {
          this.submitBtn = true;
          this._alertService.error(error.message);
        });
    }
    else {
      this.submitBtn = true;
      this._alertService.error(GlobalMessagesModel.FillMandatoryField)
    }
  }

  saveAsDraft() {
    this.RemoveOptionalFieldValidation();
    this.AddRemoveAttachmentValidation(true);
    this.frmGrp.markAllAsTouched();

    let DynamicFormValid = true;
    if (this.childComponent == undefined) {
      DynamicFormValid = false;
    }

    if (this.frmGrp.valid) {
      if (DynamicFormValid && this.childComponent != undefined) {
        this.childComponent.submitSaveAsDraftDynamicForm();
      }
      this.model.Status = this.statusEnumValues.DRAFT;
      if (this.recordId == 0) {
        this.model.CreatedBy = this.loginUserCode;

      } else {
        this.model.ModifiedBy = this.loginUserCode;

      }

      this.model.DocumentList = this.tempAttachmentsModel.filter(item => !isNullOrUndefined(item.DocPath));


      if (this.recordId == 0) {
        this.model.CommunicationTypeCode = Number(this.communicationType.split('_')[0]);
      }
      if (this.loginBaseUserType == this.userTypeEnum.DREC || this.loginBaseUserType == this.userTypeEnum.DRECADLC) {

        this.model.OfficeCode = this.model.OfficeCode != undefined ? this.model.OfficeCode : this.loginUserDetail.OfficeCodes;
        this.model.DepartmentCode = this.model.DepartmentCode != undefined ? this.model.DepartmentCode : this.loginUserDetail.DepartmentCodes;
      }

      this.model.SummaryDocumentList = this.summaryDocumentList;
      this._communicationService.AddUpdate(this.model).subscribe(response => {

        if (response.IsSuccess) {

          this._alertService.success(GlobalMessagesModel.saveSuccess);
          this._router.navigate(["communications-list/" + this.communicationType])

        } else {
          this._alertService.error(GlobalMessagesModel.saveFailed);

        }

      },
        error => {

          this._alertService.error(error.message);
        });
    }
    else {
      this._alertService.error(GlobalMessagesModel.FillMandatoryField)
    }

  }


  getChildValue(valueModel: DynamicFieldConfig[]) {
    this.model.DynamicFieldValueList = [];
    valueModel.forEach(element => {
      if (Number(element.fieldCode) > 0) {
        let item = new CommunicationTransactionDynamicPostModel();
        item.CommunicationCode = element.CommunicationCode == undefined ? 0 : element.CommunicationCode;
        item.ID = element.ID == undefined ? 0 : element.ID;
        item.IsActive = true;
        item.FieldCode = element.fieldCode;
        item.FieldValue = element.value;
        item.CreateBy = item.ID > 0 ? item.CreateBy : 0;
        item.ModifiedBy = item.ID == 0 || item.ID == undefined ? undefined : 0;
        this.model.DynamicFieldValueList.push(item)
      }
    })
  }


  //#endregion

  //#region CC Office
  getCCOfficeValue(officeListData: string[]) {

    this.model.CCOfficeList = officeListData;
  }
  //#endregion

  //#region  Field section

  GetDynamicField() {
    this.IsShowDynamic = false;
    this.dynamicField = [];
    this._communicationTypeFieldsMasterService.GetFieldsForCommunication(this.communicationType, this.recordId).subscribe(response => {

      if (response.IsSuccess) {

        this.communicationFieldValueViewModel = response.Data as CommunicationFieldValueViewModel[];
        //     if (this.recordId == 0) {
        this.bindCommunicationField(this.communicationFieldValueViewModel);
        //        }


      } else {
        this._alertService.error(response.Message);
      }
    },
      error => {
        this._alertService.error(error.message);
      });
  }

  bindCommunicationField(data: CommunicationFieldValueViewModel[]) {
    this.dynamicField = [];
    this.IsShowDynamic = false;

    if (data != undefined && data.length > 0) {

      data.forEach(element => {

        let field = new DynamicFieldConfig();
        field.type = element.FieldType.toUpperCase();
        field.label = element.FieldLabel;
        field.CSSClass = element.CSSClass;
        field.fieldCode = element.CommunicationTypeFieldsCode;
        let fName = element.FieldLabel.split(" ").join("");
        field.name = fName.split(".").join("");
        field.value = element.FieldValue ? String(element.FieldValue) : undefined;
        field.IsRequired = element.IsRequired;
        field.ToolTip = element.ToolTip;
        field.IsDisabledForEdit = (this.model.Status == this.statusEnumValues.RETURN && element.IsDisabledForEdit ? true : false);

        if (element.FieldType.toLowerCase() == this.fieldTypeEnum.TextBox.toLowerCase()) {
          field.inputType = "input";
        }
        if (element.FieldType.toLowerCase() != this.fieldTypeEnum.TextBox.toLowerCase() && element.FieldType.toLowerCase() != this.fieldTypeEnum.CHECKBOX.toLowerCase()) {
          this.filedCodes = this.filedCodes.length > 0 ? this.filedCodes + "," + element.CommunicationTypeFieldsCode : String(element.CommunicationTypeFieldsCode);

        }
        if (element.IsRequired) {
          field.validations = [
            {
              name: Validators.required.name,
              validator: Validators.required,
              message: element.FieldLabel + " is Required!"
            }
          ]
        }

        if (element.ValidationsList != null && element.ValidationsList.length > 0) {
          if (field.validations == null) {
            field.validations = [];
          }
          element.ValidationsList.forEach(valElement => {

            if (valElement.FieldType != this.fieldTypeEnum.DATE) {
              if (valElement.IsRegex || valElement.IsCustomRegex) {

                field.validations.push(
                  {
                    name: Validators.pattern.name.toLowerCase(),
                    validator: Validators.pattern(valElement.IsRegex ? valElement.RegexValue : valElement.Value),
                    message: element.FieldLabel + " " + valElement.ValidationMessage
                  })

              }
              else if (valElement.IsLength || valElement.IsRange) {
                if (valElement.IsLength) {
                  field.validations.push(
                    {
                      name: Validators.minLength.name.toLowerCase(),
                      validator: Validators.minLength(Number(valElement.Value)),
                      message: element.FieldLabel + " length Should be greater then " + valElement.Value
                    })

                  field.validations.push(
                    {
                      name: Validators.maxLength.name.toLowerCase(),
                      validator: Validators.maxLength(Number(valElement.DependentValue)),
                      message: element.FieldLabel + " length Should be smaller then " + valElement.DependentValue
                    })

                } else {
                  field.validations.push(
                    {
                      name: Validators.min.name.toLowerCase(),
                      validator: Validators.min(Number(valElement.Value)),
                      message: element.FieldLabel + " Range Should be greater then " + valElement.Value + "...!"
                    })

                  field.validations.push(
                    {
                      name: Validators.max.name.toLowerCase(),
                      validator: Validators.max(Number(valElement.DependentValue)),
                      message: element.FieldLabel + " Range Should be smaller then " + valElement.DependentValue + "...!"
                    })

                }
              }

            }
            else {
              if (field.CustomValidations == null) {
                field.CustomValidations = [];
              }
              // For Date Range   
              if (valElement.IsRange && !valElement.IsForwardValue) {
                field.CustomValidations.push({
                  MinLimit: new Date(valElement.Value),
                  MaxLimit: new Date(valElement.DependentValue),
                  MinErrorMessage: field.label + " should be smaller than " + valElement.DependentValue + "...!",
                  MaxErrorMessage: field.label + " should be greater than " + valElement.Value + "...!"

                })
              }

              else if (!valElement.IsRange && valElement.IsForwardValue) {
                field.CustomValidations.push({
                  MinLimit: new Date(),
                  MaxLimit: new Date("12/31/9999"),
                  MinErrorMessage: valElement.ValidationMessage,
                  MaxErrorMessage: field.label + " should be smaller than Max Date...!"
                })
              }
              //  For Date Compare 
              else if (valElement.IsRange && valElement.IsForwardValue) {
                field.CustomValidations.push({
                  MinLimit: new Date(),
                  MaxLimit: new Date("12/31/9999"),
                  MinErrorMessage: valElement.ValidationMessage,
                  MaxErrorMessage: valElement.ValidationMessage,
                  ValueComapreFieldCode: Number(valElement.Value)
                })
              }

              else if (valElement.IsPreviousValue) {
                field.CustomValidations.push({
                  MinLimit: new Date("1/1/100"),
                  MaxLimit: new Date(),
                  MinErrorMessage: field.label + " should be greater than minimum Date...!",
                  MaxErrorMessage: valElement.ValidationMessage
                })
              }
            }
          });
        }

        this.dynamicField.push(field);


      });
      this.IsShowDynamic = true;
      this.bindDropDownValue();
    }
  }

  bindDropDownValue() {
    if (this.filedCodes != undefined && this.filedCodes.length > 0) {
      let FiledCode = this.filedCodes.split(',');
      FiledCode.forEach(element => {
        this.GetFieldValuesByFieldCode(Number(element));
      });
    }
  }

  GetFieldValuesByFieldCode(fieldCode: number) {
    this._communicationTypeFieldsMasterService.GetFieldValueListByFieldCode(fieldCode).subscribe(
      data => {
        if (data.IsSuccess) {

          let result = <FieldValueViewModel[]>data.Data;
          let returnModel: DdlItemModel[] = [];
          result.forEach(element => {
            returnModel.push({ Value: String(element.Value), Text: element.Text });
          });

          let index = this.dynamicField.findIndex(x => x.fieldCode == fieldCode);
          if (index > -1) {


            if (returnModel.length > 0) {
              this.dynamicField[index].options = returnModel;
            } else {
              this.dynamicField[index].validations = [];
            }
          }
        }
      },
      error => {
        this._alertService.error(error.message);
      }
    );
  }

  GetUserTypeByCommunicationType() {
    this.communicationUserTypeModel = [];
    this._communicationTypeService.GetUserTypeByCommunicationType(this.communicationType).subscribe(
      response => {
        if (response.IsSuccess) {
          let resItem: CommunicationUserTypeViewModel[] = response.Data;
          this.communicationUserTypeModel = resItem;
          this.checkDefaultList();
          this.GetAttachmentTypeList();
        }

      }, error => {
      }
    );
  }

  checkDefaultList() {
    this._communicationTypeFieldsMasterService.GetDefaultFieldsForCommunication(0, this.communicationType).subscribe(
      data => {
        if (data.IsSuccess) {
          let defaultFieldModel = <CommunicationTypeDefaultFieldsMasterModel[]>data.Data;

          if (defaultFieldModel.length > 0) {
            this.defaultMaxField = defaultFieldModel.length + 1;
            this.appComponent.setPageLayout(this.communicationUserTypeModel[this.communicationUserTypeModel.findIndex(item => item.EnumValue == this.communicationType)].CommunicationType + " Communication", "keyboard_backspace", "Back To List", "communications-list/" + this.communicationType);

          }
          this.communicationUserTypeModel.forEach(elementComm => {
            if (elementComm.UserType == this.communicationInitiateByEnum.V2D || elementComm.UserType == this.communicationInitiateByEnum.D2D) {

              defaultFieldModel.forEach(element => {
                switch (String(element.FieldCode)) {

                  case this.defaultFieldEnum.VendorBank:
                    this.defaultFieldVisibleModel.IsShowVendorBankField = true;
                    this.defaultFieldVisibleModel.VendorBankSortOrder = element.SortOrder as number;
                    this.defaultFieldVisibleModel.VendorBankLabel = element.FieldName;
                    this.defaultFieldVisibleModel.IsRequiredVendorBankField = element.IsRequired;
                    if (element.IsRequired) {
                      this.frmGrp.get("BankCode").setValidators([Validators.required]);
                      this.frmGrp.get("BankCode").updateValueAndValidity();
                    }

                    break;

                  case this.defaultFieldEnum.Project:
                    this.defaultFieldVisibleModel.IsShowProjectField = true;
                    this.defaultFieldVisibleModel.ProjectSortOrder = element.SortOrder as number;
                    this.defaultFieldVisibleModel.ProjectLabel = element.FieldName;
                    this.defaultFieldVisibleModel.IsRequiredProjectField = element.IsRequired;

                    if (element.IsRequired) {
                      this.frmGrp.get("ProjectCode").setValidators([Validators.required]);
                      this.frmGrp.get("ProjectCode").updateValueAndValidity();
                    }


                    break;

                  case this.defaultFieldEnum.Office:
                    this.defaultFieldVisibleModel.IsShowOfficeField = true;
                    this.defaultFieldVisibleModel.OfficeSortOrder = element.SortOrder as number;
                    this.defaultFieldVisibleModel.OfficeLabel = element.FieldName;
                    this.defaultFieldVisibleModel.IsRequiredOfficeField = element.IsRequired;

                    if (element.IsRequired) {
                      this.frmGrp.get("OfficeCode").setValidators([Validators.required]);
                      this.frmGrp.get("OfficeCode").updateValueAndValidity();
                    }

                    break;

                  case this.defaultFieldEnum.Department:
                    this.defaultFieldVisibleModel.IsShowDepartmentField = true;
                    this.defaultFieldVisibleModel.DepartmentSortOrder = element.SortOrder as number;
                    this.defaultFieldVisibleModel.DepartmentLabel = element.FieldName;
                    this.defaultFieldVisibleModel.IsRequiredDepartmentField = element.IsRequired;

                    if (element.IsRequired) {
                      this.frmGrp.get("DepartmentCode").setValidators([Validators.required]);
                      this.frmGrp.get("DepartmentCode").updateValueAndValidity();
                    }

                    break;

                  case this.defaultFieldEnum.District:
                    this.defaultFieldVisibleModel.IsShowDistrictField = true;
                    this.defaultFieldVisibleModel.DistrictSortOrder = element.SortOrder as number;
                    this.defaultFieldVisibleModel.DistrictLabel = element.FieldName;
                    this.defaultFieldVisibleModel.IsRequiredDistrictField = element.IsRequired;

                    if (element.IsRequired) {
                      this.frmGrp.get("DistrictCode").setValidators([Validators.required]);
                      this.frmGrp.get("DistrictCode").updateValueAndValidity();
                    }

                    break;

                  case this.defaultFieldEnum.WorkOrder:
                    this.defaultFieldVisibleModel.IsShowWorkOrderField = true;
                    this.defaultFieldVisibleModel.WorkOrderSortOrder = element.SortOrder as number;
                    this.defaultFieldVisibleModel.WorkOrderLabel = element.FieldName;
                    this.defaultFieldVisibleModel.IsRequiredWorkOrderField = element.IsRequired;

                    if (element.IsRequired) {
                      this.frmGrp.get("WorkOrderCode").setValidators([Validators.required]);
                      this.frmGrp.get("WorkOrderCode").updateValueAndValidity();
                    }

                    break;

                  case this.defaultFieldEnum.VendorBank:
                    this.defaultFieldVisibleModel.IsShowVendorBankField = true;
                    this.defaultFieldVisibleModel.VendorSortOrder = element.SortOrder as number;
                    this.defaultFieldVisibleModel.VendorLabel = element.FieldName;
                    this.defaultFieldVisibleModel.IsRequiredVendorBankField = element.IsRequired;


                    if (element.IsRequired) {
                      this.frmGrp.get("BankCode").setValidators([Validators.required]);
                      this.frmGrp.get("BankCode").updateValueAndValidity();
                    }

                    break;

                  case this.defaultFieldEnum.Subject:
                    this.defaultFieldVisibleModel.IsShowSubjectField = true;
                    this.defaultFieldVisibleModel.SubjectSortOrder = element.SortOrder as number;
                    this.defaultFieldVisibleModel.SubjectLabel = element.FieldName;
                    this.defaultFieldVisibleModel.IsRequiredSubjectField = element.IsRequired;

                    if (element.IsRequired) {
                      this.frmGrp.get("Subject").setValidators([Validators.required]);
                      this.frmGrp.get("Subject").updateValueAndValidity();
                    }

                    break;

                  case this.defaultFieldEnum.Description:
                    this.defaultFieldVisibleModel.IsShowDescriptionField = true;
                    this.defaultFieldVisibleModel.DescriptionSortOrder = element.SortOrder as number;
                    this.defaultFieldVisibleModel.DescriptionLabel = element.FieldName;
                    this.defaultFieldVisibleModel.IsRequiredDescriptionField = element.IsRequired;

                    if (element.IsRequired) {
                      this.frmGrp.get("Description").setValidators([Validators.required]);
                      this.frmGrp.get("Description").updateValueAndValidity();
                    }

                    break;

                  case this.defaultFieldEnum.Amount:
                    this.defaultFieldVisibleModel.IsShowAmountField = true;
                    this.defaultFieldVisibleModel.AmountSortOrder = element.SortOrder as number;
                    this.defaultFieldVisibleModel.AmountLabel = element.FieldName;
                    this.defaultFieldVisibleModel.IsRequiredAmountField = element.IsRequired;

                    if (element.IsRequired) {
                      this.frmGrp.get("Amount").setValidators(Validators.compose([Validators.required, Validators.maxLength(100)]));
                      this.frmGrp.get("Amount").updateValueAndValidity();
                    }

                    break;

                  case this.defaultFieldEnum.PreviouseCommunication:
                    this.defaultFieldVisibleModel.PreviouseCommunicationSortOrder = element.SortOrder as number;
                    this.defaultFieldVisibleModel.PreviouseCommunicationLabel = element.FieldName;
                    this.defaultFieldVisibleModel.IsShowPreviouseCommunicationField = true;
                    this.defaultFieldVisibleModel.IsRequiredPreviouseCommunicationField = element.IsRequired;

                    break;

                  case this.defaultFieldEnum.CreatedByOffice:
                    if (elementComm.UserType == this.communicationInitiateByEnum.D2D) {

                      if ((this.loginUserDetail.BaseUserType == UserTypeEnum.HOD)) {
                        this.defaultFieldVisibleModel.IsShowCreatedByOfficeField = true;
                        this.defaultFieldVisibleModel.CreatedByOfficeSortOrder = element.SortOrder as number;
                        this.defaultFieldVisibleModel.CreatedByOfficeLabel = element.FieldName;

                        this.defaultFieldVisibleModel.IsRequiredCreatedByOfficeField = true;

                        this.frmGrp.get("CreatedByOfficeCode").setValidators([Validators.required]);
                        this.frmGrp.get("CreatedByOfficeCode").updateValueAndValidity();

                        this.GetLoginUserOffice();

                      } else if (this.loginUserDetail.OfficeCodes.split(",").length > 1) {
                        this.defaultFieldVisibleModel.IsShowCreatedByOfficeField = true;
                        this.defaultFieldVisibleModel.CreatedByOfficeSortOrder = element.SortOrder as number;
                        this.defaultFieldVisibleModel.CreatedByOfficeLabel = element.FieldName;
                        this.defaultFieldVisibleModel.IsRequiredCreatedByOfficeField = true;

                        this.frmGrp.get("CreatedByOfficeCode").setValidators([Validators.required]);
                        this.frmGrp.get("CreatedByOfficeCode").updateValueAndValidity();

                        this.GetLoginUserOffice();

                      }
                      else {
                        this.model.CreatedByOfficeCode = this.loginUserDetail.OfficeCodes.toString();
                      }
                    }

                    break;

                  case this.defaultFieldEnum.Attachments:
                    this.defaultFieldVisibleModel.AttachmentsSortOrder = element.SortOrder as number;
                    this.defaultFieldVisibleModel.AttachmentsLabel = element.FieldName;
                    this.defaultFieldVisibleModel.IsShowAttachmentsField = true;
                    this.defaultFieldVisibleModel.IsRequiredAttachmentsField = true;

                    break;


                  default:
                    break;
                }
              });
            }

            else if (elementComm.UserType == this.communicationInitiateByEnum.D2V) {
              defaultFieldModel.forEach(element => {
                switch (String(element.FieldCode)) {

                  case this.defaultFieldEnum.Office:
                    if (this.loginUserDetail.BaseUserType == UserTypeEnum.HOD || this.loginUserDetail.OfficeCodes.split(",").length > 1) {
                      this.defaultFieldVisibleModel.IsShowOfficeField = true;
                      this.defaultFieldVisibleModel.OfficeSortOrder = element.SortOrder as number;
                      this.defaultFieldVisibleModel.OfficeLabel = element.FieldName;
                      this.defaultFieldVisibleModel.IsRequiredOfficeField = element.IsRequired;


                      if (element.IsRequired) {
                        this.frmGrp.get("OfficeCode").setValidators([Validators.required]);
                        this.frmGrp.get("OfficeCode").updateValueAndValidity();
                      }

                    }
                    break;

                  case this.defaultFieldEnum.Department:

                    if (this.loginUserDetail.BaseUserType == UserTypeEnum.HOD || this.loginUserDetail.OfficeCodes.split(",").length > 1) {
                      this.defaultFieldVisibleModel.IsShowDepartmentField = true;
                      this.defaultFieldVisibleModel.DepartmentSortOrder = element.SortOrder as number;
                      this.defaultFieldVisibleModel.DepartmentLabel = element.FieldName;
                      this.defaultFieldVisibleModel.IsRequiredDepartmentField = element.IsRequired;


                      if (element.IsRequired) {
                        this.frmGrp.get("DepartmentCode").setValidators([Validators.required]);
                        this.frmGrp.get("DepartmentCode").updateValueAndValidity();
                      }


                    }
                    break;

                  case this.defaultFieldEnum.District:
                    if (this.loginUserDetail.BaseUserType == UserTypeEnum.HOD || this.loginUserDetail.OfficeCodes.split(",").length > 1) {
                      this.defaultFieldVisibleModel.IsShowDistrictField = true;
                      this.defaultFieldVisibleModel.DistrictSortOrder = element.SortOrder as number;
                      this.defaultFieldVisibleModel.DistrictLabel = element.FieldName;
                      this.defaultFieldVisibleModel.IsRequiredDistrictField = element.IsRequired;


                      if (element.IsRequired) {
                        this.frmGrp.get("DistrictCode").setValidators([Validators.required]);
                        this.frmGrp.get("DistrictCode").updateValueAndValidity();
                      }

                    }
                    break;


                  case this.defaultFieldEnum.Vendor:

                    this.defaultFieldVisibleModel.IsShowVendorField = true;
                    this.defaultFieldVisibleModel.VendorSortOrder = element.SortOrder as number;
                    this.defaultFieldVisibleModel.VendorLabel = element.FieldName;
                    this.defaultFieldVisibleModel.IsRequiredVendorField = element.IsRequired;


                    if (element.IsRequired) {
                      this.frmGrp.get("VendorCode").setValidators([Validators.required]);
                      this.frmGrp.get("VendorCode").updateValueAndValidity();
                    }


                    break;

                  case this.defaultFieldEnum.Project:
                    this.defaultFieldVisibleModel.IsShowProjectField = true;
                    this.defaultFieldVisibleModel.ProjectSortOrder = element.SortOrder as number;
                    this.defaultFieldVisibleModel.ProjectLabel = element.FieldName;
                    this.defaultFieldVisibleModel.IsRequiredProjectField = element.IsRequired;


                    if (element.IsRequired) {
                      this.frmGrp.get("ProjectCode").setValidators([Validators.required]);
                      this.frmGrp.get("ProjectCode").updateValueAndValidity();
                    }


                    break;

                  case this.defaultFieldEnum.WorkOrder:
                    this.defaultFieldVisibleModel.IsShowWorkOrderField = true;
                    this.defaultFieldVisibleModel.WorkOrderSortOrder = element.SortOrder as number;
                    this.defaultFieldVisibleModel.WorkOrderLabel = element.FieldName;

                    this.defaultFieldVisibleModel.IsRequiredWorkOrderField = element.IsRequired;


                    if (element.IsRequired) {
                      this.frmGrp.get("WorkOrderCode").setValidators([Validators.required]);
                      this.frmGrp.get("WorkOrderCode").updateValueAndValidity();
                    }

                    break;

                  case this.defaultFieldEnum.VendorBank:
                    this.defaultFieldVisibleModel.IsShowVendorBankField = true;
                    this.defaultFieldVisibleModel.VendorBankSortOrder = element.SortOrder as number;
                    this.defaultFieldVisibleModel.VendorBankLabel = element.FieldName;
                    this.defaultFieldVisibleModel.IsRequiredVendorBankField = element.IsRequired;


                    if (element.IsRequired) {
                      this.frmGrp.get("BankCode").setValidators([Validators.required]);
                      this.frmGrp.get("BankCode").updateValueAndValidity();
                    }

                    break;


                  case this.defaultFieldEnum.Subject:
                    this.defaultFieldVisibleModel.IsShowSubjectField = true;
                    this.defaultFieldVisibleModel.SubjectSortOrder = element.SortOrder as number;
                    this.defaultFieldVisibleModel.SubjectLabel = element.FieldName;
                    this.defaultFieldVisibleModel.IsRequiredSubjectField = element.IsRequired;

                    if (element.IsRequired) {
                      this.frmGrp.get("Subject").setValidators([Validators.required]);
                      this.frmGrp.get("Subject").updateValueAndValidity();
                    }

                    break;

                  case this.defaultFieldEnum.Description:
                    this.defaultFieldVisibleModel.IsShowDescriptionField = true;
                    this.defaultFieldVisibleModel.DescriptionSortOrder = element.SortOrder as number;
                    this.defaultFieldVisibleModel.DescriptionLabel = element.FieldName;
                    this.defaultFieldVisibleModel.IsRequiredDescriptionField = element.IsRequired;


                    if (element.IsRequired) {
                      this.frmGrp.get("Description").setValidators([Validators.required]);
                      this.frmGrp.get("Description").updateValueAndValidity();
                    }

                    break;


                  case this.defaultFieldEnum.Amount:
                    this.defaultFieldVisibleModel.IsShowAmountField = true;
                    this.defaultFieldVisibleModel.AmountSortOrder = element.SortOrder as number;
                    this.defaultFieldVisibleModel.AmountLabel = element.FieldName;
                    this.defaultFieldVisibleModel.IsRequiredAmountField = element.IsRequired;


                    if (element.IsRequired) {
                      this.frmGrp.get("Amount").setValidators(Validators.compose([Validators.required, Validators.maxLength(100)]));
                      this.frmGrp.get("Amount").updateValueAndValidity();
                    }

                    break;

                  //for showing Created by office
                  case this.defaultFieldEnum.CreatedByOffice:
                    if (this.loginUserDetail.BaseUserType == UserTypeEnum.HOD) {

                      this.defaultFieldVisibleModel.IsShowCreatedByOfficeField = true;
                      this.defaultFieldVisibleModel.CreatedByOfficeSortOrder = element.SortOrder as number;
                      this.defaultFieldVisibleModel.CreatedByOfficeLabel = element.FieldName;
                      this.defaultFieldVisibleModel.IsRequiredCreatedByOfficeField = element.IsRequired;


                      if (element.IsRequired) {
                        this.frmGrp.get("CreatedByOfficeCode").setValidators([Validators.required]);
                        this.frmGrp.get("CreatedByOfficeCode").updateValueAndValidity();
                      }

                      this.GetLoginUserOffice();
                    } else if (this.loginUserDetail.OfficeCodes.split(",").length > 1) {
                      this.defaultFieldVisibleModel.IsShowCreatedByOfficeField = true;
                      this.defaultFieldVisibleModel.CreatedByOfficeSortOrder = element.SortOrder as number;
                      this.defaultFieldVisibleModel.CreatedByOfficeLabel = element.FieldName;
                      this.defaultFieldVisibleModel.IsRequiredCreatedByOfficeField = element.IsRequired;


                      if (element.IsRequired) {

                        this.frmGrp.get("CreatedByOfficeCode").setValidators([Validators.required]);
                        this.frmGrp.get("CreatedByOfficeCode").updateValueAndValidity();
                      }

                      this.GetLoginUserOffice();

                    } else {
                      this.model.CreatedByOfficeCode = this.loginUserDetail.OfficeCodes.toString();
                    }

                    break;

                  case this.defaultFieldEnum.PreviouseCommunication:
                    this.defaultFieldVisibleModel.PreviouseCommunicationSortOrder = element.SortOrder as number;
                    this.defaultFieldVisibleModel.PreviouseCommunicationLabel = element.FieldName;
                    this.defaultFieldVisibleModel.IsShowPreviouseCommunicationField = true;
                    this.defaultFieldVisibleModel.IsRequiredPreviouseCommunicationField = element.IsRequired;


                    break;

                  case this.defaultFieldEnum.Attachments:
                    this.defaultFieldVisibleModel.AttachmentsSortOrder = element.SortOrder as number;
                    this.defaultFieldVisibleModel.AttachmentsLabel = element.FieldName;
                    this.defaultFieldVisibleModel.IsShowAttachmentsField = true;
                    this.defaultFieldVisibleModel.IsRequiredAttachmentsField = element.IsRequired;


                    break;



                  default:
                    break;
                }
              });
            }
          });

          // call function when page config field set

          this.onPageLoadData();

        } else {
          this._alertService.error(data.Message);
        }
      },
      error => {
        this._alertService.error(error.message);
      }
    );
  }


  GetLoginUserOffice() {

    this._userService.GetUserOfficeList().subscribe(
      data => {

        if (data.IsSuccess) {

          this.ddlCreatedByOffice = <DdlItemModel[]>data.Data;
        }
      },
      error => {

      }
    );
  }


  //#endregion

  //#region <<Detail Section>>
  getDetail() {

    this._communicationService.Detail(this.recordId).subscribe(
      data => {
        if (data.IsSuccess) {
          this.detailViewModel = <CommunicationTransactionViewModel>data.Data;

          this.isReturned = this.detailViewModel.IsReturned == 1 ? true : false;
          this.IsReadOnlyFields = (this.detailViewModel.CurrentStatus == StatusEnum.RETURN) || this.isReturned ? true : false;

          this.BindDetail();
          this.GetDistrict();
          this.GetBankByVendor();


        }
      }
    );
  }

  BindDetail() {
    if (this.detailViewModel) {
      this.model.CommunicationCode = this.detailViewModel.CommunicationCode;
      this.model.WorkOrderCode = this.detailViewModel.WorkOrderCode != undefined ? String(this.detailViewModel.WorkOrderCode) : undefined;
      this.model.CommunicationTypeCode = this.detailViewModel.CommunicationTypeCode;
      this.model.OfficeCode = this.detailViewModel.OfficeCode != undefined ? String(this.detailViewModel.OfficeCode) : undefined;
      this.model.DepartmentCode = this.detailViewModel.DepartmentCode != undefined ? String(this.detailViewModel.DepartmentCode) : undefined;
      this.model.DistrictCode = this.detailViewModel.DistrictCode != undefined ? String(this.detailViewModel.DistrictCode) : undefined;
      this.model.BankCode = this.detailViewModel.BankCode != undefined ? String(this.detailViewModel.BankCode) : undefined;
      this.model.VendorUserCode = this.detailViewModel.VendorUserCode != undefined ? String(this.detailViewModel.VendorUserCode) : undefined;
      this.model.Status = this.detailViewModel.CurrentStatus;
      this.model.ProjectCode = this.detailViewModel.projectCode != undefined ? String(this.detailViewModel.projectCode) : undefined;
      this.model.IsActive = this.detailViewModel.IsActive;
      this.model.IsDeleted = this.detailViewModel.IsDeleted;
      this.model.CreatedBy = this.detailViewModel.CreatedBy;
      this.model.CreatedOn = this.detailViewModel.CreatedOn;
      this.model.Summary = this.detailViewModel.Summary;
      this.model.Subject = this.detailViewModel.Subject;
      this.model.Description = this.detailViewModel.Description;
      this.model.Amount = this.detailViewModel.Amount;
      this.model.CreatedByOfficeCode = this.detailViewModel.CreatedByOfficeCode ? String(this.detailViewModel.CreatedByOfficeCode) : undefined;
      this.model.PreviouseCommunicationCode = this.detailViewModel.PreviouseCommunicationCode;
      this.model.SummaryDocumentList = this.detailViewModel.SummaryDocumentList;

      //    this.bindCommunicationField(this.communicationFieldValueViewModel);
      if (this.detailViewModel.DocumentList != undefined) {
        this.detailViewModel.DocumentList.forEach(ele => {
          let index = this.tempAttachmentsModel.findIndex(x => x.AttachmentTypeCode == ele.AttachmentTypeCode);
          if (index >= 0) {
            this.tempAttachmentsModel[index].DocName = ele.DocName;
            this.tempAttachmentsModel[index].DocFakePath = ele.DocName;
            this.tempAttachmentsModel[index].DocPath = ele.DocPath;
            this.tempAttachmentsModel[index].Ext = ele.Ext;
            this.tempAttachmentsModel[index].Description = ele.Description;
            this.tempAttachmentsModel[index].DocumentCode = ele.DocumentCode
          } else {
            let item = new CommunicationTransactionDocumentViewModel();
            item.AttachmentType = ele.AttachmentType;
            item.AttachmentTypeCode = ele.AttachmentTypeCode;
            item.DocName = ele.DocName;
            item.DocFakePath = ele.DocName;
            item.DocPath = ele.DocPath;
            item.Ext = ele.Ext;
            item.Description = ele.Description;
            item.DocumentCode = ele.DocumentCode;
            this.tempAttachmentsModel.push(item);
          }
        });
      }

    }
  }

  onPageLoadData() {
    this.GetDDLList();
    if (this.recordId > 0) {
      this.getDetail();

    } else {
      this.GetDistrict();
      this.GetBankByVendor();
    }
    this.GetDynamicField();


  }
  //#endregion

  //#region  <<File Upload and delete>>
  handleFileInput(files: FileList) {
    let count = 0;
    if (this.summaryDocumentList.length > 0) {
      count = this.summaryDocumentList.length;
    }

    for (let index = 0; index < files.length; index++) {
      if (files.item(index).type.match("application/pdf")) {
        if (files.item(index).size < AppSetting.FileSizeLimit.PDF) {
          const reader = new FileReader();
          reader.onload = (e: any) => {
            this.summaryDocumentList.push(new CommunicationSummaryDocumentPostModel()
            );
            this.summaryDocumentList[count + index].DocName = <string>reader.result;
            this.summaryDocumentList[count + index].DocPath = <string>reader.result;

            this.summaryDocumentList[count + index].DocName = files[index].name;
            this.summaryDocumentList[count + index].Extension = files[index].name.split(".")[1];
            this.summaryDocumentList[count + index].DocPath = <string>reader.result;
          };
          reader.readAsDataURL(files[index]);
        }
        else {
          this.summaryDocumentList = [];
          this.fileValidationMsg = AppSetting.FileSizeLimit.PDFErrorMSG;
          return;
        }
      }
      else {
        this.summaryDocumentList = [];
        this.fileValidationMsg = "Only *pdf";
        return;
      }
    }
    this.fileValidationMsg = "";
  }

  deleteWorkOrderDocument(documentCode: number, index: number) {
    this.summaryDocumentList.splice(index, 1);
  }
  //#endregion

  //#region <<Previous Communication Link>>
  searchPreviouseCommunication() {
    var k = this.model;
    let searchModel = new GlobalFilterSearchModel();
    searchModel.CommunicationTypeCode = String(this.communicationTypeCode);
    searchModel.CommunicationTypeEnum = this.communicationType;
    searchModel.DepartmentCode = this.model.DepartmentCode ? String(this.model.DepartmentCode) : undefined;
    searchModel.DistrictCode = this.model.DistrictCode ? String(this.model.DistrictCode) : undefined;
    searchModel.OfficeCode = this.model.OfficeCode ? String(this.model.OfficeCode) : undefined;
    searchModel.VendorUserCode = this.model.VendorUserCode ? String(this.model.VendorUserCode) : undefined;
    searchModel.ProjectCode = this.model.ProjectCode ? String(this.model.ProjectCode) : undefined;
    searchModel.WorkOrderCode = this.model.WorkOrderCode ? String(this.model.WorkOrderCode) : undefined;


    const dialogRef = this._dialog.open(SearchCommunicationDialogComponent, {
      width: "90%",
      data: searchModel
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        let data = result as CommunicationModel;
        this.model.PreviouseCommunicationCode = data.CommunicationCode;
        this.model.PreviouseCommunicationRefNo = data.RefNo;
        // this.model.DepartmentCode = data.DepartmentCode;
        // this.model.OfficeCode = data.OfficeCode;
        // this.model.DistrictCode = data.DistrictCode;
      }
    });
  }
  //#endregion


}
