import { FileUploaderModel } from "../../file-uploader.model";

export class ChiefMinisterProfileUpdateRequestModel {

    public Id: number = 0;

    public IsCodeOfConduct: boolean = false;

    public CodeOfConductFromDate: string = "";

    public CodeOfConductToDate: string = "";

    public PdfFIleName: string = "";

}