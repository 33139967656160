import { Injectable } from "@angular/core";
import { AppSetting } from "../../model/appsetting.model";
import { IndexModel } from "../../model/common.model";
import { Dictionary } from "../../model/dictionary.model";
import { NotificationTemplateTypeModel } from "../../model/master/notification-template-type.model";
import { BaseService } from "../base.service";

@Injectable({
    providedIn: 'root'
  })
export class NotificationTemplateTypeService {
    constructor(private readonly _baseService: BaseService) {}
  GetList(model: IndexModel) {
    //
    const result = this._baseService.post(
      AppSetting.NotificationTypeListUrl,
      model
    );
    return result;
  }

  GetById(id) {
    const result = this._baseService.get(
      AppSetting.NotificationTypeByIdUrl + id,
      null
    );
    return result;
  }

  Edit(model: NotificationTemplateTypeModel) {
    const result = this._baseService.post(
      AppSetting.NotificationTypeEditUrl,
      model
    );
    return result;
  }
  Add(model: NotificationTemplateTypeModel) {
    const result = this._baseService.post(
      AppSetting.NotificationTypeAddUrl,
      model
    );
    return result;
  }
  GetNotificationContentKey(){
    const result = this._baseService.get(
      AppSetting.GetNotificationContentKeyUrl
      
    );
    return result;

  }


 
  checkEnumValueExist(enumValue: number,id:number){
    const param = new Dictionary<any>();
    param.Add("enumValue", enumValue);
    param.Add("id", id);
    
    return this._baseService.get(AppSetting.IsExistNotificationTypeEnumValueUrl ,param);
  }

}
