import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import { AuthenticationService } from 'src/app/shared/service/authentication.service';
import { GlobalMessagesModel } from 'src/app/shared/model/common-messages.model';
import { AppComponent } from 'src/app/app.component';
import { AlertService } from 'src/app/shared/service/alert.service';
import { CommonService } from 'src/app/shared/service/common.service';
import { UserViewModel } from 'src/app/shared/model/user.model';
import { isNullOrUndefined } from 'util';
import { CommunicationAttachmentTypeModel } from 'src/app/shared/model/master/communication-attachment-type.model';
import { CommunicationAttachmentTypeService } from 'src/app/shared/service/master/communication-attachment-type.service';
import { UserTypeEnum } from 'src/app/shared/enum/fixed-values.enum';

@Component({
  selector: 'app-add-update-communication-attachment-type',
  templateUrl: './add-update-communication-attachment-type.component.html',
  styleUrls: ['./add-update-communication-attachment-type.component.scss']
})

export class AddUpdateCommunicationAttachmentTypeComponent implements OnInit {

  loginUserDetail: UserViewModel;
  fromGroup: FormGroup;
  model: CommunicationAttachmentTypeModel;
  RecordId: number = 0;
  loginUserCode: number;
  oldAttachmentType: string;

  constructor(
    private readonly fb: FormBuilder,
    private readonly appComponnet: AppComponent,
    public readonly _commonService: CommonService,
    private readonly _alertService: AlertService,
    private readonly _router: Router,
    private _route: ActivatedRoute,
    private readonly _communicationAttachmentTypeService: CommunicationAttachmentTypeService,
    private readonly _authService: AuthenticationService
  ) {
    if (!isNullOrUndefined(this._route.snapshot.params.id)) {
      this.RecordId = this._route.snapshot.params.id;
    }
    var pageHeading = (this.RecordId == 0 ? "Add" : "Update") + " Communication Attachment Type :";
    this.appComponnet.setPageLayout(pageHeading, "keyboard_backspace", "Back To List", "master/communication-attachment-type");
    this.model = new CommunicationAttachmentTypeModel();
    this.loginUserDetail = this._authService.GetCurrentUserDetail().UserViewModel;
  }

  ngOnInit() {
    this.formGroupInit();
    if (this.RecordId != 0) {
      this.getDetail();
    }
  }

  formGroupInit() {
    this.fromGroup = this.fb.group({
      AttachmentType: [null, Validators.compose([Validators.required, Validators.maxLength(100)])],
      AttachmentTypeHindi: [null, Validators.compose([Validators.required, Validators.maxLength(100)])]
    });
  }

  getDetail() {
    this._communicationAttachmentTypeService.Detail(this.RecordId).subscribe(
      data => {
        if (data.IsSuccess) {
          this.model = <CommunicationAttachmentTypeModel>data.Data;
          this.oldAttachmentType = this.model.AttachmentType;
        }
      },
      error => {
        this._alertService.error(error.message);
      }
    );
  }

  saveClick() {
    this.fromGroup.markAllAsTouched();
    if (this.fromGroup.valid) {

      if (this.RecordId == 0) {
        this.model.CreatedBy = this.loginUserDetail.UserCode;
      }
      else {
        this.model.ModifiedBy = this.loginUserDetail.UserCode;
      }

      this._communicationAttachmentTypeService.AddUpdate(this.RecordId, this.model).subscribe(
        data => {
          if (data) {
            if (data.IsSuccess) {
              this._alertService.success(this.RecordId == 0 ? GlobalMessagesModel.saveSuccess : GlobalMessagesModel.updateSuccess);
              this._router.navigate(["master/communication-attachment-type"]);
            } else {
              this._commonService.ScrollingTop();
              this._alertService.error(this.RecordId == 0 ? GlobalMessagesModel.saveError : GlobalMessagesModel.updateError);
            }
          } else {
            this._commonService.ScrollingTop();
            this._alertService.error(GlobalMessagesModel.InternalError);
          }
        },
        error => {
          this._commonService.ScrollingTop();
          this._alertService.error(GlobalMessagesModel.InternalError);
        }
      );
    }
  }

  IsAttachmentTypeExist(Event) {
    if (this.oldAttachmentType != Event.currentTarget.value) {
      this._communicationAttachmentTypeService.IsCommunicationAttachmentTypeExist(Event.currentTarget.value).subscribe(result => {
        if (result.IsSuccess) {
          let UserName = <boolean>result.Data
          if (UserName == true) {
            this.model.AttachmentType = null;
            this._alertService.error("Attachment Type is already exist!");
          }
        }
      }, error => {
        this._commonService.ScrollingTop();
        this._alertService.error(error.message);
      });
    }
  }


}
