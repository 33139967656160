import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AppComponent } from 'src/app/app.component';
import { StateEnum } from 'src/app/shared/enum/fixed-values.enum';
import { GlobalMessagesModel } from 'src/app/shared/model/common-messages.model';
import { DdlItemModel } from 'src/app/shared/model/commonddl.model';
import { AdditionalUserPermissionModel, AdditionalUserPermissionViewModel } from 'src/app/shared/model/user-permission.model';
import { UserTypeModel } from 'src/app/shared/model/user-type.model';
import { UserViewModel } from 'src/app/shared/model/user.model';
import { AlertService } from 'src/app/shared/service/alert.service';
import { AuthenticationService } from 'src/app/shared/service/authentication.service';
import { CommonService } from 'src/app/shared/service/common.service';
import { UserPermissionService } from 'src/app/shared/service/user-permission.service';
import { UserTypeService } from 'src/app/shared/service/user-type.service';
import { UserService } from 'src/app/shared/service/user.service';

@Component({
  selector: 'app-add-update-additional-permission',
  templateUrl: './add-update-additional-permission.component.html',
  styleUrls: ['./add-update-additional-permission.component.scss']
})

export class AddUpdateAdditionalPermissionComponent implements OnInit {

  id: number;
  fromGroup: FormGroup;
  userTypeList: UserTypeModel[] = [];
  loginData: UserViewModel = new UserViewModel();
  model: AdditionalUserPermissionModel = new AdditionalUserPermissionModel();

  ddlOfficeDepartment: DdlItemModel[] = [];
  fromUser_ddlDistrict: DdlItemModel[] = [];
  toUser_ddlDistrict: DdlItemModel[] = [];
  fromUser_ddlOffice: DdlItemModel[] = [];
  toUser_ddlOffice: DdlItemModel[] = [];
  ddlFromUsers: DdlItemModel[] = [];
  ddlToUsers: DdlItemModel[] = [];

  constructor(
    private readonly fb: FormBuilder,
    private readonly _alertService: AlertService,
    private readonly _permissionService: UserPermissionService,
    private readonly _authService: AuthenticationService,
    private readonly _appComponent: AppComponent,
    public readonly _commonService: CommonService,
    private readonly _route: ActivatedRoute,
    private readonly _router: Router,
    private readonly _userTypeService: UserTypeService,
    private readonly _userService: UserService
  ) {
    this.model = new AdditionalUserPermissionModel();
    this.id = _route.snapshot.params.id;
    this.model.IsActive = true;
    let title = 'Assign Additional Permission :';
    if (this.id > 0) {
      title = 'Update Additional Permission :';
      this.GetById();
    }
    this._appComponent.setPageLayout(title, "keyboard_backspace", "Back To List", "/master/permission/user-additional-permission");
    this.loginData = this._authService.GetCurrentUserDetail().UserViewModel;
    this.getDepartments();
    this.getFromUserDistrictList();
    this.getToUserDistrictList();
  }

  ngOnInit(): void {
    this.formGroupInit();
    this.getDownLevelUserType();
  }

  GetById() {
    this._permissionService.UserAdditionalPermissionGetById(this.id).subscribe(data => {
      if (data.IsSuccess) {
        let Detail = <AdditionalUserPermissionViewModel>data.Data;
        this.model.Id = Detail.Id;
        this.model.FromDate = new Date(Detail.FromDate);
        this.model.ToDate = new Date(Detail.ToDate);
        this.model.Remark = Detail.Remark;
        this.model.CreatedBy = Detail.CreatedBy;
        this.model.CreatedOn = new Date(Detail.CreatedOn);
        this.model.ModifiedBy = Detail.ModifiedBy;
        this.model.ModifiedOn = new Date(Detail.ModifiedOn);
        this.model.IsActive = Detail.IsActive;
        this.model.IsDelete = Detail.IsDelete;
        this.model.IsLocked = Detail.IsLocked;
        this.model.filepath = Detail.filepath;
        this.model.FromUserCode = String(Detail.FromUserCode);
        this.model.ToUserCode = String(Detail.ToUserCode);
        this.model.UserTypeCode = String(Detail.FromUserTypeCode);
        if (Detail.FromUserDeptCodes) {
          this.model.FromUserDepartmentCode = Detail.FromUserDeptCodes;
          this.onFromDepartmentChange();
        }
        if (Detail.ToUserDeptCodes) {
          this.model.ToUserDepartmentCode = Detail.ToUserDeptCodes;
          this.onToDepartmentChange();
        }
      }
    }, error => {
      this.model = new AdditionalUserPermissionModel();
      this._alertService.error(error.message);
    });
  }

  formGroupInit() {
    this.fromGroup = this.fb.group({
      UserTypeCode: [null, Validators.required],
      FromDate: [null, Validators.required],
      ToDate: [null, null],
      FromUserDepartmentCode: [null, null],
      FromUserDistrictCode: [null, null],
      FromUserOfficeCode: [null, null],
      FromUserCode: [null, Validators.required],
      ToUserDepartmentCode: [null, null],
      ToUserDistrictCode: [null, null],
      ToUserOfficeCode: [null, null],
      ToUserCode: [null, Validators.required],
      Remark: [null, null],
      filepath: [null, null],
      IsActive: [false, null]
    });

    this.fromGroup.get('IsActive').disable();
  }

  handleFileafter(event: any) {
    const reader = new FileReader();
    reader.onload = (event: any) => {
      this.model.filepath = event.target.result;
    };
    reader.readAsDataURL(event.target.files.item(0));
  }

  download(URL) {
    const linkSource = URL;
    const downloadLink = document.createElement("a");
    const fileName = 'Order';

    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.target = "blank";
    downloadLink.click();
  }

  SaveClick() {
    this.fromGroup.markAllAsTouched();
    if (this.fromGroup.valid) {
      this.model.IsDelete = false;
      if (this.model.Id > 0) {
        this.model.ModifiedBy = this.loginData.UserCode;
        this._permissionService.UserAdditionalPermissionAddUpdate(this.model).subscribe(data => {
          if (data.IsSuccess) {
            this._alertService.success(GlobalMessagesModel.updateSuccess);
            this._router.navigate(['/master/permission/user-additional-permission']);
          }
          else {
            this._alertService.error(data.Message);
          }
        }, error => {
          this._alertService.error(GlobalMessagesModel.updateError);
        });
      }
      else {
        this.model.CreatedBy = this.loginData.UserCode;
        this.model.Id = 0;
        this._permissionService.UserAdditionalPermissionAddUpdate(this.model).subscribe(data => {
          if (data.IsSuccess) {
            this._alertService.success(GlobalMessagesModel.saveSuccess);
            this._router.navigate(['/master/permission/user-additional-permission']);
          }
          else {
            this._alertService.error(data.Message);
          }
        }, error => {
          this._alertService.error(GlobalMessagesModel.saveError);
        });
      }
    }
  }

  getDownLevelUserType() {
    this._userTypeService.GetDownLevelUserType(this.loginData.BaseUserType, true).subscribe((data) => {
      if (data.IsSuccess) {
        this.userTypeList = <UserTypeModel[]>data.Data;
      }
    }, (error) => {
      this._alertService.error(error.message);
    });
  }

  onUserTypeChange() {
    this.fromUser_ddlOffice = this.ddlFromUsers = [];
    this.toUser_ddlOffice = this.ddlToUsers = [];

    this.model.FromUserDepartmentCode = undefined;
    this.model.FromUserDistrictCode = undefined;
    this.model.FromUserOfficeCode = undefined;
    this.model.FromUserCode = undefined;
    this.model.ToUserDepartmentCode = undefined;
    this.model.ToUserDistrictCode = undefined;
    this.model.ToUserOfficeCode = undefined;
    this.model.ToUserCode = undefined;

    if (this.model.UserTypeCode && !this.IsShowDeptFilter) {
      this.getFromUsersList();
      this.getToUsersList();
    }
  }

  get SelBaseUserType(): string {
    let baseUserType = '';
    let userTypeDetail = this.userTypeList.filter(t => t.UserTypeCode == this.model.UserTypeCode);
    if (userTypeDetail && userTypeDetail.length > 0)
      baseUserType = userTypeDetail[0].BaseUserType;
    return baseUserType;
  }

  get IsShowDeptFilter(): boolean {
    let officeUserTypes = ['HOD', 'HOO', 'HOOADLC', 'DREC', 'DRECADLC', 'DOS', 'DOSADLC'];
    return (officeUserTypes.indexOf(this.SelBaseUserType) > -1) ? true : false;
  }

  get IsShowOfficeFilter(): boolean {
    let officeUserTypes = ['HOO', 'HOOADLC', 'DREC', 'DRECADLC', 'DOS', 'DOSADLC'];
    return (officeUserTypes.indexOf(this.SelBaseUserType) > -1) ? true : false;
  }

  getDepartments() {
    this.ddlOfficeDepartment = [];
    this._userService.GetUserDepartmentForUserOfficeCreation('0', true).subscribe(data => {
      if (data.IsSuccess) {
        this.ddlOfficeDepartment = <DdlItemModel[]>data.Data;
      }
    }, error => {
      this._alertService.error(error.message);
    });
  }

  onFromDepartmentChange() {
    this.getFromUserDistrictList();
    this.getFromUserOfficeList();
    this.getFromUsersList();
  }

  onToDepartmentChange() {
    this.getToUserDistrictList();
    this.getToUserOfficeList();
    this.getToUsersList();
  }

  getFromUserDistrictList() {
    this.fromUser_ddlDistrict = [];
    let stateCode = StateEnum.Rajasthan.toString();
    let deptCode = this.model.FromUserDepartmentCode ? this.model.FromUserDepartmentCode.toString() : '0';
    this._userService.GetUserDistrictForUserOfficeCreation(stateCode, deptCode, true).subscribe(data => {
      if (data.IsSuccess) {
        this.fromUser_ddlDistrict = <DdlItemModel[]>data.Data;
      }
    }, error => {
      this._alertService.error(error.message);
    });
  }

  onFromDistrictChange() {
    this.getFromUserOfficeList();
    this.getFromUsersList();
  }

  getToUserDistrictList() {
    this.toUser_ddlDistrict = [];
    let stateCode = StateEnum.Rajasthan.toString();
    let deptCode = this.model.ToUserDepartmentCode ? this.model.ToUserDepartmentCode.toString() : '0';
    this._userService.GetUserDistrictForUserOfficeCreation(stateCode, deptCode, true).subscribe(data => {
      if (data.IsSuccess) {
        this.toUser_ddlDistrict = <DdlItemModel[]>data.Data;
      }
    }, error => {
      this._alertService.error(error.message);
    });
  }

  onToDistrictChange() {
    this.getToUserOfficeList();
    this.getToUsersList();
  }

  getFromUserOfficeList() {
    this.fromUser_ddlOffice = [];
    let deptCodes = this.model.FromUserDepartmentCode ? this.model.FromUserDepartmentCode.toString() : '0';
    let distCodes = this.model.FromUserDistrictCode ? this.model.FromUserDistrictCode.toString() : '0';
    this._userService.GetUserOfficeForUserCreation(deptCodes, distCodes).subscribe((data) => {
      if (data.IsSuccess) {
        this.fromUser_ddlOffice = <DdlItemModel[]>data.Data;
      }
    }, (error) => {
      this._alertService.error(error.message);
    });
  }

  getToUserOfficeList() {
    this.toUser_ddlOffice = [];
    let deptCodes = this.model.ToUserDepartmentCode ? this.model.ToUserDepartmentCode.toString() : '0';
    let distCodes = this.model.ToUserDistrictCode ? this.model.ToUserDistrictCode.toString() : '0';
    this._userService.GetUserOfficeForUserCreation(deptCodes, distCodes).subscribe((data) => {
      if (data.IsSuccess) {
        this.toUser_ddlOffice = <DdlItemModel[]>data.Data;
      }
    }, (error) => {
      this._alertService.error(error.message);
    });
  }

  getFromUsersList() {
    let userTypeCode = Number(this.model.UserTypeCode);
    let deptCodes = String(this.model.FromUserDepartmentCode || '');
    let distCodes = String(this.model.FromUserDistrictCode || '');
    let officeCodes = String(this.model.FromUserOfficeCode || '');

    this._commonService.GetFilterUserListForAdditionalPermission(userTypeCode, deptCodes, distCodes, officeCodes).subscribe((data) => {
      if (data.IsSuccess) {
        this.ddlFromUsers = <DdlItemModel[]>data.Data;
      }
    }, (error) => {
      this._alertService.error(error.message);
    });
  }

  getToUsersList() {
    let userTypeCode = Number(this.model.UserTypeCode);
    let deptCodes = String(this.model.ToUserDepartmentCode || '');
    let distCodes = String(this.model.ToUserDistrictCode || '');
    let officeCodes = String(this.model.ToUserOfficeCode || '');

    this._commonService.GetFilterUserListForAdditionalPermission(userTypeCode, deptCodes, distCodes, officeCodes).subscribe((data) => {
      if (data.IsSuccess) {
        this.ddlToUsers = <DdlItemModel[]>data.Data;
      }
    }, (error) => {
      this._alertService.error(error.message);
    });
  }


}
