import { Injectable } from '@angular/core';
import { BaseService } from '../base.service';
import { AppSetting } from '../../model/appsetting.model';
import { IndexModel } from '../../model/common.model';
import { ReasonTypeModel } from '../../model/master/reason-type.model';

@Injectable({
    providedIn: 'root'
})

export class ReasonTypeService {
    constructor(private readonly _baseService: BaseService, ) { }

    GetList(model: IndexModel) {       
        return this._baseService.post(AppSetting.ReasonTypeListUrl, model);
    }

    AddUpdate(id: number, model: ReasonTypeModel) {
        return this._baseService.post(AppSetting.ReasonTypeAddUpdateUrl, model);
    }

    Detail(id: number) {
         return this._baseService.get(AppSetting.ReasonTypeDetailUrl + id);
    }

    ChangeDeleteStatus(id: number) {
        return this._baseService.get(AppSetting.ReasonTypeDeleteStatusChangeUrl + id);
    }

    ChangeActiveStatus(id: number) {
        return this._baseService.get(AppSetting.ReasonTypeActiveStatusChangeUrl + id);
    }

    IsReasonTypeExist(reasonType: string){
        return this._baseService.get(AppSetting.IsReasonTypeExistUrl + reasonType);
    }

    GetReasonTypePDFDownload(model: IndexModel) {
        return this._baseService.post(AppSetting.ReasonTypePDFDownloadUrl, model);
    }
}
