export class PermissionMasterModel {
  PermissionId: number;
  PermissionCode: number;
  PermissionTitle: string;
  ApplicationCode: string;
  PermissionTypeCode: string | number;
  MenuCode: number | string;
  IsActive: boolean;
  IsDelete: boolean;
  HasExtraAuthentication: boolean;
  Password: string;
  UrlList: UrlViewModel[] = [];
}

export class UrlViewModel {
  PermissionUrlId: number;
  PermissionCode: number;
  PermissionUrl: string;
  PermissionType: string;
}

export class PermissionMasterListViewModel {
  PermissionId: number;
  PermissionCode: number;
  PermissionTitle: string | null;
  ApplicationCode: string | null;
  ApplicationTitle: string | null;
  PermissionTypeCode: number;
  PermissionTypeName: string | null;
  MenuCode: number;
  MenuTitle: string | null;
  IsActive: boolean;
  IsDelete: boolean;
  HasExtraAuthentication: boolean;
  Password: string;
}
