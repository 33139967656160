import { IndexModel } from "../common.model";

export class B2GUserManualModel {
    Id: number;
    PageCode: number | string;
    PageTypeCode: number | string;
    PageDescriptionCategory: number | string;
    Description: string;
    PDFURL: string;
    ImageURL: string;
    LookupTypeId: number | string;
    SchemeTypeCode: number | string;
    URL:string;
    constructor() {
      this.Description = "";
    }
  }
  
  export class B2GUserManualViewModel {
    Id: number;
    Description: string;
    PermissionTitle: string;
    ApplicationTitle: string;
    PermissionTypeName: string;
    MenuTitle: string;
    IsActive: boolean;
    PageDescriptionCategoryName: string;
    PDFURL: string;
    ImageURL: string;
    SSOID:string; 
    URL:string;
    IsShowPDF: boolean;
  }
  
  export class PageMasterDetailModel {
    PermissionTitle: string;
    ApplicationTitle: string;
    PermissionTypeName: string;
    MenuTitle: string;
  }
  
  export class B2GUserManualFilterModel extends IndexModel {
    PageCode: number;
    PageTypeCode: number;
    ApplicationCode: string;
    constructor() {
      super();
    }
  }
  
export class PageManualModel {
    MasterTypeCode: number | null;
    CommunicationTypeCode: number | null;
    menuName: string;
    PageCode: number | null;
    constructor(menuName = "", MasterTypeCode = 0, CommunicationTypeCode = 0, PageCode = 0) {
        this.MasterTypeCode = MasterTypeCode;
        this.CommunicationTypeCode = CommunicationTypeCode;
        this.menuName = menuName;
        this.PageCode = PageCode;
    }
}
  