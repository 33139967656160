import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatSort, MatDialog, MatTableDataSource } from '@angular/material';
import { Router } from '@angular/router';
import { AppComponent } from 'src/app/app.component';
import { ConfirmationDialogComponent } from 'src/app/confirmation-dialog/confirmation-dialog.component';
import { AppSetting } from 'src/app/shared/model/appsetting.model';
import { GlobalMessagesModel } from 'src/app/shared/model/common-messages.model';
import { PermissionModel, IndexModel, GlobalFilterModel } from 'src/app/shared/model/common.model';
import { ColumnHeaderModel } from 'src/app/shared/model/commonddl.model';
import { AdminDepartmentMasterViewModel } from 'src/app/shared/model/master/admin-department.model';
import { AlertService } from 'src/app/shared/service/alert.service';
import { AuthenticationService } from 'src/app/shared/service/authentication.service';
import { CommonService } from 'src/app/shared/service/common.service';
import { AdminDepartmentService } from 'src/app/shared/service/master/admin-department.service';
import { AddUpdateAdminDepartmentComponent } from './add-update-admin-department/add-update-admin-department.component';

@Component({
  selector: 'app-admin-department',
  templateUrl: './admin-department.component.html',
  styleUrls: ['./admin-department.component.scss']
})
export class AdminDepartmentComponent implements OnInit {


  //#region Variable
  listModel: AdminDepartmentMasterViewModel[];
  dataSource: any;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  id: number;
  displayedColumns: string[] = ['index', 'AdmDepartmentTitle', 'AdmDepartmentTitleHindi', 'ModifiedDate', 'AdmDepartmentIsActive', 'Action'];
  ViewdisplayedColumns: ColumnHeaderModel[] = [
    { Value: 'AdmDepartmentTitle', Text: 'Admin Department Name' },
    { Value: 'AdmDepartmentTitleHindi', Text: 'Admin Department Name In Hindi' },
    { Value: 'SSOID', Text: 'SSOID' }
  ];
  columnsToDisplay: string[] = this.displayedColumns.slice();
  Permission: PermissionModel = this._authService.GetPagePermission("/master/admin-department", "admin-department/create", "admin-department/detail", "admin-department/update", "admin-department/delete");
  indexModel = this._commonService.modelSetGet(new IndexModel()) as IndexModel;
  totalRecords: number;
  //#endregion
  constructor(
    private _alertService: AlertService,
    private readonly _parentApi: AppComponent,
    private _dialog: MatDialog,
    public readonly _commonService: CommonService, private readonly _authService: AuthenticationService,
    private readonly _router: Router,
    private readonly _adminDepartmentService: AdminDepartmentService) {
    this._parentApi.setPageLayout("Admin Department:", "", "", "", true);

  }
  //#endregion

  ngOnInit() {
    this.GetList();
  }


  GetList() {

    this._adminDepartmentService.GetList(this._commonService.modelSetGet(this.indexModel, true)).subscribe(
      data => {

        if ((data.IsSuccess)) {

          this.listModel = <AdminDepartmentMasterViewModel[]>data.Data.Data;
          this.dataSource = new MatTableDataSource<AdminDepartmentMasterViewModel>(this.listModel);
          this.totalRecords = data.Data.TotalRecords;
          if (!this.indexModel.IsPostBack) {
            this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.sort;
          }
        }
      },
      error => {

        this._alertService.error(error.message);
      }
    );
  }


  OpenDialog(Id) {

    const _dialogRef = this._dialog.open(AddUpdateAdminDepartmentComponent, {
      width: "500px",
      data: Id,
      disableClose: true
    });
    _dialogRef.afterClosed().subscribe((result: boolean) => {

      if (result) {
        this.GetList();
      }
    });
  }


  OnStatusClick(id) {

    const dialogRef = this._dialog.open(ConfirmationDialogComponent, {
      // width: "50%",
      data: GlobalMessagesModel.ConfirmStatusChanged
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this._adminDepartmentService.ChangeActiveStatus(id).subscribe(
          data => {
            if (data.IsSuccess) {
              this.GetList();
              this._alertService.success(data.Message);
            } else {
              this._alertService.error(data.Message);
            }
          },
          error => {
            this._alertService.error(error.message);
          }
        );
      }
    });

  }


  sortData(event) {
    this.indexModel.OrderBy = event.active;
    this.indexModel.OrderByAsc =
      event.direction == AppSetting.orderByDscAsc
        ? AppSetting.orderByAsc
        : AppSetting.orderByDsc;
    this.indexModel.IsPostBack = true;
    this.GetList();
  }

  onPaginateChange(event) {
    this.indexModel.Page = event.pageIndex + 1;
    this.indexModel.PageSize = event.pageSize;
    this.indexModel.IsPostBack = true;
    this.GetList();
  }


  SearchByKeyword(searchValue: GlobalFilterModel) {

    this.indexModel.Search = searchValue.search;
    this.indexModel.AdvanceSearchModel = { ActiveStatus: searchValue.ActiveStatus }
    this.GetList();
  }

  ClearSearch() {
    this.indexModel.Search = null;
    this.indexModel.AdvanceSearchModel = { ActiveStatus: "1" }
    this.GetList();
  }

  PagePrint(printContent) {
    this._commonService.printHTMLContent(printContent, "Admin Department Master");
  }

  //#endregion
}