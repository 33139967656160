import { Component, OnInit } from '@angular/core';
import { UserTypeEnum, StatusEnum, CommunicationTypeEnum } from 'src/app/shared/enum/fixed-values.enum';
import { InboxModel } from 'src/app/shared/model/inbox.model';
import { AppComponent } from 'src/app/app.component';
import { CommonService } from 'src/app/shared/service/common.service';
import { ActivatedRoute } from '@angular/router';
import { AlertService } from 'src/app/shared/service/alert.service';
import { MatDialog } from '@angular/material';
import { InboxService } from 'src/app/shared/service/inbox.service';
import { AuthenticationService } from 'src/app/shared/service/authentication.service';
import { isNullOrUndefined } from 'util';

@Component({
  selector: 'app-inbox-detail',
  templateUrl: './inbox-detail.component.html',
  styleUrls: ['./inbox-detail.component.scss']
})
export class InboxDetailComponent implements OnInit {

  //#region <<Variable>>
  loginBaseUserType: string;
  userTypeEnum = UserTypeEnum;
  model: InboxModel;
  recordId: number = 0;
  IsSubject: boolean = true;
  //#endregion

  //#region <<Constructor>>
  constructor(
    private readonly appComponnet: AppComponent,
    public readonly _commonService: CommonService,
    private readonly _alertService: AlertService,
    private _route: ActivatedRoute,
    private readonly _dialog: MatDialog,
    private readonly _inboxService: InboxService,
    private readonly _authService: AuthenticationService
  ) {
    this.model = new InboxModel();
    if (!isNullOrUndefined(this._route.snapshot.params.id)) {
      this.recordId = this._route.snapshot.params.id;
    }
    this.loginBaseUserType = this._authService.BaseUserType;
  }
  //#endregion

  ngOnInit() {
    if (this.recordId != 0) {
      this.getDetail();
    }
  }

  getDetail() {
    this._inboxService.Detail(this.recordId).subscribe(
      data => {
        if (data.IsSuccess) {          
          this.model = <InboxModel>data.Data;
          if (this.loginBaseUserType != UserTypeEnum.VNDR && this.model.Status == StatusEnum.SENT) {
            this.model.Status = StatusEnum.RECEIVED;
          }
          if (this.model.CommunicationType == CommunicationTypeEnum.Complaint || this.model.CommunicationType == CommunicationTypeEnum.Suggestion || this.model.CommunicationType == CommunicationTypeEnum.General) {
            this.IsSubject = true;
          } else {
            this.IsSubject = false;
          }
          this.appComponnet.setPageLayout("Inbox Detail :", "keyboard_backspace", "Back To List", "inbox");
        }
      },
      error => {
        this._alertService.error(error.message);
      }
    );
  }

}
