import { Component, OnInit, ViewChild } from "@angular/core";
import { MatDialog, MatPaginator, MatSort, MatTableDataSource } from "@angular/material";
import { AppComponent } from "src/app/app.component";
import { ConfirmationDialogComponent } from "src/app/confirmation-dialog/confirmation-dialog.component";
import { AppSetting } from "src/app/shared/model/appsetting.model";
import { GlobalMessagesModel } from "src/app/shared/model/common-messages.model";
import { GlobalFilterModel, IndexModel, PermissionModel } from "src/app/shared/model/common.model";
import { ColumnHeaderModel, DDLModel, DdlItemModel } from "src/app/shared/model/commonddl.model";
import { DistrictModel } from "src/app/shared/model/master/district.model";
import { DistrictListRequestModel } from "src/app/shared/model/master/district/district-list-request.model";
import { AlertService } from "src/app/shared/service/alert.service";
import { AuthenticationService } from "src/app/shared/service/authentication.service";
import { CommonService } from "src/app/shared/service/common.service";
import { DistrictService } from "src/app/shared/service/master/district.service";

@Component({
  selector: 'app-district',
  templateUrl: './district.component.html',
  styleUrls: ['./district.component.scss']
})

export class DistrictComponent implements OnInit {

  loginUserCode: number;
  ddlList: DDLModel;
  loginBaseUserType: string;
  dataSource: any;
  modelList: DistrictModel[] = [];
  totalRecords: number;
  //public searchModel = this._commonService.modelSetGet(new DistrictListRequestModel()) as DistrictListRequestModel;
  public searchModel: DistrictListRequestModel = new DistrictListRequestModel();


  //public dropdowns: DdlItemModel[] = [];
  public dropdowns: DdlItemModel = new DdlItemModel();


  indexModel = this._commonService.modelSetGet(new IndexModel(), false) as IndexModel;
  permission: PermissionModel = this._authService.GetPagePermission("/master/district", "district/add", "district/detail", "district/update", "district/delete");

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  displayedColumns: string[] = ["index", "DistrictTitle", "DistrictTitleHindi", "State"];
  ViewdisplayedColumns: ColumnHeaderModel[] = [
    { Value: "DistrictTitle", Text: "District" },
    { Value: "DistrictTitleHindi", Text: "District (Hindi)" },
    { Value: "State", Text: "State" }
  ];

  constructor(
    private readonly _alertService: AlertService,
    private readonly _parentApi: AppComponent,
    private readonly _dialog: MatDialog,
    public readonly _commonService: CommonService,
    private readonly _distService: DistrictService,
    private readonly _authService: AuthenticationService
  ) {
    this.loginUserCode = _authService.UserCode;
    this.loginBaseUserType = _authService.BaseUserType;
    this.getDDLList();


    if (this.permission.UpdatePageAccess || this.permission.DeletePageAccess) {
      this.displayedColumns.push("DistrictIsActive");
    }
    this.displayedColumns.push("Action");

    if (this.permission.AddPageAccess)
      this._parentApi.setPageLayout("District :", "add", "Create", "master/district/add")
    else
      this._parentApi.setPageLayout("District List :");
  }

  ngOnInit() {
    this.getDDLList();
    this.getList();
    
  }

  public onSearch() {
    this.getList();
  }

  public onClear() {
    this.searchModel = new DistrictListRequestModel();
    this.getList();
  }

  // getList() {
  //   //this.indexModel.OrderByAsc = 1;
  //   this._distService.GetList(this._commonService.modelSetGet(this.indexModel, true)).subscribe((data) => {
  //     if (data.IsSuccess) {
  //       this.modelList = <DistrictModel[]>data.Data.Data;
  //       this.dataSource = new MatTableDataSource<DistrictModel>(this.modelList);
  //       this.totalRecords = data.Data.TotalRecords;
  //       if (!this.indexModel.IsPostBack) {
  //         this.dataSource.paginator = this.paginator;
  //         this.dataSource.sort = this.sort;
  //       }
  //     }
  //   }, (error) => {
  //     this._alertService.error(error.message);
  //   });
  // }

  getList() {
    
    this._distService.GetList(this.searchModel).subscribe(
      (data) => {
        if (data.IsSuccess) {
          this.modelList = <DistrictModel[]>data.Data.Data;
          this.dataSource = new MatTableDataSource<DistrictModel>(this.modelList);
          this.totalRecords = data.Data.TotalRecords;
        } else {
          this._alertService.error(GlobalMessagesModel.InternalError);
        }
      },
      (error) => {
        this._alertService.error(error.message);
      }
    );
  }

  updateStatus(id) {
    const dialogRef = this._dialog.open(ConfirmationDialogComponent, { width: "50%", data: GlobalMessagesModel.ConfirmStatusChanged });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this._distService.UpdateStatus(id).subscribe((data) => {
          if (data.IsSuccess) {
            this.getList();
            this._alertService.success(data.Message);
          }
        }, (error) => {
          this._alertService.error(error.message);
        });
      }
    });
  }

  searchByKeyword(searchValue: GlobalFilterModel) {
    this.indexModel.Search = searchValue.search;
    this.indexModel.AdvanceSearchModel = { ActiveStatus: searchValue.ActiveStatus }
    this.getList();
  }

  clearSearch() {
    this.indexModel = new IndexModel();
    this.searchModel.AdvanceSearchModel = { ActiveStatus: "1" }
    this.getList();
  }

  sortData(event) {
    this.searchModel.OrderBy = event.active;
    this.searchModel.OrderByAsc = event.direction == AppSetting.orderByDscAsc ? AppSetting.orderByAsc : AppSetting.orderByDsc;
    this.searchModel.IsPostBack = true;
    this.getList();
  }

  onPaginateChange(event) {
    this.searchModel.Page = event.pageIndex + 1;
    this.searchModel.PageSize = event.pageSize;
    this.searchModel.IsPostBack = true;
    this.getList();
  }

  PagePrint(printContent) {
    this._commonService.printHTMLContent(printContent, "District Master");
  }

  // GetDDLList() {
  //   this._commonService
  //     .GetAllDDL(AppSetting.DistrictDropdownKeys, this.loginUserCode)
  //     .subscribe(
  //       (data) => {
  //         if (data.IsSuccess) {
  //           this.ddlList = <DDLModel>data.Data;
  //           /*
  //           added by pravesh
  //           add new Key in regarding this in appsetting model ddlDisplayUserTypeTitle
  //           */
  //           // if (this.ddlList) {
  //           //   this.ddlList.ddlDisplayUserTypeTitle = this.ddlList.ddlDisplayUserTypeTitle.filter(function (item) {
  //           //     return (item.Value == UserTypeEnum.HOD || item.Value == UserTypeEnum.DRECADLC || item.Value == UserTypeEnum.DREC || item.Value == UserTypeEnum.DLO || item.Value == UserTypeEnum.PROJ);
  //           //   });
  //           // }
  //         }
  //       },
  //       (error) => {
  //         this._alertService.error(error.message);
  //       }
  //     );
  // }

  private getDDLList() {
    this._commonService.GetAllDDL(AppSetting.DistrictDropdownKeys).subscribe((data) => {
      if (data.IsSuccess) {
        this.ddlList = <DDLModel>data.Data;
      }
    }, (error) => {
      this._alertService.error(error.message);
    });
  }
  

}
