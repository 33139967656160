import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { MatTableDataSource, MatDialog, MatSort, MatPaginator } from "@angular/material";
import { GlobalFilterModel, IndexModel } from 'src/app/shared/model/common.model';
import { ColumnHeaderModel } from 'src/app/shared/model/commonddl.model';
import { AppComponent } from 'src/app/app.component';
import { AlertService } from 'src/app/shared/service/alert.service';
import { ConfirmationDialogComponent } from 'src/app/confirmation-dialog/confirmation-dialog.component';
import { GlobalMessagesModel } from 'src/app/shared/model/common-messages.model';
import { AppSetting } from 'src/app/shared/model/appsetting.model';
import { AuthenticationService } from 'src/app/shared/service/authentication.service';
import { UserTypeEnum } from 'src/app/shared/enum/fixed-values.enum';
import { Router } from '@angular/router';
import { UserViewModel } from 'src/app/shared/model/user.model';
import { UserService } from 'src/app/shared/service/user.service';
import { CommonService } from 'src/app/shared/service/common.service';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { isNullOrUndefined } from 'util';
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

declare const require: any;
const jsPDF = require('jspdf');
require('jspdf-autotable');

@Component({
  selector: 'app-office-staff-report',
  templateUrl: './office-staff-report.component.html',
  styleUrls: ['./office-staff-report.component.scss']
})
export class OfficeStaffReportComponent implements OnInit {

  model: UserViewModel[];
  indexModel: IndexModel;
  dataSource: any;
  totalRecords: number;
  loginBaseUserType: string;
  userTypeEnum=UserTypeEnum;

  displayedColumns: string[] = ["index", "Name", "SSOID", "DepartmentNames", "DistrictNames", "ProjectNames", "Designation"];
  columnsToDisplay: string[] = this.displayedColumns.slice();

  ViewdisplayedColumns: ColumnHeaderModel[] = [
    { Value: "Name", Text: "Name" },
    { Value: "SSOID", Text: "SSO ID" },
    { Value: "DepartmentNames", Text: "Department" },
    { Value: "DistrictNames", Text: "District" },
    { Value: "Designation", Text: "Designation" },
  ];

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(private readonly appComponnet: AppComponent,
    private readonly _alertService: AlertService,
    private readonly _userService: UserService,
    private readonly _dialog: MatDialog,
    private readonly _router: Router,
    public readonly _commonService: CommonService,
    private readonly _authService: AuthenticationService
  ) {
    this.appComponnet.setPageLayout("Office Staff Report :", "", "", "", true);
    this.indexModel = new IndexModel();
    this.loginBaseUserType = this._authService.BaseUserType;
  }

  ngOnInit() {
    if (this.loginBaseUserType != this.userTypeEnum.ADM && this.loginBaseUserType != this.userTypeEnum.SADM &&  this.loginBaseUserType != this.userTypeEnum.DREC && this.loginBaseUserType != this.userTypeEnum.DRECADLC && this.userTypeEnum.HOD != this.loginBaseUserType) {
      this._router.navigate(["dashboard"]);
    }
    this.getList();
  }

  getList() {
    this._userService.GetOfficeStaffList(0, this.indexModel).subscribe(
      data => {
        if (data.IsSuccess) {

          this.model = <UserViewModel[]>data.Data.Data;
          this.dataSource = new MatTableDataSource<UserViewModel>(this.model);
          this.totalRecords = data.Data.TotalRecords;
          if (!this.indexModel.IsPostBack) {
            this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.sort;
          }
        } else {
          this._alertService.error(data.Message);
        }
      },
      error => {
        this._alertService.error(error.message);
      }
    );
  }

  updateActiveStatus(id) {
    const dialogRef = this._dialog.open(ConfirmationDialogComponent, { width: "50%", data: GlobalMessagesModel.ConfirmStatusChanged });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this._userService.ChangeActiveStatus(id).subscribe(
          data => {
            if (data.IsSuccess) {
              this._alertService.success(data.Message);
              this.getList();
            }
          },
          error => {
            this._alertService.error(error.message);
          }
        );
      }
    });
  }

  sortData(event) {
    this.indexModel.OrderBy = event.active;
    this.indexModel.OrderByAsc = event.direction == AppSetting.orderByDscAsc ? AppSetting.orderByAsc : AppSetting.orderByDsc;
    this.indexModel.IsPostBack = true;
    this.getList();
  }

  onPaginateChange(event) {
    this.indexModel.Page = event.pageIndex + 1;
    this.indexModel.PageSize = event.pageSize;
    this.indexModel.IsPostBack = true;
    this.getList();
  }

  SearchByKeyword(searchValue: GlobalFilterModel) {
    this.indexModel.Search = searchValue.search;
    //this.indexModel.AdvanceSearchModel = { ActiveStatus: searchValue.ActiveStatus }

    this.getList();
  }

  ClearSearch() {
    this.indexModel = new IndexModel();
    this.getList();
  }


  //Report
  @ViewChild('pdfTable', { static: false }) pdfTable: ElementRef;


  completeModel: UserViewModel[];
  completeIndexModel: IndexModel;
  getCompleteData() {

    this.completeIndexModel = new IndexModel();
    this.completeIndexModel.Page = 1;
    this.completeIndexModel.PageSize = this.totalRecords;
    this.completeIndexModel.IsPostBack = true;
    this.completeIndexModel.Search = this.indexModel.Search;
    this._userService.GetOfficeStaffList(0, this.completeIndexModel).subscribe(
      (completeData: any) => {
        if (completeData.IsSuccess) {

          this.completeModel = <UserViewModel[]>completeData.Data.Data;
          this.totalRecords = completeData.Data.TotalRecords;
        } else {
          this._alertService.error(completeData.Message);
        }
      },
      error => {
        this._alertService.error(error.message);
      }
    );
  }
  rowsNew: any = [];

  download() {
    //completeModel
    var i = 1;
    this.rowsNew = [];
    this.completeModel.forEach(element => {
      var temp = {
        index: i, Name: element.Name, SSOID: element.SSOID, Department: element.DepartmentNames, District: element.DistrictNames, AssignedProject: element.ProjectNames, Designation: element.Designation,
        IsActive: element.IsActive
      };
      i = i + 1;
      this.rowsNew.push(temp);
    });

  }

  public exportAsPdf(): void {
    var doc = new jsPDF('p', 'pt');

    var header = function (data) {
      doc.setFontSize(10);
      doc.setTextColor(40);
      doc.text("Office Staff Report", data.settings.margin.left, 50);
      doc.text("Date Time : " + new Date().toLocaleDateString() + " " + new Date().toLocaleTimeString(), data.settings.margin.left, 800);
    };
    var headers = [{
      title: "#",
      dataKey: "index"
    }, {
      title: "Name",
      dataKey: "Name"
    },
    {
      title: "SSOID",
      dataKey: "SSOID"
    },
    {
      title: "Department",
      dataKey: "Department"
    },
    {
      title: "District",
      dataKey: "District"
    },
    {
      title: "AssignedProject",
      dataKey: "AssignedProject"
    },
    {
      title: "Designation",
      dataKey: "Designation"
    },

    {
      title: "Is Active",
      dataKey: "IsActive"
    },
    {
      title: "Modified By",
      dataKey: "ModifiedBy"
    },

    ];
    doc.autoTable(headers, this.rowsNew, { margin: { top: 70 }, beforePageContent: header });

    doc.save("Offie-Staff-Report_" + new Date().toLocaleDateString() + "_" + new Date().toLocaleTimeString() + ".pdf");

  }

  //Excel
  public exportAsExcelFile(): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.rowsNew);
    const workbook: XLSX.WorkBook = { Sheets: { 'OfficeStaffReport': worksheet }, SheetNames: ['OfficeStaffReport'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFile(excelBuffer, 'Office-Staff');
  }

  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], { type: EXCEL_TYPE });
    FileSaver.saveAs(data, fileName + '_Report_' + new Date().toLocaleDateString() + "_" + new Date().toLocaleTimeString() + EXCEL_EXTENSION);
  }

  DownloadPdf() {
    this._userService.GetOfficeStaffPDFDownload(0, this.indexModel).subscribe(
      data => {
        if (data.IsSuccess) {
          // const linkSource = data.Data;
          // const downloadLink = document.createElement("a");
          // const fileName = 'Office Staff Report';

          // downloadLink.href = linkSource;
          // downloadLink.download = fileName;
          // downloadLink.target = "blank";
          // downloadLink.click();

          var w = window.open("about:blank");

          setTimeout(function () {
            //FireFox seems to require a setTimeout for this to work.
            w.document.body.appendChild(w.document.createElement("iframe")).src = data.Data;
            w.document.getElementsByTagName("iframe")[0].style.width = "100%";
            w.document.getElementsByTagName("iframe")[0].style.height = "100%";
          }, 0);
        } else {
          this._alertService.error(data.Message);
        }
      },
      error => {
        this._alertService.error(error.message);
      }
    );
  }

  buttonClickExportAsPDF(): void {
    // this.getCompleteData();
    // setTimeout(() => {
    //   this.download();
    //   this.exportAsPdf();
    // }, 1000);
    this.DownloadPdf();
  }

  buttonClickExportAsXLSX(): void {
    this.getCompleteData();
    setTimeout(() => {
      this.download();
      this.exportAsExcelFile();
    }, 1000);
  }

}
