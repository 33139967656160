import { Component, OnInit, ViewChild } from "@angular/core";
import { MatTableDataSource, MatPaginator, MatSort, MatDialog } from "@angular/material";
import { AppComponent } from "src/app/app.component";
import { ConfirmationDialogComponent } from "src/app/confirmation-dialog/confirmation-dialog.component";
import { AppSetting } from "src/app/shared/model/appsetting.model";
import { GlobalMessagesModel } from "src/app/shared/model/common-messages.model";
import { GlobalFilterModel, IndexModel, PermissionModel } from "src/app/shared/model/common.model";
import { ColumnHeaderModel } from "src/app/shared/model/commonddl.model";
import { NotificationMappingViewModel, NotificationTemplateTypeViewModel } from "src/app/shared/model/master/notification-template-type.model";
import { AlertService } from "src/app/shared/service/alert.service";
import { AuthenticationService } from "src/app/shared/service/authentication.service";
import { CommonService } from "src/app/shared/service/common.service";
import { NotificationTemplateTypeService } from "src/app/shared/service/master/notification-template-type.service";
import { SMSTemplateService } from "src/app/shared/service/master/sms-template.service";
import { AddEditDialogComponent } from "../../notification-template-type/add-edit-dialog/add-edit-dialog.component";
import { AddUpdateSMSTemplateMappingDialogComponent } from "./add-update-smstemplate-mapping-dialog/add-update-smstemplate-mapping-dialog.component";

@Component({
  selector: 'app-smstemplate-mapping',
  templateUrl: './smstemplate-mapping.component.html',
  styleUrls: ['./smstemplate-mapping.component.scss']
})
export class SMSTemplateMappingComponent implements OnInit {


  //#region << Variable >>
  listModel: NotificationMappingViewModel[];
  dataSource: MatTableDataSource<NotificationMappingViewModel>;
  displayedColumns: string[] = [
    'index',
    'TransactionName',
    'TemplateName',
    "IsActive",

  ];
  // tslint:disable-next-line: max-line-length
  ViewdisplayedColumns: ColumnHeaderModel[] = [
    { Value: 'TransactionName', Text: 'Transaction Name' },
    { Value: 'TemplateName', Text: 'Template Name' },


  ];

  columnsToDisplay: string[] = this.displayedColumns.slice();
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  indexModel = this._commonService.modelSetGet(new IndexModel()) as IndexModel;
  totalRecords: number;
  Permission: PermissionModel = this._authService.GetPagePermission("/master/sms-template-mapping", "sms-template-mapping/create", "sms-template-mapping/detail", "sms-template-mapping/update", "sms-template-mapping/delete");

  //#endregion

  constructor(private _parentApi: AppComponent,
    private readonly _smsTemplateService: SMSTemplateService,
    private readonly _alertService: AlertService,
    private readonly _dialog: MatDialog, private readonly _authService: AuthenticationService,
    public readonly _commonService: CommonService,) {
    this._parentApi.setPageLayout("", "", "", "", true);


  }

  //#region << Method >>
  ngOnInit() {
    this.GetList();
  }

  SortData(event) {
    this.indexModel.OrderBy = event.active;
    this.indexModel.OrderByAsc =
      event.direction === AppSetting.orderByDscAsc
        ? AppSetting.orderByAsc
        : AppSetting.orderByDsc;
    this.indexModel.IsPostBack = true;
    this.GetList();
  }

  onPaginateChange(event) {
    this.indexModel.Page = event.pageIndex + 1;
    this.indexModel.PageSize = event.pageSize;
    this.indexModel.IsPostBack = true;
    this.GetList();
  }

  GetList() {
    this._smsTemplateService.GetAllMapSMSTemplateListUrl(this._commonService.modelSetGet(this.indexModel, true)).subscribe(
      data => {
        if (data.IsSuccess) {

          this.listModel = <NotificationMappingViewModel[]>data.Data.Data;
          this.dataSource = new MatTableDataSource<NotificationMappingViewModel>(this.listModel);
          if (this.indexModel.IsPostBack === false) {
            this.dataSource.paginator = this.paginator;
            this.totalRecords = data.Data.TotalRecords;
            this.dataSource.sort = this.sort;
          }
        }
      },
      error => {
        this._alertService.error(error.message);
      }
    );
  }

  OpenDialog(Id) {
    const _dialogRef = this._dialog.open(AddUpdateSMSTemplateMappingDialogComponent, {
      width: "50%",
      data: Id
    });
    _dialogRef.afterClosed().subscribe((result: boolean) => {

      //  if (result) {
      this.GetList();
      //  }
    });
  }

  SearchByKeyword(searchValue: GlobalFilterModel) {
    this.indexModel.Search = searchValue.search;
    this.indexModel.AdvanceSearchModel = { ActiveStatus: searchValue.ActiveStatus }

    this.GetList();
  }

  ClearSearch() {
    this.indexModel = new IndexModel();
    this.GetList();
  }


  updateActiveStatus(id) {
    const dialogRef = this._dialog.open(ConfirmationDialogComponent, {
      width: "50%",
      data: GlobalMessagesModel.ConfirmStatusChanged,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this._smsTemplateService.ChangeMapedSMSTemplateActiveStatus(id).subscribe(
          (data) => {
            if (data.IsSuccess) {
              this._alertService.success(GlobalMessagesModel.updateSuccess);
              this.GetList();
            }
          },
          (error) => {
            this._alertService.error(error.message);
          }
        );
      }
    });
  }

  //#endregion

}
