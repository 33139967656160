import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppSetting } from '../model/appsetting.model';
import { DepartmentListingModel } from '../model/department.model';
import { FilterDepartmentModel } from '../model/filter-department.model';
import { FilterVendorAdminModel } from '../model/filter-vendor-admin.model';
import { VendorAdminListingModel } from '../model/vendor-admins.model';

import { AlertService } from './alert.service';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root'
})
export class CustomFilterService {

  constructor(
    private readonly _baseService: BaseService,
    private readonly _alertService: AlertService,
  ) { 
    
  }
  public getAll(model: FilterVendorAdminModel): Observable<any> {
 
    return this._baseService.post(AppSetting.FilterVendorAdmin, model).pipe(
                 map(response => {
                    if (response.IsSuccess) {
                       return response.Data as VendorAdminListingModel[];
                 }
                  return [];
             })
          );
  }

  public getDepartment(model: FilterDepartmentModel): Observable<any> {
 
    return this._baseService.post(AppSetting.FilterDepartment, model).pipe(
                 map(response => {
                    if (response.IsSuccess) {
                       return response.Data as DepartmentListingModel[];
                 }
                  return [];
             })
          );
  }
}

