import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { AppComponent } from 'src/app/app.component';
import { B2GUserManualMasterPageCodeEnum } from 'src/app/shared/enum/fixed-values.enum';
import { AppSetting } from 'src/app/shared/model/appsetting.model';
import { DDLModel, DdlItemModel } from 'src/app/shared/model/commonddl.model';
import { B2GUserManualModel, PageMasterDetailModel } from 'src/app/shared/model/master/b2-guser-manual-model';
import { PermissionMasterListViewModel } from 'src/app/shared/model/master/permission-master.model';
import { AlertService } from 'src/app/shared/service/alert.service';
import { CommonService } from 'src/app/shared/service/common.service';
import { B2GUserManualService } from 'src/app/shared/service/master/b2-guser-manual.service';
import { PermissionMasterService } from 'src/app/shared/service/master/permission-master.service';

@Component({
  selector: 'app-add-update-b2-guser-manual',
  templateUrl: './add-update-b2-guser-manual.component.html',
  styleUrls: ['./add-update-b2-guser-manual.component.scss']
})
export class AddUpdateB2GUserManualComponent implements OnInit {

  //#region << Variable >>
  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    // height: '15rem',
    // minHeight: '5rem',
    // maxHeight: '100px',
    width: 'auto',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: true,
    showToolbar: true,
    placeholder: 'Enter text here...',
    defaultParagraphSeparator: '',
    // defaultFontName: 'Mangal',
    defaultFontSize: '',
    fonts: [
      { class: 'arial', name: 'Arial' },
      { class: 'times-new-roman', name: 'Times New Roman' },
      { class: 'calibri', name: 'Calibri' },
      { class: 'comic-sans-ms', name: 'Comic Sans MS' },
      { class: 'Mangal', name: 'Mangal' }
    ],

    sanitize: true,
    toolbarPosition: 'top',

  };
  formGroup: FormGroup;
  model: B2GUserManualModel;
  title: string;
  dDLList: DDLModel;
  ddlPageMasterDetails: DdlItemModel[];
  pageDetailModel: PermissionMasterListViewModel;
  fileValidationMsgHowtoPay: string;
  fileSizeValidationMsg: string;
  ImagefileValidationMsg: string;
  B2GUserManualMasterPageCodeEnum = B2GUserManualMasterPageCodeEnum;
  //#endregion

  //#region << constructor >>

  constructor(
    private _parentApi: AppComponent,
    private readonly _b2gUserManualMasterService: B2GUserManualService,
    private readonly _alertService: AlertService,
    private readonly _router: Router,
    private _route: ActivatedRoute,
    private readonly formBuilder: FormBuilder,
    public readonly _commonService: CommonService,

  ) {
    this.model = new B2GUserManualModel();
    const id = this._route.snapshot.params.id;
    if (id) {
      this.model.Id = id;
      this.GetById();
      this._parentApi.setPageLayout(
        "B2G User Manual Update:",
        "keyboard_backspace",
        "Back To List",
        "master/b2g-user-manual"
      );
      this.title = "Update";
    } else {
      this._parentApi.setPageLayout(
        "B2G User Manual Add:",
        "keyboard_backspace",
        "Back To List",
        "master/b2g-user-manual"
      );
      this.title = "Add";
    }
    this.fileSizeValidationMsg =
      "Attachment must be less than " +
      this._commonService.GetLocalStorage("FileValidation") +
      " MB.";
  }

  //#endregion

  //#region << Method >>

  ngOnInit() {
    this.FormGroupInit();
    this.GetDDLList();
  }

  GetDDLList() {
    this._commonService.GetAllDDL(AppSetting.B2GUserManualDDLKey).subscribe(
      data => {
        
        if (data.IsSuccess) {
          this.dDLList = <DDLModel>data.Data;
        }
      },
      error => {
        this._alertService.error(error.message);
      }
    );
  }

  getPageByPageType(code) {
    this._commonService.GetPageMasterByPageTypeCode(code, '').subscribe(
      data => {
        
        if (data.IsSuccess) {
          this.ddlPageMasterDetails = <DdlItemModel[]>data.Data;
        }
      },
      error => {
        this._alertService.error(error.message);
      }
    );
  }

  getPageDetail(code) {
    if (code) {
      this._b2gUserManualMasterService.GetPageDetailByPageCode(code).subscribe(
        data => {
          if (data.IsSuccess) {
            
            this.pageDetailModel = <PermissionMasterListViewModel>data.Data;
          } else {
            this._alertService.error(data.Message);
          }
        },
        error => {
          this._alertService.error(error.message);
        }
      );
    }
  }

  GetById() {
    if (this.model.Id > 0) {
      this._b2gUserManualMasterService.GetById(this.model.Id).subscribe(
        data => {
          if (data.IsSuccess) {
            
            this.model = <B2GUserManualModel>data.Data;
            if (this.model.PageCode) {
              this.model.PageCode = String(this.model.PageCode);
              this.getPageDetail(this.model.PageCode);
            }
            if (this.model.PageDescriptionCategory) {
              this.model.PageDescriptionCategory = String(
                this.model.PageDescriptionCategory
              );
            }
            if (this.model.PageTypeCode) {
              this.getPageByPageType(this.model.PageTypeCode);
              this.model.PageTypeCode = String(this.model.PageTypeCode);
            }
            if (this.model.LookupTypeId) {
              this.model.LookupTypeId = String(
                this.model.LookupTypeId
              );
            }
            if (this.model.SchemeTypeCode) {
              this.model.SchemeTypeCode = String(
                this.model.SchemeTypeCode
              );
            }
          } else {
            this._alertService.error(data.Message);
          }
        },
        error => {
          this._alertService.error(error.message);
        }
      );
    }
  }

  SaveClick() {
    
    this.formGroup.markAllAsTouched();
    if (this.formGroup.valid) {
      if (this.model.Id) {
        this._b2gUserManualMasterService.Edit(this.model).subscribe(
          data => {
            if (data.IsSuccess) {
              this._alertService.success(data.Message);
              this._router.navigate(["master/b2g-user-manual"]);
            } else {
              this._alertService.error(data.Message);
            }
          },
          error => {
            this._alertService.error(error.message);
          }
        );
      } else {
        this._b2gUserManualMasterService.Add(this.model).subscribe(
          data => {
            if (data.IsSuccess) {
              this._alertService.success(data.Message);
              this._router.navigate(["master/b2g-user-manual"]);
            } else {
              this._alertService.error(data.Message);
            }
          },
          error => {
            this._alertService.error(error.message);
          }
        );
      }
    } else {
    }
  }

  FormGroupInit() {
    this.formGroup = this.formBuilder.group({
      PageCode: [null, Validators.required],
      PageDescriptionCategory: [null, Validators.required],
      Description: [null, Validators.required],
      PDFURL: [null],
      ImageURL: [null],
      PageTypeCode: [null],
      LookupTypeId: [null],
      SchemeTypeCode: [null],
      Url: [
        undefined,
        Validators.compose([Validators.maxLength(1000), this.UrlValidator])
      ],
    });
  }

  handleFileForPdf(event: any) {
    if (event.target.files.item(0).type.match("application/pdf")) {
      if (
        event.target.files.item(0).size < AppSetting.FileSizeLimit.PDF
      ) {
        const reader = new FileReader();
        reader.onload = (event: any) => {
          this.model.PDFURL = event.target.result;
          this.fileValidationMsgHowtoPay = "";
        };
        reader.readAsDataURL(event.target.files.item(0));
      } else {
        this.fileValidationMsgHowtoPay = AppSetting.FileSizeLimit.PDFErrorMSG;
      }
    } else {
      this.fileValidationMsgHowtoPay = "only pdf file accepted  ";
    }
  }
  UrlValidator(url): any {
    if (url.pristine) {
      return null;
    }
    const URL_REGEXP = /(^|\s)((https?:\/\/)?[\w-]+(\.[\w-]+)+\.?(:\d+)?(\/\S*)?)/;
    url.markAsTouched();
    if (URL_REGEXP.test(url.value)) {
      return null;
    }
    return {
      invalidUrl: true
    };
  }


  RemovePDF() {
    this.model.PDFURL = null;
  }

  downloadOtherDocPdf(Url) {
    
    const linkSource = Url;
    const downloadLink = document.createElement("a");
    const fileName = "Docs";

    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.target = "blank";
    downloadLink.click();
  }

  handleImageFileInput(event: any) {
    
    if (event.target.files.item(0).type.match("image/*")) {
      if (
        event.target.files.item(0).size < AppSetting.FileSizeLimit.IMG
      ) {
        const reader = new FileReader();
        reader.onload = (event: any) => {
          
          this.model.ImageURL = event.target.result;
          this.ImagefileValidationMsg = "";
        };
        reader.readAsDataURL(event.target.files.item(0));
      } else {
        this.ImagefileValidationMsg = AppSetting.FileSizeLimit.IMGErrorMSG;
      }
    } else {
      this.ImagefileValidationMsg = "only image/*";
    }
  }

  RemoveImageFile() {
    this.model.ImageURL = null;
  }

  //#endregion  << Method >>
}

