import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ActivatedRoute } from '@angular/router';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { StatusEnum } from 'src/app/shared/enum/fixed-values.enum';
import { ActionModel, ActionDocumentModel } from 'src/app/shared/model/action.model';
import { AppSetting } from 'src/app/shared/model/appsetting.model';
import { GlobalMessagesModel } from 'src/app/shared/model/common-messages.model';
import { DdlItemModel, DDLModel } from 'src/app/shared/model/commonddl.model';
import { ActionService } from 'src/app/shared/service/action.service';
import { AlertService } from 'src/app/shared/service/alert.service';
import { AuthenticationService } from 'src/app/shared/service/authentication.service';
import { CommonService } from 'src/app/shared/service/common.service';
import { isNullOrUndefined } from 'util';
import { ActionDialogComponent } from '../action-dialog/action-dialog.component';

@Component({
  selector: 'app-communication-re-open-dialog',
  templateUrl: './communication-re-open-dialog.component.html',
  styleUrls: ['./communication-re-open-dialog.component.scss']
})
export class CommunicationReOpenDialogComponent implements OnInit {

  editorConfig = AppSetting.editorConfig as AngularEditorConfig;
  loginUserCode: number;
  frmGrp: FormGroup;
  model = new ActionModel();
  fileValidationMsg: string;
  tempDocumentList: ActionDocumentModel[] = [];
  ddlStatus: DdlItemModel[] = [];
  ddlList: DDLModel;  
  commTypeEnum: string = "";
  statusEnumValues= StatusEnum;
  isDisableSubmitBtn=false;
  constructor(private readonly fb: FormBuilder,
    public readonly _commonService: CommonService,
    private readonly _alertService: AlertService,
    private _route: ActivatedRoute,
    private readonly _actionService: ActionService,
    private readonly _authService: AuthenticationService,
    public _dialogRef: MatDialogRef<CommunicationReOpenDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    if (data) {
      if (data.Id) {
        this.model.CommunicationCode = data.Id;
      }
    }
    this.loginUserCode = this._authService.UserCode;
    this.GetDDLList();    
  }

  ngOnInit() {
    this.formGroupInit();
  }

  GetDDLList() {
    this._commonService.GetAllDDL(AppSetting.CommunicationReOpenActionDropdownKeys).subscribe(
      data => {
        if (data.IsSuccess) {
          this.ddlList = <DDLModel>data.Data;
        }
      },
      error => {
        this._alertService.error(error.message);
      }
    );
  }

  formGroupInit() {
    this.frmGrp = this.fb.group({
      Reply: [null, Validators.required],      
      ReasonCode: [null,Validators.required]
    });
  }

  saveClick() {
    this.isDisableSubmitBtn=true;
    //this.setReply();
    this.frmGrp.markAllAsTouched();
    if (this.frmGrp.valid) {
      this.model.ActionCode = 0;
      this.model.CreatedBy = this.loginUserCode;
      this.model.DocumentList = this.tempDocumentList;
      //  if (this.model.Status == this.statusEnumValues.DISPOSED) {
      this.model.Status = this.statusEnumValues.REOPEN;
      // }
      this._actionService.AddUpdate(this.model).subscribe(
        data => {
          if (data) {
            if (data.IsSuccess) {
              this._alertService.success('Communication ' + this.model.Status.toLowerCase() + ' successfully.');
              this._dialogRef.close(true);
            }
            else {
              this._alertService.error('Failed to update status.');
            }
            this.isDisableSubmitBtn=false;
          }
          else {
            this._alertService.error(GlobalMessagesModel.InternalError);
            this.isDisableSubmitBtn=false;
          }
          this.isDisableSubmitBtn=false;
        },
        error => {
          this.isDisableSubmitBtn=false;
          this._alertService.error(error.message);
        }
      );
    }else{
      this.isDisableSubmitBtn=false;
    }
  }

  CloseDialog(): void {
    this._dialogRef.close();
  }

  handleFileInput(files: FileList) {
    this.tempDocumentList = [];
    for (let index = 0; index < files.length; index++) {
      if (files.item(index).type.match("application/pdf")) {
        if (files.item(index).size < AppSetting.FileSizeLimit.PDF) {
          const reader = new FileReader();
          reader.onload = (e: any) => {
            this.tempDocumentList.push(new ActionDocumentModel());
            this.tempDocumentList[index].DocName = <string>reader.result;
            this.tempDocumentList[index].DocPath = <string>reader.result;

            this.tempDocumentList[index].DocName = files[index].name;
            this.tempDocumentList[index].Extension = files[index].name.split(".")[1];
            this.tempDocumentList[index].DocPath = <string>reader.result;
          };
          reader.readAsDataURL(files[index]);
        }
        else {
          this.tempDocumentList = [];
          this.fileValidationMsg = AppSetting.FileSizeLimit.PDFErrorMSG;
          return;
        }
      }
      else {
        this.tempDocumentList = [];
        this.fileValidationMsg = "Only *pdf";
        return;
      }
    }
    this.fileValidationMsg = "";
  }

  downloadPdf(Url, name) {
    const linkSource = Url;
    const downloadLink = document.createElement("a");
    const fileName = name;
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.target = "blank";
    downloadLink.click();
  }

  deleteDocument(documentCode: number, index: number) {
    this.tempDocumentList.splice(index, 1);
  }
}