import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from "@angular/router";
import { ComplaintTypeModel } from 'src/app/shared/model/master/complaint-type.model';
import { AppComponent } from 'src/app/app.component';
import { ComplaintTypeService } from 'src/app/shared/service/master/complaint-type.service';
import { AlertService } from 'src/app/shared/service/alert.service';
import { UserTypeEnum } from 'src/app/shared/enum/fixed-values.enum';
import { AuthenticationService } from 'src/app/shared/service/authentication.service';

@Component({
  selector: 'app-complaint-type-detail',
  templateUrl: './complaint-type-detail.component.html',
  styleUrls: ['./complaint-type-detail.component.scss']
})
export class ComplaintTypeDetailComponent implements OnInit {

  model: ComplaintTypeModel;
  RecordId: number;
  IsStorage:boolean;
  constructor(
    private readonly _appComponent: AppComponent,
    private readonly _router: Router,
    private _route: ActivatedRoute,
    private readonly _complaintTypeService: ComplaintTypeService,
    private readonly _alertService: AlertService, 
    private readonly _authService: AuthenticationService
  ) {
    this.RecordId = this._route.snapshot.params.id;
    this.model = new ComplaintTypeModel();
    this._appComponent.setPageLayout(
      "Complainy Type Detail :",
      "keyboard_backspace",
      "Back To List",
      "master/complaint-type"
    );
  }

  ngOnInit() {
    this.getDetail();
  }

  getDetail() {
    this._complaintTypeService.Detail(this.RecordId).subscribe(
      data => {
        if (data.IsSuccess) {
          this.model = <ComplaintTypeModel>data.Data;
        }
      },
      error => {
        this._alertService.error(error.message);
      }
    );
  }

  ngOnDestroy() {
    this.IsStorage = true;
    sessionStorage.setItem("IsComplaintTypeStorage", JSON.stringify(this.IsStorage));
   }

}
