import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { SafeResourceUrl, DomSanitizer } from '@angular/platform-browser';
import { Router, ActivatedRoute } from '@angular/router';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { AppComponent } from 'src/app/app.component';
import { GeneralCategoryEnumLocal, GeneralCategoryEnumProd, GeneralEntryCategoryEnum } from 'src/app/shared/enum/fixed-values.enum';
import { AppSetting } from 'src/app/shared/model/appsetting.model';
import { GlobalMessagesModel } from 'src/app/shared/model/common-messages.model';
import { DDLModel } from 'src/app/shared/model/commonddl.model';
import { GeneralEntryPostModel } from 'src/app/shared/model/general-entry-model.model';
import { GeneralEntryCategoryMasterModel } from 'src/app/shared/model/master/general-entry-category-master.model';
import { MasterDataModel } from 'src/app/shared/model/master/master-data.model';
import { UserViewModel, UserDepartmentViewModel } from 'src/app/shared/model/user.model';
import { AlertService } from 'src/app/shared/service/alert.service';
import { AuthenticationService } from 'src/app/shared/service/authentication.service';
import { CommonService } from 'src/app/shared/service/common.service';
import { GeneralEntryService } from 'src/app/shared/service/general-entry.service';
import { GeneralEntryCategoryMasterService } from 'src/app/shared/service/master/general-entry-category-master.service';
import { UserService } from 'src/app/shared/service/user.service';
import { environment } from 'src/environments/environment';
import { MasterDataService } from '../../../shared/service/master/master-data.service';

@Component({
  selector: 'app-add-update-general-entry',
  templateUrl: './add-update-general-entry.component.html',
  styleUrls: ['./add-update-general-entry.component.scss']
})

export class AddUpdateGeneralEntryComponent implements OnInit {

  frmGroup: FormGroup;
  loginData: UserViewModel;
  RecordId: number = 0;
  fileValidationMsg: string = "";
  tomorrow = new Date();
  filterDDlModel: any[];
  ImagefileValidationMsg: string = "";
  Imeges: string[];
  PDFFile: SafeResourceUrl;
  ddlList: DDLModel = new DDLModel();
  model: GeneralEntryPostModel = new GeneralEntryPostModel();;
  generalentryCategory = new GeneralEntryCategoryMasterModel();
  isCMOCommentVisible = false;
  settingEditorConfig = AppSetting.editorConfig as AngularEditorConfig;
  editorConfig: AngularEditorConfig;
  moduleNameItems: { [index: string]: any } = {};
  yearItems: { [index: string]: any } = {};
  departmentItems: { [index: string]: any } = {};
  catCode: number | string;

  constructor(
    private readonly fb: FormBuilder,
    private readonly appComponent: AppComponent,
    public readonly _commonService: CommonService,
    private readonly _alertService: AlertService,
    private readonly _router: Router,
    private _route: ActivatedRoute,
    private readonly _generalEntryService: GeneralEntryService,
    private readonly _generalEntryCategoryService: GeneralEntryCategoryMasterService,
    private readonly _authService: AuthenticationService,
    private readonly _userService: UserService,
    public sanitizer: DomSanitizer,
    private _dialog: MatDialog,
    private readonly _masterDataService: MasterDataService
  ) {
    this.RecordId = this._route.snapshot.params.id;

    if (this._router.url.includes("update-comment")) {
      this.isCMOCommentVisible = true;
      this.settingEditorConfig.editable = false;
    }

    this.appComponent.setPageLayout(this.RecordId > 0 ? " Update General Entry :" : " Add General Entry :",
      "keyboard_backspace", "Back To List", "/general-entry");

    this.loginData = this._authService.GetCurrentUserDetail().UserViewModel;
    this.editorConfig = this.settingEditorConfig;
    this.getDDLList();
  }

  ngOnInit() {
    this.formGroupInit();
    if (this.RecordId > 0) {
      this.getDetail();
    }
  }

  get isDocCategorySelected(): boolean {
    let dbCatCode = environment.production ? GeneralCategoryEnumProd.Documents : GeneralCategoryEnumLocal.Documents;
    return (Number(this.model.GeneralEntryCategoryCode) == Number(dbCatCode));
  }

  getDDLList() {
    this._commonService.GetAllDDL(AppSetting.DDlKeyForGeneralEntry).subscribe(data => {
      if (data.IsSuccess) {
        let ddlData = <DDLModel>data.Data;
        this.ddlList.ddlGeneralEntryCategory = ddlData.ddlGeneralEntryCategory;
        this.ddlList.ddlTrainingTime = ddlData.ddlTrainingTime;
        this.ddlList.ddlDepartmentByLoginUserId = ddlData.ddlDepartmentByLoginUserId;
        this.ddlList.ddlRajDistrict = ddlData.ddlRajDistrict;
      }
    }, error => {
      this._alertService.error(GlobalMessagesModel.InternalError);
    });
  }

  formGroupInit() {
    this.frmGroup = this.fb.group({
      GeneralEntry: [null, null],
      // GeneralEntryHindi: [null, Validators.compose([Validators.required, Validators.maxLength(4000)])],
      GeneralEntryHindi: [null, Validators.compose([Validators.required])],
      DepartmentCode: [null, null],
      DistrictCode: [null, null],
      OfficeCode: [null, null],
      CMOComments: [undefined, null],
      KeyWord: [null, Validators.compose([Validators.required, Validators.minLength(50)])],
      GeneralEntryCategoryCode: [undefined, Validators.required],
      GeneralEntrySubCategoryCode: [undefined, null],
      Description: [undefined, null],
      DescriptionHindi: [undefined, Validators.compose([Validators.maxLength(50000)])],
      Priority: [undefined, Validators.compose([Validators.required, Validators.maxLength(3)])],
      Url: [undefined, Validators.compose([Validators.maxLength(1000), this.UrlValidator])],
      IsVisible: [null, null],
      GeneralEntryDate: [null, null],
      PdfFIleName: [undefined, null],
      ImageFiles: [undefined, null],
      CreatedBy: [null, null],
      ModifiedBy: [undefined, null],
      CreatedDate: [undefined, null],
      ModifiedDate: [undefined, null],
      TimeSlot: [undefined, null],
      //  YearValueConnectWithCMIS: [null],
      //  DepartmentIdConnectWithCMIS: [null],
      //   ModuleIdConnectWithCMIS: [null],
      //   BenificiaryList: [null]
    });
  }

  getSubCategories() {
    if (this.model.GeneralEntryCategoryCode) {
      this._commonService.GetDDL("ddlGeneralEntrySubCategory", this.model.GeneralEntryCategoryCode, 0).subscribe(data => {
        if (data.IsSuccess) {
          this.ddlList.ddlGeneralEntrySubCategory = data.Data.ddlGeneralEntrySubCategory;
        }
      }, error => {
        this._alertService.error(error.message);
      });
    }
    else {
      this.ddlList.ddlGeneralEntrySubCategory = [];
    }
  }

  onDepartmentChange() {
    this.model.OfficeCode = undefined;
    this.ddlList.ddlDepartmentOffice = [];
    this.getOffice();
  }

  getOffice() {
    if (this.model.DepartmentCode) {
      let distCode = this.model.DistrictCode ? this.model.DistrictCode : 0;
      this._commonService.GetDDL(AppSetting.ddlDepartmentOffice, this.model.DepartmentCode, distCode).subscribe(data => {
        if (data.IsSuccess) {
          let ddlData = <DDLModel>data.Data;
          this.ddlList.ddlDepartmentOffice = ddlData.ddlDepartmentOffice;
        }
      }, error => {
        this._alertService.error(error.message);
      });
    }
  }

  getDetail() {
    this._generalEntryService.Detail(this.RecordId).subscribe(data => {
      if (data.IsSuccess) {
        this.model = data.Data as GeneralEntryPostModel;

        if (this.model.DepartmentCode) { this.model.DepartmentCode = String(this.model.DepartmentCode); }
        if (this.model.DistrictCode) { this.model.DistrictCode = String(this.model.DistrictCode); }
        if (this.model.OfficeCode) { this.model.OfficeCode = String(this.model.OfficeCode); }
        this.getOffice();

        if (this.model.GeneralEntryCategoryCode) {
          this.getDataByCategoryCode(Number(this.model.GeneralEntryCategoryCode), true);
        }
        if (data.Data.GeneralEntryCategoryCode) { this.model.GeneralEntryCategoryCode = String(data.Data.GeneralEntryCategoryCode); }
        if (data.Data.GeneralEntrySubCategoryCode) { this.model.GeneralEntrySubCategoryCode = String(data.Data.GeneralEntrySubCategoryCode); }
        if (data.Data.CMOComments) { this.model.CMOComments = data.Data.CMOComments; }
        if (data.Data.TimeSlotCode) { this.model.TimeSlotCode = String(data.Data.TimeSlotCode); }

        //bind Subcategory based on category
        setTimeout(() => {
          this.getSubCategories();
          this.frmGroup.get("GeneralEntryCategoryCode").disable();
        }, 200);
      }
    }, error => {
      this._alertService.error(error.message);
    });
  }

  saveClick() {

    if (!this.isDocCategorySelected) {
      this.model.DepartmentCode = undefined;
      this.model.DistrictCode = undefined;
      this.model.OfficeCode = undefined;
    }

    this.Autokeyword();
    this.frmGroup.markAllAsTouched();
    this.setCategoryDependentValidators();

    if (this.frmGroup.valid) {
      this.model.GeneralEntry = this.model.GeneralEntryHindi;
      this.model.Description = this.model.DescriptionHindi;

      if (this.RecordId > 0) {
        const prevDate = this.model.GeneralEntryDate;

        this.model.ModifiedBy = this.loginData.UserCode;
        this._generalEntryService.Edit(this.RecordId, this.model).subscribe(
          data => {
            if (data) {
              if (data.IsSuccess) {
                this._alertService.success(GlobalMessagesModel.updateSuccess, true);
                this._router.navigate(["general-entry"]);
              } else {
                this.model.GeneralEntryDate = prevDate;
                this._commonService.ScrollingTop();
                this._alertService.error(GlobalMessagesModel.updateError);
              }
            }
          },
          error => {
            this.model.GeneralEntryDate = prevDate;
            this._commonService.ScrollingTop();
            this._alertService.error(GlobalMessagesModel.InternalError);
          }
        );
      }
      else {

        const prevDate = this.model.GeneralEntryDate;

        this.model.CreatedBy = this.loginData.UserCode;
        this._generalEntryService.Add(this.model).subscribe(
          data => {
            if (data) {
              if (data.IsSuccess) {

                this._alertService.success(GlobalMessagesModel.saveSuccess, true);
                this._router.navigate(["general-entry"]);
              } else {
                this.model.GeneralEntryDate = prevDate;
                this._commonService.ScrollingTop();
                this._alertService.error(GlobalMessagesModel.saveError);
              }
            }
          },
          error => {
            this.model.GeneralEntryDate = prevDate;
            this._commonService.ScrollingTop();
            this._alertService.error(GlobalMessagesModel.InternalError);
          }
        );
      }
    }
  }

  setCategoryDependentValidators() {
    if (
      this.generalentryCategory.IsImageMandatory ||
      this.generalentryCategory.IsPDFMandatory ||
      this.generalentryCategory.IsURLMandatory
    ) {
      if (
        (this.frmGroup.get("ImageFiles").valid && this.generalentryCategory.IsImageMandatory) ||
        (this.frmGroup.get("PdfFIleName").valid && this.generalentryCategory.IsPDFMandatory) ||
        (this.frmGroup.get("Url").valid && this.generalentryCategory.IsURLMandatory)
      ) {
        if (!this.frmGroup.get("ImageFiles").valid) {
          this.frmGroup.get("ImageFiles").setValidators(null);
        }
        if (!this.frmGroup.get("PdfFIleName").valid) {
          this.frmGroup.get("PdfFIleName").setValidators(null);
        }
        if (!this.frmGroup.get("Url").valid) {
          this.frmGroup.get("Url").setValidators(null);
        }
        this.frmGroup.get("Url").updateValueAndValidity();
        this.frmGroup.get("PdfFIleName").updateValueAndValidity();
        this.frmGroup.get("ImageFiles").updateValueAndValidity();
      }
    }
  }

  numberOnly(value, isCommaOrDash: boolean = false): boolean {
    return this._commonService.NumberOnly(value, isCommaOrDash);
  }

  handleFileInput(event: any) {
    if (event.target.files.item(0).type.match("application/pdf")) {
      var reader = new FileReader();
      reader.onload = (event: any) => {
        this.PDFFile = this.sanitizer.bypassSecurityTrustResourceUrl(
          event.target.result
        );
        this.model.PdfFIleName = event.target.result;
      };
      reader.readAsDataURL(event.target.files.item(0));
      this.fileValidationMsg = "";
    }
    // else if (event.target.files.item(0).type.match("application/x-zip-compressed")) {
    //   var reader = new FileReader();
    //   reader.onload = (event: any) => {
    //     this.PDFFile = this.sanitizer.bypassSecurityTrustResourceUrl(
    //       event.target.result
    //     );
    //     this.model.PdfFIleName = event.target.result;
    //   };
    //   reader.readAsDataURL(event.target.files.item(0));
    //   this.model.ActualFileName = event.target.files.item(0).name;
    //   this.fileValidationMsg = "";
    // }
    else {
      this.fileValidationMsg = "only *pdf file accepted ";
    }
  }

  UrlValidator(url): any {
    if (url.pristine) {
      return null;
    }
    const URL_REGEXP = /(^|\s)((https?:\/\/)?[\w-]+(\.[\w-]+)+\.?(:\d+)?(\/\S*)?)/;
    url.markAsTouched();
    if (URL_REGEXP.test(url.value)) {
      return null;
    }
    return {
      invalidUrl: true
    };
  }

  Autokeyword() {
    let department = '', dist = '', office = '', category = '', subcategory = '';
    if (this.model.DepartmentCode) {
      department = this.getDDLTextByCode("ddlDepartmentByLoginUserId", this.model.DepartmentCode);
    }
    if (this.model.DistrictCode) {
      dist = this.getDDLTextByCode("ddlRajDistrict", this.model.DistrictCode);
    }
    if (this.model.OfficeCode) {
      office = this.getDDLTextByCode("ddlDepartmentOffice", this.model.OfficeCode);
    }
    if (this.model.GeneralEntryCategoryCode) {
      category = this.getDDLTextByCode("ddlGeneralEntryCategory", this.model.GeneralEntryCategoryCode);
    }
    if (this.model.GeneralEntryCategoryCode && this.model.GeneralEntrySubCategoryCode) {
      subcategory = this.getDDLTextByCode("ddlGeneralEntrySubCategory", this.model.GeneralEntrySubCategoryCode ? this.model.GeneralEntrySubCategoryCode : 0);
    }

    this.model.AutoKeyWord = department + " " + dist + " " + office
      + " " + category + " " + subcategory
      + " " + (this.model.GeneralEntryHindi ? this.model.GeneralEntryHindi : '')
      + " " + (this.model.Url ? this.model.Url : '')
      + " " + (this.model.DescriptionHindi ? this.model.DescriptionHindi : '')
      + " " + (this.model.CMOComments ? this.model.CMOComments : '')
      + " " + (this.model.KeyWord ? this.model.KeyWord : '');
  }

  getDDLTextByCode(ddlKey, code) {
    switch (ddlKey.toLowerCase()) {
      case "ddldepartmentbyloginuserid": {
        const temp = this.ddlList.ddlDepartmentByLoginUserId ? this.ddlList.ddlDepartmentByLoginUserId.find(x => x.Value == code) : undefined;
        return temp ? (temp.Text ? temp.Text : "") : "";
      }
      case "ddlrajdistrict": {
        const temp = this.ddlList.ddlRajDistrict ? this.ddlList.ddlRajDistrict.find(x => x.Value == code) : undefined;
        return temp ? (temp.Text ? temp.Text : "") : "";
      }
      case "ddldepartmentoffice": {
        const temp = this.ddlList.ddlDepartmentOffice ? this.ddlList.ddlDepartmentOffice.find(x => x.Value == code) : undefined;
        return temp ? (temp.Text ? temp.Text : "") : "";
      }
      case "ddlgeneralentrycategory": {
        const temp = this.ddlList.ddlGeneralEntryCategory ? this.ddlList.ddlGeneralEntryCategory.find(x => x.Value == code) : undefined;
        return temp ? (temp.Text ? temp.Text : "") : "";
      }
      case "ddlgeneralentrysubcategory": {
        const temp = this.ddlList.ddlGeneralEntrySubCategory ? this.ddlList.ddlGeneralEntrySubCategory.find(x => x.Value == code) : undefined;
        return temp ? (temp.Text ? temp.Text : "") : "";
      }
    }
  }

  checkUploadImages() {
    this.ImagefileValidationMsg = "";
    if (this.model.ImageFiles && this.model.ImageFiles.length > 0) {
      this.model.ImageFiles = [];
      this._alertService.error(
        "Please upload images again according to selected category."
      );
    }
  }

  handleImageFileInput(files: FileList) {
    this.ImagefileValidationMsg = "";
    this.Imeges = [];
    var bigFileCount: number = 0;
    var invalidImageCount: number = 0;
    var valid_Height = 800,
      valid_Width = 1929;
    if (this.model.GeneralEntryCategoryCode == GeneralEntryCategoryEnum.BannerImageCode) {
      valid_Height = parseInt(GeneralEntryCategoryEnum.Banner_ImageHeight);
      valid_Width = parseInt(GeneralEntryCategoryEnum.Banner_ImageWidth);
    }

    for (let index = 0; index < files.length; index++) {
      if (files.item(index).type.match("image/*")) {
        if (files.item(index).size < 6000000) {
          let reader = new FileReader();
          reader.onload = (e: any) => {
            var img = new Image();
            var imgHeight = 0,
              imgWidth = 0;
            img.src = <string>reader.result;
            img.onload = () => {
              imgWidth = img.width;
              imgHeight = img.height;

              // Image dimension validation.
              if (imgHeight >= valid_Height && imgWidth >= valid_Width) {
                if (this.model.ImageFiles == undefined) {
                  this.model.ImageFiles = [];
                }
                this.model.ImageFiles.push(<string>reader.result);
                this.Imeges.push(<string>reader.result);
              } else {
                invalidImageCount += 1;
                this.ImagefileValidationMsg =
                  invalidImageCount + " file must have " + valid_Height + "*" +
                  valid_Width + " (H*W) for selected category.";
              }
            };
          };
          reader.readAsDataURL(files[index]);
        } else {
          bigFileCount += 1;
          this.ImagefileValidationMsg = bigFileCount + " File have more then 5MB Size";
        }
      } else {
        this.ImagefileValidationMsg = "only image/*";
        this.model.ImageFiles = [];
        return;
      }
    }
    if (bigFileCount > 0 || invalidImageCount > 0) {
      return;
    }
  }

  RemoveImageFile(i) {
    this.model.ImageFiles.splice(i, 1);
    this.Imeges.slice(i, 1);
  }

  downloadPdf(url, name) {
    if (url) {
      const link = document.createElement("a");
      link.setAttribute("href", url);
      link.setAttribute("download", name + ".pdf");
      document.body.appendChild(link);
      link.click();
      link.remove();
    }
  }

  removePDFFile() {
    this.model.PdfFIleName = undefined;
  }

  getDataByCategoryCode(code, isPageload = false) {
    if (!isPageload) {
      this.checkUploadImages();
      this.getSubCategories();
    }
    this._generalEntryCategoryService.GetById(code).subscribe(
      data => {
        if (data.IsSuccess) {
          this.generalentryCategory = <GeneralEntryCategoryMasterModel>data.Data;

          if (this.generalentryCategory.IsImageMandatory) {
            this.frmGroup.get("ImageFiles").setValidators([Validators.required]);
          } else {
            this.frmGroup.get("ImageFiles").setValidators(null);
          }

          if (this.generalentryCategory.IsPDFMandatory) {
            //   this.frmGroup.get("PdfFIleName").setValidators([Validators.required]);
          } else {
            this.frmGroup.get("PdfFIleName").setValidators(null);
          }

          if (this.generalentryCategory.IsURLMandatory) {
            this.frmGroup.get("Url").setValidators([Validators.compose([Validators.required, Validators.maxLength(1000), this.UrlValidator])]);
          } else {
            this.frmGroup.get("Url").setValidators(null);
          }

          if (this.generalentryCategory.IsTimeSlotCodeMandatory) {
            this.frmGroup.get("TimeSlot").setValidators([Validators.required]);
          } else {
            this.frmGroup.get("TimeSlot").setValidators(null);
          }

          this.frmGroup.get("Url").updateValueAndValidity();
          this.frmGroup.get("PdfFIleName").updateValueAndValidity();
          this.frmGroup.get("ImageFiles").updateValueAndValidity();
          this.frmGroup.get("TimeSlot").updateValueAndValidity();
        }
      }, error => {
        this._alertService.error(GlobalMessagesModel.InternalError);
      });
  }

  OpenFile(url) {
    if (url) {
      const link = document.createElement("a");
      link.setAttribute("target", "_blank");
      link.setAttribute("href", url);
      document.body.appendChild(link);
      link.click();
      link.remove();
    }
  }

  getTrainingSlotDetail(value) {
    this._masterDataService.Detail(value).subscribe(
      data => {
        if (data.IsSuccess) {
          let model = <MasterDataModel>data.Data;
          if (model.SortOrder) {
            this.model.Priority = this.model.Priority ? this.model.Priority : model.SortOrder;
          }
        }
      },
      error => {
        this._alertService.error(error.message);
      }
    );
  }

}