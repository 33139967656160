import { Injectable } from "@angular/core";
import { AppSetting } from "../../model/appsetting.model";
import { IndexModel } from "../../model/common.model";
import { DepartmentContactDetailsModel } from "../../model/master/department-contact-details.model";
import { BaseService } from "../base.service";
@Injectable({
  providedIn: 'root'
})
export class DepartmentContactDetailsService {
    constructor(
        private readonly _baseService: BaseService,
    ) { }

    GetList(model: IndexModel) {
        return this._baseService.post(AppSetting.DepartmentContactDetailsGetAllUrl, model);
      }
    
      AddUpdate(model: DepartmentContactDetailsModel) {
        return this._baseService.post(AppSetting.DepartmentContactDetailsAddUpdateUrl, model);
      }
    
      GetById(id) {
        return this._baseService.get(AppSetting.DepartmentContactDetailsGetByIdUrl + id, null);
      }
    
      ChangeActiveStatus(id: number) {
        return this._baseService.get(AppSetting.DepartmentContactDetailsUpdateStatusUrl + id);
      }
    
      GetDepartmentByCode(id: number) {
        return this._baseService.get(AppSetting.GetDepartmentByCodeUrl + id);
      }
    
      GetDepartmentOfficerByDepartment(departmentCode: number) {
        return this._baseService.get(AppSetting.DepartmentOfficerByDepartmentUrl + departmentCode);
      }
}
