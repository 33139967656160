import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { GlobalMessagesModel } from 'src/app/shared/model/common-messages.model';
import { MasterTypePostModel, MasterTypeViewModel } from 'src/app/shared/model/master/master-type.model';
import { UserViewModel } from 'src/app/shared/model/user.model';
import { AlertService } from 'src/app/shared/service/alert.service';
import { AuthenticationService } from 'src/app/shared/service/authentication.service';
import { CommonService } from 'src/app/shared/service/common.service';
import { MasterTypeService } from 'src/app/shared/service/master/master-type.service';
import { UserService } from 'src/app/shared/service/user.service';

@Component({
  selector: 'app-add-update-master-type-data',
  templateUrl: './add-update-master-type-data.component.html',
  styleUrls: ['./add-update-master-type-data.component.scss']
})

export class AddUpdateMasterTypeDataComponent implements OnInit {
  //#region  Variable's
  id: number;
  model = new MasterTypePostModel();
  loginData: UserViewModel;
  name = new FormControl('', [Validators.required]);
  title: string = "Add";
  existMsg = "";
  //#endregion

  //#region Constructor
  constructor(
    private readonly _alertService: AlertService,
    public readonly _dialogRef: MatDialogRef<AddUpdateMasterTypeDataComponent>,
    private readonly _masterTypeService: MasterTypeService,
    private readonly _authService: AuthenticationService,
    @Inject(MAT_DIALOG_DATA) public data: any) {

    if (data) {
      this.title = "Update";

      this.id = data;
      this.GetById();
    }

  }
  //#endregion

  //#region Method's
  ngOnInit() {
    this.loginData = this._authService.GetCurrentUserDetail().UserViewModel;
  }


  GetById() {
    this._masterTypeService.GetById(this.id).subscribe(
      data => {
        if (
          (data.IsSuccess)
        ) {
          let result = <MasterTypeViewModel>data.Data;
          this.model.MasterTypeName = result.MasterTypeName;
          this.model.MasterTypeCode = result.MasterTypeCode;
          this.model.AddUpdateUserId = result.CreatedBy;
        }
      },
      error => {
        this.model = new MasterTypePostModel();
        this._alertService.error(error.message);
      }
    );
  }


  SaveClick() {

    this.name.markAsTouched();

    if (this.name.valid) {
      this.model.AddUpdateUserId = this.loginData.UserCode;
      this._masterTypeService.AddUpdate(this.model).subscribe(data => {
        if (data.IsSuccess) {
          this._alertService.success(data.Message);
          this._dialogRef.close(true);
        }
        else {
          this._alertService.error(data.Message);

        }
      }, error => {
        this._alertService.error(error.message);
      });


    }

  }

  onNoClick(): void {
    this._dialogRef.close();
  }

  checkExist() {
    this.existMsg = undefined;
    this._masterTypeService.CheckExistMasterType(this.model.MasterTypeName.trim(), this.model.MasterTypeCode).subscribe(
      data => {

        if (data.IsSuccess) {
          let result = <MasterTypeViewModel>data.Data;
          if (result) {
            this.model.MasterTypeName = undefined;
            this.existMsg = GlobalMessagesModel.Exist

          }
        }
      },
      error => {
        this.model = new MasterTypePostModel();
        this._alertService.error(error.message);
      }
    );
  }


  //#endregion

}
