import { Component, OnInit, ViewChild } from "@angular/core";
import { MatTableDataSource, MatDialog, MatSort, MatPaginator } from "@angular/material";
import { IndexModel } from 'src/app/shared/model/common.model';
import { ColumnHeaderModel, DdlItemModel } from 'src/app/shared/model/commonddl.model';
import { AppComponent } from 'src/app/app.component';
import { AlertService } from 'src/app/shared/service/alert.service';
import { ConfirmationDialogComponent } from 'src/app/confirmation-dialog/confirmation-dialog.component';
import { GlobalMessagesModel } from 'src/app/shared/model/common-messages.model';
import { AppSetting } from 'src/app/shared/model/appsetting.model';
import { VendorModel } from 'src/app/shared/model/vendor.model';
import { VendorService } from 'src/app/shared/service/vendor.service';
import { AuthenticationService } from 'src/app/shared/service/authentication.service';
import { UserTypeEnum } from 'src/app/shared/enum/fixed-values.enum';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/shared/service/common.service';
import { DDLModel } from 'src/app/shared/model/commonddl.model';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { VendorFilterModel } from 'src/app/shared/model/vendor-filter.model';
import { UserViewModel } from 'src/app/shared/model/user.model';
import { isNullOrUndefined } from 'util';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';


const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';
declare const require: any;
const jsPDF = require('jspdf');
require('jspdf-autotable');

@Component({
  selector: 'app-vendor-list-report',
  templateUrl: './vendor-list-report.component.html',
  styleUrls: ['./vendor-list-report.component.scss']
})
export class VendorListReportComponent implements OnInit {
  model: VendorModel[];
  indexModel: IndexModel;
  dataSource: any;
  totalRecords: number;
  loginUserCode: number;
  loginBaseUserType: string;
  userTypeEnum=UserTypeEnum;
 
  ddlVerified: DdlItemModel[] = [];
  ddlList: DDLModel;
  frmGrp: FormGroup;
  isVndrDisabled: boolean = true;
  isDeptDisabled: boolean = true;
  isDistDisabled: boolean = true;
  isOfficeDisabled: boolean = true;
  filterModel: VendorFilterModel = new VendorFilterModel();
  loginUserDetail: UserViewModel;

  displayedColumns: string[] = [];
  columnsToDisplay: string[] = [];
  ViewdisplayedColumns: ColumnHeaderModel[] = [
    { Value: "VendorType", Text: "Vendor Type" },
    { Value: "CompanyName", Text: "Company" },
    { Value: "ContactPersonName", Text: "Contact Person" },
    { Value: "Email", Text: "Email" },
    { Value: "CompanyMobileNos", Text: "Mobile No" },
    { Value: "ModifiedOn", Text: "Modified On" },
    { Value: "UserName", Text: "Modified By" },
    { Value: "IsVerified", Text: "Is Verified" }

  ];
  SearchColumns: ColumnHeaderModel[] = [
    { Value: "All", Text: "All" },
    { Value: "1", Text: "True" },
    { Value: "0", Text: "False" },
  ];
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(
    private readonly appComponnet: AppComponent,
    public readonly _commonService: CommonService,
    private readonly _alertService: AlertService,
    private readonly _vendorService: VendorService,
    private readonly _dialog: MatDialog,
    private readonly _router: Router,
    private readonly _authService: AuthenticationService
  ) {
    this.loginUserCode = this._authService.UserCode;
    this.loginBaseUserType = this._authService.BaseUserType;
    this.loginUserDetail = this._authService.GetCurrentUserDetail().UserViewModel;

    this.GetDDLList();
    this.GetVendorType();

    this.appComponnet.setPageLayout("Vendor List Report :", "", "", "");
    this.indexModel = new IndexModel();


    this.displayedColumns = ["index", "VendorType", "CompanyName", "ContactPersonName", "Email", "CompanyMobileNos", "UserName", "ModifiedOn", "IsVerified"];
    if (this.loginBaseUserType == this.userTypeEnum.ADM || this.loginBaseUserType == this.userTypeEnum.SADM) {
      this.displayedColumns.push("IsActive");
    }
    this.displayedColumns.push("Action");
    this.columnsToDisplay = this.displayedColumns.slice();
  }

  ngOnInit() {
    if (this.loginBaseUserType !=  this.userTypeEnum.ADM && this.loginBaseUserType != this.userTypeEnum.SADM && this.loginBaseUserType !=  this.userTypeEnum.DREC && this.loginBaseUserType !=  this.userTypeEnum.DRECADLC && this.loginBaseUserType !=  this.userTypeEnum.HOD) {
      this._router.navigate(["dashboard"]);
    }
    this.getSearchList();
  }

  sortData(event) {
    this.indexModel.OrderBy = event.active;
    this.indexModel.OrderByAsc = event.direction == AppSetting.orderByDscAsc ? AppSetting.orderByAsc : AppSetting.orderByDsc;
    this.indexModel.IsPostBack = true;
    this.getSearchList();
  }

  onPaginateChange(event) {
    this.indexModel.Page = event.pageIndex + 1;
    this.indexModel.PageSize = event.pageSize;
    this.indexModel.IsPostBack = true;
    this.getSearchList();
  }

  SearchByKeyword(searchValue) {
    this.indexModel.Search = searchValue;
    this.getSearchList();
  }

  GetDDLList() {
    this._commonService
      .GetAllDDL(AppSetting.FilterDropdownKeys)
      .subscribe(
        (data) => {
          if (data.IsSuccess) {

            this.ddlList = <DDLModel>data.Data;
            this.GetProjectDDLList();
            this.filterModel.DepartmentCode = this.loginUserDetail.DepartmentCodes;
            if (!isNullOrUndefined(this.loginUserDetail.VendorCode)) {
              // this.filterModel.VendorCode = this.loginUserDetail.VendorCode.toString();
            }
            else if (!isNullOrUndefined(this.loginUserDetail.VendorUser_VendorCode)) {
              // this.filterModel.VendorCode = this.loginUserDetail.VendorUser_VendorCode.toString();
            }
            this.GetOffice();
          }
        },
        (error) => {
          this._alertService.error(error.message);
        }
      );
  }

  GetProjectDDLList() {
    var userDept = (this.loginUserDetail.DepartmentCodes == null) ? 0 : this.loginUserDetail.DepartmentCodes;
    this._commonService.GetDDL(AppSetting.ddlProject, userDept, this.loginUserDetail.UserCode).subscribe(
      data => {
        if (data.IsSuccess) {
          var ddl = <DDLModel>data.Data;
          this.ddlList.ddlProject = ddl.ddlProject;
        }
      },
      error => {
        this._alertService.error(error.message);
      }
    );
  }

  GetOffice() {
    this.GetProjectListByDepartmentWise();
    if (!isNullOrUndefined(this.filterModel.DepartmentCode)) {
      this.filterModel.DistrictCode =
        this.filterModel.DistrictCode == undefined ? 0 : this.filterModel.DistrictCode;
      this._commonService
        .GetDDL(
          AppSetting.ddlDepartmentOffice,
          this.filterModel.DepartmentCode,
          this.filterModel.DistrictCode
        )
        .subscribe(
          (data) => {
            if (data.IsSuccess) {
              var ddl = <DDLModel>data.Data;
              this.ddlList.ddlDepartmentOffice = ddl.ddlDepartmentOffice;
            }
          },
          (error) => {
            this._alertService.error(error.message);
          }
        );

    } else {
      this.ddlList.ddlDepartmentOffice = null;
    }
  }

  GetProjectListByDepartmentWise() {
    var userDept = (this.loginUserDetail.DepartmentCodes == null) ? 0 : this.loginUserDetail.DepartmentCodes;

    if (!isNullOrUndefined(this.filterModel.DepartmentCode)) {

      this._commonService.GetDDL(AppSetting.ddlProject, this.filterModel.DepartmentCode, this.loginUserDetail.UserCode).subscribe(
        data => {
          if (data.IsSuccess) {
            var ddl = <DDLModel>data.Data;
            this.ddlList.ddlProject = ddl.ddlProject;
          }
        },
        error => {
          this._alertService.error(error.message);
        }
      );
    }
    else {
      this.GetProjectDDLList();
    }

  }

  GetVendorType() {

    this._commonService.GetAllDDL(AppSetting.VendorDropdownKeys).subscribe(
      (data) => {
        if (data.IsSuccess) {

          this.ddlList = <DDLModel>data.Data;
        }
      },
      (error) => {
        this._alertService.error(error.message);
      }
    );
  }

  onSearch() {
    this.filterModel.LoginUserCode = this.loginUserDetail.UserCode;
    this.getSearchList();

  }

  onClearClick() {
    this.filterModel = new VendorFilterModel();
    this.onSearch();
    this.GetDDLList();
  }

  getSearchList() {
    this.filterModel.LoginUserCode = 0;
    this._vendorService.GetSearchList(this.filterModel).subscribe(
      data => {
        if (data.IsSuccess) {

          this.model = <VendorModel[]>data.Data.Data;
          this.dataSource = new MatTableDataSource<VendorModel>(this.model);
          this.totalRecords = data.Data.TotalRecords;
          if (!this.indexModel.IsPostBack) {
            this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.sort;
          }
        } else {
          this._alertService.error(data.Message);
        }
      },
      error => {
        this._alertService.error(error.message);
      }
    );
  }


  completeModel: VendorModel[];//change
  completeIndexModel: IndexModel;
  getCompleteData() {
    this.completeIndexModel = new IndexModel();
    this.completeIndexModel.Page = 1;
    this.completeIndexModel.PageSize = this.totalRecords;
    this.completeIndexModel.IsPostBack = true;

    this._vendorService.GetSearchList(this.filterModel).subscribe( //change
      (completeData: any) => {
        if (completeData.IsSuccess) {
          this.completeModel = <VendorModel[]>completeData.Data.Data; //change

          this.totalRecords = completeData.Data.TotalRecords;
          //this.download();
        } else {
          this._alertService.error(completeData.Message);
        }
      },
      error => {
        this._alertService.error(error.message);
      }
    );
  }

  DownloadPdf() {

    this._vendorService.GetVendorListPDFDownload(this.filterModel).subscribe(
      data => {
        if (data.IsSuccess) {

          // const linkSource = data.Data;
          // const downloadLink = document.createElement("a");
          // const fileName = 'Vendor List Report';

          // downloadLink.href = linkSource;
          // downloadLink.download = fileName;
          // downloadLink.target = "blank";
          // downloadLink.click();
          var w = window.open("about:blank");

          setTimeout(function () {
            //FireFox seems to require a setTimeout for this to work.
            w.document.body.appendChild(w.document.createElement("iframe")).src = data.Data;
            w.document.getElementsByTagName("iframe")[0].style.width = "100%";
            w.document.getElementsByTagName("iframe")[0].style.height = "100%";
          }, 0);
        } else {
          this._alertService.error(data.Message);
        }
      },
      error => {
        this._alertService.error(error.message);
      }
    );
  }

  buttonClickExportAsPDF(): void {
    // this.getCompleteData();
    // setTimeout(() => {
    //   this.download();
    //   this.exportAsPdf();
    // }, 1000);
    this.DownloadPdf();
  }
  buttonClickExportAsXLSX(): void {
    this.getCompleteData();
    setTimeout(() => {
      this.download();
      this.exportAsExcelFile();
    }, 1000);
  }

  rowsNew: any = [];

  download() {
    //completeModel
    var i = 1;
    this.rowsNew = [];
    this.completeModel.forEach(element => {
      var temp = {
        SNo: i, VendorType: element.VendorType,
        CompanyName: element.CompanyName, ContactPersonName: element.ContactPersonName, Email: element.Email, CompanyMobileNos: element.CompanyMobileNos, IsActive: element.IsActive, ModifiedBy: element.UserName, ModifiedOn: element.ModifiedOn, IsVerified: element.IsVerified
      }; //change
      i = i + 1;
      this.rowsNew.push(temp);
    });
  }

  public exportAsPdf(): void {
    var doc = new jsPDF('p', 'pt');
    var header = function (data) {
      doc.setFontSize(10);
      doc.setTextColor(40);
      doc.text("AttachmentType", data.settings.margin.left, 50);
      doc.text("Date Time : " + new Date().toLocaleDateString() + " " + new Date().toLocaleTimeString(), data.settings.margin.left, 800);
    };
    var headers = [{
      title: "#",
      dataKey: "index"
    }, {
      title: "Vendor Type",
      dataKey: "VendorType"
    },
    {
      title: "CompanyName",
      dataKey: "CompanyName"
    },
    {
      title: "Person Name",
      dataKey: "ContactPersonName"
    },
    {
      title: "Email",
      dataKey: "Email"
    },
    {
      title: "CompanyMobileNos",
      dataKey: "CompanyMobileNos"
    },
    {
      title: "Is Active",
      dataKey: "IsActive"
    },
    {
      title: "Modified By",
      dataKey: "ModifiedBy"
    },
    {
      title: "Modified Date",
      dataKey: "ModifiedDate"
    },
    {
      title: "Is Verified",
      dataKey: "IsVerified"
    }
    ];
    doc.autoTable(headers, this.rowsNew, { margin: { top: 70 }, beforePageContent: header });
    doc.save("VendorList-Report" + new Date().toLocaleDateString() + "_" + new Date().toLocaleTimeString() + ".pdf");
  } //change
  public exportAsExcelFile(): void {
    //this.getCompleteData();
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.rowsNew);
    const workbook: XLSX.WorkBook = { Sheets: { 'vendorReport': worksheet }, SheetNames: ['vendorReport'] }; //change
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFile(excelBuffer, 'VendorList'); //change
  }
  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], { type: EXCEL_TYPE });
    FileSaver.saveAs(data, fileName + '_Report_' + new Date().toLocaleDateString() + "_" + new Date().toLocaleTimeString() + EXCEL_EXTENSION);
  }

}
