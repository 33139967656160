import { Component, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { MatPaginator, MatSort, MatTableDataSource } from "@angular/material";
import { AppComponent } from "src/app/app.component";
import { UserTypeEnum } from "src/app/shared/enum/fixed-values.enum";
import { AppSetting } from "src/app/shared/model/appsetting.model";
import { IndexModel, PermissionModel, GlobalFilterModel } from "src/app/shared/model/common.model";
import { UserManualViewModel, UserManualModel } from "src/app/shared/model/master/user-manual.model";
import { UserTypeModel } from "src/app/shared/model/user-type.model";
import { AlertService } from "src/app/shared/service/alert.service";
import { AuthenticationService } from "src/app/shared/service/authentication.service";
import { CommonService } from "src/app/shared/service/common.service";
import { UserManualService } from "src/app/shared/service/master/user-manual.service";
import { UserTypeService } from "src/app/shared/service/user-type.service";
import { DdlItemModel, DDLModel } from "./../../../shared/model/commonddl.model";

@Component({
  selector: 'app-user-manual',
  templateUrl: './user-manual.component.html',
  styleUrls: ['./user-manual.component.scss']
})

export class UserManualComponent implements OnInit {
  listModel: UserManualViewModel[];
  ddlUserType: DdlItemModel[] = [];
  accessUserTypeList: UserTypeModel[] = [];
  loginBaseUserType: string;
  dataSource: any;
  displayedColumns: string[] = ["index", "UserTypeTitle","UserCategory", "UserManualUrl", "SupportContactUrl", "ModifiedDate", "Action"];
  columnsToDisplay: string[] = this.displayedColumns.slice();
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  indexModel: IndexModel;
  totalRecords: number;
  isVendorUserCategoryShow: boolean = false;
  get f() { return this.formGroup.controls; }
  model: UserManualModel;
  fileValidationMsg: string;
  fileValidationblankMsg: string;
  userManualFileName: string;
  contactFileName: string;
  formGroup: FormGroup;


  Permission: PermissionModel = this._authService.GetPagePermission("/master/help-document", "/help-document/create", "/help-document/detail", "/help-document/update", "/help-document/delete");

  ddlList = new DDLModel();
  constructor(
    private readonly fb: FormBuilder,
    private readonly _userManualService: UserManualService,
    private readonly _alertService: AlertService,
    public readonly _commonService: CommonService,
    private readonly _userTypeService: UserTypeService,
    private readonly _authService: AuthenticationService,
    private readonly _parentApi: AppComponent
  ) {
    this._parentApi.setPageLayout("Help Document :", "", "", "");
    this.model = new UserManualModel();
    this.indexModel = new IndexModel();
    this.loginBaseUserType = this._authService.BaseUserType;
  }

  ngOnInit() {
    this.formGroupInit();
    this.GetList();
    this.getDownLevelUserType();

  }
  //#region << List method >>

  GetList() {
    
    this._userManualService.GetList(this.indexModel).subscribe(
      data => {
        if (data.IsSuccess) {
          
          this.listModel = <UserManualViewModel[]>data.Data.Data;
          this.dataSource = new MatTableDataSource<UserManualViewModel>(this.listModel);
          if (this.indexModel.IsPostBack == false) {
            this.dataSource.paginator = this.paginator;
            this.totalRecords = data.Data.TotalRecords;
            this.dataSource.sort = this.sort;
          }
        }
      },
      error => {
        this._alertService.error(error.message);
      }
    );
  }

  SortData(event) {
    this.indexModel.OrderBy = event.active;
    this.indexModel.OrderByAsc =
      event.direction == AppSetting.orderByDscAsc ? AppSetting.orderByAsc : AppSetting.orderByDsc;
    this.indexModel.IsPostBack = true;
    this.GetList();
  }

  onPaginateChange(event) {
    this.indexModel.Page = event.pageIndex + 1;
    this.indexModel.PageSize = event.pageSize;
    this.indexModel.IsPostBack = true;
    this.GetList();
  }

  PagePrint(printContent) {
    this._commonService.printHTMLContent(printContent, "Help Document");
  }

  //#endregion 

  //#region << Add/Update Method >>
  formGroupInit() {
    this.formGroup = this.fb.group({
      file: [null, Validators.required],
      blankfile: [null, null],
      typeCode: [null, Validators.required],
      UserCategoryCode: [null, null]

    });
  }

  getDownLevelUserType() {
    let isIncludeItself = (this.loginBaseUserType == UserTypeEnum.ADM || this.loginBaseUserType == UserTypeEnum.SADM) ? true : false;
    this._userTypeService.GetDownLevelUserType(this.loginBaseUserType, isIncludeItself, -1).subscribe(
      (data) => {
        if (data.IsSuccess) {
          this.ddlUserType = [];
          var userTypeList = <UserTypeModel[]>data.Data;
          userTypeList.forEach((element) => {
            this.ddlUserType.push({
              Value: element.UserTypeCode.toString(),// Value: element.UserType,
              Text: element.UserTypeTitle + " (" + element.UserType + ")",
            });
            this.accessUserTypeList.push(element);
          });
        }
      },
      (error) => {
        this._alertService.error(error.message);
      }
    );
  }

  RemoveSupportDoc(isLogo: boolean = true) {
    if (isLogo) {
      this.model.SupportContactUrl = null;
    }
    else {
      this.model.SupportContactUrl = null;
    }
  }

  RemoveHelpDoc(isLogo: boolean = true) {
    if (isLogo) {
      this.model.UserManualUrl = null;
    }
    else {
      this.model.UserManualUrl = null;
    }

  }

  handleFileInput(event: any) {

    
    if (event.target.files.item(0).type.match("application/pdf")) {
      const reader = new FileReader();
      reader.onload = (ev: any) => {
        this.model.UserManualUrl = ev.target.result;
        this.userManualFileName = event.target.files.item(0).name;
      };
      reader.readAsDataURL(event.target.files.item(0));
      this.fileValidationMsg = "";
    } else {
      this.fileValidationMsg = "only pdf file accepted ";
      this.model.UserManualUrl = undefined;
    }
  }

  handleBlankFileInput(event: any) {
    
    if (event.target.files.item(0).type.match("application/pdf")) {
      const reader = new FileReader();
      reader.onload = (ev: any) => {
        this.model.SupportContactUrl = ev.target.result;
        this.contactFileName = event.target.files.item(0).name;
      };
      reader.readAsDataURL(event.target.files.item(0));
      this.fileValidationblankMsg = "";
    } else {
      this.fileValidationblankMsg = "only pdf file accepted ";
      this.model.SupportContactUrl = undefined;

    }
  }

  saveClick() {
    
    this.formGroup.markAllAsTouched();
    if (this.model.Id > 0) {
      if (this.formGroup.valid) {
        this._userManualService.Edit(this.model).subscribe(
          data => {
            if (data.IsSuccess) {
              this.GetList();
              this._alertService.success(data.Message);

              this.model = new UserManualModel();
            } else {
              this._alertService.error(data.Message);
            }
          },
          error => {
            this._alertService.error(error.error.ExceptionMessage);
          }
        );

      }
    } else {
      this.formGroup.markAsTouched();
      if (this.formGroup.valid) {
        this._userManualService.Add(this.model).subscribe(
          data => {
            if (data.IsSuccess) {
              this.model = new UserManualModel();
              this.GetList();
              this._alertService.success(data.Message);
            } else {
              this._alertService.error(data.Message);
            }
          },
          error => {
            this._alertService.error(error.error.ExceptionMessage);
          }
        );

      }
    }
  }


  getByID(id) {
    this._userManualService.GetById(id, true).subscribe(
      data => {
        if (data.IsSuccess) {
          
          this.model = new UserManualModel();
          this.model = <UserManualModel>data.Data;
          this.model.UserTypeCode = String(this.model.UserTypeCode);
          this.model.UserCategoryCode = this.model.UserCategoryCode ? String(this.model.UserCategoryCode) : undefined;


          this.f.file.clearValidators();
          this.f.file.updateValueAndValidity();

          this.userManualFileName = "click to Download";
          this.contactFileName = "click to Download";


        } else {
          this._alertService.error(data.Message);
        }
      },
      error => {
        this._alertService.error(error.error.ExceptionMessage);
      }
    );
  }

  reset() {
    this.model = new UserManualModel();

  }

  canUpdateRecord(userType: string): boolean {
    let canUpdate = false;
    var records = this.accessUserTypeList.filter(t => t.UserType == userType);
    if (this.Permission.UpdatePageAccess && (records.length > 0)) {
      canUpdate = true;
    }

    return canUpdate;
  }


  getUserCategory() {

    this.ddlList.ddlUserCategory = [];
    if (this.model.UserTypeCode && this.model.UserTypeCode == UserTypeEnum.VNDRUSERCode) {

      this._commonService.GetDDL(AppSetting.DDlKeyForUserCategory, this.model.UserTypeCode).subscribe(
        data => {
          if (data.IsSuccess) {
            let ddlList = <DDLModel>data.Data;
            this.ddlList.ddlUserCategory = ddlList.ddlUserCategory;
            this.f.UserCategoryCode.setValidators([Validators.required]);
            this.isVendorUserCategoryShow = true;

          }
        },
        error => {
          this._alertService.error(error.message);
        }
      );
    }
    else {
      this.isVendorUserCategoryShow = false;
      this.f.UserCategoryCode.setValidators(null);

    }
    this.f.UserCategoryCode.updateValueAndValidity();
  }
  //#endregion
}
