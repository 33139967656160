import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { GlobalMessagesModel } from 'src/app/shared/model/common-messages.model';
import { AdminDepartmentMasterModel } from 'src/app/shared/model/master/admin-department.model';
import { UserViewModel } from 'src/app/shared/model/user.model';
import { AlertService } from 'src/app/shared/service/alert.service';
import { AuthenticationService } from 'src/app/shared/service/authentication.service';
import { CommonService } from 'src/app/shared/service/common.service';
import { AdminDepartmentService } from 'src/app/shared/service/master/admin-department.service';
import { UserService } from 'src/app/shared/service/user.service';

@Component({
  selector: 'app-add-update-admin-department',
  templateUrl: './add-update-admin-department.component.html',
  styleUrls: ['./add-update-admin-department.component.scss']
})
export class AddUpdateAdminDepartmentComponent implements OnInit {

  id: number;
  model: AdminDepartmentMasterModel;
  name = new FormControl('', [Validators.required]);
  AdmDeptShortTitle=new FormControl('',null);
  
  title: string = "Add";
  submitBtn = true;

  constructor(
    private readonly _userService: UserService,
    private readonly _alertService: AlertService,
    public readonly _dialogRef: MatDialogRef<AddUpdateAdminDepartmentComponent>,
    private readonly _adminDepartmentService: AdminDepartmentService,
    private readonly _authService: AuthenticationService,
    public readonly _commonService: CommonService,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    if (data) {
      this.id = data;
      this.GetById();
    }
    this.model = new AdminDepartmentMasterModel();
  }

  ngOnInit() {
  }

  GetById() {
    this._adminDepartmentService.GetById(this.id).subscribe(
      data => {
        if (
          (data.IsSuccess)
        ) {
          this.model = <AdminDepartmentMasterModel>data.Data;
        }
      },
      error => {
        this.model = new AdminDepartmentMasterModel();
        this._alertService.error(error.message);
      }
    );
  }


  SaveClick() {
    
    this.submitBtn = false;
    this.name.markAsTouched();
    if (this.name.valid) {
      if (this.model.AdmDepartmentId>0) {

        this._adminDepartmentService.Edit(this.model).subscribe(data => {
          if (data.IsSuccess) {
            
            this._alertService.success(data.Message);
            this._dialogRef.close(true);
          }
          else {
            this.submitBtn = true;
            this._alertService.error(data.Message);

          }
        }, error => {
          this.submitBtn = true;
          this._alertService.error(error.message);
        });
      }
      else {
        this._adminDepartmentService.Add(this.model).subscribe(data => {
          
          if (data.IsSuccess) {
            
            this._alertService.success(data.Message);
            this._dialogRef.close(true);
          }
          else {
            this.submitBtn = true;
            this._alertService.error(data.Message);
          }
        }, error => {
          this.submitBtn = true;
          this._alertService.error(error.message);
        });
      }
    }
    else {
      this.submitBtn = true;
    }

  }

  onNoClick(): void {

    this._dialogRef.close();
  }
  IsAdminDepartmentMasterExist() {

    this._adminDepartmentService.IsAdminDepartmentExist(this.model.AdmDepartmentTitle, this.model.AdmDepartmentId).subscribe(result => {
      if (result.IsSuccess) {
        let UserName = <boolean>result.Data
     
          this.model.AdmDepartmentTitle=undefined;
          this._alertService.error(GlobalMessagesModel.Exist);
       
      }
    }, error => {
      this._commonService.ScrollingTop();
      this._alertService.error(error.message);
    });

  }

}
