export class ConcernModel {
    ConcernCode: number;
    ConcernName: string;
    ConcernNameHindi: string;
    IsActive: boolean;
    IsDeleted: boolean;
    CreatedBy: number | null;
    CreatedOn: Date | null;
    ModifiedBy: number | null;
    ModifiedOn: Date | null;
    UserName: String;
}

export class ConcernReportModel {
    MasterCode: number;
    FieldValueInEnglish: string;
    FieldValueInHindi: string;
    IsActive: boolean;
    IsDeleted: boolean;
    CreatedBy: number | null;
    CreatedOn: Date | null;
    ModifiedBy: number | null;
    ModifiedOn: Date | null;
    UserName:string;
}
