import { Injectable } from '@angular/core';
import { AppSetting } from '../../model/appsetting.model';
import { IndexModel } from '../../model/common.model';
import { Dictionary } from '../../model/dictionary.model';
import { MasterTypePostModel } from '../../model/master/master-type.model';
import { BaseService } from '../base.service';

@Injectable({
  providedIn: 'root'
})
export class MasterTypeService {

  
  constructor(private readonly _baseService: BaseService, ) { }

  GetList(model: IndexModel) {
    return this._baseService.post(AppSetting.MasterTypeListUrl, model);
  }

  AddUpdate(model: MasterTypePostModel) {
    return this._baseService.post(AppSetting.MasterTypeAddUpdateUrl, model);
  }


  ChangeActiveStatus(id: number) {
    return this._baseService.get(AppSetting.MasterTypeUpdateIsActiveUrl + id);
  }

  ChangeDeleteStatus(id: number) {
    return this._baseService.get(AppSetting.MasterTypeDeleteUrl + id);
  }

   GetById(id: number) {
    return this._baseService.get(AppSetting.MasterTypeDetailUrl + id);
  }

  
  CheckExistMasterType(name: string, id: number=0) {
    const param = new Dictionary<any>();
    param.Add("name", name);
    param.Add("id", id);
    return this._baseService.get(AppSetting.MasterTypeCheckExistMasterTypeUrl ,param);
  }

}
