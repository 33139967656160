import { Injectable } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { BehaviorSubject, Observable } from "rxjs";
import { filter } from "rxjs/operators";

@Injectable({
    providedIn: "root"
})

export class UrlService {

    private previousUrl: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);
    private currentUrl: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);

    public constructor(
        private router: Router
    ) {
        this.router.events.pipe(
            filter((event) => event instanceof NavigationEnd)
        ).subscribe((event: any) => {
            this.setPreviousUrl(this.currentUrl.getValue());
            this.setCurrentUrl(event.url);
        });
    }

    public previousUrl$: Observable<string | null> = this.previousUrl.asObservable();
    public currentUrl$: Observable<string | null> = this.currentUrl.asObservable();

    private setPreviousUrl(previousUrl: string | null) {
        if (previousUrl != null) {
            localStorage.setItem('previousUrl', previousUrl);
            this.previousUrl.next(previousUrl);
        }
        else if (previousUrl == null) {
            this.previousUrl.next(localStorage.getItem('previousUrl'));
        }

    }

    private setCurrentUrl(currentUrl: string | null) {
        this.currentUrl.next(currentUrl);
    }
}