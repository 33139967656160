import {
  ComponentFactoryResolver,
  ComponentRef,
  Directive,
  Input,
  OnInit,
  ViewContainerRef
} from "@angular/core";
import { FormGroup } from "@angular/forms";
import { InputComponent } from "../input/input.component";
import { ButtonComponent } from "../button/button.component";
import { SelectComponent } from "../select/select.component";
import { DateComponent } from "../date/date.component";
import { RadiobuttonComponent } from "../radiobutton/radiobutton.component";
import { CheckboxComponent } from "../checkbox/checkbox.component";
import { DynamicFieldConfig } from "src/app/shared/model/dynamic-field-config.model";
import { TextInfoComponent } from "../text-info/text-info.component";

const componentMapper = {
  input: InputComponent,
  button: ButtonComponent,
  select: SelectComponent,
  date: DateComponent,
  radiobutton: RadiobuttonComponent,
  checkbox: CheckboxComponent,
  dropdown: SelectComponent,
  TEXTBOX: InputComponent,
  DROPDOWN: SelectComponent,
  CHECKBOX: CheckboxComponent,
  RADIOBUTTON: RadiobuttonComponent,
  TEXTAREA: InputComponent,
  DATE:DateComponent,
  TEXTINFO: TextInfoComponent,

};
@Directive({
  selector: "[dynamicField]"
})
export class DynamicFieldDirective implements OnInit {
  @Input() field: DynamicFieldConfig;
  @Input() fields: DynamicFieldConfig[];

  @Input() group: FormGroup;
  componentRef: any;
  constructor(
    private resolver: ComponentFactoryResolver,
    private container: ViewContainerRef
  ) { }
  ngOnInit() {
    
    if (this.field.type.toUpperCase() != "SECTIONHEADING"){
      const factory = this.resolver.resolveComponentFactory(
        componentMapper[this.field.type.toUpperCase()]
      );
      this.componentRef = this.container.createComponent(factory);
      this.componentRef.instance.field = this.field;
      this.componentRef.instance.fields = this.fields;
      this.componentRef.instance.group = this.group;
    }
  }
}
