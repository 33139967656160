export class ComplaintTypeModel {
    ComplaintTypeCode: number;
    ComplaintType: string;
    ComplaintTypeHindi: string;
    IsActive: boolean;
    IsDeleted: boolean;
    CreatedBy: number | null;
    CreatedOn: Date | null;
    ModifiedBy: number | null;
    ModifiedOn: Date | null;
    UserName: String;
}

export class ComplaintTypeReportModel {
    MasterCode: number;
    FieldValueInEnglish: string;
    FieldValueInHindi: string;
    IsActive: boolean;
    IsDeleted: boolean;
    CreatedBy: number | null;
    CreatedOn: Date | null;
    ModifiedBy: number | null;
    ModifiedOn: Date | null;
    UserName:string;
}
