import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { IndexModel } from '../model/common.model';
import { AppSetting } from '../model/appsetting.model';
import { WorkOrderClearModel, WorkOrderModel, WorkOrderSearchModel } from '../model/work-order.model';
import { AuthenticationService } from './authentication.service';
import { UserTypeEnum } from '../enum/fixed-values.enum';
//import { WorkOrderFilterModel } from '../model/work-order-filter.model';

@Injectable({
    providedIn: 'root'
})

export class WorkOrderService {

    constructor(private readonly _baseService: BaseService, private readonly _authService: AuthenticationService) { }

    GetList(loginUserCode: number, model: IndexModel) {
        return this._baseService.post(AppSetting.WorkOrderListUrl + "?loginUserCode=" + loginUserCode, model);
    }

    AddUpdate(id: number, model: WorkOrderModel) {
        return this._baseService.post(AppSetting.WorkOrderAddUpdateUrl, model);
    }

    Detail(id: number) {
        return this._baseService.get(AppSetting.WorkOrderDetailUrl + id);
    }

    ChangeActiveStatus(id: number) {
        return this._baseService.get(AppSetting.WorkOrderActiveStatusChangeUrl + id);
    }

    ChangeDeleteStatus(id: number) {
        return this._baseService.get(AppSetting.WorkOrderDeleteStatusChangeUrl + id);
    }

    IsWorkOrderNoExist(orderNo: string) {
        return this._baseService.get(AppSetting.IsWorkOrderNoExistUrl + orderNo);
    }

    ChangeOICAccepted(id: number) {

        return this._baseService.get(AppSetting.OICAcceptedChangeUrl + id);
    }

    GetWorkorderListPDFDownload(loginUserCode: number, model: IndexModel) {
        return this._baseService.post(AppSetting.WorkOrderListPDFDownloadUrl + "?loginUserCode=" + loginUserCode, model);
    }

    ChangeAcceptMethod(id: number) {
        return this._baseService.get(AppSetting.WorkOrderChangeAcceptMethodChangeUrl + id);
    }

    GetFilterList(model: WorkOrderSearchModel) {
        model.LoginUserCode = this._authService.LoggedInUserDetail.UserViewModel.UserCode;
        return this._baseService.post(AppSetting.GetWorkOrderBySearchUrl, model);
    }

    canUserTypeCreateWO() {
        if (this._authService.BaseUserType == UserTypeEnum.DREC || this._authService.BaseUserType == UserTypeEnum.HOO
            || this._authService.BaseUserType == UserTypeEnum.DOS || this._authService.BaseUserType == UserTypeEnum.HOD
            || this._authService.BaseUserType == UserTypeEnum.DRECADLC || this._authService.BaseUserType == UserTypeEnum.HOOADLC
            || this._authService.BaseUserType == UserTypeEnum.DOSADLC || this._authService.BaseUserType == UserTypeEnum.VNDR
            || this._authService.BaseUserType == UserTypeEnum.VNDRUSER || this._authService.BaseUserType == UserTypeEnum.SADM || this._authService.BaseUserType == UserTypeEnum.ADM) {
            return true;
        } else {
            return false;
        }
    }

    WorkOrderClear(workorderClearmodel: WorkOrderClearModel) {
        return this._baseService.post(AppSetting.WorkOrderClear, workorderClearmodel);

    }
}
