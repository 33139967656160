export class StatusMasterModel {
    StatusCode: number;
    Status: string;
    StatusNameCommList: string;
    VTOD_VendorDisplayName: string;
    VTOD_DepartmentDisplayName: string;
    DTOV_VendorDisplayName: string;
    DTOV_DepartmentDisplayName: string;
    IsActive: boolean;
    IsDeleted: boolean;
    CreatedBy: number | null;
    CreatedOn: string | null;
    ModifiedBy: number | null;
    ModifiedOn: string | null;
}