import { Component, Inject, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { StateEnum, UserTypeEnum } from 'src/app/shared/enum/fixed-values.enum';
import { AppSetting } from 'src/app/shared/model/appsetting.model';
import { GlobalMessagesModel } from 'src/app/shared/model/common-messages.model';
import { DdlItemModel, DDLModel, FilterDDlModel } from 'src/app/shared/model/commonddl.model';
import { UserViewModel, UserCreationModel, userCreationConfigModel } from 'src/app/shared/model/user.model';
import { AlertService } from 'src/app/shared/service/alert.service';
import { AuthenticationService } from 'src/app/shared/service/authentication.service';
import { CommonService } from 'src/app/shared/service/common.service';
import { UserService } from 'src/app/shared/service/user.service';
import { isNullOrUndefined } from 'util';
import { SetUserPrimaryDetailDialogComponent } from '../set-user-primary-detail-dialog/set-user-primary-detail-dialog.component';

@Component({
  selector: 'app-update-profile',
  templateUrl: './update-profile.component.html',
  styleUrls: ['./update-profile.component.scss'],
})

export class UpdateProfileComponent implements OnInit {

  model = new UserCreationModel();
  UserForm: FormGroup;
  loginData: UserViewModel;
  fileValidationMsg: string;
  fieldConfig = new userCreationConfigModel();
  ddlList: DDLModel = new DDLModel();
  ddlDepartment: DdlItemModel[] = [];
  ddlAllDepartment: DdlItemModel[] = [];
  filterDDlModel: FilterDDlModel[] = [];
  selectedAll = -1;
  selectedDptAll = -1;
  AdminDepartment: any;
  userTypeEnum = UserTypeEnum;
  ddlDistrictByOffice: DdlItemModel[] = [];
  ddlProject: DdlItemModel[];
  isMultipleRights = true;
  isShowField = false;
  showClosePopupBtn: boolean = true;

  constructor(private readonly _authService: AuthenticationService,
    private readonly fb: FormBuilder,
    private readonly _commonService: CommonService,
    private readonly _alertService: AlertService,
    private _userService: UserService,
    public _dialogRef: MatDialogRef<UpdateProfileComponent>,
    private readonly _dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    if (data && data.hasCloseBtn) {
      this.showClosePopupBtn = JSON.parse(data.hasCloseBtn);

      let isTempLogin = this._commonService.GetLocalStorage('IsTempLogin');
      if (isTempLogin && isTempLogin == 'true') { this.showClosePopupBtn = true; }
    }
    this.loginData = this._authService.GetCurrentUserDetail().UserViewModel;
    this.getDDLList();
  }

  ngOnInit() {
    this.getUserDetail();
    this.formGroupInit();
    this.GetProjectDDLList();
  }

  getUserDetail() {
    this._userService.GetB2GUserById(this.loginData.UserCode).subscribe(data => {
      if (data.IsSuccess) {
        this.model = <UserCreationModel>data.Data;

        this.RebindField();
        if (this.model.AdminDepartment) {
          this.getFilterdDDL(this.model.AdminDepartment, 'ddlDepartment', 'ddlAdminDepartment');
        }
        if (this.model.DepartmentCode || this.model.OfficeCode || this.model.DistrictCode) {
          this.getOfficeList();
        }
        if (this.model.ParentDepartmentCode) {
          this.model.ParentDepartmentCode = this.model.ParentDepartmentCode.toString();
        }
        this.getDistrictByState();
      }
      //console.clear();

      this.isShowField = true;
    }, error => {
      this.closeDialog();
      this._alertService.error(error.message);
    });
  }

  getDDLList() {
    this._commonService.GetAllDDL(AppSetting.DDlKeyForUserProfile).subscribe(
      (data) => {
        if (data.IsSuccess) {
          let ddlList = <DDLModel>data.Data;
          this.ddlList.ddlAdminDepartment = ddlList.ddlAdminDepartment;
          this.ddlList.ddlState = ddlList.ddlState;
          this.ddlList.ddlTitle = ddlList.ddlTitle;
          this.ddlList.ddlGender = ddlList.ddlGender;
          this.ddlList.ddlDesignation = ddlList.ddlDesignation;

          this.ddlAllDepartment = ddlList.ddlDepartment;
        }
      },
      (error) => {
        this._alertService.error(error.message);
      }
    );
  }

  formGroupInit() {
    this.UserForm = this.fb.group({
      Name: [{ value: null, disabled: true }],
      SSOID: [{ value: null, disabled: true }],
      Email: [null, Validators.compose([Validators.required, Validators.email])],
      Mobile: [null, Validators.compose([Validators.required, Validators.maxLength(12)])],
      ProfilePic: [null, null],
      ParentDepartmentCode: [null, Validators.required],
      AdminDepartment: [null, null],
      Department: [null, null],
      District: [null, null],
      ProjectCodes: [null, null],
      StateCodes: [null, null],
      Office: [null, null],
      Designation: [null, null],
      Gender: [null, Validators.required]
    });
  }

  numberOnly(value, isCommaOrDash: boolean = false): boolean {
    return this._commonService.NumberOnly(value, isCommaOrDash);
  }

  handleFileInput(event: any) {
    this.fileValidationMsg = "";

    if (event.target.files.length > 0) {
      let fileType = event.target.files.item(0).type;
      //if (fileType.match("image/*")) {
      if (fileType == 'image/jpg' || fileType == 'image/jpeg' || fileType == 'image/png') {
        if (event.target.files.item(0).size > AppSetting.FileSizeLimit.IMG) {
          this.fileValidationMsg = AppSetting.FileSizeLimit.IMGErrorMSG;
          this._commonService.ScrollingTop();
        }
        else {
          let reader = new FileReader();
          reader.onload = (event: any) => {
            this.model.ProfilePic = event.target.result;
            this.fileValidationMsg = "";
          };
          reader.readAsDataURL(event.target.files.item(0));
        }
      }
      else {
        this.fileValidationMsg = "Only JPG, JPEG & PNG image file accepted!";
      }
    }
  }

  onSubmit() {
    this.UserForm.markAllAsTouched();
    if (this.UserForm.valid) {
      if (!this.isMultipleRights) {
        this.fieldControlChange(true)
      }
      this.RebindField(true);

      this.UserForm.markAllAsTouched();
      if (this.UserForm.valid) {

        this.model.IsUpdateProfile = true;

        if (this.isMultipleRights) {
          this.OpenSetPrimaryDetailPopup();
        }
        else {
          this.model.PrimaryDepartmentCode = this.model.DepartmentCode ? String(this.model.DepartmentCode) : undefined;
          this.model.PrimaryDistrictCode = this.model.DistrictCode ? String(this.model.DistrictCode) : undefined;
          this.model.PrimaryOfficeCode = this.model.OfficeCode ? String(this.model.OfficeCode) : undefined;
          this.saveUser();
        }
      }
    }
  }

  saveUser() {
    this._userService.AddUpdateUserCreation(this.model).subscribe((data) => {
      if (data != null && data.IsSuccess) {
        this._authService.LoggedInUserDetail.UserViewModel.IsUpdateProfile = true;
        this._alertService.success(this.loginData.UserCode == 0 ? GlobalMessagesModel.saveSuccess : GlobalMessagesModel.updateSuccess);
        this.closeDialog(true);
      } else {
        this._alertService.error(data.Message);
        this._alertService.error(this.loginData.UserCode == 0 ? data.message : GlobalMessagesModel.updateError);
      }
    }, (error) => {
      this._alertService.error(error.message);
    });
  }

  fieldControlChange(isChangeInList = false) {
    if (isChangeInList == true) {

      if (this.fieldConfig.IsAdminDepartmentShow) {
        this.model.AdminDepartment = !isNullOrUndefined(this.model.AdminDepartment) ? this.model.AdminDepartment.toString().split(',') : undefined;
      } else {
        this.model.AdminDepartment = undefined;
      }
      if (this.fieldConfig.IsDepartmentShow) {
        this.model.DepartmentCode = !isNullOrUndefined(this.model.DepartmentCode) ? this.model.DepartmentCode.toString().split(',') : undefined;
      }
      if (this.fieldConfig.IsDistrictShow) {
        this.model.DistrictCode = !isNullOrUndefined(this.model.DistrictCode) ? this.model.DistrictCode.toString().split(',') : undefined;
      }
      if (this.fieldConfig.IsOfficeShow) {
        this.model.OfficeCode = !isNullOrUndefined(this.model.OfficeCode) ? this.model.OfficeCode.toString().split(',') : undefined;
      }
      if (this.fieldConfig.IsStateShow) {
        this.model.StateCodes = !isNullOrUndefined(this.model.StateCodes) ? this.model.StateCodes.toString().split(',') : ["20"];
      }
      if (this.fieldConfig.IsProjectShow) {
        this.model.ProjectCode = !isNullOrUndefined(this.model.ProjectCode) ? this.model.ProjectCode.toString().split(',') : undefined;
      }
    } else {
      if (this.isMultipleRights == false) {
        if (this.fieldConfig.IsAdminDepartmentShow) {
          this.model.AdminDepartment = !isNullOrUndefined(this.model.AdminDepartment) ? String(this.model.AdminDepartment) : undefined;
        }
        if (this.fieldConfig.IsDepartmentShow) {
          this.model.DepartmentCode = !isNullOrUndefined(this.model.DepartmentCode) ? String(this.model.DepartmentCode) : undefined;
        }
        if (this.fieldConfig.IsDistrictShow) {
          this.model.DistrictCode = !isNullOrUndefined(this.model.DistrictCode) ? String(this.model.DistrictCode) : undefined;
        }
        if (this.fieldConfig.IsOfficeShow) {
          this.model.OfficeCode = !isNullOrUndefined(this.model.OfficeCode) ? String(this.model.OfficeCode) : undefined;
        }
        if (this.fieldConfig.IsStateShow) {
          this.model.StateCodes = !isNullOrUndefined(this.model.StateCodes) ? String(this.model.StateCodes) : "20";
        }
        if (this.fieldConfig.IsProjectShow) {
          this.model.ProjectCode = !isNullOrUndefined(this.model.ProjectCode) ? String(this.model.ProjectCode) : undefined;
        }
      } else {
        if (this.fieldConfig.IsAdminDepartmentShow) {
          this.model.AdminDepartment = !isNullOrUndefined(this.model.AdminDepartment) ? this.model.AdminDepartment.toString().split(',') : undefined;
        }
        if (this.fieldConfig.IsDepartmentShow) {
          this.model.DepartmentCode = !isNullOrUndefined(this.model.DepartmentCode) ? this.model.DepartmentCode.toString().split(',') : undefined;
        }
        if (this.fieldConfig.IsDistrictShow) {
          this.model.DistrictCode = !isNullOrUndefined(this.model.DistrictCode) ? this.model.DistrictCode.toString().split(',') : undefined;
        }
        if (this.fieldConfig.IsOfficeShow) {
          this.model.OfficeCode = !isNullOrUndefined(this.model.OfficeCode) ? this.model.OfficeCode.toString().split(',') : undefined;
        }
        if (this.fieldConfig.IsStateShow) {
          this.model.StateCodes = !isNullOrUndefined(this.model.StateCodes) ? this.model.StateCodes.toString().split(',') : ["20"];
        }
        if (this.fieldConfig.IsProjectShow) {
          this.model.ProjectCode = !isNullOrUndefined(this.model.ProjectCode) ? this.model.ProjectCode.toString().split(',') : undefined;
        }
      }
    }

  }

  RebindField(isRecont = false) {
    this.fieldConfig.IsAdminDepartmentShow = true;
    this.fieldConfig.IsDepartmentShow = true;
    this.fieldConfig.IsStateShow = true;
    this.fieldConfig.IsDistrictShow = true;
    this.fieldConfig.IsOfficeShow = true;
    this.fieldConfig.IsProjectShow = true;
    this.fieldConfig.IsDesignationShow = true;

    const departmentControl = this.UserForm.get("Department");
    const districtControl = this.UserForm.get("District");
    const adminDepartmentControl = this.UserForm.get("AdminDepartment");
    const officeControl = this.UserForm.get("Office");
    const projectControl = this.UserForm.get("ProjectCodes");
    const designationControl = this.UserForm.get("Designation");
    const StateCodesControl = this.UserForm.get("StateCodes");

    projectControl.setValidators([Validators.required]);
    officeControl.setValidators([Validators.required]);
    adminDepartmentControl.setValidators([Validators.required]);
    departmentControl.setValidators([Validators.required]);
    districtControl.setValidators([Validators.required]);
    designationControl.setValidators([Validators.required]);
    StateCodesControl.setValidators([Validators.required]);


    let UserType = !isNullOrUndefined(this.loginData.BaseUserType) && this.loginData.BaseUserType != "" ? this.loginData.BaseUserType : this.model.UserType;

    if (UserType == UserTypeEnum.ADLC) {

      switch (this.model.UserTypeCategory) {
        case this.userTypeEnum.HOD:
          this.fieldConfig.IsProjectShow = false;
          projectControl.setValidators(null);

          this.fieldConfig.IsOfficeShow = false;
          officeControl.setValidators(null);

          this.fieldConfig.IsDistrictShow = false;
          districtControl.setValidators(null);

          this.fieldConfig.IsStateShow = false;
          StateCodesControl.setValidators(null);


          break;
        case this.userTypeEnum.DLO:
          this.fieldConfig.IsProjectShow = false;
          projectControl.setValidators(null);

          this.fieldConfig.IsOfficeShow = false;
          officeControl.setValidators(null);

          this.fieldConfig.IsAdminDepartmentShow = false;
          adminDepartmentControl.setValidators(null);

          this.fieldConfig.IsDepartmentShow = false;
          departmentControl.setValidators(null);

          break;
        default:
          this.fieldConfig.IsProjectShow = false;
          projectControl.setValidators(null);

          this.fieldConfig.IsOfficeShow = false;
          officeControl.setValidators(null);

          this.fieldConfig.IsAdminDepartmentShow = false;
          adminDepartmentControl.setValidators(null);

          this.fieldConfig.IsDepartmentShow = false;
          departmentControl.setValidators(null);

          this.fieldConfig.IsDistrictShow = false;
          districtControl.setValidators(null);

          this.fieldConfig.IsStateShow = false;
          StateCodesControl.setValidators(null);
          break;
      }

      this.isMultipleRights = true;
    }
    else if (UserType == UserTypeEnum.ADM || UserType == UserTypeEnum.SADM) {
      this.fieldConfig.IsProjectShow = false;
      projectControl.setValidators(null);

      this.fieldConfig.IsOfficeShow = false;
      officeControl.setValidators(null);

      this.fieldConfig.IsAdminDepartmentShow = false;
      adminDepartmentControl.setValidators(null);

      this.fieldConfig.IsDepartmentShow = false;
      departmentControl.setValidators(null);

      this.fieldConfig.IsDistrictShow = false;
      districtControl.setValidators(null);

      this.fieldConfig.IsStateShow = false;
      StateCodesControl.setValidators(null);


      this.isMultipleRights = false;

    }
    else if (UserType == UserTypeEnum.SADM) {
      this.fieldConfig.IsProjectShow = false;
      projectControl.setValidators(null);

      this.fieldConfig.IsOfficeShow = false;
      officeControl.setValidators(null);

      this.fieldConfig.IsAdminDepartmentShow = false;
      adminDepartmentControl.setValidators(null);

      this.fieldConfig.IsDepartmentShow = false;
      departmentControl.setValidators(null);

      this.fieldConfig.IsDistrictShow = false;
      districtControl.setValidators(null);

      this.fieldConfig.IsStateShow = false;
      StateCodesControl.setValidators(null);


      this.isMultipleRights = false;

    }
    else if (UserType == UserTypeEnum.DREC) {


      this.fieldConfig.IsProjectShow = false;
      projectControl.setValidators(null);


      this.isMultipleRights = false;
    }
    else if (UserType == UserTypeEnum.DRECADLC) {

      this.fieldConfig.IsProjectShow = false;
      projectControl.setValidators(null);



      this.isMultipleRights = false;

    }
    else if (UserType == UserTypeEnum.HOD) {

      this.fieldConfig.IsProjectShow = false;
      projectControl.setValidators(null);

      this.fieldConfig.IsOfficeShow = false;
      officeControl.setValidators(null);

      this.fieldConfig.IsDistrictShow = false;
      districtControl.setValidators(null);

      this.fieldConfig.IsStateShow = false;
      StateCodesControl.setValidators(null);


      this.isMultipleRights = true;


    }
    else if (UserType == UserTypeEnum.VNDR) {
      this.fieldConfig.IsProjectShow = false;
      projectControl.setValidators(null);

      this.fieldConfig.IsOfficeShow = false;
      officeControl.setValidators(null);

      this.fieldConfig.IsAdminDepartmentShow = false;
      adminDepartmentControl.setValidators(null);

      this.fieldConfig.IsDepartmentShow = false;
      departmentControl.setValidators(null);

      this.fieldConfig.IsDistrictShow = false;
      districtControl.setValidators(null);

      this.fieldConfig.IsDesignationShow = false;
      designationControl.setValidators(null);

      this.fieldConfig.IsStateShow = false;
      StateCodesControl.setValidators(null);

      this.isMultipleRights = false;

    }
    else if (UserType == UserTypeEnum.VNDRUSER) {
      this.fieldConfig.IsProjectShow = false;
      projectControl.setValidators(null);

      this.fieldConfig.IsOfficeShow = false;
      officeControl.setValidators(null);

      this.fieldConfig.IsAdminDepartmentShow = false;
      adminDepartmentControl.setValidators(null);

      this.fieldConfig.IsDepartmentShow = false;
      departmentControl.setValidators(null);

      this.fieldConfig.IsDistrictShow = false;
      districtControl.setValidators(null);

      this.fieldConfig.IsDesignationShow = false;
      designationControl.setValidators(null);

      this.fieldConfig.IsStateShow = false;
      StateCodesControl.setValidators(null);
    }
    else if (UserType == UserTypeEnum.HOO) {
      this.fieldConfig.IsProjectShow = false;
      projectControl.setValidators(null);

      this.fieldConfig.IsStateShow = false;
      StateCodesControl.setValidators(null);

      this.getDistrictByState(); ////for bind default district

      this.isMultipleRights = true;
    }
    else if (UserType == UserTypeEnum.HOOADLC) {
      this.fieldConfig.IsProjectShow = false;
      projectControl.setValidators(null);

      this.fieldConfig.IsStateShow = false;
      StateCodesControl.setValidators(null);
      this.getDistrictByState(); ////for bind default district

      this.isMultipleRights = true;


    }
    else if (UserType == UserTypeEnum.DLO) {
      this.fieldConfig.IsProjectShow = false;
      projectControl.setValidators(null);

      this.fieldConfig.IsStateShow = false;
      StateCodesControl.setValidators(null);
      this.getDistrictByState(); ////for bind default district

      this.isMultipleRights = true;
    }
    else if (UserType == UserTypeEnum.DOS) {
      this.fieldConfig.IsProjectShow = false;
      projectControl.setValidators(null);

      this.isMultipleRights = false;
    }
    else if (UserType == UserTypeEnum.DOSADLC) {

      this.fieldConfig.IsProjectShow = false;
      projectControl.setValidators(null);


      this.isMultipleRights = false;

    }
    else if (UserType == UserTypeEnum.PROJ) {
      this.fieldConfig.IsOfficeShow = false;
      officeControl.setValidators(null);

      this.fieldConfig.IsDistrictShow = false;
      districtControl.setValidators(null);

      this.fieldConfig.IsStateShow = false;
      StateCodesControl.setValidators(null);
      this.getDistrictByState(); ////for bind default district

      this.isMultipleRights = true;
    }

    adminDepartmentControl.updateValueAndValidity();
    departmentControl.updateValueAndValidity();
    districtControl.updateValueAndValidity();
    officeControl.updateValueAndValidity();
    projectControl.updateValueAndValidity();
    designationControl.updateValueAndValidity();
    StateCodesControl.updateValueAndValidity();

    if (isRecont == false) {
      this.fieldControlChange();
    }
    else { this.isMultipleRights = this.isMultipleRights; }
  }

  selectADMAll() {
    setTimeout(() => {
      if (this.selectedDptAll < 0) {
        this.model.AdminDepartment = this.ddlList.ddlAdminDepartment.map(function (a) {
          return String(a.Value);
        });
        this.selectedDptAll = 1;
      } else {
        this.selectedDptAll = -1;
        this.model.AdminDepartment = [];
      }
    }, 100);
  }

  getFilterdDDL(event, key, filterFrom) {
    const item = new FilterDDlModel();
    item.FilterFor = key;
    item.Value = event != undefined ? (event.multiple != undefined && event.multiple == true ? JSON.stringify(event.value) : isNullOrUndefined(event.value) || event.value == "" ? "[" + event + "]" : "[" + event.value + "]") : null;
    item.FilterFrom = filterFrom;
    this.filterDDlModel = [];
    this.filterDDlModel.push(item);
    if (item.Value == "") {
      item.Value = "[" + event + "]";
    }
    this._commonService.GetFilterdDDL(this.filterDDlModel, this.loginData.BaseUserTypeCode, 0).subscribe((data) => {
      if (data.IsSuccess) {
        if (key == "ddlDepartment") {
          this.ddlDepartment = data.Data.ddlDepartment;
        }
      }
    }, (error) => {
      this._alertService.error(error.message);
    });
  }

  getOfficeList() {
    let deptCode = isNullOrUndefined(this.model.DepartmentCode) ? "0" : this.model.DepartmentCode.toString();
    let districtCode = isNullOrUndefined(this.model.DistrictCode) ? "0" : this.model.DistrictCode.toString();
    this._userService.GetUserOfficeList(deptCode, districtCode, 0).subscribe(
      (data) => {
        if (data.IsSuccess) {
          this.ddlList.ddlOffice = <DdlItemModel[]>data.Data;
        }
      }, (error) => {
        this._alertService.error(error.message);
      });
  }

  getDistrictByState() {
    let departmentCode = (this.model && this.model.DepartmentCode && this.model.DepartmentCode.length > 0) ? this.model.DepartmentCode.toString() : "0"
    let StateCodes = this.model.StateCodes ? this.model.StateCodes.toString() : StateEnum.Rajasthan.toString();

    this._userService.GetUserDistrictList(StateCodes, false, departmentCode, 0).subscribe((data) => {
      if (data.IsSuccess) {
        this.ddlDistrictByOffice = <DdlItemModel[]>data.Data;
      }
    }, (error) => {
      this._alertService.error(error.message);
    });
  }

  selectAll() {
    if (this.selectedAll < 0) {
      this.model.DepartmentCode = this.ddlDepartment.map(function (a) {
        return a.Value as string;
      });
      this.selectedAll = 1;
    } else {
      this.selectedAll = -1;
      this.model.DepartmentCode = [];
    }
  }

  selectAllProject() {
    if (this.selectedAll < 0) {
      this.model.ProjectCode = this.ddlProject.map(function (a) {
        return a.Value as string;
      });
      this.selectedAll = 1;
    } else {
      this.selectedAll = -1;
      this.model.ProjectCode = [];
    }
  }

  selectAllState() {
    if (this.model.StateCodes.length != this.ddlList.ddlState.length) {
      this.model.StateCodes = this.ddlList.ddlState.map(function (a) {
        return a.Value as string;
      });
    } else {
      this.model.StateCodes = [];
    }
  }

  selectAllDistrict() {
    if (this.model.DistrictCode.length != this.ddlDistrictByOffice.length) {
      this.model.DistrictCode = this.ddlDistrictByOffice.map(function (a) {
        return a.Value as string;
      });
    } else {
      this.model.DistrictCode = [];
    }
  }

  selectAllOffice() {
    if (this.model.OfficeCode.length != this.ddlList.ddlOffice.length) {
      this.model.OfficeCode = this.ddlList.ddlOffice.map(function (a) {
        return a.Value as string;
      });
    } else {
      this.model.OfficeCode = [];
    }
  }

  GetProjectDDLList() {
    var deptCodes = this.loginData.DepartmentCodes;
    if (this.loginData.BaseUserType == UserTypeEnum.ADM || this.loginData.BaseUserType == UserTypeEnum.SADM) {
      deptCodes = "0";
    }
    this._commonService.GetDDL(AppSetting.ddlProject, deptCodes).subscribe((data) => {
      if (data.IsSuccess) {
        var ddl = <DDLModel>data.Data;
        this.ddlProject = ddl.ddlProject;
        setTimeout(() => {
          this.ddlList.ddlProject = ddl.ddlProject;
        }, 200);
      }
    }, (error) => {
      this._alertService.error(error.message);
    });
  }

  setUserTypeValidators() {
    const departmentControl = this.UserForm.get("Department");
    const districtControl = this.UserForm.get("District");
    const adminDepartmentControl = this.UserForm.get("AdminDepartment");
    const officeControl = this.UserForm.get("Office");
    const projectControl = this.UserForm.get("ProjectCodes");
    const designationControl = this.UserForm.get("Designation");


    projectControl.setValidators(null);
    officeControl.setValidators(null);
    adminDepartmentControl.setValidators(null);
    departmentControl.setValidators(null);
    districtControl.setValidators(null);
    designationControl.setValidators(null);


    if (
      this.loginData.BaseUserType == UserTypeEnum.DREC ||
      this.loginData.BaseUserType == UserTypeEnum.HOO ||
      this.loginData.BaseUserType == UserTypeEnum.DOS ||
      this.loginData.BaseUserType == UserTypeEnum.DLO ||
      this.loginData.BaseUserType == this.userTypeEnum.HOOADLC
      || this.loginData.BaseUserType == this.userTypeEnum.DRECADLC
      || this.loginData.BaseUserType == this.userTypeEnum.DOSADLC
    ) {
      departmentControl.setValidators([Validators.required]);
      adminDepartmentControl.setValidators([Validators.required]);
      officeControl.setValidators([Validators.required]);
      districtControl.setValidators([Validators.required]);
    }

    if (this.loginData.BaseUserType == UserTypeEnum.HOD) {
      departmentControl.setValidators([Validators.required]);
      adminDepartmentControl.setValidators([Validators.required]);
    }

    if (this.loginData.BaseUserType == UserTypeEnum.PROJ) {
      departmentControl.setValidators([Validators.required]);
      adminDepartmentControl.setValidators([Validators.required]);
      officeControl.setValidators([Validators.required]);
      districtControl.setValidators([Validators.required]);
      projectControl.setValidators([Validators.required]);
    }

    if (this.loginData.BaseUserType == UserTypeEnum.VNDR) {
      districtControl.setValidators([Validators.required]);
    }

    if (this.loginData.BaseUserType == UserTypeEnum.ADM || this.loginData.BaseUserType == UserTypeEnum.SADM || this.loginData.BaseUserType == UserTypeEnum.ADLC) {
      departmentControl.setValidators(null);
      adminDepartmentControl.setValidators(null);
      officeControl.setValidators(null);
      districtControl.setValidators(null);
      projectControl.setValidators(null);
    }



    adminDepartmentControl.updateValueAndValidity();
    departmentControl.updateValueAndValidity();
    districtControl.updateValueAndValidity();
    officeControl.updateValueAndValidity();
    projectControl.updateValueAndValidity();
    designationControl.updateValueAndValidity();



  }

  isRequiredField(field: string) {

    const form_field = this.UserForm.get(field);
    if (!form_field.validator) {
      return false;
    }
    const validator = form_field.validator({} as AbstractControl);
    return (validator && validator.required);
  }

  closeDialog(val = false): void {
    this._dialogRef.close(val);
  }

  OpenSetPrimaryDetailPopup() {
    const _dialogRef = this._dialog.open(SetUserPrimaryDetailDialogComponent, {
      width: "500px",
      height: "320px",
      data: { model: this.model, fieldConfig: this.fieldConfig },
      disableClose: true
    });
    _dialogRef.afterClosed().subscribe((res: any) => {
      if (res) {
        this.model.PrimaryDepartmentCode = res.PrimaryDepartmentCode;
        this.model.PrimaryDistrictCode = res.PrimaryDistrictCode;
        this.model.PrimaryOfficeCode = res.PrimaryOfficeCode;
        this.saveUser();
      }
    });
  }

}
