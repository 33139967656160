import { Component, OnInit, Inject } from "@angular/core";
import { FormControl, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { ComplainEntryTypeMasterViewModel } from "src/app/shared/model/master/complain-entry-type-master.model";
import { UserViewModel } from "src/app/shared/model/user.model";
import { AlertService } from "src/app/shared/service/alert.service";
import { AuthenticationService } from "src/app/shared/service/authentication.service";
import { CommonService } from "src/app/shared/service/common.service";
import { ComplainEntryTypeMasterService } from "src/app/shared/service/complain-entry-type-master.service";
import { UserService } from "src/app/shared/service/user.service";

@Component({
  selector: 'app-add-update-entry-type',
  templateUrl: './add-update-entry-type.component.html',
  styleUrls: ['./add-update-entry-type.component.scss']
})
export class AddUpdateEntryTypeComponent implements OnInit {


  id: number;
  model: ComplainEntryTypeMasterViewModel;
  loginData: UserViewModel;
  name = new FormControl('', [Validators.required]);

  title: string = "Add";

  constructor(
    private readonly _userService: UserService,
    private readonly _alertService: AlertService,
    public readonly _dialogRef: MatDialogRef<AddUpdateEntryTypeComponent>,
    private readonly _complainEntryTypeMasterService: ComplainEntryTypeMasterService,
    private readonly _authService: AuthenticationService,
    public readonly _commonService: CommonService,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    if (data) {
      this.id = data;
      this.GetById();
      this.title = 'Edit';
    }
    this.model = new ComplainEntryTypeMasterViewModel();
  }

  ngOnInit() {
    this.loginData = this._authService.GetCurrentUserDetail().UserViewModel;
  }

  GetById() {
    this._complainEntryTypeMasterService.GetById(this.id).subscribe(
      data => {
        if (
          (data.IsSuccess)
        ) {
          this.model = <ComplainEntryTypeMasterViewModel>data.Data;
        }
      },
      error => {
        this.model = new ComplainEntryTypeMasterViewModel();
        this._alertService.error(error.message);
      }
    );
  }


  SaveClick() {
    this.name.markAsTouched();
    if (this.name.valid) {
      if (this.model.Id) {
        this.model.ModifiedBy = this.loginData.UserCode;
        this._complainEntryTypeMasterService.Edit(this.model).subscribe(data => {
          if (data.IsSuccess) {
            this._alertService.success(data.Message);
            this._dialogRef.close(true);
          }
          else {
            this._alertService.error(data.Message);

          }
        }, error => {
          this._alertService.error(error.message);
        });
      }
      else {
        this.model.CreatedBy = this.loginData.UserCode;
        this._complainEntryTypeMasterService.Add(this.model).subscribe(data => {
          if (data.IsSuccess) {
            this._alertService.success(data.Message);
            this._dialogRef.close(true);
          }
          else {
            this._alertService.error(data.Message);
          }
        }, error => {
          this._alertService.error(error.message);
        });
      }
    }

  }

  onNoClick(): void {
    this._dialogRef.close();
  }

}


