import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { AppComponent } from 'src/app/app.component';
import { AlertService } from 'src/app/shared/service/alert.service';
import { CommunicationAttachmentTypeModel } from 'src/app/shared/model/master/communication-attachment-type.model';
import { CommunicationAttachmentTypeService } from 'src/app/shared/service/master/communication-attachment-type.service';
import { UserTypeEnum } from 'src/app/shared/enum/fixed-values.enum';
import { AuthenticationService } from 'src/app/shared/service/authentication.service';

@Component({
  selector: 'app-communication-attachment-type-detail',
  templateUrl: './communication-attachment-type-detail.component.html',
  styleUrls: ['./communication-attachment-type-detail.component.scss']
})

export class CommunicationAttachmentTypeDetailComponent implements OnInit {
  model: CommunicationAttachmentTypeModel;
  RecordId: number;

  constructor(
    private readonly _appComponent: AppComponent,
    private readonly _router: Router,
    private _route: ActivatedRoute,
    private readonly _communicationAttachmentTypeService: CommunicationAttachmentTypeService,
    private readonly _alertService: AlertService
  ) {
    this.RecordId = this._route.snapshot.params.id;
    this.model = new CommunicationAttachmentTypeModel();
    this._appComponent.setPageLayout(
      "Communication Attachment Type Detail :",
      "keyboard_backspace",
      "Back To List",
      "master/communication-attachment-type"
    );
  }

  ngOnInit() {
    this.getDetail();
  }

  getDetail() {
    this._communicationAttachmentTypeService.Detail(this.RecordId).subscribe(
      data => {
        if (data.IsSuccess) {
          this.model = <CommunicationAttachmentTypeModel>data.Data;
        }
      },
      error => {
        this._alertService.error(error.message);
      }
    );
  }

 
}
