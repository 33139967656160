export class DistrictModel {
    DistrictId: number;
    DistrictCode: number;
    DistrictTitle: string;
    DistrictTitleHindi: string;
    DistrictShortTitle: string;
    DistrictShortTitleHindi: string;
    District_DivisionCode: number | string;
    District_StateCode: number | string;
    DistrictIsActive: boolean;
    DistrictIsDeleted: boolean | null;
    DISTRICT_ID_TEXT: string;
    DISTRICT_Jeelapramukh: string;
    DistrictImage: string;
    CreatedDate: Date | null;
    CreatedBy: number | null;
    ModifiedDate: Date | null;
    ModifiedBy: number | null;

    State: string;
    DivisionTitle: string;

    constructor() {
        this.DistrictId = 0;
        this.DistrictCode = 0;
        this.DistrictIsActive = true;
        this.DistrictIsDeleted = false;
    }
}
