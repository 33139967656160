import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { AppComponent } from 'src/app/app.component';
import { DDLModel } from 'src/app/shared/model/commonddl.model';
import { DepartmentContactDetailsViewModel } from 'src/app/shared/model/master/department-contact-details.model';
import { UserViewModel, UserDepartmentViewModel } from 'src/app/shared/model/user.model';
import { AlertService } from 'src/app/shared/service/alert.service';
import { DepartmentContactDetailsService } from 'src/app/shared/service/master/department-contact-details.service';

@Component({
  selector: 'app-detail-department-contact-details',
  templateUrl: './detail-department-contact-details.component.html',
  styleUrls: ['./detail-department-contact-details.component.scss']
})
export class DetailDepartmentContactDetailsComponent implements OnInit {

  formGroup: FormGroup;
  model: DepartmentContactDetailsViewModel;
  title: string;
  loginData: UserViewModel;
  ddlDepartment: UserDepartmentViewModel[];
  dDLList: DDLModel;
  constructor(private _parentApi: AppComponent,
    private readonly _departmentContactService: DepartmentContactDetailsService,
    private readonly _alertService: AlertService,
    private _route: ActivatedRoute) {
    this.model = new DepartmentContactDetailsViewModel();

    if (this._route.snapshot.params.id) {
      this.model.Id = this._route.snapshot.params.id;
      this.GetById();
      this._parentApi.setPageLayout("Department Contact Details :", "keyboard_backspace", "Back To List", "master/department-contact-details");
      this.title = "Detail";
    }
  }

  ngOnInit() {
  }

  GetById() {

    this._departmentContactService.GetById(this.model.Id)
      .subscribe(
        data => {
          if (data.IsSuccess) {

            this.model = <DepartmentContactDetailsViewModel>data.Data;
          } else {
            this._alertService.error(data.Message);
          }
        },
        error => {
          this._alertService.error(error.message);
        }
      );
  }

}
