import { UserViewModel } from 'src/app/shared/model/user.model';
import { Injectable } from "@angular/core";
import { LoggedInUserDetailViewModel } from "../model/user.model";
import { BaseService } from "./base.service";
import { Router } from "@angular/router";
import { isNullOrUndefined } from "util";
import { AppSetting } from "../model/appsetting.model";
import { SessionTimeOut } from "../enum/fixed-values.enum";
import { environment } from "src/environments/environment";
import { UserPermissionService } from "./user-permission.service";
import { AssignedUserPermissionViewModel } from "../model/user-permission.model";
import { NavItem } from "../model/nav-item.model";
import { MenuMasterService } from "./master/menu-master.service";
import { CommonService } from './common.service';
import { PermissionModel } from '../model/common.model';

@Injectable({
  providedIn: "root",
})

export class AuthenticationService {
  LoggedInUserDetail: LoggedInUserDetailViewModel;
  public IsAuthentication: boolean = false;
  public UserCode: number = null;
  public UserType: string = null;
  public BaseUserType: string = null;

  //public SSOID: string = "cmisnewtest1"; // Admin
  public SSOID: string;// = "chetan.maini1"; // Vendor
  //public SSOID: string = "";      // Head Of Department
  //public SSOID: string = "rluhadia.doit"; // Department Reception

  constructor(
    private readonly _baseService: BaseService,
    private readonly _router: Router,
    private readonly _userPermissionService: UserPermissionService,
    private readonly _menuService: MenuMasterService,
    private readonly _commonService: CommonService) {
    //  
    this.IsAuthentication = this._commonService.GetLocalStorage("IsAuthentication") != undefined && this._commonService.GetLocalStorage("IsAuthentication") != "" ? JSON.parse(this._commonService.GetLocalStorage("IsAuthentication")) : false;
    if (!this.IsAuthentication) {
      if (this.IsSessionOut()) {
        if (environment.SSOPortalLogin) {
          this.Login();
        } else {
          //this._router.navigate(["login"]); //todo back to sso
        }
        //   this.Login();
      }
      else {
        this.IsAuthentication = true;
        this.LoggedInUserDetail = this.GetCurrentUserDetail();
        this.UserCode = this.LoggedInUserDetail.UserViewModel.UserCode;
        this.UserType = this.LoggedInUserDetail.UserViewModel.UserType;
        this.BaseUserType = this.LoggedInUserDetail.UserViewModel.BaseUserType;
      }
    }
    else {
      this.IsAuthentication = true;
      this.LoggedInUserDetail = this.GetCurrentUserDetail();
      this.UserCode = this.LoggedInUserDetail.UserViewModel.UserCode;
      this.UserType = this.LoggedInUserDetail.UserViewModel.UserType;
      this.BaseUserType = this.LoggedInUserDetail.UserViewModel.BaseUserType;
    }
  }

  Login(): any {
    this.removeLocalStorage();
    this.AuthenticateUser();
  }

  AuthenticateUser() {
    //this.SSOID = environment.production ? "cmisnewtest1" : this._commonService.GetLocalStorage("SSOID");
    this.SSOID = this._commonService.GetLocalStorage("SSOID");

    if (!isNullOrUndefined(this.SSOID)) {
      let serv = this._baseService.get(AppSetting.LogInUrl + this.SSOID).subscribe(
        (data) => {
          serv.unsubscribe();
          if (data.IsSuccess) {
            this.IsAuthentication = true;
            this.LoggedInUserDetail = <LoggedInUserDetailViewModel>data.Data;
            this.SSOID = this.LoggedInUserDetail.UserViewModel.SSOID;
            this.UserCode = this.LoggedInUserDetail.UserViewModel.UserCode;
            this.UserType = this.LoggedInUserDetail.UserViewModel.UserType;
            this.BaseUserType = this.LoggedInUserDetail.UserViewModel.BaseUserType;

            this._commonService.SetLocalStorage("SessionTime", new Date().toString());

            // if (this.IsSessionOut()) {
            //   this.BackToSSO();
            // }

            this._commonService.SetLocalStorage(
              "UserDetails",
              JSON.stringify(this.LoggedInUserDetail)
            );
            this._commonService.SetLocalStorage(
              "IsAuthentication",
              String(this.IsAuthentication)
            );
            this._commonService.SetLocalStorage(
              "Token",
              "AuthCMO " + this.LoggedInUserDetail.Token
            );
            this._commonService.SetLocalStorage(
              "UserCode",
              this.LoggedInUserDetail.UserViewModel.UserCode.toString()
            );
            this._commonService.SetLocalStorage(
              "UserTypeCode",
              this.LoggedInUserDetail.UserViewModel.UserTypeCode.toString()
            );
            this._commonService.SetLocalStorage(
              "UserType",
              this.LoggedInUserDetail.UserViewModel.UserType
            );
            this._commonService.SetLocalStorage(
              "BaseUserType",
              this.LoggedInUserDetail.UserViewModel.BaseUserType
            );
            this._commonService.SetLocalStorage(
              "SSOID",
              this.LoggedInUserDetail.UserViewModel.SSOID
            );
            this._commonService.SetLocalStorage(
              "Name",
              this.LoggedInUserDetail.UserViewModel.Name
            );
            if (isNullOrUndefined(this.LoggedInUserDetail.UserViewModel.ProfilePic)) {
              this.LoggedInUserDetail.UserViewModel.ProfilePic =
                AppSetting.DefaultProfilePic;
            }

            this._commonService.RemoveLocalStorage("IsGlobalSearch");
            this._commonService.RemoveLocalStorage("GlobalCommunicationFilterSearch");

            // this._router.navigate(['dashboard']);
            this._router.navigate(['dashboard']);
            setTimeout(() => {
              //    window.location.href = environment.ApplicationBaseUrl;
            }, 0);
            //return true;
          }
          else if (data.StatusCode === 401) {
            this.BackToSSO();
            return false;
          } else {
            this.IsAuthentication = false;
            this._router.navigate(['login']);
            return false;
          }
        },
        (error) => {
          this.IsAuthentication = false;
          this.BackToSSO();
        }
      );
    } else {
      this.LogOut();
    }
  }

  LogOut() {
    let SSO = this._commonService.GetLocalStorage("SSOID");

    this.removeLocalStorage();
    this._commonService.RemoveLocalStorage("IsTempLogin");
    this._commonService.RemoveLocalStorage("SessionTime");
    let URL = `${AppSetting.LogOutUrl}&ssoid=${SSO}`;
    this._commonService.RemoveLocalStorage("SSOID");
    //Demo Build
    if (environment.SSOPortalLogin) {
      window.location.href = URL;
    } else {
      this._router.navigate(["login"]);
    }
  }

  BackToSSO() {
    // let SSO = this._commonService.GetLocalStorage("SSOID");
    // this.removeLocalStorage();
    // this._commonService.RemoveLocalStorage("IsTempLogin");
    // this._commonService.RemoveLocalStorage("SessionTime");
    // this._commonService.RemoveLocalStorage("SSOID");
    if (environment.isLiveServer) {
      window.location.href = `https://sso.rajasthan.gov.in/sso`;
    }

    //window.location.href = `${AppSetting.BackToSSOUrl}&ssoid=${SSO}`;
  }

  removeLocalStorage() {
    this.LoggedInUserDetail = null;
    this.IsAuthentication = false;
    this._commonService.RemoveLocalStorage("Token");
    this._commonService.RemoveLocalStorage("UserCode");
    this._commonService.RemoveLocalStorage("UserTypeCode");
    this._commonService.RemoveLocalStorage("UserType");
    this._commonService.RemoveLocalStorage("BaseUserType");

    this._commonService.RemoveLocalStorage("Name");
    this._commonService.RemoveLocalStorage("UserDetails");
    this._commonService.RemoveLocalStorage("IsAuthentication");

    this._commonService.RemoveLocalStorage("originalUserCode");
    this._commonService.RemoveLocalStorage("originalUserName");
  }

  GetCurrentUserDetail(): LoggedInUserDetailViewModel {
    if (this._commonService.GetLocalStorage("UserDetails")) {
      const userDetails = <LoggedInUserDetailViewModel>(
        JSON.parse(this._commonService.GetLocalStorage("UserDetails"))
      );
      return userDetails;
    } else {
      this.Login();
    }
    return null;
  }

  GetUserIsAuthenticated(): boolean {
    if (this._commonService.GetLocalStorage("IsAuthentication")) {
      if (this._commonService.GetLocalStorage("IsAuthentication").toLowerCase() == "true") {
        return true;
      }
    }
    return false;
  }

  IsSessionOut() {
    if (!this._commonService.GetLocalStorage("SessionTime")) {
      //this._commonService.SetLocalStorage("SessionTime", new Date().toString());
      return true;
    } else {
      const timeDiff = Math.round(
        (new Date().getTime() -
          new Date(this._commonService.GetLocalStorage("SessionTime")).getTime()) /
        60000
      ); //in minute
      if (!(timeDiff < SessionTimeOut.TimeInMinute)) {
        return true;
      } else {
        return false;
      }
    }
  }

  UpdateLoginUserPermission(UserCode = this.GetCurrentUserDetail().UserViewModel.UserCode) {
    let serv = this._userPermissionService.GetPermissionByUserCode(UserCode)
      .subscribe((response) => {
        serv.unsubscribe();
        if (response.IsSuccess) {

          this.LoggedInUserDetail.AssignedUserPermissionViewModelList = <AssignedUserPermissionViewModel[]>response.Data;
          this._commonService.RemoveLocalStorage("UserDetails");
          this._commonService.SetLocalStorage("UserDetails", JSON.stringify(this.LoggedInUserDetail)
          );
        }
      });
  }

  UpdateLoginUserMenu(UserCode = this.GetCurrentUserDetail().UserViewModel.UserCode) {
    let serv = this._menuService
      .GetAllByUserId(UserCode).subscribe((response) => {
        serv.unsubscribe();
        if (response.IsSuccess) {

          this.LoggedInUserDetail.UserMenuViewModelList = <NavItem[]>(response.Data);
          this._commonService.RemoveLocalStorage("UserDetails");
          this._commonService.SetLocalStorage("UserDetails", JSON.stringify(this.LoggedInUserDetail)
          );
        }
      });
  }

  UpdateLoginUserDetail() {
    let UserCode = this.GetCurrentUserDetail().UserViewModel.UserCode;
    let serv = this._baseService.get(AppSetting.UserDetailUrl + UserCode).subscribe((response) => {

      serv.unsubscribe();
      if (response.IsSuccess) {

        this.LoggedInUserDetail.UserViewModel = <UserViewModel>response.Data;
        this._commonService.RemoveLocalStorage("UserDetails");
        console.log(this.LoggedInUserDetail);

        this._commonService.SetLocalStorage("UserDetails", JSON.stringify(this.LoggedInUserDetail)
        );
      }
    });
  }

  RefreshLoginUserDetail() {
    this.UpdateLoginUserDetail();
    this.UpdateLoginUserPermission();
    this.UpdateLoginUserMenu();


  }


  Detail(id: number) {
    return this._baseService.get(AppSetting.UserDetailUrl + id);
  }

  IsAccessibleUrl(requestedUrl: string): boolean {
    const currentUserDetail = this.GetCurrentUserDetail();
    if (currentUserDetail && currentUserDetail.AssignedUserPermissionViewModelList) {
      const permissionData = <AssignedUserPermissionViewModel>(
        currentUserDetail.AssignedUserPermissionViewModelList.find((x) =>
          x.PermissionUrl.includes(requestedUrl)
        )
      );
      if (permissionData && permissionData.IsLoadPermission) {
        if (!permissionData.IsLoadPermission) {
          const secondPermissionData = <AssignedUserPermissionViewModel>(
            currentUserDetail.AssignedUserPermissionViewModelList.find((x) =>
              x.PermissionUrl.includes(requestedUrl) && x.UserPermissionId !== permissionData.UserPermissionId
            )
          );
          if (secondPermissionData) {
            return secondPermissionData.IsLoadPermission;
          }
          else {
            return false;
          }
        }
        else {
          return permissionData.IsLoadPermission;
        }
      }
      return false;
    }
    else {
      return false;
    }
  }

  SwitchUser(SSOID: string) {
    this._commonService.SetLocalStorage("SSOID", SSOID);
    this.AuthenticateUser();
  }

  GetPagePermission(
    ListUrl: string = "",
    AddUrl: string = "",
    DetailUrl: string = "",
    UpdateUrl: string = "",
    DeleteUrl: string = "",
    Custom1Url: string = "",
    Custom2Url: string = "",
    Custom3Url: string = ""
  ): PermissionModel {
    let model = new PermissionModel();
    model.ListPageAccess = ListUrl != "" ? this.IsAccessibleUrl(ListUrl) : false;
    model.AddPageAccess = AddUrl != "" ? this.IsAccessibleUrl(AddUrl) : false;
    model.DetailPageAccess = DetailUrl != "" ? this.IsAccessibleUrl(DetailUrl) : false;
    model.UpdatePageAccess = UpdateUrl != "" ? this.IsAccessibleUrl(UpdateUrl) : false;
    model.DeletePageAccess = DeleteUrl != "" ? this.IsAccessibleUrl(DeleteUrl) : false;
    model.Custom1PageAccess = Custom1Url != "" ? this.IsAccessibleUrl(Custom1Url) : false;
    model.Custom2PageAccess = Custom2Url != "" ? this.IsAccessibleUrl(Custom2Url) : false;
    model.Custom3PageAccess = Custom3Url != "" ? this.IsAccessibleUrl(Custom3Url) : false;
    return model;
  }

}
