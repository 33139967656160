import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { AppComponent } from "src/app/app.component"
import { ProjectReportModel } from 'src/app/shared/model/master/project-report.model';
import { ProjectService } from 'src/app/shared/service/project.service';
import { GlobalFilterModel, IndexModel } from 'src/app/shared/model/common.model';
import { GlobalMessagesModel } from 'src/app/shared/model/common-messages.model';
import { AppSetting } from 'src/app/shared/model/appsetting.model';
import { MatTableDataSource, MatDialog, MatSort, MatPaginator } from "@angular/material";
import { ConfirmationDialogComponent } from "src/app/confirmation-dialog/confirmation-dialog.component";
import { AlertService } from 'src/app/shared/service/alert.service';
import { CommonService } from 'src/app/shared/service/common.service';
import { ColumnHeaderModel } from 'src/app/shared/model/commonddl.model';
import { UserTypeEnum } from 'src/app/shared/enum/fixed-values.enum';
import { AuthenticationService } from 'src/app/shared/service/authentication.service';
import { Router } from '@angular/router';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { ProjectReportRequestModel} from 'src/app/shared/model/project-report/project-report-request.model';
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

declare const require: any;
const jsPDF = require('jspdf');
require('jspdf-autotable');
@Component({
  selector: 'app-project-report',
  templateUrl: './project-report.component.html',
  styleUrls: ['./project-report.component.scss']
})
export class ProjectReportComponent implements OnInit {

  model: ProjectReportModel[];
  indexModel: IndexModel;
  dataSource: any;
  totalRecords: number;

  loginUserCode: number;
  loginBaseUserType: string; 

  userTypeEnum = UserTypeEnum;
  displayedColumns: string[] = [];
  columnsToDisplay: string[] = [];

  ViewdisplayedColumns: ColumnHeaderModel[] = [
    { Value: "ProjectName", Text: "Project Name" },
    { Value: "DepartmentName", Text: "Department Name" },
    { Value: "VendorCompany", Text: "Vendor Company" },
    { Value: "AssignedProjectOfficerName", Text: "OIC Assign" },
    { Value: "ModifiedOn", Text: "ModifiedOn" },
    { Value: "UserName", Text: "Modified By" }
  ];
  SearchColumns: ColumnHeaderModel[] = [
    { Value: "ProjectName", Text: "Project Name" },
    { Value: "DepartmentName", Text: "Department Name" },
    { Value: "VendorCompany", Text: "Vendor Company" },
    { Value: "AssignedProjectOfficerName", Text: "OIC Assign" },
  ];

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  constructor(private readonly appComponnet: AppComponent,
    private readonly _alertService: AlertService,
    private readonly _projectService: ProjectService,
    private readonly _router: Router,
    private readonly _dialog: MatDialog,
    public readonly _commonService: CommonService,
    private readonly _authService: AuthenticationService
  ) {
    this.loginUserCode = _authService.UserCode;
    this.loginBaseUserType = _authService.BaseUserType;

    this.displayedColumns = ["index", "ProjectName", "DepartmentName", "VendorCompany", "AssignedProjectOfficerName", "UserName", "ModifiedOn"];
    if (this.loginBaseUserType == this.userTypeEnum.ADM || this.loginBaseUserType == this.userTypeEnum.SADM) {
      this.displayedColumns.push("IsActive");
    }
    this.columnsToDisplay = this.displayedColumns.slice();
    this.appComponnet.setPageLayout("Project Report :", "", "", "");
    this.indexModel = this._commonService.modelSetGet(new ProjectReportRequestModel()) as ProjectReportRequestModel;
  }

  ngOnInit() {
    if (this.userTypeEnum.ADM != this.loginBaseUserType && this.userTypeEnum.SADM != this.loginBaseUserType && this.userTypeEnum.HOD != this.loginBaseUserType && this.userTypeEnum.DREC != this.loginBaseUserType && this.userTypeEnum.DRECADLC != this.loginBaseUserType) {
      this._router.navigate(["dashboard"]);
    }
    this.getList();
  }

  getList() {
    this._projectService.GetList(this.loginUserCode, this.indexModel).subscribe(
      data => {
        if (data.IsSuccess) {
          this.model = <ProjectReportModel[]>data.Data.Data;
          this.dataSource = new MatTableDataSource<ProjectReportModel>(this.model);
          this.totalRecords = data.Data.TotalRecords;
          if (!this.indexModel.IsPostBack) {
            this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.sort;
          }
        } else {
          this._alertService.error(data.Message);
        }
      },
      error => {
        this._alertService.error(error.message);
      }
    );
  }

  sortData(event) {
    this.indexModel.OrderBy = event.active;
    this.indexModel.OrderByAsc = event.direction == AppSetting.orderByDscAsc ? AppSetting.orderByAsc : AppSetting.orderByDsc;
    this.indexModel.IsPostBack = true;
    this.getList();
  }

  onPaginateChange(event) {
    this.indexModel.Page = event.pageIndex + 1;
    this.indexModel.PageSize = event.pageSize;
    this.indexModel.IsPostBack = true;
    this.getList();
  }

  SearchByKeyword(searchValue: GlobalFilterModel) {
    this.indexModel.Search = searchValue.search;
    this.indexModel.AdvanceSearchModel = { ActiveStatus: searchValue.ActiveStatus }

    this.getList();
  }

 
  public Reset() {
    this.indexModel = new ProjectReportRequestModel();
    this.getList();
  }

  @ViewChild('pdfTable', { static: false }) pdfTable: ElementRef;


  completeModel: ProjectReportModel[];
  completeIndexModel: IndexModel;
  getCompleteData() {
    this.completeIndexModel = new IndexModel();
    this.completeIndexModel.Page = 1;
    this.completeIndexModel.PageSize = this.totalRecords;
    this.completeIndexModel.IsPostBack = true;
    this.completeIndexModel.Search = this.indexModel.Search;
    this._projectService.GetList(this.loginUserCode, this.completeIndexModel).subscribe(
      (completeData: any) => {
        if (completeData.IsSuccess) {
          this.completeModel = <ProjectReportModel[]>completeData.Data.Data;
          this.totalRecords = completeData.Data.TotalRecords;
          this.DownloadPdf();
        } else {
          this._alertService.error(completeData.Message);
        }
      },
      error => {
        this._alertService.error(error.message);
      }
    );
  }
  rowsNew: any = [];
  // download() {
  //   //completeModel
  //   var i = 1;
  //   this.rowsNew = [];
  //   this.completeModel.forEach(element => {
  //     var temp = {
  //       index: i, ProjectName: element.ProjectName,
  //       DepartmentName: element.DepartmentName, VendorCompany: element.VendorCompany,
  //       IsActive: element.IsActive, UserName: element.UserName, ModifiedOn: element.ModifiedOn
  //     };
  //     i = i + 1;
  //     this.rowsNew.push(temp);
  //   });
  //   this.exportAsPdf();
  // }
  DownloadPdf() {
    this._projectService.GetProjectPDFDownload(this.loginUserCode, this.indexModel).subscribe(
      data => {
        if (data.IsSuccess) {
          // const linkSource = data.Data;
          // const downloadLink = document.createElement("a");
          // const fileName = 'Department Report';

          // downloadLink.href = linkSource;
          // downloadLink.download = fileName;
          // downloadLink.target = "blank";
          // downloadLink.click();
          var w = window.open("about:blank");

          setTimeout(function () {
            //FireFox seems to require a setTimeout for this to work.
            w.document.body.appendChild(w.document.createElement("iframe")).src = data.Data;
            w.document.getElementsByTagName("iframe")[0].style.width = "100%";
            w.document.getElementsByTagName("iframe")[0].style.height = "100%";
          }, 0);
        } else {
          this._alertService.error(data.Message);
        }
      },
      error => {
        this._alertService.error(error.message);
      }
    );
  }

  public exportAsPdf(): void {
    var doc = new jsPDF('p', 'pt');
    var header = function (data) {
      doc.setFontSize(10);
      doc.setTextColor(40);
      doc.setFontStyle('normal');
      doc.text("Project", data.settings.margin.left, 50);
      doc.text("Date Time : " + new Date().toLocaleDateString() + " " + new Date().toLocaleTimeString(), data.settings.margin.left, 800);
    };
    var headers = [{
      title: "#",
      dataKey: "index"
    }, {
      title: "Project name",
      dataKey: "ProjectName"
    },
    {
      title: "Department Name",
      dataKey: "DepartmentName"
    },
    {
      title: "Vendor Company",
      dataKey: "VendorCompany"
    },
    {
      title: "OIC Assign",
      dataKey: "AssignedProjectOfficerName"
    },
    {
      title: "Is Active",
      dataKey: "IsActive"
    },
    {
      title: "ModifiedBy",
      dataKey: "UserName"
    },
    {
      title: "Modified On",
      dataKey: "ModifiedOn"
    },
    ];
    doc.autoTable(headers, this.rowsNew, { margin: { top: 70 }, beforePageContent: header });
    doc.save("Project-Report" + new Date().toLocaleDateString() + "_" + new Date().toLocaleTimeString() + ".pdf");
  }

  //excellll
  public exportAsExcelFile(): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.rowsNew);
    const workbook: XLSX.WorkBook = { Sheets: { 'projectReport': worksheet }, SheetNames: ['projectReport'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFile(excelBuffer, 'Project');
  }

  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], { type: EXCEL_TYPE });
    FileSaver.saveAs(data, fileName + '_Report_' + new Date().toLocaleDateString() + "_" + new Date().toLocaleTimeString() + EXCEL_EXTENSION);
  }

  buttonClickExportAsPDF(): void {
    this.getCompleteData();
  }

  buttonClickExportAsXLSX(): void {
    this.getCompleteExcelData();
  }

  getCompleteExcelData() {
    this.completeIndexModel = new IndexModel();
    this.completeIndexModel.Page = 1;
    this.completeIndexModel.PageSize = this.totalRecords;
    this.completeIndexModel.IsPostBack = true;
    this.completeIndexModel.Search = this.indexModel.Search;
    this._projectService.GetList(this.loginUserCode, this.completeIndexModel).subscribe(
      (completeData: any) => {
        if (completeData.IsSuccess) {
          this.completeModel = <ProjectReportModel[]>completeData.Data.Data;
          this.totalRecords = completeData.Data.TotalRecords;
          this.downloadExcel();
        } else {
          this._alertService.error(completeData.Message);
        }
      },
      error => {
        this._alertService.error(error.message);
      }
    );
  }

  downloadExcel() {
    var i = 1;
    this.rowsNew = [];
    this.completeModel.forEach(element => {
      var temp = {
        SNo: i, ProjectName: element.ProjectName,
        DepartmentName: element.DepartmentName, VendorCompany: element.VendorCompany, OICAssign: element.AssignedProjectOfficerName,
        IsActive: element.IsActive, ModifiedBy: element.UserName, ModifiedOn: element.ModifiedOn
      };
      i = i + 1;
      this.rowsNew.push(temp);
    });
    this.exportAsExcelFile();
  }
}