import { Component, OnInit } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { DynamicFieldConfig } from "src/app/shared/model/dynamic-field-config.model";
@Component({
  selector: "app-checkbox",
  template: `
  <div  [ngClass]="[field.CSSClass?field.CSSClass:'col l4 xl4 m4 s12']" class="margin-top" [formGroup]="group" [(ngModel)]="field.value"  >
<mat-checkbox [formControlName]="field.name" matTooltip="{{field.ToolTip}}"  matTooltipPosition="above" >{{field.label}}</mat-checkbox>
<ng-container *ngFor="let validation of field.validations;let indx=index" ngProjectAs="mat-error">
<mat-error class="dynamic-feild-error" *ngIf="group.get(field.name).hasError(validation.name) && group.get(field.name).touched">{{validation.message}}</mat-error>

</ng-container>

</div>
`,
  styles: []
})
export class CheckboxComponent implements OnInit {
  field: DynamicFieldConfig;
  group: FormGroup;
  fields: DynamicFieldConfig[];

  constructor() {}
  ngOnInit() {}
}
