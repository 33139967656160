import { Component, OnInit } from '@angular/core';
import { DDLModel, DdlItemModel } from '../shared/model/commonddl.model';
import { Validators, FormControl } from '@angular/forms';
import { AuthenticationService } from '../shared/service/authentication.service';
import { AlertService } from '../shared/service/alert.service';
import { CommonService } from '../shared/service/common.service';
import { AppSetting } from '../shared/model/appsetting.model';
import { GlobalMessagesModel } from '../shared/model/common-messages.model';
import { AppComponent } from '../app.component';

@Component({
  selector: 'app-temporary-login',
  templateUrl: './temporary-login.component.html',
  styleUrls: ['./temporary-login.component.scss']
})

export class TemporaryLoginComponent implements OnInit {

  ddlList: DDLModel = new DDLModel();
  ddlSSOId: DdlItemModel[] = [];
  ddlDepartmentByLoginUserId: DdlItemModel[] = [];
  DepartmentCode: number | string;
  DistrictCode: number | string;
  OfficeCode: number | string;
  userType: number;
  ssoId: string;
  selectedSSOId: string;
  isSubmit = false;
  frm = new FormControl('', [Validators.required]);

  constructor(
    private readonly _authService: AuthenticationService,
    private readonly _alertService: AlertService,
    public readonly _commonService: CommonService,
    private readonly appComponnet: AppComponent,
  ) {
    this.appComponnet.setPageLayout('Temporary Login :', '', '', '');
    this.getDDLList();
  }

  ngOnInit() {

    
  }

  getDDLList() {
    this._commonService.GetAllDDL(AppSetting.DDLKeyForTempLogin).subscribe((data) => {
      if (data.IsSuccess) {
        let ddlData = <DDLModel>data.Data;
        this.ddlList.ddlDepartmentByLoginUserId = ddlData.ddlDepartmentByLoginUserId;
        this.ddlList.ddlRajDistrict = ddlData.ddlRajDistrict;
        this.ddlList.ddlOfficeByLoginUserId = ddlData.ddlOfficeByLoginUserId;
        this.ddlList.ddlUserTypetemplogin = ddlData.ddlUserTypetemplogin;
      }
    }, (error) => {
      this._alertService.error(GlobalMessagesModel.InternalError);
    });
  }

  GetOffice() {
    if (this.DepartmentCode) {
      this.getSSOIDListByDepartment(this.DepartmentCode);

      let distCode = this.DistrictCode ? this.DistrictCode : 0;
      this._commonService.GetDDL(AppSetting.ddlOfficeByLoginUserId, this.DepartmentCode, distCode).subscribe((data) => {
        if (data.IsSuccess) {
          var ddl = <DDLModel>data.Data;
          this.ddlList.ddlOfficeByLoginUserId = ddl.ddlOfficeByLoginUserId;
        }
      }, (error) => {
        this._alertService.error(error.message);
      });
    }
    else {
      this.ddlList.ddlDepartmentOffice = null;
    }
  }

  getSSOIDList() {
    let selUserType = this.userType ? this.userType : 0;
    let selOfficeCode = this.OfficeCode ? Number(this.OfficeCode) : 0;
    let selSSOId = this.ssoId ? this.ssoId : '';

    this._commonService.GetAllSSOIDByUserType(selUserType, selOfficeCode, selSSOId).subscribe((data) => {
      if (data.IsSuccess) {
        this.ddlSSOId = <DdlItemModel[]>data.Data;
        if (this.ddlSSOId && this.ddlSSOId.length > 0 && this.selectedSSOId) {
          let isExist = this.ddlSSOId.find(x => x.Value == this.selectedSSOId);
          if (!isExist) {
            this.selectedSSOId = undefined;
          }
        }
      }
    }, (error) => {
      this._alertService.error(error.message);
    });
  }

  getSSOIDListByDepartment(DepartmentCode) {
    if (!DepartmentCode) { DepartmentCode = 0; }
    this._commonService.GetAllSSOIDByDepartment_UserType(DepartmentCode).subscribe(
      (data) => {
        if (data.IsSuccess) {
          this.ddlSSOId = <DdlItemModel[]>data.Data;
        }
      }, (error) => {
        this._alertService.error(error.message);
      });
  }

  onSubmit() {
    this.frm.markAsTouched();
    if (this.frm.valid) {
      this._commonService.SetLocalStorage('SSOID', this.selectedSSOId);
      this._commonService.SetLocalStorage('IsTempLogin', 'true');
      this.isSubmit=true;
      this._authService.Login();
    }
  }

}
