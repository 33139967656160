import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AppComponent } from 'src/app/app.component';
import { WorkOrderCubCategoryModel } from 'src/app/shared/model/master/work-order-cub-category.model';
import { AlertService } from 'src/app/shared/service/alert.service';
import { WorkOrderSubCategoryService } from 'src/app/shared/service/master/work-order-sub-category.service';

@Component({
  selector: 'app-work-order-sub-category-detail',
  templateUrl: './work-order-sub-category-detail.component.html',
  styleUrls: ['./work-order-sub-category-detail.component.scss']
})
export class WorkOrderSubCategoryDetailComponent implements OnInit {

  model: WorkOrderCubCategoryModel;
  RecordId: number;
  IsStorage: boolean;

  constructor(private readonly _appComponent: AppComponent,
    private readonly _router: Router,
    private _route: ActivatedRoute,
    private readonly _workorderSubCategoryService: WorkOrderSubCategoryService,
    private readonly _alertService: AlertService
  ) {
    this.RecordId = this._route.snapshot.params.id;
    this.model = new WorkOrderCubCategoryModel();
    this._appComponent.setPageLayout(
      "WorkOrder Sub Category Detail :",
      "keyboard_backspace",
      "Back To List",
      "master/work-order-sub-category"
    );
  }

  ngOnInit() {
    this.getDetail();
  }

  getDetail() {
    this._workorderSubCategoryService.Detail(this.RecordId).subscribe(
      data => {
        if (data.IsSuccess) {
          this.model = <WorkOrderCubCategoryModel>data.Data;
        }
      },
      error => {
        this._alertService.error(error.message);
      }
    );
  }

  ngOnDestroy() {
    this.IsStorage = true;
    sessionStorage.setItem("IsWorkOrderSubCategoryStorage", JSON.stringify(this.IsStorage));
  }

}
