import { IndexModel } from "./common.model";

export class GeneralEntryModel {

  Id: number;
  GeneralEntry: string;
  GeneralEntryHindi: string;
  GeneralEntryCategoryCode: number | any;
  UserBy: number | any;
  DepartmentCode: number | any;
  DistrictCode: number | any;
  OfficeCode: number | any;
  Description: string;
  DescriptionHindi: string;
  Priority: number;
  PdfFIleName: string;
  Url: string;
  GeneralEntryDate: Date | string;
  CreatedBy: number;
  ModifiedBy: number;
  CreatedDate: Date | string;
  ModifiedDate: Date | string;
  IsActive: boolean;
  IsDeleted: boolean;
  IsVisible: boolean;
  GeneralEntrySubCategoryCode: number;
  ImagePath: string;
  GeneralEntryCategory: string;
  GeneralEntryCategoryHindi: string;
  GeneralEntryCategoryIsVisible: boolean;
  GeneralEntryCategoryImagePath: string;
  GeneralEntrySubCategory: string;
  GeneralEntrySubCategoryHindi: string;
  GeneralEntrySubCategoryImagePath: string;
  Department: string;
  DepartmentHindi: string;
  District: string;
  Office: string;
  ImageFiles: string[] = [];
  CMOComments: string;
  KeyWord: string;
  AutoKeyWord: string;
  TotalCount: number;
  GeneralEntryAttachments: string[] = [];
  TimeSlotCode: number | string;
  TimeSlot: string;
}
export class GeneralEntryPostModel {
  Id: number;
  GeneralEntry: string;
  GeneralEntryHindi: string;
  GeneralEntryCategoryCode: number | string;
  UserBy: number | any;
  DepartmentCode: number | string;
  DistrictCode: number | string;
  OfficeCode: number | string;
  Description: string;
  DescriptionHindi: string;
  Priority: number;
  PdfFIleName: string;
  Url: string;
  GeneralEntryDate: Date | string;
  CreatedBy: number;
  ModifiedBy: number;
  CreatedDate: Date;
  ModifiedDate: Date;
  IsActive: boolean;
  IsDeleted: boolean;
  IsVisible: boolean;
  GeneralEntrySubCategoryCode: number | string;
  ImageFiles: string[] = [];
  CMOComments: string;
  KeyWord: string;
  AutoKeyWord: string;
  TimeSlotCode: number | string;
  // ConnectWithCMIS: GeneralEntryConnectWithCMISParameterModel[]=[];
  BenificiaryList: string[] = [];
  ActualFileName: string;
}

export class CustomSearchModel {
  CategoryCode: number | string;
  SubCategoryCode: number;
  DepartmentCode: number;
  Userby: number;
  SearchField: string;
  ActiveView: number;
  CreatedFrom: Date | string;
  CreatedTo: Date | string;
  CMOComments: string;
  KeyWord: string;
  AutoKeyWord: string;
  IsExportToExcel: boolean;
  indexmodel: IndexModel;

  constructor() {
    // super();
    this.indexmodel = new IndexModel();
    this.CategoryCode = 0;
    this.SubCategoryCode = 0;
    this.DepartmentCode = 0;
    this.Userby = 0;
    this.SearchField = "";
    this.CMOComments = "";
    this.KeyWord = "";
    this.AutoKeyWord = "";
    this.ActiveView = -1;
    this.CreatedFrom = "";
    this.CreatedTo = "";
    this.IsExportToExcel = false;
  }
}

export class GeneralEntrySearchModel {
  constructor() {
    this.AdmDepartmentCode = 0;
    this.DepartmentCode = 0;
    this.CategoryCode = 0;
    this.SubCategoryCode = 0;
    this.FromDate = ""
    this.ToDate = ""
    this.SearchKeyword = ""
    this.IsUrlRequired = false;
    this.IsImageRequired = false;
    this.IsPdfRequired = false;
    this.IsActive = true;
    this.IndexModel = new IndexModel();
  }
  AdmDepartmentCode: number | string;
  DepartmentCode: number | string;
  CategoryCode: number | string;
  SubCategoryCode: number | string;
  FromDate: string;
  ToDate: string;
  SearchKeyword: string;
  IsUrlRequired: boolean;
  IsImageRequired: boolean;
  IsPdfRequired: boolean;
  IsActive: boolean | null;
  IsVisibleInHome: boolean | null;
  IsVisibleInFront: boolean | null;

  IndexModel: IndexModel;

}
