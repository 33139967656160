import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { AppComponent } from 'src/app/app.component';
import { AlertService } from 'src/app/shared/service/alert.service';
import { UserTypeEnum } from 'src/app/shared/enum/fixed-values.enum';
import { AuthenticationService } from 'src/app/shared/service/authentication.service';
import { MasterDataModel } from 'src/app/shared/model/master/master-data.model';
import { MasterDataService } from 'src/app/shared/service/master/master-data.service';


@Component({
  selector: 'app-master-data-detail',
  templateUrl: './master-data-detail.component.html',
  styleUrls: ['./master-data-detail.component.scss']
})
export class MasterDataDetailComponent implements OnInit {

  model: MasterDataModel;
  RecordId: number;
  IsStorage: boolean;
  constructor(
    private readonly _appComponent: AppComponent,
    private _route: ActivatedRoute,
    private readonly _masterDataService: MasterDataService,
    private readonly _alertService: AlertService
  ) {
    this.RecordId = this._route.snapshot.params.id;
    this.model = new MasterDataModel();
    this._appComponent.setPageLayout(
      "Look Up Data Detail :",
      "keyboard_backspace",
      "Back To List",
      "master/master-data"
    );
  }

  ngOnInit() {
    this.getDetail();
  }

  getDetail() {
    this._masterDataService.Detail(this.RecordId).subscribe(
      data => {
        if (data.IsSuccess) {
          this.model = <MasterDataModel>data.Data;
        }
      },
      error => {
        this._alertService.error(error.message);
      }
    );
  }

  ngOnDestroy() {
    this.IsStorage = true;
  //  sessionStorage.setItem("IsMasterDataStorage", JSON.stringify(this.IsStorage));
  }

}
