import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { IndexModel } from '../model/common.model';
import { AppSetting } from '../model/appsetting.model';
import { EmdReturnCommunicationModel } from '../model/emd-return-communication.model';

@Injectable({
    providedIn: 'root'
})

export class EmdReturnCommunicationService {

    constructor(private readonly _baseService: BaseService) { }

    GetList(loginUserCode: number, search: string, status: string, model: IndexModel) {
        return this._baseService.post(AppSetting.EMDReturnCommunicationListUrl + "?loginUserCode=" + loginUserCode + "&search=" + search + "&status=" + status, model);
    }

    AddUpdate(model: EmdReturnCommunicationModel) {
        return this._baseService.post(AppSetting.EMDReturnCommunicationAddUpdateUrl, model);
    }

    Detail(id: number) {
        return this._baseService.get(AppSetting.EMDReturnCommunicationDetailUrl + id);
    }

}