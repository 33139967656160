export class OutboxModel {
    CommunicationCode: number;
    Subject: string;
    Description: string;

    WorkOrderCode: number | string;
    WorkOrderTitle: string;
    WorkOrderSubject: string;
    OrderDate: Date | null;
    OrderNumber: string;

    CommunicationTypeCode: number | string;
    CommunicationType: string;
    CommunicationTypeHindi: string;
    CommunicationTypeEnumValue: string;

    OfficeCode: number | string;
    OfficeName: string;
    OfficeDDOCode: number | null;
    DepartmentCode: number | string;
    DepartmentName: string;
    DepartmentNameHindi: string;
    DistrictCode: number | string;
    DistrictName: string;
    DistrictNameHindi: string;

    RefNo: string;
    Status: string;
    StatusDate: string;
    StatusDateTime: Date | string;
    IsActive: boolean;
    IsDeleted: boolean;
    CreatedBy: number | null;
    CreatedOn: Date | null;
    ModifiedBy: number | null;
    ModifiedOn: Date | null;
    DocumentList: CommunicationDocumentModel[] = [];
    DeletedDocumentCodes: string;
    IsView_BtnAction: boolean = false;
    IsView_BtnActionHistory: boolean = false;
    DisplayCreateDate:string;
    Vendor:string;
    OfficeLookupList: InvoiceCommunicationOfficeHistoryViewModel[] = [];
    CurrentStatus: string;
    ProjectOrTenderName: string;
    ProjectName: string;
    projectCode: number;
    SearchText: string;
    VendorCode: number | null;
    OfficeStaffCode: number | null;
    OfficeStaffUser: string;
    Purpose: string;
    BankCode: number | null;
    BankName: string;
    BankNameHindi: string;
    VendorUserCode: number | null;
    VendorContactPersonName: string;
    SLADays: number | null;
    SLANotificationDays: number | null;
    UserTypeCode: number;
    UserType: string;
    IsMoved: number;
    IsCreatedByVendor: number;
    CanReturn: number;
    CanReOpen: number;
    IsAssigned: number;
    Department_AdmDepartmentCode: number | null;
    Summary: string;
};

export class CommunicationDocumentModel {
    DocumentCode: number;
    CommunicationCode: number;
    AttachmentTypeCode: number | null;
    AttachmentType: string;
    AttachmentTypeHindi: string;
    DocName: string;
    DocPath: string;
    Ext: string;
}

export class InvoiceCommunicationOfficeHistoryViewModel {
    CommunicationOfficeLookupCode: number;
    CommunCommunicationCodeicationCode: number;
    OfficeCode: number;
    OfficeName: string;
    DepartmentName: string;
    UserName: string;
    ChangedOn: Date | null;
  }
