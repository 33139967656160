import { ChangeDetectorRef, Component, OnInit, SimpleChanges } from '@angular/core';
import { FormGroup } from "@angular/forms";
import { DynamicFieldConfig } from "src/app/shared/model/dynamic-field-config.model";
import { Validator } from '../../shared/model/dynamic-field-config.model';
@Component({
  selector: "app-date",
  template: `
  <div [ngClass]="[field.CSSClass?field.CSSClass:'col l4 xl4 m4 s12']">
<mat-form-field class="example-full-width" [formGroup]="group" appearance="outline" >
<mat-label>{{field.label}}  <span *ngIf="field.IsRequired" style="color: red">*</span></mat-label>
<input (focus)="this.group.get(this.field.name).markAsTouched()" matInput [matDatepicker]="picker" [formControlName]="field.name" (focus)="picker.open()" [placeholder]="field.label" [(ngModel)]="field.value" matTooltip="{{field.ToolTip}}"  matTooltipPosition="above"
[min]="minDate" [max]="maxDate" [readonly]="true"
>
<mat-datepicker-toggle matSuffix [for]="picker"  ></mat-datepicker-toggle>
<mat-datepicker #picker [disabled]="field.IsDisabledForEdit"></mat-datepicker>
</mat-form-field>

<ng-container *ngFor="let validation of  validations;let indx=index" ngProjectAs="mat-error" >

<mat-error class="dynamic-feild-error"  *ngIf="group.get(field.name).hasError(validation.name) && group.get(field.name).touched" >{{validation.message}}</mat-error>
<br *ngIf="indx <= validations?.length-1 && validations?.length>1">

</ng-container>
<ng-container *ngIf="field.CustomValidations!=null && field.CustomValidations.length>0">
<mat-error  class="dynamic-feild-error"*ngIf="group.get(field.name).hasError('matDatepickerMax')">{{field.CustomValidations[0].MaxErrorMessage}}</mat-error>
 
<mat-error class="dynamic-feild-error" *ngIf="group.get(field.name).hasError('matDatepickerMin')">{{field.CustomValidations[0].MinErrorMessage}}</mat-error>

</ng-container>
 
</div>
`,
  styles: []
})
export class DateComponent implements OnInit {
  fields: DynamicFieldConfig[];
  field: DynamicFieldConfig;
  group: FormGroup;
  validations: Validator[];
  _minDate = new Date("1/1/100");
  _maxDate = new Date("12/31/9999");
  private compareFieldName: string = "";
  get minDate() { return (this.compareFieldName != "" ? this.group.get(this.compareFieldName).value : this._minDate) }
  get maxDate() { return this._maxDate }


  constructor(private cdRef: ChangeDetectorRef) {
    // console.info("field Date")
    // console.info(JSON.stringify(this.fields))
  }
  ngOnInit() {
    this.applyValidations();

  }


  applyValidations() {
    
    this.validations = this.field.validations;
    if (this.field.CustomValidations != null && this.field.CustomValidations.length > 0) {
      let compareValidation = this.field.CustomValidations.find(x => x.ValueComapreFieldCode > 0);
      if (compareValidation) {
        this.compareFieldName = this.fields.find(x => x.fieldCode == compareValidation.ValueComapreFieldCode).name;
      } else {
        this._minDate = this.field.value ? new Date(this.field.value) : this.field.CustomValidations[0].MinLimit;
        this._maxDate = this.field.CustomValidations[0].MaxLimit;

      }


    }
  }

  ngOnChanges(changes: SimpleChanges) {
    this.UpdateValueValidation();  
  }

  ngAfterViewChecked(): void {
    this.cdRef.markForCheck();
    this.cdRef.detectChanges();
  }

  UpdateValueValidation() {

    this.fields.forEach(element => {
      let item = element.CustomValidations.find(x => x.ValueComapreFieldCode == element.fieldCode);
      if (item) {
        
        let fieldValue = this.fields.find(x => x.fieldCode == Number(item.ValueComapreFieldCode));
        this._minDate = new Date(fieldValue.value);
      }
    });
  }

}
