import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { AppComponent } from 'src/app/app.component';
import { ConfirmationDialogComponent } from 'src/app/confirmation-dialog/confirmation-dialog.component';
import { AppSetting } from 'src/app/shared/model/appsetting.model';
import { GlobalMessagesModel } from 'src/app/shared/model/common-messages.model';
import { IndexModel, PermissionModel } from 'src/app/shared/model/common.model';
import { ColumnHeaderModel } from 'src/app/shared/model/commonddl.model';
import { UserTypeViewModel } from 'src/app/shared/model/user-type.model';
import { AlertService } from 'src/app/shared/service/alert.service';
import { AuthenticationService } from 'src/app/shared/service/authentication.service';
import { CommonService } from 'src/app/shared/service/common.service';
import { UserTypeService } from 'src/app/shared/service/user-type.service';
import { UserService } from 'src/app/shared/service/user.service';
import { AddUpdateChildUserTypeComponent } from './add-update-child-user-type/add-update-child-user-type.component';
import { AddUpdateUserTypeMasterComponent } from './add-update-user-type-master/add-update-user-type-master.component';

@Component({
  selector: 'app-user-type-master',
  templateUrl: './user-type-master.component.html',
  styleUrls: ['./user-type-master.component.scss']
})

export class UserTypeMasterComponent implements OnInit {

  listModel: UserTypeViewModel[];
  dataSource: any;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  id: number;
  displayedColumns: string[] = [
    "index",
    "UserTypeTitle",
    "UserType",
    "BaseUserType",
    "ParentUserType",
    "IsActive",
    "Action"
  ];
  ViewdisplayedColumns: ColumnHeaderModel[] = [ ];
  columnsToDisplay: string[] = this.displayedColumns.slice();
  Permission: PermissionModel = this._authService.GetPagePermission("/master/user-type", "user-type/create", "user-type/detail", "user-type/update", "user-type/delete");
  indexModel: IndexModel;
  totalRecords: number;

  constructor(
    private readonly _parentApi: AppComponent,
    public readonly _commonService: CommonService,
    private readonly _alertService: AlertService,
    private readonly _router: Router,
    private _route: ActivatedRoute,
    private _userService: UserService,
    private readonly _userTypeService: UserTypeService,
    private readonly _authService: AuthenticationService,
    private readonly _dialog: MatDialog,) {
    this._parentApi.setPageLayout(
      "User Type Master:", "", "", "", true);
    this.indexModel = new IndexModel();
    
    this.Permission;
  }

  ngOnInit() {
    this.GetList();
  }

  GetList() {
    this._userTypeService.GetList(this.indexModel).subscribe(
      data => {
        if (data.IsSuccess) {
          this.listModel = <UserTypeViewModel[]>data.Data.Data;
          this.dataSource = new MatTableDataSource<UserTypeViewModel>(this.listModel);
          this.totalRecords = data.Data.TotalRecords;
          if (!this.indexModel.IsPostBack) {
            this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.sort;
          }
        }
      },
      error => {
        this._alertService.error(error.message);
      }
    );
  }

  OnStatusClick(id) {
    const dialogRef = this._dialog.open(ConfirmationDialogComponent, { width: "50%", data: GlobalMessagesModel.ConfirmStatusChanged });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this._userTypeService.ChangeActiveStatus(id).subscribe(
          data => {
            if (data.IsSuccess) {
              this.GetList();
              this._alertService.success(data.Message);
            } else {
              this._alertService.error(data.Message);
            }
          },
          error => {
            this._alertService.error(error.message);
          }
        );
      }
    });
  }

  OpenDialogForParentUserType(Id) {
    const _dialogRef = this._dialog.open(AddUpdateUserTypeMasterComponent, {
      width: "500px",
      data: Id,
      disableClose: true
    });
    _dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        this.GetList();
      }
    });
  }

  OpenDialogForChildUserType(Id) {
    const _dialogRef = this._dialog.open(AddUpdateChildUserTypeComponent, {
      width: "500px",
      data: Id,
      disableClose: true
    });
    _dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        this.GetList();
      }
    });
  }

  sortData(event) {
    this.indexModel.OrderBy = event.active;
    this.indexModel.OrderByAsc = event.direction == AppSetting.orderByDscAsc ? AppSetting.orderByAsc : AppSetting.orderByDsc;
    this.indexModel.IsPostBack = true;
    this.GetList();
  }

  onPaginateChange(event) {
    this.indexModel.Page = event.pageIndex + 1;
    this.indexModel.PageSize = event.pageSize;
    this.indexModel.IsPostBack = true;
    this.GetList();
  }

}
