import { TitleCasePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatSort } from '@angular/material';
import { ActivatedRoute } from '@angular/router';
import { AppComponent } from 'src/app/app.component';
import { StatusEnum, UserTypeEnum } from 'src/app/shared/enum/fixed-values.enum';
import { PermissionModel } from 'src/app/shared/model/common.model';
import { ColumnHeaderModel, DDLModel, DdlItemModel } from 'src/app/shared/model/commonddl.model';
import { UserViewModel } from 'src/app/shared/model/user.model';
import { AlertService } from 'src/app/shared/service/alert.service';
import { AuthenticationService } from 'src/app/shared/service/authentication.service';

@Component({
  selector: 'app-statistical-report-drill-down',
  templateUrl: './statistical-report-drill-down.component.html',
  styleUrls: ['./statistical-report-drill-down.component.scss']
})
export class StatisticalReportDrillDownComponent implements OnInit {

  userTypeEnum = UserTypeEnum;
  dataSource: any;
  totalRecords: number;
  ddlStatus: DdlItemModel[] = [];
  status: string = '';
  search: string = '';
  statusPara: string;
  ddlList: DDLModel;
  communicationTypeCode: string;
  moduleCode: string;
  communicationStatusEnum = StatusEnum;
  IsAddEdit = false;
  Permission: PermissionModel = this._authService.GetPagePermission("/communications-list", "/communications-list/create", "/communications-list/detail", "/communications-list/update", "/communications-list/delete");
  isShow = true;
  //drilldownhModel = this._commonService.modelSetGet(new DashboardDrilDownModel(), false) as DashboardDrilDownModel;
  fromDate: Date | string;
  toDate: Date | string;
  ddlDistrict: DdlItemModel[] = [];
  loginUserDetail: UserViewModel;
  IsCreatedByVendor: string = "";

  ageFrom: number = 0;
  ageTo: number = 0;
  filterDesc: string;
  communicationType: string;

  routerURL: string;


  displayedColumns: string[] = ["index", "Subject", "DepartmentName", "OfficeName", "CommunicationType", "RefNo", "LastActionDate", "Status", "Action"];

  columnsToDisplay: string[] = this.displayedColumns.slice();
  ViewdisplayedColumns: ColumnHeaderModel[] = [
    { Value: "Subject", Text: "Subject" },
    { Value: "DepartmentName", Text: "Department" },
    { Value: "OfficeName", Text: "Office" },
    { Value: "CommunicationType", Text: "Communication Type" },
    { Value: "RefNo", Text: "References No." },
    { Value: "LastActionDate", Text: "Date of Action" },
    { Value: "Status", Text: "Status" },
  ];

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  isShowUserManual = true;
  reportType: string;
  para1: string;
  para2: string;
  para3: string;
  para4: string;


  constructor(
    private readonly appComponnet: AppComponent,
    private readonly _authService: AuthenticationService, public titleCasePipe: TitleCasePipe,
    private readonly _alertService: AlertService,
    private _route: ActivatedRoute,
  ) {
    this.appComponnet.setPageLayout("Statistical Reports :", "", "", "");

  }

  ngOnInit() {

  }

  //  private GetCommunicationDrilldownList() {
  //     this.drilldownhModel.ReportType = this.reportType;
  //     this.drilldownhModel.Para1 = this.para1;
  //     this.drilldownhModel.Para2 = this.para2;
  //     this.drilldownhModel.Para3 = this.para3;
  //     this.drilldownhModel.Para4 = this.para4;

  //     this._communicationService.GetCommunicationDrilldownList(this._commonService.modelSetGet(this.drilldownhModel, true)).subscribe((data) => {
  //       if (data.IsSuccess) {
  //         this.model = <CommunicationModel[]>data.Data.Data ? data.Data.Data : [];

  //         this.dataSource = new MatTableDataSource<CommunicationModel>(this.model);
  //         this.totalRecords = data.Data.TotalRecords;
  //         if (!this.drilldownhModel.indexModel.IsPostBack) {
  //           this.dataSource.paginator = this.paginator;
  //           this.dataSource.sort = this.sort;
  //         }
  //       }
  //       else {
  //         this._alertService.error(data.Message);
  //       }
  //     }, (error) => {
  //       this._alertService.error(error.message);
  //     });
  //   }

}
