import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { AppSetting } from 'src/app/shared/model/appsetting.model';
import { GlobalMessagesModel } from 'src/app/shared/model/common-messages.model';
import { DDLModel, DdlItemModel } from 'src/app/shared/model/commonddl.model';
import { GeneralEntrySubCategoryMasterPostModel } from 'src/app/shared/model/master/general-entry-sub-category-master.model';
import { UserViewModel, UserDepartmentViewModel } from 'src/app/shared/model/user.model';
import { AlertService } from 'src/app/shared/service/alert.service';
import { AuthenticationService } from 'src/app/shared/service/authentication.service';
import { CommonService } from 'src/app/shared/service/common.service';
import { GeneralEntrySubCategoryMasterService } from 'src/app/shared/service/master/general-entry-sub-category-master.service';
import { UserService } from 'src/app/shared/service/user.service';

@Component({
  selector: 'app-add-update-general-entry-sub-category-master',
  templateUrl: './add-update-general-entry-sub-category-master.component.html',
  styleUrls: ['./add-update-general-entry-sub-category-master.component.scss']
})
export class AddUpdateGeneralEntrySubCategoryMasterComponent implements OnInit {

 //#region Variable
 id: number;
 model: GeneralEntrySubCategoryMasterPostModel;
 loginData: UserViewModel;
 dDLList: DDLModel;
 fromGroup: FormGroup;
 ImagefileValidationMsg: string = "";
 ddlDepartment: UserDepartmentViewModel[];
 ddlKPICategory: DdlItemModel[]=[];
 //#endregion

 //#region constructor
 constructor(
   private readonly fb: FormBuilder,
   public readonly _commonService: CommonService,
   private readonly _alertService: AlertService,
   private readonly _userService: UserService,
   public readonly _dialogRef: MatDialogRef< AddUpdateGeneralEntrySubCategoryMasterComponent  >,
   private readonly _generalEntrySubCategoryService: GeneralEntrySubCategoryMasterService,
   private readonly _authService: AuthenticationService,
   @Inject(MAT_DIALOG_DATA) public data: any
 ) {
   this.model = new GeneralEntrySubCategoryMasterPostModel();
   this.loginData = this._authService.GetCurrentUserDetail().UserViewModel;
   this.GetDDLList();

   this.loginData = this._authService.GetCurrentUserDetail().UserViewModel;
 }
 //#endregion

 //#region Method
 ngOnInit() {
   this.formGroupInit();
   this.getDepartment();
   if (this.data || 0 > 0) {
     this.id = this.data;
     this.GetById();
   }
 }
 getDepartment() {
   
   this._userService.GetUserDepartment(this.loginData.UserCode).subscribe(
     (data) => {
       if (data.IsSuccess) {
         this.ddlDepartment = <UserDepartmentViewModel[]>data.Data;
       }
     },
     (error) => {
       this._alertService.error(error.message);
     }
   );
 }

 getKPIList(code) {
   this._commonService.GetKPIByDepartmentCode(Number(code)).subscribe(
     (data) => {
       if (data.IsSuccess) {
         
         this.ddlKPICategory = data.Data as DdlItemModel[];
       }
     },
     (error) => {
       this._alertService.error(error.message);
     }
   );
 }

 GetById() {
   this._generalEntrySubCategoryService.GetById(this.id).subscribe(
     (data) => {
       if (data.IsSuccess) {
         this.model = <GeneralEntrySubCategoryMasterPostModel>data.Data;
         this.model.CategoryCode = String(this.model.CategoryCode);
         this.model.DepartmentCode = String(this.model.DepartmentCode);
         this.getKPIList(this.model.DepartmentCode);
         if (this.model.KPICategoryCode) {
           this.model.KPICategoryCode = String(this.model.KPICategoryCode);
         }
        // this.model.IsApplicableToAllDPT = String(this.model.IsApplicableToAllDPT);
         //alert(this.model.CategoryCode);
       }
     },
     (error) => {
       this.model = new GeneralEntrySubCategoryMasterPostModel();
       this._alertService.error(error.message);
     }
   );
 }

 SaveClick() {
   
   this.fromGroup.markAllAsTouched();
   if (this.fromGroup.valid) {
     if (this.model.SubCategoryId) {
       this.model.ModifiedBy = this.loginData.UserCode;
       this._generalEntrySubCategoryService
         .Edit(this.model.SubCategoryId, this.model)
         .subscribe(
           (data) => {
             if (data.IsSuccess) {
               this._alertService.success(GlobalMessagesModel.updateSuccess);
               this._dialogRef.close(true);
             } else {
               this._alertService.error(data.Message);
             }
           },
           (error) => {
             this._alertService.error(GlobalMessagesModel.updateError);
           }
         );
     } else {
       this.model.CreatedBy = this.loginData.UserCode;
       this._generalEntrySubCategoryService.Add(this.model).subscribe(
         (data) => {
           if (data.IsSuccess) {
             this._alertService.success(GlobalMessagesModel.saveSuccess);
             this._dialogRef.close(true);
           } else {
             this._alertService.error(data.Message);
           }
         },
         (error) => {
           this._alertService.error(GlobalMessagesModel.saveError);
         }
       );
     }
   }
 }

 GetDDLList() {
   
   this._commonService
     .GetAllDDL(AppSetting.DDlKeyForGeneralEntrySubCategoryMaster)
     .subscribe(
       (data) => {
         if (data.IsSuccess) {
           this.dDLList = <DDLModel>data.Data;
         }
       },
       (error) => {
         this._alertService.error(error.message);
       }
     );
 }
 onNoClick(): void {
   this._dialogRef.close();
 }
 formGroupInit() {
   this.fromGroup = this.fb.group({
     DepartmentCode: [null, Validators.required],
     CategoryCode: [null, Validators.required],
     Title: [null, Validators.required],
     TitleHindi: [null, Validators.required],
     IsApplicableToAllDPT: [null],
     KPICategoryCode:[null]
   });
 }

 handleFileInput(event: any) {
   if (event.target.files.item(0).type.match("image/*")) {
     var reader = new FileReader();
     reader.onload = (event: any) => {
       this.model.ImagePath = event.target.result;
     };
     reader.readAsDataURL(event.target.files.item(0));
     this.ImagefileValidationMsg = "";
   } else {
     this.ImagefileValidationMsg = "only *image file accepted ";
   }
 }

 RemoveImageFile() {
   this.model.ImagePath = undefined;
 }
 //#endregion
}
