import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AppComponent } from 'src/app/app.component';
import { ProjectCategoryModel } from 'src/app/shared/model/master/project-category.model';
import { WorkOrderCategoryModel } from 'src/app/shared/model/master/work-order-category.model';
import { AlertService } from 'src/app/shared/service/alert.service';
import { WorkOrderCategoryService } from 'src/app/shared/service/master/work-order-category.service';

@Component({
  selector: 'app-work-order-category-detail',
  templateUrl: './work-order-category-detail.component.html',
  styleUrls: ['./work-order-category-detail.component.scss']
})
export class WorkOrderCategoryDetailComponent implements OnInit {
  model: WorkOrderCategoryModel;
  RecordId: number;
 

  constructor( private readonly _appComponent: AppComponent,
    private readonly _router: Router,
    private _route: ActivatedRoute,
    private readonly _workorderCategoryService: WorkOrderCategoryService,
    private readonly _alertService: AlertService
  ) {
    this.RecordId = this._route.snapshot.params.id;
    this.model = new WorkOrderCategoryModel();
    this._appComponent.setPageLayout(
      "WorkOrder Category Detail :",
      "keyboard_backspace",
      "Back To List",
      "master/work-order-category"
    );}

  ngOnInit() {
    this.getDetail();
  }

  getDetail() {
    this._workorderCategoryService.Detail(this.RecordId).subscribe(
      data => {
        if (data.IsSuccess) {
          this.model = <ProjectCategoryModel>data.Data;
        }
      },
      error => {
        this._alertService.error(error.message);
      }
    );
  }


}
