export class MasterTypeViewModel {
    MasterTypeCode: number;
    MasterTypeName: string;
    IsActive: boolean;
    IsDeleted: boolean;
    CreatedBy: number ;
    CreatedOn: string ;
    ModifiedBy: number ;
    ModifiedOn: string ;
    TableName: string;
    ValueMember: string;
    DisplayMember: string;
    SSOID:string;
}

export class MasterTypePostModel {
    constructor(){
        this.MasterTypeCode=0;
    }
    MasterTypeCode: number;
    MasterTypeName: string;
    AddUpdateUserId: number;
}