import { Injectable } from '@angular/core';
import { AppSetting } from '../model/appsetting.model';
import { AuthenticationService } from './authentication.service';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root'
})
export class UtilityService {

  constructor(private readonly _baseService: BaseService, private readonly _authService: AuthenticationService) { }

  DeleteDatabaseRecords(actionType: string) {
    return this._baseService.get(AppSetting.DeleteDatabaseRecordsUrl + actionType);
  }
}
