import { Injectable } from "@angular/core";
import { AppSetting } from "../../model/appsetting.model";
import { StatisticalFilterModel } from "../../model/report/search-criteria.model";
import { BaseService } from "../base.service";

@Injectable({
  providedIn: "root",
})

export class StatisticalReportService {
  constructor(private readonly _baseService: BaseService) {}

  GetStatisticalReportData(model: StatisticalFilterModel) {
    return this._baseService.post(AppSetting.StatisticalReportDataUrl, model);
  }
}
