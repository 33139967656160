import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { AppComponent } from "src/app/app.component"
import { DepartmentModel } from 'src/app/shared/model/master/department.model';
import { DepartmentService } from 'src/app/shared/service/master/department.service';
import { DepartmentMaster } from 'src/app/shared/service/master/department-master.service';
import { GlobalFilterModel, IndexModel } from 'src/app/shared/model/common.model';
import { GlobalMessagesModel } from 'src/app/shared/model/common-messages.model';
import { AppSetting } from 'src/app/shared/model/appsetting.model';
import { MatTableDataSource, MatDialog, MatSort, MatPaginator } from "@angular/material";
import { ConfirmationDialogComponent } from "src/app/confirmation-dialog/confirmation-dialog.component";
import { AlertService } from 'src/app/shared/service/alert.service';
import { CommonService } from 'src/app/shared/service/common.service';
import { ColumnHeaderModel } from 'src/app/shared/model/commonddl.model';
import { UserTypeEnum } from 'src/app/shared/enum/fixed-values.enum';
import { AuthenticationService } from 'src/app/shared/service/authentication.service';
import { ActivatedRoute, Router } from '@angular/router';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { isNullOrUndefined } from 'util';
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

declare const require: any;
const jsPDF = require('jspdf');
require('jspdf-autotable');

@Component({
  selector: 'app-department-report',
  templateUrl: './department-report.component.html',
  styleUrls: ['./department-report.component.scss']
})
export class DepartmentReportComponent implements OnInit {
  model: DepartmentModel[];
  indexModel: IndexModel;
  dataSource: any;
  totalRecords: number;
  loginUserCode: number;
  loginBaseUserType: string;
userTypeEnum=UserTypeEnum;

  queryString: string;
  isActive: number = 1;
  // displayedColumns: string[] = ["index", "DepartmentName", "DepartmentNameHindi", "IsActive", "Action"];
  // columnsToDisplay: string[] = this.displayedColumns.slice();
  displayedColumns: string[] = [];
  columnsToDisplay: string[] = [];

  ViewdisplayedColumns: ColumnHeaderModel[] = [
    { Value: "AdmDepartmentTitle", Text: "Admin Department" },
    { Value: "DepartmentCategoryName", Text: "Department Category" },
    { Value: "DepartmentCode", Text: "Department Code" },
    { Value: "DepartmentTitle", Text: "Department Name In English" },
    { Value: "DepartmentTitleHindi", Text: "Display Name(Department Name In Hindi)" },
    { Value: "DistrictTitle", Text: "District" },
    { Value: "ModifiedDate", Text: "ModifiedDate" },
    { Value: "DepartmentIsActive", Text: "Active Status" }
  ];

  SearchdisplayedColumns: ColumnHeaderModel[] = [
    { Value: "DepartmentTitle", Text: "Department Name" },
    { Value: "DepartmentTitleHindi", Text: "Department Name(Hindi)" }
  ];

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  constructor(private readonly appComponent: AppComponent,
    private readonly _alertService: AlertService,
    private readonly _departmentService: DepartmentMaster,
    private readonly _router: Router,
    private readonly _dialog: MatDialog,
    public readonly _commonService: CommonService,
    private readonly _authService: AuthenticationService,
    private _route: ActivatedRoute
  ) {
    this.loginUserCode = this._authService.UserCode;
    this.loginBaseUserType = this._authService.BaseUserType;

    this.displayedColumns = [ "index",
    "AdmDepartmentTitle",
    "DepartmentCategoryName",
    "DepartmentCode",
    "DepartmentTitle",
    "DepartmentTitleHindi",
    "DistrictTitle",
    "ModifiedDate",
    "DepartmentIsActive"];
    if (this.loginBaseUserType == this.userTypeEnum.ADM || this.loginBaseUserType == this.userTypeEnum.SADM) {
      this.displayedColumns.push("IsActive");
    }
    //  this.displayedColumns.push("Action");
    this.columnsToDisplay = this.displayedColumns.slice();

    if (this.loginBaseUserType == this.userTypeEnum.ADM || this.loginBaseUserType == this.userTypeEnum.SADM) {
      this.appComponent.setPageLayout("Department Report :", "", "", "");
    }
    else if (this.loginBaseUserType == this.userTypeEnum.VNDR) {
      this.appComponent.setPageLayout("Vendor Department Report :", "", "", "");
    }
    if (!isNullOrUndefined(this._route.snapshot.params["type"])) {
      this.queryString = this._route.snapshot.params["type"];
    }
    this.indexModel = new IndexModel();
  }

  ngOnInit() {
    if (this.userTypeEnum.ADM != this.loginBaseUserType &&  this.loginBaseUserType != this.userTypeEnum.SADM && this.userTypeEnum.HOD != this.loginBaseUserType && this.userTypeEnum.VNDR != this.loginBaseUserType) {
      this._router.navigate(["dashboard"]);
    }
    this.getList();
  }

  getList() {
    if (this.queryString == "vndrdept") {
      //this._departmentService.GetDepartmentByVendorList(this.indexModel, this.isActive, this.loginUserCode).subscribe(
      this._departmentService.GetDepartmentByVendorList(this.loginUserCode, this.indexModel).subscribe(
        data => {
          if (data.IsSuccess) {
            this.model = <DepartmentModel[]>data.Data.Data;
            this.dataSource = new MatTableDataSource<DepartmentModel>(this.model);
            this.totalRecords = data.Data.TotalRecords;
            if (!this.indexModel.IsPostBack) {
              this.dataSource.paginator = this.paginator;
              this.dataSource.sort = this.sort;
            }
          } else {
            this._alertService.error(data.Message);
          }
        },
        error => {
          this._alertService.error(error.message);
        }
      );
    }
    else {
      // this.indexModel.AdvanceSearchModel = { ActiveStatus: searchValue.ActiveStatus }
      this._departmentService.GetList(this.indexModel, this.loginUserCode).subscribe(
        data => {
          if (data.IsSuccess) {
            this.model = <DepartmentModel[]>data.Data.Data;
            this.dataSource = new MatTableDataSource<DepartmentModel>(this.model);
            this.totalRecords = data.Data.TotalRecords;
            if (!this.indexModel.IsPostBack) {
              this.dataSource.paginator = this.paginator;
              this.dataSource.sort = this.sort;
            }
          } else {
            this._alertService.error(data.Message);
          }
        },
        error => {
          this._alertService.error(error.message);
        }
      );
    }
  }

  updateDeleteStatus(id) {
    /*
    const dialogRef = this._dialog.open(ConfirmationDialogComponent, { width: "50%", data: GlobalMessagesModel.ConfirmDeleted });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this._departmentService.ChangeDeleteStatus(id).subscribe(
          data => {
            if (data.IsSuccess) {
              this._alertService.success(data.Message);
              this._commonService.ScrollingTop();
              this.getList();
            }
          },
          error => {
            this._alertService.error(error.message);
          }
        );
      }
    });
    */
  }

  updateActiveStatus(id) {
    const dialogRef = this._dialog.open(ConfirmationDialogComponent, { width: "50%", data: GlobalMessagesModel.ConfirmStatusChanged });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this._departmentService.ChangeActiveStatus(id).subscribe(
          data => {
            if (data.IsSuccess) {
              this._alertService.success(data.Message);
              this.getList();
            }
          },
          error => {
            this._alertService.error(error.message);
          }
        );
      }
    });
  }


  SearchByKeyword(searchValue: GlobalFilterModel) {
    this.indexModel.Search = searchValue.search;
    this.indexModel.AdvanceSearchModel = { ActiveStatus: searchValue.ActiveStatus }

    this.getList();
  }

  ClearSearch() {
    this.indexModel = new IndexModel();
    this.getList();
  }


  sortData(event) {
    this.indexModel.OrderBy = event.active;
    this.indexModel.OrderByAsc = event.direction == AppSetting.orderByDscAsc ? AppSetting.orderByAsc : AppSetting.orderByDsc;
    this.indexModel.IsPostBack = true;
    this.getList();
  }

  onPaginateChange(event) {
    this.indexModel.Page = event.pageIndex + 1;
    this.indexModel.PageSize = event.pageSize;
    this.indexModel.IsPostBack = true;
    this.getList();
  }

  @ViewChild('pdfTable', { static: false }) pdfTable: ElementRef;


  completeModel: DepartmentModel[];
  completeIndexModel: IndexModel;
  getCompleteData() {
    this.completeIndexModel = new IndexModel();
    this.completeIndexModel.Page = 1;
    this.completeIndexModel.PageSize = this.totalRecords;
    this.completeIndexModel.IsPostBack = true;
    this.completeIndexModel.Search = this.indexModel.Search;

    if (this.queryString == "vndrdept") {

      this._departmentService.GetDepartmentByVendorList(this.loginUserCode, this.completeIndexModel).subscribe(
        (completeData: any) => {
          if (completeData.IsSuccess) {
            this.completeModel = <DepartmentModel[]>completeData.Data.Data;
            this.totalRecords = completeData.Data.TotalRecords;
          } else {
            this._alertService.error(completeData.Message);
          }
        },
        error => {
          this._alertService.error(error.message);
        }
      );

    }
    else {
      this._departmentService.GetList(this.indexModel, this.loginUserCode).subscribe(
        (completeData: any) => {
          if (completeData.IsSuccess) {

            this.completeModel = <DepartmentModel[]>completeData.Data.Data;
            this.totalRecords = completeData.Data.TotalRecords;
          } else {
            this._alertService.error(completeData.Message);
          }
        },
        error => {
          this._alertService.error(error.message);
        }
      );
    }
  }
  rowsNew: any = [];

  download() {
    //completeModel
    var i = 1;
    this.rowsNew = [];
    this.completeModel.forEach(element => {
      var temp = {
        index: i, DepartmentTitle: element.DepartmentTitle, DepartmentTitleHindi: element.DepartmentTitleHindi,
      };
      i = i + 1;
      this.rowsNew.push(temp);
    });

  }

  public exportAsPdf(): void {
    var doc = new jsPDF('p', 'pt');
    if (this.queryString == "vndrdept") {
      var header = function (data) {
        doc.setFontSize(10);
        doc.setTextColor(40);
        doc.text("Vendor Department Report", data.settings.margin.left, 50);
        doc.text("Date Time : " + new Date().toLocaleDateString() + " " + new Date().toLocaleTimeString(), data.settings.margin.left, 800);
      };
    }
    else {
      var header = function (data) {
        doc.setFontSize(10);
        doc.setTextColor(40);
        doc.text("Department Master", data.settings.margin.left, 50);
        doc.text("Date Time : " + new Date().toLocaleDateString() + " " + new Date().toLocaleTimeString(), data.settings.margin.left, 800);
      };
    }

    var headers = [{
      title: "#",
      dataKey: "index"
    }, {
      title: "Department Name",
      dataKey: "DepartmentTitle"
    },
    {
      title: "Department Name(Hindi)",
      dataKey: "DepartmentTitleHindi"
    },

    {
      title: "Is Active",
      dataKey: "IsActive"
    },
    {
      title: "Modified By",
      dataKey: "ModifiedBy"
    },
    {
      title: "Modified On",
      dataKey: "ModifiedOn"
    },
    ];
    doc.autoTable(headers, this.rowsNew, { margin: { top: 70 }, beforePageContent: header });
    if (this.queryString == "vndrdept") {
      doc.save("Vendor-Department-Report_" + new Date().toLocaleDateString() + "_" + new Date().toLocaleTimeString() + ".pdf");
    }
    else {
      doc.save("Department-Master-Report_" + new Date().toLocaleDateString() + "_" + new Date().toLocaleTimeString() + ".pdf");
    }
  }

  //Excel
  public exportAsExcelFile(): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.rowsNew);
    if (this.queryString == "vndrdept") {
      const workbook: XLSX.WorkBook = { Sheets: { 'Vendor Department Report': worksheet }, SheetNames: ['Vendor Department Report'] };
      const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
      this.saveAsExcelFile(excelBuffer, 'Vendor Department');
    }
    else {
      const workbook: XLSX.WorkBook = { Sheets: { 'Department Master Report': worksheet }, SheetNames: ['Department Master Report'] };
      const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
      this.saveAsExcelFile(excelBuffer, 'Department-Master');
    }
  }

  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], { type: EXCEL_TYPE });
    FileSaver.saveAs(data, fileName + '_Report_' + new Date().toLocaleDateString() + "_" + new Date().toLocaleTimeString() + EXCEL_EXTENSION);
  }

  DownloadPdf() {
    this._departmentService.GetPDFDownload(this.indexModel, this.isActive, this.loginUserCode).subscribe(
      data => {
        if (data.IsSuccess) {
          // const linkSource = data.Data;
          // const downloadLink = document.createElement("a");
          // const fileName = 'Vendor Type Report';

          // downloadLink.href = linkSource;
          // downloadLink.download = fileName;
          // downloadLink.target = "blank";
          // downloadLink.click();
          var w = window.open("about:blank");

          setTimeout(function () {
            //FireFox seems to require a setTimeout for this to work.
            w.document.body.appendChild(w.document.createElement("iframe")).src = data.Data;
            w.document.getElementsByTagName("iframe")[0].style.width = "100%";
            w.document.getElementsByTagName("iframe")[0].style.height = "100%";
          }, 0);
        } else {
          this._alertService.error(data.Message);
        }
      },
      error => {
        this._alertService.error(error.message);
      }
    );
  }

  buttonClickExportAsPDF(): void {
    // this.getCompleteData();
    // setTimeout(() => {
    //   this.download();
    //   this.exportAsPdf();
    // }, 1000);
    this.DownloadPdf();
  }

  buttonClickExportAsXLSX(): void {
    this.getCompleteData();
    setTimeout(() => {
      this.download();
      this.exportAsExcelFile();
    }, 1000);
  }


}

