import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { DesignationMasterModel } from 'src/app/shared/model/master/DesignationMaster.model';
import { UserViewModel } from 'src/app/shared/model/user.model';
import { AlertService } from 'src/app/shared/service/alert.service';
import { AuthenticationService } from 'src/app/shared/service/authentication.service';
import { CommonService } from 'src/app/shared/service/common.service';
import { DesignationMasterService } from 'src/app/shared/service/master/designation-master.service';
import { UserService } from 'src/app/shared/service/user.service';

@Component({
  selector: 'app-add-update-designation-master',
  templateUrl: './add-update-designation-master.component.html',
  styleUrls: ['./add-update-designation-master.component.scss']
})
export class AddUpdateDesignationMasterComponent implements OnInit {

  //#region  Variable's
  id: number;
  model: DesignationMasterModel;
  loginData: UserViewModel;
  name = new FormControl('', [Validators.required]);
  DisplayOrder = new FormControl('', [Validators.required]);
  title: string = "Add";
  //#endregion

  //#region Constructor
  constructor(
    public readonly _commonService: CommonService,
    private readonly _alertService: AlertService,
    public readonly _dialogRef: MatDialogRef<AddUpdateDesignationMasterComponent>,
    private readonly _designationMasterService: DesignationMasterService,
    private readonly _authService: AuthenticationService,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    if (data) {
      this.title = "Update";
      this.id = data;
      this.GetById();
    }
    this.model = new DesignationMasterModel();
  }
  //#endregion

  //#region Method's
  ngOnInit() {
    this.loginData = this._authService.GetCurrentUserDetail().UserViewModel;
  }


  GetById() {
    this._designationMasterService.GetById(this.id).subscribe(
      data => {
        if (
          (data.IsSuccess)
        ) {
          this.model = <DesignationMasterModel>data.Data;
        }
      },
      error => {
        this.model = new DesignationMasterModel();
        this._alertService.error(error.message);
      }
    );
  }


  SaveClick() {

    this.name.markAsTouched();
    this.DisplayOrder.markAsTouched();
    if (this.name.valid && this.DisplayOrder.valid) {
      if (this.model.DesignationId) {

        this._designationMasterService.Edit(this.model).subscribe(data => {
          if (data.IsSuccess) {
            this._alertService.success(data.Message);
            this._dialogRef.close(true);
          }
          else {
            this._alertService.error(data.Message);

          }
        }, error => {
          this._alertService.error(error.message);
        });
      }
      else {
        this._designationMasterService.Add(this.model).subscribe(data => {
          if (data.IsSuccess) {
            this._alertService.success(data.Message);
            this._dialogRef.close(true);
          }
          else {
            this._alertService.error(data.Message);
          }
        }, error => {
          this._alertService.error(error.message);
        });
      }
    }

  }

  onNoClick(): void {
    this._dialogRef.close();
  }

  numberOnly(value, isCommaOrDash: boolean = false): boolean {
    return this._commonService.NumberOnly(value, isCommaOrDash);
  }

  //#endregion


}
