import { CommonService } from 'src/app/shared/service/common.service';
import { AlertService } from '../../../shared/service/alert.service';
import { ProjectService } from '../../../shared/service/project.service';
import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { WorkOrderModel } from 'src/app/shared/model/work-order.model';
import { ProjectViewModel } from 'src/app/shared/model/project.model';
import { WorkOrderService } from 'src/app/shared/service/work-order.service';
import { CommunicationService } from '../../../shared/service/communication.service';
import { CommunicationTransactionViewModel } from 'src/app/shared/model/communication-transaction.model';
import { CommunicationFieldValueViewModel, CommunicationTypeDefaultFieldsMasterModel, DefaultFieldVisibleModel } from 'src/app/shared/model/master/communication-type-fields-master.model';
import { CommunicationTypeFieldsMasterService } from 'src/app/shared/service/master/communication-type-fields-master.service';
import { FieldTypeEnum } from 'src/app/shared/enum/fixed-values.enum';
import { DefaultFieldEnum } from 'src/app/shared/enum/default-field.enum';

@Component({
  selector: 'app-field-detail-section',
  templateUrl: './field-detail-section.component.html',
  styleUrls: ['./field-detail-section.component.scss']
})

export class FieldDetailSectionComponent implements OnInit {
  @Input() SelectedValue: number = 0;
  @Input() Type: string;
  workOrderDetailModel = new WorkOrderModel();
  projectDetailModel = new ProjectViewModel();
  CommunicatioModel = new CommunicationTransactionViewModel();
  communicationFieldValueViewModel: CommunicationFieldValueViewModel[] = [];
  fieldTypeEnum = FieldTypeEnum;
  communicationDefaultFieldEnum = DefaultFieldEnum;
  communicationDefaultFieldConfigModel = new DefaultFieldVisibleModel();

  constructor(private readonly _projectService: ProjectService,
    private readonly _workOrderService: WorkOrderService,
    private readonly _communicationService: CommunicationService,
    private readonly _communicationTypeFieldsMasterService: CommunicationTypeFieldsMasterService,
    private _alertService: AlertService,
    private _commonService: CommonService) { }

  ngOnInit() { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.SelectedValue.previousValue != changes.SelectedValue.currentValue) {
      this.getData();
    }
  }

  getData() {
    switch (this.Type) {
      case "WORKORDER":
        this.getWorkOrderDetail()
        break;

      case "PROJECT":
        this.getProjectDetail();
        break;

      case "COMMUNICATION":
        this.getCommunicationDetail();
        break;

      default:
        break;
    }
  }

  getWorkOrderDetail() {
    if (this.SelectedValue > 0) {
      this._workOrderService.Detail(this.SelectedValue).subscribe(data => {
        if (data.IsSuccess) {
          this.workOrderDetailModel = <WorkOrderModel>data.Data;
        }
      }, error => {
        this._alertService.error(error.message);
      });
    }
    else {
      this.workOrderDetailModel = new WorkOrderModel();
    }
  }

  getProjectDetail() {
    if (this.SelectedValue > 0) {
      this._projectService.Detail(this.SelectedValue).subscribe(data => {
        if (data.IsSuccess) {
          this.projectDetailModel = <ProjectViewModel>data.Data;
        }
      }, error => {
        this._alertService.error(error.message);
      });
    }
    else {
      this.projectDetailModel = new ProjectViewModel();
    }
  }

  getCommunicationDetail() {
    if (this.SelectedValue > 0) {
      this._communicationService.Detail(this.SelectedValue, true).subscribe(data => {
        if (data.IsSuccess) {
          this.CommunicatioModel = <CommunicationTransactionViewModel>data.Data;
          this.GetCommunicationDynamicField();
          this.getCommunicationDefaultList(this.CommunicatioModel.CommunicationTypeEnumValue)
        }
      });
    }
    else {
      this.CommunicatioModel = new CommunicationTransactionViewModel();
    }
  }

  GetCommunicationDynamicField() {
    this._communicationTypeFieldsMasterService.GetFieldsForCommunication(this.CommunicatioModel.CommunicationTypeEnumValue, this.SelectedValue).subscribe(response => {
      if (response.IsSuccess) {
        this.communicationFieldValueViewModel = response.Data as CommunicationFieldValueViewModel[];
      } else {
        this._alertService.error(response.Message);
      }
    }, error => {
      this._alertService.error(error.message);
    });
  }

  getCommunicationDefaultList(communicationType) {
    this._communicationTypeFieldsMasterService.GetDefaultFieldsForCommunication(0, communicationType).subscribe(data => {
      if (data.IsSuccess) {
        let defaultFieldModel = <CommunicationTypeDefaultFieldsMasterModel[]>data.Data;
        defaultFieldModel.forEach(element => {
          switch (String(element.FieldCode)) {
            case this.communicationDefaultFieldEnum.Project:
              this.communicationDefaultFieldConfigModel.IsShowProjectField = true;
              break;

            case this.communicationDefaultFieldEnum.Department:
              this.communicationDefaultFieldConfigModel.IsShowDepartmentField = true;
              break;

            case this.communicationDefaultFieldEnum.District:
              this.communicationDefaultFieldConfigModel.IsShowDistrictField = true;
              break;

            case this.communicationDefaultFieldEnum.WorkOrder:
              this.communicationDefaultFieldConfigModel.IsShowWorkOrderField = true;
              break;

            case this.communicationDefaultFieldEnum.VendorBank:
              this.communicationDefaultFieldConfigModel.IsShowVendorBankField = true;
              break;

            case this.communicationDefaultFieldEnum.Attachments:
              this.communicationDefaultFieldConfigModel.IsShowAttachmentsField = true;
              break;

            case this.communicationDefaultFieldEnum.Office:
              this.communicationDefaultFieldConfigModel.IsShowOfficeField = true;
              break;

            default:
              break;
          }
        });
      } else {
        this._alertService.error(data.Message);
      }
    }, error => {
      this._alertService.error(error.message);
    });
  }



}
