import { Component, HostBinding, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { NavService } from '../shared/service/nav.service';
import { NavItem } from '../shared/model/nav-item.model';

@Component({
  selector: 'app-menu-list-item',
  templateUrl: './menu-list-item.component.html',
  styleUrls: ['./menu-list-item.component.scss'],
  animations: [
    trigger('indicatorRotate', [
      state('collapsed', style({ transform: 'rotate(0deg)' })),
      state('expanded', style({ transform: 'rotate(180deg)' })),
      transition('expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4,0.0,0.2,1)')
      ),
    ])
  ]
})

export class MenuListItemComponent implements OnInit {
  expanded: boolean = false;
  @HostBinding('attr.aria-expanded') ariaExpanded = this.expanded;
  @Input() item: NavItem;
  @Input() depth: number;
  @Output() onMenuOpen = new EventEmitter<void>();
  @Output() onActiveLinkUpdate = new EventEmitter<string>();

  constructor(public navService: NavService,
    public router: Router) {
    if (this.depth === undefined) {
      this.depth = 0;
    }
  }

  ngOnInit() {
    this.navService.currentUrl.subscribe((url: string) => {

      if (this.item.route && url) {
        this.item.isExpanded = url.indexOf(`/${this.item.route}`) === 0;
        this.ariaExpanded = this.item.isExpanded;
      }
    });
  }




  onItemSelected(item: NavItem) {
    sessionStorage.setItem("MenuName", item.displayName);
    if (!item.children || !item.children.length) {
      if (item.isRedirect) {
        window.location.href = item.route;

      } else {
        this.clearSessionStorage("postBackSearch_")

        this.router.navigate([item.route]);
      }
    }

    // if (item.children && item.children.length) {
    //   this.expanded = !this.expanded;

    if (this.item.isParent) {
      this.onMenuOpen.emit();

    } else {
      this.item.isExpanded = !this.item.isExpanded;
    }

    if (item.route) {
      this.onActiveLinkUpdate.emit(item.route);
    }
    // }
  }

  clearSessionStorage(ContainsKey) {
    var n = sessionStorage.length;
    while (n--) {
      var key = sessionStorage.key(n);
      if (key.includes(ContainsKey)) {
        sessionStorage.removeItem(key);
      }
    }
  }

}
