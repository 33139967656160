import { Component, OnInit, Inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { DdlItemModel, DDLModel } from 'src/app/shared/model/commonddl.model';
import { GlobalMessagesModel } from 'src/app/shared/model/common-messages.model';
import { CommonService } from 'src/app/shared/service/common.service';
import { AlertService } from 'src/app/shared/service/alert.service';
import { UserViewModel, SSOUserModel } from 'src/app/shared/model/user.model';
import { FormGroup, Validators, FormBuilder, FormControl } from '@angular/forms';
import { AuthenticationService } from 'src/app/shared/service/authentication.service';
import { UserService } from 'src/app/shared/service/user.service';
import { isNullOrUndefined, debug } from 'util';
import { UserTypeEnum, AlertTypeEnum } from 'src/app/shared/enum/fixed-values.enum';
import { AlertModel } from 'src/app/shared/model/common.model';
import { AppSetting } from 'src/app/shared/model/appsetting.model';

@Component({
  selector: 'app-add-update-user-dialog',
  templateUrl: './add-update-user-dialog.component.html',
  styleUrls: ['./add-update-user-dialog.component.scss']
})

export class AddUpdateUserDialogComponent implements OnInit {
  loginUserDetail: UserViewModel;
  frmGrp: FormGroup;
  ddlList: DDLModel;
  ddlOfficeDepartment: DdlItemModel[];
  ddlProject: DdlItemModel[];
  ddlDistrict: DdlItemModel[];
  ddlDepartmentOffice: DdlItemModel[];
  model: UserViewModel;
  ssoUserDetail: SSOUserModel;
  recordId: number = 0;
  heading: string;
  oldSSOId: string;
  alertModel: AlertModel;
  selectedAll = -1;
  isUserExistOnSso = true;
  UserTypeEnum = UserTypeEnum;
  submitBtn = true;

  constructor(private readonly fb: FormBuilder,
    public readonly _commonService: CommonService,
    private readonly _alertService: AlertService,
    private readonly _userService: UserService,
    private readonly _authService: AuthenticationService,
    public _dialogRef: MatDialogRef<AddUpdateUserDialogComponent>,
    private readonly _route: ActivatedRoute,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.loginUserDetail = _authService.LoggedInUserDetail.UserViewModel;
    this.GetDDLList();
    this.GetProjectDDLList();
    this.bindDepartmentDropdown();
    this.bindDistrictDropdown();
    this.model = new UserViewModel();
    this.model.UserType = UserTypeEnum.DOS;
    if (data) {
      if (data.Id) {
        this.recordId = data.Id;
        this.getDetail();
      }
    }
    this.heading = (this.recordId == 0 ? "Add" : "Update") + " Office Staff";
  }

  ngOnInit() {
    if (this.loginUserDetail.BaseUserType == UserTypeEnum.DREC || this.loginUserDetail.BaseUserType == UserTypeEnum.DRECADLC) {
      this.model.DepartmentCodes = this.loginUserDetail.DepartmentCodes;
      this.model.DistrictCodes = this.loginUserDetail.DistrictCodes;
      this.GetOffice();
      this.model.OfficeCodes = this.loginUserDetail.OfficeCodes;
    }
    this.formGroupInit();
  }

  formGroupInit() {
    const reg = '^((0091)|(\+91)|0?)[789]{1}\d{9}$';
    // const emailregx = '[A-Za-z0-9._%-]+@[A-Za-z0-9._%-]+\\.[a-z]{2,3}';
    this.frmGrp = this.fb.group({
      UserType: new FormControl({ value: '', disabled: (!isNullOrUndefined(this.model.UserType) && this.model.UserType != '') }, [Validators.required]),
      SSOID: [null, Validators.compose([Validators.required, Validators.maxLength(50)])],
      Title: [null, Validators.compose([Validators.required])],
      Name: [null, Validators.required],
      Email: [null, Validators.required],
      Gender: [null, Validators.required],
      Mobile: [null, Validators.compose([Validators.required, Validators.maxLength(35)])],
      // Mobile: [null, Validators.compose([Validators.required, Validators.maxLength(10),Validators.pattern(reg)]),],
      //DepartmentCodes: [null, Validators.required],
      //DistrictCodes: [null],
      //OfficeCodes: [null, Validators.required],
      Designation: [null, Validators.required],
      ProjectCodes: [null],
      DepartmentCodes: new FormControl({ value: '', disabled: (this.loginUserDetail.BaseUserType == UserTypeEnum.DREC || this.loginUserDetail.BaseUserType==UserTypeEnum.DRECADLC) }, [Validators.required]),
      // DistrictCodes: new FormControl({ value: '', disabled: (this.loginUserDetail.BaseUserType == UserTypeEnum.DREC) }, null),
      // OfficeCodes: new FormControl({ value: '', disabled: (this.loginUserDetail.BaseUserType == UserTypeEnum.DREC) }, [Validators.required]),

      DistrictCodes: new FormControl({ value: '' }, null),
      OfficeCodes: new FormControl({ value: '' }, [Validators.required]),


    });
  }

  GetDDLList() {
    this._commonService.GetAllDDL(AppSetting.UserDropdownKeys).subscribe(
      data => {
        if (data.IsSuccess) {
          this.ddlList = <DDLModel>data.Data;
        }
      },
      error => {
        this._alertService.error(error.message);
      }
    );
  }

  selectAll() {

    if (this.selectedAll < 0) {
      this.model.ProjectCode = this.ddlProject.map(
        function (a) {
          return a.Value;
        }
      );
      this.selectedAll = 1;
    } else {
      this.selectedAll = -1;
      this.model.ProjectCode = [];
    }
  }

  GetProjectDDLList() {
    var deptCodes = this.loginUserDetail.DepartmentCodes;
    if (this.loginUserDetail.BaseUserType == UserTypeEnum.ADM || this.loginUserDetail.BaseUserType == UserTypeEnum.SADM) {
      deptCodes = "0";
    }
    this._commonService.GetDDL(AppSetting.ddlProject, deptCodes).subscribe(
      data => {
        if (data.IsSuccess) {
          var ddl = <DDLModel>data.Data;
          //this.ddlList.ddlProject = ddl.ddlProject;
          this.ddlProject = ddl.ddlProject;
          setTimeout(() => {
            this.ddlList.ddlProject = ddl.ddlProject;
          }, 200);
        }
      },
      error => {
        this._alertService.error(error.message);
      }
    );
  }

  bindDepartmentDropdown() {
    this._userService.GetUserDepartmentList(0).subscribe(
      data => {
        if (data.IsSuccess) {
          this.ddlOfficeDepartment = <DdlItemModel[]>data.Data;
        }
      },
      error => {
        this.alert(error.message, AlertTypeEnum.Error);
      }
    );
  }

  bindDistrictDropdown() {
    this._userService.GetUserDistrictList(0).subscribe(
      data => {
        if (data.IsSuccess) {
          this.ddlDistrict = <DdlItemModel[]>data.Data;
        }
      },
      error => {
        this.alert(error.message, AlertTypeEnum.Error);
      }
    );
  }

  getDetail() {
    this._userService.Detail(this.recordId).subscribe(
      data => {
        if (data.IsSuccess) {
          this.model = <UserViewModel>data.Data;
          this.oldSSOId = this.model.SSOID;

          if (!isNullOrUndefined(this.model.ProjectCodes)) {
            this.model.ProjectCode = this.model.ProjectCodes.split(",")
          }
          this.GetOffice();
        }
      },
      error => {
        this.model = new UserViewModel();
        this.alert(error.message, AlertTypeEnum.Error);
      }
    );
  }

  getSSODetail(Event) {

    if (Event.currentTarget.value != this.oldSSOId) {
      this._userService.IsSSOIdExist(Event.currentTarget.value).subscribe(result => {
        if (result.IsSuccess) {
          let ssoId = result.Data != null ? <boolean>result.Data : null;
          if (ssoId != null && ssoId == true) {
            this.model.Email = undefined;
            this.model.Mobile = undefined;
            this.model.Gender = undefined;
            this.model.ProfilePic = "";
            this._commonService.ScrollingTop();
            this.alert(result.Message, AlertTypeEnum.Error);
          } else {
            this.getUserDetailFromSSO(this.model.SSOID);
          }
        }
      }, error => {
        this._commonService.ScrollingTop();
        this.alert(GlobalMessagesModel.InternalError, AlertTypeEnum.Error);
      });
    }
  }

  getUserDetailFromSSO(ssoId: string) {
    this._userService.GetUserDetailFromSSO(ssoId).subscribe(result => {
      this._alertService.blank();
      if (result.IsSuccess) {
        this.ssoUserDetail = <SSOUserModel>result.Data
        this.model.Name = this.ssoUserDetail.FirstName + " " + this.ssoUserDetail.LastName;
        this.model.Email = this.ssoUserDetail.MailPersonal;
        this.model.Mobile = this.ssoUserDetail.Mobile;
        this.model.Gender = this.ssoUserDetail.Gender;
        this.model.ProfilePic = this.ssoUserDetail.Photo != null && this.ssoUserDetail.Photo.length > 0 ? this.ssoUserDetail.Photo : "";
        this.isUserExistOnSso = true;
      } else {
        this.model.Name = undefined;
        this.model.Email = undefined;
        this.model.Mobile = undefined;
        this.model.Gender = undefined;
        this.model.ProfilePic = "";
        this._commonService.ScrollingTop();
        this.alert(ssoId + " SSO ID Not Found!", AlertTypeEnum.Error);
        this.isUserExistOnSso = false;

      }
    }, error => {
      this._commonService.ScrollingTop();
      this.alert(GlobalMessagesModel.InternalError, AlertTypeEnum.Error);
    });
  }

  handleFileInput(event: any) {
    if (event.target.files.item(0).type.match('image/*')) {
      if (event.target.files.item(0).size > 5242880) {
        this.alert("File size must be less than 5 MB", AlertTypeEnum.Error);
        this._commonService.ScrollingTop();
      } else {
        let reader = new FileReader();
        reader.onload = (event: any) => {
          this.model.ProfilePic = event.target.result;
        }
        reader.readAsDataURL(event.target.files.item(0));
      }
    } else {
      this.alert('Only *images accepted!', AlertTypeEnum.Error);
    }
  }

  GetOffice() {
    if (!isNullOrUndefined(this.model.DepartmentCodes)) {
      this.model.DistrictCodes = (this.model.DistrictCodes == undefined) ? "0" : this.model.DistrictCodes;
      this._userService.GetUserOfficeList(this.model.DepartmentCodes, this.model.DistrictCodes).subscribe(
        data => {
          if (data.IsSuccess) {
            this.ddlDepartmentOffice = <DdlItemModel[]>data.Data;
            this.GetDistrictByDepartment();
          }
        },
        error => {
          this.alert(error.message, AlertTypeEnum.Error);
        }
      );
    }
    else {
      this.ddlDepartmentOffice = null;
    }
  }

  saveClick() {
    this.submitBtn=false;
    this.frmGrp.markAllAsTouched();
    if (this.frmGrp.valid && this.isUserExistOnSso) {
      if (this.recordId == 0) {
        this.model.CreatedBy = this.loginUserDetail.UserCode;
      }
      else {
        this.model.ModifiedBy = this.loginUserDetail.UserCode;
      }
      if (!isNullOrUndefined(this.model.ProjectCode)) {
        this.model.ProjectCodes = this.model.ProjectCode.join(",");
      }
      else {
        this.model.ProjectCodes = '';
      }

      this._userService.AddUpdate(this.recordId, this.model).subscribe(
        data => {
          if (data.IsSuccess) {

            this._alertService.success(this.recordId == 0 ? GlobalMessagesModel.saveSuccess : GlobalMessagesModel.updateSuccess);
            this._dialogRef.close(true);
          } else {
            if (data) {
              this.alert(data.Message, AlertTypeEnum.Error);
            }
            else {
              this.submitBtn=true;
              this.alert(data.Message, AlertTypeEnum.Error);
              this.alert(this.recordId == 0 ? data.message : GlobalMessagesModel.updateError, AlertTypeEnum.Error);
            }

          }
        },
        error => {
          this.submitBtn=true;
          this.alert(error.message, AlertTypeEnum.Error);
        });
    }
    else if (!this.isUserExistOnSso) {
      this.submitBtn=true;
      this.alert(this.model.SSOID + " SSO ID Not exist on sso server!", AlertTypeEnum.Error);
    }
    else{
      this.submitBtn=true;
    }
  }

  closeDialog(): void {
    this._dialogRef.close();
  }

  alert(message: string, type: string) {
    this.alertModel = new AlertModel();
    this.alertModel.Message = message;
    this.alertModel.Type = type;
    setTimeout(() => {
      this.alertModel = new AlertModel();
    }, 5000);
  }

  GetDistrictByDepartment() {
    if (!isNullOrUndefined(this.model.DepartmentCodes)) {
      this._commonService.GetDDL(AppSetting.ddlDistrict, this.model.DepartmentCodes).subscribe(
        data => {
          if (data.IsSuccess) {
            var ddl = <DDLModel>data.Data;
            this.ddlDistrict = ddl.ddlDistrict;
          }
        },
        error => {
          this._alertService.error(error.message);
        }
      );
    }
    else {
      this.ddlList.ddlDistrict = null;
    }
  }

}
