import { VendorUserSearchModel } from './../model/user.model';
import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { IndexModel } from '../model/common.model';
import { AppSetting } from '../model/appsetting.model';
import { AuthenticationService } from './authentication.service';
import { UserViewModel, UserCreationModel, UserNotificationFilterModel, UserNotificationModel, UserExportModel, UserCreationViewModel } from '../model/user.model';
import { Dictionary } from '../model/dictionary.model';
import { UserAdditionalUserTypePostModel } from '../model/user-permission.model';

@Injectable({
    providedIn: 'root'
})

export class UserService {
    loginUserCode: number;

    constructor(private readonly _baseService: BaseService, private readonly _authenticationService: AuthenticationService) {
        this.loginUserCode = _authenticationService.UserCode;
    }

    GetOfficeStaffList(officeCode: number, model: IndexModel) {
        return this._baseService.post(AppSetting.OfficeStaffUserListUrl + "?loginUserCode=" + this.loginUserCode + "&officeCode=" + officeCode, model);
    }

    AddUpdate(id: number, model: UserViewModel) {
        return this._baseService.post(AppSetting.UserAddUpdateUrl, model);
    }

    GetUserDetailFromSSO(ssoId: string) {
        return this._baseService.get(AppSetting.GetSSODetailFromSSO + ssoId);
    }

    Detail(id: number) {
        return this._baseService.get(AppSetting.UserDetailUrl + id);
    }

    GetB2GUserById(id: number, ssoId: string = '') {
        var param = new Dictionary<any>();
        param.Add('id', id);
        param.Add('ssoId', ssoId);
        return this._baseService.get(AppSetting.B2GUserByIdUrl, param);
    }

    LocateUser(ssoId: string) {
        var param = new Dictionary<any>();
        param.Add('ssoId', ssoId);
        return this._baseService.get(AppSetting.LocateUserUrl, param);
    }

    ChangeActiveStatus(id: number) {
        return this._baseService.get(AppSetting.UserActiveStatusChangeUrl + id);
    }

    ChangeDeleteStatus(id: number) {
        return this._baseService.get(AppSetting.UserDeleteStatusChangeUrl + id);
    }

    IsSSOIdExist(ssoId: string, userCode = 0) {
        var param = new Dictionary<any>();
        param.Add("id", ssoId);
        param.Add("userCode", userCode);
        return this._baseService.get(AppSetting.UserSSOIDExistUrl, param);
    }

    CheckHODUserSSOId(ssoId: string) {
        return this._baseService.get(AppSetting.CheckHODUserSSOId + ssoId);
    }

    GetUserAdminDepartmentList(isNonExistOffice = false) {
        var param = new Dictionary<any>();
        param.Add("userCode", this.loginUserCode);
        param.Add("isNonExistOffice", isNonExistOffice);
        return this._baseService.get(AppSetting.GetUserAdminDepartmentDDlUrl, param);
    }

    GetUserAdminDepartmentForUserOfficeCreation(isOfficeExist = false) {
        var param = new Dictionary<any>();
        param.Add("userCode", this.loginUserCode);
        param.Add("isOfficeExist", isOfficeExist);
        return this._baseService.get(AppSetting.GetUserAdminDepartmentForUserOfficeCreationUrl, param);
    }

    GetUserDepartmentList(adminDepartmentCode: any = 0, isNonExistOffice = false, loginUserCode = this.loginUserCode) {
        var param = new Dictionary<any>();
        param.Add("userCode", loginUserCode);
        param.Add("adminDepartmentCode", adminDepartmentCode);
        param.Add("isNonExistOffice", isNonExistOffice);
        return this._baseService.get(AppSetting.GetUserDepartmentDDlUrl, param);
    }

    GetUserDepartmentForUserOfficeCreation(adminDeptCodes: string, isOfficeExist = false) {
        var param = new Dictionary<any>();
        param.Add("userCode", this.loginUserCode);
        param.Add("adminDeptCodes", adminDeptCodes);
        param.Add("isOfficeExist", isOfficeExist);
        return this._baseService.get(AppSetting.GetUserDepartmentForUserOfficeCreationUrl, param);
    }

    GetUserDistrictList(stateCode: any = "", isNonExistOffice = false, departmentCode = "", loginUserCode = this.loginUserCode) {
        var param = new Dictionary<any>();
        param.Add("userCode", loginUserCode);
        param.Add("stateCode", stateCode);
        param.Add("isNonExistOffice", isNonExistOffice);
        param.Add("departmentCode", departmentCode);
        return this._baseService.get(AppSetting.GetUserDistrictDDlUrl, param);
    }

    GetUserDistrictForUserOfficeCreation(stateCodes: string, deptCodes: string, isOfficeExist = false) {
        var param = new Dictionary<any>();
        param.Add("userCode", this.loginUserCode);
        param.Add("stateCodes", stateCodes);
        param.Add("deptCodes", deptCodes);
        param.Add("isOfficeExist", isOfficeExist);
        return this._baseService.get(AppSetting.GetUserDistrictForUserOfficeCreationUrl, param);
    }

    GetUserOfficeList(departmentCode: any = 0, districtCode: any = 0, loginUserCode = this.loginUserCode) {
        var param = new Dictionary<any>();
        param.Add("userCode", loginUserCode);
        param.Add("departmentCode", departmentCode);
        param.Add("districtCode", districtCode);
        return this._baseService.get(AppSetting.GetUserOfficeDDlUrl, param);
    }

    GetUserOfficeHavingStaffList(departmentCode: any = 0, districtCode: any = 0, loginUserCode = this.loginUserCode) {
        var param = new Dictionary<any>();
        param.Add("userCode", loginUserCode);
        param.Add("departmentCode", departmentCode);
        param.Add("districtCode", districtCode);
        return this._baseService.get(AppSetting.GetUserOfficeHavingStaffDDlUrl, param);
    }

    GetUserOfficeForUserCreation(deptCodes: string, distCodes: string) {
        var param = new Dictionary<any>();
        param.Add("userCode", this.loginUserCode);
        param.Add("deptCodes", deptCodes);
        param.Add("distCodes", distCodes);
        return this._baseService.get(AppSetting.GetUserOfficeForUserCreationUrl, param);
    }

    GetVenderOfficeList(departmentCode: any, districtCode: any) {
        return this._baseService.get(AppSetting.GetVenderOfficeDDlUrl + "?userCode=" + this.loginUserCode + "&departmentCode=" + departmentCode + "&districtCode=" + districtCode);
    }

    GetVenderOfficeHavingStaffList(departmentCode: any, districtCode: any) {
        return this._baseService.get(AppSetting.GetUserOfficeHavingStaffDDlUrl + "?userCode=" + this.loginUserCode + "&departmentCode=" + departmentCode + "&districtCode=" + districtCode);
    }
    GetAdminLoginUserCreationList(model: IndexModel) {
        return this._baseService.post(AppSetting.AdminLoginUserCreationListUrl, model);
    }

    ExportUserData(model: IndexModel) {
        return this._baseService.post(AppSetting.ExportUserDataUrl, model);
    }

    GetVendorUserList(officeCode: number, model: IndexModel) {
        return this._baseService.post(AppSetting.VendorUserListUrl + "?loginUserCode=" + this.loginUserCode + "&officeCode=" + officeCode, model);
    }

    GetOfficeStaffPDFDownload(officeCode: number, model: IndexModel) {
        return this._baseService.post(AppSetting.OfficeStaffPDFDownloadUrl + "?loginUserCode=" + this.loginUserCode + "&officeCode=" + officeCode, model);
    }

    GetUserDepartment(userId: number) {
        return this._baseService.get(AppSetting.UserDepartmentUrl + userId);
    }

    AddUpdateUserCreation(model: UserCreationModel) {
        return this._baseService.post(AppSetting.B2GUserCreationUrl, model);
    }

    AddUpdateLoginUserCreation(id: number, model: UserCreationViewModel) {
        return this._baseService.post(AppSetting.LoginUserCreationAddUpdateUrl, model);
    }

    //#region SMS
    GetUseForNotification(model: UserNotificationFilterModel) {
        return this._baseService.post(AppSetting.GetUseForNotificationUrl, model);
    }

    SendNotificationToUser(model: UserNotificationModel) {
        return this._baseService.post(AppSetting.SendNotificationToUserUrl, model);
    }

    GetGeneralEntrySubCategory(userCode: number, categoryCode: number = 0) {
        var param = new Dictionary<any>();
        param.Add("id", userCode);
        param.Add("categoryCode", categoryCode);
        return this._baseService.get(AppSetting.UserGeneralEntrySubCategoryUrl, param);
    }

    //#endregion

    //#region Import excel data
    ImportExcelUserAndOffice(model: UserExportModel) {
        return this._baseService.post(AppSetting.ImportExcelUserAndOffice, model);
    }
    //#endregion

    GetUserListForReport(model: IndexModel) {
        return this._baseService.post(AppSetting.ReportUserListUrl, model);
    }

    GetVendorUserSearchList(model: VendorUserSearchModel) {
        model.LoginUserCode = this._authenticationService.LoggedInUserDetail.UserViewModel.UserCode;
        return this._baseService.post(AppSetting.GetVendorUserBySearchUrl, model);
    }

    GetApproverUsers(officeCode: number) {
        var params = new Dictionary<any>();
        params.Add("id", officeCode);
        return this._baseService.get(AppSetting.GetApproverUsersUrl, params);
    }

    SelfRegister(model: UserCreationModel) {
        return this._baseService.post(AppSetting.SelfRegisterUrl, model);
    }

    AddUpdateAdditionalType(model: UserAdditionalUserTypePostModel) {
        return this._baseService.post(AppSetting.AddUpdateAdditionalTypeUrl, model);
    }

    GetAdditionalUserTypeById(Id) {
        var params = new Dictionary<any>();
        params.Add("id", Id);
        return this._baseService.get(AppSetting.GetAdditionalUserTypeByIdUrl, params);
    }

    UpdateCurrentUserType(Id?: number) {
        var params = new Dictionary<any>();
        params.Add("id", Id);
        return this._baseService.get(AppSetting.UpdateCurrentUserTypeUrl, Id > 0 ? params : undefined);
    }
}
