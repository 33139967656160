import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { AuthenticationService } from 'src/app/shared/service/authentication.service';
import { GlobalMessagesModel } from 'src/app/shared/model/common-messages.model';
import { AlertService } from 'src/app/shared/service/alert.service';
import { isNullOrUndefined } from 'util';
import { MAT_DIALOG_DATA, MatDialogRef, MatRadioChange } from '@angular/material';
import { UserTypeEnum, StatusEnum } from 'src/app/shared/enum/fixed-values.enum';
import { CommonService } from 'src/app/shared/service/common.service';
import { ActionModel, ActionDocumentModel } from '../../../shared/model/action.model';
import { ActionService } from '../../../shared/service/action.service';
import { DdlItemModel, DDLModel } from '../../../shared/model/commonddl.model';
import { AppSetting } from 'src/app/shared/model/appsetting.model';
import { AngularEditorConfig } from '@kolkov/angular-editor';

@Component({
  selector: 'app-vendor-action-dialog',
  templateUrl: './vendor-action-dialog.component.html',
  styleUrls: ['./vendor-action-dialog.component.scss']
})
export class VendorActionDialogComponent implements OnInit {

  //#region <<Variable>>
  loginUserCode: number;
  frmGrp: FormGroup;
  model: ActionModel;
  CanReturn: number = 0;
  CurrentStatus: string;
  fileValidationMsg: string;
  tempDocumentList: ActionDocumentModel[] = [];
  ddlStatus: DdlItemModel[] = [];
  ddlList: DDLModel;
  disabled: boolean = true;
  editorConfig = AppSetting.editorConfig as AngularEditorConfig;
  IsReturn: boolean = false;
  IsAccept: boolean = false;
  IsReject: boolean = false;
  IsDisposed: boolean = false;
  IsClose: boolean = false;

  //#endregion

  //#region <<Constructor>>
  constructor(private readonly fb: FormBuilder,
    public readonly _commonService: CommonService,
    private readonly _alertService: AlertService,
    private _route: ActivatedRoute,
    private readonly _actionService: ActionService,
    private readonly _authService: AuthenticationService,
    public _dialogRef: MatDialogRef<VendorActionDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.model = new ActionModel();
    if (data) {
      if (data.Id) {
        this.model.CommunicationCode = data.Id;
        this.CanReturn = data.CanReturn;
        this.CurrentStatus = data.Status;
      }
    }
    // this.model.Status = StatusEnum.ACCEPT;
    this.loginUserCode = this._authService.UserCode;
    this.bindStatus();
    this.GetDDLList();
  }
  //#endregion

  ngOnInit() {
    this.formGroupInit();
  }

  bindStatus() {
    var item1: DdlItemModel = new DdlItemModel();
    item1.Text = StatusEnum.ACCEPT;
    item1.Value = StatusEnum.ACCEPT;
    this.ddlStatus.push(item1);

    var item2: DdlItemModel = new DdlItemModel();
    item2.Text = StatusEnum.REJECT;
    item2.Value = StatusEnum.REJECT;
    this.ddlStatus.push(item2);

    if (this.CanReturn == 1) {
      var item3: DdlItemModel = new DdlItemModel();
      item3.Text = StatusEnum.RETURN;
      item3.Value = StatusEnum.RETURN;
      this.ddlStatus.push(item3);
    }

    var item4: DdlItemModel = new DdlItemModel();
    item4.Text = StatusEnum.DISPOSED;
    item4.Value = StatusEnum.DISPOSED;
    this.ddlStatus.push(item4);

    var item4: DdlItemModel = new DdlItemModel();
    item4.Text = StatusEnum.CLOSE;
    item4.Value = StatusEnum.CLOSE;
    this.ddlStatus.push(item4);
  }

  formGroupInit() {
    this.frmGrp = this.fb.group({
      Reply: [null, Validators.required],
      Status: [null, Validators.required],
      ReasonCode: [null]
    });
  }


  saveClick() {
    this.frmGrp.markAllAsTouched();
    if (this.frmGrp.valid) {
      this.model.ActionCode = 0;
      this.model.CreatedBy = this.loginUserCode;
      this.model.DocumentList = this.tempDocumentList;
      this._actionService.AddUpdate(this.model).subscribe(
        data => {
          if (data) {
            if (data.IsSuccess) {
              this._alertService.success('Communication ' + ((this.model.Status == StatusEnum.ACCEPT) ? 'accepted' : this.model.Status.toLowerCase()) + ' successfully.');
              this._dialogRef.close(true);
            }
            else {
              this._alertService.error('Failed to update status.');
            }
          }
          else {
            this._alertService.error(GlobalMessagesModel.InternalError);
          }
        },
        error => {
          this._alertService.error(error.message);
        }
      );
    }
  }

  CloseDialog(): void {
    this._dialogRef.close();
  }

  handleFileInput(files: FileList) {
    this.tempDocumentList = [];

    for (let index = 0; index < files.length; index++) {
      if (files.item(index).type.match("application/pdf")) {
        if (files.item(index).size < AppSetting.FileSizeLimit.PDF) {
          const reader = new FileReader();
          reader.onload = (e: any) => {
            this.tempDocumentList.push(new ActionDocumentModel());
            this.tempDocumentList[index].DocName = <string>reader.result;
            this.tempDocumentList[index].DocPath = <string>reader.result;

            this.tempDocumentList[index].DocName = files[index].name;
            this.tempDocumentList[index].Extension = files[index].name.split(".")[1];
            this.tempDocumentList[index].DocPath = <string>reader.result;
          };
          reader.readAsDataURL(files[index]);
        }
        else {
          this.tempDocumentList = [];
          this.fileValidationMsg = AppSetting.FileSizeLimit.PDFErrorMSG;
          return;
        }
      }
      else {
        this.tempDocumentList = [];
        this.fileValidationMsg = "Only *pdf";
        return;
      }
    }
    this.fileValidationMsg = "";
  }

  downloadPdf(Url, name) {
    const linkSource = Url;
    const downloadLink = document.createElement("a");
    const fileName = name;

    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.target = "blank";
    downloadLink.click();
  }

  deleteDocument(documentCode: number, index: number) {
    this.tempDocumentList.splice(index, 1);
  }

  checkStatusType() {
    this.IsReturn = false;
    this.IsAccept = false;
    this.IsReject = false;
    this.IsDisposed = false;
    this.IsClose = false;

    if (this.model.Status == StatusEnum.ACCEPT ) {
      this.IsAccept = true;
    }
    else if (this.model.Status == StatusEnum.REJECT) {
      this.IsReject = true;
    }
    else if (this.model.Status == StatusEnum.RETURN) {
      this.IsReturn = true;
    }
    else if (this.model.Status == StatusEnum.DISPOSED) {
      this.IsDisposed = true;
    }
    else if (this.model.Status == StatusEnum.CLOSE) {
      this.IsClose = true;
    }
    else {
      this.IsReturn = false;
      this.IsAccept = false;
      this.IsReject = false;
      this.IsDisposed = false;
      this.IsClose = false;
    }

  }

  GetDDLList() {
    this._commonService.GetAllDDL(AppSetting.CommunicationActionDropdownKeys).subscribe(
      data => {
        if (data.IsSuccess) {
          this.ddlList = <DDLModel>data.Data;
        }
      },
      error => {
        this._alertService.error(error.message);
      }
    );
  }

}
