import { Component, OnInit, ViewChild } from "@angular/core";
import { AppComponent } from "src/app/app.component";
import { AlertService } from "src/app/shared/service/alert.service";
import { MatDialog, MatTableDataSource, MatSort, MatPaginator } from "@angular/material";
import { Router } from "@angular/router";
import { CommonService } from "src/app/shared/service/common.service";
import { AuthenticationService } from "src/app/shared/service/authentication.service";
import { PermissionModel } from "src/app/shared/model/common.model";
import { ConfirmationDialogComponent } from "src/app/confirmation-dialog/confirmation-dialog.component";
import { ColumnHeaderModel, DDLModel, DdlItemModel } from "src/app/shared/model/commonddl.model";
import { UserTypeEnum } from "src/app/shared/enum/fixed-values.enum";
import { GlobalMessagesModel } from 'src/app/shared/model/common-messages.model';
import { AppSetting } from 'src/app/shared/model/appsetting.model';
import { UserDetailDialogComponent } from "../user/user-detail-dialog/user-detail-dialog.component";
import { UserService } from "src/app/shared/service/user.service";
import { UserCreationViewModel, UserViewModel, UserCustomSearchModel } from "src/app/shared/model/user.model";
import { UserTypeService } from 'src/app/shared/service/user-type.service';
import { UserTypeModel } from 'src/app/shared/model/user-type.model';
import { UserSpecificPermissionDialogComponent } from "../master/permission/user-specific-permission-dialog/user-specific-permission-dialog.component";
import { UserPermissionService } from "src/app/shared/service/user-permission.service";
import { AddUpdateAdditionalUserTypeDialogComponent } from "./add-update-additional-user-type-dialog/add-update-additional-user-type-dialog.component";

@Component({
  selector: "app-user-creation",
  templateUrl: "./user-creation.component.html",
  styleUrls: ["./user-creation.component.scss"],
})

export class UserCreationComponent implements OnInit {

  model: UserCreationViewModel[];
  dataSource: any;
  totalRecords: number;
  loginBaseUserType: string;
  Permission: PermissionModel = this._authService.GetPagePermission("/master/user-master", "user-master/add", "user-master/detail", "user-master/update", "user-master/delete");
  locateUserPermission: PermissionModel = this._authService.GetPagePermission("/locate-user");

  displayedColumns: string[] = ["index", "Name", "UserTypeTitle", "DefaultDetail", "SpecificPermissionCount",
    "CreatedByUserName", "ModifiedByUserName", "IsActive", "Action"];
  columnsToDisplay: string[] = this.displayedColumns.slice();

  ViewdisplayedColumns: ColumnHeaderModel[] = [
    { Value: "UserTypeTitle", Text: "User Type" }
  ];
  userType: string | number;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  UserTypeEnum = UserTypeEnum;
  loginUserCode: number = 0;
  isShow = true;
  ddlList = new DDLModel();
  loginData: UserViewModel;
  fromDate: Date | string;//= new Date("12/17/2018");
  toDate: Date | string;//= new Date();
  searchModel = this._commonService.modelSetGet(new UserCustomSearchModel()) as UserCustomSearchModel;
  ddlAdminDepartment: DdlItemModel[] = [];
  ddlUserType: DdlItemModel[] = [];
  userTypeList: UserTypeModel[] = [];

  constructor(
    private readonly appComponnet: AppComponent,
    private readonly _alertService: AlertService,
    private readonly _dialog: MatDialog,
    private readonly _router: Router,
    public readonly _commonService: CommonService,
    private readonly _authService: AuthenticationService,
    private readonly _userService: UserService,
    private readonly _userTypeService: UserTypeService,
    private readonly _userPermissionService: UserPermissionService
  ) {
    this.fromDate = new Date();
    this.toDate = new Date();
    this.Permission.AddPageAccess ? this.appComponnet.setPageLayout("User Master:", "add", "Create", "user-master/add") : this.appComponnet.setPageLayout("User Master:");
    this.loginUserCode = this._authService.LoggedInUserDetail.UserViewModel.UserCode;
    this.loginBaseUserType = this._authService.LoggedInUserDetail.UserViewModel.BaseUserType;
    this.searchModel.indexModel.OrderBy = "CreatedOn";
    this.searchModel.indexModel.OrderByAsc = 0;
    this.getDDLList();
    this.GetADLCUserCategory();
  }

  ngOnInit() {
    this.getList();

    this.getAdminDepartment();
    this.GetDepartment();
    this.GetDistrict();
    this.getDownLevelUserType();
  }

  getOfficeList() {
    this.ddlList.ddlOffice = [];
    let deptCodes = this.searchModel.DepartmentCode ? this.searchModel.DepartmentCode.toString() : '0';
    let distCodes = this.searchModel.DistrictCode ? this.searchModel.DistrictCode.toString() : '0';
    this._userService.GetUserOfficeForUserCreation(deptCodes, distCodes).subscribe((data) => {
      if (data.IsSuccess) {
        this.ddlList.ddlOffice = <DdlItemModel[]>data.Data;
      }
    }, (error) => {
      this._alertService.error(error.message);
    });
  }

  getDownLevelUserType() {
    this._userTypeService.GetDownLevelUserType(this.loginBaseUserType).subscribe(
      (data) => {
        if (data.IsSuccess) {
          this.ddlUserType = [];
          this.userTypeList = <UserTypeModel[]>data.Data;
          this.userTypeList.forEach((element) => {
            this.ddlUserType.push({
              Value: element.UserType,
              Text: element.UserType + " (" + element.UserTypeTitle + ")",
            });
          });
          //this.RebindField();
        }
      },
      (error) => {
        this._alertService.error(error.message);
      }
    );
  }

  getAdminDepartment() {
    this.ddlAdminDepartment = [];
    this._userService.GetUserAdminDepartmentForUserOfficeCreation(true).subscribe((data) => {
      if (data.IsSuccess) {
        this.ddlAdminDepartment = <DdlItemModel[]>data.Data;
      }
    }, (error) => {
      this._alertService.error(error.message);
    });
  }

  getList() {

    //#region Filter Search
    if (this.fromDate) {
      const uTCFromDate = new Date(Date.UTC(new Date(this.fromDate).getFullYear(), new Date(this.fromDate).getMonth(), new Date(this.fromDate).getDate()));
      this.fromDate = uTCFromDate;
    }

    if (this.toDate) {
      const uTCToDate = new Date(Date.UTC(new Date(this.toDate).getFullYear(), new Date(this.toDate).getMonth(), new Date(this.toDate).getDate()));
      this.toDate = uTCToDate;
    }

    if (this.fromDate || this.toDate) {
      this.searchModel.indexModel.AdvanceSearchModel = {
        FromDate: this.fromDate,
        ToDate: this.toDate,
      };
    }

    this.searchModel.CreatedFrom = this.fromDate;
    this.searchModel.CreatedTo = this.toDate;
    this.searchModel.indexModel.AdvanceSearchModel = {
      Name: this.searchModel.Name,
      Ssoid: this.searchModel.Ssoid,
      AdminDepartmentCode: this.searchModel.AdminDepartmentCode,
      UserTypeCode: this.searchModel.UserType,
      DepartmentCode: this.searchModel.DepartmentCode,
      OfficeCode: this.searchModel.OfficeCode,
      DesignationCode: this.searchModel.DesignationCode,
      DistrictCode: this.searchModel.DistrictCode,
      Email: this.searchModel.Email,
      ActiveView: this.searchModel.ActiveView,
      IsUpdateProfile: this.searchModel.IsUpdateProfile,
      CreatedFrom: this.searchModel.CreatedFrom,
      CreatedTo: this.searchModel.CreatedTo,
      FromDate: this.fromDate,
      ToDate: this.toDate,
      IsShowUserWithSpecificPermission: this.searchModel.IsShowUserWithSpecificPermission,
      UserTypeCategory: this.searchModel.UserTypeCategory
    };
    this.searchModel = this._commonService.modelSetGet(this.searchModel, true);

    //#endregion
    if (this.searchModel.IsExportToExcel) {
      this.exportToCSV();
    }
    else {
      this._userService.GetAdminLoginUserCreationList(this.searchModel.indexModel).subscribe((data) => {
        if (data.IsSuccess) {

          this.model = <UserCreationViewModel[]>data.Data.Data;
          this.dataSource = new MatTableDataSource<UserCreationViewModel>(this.model);
          // this.totalRecords = data.Data.TotalRecords;
          // this.dataSource.paginator = this.paginator;
          // this.dataSource.sort = this.sort;
          this.totalRecords = data.Data.TotalRecords;
          //  if (!this.searchModel.indexModel.IsPostBack) {
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
          // }
        } else {
          this._alertService.error(GlobalMessagesModel.DataRetrieveIssue);
        }
      }, (error) => {
        this._alertService.error(GlobalMessagesModel.DataRetrieveIssue);
      });
    }
  }

  updateActiveStatus(id) {
    const dialogRef = this._dialog.open(ConfirmationDialogComponent, {
      // width: "50%",
      data: GlobalMessagesModel.ConfirmStatusChanged,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this._userService.ChangeActiveStatus(id).subscribe(
          (data) => {
            if (data.IsSuccess) {
              this._alertService.success(data.Message);
              this.getList();
            }
          },
          (error) => {
            this._alertService.error(error.message);
          }
        );
      }
    });
  }

  updateDeleteStatus(id) {
    const dialogRef = this._dialog.open(ConfirmationDialogComponent, {
      width: "50%",
      data: GlobalMessagesModel.ConfirmDeleted,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this._userService.ChangeDeleteStatus(id).subscribe(
          (data) => {
            if (data.IsSuccess) {
              this._alertService.success(data.Message);
              this._commonService.ScrollingTop();
              this.getList();
            }
          },
          (error) => {
            this._alertService.error(GlobalMessagesModel.DataRetrieveIssue);
          }
        );
      }
    });
  }

  sortData(event) {
    this.searchModel.indexModel.OrderBy = event.active;
    this.searchModel.indexModel.OrderByAsc =
      event.direction == AppSetting.orderByDscAsc
        ? AppSetting.orderByAsc
        : AppSetting.orderByDsc;
    this.searchModel.indexModel.IsPostBack = true;
    this.getList();
  }

  onPaginateChange(event) {
    this.searchModel.indexModel.Page = event.pageIndex + 1;
    this.searchModel.indexModel.PageSize = event.pageSize;
    this.searchModel.indexModel.IsPostBack = true;
    this.getList();
  }

  ClearSearch() {
    this.searchModel = new UserCustomSearchModel();
    this.fromDate = '';
    this.toDate = '';
    this.getList();
  }

  toggleDisplay() {
    this.isShow = !this.isShow;
  }

  GetDepartment() {
    this.ddlList.ddlOfficeDepartment = [];
    this._userService.loginUserCode = this.loginUserCode;
    let adminDeptCodes = this.searchModel.AdminDepartmentCode ? this.searchModel.AdminDepartmentCode : 0;
    this._userService.GetUserDepartmentForUserOfficeCreation(adminDeptCodes.toString(), true).subscribe(data => {
      if (data.IsSuccess) {
        this.ddlList.ddlOfficeDepartment = <DdlItemModel[]>data.Data;
      }
    }, error => {
      this._alertService.error(error.message);
    });
  }

  GetDistrict() {
    this.ddlList.ddlDistrict = [];
    this._userService.loginUserCode = this.loginUserCode;
    this._userService.GetUserDistrictForUserOfficeCreation('20', '0', true).subscribe(data => {
      if (data.IsSuccess) {
        this.ddlList.ddlDistrict = <DdlItemModel[]>data.Data;
      }
    }, error => {
      this._alertService.error(error.message);
    });
  }

  getDDLList() {
    this._commonService.GetAllDDL(AppSetting.DDlKeyForUserList).subscribe((data) => {
      if (data.IsSuccess) {
        var ddlData = <DDLModel>data.Data;
        this.ddlList.ddlDesignation = ddlData.ddlDesignation;
      }
    }, (error) => {
      this._alertService.error(error.message);
    });
  }

  GetADLCUserCategory() {
    this._commonService.GetAllDDL(AppSetting.DDlADLCUserCategoryList).subscribe((data) => {
      if (data.IsSuccess) {

        var ddlData = <DDLModel>data.Data;
        this.ddlList.ddlADLCUserCategory = ddlData.ddlADLCUserCategory;
      }
    }, (error) => {
      this._alertService.error(error.message);
    });
  }

  OpenUserDetailDialog(Id) {
    const _dialogRef = this._dialog.open(UserDetailDialogComponent, {
      width: "1000px",
      data: { Id },
      disableClose: true
    });
  }

  showSpecificPermissionDialog(userId: number) {
    this._dialog.open(UserSpecificPermissionDialogComponent, {
      width: "800px",
      data: userId,
    });
  }

  resetUserSpecificPermission(userId: number) {
    this._userPermissionService.ResetUserSpecificPermission(userId).subscribe((data) => {
      if (data.IsSuccess) {
        this.getList();
        this._alertService.success(data.Message);
      }
    }, (error) => {
      this._alertService.error(error.message);
    });
  }

  downloadCsv() {

    this.searchModel.IsExportToExcel = true;
    this.getList();
  }

  exportToCSV() {

    this._userService.ExportUserData(this.searchModel.indexModel).subscribe((data) => {
      if (data.IsSuccess) {

        const downloadLink = document.createElement("a");
        downloadLink.href = data.Data;
        downloadLink.download = "User-Report";
        downloadLink.target = "blank";
        downloadLink.click();
      } else {
        this._alertService.error(data.Message);
      }
      this.searchModel.IsExportToExcel = false;
    }, (error) => {
      this._alertService.error(error.message);
    });
  }


  onAdditionalRolepermission(id) {
    const dialogRef = this._dialog.open(AddUpdateAdditionalUserTypeDialogComponent, {
      width: "50%",
      data: { Id: id },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this._alertService.success(GlobalMessagesModel.RoleSavedSuccess);

      }
    });
  }

}
