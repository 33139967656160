import { Injectable } from "@angular/core";
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Observable } from "rxjs";
import { finalize, tap } from "rxjs/operators";
import { LoaderService } from '../shared/service/loader.service';
import { ActivatedRoute, ActivatedRouteSnapshot, Router } from "@angular/router";
import { AuthenticationService } from "../shared/service/authentication.service";
import { CommonService } from '../shared/service/common.service';

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {
  private currentRequests = 0;
  private totalRequest = 0;
  constructor(public loaderService: LoaderService,
    public router: Router, private _commonService: CommonService,
    public _activeRouter: ActivatedRoute,
    public _authenticationService: AuthenticationService) {
  }
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.loaderService.show();
    this.currentRequests++;
    this.totalRequest++;

    let token = this._commonService.GetLocalStorage("Token") != null ? this._commonService.GetLocalStorage("Token") : null;
    let userCode = this._commonService.GetLocalStorage("UserCode") != null ? this._commonService.GetLocalStorage("UserCode") : null;
    let userTypeCode = this._commonService.GetLocalStorage("UserTypeCode") != null ? this._commonService.GetLocalStorage("UserTypeCode") : null;
    let userType = this._commonService.GetLocalStorage("UserType") != null ? this._commonService.GetLocalStorage("UserType") : null;
    let baseUserType = this._commonService.GetLocalStorage("BaseUserType") != null ? this._commonService.GetLocalStorage("BaseUserType") : null;
    let ssoId = this._commonService.GetLocalStorage("SSOID") != null ? this._commonService.GetLocalStorage("SSOID") : null;
    let name = this._commonService.GetLocalStorage("Name") != null ? this._commonService.GetLocalStorage("Name") : null;
    let currentUrl = this.router.url;

    if (token != null) {
      req = req.clone({
        setHeaders: {
          Authorization: token,
          UserCode: userCode,
          UserTypeCode: userTypeCode,
          UserType: userType,
          BaseUserType: baseUserType,
          SSOID: ssoId,
          Name: name,
          PageUrl: currentUrl
        }
      });
    } else {
      req = req.clone({
        setHeaders: {}
      });

    }

    return next.handle(req).pipe(
      tap(() => { },
        (err: any) => {
          if (err instanceof HttpErrorResponse) {
            if (err.status !== 401) {
              return;
            }
            this._authenticationService.LogOut();
          }
        }),

      finalize(() => {
        this.loaderService.CompletedProcess.next(Math.round(100 - (this.currentRequests / this.totalRequest) * 100));
        this.currentRequests--;
        this.loaderService.hide();
        if (this.currentRequests === 0) {
          this.loaderService.CompletedProcess.next(100);
          this.totalRequest = 0;
          this.loaderService.hideProcess();

        }
      })
    );

  }
}
