import { TitleCasePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatSort, MatDialog, MatTableDataSource } from '@angular/material';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { AppComponent } from 'src/app/app.component';
import { UserTypeEnum, StatusEnum } from 'src/app/shared/enum/fixed-values.enum';
import { AppSetting } from 'src/app/shared/model/appsetting.model';
import { PermissionModel } from 'src/app/shared/model/common.model';
import { DdlItemModel, DDLModel, ColumnHeaderModel } from 'src/app/shared/model/commonddl.model';
import { CommunicationModel, CommunicationSearchModel } from 'src/app/shared/model/communication.model';
import { DashboardDrilDownModel } from 'src/app/shared/model/dashboard.model';
import { StatusMasterModel } from 'src/app/shared/model/master/status-master.model';
import { UserViewModel } from 'src/app/shared/model/user.model';
import { AlertService } from 'src/app/shared/service/alert.service';
import { AuthenticationService } from 'src/app/shared/service/authentication.service';
import { CommonService } from 'src/app/shared/service/common.service';
import { CommunicationService } from 'src/app/shared/service/communication.service';
import { isNullOrUndefined } from 'util';
@Component({
  selector: 'app-dashboard-drill-down',
  templateUrl: './dashboard-drill-down.component.html',
  styleUrls: ['./dashboard-drill-down.component.scss']
})
export class DashboardDrillDownComponent implements OnInit {

  model: CommunicationModel[] = [];
  userTypeEnum = UserTypeEnum;
  dataSource: any;
  totalRecords: number;
  ddlStatus: DdlItemModel[] = [];
  status: string = '';
  search: string = '';
  statusPara: string;
  ddlList: DDLModel;
  communicationTypeCode: string;
  moduleCode: string;
  communicationStatusEnum = StatusEnum;
  IsAddEdit = false;
  Permission: PermissionModel = this._authService.GetPagePermission("/communications-list", "/communications-list/create", "/communications-list/detail", "/communications-list/update", "/communications-list/delete");
  isShow = true;
  drilldownhModel = this._commonService.modelSetGet(new DashboardDrilDownModel(), false) as DashboardDrilDownModel;
  fromDate: Date | string;
  toDate: Date | string;
  ddlDistrict: DdlItemModel[] = [];
  loginUserDetail: UserViewModel;
  IsCreatedByVendor: string = "";
  modelStatus: StatusMasterModel;
  ageFrom: number = 0;
  ageTo: number = 0;
  filterDesc: string;
  communicationType: string;

  routerURL: string;


  displayedColumns: string[] = ["index", "Subject", "DepartmentName", "OfficeName", "CommunicationType", "RefNo", "LastActionDate", "Status", "Action"];

  columnsToDisplay: string[] = this.displayedColumns.slice();
  ViewdisplayedColumns: ColumnHeaderModel[] = [
    { Value: "Subject", Text: "Subject" },
    { Value: "DepartmentName", Text: "Department" },
    { Value: "OfficeName", Text: "Office" },
    { Value: "CommunicationType", Text: "Communication Type" },
    { Value: "RefNo", Text: "References No." },
    { Value: "LastActionDate", Text: "Date of Action" },
    { Value: "Status", Text: "Status" },
  ];

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  isShowUserManual = true;
  reportType: string;
  para1: string;
  para2: string;
  para3: string;
  para4: string;

  constructor(private readonly appComponnet: AppComponent,
    private readonly _alertService: AlertService,
    private _route: ActivatedRoute,
    private readonly _communicationService: CommunicationService,
    public readonly _commonService: CommonService,
    private readonly _authService: AuthenticationService, public titleCasePipe: TitleCasePipe,
  ) {
    let params = this._route.snapshot.params;
    if (params) {
      this.reportType = params.type;
      this.para1 = params.para1 ? params.para1 : '0';
      this.para2 = params.para2 ? params.para2 : '0';
      this.para3 = params.para3 ? params.para3 : '0';
      this.para4 = params.para4 ? params.para4 : '0';
    }
    this.setLayout();
  }

  ngOnInit() {

    this._route.paramMap.subscribe((params: ParamMap) => {
      if (this.reportType) {
        this.GetCommunicationDrilldownList();
      }
    });
  }

  GetCommunicationDrilldownList() {
    this.drilldownhModel.ReportType = this.reportType;
    this.drilldownhModel.Para1 = this.para1;
    this.drilldownhModel.Para2 = this.para2;
    this.drilldownhModel.Para3 = this.para3;
    this.drilldownhModel.Para4 = this.para4;

    this._communicationService.GetCommunicationDrilldownList(this._commonService.modelSetGet(this.drilldownhModel, true)).subscribe((data) => {
      if (data.IsSuccess) {
        this.model = <CommunicationModel[]>data.Data.Data ? data.Data.Data : [];

        this.dataSource = new MatTableDataSource<CommunicationModel>(this.model);
        this.totalRecords = data.Data.TotalRecords;
        if (!this.drilldownhModel.indexModel.IsPostBack) {
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
        }
      }
      else {
        this._alertService.error(data.Message);
      }
    }, (error) => {
      this._alertService.error(error.message);
    });
  }

  setLayout() {

    switch (this.reportType) {
      case 'AgeWiseStatusCount':
        if (Number(this.para3) > 0) {
          this.appComponnet.setPageLayout(this.Getpageheading() + ":", "keyboard_backspace", "Back To Dashboard", "/dashboard", false, true);
          this.filterDesc = "Status: " + this.para1 + ", Age: " + this.para2 + "-" + this.para3 + " Days";
        }
        else {
          this.appComponnet.setPageLayout(this.Getpageheading() + ":", "keyboard_backspace", "Back To Dashboard", "/dashboard", false, true);
          this.filterDesc = "Status: " + this.para1 + ", Age: >" + this.para2 + " Days";
        }
        break;
      case 'AgeWiseCommunicationCount':
      case 'AgeWiseCommunicationCountNoActionTaken':
      case 'AgeWiseDepartmentWiseCount':
      case 'AgeWiseVendorWiseCount':
        if (Number(this.para3) > 0) {
          this.appComponnet.setPageLayout(this.Getpageheading() + ":", "keyboard_backspace", "Back To Dashboard", "/dashboard", false, true);
          this.filterDesc = "CommunicationType: " + this.para1 + ", Age: " + this.para2 + "-" + this.para3 + " Days";
        }
        else {
          this.appComponnet.setPageLayout(this.Getpageheading() + ":", "keyboard_backspace", "Back To Dashboard", "/dashboard", false, true);
          this.filterDesc = "CommunicationType: " + this.para1 + ", Age: >" + this.para2 + " Days";
        }
        break;

      case 'ProjectAssignmentWise':
        if (Number(this.para3) > 0) {
          this.appComponnet.setPageLayout(this.Getpageheading() + ":", "keyboard_backspace", "Back To Dashboard", "/dashboard", false, true);
          this.filterDesc = "Age: " + this.para2 + "-" + this.para3 + " Days";
        }
        else {
          this.appComponnet.setPageLayout(this.Getpageheading() + ":", "keyboard_backspace", "Back To Dashboard", "/dashboard", false, true);
          this.filterDesc = "Age: >" + this.para2 + " Days";
        }
        break;
        break;

      default:
        this.appComponnet.setPageLayout(this.Getpageheading(), "keyboard_backspace", "Back To Dashboard", "/dashboard", false, true);
        break;
    }
  }

  Getpageheading(): string {
    let pageTitle;
    switch (this.reportType) {

      case 'AgeWiseStatusCount':
        pageTitle = "Age status wise commication List";
        break;

      case 'AgeWiseCommunicationCount':
        pageTitle = "Age status wise commication List";
        break;

      case 'AgeWiseCommunicationCountNoActionTaken':
        pageTitle = "Age Wise Communication (No Action Taken) List";
        break;

      case 'AgeWiseDepartmentWiseCount':
        pageTitle = "Age Wise Department Wise Count";
        break;

      case 'ProjectAssignmentWise':
        pageTitle = "Project Assignment Wise";
        break;

      case 'AgeWiseVendorWiseCount':
        pageTitle = "Age Wise Vendor Wise Count";
        break;

      default:
        pageTitle = "Commication List";
        break;
    }
    return pageTitle;
  }

  PagePrint(printContent) {
    this._commonService.printHTMLContent(printContent, this.Getpageheading());
  }

  sortData(event) {
    this.drilldownhModel.indexModel.OrderBy = event.active;
    this.drilldownhModel.indexModel.OrderByAsc = event.direction == AppSetting.orderByDscAsc ? AppSetting.orderByAsc : AppSetting.orderByDsc;
    this.drilldownhModel.indexModel.IsPostBack = true;
    this.GetCommunicationDrilldownList();
  }

  onPaginateChange(event) {
    this.drilldownhModel.indexModel.Page = event.pageIndex + 1;
    this.drilldownhModel.indexModel.PageSize = event.pageSize;
    this.drilldownhModel.indexModel.IsPostBack = true;
    this.GetCommunicationDrilldownList();
  }
}