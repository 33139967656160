import { Injectable } from '@angular/core';
import { BaseService } from '../base.service';
import { AppSetting } from '../../model/appsetting.model';
import { IndexModel } from '../../model/common.model';
import { VendorTypeModel } from '../../model/master/vendor-type.model';

@Injectable({
    providedIn: 'root'
})

export class VendorTypeService {

    constructor(private readonly _baseService: BaseService,) { }

    GetList(model: IndexModel) {
        return this._baseService.post(AppSetting.VendorTypeListUrl, model);
    }

    GetPDFDownload(model: IndexModel) {
        return this._baseService.post(AppSetting.PDFDownloadUrl, model);
    }


    AddUpdate(id: number, model: VendorTypeModel) {
        return this._baseService.post(AppSetting.VendorTypeAddUpdateUrl, model);
    }

    Detail(id: number) {
        return this._baseService.get(AppSetting.VendorTypeDetailUrl + id);
    }

    ChangeDeleteStatus(id: number) {
        return this._baseService.get(AppSetting.VendorTypeDeleteStatusChangeUrl + id);
    }

    ChangeActiveStatus(id: number) {
        return this._baseService.get(AppSetting.VendorTypeActiveStatusChangeUrl + id);
    }

    IsVendorTypeExist(vendorType: string) {
        return this._baseService.get(AppSetting.IsVendorTypeExistUrl + vendorType);
    }

}
