import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource, MatDialog, MatSort, MatPaginator } from "@angular/material";
import { GlobalFilterModel, IndexModel, PermissionModel } from 'src/app/shared/model/common.model';
import { ColumnHeaderModel } from 'src/app/shared/model/commonddl.model';
import { AppComponent } from 'src/app/app.component';
import { AlertService } from 'src/app/shared/service/alert.service';
import { CommonService } from 'src/app/shared/service/common.service';
import { ConfirmationDialogComponent } from 'src/app/confirmation-dialog/confirmation-dialog.component';
import { GlobalMessagesModel } from 'src/app/shared/model/common-messages.model';
import { AppSetting } from 'src/app/shared/model/appsetting.model';
import { OutboxService } from 'src/app/shared/service/outbox.service';
import { AuthenticationService } from 'src/app/shared/service/authentication.service';
import { StatusEnum, UserTypeEnum } from 'src/app/shared/enum/fixed-values.enum';
import { debug } from 'util';
import { OutboxModel } from 'src/app/shared/model/outbox.model';
import { DatePipe } from '@angular/common';
import { GlobalFilterSearchModel } from 'src/app/shared/model/Global-communication-search.model';
@Component({
  selector: 'app-outbox',
  templateUrl: './outbox.component.html',
  styleUrls: ['./outbox.component.scss']
})
export class OutboxComponent implements OnInit {
  model: OutboxModel[];
  //indexModel = this._commonService.modelSetGet(new IndexModel()) as IndexModel;
  dataSource: any;
  totalRecords: number;
  loginUserCode: number;
  loginBaseUserType: string;
  userTypeVNDR: string = UserTypeEnum.VNDR;
  
  statusEnum = StatusEnum;
  globalSearchModel = this._commonService.modelSetGet(new GlobalFilterSearchModel()) as GlobalFilterSearchModel;
  isShowFilter = false;


  displayedColumns: string[] = ["index", "Subject", "DepartmentName", "OfficeName", "DistrictName", "CommunicationType", "RefNo", "Vendor", "DisplayCreateDate", "Status", "InitiateBy", "Action"];
  //displayedColumns: string[] = ["index", "DepartmentName", "OfficeName","DistrictName","CommunicationType","Vendor","DisplayCreateDate", "Action"];
  columnsToDisplay: string[] = this.displayedColumns.slice();
  ViewdisplayedColumns: ColumnHeaderModel[] = [
    { Value: "Subject", Text: "Subject" },
    { Value: "DepartmentName", Text: "Department Name" },
    { Value: "OfficeName", Text: "Office Name" },
    { Value: "DistrictName", Text: "District Name" },
    { Value: "CommunicationType", Text: "Communication Type" },
    { Value: "RefNo", Text: "Reference No." }, 
    { Value: "Vendor", Text: "Vendor" },
    { Value: "DisplayCreateDate", Text: "Date" },
    { Value: "Status", Text: "Status" },
  ];
  SearchColumns: ColumnHeaderModel[] = [
    { Value: "DepartmentName", Text: "Department Name" },
    { Value: "OfficeName", Text: "Office Name" },
    { Value: "DistrictName", Text: "District Name" },
    { Value: "CommunicationType", Text: "Communication Type" },
    { Value: "RefNo", Text: "Reference No." },
    { Value: "Subject", Text: "Subject" },
    { Value: "Vendor", Text: "Vendor" },
    { Value: "Status", Text: "Status" },
  ];

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  Permission: PermissionModel = this._authService.GetPagePermission("/outbox", "outbox/add", "outbox/detail", "outbox/update", "outbox/delete", "communications-list/communication-detail/outbox", "communications-list/action-history", "/outbox/action");

  constructor(
    private readonly appComponnet: AppComponent,
    private readonly _alertService: AlertService,
    private readonly _outboxService: OutboxService,
    private readonly _dialog: MatDialog,
    public readonly _commonService: CommonService,
    private readonly _authService: AuthenticationService,
    public datepipe: DatePipe
  ) {

    this.loginUserCode = _authService.UserCode;
    this.loginBaseUserType = _authService.BaseUserType;
    this.appComponnet.setPageLayout("Outbox :", "", "", "");
  }

  ngOnInit() {
    this.GetGlobalSearchData();
  }

  
  GetGlobalSearchData() {
    this.globalSearchModel = this._commonService.modelSetGet(this.globalSearchModel, true)
    this._outboxService.GetListbySearchFilter(this.globalSearchModel).subscribe(
      data => {
        if (data.IsSuccess) {
          this.model = <OutboxModel[]>data.Data.Data;

          this.model.forEach(element => {
            element.DisplayCreateDate = (this.datepipe.transform(element.CreatedOn, 'dd/MM/yyyy')).toString();
          });
          this.dataSource = new MatTableDataSource<OutboxModel>(this.model);
          this.totalRecords = data.Data.TotalRecords;
          if (!this.globalSearchModel.indexModel.IsPostBack) {
            this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.sort;

          }
        } else {
          this._alertService.error(data.Message);
        }
      },
      error => {
        this._alertService.error(error.message);
      }
    );
  }
 
  sortData(event) {
    this.globalSearchModel.indexModel.OrderBy = event.active;
    this.globalSearchModel.indexModel.OrderByAsc = event.direction == AppSetting.orderByDscAsc ? AppSetting.orderByAsc : AppSetting.orderByDsc;
    this.globalSearchModel.indexModel.IsPostBack = true;
    this.GetGlobalSearchData();
  }

  onPaginateChange(event) {
    this.globalSearchModel.indexModel.Page = event.pageIndex + 1;
    this.globalSearchModel.indexModel.PageSize = event.pageSize;
    this.globalSearchModel.indexModel.IsPostBack = true;
    this.GetGlobalSearchData();
  }
  onSearchData(modelSearch) {

    this.globalSearchModel = modelSearch;
    this.GetGlobalSearchData();
  }
  getGlobalFilterCriteria() {

    if (this._commonService.GetLocalStorage("IsGlobalSearch") != null && JSON.parse(this._commonService.GetLocalStorage("IsGlobalSearch")) == 1 && this._commonService.GetLocalStorage("GlobalCommunicationFilterSearch")) {
      this.globalSearchModel = JSON.parse(this._commonService.GetLocalStorage("GlobalCommunicationFilterSearch")) as GlobalFilterSearchModel;

    }
    else {
      this.globalSearchModel = this._commonService.modelSetGet(new GlobalFilterSearchModel())


    }
  }
 
}