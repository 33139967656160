import { Component, OnInit, Inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { AlertService } from 'src/app/shared/service/alert.service';
import { UserViewModel } from 'src/app/shared/model/user.model';
import { AuthenticationService } from 'src/app/shared/service/authentication.service';
import { UserService } from 'src/app/shared/service/user.service';

@Component({
  selector: 'app-vendor-user-detail-dialog',
  templateUrl: './vendor-user-detail-dialog.component.html',
  styleUrls: ['./vendor-user-detail-dialog.component.scss']
})
export class VendorUserDetailDialogComponent implements OnInit {

  loginUserCode: number;
  model: UserViewModel;
  recordId: number = 0;
  heading: string;

  constructor(
    private readonly _alertService: AlertService,
    private readonly _userService: UserService,
    private readonly _authService: AuthenticationService,
    public _dialogRef: MatDialogRef<VendorUserDetailDialogComponent>,
    private readonly _route: ActivatedRoute,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.model = new UserViewModel();
    if (data) {
      if (data.Id) {
        this.recordId = data.Id;
        this.getDetail();
      }
    }
    this.loginUserCode = this._authService.UserCode;
    this.heading = (this.recordId == this.loginUserCode ? "My" : "Vendor User") + " Profile";
  }

  ngOnInit() {
  }

  getDetail() {
    
    this._userService.Detail(this.recordId).subscribe(
      data => {
        if (data.IsSuccess) {
          this.model = <UserViewModel>data.Data;
        }
      },
      error => {
        this.model = new UserViewModel();
        this._alertService.error(error.message);
      }
    );
  }

  closeDialog(): void {
    this._dialogRef.close();
  }

}
