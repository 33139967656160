import { Injectable } from "@angular/core";
import { AppSetting } from "../../model/appsetting.model";
import { IndexModel } from "../../model/common.model";
import { Dictionary } from "../../model/dictionary.model";
import { DistrictModel } from "../../model/master/district.model";
import { BaseService } from "../base.service";

@Injectable({
    providedIn: 'root'
})

export class DistrictService {

    constructor(private readonly _baseService: BaseService) {
    }

    GetList(model: IndexModel) {
        return this._baseService.post(AppSetting.DistrictListUrl, model);
    }

    GetDetail(id: number) {
        let param = new Dictionary<any>();
        param.Add("id", id);
        return this._baseService.get(AppSetting.DistrictDetailUrl, param);
    }

    AddUpdate(model: DistrictModel) {
        return this._baseService.post(AppSetting.DistrictAddUpdateUrl, model);
    }

    Delete(id: number) {
        let param = new Dictionary<any>();
        param.Add("id", id);
        return this._baseService.get(AppSetting.DistrictDeleteUrl, param);
    }

    UpdateStatus(id: number) {
        let param = new Dictionary<any>();
        param.Add("id", id);
        return this._baseService.get(AppSetting.DistrictUpdateActiveStatusUrl, param);
    }

    IsDistrictExist(title: string, distCode: number, stateCode: any) {
        let param = new Dictionary<any>();
        param.Add("title", title);
        param.Add("distCode", distCode);
        param.Add("stateCode", stateCode);
        return this._baseService.get(AppSetting.DistrictIsExistUrl, param);
    }

}
