import { Component, OnInit, Inject } from "@angular/core";
import { FormControl, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { ComplainStatusMasterViewModel } from "src/app/shared/model/master/complain-status-master.model";
import { UserViewModel } from "src/app/shared/model/user.model";
import { AlertService } from "src/app/shared/service/alert.service";
import { AuthenticationService } from "src/app/shared/service/authentication.service";
import { CommonService } from "src/app/shared/service/common.service";
import { ComplainStatusMasterService } from "src/app/shared/service/complain-status-master.service";
import { UserService } from "src/app/shared/service/user.service";

@Component({
  selector: 'app-add-update-status',
  templateUrl: './add-update-status.component.html',
  styleUrls: ['./add-update-status.component.scss']
})
export class AddUpdateStatusComponent implements OnInit {


  id: number;
  model: ComplainStatusMasterViewModel;
  loginData: UserViewModel;
  name = new FormControl('', [Validators.required]);

  title: string = "Add";

  constructor(
    private readonly _userService: UserService,
    private readonly _alertService: AlertService,
    public readonly _dialogRef: MatDialogRef<AddUpdateStatusComponent>,
    private readonly _complainStatusMasterService: ComplainStatusMasterService,
    private readonly _authService: AuthenticationService,
    public readonly _commonService: CommonService,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    if (data) {
      this.id = data;
      this.GetById();
      this.title = 'Edit';
    }
    this.model = new ComplainStatusMasterViewModel();
  }

  ngOnInit() {
    this.loginData = this._authService.GetCurrentUserDetail().UserViewModel;
  }

  GetById() {
    this._complainStatusMasterService.GetById(this.id).subscribe(
      data => {
        if (
          (data.IsSuccess)
        ) {
          this.model = <ComplainStatusMasterViewModel>data.Data;
        }
      },
      error => {
        this.model = new ComplainStatusMasterViewModel();
        this._alertService.error(error.message);
      }
    );
  }


  SaveClick() {
    this.name.markAsTouched();
    if (this.name.valid) {
      if (this.model.Id) {
        this.model.ModifiedBy = this.loginData.UserCode;
        this._complainStatusMasterService.Edit(this.model).subscribe(data => {
          if (data.IsSuccess) {
            this._alertService.success(data.Message);
            this._dialogRef.close(true);
          }
          else {
            this._alertService.error(data.Message);

          }
        }, error => {
          this._alertService.error(error.message);
        });
      }
      else {
        this.model.CreatedBy = this.loginData.UserCode;
        this._complainStatusMasterService.Add(this.model).subscribe(data => {
          if (data.IsSuccess) {
            this._alertService.success(data.Message);
            this._dialogRef.close(true);
          }
          else {
            this._alertService.error(data.Message);
          }
        }, error => {
          this._alertService.error(error.message);
        });
      }
    }

  }

  onNoClick(): void {
    this._dialogRef.close();
  }

  Numberonly(event): boolean {
    return this._commonService.numberOnly(event);
  }
}


