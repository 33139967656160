import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-secondary-dashboard',
  templateUrl: './secondary-dashboard.component.html',
  styleUrls: ['./secondary-dashboard.component.scss']
})
export class SecondaryDashboardComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
