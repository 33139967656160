import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { AppSetting } from '../model/appsetting.model';
import { AuthenticationService } from './authentication.service';
import { DashBoardFilterModel } from '../model/dashboard-filter.model';
import { GlobalFilterSearchModel } from '../model/Global-communication-search.model';

@Injectable({
    providedIn: 'root'
})

export class DashboardService {
    loginUserCode: number;

    constructor(private readonly _baseService: BaseService, private readonly _authenticationService: AuthenticationService) {
        this.loginUserCode = _authenticationService.UserCode;
    }

    // GetCommunicationByStatus(status: string) {        
    //     return this._baseService.get(AppSetting.CommunicationLisByStatustUrl + "?loginUserCode=" + this.loginUserCode + "&status=" + status);
    // }

    GetCommunicationByStatus(model: DashBoardFilterModel) {
        return this._baseService.post(AppSetting.CommunicationLisByStatustUrl, model);
    }

    GetConversationNotification() {
        return this._baseService.get(AppSetting.ConversationNotificationListUrl + "?loginUserCode=" + this.loginUserCode);
    }

    GetUserDashboardStatsData() {
        return this._baseService.get(AppSetting.GetUserDashboardStatsDataUrl + "?loginUserCode=" + this.loginUserCode);
    }

    GetUserDashboardData(model: DashBoardFilterModel) {
        return this._baseService.post(AppSetting.GetUserDashboardDataUrl, model);
    }

    // GetDashboardStatusData() {
    //     return this._baseService.get(AppSetting.GetDashboardStatusData + "?loginUserCode=" + this.loginUserCode);
    // }

    // GetDashboardStatusData(model: GlobalFilterSearchModel) {
    //     return this._baseService.post(AppSetting.GetDashboardStatusData, model);
    // }


    GetDepartmentDashboardCountData(model: GlobalFilterSearchModel) {
        return this._baseService.post(AppSetting.DashboardStatusDataUrl, model);
    }

    GetDepartmentToDoData() {
        return this._baseService.get(AppSetting.DepartmentToDoDataUrl);
    }


    GetVendorStatusCountData() {
        return this._baseService.get(AppSetting.VendorStatusCountDataUrl);
    }


    GetDepartmentStatuCountData() {
        return this._baseService.get(AppSetting.DepartmentStatuCountDataUrl);
    }

    


    // GetAllGlobalListSearch( model: GlobalFilterSearchModel) {
    //     return this._baseService.post(AppSetting.AllGlobalListSearchUrl,model);
    // }

    // GetDRECDashboardStatData() {
    //     return this._baseService.get(AppSetting.GetDRECDashboardStatData + "?loginUserCode=" + this.loginUserCode);
    // }

}