export class VendorFilterModel {
    constructor() {
        this.LoginUserCode = 0;
        this.VendorCode = 0;
        this.DepartmentCode = 0;
        this.ProjectCode = 0;
        this.DistrictCode = 0;
        this.OfficeCode = 0;
        
    }
    LoginUserCode: number;
    VendorCode: number;
    DepartmentCode: number | string;
    ProjectCode: number | string;
    DistrictCode: number | string;
    OfficeCode: number | string;
    IsVerified: number | string;
    VendorTypeCode: number | string;
}
