import { DdlItemModel } from "./commonddl.model";

export class DynamicFieldConfig {
    label?: string;
    name?: string;
    inputType?: string;
    options?: DdlItemModel[];
    collections?: any;
    type: string;
    value?: any;
    validations?: Validator[];
    fieldCode?: number | string;
    ID?: number;
    CommunicationCode?: number;
    CSSClass?: string;
    IsRequired: boolean;
    IsDisabledForEdit: boolean;
    ToolTip: string;
    CustomValidations?: CustomValidations[];
}
export class Validator {
    name: string;
    validator: any;
    message: string;
}

export class CustomValidations {

    MinLimit?: any;
    MinErrorMessage?:string;
    MaxLimit?: any;
    MaxErrorMessage?:string;
    ValueComapreFieldCode? : number;
}