/*import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class MasterReportsService {

  constructor() { }
}
*/
import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { AppSetting } from '../model/appsetting.model';
import { MasterReportModel } from '../model/master-reports.modal';
import { IndexModel } from '../model/common.model';

@Injectable({
    providedIn: 'root'
})

export class MasterReportsService {

    constructor(private readonly _baseService: BaseService,) { }

    GetList(model: IndexModel,masterId="0") {


        return this._baseService.post(AppSetting.MasterTypeReportUrl+masterId , model);
    }

    GetPDFDownload(model: IndexModel,masterId="0") {
        return this._baseService.post(AppSetting.MasterTypeReportPDFDownloadUrl+masterId, model);
    }


}
