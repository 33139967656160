import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { AppComponent } from "src/app/app.component";
import { GlobalFilterModel, IndexModel } from 'src/app/shared/model/common.model';
import { GlobalMessagesModel } from 'src/app/shared/model/common-messages.model';
import { AppSetting } from 'src/app/shared/model/appsetting.model';
import { MatTableDataSource, MatDialog, MatSort, MatPaginator } from "@angular/material";
import { ConfirmationDialogComponent } from "src/app/confirmation-dialog/confirmation-dialog.component";
import { AlertService } from 'src/app/shared/service/alert.service';
import { CommonService } from 'src/app/shared/service/common.service';
import { ColumnHeaderModel } from 'src/app/shared/model/commonddl.model';
import { UserTypeEnum } from 'src/app/shared/enum/fixed-values.enum';
import { AuthenticationService } from 'src/app/shared/service/authentication.service';
import { Router } from '@angular/router';
import { RefundRequestReasonModel, RefundRequestReasonReportModel } from 'src/app/shared/model/master/refund-request-reason.model';
import { RefundRequestReasonService } from 'src/app/shared/service/master/refund-request-reason.service';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

declare const require: any;
const jsPDF = require('jspdf');
require('jspdf-autotable');
@Component({
  selector: 'app-refund-request-reason-report',
  templateUrl: './refund-request-reason-report.component.html',
  styleUrls: ['./refund-request-reason-report.component.scss']
})
export class RefundRequestReasonReportComponent implements OnInit {

  model: RefundRequestReasonReportModel[];
  indexModel: IndexModel;
  dataSource: any;
  totalRecords: number;

  displayedColumns: string[] = ["index", "FieldValueInEnglish", "FieldValueInHindi", "ModifiedOn", "UserName", "IsActive"];
  columnsToDisplay: string[] = this.displayedColumns.slice();
  ViewdisplayedColumns: ColumnHeaderModel[] = [
    { Value: "FieldValueInEnglish", Text: "Reason" },
    { Value: "FieldValueInHindi", Text: "Reason (Hindi)" },
    { Value: "ModifiedOn", Text: "Modified On" },
    { Value: "UserName", Text: "ModifiedBy" }
  ];

  SearchdisplayedColumns: ColumnHeaderModel[] = [
    { Value: "FieldValueInEnglish", Text: "Reason" },
    { Value: "FieldValueInHindi", Text: "Reason (Hindi)" },
  ];

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(
    private readonly appComponnet: AppComponent,
    private readonly _alertService: AlertService,
    private readonly _refundRequestReasonService: RefundRequestReasonService,
    private readonly _router: Router,
    private readonly _dialog: MatDialog,
    public readonly _commonService: CommonService,
    private readonly _authService: AuthenticationService
  ) {
    this.appComponnet.setPageLayout("Refund Request Reason Report :", "", "", "");
    this.indexModel = new IndexModel();
  }

  ngOnInit() {
    this.getList();
  }

  getList() {
    this._refundRequestReasonService.GetList(this.indexModel).subscribe(
      data => {
        if (data.IsSuccess) {
          this.model = <RefundRequestReasonReportModel[]>data.Data.Data;
          this.dataSource = new MatTableDataSource<RefundRequestReasonReportModel>(this.model);
          this.totalRecords = data.Data.TotalRecords;
          if (!this.indexModel.IsPostBack) {
            this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.sort;
          }
        } else {
          this._alertService.error(data.Message);
        }
      },
      error => {
        this._alertService.error(error.message);
      }
    );
  }

  updateDeleteStatus(id) {
    const dialogRef = this._dialog.open(ConfirmationDialogComponent, { width: "50%", data: GlobalMessagesModel.ConfirmDeleted });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this._refundRequestReasonService.ChangeDeleteStatus(id).subscribe(
          data => {
            if (data.IsSuccess) {
              this._alertService.success(data.Message);
              this._commonService.ScrollingTop();
              this.getList();
            }
          },
          error => {
            this._alertService.error(error.message);
          }
        );
      }
    });
  }

  updateActiveStatus(id) {
    const dialogRef = this._dialog.open(ConfirmationDialogComponent, { width: "50%", data: GlobalMessagesModel.ConfirmStatusChanged });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this._refundRequestReasonService.ChangeActiveStatus(id).subscribe(
          data => {
            if (data.IsSuccess) {
              this._alertService.success(data.Message);
              this.getList();
            }
          },
          error => {
            this._alertService.error(error.message);
          }
        );
      }
    });
  }

  sortData(event) {
    this.indexModel.OrderBy = event.active;
    this.indexModel.OrderByAsc = event.direction == AppSetting.orderByDscAsc ? AppSetting.orderByAsc : AppSetting.orderByDsc;
    this.indexModel.IsPostBack = true;
    this.getList();
  }

  onPaginateChange(event) {
    this.indexModel.Page = event.pageIndex + 1;
    this.indexModel.PageSize = event.pageSize;
    this.indexModel.IsPostBack = true;
    this.getList();
  }
  SearchByKeyword(searchValue: GlobalFilterModel) {
    this.indexModel.Search = searchValue.search;
    this.indexModel.AdvanceSearchModel = { ActiveStatus: searchValue.ActiveStatus }

    this.getList();
  }

  ClearSearch() {
    this.indexModel = new IndexModel();
    this.getList();
  }


  //Report
  @ViewChild('pdfTable', { static: false }) pdfTable: ElementRef;
  completeModel: RefundRequestReasonReportModel[];//change
  completeIndexModel: IndexModel;

  getCompleteData() {
    this.completeIndexModel = new IndexModel();
    this.completeIndexModel.Page = 1;
    this.completeIndexModel.PageSize = this.totalRecords;
    this.completeIndexModel.IsPostBack = true;
    this.completeIndexModel.Search = this.indexModel.Search;
    this._refundRequestReasonService.GetList(this.completeIndexModel).subscribe( //change
      (completeData: any) => {
        if (completeData.IsSuccess) {
          this.completeModel = <RefundRequestReasonReportModel[]>completeData.Data.Data; //change
          this.totalRecords = completeData.Data.TotalRecords;
          //this.download();
        } else {
          this._alertService.error(completeData.Message);
        }
      },
      error => {
        this._alertService.error(error.message);
      }
    );
  }

  DownloadPdf() {    
    this._refundRequestReasonService.GetRefundRequestReasonPDFDownload(this.indexModel).subscribe(
      data => {
        if (data.IsSuccess) {          
          // const linkSource = data.Data;
          // const downloadLink = document.createElement("a");
          // const fileName = 'Refund Request Reason Type Report';

          // downloadLink.href = linkSource;
          // downloadLink.download = fileName;
          // downloadLink.target = "blank";
          // downloadLink.click();
          var w = window.open("about:blank");

          setTimeout(function() {
            //FireFox seems to require a setTimeout for this to work.
            w.document.body.appendChild(w.document.createElement("iframe")).src = data.Data;
            w.document.getElementsByTagName("iframe")[0].style.width = "100%";
            w.document.getElementsByTagName("iframe")[0].style.height = "100%";
          }, 0);
        } else {
          this._alertService.error(data.Message);
        }
      },
      error => {
        this._alertService.error(error.message);
      }
    );
  }

  buttonClickExportAsPDF(): void {
    // this.getCompleteData();
    // setTimeout(() => {
    //   this.download();
    //   this.exportAsPdf();
    // }, 1000);
    this.DownloadPdf();
  }
  buttonClickExportAsXLSX(): void {
    this.getCompleteData();
    setTimeout(() => {
      this.download();
      this.exportAsExcelFile();
    }, 1000);
  }

  rowsNew: any = [];

  download() {
    var i = 1;
    this.rowsNew = [];
    this.completeModel.forEach(element => {
      var temp = {
        SNo: i, Reason: element.FieldValueInEnglish, ReasonHindi: element.FieldValueInHindi,
        IsActive: element.IsActive, ModifiedOn: element.ModifiedOn, ModifiedBy: element.UserName
      };
      i = i + 1;
      this.rowsNew.push(temp);
    });

  }

  public exportAsPdf(): void {
    var doc = new jsPDF('p', 'pt');
    var header = function (data) {
      doc.setFontSize(10);
      doc.setTextColor(40);
      doc.text("RefundRequestReasonType", data.settings.margin.left, 50);
      doc.text("Date Time : " + new Date().toLocaleDateString() + " " + new Date().toLocaleTimeString(), data.settings.margin.left, 800);
    };
    var headers = [{  //change
      title: "#",
      dataKey: "SNo"
    }, {
      title: "Reason",
      dataKey: "Reason"
    },
    {
      title: "Reason (Hindi)",
      dataKey: "ReasonHindi"
    },
    {
      title: "Is Active",
      dataKey: "IsActive"
    },
    {
      title: "Modified On",
      dataKey: "ModifiedOn"
    },
    {
      title: "Modified By",
      dataKey: "ModifiedBy"
    }

    ];
    doc.autoTable(headers, this.rowsNew, { margin: { top: 70 }, beforePageContent: header });
    doc.save("Refund-Reason-Request-Report_" + new Date().toLocaleDateString() + "_" + new Date().toLocaleTimeString() + ".pdf"); //change
  }

  //excellll
  public exportAsExcelFile(): void {
    //this.getCompleteData();
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.rowsNew);
    const workbook: XLSX.WorkBook = { Sheets: { 'refundReasonRequestReport': worksheet }, SheetNames: ['refundReasonRequestReport'] }; //change
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFile(excelBuffer, 'Refund-Reason-Request'); //change
  }

  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], { type: EXCEL_TYPE });
    FileSaver.saveAs(data, fileName + '_Report_' + new Date().toLocaleDateString() + "_" + new Date().toLocaleTimeString() + EXCEL_EXTENSION);


  }
}