import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Router } from '@angular/router';
import { AppSetting } from 'src/app/shared/model/appsetting.model';
import { GlobalMessagesModel } from 'src/app/shared/model/common-messages.model';
import { DDLModel, DdlItemModel } from "src/app/shared/model/commonddl.model";
import { CommunicationDefaultFieldPostModel, CommunicationTypeDefaultFieldsMasterModel } from 'src/app/shared/model/master/communication-type-fields-master.model';
import { UserViewModel } from 'src/app/shared/model/user.model';
import { AlertService } from 'src/app/shared/service/alert.service';
import { AuthenticationService } from 'src/app/shared/service/authentication.service';
import { CommonService } from 'src/app/shared/service/common.service';
import { CommunicationTypeFieldsMasterService } from 'src/app/shared/service/master/communication-type-fields-master.service';

@Component({
  selector: 'app-assign-default-field',
  templateUrl: './assign-default-field.component.html',
  styleUrls: ['./assign-default-field.component.scss']
})
export class AssignDefaultFieldComponent implements OnInit {

  //#region <<Variable>>
  loginUserDetail: UserViewModel;
  loginUserCode: number;
  frmGrp: FormGroup;
  model: CommunicationDefaultFieldPostModel[] = []//= new CommunicationDefaultFieldPostModel();

  ddlList: DDLModel = new DDLModel();
  communicationTypeCode: number;
  selectedField: string[] = [];
 
  
  //#endregion

  constructor(private readonly fb: FormBuilder,
    public readonly _commonService: CommonService,
    private readonly _alertService: AlertService,
    public _dialogRef: MatDialogRef<AssignDefaultFieldComponent>,
    private readonly _communicationTypeFieldsMasterService: CommunicationTypeFieldsMasterService,
    private readonly _router: Router,
    private readonly _authService: AuthenticationService,
    @Inject(MAT_DIALOG_DATA) public data: any) {

    this.loginUserDetail = this._authService.GetCurrentUserDetail().UserViewModel;

    if (data) {

      if (data.CommunicationTypeCode) {
        this.communicationTypeCode = data.CommunicationTypeCode;

      }
    

    } else {
      this._dialogRef.close();
    }
  }

  ngOnInit() {
    this.formGroupInit();

    this.GetDDLList();
  }

  formGroupInit() {
    this.frmGrp = this.fb.group({
      FieldCode: [null, Validators.required],
      

    });
  }

  GetDDLList() {

    this._communicationTypeFieldsMasterService.GetddlDefaultFields(this.communicationTypeCode).subscribe(
      data => {

        this.ddlList.ddlDefaultField = <DdlItemModel[]>data;

        if (this.ddlList.ddlDefaultField.length == 0) {
          this._alertService.success(GlobalMessagesModel.NoFieldFound);
          this._dialogRef.close(true);
        }

      },
      error => {
        this._alertService.error(error.message);
      }
    );
  }


  saveClick() {

    this.frmGrp.markAllAsTouched();
    if (this.frmGrp.valid) {

      this.selectedField.toString().split(",").forEach(item => {

        this.model.push({
          CommunicationTypeCode: this.communicationTypeCode,
          CreateBy: this.loginUserDetail.UserCode,
          FieldCode: Number(item), 
        } as CommunicationDefaultFieldPostModel);

      })
      let value = this.model;

      this._communicationTypeFieldsMasterService.AddUpdateDefaultFields(this.model).subscribe(
        data => {
          if (data.IsSuccess) {

            this._alertService.success(GlobalMessagesModel.saveSuccess);
            this._dialogRef.close(true);
          } else {
            this._alertService.error(data.Message);
            this._alertService.error(GlobalMessagesModel.saveError);
          }
        },
        error => {
          this._alertService.error(error.message);
        }
      );
    }
  }

  CloseDialog(): void {
    this._dialogRef.close();
  }


  OnSelectAssignField() {
    if (this.selectedField.length != this.ddlList.ddlDefaultField.length) {
      this.selectedField = this.ddlList.ddlDefaultField.map(function (a) {
        return String(a.Value);
      });
    } else {
      this.selectedField = [];
    }

  }


}
