import { Component, OnInit, ViewChild } from "@angular/core";
import { AppComponent } from "src/app/app.component";
import { GlobalFilterModel, IndexModel, PermissionModel, SearchModel } from 'src/app/shared/model/common.model';
import { GlobalMessagesModel } from 'src/app/shared/model/common-messages.model';
import { AppSetting } from 'src/app/shared/model/appsetting.model';
import { MatTableDataSource, MatDialog, MatSort, MatPaginator } from "@angular/material";
import { ConfirmationDialogComponent } from "src/app/confirmation-dialog/confirmation-dialog.component";
import { AlertService } from 'src/app/shared/service/alert.service';
import { CommonService } from 'src/app/shared/service/common.service';
import { CommunicationTypeModel } from 'src/app/shared/model/master/communication-type.model';
import { CommunicationTypeService } from 'src/app/shared/service/master/communication-type.service';
import { ColumnHeaderModel } from 'src/app/shared/model/commonddl.model';
import { UserTypeEnum } from 'src/app/shared/enum/fixed-values.enum';
import { Router } from '@angular/router';
import { AuthenticationService } from 'src/app/shared/service/authentication.service';

@Component({
  selector: 'app-communication-type',
  templateUrl: './communication-type.component.html',
  styleUrls: ['./communication-type.component.scss']
})

export class CommunicationTypeComponent implements OnInit {
  userTypeEnum = UserTypeEnum;
  model: CommunicationTypeModel[];
  indexModel = this._commonService.modelSetGet(new IndexModel());
  dataSource: any;
  totalRecords: number;
  defaultValue: SearchModel;

  displayedColumns: string[] = ["index", "CommunicationType", "CommunicationTypeHindi", "CommunicationTypeForUserTypeTitle", "CreatedByUserName", "ModifiedDate", "IsActive", "Action"];
  ViewdisplayedColumns: ColumnHeaderModel[] = [
    { Value: "CommunicationType", Text: "Communication Type" },
    { Value: "CommunicationTypeHindi", Text: "Communication Type (Hindi)" },
    { Value: "CommunicationTypeForUserTypeTitle", Text: "User Type" },

  ];
  columnsToDisplay: string[] = this.displayedColumns.slice();
  Permission: PermissionModel = this._authService.GetPagePermission("/master/communication-type", "/communication-type/add", "/communication-type/detail", "/communication-type/update", "/communication-type/delete", "/communication-type/communication-fields/", "/communication-type/communication-fields/");

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(
    private readonly appComponnet: AppComponent,
    private readonly _alertService: AlertService,
    private readonly _communicationTypeService: CommunicationTypeService,
    private readonly _router: Router,
    private readonly _dialog: MatDialog,
    public readonly _commonService: CommonService,
    private readonly _authService: AuthenticationService
  ) {


    this.Permission.AddPageAccess ? this.appComponnet.setPageLayout("Communication Type :", "add", "Create", "master/communication-type/add") : this.appComponnet.setPageLayout("Communication Type :");

    this.indexModel.OrderBy = "CommunicationType";
    this.indexModel.OrderByAsc = 1;


  }

  ngOnInit() {
    // if (UserTypeEnum.ADM != this._authService.BaseUserType) {
    //   this._router.navigate(["dashboard"]);
    // }
    this.getList();
  }

  getList() {
    //  this.indexModel.PageSize = 101;

    this._communicationTypeService.GetList(this._commonService.modelSetGet(this.indexModel, true)).subscribe(
      data => {
        if (data.IsSuccess) {
          this.model = <CommunicationTypeModel[]>data.Data.Data;
          this.dataSource = new MatTableDataSource<CommunicationTypeModel>(this.model);
          this.totalRecords = data.Data.TotalRecords;
          if (!this.indexModel.IsPostBack) {
            this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.sort;
          }
        } else {
          this._alertService.error(data.Message);
        }
      },
      error => {
        this._alertService.error(error.message);
      }
    );
  }

  updateDeleteStatus(id) {
    const dialogRef = this._dialog.open(ConfirmationDialogComponent, { width: "50%", data: GlobalMessagesModel.ConfirmDeleted });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this._communicationTypeService.ChangeDeleteStatus(id).subscribe(
          data => {
            if (data.IsSuccess) {
              this._alertService.success(data.Message);
              this._commonService.ScrollingTop();
              this.getList();
            }
          },
          error => {
            this._alertService.error(error.message);
          }
        );
      }
    });
  }

  updateActiveStatus(id) {
    const dialogRef = this._dialog.open(ConfirmationDialogComponent, { width: "50%", data: GlobalMessagesModel.ConfirmStatusChanged });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this._communicationTypeService.ChangeActiveStatus(id).subscribe(
          data => {
            if (data.IsSuccess) {
              this._alertService.success(data.Message);
              this.getList();
            }
          },
          error => {
            this._alertService.error(error.message);
          }
        );
      }
    });
  }

  sortData(event) {
    this.indexModel.OrderBy = event.active;
    this.indexModel.OrderByAsc = event.direction == AppSetting.orderByDscAsc ? AppSetting.orderByAsc : AppSetting.orderByDsc;
    this.indexModel.IsPostBack = true;
    this.getList();
  }

  onPaginateChange(event) {
    this.indexModel.Page = event.pageIndex + 1;
    this.indexModel.PageSize = event.pageSize;
    this.indexModel.IsPostBack = true;
    this.getList();
  }


  SearchByKeyword(searchValue: GlobalFilterModel) {

    this.indexModel.Search = searchValue.search;
    this.indexModel.AdvanceSearchModel = { ActiveStatus: searchValue.ActiveStatus }
    this.getList();
  }

  ClearSearch() {
    this.indexModel.Search = null;
    this.indexModel.AdvanceSearchModel = { ActiveStatus: "1" }
    this.getList();
  }

  PagePrint(printContent) {
    this._commonService.printHTMLContent(printContent, "Communication Type Master");
  }
  // proceedToMapFields(Id) {
  //   const _dialogRef = this._dialog.open(MapFieldsComponent, {
  //     width: "500px",
  //     data: { Id },
  //     disableClose: true
  //   });
  //   _dialogRef.afterClosed().subscribe((result: boolean) => {
  //     if (result) {
  //       this.getList();
  //     }
  //   });
  // }
}
