
export class FixedValues {
}

export enum UserTypeEnum {
  ADM = 'ADM',
  ADMCode = 1,
  DREC = 'DREC',
  DRECCode = 2,
  HOD = 'HOD',
  HODCode = 3,
  VNDR = 'VNDR',
  VNDRCode = 4,
  DOS = 'DOS',
  DOSCode = 5,
  VNDRUSER = 'VNDRUSER',
  VNDRUSERCode = 6,
  SADM = 'SADM',
  SADMCode = 7,
  ADLC = 'ADLC',
  ADLCCode = 8,
  HOO = 'HOO',
  HOOCode = 9,
  DLO = 'DLO',
  DLOCode = 10,
  PROJ = 'PROJ',
  PROJCode = 11,
  HOOADLC = 'HOOADLC',
  HOOADLCCode = 15,
  DRECADLC = 'DRECADLC',
  DRECADLCCode = 20,
  DOSADLC = 'DOSADLC',
  DOSADLCCode = 17,
  STTLVL = 'STTLVL',
  STTLVLCode = 22,
  ADMIN = 'ADMIN',
  ADMINCode = 14,
  OIC = 'OIC',
  OICCode = 12,
  CMOADMIN = 'CMOADMIN',
  CMOADMINCode = 13,
  INTDPT = 'INTDPT',
  INTDPTCode = 26
}

export enum UserTypeProdEnum {
  ADM = 'ADM',
  ADMCode = 1,
  DREC = 'DREC',
  DRECCode = 2,
  HOD = 'HOD',
  HODCode = 3,
  VNDR = 'VNDR',
  VNDRCode = 4,
  DOS = 'DOS',
  DOSCode = 5,
  VNDRUSER = 'VNDRUSER',
  VNDRUSERCode = 6,
  SADM = 'SADM',
  SADMCode = 7,
  ADLC = 'ADLC',
  ADLCCode = 8,
  HOO = 'HOO',
  HOOCode = 9,
  DLO = 'DLO',
  DLOCode = 10,
  PROJ = 'PROJ',
  PROJCode = 11,
  HOOADLC = 'HOOADLC',
  HOOADLCCode = 19,
  DRECADLC = 'DRECADLC',
  DRECADLCCode = 16,
  DOSADLC = 'DOSADLC',
  DOSADLCCode = 17,
  STTLVL = 'STTLVL',
  STTLVLCode = 19,
  OIC = 'OIC',
  OICCode = 12,
  CMOADMIN = 'CMOADMIN',
  CMOADMINCode = 13,
  ADMIN = 'ADMIN',
  ADMINCode = 14,
  CCC = 'CCC',
  CCCCode = 18
}
export enum AlertTypeEnum {
  Success = 'success',
  Error = 'error',
}

export enum SessionTimeOut {
  TimeInMinute = 30
}

export enum StatusEnum {
  DRAFT = 'DRAFT',
  SENT = 'SENT',
  RECEIVED = 'RECEIVED',
  ACCEPT = 'ACCEPT',
  REJECT = 'REJECT',
  PENDING = 'PENDING',
  DISPOSED = 'DISPOSED',
  RETURN = 'RETURN',
  CLOSE = 'CLOSE',
  INPROCESS = 'IN-PROCESS',
  REOPEN = 'REOPEN',
  RESPONSE_BACK = 'RESPONSE-BACK',
  RESUBMIT = 'RE-SUBMIT',
  LATEST = 'LATEST'
}

export enum VerifiedEnum {
  YES = 'Yes',
  NO = 'No'
}

export enum CommunicationTypeEnum {
  EMDReturn = 'EMDReturn',
  Invoice = 'Invoice',
  Complaint = 'Complaint',
  General = 'General',
  Suggestion = 'Suggestion'
}

export enum FieldTypeEnum {
  TextBox = "TEXTBOX",
  TextBoxCode = 1,
  DropDown = "DROPDOWN",
  DropDownCode = 2,
  CHECKBOX = "CHECKBOX",
  CheckBoxCode = 3,
  RADIOBUTTON = "RADIOBUTTON",
  RadioButtonCode = 4,
  TEXTAREA = "TEXTAREA",
  TextAreaCode = 5,
  DATE = "DATE",
  DATECode = 9
}

export enum GeneralEntryCategoryEnum {
  BannerImage = "Banner Image",
  BannerImageCode = "1",
  Banner_ImageHeight = "500",
  Banner_ImageWidth = "1920",
  PosterImagesCode = "2",
  FAQCode = "3",
  DepartmentalPhotoGalleryCode = "4",
  DocumentsCode = "5",
  HowitworkCode = "6",
  AboutusCode = "7",
  ContactusCode = "8",
  SocialMediaLinkCode = "9",
  NodalOfficerDetailCode = "10",
  ImportantLinksCode = "11",
  MasterTrainersCode = "13",
  VendorTrainingCode = "14",
  DepartmentTrainingCode = "15",
  ServiceRequestForBuildCode = "16",

}



export enum GeneralEntrySubCategoryEnum {
  Award = 1,
  Department = 2,
  Facebook = 3,
  Instagram = 4,
  Twitter = 5,
  Vendor = 6,
  General = 7,
  Zoom_14 = 8,
  WebEx_14 = 9,
  VideoConferencing = 10,
  WebEx_15 = 11,
  Zoom_15 = 12,
  B2G = 13,
  Jankalyan = 14,
  CMRF = 15,
  CMISAgile = 16,
  GeneralHelp = 17,
  TrainingOrders = 18,
  ReportHelp = 19
}


export enum B2GUserManualMasterPageCodeEnum {
  CommunicationType = 8,
  MasterType = 43,
}

export enum AreaTypeEnum {
  UrbanProd = 144,
  RuralProd = 145,

  UrbanStage = 8,
  RuralStage = 8,

  UrbanDSStage = 87,
  RuralDSStage = 90,

  UrbanDSCMOProd = 88,
  RuralDSCMOProd = 89,
}

export enum StateEnum {
  Rajasthan = 20,
}

export enum CommunicationInitiateByEnum {
  D2V = 'DREC',
  D2D = 'HOD',
  V2D = 'VNDR',
  INTDPT = 'INTDPT',
  D2VValue = 0,
  D2DValue = 2,
  V2DValue = 1,
  INTDPTValue = 3
}

export enum GeneralCategoryEnumLocal {
  FAQ = 3,
  DepartmentalPhotoGallery = 4,
  BannerImages = 1,
  Documents = 5,
  HowItWork = 6,
  AboutUs = 7,
  ContactUs_NodalOfficerDetail = 8,
  SocialMediaLink = 9,
  NodalOfficerDetail_Footer = 10,
  ImportantLinks = 11,
  TrainingCalendar = 12,
  MasterTrainer = 13,
  DepartmentTraining = 12,
  VendorTraining = 13
}


export enum GeneralCategoryEnumProd {
  PosterImages = 2,
  FAQ = 3,
  DepartmentalPhotoGallery = 4,
  BannerImages = 1,
  Documents = 5,
  HowItWork = 6,
  AboutUs = 7,
  ContactUs_NodalOfficerDetail = 8,
  SocialMediaLink = 9,
  NodalOfficerDetail_Footer = 10,
  ImportantLinks = 11,
  TrainingCalendar = 12,
  MasterTrainer = 13,
  DepartmentTraining = 15,
  VendorTraining = 14,
  ServiceRequestForBuild = 16,

}

export enum PreferSearchTypeEnum {
  Office,
  Vendor
};

export enum CustomFilterTypeEnum {
  Vendor,
  Department
}
