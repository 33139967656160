import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { AppComponent } from 'src/app/app.component';
import { AlertService } from 'src/app/shared/service/alert.service';
import { VendorModel } from 'src/app/shared/model/vendor.model';
import { VendorService } from 'src/app/shared/service/vendor.service';
import { AuthenticationService } from 'src/app/shared/service/authentication.service';
import { CommonService } from 'src/app/shared/service/common.service';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { UserViewModel } from 'src/app/shared/model/user.model';
import { GlobalMessagesModel } from 'src/app/shared/model/common-messages.model';

@Component({
  selector: 'app-update-vendor-profile',
  templateUrl: './update-vendor-profile.component.html',
  styleUrls: ['./update-vendor-profile.component.scss']
})

export class UpdateVendorProfileComponent implements OnInit {
  loginUserDetail: UserViewModel;
  model: VendorModel;
  RecordId: number = 0;
  frmGrp: FormGroup;

  constructor(private readonly fb: FormBuilder,
    private readonly _appComponent: AppComponent,
    private _route: ActivatedRoute,
    private readonly _vendorService: VendorService,
    public readonly _commonService: CommonService,
    private readonly _alertService: AlertService,
    private readonly _authService: AuthenticationService,
    private readonly _router: Router
  ) {
    this.RecordId = this._route.snapshot.params.id;
    this.model = new VendorModel();
    // this._appComponent.setPageLayout("Edit profile :", "keyboard_backspace", "Back To List", "vendor");
    this._appComponent.setPageLayout("Edit profile :", "keyboard_backspace", "", "");
  }

  ngOnInit() {
    this.formGroupInit();
    if (this.RecordId != 0) {
      this.getDetail();
    }
  }

  formGroupInit() {
    const reg = '^[A-Za-z]{5}[0-9]{4}[A-Za-z]$';

    this.frmGrp = this.fb.group({
      ContactPersonName: [null, Validators.required],
      MobileNo1: [null, Validators.required],
      MobileNo2: [null],
      Email: [null, Validators.required],
      PANNo: [null, Validators.compose([Validators.required, Validators.maxLength(10), Validators.pattern(reg)])],
      TANNo: [null, Validators.maxLength(10)],
      AadharNo: [null, Validators.compose([Validators.required, Validators.minLength(12), Validators.maxLength(12)])],
      GSTINNo: [null, Validators.compose([Validators.required, Validators.maxLength(15)])],
      BRNNo: [null, Validators.compose([Validators.minLength(16),Validators.maxLength(16)])],
    });
  }

  getDetail() {
    this._vendorService.Detail(this.RecordId).subscribe(
      data => {
        if (data.IsSuccess) {
          this.model = <VendorModel>data.Data;
          if (this.model.UserCode == this._authService.UserCode) {
            //this._appComponent.setPageLayout("Edit Profile :", "", "Back to Profile", "vendor/bank-account/"+this.RecordId);
          }
          if (this.model.PANNo) {
            this.model.PANNo = this._commonService.convertText(this.model.PANNo, false);
          }
          if (this.model.AadharNo) {
            this.model.AadharNo = this._commonService.convertText(this.model.AadharNo, false);
          }
          this.RecordId = this.model.VendorCode;
          this.model.ContactPersonName = this.model.ContactPersonName;
          this.model.MobileNo1 = this.model.MobileNo1.toString();
          this.model.MobileNo2 = this.model.MobileNo2.toString();
          this.model.Email = this.model.Email.toString();
        }
      },
      error => {
        this._alertService.error(error.message);
      }
    );
  }

  keyPressAlphaNumeric(event) {
    var inp = String.fromCharCode(event.keyCode);
    if (/[a-zA-Z0-9]/.test(inp)) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }

  saveClick() {
    this.frmGrp.markAllAsTouched();
    if (this.frmGrp.valid) {
      // if (this.RecordId == 0) {
      //   this.model.CreatedBy = this.loginUserDetail.UserCode;
      // }
      // else
      if (this.RecordId != 0) {
        this.model.ModifiedBy = this.model.UserCode;// this.loginUserDetail.UserCode;
        this.model.DocumentList = null;
      }
      if (this.model.AadharNo) {
        this.model.AadharNo = this._commonService.convertText(this.model.AadharNo);
      }
      if (this.model.PANNo) {
        this.model.PANNo = this._commonService.convertText(this.model.PANNo);
      }

      this._vendorService.AddUpdate(this.RecordId, this.model).subscribe(
        data => {
          if (data) {
            if (data.IsSuccess) {
              this._alertService.success(this.RecordId == 0 ? GlobalMessagesModel.saveSuccess : GlobalMessagesModel.updateSuccess);
              this._router.navigate(["/vendor/detail/" + this.RecordId]);
            } else {
              this._commonService.ScrollingTop();
              this._alertService.error(this.RecordId == 0 ? GlobalMessagesModel.saveError : GlobalMessagesModel.updateError);
            }
          } else {
            this._commonService.ScrollingTop();
            this._alertService.error(GlobalMessagesModel.InternalError);
          }
        },
        error => {
          this._commonService.ScrollingTop();
          this._alertService.error(GlobalMessagesModel.InternalError);
        }
      );
    }
  }

}
