import { BaseService } from '../base.service';
import { IndexModel } from '../../model/common.model';
import { AppSetting } from '../../model/appsetting.model';
import { SuggestionForModel } from '../../model/master/suggestion-for.model';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})

export class SuggestionForService {
    constructor(private readonly _baseService: BaseService,) { }

    GetList(model: IndexModel) {
        return this._baseService.post(AppSetting.SuggestionListUrl, model);
    }

    AddUpdate(id: number, model:SuggestionForModel) {
        return this._baseService.post(AppSetting.SuggestionAddUpdateUrl, model);
    }

    Detail(id: number) {
        return this._baseService.get(AppSetting.SuggestionDetailUrl + id);
    }

    ChangeDeleteStatus(id: number) {
        return this._baseService.get(AppSetting.SuggestionDeleteStatusChangeUrl + id);
    }

    ChangeActiveStatus(id: number) {
        return this._baseService.get(AppSetting.SuggestionActiveStatusChangeUrl + id);
    }

    IsSuggestionExist(vendorType: string) {
        return this._baseService.get(AppSetting.IsSuggestionExistUrl + vendorType);
    }

    GetSuggestionPDFDownload(model: IndexModel) {
        return this._baseService.post(AppSetting.SuggestionPDFDownloadUrl, model);
    }
}
