import { Component, OnInit, ViewChild } from '@angular/core';
import { ProjectMappingViewModel } from 'src/app/shared/model/projectMapping.model';
import { GlobalFilterModel, IndexModel } from 'src/app/shared/model/common.model';

import { ColumnHeaderModel } from 'src/app/shared/model/commonddl.model';
import { MatPaginator, MatSort, MatDialog, MatTableDataSource } from '@angular/material';
import { AppComponent } from 'src/app/app.component';
import { AlertService } from 'src/app/shared/service/alert.service';
import { ProjectMappingService } from 'src/app/shared/service/project-mapping.service';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/shared/service/common.service';
import { AuthenticationService } from 'src/app/shared/service/authentication.service';
import { UserTypeEnum } from 'src/app/shared/enum/fixed-values.enum';
import { ConfirmationDialogComponent } from 'src/app/confirmation-dialog/confirmation-dialog.component';
import { GlobalMessagesModel } from 'src/app/shared/model/common-messages.model';
import { AppSetting } from 'src/app/shared/model/appsetting.model';
import { AddUpdateloginuserCreationComponent } from './add-updateloginuser-creation/add-updateloginuser-creation.component';
import { UserService } from 'src/app/shared/service/user.service';
import { UserCreationViewModel } from 'src/app/shared/model/user.model';

@Component({
  selector: 'app-login-user-creation',
  templateUrl: './login-user-creation.component.html',
  styleUrls: ['./login-user-creation.component.scss']
})
export class LoginUserCreationComponent implements OnInit {

  //#region Variable
  model: UserCreationViewModel[];
  indexModel: IndexModel;
  dataSource: any;
  totalRecords: number;
  loginUserType: string;

  displayedColumns: string[] = ["index", "Name", "SSOID", "DepartmentNames", "DistrictNames", "UserTypeTitle", "IsActive", "Action"];
  columnsToDisplay: string[] = this.displayedColumns.slice();

  ViewdisplayedColumns: ColumnHeaderModel[] = [
    { Value: "Name", Text: "Name" },
    { Value: "SSOID", Text: "SSO ID" },
    { Value: "DepartmentNames", Text: "Department" },
    { Value: "DistrictNames", Text: "District" },
    { Value: "UserTypeTitle", Text: "User Type" },
    // { Value: "AssignDate", Text: "AssignDate" },
  ];
  loginBaseUserType: string;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  UserTypeEnum = UserTypeEnum;
  //#endregion

  constructor(
    private readonly appComponnet: AppComponent,
    private readonly _alertService: AlertService,
    private readonly _projectMappingService: ProjectMappingService,
    private readonly _userService: UserService,
    private readonly _dialog: MatDialog,
    private readonly _router: Router,
    public readonly _commonService: CommonService,
    private readonly _authService: AuthenticationService
  ) {


    this.appComponnet.setPageLayout("", "", "", "", true);


    this.indexModel = new IndexModel();
  }

  ngOnInit() {
    this.loginBaseUserType = this._authService.BaseUserType;
    if (this.loginBaseUserType == UserTypeEnum.ADLC || this.loginBaseUserType == UserTypeEnum.ADM || this.loginBaseUserType == UserTypeEnum.SADM) {
      this.getList();
    }
    else {
      this._router.navigate(["dashboard"]);
    }
  }

  //#region Method

  getList() {
    this._userService.GetAdminLoginUserCreationList(this.indexModel).subscribe(
      data => {
        if (data.IsSuccess) {
          this.model = <UserCreationViewModel[]>data.Data.Data;
          this.dataSource = new MatTableDataSource<UserCreationViewModel>(this.model);
          this.totalRecords = data.Data.TotalRecords;
          // if (!this.indexModel.IsPostBack) {
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
          // }
        } else {
          this._alertService.error(data.Message);
        }
      },
      error => {
        this._alertService.error(error.message);
      }
    );
  }

  updateActiveStatus(id) {
    const dialogRef = this._dialog.open(ConfirmationDialogComponent, { 
     // width: "50%", 
      data: GlobalMessagesModel.ConfirmStatusChanged });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this._userService.ChangeActiveStatus(id).subscribe(
          data => {
            if (data.IsSuccess) {
              this._alertService.success(data.Message);
              this.getList();
            }
          },
          error => {
            this._alertService.error(error.message);
          }
        );
      }
    });
  }

  updateDeleteStatus(id) {
    const dialogRef = this._dialog.open(ConfirmationDialogComponent, { 
      //width: "50%",
       data: GlobalMessagesModel.ConfirmDeleted });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this._userService.ChangeDeleteStatus(id).subscribe(
          data => {
            if (data.IsSuccess) {
              this._alertService.success(data.Message);
              this._commonService.ScrollingTop();
              this.getList();
            }
          },
          error => {
            this._alertService.error(error.message);
          }
        );
      }
    });
  }

  sortData(event) {
    this.indexModel.OrderBy = event.active;
    this.indexModel.OrderByAsc = event.direction == AppSetting.orderByDscAsc ? AppSetting.orderByAsc : AppSetting.orderByDsc;
    this.indexModel.IsPostBack = true;
    this.getList();
  }

  onPaginateChange(event) {
    this.indexModel.Page = event.pageIndex + 1;
    this.indexModel.PageSize = event.pageSize;
    this.indexModel.IsPostBack = true;
    this.getList();
  }

  SearchByKeyword(searchValue: GlobalFilterModel) {
    this.indexModel.Search = searchValue.search;
    this.indexModel.AdvanceSearchModel = { ActiveStatus: searchValue.ActiveStatus }

    this.getList();
  }

  ClearSearch() {
    this.indexModel = new IndexModel();
    this.getList();
  }

  OpenAddUpdateUserDialog(Id) {
    const _dialogRef = this._dialog.open(AddUpdateloginuserCreationComponent, {
      width: "1000px",
      data: { Id },
      disableClose: true
    });
    _dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        this.getList();
      }
    });
  }

  OpenUserDetailDialog(Id) {
    // const _dialogRef = this._dialog.open(ProjectMappingDetailDialogComponent, {
    //   width: "1000px",
    //   data: { Id },
    //   disableClose: true
    // });
    // _dialogRef.afterClosed().subscribe((result: boolean) => {
    //   if (result) {
    //     this.getList();
    //   }
    // });
  }

  //#endregion

}
