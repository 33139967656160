import { IndexModel } from './common.model';

// export class GlobalFilterSearchModel {
//   constructor() {
//     this.indexModel = new IndexModel();
//   }

//   CommunicationTypeCode: string[] | number[];
//   AdminDepartmentCode: string[] | number[];
//   DepartmentCode: string[] | number[];
//   OfficeCode: string[] | number[];
//   DistrictCode: string[] | number[];
//   ProjectCode: string[] | number[];
//   WorkOrderCode: string[] | number[];
//   Email: string;
//   ActiveView: boolean = true;
//   CreatedFrom: Date | string;
//   CreatedTo: Date | string;
//   indexModel: IndexModel;
// }

export class GlobalFilterSearchModel {
	constructor() {
		this.indexModel = new IndexModel();
		this.ActiveView = true;
	}
	LoginUserCode: number;
	CommunicationTypeEnum: string;
	ReferenceNumber: string;
	DepartmentCode: number | string | any;
	FromDate: string;
	ToDate: string;
	Search: string;
	Status: string;
	indexModel: IndexModel;
	CommunicationTypeCode: number | string | any;
	AdminDepartmentCode: number | string | any;
	OfficeCode: number | string | any;
	DistrictCode: number | string | any;
	ProjectCode: number | null | string | any;
	WorkOrderCode: number | string | any;
	ActiveView: boolean;
	VendorCode: number | string | any;
	OfficeStaffCode: number | string | any;
	BankCode: number | string | any;
	VendorUserCode: number | string | any;
}