import { Component, OnInit, Inject } from '@angular/core';
import { UserViewModel } from 'src/app/shared/model/user.model';
import { AlertService } from 'src/app/shared/service/alert.service';
import { UserService } from 'src/app/shared/service/user.service';
import { AuthenticationService } from 'src/app/shared/service/authentication.service';
import { MatDialogRef, MAT_DIALOG_DATA, DateAdapter, MAT_DATE_FORMATS } from '@angular/material';
import { ActivatedRoute } from '@angular/router';
import { ProjectMappingService } from 'src/app/shared/service/project-mapping.service';
import { AppDateAdapter, APP_DATE_FORMATS } from 'src/app/shared/model/format-datepicker';

@Component({
  selector: 'app-project-mapping-detail-dialog',
  templateUrl: './project-mapping-detail-dialog.component.html',
  styleUrls: ['./project-mapping-detail-dialog.component.scss'],
  providers: [
    { provide: DateAdapter, useClass: AppDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS }
  ]
})
export class ProjectMappingDetailDialogComponent implements OnInit {
  loginUserCode: number;
  model: UserViewModel;
  recordId: number = 0;
  heading: string;

  constructor(
    private readonly _alertService: AlertService,
    private readonly _projectMappingService: ProjectMappingService,
    private readonly _userDetail: UserService,
    private readonly _authService: AuthenticationService,
    public _dialogRef: MatDialogRef<ProjectMappingDetailDialogComponent>,
    private readonly _route: ActivatedRoute,
    @Inject(MAT_DIALOG_DATA) public data: any) {
      
    this.model = new UserViewModel();
    if (data) {
      if (data.Id) {
        this.recordId = data.Id;

      }
    }
    this.loginUserCode = this._authService.UserCode;
    this.heading = (this.recordId == this.loginUserCode ? "My" : "Office Staff") + " Profile";
  }

  ngOnInit() {
    this.getDetail();
  }

  getDetail() {
    this._userDetail.Detail(this.recordId).subscribe(
      data => {
        
        if (data.IsSuccess) {
          this.model = <UserViewModel>data.Data;
        }
      },
      error => {
        this.model = new UserViewModel();
        this._alertService.error(error.message);
      }
    );
  }

  closeDialog(): void {
    this._dialogRef.close();
  }


}
