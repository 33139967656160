import { AuthenticationService } from './authentication.service';
import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { IndexModel } from '../model/common.model';
import { AppSetting } from '../model/appsetting.model';
import { ProjectSearchModel, ProjectModel, ProjectClearModel } from 'src/app/shared/model/project.model';
import { UserTypeEnum } from '../enum/fixed-values.enum';

@Injectable({
    providedIn: 'root'
})

export class ProjectService {

    constructor(private readonly _baseService: BaseService, private readonly _authService: AuthenticationService) { }

    GetAllNotAssignedProjects(loginUserCode: number) {
        return this._baseService.post(AppSetting.NotAssignedProjectListUrl + "?loginUserCode=" + loginUserCode, null);
    }

    GetList(loginUserCode: number, model: IndexModel) {
        return this._baseService.post(AppSetting.ProjectListUrl + "?loginUserCode=" + loginUserCode, model);
    }

    AddUpdate(id: number, model: ProjectModel) {
        return this._baseService.post(AppSetting.ProjectAddUpdateUrl, model);
    }

    Detail(id: number) {
        return this._baseService.get(AppSetting.ProjectDetailUrl + id);
    }

    ChangeAcceptMethod(id: number) {
        return this._baseService.get(AppSetting.ChangeAcceptMethodChangeUrl + id);
    }

    ChangeActiveStatus(id: number) {
        return this._baseService.get(AppSetting.ProjectActiveStatusChangeUrl + id);
    }

    ChangeDeleteStatus(id: number) {
        return this._baseService.get(AppSetting.ProjectDeleteStatusChangeUrl + id);
    }

    IsProjectNameExist(projectName: string) {
        return this._baseService.get(AppSetting.IsProjectNameExistUrl + projectName);
    }

    GetProjectPDFDownload(loginUserCode: number, model: IndexModel) {
        return this._baseService.post(AppSetting.GetProjectPDFDownload + "?loginUserCode=" + loginUserCode, model);
    }

    GetProjectBySearch(projectSearchmodel: ProjectSearchModel) {
        projectSearchmodel.LoginUserCode = this._authService.UserCode;
        return this._baseService.post(AppSetting.ProjectListySearchUrl, projectSearchmodel);

    }


    canUserTypeCreateProject() {
        if (this._authService.BaseUserType == UserTypeEnum.DREC || this._authService.BaseUserType == UserTypeEnum.HOO
            || this._authService.BaseUserType == UserTypeEnum.DOS || this._authService.BaseUserType == UserTypeEnum.HOD
            || this._authService.BaseUserType == UserTypeEnum.DRECADLC || this._authService.BaseUserType == UserTypeEnum.HOOADLC
            || this._authService.BaseUserType == UserTypeEnum.DOSADLC || this._authService.BaseUserType == UserTypeEnum.VNDR
            || this._authService.BaseUserType == UserTypeEnum.VNDRUSER || this._authService.BaseUserType == UserTypeEnum.SADM || this._authService.BaseUserType == UserTypeEnum.ADM) {
            return true;
        } else {
            return false;
        }
    }

    ProjectClear(projectClearmodel: ProjectClearModel) {
        return this._baseService.post(AppSetting.ProjectClear, projectClearmodel);

    }
}