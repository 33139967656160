export class NotificationTemplateTypeModel {
    Id: number;
    Name: string;
    NameHindi: string;
    TransactionENUM: number;
    IsMail: boolean;
    IsSMS: boolean;

}

export class NotificationTemplateTypeViewModel {
    Id: number;
    Name: string;
    NameHindi: string;
    TransactionENUM: number;
    IsMail: boolean;
    IsSMS: boolean;
    Code: number;
    IsActive: boolean;
    IsDelete: boolean;
    CreatedDate: Date
    CreatedBy: number;
    ModifiedDate: Date
    ModifiedBy: number;

}


export class NotificationMappingModel {
    Id: number;
    TransactionCode: number|string;
    TemplateCode: number |string;


}

export class NotificationMappingViewModel {
    Id: number;
    TransactionCode: number;
    TemplateCode: number;
    IsSMS: boolean;
    IsMail: boolean;
    IsActive: boolean;
    IsDelete: boolean;
    CreatedDate: Date;
    CreatedBy: number;
    ModifiedDate: Date;
    ModifiedBy: number;
    TransactionName: string;
    TemplateName: string;
    SMSContent: string;

}