import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from "@angular/router";
import { AuthenticationService } from 'src/app/shared/service/authentication.service';
import { AppComponent } from 'src/app/app.component';
import { AlertService } from 'src/app/shared/service/alert.service';
import { isNullOrUndefined } from 'util';
import { CommunicationModel } from 'src/app/shared/model/communication.model';
import { CommunicationService } from 'src/app/shared/service/communication.service';
import { MatDialog } from '@angular/material';
import { ChangeCommunicationOfficeDialogComponent } from '../change-communication-office-dialog/change-communication-office-dialog.component';
import { UserTypeEnum, StatusEnum } from 'src/app/shared/enum/fixed-values.enum';
import { CommonService } from 'src/app/shared/service/common.service';

@Component({
  selector: 'app-communication-detail',
  templateUrl: './communication-detail.component.html',
  styleUrls: ['./communication-detail.component.scss']
})

export class CommunicationDetailComponent implements OnInit {

  //#region <<Variable>>
  loginBaseUserType: string;
  model: CommunicationModel;
  RecordId: number = 0;
  cId: number = 0;
  IsHideDeptInternal_VendorCommBtn: boolean = false;
  userTypeEnum = UserTypeEnum;
  //#endregion

  //#region <<Constructor>>
  constructor(
    private readonly appComponent: AppComponent,
    public readonly _commonService: CommonService,
    private readonly _alertService: AlertService,
    private _route: ActivatedRoute,
    private readonly _dialog: MatDialog,
    private readonly _communicationService: CommunicationService,
    private readonly _authService: AuthenticationService
  ) {
    this.model = new CommunicationModel();
    if (!isNullOrUndefined(this._route.snapshot.params.id)) {
      this.RecordId = this._route.snapshot.params.id;
    }
    if (!isNullOrUndefined(this._route.snapshot.params.cid)) {
      this.cId = this._route.snapshot.params.cid;
    }
    this.loginBaseUserType = this._authService.BaseUserType;
  }
  //#endregion

  ngOnInit() {
    if (this.RecordId != 0) {
      this.getDetail();
    }
  }

  getDetail() {
    this._communicationService.Detail(this.RecordId).subscribe(
      data => {
        if (data.IsSuccess) {
          this.model = <CommunicationModel>data.Data;
          if (this.model.Status == StatusEnum.SENT && this.loginBaseUserType != UserTypeEnum.VNDR && this.loginBaseUserType != UserTypeEnum.VNDRUSER) {
            this.model.Status = StatusEnum.RECEIVED;
          }

          if (this.model.Status == StatusEnum.RECEIVED || this.model.Status == StatusEnum.RETURN || this.model.Status == StatusEnum.ACCEPT || this.model.Status == StatusEnum.CLOSE || this.model.Status == StatusEnum.DISPOSED || this.model.Status == StatusEnum.REJECT) {
            this.IsHideDeptInternal_VendorCommBtn = true;
          }
          else {
            this.IsHideDeptInternal_VendorCommBtn = false;
          }
          if (this.cId) {
            this.appComponent.setPageLayout("Communication Detail :", "keyboard_backspace", "Back To Previous communication-list",
              `communications-list/communication-detail/${this.cId}`)
          } else {
            this.appComponent.setPageLayout("Communication Detail :", "keyboard_backspace", "Back To List", "communication");
          }

        }
      },
      error => {
        this._alertService.error(error.message);
      }
    );
  }

  OpenChangeOfficeDialog(Id) {
    const _dialogRef = this._dialog.open(ChangeCommunicationOfficeDialogComponent, {
      width: "500px",
      data: { Id },
      disableClose: true
    });
    _dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        this.getDetail();
      }
    });
  }

}
