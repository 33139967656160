import { IndexModel } from "../common.model";

export class UserPreferLookupViewModel {
  LookupCode: number;
  PreferOfficeCode: number | null;
  PreferVendorCode: number | null;
  UserOfficeCode: number | null;
  CreatedBy: number;
  CreatedOn: string;
  DepartmentCodes: string;
  OfficeCodes: string;
  VendorCodes: string;
  IsActive: boolean;
  POfficeName: string;
  PDepartment: string;
  PDistrict: string;
  UOfficeName: string;
  UDepartment: string;
  UDistrict: string;
  CompanyName: string;

  DepartmentCodeIds: string[];
  OfficeCodeIds: string[];
  VendorCodeIds: string[];
  DepartmentCode: number | string;
  SSOID: number | string;
}
export class CCSearchViewModel {
  UserOfficeCode: string;
  IsPrefered: boolean;
  DistrictCode: number | null;
  DepartmentCode: string | number;
  indexModel: IndexModel;
  constructor() {
    this.indexModel = new IndexModel();
    this.UserOfficeCode = "";
    this.IsPrefered = true;
    this.DistrictCode = 0;
    this.DepartmentCode = 0;
  }
}
