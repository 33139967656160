export class UserPermission {
}

export class DefaultPermissionViewModel {
    PermissionCode: number;
    PermissionTitle: string;
    ApplicationCode: string;
    PermissionTypeCode: number;
    PermissionTypeName: string;
    MenuCode: number;
    MenuTitle: string;
    DefaultPermissionId: number;
    UserType: string;
    AddPermission: boolean;
    EditPermission: boolean;
    DeletePermission: boolean;
    ViewPermission: boolean;
    IsUserManual: boolean;
}

export class UserPermissionViewModel {
    PermissionCode: number;
    PermissionTitle: string;
    ApplicationCode: string;
    PermissionTypeCode: number;
    PermissionTypeName: string;
    MenuCode: number;
    MenuTitle: string;
    UserPermissionId: number;
    UserCode: number;
    AddPermission: boolean;
    EditPermission: boolean;
    DeletePermission: boolean;
    ViewPermission: boolean;
}

export class AssignedUserPermissionViewModel {
    PermissionCode: number;
    PermissionTitle: string;
    ApplicationCode: string;
    DisplayTitle: string;
    AppIcon: string;
    PermissionTypeCode: number;
    PermissionTypeName: string;
    MenuCode: number;
    MenuTitle: string;
    PermissionUrl: string;
    UserPermissionId: number;
    IsLoadPermission: boolean;
    AddPermission: boolean;
    EditPermission: boolean;
    DeletePermission: boolean;
    ViewPermission: boolean;
    HasExtraAuthentication: boolean;
    MenuIcon: string;
    DefaultUrl: string;

    UserCode: number | null;
}

export class AdditionalUserPermissionModel {
    Id: number;
    FromUserCode: number | string;
    ToUserCode: number | string;
    FromDate: string | Date;
    ToDate: string | Date;
    Remark: string;
    CreatedBy: number | null;
    CreatedOn: string | Date;
    ModifiedBy: number | null;
    ModifiedOn: string | Date;
    IsActive: boolean | null;
    IsDelete: boolean | null;
    IsLocked: boolean | null;
    filepath: string;

    UserTypeCode: number | string;
    FromUserDepartmentCode: number | string;
    FromUserDistrictCode: number | string;
    FromUserOfficeCode: number | string;
    ToUserDepartmentCode: number | string;
    ToUserDistrictCode: number | string;
    ToUserOfficeCode: number | string;
}




export class AdditionalUserPermissionViewModel {
    Id: number;
    FromUserCode: number | null;
    ToUserCode: number | null;
    Remark: string;
    FromDate: string;
    ToDate: string;
    CreatedOn: string;
    ModifiedOn: string;
    IsActive: boolean | null;
    IsDelete: boolean | null;
    IsLocked: boolean;
    CreatedBy: number | null;
    ModifiedBy: number | null;
    filepath: string;
    CreatedByName: string;
    ModifiedByName: string;
    FromUserName: string;
    FromUserType: string;
    FromUserTypeCode: number;
    FromBaseUserType: string;
    FromBaseUserTypeCode: number;
    FromUserDeptCodes: string;
    FromUserDistCodes: string;
    FromUserOfficeCodes: string;
    ToUserName: string;
    ToUserType: string;
    ToUserTypeCode: number;
    ToBaseUserType: string;
    ToBaseUserTypeCode: number;
    ToUserDeptCodes: string;
    ToUserDistCodes: string;
    ToUserOfficeCodes: string;
}

export class SpecificPermissionUserModel {
    UserCode: number;
    UserType: string;
    UserTypeTitle: string;
    UserName: string;
    PermissionCode: number;
}

export class DefaultPermissionUserTypeModel {
    UserTypeCode: number;
    UserType: string;
    UserTypeTitle: string;
    PermissionCode: number;
}

export class UserSpecificPagePermissionModel {
    UserPermissionId: number;
    UserCode: number;
    ApplicationTitle: string;
    PermissionTitle: string;
    AddPermission: boolean;
    EditPermission: boolean;
    DeletePermission: boolean;
    ViewPermission: boolean;

    Permissions: string;
}


export class UserAdditionalUserTypePostModel {
    UserCode: number;
    UserTypeCode: number[] | string[];
}

export interface UserAdditionalUserTypeModel {
    UserCode: number;
    UserType: AdditionalUserTypeModel[];
}

export interface AdditionalUserTypeModel {
    UserTypeCode: number;
    UserType: string;
    DisplayUserTypeTitle: string;
    IsSelected: boolean;
}