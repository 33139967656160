import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource, MatDialog, MatSort, MatPaginator, MatRadioChange } from "@angular/material";
import { IndexModel } from 'src/app/shared/model/common.model';
import { ColumnHeaderModel, DdlItemModel, DDLModel } from 'src/app/shared/model/commonddl.model';
import { AppComponent } from 'src/app/app.component';
import { AlertService } from 'src/app/shared/service/alert.service';
import { CommonService } from 'src/app/shared/service/common.service';
import { ConfirmationDialogComponent } from 'src/app/confirmation-dialog/confirmation-dialog.component';
import { GlobalMessagesModel } from 'src/app/shared/model/common-messages.model';
import { AppSetting } from 'src/app/shared/model/appsetting.model';
import { CommunicationModel, CommunicationReportSearchViewModel } from 'src/app/shared/model/communication.model';
import { CommunicationService } from 'src/app/shared/service/communication.service';
import { Router, ActivatedRoute } from '@angular/router';
import { isNullOrUndefined } from 'util';
import { UserViewModel } from 'src/app/shared/model/user.model';
import { AuthenticationService } from 'src/app/shared/service/authentication.service';
import { UserTypeEnum, StatusEnum } from 'src/app/shared/enum/fixed-values.enum';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { ReportFilterModel } from 'src/app/shared/model/report-filter.model';
/* new imports*/

import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { DatePipe } from '@angular/common';
import { UserService } from 'src/app/shared/service/user.service';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

declare const require: any;
const jsPDF = require('jspdf');
require('jspdf-autotable');

@Component({
  selector: 'app-new-old-communication-report',
  templateUrl: './new-old-communication-report.component.html',
  styleUrls: ['./new-old-communication-report.component.scss']
})

export class NewOldCommunicationReportComponent implements OnInit, AfterViewInit {
  isDeptDisabled = false;
  model: CommunicationModel[];
  modellist: CommunicationModel;
  loginUserCode: number;
  loginBaseUserType: string;
  userTypeVNDR: string = UserTypeEnum.VNDR;
  indexModel: IndexModel;
  dataSource: any;
  totalRecords: number;
  ddlStatus: DdlItemModel[] = [];
  status: string = 'new';
  search: string = '';
  reportTitle: string = "";
  reportFileName: string = "";
  searchModel = this._commonService.modelSetGet(new CommunicationReportSearchViewModel()) as CommunicationReportSearchViewModel;
  reportModel: ReportFilterModel;// = new ReportFilterModel();
  ddlList: DDLModel;
  loginUserDetail: UserViewModel;
  //add new variables for sent to department
  departmentCode: number;
  projectCode: number;
  workOrderCode: number;
  // todayDate: Date = new Date((new Date().getTime()));
  todayDate: '';

  displayedColumns: string[] = ["index", "Subject", "CommunicationType", "DistrictName", "VendorContactPersonName", "DepartmentName", "OfficeName", "DRECADLCUserDetail", "ProjectName", "WorkOrderTitle", "CreatedOn", "Status"];  //"IsActive"
  columnsToDisplay: string[] = this.displayedColumns.slice();
  ViewdisplayedColumns: ColumnHeaderModel[] = [
    { Value: "Subject", Text: "Subject" },
    { Value: "CommunicationType", Text: "Communication Type" },
    { Value: "DistrictName", Text: "District Name" },
    { Value: "VendorContactPersonName", Text: "Vendor" },
    { Value: "DepartmentName", Text: "Department" },
    { Value: "OfficeName", Text: "Office" },
    { Value: "ProjectName", Text: "Project" },
    { Value: "WorkOrderTitle", Text: "WorkOrder" },
  ];
  SearchdisplayedColumns: ColumnHeaderModel[] = [
    { Value: "DepartmentCode", Text: "Department" },
    { Value: "ProjectCode", Text: "Project" },
    { Value: "WorkOrderCode", Text: "Work Order" },
    { Value: "Status", Text: "Status" },
    { Value: "fromDate", Text: "fromDate" },
    { Value: "toDate", Text: "toDate" },
    { Value: "userCode", Text: "User Name" },
    { Value: "UserType", Text: "User Role" },

  ];
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  fromDate: Date | string;
  toDate: Date | string;

  constructor(private readonly appComponnet: AppComponent,
    private readonly _alertService: AlertService,
    private readonly _router: Router,
    private _route: ActivatedRoute,
    private readonly _communicationService: CommunicationService,
    private readonly _dialog: MatDialog,
    public readonly _commonService: CommonService,
    private readonly _authService: AuthenticationService,
    private readonly _userService: UserService,
    public datepipe: DatePipe
  ) {
    this.indexModel = new IndexModel();
    this.reportModel = new ReportFilterModel();
    this.searchModel.Status = '';
    this.status = this.searchModel.Status;

    this.loginUserCode = this._authService.UserCode;
    this.loginBaseUserType = this._authService.BaseUserType;
    this.loginUserDetail = this._authService.GetCurrentUserDetail().UserViewModel;
    this.GetDDLList();
    this.SetPageHeader();

  }

  ngOnInit(): void {
    this.bindStatus();
    this.searchModel.indexModel = new IndexModel();
    this.getList();
  }

  ngAfterViewInit(): void {
    this.getList();
  }

  bindStatus() {
    var item4: DdlItemModel = new DdlItemModel();
    item4.Text = StatusEnum.RETURN;
    item4.Value = StatusEnum.RETURN;
    this.ddlStatus.push(item4);

    var item2: DdlItemModel = new DdlItemModel();
    item2.Text = StatusEnum.ACCEPT;
    item2.Value = StatusEnum.ACCEPT;
    this.ddlStatus.push(item2);

    var item3: DdlItemModel = new DdlItemModel();
    item3.Text = StatusEnum.REJECT;
    item3.Value = StatusEnum.REJECT;
    this.ddlStatus.push(item3);

    var item1: DdlItemModel = new DdlItemModel();
    item1.Text = (this.loginBaseUserType == UserTypeEnum.VNDR) ? StatusEnum.SENT : StatusEnum.RECEIVED;
    item1.Value = StatusEnum.SENT;
    this.ddlStatus.push(item1);

  }

  onDepartmentChange() {
    this.getOfficeList();
  }

  private getOfficeList() {
    this.ddlList.ddlOffice = [];
    let deptCodes = this.searchModel.DepartmentCode.toString();
    let distCodes = '0';
    this._userService.GetUserOfficeForUserCreation(deptCodes, distCodes).subscribe((data) => {
      if (data.IsSuccess) {
        this.ddlList.ddlOffice = <DdlItemModel[]>data.Data;
      }
    }, (error) => {
      this._alertService.error(error.message);
    });
  }

  getList() {
    let status = (this.searchModel.Status).toLowerCase();
    this.SetPageHeader();
    this.searchModel.DepartmentCode = this.searchModel.DepartmentCode ? this.searchModel.DepartmentCode.toString() : '';
    this.searchModel.ProjectCode = this.searchModel.ProjectCodes ? this.searchModel.ProjectCodes.toString() : '';
    this.searchModel.WorkOrderCode = this.searchModel.WorkOrderCodes ? this.searchModel.WorkOrderCodes.toString() : '';
    this.searchModel.UserType = this.searchModel.UserTypes ? this.searchModel.UserTypes.toString() : '';
    this.searchModel.UserCode = this.searchModel.UserTypes ? this.searchModel.UserCodes.toString() : '';
    this.searchModel.OfficeCode = Number(this.searchModel.OfficeCode);
    this.searchModel.VendorCode = +this.searchModel.VendorCode;
    this.searchModel.CommunicationTypeCode = Number(this.searchModel.CommunicationTypeCode)

    if (this.searchModel.IsExportToExcel) {
      this.exportToCSV();
    }
    else {
      if (status.toLowerCase() == "movedept") {
        this._communicationService.GetMooveDepartmentOfficeCommunicationList(this.loginUserCode, this.reportModel).subscribe(
          data => {
            if (data.IsSuccess) {
              this.model = <CommunicationModel[]>data.Data;
              this.dataSource = new MatTableDataSource<CommunicationModel>(this.model);
              this.totalRecords = data.Data.length;
            } else {
              this._alertService.error(data.Message);
            }
          },
          error => {
            this._alertService.error(error.message);
          }
        );
      }
      else if (status.toLowerCase() == "forwarded") {
        this._communicationService.GetForwardedCommunicationReport(this.loginUserCode, this.status, this.reportModel).subscribe(
          data => {
            if (data.IsSuccess) {
              this.model = <CommunicationModel[]>data.Data.Data;
              this.model.forEach(element => {
                if (element.Status != StatusEnum.SENT) {
                  element.IsView_BtnActionHistory = true;
                }
                if ((this.loginBaseUserType == UserTypeEnum.DREC || this.loginBaseUserType == UserTypeEnum.DRECADLC) && element.Status == StatusEnum.SENT) {
                  element.IsView_BtnAction = true;
                }
                if (this.loginBaseUserType != UserTypeEnum.VNDR && this.loginBaseUserType != UserTypeEnum.VNDRUSER && element.Status == StatusEnum.SENT) {
                  element.Status = StatusEnum.RECEIVED;
                }
              });
              this.dataSource = new MatTableDataSource<CommunicationModel>(this.model);
              this.totalRecords = data.Data.TotalRecords;
              if (!this.indexModel.IsPostBack) {
                this.dataSource.paginator = this.paginator;
                this.dataSource.sort = this.sort;
              }
            } else {
              this._alertService.error(data.Message);
            }
          },
          error => {
            this._alertService.error(error.message);
          }
        );
      }
      else {
        this._communicationService.GetNewOldCommunicationList(this._commonService.modelSetGet(this.searchModel, true)).subscribe(
          data => {
            if (data.IsSuccess) {
              this.model = <CommunicationModel[]>data.Data.Data;

              // this.model.forEach(element => {
              //   if (element.Status != StatusEnum.SENT) {
              //     element.IsView_BtnActionHistory = true;
              //   }
              //   if ((this.loginBaseUserType == UserTypeEnum.DREC || this.loginBaseUserType == UserTypeEnum.DRECADLC) && element.Status == StatusEnum.SENT) {
              //     element.IsView_BtnAction = true;
              //   }
              //   if (this.loginBaseUserType != UserTypeEnum.VNDR && this.loginBaseUserType != UserTypeEnum.VNDRUSER && element.Status == StatusEnum.SENT) {
              //     element.Status = StatusEnum.RECEIVED;
              //   }
              // });

              this.dataSource = new MatTableDataSource<CommunicationModel>(this.model);
              this.totalRecords = data.Data.TotalRecords;
              if (!this.indexModel.IsPostBack) {
                this.dataSource.paginator = this.paginator;
                this.dataSource.sort = this.sort;
              }
            } else {
              this._alertService.error(data.Message);
            }
          },
          error => {
            this._alertService.error(error.message);
          }
        );
      }
    }
  }

  GetDDLList() {
    this._commonService
      .GetAllDDL(AppSetting.ReportFilterDropdownKeys, this.loginUserCode)
      .subscribe(
        (data) => {
          if (data.IsSuccess) {
            this.ddlList = <DDLModel>data.Data;
            /*
            added by pravesh
            add new Key in regarding this in appsetting model ddlDisplayUserTypeTitle
            */
            // if (this.ddlList) {
            //   this.ddlList.ddlDisplayUserTypeTitle = this.ddlList.ddlDisplayUserTypeTitle.filter(function (item) {
            //     return (item.Value == UserTypeEnum.HOD || item.Value == UserTypeEnum.DRECADLC || item.Value == UserTypeEnum.DREC || item.Value == UserTypeEnum.DLO || item.Value == UserTypeEnum.PROJ);
            //   });
            // }
          }
        },
        (error) => {
          this._alertService.error(error.message);
        }
      );
  }

  BindUserList() {
    this._commonService
      .GetAllDDL(AppSetting.ddlVendorUser, this.loginUserCode)
      .subscribe(
        (data) => {
          if (data.IsSuccess) {
            var ddl = <DDLModel>data.Data;
            this.ddlList.ddlVendorUser = ddl.ddlVendorUser;
          }
        },
        (error) => {
          this._alertService.error(error.message);
        }
      );
  }
  GetProjectListByDepartmentWise() {
    //var userDept = (this.loginUserDetail.DepartmentCodes == null) ? 0 : this.loginUserDetail.DepartmentCodes;
    if (!isNullOrUndefined(this.reportModel.DepartmentCode) && this.reportModel.DepartmentCode.length > 0) {
      this._commonService.GetDDLMultipleValue(AppSetting.ddlProject, this.reportModel.DepartmentCode.toString(), this.loginUserDetail.UserCode).subscribe(
        data => {
          if (data.IsSuccess) {
            var ddl = <DDLModel>data.Data;
            this.ddlList.ddlProject = ddl.ddlProject;
          }
        },
        error => {
          this._alertService.error(error.message);
        }
      );
    }
  }

  GetWorkOrderByproject() {
    if (!isNullOrUndefined(this.reportModel.ProjectCode && this.reportModel.ProjectCode.length > 0)) {
      //this._commonService.GetDDL(AppSetting.ddlWorkOrder,this.reportModel.ProjectCode,this.loginUserCode)
      this._commonService.GetDDLMultipleValue(AppSetting.ddlWorkOrder, this.reportModel.ProjectCode.toString(), this.loginUserDetail.UserCode)
        .subscribe(

          (data) => {
            if (data.IsSuccess) {
              var ddl = <DDLModel>data.Data;
              this.ddlList.ddlWorkOrder = ddl.ddlWorkOrder;
            }
          },
          (error) => {
            this._alertService.error(error.message);
          }
        );
    } else {
      this.ddlList.ddlWorkOrder = null;
    }
  }

  onClearclick() {
    this.searchModel = new CommunicationReportSearchViewModel();
    this.getList();
  }

  //Report

  completeModel: CommunicationModel[];
  completeIndexModel: IndexModel;

  getCompleteData() {
    this.completeIndexModel = new IndexModel();
    this.completeIndexModel.Page = 1;
    this.completeIndexModel.PageSize = this.totalRecords;
    this.completeIndexModel.IsPostBack = true;
    if (this.status.toLowerCase() == "movedept") {
      this._communicationService.GetMooveDepartmentOfficeCommunicationList(this.loginUserCode, this.reportModel).subscribe(
        (completeData: any) => {
          if (completeData.IsSuccess) {
            this.completeModel = <CommunicationModel[]>completeData.Data;
            this.totalRecords = completeData.Data.TotalRecords;
          } else {
            this._alertService.error(completeData.Message);
          }
        },
        error => {
          this._alertService.error(error.message);
        }
      );
    }
    else if (this.status.toLowerCase() == "forwarded") {
      this._communicationService.GetForwardedCommunicationReport(this.loginUserCode, this.status, this.reportModel).subscribe(
        (completeData: any) => {
          if (completeData.IsSuccess) {
            this.completeModel = <CommunicationModel[]>completeData.Data.Data;
            this.totalRecords = completeData.Data.TotalRecords;
          } else {
            this._alertService.error(completeData.Message);
          }
        },
        error => {
          this._alertService.error(error.message);
        }
      );
    }
    else {
      this._communicationService.GetNewOldCommunicationList(this._commonService.modelSetGet(this.searchModel, true)).subscribe(
        (completeData: any) => {
          if (completeData.IsSuccess) {
            this.completeModel = <CommunicationModel[]>completeData.Data.Data;
            this.totalRecords = completeData.Data.TotalRecords;
          } else {
            this._alertService.error(completeData.Message);
          }
        },
        error => {
          this._alertService.error(error.message);
        }
      );
    }
  }

  rowsNew: any = [];



  buttonClickExportAsPDF(): void {
    this.getCompleteData();
    setTimeout(() => {
      this.download();
      this.exportAsPdf();
    }, 1000);
  }

  public exportAsPdf(): void {
    var doc = new jsPDF('p', 'pt');
    if (this.status.toLowerCase() == "newsent") {
      var header = function (data) {
        doc.setFontSize(10);
        doc.setTextColor(40);
        doc.text("New Sent communication Report", data.settings.margin.left, 50);
        doc.text("Date Time : " + new Date().toLocaleDateString() + " " + new Date().toLocaleTimeString(), data.settings.margin.left, 800);
      };
    }
    else if (this.status.toLowerCase() == "oldsent") {
      var header = function (data) {
        doc.setFontSize(10);
        doc.setTextColor(40);
        doc.text("Sent communication Report", data.settings.margin.left, 50);
        doc.text("Date Time : " + new Date().toLocaleDateString() + " " + new Date().toLocaleTimeString(), data.settings.margin.left, 800);
      };
    }
    else if (this.status.toLowerCase() == "newreturn") {
      var header = function (data) {
        doc.setFontSize(10);
        doc.setTextColor(40);
        doc.text("New Return communication Report", data.settings.margin.left, 50);
        doc.text("Date Time : " + new Date().toLocaleDateString() + " " + new Date().toLocaleTimeString(), data.settings.margin.left, 800);
      };
    }
    else if (this.status.toLowerCase() == "newreject") {
      var header = function (data) {
        doc.setFontSize(10);
        doc.setTextColor(40);
        doc.text("New Reject communication Report", data.settings.margin.left, 50);
        doc.text("Date Time : " + new Date().toLocaleDateString() + " " + new Date().toLocaleTimeString(), data.settings.margin.left, 800);
      };
    }
    else if (this.status.toLowerCase() == "movedept") {
      var header = function (data) {
        doc.setFontSize(10);
        doc.setTextColor(40);
        doc.text("Communication move other department & office Report", data.settings.margin.left, 50);
        doc.text("Date Time : " + new Date().toLocaleDateString() + " " + new Date().toLocaleTimeString(), data.settings.margin.left, 800);
      };
    }
    else if (this.status.toLowerCase() == "oldreturn") {
      var header = function (data) {
        doc.setFontSize(10);
        doc.setTextColor(40);
        doc.text("Old Return communication Report", data.settings.margin.left, 50);
        doc.text("Date Time : " + new Date().toLocaleDateString() + " " + new Date().toLocaleTimeString(), data.settings.margin.left, 800);
      };
    }
    else if (this.status.toLowerCase() == "forwarded") {
      var header = function (data) {
        doc.setFontSize(10);
        doc.setTextColor(40);
        doc.text("Forward Communication Report", data.settings.margin.left, 50);
        doc.text("Date Time : " + new Date().toLocaleDateString() + " " + new Date().toLocaleTimeString(), data.settings.margin.left, 800);
      };
    }
    else {
      var header = function (data) {
        doc.setFontSize(10);
        doc.setTextColor(40);
        doc.text("Pending Communication Report List", data.settings.margin.left, 50);
        doc.text("Date Time : " + new Date().toLocaleDateString() + " " + new Date().toLocaleTimeString(), data.settings.margin.left, 800);
      };
    }
    var headers = [{
      title: "#",
      dataKey: "SNo"
    }, {
      title: "Subject",
      dataKey: "Subject"
    },
    {
      title: "Communication Type",
      dataKey: "CommunicationType"
    },
    {
      title: "DistrictName",
      dataKey: "DistrictName"
    },
    {
      title: "VendorContactPersonName",
      dataKey: "Vendor"
    },
    {
      title: "Department Name",
      dataKey: "DepartmentName"
    },
    {
      title: "Office Name",
      dataKey: "OfficeName"
    },
    {
      title: "Project",
      dataKey: "Project"
    },
    {
      title: "WorkOrder",
      dataKey: "WorkOrder"
    },
    {
      title: "Status",
      dataKey: "Status"
    }
    ];
    doc.autoTable(headers, this.rowsNew, { margin: { top: 70 }, beforePageContent: header });
    doc.save(this.reportFileName + "_Report_" + new Date().toLocaleDateString() + "_" + new Date().toLocaleTimeString() + ".pdf");
  }
  //#region PAgging
  sortData(event) {
    this.searchModel.indexModel.OrderBy = event.active;
    this.searchModel.indexModel.OrderByAsc = event.direction == AppSetting.orderByDscAsc ? AppSetting.orderByAsc : AppSetting.orderByDsc;
    this.searchModel.indexModel.IsPostBack = true;
    this.getList();
  }
  SetPageHeader() {
    let status = (this.searchModel.Status).toLowerCase();
    if (this.loginBaseUserType == UserTypeEnum.VNDR || this.loginBaseUserType == UserTypeEnum.ADM || this.loginBaseUserType == UserTypeEnum.SADM) {
      if (this.searchModel.FromDate == this.todayDate) {
        this.reportTitle = "Communication Report : New " + this.searchModel.Status;
        this.reportFileName = "CommunicationReport_New" + this.searchModel.Status;
      }
      else {
        this.reportTitle = "Communication Report : " + this.searchModel.Status;
        this.reportFileName = "CommunicationReport_" + this.searchModel.Status;
      }

      if (status.toLowerCase() == "movedept") {
        this.reportTitle = "Communication move other department & office Report :";
        this.reportFileName = "MovedDepartment_OfficeComunication";
      }

      else if (status.toLowerCase() == "forwarded") {
        this.reportTitle = "Forward Communication Report :";
        this.reportFileName = "Forward-Comunication";
      }
      // else {
      //   this.reportTitle = "Pending Communication Report List :";
      //   this.reportFileName = "Pending-Communication";
      // }
      this.appComponnet.setPageLayout(this.reportTitle, "", "", "", false, false);
    }
  }
  onPaginateChange(event) {
    this.searchModel.indexModel.Page = event.pageIndex + 1;
    this.searchModel.indexModel.PageSize = event.pageSize;
    this.searchModel.indexModel.IsPostBack = true;
    this.getList();
  }
  //#endregion
  //#region Export Excel Code
  downloadCsv() {
    this.searchModel.IsExportToExcel = true;
    this.getList();
  }
  exportToCSV() {
    this._communicationService.ExportCommunicationData(this._commonService.modelSetGet(this.searchModel, true)).subscribe((data) => {
      if (data.IsSuccess) {
        const downloadLink = document.createElement("a");
        downloadLink.href = data.Data;
        downloadLink.download = "Communication_" + this.status + "_Report_" + new Date().toLocaleDateString() + "_" + new Date().toLocaleTimeString();
        downloadLink.target = "blank";
        downloadLink.click();
      } else {
        this._alertService.error(data.Message);
      }
      this.searchModel.IsExportToExcel = false;
    }, (error) => {
      this._alertService.error(error.message);
    });
  }
  //#endregion
  //#region unused code
  // buttonClickExportAsXLSX(): void {
  //   // this.getCompleteData();
  //   setTimeout(() => {
  //     this.download();
  //     this.exportAsExcelFile();
  //   }, 1000);
  // }
  // public exportAsExcelFile(): void {

  //   const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.rowsNew);
  //   // if (this.status.toLowerCase() == "newsent") {
  //   //   const workbook: XLSX.WorkBook = { Sheets: { 'New Sent communication': worksheet }, SheetNames: ['New Sent communication'] };
  //   //   const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
  //   //   this.saveAsExcelFile(excelBuffer, this.reportFileName);
  //   // }
  //   // else if (this.status.toLowerCase() == "oldsent") {
  //   //   const workbook: XLSX.WorkBook = { Sheets: { 'Old Sent communication': worksheet }, SheetNames: ['Sent communication'] };
  //   //   const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
  //   //   this.saveAsExcelFile(excelBuffer, this.reportFileName);
  //   // }
  //   // else if (this.status.toLowerCase() == "newreturn") {
  //   //   const workbook: XLSX.WorkBook = { Sheets: { 'New Return communication': worksheet }, SheetNames: ['New Return communication'] };
  //   //   const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
  //   //   this.saveAsExcelFile(excelBuffer, this.reportFileName);
  //   // }
  //   // else if (this.status.toLowerCase() == "newreject") {
  //   //   const workbook: XLSX.WorkBook = { Sheets: { 'New Reject communication': worksheet }, SheetNames: ['New Reject communication'] };
  //   //   const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
  //   //   this.saveAsExcelFile(excelBuffer, this.reportFileName);
  //   // }
  //   // else if (this.status.toLowerCase() == "movedept") {
  //   //   const workbook: XLSX.WorkBook = { Sheets: { 'Comm. move dept & office': worksheet }, SheetNames: ['Comm. move dept & office'] };
  //   //   const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
  //   //   this.saveAsExcelFile(excelBuffer, this.reportFileName);
  //   // }
  //   // else if (this.status.toLowerCase() == "oldreturn") {
  //   //   const workbook: XLSX.WorkBook = { Sheets: { 'Old Return communication': worksheet }, SheetNames: ['Old Return communication'] };
  //   //   const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
  //   //   this.saveAsExcelFile(excelBuffer, this.reportFileName);
  //   // }
  //   // else if (this.status.toLowerCase() == "forwarded") {
  //   //   const workbook: XLSX.WorkBook = { Sheets: { 'Forward Communication': worksheet }, SheetNames: ['Forward Communication'] };
  //   //   const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
  //   //   this.saveAsExcelFile(excelBuffer, this.reportFileName);
  //   // }
  //   // else {
  //   //   const workbook: XLSX.WorkBook = { Sheets: { 'Pending Communication': worksheet }, SheetNames: ['Pending Communication'] };
  //   //   const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
  //   //   this.saveAsExcelFile(excelBuffer, this.reportFileName);
  //   // }

  //   const workbook: XLSX.WorkBook = { Sheets: { ' Communication': worksheet }, SheetNames: [' Communication'] };

  //   // if (new Date(this.searchModel.FromDate).toDateString() == new Date(this.todayDate).toDateString()) {
  //   //   const workbook: XLSX.WorkBook = { Sheets: { 'New Communication': worksheet }, SheetNames: ['New Communication'] };

  //   // }
  //   // else {
  //   //   const workbook: XLSX.WorkBook = { Sheets: { ' Communicationdddd': worksheet }, SheetNames: ['Communicationsfsdfsd'] };

  //   // }
  //   const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
  //   this.saveAsExcelFile(excelBuffer, this.reportFileName);
  // }
  // private saveAsExcelFile(buffer: any, fileName: string): void {
  //   const data: Blob = new Blob([buffer], { type: EXCEL_TYPE });
  //   FileSaver.saveAs(data, fileName + '_Report_' + new Date().toLocaleDateString() + "_" + new Date().toLocaleTimeString() + EXCEL_EXTENSION);
  // }
  download() {
    var i = 1;
    this.rowsNew = [];
    //this.completeModel.forEach(element => {
    this.model.forEach(element => {
      var temp = {
        SNo: i, Subject: element.Subject,
        'Communication Type': element.CommunicationType,
        'District Name': element.DistrictName,
        'Vendor': element.VendorContactPersonName,
        'Department': element.DepartmentName,
        'Office': element.OfficeName,
        'Department Office Reception With ADLC': element.DRECADLCUserDetail != null ? element.DRECADLCUserDetail.replace(',', '  \n ') : element.DRECADLCUserDetail,
        Project: element.ProjectName, WorkOrder: element.WorkOrderTitle,
        'Entry Date': this.datepipe.transform(element.CreatedOn, 'dd/MM/yyyy h:mm:ss a'),
        'Status': element.Status
      };
      i = i + 1;
      this.rowsNew.push(temp);
    });
  }
  //#endregion
}
