import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import {
  MatFormFieldModule, MatDatepickerModule, MatNativeDateModule, MatRadioModule, MatInputModule, MatButtonModule, MatCardModule, MatIconModule,
  MatSidenavModule, MatTableModule, MatDialogModule, MatToolbarModule, MatStepperModule, MatTabsModule, MatExpansionModule, MatTooltipModule, MatMenuModule,
  MatSelectModule, MatCheckboxModule, MatPaginatorModule, MatAutocompleteModule, MatSortModule, MatProgressSpinnerModule, MatListModule,
  MatFormFieldControl,
  MatSlideToggleModule,
  MatProgressBarModule
} from '@angular/material';
import { A11yModule } from '@angular/cdk/a11y';
import { CdkStepperModule } from '@angular/cdk/stepper';
import { CdkTableModule } from '@angular/cdk/table';
import { CdkTreeModule } from '@angular/cdk/tree';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
// import { DragDropModule } from '@angular/cdk/drag-drop';

const CommonModules = [
  HttpClientModule,
  ReactiveFormsModule,
  FormsModule,
]

const MatModules = [
  MatFormFieldModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatRadioModule,
  MatInputModule,
  MatButtonModule,
  MatCardModule,
  MatIconModule,
  MatSidenavModule,
  MatTableModule,
  MatDialogModule,
  MatToolbarModule,
  MatStepperModule,
  MatTabsModule,
  MatExpansionModule,
  MatTooltipModule,
  MatMenuModule,
  MatSelectModule,
  MatCheckboxModule,
  MatPaginatorModule,
  MatAutocompleteModule,
  MatSortModule,
  MatProgressSpinnerModule,
  MatListModule,
  A11yModule,
  CdkStepperModule,
  CdkTableModule,
  CdkTreeModule,
  BrowserModule,
  BrowserAnimationsModule,
  MatProgressBarModule
  //DragDropModule,
]

@NgModule({
  imports: [
    CommonModule,
    CommonModules,
    MatModules,
    //MatFormFieldControl,
  ],
  exports: [
    CommonModules,
    MatModules,
    MatSlideToggleModule,
  ]
})

export class AppMaterialModule { }
