export class UserManualModel {
  Id: number;
  UserTypeCode: number | string;;
  UserManualUrl: string;
  SupportContactUrl: string;
  UserCategoryCode: number | string;
  constructor() {
    this.Id = 0;
  }
}

export class UserManualViewModel {
  Id: number;
  UserTypeCode: number;
  UserManualUrl: string;
  SupportContactUrl: string;
  UserTypeTitle: string;
  ModifiedDate: Date | null;
  SSOID: string;
  UserCategory:string;
  constructor() {
    this.UserManualUrl = null;
  }
}

export class CommonDocModel {
  UserManualUrl: string;
  SupportContactUrl: string;
}
