import { Component, OnInit } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { DynamicFieldConfig } from "src/app/shared/model/dynamic-field-config.model";
@Component({
  selector: "app-input",
  template: `
  <div [ngClass]="[field.CSSClass?field.CSSClass:'col l4 xl4 m4 s12']" *ngIf="field.type.toUpperCase()=='TEXTBOX'">
<mat-form-field class="example-full-width" [formGroup]="group" appearance="outline">
<mat-label>{{field.label}}<span *ngIf="field.IsRequired" style="color: red">*</span></mat-label>

<input matInput [formControlName]="field.name" [placeholder]="field.label" [type]="field.inputType" [(ngModel)]="field.value" matTooltip="{{field.ToolTip}}"  matTooltipPosition="above" (focus)="this.group.get(this.field.name).markAsTouched();" [readonly]="field.IsDisabledForEdit">
<mat-hint></mat-hint>
</mat-form-field>
 <ng-container *ngFor="let validation of field.validations;let indx=index" ngProjectAs="mat-error" >
 <mat-error class="dynamic-feild-error"  *ngIf="group.get(field.name).hasError(validation.name) && group.get(field.name).touched  " >
  {{validation.message}}
</mat-error>
</ng-container>



</div>

<div [ngClass]="[field.CSSClass?field.CSSClass:'col l4 xl4 m4 s12']" *ngIf="field.type.toUpperCase()=='TEXTAREA'">
<mat-form-field class="example-full-width" [formGroup]="group" appearance="outline">
<mat-label>{{field.label}} <span *ngIf="field.IsRequired" style="color: red">*</span></mat-label>
<textarea matInput [formControlName]="field.name" [placeholder]="field.label"  [(ngModel)]="field.value" matTooltip="{{field.ToolTip}}"  matTooltipPosition="above" (focus)="this.group.get(this.field.name).markAsTouched();" [readonly]="field.IsDisabledForEdit"></textarea>
<mat-hint></mat-hint>
</mat-form-field>

<ng-container *ngFor="let validation of field.validations;let indx=index" ngProjectAs="mat-error">
<mat-error class="dynamic-feild-error" *ngIf="group.get(field.name).hasError(validation.name) && group.get(field.name).touched">{{validation.message}}</mat-error>
<!-- <br *ngIf="indx <= field?.validations?.length-1 && field?.validations?.length>1"> -->
</ng-container>


</div>
`,
  styles: []
})
export class InputComponent implements OnInit {
  field: DynamicFieldConfig;
  group: FormGroup;
  fields: DynamicFieldConfig[];

  // validations: any;
  constructor() { }
  ngOnInit() {
  }
  Touch() {




  }



}
