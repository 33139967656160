import { FieldTypeEnum } from './../../../../shared/enum/fixed-values.enum';
import { AlertService } from './../../../../shared/service/alert.service';
import { Component, Inject, OnInit } from '@angular/core';
import { CommonService } from 'src/app/shared/service/common.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Router } from '@angular/router';
import { CommunicationTypeFieldsMasterModel, CommunicationTypeFieldValidationViewModel } from 'src/app/shared/model/master/communication-type-fields-master.model';
import { AuthenticationService } from 'src/app/shared/service/authentication.service';
import { CommunicationTypeFieldsMasterService } from 'src/app/shared/service/master/communication-type-fields-master.service';
import { isNullOrUndefined } from 'util';

@Component({
  selector: 'app-detail-communication-type-field-dialog',
  templateUrl: './detail-communication-type-field-dialog.component.html',
  styleUrls: ['./detail-communication-type-field-dialog.component.scss']
})
export class DetailCommunicationTypeFieldDialogComponent implements OnInit {
  recordId: number = 0;
  model = new CommunicationTypeFieldsMasterModel();
  validationsListViewModel: CommunicationTypeFieldValidationViewModel[] = [];
  fieldTypeEnum = FieldTypeEnum;
  constructor(public readonly _commonService: CommonService,
    private readonly _alertService: AlertService,
    public _dialogRef: MatDialogRef<DetailCommunicationTypeFieldDialogComponent>,
    private readonly _communicationTypeFieldsMasterService: CommunicationTypeFieldsMasterService,
    private readonly _router: Router,
    private readonly _authService: AuthenticationService,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    if (data) {
      if (data.Id) {
        this.recordId = data.Id;
      }
    }
  }

  ngOnInit() {
    this.GetDetail();
  }

  CloseDialog(): void {
    this._dialogRef.close();
  }

  GetDetail() {
    this._communicationTypeFieldsMasterService.Detail(this.recordId).subscribe(
      data => {
        if (data.IsSuccess) {

          this.model = <CommunicationTypeFieldsMasterModel>data.Data;
          this.GetFieldValidationList();
        }
      },
      error => {
        this.model = new CommunicationTypeFieldsMasterModel();
        this._alertService.error(error.message);
      }
    );
  }

  GetFieldValidationList() {
    if (!isNullOrUndefined(this.model.FieldTypeCode)) {
      this._communicationTypeFieldsMasterService.GetFieldsValidations(Number(this.model.FieldTypeCode), this.model.CommunicationTypeFieldsCode).subscribe(response => {
        if (response.IsSuccess) {
          this.validationsListViewModel = response.Data;
          this.validationsListViewModel = this.validationsListViewModel.filter(x => x.code > 0);
        }
      })
    }
  }

  CallPrint(printContent) {

    this._commonService.printHTMLContent(printContent, this.model.CommunicationType + " Field Detail");
  }

}
