import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource, MatDialog, MatSort, MatPaginator } from "@angular/material";
import { IndexModel } from 'src/app/shared/model/common.model';
import { ColumnHeaderModel, DDLModel } from 'src/app/shared/model/commonddl.model';
import { AppComponent } from 'src/app/app.component';
import { AlertService } from 'src/app/shared/service/alert.service';
import { ConfirmationDialogComponent } from 'src/app/confirmation-dialog/confirmation-dialog.component';
import { GlobalMessagesModel } from 'src/app/shared/model/common-messages.model';
import { AppSetting } from 'src/app/shared/model/appsetting.model';
import { AuthenticationService } from 'src/app/shared/service/authentication.service';
import { UserTypeEnum } from 'src/app/shared/enum/fixed-values.enum';
import { Router } from '@angular/router';
import { UserViewModel } from 'src/app/shared/model/user.model';
import { CommonService } from 'src/app/shared/service/common.service';
import { UserService } from 'src/app/shared/service/user.service';
import { AddUpdateVendorUserDialogComponent } from '../../vendor-user/add-update-vendor-user-dialog/add-update-vendor-user-dialog.component';
import { VendorUserDetailDialogComponent } from '../../vendor-user/vendor-user-detail-dialog/vendor-user-detail-dialog.component';
import { isNullOrUndefined } from 'util';
import { DashBoardFilterModel } from 'src/app/shared/model/dashboard-filter.model';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';
declare const require: any;
const jsPDF = require('jspdf');
require('jspdf-autotable');

@Component({
  selector: 'app-vendor-user-report',
  templateUrl: './vendor-user-report.component.html',
  styleUrls: ['./vendor-user-report.component.scss']
})
export class VendorUserReportComponent implements OnInit {

  model: UserViewModel[];
  indexModel: IndexModel;
  dataSource: any;
  totalRecords: number;
  ddlList: DDLModel;
  filterModel: DashBoardFilterModel = new DashBoardFilterModel();
  search: string = '';

  displayedColumns: string[] = ["index", "Name", "SSOID", "DepartmentNames", "OfficeNames", "DistrictNames", "ProjectNames", "IsActive"];
  columnsToDisplay: string[] = this.displayedColumns.slice();
  ViewdisplayedColumns: ColumnHeaderModel[] = [
    { Value: "Name", Text: "Name" },
    { Value: "SSOID", Text: "SSO ID" },
    //{ Value: "DepartmentNames", Text: "Department" },
    //{ Value: "DistrictNames", Text: "District" },
    //{ Value: "ProjectNames", Text: "Project" },

  ];
  isDeptDisabled:boolean;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  loginUserDetail: any;
  filterData: UserViewModel[] = [];
  //filterModel: any;
  //filterData: any[];
  constructor(
    private readonly appComponnet: AppComponent,
    private readonly _alertService: AlertService,
    private readonly _userService: UserService,
    private readonly _dialog: MatDialog,
    private readonly _router: Router,
    public readonly _commonService: CommonService,
    private readonly _authService: AuthenticationService,

  ) {
    this.appComponnet.setPageLayout("Vendor User :", "", "", "", true);
    this.indexModel = new IndexModel();
    this.loginUserDetail = this._authService.GetCurrentUserDetail().UserViewModel;
    this.GetDDLList();
  }

  ngOnInit() {
    this.search = '';
    this.filterModel.DepartmentCode = null;
    this.filterModel.ProjectCode = null;
    this.filterModel.DistrictCode = 0;
    this.filterModel.OfficeCode = null;
    this.getList();
  }

  getList() {
    
    this._userService.GetVendorUserList(0, this.indexModel).subscribe(
      data => {
        if (data.IsSuccess) {
          this.filterData = [];
          this.model = <UserViewModel[]>data.Data.Data;
          this.model.forEach((element) => {
            var addRecord: any = true;

            var departmentArray: String[];
            if (!isNullOrUndefined(this.filterModel.DepartmentCode)) {
              if (element.DepartmentCodes != null) {
                departmentArray = element.DepartmentCodes.split(",");
                var found = false;
                departmentArray.forEach(element1 => {
                  if (this.filterModel.DepartmentCode == element1) {
                    found = true;
                    addRecord = true;
                  } else {
                    addRecord = false;
                    if (found) {
                      addRecord = true;
                    }

                  }

                });
              } else {
                addRecord = false;
              }
            }
            //------project filter----  
            var projectArray: String[];
            if (!isNullOrUndefined(this.filterModel.ProjectCode) && addRecord) {
              if (element.ProjectCodes != null) {
                projectArray = element.ProjectCodes.split(",");
                var found = false;
                projectArray.forEach(element1 => {
                  if (this.filterModel.ProjectCode == element1) {
                    found = true;
                    addRecord = true;
                  } else {
                    addRecord = false;
                    if (found) {
                      addRecord = true;
                    }
                  }
                });
              } else {
                addRecord = false;
              }
            }
            //----district filter
            var districtArray: String[];
            if (!isNullOrUndefined(this.filterModel.DistrictCode) && addRecord &&
              this.filterModel.DistrictCode != 0) {
              if (element.DistrictCodes != null) {
                districtArray = element.DistrictCodes.split(",");
                var found = false;
                districtArray.forEach(element1 => {
                  if (this.filterModel.DistrictCode == element1) {
                    found = true;
                    addRecord = true;
                  } else {
                    addRecord = false;
                    if (found) {
                      addRecord = true;
                    }
                  }
                });
              } else {
                addRecord = false;
              }
            }
            //-------all filter done
            if (addRecord) {
              this.filterData.push(element);
            }
          });
          this.dataSource = new MatTableDataSource<UserViewModel>(this.filterData);
          this.totalRecords = data.Data.TotalRecords;
          if (!this.indexModel.IsPostBack) {
            this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.sort;
          }
        } else {
          this._alertService.error(data.Message);
        }
      },
      error => {
        this._alertService.error(error.message);
      }
    );
  }

  GetProjectDDLList() {
    var userDept = (this.loginUserDetail.DepartmentCodes == null) ? 0 : this.loginUserDetail.DepartmentCodes;
    this._commonService.GetDDL(AppSetting.ddlProject, userDept, this.loginUserDetail.UserCode).subscribe(
      data => {
        if (data.IsSuccess) {
          var ddl = <DDLModel>data.Data;
          this.ddlList.ddlProject = ddl.ddlProject;
        }
      },
      error => {
        this._alertService.error(error.message);
      }
    );
  }

  GetDistrictDDLList() {
    this._commonService.GetDDL(AppSetting.ddlDistrict, 0, this.loginUserDetail.UserCode).subscribe(
      data => {
        if (data.IsSuccess) {
          var ddl = <DDLModel>data.Data;
          this.ddlList.ddlDistrict = ddl.ddlDistrict;
        }
      },
      error => {
        this._alertService.error(error.message);
      }
    );
  }

  sortData(event) {
    this.indexModel.OrderBy = event.active;
    this.indexModel.OrderByAsc = event.direction == AppSetting.orderByDscAsc ? AppSetting.orderByAsc : AppSetting.orderByDsc;
    this.indexModel.IsPostBack = true;
    this.getList();
  }

  onPaginateChange(event) {
    this.indexModel.Page = event.pageIndex + 1;
    this.indexModel.PageSize = event.pageSize;
    this.indexModel.IsPostBack = true;
    this.getList();
  }

  GetDDLList() {
    this._commonService
      .GetAllDDL(AppSetting.FilterDropdownKeys)
      .subscribe(
        (data) => {
          if (data.IsSuccess) {
            this.ddlList = <DDLModel>data.Data;
            this.GetProjectDDLList();
            this.GetDistrictDDLList();
            this.filterModel.DepartmentCode = this.loginUserDetail.DepartmentCodes;
            if (!isNullOrUndefined(this.loginUserDetail.VendorCode)) {
              this.filterModel.VendorCode = this.loginUserDetail.VendorCode.toString();
            }
            else if (!isNullOrUndefined(this.loginUserDetail.VendorUser_VendorCode)) {
              this.filterModel.VendorCode = this.loginUserDetail.VendorUser_VendorCode.toString();
            }
            this.GetOffice();
          }
        },
        (error) => {
          this._alertService.error(error.message);
        }
      );
  }

  GetOffice() {
    this.GetProjectListByDepartmentWise();
    if (!isNullOrUndefined(this.filterModel.DepartmentCode)) {
      this.filterModel.DistrictCode = this.filterModel.DistrictCode == undefined ? 0 : this.filterModel.DistrictCode;
      this._commonService
        .GetDDL(AppSetting.ddlDepartmentOffice, this.filterModel.DepartmentCode, this.filterModel.DistrictCode
        )
        .subscribe(
          (data) => {
            if (data.IsSuccess) {
              var ddl = <DDLModel>data.Data;
              this.ddlList.ddlDepartmentOffice = ddl.ddlDepartmentOffice;

              //Added on 07/09/2020
              if (this.ddlList.ddlDepartmentOffice != null && this.ddlList.ddlDepartmentOffice.length == 1) {
                this.filterModel.OfficeCode = ddl.ddlDepartmentOffice[0].Value;
              }
            }
          },
          (error) => {
            this._alertService.error(error.message);
          }
        );

    } else {
      this.ddlList.ddlDepartmentOffice = null;
    }
  }

  GetProjectListByDepartmentWise() {
    var userDept = (this.loginUserDetail.DepartmentCodes == null) ? 0 : this.loginUserDetail.DepartmentCodes;
    if (!isNullOrUndefined(this.filterModel.DepartmentCode)) {
      this._commonService.GetDDL(AppSetting.ddlProject, this.filterModel.DepartmentCode, this.loginUserDetail.UserCode).subscribe(
        data => {
          if (data.IsSuccess) {
            var ddl = <DDLModel>data.Data;
            this.ddlList.ddlProject = ddl.ddlProject;
          }
        },
        error => {
          this._alertService.error(error.message);
        }
      );
    }
    else {
      this.GetProjectDDLList();
    }

  }

  onClearClick() {
    this.search = '';
    this.filterModel.DepartmentCode = null;
    this.filterModel.ProjectCode = null;
    this.filterModel.DistrictCode = 0;
    this.filterModel.OfficeCode = null;
    this.getList();
  }

  buttonClickExportAsPDF(): void {
    this.getCompleteData();
    setTimeout(() => {
      this.download();
      this.exportAsPdf();
    }, 5000);
  }

  buttonClickExportAsXLSX(): void {
    this.getCompleteData();
    setTimeout(() => {
      this.download();
      this.exportAsExcelFile();
    }, 5000);
  }
  @ViewChild('pdfTable', { static: false }) pdfTable: ElementRef;
  completeModel: UserViewModel[];
  completeIndexModel: IndexModel;

  getCompleteData() {
    this.completeIndexModel = new IndexModel();
    this.completeIndexModel.Page = 1;
    this.completeIndexModel.PageSize = this.totalRecords;
    this.completeIndexModel.IsPostBack = true;
    this.filterData = [];
    this._userService.GetVendorUserList(0, this.completeIndexModel).subscribe(
      data => {
        if (data.IsSuccess) {
          this.completeModel = <UserViewModel[]>data.Data.Data;
          this.completeModel.forEach((element) => {
            var addRecord: any = true;
            var departmentArray: String[];
            if (!isNullOrUndefined(this.filterModel.DepartmentCode)) {
              if (element.DepartmentCodes != null) {
                departmentArray = element.DepartmentCodes.split(",");
                var found = false;
                departmentArray.forEach(element1 => {
                  if (this.filterModel.DepartmentCode == element1) {
                    found = true;
                    addRecord = true;
                  } else {
                    addRecord = false;
                    if (found) {
                      addRecord = true;
                    }
                  }
                });
              } else {
                addRecord = false;
              }
            }
            //------project filter----  
            var projectArray: String[];
            if (!isNullOrUndefined(this.filterModel.ProjectCode) && addRecord) {
              if (element.ProjectCodes != null) {
                projectArray = element.ProjectCodes.split(",");
                var found = false;
                projectArray.forEach(element1 => {
                  if (this.filterModel.ProjectCode == element1) {
                    found = true;
                    addRecord = true;
                  } else {
                    addRecord = false;
                    if (found) {
                      addRecord = true;
                    }
                  }
                });
              } else {
                addRecord = false;
              }
            }
            //----district filter
            var districtArray: String[];
            if (!isNullOrUndefined(this.filterModel.DistrictCode) && addRecord &&
              this.filterModel.DistrictCode != 0) {
              if (element.DistrictCodes != null) {
                districtArray = element.DistrictCodes.split(",");
                var found = false;
                districtArray.forEach(element1 => {
                  if (this.filterModel.DistrictCode == element1) {
                    found = true;
                    addRecord = true;
                  } else {
                    addRecord = false;
                    if (found) {
                      addRecord = true;
                    }
                  }
                });
              } else {
                addRecord = false;
              }
            }
            //-------all filter done
            if (addRecord) {
              this.filterData.push(element);
            }
          }); // this is for loop
          this.dataSource = new MatTableDataSource<UserViewModel>(this.filterData);
          this.totalRecords = data.Data.TotalRecords;
          if (!this.completeIndexModel.IsPostBack) {
            this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.sort;
          }
        } else {
          this._alertService.error(data.Message);
        }
      },
      error => {
        this._alertService.error(error.message);
      }
    );
  }

  rowsNew: any = [];

  download() {   
    var i = 1;
    this.rowsNew = [];  
    this.filterData.forEach(element => {      
      var temp = {
        SNo: i, Name: element.Name, SSO_ID: element.SSOID, Department: element.DepartmentNames,Office:element.OfficeNames,
        District: element.DistrictNames, Project: element.ProjectNames
      };
      i = i + 1;
      this.rowsNew.push(temp);
    });

  }

  public exportAsPdf(): void {
    var doc = new jsPDF('p', 'pt');
    var header = function (data) {
      doc.setFontSize(10);
      doc.setTextColor(40);     
      doc.text("Vendor-User-Report", data.settings.margin.left, 50);
      doc.text("Date Time : "+new Date().toLocaleDateString() + " " + new Date().toLocaleTimeString(), data.settings.margin.left, 800);
    };
    var headers = [{  //change
      title: "#",
      dataKey: "SNo"
    }, {
      title: "Name",
      dataKey: "Name"
    }, {
      title: "SSO_ID",
      dataKey: "SSO_ID"
    }, {
      title: "Department",
      dataKey: "Department"
    }, 
    {
      title: "Office",
      dataKey: "Office"
    }, 
    {
      title: "District",
      dataKey: "District"
    }, {
      title: "Project",
      dataKey: "Project"
    }
    ];
    doc.autoTable(headers, this.rowsNew, { margin: { top: 70 }, beforePageContent: header });
    doc.save("Vendor-User-Report"+new Date().toLocaleDateString() + "_" + new Date().toLocaleTimeString()+".pdf"); //change
  }

  public exportAsExcelFile(): void {    
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.rowsNew);
    const workbook: XLSX.WorkBook = { Sheets: { 'VendorUserReport': worksheet }, SheetNames: ['VendorUserReport'] }; //change
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFile(excelBuffer, 'Vendor-User-'); //change
  }
  
  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], { type: EXCEL_TYPE });
    FileSaver.saveAs(data, fileName + 'Report_' + new Date().toLocaleDateString() + "_" + new Date().toLocaleTimeString() + EXCEL_EXTENSION);
  }
}
