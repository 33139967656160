import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { DDLModel } from 'src/app/shared/model/commonddl.model';
import { WorkOrderClearModel } from 'src/app/shared/model/work-order.model';
import { AlertService } from 'src/app/shared/service/alert.service';
import { CommonService } from 'src/app/shared/service/common.service';
import { WorkOrderService } from 'src/app/shared/service/work-order.service';
import { MatDialogRef } from '@angular/material/dialog';
import { AppSetting } from 'src/app/shared/model/appsetting.model';
import { GlobalMessagesModel } from 'src/app/shared/model/common-messages.model';

@Component({
  selector: 'app-clear-work-order-popup',
  templateUrl: './clear-work-order-popup.component.html',
  styleUrls: ['./clear-work-order-popup.component.scss']
})
export class ClearWorkOrderPopupComponent implements OnInit {
  frmGrp: FormGroup;
  public ddlList = new DDLModel();
  public model: WorkOrderClearModel = new WorkOrderClearModel()
  constructor(
    private readonly _alertService: AlertService,
    private _commonService: CommonService,
    private fb: FormBuilder,
    private workorderService: WorkOrderService,
    private readonly _router: Router,
    public dialogRef: MatDialogRef<ClearWorkOrderPopupComponent>
  ) { }

  ngOnInit() {
    this.GetProjectList();
    this.formGroupInit();

  }



  GetProjectList() {

    this._commonService.GetAllDDL(AppSetting.VendorCommunicationPermissionDdl).subscribe(
      response => {
        if (response.IsSuccess) {
          let data = <DDLModel>response.Data;
          this.ddlList.ddlProject = data.ddlProject;

        }
      },
      error => {
        this._alertService.error(error.message);
      }
    );
  }
  formGroupInit() {
    this.frmGrp = this.fb.group({
      ProjectCode: [this.model.ProjectCode],

    });
  }
  public onSubmit() {
   
    if (this.frmGrp.valid) {
      this.workorderService.WorkOrderClear(this.model).subscribe(data => {
        if (data) {
          if (data.IsSuccess) {
            this._alertService.success(GlobalMessagesModel.deleteSuccess);
            this.dialogRef.close();
           }
          else {

            this._alertService.error('WorkOrders are being used in communication.');
            this.dialogRef.close();
          }
        }
      });
    }
  }

}
