import { Component, Inject, OnInit, ViewChild } from "@angular/core";
import { MatDialogRef, MatPaginator, MatTableDataSource, MAT_DIALOG_DATA } from "@angular/material";
import { IndexModel } from "src/app/shared/model/common.model";
import { ColumnHeaderModel } from "src/app/shared/model/commonddl.model";
import { DefaultPermissionUserTypeModel, SpecificPermissionUserModel } from "src/app/shared/model/user-permission.model";
import { AlertService } from "src/app/shared/service/alert.service";
import { UserPermissionService } from "src/app/shared/service/user-permission.service";

@Component({
  selector: 'app-page-permission-list-dialog',
  templateUrl: './page-permission-list-dialog.component.html',
  styleUrls: ['./page-permission-list-dialog.component.scss']
})

export class PagePermissionListDialogComponent implements OnInit {

  perCode: number = 0;
  perTitle: string = '';
  dataSource: any;
  indexModel: IndexModel= new IndexModel();
  userList: SpecificPermissionUserModel[] = [];
  userTypeList: DefaultPermissionUserTypeModel[] = [];

  displayedColumns: string[] = ["index", "UserTypeTitle", "UserName", "Action"];
  ViewdisplayedColumns: ColumnHeaderModel[] = [
    { Value: "UserTypeTitle", Text: "User Type" },
    { Value: "UserName", Text: "User" }
  ];

  columnsToDisplay: string[] = this.displayedColumns.slice();
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  constructor(
    public _dialogRef: MatDialogRef<PagePermissionListDialogComponent>,
    private readonly _alertService: AlertService,
    private readonly _userPermissionService: UserPermissionService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    if (data) {
      this.perCode = data.code;
      this.perTitle = data.title;
    }
  }

  ngOnInit() {
    if (this.perCode > 0) {
      this.GetDefaultPermissionUserTypes();
      this.GetSpecificPermissionUsers();
    }
  }

  GetDefaultPermissionUserTypes() {
    this._userPermissionService.GetDefaultPermissionUserTypes(this.perCode).subscribe((data) => {
      if (data.IsSuccess) {
        this.userTypeList = <DefaultPermissionUserTypeModel[]>(data.Data);
      }
    }, (error) => {
      this._alertService.error(error.message);
    });
  }

  GetSpecificPermissionUsers() {
    this._userPermissionService.GetSpecificPermissionUsers(this.perCode).subscribe((data) => {
      if (data.IsSuccess) {
        this.userList = <SpecificPermissionUserModel[]>data.Data;
        this.dataSource = new MatTableDataSource<any>(this.userList);
        this.dataSource.paginator = this.paginator;
      }
    }, (error) => {
      this._alertService.error(error.message);
    });
  }

  onNoClick(): void {
    this._dialogRef.close();
  }

  onPaginateChange(event) {
    this.indexModel.Page = event.pageIndex + 1;
    this.indexModel.PageSize = event.pageSize;
  }

  resetUserSpecificPermission(userId: number) {
    this._userPermissionService.ResetUserSpecificPermission(userId).subscribe((data) => {
      if (data.IsSuccess) {
        this.GetSpecificPermissionUsers();
        alert(data.Message);
        this._alertService.success(data.Message);
      }
    }, (error) => {
      this._alertService.error(error.message);
    });
  }

}
