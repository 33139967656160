export class NotificationSMSTemplatesModel {
    Id?: number;
    TemplateName: string;
    SMSContent: string;
    IsTransaction: boolean;
    TemplateID: string;
    SenderID: string;
    DateOfApproval: Date;
}


export class NotificationSMSTemplateViewModel {
    Id?: number;
    TemplateName: string;
    SMSContent: string;
    Code: number;
    IsActive: boolean;
    IsDelete: boolean;
    CreatedDate: Date;
    CreatedBy: number;
    ModifiedDate: Date;
    ModifiedBy: number;
}

