import { AppComponent } from './../../app.component';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ColumnHeaderModel } from 'src/app/shared/model/commonddl.model';
import { MatPaginator, MatSort, MatDialog, MatTableDataSource, DateAdapter, MAT_DATE_FORMATS } from '@angular/material';
import { IndexModel } from 'src/app/shared/model/common.model';
import { ProjectMappingViewModel } from 'src/app/shared/model/projectMapping.model';
import { AlertService } from 'src/app/shared/service/alert.service';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/shared/service/common.service';
import { AuthenticationService } from 'src/app/shared/service/authentication.service';
import { UserTypeEnum } from 'src/app/shared/enum/fixed-values.enum';
import { AddUpdateProjectMappingDialogComponent } from './add-update-project-mapping-dialog/add-update-project-mapping-dialog.component';
import { AppSetting } from 'src/app/shared/model/appsetting.model';
import { ProjectMappingService } from 'src/app/shared/service/project-mapping.service';
import { AppDateAdapter, APP_DATE_FORMATS } from 'src/app/shared/model/format-datepicker';
import { ProjectMappingDetailDialogComponent } from './project-mapping-detail-dialog/project-mapping-detail-dialog.component';
import { UserService } from 'src/app/shared/service/user.service';
import { UserDetailDialogComponent } from '../user/user-detail-dialog/user-detail-dialog.component';

@Component({
  selector: 'app-project-mapping',
  templateUrl: './project-mapping.component.html',
  styleUrls: ['./project-mapping.component.scss'],
  providers: [
    { provide: DateAdapter, useClass: AppDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS }
  ]
})

export class ProjectMappingComponent implements OnInit {

  model: ProjectMappingViewModel[];
  indexModel = this._commonService.modelSetGet(new IndexModel()) as IndexModel;
  dataSource: any;
  totalRecords: number;
  loginUserType: string;
  baseUserType: string;
  userTypeEnum = UserTypeEnum;

  displayedColumns: string[] = ["index", "ProjectName", "AssignedProjectOfficerName", "SSOID", "DepartmentName", "AssignDate", "AssignBy", "Action"];
  columnsToDisplay: string[] = this.displayedColumns.slice();

  ViewdisplayedColumns: ColumnHeaderModel[] = [
    { Value: "ProjectName", Text: "Project" },
    { Value: "AssignedProjectOfficerName", Text: "User" },
    { Value: "SSOID", Text: "SSO ID" },
    { Value: "DepartmentName", Text: "Department" }
  ];

  ViewSearchModel: ColumnHeaderModel[] = [
    { Value: "ProjectName", Text: "Project" },
    { Value: "AssignedProjectOfficerName", Text: "User" },
    { Value: "SSOID", Text: "SSO ID" },
    { Value: "DepartmentName", Text: "Department" }
  ];

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(
    private readonly appComponnet: AppComponent,
    private readonly _alertService: AlertService,
    private readonly _userService: UserService,
    private readonly _projectMappingService: ProjectMappingService,
    private readonly _dialog: MatDialog,
    private readonly _router: Router,
    public readonly _commonService: CommonService,
    private readonly _authService: AuthenticationService
  ) {
    this.baseUserType = this._authService.BaseUserType;
    this.appComponnet.setPageLayout("OIC Project Mapping :", "", "", "", true);
  }

  ngOnInit() {
    this.getList();
  }

  getList() {
    this._projectMappingService.GetList(this._commonService.modelSetGet(this.indexModel, true)).subscribe(data => {
      if (data.IsSuccess) {
        this.model = <ProjectMappingViewModel[]>data.Data.Data;
        this.dataSource = new MatTableDataSource<ProjectMappingViewModel>(this.model);
        this.totalRecords = data.Data.TotalRecords;
        if (!this.indexModel.IsPostBack) {
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
        }
      }
      else {
        this._alertService.error(data.Message);
      }
    }, error => {
      this._alertService.error(error.message);
    });
  }

  sortData(event) {
    this.indexModel.OrderBy = event.active;
    this.indexModel.OrderByAsc = event.direction == AppSetting.orderByDscAsc ? AppSetting.orderByAsc : AppSetting.orderByDsc;
    this.indexModel.IsPostBack = true;
    this.getList();
  }

  onPaginateChange(event) {
    this.indexModel.Page = event.pageIndex + 1;
    this.indexModel.PageSize = event.pageSize;
    this.indexModel.IsPostBack = true;
    this.getList();
  }

  SearchByKeyword(searchValue) {
    this.indexModel.Search = searchValue.search;
    this.indexModel.AdvanceSearchModel = { ActiveStatus: searchValue.ActiveStatus }
    this.getList();
  }

  ClearSearch() {
    this.indexModel = new IndexModel();
    this.indexModel.AdvanceSearchModel = { ActiveStatus: "1" }
    this.getList();
  }

  OpenUserDetailDialog(Id) {
    const _dialogRef = this._dialog.open(ProjectMappingDetailDialogComponent, {
      width: "90%",
      data: { Id },
      disableClose: true
    });
    _dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) { }
    });
  }

  openProjectMappingDialog(id, isReassign) {
    const _dialogRef = this._dialog.open(AddUpdateProjectMappingDialogComponent, {
      width: "1000px",
      height: isReassign ? "280px" : "400px",
      data: { id: id, isReassign: isReassign },
      disableClose: true
    });
    _dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        this.getList();
      }
    });
  }

}
