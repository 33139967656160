import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { IndexModel } from '../model/common.model';
import { AppSetting } from '../model/appsetting.model';
import { ActionModel } from '../model/action.model';

@Injectable({
    providedIn: 'root'
})

export class ActionService {

    constructor(private readonly _baseService: BaseService) { }

    GetList(communicationCode: number, userCode: number) {
        return this._baseService.get(AppSetting.ActionListUrl + "?communicationCode=" + communicationCode + "&userCode=" + userCode);
    }

    GetInternalTransferHistoryList(communicationCode: number, userCode: number) {
        return this._baseService.get(AppSetting.InternalTransferHistoryListUrl + "?communicationCode=" + communicationCode + "&userCode=" + userCode);
    }

    AddUpdate(model: ActionModel) {
        return this._baseService.post(AppSetting.ActionAddUpdateUrl, model);
    }

}