import { MenuMasterService } from './../../../../shared/service/master/menu-master.service';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { DDLModel } from 'src/app/shared/model/commonddl.model';
import { MenuViewModel } from 'src/app/shared/model/master/menu-view-model.model';
import { AlertService } from 'src/app/shared/service/alert.service';
import { CommonService } from 'src/app/shared/service/common.service';
import { AppSetting } from 'src/app/shared/model/appsetting.model';
import { GlobalMessagesModel } from 'src/app/shared/model/common-messages.model';

@Component({
  selector: 'app-add-update-menu-master',
  templateUrl: './add-update-menu-master.component.html',
  styleUrls: ['./add-update-menu-master.component.scss']
})
export class AddUpdateMenuMasterComponent implements OnInit {

  dDLList: DDLModel;
  model: MenuViewModel;
  id: number;
  pageTitle = "Add Menu";

  constructor(public readonly _commonService: CommonService,
    private readonly _alertService: AlertService,
    private readonly _menuService: MenuMasterService,
    public _dialogRef: MatDialogRef<AddUpdateMenuMasterComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.model = new MenuViewModel();
  }

  ngOnInit() {
    this.GetDDLList();
    if (this.data != null) {
      this.pageTitle = "Update Menu";
      this.id = this.data.Id;
      this.GetById();
    }
  }

  GetDDLList() {
    this._commonService.GetAllDDL(AppSetting.DDlKeyForMenuMaster).subscribe(
      data => {
        if (data.IsSuccess) {
          this.dDLList = <DDLModel>data.Data;
        }
      },
      error => {
        this._alertService.error(error.message);
      }
    );
  }

  GetById() {
    this._menuService.GetById(this.id).subscribe(
      data => {
        if (data.IsSuccess) {
          this.model = <MenuViewModel>data.Data;
        }
      },
      error => {
        this._alertService.error(error.message);
      }
    );
  }

  SaveClick() {
   
    if (!this._commonService.IsNullOrEmpty(this.model.MenuId) && this.model.MenuId > 0) {
      this._menuService.EditMenu(this.id, this.model).subscribe(data => {
        if (data.IsSuccess) {
          this._alertService.success(GlobalMessagesModel.updateSuccess);
          this._dialogRef.close({ Id: data.Data, IsSuccess: data.IsSuccess });
        } else {
          this._alertService.error(data.Message);
        }
      }, error => {
        this._alertService.error(error.message);
      });
    } else {
      this._menuService.AddMenu(this.model).subscribe(data => {
        if (data.IsSuccess) {
          this._alertService.success(GlobalMessagesModel.saveSuccess);
          this._dialogRef.close({ Id: data.Data, IsSuccess: data.IsSuccess });
        } else {
          this._alertService.error(data.Message);
        }
      }, error => {
        this._alertService.error(error.message);
      });
    }
  }

  onCloseClick() {
    this._dialogRef.close();
  }

}
