import { IndexModel } from "../../common.model";

export class DistrictListRequestModel extends IndexModel{

     public districtName: string = ""; 
     public stateId: number = 0;
     public isActive: boolean = true;

    

}