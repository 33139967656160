import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { GlobalFilterSearchModel } from 'src/app/shared/model/Global-communication-search.model';
import { OfficeMasterViewModel } from 'src/app/shared/model/master/office-master.model';
import { UserViewModel } from 'src/app/shared/model/user.model';
import { AlertService } from 'src/app/shared/service/alert.service';
import { AuthenticationService } from 'src/app/shared/service/authentication.service';
import { GlobalCommunicationSearchService } from 'src/app/shared/service/global-communication-search.service';
import { OfficeMasterService } from 'src/app/shared/service/master/office-master.service';

@Component({
  selector: 'app-change-office-dialog',
  templateUrl: './change-office-dialog.component.html',
  styleUrls: ['./change-office-dialog.component.scss']
})

export class ChangeOfficeDialogComponent implements OnInit {

  loginData: UserViewModel;
  selOfficeCode: number;
  officeList: OfficeMasterViewModel[] = [];
  globalCommSearch: GlobalFilterSearchModel;

  constructor(
    private readonly _authService: AuthenticationService,
    private readonly _officeService: OfficeMasterService,
    private readonly _alertService: AlertService,
    private readonly _globalCommSearchService: GlobalCommunicationSearchService,
    public _dialogRef: MatDialogRef<ChangeOfficeDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.loginData = this._authService.GetCurrentUserDetail().UserViewModel;
    this.globalCommSearch = this._globalCommSearchService.GetDetail();
    if (this.globalCommSearch && this.globalCommSearch.OfficeCode) {
      this.selOfficeCode = Number(this.globalCommSearch.OfficeCode);
    }
    
  }

  ngOnInit() {
    this.getOfficeList();
  }

  getOfficeList() {
    this._officeService.GetOfficeListByIds(this.loginData.OfficeCodes).subscribe((data) => {
      if (data.IsSuccess) {
        this.officeList = <OfficeMasterViewModel[]>data.Data;
      }
    }, (error) => {
      this._alertService.error(error.message);
    });
  }

  onOfficeChange() {
    let selOffice = this.officeList.find(x => x.OfficeCode == this.selOfficeCode);
    if (selOffice) {
      this.globalCommSearch.AdminDepartmentCode = selOffice.AdmDepartmentCode;
      this.globalCommSearch.DepartmentCode = selOffice.DepartmentCode;
      this.globalCommSearch.OfficeCode = String(selOffice.OfficeCode);
      this._globalCommSearchService.SetDetail(this.globalCommSearch);
      this.closeDialog();
    }
  }

  closeDialog(): void {
    this._dialogRef.close(true);
  }

}
