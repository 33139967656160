import { Component, OnInit, ViewChild } from "@angular/core";
import { MatPaginator, MatSort, MatDialog, MatTableDataSource } from "@angular/material";
import { Router, ActivatedRoute } from "@angular/router";
import { AppComponent } from "src/app/app.component";
import { ConfirmationDialogComponent } from "src/app/confirmation-dialog/confirmation-dialog.component";
import { UserTypeEnum } from "src/app/shared/enum/fixed-values.enum";
import { AppSetting } from "src/app/shared/model/appsetting.model";
import { GlobalMessagesModel } from "src/app/shared/model/common-messages.model";
import { IndexModel, SearchModel, GlobalFilterModel } from "src/app/shared/model/common.model";
import { ColumnHeaderModel } from "src/app/shared/model/commonddl.model";
import { CommunicationTypeFieldsMasterModel, CommunicationTypeDefaultFieldsMasterModel, DefaultFieldSortOrderPostModel } from "src/app/shared/model/master/communication-type-fields-master.model";
import { AlertService } from "src/app/shared/service/alert.service";
import { AuthenticationService } from "src/app/shared/service/authentication.service";
import { CommonService } from "src/app/shared/service/common.service";
import { CommunicationTypeFieldsMasterService } from "src/app/shared/service/master/communication-type-fields-master.service";
import { AddCommunicationTypeFieldsComponent } from "../add-communication-type-fields/add-communication-type-fields.component";
import { AssignDefaultFieldComponent } from "../assign-default-field/assign-default-field.component";
import { CommunicationDefaultFieldConfigComponent } from "../communication-default-field-config/communication-default-field-config.component";
import { DetailCommunicationTypeFieldDialogComponent } from "../detail-communication-type-field-dialog/detail-communication-type-field-dialog.component";

@Component({
  selector: 'app-communication-fields',
  templateUrl: './communication-fields.component.html',
  styleUrls: ['./communication-fields.component.scss']
})
export class CommunicationFieldsComponent implements OnInit {

  model: CommunicationTypeFieldsMasterModel[] = [];
  indexModel = this._commonService.modelSetGet(new IndexModel(), false);
  dataSource: any;
  totalRecords: number;
  defaultValue: SearchModel;
  communicationTypeCode: number;
  communicationType: string;
  displayedColumns: string[] = ["index", "FieldLabel", "ToolTip", "FieldType", "CommunicationType", "DisplayOrder", "CSSDisplayText", "IsRequired", "IsDisabledForEdit", "IsHidden", "Action"];
  columnsToDisplay: string[] = this.displayedColumns.slice();
  ViewdisplayedColumns: ColumnHeaderModel[] = [
    { Value: "FieldLabel", Text: "Field Label" },
    { Value: "ToolTip", Text: "Tool Tip" },
    { Value: "FieldType", Text: "Field Type" },
    { Value: "CommunicationType", Text: "Communication Type" },
    { Value: "DisplayOrder", Text: "Display Order" },
    { Value: "CSSDisplayText", Text: "CSS Display Text" },
  ];


  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  defaultFieldModel: CommunicationTypeDefaultFieldsMasterModel[] = [];
  defaultDataSource: any;
  communicationTypeEnum: string;
  defaultDisplayedColumns: string[] = ["index", "FieldName", "CommunicationType", "FieldType", "IsRequired", "SortOrder", "Action"];
  defaultColumnsToDisplay: string[] = this.defaultDisplayedColumns.slice();
  defaultViewdisplayedColumns: ColumnHeaderModel[] = [
    { Value: "FieldName", Text: "Field Name" },
    { Value: "CommunicationType", Text: "Communication Type" },

  ];
  TotalDefaultFieldData = 0;
  userTypeEnum = UserTypeEnum;
  ddlSortOrder: string[] = [];
  constructor(private readonly appComponnet: AppComponent,
    private readonly _alertService: AlertService,
    private readonly _communicationTypeFieldsMasterService: CommunicationTypeFieldsMasterService,
    private readonly _router: Router,
    private readonly _dialog: MatDialog,
    public readonly _commonService: CommonService,
    public readonly _authService: AuthenticationService,
    private _route: ActivatedRoute,
  ) {

    this.communicationTypeEnum = this._route.snapshot.params.id;

    this.appComponnet.setPageLayout("Communication Fields :", "", "", "", true);

  }

  ngOnInit() {
    if (this.userTypeEnum.ADM != this._authService.BaseUserType && this.userTypeEnum.SADM != this._authService.BaseUserType) {
      this._router.navigate(["dashboard"]);
    }

    this.getDefaultFieldList();
    this.getDynamicFieldList();
  }

  getDynamicFieldList() {
    this._communicationTypeFieldsMasterService.GetList(this.communicationTypeEnum, this._commonService.modelSetGet(this.indexModel, true)).subscribe(
      data => {

        if (data.IsSuccess) {

          this.model = <CommunicationTypeFieldsMasterModel[]>data.Data.Data;

          this.dataSource = new MatTableDataSource<CommunicationTypeFieldsMasterModel>(this.model);
          this.totalRecords = data.Data.TotalRecords;
          if (!this.indexModel.IsPostBack) {
            this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.sort;
          }
        } else {
          this._alertService.error(data.Message);
        }
      },
      error => {
        this._alertService.error(error.message);
      }
    );
  }

  updateDeleteStatus(id) {
    const dialogRef = this._dialog.open(ConfirmationDialogComponent, { width: "50%", data: GlobalMessagesModel.ConfirmDeleted });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this._communicationTypeFieldsMasterService.ChangeDeleteStatus(id).subscribe(
          data => {
            if (data.IsSuccess) {
              this._alertService.success(data.Message);
              this._commonService.ScrollingTop();
              this.getDynamicFieldList();
            }
          },
          error => {
            this._alertService.error(error.message);
          }
        );
      }
    });
  }

  updateHiddenStatus(id) {
    const dialogRef = this._dialog.open(ConfirmationDialogComponent, { width: "50%", data: GlobalMessagesModel.ConfirmHide });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {


        this._communicationTypeFieldsMasterService.ChangeHiddenStatus(id).subscribe(
          data => {
            if (data.IsSuccess) {
              this._alertService.success(data.Message);
              this._commonService.ScrollingTop();
              this.getDynamicFieldList();
            }
          },
          error => {
            this._alertService.error(error.message);
          }
        );
      }
    });
  }


  updateActiveStatus(id) {
    const dialogRef = this._dialog.open(ConfirmationDialogComponent, { width: "50%", data: GlobalMessagesModel.ConfirmStatusChanged });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this._communicationTypeFieldsMasterService.ChangeActiveStatus(id).subscribe(
          data => {
            if (data.IsSuccess) {
              this._alertService.success(data.Message);
              this.getDynamicFieldList();
            }
          },
          error => {
            this._alertService.error(error.message);
          }
        );
      }
    });
  }

  sortData(event) {
    this.indexModel.OrderBy = event.active;
    this.indexModel.OrderByAsc = event.direction == AppSetting.orderByDscAsc ? AppSetting.orderByAsc : AppSetting.orderByDsc;
    this.indexModel.IsPostBack = true;
    this.getDynamicFieldList();
  }

  onPaginateChange(event) {
    this.indexModel.Page = event.pageIndex + 1;
    this.indexModel.PageSize = event.pageSize;
    this.indexModel.IsPostBack = true;
    this.getDynamicFieldList();
  }

  SearchByKeyword(searchValue: GlobalFilterModel) {

    this.indexModel.Search = searchValue.search;

    this.getDynamicFieldList();
  }

  ClearSearch() {
    this.indexModel = new IndexModel();

    this.getDynamicFieldList();
  }

  OpenAddUpdateDynamicFieldDialog(Id) {

    let CommunicationTypeCode = this.communicationTypeCode;
    let existingDisplayOrder = [];
    this.model.forEach(ele => { existingDisplayOrder.push(ele.DisplayOrder) });

    const _dialogRef = this._dialog.open(AddCommunicationTypeFieldsComponent, {
      height: "80%",
      width: "80%",
      data: { Id, CommunicationTypeCode, SelectedDisplayOrder: existingDisplayOrder.join(',') },
      disableClose: true
    });
    _dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        this.getDynamicFieldList();
      }
    });
  }

  OpenDynamicFieldDetailDialog(fieldCode) {
    const _dialogRef = this._dialog.open(DetailCommunicationTypeFieldDialogComponent, {
      height: "90%",
      width: "85%",
      data: { Id: fieldCode },
      disableClose: true
    });
    _dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        //  this.getDynamicFieldList();
      }
    });
  }

  checkExistingSortOrder(value: string, fieldCode: number) {

    return !this.defaultFieldModel.find(a => a.FieldCode != fieldCode && a.SortOrder && a.SortOrder == Number(value));
  }

  bindDDLSortOrder() {
    this.defaultFieldModel.forEach((item, i) => {
      this.ddlSortOrder.push(String((i + 1)));
    });
  }


  //set Field Sort Order
  SetDefaultFieldSortOrder(item: CommunicationTypeDefaultFieldsMasterModel) {

    let objSortOrder = new DefaultFieldSortOrderPostModel();
    objSortOrder.CommunicationTypeCode = item.CommunicationTypeCode as number;
    objSortOrder.SortOrder = item.SortOrder as number;
    objSortOrder.FieldCode = item.FieldCode;

    let service = this._communicationTypeFieldsMasterService.SetDefaultFieldSortOrder(objSortOrder).subscribe(respose => {
      service.unsubscribe();
      if (respose.IsSucess) {
        this._alertService.success(respose.Message);

      }
    });
  }

  getDefaultFieldList() {

    let GetDefaultFieldsForCommunication = this._communicationTypeFieldsMasterService.GetDefaultFieldsForCommunication(0, this.communicationTypeEnum).subscribe(
      data => {
        GetDefaultFieldsForCommunication.unsubscribe();
        if (data.IsSuccess) {

          this.defaultFieldModel = <CommunicationTypeDefaultFieldsMasterModel[]>data.Data;
          this.defaultFieldModel = this.defaultFieldModel.filter(x => x.FieldCode != 7); //for attachemnt

          this.defaultFieldModel.map(x => x.SortOrder = x.SortOrder ? String(x.SortOrder) : x.SortOrder);
          this.communicationType = this.defaultFieldModel[0].CommunicationType;
          this.communicationTypeCode = Number(this.defaultFieldModel[0].CommunicationTypeCode);
          this.defaultDataSource = new MatTableDataSource<CommunicationTypeDefaultFieldsMasterModel>(this.defaultFieldModel);
          this.TotalDefaultFieldData = this.defaultDataSource.data.length;

          this.bindDDLSortOrder();
        } else {
          this._alertService.error(data.Message);
        }
      },
      error => {
        this._alertService.error(error.message);
      }
    );
  }


  updateDefaultFieldDeleteStatus(CommunicationDefaultFieldCode) {
    const dialogRef = this._dialog.open(ConfirmationDialogComponent, {
      width: "50%",
      data: GlobalMessagesModel.ConfirmDeleted
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this._communicationTypeFieldsMasterService.ChangeDefaultFieldDeleteStatus(CommunicationDefaultFieldCode).subscribe(
          data => {
            if (data.IsSuccess) {
              this._alertService.success(data.Message);
              this._commonService.ScrollingTop();
              this.getDefaultFieldList();
            }
          },
          error => {
            this._alertService.error(error.message);
          }
        );
      }
    });
  }

  changeFieldSortOrderAndLabel(fieldCode = 0) {
    const _dialogRef = this._dialog.open(CommunicationDefaultFieldConfigComponent, {
      height: "50%",
      width: "50%",
      data: { FieldCode: fieldCode, CommunicationTypeCode: this.communicationTypeCode, FieldModel: this.defaultFieldModel },
      disableClose: true
    });
    _dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        this.getDefaultFieldList();
      }
    });
  }

  OpenAssignDefaultFieldDialog(fieldCode = 0) {


    const _dialogRef = this._dialog.open(AssignDefaultFieldComponent, {
      height: "50%",
      width: "50%",
      data: { FieldCode: fieldCode, CommunicationTypeCode: this.communicationTypeCode },
      disableClose: true
    });
    _dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        this.getDefaultFieldList();
      }
    });
  }

}