import { isNullOrUndefined } from "util";
import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { AuthenticationService } from 'src/app/shared/service/authentication.service';
import { GlobalMessagesModel } from 'src/app/shared/model/common-messages.model';
import { AlertService } from 'src/app/shared/service/alert.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { StatusEnum } from 'src/app/shared/enum/fixed-values.enum';
import { CommonService } from 'src/app/shared/service/common.service';
import { ActionModel, ActionDocumentModel } from '../../../shared/model/action.model';
import { ActionService } from '../../../shared/service/action.service';
import { DdlItemModel, DDLModel } from '../../../shared/model/commonddl.model';
import { AppSetting } from 'src/app/shared/model/appsetting.model';
import { AngularEditorConfig } from "@kolkov/angular-editor";

@Component({
  selector: 'app-action-dialog',
  templateUrl: './action-dialog.component.html',
  styleUrls: ['./action-dialog.component.scss']
})

export class ActionDialogComponent implements OnInit {
  editorConfig = AppSetting.editorConfig as AngularEditorConfig;
  loginUserCode: number;
  frmGrp: FormGroup;
  model = new ActionModel();
  fileValidationMsg: string;
  tempDocumentList: ActionDocumentModel[] = [];
  ddlStatus: DdlItemModel[] = [];
  ddlList: DDLModel;
  IsReturn: boolean = false;
  IsAccept: boolean = false;
  IsReject: boolean = false;
  //IsDisposed: boolean = false;
  currStatus: string = "";
  CanReturn: number = 0;
  isDisableSubmitBtn=false;
  constructor(
    private readonly fb: FormBuilder,
    public readonly _commonService: CommonService,
    private readonly _alertService: AlertService,
    private _route: ActivatedRoute,
    private readonly _actionService: ActionService,
    private readonly _authService: AuthenticationService,
    public _dialogRef: MatDialogRef<ActionDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
      
    if (data) {
      if (data.id) {
        this.model.CommunicationCode = data.id;
      }
      if (data.status) {
        this.currStatus = data.status
      }
      if (data.canReturn) {
        this.CanReturn = data.canReturn
      }
    }
    this.loginUserCode = this._authService.UserCode;
    this.bindStatus();
    this.GetDDLList();
  }

  ngOnInit() {
    this.formGroupInit();
  }

  bindStatus() {
    
    if (this.currStatus != StatusEnum.ACCEPT) {
      var item1: DdlItemModel = new DdlItemModel();
      item1.Text = StatusEnum.ACCEPT;
      item1.Value = StatusEnum.ACCEPT;
      this.ddlStatus.push(item1);
    }

    if (this.currStatus != StatusEnum.REJECT) {
      var item2: DdlItemModel = new DdlItemModel();
      item2.Text = StatusEnum.REJECT;
      item2.Value = StatusEnum.REJECT;
      this.ddlStatus.push(item2);
    }

    if (this.currStatus != StatusEnum.RETURN && this.CanReturn == 1) {
      var item3: DdlItemModel = new DdlItemModel();
      item3.Text = StatusEnum.RETURN;
      item3.Value = StatusEnum.RETURN;
      this.ddlStatus.push(item3);
    }
    // if (this.currStatus != StatusEnum.DISPOSED ) {
    //   var item4: DdlItemModel = new DdlItemModel();
    //   item4.Text = StatusEnum.DISPOSED;
    //   item4.Value = StatusEnum.DISPOSED;
    //   this.ddlStatus.push(item4);
    // }
    this.showStatusReasonDropdown();
  }

  formGroupInit() {
    this.frmGrp = this.fb.group({
      Reply: [null, Validators.required],
      Status: [null, Validators.required],
      ReasonCode: [null]

      //ReasonCode: new FormControl({ value: '', IsReturn:true }, [Validators.required])
    });
  }

  showStatusReasonDropdown() {


    this.IsReturn = false;
    this.IsAccept = false;
    this.IsReject = false;
    //this.IsDisposed=false;
    if (this.model.Status == "ACCEPT") {
      this.IsAccept = true;
    }
     if (this.model.Status == "REJECT") {
      this.IsReject = true;
    }

     if (this.model.Status == "RETURN") {
      this.IsReturn = true;
    }
    // if (this.model.Status == "DISPOSED") {
    //   this.IsDisposed = true;
    // }

  }

  saveClick() {
    this.isDisableSubmitBtn=true;
    this.setReply();
    this.frmGrp.markAllAsTouched();
    if (this.frmGrp.valid) {
      this.model.ActionCode = 0;
      this.model.CreatedBy = this.loginUserCode;
      this.model.DocumentList = this.tempDocumentList;
      this._actionService.AddUpdate(this.model).subscribe(
        data => {
          if (data) {
            if (data.IsSuccess) {
              this.isDisableSubmitBtn=false;
              this._alertService.success('Communication ' + this.model.Status.toLowerCase() + ' successfully.');
              this._dialogRef.close(true);
             
              //Update Inbox/outbox counts
              this._commonService.isUpdateInboxoutbox.next(true);
            }
            else {
              this._alertService.error('Failed to update status.');
            }
          }
          else {
            this._alertService.error(GlobalMessagesModel.InternalError);
          }
        },
        error => {
          this._alertService.error(error.message);
        }
      );
    }else{
      this.isDisableSubmitBtn=false;
    }
  }

  handleFileInput(files: FileList) {


    this.tempDocumentList = [];
    for (let index = 0; index < files.length; index++) {
      if (files.item(index).type.match("application/pdf")) {
        if (files.item(index).size < AppSetting.FileSizeLimit.PDF) {
          const reader = new FileReader();
          reader.onload = (e: any) => {
            this.tempDocumentList.push(new ActionDocumentModel());
            this.tempDocumentList[index].DocName = <string>reader.result;
            this.tempDocumentList[index].DocPath = <string>reader.result;

            this.tempDocumentList[index].DocName = files[index].name;
            this.tempDocumentList[index].Extension = files[index].name.split(".")[1];
            this.tempDocumentList[index].DocPath = <string>reader.result;
          };
          reader.readAsDataURL(files[index]);
        }
        else {
          this.tempDocumentList = [];
          this.fileValidationMsg = AppSetting.FileSizeLimit.PDFErrorMSG;
          return;
        }
      }
      else {
        this.tempDocumentList = [];
        this.fileValidationMsg = "Only *pdf";
        return;
      }
    }
    this.fileValidationMsg = "";
  }

  downloadPdf(Url, name) {
    const linkSource = Url;
    const downloadLink = document.createElement("a");
    const fileName = name;
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.target = "blank";
    downloadLink.click();
  }

  deleteDocument(documentCode: number, index: number) {
    this.tempDocumentList.splice(index, 1);
  }

  CloseDialog(): void {
    this._dialogRef.close();
  }

  GetDDLList() {
    this._commonService.GetAllDDL(AppSetting.CommunicationActionDropdownKeys).subscribe(
      data => {
        if (data.IsSuccess) {
          this.ddlList = <DDLModel>data.Data;
        }
      },
      error => {
        this._alertService.error(error.message);
      }
    );
  }


  setReply() {

    if ((isNullOrUndefined(this.model.Reply)) && !isNullOrUndefined(this.model.ReasonCode)) {
      if (this.model.Status == 'ACCEPT') {
        this.model.Reply = this.ddlList.ddlAcceptReason[this.ddlList.ddlAcceptReason.findIndex(x => Number(x.Value) == Number(this.model.ReasonCode))].Text;

      }
      if (this.model.Status == 'REJECT') {
        this.model.Reply = this.ddlList.ddlRejectReason[this.ddlList.ddlRejectReason.findIndex(x => Number(x.Value) == Number(this.model.ReasonCode))].Text;

      }

      if (this.model.Status == 'RETURN') {

        this.model.Reply = this.ddlList.ddlReason[this.ddlList.ddlReason.findIndex(x => Number(x.Value) == Number(this.model.ReasonCode))].Text;
      }
    }

  }


}