import { Injectable } from '@angular/core';
import { AuthenticationService } from './authentication.service';
import { BaseService } from './base.service';
import { IndexModel } from '../model/common.model';
import { AppSetting } from '../model/appsetting.model';
import { UserTypeEnum } from '../enum/fixed-values.enum';
import { FormBuilder, FormGroup } from '@angular/forms';
import { VendorPermissionDelegationRequestModel } from '../model/master/vendor-permission-delegation.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AlertService } from './alert.service';
import { VendorPermissionDelegationListViewModel } from '../model/master/vendor-permission-list-view.model';

@Injectable()
export class VendorPermissionDelegationService {
  private form: FormGroup;
  private model: VendorPermissionDelegationRequestModel;

  constructor(private readonly _baseService: BaseService,
    private readonly _authService: AuthenticationService,
    private readonly _formBuilder: FormBuilder,
    private readonly _alertService: AlertService
  ) {
    this.model = new VendorPermissionDelegationRequestModel();
    this.form = this.initializeForm();
  }


  canUserTypeCreateWO() {
    if (this._authService.BaseUserType == UserTypeEnum.DOS || this._authService.BaseUserType == UserTypeEnum.HOD
    ) {
      return true;
    } else {
      return false;
    }
  }

  public get Form() {
    return this.form;
  }

  public get DelegateUserID() {
    return this.Form.get('delegateUserID');
  }

  public get DelegatorUserID() {
    return this.Form.get('delegatorUserID');
  }

  public add(model: VendorPermissionDelegationRequestModel): Observable<boolean> {
    return this._baseService.post(AppSetting.AddVendorPermissionDelegation, model).pipe(
      map(response => {
        if (response.IsSuccess) {
          this._alertService.success(response.Message);
          return true;
        }
        this._alertService.error(response.Message)
        return false;
      })
    );
  }

  public getAll(model: IndexModel): Observable<VendorPermissionDelegationListViewModel[]> {
    return this._baseService.post(AppSetting.GetDataVendorPermissionDelegation, model).pipe(
      map(response => {
        if (response.IsSuccess) {
          return response.Data as VendorPermissionDelegationListViewModel[];
        }
        return [];
      })
    );
  }

  public getDetail(id: number) {
    return this._baseService.get(AppSetting.GetByIdVendorPermissionDelegation + id);
  }
  private initializeForm(): FormGroup {
    return this._formBuilder.group({
      delegateUserID: [this.model.delegateUserID],
      delegatorUserID: [this.model.delegatorUserID],
    });
  }
}
