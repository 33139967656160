export class SuggestionForModel {
    SuggestionCode: number;
    Suggestion: string;
    SuggestionHindi: string;
    IsActive: boolean;
    IsDeleted: boolean;
    CreatedBy: number | null;
    CreatedOn: Date | null;
    ModifiedBy: number | null;
    ModifiedOn: Date | null;
    UserName: string;
}
export class SuggestionForReportModel {
    MasterCode: number;
    FieldValueInEnglish: string;
    FieldValueInHindi: string;
    IsActive: boolean;
    IsDeleted: boolean;
    CreatedBy: number | null;
    CreatedOn: Date | null;
    ModifiedBy: number | null;
    ModifiedOn: Date | null;
    UserName:string;
}
