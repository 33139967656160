import { Injectable } from "@angular/core";
import { AppSetting } from "../../model/appsetting.model";
import { IndexModel } from "../../model/common.model";
import { BaseService } from "../base.service";
import { ChiefMinisterProfileModel } from "../../model/master/chief-minister-profile/chief-minister-profile.model";
import { Dictionary } from "../../model/dictionary.model";
import { FormBuilder, Validators, FormGroup } from "@angular/forms";
import { ChiefMinisterProfileUpdateRequestModel } from "../../model/master/chief-minister-profile/chief-minister-profile-update-request.model";
@Injectable()

export class ChiefMinisterProfileService {

    private form: FormGroup = this.getNewForm();

    constructor(
        private readonly _baseService: BaseService,
        private readonly _formBuilder: FormBuilder) {
    }

    //#region Properties
    get Form() {
        return this.form;
    }

    get IsCodeOfConduct() {
        return this.Form.get('isCodeOfConduct');
    }

    get CodeOfConductFromDate() {
        return this.Form.get('codeOfConductFromDate');
    }

    get CodeOfConductToDate() {
        return this.Form.get('codeOfConductToDate');
    }

    get PdfFIleName() {
        return this.Form.get('pdfFIleName');
    }
    //#endregion Properties

    //#region APIs
    get() {
        return this._baseService.get(AppSetting.ChiefMinisterProfileListUrl);
    }

    getById(id: number, isBase64) {
        let param = new Dictionary<any>();
        param.Add("id", id);
        param.Add("isBase64", isBase64);
        return this._baseService.get(AppSetting.ChiefMinisterProfileDetailUrl, param);
    }

    update(model: ChiefMinisterProfileUpdateRequestModel) {
        return this._baseService.post(AppSetting.ChiefMinisterProfileEditUrl, model);
    }
    //#endregion APIs

    //#region Private Methods
    private getNewForm(): FormGroup {
        return this._formBuilder.group({
            isCodeOfConduct: [false, Validators.required],
            codeOfConductFromDate: [null],
            codeOfConductToDate: [null],
            pdfFIleName: ['']
        });
    }
    //#endregion Private Methods
}
