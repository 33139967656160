import { Component, OnInit, Inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { AppSetting } from 'src/app/shared/model/appsetting.model';
import { DDLModel } from 'src/app/shared/model/commonddl.model';
import { GlobalMessagesModel } from 'src/app/shared/model/common-messages.model';
import { CommonService } from 'src/app/shared/service/common.service';
import { AlertService } from 'src/app/shared/service/alert.service';
import { UserViewModel } from 'src/app/shared/model/user.model';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { AuthenticationService } from 'src/app/shared/service/authentication.service';
import { BankAccountModel, BankBranchViewModel } from 'src/app/shared/model/bank-account.model';
import { BankAccountService } from 'src/app/shared/service/bank-account.service';
import { isNullOrUndefined } from 'util';
import { AlertTypeEnum } from 'src/app/shared/enum/fixed-values.enum';
import { AlertModel } from 'src/app/shared/model/common.model';

@Component({
  selector: 'app-add-update-bank-account-dialog',
  templateUrl: './add-update-bank-account-dialog.component.html',
  styleUrls: ['./add-update-bank-account-dialog.component.scss']
})

export class AddUpdateBankAccountDialogComponent implements OnInit {

  loginUserDetail: UserViewModel;
  frmGrp: FormGroup;
  ddlList: DDLModel = new DDLModel();
  model: BankAccountModel = new BankAccountModel();
  recordId: number = 0;
  heading: string;
  oldBankAccountNo: string;
  isDisable: boolean = false;
  isBankListDisable: boolean = true;
  submitBtn: boolean = true;
  fileErrMsg: string;

  constructor(private readonly fb: FormBuilder,
    public readonly _commonService: CommonService,
    private readonly _alertService: AlertService,
    private readonly _bankAccountService: BankAccountService,
    private readonly _authService: AuthenticationService,
    public _dialogRef: MatDialogRef<AddUpdateBankAccountDialogComponent>,
    private readonly _route: ActivatedRoute,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.GetDDLList();
    this.model = new BankAccountModel();
    this.model.IsPrimary = false;
    this.loginUserDetail = this._authService.GetCurrentUserDetail().UserViewModel;
    if (data) {
      this.model.VendorCode = Number(data.VendorCode);
      if (data.Id) {
        this.recordId = data.Id;
        this.GetDetail();
      }
    }
    this.heading = (this.recordId == 0 ? "Add" : "Update") + " Bank Account";
  }

  ngOnInit() {
    this.formGroupInit();
  }

  formGroupInit() {
    this.frmGrp = this.fb.group({
      BankHolderName: [null, Validators.compose([Validators.required, Validators.maxLength(100)])],
      BankAccountNo: [null, Validators.compose([Validators.required, Validators.maxLength(16)])],
      IFSCCode: [null, Validators.compose([Validators.required, Validators.maxLength(11)])],
      BankCode: [null],
      BankBranchCode: [null],
      IsPrimary: [null],
      IsKnowIFSCCode: [null]
    });
  }

  GetDDLList() {
    this._commonService.GetAllDDL(AppSetting.BankAccountDropdownKeys).subscribe(data => {
      if (data.IsSuccess) {
        this.ddlList = <DDLModel>data.Data;
      }
    }, error => {
      this._alertService.error(error.message);
    });
  }

  GetDetail() {
    this._bankAccountService.Detail(this.recordId).subscribe(data => {
      if (data.IsSuccess) {
        this.model = <BankAccountModel>data.Data;
        this.model.BankCode = String(this.model.BankCode);
        this.oldBankAccountNo = this.model.BankAccountNo;
      }
    }, error => {
      this.model = new BankAccountModel();
      this._alertService.error(error.message);
    });
  }

  SaveClick() {
    this.fileErrMsg = undefined;
    this.frmGrp.markAllAsTouched();
    if (this.frmGrp.valid && (this.model.UploadFile || this.model.CancelChequeBase64)) {
      this.submitBtn = false;
      this.isDisable = true;

      if (this.recordId == 0) {
        this.model.CreatedBy = this.loginUserDetail.UserCode;
      }
      else {
        this.model.ModifiedBy = this.loginUserDetail.UserCode;
      }

      this._bankAccountService.AddUpdate(this.recordId, this.model).subscribe(data => {
        if (data.IsSuccess) {
          this._alertService.success(this.recordId == 0 ? GlobalMessagesModel.saveSuccess : GlobalMessagesModel.updateSuccess);
          this._dialogRef.close(true);
        } else {
          this.submitBtn = true;
          this._alertService.error(data.Message);
          this._alertService.error(this.recordId == 0 ? GlobalMessagesModel.saveError : GlobalMessagesModel.updateError);
        }
      },
        error => {
          this.submitBtn = true;
          this._alertService.error(error.message);
        });
    }
    else if (!(this.model.UploadFile || this.model.CancelChequeBase64)) {
      this.fileErrMsg = "Please upload cancel cheque!";
    }
    this.isDisable = false;
  }

  IsVendorBankAccountExist(Event) {
    if (this.oldBankAccountNo != Event.currentTarget.value) {
      this._bankAccountService.IsVendorBankAccountExist(Event.currentTarget.value, this.model.VendorCode).subscribe(result => {

        if (result.IsSuccess) {
          let isExist = <boolean>result.Data
          if (isExist == true) {
            this.model.BankAccountNo = null;
            this._alertService.error("Account No is already exist!");
          }
        }
      }, error => {
        this._commonService.ScrollingTop();
        this._alertService.error(error.message);
      });
    }
  }

  GetBankBranch() {
    if (!isNullOrUndefined(this.model.BankCode)) {
      this._commonService.GetDDL(AppSetting.ddlBankBranch, this.model.BankCode).subscribe(
        data => {
          if (data.IsSuccess) {
            var ddl = <DDLModel>data.Data;
            this.ddlList.ddlBankBranch = ddl.ddlBankBranch;
          }
        },
        error => {
          this._alertService.error(error.message);
        }
      );
    }
    else {
      this.ddlList.ddlBankBranch = null;
    }
  }

  GetBankBranchDetail() {
    if (!isNullOrUndefined(this.model.IFSCCode)) {
      this._bankAccountService.GetBankBranchDetailByIFSC(this.model.IFSCCode).subscribe(
        data => {
          if (data.IsSuccess) {
            var bankBranchModel = <BankBranchViewModel>data.Data;
            this.model.IFSCCode = bankBranchModel.IFSCCode;
            this.model.BankBranchCode = bankBranchModel.BankBranchCode;
            this.model.BankName = bankBranchModel.BankName;
            this.model.BranchName = bankBranchModel.BranchName;
            this.model.BankBranchAddress = bankBranchModel.Address;
          }
          else {
            this.model.IFSCCode = null;
            this.model.BankBranchCode = null;
            this.model.BankName = null;
            this.model.BranchName = null;
            this.model.BankBranchAddress = null;
          }
        },
        error => {
          this.model.IFSCCode = null;
          this.model.BankBranchCode = null;
          this.model.BankName = null;
          this.model.BranchName = null;
          this.model.BankBranchAddress = null;
          this._alertService.error(error.message);
        }
      );
    }
    else {
      this.model.IFSCCode = null;
      this.model.BankBranchCode = null;
      this.model.BankName = null;
      this.model.BranchName = null;
      this.model.BankBranchAddress = null;
    }
  }

  CloseAddUpdateBankAccountDialog(): void {
    this._dialogRef.close();
  }

  GetBankBranchDetailByBrachCode() {
    if (!isNullOrUndefined(this.model.BankBranchCode)) {
      this._bankAccountService.GetBankBranchDetailByBranchCode(this.model.BankBranchCode).subscribe(
        data => {
          if (data.IsSuccess) {
            var bankBranchModel = <BankBranchViewModel>data.Data;
            this.model.IFSCCode = bankBranchModel.IFSCCode;
            // this.model.BankBranchCode = bankBranchModel.BankBranchCode;
            // this.model.BankName = bankBranchModel.BankName;
            // this.model.BranchName = bankBranchModel.BranchName;
            // this.model.BankBranchAddress = bankBranchModel.Address;
          }
          else {
            this.model.IFSCCode = null;
            // this.model.BankBranchCode = null;
            // this.model.BankName = null;
            // this.model.BranchName = null;
            // this.model.BankBranchAddress = null;
          }
        },
        error => {
          this.model.IFSCCode = null;
          // this.model.BankBranchCode = null;
          // this.model.BankName = null;
          // this.model.BranchName = null;
          // this.model.BankBranchAddress = null;
          this._alertService.error(error.message);
        }
      );
    }
    else {
      this.model.IFSCCode = null;
      // this.model.BankBranchCode = null;
      // this.model.BankName = null;
      // this.model.BranchName = null;
      // this.model.BankBranchAddress = null;
    }
  }

  DisableBankListSection() {

    if (this.model.IsKnowIFSCCode == true) {
      this.isBankListDisable = false;
    }
    else {
      this.isBankListDisable = true;
      this.model.IFSCCode = null;
    }
  }

  handleFileInput(event: any) {
    this.fileErrMsg = undefined;
    if (event.target.files.item(0).type.match('image/*')) {
      if (event.target.files.item(0).size > AppSetting.FileSizeLimit.DEFAULT) {
        this.fileErrMsg = AppSetting.FileSizeLimit.DEFAULTErrorMSG;
        this._commonService.ScrollingTop();
      } else {
        let reader = new FileReader();
        reader.onload = (event: any) => {
          this.model.UploadFile = event.target.result;
        }
        reader.readAsDataURL(event.target.files.item(0));
      }
    } else {
      this.fileErrMsg = "Only *images accepted!";
    }
  }

}

