import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import { AuthenticationService } from 'src/app/shared/service/authentication.service';
import { GlobalMessagesModel } from 'src/app/shared/model/common-messages.model';
import { AppComponent } from 'src/app/app.component';
import { AlertService } from 'src/app/shared/service/alert.service';
import { CommonService } from 'src/app/shared/service/common.service';
import { UserViewModel } from 'src/app/shared/model/user.model';
import { AppSetting } from 'src/app/shared/model/appsetting.model';
import { WorkOrderService } from 'src/app/shared/service/work-order.service';
import { WorkOrderModel, WorkOrderDocumentModel } from 'src/app/shared/model/work-order.model';
import { StateEnum, UserTypeEnum } from 'src/app/shared/enum/fixed-values.enum';
import { DdlItemModel, DDLModel, FilterDDlModel } from 'src/app/shared/model/commonddl.model';
import { ProjectService } from 'src/app/shared/service/project.service';
import { ProjectModel } from 'src/app/shared/model/project.model';
import { DateAdapter, MatDialog, MAT_DATE_FORMATS } from '@angular/material';
import { AppDateAdapter, APP_DATE_FORMATS } from 'src/app/shared/model/format-datepicker';
import { UserService } from 'src/app/shared/service/user.service';
import { ConfirmationDialogComponent } from 'src/app/confirmation-dialog/confirmation-dialog.component';

@Component({
  selector: 'app-add-update-work-order',
  templateUrl: './add-update-work-order.component.html',
  styleUrls: ['./add-update-work-order.component.scss'],
  providers: [
    { provide: DateAdapter, useClass: AppDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS },
  ]
})

export class AddUpdateWorkOrderComponent implements OnInit {

  loginBaseUserType: string;
  frmGrp: FormGroup;
  RecordId: number = 0;
  oldOrderNo: string;
  fileValidationMsg: string;
  filterDDlModel: any[];
  submitBtn = true;
  ddlList: DDLModel = new DDLModel();
  tempDeletedDocumentCodes: string[] = [];
  tempDocumentList: WorkOrderDocumentModel[] = [];
  model: WorkOrderModel = new WorkOrderModel();
  loginUserDetail: UserViewModel = new UserViewModel();
  projectDetailModel: ProjectModel = new ProjectModel();
  userTypeEnum = UserTypeEnum;

  get minDate(): Date {
    var tomorrow = this.model != null && this.model.WorkOrderCode > 0 && this.model.WorkOrderEndtDate != undefined ? new Date(this.model.WorkOrderEndtDate) : this.model.WorkOrderStartDate != undefined ? new Date(this.model.WorkOrderStartDate) : new Date();
    this.model != null && this.model.WorkOrderCode > 0 ? tomorrow.setDate(tomorrow.getDate()) : tomorrow.setDate(tomorrow.getDate() + 1);
    return tomorrow;
  }

  startMinDate: Date = (this.model != null && this.model.OrderDate != null ? new Date(this.model.OrderDate) : new Date());
  wrkOrderMinDate: Date = new Date();
  wrkOrderMaxDate: Date = new Date();
  constructor(
    private readonly fb: FormBuilder,
    private readonly appComponent: AppComponent,
    public readonly _commonService: CommonService,
    private readonly _alertService: AlertService,
    private readonly _router: Router,
    private _route: ActivatedRoute,
    private readonly _workOrderService: WorkOrderService,
    private readonly _authService: AuthenticationService,
    private readonly _projectService: ProjectService,
    private readonly _userService: UserService,
    private readonly _dialog: MatDialog,
  ) {
    if (this._route.snapshot.params.id) {
      this.RecordId = this._route.snapshot.params.id;
    }
    var pageHeading = (this.RecordId == 0 ? "Add" : "Update") + " Work Order :";
    this.appComponent.setPageLayout(pageHeading, "keyboard_backspace", "Back To List", "work-order");
    this.loginUserDetail = this._authService.GetCurrentUserDetail().UserViewModel;
    this.loginBaseUserType = this._authService.BaseUserType;

    this.GetDDLList();
  }

  ngOnInit() {
    if (!this._workOrderService.canUserTypeCreateWO()) {
      this._router.navigate(["dashboard"]);
    }

    this.formGroupInit();
    this.getDetail();
  }

  formGroupInit() {
    this.frmGrp = this.fb.group({
      ProjectCode: [null, Validators.required],
      DepartmentCode: new FormControl({ value: '' }, [Validators.required]),
      DistrictCode: [null, Validators.required],
      OfficeCode: [null, Validators.required],
      OrderNumber: [null, Validators.compose([Validators.required, Validators.maxLength(35)])],
      OrderDate: ["", Validators.required],
      Title: [null, Validators.compose([Validators.maxLength(100)])],
      Subject: [null, Validators.compose([Validators.required, Validators.maxLength(100)])],
      Description: [null, Validators.compose([Validators.required, Validators.maxLength(500)])],
      Tender: [null, Validators.maxLength(100)],
      Referencee: [null, Validators.maxLength(100)],
      Amount: [null, Validators.compose([Validators.required, Validators.maxLength(100)])],
      UserCode: [{ value: null, disabled: true }, null],
      OIC_Designation: [{ value: null, disabled: true }, null],
      OIC_Email: [{ value: null, disabled: true }, null],
      OIC_Mobile: [{ value: null, disabled: true }, null],
      CategoryCode: [null, Validators.required],
      WorkOrderSubCategoryCode: [undefined],
      WorkOrderStartDate: [null],
      WorkOrderEndtDate: [null],
      PaymentTypeCode: [null, Validators.required],
      WorkLocation: [null, null]
    });
  }

  GetDDLList() {
    this._commonService.GetAllDDL(AppSetting.WorkOrderDropdownKeys, Number(this.loginUserDetail.UserTypeCode), this.loginUserDetail.UserCode).subscribe(
      data => {
        if (data.IsSuccess) {
          var ddlList = <DDLModel>data.Data;

          this.ddlList.ddlProjectDepartment = ddlList.ddlProjectDepartment;
          //this.ddlList.ddlOfficeDistrict = ddlList.ddlOfficeDistrict;
          this.ddlList.ddlProject = ddlList.ddlProject;
          this.ddlList.ddlWorkOrderCategory = ddlList.ddlWorkOrderCategory;
          this.ddlList.ddlPaymentType = ddlList.ddlPaymentType;

          if (this.RecordId == 0) {
            this.changeFieldForUser();
          }
        }
      },
      error => {
        this._alertService.error(error.message);
      }
    );
  }

  GetOffice() {
    if (this.model.DepartmentCode) {
      this.model.DistrictCode = (this.model.DistrictCode == undefined) ? 0 : this.model.DistrictCode;
      this._userService.GetUserOfficeList(this.model.DepartmentCode, this.model.DistrictCode).subscribe(data => {
        if (data.IsSuccess) {
          this.ddlList.ddlDepartmentOffice = <DdlItemModel[]>data.Data;
        }
      }, error => {
        this._alertService.error(error.message);
      });
    }
    else {
      this.ddlList.ddlDepartmentOffice = [];
    }
  }

  GetUserOfficeHavingStaff() {
    if (this.model.DepartmentCode) {
      this.model.DistrictCode = (this.model.DistrictCode == undefined) ? 0 : this.model.DistrictCode;
      this._userService.GetUserOfficeHavingStaffList(this.model.DepartmentCode, this.model.DistrictCode).subscribe(data => {
        if (data.IsSuccess) {
          this.ddlList.ddlDepartmentOffice = <DdlItemModel[]>data.Data;
        }
      }, error => {
        this._alertService.error(error.message);
      });
    }
    else {
      this.ddlList.ddlDepartmentOffice = [];
    }
  }
  
  getDetail() {
    if (this.RecordId > 0) {
      this._workOrderService.Detail(this.RecordId).subscribe(data => {
        if (data.IsSuccess) {
          this.model = <WorkOrderModel>data.Data;
          this.oldOrderNo = this.model.OrderNumber;

          if (this.model.ProjectCode) { this.model.ProjectCode = String(this.model.ProjectCode); }
          this.GetProjectDetail();
          if (this.model.DepartmentCode) { this.model.DepartmentCode = String(this.model.DepartmentCode); }
          if (this.model.DistrictCode) { this.model.DistrictCode = String(this.model.DistrictCode); }
          //this.GetOffice();
          this.GetUserOfficeHavingStaff()

          if (this.model.OfficeCode) { this.model.OfficeCode = String(this.model.OfficeCode); }

          this.GetOfficeUser();
          this.model.UserCode = String(this.model.UserCode);
          this.model.CategoryCode = this.model.CategoryCode ? String(this.model.CategoryCode) : undefined;
          this.model.WorkOrderSubCategoryCode = this.model.WorkOrderSubCategoryCode ? String(this.model.WorkOrderSubCategoryCode) : undefined;
          if (this.model.PaymentTypeCode) { this.model.PaymentTypeCode = String(this.model.PaymentTypeCode); }
          this.wrkOrderMinDate = this.model.OrderDate ? new Date(this.model.OrderDate) : this.wrkOrderMinDate;
          this.tempDocumentList = this.model.DocumentList;
          //this.changeFieldForUser();
        }
      }, error => {
        this._alertService.error(error.message);
      });
    }
  }

  submitData() {
    if (!this.model.IsDraft) {
      this.frmGrp.markAllAsTouched();
    }
    if ((this.frmGrp.valid && !this.model.IsDraft) || this.model.IsDraft) {
      if (this.RecordId == 0) {
        this.model.CreatedBy = this.loginUserDetail.UserCode;
      }
      else {
        this.model.ModifiedBy = this.loginUserDetail.UserCode;
      }

      this.model.DocumentList = this.tempDocumentList;
      this.model.DeletedDocumentCodes = this.tempDeletedDocumentCodes.toString();
      if (!this.model.IsDraft) {
        const dialogRef = this._dialog.open(ConfirmationDialogComponent, {
          width: "50%",
          data: GlobalMessagesModel.ConfirmFinalSubmit,
          disableClose: true
        });
        dialogRef.afterClosed().subscribe(result => {
          if (result) {
            this.model.IsFinalSubmit = (result as boolean);
          } else {
            this.model.IsFinalSubmit = false;
          }
          this.saveData();
        });
      } else {
        this.saveData();
      }

    } else {
      this.submitBtn = true;
    }
  }

  saveData() {
    this._workOrderService.AddUpdate(this.RecordId, this.model).subscribe(
      data => {
        if (data) {
          if (data.IsSuccess) {
            this._alertService.success(this.RecordId == 0 ? GlobalMessagesModel.saveSuccess : GlobalMessagesModel.updateSuccess);
            this._router.navigate(["work-order"]);
          } else {
            this.submitBtn = true;
            this._commonService.ScrollingTop();
            this._alertService.error(data.Message);
          }
        } else {
          this.submitBtn = true;
          this._commonService.ScrollingTop();
          this._alertService.error(GlobalMessagesModel.InternalError);
        }
      },
      error => {
        this.submitBtn = true;
        this._commonService.ScrollingTop();
        this._alertService.error(GlobalMessagesModel.InternalError);
      }
    );
  }

  IsWorkOrderNumberExist(Event) {
    if (this.oldOrderNo != Event.currentTarget.value) {
      this._workOrderService.IsWorkOrderNoExist(Event.currentTarget.value).subscribe(result => {

        if (result.IsSuccess) {
          let UserName = <boolean>result.Data
          if (UserName == true) {
            this.model.OrderNumber = null;
            this._alertService.error("Order Number is already exist!");
          }
        }
      }, error => {
        this._commonService.ScrollingTop();
        this._alertService.error(error.message);
      });
    }
  }

  handleFileInput(files: FileList) {    
    let count = 0;
    if (this.tempDocumentList != undefined && this.tempDocumentList.length > 0) {
      count = this.tempDocumentList.length;
    }

    for (let index = 0; index < files.length; index++) {
      if (files.item(index).type.match("application/pdf")) {
        if (files.item(index).size < AppSetting.FileSizeLimit.PDF) {
          const reader = new FileReader();
          reader.onload = (e: any) => {

            if (this.tempDocumentList == undefined) {
              this.tempDocumentList = [];
            }

            this.tempDocumentList.push(new WorkOrderDocumentModel()
            );
            this.tempDocumentList[count + index].DocName = <string>reader.result;
            this.tempDocumentList[count + index].DocPath = <string>reader.result;
            this.tempDocumentList[count + index].DocName = files[index].name;
            this.tempDocumentList[count + index].Extension = files[index].name.split(".")[1];
            this.tempDocumentList[count + index].DocPath = <string>reader.result;
          };
          reader.readAsDataURL(files[index]);
        }
        else {
          this.tempDocumentList = [];
          this.fileValidationMsg = AppSetting.FileSizeLimit.PDFErrorMSG;
          return;
        }
      }
      else {
        this.tempDocumentList = [];
        this.fileValidationMsg = "Only *pdf";
        return;
      }
    }
    this.fileValidationMsg = "";
  }

  downloadPdf(Url, name) {
    const linkSource = Url;
    const downloadLink = document.createElement("a");
    const fileName = name;
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.target = "blank";
    downloadLink.click();

  }

  deleteWorkOrderDocument(documentCode: number, index: number) {
    if (documentCode)
    this.tempDeletedDocumentCodes.push(documentCode.toString());

    this.tempDocumentList.splice(index, 1);
  }

  GetProjectDetail() {
    if (this.model.ProjectCode) {
      this._projectService.Detail(Number(this.model.ProjectCode)).subscribe(data => {
        if (data.IsSuccess) {
          this.projectDetailModel = <ProjectModel>data.Data;
          this.model.DepartmentCode = String(this.projectDetailModel.DepartmentCode);
          this.GetDistrictByDepartment();
          this.model.Tender = this.projectDetailModel.TendorID;
          // this.wrkOrderMinDate = this.projectDetailModel.TendorRefDate ? new Date(this.projectDetailModel.TendorRefDate) : (this.model.OrderDate ? new Date(this.model.OrderDate) : this.wrkOrderMinDate);
          //this.wrkOrderMaxDate = this.projectDetailModel.CreatedOn ? new Date(this.projectDetailModel.CreatedOn) : this.wrkOrderMaxDate;          
          this.wrkOrderMinDate = this.projectDetailModel.CreatedOn ? new Date(this.projectDetailModel.CreatedOn) : (this.model.OrderDate ? new Date(this.model.OrderDate) : this.wrkOrderMinDate);

          this.startMinDate = this.model != undefined && this.model.OrderDate != undefined ? new Date(this.model.OrderDate) : this.startMinDate;
          this.model.UserCode = String(this.projectDetailModel.UserCode);
        }
      }, error => {
        this._alertService.error(error.message);
      });
    }
    else {
      this.projectDetailModel = new ProjectModel();
    }
  }

  GetOfficeUser() {
    if (this.model.OfficeCode) {
      this.model.DistrictCode = (this.model.DistrictCode == undefined) ? 0 : this.model.DistrictCode;
      this._commonService.GetDDL(AppSetting.ddlOfficeReceptionUsers, this.model.OfficeCode).subscribe(
        data => {
          if (data.IsSuccess) {
            var ddl = <DDLModel>data.Data;
            this.ddlList.ddlOfficeReceptionUsers = ddl.ddlOfficeReceptionUsers;
          }
        },
        error => {
          this._alertService.error(error.message);
        }
      );
    }
    else {
      this.ddlList.ddlOfficeReceptionUsers = null;
    }
  }

  GetUserDetail() {
    if (this.model.UserCode && this.model.UserCode != 0) {
      this._userService.Detail(Number(this.model.UserCode)).subscribe(
        data => {
          if (data.IsSuccess) {
            var userModel = <UserViewModel>data.Data;
            this.model.OIC_Email = userModel.Email;
            this.model.OIC_Designation = userModel.Designation;
            this.model.OIC_DesignationName = userModel.DesignationName;
            this.model.OIC_Mobile = userModel.Mobile;
          }
        },
        error => {
          this._alertService.error(error.message);
        }
      );
    }

  }

  getFilterdDDL(filterFor, value, filterFrom) {
    const item = new FilterDDlModel();
    item.FilterFor = filterFor;
    item.Value = value;
    item.FilterFrom = filterFrom;
    this.filterDDlModel = [];
    this.filterDDlModel.push(item);
    this._commonService.GetFilterdDDL(this.filterDDlModel).subscribe(data => {
      if (data.IsSuccess) {
        if (item.FilterFor == "ddlWorkOrderSubCategory") {
          this.ddlList.ddlWorkOrderSubCategory = data.Data.ddlWorkOrderSubCategory;
        }
      }
    }, error => {
      this._alertService.error(error.message);
    });
  }

  GetDistrictByDepartment() {
    if (this.model.DepartmentCode) {
      let stateCode = String(StateEnum.Rajasthan);
      this._userService.GetUserDistrictList(stateCode, false, this.model.DepartmentCode.toString()).subscribe(data => {
        if (data.IsSuccess) {
          // this.ddlList.ddlDistrict = <DdlItemModel[]>data.Data;
          this.ddlList.ddlOfficeDistrict = <DdlItemModel[]>data.Data;
        }
      }, error => {
        this._alertService.error(error.message);
      });
    }
    else {
      // this.ddlList.ddlDistrict = [];
      this.ddlList.ddlOfficeDistrict = [];
    }
  }

  GetProjectByDepartmentOffice() {
    this.ddlList.ddlProject = null;
    if (this.model.DepartmentCode) {
      let officeCode = this.model.OfficeCode ? this.model.OfficeCode : 0;
      this._commonService.GetDDL(AppSetting.ddlOfficeProject, this.model.DepartmentCode, officeCode, this.loginUserDetail.UserCode).subscribe((data) => {
        if (data.IsSuccess) {
          var ddl = <DDLModel>data.Data;
          this.ddlList.ddlProject = ddl.ddlOfficeProject;
        }
      }, (error) => {
        this._alertService.error(error.message);
      });
    }
  }

  saveAsDraft() {
    this.model.IsDraft = true;
    this.submitData();
  }

  saveClick() {
    this.submitBtn = false;
    this.model.IsDraft = false;
    this.submitData();
  }

  onDepartmentChange() {
    this.GetProjectByDepartmentOffice();
    setTimeout(() => {
      //this.GetOffice();
      this.GetUserOfficeHavingStaff()
    }, 100);
    setTimeout(() => {
      this.GetDistrictByDepartment();
    }, 100);
  }

  onOfficeChange() {
    this.GetProjectByDepartmentOffice();
    setTimeout(() => { this.GetOfficeUser(); }, 100);
  }

  changeFieldForUser() {
    //const VendorControl = this.frmGrp.get("VendorCode");
    if (this.loginUserDetail.BaseUserType == this.userTypeEnum.DREC || this.loginUserDetail.BaseUserType == this.userTypeEnum.HOO
      || this.loginUserDetail.BaseUserType == this.userTypeEnum.DOS || this.loginUserDetail.BaseUserType == this.userTypeEnum.HOD
      || this.loginUserDetail.BaseUserType == this.userTypeEnum.DRECADLC || this.loginUserDetail.BaseUserType == this.userTypeEnum.HOOADLC
      || this.loginUserDetail.BaseUserType == this.userTypeEnum.DOSADLC || this.loginUserDetail.BaseUserType == UserTypeEnum.VNDR
      || this.loginUserDetail.BaseUserType == UserTypeEnum.VNDRUSER) {

      if (this.RecordId == 0) {
        if (this.loginUserDetail.DepartmentCodes && this.loginUserDetail.DepartmentCodes.split(',').length > 0)
          this.model.DepartmentCode = String(this.loginUserDetail.DepartmentCodes.split(',')[0]);
        if (this.loginUserDetail.DistrictCodes && this.loginUserDetail.DistrictCodes.split(',').length > 0)
          this.model.DistrictCode = String(this.loginUserDetail.DistrictCodes.split(',')[0]);
        if (this.loginUserDetail.OfficeCodes && this.loginUserDetail.OfficeCodes.split(',').length > 0)
          this.model.OfficeCode = String(this.loginUserDetail.OfficeCodes.split(',')[0]);
      }
    }

    // if (this.loginUserDetail.BaseUserType == this.userTypeEnum.VNDR || this.loginUserDetail.BaseUserType == this.userTypeEnum.VNDRUSER) {
    //   VendorControl.setValidators(null);
    //   this.model.VendorCode = String(this.loginUserDetail.UserCode);
    // } else {
    //   VendorControl.setValidators([Validators.required]);
    // }

    // VendorControl.updateValueAndValidity();
  }

}
