import { BaseService } from './base.service';
import { IndexModel } from '../model/common.model';
import { AppSetting } from '../model/appsetting.model';
import { Injectable } from '@angular/core';
import { GlobalFilterSearchModel } from '../model/Global-communication-search.model';

@Injectable({
    providedIn: 'root'
})

export class OutboxService {
    constructor(private readonly _baseService: BaseService) { }

    GetList(workOrderCode: number, loginUserCode: number, search: string, model: IndexModel) {
        return this._baseService.post(AppSetting.OutboxListUrl + "?workOrderCode=" + workOrderCode + "&loginUserCode=" + loginUserCode + "&search=" + search , model);
    }
    
    GetListbySearchFilter(model: GlobalFilterSearchModel) {
        return this._baseService.post(AppSetting.OutboxListUrl, model);
    }

    Detail(id: number) {
        return this._baseService.get(AppSetting.OutboxDetailUrl + id);
    }

    ChangeDeleteStatus(id: number) {
        return this._baseService.get(AppSetting.OutboxDeleteStatusChangeUrl + id);
    }

    // GetOutboxCounter() {
    //     return this._baseService.get(AppSetting.OutboxCounterUrl);
    // } 
}
