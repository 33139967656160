import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatSort, MatDialog, MatTableDataSource } from '@angular/material';
import { AppComponent } from 'src/app/app.component';
import { ConfirmationDialogComponent } from 'src/app/confirmation-dialog/confirmation-dialog.component';
import { AppSetting } from 'src/app/shared/model/appsetting.model';
import { GlobalMessagesModel } from 'src/app/shared/model/common-messages.model';
import { GlobalFilterModel, IndexModel } from 'src/app/shared/model/common.model';
import { ColumnHeaderModel } from 'src/app/shared/model/commonddl.model';
import { PermissionMasterListViewModel } from 'src/app/shared/model/master/permission-master.model';
import { AlertService } from 'src/app/shared/service/alert.service';
import { CommonService } from 'src/app/shared/service/common.service';
import { PermissionMasterService } from 'src/app/shared/service/master/permission-master.service';
import { SetPagePasswordDialogComponent } from './set-page-password-dialog/set-page-password-dialog.component';

@Component({
  selector: 'app-permission-master',
  templateUrl: './permission-master.component.html',
  styleUrls: ['./permission-master.component.scss']
})

export class PermissionMasterComponent implements OnInit {

  model: PermissionMasterListViewModel[];
  dataSource: any;
  displayedColumns: string[] = ['index', 'PermissionTitle', 'ApplicationTitle', 'PermissionTypeName', 'MenuTitle', 'IsActive', 'Action'];
  ViewdisplayedColumns: ColumnHeaderModel[] = [
    { Value: 'PermissionTitle', Text: 'Page Name' },
    { Value: 'ApplicationTitle', Text: 'Module Name' },
    { Value: 'PermissionTypeName', Text: 'Page Type' },
    { Value: 'MenuTitle', Text: 'Menu Name' }
  ];

  columnsToDisplay: string[] = this.displayedColumns.slice();
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  indexModel = this._commonService.modelSetGet(new IndexModel()) as IndexModel;
  totalRecords: number;

  constructor(private readonly _alertService: AlertService,
    private readonly _dialog: MatDialog,
    public readonly _commonService: CommonService,
    private readonly appComponnet: AppComponent,
    private readonly _permissionMasterService: PermissionMasterService,) {
    this.appComponnet.setPageLayout('Pages :', 'add', 'Create', 'master/permission-master/add');

  }

  ngOnInit() {
    this.getList();
  }

  getList() {
    this._permissionMasterService.GetList(this._commonService.modelSetGet(this.indexModel, true)).subscribe(
      data => {
        if (data.IsSuccess) {
          this.model = <PermissionMasterListViewModel[]>data.Data.Data;
          
          this.model.map(item =>
            item.MenuTitle = (item.MenuTitle != undefined && item.MenuTitle.indexOf('~') > -1 ? item.MenuTitle.split('~')[1] : item.MenuTitle))

          this.dataSource = new MatTableDataSource<PermissionMasterListViewModel>(this.model);
          this.totalRecords = data.Data.TotalRecords;
          if (!this.indexModel.IsPostBack) {
            this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.sort;
          }
        }
        else {
          this._alertService.error(data.Message);
        }
      },
      error => {
        this._alertService.error(error.message);
      }
    );
  }

  sortData(event) {
    this.indexModel.OrderBy = event.active;
    this.indexModel.OrderByAsc = event.direction == AppSetting.orderByDscAsc ? AppSetting.orderByAsc : AppSetting.orderByDsc;
    this.indexModel.IsPostBack = true;
    this.getList();
  }

  onPaginateChange(event) {
    this.indexModel.Page = event.pageIndex + 1;
    this.indexModel.PageSize = event.pageSize;
    this.indexModel.IsPostBack = true;
    this.getList();
  }



  updateDeleteStatus(id) {
    const dialogRef = this._dialog.open(ConfirmationDialogComponent, {
      width: '50%',
      data: GlobalMessagesModel.ConfirmStatusChanged
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this._permissionMasterService.ChangeDeleteStatus(id).subscribe(
          data => {
            if (data.IsSuccess) {
              this._alertService.success(data.Message);
              this._commonService.ScrollingTop();
              this.getList();
            }
          },
          error => {
            this._alertService.error(error.message);
          });
      }
    });
  }

  updateActiveStatus(id) {
    const dialogRef = this._dialog.open(ConfirmationDialogComponent, {
      width: '50%',
      data: GlobalMessagesModel.ConfirmStatusChanged
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this._permissionMasterService.ChangeActiveStatus(id).subscribe(
          data => {
            if (data.IsSuccess) {
              this._alertService.success(data.Message);
              this.getList();
            }
          },
          error => {
            this._alertService.error(error.message);
          }
        );
      }
    });
  }

  SearchByKeyword(searchValue: GlobalFilterModel) {
    this.indexModel.Search = searchValue.search;
    this.indexModel.AdvanceSearchModel = { ActiveStatus: searchValue.ActiveStatus }

    this.getList();
  }

  ClearSearch() {
    this.indexModel = new IndexModel();
    this.getList();
  }



  openSetPagePasswordPopup(id: number) {
    const _dialogRef = this._dialog.open(SetPagePasswordDialogComponent, {
      width: "500px",
      height: "240px",
      data: { Id: id },
      disableClose: true
    });
    _dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        this.getList();
      }
    });
  }

  PagePrint(printContent) {
    this._commonService.printHTMLContent(printContent, "Page Master");
  }

}
